import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Body2, InfoIconButton, TemplateForm } from 'dls'
import { Link } from 'dls/atoms/Link'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import StatementConfigLoader from 'pages/features/Statements/StatementConfigLoader'
import StatementRadio from 'pages/features/Statements/SelectStatementPage/StatementRadio'
import { EntityBadgeStatements, Paper } from 'dls/atoms'
import { AvatarSize } from 'dls/shared/types'
import { StatementType } from 'store/products/types'
import StatementConfigActions from 'store/templates/statementConfiguration/actions'
import StatementConfigSelectors from 'store/templates/statementConfiguration/selectors'
import useModal from 'components/Modal/hooks/useModal'
import { HelpModalType } from 'store/modal/types'

const RadioItem = styled.li`
  list-style: none;
  position: relative;
  width: 100%;
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      max-width: calc(50% - 8px);
  }
  `}
`

const SelectStatementPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { productKey = '' } = useParams()
  const [product] = useSelectedProduct(productKey)
  const radioName = 'configKey'
  const productPageUrl = `/products/${productKey}/features/statements`
  const { showInfographicModal } = useModal()

  const draftStatementConfigKey = useSelector(StatementConfigSelectors.selectDraftStatementsForm)?.statementValue?.find(
    (statement) => statement.statementType === StatementType.INTEREST_STATEMENT
  )?.statementConfigKey

  const statementConfigKey =
    draftStatementConfigKey ??
    product?.statements?.find((stmnt) => stmnt.statementType === StatementType.INTEREST_STATEMENT)?.statementConfigKey

  const {
    formState: { isValid, isDirty },
    control,
    reset,
    getValues,
  } = useForm({ defaultValues: { configKey: statementConfigKey } })

  useEffect(() => {
    reset({ configKey: statementConfigKey })
  }, [statementConfigKey])

  if (!product) {
    return null
  }

  const onSubmitHandler = () => {
    const statementConfigurationKey = getValues(radioName) ?? ''
    dispatch(StatementConfigActions.updateSelectedStatementKey({ statementConfigurationKey }))
    navigate(productPageUrl)
  }

  const onCancelHandler = () => {
    dispatch(StatementConfigActions.updateSelectedStatementKey({ statementConfigurationKey: undefined }))
    navigate(productPageUrl)
  }

  return (
    <TemplateForm
      icon={<EntityBadgeStatements size={AvatarSize.LARGE} />}
      title={t('Choose statement')}
      description={
        <Body2>
          {t('Statements show here can be created and managed in ')}
          <Link component={RouterLink} to="/templates" color="secondary">
            {t('templates')}
          </Link>
        </Body2>
      }
      toolbarButtons={
        <InfoIconButton
          key="more-about-icon"
          ariaProps={{
            'aria-label': `${t('About statements')}`,
            'aria-haspopup': 'dialog',
            'aria-controls': 'more-about-dialog',
          }}
          onClick={() => showInfographicModal(HelpModalType.INTEREST_STATEMENTS_PRODUCT)}
          fontSize="medium"
          style={{ marginRight: 8 }}
        />
      }
      main={
        <Paper elevation={0}>
          <StatementConfigLoader product={product}>
            {(interestStatementConfigs) => (
              <form>
                <Box component="ol" display="flex" gridGap={16} flexWrap="wrap" padding={0} margin={0}>
                  {interestStatementConfigs.map((config) => (
                    <RadioItem key={config.statementConfigKey}>
                      <Controller
                        name={radioName}
                        control={control}
                        render={({ field }) => (
                          <StatementRadio
                            {...field}
                            label={config.name}
                            value={config.statementConfigKey ?? ''}
                            checked={config.statementConfigKey === field.value}
                            segment={product?.productSegment}
                            config={config}
                            t={t}
                          />
                        )}
                      />
                    </RadioItem>
                  ))}
                </Box>
              </form>
            )}
          </StatementConfigLoader>
        </Paper>
      }
      submitButtonText={t('Choose')}
      submitButtonDisabled={!isValid || !isDirty}
      submitButtonAriaProps={{ 'aria-controls': 'main' }}
      onSubmit={onSubmitHandler}
      onCancel={onCancelHandler}
    />
  )
}

export default SelectStatementPage
