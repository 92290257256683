import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { Colleague, Team, TeamFormValues, UserFormValues } from 'store/user/types'

interface LoginResponse {
  accessToken: string
  refreshToken: string
  tenxToken: string
  scope: string
  tokenType: string
  expiresIn: string
}

async function getToken(code: string) {
  const params = { code }
  const { data } = await (await createAsyncAuthAwareAxios('/v1')).post<LoginResponse>(
    '/colleagues/oauth2/token',
    params
  )
  return data
}

async function login(username: string, passcode: string) {
  const params = { username, passcode }
  const { data } = await (await createAsyncAuthAwareAxios('/v1')).post<LoginResponse>('/colleagues/login', params)
  return data
}

async function logout() {
  const { data } = await (await createAsyncAuthAwareAxios('/v1')).post('/colleagues/logout')
  return data
}

async function getUsers() {
  const { data } = await (await createAsyncAuthAwareAxios('')).get<Colleague[]>('/v1/colleagues/users')
  return data
}

async function createUser(payload: Partial<UserFormValues>) {
  const { data } = await (await createAsyncAuthAwareAxios('')).post<Colleague>(`/v1/colleagues/users`, payload)
  return data
}

async function getTeams() {
  const { data } = await (await createAsyncAuthAwareAxios('', true)).get<Team[]>('/v1/teams')
  return data
}

async function createTeam(payload: Partial<TeamFormValues>) {
  const { data } = await (await createAsyncAuthAwareAxios('', true)).post<Team>(`/v1/teams`, payload)
  return data
}

async function getUserByKey(payload: any) {
  const { data } = await (await createAsyncAuthAwareAxios('')).get<Colleague>(`/v1/colleagues/users/${payload}`)
  return data
}

export default { getToken, login, logout, getUsers, createUser, getTeams, createTeam, getUserByKey }
