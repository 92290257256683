import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Field, FormikProps, Form } from 'formik'
import { useSelector } from 'react-redux'
import DebitInterestInfoButton from '../DebitInterestInfoButton'
import { withFormikSimple } from 'utils/form.utils'
import { TooltipStyle } from 'pages/features/CreditInterest/styles'
import { DayCount, DebitInterestFormValues, BalanceCriteria, RoundingMethod } from 'store/products/types'
import {
  TextFieldComplex,
  ContextualHelp,
  FieldAmount,
  SelectFieldComplex,
  NoActivityHelpPanel,
  TemplateSubReview,
  BackButton,
} from 'components'
import ProductsSelectors from 'store/products/selectors'
import { H1, H2, H3, Body2, InfoPanel, Paper } from 'dls'
import ISOFrequencySelector from 'components/ISOFrequencySelector/ISOFrequencySelector'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Flexbox } from 'pages/Layout/Styled'
import { debitInterestCommonConfigValidationSchema } from 'pages/features/DebitInterest/utils/debitInterestValidationSchema'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  initialSetup?: boolean
  productKey: string
  isLending: boolean
}

const CommonConfigForm = (props: FormikProps<DebitInterestFormValues> & Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { initialSetup, isLending, isValid } = props
  const { isDebitInterestEnabled } = useSelector(ProductsSelectors.selectProductPermissions)

  const roundingTooltip = (
    <>
      <>{t('Round up – always round up the 3rd decimal')}</>
      <br />
      <>{t('Round down – always round down the 3rd decimal')}</>
      <br />
      <>
        {t(`Standard rounding – if the 3rd decimal place is equal or greater than 5 the value will
    round up, otherwise it will round down.`)}
      </>
    </>
  )

  const renderForm = () => (
    <>
      {initialSetup && (
        <Grid item xs={12}>
          <H2>{t(`Debit interest common config`)}</H2>
        </Grid>
      )}
      <Grid item xs={12}>
        <Field
          id="description"
          name="description"
          label={t('Description')}
          type="text"
          component={TextFieldComplex}
          placeholder={t('Enter description')}
        />
      </Grid>
      <Grid item xs={12}>
        <H3>{t('How interest is calculated & charged')}</H3>
      </Grid>
      <Grid item xs={12}>
        <InfoPanel
          title={t(isDebitInterestEnabled ? 'Default settings' : "Accrual period can't be changed")}
          body={t(
            isDebitInterestEnabled
              ? 'Interest calculation always happens at the end of each day. The accrual period can’t be changed after this product has been published'
              : 'These can’t be changed because a version of this product has already been published'
          )}
          css="margin: 0"
        />
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={12}>
          <ISOFrequencySelector disabled={!isDebitInterestEnabled} />
        </Grid>
      </Grid>
      <Grid container item direction="row" xs={12} spacing={1}>
        <Grid item xs={4}>
          <Field
            id="notificationApplication"
            name="notificationApplication"
            label={t('Apply after')}
            component={FieldAmount}
            placeholder={t('Enter number of days')}
            positiveIntegersOnly
            hideCurrencySymbol
            required
          />
        </Grid>
        <Grid item xs={2} style={{ paddingLeft: '10px' }}>
          <TooltipStyle>
            <ContextualHelp
              title={<>{t('The number of days after the accrual period that the account will be debited')}</>}
            />
          </TooltipStyle>
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={4}>
          <Field
            label={t('Rounding method')}
            name="roundingMethod"
            data={RoundingMethod}
            component={SelectFieldComplex}
            required
            displayEmpty
            fullWidth
            placeholder={t('Please select')}
            selectProps={{ displayEmpty: true }}
            inputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TooltipStyle>
            <ContextualHelp title={roundingTooltip} />
          </TooltipStyle>
        </Grid>
      </Grid>
    </>
  )

  return initialSetup ? (
    renderForm()
  ) : (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={() => navigate(-1)} aria-controls="main" />
              </Grid>
              <Grid item>
                <DebitInterestInfoButton />
              </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <H1 gutterBottom>{t(`DEBIT_INTEREST.SETUP_HEADER`)}</H1>
              </Grid>
              <Grid item>
                <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
                  {t(`DEBIT_INTEREST.${isLending ? 'LOAN' : 'CURRENT_SAVINGS'}.SETUP_DESCRIPTION`)}
                </Body2>
              </Grid>
            </Grid>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <Form>
            <Paper>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <H2>{t('DEBIT_INTEREST.commonConfig')}</H2>
                </Grid>
                <Grid container item direction="column" spacing={3}>
                  {renderForm()}
                </Grid>
              </Grid>
              <Grid item>
                <Flexbox width="100%" direction="row" justifyContent="flex-end" style={{ marginTop: 24 }}>
                  <Button
                    color={ColorType.BUTTON}
                    onClick={() => navigate(`/products/${props.productKey}/features/debit-interest`)}
                    style={{
                      marginRight: 16,
                    }}
                    aria-controls="main"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button variant="contained" color={ColorType.BUTTON} type="submit" disabled={!isValid}>
                    {t('Submit')}
                  </Button>
                </Flexbox>
              </Grid>
            </Paper>
          </Form>
        }
      />
    </ContentContainer>
  )
}

export const CommonConfigFormWithoutFormik = CommonConfigForm

export default withFormikSimple<Props, DebitInterestFormValues>(CommonConfigForm, {
  defaultValues: {
    dayCount: DayCount.ACT365,
    tierBands: [],
    balanceCriteria: BalanceCriteria.ENDOFDAY,
  },
  validationSchema: debitInterestCommonConfigValidationSchema,
})
