import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Field, Form, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { StyledDivider } from '../../features/TransactionRules/EditDefaultAccessForm'
import { headRows } from './TableRows'
import { FormBottom } from './Styled'
import { ConfirmPublish } from './ConfirmPublish'
import { EnhancedTableField, SubmitButton } from 'components'
import { withFormikSimple } from 'utils/form.utils'
import { SelectFieldData } from 'components/form/SelectFieldComplex'
import { AvailableProducts, PackagesWithProductsFormValue, PackageType } from 'store/packages/types'
import countriesJson from 'static/countries.json'
import { TableCommonDataType, TableCommonGetKeyString, TableCommonGetSearchString } from 'components/TableCommon/types'
import { CountryItem } from 'store/utils/types'
import PackagesActions from 'store/packages/actions'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  availableProducts: AvailableProducts[] | undefined
}

interface Props extends FormikProps<PackagesWithProductsFormValue>, OwnProps {}

const getSearchString = (item: AvailableProducts) => `${item.productName}`
const getKeyString = (item: AvailableProducts) => `${item.productKey}`

const EditPackageForm = (props: Props): React.ReactElement => {
  const { availableProducts, handleSubmit, initialValues, values } = props

  const dispatch = useDispatch()
  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      dispatch(PackagesActions.isPublishPackage(true))
    }
    if (isEqual(initialValues, values)) {
      dispatch(PackagesActions.isPublishPackage(false))
    }
  }, [initialValues, values, dispatch])

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openConfirmPublish, setOpenConfirmPublish] = useState(false)
  const onClickConfirmPublish = useCallback(() => setOpenConfirmPublish(true), [setOpenConfirmPublish])
  const onCloseConfirmPublish = () => setOpenConfirmPublish(false)
  const selectDataCountriesJson: SelectFieldData = {}
  Object.entries(countriesJson).forEach(([, item]: [string, CountryItem]) => {
    selectDataCountriesJson[`${item.alpha3Code}`] = {
      name: item.alpha3Code,
      label: item.name,
      flag: item.alpha2Code,
    }
  })

  const selectDataPackageType: SelectFieldData = {}
  Object.entries(PackageType).forEach(([key, value]) => {
    selectDataPackageType[key] = { name: value }
  })

  const confirmPublish = () => {
    onClickConfirmPublish()
  }

  return (
    <>
      <Form>
        {availableProducts && (
          <Field
            name="products"
            label=""
            type="text"
            component={EnhancedTableField}
            searchPlaceholderText={t('Search by name')}
            searchAria={t('Search products by name')}
            headRows={headRows}
            getSearchString={getSearchString as TableCommonGetSearchString}
            getKeyString={getKeyString as TableCommonGetKeyString}
            data={availableProducts as TableCommonDataType[]}
            title={t('Choose the products')}
            defaultRowsPerPage={25}
            selectableCellProps={{ style: { verticalAlign: 'text-top' } }}
            cardElevation={0}
            noEmptyRows
            touchedNotNeeded
            disableSelectAll
            required
          />
        )}
        <StyledDivider variant="fullWidth" style={{ height: 2 }} />
        <FormBottom container spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Grid item>
            <Button color={ColorType.BUTTON} onClick={() => navigate('/packages')} variant="text" aria-controls="main">
              {t('Cancel')}
            </Button>
          </Grid>
          <Grid item>
            <SubmitButton variant="contained" color={ColorType.BUTTON} onClick={confirmPublish}>
              {t('Create Package')}
            </SubmitButton>
          </Grid>
        </FormBottom>
      </Form>
      <ConfirmPublish open={openConfirmPublish} onClose={onCloseConfirmPublish} onClick={() => handleSubmit()} />
    </>
  )
}

export default withFormikSimple<OwnProps, PackagesWithProductsFormValue>(EditPackageForm, {
  validationSchema: Yup.object<Partial<PackagesWithProductsFormValue>>({
    products: Yup.array(Yup.string()).min(1, 'Must have at least 1 product selected'),
  }).required(),
})
