import React, { useEffect, useState } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { mapStateToProductProps } from './ProductFeatureViewMode/ProductFeatureViewMode'
import { StepperContainer } from './Style'
import ProductActions from 'components/ProductCard/ProductActions'
import DeleteRetireDialog from 'components/DeleteRetireDialog/DeleteRetireDialog'
import { ExtendedProduct, DeleteOrRetire, RetireOrDeleteProductOrPackage } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import ProductStepper from 'components/ProductPackageStepper/ProductPackageStepper'
import ProductsActions from 'store/products/actions'
import initialState from 'store/products/reducers/initialState'
import ProductsSelectors from 'store/products/selectors'
import PublishProduct from 'pages/Product/features/PublishProduct'
import ProductApproval from 'pages/Product/features/ProductApproval'
import EditLiveProduct from 'pages/Product/features/EditLiveProduct'
import { stopPropagation } from 'utils/form.utils'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface OwnProps {
  product: ExtendedProduct
  isOldVersion?: boolean
}

type Props = ReturnType<typeof mapStateToProductProps> & OwnProps

const ProductStepperCard = ({ product, isOldVersion }: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectAllProductsOrigin = useSelector(ProductsSelectors.selectAllProductsOrigin)
  const selectDataLoading = useSelector(ProductsSelectors.selectDataLoading)
  const selectError = useSelector(ProductsSelectors.selectError)
  const isProductInDesign = product.status === ProductStatus.DESIGN || product.status === ProductStatus.UPDATED

  useEffect(() => {
    if (
      product &&
      product.eligibility &&
      product.eligibility.productDependencyEligibility &&
      product.eligibility.productDependencyEligibility.dependency &&
      product.eligibility.productDependencyEligibility.dependency.productKey &&
      selectAllProductsOrigin === initialState.data &&
      !selectDataLoading &&
      !selectError
    ) {
      dispatch(ProductsActions.fetch())
    }
  }, [selectDataLoading, selectAllProductsOrigin, dispatch, selectError, product])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [actionType, setActionType] = useState<DeleteOrRetire>(DeleteOrRetire.DELETEPRODUCT)

  const handleDeleteOrRetire = (values: RetireOrDeleteProductOrPackage) => {
    setAnchorEl(null)
    setOpen(false)
    switch (actionType) {
      case DeleteOrRetire.DELETEPRODUCT:
        return dispatch(ProductsActions.deleteProduct(values))
      case DeleteOrRetire.RETIREPRODUCT:
        return dispatch(ProductsActions.retireProduct(values))
      default:
        throw new Error('Unknown delete action')
    }
  }
  const closeConfirmAndMenu = (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(false)
  }

  const openConfirm = (newActonType: DeleteOrRetire) => (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(true)
    setActionType(newActonType)
  }

  const { showInfographicModal } = useModal()

  return (
    <Paper style={{ padding: 16, marginTop: 24 }}>
      <StepperContainer>
        <div style={{ gridArea: 'stepperArea' }} className="stepperArea">
          <ProductStepper
            isOldVersion={isOldVersion}
            effectiveDate={product.effectiveDate || ''}
            status={product.status}
            type="PRODUCT"
          />
        </div>
        <div style={{ gridArea: 'infoArea', textAlign: 'right' }}>
          <InfoIconButton
            key="more-about-icon"
            ariaProps={{
              'aria-label': `${t('About products')}`,
              'aria-haspopup': 'dialog',
              'aria-controls': 'more-about-dialog',
            }}
            onClick={() => showInfographicModal(HelpModalType.PRODUCTS)}
            fontSize="large"
          />
        </div>
        <div style={{ gridArea: 'actionArea', whiteSpace: 'nowrap' }}>
          {!isOldVersion && product && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{ flexWrap: 'nowrap' }}
            >
              {!isOldVersion && [ProductStatus.LIVE].includes(product.status) && (
                <Grid item>
                  <EditLiveProduct product={product} />
                </Grid>
              )}
              {!isOldVersion &&
                [ProductStatus.REVIEW, ProductStatus.DESIGN, ProductStatus.UPDATED].includes(product.status) && (
                  <Grid item>
                    <ProductApproval product={product} isProductInDesign={isProductInDesign} />
                  </Grid>
                )}
              {[ProductStatus.REVIEW, ProductStatus.APPROVED].includes(product.status) && (
                <Grid item>
                  <PublishProduct product={product} />
                </Grid>
              )}
              <Grid item>
                <ProductActions
                  product={product}
                  searchElement={product.status}
                  iconButton
                  isOnProductPage
                  openConfirm={openConfirm}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl as any}
                  closeConfirmAndMenu={closeConfirmAndMenu}
                />
                <DeleteRetireDialog
                  open={open}
                  productOrPackageKey={product.productKey}
                  closeDialog={closeConfirmAndMenu}
                  handleDeleteOrRetire={handleDeleteOrRetire}
                  productOrPackageName={product.productName}
                  actionType={actionType}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </StepperContainer>
    </Paper>
  )
}

export default connect(mapStateToProductProps)(ProductStepperCard)
