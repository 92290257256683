import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress } from '@material-ui/core'
import { Body2, H3 } from 'dls'

const StatementTemplatesLoading = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="326px">
      <Box mb={5}>
        <CircularProgress size={75} />
      </Box>
      <H3 variantMapping={{ h3: 'p' }} gutterBottom>
        {t('Retrieving template')}
      </H3>
      <Body2>{t('Please wait')}</Body2>
    </Box>
  )
}

export default StatementTemplatesLoading
