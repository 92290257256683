import { v4 } from 'uuid'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { Feature, FeeRewardFeatureType, ProductKey } from 'store/products/types'
import { FeeReward } from 'store/products/features/feesRewards/types'

async function createFeeReward(
  productKey: ProductKey,
  version: number,
  payload: FeeReward,
  featureType: FeeRewardFeatureType
): Promise<FeeReward> {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).post<FeeReward>(
    `/v1/products/${productKey}/versions/${version}/features/${
      featureType === Feature.FEES ? 'scheduledFees' : 'scheduledRewards'
    }/v1`,
    payload,
    {
      headers: {
        'Idempotency-Key': v4(),
      },
    }
  )

  return data
}

async function updateFeeReward(
  productKey: ProductKey,
  version: number,
  entity: FeeReward,
  featureType: FeeRewardFeatureType
): Promise<FeeReward> {
  const { id, ...payload } = entity
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).put<FeeReward>(
    `/v1/products/${productKey}/versions/${version}/features/${
      featureType === Feature.FEES ? 'scheduledFees' : 'scheduledRewards'
    }/v1/${id}`,
    payload,
    {
      headers: {
        'Idempotency-Key': v4(),
      },
    }
  )

  return data
}

async function deleteFeeReward(productKey: ProductKey, version: number, id: string, featureType: FeeRewardFeatureType) {
  await (await createAsyncAuthAwareAxios(null, false)).delete(
    `/v1/products/${productKey}/versions/${version}/features/${
      featureType === Feature.FEES ? 'scheduledFees' : 'scheduledRewards'
    }/v1/${id}`
  )
}

export default {
  createFeeReward,
  updateFeeReward,
  deleteFeeReward,
}
