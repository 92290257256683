import React from 'react'
import { find } from 'lodash'
import { TFunction } from 'i18next'
import { Box, CircularProgress, Grid, Link, Typography } from '@material-ui/core'
import { Done, Error } from '@material-ui/icons/'
import styled from 'styled-components/macro'
import { CreditInterest } from 'store/products/types'
import { WithholdingRule } from 'store/withholdingTax/types'
import { stopPropagation } from 'utils/form.utils'

export const getRuleName = (rules: WithholdingRule[], ruleId: string, t: TFunction) => {
  const res = find(rules, { ruleId })
  return !!res && !!res.name ? res.name : t('Unknown')
}

export const ErrorTypography = styled(Typography)`
  color: ${(props: any) => `${props.theme.palette.error.main}`};
  margin-right: 16px;
`

export const TryAgainTypography = styled(Typography)`
  color: ${(props: any) => `${props.theme.palette.secondary.main}`};
`

interface Params {
  creditInterest: CreditInterest
  selectTaxRules?: WithholdingRule[]
  selectTaxRulesIsLoading: boolean
  selectError?: Error
  refreshTaxRules: () => void
  t: TFunction
}
const getWithholdingTaxDefinition = ({
  selectTaxRules,
  creditInterest: { withholdingTax },
  selectTaxRulesIsLoading,
  selectError,
  refreshTaxRules,
  t,
}: Params) => [
  {
    term: t('Withhold tax from interest'),
    definitions: [
      <>
        {!!withholdingTax && !!withholdingTax.taxRules && withholdingTax.taxRules.length > 0 ? (
          <>
            {selectTaxRulesIsLoading && (
              <CircularProgress
                size={25}
                style={{
                  marginTop: 0,
                  position: 'absolute',
                }}
              />
            )}
            {selectError && (
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Error
                    color="error"
                    style={{
                      marginTop: 0,
                      marginLeft: -31,
                      position: 'absolute',
                    }}
                  />
                  <ErrorTypography>{t('Error getting rules')}</ErrorTypography>
                </Grid>
                <Grid item>
                  <TryAgainTypography>
                    <Link
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        refreshTaxRules()
                      }}
                      color="secondary"
                      href="#top"
                    >
                      {t('try again')}
                    </Link>
                  </TryAgainTypography>
                </Grid>
              </Grid>
            )}
            {!selectTaxRulesIsLoading && !selectError && !!selectTaxRules && (
              <>
                <div>{t('Yes, applying the following rules;')}</div>
                <ul css="list-style: none; padding: 0">
                  {withholdingTax.taxRules.map((ruleId, key) => (
                    <li key={key}>
                      <Box display="flex" alignItems="center" gridGap={8}>
                        <Done />
                        <span>{getRuleName(selectTaxRules, ruleId, t)}</span>
                      </Box>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        ) : (
          <div>{t('No')}</div>
        )}
      </>,
    ],
  },
]

export default getWithholdingTaxDefinition
