import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams, useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { FeatureFormPageProps } from '../FeaturePage.page'
import StatementForm from './StatementForm'
import { hasStatementsFeature, toStatementFormValues } from './statementTransformers'
import { Feature, StatementFormValues } from 'store/products/types'
import ProductsActions from 'store/products/actions'
import { RootState } from 'store/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import { Loader } from 'components'
import StatementConfigSelectors from 'store/templates/statementConfiguration/selectors'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStatement: (productKey: string) => (statementFormValuesdefined: StatementFormValues) => {
    dispatch(ProductsActions.setStatement({ ...statementFormValuesdefined, productKey }))
  },
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const StatementFormPage = (props: Props) => {
  const { setStatement } = props
  const { productKey = '' } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)
  const draftStatementsForm = useSelector(StatementConfigSelectors.selectDraftStatementsForm)
  const navigate = useNavigate()
  useUpdateBreadcrumbs({ productKey, context: Feature.STATEMENTS })

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.STATEMENTS)) navigate(`./../not-allowed`)

  if (isLoading || !product) return <Loader />

  const initialValues = draftStatementsForm ? cloneDeep(draftStatementsForm) : toStatementFormValues(product)

  return (
    <StatementForm
      product={product}
      onSubmit={setStatement(productKey)}
      hasFeature={hasStatementsFeature(product) || !!draftStatementsForm?.statementValue?.length}
      initialValues={initialValues}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StatementFormPage)
