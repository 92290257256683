import { defaultsDeep, cloneDeep, get, orderBy } from 'lodash'
import { ThemeOptions } from '@material-ui/core'
// import { useSelector } from 'react-redux'
// import { useTranslation } from 'react-i18next'
import { PackagesWithProducts } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { ClientConfig, TenantConfig } from 'store/tenant-config/types'
import defaultTheme from 'config/themeDefault.json'
import { UserInformation, UserProfile, UserRole } from 'store/user/types'
import { Feature, TransactionRuleItemRule } from 'store/products/types'
// import { selectFeatures } from 'store/tenant-config/selectors'
// import { selectUserRole } from 'store/user/selectors'
export const applicationURL = /^$|((https|http)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(https|http)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/
export const checkUrl = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
export const checkAlphanumericWithoutSpace = /^[A-Za-z0-9-]+$/

export const slug = (name: string) => name.replace(/([A-Z])/g, '-$1').toLowerCase()

export const isPackageEditable = (currentPackage: PackagesWithProducts): boolean =>
  [PackagesStatus.DESIGN].includes(currentPackage.packageStatus)

export const isClientConfigAvailable = (clientConfig: ClientConfig | undefined, path: string | string[]): boolean => {
  const _path: string[] = Array.isArray(path) ? path : path.split('.')
  // eslint-disable-next-line no-restricted-syntax, no-plusplus
  for (let index = 0; index < _path.length; index++) {
    if (get(clientConfig, _path.slice(0, index + 1), undefined) === false) {
      return false
    }
  }
  return true
}

export const getMergedThemeWithDefault = (theme: ThemeOptions) => defaultsDeep(cloneDeep(theme), defaultTheme)

export const hasWithholdingTaxRule = (tenantConfig?: TenantConfig): boolean =>
  (!!tenantConfig && !!tenantConfig.features && tenantConfig.features.withholding_tax === true) || false

export const showMissionWIP = (tenantConfig?: TenantConfig): boolean => !!tenantConfig?.features?.mission_wip

export const hasTaxRules = (selectTaxRules: any): boolean =>
  !!selectTaxRules && Array.isArray(selectTaxRules) && selectTaxRules.length > 0

export const allowSelfAssign = (tenantConfig?: TenantConfig): boolean =>
  !!tenantConfig && !!tenantConfig.features && tenantConfig.features.allow_self_assign === true

export const isLocalEnvironment = (): boolean => process.env.NODE_ENV !== 'production'

export const isSTBEnvironment = (tenantConfig?: TenantConfig): boolean => !!tenantConfig?.environment.includes('test')

export const hasUserRightForPath = (userRole?: UserRole, path?: string, roleCheck?: boolean | string): boolean => {
  if (roleCheck === false) return true
  if (userRole) {
    if (userRole === UserRole.ADMIN || (!!path && path.includes('help'))) {
      return true
    }
    if (userRole === UserRole.PRODUCT && !!path && !`${path}`.includes('accounts')) {
      return true
    }
    if (
      userRole === UserRole.ACCOUNTS &&
      !!path &&
      !`${path}`.includes('templates') &&
      !`${path}`.includes('products') &&
      !`${path}`.includes('packages') &&
      !`${path}`.includes('reference') &&
      !`${path}`.includes('governance')
    ) {
      return true
    }
  }
  return false
}

export const useUserNameWithRole = () =>
  // temporarly disabled
  // const userRole = useSelector(selectUserRole)
  // const features = useSelector(selectFeatures)
  // const { t } = useTranslation()
  // if (features.role_check === true && ['BM_ADMIN', 'BM_PRODUCT_CREATOR', 'BM_ACCOUNTS_CREATOR'].includes(userRole)) {
  //   return (profile?: UserInformation | UserProfile) =>
  //     profile ? `${profile.firstName} ${profile.lastName} (${t(userRole)})` : null
  // }
  (profile?: UserInformation | UserProfile) => (profile ? `${profile.firstName} ${profile.lastName}` : undefined)

export const reOrderTransactionsRulesItems = (items: TransactionRuleItemRule[]): TransactionRuleItemRule[] =>
  orderBy(items, ['order'], ['asc']).map((i, order) => ({ ...i, order }))

export const truncate = (input: string, len: number) => (input.length > len ? `${input.substring(0, len)}...` : input)

export const isNullOrUndefined = (val: any) => typeof val === 'undefined' || val === null

export const getObjectByKey = <T>(obj: T) => <K extends keyof T>(prop: K) => <P extends keyof T[K]>(secondaryProp: P) =>
  obj?.[prop]?.[secondaryProp]

export const getCustomInitialFeatureRoute = (featureName: string) => {
  switch (featureName) {
    case Feature.DEBIT_INTEREST:
      return 'debit-interest-common-config'
    default:
      return featureName
  }
}

// Function to render Hex unicode to string. e.g. &#x2F; = /
export const hexCodeToString = (Text: string = '') => {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = Text
  return textarea.value
}

export const extractKeysFromObject = (data: {}, keys: string[]): string[] | undefined => keys.map((key) => data[key])
