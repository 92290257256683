import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { BreadcrumbsState } from './types'

const initialState = {
  error: undefined,
  pageTitleAndUrl: [],
}

const reducer = createReducer<BreadcrumbsState>(initialState, {
  [actions.updateBreadCrumbs.type]: (state) => ({ ...state, loading: true }),
  [actions.updateBreadCrumbsSuccess.type]: (state, action) => ({
    ...state,
    pageTitleAndUrl: action.payload,
    loading: false,
  }),
  [actions.updateBreadCrumbsFail.type]: (state, action) => ({ ...state, error: action.payload, loading: false }),
})

export default reducer
