/* eslint-disable react/no-unused-prop-types */
import React, { ReactElement } from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'
import { StyledCardActionArea, StyledContainerPaper } from './Styled'

interface CatalogCardProps {
  onClickOnCard: (e: React.MouseEvent) => void
  id: string
  region1?: ReactElement[]
  region2?: ReactElement[]
  region3?: ReactElement[]
  region4?: ReactElement[]
}

const GridContainer = styled.div<{
  region1?: ReactElement[]
  region2?: ReactElement[]
  region3?: ReactElement[]
  region4?: ReactElement[]
  id?: string
}>`
  margin: 0px;
  display: grid;
  grid-gap: 24px;
  ${({ region2, region3 }) => `
    grid-template-columns: ${!region2 && !region3 ? '14fr 1fr' : '12fr 1fr 2fr 1fr'};
  `};
  align-items: center;
  justify-items: center;
  width: 100%;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin: 13px;
      grid-template-columns: 50% 50%;
      align-items: center;
      justify-items: stretch;
      grid-gap: 0px;
      width: 100%;
      row-gap: 16px;
    }
  `};
`

const StyledRegion1 = styled.div`
  justify-self: flex-start;
`

const StyledRegion2 = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      justify-self: end;
    }
  `};
`

const StyledRegion3 = styled.div`
  justify-self: flex-start;
`

const StyledRegion4 = styled.div`
  justify-self: flex-end;
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      justify-self: end;
    }
  `};
`

const CatalogCard = ({ id, onClickOnCard, region1, region2, region3, region4 }: CatalogCardProps) => (
  <StyledContainerPaper elevation={1} data-test-id={id} data-dls="organism-CatalogCard">
    <StyledCardActionArea onClick={onClickOnCard} id={id}>
      <GridContainer region1={region1} region2={region2} region3={region3} region4={region4}>
        <StyledRegion1>
          {region1 && region1.length > 0 && (
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} wrap="nowrap">
              {region1.map((item, key) => (
                <Grid item key={key}>
                  {item}
                </Grid>
              ))}
            </Grid>
          )}
        </StyledRegion1>
        {((region2 && region2.length > 0) || (region3 && region3.length > 0)) && (
          <>
            <StyledRegion2>
              {region2 && region2.length > 0 && (
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
                  {region2.map((item, key) => (
                    <Grid item key={key}>
                      {item}
                    </Grid>
                  ))}
                </Grid>
              )}
            </StyledRegion2>
            <StyledRegion3>
              {region3 && region3.length > 0 && (
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
                  {region3.map((item, key) => (
                    <Grid item key={key}>
                      {item}
                    </Grid>
                  ))}
                </Grid>
              )}
            </StyledRegion3>
          </>
        )}

        <StyledRegion4>
          {region4 && region4.length > 0 && (
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
              {region4.map((item, key) => (
                <Grid item key={key}>
                  {item}
                </Grid>
              ))}
            </Grid>
          )}
        </StyledRegion4>
      </GridContainer>
    </StyledCardActionArea>
  </StyledContainerPaper>
)

export default CatalogCard

export const CatalogCardContainer = ({ children, style }: { children: ReactElement; style?: React.CSSProperties }) => (
  <div
    style={{ minHeight: 88, marginBottom: 16, height: 'auto !important', ...style }}
    data-dls="organism-CatalogCardContainer"
  >
    {children}
  </div>
)
