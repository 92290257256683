import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { BankInterestRateState } from './types'

const initialState = {
  loading: false,
  isUpdating: false,
  error: undefined,
  bankInterestRate: undefined,
  selectedIndexRate: undefined,
}

const reducer = createReducer<BankInterestRateState>(initialState, {
  [actions.fetchBankInterestRate.type]: (state) => ({ ...state, loading: true }),
  [actions.fetchBankInterestRateSuccess.type]: (state, action) => ({
    ...state,
    bankInterestRate: action.payload,
    loading: false,
  }),
  [actions.fetchBankInterestRateFail.type]: (state, action) => ({ ...state, loading: false, error: action.payload }),

  [actions.getBankIndexRateDetails.type]: (state) => ({ ...state, loading: true }),
  [actions.getBankIndexRateDetailsSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    selectedIndexRate: action.payload,
  }),
  [actions.getBankIndexRateDetailsFail.type]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [actions.editRate.type]: (state) => ({
    ...state,
    isUpdating: true,
  }),
  [actions.editRateSuccess.type]: (state, action) => ({
    ...state,
    selectedIndexRate: action.payload,
    isUpdating: false,
  }),
  [actions.editRateFail.type]: (state, action) => ({
    ...state,
    error: action.payload,
    isUpdating: false,
  }),
  [actions.createBankInterestRate.type]: (state) => ({ ...state, loading: true }),
  [actions.createBankInterestRateSuccess.type]: (state, action) => ({
    ...state,
    bankInterestRate: [...(state.bankInterestRate as any), action.payload],
    loading: false,
  }),
  [actions.createBankInterestRateFail.type]: (state, action) => ({ ...state, loading: false, error: action.payload }),

  [actions.addRate.type]: (state) => ({
    ...state,
    isUpdating: true,
  }),
  [actions.addRateSuccess.type]: (state, action) => ({
    ...state,
    selectedIndexRate: action.payload,
    isUpdating: false,
  }),
  [actions.addRateSuccess.type]: (state, action) => ({
    ...state,
    error: action.payload,
    isUpdating: false,
  }),
})

export default reducer
