import React from 'react'
import ReduxProvider from './ReduxProvider'
import I18nProvider from './I18nProvider'
import ThemeProvider from './TenantConfigProvider'

const Provider = ({ children, storybook }: { storybook?: boolean; children: React.ReactNode }): React.ReactElement => (
  <ReduxProvider storybook={storybook}>
    <I18nProvider>
      <ThemeProvider storybook={!!storybook}>{children}</ThemeProvider>
    </I18nProvider>
  </ReduxProvider>
)

export default Provider
