import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { FastField, FormikProps, getIn } from 'formik'
import { orderBy } from 'lodash'
import { CreditInterestFormValues } from 'store/products/types'
import FormikMultiSelectAutocomplete, {
  AutocompleteSelectFieldData,
} from 'components/form/FormikMultiSelectAutocomplete'
import { AccessibleLink, ContextualHelp, SwitchFieldComplex, Loader } from 'components'
import { WithholdingRule } from 'store/withholdingTax/types'
import { hasTaxRules } from 'utils/common.util'
import { InfoPanel } from 'dls'

interface OwnProps {
  selectTaxRules?: WithholdingRule[]
}

const Title = styled(Typography)`
  margin-bottom: 24px;
`

const ContentSpace = styled.div`
  padding-top: 32px;
  width: 100%;
`

interface StaticMarginProps extends FormikProps<CreditInterestFormValues>, OwnProps {}

const WithholdingTax = (props: StaticMarginProps): React.ReactElement => {
  const { t } = useTranslation()
  const { values, selectTaxRules } = props

  const showWithholdingTax = getIn(values, 'showWithholdingTax')

  const availableHoldingTaxRules = useMemo(() => hasTaxRules(selectTaxRules), [selectTaxRules])

  const selectTaxRulesData: AutocompleteSelectFieldData = {}
  orderBy(selectTaxRules, ['name'], ['asc']).forEach((item) => {
    selectTaxRulesData[item.ruleId] = { label: item.name, key: item.ruleId }
  })

  return (
    <ContentSpace>
      <Grid container direction="row" justifyContent="flex-start" alignContent="center" alignItems="center">
        {selectTaxRules ? (
          <>
            <Grid item>
              <FastField
                name="showWithholdingTax"
                aria-label="show withholding tax"
                data-test-id="withholdingTax.showWithholdingTax"
                label={t('Withhold tax from credit interest')}
                component={SwitchFieldComplex}
                onClick={() => {
                  if (showWithholdingTax === true /* because the new value will be false */) {
                    props.setFieldValue('withholdingTax', null)
                    props.setFieldTouched('withholdingTax', false, false)
                  }
                }}
              />
            </Grid>
            <Grid item>
              <ContextualHelp
                title={t(
                  'If you choose to enable withholding tax you’ll be able to choose the rules and tax rates which apply to the credit interest for this product. The rules you’ll see here are created and managed in Reference data.'
                )}
              />
            </Grid>
          </>
        ) : (
          <Loader shrink />
        )}
      </Grid>
      {showWithholdingTax === true && selectTaxRules ? (
        <>
          {availableHoldingTaxRules ? (
            <>
              <Title variant="h3" style={{ marginTop: 24 }} aria-label="show withholding tax">
                {t('Withholding tax rules')}
              </Title>
              <Typography variant="body1" variantMapping={{ body2: 'div' }}>
                {t(`Choose the withholding tax rules applicable for this product or you can create new rules in `)}
                <AccessibleLink color="secondary" to="/reference-data/withholding-tax">
                  {t(`Reference data.`)}
                </AccessibleLink>
              </Typography>
              <Grid container direction="row" justifyContent="flex-start" spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={12}>
                  <FastField
                    label={t('Select rules')}
                    name="withholdingTax.taxRules"
                    aria-label="tax rules"
                    data={selectTaxRulesData}
                    data-test-id="withholdingTax.taxRules"
                    component={FormikMultiSelectAutocomplete}
                    required
                    displayEmpty
                    fullWidth
                    multiple
                    selectAll
                    selectAllLabel={t('Select all rules')}
                    placeholder={t('Select or start typing')}
                    selectProps={{ displayEmpty: true }}
                    inputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <InfoPanel
              title={t('You don’t have any rules for withholding tax')}
              body={
                <>
                  <Typography variant="body2" variantMapping={{ body2: 'div' }}>
                    {t(`Don’t worry, you can create rules for `)}
                    <AccessibleLink color="secondary" to="/reference-data/withholding-tax">
                      {t(`withholding tax in Reference data. `)}
                    </AccessibleLink>
                    {t(`Once you have at least 1 rule created you’ll be able to continue here`)}
                  </Typography>
                </>
              }
            />
          )}
        </>
      ) : null}
    </ContentSpace>
  )
}

export default WithholdingTax
