import React from 'react'
import DebitInterestRateDefinitions from '../shared/DebitInterestRateDefinitions'
import CommonConfigDefinitions from './CommonConfigDefinitions'
import { DebitInterest, ExtendedProduct } from 'store/products/types'

interface OwnProps {
  product: ExtendedProduct
  debitInterest: DebitInterest
  isLending: boolean
}

const ViewDebitInterest = ({ product, debitInterest, isLending }: OwnProps) => {
  if (!debitInterest) {
    return null
  }

  const { rateAboveLimit, rateAboveLimitOverride, rateWithinLimit, commonConfig, description } = debitInterest

  return (
    <>
      <CommonConfigDefinitions commonConfig={commonConfig} description={description} />
      <DebitInterestRateDefinitions
        position="productPage"
        product={product}
        rateAboveLimit={rateAboveLimit}
        rateWithinLimit={rateWithinLimit}
        rateAboveLimitOverride={rateAboveLimitOverride}
        currencyCode={product?.currency?.currencyCode}
        isLending={isLending}
      />
    </>
  )
}

export default ViewDebitInterest
