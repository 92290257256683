/* eslint-disable no-nested-ternary */
import { StepLabelProps } from '@material-ui/core/StepLabel'
import {
  StatusDesignStepIcon,
  StatusReviewStepIcon,
  StatusApprovedStepIcon,
  StatusScheduledStepIcon,
  StatusLiveStepIcon,
  StatusRetiredStepIcon,
  CompletedIcon,
} from './StepIcons'
import { BankDateTime } from 'utils/date.utils'
import { ProductStatus } from 'store/products/typings/productStatus'
import { PackagesStatus } from 'store/packages/typings/packageStatus'

export interface Steps {
  status: ProductStatus | PackagesStatus
  label: string
  completed?: boolean
  labelProps?: StepLabelProps
  StepIconComponent?: any
}

export const getSteps = (
  status: ProductStatus,
  isGovernanceEnabled: string | boolean | undefined,
  currentStep: number
): Steps[] => {
  const steps: Steps[] = []
  steps.push({
    status: ProductStatus.DESIGN,
    label: 'Design',
    completed: currentStep > steps.length,
    labelProps: {
      StepIconComponent:
        currentStep === steps.length ? StatusDesignStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
      StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
    },
  })
  steps.push({
    status: ProductStatus.REVIEW,
    label: 'Review',
    completed: currentStep > steps.length,
    labelProps: {
      StepIconComponent:
        currentStep === steps.length ? StatusReviewStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
      StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
    },
  })
  if (isGovernanceEnabled) {
    steps.push({
      status: ProductStatus.APPROVED,
      label: 'Approved',
      completed: currentStep > steps.length,
      labelProps: {
        StepIconComponent:
          currentStep === steps.length
            ? StatusApprovedStepIcon
            : currentStep > steps.length
            ? CompletedIcon
            : undefined,
        StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
      },
    })
  }
  steps.push({
    status: ProductStatus.LIVE,
    label: 'Scheduled',
    completed: currentStep > steps.length,
    labelProps: {
      StepIconComponent:
        currentStep === steps.length ? StatusScheduledStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
      StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
    },
  })
  steps.push({
    status: ProductStatus.LIVE,
    label: 'Live',
    completed: currentStep > steps.length,
    labelProps: {
      StepIconComponent:
        currentStep === steps.length ? StatusLiveStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
      StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
    },
  })
  if (status === ProductStatus.CLOSED) {
    steps.push({
      status: ProductStatus.CLOSED,
      label: 'Retired',
      completed: currentStep > steps.length,
      labelProps: {
        StepIconComponent:
          currentStep >= steps.length ? StatusRetiredStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
        StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
      },
    })
  }

  return steps
}

export const getActiveStep = (
  status: ProductStatus,
  isGovernanceEnabled: string | boolean,
  date?: BankDateTime
): number => {
  const offsetAfterApproveStatus = isGovernanceEnabled ? 1 : 0
  if ([ProductStatus.DESIGN, ProductStatus.UPDATED].includes(status)) return 0
  if ([ProductStatus.REVIEW].includes(status)) return 1
  if ([ProductStatus.APPROVED].includes(status)) return 1 + offsetAfterApproveStatus
  if ([ProductStatus.LIVE].includes(status) && date && date.isAfterNow) return 2 + offsetAfterApproveStatus
  if ([ProductStatus.LIVE].includes(status) && !(date && date.isAfterNow)) return 3 + offsetAfterApproveStatus
  if ([ProductStatus.CLOSED].includes(status)) return 4 + offsetAfterApproveStatus
  return 0
}
