import React from 'react'
import 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core/'
import { Card } from 'store/products/types'
import { cardLogo } from 'pages/Product/Pdf/cards'
import { CardLogo } from 'pages/features/Cards/Styled'
import { Body1, Body2, Line, ReviewRows, Subtitle1 } from 'dls'
import { ReviewRowItem } from 'dls/molecules/ReviewRows/types'

interface OwnProps {
  cards: Card[]
}

const ViewCards = (props: OwnProps): React.ReactElement => {
  const { t } = useTranslation()

  const buildCardImageDefitions = (card: Card): ReviewRowItem[] => {
    const definitions = [
      {
        term: t('What customer will see'),
        definitions: [
          <Box key="card" display="flex" flexDirection="column" gridGap={16}>
            {card.name && <Body2>{card.name}</Body2>}
            <Box display="flex" justifyContent="space-between">
              {card.description && <Body2>{card.description}</Body2>}
              {card.frontTemplateImage?.url && (
                <img src={card.frontTemplateImage.url} width="30%" alt={t('card image')} aria-hidden />
              )}
            </Box>
          </Box>,
        ],
      },
    ]

    if (card.frontTemplateImage?.url) {
      definitions.push({
        term: t('Card image URL'),
        definitions: [<Body2 key="card-url">{card.frontTemplateImage.url}</Body2>],
      })
    }

    return definitions
  }

  const buildCardDetailsDefinitions = (card: Card): ReviewRowItem[] => {
    const definitions: ReviewRowItem[] = []

    if (card.scheme) {
      definitions.push({
        term: t('Scheme'),
        definitions: [
          <Box key="scheme">
            <CardLogo src={cardLogo[card.scheme]} alt={card.scheme} />
            <Body1>{t(card.scheme)}</Body1>
          </Box>,
        ],
      })

      if (card.maximumNumber) {
        definitions.push({
          term: t('Maximum number of cards'),
          definitions: [`${card.maximumNumber}`],
        })
      }
    }

    if (card.processorId) {
      definitions.push({
        term: t('Processor product ID'),
        definitions: [card.processorId],
      })
    }

    if (card.processorLogoId) {
      definitions.push({
        term: t('Processor logo ID'),
        definitions: [card.processorLogoId],
      })
    }

    return definitions
  }

  return (
    <>
      {props.cards.map((card) => (
        <div key={card.cardId ? card.cardId : uuidv4()} data-test-id="card-item" css="word-break: break-word">
          {card.name && <Subtitle1 gutter>{`${card.name} - ${t(`${card.formType}_LABEL`).toLowerCase()}`}</Subtitle1>}
          <ReviewRows
            definitionsList={[
              {
                groupSeparator: 'light',
                definitions: buildCardImageDefitions(card),
              },
              {
                groupSeparator: 'light',
                definitions: buildCardDetailsDefinitions(card),
              },
              {
                definitions: [
                  {
                    term: t('How the card can be used'),
                    definitions: [
                      t(
                        `ATM, Chip & PIN, CNP (Card not present - online, mobile and over the phone), Contactless, International, MagStripe`
                      ),
                    ],
                  },
                ],
              },
            ]}
          />
          <Line css="margin-top: 24px; margin-bottom: 24px" type="strong" />
        </div>
      ))}
    </>
  )
}

export default ViewCards
