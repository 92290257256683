import React, { useState } from 'react'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import { KeyboardArrowDown, Create, RemoveRedEye, AddCircle } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { EntityBadgeHistory } from 'dls/atoms'
import AccessibleMenu, { AccessibleMenuItem } from 'components/AccessibleMenu/AccessibleMenu'
import { BankInterestRate } from 'store/referenceData/types'
import { stopPropagation } from 'utils/form.utils'
import { Button, ButtonCustomProps } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  indexRate: BankInterestRate
  noArrowDown?: boolean
  buttonChildren: React.ReactNode
  buttonProps?: ButtonCustomProps
}

const BankInterestRateCardActions = ({ indexRate, buttonProps, noArrowDown = false, buttonChildren }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentDate = moment()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const id = `index-rate-menu-${indexRate.bankIndexKey}`

  function openMenu(e: React.MouseEvent<HTMLButtonElement>) {
    stopPropagation(e)
    setAnchorEl(e.currentTarget)
  }

  function closeMenu(e: React.MouseEvent) {
    stopPropagation(e)
    setAnchorEl(null)
  }

  function onClick(e: React.MouseEvent<Element>, url: string | number) {
    e.preventDefault()
    setTimeout(() => navigate(`/reference-data/index-rates/${indexRate.bankIndexKey}${url || ''}`))
  }

  const historicalDateRate = indexRate.rates.filter((item) =>
    item.effectiveDate ? moment(item.effectiveDate).isSameOrBefore(currentDate) : ''
  )

  const futureDateRate = indexRate.rates.filter((item) =>
    item.effectiveDate ? moment(item.effectiveDate).isAfter(currentDate) : ''
  )

  const menuItems: AccessibleMenuItem[] = []

  if (indexRate) {
    menuItems.push({
      onClick,
      testId: 'index-rate-view-button',
      ariaControls: 'main',
      children: (
        <>
          <ListItemIcon>
            <RemoveRedEye style={{ marginRight: 8 }} />
          </ListItemIcon>
          <ListItemText primary={t('View')} />
        </>
      ),
    })
  }
  if (futureDateRate?.length > 0) {
    menuItems.push({
      onClick: (e: React.MouseEvent) => onClick(e, '/edit'),
      testId: 'index-rate-edit-button',
      ariaControls: 'main',
      children: (
        <>
          <ListItemIcon>
            <Create style={{ marginRight: 8 }} />
          </ListItemIcon>
          <ListItemText primary={t('Edit future rate')} />
        </>
      ),
    })
  }
  menuItems.push({
    onClick: (e: React.MouseEvent) => onClick(e, '/add'),
    testId: 'index-rate-add-button',
    ariaControls: 'main',
    children: (
      <>
        <ListItemIcon>
          <AddCircle style={{ marginRight: 8 }} />
        </ListItemIcon>
        <ListItemText primary={t('Add future rate')} />
      </>
    ),
  })
  if (historicalDateRate?.length > 0) {
    menuItems.push({
      onClick: (e: React.MouseEvent) => onClick(e, '/history'),
      testId: 'index-rate-historic-rate-button',
      ariaControls: 'main',
      children: (
        <>
          <ListItemIcon>
            <EntityBadgeHistory style={{ verticalAlign: 'bottom' }} />
          </ListItemIcon>
          <ListItemText primary={t('View historic rates')} />
        </>
      ),
    })
  }

  const menuButton = (
    <Button
      color={ColorType.NONE}
      size="small"
      onClick={openMenu}
      {...buttonProps}
      disabled={menuItems.length === 0}
      aria-label="More actions"
      aria-controls={`index-rate-menu-${indexRate.bankIndexKey}`}
      aria-haspopup="true"
    >
      {buttonChildren}
      {!noArrowDown && <KeyboardArrowDown />}
    </Button>
  )

  return (
    <>
      {menuItems.length > 0 ? (
        <AccessibleMenu
          id={id}
          anchorEl={anchorEl as any}
          menuItems={menuItems}
          menuButton={menuButton}
          onClose={closeMenu}
        />
      ) : (
        <Button color={ColorType.BUTTON} size="small" onClick={onClick as any} style={{ width: 91 }}>
          {t('View')}
        </Button>
      )}
    </>
  )
}

export default BankInterestRateCardActions
