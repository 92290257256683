import React from 'react'
import { useTranslation } from 'react-i18next'
import About from './about'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const AboutPage = () => {
  const { t } = useTranslation()
  useBreadcrumbs({ breadcrumbs: [{ title: t('About'), path: '' }], trigger: true })

  return <About />
}

export default AboutPage
