import { ClickAwayListener } from '@material-ui/core'
import { DatePicker, DateTimePicker as DateTimePickerMUI, DateTimePickerProps } from '@material-ui/pickers'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'
import moment, { Moment } from 'moment-timezone'
import Input, { MInputProps } from './Input'
import { DATETIME_FORMAT, DATE_FORMAT, floorTimestamp } from 'utils/date.utils'
import { FloorType } from 'utils/types'

interface DateTimePickerOwnProps extends Omit<MInputProps, 'onChange' | 'value'> {
  dateTimePickerProps?: Omit<DateTimePickerProps, 'css'>
  label?: string
  type: string
  format?: string
  disableToolbar?: boolean | undefined
  autoOk?: boolean | undefined
  defaultIsNow?: boolean
  round?: FloorType
  required?: boolean
  onChange: (e: Moment | null) => void
  value: Moment | null
}

const BMDateTimePicker = styled(({ pickerType, ...props }) =>
  pickerType === 'DateTime' ? <DateTimePickerMUI {...props} /> : <DatePicker {...props} />
)`
  margin-top: -14px;
  & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
    display: none;
  }
  .MuiPickersCalendar-root {
    min-height: 210px;
  }
  & .Mui-error {
    color: ${(props: any) => `${props.theme.palette.error.dark}`};
  }

  & .Mui-disabled {
    color: ${(props: any) => `${props.theme.palette.text.disabled}`};
  }
`

const DateTimePicker = React.forwardRef(
  (
    {
      format,
      defaultIsNow = true,
      round,
      type,
      value,
      onChange,
      dateTimePickerProps,
      ...fieldProps
    }: DateTimePickerOwnProps,
    ref: React.Ref<any>
  ) => {
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
      if (defaultIsNow && !value && !(fieldProps.disabled || fieldProps.readOnly)) {
        onChange(moment())
      }
    }, [value, defaultIsNow, fieldProps.disabled, fieldProps.readOnly])

    const setValue = (date: Moment) => {
      if (date && !(fieldProps.disabled || fieldProps.readOnly)) {
        onChange(round ? floorTimestamp(date as Moment, round) : date)
      }
    }

    const sonChangeDebounced = useCallback(setValue, [])
    return (
      <ClickAwayListener data-dls="molecule-dateTime-picker" onClickAway={() => setOpen(false)}>
        <BMDateTimePicker
          disableMaskedInput
          disablePast
          css={undefined}
          open={open}
          value={[undefined, null].includes(value as any) ? null : value}
          onOpen={() => !(fieldProps.disabled || fieldProps.readOnly) && setOpen(true)}
          onClose={() => setOpen(false)}
          pickerType={['datetime', 'datetime-local'].includes(type) ? 'DateTime' : 'Date'}
          onChange={sonChangeDebounced}
          inputFormat={format || ['datetime', 'datetime-local'].includes(type) ? DATETIME_FORMAT : DATE_FORMAT}
          mask="__/___/____"
          renderInput={({ disabled, inputProps, ...props }: { [x: string]: any; disabled: any; inputProps: any }) => (
            <Input
              {...props}
              {...fieldProps}
              disabled={disabled || fieldProps.disabled || fieldProps.readOnly}
              inputProps={{
                ...inputProps,
                disabled: disabled || fieldProps.disabled || fieldProps.readOnly || inputProps.disabled,
              }}
              onClick={() => !(fieldProps.disabled || fieldProps.readOnly) && setOpen(true)}
              error={fieldProps.error || !!props.error}
              helperText={props.error ? props.error : fieldProps.helperText || props.helperText}
              endAdornment={fieldProps.endAdornment || props.InputProps.endAdornment}
              startAdornment={fieldProps.startAdornment || props.InputProps.startAdornment}
              ref={ref}
            />
          )}
          {...dateTimePickerProps}
        />
      </ClickAwayListener>
    )
  }
)

export default DateTimePicker
