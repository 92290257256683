import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReachLink, useNavigate } from 'react-router-dom'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { Body1 } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  title: string
  goto: string
  icon: React.ReactElement
}

const ReferenceDataCard = ({ title, goto, icon }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(goto)
  }

  return (
    <CatalogCard
      region1={[
        icon,
        <Body1 variantMapping={{ body1: 'div' }} key="1">
          {t(title)}
        </Body1>,
      ]}
      region4={[
        <Button component={ReachLink} to={goto} aria-controls="main" variant="text" color={ColorType.BUTTON} key="1">
          {t('View')}
        </Button>,
      ]}
      onClickOnCard={handleClick}
      id=""
    />
  )
}

export default ReferenceDataCard
