import { Grid, Paper, Typography } from '@material-ui/core'
import { Form, FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import React, { ReactNode, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, kebabCase, mapValues, every, capitalize } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import CustomDocument from '../Documents/CustomDocument'
import AemDocumentView from '../Documents/AemDocumentView'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { AdditionSpacing } from '../Styled'
import {
  FormBottomBar,
  FormAddFeatureButton,
  SectionArray,
  ZeroStateLandingPage,
  ConfirmModalDeprecated,
} from 'components'
import { Document, MultipleChoiceElement, Origin, ProductKey, CommonFeaturePageValues } from 'store/products/types'
import { stopPropagation, withFormikSimple } from 'utils/form.utils'
import { DeleteInfo, NoTerms } from 'assets'
import { DeleteIconButton, Accordion, Body1 } from 'dls'
import { featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'

interface OwnProps {
  defaultValues: {
    termsAndConditionsVal?: {
      origin: string
      files: {
        format: null
        url: string
        label: string
      }[]
    }
  }
  // eslint-disable-next-line react/no-unused-prop-types
  hasFeature?: boolean
  productKey?: ProductKey
  remoteDocuments?: Document[]
}

interface Props {
  remove: (a: number) => void
  name: string
  index: number
  title: string
}
interface TermsAndConditionsFormProps extends FormikProps<CommonFeaturePageValues>, OwnProps {}

const createRemoteTermsAndConditionsElements = (
  documents: Document[],
  t: TFunction
): { [key: string]: MultipleChoiceElement } =>
  documents.reduce<any>(
    (acc, remoteDocument) =>
      kebabCase(remoteDocument.type) === kebabCase(t('termsAndConditions'))
        ? [
            ...acc,
            // assertion because document types are different between services, this one SHOULD always give name
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {
              defaultValue: { ...remoteDocument, origin: Origin.AEM, formType: 'remoteDocument' },
              multiple: false,
              name: 'existingTermsAndConditions',
              identifier: remoteDocument.identifier,
              identifierOriginal: remoteDocument.identifierOriginal,
              itemName: remoteDocument.name,
              disable: remoteDocument.name,
              label: `${remoteDocument.title || remoteDocument.name} v${remoteDocument.version}`,
            },
          ]
        : acc,

    []
  )

const TermsAndConditionsForm = ({
  values,
  defaultValues = {},
  remoteDocuments = [],
  productKey = '',
}: TermsAndConditionsFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const remoteElements = useMemo(() => createRemoteTermsAndConditionsElements(remoteDocuments, t), [remoteDocuments])
  const elementsList = useMemo(
    () =>
      remoteDocuments.length === 0
        ? {
            existingTermsAndConditions: {
              defaultValue: { ...defaultValues.termsAndConditionsVal, formType: 'existingTermsAndConditions' },
              multiple: true,
              name: 'existingTermsAndConditions',
              label: t('customTermsAndConditions'),
            },
          }
        : {
            existingTermsAndConditions: remoteElements,
            customTermsAndConditions: {
              defaultValue: { ...defaultValues.termsAndConditionsVal, formType: 'existingTermsAndConditions' },
              multiple: true,
              name: 'existingTermsAndConditions',
              label: t('customTermsAndConditions'),
            },
          },
    [remoteElements]
  )

  const [openConfirm, setOpenConfirm] = useState<number | null>(null)

  const getDeleteConfirm = ({ remove, name, index, title }: Props): ReactNode => (
    <ConfirmModalDeprecated
      id={`delete-card-${name}-${index}`}
      key={`delete-card-${name}-${index}`}
      onCancelClick={() => {
        setOpenConfirm(null)
      }}
      open={openConfirm === index}
      onConfirmClick={{
        action: () => {
          setOpenConfirm(null)
          remove(index)
        },
        title: t('Ok, got it'),
      }}
      title={<>{t(`${title} will only be deleted when you submit the form`)}</>}
      image={{ src: DeleteInfo, alt: t('Delete Icon') }}
      imageStyle={{ marginBottom: '24px' }}
      cancelText="Cancel"
    />
  )

  const renderDocument = (): ReactNode | null => {
    const remoteElementKey = 'existingTermsAndConditions'
    return (
      <SectionArray key={remoteElementKey} name={remoteElementKey}>
        {(props) => {
          const { remove, name, index } = props
          const item = getIn(values, name)
          const description = getIn(values, `${name}.description`)
          const version = getIn(values, `${name}.version`)
          const files = getIn(values, `${name}.files`)
          const origin = getIn(values, `${name}.origin`)
          const isNewlyAdded =
            !!getIn(values, `${name}.formType`) && index === values.existingTermsAndConditions?.length - 1

          let title = `${capitalize(`${item.name || ''}`)}`
          let keyName = kebabCase(item.name)

          if (origin === Origin.CUSTOM) {
            title = title || t('New document (from URL)')
            keyName = `${values.existingTermsAndConditions.length}`
          } else if (origin !== Origin.CUSTOM && origin !== Origin.AEM) {
            return null
          }

          return (
            <React.Fragment key={`AEMorCustomTermsAndConditions-${keyName}-${index}`}>
              {openConfirm === index && getDeleteConfirm({ remove, name, index, title })}
              <Accordion
                title={<Body1>{title}</Body1>}
                headerIcon={featureIconsCreator(featureSVGIcons.termsAndConditions)}
                headerButtonGroup={
                  <DeleteIconButton
                    onClick={(e: React.MouseEvent) => {
                      stopPropagation(e)
                      setOpenConfirm(index)
                    }}
                  />
                }
                defaultExpanded={isNewlyAdded}
                externalExpanded={isNewlyAdded}
                style={{ marginBottom: 16 }}
              >
                {origin === Origin.AEM ? (
                  <AdditionSpacing>
                    <AemDocumentView files={files} description={description} version={version} />
                  </AdditionSpacing>
                ) : (
                  <AdditionSpacing>
                    <CustomDocument name={`${name}`} />
                  </AdditionSpacing>
                )}
              </Accordion>
            </React.Fragment>
          )
        }}
      </SectionArray>
    )
  }

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Terms & conditions')}
      description={t('Here you can add your terms & conditions to your product.')}
      rightArea={
        <FormAddFeatureButton
          addLabel={t('Add terms & conditions')}
          // This props create dropdown
          elements={elementsList as any}
        />
      }
    >
      <Form>
        {!every(values, (value) => isEmpty(value)) ? (
          renderDocument()
        ) : (
          <Paper style={{ paddingTop: 40, marginBottom: 15 }}>
            <ZeroStateLandingPage
              image={NoTerms}
              title="No terms & conditions have been added"
              fixHeight={false}
              imageAlt="Image of a lady with Terms and conditions document"
            >
              <Typography variant="body2" style={{ marginBottom: 40, marginTop: -10 }}>
                {t('Select “Add terms & conditions” above to get started')}
              </Typography>
            </ZeroStateLandingPage>
          </Paper>
        )}
        <Grid item xs={12}>
          <FormBottomBar position="bottom" showCancel onCancelClick={onPressBack} />
        </Grid>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, CommonFeaturePageValues>(TermsAndConditionsForm, {
  validationSchema: Yup.lazy<object>((obj) =>
    Yup.object(
      mapValues(obj, () =>
        Yup.array(
          Yup.object({
            name: Yup.string().required('This is a required field'),
            description: Yup.string().required('This is a required field'),
            version: Yup.string().required('This is a required field').min(1).max(200),
            files: Yup.array(
              Yup.object({
                format: Yup.mixed().required('This is a required field'),
                url: Yup.string().url('This must be valid URL').required('This is a required field'),
                label: Yup.string().required('This is a required field'),
              })
            ),
          })
        )
      )
    )
  ),
  isInitialValid: (props) => !!props.hasFeature,
})
