import styled from 'styled-components/macro'
import { Grid, Paper, Dialog, Typography, InputAdornment } from '@material-ui/core'

export const ButtonContainer = styled.div`
  align-items: center;
  margin-bottom: 24px;
`
export const ContentPaper = styled(Paper)`
  padding: 0px;
  margin-bottom: 24px;
  overflow: auto;
`
export const FormBottom = styled.div`
  .MuiCardActions-root {
    padding: 0;
    margin: 6px 4px;
    display: block;
  }
`
export const ContentPaperContainer = styled(ContentPaper)`
  padding: 24px;
`
export const StyledInputAdornment = styled(InputAdornment)`
  margin-left: 0px;
  margin-right: 10px;
`
export const ContentGridContainer = styled(Grid)`
  padding-top: 0px;
  padding-bottom: 24px;
`
export const TooltipStyle = styled.div`
  margin-top: 16px;
  margin-left: 16px;
`
export const TypographyStyle = styled(Typography)`
  margin-bottom: 24px;
`
export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 600px;
  }
`
export const RowGrid = styled(Grid)`
  margin-bottom: 24px;
`
