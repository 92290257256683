/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import UnavailablePage from '../../Unavailable/Unavailable'
import TransactionsHeader from './components/TransactionsHeader'
import TransactionsLoader from './components/TransactionsLoader'
import withTransactionModals, { InjectedProps } from './hocs/withTransactionModals'
import useTransactions from './hooks/useTransactions'
import TransactionsTable from './components/TransactionsTable'
import { initialValues, onSelectAll, TRANSACTIONS_STATE } from './utils'
import SearchForm from './components/SearchForm'
import { TransactionTabs } from './components/TransactionTabs'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import ContentContainer from 'pages/Layout/ContentContainer'

interface Props {
  isSearchPage?: boolean
}

const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`

function TransactionsPage({ setModalItem, setCurrentModal, isSearchPage = false }: Props & InjectedProps) {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const { subscriptionKey = '' } = useParams()
  const {
    account,
    accounts,
    currency,
    filters,
    isLoadingMore,
    isLoadMoreResult,
    loadMore,
    onTransferClick,
    setFilter,
    showLoadMore,
    state,
    transactions,
    onSearch,
    showSearchResults,
    onReset,
    searchQuery,
  } = useTransactions(subscriptionKey, isSearchPage)
  const { t } = useTranslation()

  let resultsFoundText: ReturnType<typeof t> | number
  if (transactions) {
    resultsFoundText = showLoadMore ? t(`More than {{length}}`, { length: transactions.length }) : transactions.length
  } else {
    resultsFoundText = 0
  }

  useEffect(() => {
    if (state === TRANSACTIONS_STATE.SUCCESS && showSearchResults && transactions && !isLoadMoreResult) {
      setTimeout(() => {
        const yOffset = -75
        const element = searchInputRef?.current
        if (element) {
          const y = element?.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      }, UI_DELAY_TO_SHOW_LOADING)
    }
  }, [state, showSearchResults, transactions, isLoadMoreResult])

  const value = isSearchPage ? 'search' : 'listing'
  return (
    <ContentContainer>
      <TabContext value={value}>
        <TransactionsHeader suspenseAccount={account} />
        <TransactionTabs searchPage={isSearchPage} />
        <StyledTabPanel value="listing" aria-live="polite">
          <Paper elevation={1}>
            {state === TRANSACTIONS_STATE.ERROR && <UnavailablePage from={window.location.pathname} hideButton />}
            {state === TRANSACTIONS_STATE.LOADING && <TransactionsLoader />}
            {state === TRANSACTIONS_STATE.SUCCESS && (
              <TransactionsTable
                transactions={transactions || []}
                accounts={accounts}
                subscriptionKey={subscriptionKey}
                currency={currency}
                setModalItem={setModalItem}
                setCurrentModal={setCurrentModal}
                initialValues={initialValues(subscriptionKey)}
                onTransferClick={onTransferClick}
                onSubmit={onTransferClick}
                filters={filters}
                setFilter={setFilter}
                isSearchPage={isSearchPage}
                isLoadingMore={isLoadingMore}
                loadMore={loadMore}
                showLoadMore={showLoadMore}
                onSelectAll={onSelectAll}
              />
            )}
          </Paper>
        </StyledTabPanel>
        <StyledTabPanel value="search" aria-live="polite">
          <Paper elevation={1} id="search-form">
            <SearchForm
              onSubmit={onSearch}
              initialValues={{
                cr_dr: 'CREDITANDDEBIT',
                transactionStatus: '',
                f_amt: '',
                t_amt: '',
                ...searchQuery,
              }}
              currency={currency}
              onReset={onReset}
            />
          </Paper>
          <div id="search-results" ref={searchInputRef} />
          {state === TRANSACTIONS_STATE.SUCCESS && showSearchResults && transactions && (
            <Box my={3}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h3">{`${resultsFoundText} ${t('results found')}`}</Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          {showSearchResults && (
            <Paper elevation={1}>
              {state === TRANSACTIONS_STATE.ERROR && <UnavailablePage from={window.location.pathname} hideButton />}
              {state === TRANSACTIONS_STATE.LOADING && <TransactionsLoader />}
              {state === TRANSACTIONS_STATE.SUCCESS && (
                <TransactionsTable
                  transactions={transactions || []}
                  accounts={accounts}
                  subscriptionKey={subscriptionKey}
                  currency={currency}
                  setModalItem={setModalItem}
                  setCurrentModal={setCurrentModal}
                  initialValues={initialValues(subscriptionKey)}
                  onTransferClick={onTransferClick}
                  onSubmit={onTransferClick}
                  filters={filters}
                  setFilter={setFilter}
                  isSearchPage={isSearchPage}
                  isLoadingMore={isLoadingMore}
                  loadMore={loadMore}
                  showLoadMore={showLoadMore}
                  onSelectAll={onSelectAll}
                />
              )}
            </Paper>
          )}
        </StyledTabPanel>
      </TabContext>
    </ContentContainer>
  )
}

export default withTransactionModals(TransactionsPage)
