import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import ProductsSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'
import { ProductSyndicateResponse, ProductSyndicationError } from 'store/products/typings/productSyndication'

export const useProductSyndication = (
  productKey: string,
  version: number
): {
  productSyndication: ProductSyndicateResponse | null
  productSyndicationError?: ProductSyndicationError
  isLoading: boolean
  getProductSyndication: () => void
} => {
  const dispatch = useDispatch()
  const productSyndication = useSelector(ProductsSelectors.selectProductSyndication)
  const productSyndicationError = useSelector(ProductsSelectors.selectProductSyndicationError)
  const isLoading = useSelector(ProductsSelectors.selectProductSyndicationLoading)

  const getProductSyndication = () => {
    dispatch(ProductsActions.getProductSyndication({ productKey, version }))
  }

  useEffect(() => {
    getProductSyndication()
  }, [productKey, dispatch])
  return { productSyndication, productSyndicationError, isLoading, getProductSyndication }
}
