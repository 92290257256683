import React, { useState, useEffect, useContext } from 'react'
import * as Yup from 'yup'
import { cloneDeep, kebabCase } from 'lodash'
import { TableContainer } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useNavigate, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { INITIAL_FILTERS } from '../utils'
import { getTransactionStatus, statusFilterOptions } from './TransactionsTableHeadRows'
import SuspenseAccountsActions from 'store/accounts/actions'
import reasonCodeMappings from 'utils/reasonCodeMappings.json'
import { H6 } from 'dls/atoms/Typography'
import { getReasonDescription, getReason } from 'utils/accounts.utils'
import { toBankTz } from 'utils/date.utils'
import { Flag, ContextualHelp } from 'components'
import { TransactionStatus, SuspenseTransactionItem } from 'store/accounts/types'
import { getCurrencyInfo } from 'api/utils'
import { CurrencyItem } from 'store/utils/types'
import { CopyComponent } from 'dls/molecules/Copy'
import { DefinationList, DefinationTitle, DefinationData } from 'dls/shared/styled'
import { DesignIconButton } from 'dls/molecules/IconButtons/IconButtons'
import { Button } from 'dls/atoms/Button'
import Input from 'dls/molecules/Input'
import SuspenseAccountsSelectors from 'store/accounts/selectors'
import { checkAlphanumericWithoutSpace } from 'utils/common.util'
import { TENANT_URL_PREFIX } from 'utils/constants'
import { TenantStateContext } from 'context/tenantSelectorContext'

const StyledTableContainer = styled(TableContainer)`
  margin: 40px 0 25px 38px;
`
const TooltipStyle = styled.div`
  margin-left: 16px;
  display: inline-block;
  vertical-align: middle;
`

export const ExpandedContent = ({ row }: { row: SuspenseTransactionItem }) => {
  const { t } = useTranslation()
  const tenantConfig = useContext(TenantStateContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedSuspenseTransactions = useSelector(SuspenseAccountsSelectors.selectedSuspenseTransactions)
  const bankTimestamp = toBankTz(row.postedTimeStamp)
  const postedTimestampToTime = `${bankTimestamp.time} (${bankTimestamp.tz})`
  const [editParentTransaction, setEditParentTransaction] = useState(false)
  const [parentTransactionIdEdited, setParentTransactionIdEdited] = useState('')
  const [valid, setValid] = useState(true)
  const [validMessage, setValidMessage] = useState('')
  const match = useMatch(
    `/${TENANT_URL_PREFIX}/${kebabCase(tenantConfig?.tenantName)}/accounts/:subscriptionKey/:isSearchPage`
  )

  const resetParentTransactionValue = () => {
    setParentTransactionIdEdited('')
    setValid(true)
    setValidMessage('')
    setEditParentTransaction(false)
  }

  const toogleEditParentTransaction = () => {
    if (editParentTransaction) {
      resetParentTransactionValue()
    }
    setEditParentTransaction(!editParentTransaction)
  }

  const validateValue = async (value: any) => {
    await new Promise((resolve) => setTimeout(resolve, 400))
    try {
      Yup.string()
        .required(t('Field is required'))
        .min(16, t('Must be at least 16 characters long'))
        .max(51, t('This must be at most 51 characters'))
        .validateSync(value)
      setValid(true)
      setValidMessage('')
    } catch (error: any) {
      setValid(false)
      setValidMessage(error?.message)
    }
  }

  const setParentTransactionValue = (event: any) => {
    if (event.target.value.match(checkAlphanumericWithoutSpace) || event.target.value === '') {
      setParentTransactionIdEdited(event.target.value)
      validateValue(event.target.value)
    }
  }

  const onSubmit = () => {
    const suspenceTransactions = cloneDeep(selectedSuspenseTransactions)
    const newTransactions = suspenceTransactions?.transactions.map(function (item: any) {
      return item.transactionId === row.transactionId
        ? { ...item, parentTransactionId: parentTransactionIdEdited }
        : item
    })
    const selectedSuspenseTransactionsNew = cloneDeep(selectedSuspenseTransactions)

    dispatch(
      SuspenseAccountsActions.confirmReconcileTransaction({
        SuspenseTransactionItem: { ...row, parentTransactionId: parentTransactionIdEdited },
        selectedSuspenseTransactions: { ...selectedSuspenseTransactionsNew, transactions: newTransactions },
      })
    )
  }

  useEffect(() => {
    const suspenceTransactions = cloneDeep(selectedSuspenseTransactions)

    if (
      suspenceTransactions?.transactions.some(
        (transaction) =>
          transaction.parentTransactionId === parentTransactionIdEdited &&
          transaction.transactionId === row.transactionId
      )
    ) {
      if (match?.params?.isSearchPage === 'search') {
        // It should always land of transaction page only even if customer do action on search page
        navigate(`/accounts/${row.subscriptionKey}`)
      } else {
        const statusFilterOptionsLocal = statusFilterOptions(t)

        const transactionStatus = statusFilterOptionsLocal[INITIAL_FILTERS.status].status?.join(',')

        dispatch(
          SuspenseAccountsActions.getSuspenseTransactions({
            subscriptionKey: row.subscriptionKey,
            transactionStatus: transactionStatus || undefined,
          })
        )
      }
    }
  }, [selectedSuspenseTransactions])

  return (
    <StyledTableContainer>
      <H6 id="transaction-detail">{t('Transaction details')}</H6>
      <DefinationList aria-labelledby="transaction-detail" width="100%" pb={16} pr={10} pt={16}>
        <DefinationTitle width="200px" pr={24} pb={16} pt={6}>
          {t('Time posted')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210} pt={2}>
          {postedTimestampToTime}
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={16} pt={6}>
          {t('Currency')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210} pt={2}>
          <Flag code={row.amount.currency.substr(0, 2)} size={20} />
          {row.amount.currency}
          {' - '}
          {(getCurrencyInfo(row.amount.currency || 'GBP') || ({} as CurrencyItem)).namePlural}
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={16} pt={6}>
          {t('Status')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210} pt={2}>
          {getTransactionStatus(row.transactionStatus, t) || t('N/A')}
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={16} pt={6}>
          {t('Reason')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210} pt={2}>
          {getReason(row?.reasonCode || '', reasonCodeMappings, t)}
          <TooltipStyle>
            <ContextualHelp title={getReasonDescription(row?.reasonCode || '', reasonCodeMappings, t)} />
          </TooltipStyle>
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={16} pt={6}>
          {t('Transaction code')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210} pt={2}>
          {row.transactionCode}
          <TooltipStyle>
            <ContextualHelp title={t('The transaction code represents a type of payment or transaction')} />
          </TooltipStyle>
        </DefinationData>
      </DefinationList>
      <H6 id="customer-identifiers">{t('Customer identifiers')}</H6>
      <DefinationList aria-labelledby="customer-identifiers" width="100%" pb={16} pr={10} pt={16}>
        <DefinationTitle width="200px" pr={24} pb={16} pt={row.customerPartyKey ? 10 : 2}>
          {t('Party key')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210}>
          {row.customerPartyKey || t('N/A')}
          <TooltipStyle>
            <ContextualHelp title={t('Party key is the unique customer identifier')} />
          </TooltipStyle>
          {row.customerPartyKey && (
            <CopyComponent
              onClickTitle="Copied"
              payload={row.customerPartyKey}
              tabIndex="0"
              title="Copy"
              style={{ top: -3 }}
            />
          )}
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={16} pt={row.customerSubscriptionKey ? 10 : 2}>
          {t('Subscription key')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210}>
          {row.customerSubscriptionKey || t('N/A')}
          <TooltipStyle>
            <ContextualHelp
              title={t(
                'Subscription key is the unique identifier which represents the association between the party and product'
              )}
            />
          </TooltipStyle>
          {row.customerSubscriptionKey && (
            <CopyComponent
              onClickTitle="Copied"
              payload={row.customerSubscriptionKey}
              tabIndex="0"
              title="Copy"
              style={{ top: -3 }}
            />
          )}
        </DefinationData>
      </DefinationList>

      <H6 id="transaction-identifiers">{t('Transaction identifiers')}</H6>
      <DefinationList aria-labelledby="transaction-identifiers" width="100%" pb={0} pr={10} pt={8}>
        <DefinationTitle width="200px" pr={24} pb={4} pt={row.transactionId ? 10 : 2}>
          {t('Suspense transaction ID')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210}>
          {row.transactionId}
          <TooltipStyle>
            <ContextualHelp title={t('Shared between all transactions in a group')} />
          </TooltipStyle>
          {row.transactionId && (
            <CopyComponent
              onClickTitle="Copied"
              payload={row.transactionId}
              tabIndex="0"
              title="Copy"
              style={{ top: -3 }}
            />
          )}
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={4} pt={row.customerTransactionId ? 10 : 2}>
          {t('Customer transaction ID')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210}>
          {row.customerTransactionId || t('N/A')}
          <TooltipStyle>
            <ContextualHelp
              title={t(
                'The suspense transaction ID is unique and contains encoding for the transaction, date and version'
              )}
            />
          </TooltipStyle>
          {row.customerTransactionId && (
            <CopyComponent
              onClickTitle="Copied"
              payload={row.customerTransactionId}
              tabIndex="0"
              title="Copy"
              style={{ top: -3 }}
            />
          )}
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={4} pt={row.correlationId ? 10 : 2}>
          {t('Correlation transaction ID')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210}>
          {row.correlationId || t('N/A')}
          <TooltipStyle>
            <ContextualHelp
              title={t('Customer transaction ID maps to the customer transaction which triggered the entry')}
            />
          </TooltipStyle>
          {row.correlationId && (
            <CopyComponent
              onClickTitle="Copied"
              payload={row.correlationId}
              tabIndex="0"
              title="Copy"
              style={{ top: -3 }}
            />
          )}
        </DefinationData>
        <DefinationTitle width="200px" pr={24} pb={0} pt={10}>
          {t('Parent transaction ID')}
        </DefinationTitle>
        <DefinationData lastItem marginInlineStart={210} pt={0}>
          {row.parentTransactionId || (!editParentTransaction && (parentTransactionIdEdited || t('N/A')))}
          {editParentTransaction && (
            <Input
              id="parentTransactionField"
              currencyCode="GBP"
              name="firstname"
              placeholder="Enter parent transaction ID"
              required
              error={!valid}
              errorText={validMessage}
              type="text"
              style={{ width: 490 }}
              value={parentTransactionIdEdited}
              onChange={setParentTransactionValue}
              ariaProps={{
                'aria-label': 'Parent ID',
              }}
            />
          )}
          <TooltipStyle>
            <ContextualHelp title={t('Matches the Transaction ID of the transaction used to create the entry')} />
          </TooltipStyle>
          {(row.parentTransactionId || parentTransactionIdEdited) && !editParentTransaction && (
            <CopyComponent
              onClickTitle="Copied"
              payload={row.parentTransactionId || parentTransactionIdEdited}
              tabIndex="0"
              title="Copy"
              style={{ top: -3 }}
            />
          )}
          {!(row.parentTransactionId || parentTransactionIdEdited) &&
            !editParentTransaction &&
            row.transactionStatus === TransactionStatus.PENDING && (
              <DesignIconButton tabIndex="0" title="Edit" style={{ top: -3 }} onClick={toogleEditParentTransaction} />
            )}
          {editParentTransaction && (
            <>
              <Button
                component="button"
                variant="contained"
                style={{ marginLeft: 16 }}
                disabled={!parentTransactionIdEdited || !!(parentTransactionIdEdited && !valid && !!validMessage)}
                onClick={onSubmit}
                ariaProps={{
                  'aria-hasPopup': 'dialog',
                  'aria-controls': 'confirmation-dialog',
                }}
              >
                Submit
              </Button>
              <Button
                component="button"
                variant="text"
                style={{ marginLeft: 16 }}
                onClick={resetParentTransactionValue}
              >
                Cancel
              </Button>
            </>
          )}
        </DefinationData>
      </DefinationList>
    </StyledTableContainer>
  )
}
