import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Fade, Grid, Menu, MenuItem } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import styled from 'styled-components/macro'
import { AddElement } from 'components'
import { MultipleChoiceElement } from 'store/products/types'

interface NestedElementProps {
  elements: MultipleChoiceElement[]
  name: string
  handleClose: () => void
  parentAnchorEl?: HTMLElement | null
  forwardedRef?: React.Ref<unknown>
  triggerAction?: (item: any) => void
}

const ArrowStyle = styled(KeyboardArrowRight)`
  color: ${(props: any) => props.theme.palette.text.secondary};
`
const AddNestedElement = (props: NestedElementProps) => {
  const { t } = useTranslation()
  const { name, elements, handleClose, parentAnchorEl, triggerAction } = props

  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(null)

  function handleChildMenuItemClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    setNestedAnchorEl(event.currentTarget)
  }

  function closeNestedMenu() {
    setNestedAnchorEl(null)
  }

  function renderSubmenu() {
    return (
      <Menu
        id={`nested-menu-${name}`}
        anchorEl={nestedAnchorEl}
        keepMounted
        open={Boolean(parentAnchorEl) && Boolean(nestedAnchorEl)}
        onClose={closeNestedMenu}
        css={`
          .MuiMenu-paper {
            margin-left: 8px;
          }
        `}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        TransitionComponent={Fade}
      >
        {/* <div onMouseLeave={() => setNestedAnchorEl(null)}>
          {// todo: this is diverted from the parent, {} vs []
          elements.map(element => (
            <AddElement
              key={element.name}
              name={element.name || ''}
              label={element.label || ''}
              defaultValue={element.defaultValue}
              multiple={element.multiple}
              onAfterClick={handleClose}
            />
          ))}
        </div> */}
        <div onMouseLeave={() => setNestedAnchorEl(null)}>
          {
            // todo: this is diverted from the parent, {} vs []
            elements.map((element, index) => (
              <AddElement
                ref={props.forwardedRef}
                key={index}
                name={element.name || ''}
                itemName={element.itemName || ''}
                label={element.label || ''}
                defaultValue={element.defaultValue}
                multiple={element.multiple}
                onAfterClick={handleClose}
                menuItemProps={element.menuItemProps}
                readOnly={element.readOnly}
                disabled={element.disabled}
                triggerAction={triggerAction}
                identifier={element.identifier}
                identifierOriginal={element.identifierOriginal}
              />
            ))
          }
        </div>
      </Menu>
    )
  }

  return (
    <>
      <MenuItem onClick={handleChildMenuItemClick}>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs={11}>
            {t(name)}
          </Grid>
          <Grid item xs={1}>
            <ArrowStyle>
              <KeyboardArrowRight />
            </ArrowStyle>
          </Grid>
        </Grid>
      </MenuItem>
      {renderSubmenu()}
    </>
  )
}

export default React.forwardRef((props: NestedElementProps, ref?: React.Ref<any>) => (
  <AddNestedElement {...props} forwardedRef={ref} />
))
