import { Control, UseFormWatch, FieldValues, UseFormSetValue } from 'react-hook-form'
import styled from 'styled-components/macro'
import { MenuItem } from 'dls/atoms/MenuItem'

export type FormInputProps = {
  control: Control
  watch: UseFormWatch<FieldValues>
  onChange: (onChange: () => void) => void
  setValue?: UseFormSetValue<FieldValues>
}

export const GroupHeadingMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 14px;
  padding: 8px 16px;
  pointer-events: none;
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  padding: 8px 24px;
`
