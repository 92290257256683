import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { TasksState, TasksResultType, AssignTasksResultType } from './types'

const initialState = {
  isLoading: false,
  isAssigning: false,
  updating: false,
  error: undefined,
  taksForProduct: [],
  taksForAssignee: [],
  selectedTask: undefined,
  assignee: undefined,
  taskDefinitions: [],
  assignTasksResult: null,
  taskActionResult: null,
}

const reducer = createReducer<TasksState>(initialState, {
  [actions.reject.type]: (state) => ({ ...state, taskActionResult: null, updating: true }),
  [actions.rejectSuccess.type]: (state) => ({
    ...state,
    updating: false,
    taskActionResult: TasksResultType.REJECT_SUCCESS,
  }),
  [actions.rejectFail.type]: (state, action) => ({
    ...state,
    updating: false,
    taskActionResult: TasksResultType.FAIL,
    error: action.payload,
  }),
  [actions.assignTasks.type]: (state) => ({ ...state, isAssigning: true, assignTasksResult: null }),
  [actions.assignTasksSuccesss.type]: (state) => ({
    ...state,
    isAssigning: false,
    assignTasksResult: AssignTasksResultType.SUCCESS,
  }),
  [actions.assignTasksFails.type]: (state, action) => ({
    ...state,
    isAssigning: false,
    error: action.payload,
    assignTasksResult: AssignTasksResultType.FAIL,
  }),
  [actions.assignTasksResultClear.type]: (state) => ({
    ...state,
    assignTasksResult: null,
  }),

  [actions.approve.type]: (state) => ({ ...state, taskActionResult: null, updating: true }),
  [actions.approveSuccess.type]: (state) => ({
    ...state,
    updating: false,
    taskActionResult: TasksResultType.APPROVE_SUCCESS,
  }),
  [actions.approveFail.type]: (state, action) => ({
    ...state,
    updating: false,
    taskActionResult: TasksResultType.FAIL,
    error: action.payload,
  }),
  [actions.taskActionResultClear.type]: (state) => ({
    ...state,
    taskActionResult: null,
  }),

  [actions.tasksExecutionsForAssignee.type]: (state) => ({ ...state, isLoading: true }),
  [actions.tasksExecutionsForAssigneeSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    taksForAssignee: action.payload,
  }),
  [actions.tasksExecutionsForAssigneeFail.type]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }),

  [actions.getTasksDefinition.type]: (state) => ({ ...state, isLoading: true }),
  [actions.getTasksDefinitionSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    taskDefinitions: action.payload,
  }),
  [actions.getTasksDefinitionFail.type]: (state, action) => ({ ...state, isLoading: false, error: action.payload }),

  [actions.createTasksDefinition.type]: (state) => ({ ...state, isLoading: true }),
  [actions.createTasksDefinitionSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    selectedTask: action.payload,
  }),
  [actions.createTasksDefinitionFail.type]: (state, action) => ({ ...state, isLoading: false, error: action.payload }),

  [actions.updateTasksDefinition.type]: (state) => ({ ...state, isLoading: true }),
  [actions.updateTasksDefinitionSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    selectedTask: action.payload,
  }),
  [actions.updateTasksDefinitionFail.type]: (state, action) => ({ ...state, isLoading: false, error: action.payload }),

  [actions.tasksExecutionsForProduct.type]: (state) => ({ ...state, isLoading: true }),
  [actions.tasksExecutionsForProductSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    taksForProduct: action.payload,
  }),
  [actions.tasksExecutionsForProductFail.type]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }),

  [actions.getAssigneeForProductTaskSuccess.type]: (state, action) => ({
    ...state,
    assignee: action.payload,
  }),
  [actions.getAssigneeForProductTaskFail.type]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [actions.clearTasksForProduct.type]: (state) => ({ ...state, assignee: undefined, taksForProduct: [] }),
})

export default reducer
