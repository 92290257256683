import { call, put, delay } from 'redux-saga/effects'
import i18next from 'i18next'
import { ExtendedProduct, DebitInterestCommonConfigFormValues, LendingRateFormValues } from '../types'
import { showPendingActionModalSaga } from '../../modal/sagas'
import { DebitInterestApi } from 'api'
import ModalActions from 'store/modal/actions'
import ProductsActions from 'store/products/actions'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import {
  toDebitInterestCommonConfigPayload,
  toSetupDebitInterestPayload,
  toLendingRatePayload,
} from 'pages/features/DebitInterest/utils/transformers'
import { navigate } from 'store/router/actions'

export function* setupDebitInterestSaga(action: ReturnType<typeof ProductsActions.setupDebitInterest>) {
  try {
    const {
      payload: { productKey, version, rateType, ...formData },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    yield call(
      DebitInterestApi.createDebitInterest,
      productKey,
      version,
      toSetupDebitInterestPayload(formData, rateType)
    )
    yield put(navigate(`/products/${productKey}/features/debit-interest` as any))
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showFeatureSuccessModal({
        featureName: 'Debit interest setup',
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* deleteDebitInterestFeatureSaga(action: ReturnType<typeof ProductsActions.deleteDebitInterestFeature>) {
  try {
    const {
      payload: { productKey, version },
    } = action

    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: `You're deleting debit interest feature`,
      message: "Are you sure? This can't be undone.",
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())
      yield call(DebitInterestApi.deleteDebitInterestFeature, productKey, version)

      yield put(ProductsActions.getProductDetails({ productKey }))

      yield put(navigate(`/products/${productKey}` as any))

      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: `Debit interest successfully deleted`,
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* updateDebitInterestSaga(action: ReturnType<typeof ProductsActions.updateDebitInterest>) {
  const t = i18next.t.bind(i18next)

  try {
    const {
      payload: { productKey, version, entity, ...formData },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    yield call(
      DebitInterestApi.updateDebitInterest,
      entity,
      productKey,
      version,
      entity === 'commonConfig'
        ? toDebitInterestCommonConfigPayload(formData as DebitInterestCommonConfigFormValues)
        : toLendingRatePayload(formData as LendingRateFormValues)
    )
    yield put(navigate(`/products/${productKey}/features/debit-interest` as any))

    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(ModalActions.closeModal())

    yield put(
      ModalActions.showFeatureSuccessModal({
        featureName: t(`DEBIT_INTEREST.${entity}`),
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* removeDebitInterestRateSaga(action: ReturnType<typeof ProductsActions.removeDebitInterestRate>) {
  try {
    const t = i18next.t.bind(i18next)
    const {
      payload: { productKey, version, rateType },
    } = action

    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: t("You're deleting {{rate}}", { rate: t(`DEBIT_INTEREST.${rateType}`) }),
      message: "Are you sure? This can't be undone.",
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())
      yield call(DebitInterestApi.removeDebitInterestRate, rateType, productKey, version)

      yield put(ProductsActions.getProductDetails({ productKey }))

      yield put(navigate(`/products/${productKey}/features/debit-interest`))

      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: t('{{rate}} successfully deleted', { rate: t(`DEBIT_INTEREST.${rateType}`) }),
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}
