import React from 'react'
import { useTranslation } from 'react-i18next'
import HelpPanel from 'dls/organisms/HelpPanel/HelpPanel'
import { H4, Body1 } from 'dls/atoms/Typography'
import { NoActivityOrCommentImage } from 'assets'

const NoActivityHelpPanel = ({ id = '' }: { id?: string }) => {
  const { t } = useTranslation()
  return (
    <HelpPanel
      id={id}
      description={
        <>
          <H4>{t('Nothing to display')}</H4>
          <Body1>{t('Relax and enjoy the moment!')}</Body1>
        </>
      }
      imageProps={{
        alt: t('landing page'),
        src: NoActivityOrCommentImage,
      }}
      title="ACTIVITY"
    />
  )
}

export default NoActivityHelpPanel
