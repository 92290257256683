import React, { useState, useEffect } from 'react'
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { ListItem } from './ListItem'
import { DataPickerRow } from 'dls/shared/types'
import { StyledResetOL } from 'dls/shared/styled'

interface Props {
  dataPickerRowsData: DataPickerRow[]
  formChanged: () => void
  changeParentState?: ({ data, index }: { data: DataPickerRow[]; index: number }) => void
  depth: number
  path: string
}

const DataList = ({ dataPickerRowsData, changeParentState, formChanged, depth, path = '' }: Props) => {
  const [dataPickerRows, setDataPickerRows] = useState(dataPickerRowsData)
  const [checkout, setCheckout] = useState(false)

  const getParentCorrectStatus = (data: DataPickerRow) => {
    if (data.children) {
      data.children.forEach((node: DataPickerRow, index: number) => {
        if (index === 0) data.checked = node.checked
        else data.checked = data.checked && node.checked
      })
    }

    return !!data.checked
  }

  const onChangeParentState = ({ data, index }: { data: DataPickerRow[]; index: number }) => {
    const existingData = cloneDeep(dataPickerRows)
    existingData[index].children = cloneDeep(data)

    if (existingData[index].children) {
      const status = getParentCorrectStatus(cloneDeep(existingData[index]))
      existingData[index].checked = status

      if (changeParentState && path && depth !== 0) {
        changeParentState({ data: existingData, index: Number(path.split('.')[depth - 1]) })
      }
    }

    if ((!existingData[index].children || !(changeParentState && path)) && !isEqual(existingData, dataPickerRows))
      setDataPickerRows(existingData)
  }

  const changeAllChildStatus = (childNodes: DataPickerRow[], state?: boolean) => {
    if (childNodes) {
      childNodes.forEach((childNode: DataPickerRow) => {
        childNode.checked = state
        if (childNode.children) {
          changeAllChildStatus(childNode.children, state)
        }
      })
    }
  }

  const changeCurrentNodeStatus = ({
    allNodes,
    location,
    currentNode,
  }: {
    allNodes: DataPickerRow[]
    location: string
    currentNode?: DataPickerRow
  }) => {
    const pathArray = location.split('.')
    currentNode = allNodes[Number(pathArray[pathArray.length - 1])]

    if (currentNode) {
      currentNode.checked = !currentNode.checked

      if (currentNode.children) {
        changeAllChildStatus(currentNode.children, currentNode.checked)
      }
    }

    return currentNode
  }

  const handleClick = (location: string) => {
    const pathArray = location.split('.')
    const updatedRowsData = changeCurrentNodeStatus({
      allNodes: cloneDeep(dataPickerRows),
      location,
    })
    const newData = cloneDeep(dataPickerRows)
    newData[Number(pathArray[pathArray.length - 1])] = updatedRowsData

    if (!isEqual(newData, dataPickerRows)) setDataPickerRows(newData)
    setCheckout(true)
  }

  const renderList = React.useMemo(
    () => (
      <StyledResetOL
        {...(depth === 0 && {
          'aria-labelledby': 'datapickerHeading',
          role: 'region',
          'aria-live': 'polite',
          id: 'transaction-codes',
        })}
        key={`${dataPickerRows.length}-${depth || '0'}-ol-${path}`}
      >
        {dataPickerRows.map((node: DataPickerRow, index: number) => (
          <li key={`${node.code}-${depth || '0'}-li-${index}`}>
            <ListItem
              isLeaf={!node.children}
              dataPickerRow={node}
              depth={depth}
              index={index}
              handleClick={handleClick}
              path={path}
            />
            {node.children && (
              <DataList
                dataPickerRowsData={node.children}
                depth={depth + 1}
                changeParentState={onChangeParentState}
                path={path ? `${path}.${index}` : `${index}`}
                formChanged={formChanged}
                key={`${node.children.length}-${depth || '0'}-data-list-li-${path}`}
              />
            )}
          </li>
        ))}
      </StyledResetOL>
    ),
    [dataPickerRows]
  )

  useEffect(() => {
    setDataPickerRows(dataPickerRowsData)
  }, [dataPickerRowsData])

  useEffect(() => {
    formChanged()
  }, [dataPickerRows])

  useEffect(() => {
    if (checkout) {
      if (changeParentState && path) {
        changeParentState({ data: [...dataPickerRows], index: Number(path.split('.')[depth - 1]) })
      }

      setCheckout(false)
    }
  }, [checkout])

  return isEmpty(dataPickerRows) ? (
    <StyledResetOL
      role="region"
      aria-live="polite"
      key={`${dataPickerRows.length}-${depth || '0'}-not-rendered-li-${path}`}
    >
      <li>No Data</li>
    </StyledResetOL>
  ) : (
    <div key={`${dataPickerRows.length}-${depth || '0'}-div-container-${path}`}>{renderList}</div>
  )
}

export default DataList
