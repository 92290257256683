import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { SuspenseAccountsState, SuspenseTransactionsResponse, ConfirmTransactionResultType } from './types'

const initialState = {
  loading: false,
  transactionsLoading: false,
  transactionsAppending: false,
  selectedSuspenseAccountLoading: false,
  isUpdating: false,
  error: undefined,
  transactionError: undefined,
  suspenseAccounts: undefined,
  suspenseAccount: undefined,
  selectedSuspenseTransactions: undefined,
  selectedSuspenseTransactionsForStat: undefined,
  confirmTransactionLoading: false,
  confirmTransactionResult: undefined,
}

const reducer = createReducer<SuspenseAccountsState>(initialState, {
  [actions.fetchSuspenseAccounts.type]: (state) => ({ ...state, loading: true }),
  [actions.fetchSuspenseAccountsSuccess.type]: (state, action) => ({
    ...state,
    suspenseAccounts: action.payload,
    loading: false,
  }),
  [actions.fetchSuspenseAccountsFail.type]: (state, action) => ({ ...state, loading: false, error: action.payload }),

  [actions.getSuspenseTransactions.type]: (state) => ({
    ...state,
    transactionsLoading: true,
    transactionError: undefined,
  }),
  [actions.getSuspenseTransactionsLoadMore.type]: (state) => ({
    ...state,
    transactionsAppending: true,
    transactionError: undefined,
  }),
  [actions.getSuspenseTransactionsClear.type]: (state) => ({
    ...state,
    selectedSuspenseTransactions: undefined,
    transactionError: undefined,
    transactionsAppending: false,
  }),
  [actions.getSuspenseTransactionsSuccess.type]: (state, action) => ({
    ...state,
    selectedSuspenseTransactions: action.payload,
    transactionsLoading: false,
    transactionError: undefined,
    transactionsAppending: false,
  }),
  [actions.getSuspenseTransactionsSuccessLoadMore.type]: (state, action) => {
    const selectedSuspenseTransactions = {
      transactions: [
        ...(state.selectedSuspenseTransactions || ({} as SuspenseTransactionsResponse)).transactions,
        ...action.payload.transactions,
      ],
      links: action.payload.links,
    } as SuspenseTransactionsResponse
    return {
      ...state,
      selectedSuspenseTransactions,
      transactionsLoading: false,
      transactionsAppending: false,
      transactionError: undefined,
    }
  },
  [actions.getSuspenseTransactionsFail.type]: (state, action) => ({
    ...state,
    transactionsLoading: false,
    transactionError: action.payload,
    transactionsAppending: false,
  }),

  [actions.getSuspenseAccount.type]: (state) => ({
    ...state,
    suspenseAccount: undefined,
    selectedSuspenseAccountLoading: true,
    error: undefined,
  }),
  [actions.getSuspenseAccountSuccess.type]: (state, action) => ({
    ...state,
    suspenseAccount: action.payload,
    selectedSuspenseAccountLoading: false,
    error: undefined,
  }),
  [actions.getSuspenseAccountFail.type]: (state, action) => ({
    ...state,
    selectedSuspenseAccountLoading: false,
    error: action.payload,
  }),

  [actions.getSuspenseTransactionsForStat.type]: (state, action) => ({
    ...state,
    selectedSuspenseTransactionsForStat: {
      ...state.selectedSuspenseTransactionsForStat,
      [action.payload]: undefined,
    },
    transactionsLoading: true,
  }),
  [actions.getSuspenseTransactionsForStatSuccess.type]: (state, action) => ({
    ...state,
    selectedSuspenseTransactionsForStat: {
      ...state.selectedSuspenseTransactionsForStat,
      [action.payload.key]: action.payload.data,
    },
    transactionsLoading: false,
  }),
  [actions.getSuspenseTransactionsForStatFail.type]: (state, action) => ({
    ...state,
    selectedSuspenseTransactionsForStat: {
      ...state.selectedSuspenseTransactionsForStat,
      [action.payload.key]: action.payload.data,
    },
    transactionsLoading: false,
  }),
  [actions.confirmReconcileTransactionSuccess.type]: (state, action) => ({
    ...state,
    selectedSuspenseTransactions: action.payload.selectedSuspenseTransactions,
  }),
  [actions.confirmTransaction.type]: (state) => ({ ...state, confirmTransactionLoading: true, error: undefined }),
  [actions.confirmTransactionSuccess.type]: (state) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.SUCCESS,
    error: undefined,
  }),
  [actions.confirmTransactionFail.type]: (state, action) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.FAIL,
    error: action.payload,
  }),
  [actions.confirmTransactionResultClear.type]: (state) => ({
    ...state,
    confirmTransactionResult: undefined,
    error: undefined,
  }),
  [actions.confirmWriteOffAndWriteBackTransaction.type]: (state) => ({
    ...state,
    confirmTransactionLoading: true,
    error: undefined,
  }),
  [actions.confirmWriteOffAndWriteBackTransactionSuccess.type]: (state) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.SUCCESS,
    error: undefined,
  }),
  [actions.confirmWriteOffAndWriteBackTransactionFail.type]: (state, action) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.FAIL,
    error: action.payload,
  }),

  [actions.confirmNostroWashTransaction.type]: (state) => ({
    ...state,
    confirmTransactionLoading: true,
    error: undefined,
  }),
  [actions.confirmNostroWashTransactionSuccess.type]: (state) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.SUCCESS,
    error: undefined,
  }),
  [actions.confirmNostroWashTransactionFail.type]: (state, action) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.FAIL,
    error: action.payload,
  }),
  [actions.reverseWriteOffAndWriteBackTransaction.type]: (state) => ({
    ...state,
    confirmTransactionLoading: true,
    error: undefined,
  }),
  [actions.reverseWriteOffAndWriteBackTransactionSuccess.type]: (state) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.SUCCESS,
    error: undefined,
  }),

  [actions.reverseWriteOffAndWriteBackTransactionFail.type]: (state, action) => ({
    ...state,
    confirmTransactionLoading: false,
    confirmTransactionResult: ConfirmTransactionResultType.FAIL,
    error: action.payload,
  }),
})

export default reducer
