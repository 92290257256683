import React from 'react'
import { DialogContent as MUIDialogContent, DialogContentProps as MUIDialogContentProps } from '@material-ui/core'

export interface DialogContentProps extends MUIDialogContentProps {}

export const DialogContent = (props: DialogContentProps) => (
  <MUIDialogContent data-dls="atom-dialog-content" {...props} />
)

export default DialogContent
