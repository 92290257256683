import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid } from '@material-ui/core'
import styled from 'styled-components/macro'
import { getIcon } from 'pages/Product/product.utils'
import { LendingRateType } from 'store/products/types'
import Tile from 'components/ProductBuilder/Tile'
import { HelpWheelIcon } from 'assets'
import { H3 } from 'dls'

type Props = {
  rateType?: LendingRateType
  onChange: (value: LendingRateType) => void
}

const WideSeparator = styled(Grid)`
  padding: 12px;
`

const DebitInterestTypeSelector = ({ rateType, onChange }: Props) => {
  const { t } = useTranslation()

  return (
    <Grid item xs={12}>
      <Box mt={2.5}>
        <Divider />
      </Box>
      <Box mt={4}>
        <Grid container item direction="column" spacing={3}>
          <Grid item>
            <H3>{t('Set up your first debit interest type')}</H3>
          </Grid>
          <Grid container item direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <Tile
                title={t('Unauthorised borrowing')}
                icon={getIcon('trending_down')}
                selected={rateType === LendingRateType.rateAboveLimit}
                toggleSelect={() => onChange(LendingRateType.rateAboveLimit)}
                disabled={false}
                showRadioButton
              />
            </Grid>
            <WideSeparator item>{t('or')}</WideSeparator>
            <Grid item>
              <Tile
                title={t('Authorised borrowing within limit')}
                icon={<img src={HelpWheelIcon} alt="help buoy" />}
                selected={rateType === LendingRateType.rateWithinLimit}
                toggleSelect={() => onChange(LendingRateType.rateWithinLimit)}
                disabled={false}
                showRadioButton
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default DebitInterestTypeSelector
