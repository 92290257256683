import { Grid, Typography, Avatar, Tooltip } from '@material-ui/core'
import styled from 'styled-components/macro'
import React from 'react'
import { relativeDate } from 'utils/date.utils'
import { Message } from 'store/activities/types'
import { getInitials } from 'utils/ui.utils'

interface Props {
  message: Message
}

const GridIconNameContainer = styled(Grid)`
  padding-bottom: 0px;
  padding-right: 16px;
  padding-left: 16px;
  /* Safari Specific */
  display: inline-flex;
  -webkit-margin-after: -68px;
`
const TextGridItem = styled(Grid)`
  background-color: ${(props: any) => `${props.theme.palette.action.selected}`};
  opacity: 1;
  border-radius: 0px;
  padding-top: 0px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 0px;
  margin-left: 16px;
  margin-right: 16px;
`
const TypographyName = styled(Typography)`
  font-weight: 600;
  color: ${(props: any) => props.theme.palette.text.primary};
`
const StyledAvatar = styled(Avatar)`
  background-color: ${(props: any) => `${props.theme.palette.divider}`};
  color: ${(props: any) => `${props.theme.palette.text.primary}`};
  width: 48px;
  height: 48px;
  .MuiAvatar-root {
    margin: 4rem;
  }
`

const TypographyText = styled(Typography)`
  color: ${(props: any) => `${props.theme.palette.text.secondary}`};
`
export const MessageList = ({ message }: Props) => (
  <TextGridItem item>
    <Grid container spacing={8} direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid item>
        <TypographyText variant="body1">{message.text}</TypographyText>
      </Grid>
    </Grid>
  </TextGridItem>
)
export const ProfileSection = ({ message }: Props) => {
  const createdDate = relativeDate(message.createdDate)
  return (
    <Grid item>
      <Grid
        id="conversation_top"
        container
        spacing={8}
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid
          item
          style={{
            marginTop: 34,
            marginBottom: 34,
          }}
        >
          <GridIconNameContainer container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <StyledAvatar alt="profile">{message.name ? getInitials(message.name) : ''}</StyledAvatar>
            </Grid>
            <Grid item>
              <Tooltip
                placement="right"
                title={createdDate.dateTime}
                aria-label={createdDate.dateTime}
                disableHoverListener={createdDate.isOver5Days}
              >
                <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                  <Grid item>
                    <TypographyName variant="body1" variantMapping={{ body1: 'div' }}>
                      {message.name}
                    </TypographyName>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" variantMapping={{ caption: 'div' }}>
                      {createdDate.relativeDateTime}
                    </Typography>
                  </Grid>
                </Grid>
              </Tooltip>
            </Grid>
          </GridIconNameContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}
