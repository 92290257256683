import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import useDebitInterest from '../../utils/useDebitInterest'
import DebitInterestRateDefinitions from '../shared/DebitInterestRateDefinitions'
import { Feature } from 'store/products/types'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader, TemplateSubReview, BackButton, H1, Body2, NoActivityHelpPanel } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import DebitInterestInfoButton from 'pages/features/DebitInterest/update/components/DebitInterestInfoButton'
import CommonConfigPaper from 'pages/features/DebitInterest/view/featurePage/CommonConfigPaper'
import ProductsSelectors from 'store/products/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'
import { InfoPanel } from 'dls'

const ViewDebitInterestPage = () => {
  const { productKey = '' } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [product, isLoading] = useSelectedProduct(productKey)
  useUpdateBreadcrumbs({ productKey, context: Feature.DEBIT_INTEREST })
  const { isLending, submitDebitInterestRateRemoval } = useDebitInterest({
    productKey,
    version: product?.version,
    productLine: product?.productLine,
  })
  const { isDebitInterestEnabled: previousVersionHasNoDebitInterest } = useSelector(
    ProductsSelectors.selectProductPermissions
  )

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  if (isLoading || !product?.debitInterest) {
    return <Loader />
  }

  if (!product) {
    return null
  }

  const { debitInterest } = product

  return (
    <ContentContainer data-test-id="loan-interest-view-page">
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={onPressBack} aria-controls="main" />
              </Grid>
              <Grid item>
                <div style={{ gridArea: 'infoArea', textAlign: 'right' }}>
                  <DebitInterestInfoButton />
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <H1 gutterBottom>{t('Debit interest')}</H1>
              </Grid>
            </Grid>
            <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
              {t(
                'Here you can define the rate of interest a customer will pay, how it is calculated and when it is charged'
              )}
            </Body2>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <>
            {!previousVersionHasNoDebitInterest && (
              <Box mb={3}>
                <InfoPanel
                  title={t(`Debit interest has been configured on a previous version of this product`)}
                  body={t(`This means that you cannot change the accrual period or delete the feature`)}
                />
              </Box>
            )}
            <CommonConfigPaper
              commonConfig={debitInterest.commonConfig}
              description={debitInterest.description}
              title="Debit interest common config"
            />
            <DebitInterestRateDefinitions
              position="featurePage"
              product={product}
              rateAboveLimit={debitInterest.rateAboveLimit}
              rateWithinLimit={debitInterest.rateWithinLimit}
              rateAboveLimitOverride={debitInterest.rateAboveLimitOverride}
              currencyCode={product.currency?.currencyCode}
              isLending={isLending}
              title={isLending ? '' : 'INTEREST RATES'}
              removeRate={submitDebitInterestRateRemoval}
            />
          </>
        }
      />
    </ContentContainer>
  )
}

export default ViewDebitInterestPage
