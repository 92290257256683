import React from 'react'
import { Body2 } from 'dls/atoms'

interface OwnProps {
  Icon: JSX.Element
  label: string
}

const IconLabel = ({ Icon, label }: OwnProps) => (
  <span data-dls="molecules-IconLabel" style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
    {Icon}
    <Body2 variantMapping={{ body2: 'span' }}>{label}</Body2>
  </span>
)

export default IconLabel
