import { createAction } from '@reduxjs/toolkit'
import { To, NavigateOptions } from 'react-router-dom'

export interface NavigateFunctionWithOptions {
  to: To
  options?: NavigateOptions
}

type NavigateActionProps = string | NavigateFunctionWithOptions

export const navigate = createAction<NavigateActionProps>('ROUTER/NAVIGATE')

const actions = {
  navigate,
}

export default actions
