/* eslint-disable no-nested-ternary */
import React from 'react'
import { Divider } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { InfoOutlined } from '@material-ui/icons'
import { CustomBody1, TitleSection, ContentSection, DescriptionSection, ImageSection } from './styles'
import { Paper, Graphic } from 'dls/atoms/'
import { ImageTypes, ColorType } from 'dls/shared/types'
import { LinkWithText } from 'dls/molecules/Buttons'

interface ButtonCustomProps extends ButtonProps {
  copy?: string
  ariaProps?: Record<string, string | boolean>
}

interface OwnProps {
  title: string | React.ReactElement
  imageProps?: ImageTypes
  ariaProps?: Record<string, string | boolean>
  description: string | React.ReactElement
  buttonProps?: ButtonCustomProps
  id?: string
}

const HelpPanel = ({ title, description = '', imageProps, ariaProps, buttonProps, id }: OwnProps) => {
  const { t } = useTranslation()

  const renderButton = () => (
    <LinkWithText
      variant="text"
      aria-haspopup="dialog"
      aria-controls="more-about-dialog"
      endIcon={<InfoOutlined />}
      {...buttonProps?.ariaProps}
      {...buttonProps}
      color={ColorType.BUTTON}
    >
      {buttonProps?.copy}
    </LinkWithText>
  )

  return (
    <Paper elevation={1} {...ariaProps} data-dls="organism-HelpPanel" id={id}>
      {title && (
        <TitleSection>
          <CustomBody1 variant="body1" variantMapping={{ body1: 'h4' }}>
            {title}
          </CustomBody1>
          <Divider />
        </TitleSection>
      )}
      <ContentSection>
        {imageProps && (
          <ImageSection>
            <Graphic {...imageProps} />
          </ImageSection>
        )}
        {typeof description === 'string' ? <DescriptionSection>{t(description)}</DescriptionSection> : description}
        {buttonProps && renderButton()}
      </ContentSection>
    </Paper>
  )
}

export default HelpPanel
