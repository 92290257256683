import { Moment } from 'moment-timezone'
import { MenuItemProps } from '@material-ui/core'
import { Fees, Rewards } from './features/feesRewards/types'
import { CreditLimitResponse } from './typings/creditLimit'
import { MultiParty } from './typings/multiParty'
import { FrequencyFormValues } from './typings/frequencySelector'
import { ProductSyndicateResponse } from './typings/productSyndication'
import { PaymentLimitsResponse } from 'store/products/typings/paymentLimits'
import { CardTransactionLimitsResponse } from 'store/products/typings/cardLimits'
import { Colleague } from 'store/user/types'
import { StatementLevel } from 'store/templates/statementConfiguration/types'
import { ProductStatus } from 'store/products/typings/productStatus'

export type ProductKey = string
export type TransactionRule = string
export type FeatureId = string

export enum Feature {
  CARDS = 'cards',
  CREDITINTEREST = 'creditInterest',
  CURRENCY = 'currency',
  DOCUMENTS = 'documents',
  ELIGIBILITY = 'eligibility',
  IDENTIFICATION = 'identification',
  LIMITS = 'limits',
  REQUIREDEXTERNALID = 'requiredExternalId',
  STATEMENTS = 'statements',
  SUBSCRIPTIONCREATIONRULE = 'subscriptionCreationRule',
  TERMSANDCONDITIONS = 'termsAndConditions',
  TRANSACTIONRULES = 'transactionRules',
  PRODUCTCORE = 'productCore',
  DEBIT_INTEREST = 'debitInterest',
  PAYMENT_SCHEDULE = 'paymentSchedule',
  FEES = 'fees',
  REWARDS = 'rewards',
  PAYMENT_LIMITS = 'paymentLimits',
  CARD_TRANSACTION_LIMITS = 'cardLimits',
  CREDIT_LIMIT = 'minMaxCreditLimit',
  MULTI_PARTY = 'multiParty',
  PRODUCT_SYNDICATION = 'productSyndication',
}

export type FeeRewardFeatureType = Feature.FEES | Feature.REWARDS

export const LoanAccountFeature = [
  Feature.CURRENCY,
  Feature.DEBIT_INTEREST,
  Feature.DOCUMENTS,
  Feature.ELIGIBILITY,
  Feature.IDENTIFICATION,
  Feature.PAYMENT_SCHEDULE,
  Feature.PRODUCTCORE,
  Feature.REQUIREDEXTERNALID,
  Feature.FEES,
  Feature.REWARDS,
  Feature.STATEMENTS,
  Feature.SUBSCRIPTIONCREATIONRULE,
  Feature.TERMSANDCONDITIONS,
  Feature.CARD_TRANSACTION_LIMITS,
  Feature.CREDIT_LIMIT,
  Feature.MULTI_PARTY,
  Feature.PRODUCT_SYNDICATION,
]

export const MortgageAccountFeature = [
  Feature.CURRENCY,
  Feature.DEBIT_INTEREST,
  Feature.DOCUMENTS,
  Feature.ELIGIBILITY,
  Feature.IDENTIFICATION,
  Feature.PAYMENT_SCHEDULE,
  Feature.PRODUCTCORE,
  Feature.REQUIREDEXTERNALID,
  Feature.FEES,
  Feature.REWARDS,
  Feature.STATEMENTS,
  Feature.SUBSCRIPTIONCREATIONRULE,
  Feature.TERMSANDCONDITIONS,
  Feature.CARD_TRANSACTION_LIMITS,
  Feature.CREDIT_LIMIT,
  Feature.MULTI_PARTY,
  Feature.PRODUCT_SYNDICATION,
]

export const SavingAccountFeature = [
  Feature.CARDS,
  Feature.CREDITINTEREST,
  Feature.CURRENCY,
  Feature.DOCUMENTS,
  Feature.ELIGIBILITY,
  Feature.IDENTIFICATION,
  Feature.LIMITS,
  Feature.DEBIT_INTEREST,
  Feature.PAYMENT_LIMITS,
  Feature.PRODUCTCORE,
  Feature.REQUIREDEXTERNALID,
  Feature.FEES,
  Feature.REWARDS,
  Feature.STATEMENTS,
  Feature.SUBSCRIPTIONCREATIONRULE,
  Feature.TERMSANDCONDITIONS,
  Feature.TRANSACTIONRULES,
  Feature.CARD_TRANSACTION_LIMITS,
  Feature.CREDIT_LIMIT,
  Feature.MULTI_PARTY,
  Feature.PRODUCT_SYNDICATION,
]

export const CurrentAccountFeature = [
  Feature.CARDS,
  Feature.CREDITINTEREST,
  Feature.CURRENCY,
  Feature.DOCUMENTS,
  Feature.ELIGIBILITY,
  Feature.IDENTIFICATION,
  Feature.LIMITS,
  Feature.DEBIT_INTEREST,
  Feature.PAYMENT_LIMITS,
  Feature.PRODUCTCORE,
  Feature.REQUIREDEXTERNALID,
  Feature.FEES,
  Feature.REWARDS,
  Feature.STATEMENTS,
  Feature.SUBSCRIPTIONCREATIONRULE,
  Feature.TERMSANDCONDITIONS,
  Feature.TRANSACTIONRULES,
  Feature.CARD_TRANSACTION_LIMITS,
  Feature.CREDIT_LIMIT,
  Feature.MULTI_PARTY,
  Feature.PRODUCT_SYNDICATION,
]

export const AccountWithFeaturesMap = {
  PersonalCurrentAccount: CurrentAccountFeature,
  CurrentAccount: CurrentAccountFeature,
  Savings: SavingAccountFeature,
  Loans: LoanAccountFeature,
  Mortgages: MortgageAccountFeature,
}

export enum FeatureName {
  PRODUCT = 'Product',
  CARDS = 'Cards',
  CREDITINTEREST = 'Credit interest',
  CURRENCY = 'Currency',
  DOCUMENTS = 'Documents',
  ELIGIBILITY = 'Eligibility',
  IDENTIFICATION = 'Identification',
  LIMITS = 'Limits',
  REQUIREDEXTERNALID = 'External identifiers',
  STATEMENTS = 'Statements',
  SUBSCRIPTIONCREATIONRULE = 'Subscription creation rules',
  TERMSANDCONDITIONS = 'Terms and conditions',
  TRANSACTIONRULES = 'Transaction rules',
  PRODUCTCORE = 'Product details',
  PRODUCTCODE = 'Product codes',
  DEBIT_INTEREST = 'Debit interest',
  FEES = 'Fees',
  REWARDS = 'Rewards',
  PAYMENT_SCHEDULE = 'Amount and Payment schedule',
  PAYMENT_LIMITS = 'Payment limits',
  CARD_LIMITS = 'Card transaction limits',
  CREDIT_LIMIT = 'Credit limit',
  MULTI_PARTY = 'Multi-party',
  PRODUCT_SYNDICATION = 'Product syndication',
}

export interface DeleteFeaturePayload {
  productKey: ProductKey
  productVersion: number
  featureName: string
}

export enum RateTierBand {
  FLAT = 'FLAT',
  TIER = 'TIER',
  BAND = 'BAND',
}

export interface PackageFormValues {
  countriesOfResidence: string
}

export interface CardImage {
  alt: string
  url: string
}

export interface ProductSyndicationError extends Error {
  nonSyndicated: boolean
  error: boolean
}

export interface ProductsState {
  loading: boolean
  updating: boolean
  error?: Error
  data: Record<ProductKey, Product>
  dataLoading: boolean
  selectedProduct?: ExtendedProduct
  productSyndication: ProductSyndicateResponse | null
  productSyndicationLoading: boolean
  productSyndicationError?: ProductSyndicationError
  colleagues: Colleague[]
  versions?: Product[]
  assigneeModalOpen: boolean
  review: boolean
  reviewError?: Error
  taskLoader: boolean
  publishing: boolean
  publishSuccess: boolean
  publishError?: Error
  reviewNoUserError?: Error
  reviewGeneralError?: Error
  getVersionsLoading: boolean
  getVersionsFail: boolean
  productVersionsLoading: boolean
  productVersionsFail: boolean
  cardImagesLoading: boolean
  cardImagesError?: boolean
  cardImages: CardImage[]
  productPermissions: ProductPermissions
  transactionRulesDefaultAccessPutting: boolean
  transactionRulesDefaultAccessPuttingSuccess: boolean
  transactionRulesDefaultAccessPuttingError?: Error
  transactionRuleDeleting: boolean
  transactionRuleDeletingSuccess: boolean
  transactionRuleDeletingError?: Error
  paymentSchedulesDefaultAccessSetting: boolean
  paymentSchedulesDefaultAccessSettingSuccess: boolean
  paymentSchedulesDefaultAccessSettingError?: Error
  selectedPaymentSchedules?: PaymentSchedule
  createNewProductVersionLoading: boolean
  createNewProductVersionFail: boolean
  createNewProductSuccess: boolean
}

export enum ProductCategory {
  Basic = 'Basic',
  Premium = 'Premium',
  VIP = 'VIP',
}

export interface Product {
  closedBy?: string | null
  closedDate?: string | null
  createdBy: string
  createdDate: string
  productCategory: string
  productDescription: string
  productKey: string
  productName: string
  productSegment: ProductSegment
  productLine: ProductLine
  productGroup: ProductGroup
  productType: ProductType
  publishedBy: string | null
  publishedDate: string | null
  status: ProductStatus
  tags: string[]
  updatedBy: string | null
  updatedDate: string | null
  version: number
  effectiveDate?: string | null
}

export enum ProductLine {
  ACCOUNTS = 'ACCOUNTS',
  LENDING = 'LENDING',
  DEPOSITS = 'DEPOSITS',
}

export enum ProductGroup {
  CURRENT = 'CURRENT',
  SAVINGS = 'SAVINGS',
  POOLS = 'POOLS',
  NOSTRO_VOSTRO = 'NOSTRO_VOSTRO',
  LOANS = 'LOANS',
  MORTGAGES = 'MORTGAGES',
}

export enum ProductSegment {
  BUSINESS = 'Business',
  PERSONAL = 'Personal',
}

export interface ExtendedProduct extends Product {
  cards: Card[]
  closedDate?: string
  createdBy: string
  createdDate: string
  creditInterest: CreditInterest | null
  debitInterest: DebitInterest | null
  currency: Currency
  documents: Document[]
  effectiveDate?: string | null
  eligibility: Eligibility | null
  identification: Identification | null
  internalDocumentation?: InternalDocumentation
  limits: Limits | null
  requiredExternalId: RequiredExternalId[] | null
  fees: Fees | null
  rewards: Rewards | null
  statements: Statement[]
  subscriptionCreationRule: SubscriptionCreationRule
  teams: Team[] | null
  termsAndConditions: Document[]
  transactionRules: TransactionRules | null
  paymentSchedule?: PaymentSchedule
  paymentLimits: PaymentLimitsResponse | null
  cardLimits: CardTransactionLimitsResponse | null
  featureName?: string
  minMaxCreditLimit?: CreditLimitResponse
  multiParty?: MultiParty
  productSyndication: ProductSyndicateResponse | null
}

export enum ApplicationFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
}

export enum ApplicationOccurency {
  MONTH_END = 'MONTH_END',
  MONTH_BEGINNING = 'MONTH_BEGININNG',
  ACCOUNT_ANNIVERSARY = 'ACCOUNT_ANNIVERSARY',
  SPECIFIC_DAY = 'SPECIFIC_DAY',
}

export interface TransactionRules {
  inbound?: TransactionRuleItem
  outbound?: TransactionRuleItem
}

export interface TransactionRuleItem {
  defaultAccess: TransactionRuleAccess
  rules?: TransactionRuleItemRule[] | null
}

export interface TransactionRuleItemRule {
  access?: TransactionRuleAccess
  applicableCriteria: ApplicableCriteria[]
  matchCriteria: MatchCriteria[]
  key?: string
  name?: string
  order: number
}

export interface MatchCriteria {
  operator: TransactionRuleOperator
  type: TransactionRuleType
  values: string[]
}

export enum TransactionRuleType {
  // MERCHANT_CATEGORY_CODE = 'MERCHANT_CATEGORY_CODE',
  // ORIGIN_STATE = 'ORIGIN_STATE',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
  ROUTING_DESTINATION = 'ROUTING_DESTINATION',
  SCHEME_NAME = 'SCHEME_NAME',
  SWEEP_TYPE = 'SWEEP_TYPE',
  // TRANSACTION_CODE = 'TRANSACTION_CODE',
}

export enum TransactionRuleOperator {
  EQUAL_TO = 'EQUAL_TO',
  ANY_OF = 'IN',
  REGEX = 'REGEX',
}

type ApplicableCriteriaDomain = 'PMNT' | 'CAMT'

export interface ApplicableCriteria {
  domain: ApplicableCriteriaDomain
  family?: string
}

export enum TransactionRuleAccess {
  DENY = 'DENY',
  PERMIT = 'PERMIT',
}

export interface Profile {
  firstName: string
  lastName: string
  title?: string
}

export interface Eligibility {
  ageEligibility?: AgeEligibility
  incomeEligibility?: IncomeEligibility
  officerEligibility?: OfficerEligibility
  residencyEligibility?: ResidencyEligibility[]
  studentAccountEligibility?: StudentAccountEligibility
  taxResidencyEligibility?: TaxResidencyEligibility
  taxIdentificationEligibility?: TaxIdentificationEligibility
  productDependencyEligibility?: ProductDependencyEligibility
}

export interface ProductDependencyEligibility {
  dependencies?: string[] // ??????
  dependency?: ProductDependency
  operator?: ProductDependencyOperator
}

export enum ProductDependencyOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface ProductDependency {
  productKey?: ProductKey
  subscriptionStatus?: ProductDependencySubscriptionStatus
}

export enum ProductDependencySubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PROVISIONED = 'PROVISIONED',
  CLOSED = 'CLOSED',
  CLOSURE_PENDING = 'CLOSURE_PENDING',
}

export interface EligibilityFormValues {
  ageEligibility: AgeEligibilityFormValues[]
  incomeEligibility: IncomeEligibility[]
  residencyEligibility: ResidencyEligibility[]
  officerEligibility: OfficerEligibility[]
  studentAccountEligibility: StudentAccountEligibility[]
  taxIdentificationEligibility: TaxIdentificationEligibility[]
  taxResidencyEligibility: TaxResidencyEligibility[]
  productDependencyEligibility: ProductDependencyEligibility[]
}

export interface AgeEligibility {
  minimumAge: number
  maximumAge: number | null
}

export interface AgeEligibilityFormValues {
  minimumAge: number
  maximumAge: number | null
}

export interface IncomeEligibility {
  minimumIncome: number
  maximumIncome: number | null
  period?: string
}

export interface StudentAccountEligibility {
  studentIdRequired: boolean
}

export interface TaxResidencyEligibility {
  countryIncluded: string[]
}

export interface TaxIdentificationEligibility {
  countryCode: string[]
}

export enum CountryIncluded {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GN = 'GN',
  GM = 'GM',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export enum Alpha3CountryCodes {
  ABW = 'AW',
  AFG = 'AF',
  AGO = 'AO',
  AIA = 'AI',
  ALA = 'AX',
  ALB = 'AL',
  AND = 'AD',
  ANT = 'AN',
  ARE = 'AE',
  ARG = 'AR',
  ARM = 'AM',
  ASM = 'AS',
  ATA = 'AQ',
  ATF = 'TF',
  ATG = 'AG',
  AUS = 'AU',
  AUD = 'AU',
  AUT = 'AT',
  AZE = 'AZ',
  BDI = 'BI',
  BEL = 'BE',
  BEN = 'BJ',
  BFA = 'BF',
  BGD = 'BD',
  BGR = 'BG',
  BHR = 'BH',
  BHS = 'BS',
  BIH = 'BA',
  BLM = 'BL',
  BLR = 'BY',
  BLZ = 'BZ',
  BMU = 'BM',
  BOL = 'BO',
  BRA = 'BR',
  BRB = 'BB',
  BRN = 'BN',
  BTN = 'BT',
  BVT = 'BV',
  BWA = 'BW',
  CAF = 'CF',
  CAN = 'CA',
  CCK = 'CC',
  CHE = 'CH',
  CHL = 'CL',
  CHN = 'CN',
  CIV = 'CI',
  CMR = 'CM',
  COD = 'CD',
  COG = 'CG',
  COK = 'CK',
  COL = 'CO',
  COM = 'KM',
  CPV = 'CV',
  CRI = 'CR',
  CUB = 'CU',
  CXR = 'CX',
  CYM = 'KY',
  CYP = 'CY',
  CZE = 'CZ',
  DEU = 'DE',
  DJI = 'DJ',
  DMA = 'DM',
  DNK = 'DK',
  DOM = 'DO',
  DZA = 'DZ',
  ECU = 'EC',
  EGY = 'EG',
  ERI = 'ER',
  ESH = 'EH',
  ESP = 'ES',
  EST = 'EE',
  ETH = 'ET',
  FIN = 'FI',
  FJI = 'FJ',
  FLK = 'FK',
  FRA = 'FR',
  FRO = 'FO',
  FSM = 'FM',
  GAB = 'GA',
  GBR = 'GB',
  GBP = 'GB',
  GEO = 'GE',
  GGY = 'GG',
  GHA = 'GH',
  GIB = 'GI',
  GIN = 'GN',
  GLP = 'GP',
  GMB = 'GM',
  GNB = 'GW',
  GNQ = 'GQ',
  GRC = 'GR',
  GRD = 'GD',
  GRL = 'GL',
  GTM = 'GT',
  GUF = 'GF',
  GUM = 'GU',
  GUY = 'GY',
  HKG = 'HK',
  HMD = 'HM',
  HND = 'HN',
  HRV = 'HR',
  HTI = 'HT',
  HUN = 'HU',
  IDN = 'ID',
  IMN = 'IM',
  IND = 'IN',
  IOT = 'IO',
  IRL = 'IE',
  IRN = 'IR',
  IRQ = 'IQ',
  ISL = 'IS',
  ISR = 'IL',
  ITA = 'IT',
  JAM = 'JM',
  JEY = 'JE',
  JOR = 'JO',
  JPN = 'JP',
  KAZ = 'KZ',
  KEN = 'KE',
  KGZ = 'KG',
  KHM = 'KH',
  KIR = 'KI',
  KNA = 'KN',
  KOR = 'KR',
  KWT = 'KW',
  LAO = 'LA',
  LBN = 'LB',
  LBR = 'LR',
  LBY = 'LY',
  LCA = 'LC',
  LIE = 'LI',
  LKA = 'LK',
  LSO = 'LS',
  LTU = 'LT',
  LUX = 'LU',
  LVA = 'LV',
  MAC = 'MO',
  MAF = 'MF',
  MAR = 'MA',
  MCO = 'MC',
  MDA = 'MD',
  MDG = 'MG',
  MDV = 'MV',
  MEX = 'MX',
  MHL = 'MH',
  MKD = 'MK',
  MLI = 'ML',
  MLT = 'MT',
  MMR = 'MM',
  MNE = 'ME',
  MNG = 'MN',
  MNP = 'MP',
  MOZ = 'MZ',
  MRT = 'MR',
  MSR = 'MS',
  MTQ = 'MQ',
  MUS = 'MU',
  MWI = 'MW',
  MYS = 'MY',
  MYT = 'YT',
  NAM = 'NA',
  NCL = 'NC',
  NER = 'NE',
  NFK = 'NF',
  NGA = 'NG',
  NIC = 'NI',
  NIU = 'NU',
  NLD = 'NL',
  NOR = 'NO',
  NPL = 'NP',
  NRU = 'NR',
  NZL = 'NZ',
  OMN = 'OM',
  PAK = 'PK',
  PAN = 'PA',
  PCN = 'PN',
  PER = 'PE',
  PHL = 'PH',
  PLW = 'PW',
  PNG = 'PG',
  POL = 'PL',
  PRI = 'PR',
  PRK = 'KP',
  PRT = 'PT',
  PRY = 'PY',
  PSE = 'PS',
  PYF = 'PF',
  QAT = 'QA',
  REU = 'RE',
  ROU = 'RO',
  RUS = 'RU',
  RWA = 'RW',
  SAU = 'SA',
  SDN = 'SD',
  SEN = 'SN',
  SGP = 'SG',
  SGS = 'GS',
  SHN = 'SH',
  SJM = 'SJ',
  SLB = 'SB',
  SLE = 'SL',
  SLV = 'SV',
  SMR = 'SM',
  SOM = 'SO',
  SPM = 'PM',
  SRB = 'RS',
  SSD = 'SS',
  STP = 'ST',
  SUR = 'SR',
  SVK = 'SK',
  SVN = 'SI',
  SWE = 'SE',
  SWZ = 'SZ',
  SYC = 'SC',
  SYR = 'SY',
  TCA = 'TC',
  TCD = 'TD',
  TGO = 'TG',
  THA = 'TH',
  TJK = 'TJ',
  TKL = 'TK',
  TKM = 'TM',
  TLS = 'TL',
  TON = 'TO',
  TTO = 'TT',
  TUN = 'TN',
  TUR = 'TR',
  TUV = 'TV',
  TWN = 'TW',
  TZA = 'TZ',
  UGA = 'UG',
  UKR = 'UA',
  UMI = 'UM',
  URY = 'UY',
  USA = 'US',
  UZB = 'UZ',
  VAT = 'VA',
  VCT = 'VC',
  VEN = 'VE',
  VGB = 'VG',
  VIR = 'VI',
  VNM = 'VN',
  VUT = 'VU',
  WLF = 'WF',
  WSM = 'WS',
  XKX = 'XK',
  YEM = 'YE',
  ZAF = 'ZA',
  ZMB = 'ZM',
  ZWE = 'ZW',
}

export interface OfficerEligibility {
  maxAmount: number
  minAmount: number
  officerType: OfficerType
}

export enum OfficerType {
  DIRECTOR = 'Director',
  OWNER = 'Owner',
  OTHER = 'Other',
  PARTNER = 'Partner',
}

export interface ResidencyEligibility {
  countryIncluded: string[]
}

interface InternalDocumentation {
  salesAccessChannels: SalesAccessChannels[]
  servingAccessChannels: ServingAccessChannels[]
  productURL: string
  fraudRiskRating: string
  finCrimeRiskRating: string
  mobileWallet: MobileWallet[]
}

export enum SalesAccessChannels {
  BRANCH = 'Branch',
  CALL_CENTRE = 'CallCentre',
  POST = 'Post',
  ONLINE = 'Online',
  RELATIONSHIP_MANAGER = 'RelationshipManager',
}

export enum ServingAccessChannels {
  ATM = 'ATM',
  BRANCH = 'Branch',
  CALL_CENTRE = 'CallCentre',
  POST = 'Post',
  MOBILE_BANKING_APP = 'MobileBankingApp',
  ONLINE = 'Online',
  POST_OFFICE = 'PostOffice',
  RELATIONSHIP_MANAGER = 'RelationshipManager',
  TEXT = 'Text',
}

export enum MobileWallet {
  ANDROID_PAY = 'AndroidPay',
  APPLE_PAY = 'ApplePay',
  GOOGLE_PAY = 'GooglePay',
  ISSUER_MOBILE_APP = 'IssuerMobileApp',
  MOBILE_BANKING_APP = 'MobileBankingApp',
  OTHER = 'Other',
  PAYM = 'PayM',
  SAMSUNG_PAY = 'SamsungPay',
  VODAFONE_WALLET = 'VodafoneWallet',
}

interface Team {
  teamKey: string
  write: boolean
}

export interface Statement {
  accountAnniversary?: boolean
  statementType?: StatementType
  statementPeriod?: StatementPeriod
  statementDescription?: string
  startDay?: number
  startMonth?: number
  offsetDays?: number
  hideExternalIdentifiers: boolean
  template?: StatementTemplate
  statementConfigKey?: string | null
  level?: StatementLevel
}

export interface StatementFormValues {
  statementValue?: Statement[]
}

export interface StatementTemplate {
  id: string
  name: string
}

export enum StatementType {
  ACCOUNT_STATEMENT = 'ACCOUNTSTATEMENT',
  INTEREST_SUMMARY = 'INTERESTSUMMARY',
  FEES_STATEMENT = 'FEESSTATEMENT',
  INTEREST_STATEMENT = 'INTERESTSTATEMENT',
}

export enum StatementPeriod {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  // DEFAULT = 'DEFAULT',
}

export enum StartMonth {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export interface Frequency {
  frequencyType: FrequencyTypeValue
  month?: FrequencyMonth
  day?: number
  dayOfWeek?: FrequencyDayWeek
}

export enum FrequencyTypeValue {
  TRIGGERED_DAILY = 'TRIGGERED_DAILY', // @INFO: deprecated, use DAILY
  MONTH_BEGINNING = 'MONTH_BEGINNING',
  MONTH_END = 'MONTH_END',
  MONTHLY_ANNIVERSARY_OF_ACCOUNT = 'MONTH_ANNIVERSARY_OF_ACCOUNT',
  ANNUAL_ANNIVERSARY_OF_ACCOUNT = 'ANNUAL_ANNIVERSARY_OF_ACCOUNT',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTH = 'MONTH',
  ANNUAL = 'ANNUAL',
  DAYS_FROM_CALCULATION = 'DAYS_FROM_CALCULATION',
  PER_OCCURRENCE = 'PER_OCCURRENCE',
}

export enum FrequencyMonth {
  JAN = 'JAN',
  FEB = 'FEB',
  MAR = 'MAR',
  APR = 'APR',
  MAY = 'MAY',
  JUN = 'JUN',
  JUL = 'JUL',
  AUG = 'AUG',
  SEP = 'SEP',
  OCT = 'OCT',
  NOV = 'NOV',
  DEC = 'DEC',
}

export enum FrequencyDayWeek {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum TierStatusDefault {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum Period {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
}

export enum FrequencyType {
  CALCULATION = 'calculationFrequency',
  APPLICATION = 'applicationFrequency',
  COMPOUNDING = 'compoundingFrequency',
}

// How often
export enum TopLevelFrequency {
  ANNUAL = 'ANNUAL',
  MONTH = 'MONTH',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  DAYS_FROM_CALCULATION = 'DAYS_FROM_CALCULATION',
  PER_OCCURRENCE = 'PER_OCCURRENCE',
}

export enum MonthlyFrequencyItems {
  END = 'END',
  BEGINNING = 'BEGINNING',
  ANNIVERSARY = 'ANNIVERSARY',
  SPECIFIC_DAY = 'SPECIFIC_DAY',
}

export enum CalendarDay {
  DAY1 = 'DAY1',
  DAY2 = 'DAY2',
  DAY3 = 'DAY3',
  DAY4 = 'DAY4',
  DAY5 = 'DAY5',
  DAY6 = 'DAY6',
  DAY7 = 'DAY7',
  DAY8 = 'DAY8',
  DAY9 = 'DAY9',
  DAY10 = 'DAY10',
  DAY11 = 'DAY11',
  DAY12 = 'DAY12',
  DAY13 = 'DAY13',
  DAY14 = 'DAY14',
  DAY15 = 'DAY15',
  DAY16 = 'DAY16',
  DAY17 = 'DAY17',
  DAY18 = 'DAY18',
  DAY19 = 'DAY19',
  DAY20 = 'DAY20',
  DAY21 = 'DAY21',
  DAY22 = 'DAY22',
  DAY23 = 'DAY23',
  DAY24 = 'DAY24',
  DAY25 = 'DAY25',
  DAY26 = 'DAY26',
  DAY27 = 'DAY27',
  DAY28 = 'DAY28',
  DAY29 = 'DAY29',
  DAY30 = 'DAY30',
  DAY31 = 'DAY31',
}

export enum CalendarMonth {
  MONTH01 = 'MONTH01',
  MONTH02 = 'MONTH02',
  MONTH03 = 'MONTH03',
  MONTH04 = 'MONTH04',
  MONTH05 = 'MONTH05',
  MONTH06 = 'MONTH06',
  MONTH07 = 'MONTH07',
  MONTH08 = 'MONTH08',
  MONTH09 = 'MONTH09',
  MONTH10 = 'MONTH10',
  MONTH11 = 'MONTH11',
  MONTH12 = 'MONTH12',
}

export enum WeeklyFrequencyItems {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum YearlyFrequencyItems {
  DAY_OF_OPENING = 'DAY_OF_OPENING',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
}

export enum ChargeTemplateNames {
  EVENT_BASED_CHARGE = 'eventBasedCharge', // Type D
  TRANSACTION_CHARGE = 'transactionCharge', // Type A
  SCHEDULED_PAYMENTS_CHARGE = 'scheduledPaymentsCharge', // Type C
  SCHEDULED_ACCOUNT_MANAGEMENT_CHARGE = 'scheduledAccountManagementCharge', // Type B
}

export const TopLevelApplicationFrequencyPerType = {
  [`${FrequencyType.APPLICATION}--TYPE_${TopLevelFrequency.ANNUAL}`]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
  },
  [`${FrequencyType.APPLICATION}--TYPE_${TopLevelFrequency.MONTH}`]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
  },
  [`${FrequencyType.APPLICATION}--TYPE_${TopLevelFrequency.WEEKLY}`]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
  },
  [`${FrequencyType.APPLICATION}--TYPE_${TopLevelFrequency.DAILY}`]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
  },
  [`${FrequencyType.APPLICATION}--TYPE_PER_OCCURRENCE`]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
    [TopLevelFrequency.PER_OCCURRENCE]: {
      name: TopLevelFrequency.PER_OCCURRENCE,
      label: `${TopLevelFrequency.PER_OCCURRENCE}.label`,
    },
  },
}

export const TopLevelFrequencyPerType = {
  [FrequencyType.APPLICATION]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.WEEKLY]: {
      name: TopLevelFrequency.WEEKLY,
      label: `${TopLevelFrequency.WEEKLY}.label`,
    },
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
    [TopLevelFrequency.PER_OCCURRENCE]: {
      name: TopLevelFrequency.PER_OCCURRENCE,
      label: `${TopLevelFrequency.PER_OCCURRENCE}.label`,
    },
  },
  [FrequencyType.CALCULATION]: {
    // @TODO: Bring these back once the backend supports them again
    // [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    // [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    // [TopLevelFrequency.WEEKLY]: {
    //   name: TopLevelFrequency.WEEKLY,
    //   label: `${TopLevelFrequency.WEEKLY}.label`,
    // },
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
  },
  [FrequencyType.COMPOUNDING]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
  },
  [`${FrequencyType.CALCULATION}--TYPE_${ChargeTemplateNames.TRANSACTION_CHARGE}`]: {
    [TopLevelFrequency.PER_OCCURRENCE]: {
      name: TopLevelFrequency.PER_OCCURRENCE,
      label: `${TopLevelFrequency.PER_OCCURRENCE}.label`,
    },
  },
  [`${FrequencyType.APPLICATION}--TYPE_${ChargeTemplateNames.TRANSACTION_CHARGE}`]: {
    [TopLevelFrequency.PER_OCCURRENCE]: {
      name: TopLevelFrequency.PER_OCCURRENCE,
      label: `${TopLevelFrequency.PER_OCCURRENCE}.label`,
    },
  },
  [`${FrequencyType.CALCULATION}--TYPE_${ChargeTemplateNames.SCHEDULED_ACCOUNT_MANAGEMENT_CHARGE}`]: {
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
  },
  [`${FrequencyType.APPLICATION}--TYPE_${ChargeTemplateNames.SCHEDULED_ACCOUNT_MANAGEMENT_CHARGE}`]: {
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
  },
  [`${FrequencyType.CALCULATION}--TYPE_${ChargeTemplateNames.SCHEDULED_PAYMENTS_CHARGE}`]: {
    [TopLevelFrequency.PER_OCCURRENCE]: {
      name: TopLevelFrequency.PER_OCCURRENCE,
      label: `${TopLevelFrequency.PER_OCCURRENCE}.label`,
    },
  },
  [`${FrequencyType.APPLICATION}--TYPE_${ChargeTemplateNames.SCHEDULED_PAYMENTS_CHARGE}`]: {
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
  },
  [`${FrequencyType.CALCULATION}--TYPE_${ChargeTemplateNames.EVENT_BASED_CHARGE}`]: {
    [TopLevelFrequency.PER_OCCURRENCE]: {
      name: TopLevelFrequency.PER_OCCURRENCE,
      label: `${TopLevelFrequency.PER_OCCURRENCE}.label`,
    },
  },
  [`${FrequencyType.APPLICATION}--TYPE_${ChargeTemplateNames.EVENT_BASED_CHARGE}`]: {
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
  },
  [`${FrequencyType.APPLICATION}--TYPE_DEFAULT`]: {
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
  },
  [`${FrequencyType.CALCULATION}--TYPE_DEFAULT`]: {
    [TopLevelFrequency.ANNUAL]: { name: TopLevelFrequency.ANNUAL, label: `${TopLevelFrequency.ANNUAL}.label` },
    [TopLevelFrequency.MONTH]: { name: TopLevelFrequency.MONTH, label: `${TopLevelFrequency.MONTH}.label` },
    [TopLevelFrequency.WEEKLY]: { name: TopLevelFrequency.WEEKLY, label: `${TopLevelFrequency.WEEKLY}.label` },
    [TopLevelFrequency.DAILY]: { name: TopLevelFrequency.DAILY, label: `${TopLevelFrequency.DAILY}.label` },
    [TopLevelFrequency.PER_OCCURRENCE]: {
      name: TopLevelFrequency.PER_OCCURRENCE,
      label: `${TopLevelFrequency.PER_OCCURRENCE}.label`,
    },
  },
}

interface BaseRate {
  interestRate?: number
  fixedVariableType: FixedVariableType
  calculationMethod?: RateTierBand
  tierBands?: LendingTierBand[]
}

export interface CreditInterest {
  calculationFrequency: Frequency
  applicationFrequency: Frequency
  balanceCriteria?: BalanceCriteria
  dayCount: DayCount
  compoundingFrequency: Frequency
  operand?: Operand
  spread?: number
  partnercentricModel?: boolean
  bankInterestRateIndex?: string
  withholdingTax?: CreditInterestTaxRulesFormValues
  interestRate?: number
  fixedVariableType: FixedVariableType
  tierBandCalcMethod?: RateTierBand
  interestTierBand?: TierBand[]
  roundingMethod?: RoundingMethod
}

export enum LendingRateType {
  rateAboveLimit = 'rateAboveLimit', // Type 1: Unauthorised borrowing
  rateWithinLimit = 'rateWithinLimit', // Type 2: Authorised borrowing within limit
  rateAboveLimitOverride = 'rateAboveLimitOverride', // Type 3: Authorised borrowing beyond limit
}

export interface LendingRate extends BaseRate {
  spread?: number
  operator?: Operand
  cappingAmount?: number
  notAdded?: boolean
  partnercentricModel?: boolean
  type?: LendingRateType
  fixedVariableType: FixedVariableType
  bankInterestRateIndex?: string
}

export interface DebitInterestCommonConfig {
  balanceCriteria?: BalanceCriteria
  dayCount: DayCount
  notificationApplication: number
  roundingMethod: RoundingMethod
  applicationFrequency: string
  calculationFrequency: string
}

export interface DebitInterestFixedRate extends LendingRate {
  type?: LendingRateType
}

export interface DebitInterest {
  description: string
  commonConfig: DebitInterestCommonConfig
  rateWithinLimit?: LendingRate
  rateAboveLimit?: LendingRate
  rateAboveLimitOverride?: LendingRate
  previousVersionHasDebitInterest?: boolean
}

export enum TypeOfIndexRate {
  INDEPENDENT_INDEXED_RATE = 'INDEPENDENT_INDEXED_RATE',
  INDEX_RATE_PLUS_STATIC_MARGIN = 'INDEX_RATE_PLUS_STATIC_MARGIN',
  INDEX_RATE_PLUS_VARIABLE_MARGIN = 'INDEX_RATE_PLUS_VARIABLE_MARGIN',
}

export enum TypeOfIndexRateNoReferenceData {
  INDEPENDENT_INDEXED_RATE = 'INDEPENDENT_INDEXED_RATE',
}

export enum BalanceCriteria {
  ENDOFDAY = 'ENDOFDAY',
}

export enum DayCount {
  ACT365 = 'ACT365',
}

export enum FixedVariableType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export interface TierBandForm {
  startRange?: number
  endRange?: number
  tierBandRate?: string
  tierBandSpread?: number
  tierBandOperand?: Operand
}

export interface TierBand {
  startRange?: number
  endRange?: number
  tierBandRate?: number
  tierBandSpread?: number
  tierBandOperand?: Operand
}

export interface LendingTierBand {
  startRange?: number
  endRange?: number
  interestRate?: number
  spread?: number
  operator?: Operand
}

export interface LendingTierBandForm {
  startRange?: number
  endRange?: number
  interestRate?: string
  spread?: string
  operator?: Operand
}

export const MAX_END_RANGE = 9999999.99
export const MAX_END_RANGE_DEBIT_INTEREST = '999999999999999999.99'

export enum RoundingMethod {
  ROUND_UP = 'ROUNDUP',
  ROUND_DOWN = 'ROUNDDOWN',
  ROUND_STD = 'ROUNDSTD',
}

export enum Operand {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT',
}

export enum CalculationMethod {
  TIER = 'TIER',
  BAND = 'BAND',
}

export interface Currency {
  currencyCode: string
  country: string
}

export enum RequiredExternalId {
  AIIN = 'AIIN',
  // CPAN = 'CPAN',
  // IBAN = 'IBAN',
  // BBAN = 'BBAN',
}

export interface RequiredExternalIdFormValues {
  requiredExternalId: RequiredExternalId[]
}
export interface Card {
  scheme: CardScheme
  type: CardType
  cardId?: string
  name: string
  description: string
  formType: CardFormType
  processorId: string
  processorLogoId: string
  maximumNumber: number
  defaultCard: boolean
  frontTemplateImage: {
    url: string
  }
}

export interface CardsFormValues {
  cards: Card[]
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  PROVISIONED = 'provisioned',
}

export enum CardFormType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

export enum ChannelSettings {
  ATM = 'atm',
  CHIPPIN = 'chipPin',
  CNP = 'cnp',
  CONTACTLESS = 'contactless',
  INTERNATIONAL = 'international',
  MAGSTRIPE = 'magStripe',
}

export enum CardScheme {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  // UNIONPAY = 'UNIONPAY',
}

export enum CardType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export interface SubscriptionCreationRule {
  lockedStatus: boolean
  defaultSubscriptionStatus: SubscriptionStatus
}

export enum Origin {
  CUSTOM = 'CUSTOM',
  AEM = 'AEM',
}

export interface Document {
  description?: string
  files?: File[]
  identifier?: string
  identifierOriginal?: string
  name?: string
  publishedDate?: string
  title?: string
  type?: string
  version?: string
  origin: Origin | string
}

export interface SubcriptionCreationPayload {
  defaultSubscriptionStatus: SubscriptionStatus
  lockedStatus: boolean
}

export interface File {
  format: FileFormat
  label: string
  url: string
}

export enum FileFormat {
  PDF = 'PDF',
  HTML = 'HTML',
  CSV = 'CSV',
  DOC = 'DOC',
  OTHER = 'OTHER',
}

export enum TermsAndConditionsFileFormat {
  PDF = 'PDF',
  HTML = 'HTML',
  OTHER = 'OTHER',
}

export interface Limits {
  transactionLimits?: TransactionLimit[]
  schemeLimits?: SchemeLimit[]
  productLimits?: ProductLimit[]
  subscriptionLimit?: SubscriptionLimit
  cardLimit?: CardLimit
}
export interface CardLimit {
  maximumNumber?: number
}
export interface SubscriptionLimit {
  maximumNumber?: number
}

export interface TransactionLimit {
  transactionName?: TransactionName
  description?: string
  minimumAmount?: number
  maximumAmount?: number
  resetPeriod?: ResetPeriod
}

export enum TransactionName {
  ATM_WITHDRAWAL = 'ATMWITHDRAWAL',
  TRANSFER_OUT = 'TRANSFEROUT',
}

export enum ResetPeriod {
  DAY = 'DAY',
  MONTH = 'MONTH',
}

export interface SchemeLimit {
  schemeName?: SchemeName
  description?: string
  minimumAmount?: number
  maximumAmount?: number
  resetPeriod?: ResetPeriod
}

export enum SchemeName {
  FPS = 'FPS',
  BACS = 'BACS',
  // SWIFT = 'SWIFT',
  // EURO1 = 'EURO1',
  // STEP1 = 'STEP1',
  // STEP2 = 'STEP2',
  // TARGET2 = 'TARGET2',
  // CNAPS2 = 'CNAPS2',
  // G3 = 'G3',
  // FEDWIRE = 'FEDWIRE',
  // CLS = 'CLS',
  // SCH = 'SCH',
  // CHIPS = 'CHIPS',
  // CHEQUE = 'CHEQUE',
}

export interface ProductLimit {
  productKey: string
  productName: string
  productType: ProductType
  description: string
  maximumNumber: number
}

export enum FundingMechanism {
  CARD = 'CARD',
  TRANSFER = 'TRANSFER',
}

export enum ProductType {
  PERSONAL_CURRENT_ACCOUNT = 'PersonalCurrentAccount',
  CURRENT_POOL = 'CurrentPool',
  SAVINGS_POOL = 'SavingsPool',
  BILLS_POOL = 'BillsPool',
  ACCOUNT = 'Account',
  MORTGAGE = 'Mortgage',
  LOANS = 'Loans',
  LENDING = 'Lendings',
  CURRENT_ACCOUNT = 'CurrentAccount',
  OTHER = 'Other',
  CUSTOM = 'Custom',
  RESERVEACCOUNT = 'ReserveAccount',
  SAVINGS = 'Savings',
  MORTGAGES = 'Mortgages',
}

export interface PublishProductPayload {
  productKey: ProductKey
  effectiveDate: Moment
  message: string
}

export interface PublishProductFormValues {
  effectiveDate: Moment
  message: string
}

export interface ReviewProductPayload {
  productKey: ProductKey
  message?: string
  effectiveDate: Moment
}

export interface ReviewProductPayload4Action {
  productKey: ProductKey
  message?: string
  effectiveDate: Moment
  version?: string
  status?: ProductStatus
}

export interface SetFeatureEligibilityPayload {
  productKey: ProductKey
  eligibility: Eligibility
}

export interface CreateProductPayload {
  productName: string
  productType: string
  productSegment: string
  productLine: ProductLine
  productGroup: ProductGroup
  productTaxonomy?: string
  productCategory: string
  productDescription: string
  tags: string[]
}

export interface ProductFormValues extends ExtendedProduct {
  productTaxonomy: string
}

interface Params {
  templateId?: string
}

export interface CreateProductWithTemplate {
  body?: CreateProductPayload
  params?: Params
}

export type FrequencyValues =
  | {
      ROOT: TopLevelFrequency.ANNUAL
      [TopLevelFrequency.ANNUAL]: {
        secondary: YearlyFrequencyItems
        tertiary?: Date
      }
    }
  | {
      ROOT: TopLevelFrequency.MONTH
      [TopLevelFrequency.MONTH]: {
        secondary: MonthlyFrequencyItems
        tertiary?: number
      }
    }
  | {
      ROOT: TopLevelFrequency.DAYS_FROM_CALCULATION
      [TopLevelFrequency.DAYS_FROM_CALCULATION]: {
        secondary: number
      }
    }
  | {
      ROOT: TopLevelFrequency.WEEKLY
      [TopLevelFrequency.WEEKLY]: {
        secondary?: FrequencyDayWeek
      }
    }
  | {
      ROOT: TopLevelFrequency.DAILY
    }
  | {
      ROOT: TopLevelFrequency.PER_OCCURRENCE
    }
  | {}

export type CreditInterestFormValues = {
  [key in typeof FrequencyType[keyof typeof FrequencyType]]: FrequencyValues
} & {
  fixedVariableType: FixedVariableType
  roundingMethod?: RoundingMethod
  dayCount: DayCount
  interestTierBand?: TierBandForm[]
  balanceCriteria?: BalanceCriteria
  interestRate?: string
  operand?: Operand
  spread?: number
  tierBandCalcMethod?: RateTierBand
  bankInterestRateIndex?: string
  typeOfIndexRate?: TypeOfIndexRate | TypeOfIndexRateNoReferenceData
  partnercentricModel?: boolean
  showWithholdingTax?: boolean
  availableHoldingTaxRules?: boolean
  withholdingTax?: CreditInterestTaxRulesFormValues
}

export type CreditInterestPayloadValues = {
  [key in typeof FrequencyType[keyof typeof FrequencyType]]: FrequencyValues
} & {
  fixedVariableType: FixedVariableType
  roundingMethod?: RoundingMethod
  dayCount: DayCount
  interestTierBand?: TierBand[]
  balanceCriteria?: BalanceCriteria
  interestRate?: number
  operand?: Operand
  spread?: number
  tierBandCalcMethod?: RateTierBand
  bankInterestRateIndex?: string
  typeOfIndexRate?: TypeOfIndexRate | TypeOfIndexRateNoReferenceData
  partnercentricModel?: boolean
  showWithholdingTax?: boolean
  availableHoldingTaxRules?: boolean
  withholdingTax?: CreditInterestTaxRulesFormValues
}

export type DebitInterestFormValues = FrequencyFormValues & {
  description: string
  fixedVariableType: FixedVariableType
  dayCount: DayCount
  tierBands?: LendingTierBandForm[]
  interestRate?: string
  roundingMethod: RoundingMethod
  calculationMethod?: RateTierBand
  bankInterestRateIndex?: string
  balanceCriteria?: BalanceCriteria
  typeOfIndexRate?: TypeOfIndexRate
  cappingAmount?: number
  notificationApplication: number
  type: string
  partnercentricModel?: boolean
  spread: string
  operator: Operand
}

export type DebitInterestCommonConfigFormValues = FrequencyFormValues & {
  description?: string
  dayCount: DayCount
  balanceCriteria?: BalanceCriteria
  notificationApplication: number
  roundingMethod: RoundingMethod
}

export type LendingRateFormValues = {
  typeOfIndexRate?: TypeOfIndexRate
  interestRate?: string
  operator?: Operand
  spread?: string
  tierBands?: LendingTierBandForm[]
  bankInterestRateIndex?: string
  calculationMethod?: RateTierBand
  fixedVariableType: FixedVariableType
  type?: string
  partnercentricModel?: boolean
  cappingAmount?: number
}

export type CreditInterestTaxRulesFormValues = {
  taxRules: string[]
}

export interface LimitsFormValues extends Limits {
  schemeLimitsFPS?: Partial<SchemeLimit>[]
  schemeLimitsBACS?: Partial<SchemeLimit>[]
  transactionLimitsATM?: Partial<TransactionLimit>[]
  transactionLimitsOUT?: Partial<TransactionLimit>[]

  // This below commented code will be used, Dont remove!!!!!!!

  // productLimitPersonalCurrentAccount?: Partial<ProductLimit>[]
  // productLimitCurrentPool?: Partial<ProductLimit>[]
  // productLimitSavingsPool?: Partial<ProductLimit>[]
  // productLimitBillsPool?: Partial<ProductLimit>[]
  // productLimitAccount?: Partial<ProductLimit>[]
  // productLimitMortgage?: Partial<ProductLimit>[]
  // productLimitLoan?: Partial<ProductLimit>[]
  // productLimitCurrentAccount?: Partial<ProductLimit>[]
  // productLimitOther?: Partial<ProductLimit>[]
  // productLimitCustom?: Partial<ProductLimit>[]
  subscriptionLimitOnly?: Partial<SubscriptionLimit>[]
  cardLimitOnly?: Partial<CardLimit>[]
}

export interface MultipleChoiceElement {
  defaultValue?: unknown
  multiple?: boolean
  name?: string
  itemName?: string
  label?: string
  overrideLabel?: string
  menuItemProps?: MenuItemProps
  readOnly?: boolean
  disabled?: boolean
  identifier?: string
  identifierOriginal?: string
}

export interface CommonFeaturePageValues {
  [key: string]: Document[]
}

export interface UpdateProductPayload {
  productDescription: string
  productName: string
  productTaxonomy?: string
  tags: string[]
}

export interface UpdateProductFormValues {
  productDescription: string
  productName: string
  productTaxonomy?: string
}

export interface DocumentsValues {
  [key: string]: Document[]
}

export enum SelectTransactionType {
  CARDPAYMENT = 'CARDPAYMENT',
  CASHWITHDRAWAL = 'CASHWITHDRAWAL',
}

export enum SelectAuthneticationMethod {
  CHIPANDPIN = 'CHIP AND PIN',
  MAGSTRIPE = 'MAG STRIPE',
  CONTACTLESS = 'CONTACTLESS',
}

export enum SelectBooleanValues {
  YES = 'YES',
  NO = 'NO',
}

export interface Identification {
  productCode?: string
  productCodeDescription?: string
  subProductCode?: string
  subProductCodeDescription?: string
}

export interface RetireOrDeleteProductOrPackage {
  productOrPackageKey: ProductKey
  message: string
  productOrPackageName: string
}

export enum DeleteOrRetire {
  DELETEPRODUCT = 'DELETEPRODUCT',
  DELETEPACKAGE = 'DELETEPACKAGE',
  RETIREPRODUCT = 'RETIREPRODUCT',
}

export interface ProductPermissions {
  isCreditInterestEnabled?: boolean
  isDebitInterestEnabled?: boolean
  publishedDebitInterestRates?: string[]
  debitInterestRates?: string[] | null
}

export type TransactionExceptionType = 'inbound' | 'outbound'

export enum AmortisationMethod {
  STRAIGHT_LINE = 'STRAIGHT_LINE',
  DECLINING_BALANCE_EQUAL = 'DECLINING_BALANCE_EQUAL',
}

export enum StraightLineRoundingAdjustment {
  NO_ADJUSTMENT = 'NO_ADJUSTMENT',
  EXACT = 'EXACT',
}

export enum DecliningBalanceRoundingAdjustment {
  NO_ADJUSTMENT = 'NO_ADJUSTMENT',
  ADJUST_LAST_INTEREST = 'ADJUST_LAST_INTEREST',
  ADJUST_LAST_REPAYMENT = 'ADJUST_LAST_REPAYMENT',
}

export enum AllowedRepaymentFrequencies {
  WEEKLY = 'WEEKLY',
  FORTNIGHTLY = 'FORTNIGHTLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}

export enum PaymentScheduleTerm {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum PaymentTermTypeDays {
  'DAYS' = 1,
  'WEEKS' = 7,
  'FORTNIGHT' = 14,
  'MONTHS' = 31,
  'QUARTERLY' = 92,
  'YEARS' = 366,
}

export enum Amount {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

interface BasePaymentSchedule {
  description?: string
  allowedRepaymentFrequencies: AllowedRepaymentFrequencies[]
  term: {
    minimum: number | ''
    maximum: number | ''
    type: PaymentScheduleTerm
  }
  amount: {
    minimum: number | ''
    maximum: number | ''
  }
}

export interface StraighLinePaymentSchedule extends BasePaymentSchedule {
  amortisationMethod: AmortisationMethod.STRAIGHT_LINE
  calculationRoundingAdjustment: StraightLineRoundingAdjustment
}

export interface DecliningBalanceSchedule extends BasePaymentSchedule {
  amortisationMethod: AmortisationMethod.DECLINING_BALANCE_EQUAL
  calculationRoundingAdjustment: DecliningBalanceRoundingAdjustment
}

export type PaymentSchedule = StraighLinePaymentSchedule | DecliningBalanceSchedule

export interface FeaturePostResponse {
  featureId: FeatureId
}

export enum AllowedRolesMapType {
  Administrator = 'Admins',
  PrimaryOwner = 'Owners',
  ReadOnly = 'Viewers',
}

export interface ProductVersion {
  productKey?: string
  versions?: Array<Product>
}

export interface SearchProductsResponse {
  content: ProductVersion[]
}

export interface SearchProduct {
  key: string
  products: ProductVersion[]
}
