import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.publish.type]: (state: ProductsState) => ({
    ...state,
    publishing: true,
  }),
  [actions.publishSuccess.type]: (state: ProductsState, action: ReturnType<typeof actions.publishSuccess>) => ({
    ...state,
    publishing: false,
    selectedProduct: action.payload,
    publishSuccess: true,
  }),
  [actions.publishFail.type]: (state: ProductsState, action: ReturnType<typeof actions.publishFail>) => ({
    ...state,
    publishing: false,
    publishError: action.payload,
  }),
}
export default reducer
