import { Grid, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import React from 'react'
import styled from 'styled-components/macro'
import { HeaderTenantLogo } from 'components/ActualTenantLogo/ActualTenantLogo'
import { ExtendedProduct } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { LogoType } from 'store/tenant-config/types'
import ProductVersionSelector from 'components/ProductVersionSelector'

interface OwnProps {
  product: ExtendedProduct
  isOldVersion?: boolean
}

const mapStateToProps = () => ({})

type Props = ReturnType<typeof mapStateToProps> & OwnProps

const ProductNameDiv = styled.div`
  overflow-wrap: anywhere;
`

export const getProductStatusProgress = (productStatus: ProductStatus, governanceEnabled: boolean | string) => {
  if (governanceEnabled) {
    switch (productStatus) {
      case ProductStatus.DESIGN:
      case ProductStatus.UPDATED:
        return 33
      case ProductStatus.REVIEW:
        return 66
      case ProductStatus.APPROVED:
      case ProductStatus.LIVE:
      case ProductStatus.CLOSED:
        return 100
      default:
        return 0
    }
  } else {
    switch (productStatus) {
      case ProductStatus.DESIGN:
      case ProductStatus.UPDATED:
        return 50
      case ProductStatus.REVIEW:
        return 100
      case ProductStatus.APPROVED:
      case ProductStatus.LIVE:
      case ProductStatus.CLOSED:
        return 100
      default:
        return 0
    }
  }
}

const ProductHeader = ({ product, isOldVersion }: Props): React.ReactElement => (
  <ProductNameDiv id="productHeader">
    <Typography variant="h1">
      <HeaderTenantLogo type={LogoType.COLLAPSE} showIcon={false} />
      {product.productName}
    </Typography>
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
      <Grid item xs={12}>
        <ProductVersionSelector product={product} isVersionLatest={!isOldVersion} />
      </Grid>
    </Grid>
  </ProductNameDiv>
)

export default connect(mapStateToProps, null)(ProductHeader)
