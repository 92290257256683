import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import { RootState } from 'store'

const selectPackageState = (state: RootState) => state.packages

const selectAllPackages = createSelector(selectPackageState, (state) => state.data)

const selectLoading = createSelector(selectPackageState, (state) => state.loading)
const selectError = createSelector(selectPackageState, (packageState) => packageState?.error)

const selectSelectedPackage = createSelector(selectPackageState, (state) => state.selectedPackage)
const selectAvailableProducts = createSelector(selectPackageState, (state) => state.availableProducts)
const selectPublishError = createSelector(selectPackageState, (state) => state.publishError)

const selectPackageName = createSelector(selectPackageState, (state) => state.productPackageName)
const selectPackageType = createSelector(selectPackageState, (state) => state.packageType)
const selectCountryOfResidence = createSelector(selectPackageState, (state) => state.countryOfResidence)
const selectBrandId = createSelector(selectPackageState, (state) => state.brandId)

const selectIsPackagePublish = createSelector(selectPackageState, (state) => state.isPublished)
const selectPublishing = createSelector(selectPackageState, (state) => state.publishing)
const selectPublishSuccess = createSelector(selectPackageState, (state) => state.publishSuccess)

const selectEarliestPackageLiveDate = createSelector(
  selectPackageState,
  (state): moment.Moment => {
    const today = moment()
    if (!state.selectedPackage || !state.availableProducts) return today
    const { products } = state.selectedPackage
    if (!state || typeof products === 'undefined') return today

    // Get the earliest date of a product being live from all the versions
    const packageProductKeys = products.map(({ productKey }) => productKey)
    const packageProductsEffectiveDates = state.availableProducts
      .filter((product) => packageProductKeys.includes(product.productKey) && product.effectiveDate)
      .reduce((acc, { effectiveDate, productKey }) => {
        const packageProductsEffectiveDate = acc[productKey]
        const m = moment(effectiveDate)
        const parsedEffectiveDate = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m

        const shouldReplaceDate =
          !packageProductsEffectiveDate || parsedEffectiveDate.isBefore(packageProductsEffectiveDate)
        return shouldReplaceDate
          ? {
              ...acc,
              [productKey]: parsedEffectiveDate,
            }
          : { ...acc }
      }, {})

    // after getting the earliest date in any version of a product, get the latest date in list of products,
    // as this is the earlier date when all the products will live
    const maxEffectiveDate = moment.max(Object.values(packageProductsEffectiveDates))
    return maxEffectiveDate.isAfter(today) ? maxEffectiveDate : today
  }
)

export default {
  selectAllPackages,
  selectSelectedPackage,
  selectAvailableProducts,
  selectLoading,
  selectError,
  selectPackageName,
  selectPackageType,
  selectCountryOfResidence,
  selectBrandId,
  selectIsPackagePublish,
  selectPublishing,
  selectPublishSuccess,
  selectEarliestPackageLiveDate,
  selectPublishError,
}
