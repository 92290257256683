import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Grid, Typography } from '@material-ui/core'
import { useNavigate, useMatch } from 'react-router-dom'
import RefreshIcon from '@material-ui/icons/Refresh'
import { decodeUrl } from 'utils/url.utils'
import { Unavailable } from 'assets'
import { TENANT_URL_PREFIX } from 'utils/constants'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const DivEmptyLanding = styled.div`
  height: 440px;
`
const MainZeroContainer = styled(Grid)`
  height: 100%;
`

const CenterText = styled(Typography)`
  white-space: pre;
  text-align: center;
`

interface Props {
  from?: string
  hideButton?: boolean
  title?: string
  subtitle?: string
  buttonText?: string
  hideButtonIcon?: boolean
}

const UnavailablePage = ({ from, hideButton = false, title, subtitle, buttonText, hideButtonIcon = false }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const match = useMatch(`/${TENANT_URL_PREFIX}/:id/unavailable/*`)
  function MoveToProduct() {
    const fromFromMatch = match?.params['*']
    window.scrollTo(0, 0)
    navigate(`/${decodeUrl((from || fromFromMatch) as any)}` as any)
  }

  return (
    <DivEmptyLanding id="server_unavailable">
      <MainZeroContainer container direction="row" alignItems="center" justifyContent="center">
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <img src={Unavailable} alt="Server unavailable" />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
              <Grid item>
                <Typography variant="h1">{t(title || 'Unable to connect to the server')}</Typography>
              </Grid>
              <Grid item>
                <CenterText variant="body1">
                  {t(
                    subtitle || 'The connection to the server was reset while the page was loading. \nPlease try again'
                  )}
                </CenterText>
              </Grid>
            </Grid>
          </Grid>
          {!hideButton && (
            <Grid item>
              <Button
                variant="contained"
                color={ColorType.BUTTON}
                onClick={MoveToProduct}
                css="text-transform: uppercase"
                aria-controls="main"
              >
                {!hideButtonIcon && <RefreshIcon />}
                {t(buttonText || 'Try Again')}
              </Button>
            </Grid>
          )}
        </Grid>
      </MainZeroContainer>
    </DivEmptyLanding>
  )
}

export default UnavailablePage
