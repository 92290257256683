import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fontSizeTypes } from 'dls/shared/types'
import { CopiedIconButton, CopyIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface OwnProps {
  payload: string
  size?: fontSizeTypes
  extraButtonProps?: object
  style?: React.CSSProperties
}

const CopyButton = ({ payload, size = 'inherit', extraButtonProps = {}, style }: OwnProps) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)

  const onCopy = () => {
    navigator.clipboard.writeText(payload)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const label = t(!copied ? 'copy' : 'copied')
  const CopyIcon = copied ? CopiedIconButton : CopyIconButton

  return (
    <CopyIcon
      {...extraButtonProps}
      style={style}
      onClick={onCopy}
      ariaProps={{ 'aria-label': label }}
      fontSize={size}
    />
  )
}

export default CopyButton
