import React from 'react'
import { Dialog, DialogContent, Fade, Paper, DialogActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ChangeHistory, InfoOutlined } from '@material-ui/icons'
import styled from 'styled-components/macro'
import useModal from '../hooks/useModal'
import { StyledButton } from './styled'
import { ModalPendingAction } from 'store/modal/types'
import { Body2, H3 } from 'dls/atoms/Typography'
import { DeleteInfo } from 'assets'
import { Flexbox } from 'pages/Layout/Styled'
import { AccessibleLink } from 'components'
import { ColorType } from 'dls/shared/types'

interface Props {
  title?: string
  message?: string
  messageLink?: {
    label: string
    url: string
  }
  pendingAction?: ModalPendingAction
  onClose?: (open: boolean) => void
  confirmBtnLabel?: string
  confirmNavigationRoute?: string
}

const StyledInfoOutlined = styled(InfoOutlined)`
  color: ${(props: any) => props.theme.palette.success.main};
  font-size: 74px;
`

const StyledChangeHistory = styled(ChangeHistory)`
  margin-bottom: 24px;
  color: ${(props: any) => `${props.theme.palette.error.dark}`};
  font-size: 106px;
`

const PendingActionModal = ({
  title,
  message,
  messageLink,
  pendingAction,
  onClose,
  confirmBtnLabel,
  confirmNavigationRoute,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { closeModal, confirmModalAction, cancelModalAction } = useModal()

  const defineModalImage = () => {
    switch (pendingAction) {
      case ModalPendingAction.REMOVE:
        return <img src={DeleteInfo} alt={t('Delete Icon')} width="200px" />
      case ModalPendingAction.PRODUCT_NEW_CREATE:
        return <StyledInfoOutlined />
      case ModalPendingAction.CREATE:
        return <StyledChangeHistory />
      default:
        return null
    }
  }

  const onCancel = () => {
    if (confirmNavigationRoute) {
      closeModal()
    } else {
      cancelModalAction()
    }
  }

  const onConfirm = () => {
    if (confirmNavigationRoute) {
      closeModal()
      navigate(confirmNavigationRoute)
    } else {
      confirmModalAction()
    }
  }

  return (
    <Dialog
      open
      scroll="paper"
      onClose={() => onClose && onClose(false)}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
      id="confirmation-dialog"
    >
      <DialogContent>
        <Flexbox direction="column" justifyContent="center" alignItems="center" pt={40} pb={30}>
          {defineModalImage()}
          {title ? (
            <H3 data-test-id="confirmation-dialog-title" style={{ marginTop: 16, textAlign: 'center' }}>
              {t(title)}
            </H3>
          ) : null}
          <Body2 variantMapping={{ body2: 'div' }} style={{ marginTop: 8, textAlign: 'center' }}>
            {t(message || '')}{' '}
            {messageLink ? (
              <AccessibleLink to={messageLink.url} color="secondary" aria-label={t(messageLink.label)}>
                {messageLink.label}
              </AccessibleLink>
            ) : (
              ''
            )}
          </Body2>
        </Flexbox>
      </DialogContent>
      <DialogActions>
        <Flexbox direction="row" justifyContent="center" width="100%" pb={40}>
          <StyledButton
            disabled={false}
            onClick={onCancel}
            color={ColorType.BUTTON}
            variant="text"
            data-test-id="dialog-cancel-button"
          >
            {t('Cancel')}
          </StyledButton>
          <StyledButton
            disabled={false}
            onClick={onConfirm}
            color={ColorType.BUTTON}
            variant="contained"
            data-test-id="dialog-confirm-button"
          >
            {confirmBtnLabel || t('Confirm')}
          </StyledButton>
        </Flexbox>
      </DialogActions>
    </Dialog>
  )
}

export default PendingActionModal
