import { ExtendedProduct, TransactionRuleAccess, TransactionRules } from 'store/products/types'

export const hasTransactionRulesFeature = (product: ExtendedProduct): boolean => !!product && !!product.transactionRules

export const toTransactionRulesFormValues = (product: ExtendedProduct): TransactionRules =>
  hasTransactionRulesFeature(product)
    ? {
        ...product.transactionRules,
        inbound: {
          ...product.transactionRules?.inbound,
          defaultAccess: product.transactionRules?.inbound?.defaultAccess || TransactionRuleAccess.PERMIT,
        },
        outbound: {
          ...product.transactionRules?.outbound,
          defaultAccess: product.transactionRules?.outbound?.defaultAccess || TransactionRuleAccess.PERMIT,
        },
      }
    : ({
        inbound: { rules: [], defaultAccess: TransactionRuleAccess.PERMIT },
        outbound: { rules: [], defaultAccess: TransactionRuleAccess.PERMIT },
      } as TransactionRules)

export const featureTransactionRulesToPayload = (data: TransactionRules) =>
  // Remove empty values (ie, name)
  JSON.parse(JSON.stringify(data, (key, value) => (value === '' ? undefined : value)))
