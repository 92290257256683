import React from 'react'
import { useTranslation } from 'react-i18next'
import SidePanel from 'components/WidgetContainer/SidePanel'
import { WithholdingTaxInfoGraphic } from 'assets'
import { HelpModalType } from 'store/modal/types'

const WithholdingTaxRuleSidePanel = () => {
  const { t } = useTranslation()
  return (
    <SidePanel
      heading={t('MORE ABOUT WITHHOLDING TAX RULES')}
      image={WithholdingTaxInfoGraphic}
      imageAlt={t('Graphic showing a pie chart”')}
      details={t(
        'Here you can create and manage rules for when and how much tax should be withheld from customers credit interest.'
      )}
      linkToOpenModal={t('More about withholding tax')}
      infographicType={HelpModalType.PRODUCT_LIFECYCLE}
    />
  )
}

export default WithholdingTaxRuleSidePanel
