import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { find, kebabCase } from 'lodash'
import WithholdingTaxRuleSidePanel from '../../shared/WithholdingTaxRuleSidePanel'
import useTaxRule from '../../hooks/useTaxRule'
import { toTaxRuleFormValues } from '../../hooks/transformers'
import EditFutureRateForm from './EditTaxRuleForm'
import { Loader, TemplateFeatureForm, H1, Body2 } from 'components'
import { selectTenantCurrencyCode } from 'store/tenant-config/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

function EditTaxRulePage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { ruleId, taxRuleId } = useParams()

  const { selectedTaxRule, isLoading, getTaxRule, updateTaxRule } = useTaxRule()

  const currencyCode = useSelector(selectTenantCurrencyCode)

  const allRulesPageUrl = '/reference-data/withholding-tax'
  const selectedTaxRulePageUrl = `${allRulesPageUrl}/${selectedTaxRule?.ruleId}`

  const isValidRule = selectedTaxRule?.taxRules?.[0]?.withholdTax === true

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: '/reference-data/' },
      { title: t('Rules for withholding tax'), path: allRulesPageUrl },
      { title: `${selectedTaxRule?.name}`, path: selectedTaxRulePageUrl },
      { title: t('Edit future rate'), path: '' },
    ],
    trigger: !!selectedTaxRule,
  })

  useEffect(() => {
    if (ruleId) {
      getTaxRule(ruleId)
    }
  }, [ruleId])

  useEffect(() => {
    if (!isLoading && !!selectedTaxRule && !isValidRule) {
      navigate(allRulesPageUrl)
    }
  }, [isLoading, selectedTaxRule, isValidRule])

  if (isLoading || !selectedTaxRule || !isValidRule) {
    return <Loader />
  }

  const actualTaxRule = find(selectedTaxRule.taxRules, { taxRuleId })

  if (!actualTaxRule) {
    return null
  }

  return (
    <ContentContainer data-test-id={kebabCase(`Rule for withholding tax-form-container`)}>
      <TemplateFeatureForm
        header={
          <>
            <H1 gutterBottom>{t('Edit future rate')}</H1>
            <Body2 gutterBottom>{t('Here you can set a future rate for the tax rule')}</Body2>
          </>
        }
        main={
          <EditFutureRateForm
            currencyCode={currencyCode}
            selectedTaxRule={selectedTaxRule}
            initialValues={{
              ...toTaxRuleFormValues(actualTaxRule),
            }}
            onSubmit={(values) => updateTaxRule({ ruleId, taxRuleId, ...values }, 'edit')}
            onCancelClick={() => navigate(selectedTaxRulePageUrl)}
            aria-controls="main"
          />
        }
        aside={<WithholdingTaxRuleSidePanel />}
      />
    </ContentContainer>
  )
}

export default EditTaxRulePage
