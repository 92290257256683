import React from 'react'
import { ArrowDropDownCircle } from '@material-ui/icons'
import styled from 'styled-components/macro'

interface Props {
  indexRate: number
}

const StyledArrowDropDownCircle = styled(ArrowDropDownCircle)`
  transform: rotate(180deg);
  font-size: 14px;
  color: ${(props: any) => props.theme.palette.success.main};
`

const Styled1ArrowDropDownCircle = styled(ArrowDropDownCircle)`
  font-size: 14px;
  color: ${(props: any) => props.theme.palette.error.dark};
`

const IndexRateIcon = ({ indexRate = 0 }: Props): React.ReactElement => (
  <>{indexRate > 0 ? <StyledArrowDropDownCircle /> : <Styled1ArrowDropDownCircle />}</>
)

export default IndexRateIcon
