import React from 'react'
import { ChangeHistoryOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Typography, Divider } from '@material-ui/core'

interface OwnProps {
  maxSelectableTransactions: number
  colour: string
}

const OverMaxSelectedInfo = ({ maxSelectableTransactions, colour }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <div style={{ textAlign: 'center', marginTop: '30px' }}>
      <ChangeHistoryOutlined style={{ color: colour, fontSize: '60px' }} />
      <Typography variant="h3" variantMapping={{ h3: 'h2' }} css="display: contents;" color="error">
        {t('EXCEEDED_MAX_SELECTABLE')}
      </Typography>
      <Typography variant="body2" style={{ marginTop: '12px' }}>
        {t('EXCEEDED_MAX_SELECTABLE_DESC', { maxSelectableTransactions })}
        <br />
        {t('EXCEEDED_MAX_SELECTABLE_HINT')}
      </Typography>
      <Divider variant="middle" style={{ marginTop: '30px' }} />
    </div>
  )
}

export default OverMaxSelectedInfo
