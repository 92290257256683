import React from 'react'
import { compact } from 'lodash'
import { DefinitionTerm, DefinitionRow, Definition } from './styles'
import { ReviewRowItem } from './types'
import { Body2 } from 'dls/atoms/Typography'
import { Line } from 'dls/atoms'

const ReviewRow = ({ term, definitions, itemSeparator }: ReviewRowItem) => (
  <DefinitionRow data-dls="molecules-review-row">
    <DefinitionTerm>
      {typeof term === 'string' ? <Body2 variantMapping={{ body2: 'span' }}>{term}</Body2> : <>{term}</>}
    </DefinitionTerm>
    {compact(definitions).map((definitionItem, idx) => {
      const isItemString = typeof definitionItem === 'string'
      return (
        <Definition
          key={isItemString ? `review-row-${definitionItem}-${idx}` : `review-row--${definitions.length}-${idx}`}
        >
          {isItemString ? <Body2 variantMapping={{ body2: 'span' }}>{definitionItem}</Body2> : <>{definitionItem}</>}
        </Definition>
      )
    })}
    {itemSeparator && <Line type={itemSeparator} customStyles={{ width: '100%', marginTop: 12 }} aria-hidden />}
  </DefinitionRow>
)

export default ReviewRow
