import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, CardActionArea, CardContent, Grid } from '@material-ui/core'
import { kebabCase } from 'lodash'
import styled from 'styled-components/macro'
import navigationMenu from './pages'
import { FeatureFlag } from 'components/ControlComponents'
import HoverCard from 'components/CardWithShadow'
import ContentContainer from 'pages/Layout/ContentContainer'
import { TemplateHub } from 'components'
import { H1, H6, Body2 } from 'dls'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const HelpList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const HelpListLi = styled.li`
  margin: 0;
  padding: 0;
`
function HelpPage() {
  const { t } = useTranslation()
  const pages = navigationMenu()

  useBreadcrumbs({ breadcrumbs: [{ title: t('Help'), path: '' }], trigger: true })

  const pagesContent = Object.keys(pages)
    .filter((pageUrl) => pages[pageUrl].show)
    .map((pageUrl) => {
      const content = pages[pageUrl]
      const EntityBadge = content.icon
      return (
        <FeatureFlag name={content.featureFlag} key={pageUrl}>
          <Grid item md={4} sm={6} xs={12} component={HelpListLi}>
            <HoverCard data-test-id={kebabCase(`help-card-${content.title.props.children}`)}>
              <CardActionArea
                disableRipple
                component={Link}
                to={`/help/${pageUrl}`}
                aria-controls="main"
                role="link"
                style={{ minHeight: '200px' }}
              >
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                    <EntityBadge />
                    <Box mb={1} mt={3}>
                      <H6 align="center" variantMapping={{ h6: 'h2' }}>
                        {content.title}
                      </H6>
                    </Box>
                    <Body2 align="center">{content.description}</Body2>
                  </Box>
                </CardContent>
              </CardActionArea>
            </HoverCard>
          </Grid>
        </FeatureFlag>
      )
    })

  return (
    <ContentContainer data-test-id="help-page-home">
      <TemplateHub
        main={
          <Grid container spacing={3} alignContent="center" alignItems="stretch" component={HelpList}>
            {pagesContent}
          </Grid>
        }
        header={<H1 align="center">{t('How can we help you?')}</H1>}
      />
    </ContentContainer>
  )
}

export default HelpPage
