import React from 'react'
import ProductCoreForm from './ProductCoreForm'
import { CreateProductPayload } from 'store/products/types'

interface Props {
  onSubmit: (product: CreateProductPayload) => void
  onPressCancel: () => void
  isNewCustomProduct: boolean
}

const CreateProductForm = (Props: Props) => {
  const { onSubmit, onPressCancel } = Props
  const initialValues = {
    productName: '',
    productDescription: '',
    productTaxonomy: '',
  }

  return <ProductCoreForm onPressCancel={onPressCancel} initialValues={initialValues} onSubmit={onSubmit} />
}

export default CreateProductForm
