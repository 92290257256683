import React from 'react'
import { Card } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Variant } from '@material-ui/core/styles/createTypography'
import styled from 'styled-components/macro'
import { Flexbox } from '../Styled'
import { Accordion } from 'dls'
import { H3, Body1 } from 'components'

const StyledCard = styled(Card)<{ disableCardBorder: boolean }>`
  ${({ disableCardBorder }) => disableCardBorder && 'box-shadow: none'}
`

type Props = React.PropsWithChildren<{
  headline: string
  title: React.ReactElement
  titleVariant?: Variant
  headerIcon: React.ReactElement
  headerAction?: React.ReactElement
  fixedBottomContent?: React.ReactElement
  accordionDisabled?: boolean
  scrollEnabled?: boolean
  disableCardBorder?: boolean
}>

const EntitySectionCard = ({
  children,
  headline,
  title,
  headerIcon,
  headerAction,
  fixedBottomContent,
  accordionDisabled,
  scrollEnabled,
  disableCardBorder,
}: Props): React.ReactElement => {
  const { t } = useTranslation()

  const renderSectionCard = (content: React.ReactElement) => (
    <Flexbox direction="column" pb={24}>
      <H3 variantMapping={{ h3: 'h2' }} style={{ paddingBottom: 24 }}>
        {t(headline)}
      </H3>
      <Flexbox direction="column">{content}</Flexbox>
    </Flexbox>
  )

  const HeaderGroup = () => (
    <Flexbox direction="row" width="100%" p={24}>
      {headerIcon}
      <Body1
        variantMapping={{ body1: 'div' }}
        style={{ paddingLeft: 24, flexGrow: 2, verticalAlign: 'middle', lineHeight: 'inherit' }}
      >
        {title}
      </Body1>
      <Flexbox
        direction="column"
        justifyContent="center"
        style={{ position: 'relative', right: accordionDisabled ? 0 : -24 }}
      >
        {headerAction}
      </Flexbox>
    </Flexbox>
  )

  const ContentGroup = () => (
    <Flexbox direction="column" width="100%">
      <Flexbox
        direction="column"
        width="100%"
        style={
          !accordionDisabled
            ? {
                maxHeight: scrollEnabled ? 300 : 'auto',
                overflowY: scrollEnabled ? 'scroll' : 'visible',
              }
            : {}
        }
      >
        {children}
      </Flexbox>
      {fixedBottomContent}
    </Flexbox>
  )

  const WithAccordion = () => (
    <Accordion
      title={title}
      headerIcon={headerIcon}
      headerButtonGroup={headerAction}
      disableCardBorder={!!disableCardBorder}
      defaultExpanded
    >
      <ContentGroup />
    </Accordion>
  )

  return accordionDisabled
    ? renderSectionCard(
        <StyledCard disableCardBorder={!!disableCardBorder}>
          <HeaderGroup />
          <ContentGroup />
        </StyledCard>
      )
    : renderSectionCard(<WithAccordion />)
}

export default EntitySectionCard
