import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components/macro'
import { Divider } from '@material-ui/core'

export const TableCellBase = styled(TableCell)<{ narrowPadding?: boolean }>`
  border-bottom: none;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding: ${({ narrowPadding }) => (narrowPadding ? `5px 16px 0px 10px` : `16px 16px 0px 10px`)};
`

export const StyledTableCell1 = styled(TableCellBase)`
  width: 30%;
`

export const StyledTableCell2 = styled(TableCellBase)`
  width: 67%;
`
export const StyledTableCell4 = styled(TableCellBase)`
  width: 100%;
`
export const StyledTableSmallCell2 = styled(TableCellBase)`
  width: 30%;
`

export const StyledTableCell3 = styled(TableCellBase)`
  width: 35%;
`

export const SectionDivider = styled(Divider)`
  margin-top: 16px;
  height: 2px;
`

export const StyledDivider = styled(Divider)`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 16px;
  margin-bottom: 0px;
`

export const DarkDivider = styled(Divider)`
  height: 2px;
  margin-top: 13px;
  margin-bottom: 28px;
  background-color: ${(props: any) => `${props.theme.palette.text.disabled}`};
`

export const BlackDivider = styled(Divider)`
  height: 1px;
  margin: 12px;
  background-color: ${(props: any) => `${props.theme.palette.text.primary}`};
`
