import { Dialog, Fade } from '@material-ui/core'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { RootState } from '../../store'
import PublishPackageForm from './PublishPackageForm'
import PackagesSelectors from 'store/packages/selectors'
import PackagesActions from 'store/packages/actions'
import { PackagesWithProducts, PublishPackageFormValues } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  selectedPackage: PackagesWithProducts
}

const mapDispatchToProps = {
  publishPackage: PackagesActions.publishPackage,
}

const mapStateToProps = (state: RootState) => ({
  minEffectiveDate: PackagesSelectors.selectEarliestPackageLiveDate(state),
})

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & OwnProps

const PublishPackage = ({ publishPackage, selectedPackage, minEffectiveDate }: Props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const onPublish = ({ publicationDate }: PublishPackageFormValues) => {
    setOpen(false)
    publishPackage({
      productPackageId: selectedPackage.productPackageId,
      publicationDate: publicationDate.isBefore(moment()) ? publicationDate.add(1, 'm') : publicationDate,
    })
  }
  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        data-test-id="create-package-button"
        color={ColorType.BUTTON}
        disabled={selectedPackage.packageStatus === PackagesStatus.LIVE}
      >
        {t('Publish')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Fade} disableEnforceFocus fullWidth>
        <PublishPackageForm
          initialValues={{
            publicationDate: moment().isSameOrAfter(minEffectiveDate, 'minute')
              ? moment().add(1, 'minutes').startOf('minute')
              : minEffectiveDate,
          }}
          onSubmit={onPublish}
          setOpen={setOpen}
          minEffectiveDate={minEffectiveDate}
        />
      </Dialog>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishPackage)
