import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { MenuItem } from 'dls/atoms/MenuItem'
import { TemplateForm, InfoIconButton, Paper, Input, Select, MCheckbox, InfoPanel, ContextualHelp } from 'dls'
import { H2, EntityBadgeProductSyndication } from 'dls/atoms'
import { internalMdPadding } from 'dls/shared/styleVar'
import { AvatarSize } from 'dls/shared/types'
import { StyledFieldContainer } from 'dls/shared/styled'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'
import { CopyComponent } from 'dls/molecules/Copy'
import { ProductSyndicationFormValues, ProductSyndicationCategory } from 'store/products/typings/productSyndication'
import { applicationURL } from 'utils/common.util'

interface Props {
  defaultValues: ProductSyndicationFormValues
}
const SyndicationForm = (props: Props) => {
  const { defaultValues } = props
  const { showInfographicModal } = useModal()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    control,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        productName: Yup.string().required(t('Enter a name')),
        productDescription: Yup.string().required(t('Enter a description')),
        productCategory: Yup.string().required(t('Please select the category')),
        brand: Yup.string().required(t('Enter the brand')),
        applicationUri: Yup.string().matches(
          applicationURL,
          t('Enter a valid URI including URL/Path which is will start with http:// or https://')
        ),
      })
    ),
    mode: 'onBlur',
    defaultValues,
  })

  return (
    <>
      <TemplateForm
        main={
          <form>
            <section aria-labelledby="about-product-syndication" style={{ marginBottom: internalMdPadding }}>
              <Paper elevation={0} responsivePadding>
                <H2 gutter id="about-product-syndication">
                  {t('About the product')}
                </H2>
                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item xs={12}>
                    <StyledFieldContainer>
                      <Controller
                        name="productName"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            id="productName"
                            label={t('Name')}
                            placeholder={t('Product name')}
                            required
                            fullWidth
                            error={fieldState.invalid}
                            errorText={fieldState.error?.message}
                            {...field}
                            aria-labelledby="productName-helper"
                          />
                        )}
                      />
                      <ContextualHelp
                        title={<>{t('Enter the name of the product as it should appear to customers')}</>}
                        id="productName-helper"
                      />
                    </StyledFieldContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFieldContainer>
                      <Controller
                        name="productDescription"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            id="productDescription"
                            label={t('Description')}
                            placeholder={t('Description')}
                            required
                            fullWidth
                            error={fieldState.invalid}
                            errorText={fieldState.error?.message}
                            {...field}
                            aria-labelledby="productDescription-helper"
                          />
                        )}
                      />
                      <ContextualHelp
                        title={<>{t('Describe the product as it should appear to customers')}</>}
                        id="productDescription-helper"
                      />
                    </StyledFieldContainer>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={11} sm={9} md={9} lg={9}>
                      <StyledFieldContainer>
                        <Input
                          id="productId"
                          name="productId"
                          label={t('Product ID')}
                          disabled
                          fullWidth
                          required
                          value={defaultValues?.productKey}
                          aria-labelledby="productId-helper"
                        />
                        <ContextualHelp
                          title={<>{t('The product ID is taken from the associated product and cannot be changed')}</>}
                          id="productId-helper"
                        />
                      </StyledFieldContainer>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} lg={2}>
                      <CopyComponent
                        onClickTitle="Copied"
                        payload={defaultValues?.productKey || ''}
                        tabIndex="0"
                        title="Copy"
                        style={{ marginLeft: -10 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <StyledFieldContainer>
                        <Controller
                          name="productCategory"
                          control={control}
                          render={({ field }) => (
                            <Box css={{ flexGrow: 2 }}>
                              <Select
                                id="productCategory"
                                label={t('Category')}
                                {...field}
                                required
                                fullWidth
                                placeholder={t('Please select')}
                                displayEmpty
                                disabled
                                style={{ marginBottom: 0 }}
                                aria-labelledby="productCategory-helper"
                              >
                                {Object.keys(ProductSyndicationCategory).map((option) => (
                                  <MenuItem key={option} value={option}>
                                    <Box display="flex" alignItems="center" ml={1} mr={2}>
                                      {t(`PRODUCT_CATEGORY.${option}`)}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          )}
                        />
                        <ContextualHelp
                          title={<>{t('The category is preset for you based on the associated products details')}</>}
                          id="productCategory-helper"
                        />
                      </StyledFieldContainer>
                    </Grid>
                  </Grid>
                  <Grid item style={{ paddingBottom: 0 }}>
                    <StyledFieldContainer>
                      <Controller
                        name="isTailored"
                        control={control}
                        render={({ field }) => (
                          <MCheckbox
                            id="isTailored"
                            checked={!!field.value}
                            label={t('Tailored to the customer')}
                            {...field}
                            aria-labelledby="isTailored-helper"
                          />
                        )}
                      />
                      <ContextualHelp
                        style={{ marginLeft: 0 }}
                        title={
                          <>
                            {t(
                              'This indicates whether the product is specifically tailored to a circumstance. If true fees and prices are significantly negotiated depending on context. While all products are open to a degree of tailoring this flag indicates that tailoring is expected and thus that the provision of specific fees and rates is not applicable'
                            )}
                          </>
                        }
                        id="isTailored-helper"
                      />
                    </StyledFieldContainer>
                  </Grid>
                </Grid>
              </Paper>
            </section>
            <section aria-labelledby="product-syndication-branding" style={{ marginBottom: internalMdPadding }}>
              <Paper elevation={0} responsivePadding>
                <H2 gutter id="product-syndication-branding">
                  {t('Brand & marketing')}
                </H2>
                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <StyledFieldContainer>
                        <Controller
                          name="brand"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Input
                              id="brand"
                              label={t('Brand')}
                              placeholder={t('Brand name')}
                              required
                              fullWidth
                              error={fieldState.invalid}
                              errorText={fieldState.error?.message}
                              {...field}
                              aria-labelledby="brand-helper"
                            />
                          )}
                        />
                        <ContextualHelp
                          title={<>{t('This is the brand of the associated products organisation e.g. Coca Cola')}</>}
                          id="brand-helper"
                        />
                      </StyledFieldContainer>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <StyledFieldContainer>
                        <Controller
                          name="brandName"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Input
                              id="brandName"
                              label={t('Brand name (Optional)')}
                              placeholder={t('Enter brand name')}
                              fullWidth
                              error={fieldState.invalid}
                              errorText={fieldState.error?.message}
                              {...field}
                              aria-labelledby="brandName-helper"
                            />
                          )}
                        />
                        <ContextualHelp
                          title={
                            <>
                              {t(
                                'This is the brand name of the associated product which could be different from the  brand e.g Coca Cola and Coke Zero being the brand name'
                              )}
                            </>
                          }
                          id="brandName-helper"
                        />
                      </StyledFieldContainer>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFieldContainer>
                      <Controller
                        name="applicationUri"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            id="applicationUri"
                            label={t('Website URL (where customers can apply - optional)')}
                            placeholder={t('E.g. http://www.')}
                            fullWidth
                            error={fieldState.invalid}
                            errorText={fieldState.error?.message}
                            {...field}
                            aria-labelledby="applicationUri-helper"
                          />
                        )}
                      />
                      <ContextualHelp
                        title={
                          <>
                            {t(
                              'The full path to the web page where a customer can view and apply for the associated product'
                            )}
                          </>
                        }
                        id="applicationUri-helper"
                      />
                    </StyledFieldContainer>
                  </Grid>
                </Grid>
              </Paper>
            </section>
            <section aria-labelledby="product-syndication-dates">
              <Paper elevation={0} responsivePadding>
                <H2 gutter id="product-syndication-dates">
                  {t('Dates')}
                </H2>
                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item sm={12} md={12} lg={12}>
                    <InfoPanel
                      body={
                        <>
                          {t(
                            'You’ll be able to add an effective date and optional end date when the syndication is published'
                          )}
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </section>
          </form>
        }
        title={t(`Details about ${defaultValues?.productName}`)}
        description={t(
          'Complete all mandatory fields. You can use the clipboard icon to paste field values from your products configuration and you can use the top right ‘quick view’ tab to quickly check your product configuration.'
        )}
        toolbarButtons={
          <Grid container direction="row" justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <InfoIconButton
                ariaProps={{
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                  'aria-label': `${t('More about Product syndication')}`,
                }}
                onClick={() => showInfographicModal(HelpModalType.PRODUCT_SYNDICATION)}
              />
            </Grid>
          </Grid>
        }
        icon={<EntityBadgeProductSyndication size={AvatarSize.LARGE} />}
        headerProps={{ 'aria-label': t(`Details about ${defaultValues?.productName}`) }}
        submitButtonDisabled={!isValid || !isDirty}
        onCancel={() => navigate(`/products/${defaultValues.productKey}`)}
      />
    </>
  )
}

export default SyndicationForm
