/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import { InputLabelProps, Grid } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import styled from 'styled-components/macro'
import Chip from '@material-ui/core/Chip'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'
import EnhancedFormikField, { EnhancedFormikFieldProps, getFormFieldCalculatedProps } from './EnhancedFormikField'
import { Flag } from 'components'

export interface AutocompleteSelectFieldDataItem {
  key: string | number
  label?: string
  flag?: string
  header?: boolean
}

export interface AutocompleteSelectFieldData {
  [key: string]: AutocompleteSelectFieldDataItem | string
}

export type AutoCompleteSelectFieldItem = AutocompleteSelectFieldDataItem | string

export interface AutoCompleteSelectFieldComplexProps<T> {
  required?: boolean
  selectProps?: Omit<AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>, 'value'>
  data: AutocompleteSelectFieldData
  helperText?: React.ReactNode
  inputLabelProps?: InputLabelProps
  placeholder?: string
  hasFlag?: boolean
  multiple?: boolean
  selectAll?: boolean
  selectAllLabel?: string
  chipLabel?: (item: AutoCompleteSelectFieldItem) => React.ReactNode
}

const FlagContainer = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: text-bottom;
  width: auto;
  height: auto;
`

const ChipContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`

type Props = FieldProps &
  AutoCompleteSelectFieldComplexProps<AutoCompleteSelectFieldItem> &
  Partial<EnhancedFormikFieldProps>

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const selectAllKey = '--selectall--'

const FormikMultiSelectAutocomplete = (props: Props) => {
  const {
    selectProps,
    data,
    field,
    form,
    helperText,
    inputLabelProps,
    placeholder,
    label,
    required,
    hasFlag = false,
    multiple = false,
    chipLabel,
    selectAll = false,
    selectAllLabel,
    ...otherProps
  } = props

  const { t } = useTranslation()
  const { hasError, isDisabled } = getFormFieldCalculatedProps(props)

  const [options, setOptions] = React.useState<AutoCompleteSelectFieldItem[] | undefined>(undefined)

  useEffect(() => {
    if (!options && data) {
      const selectedData: AutoCompleteSelectFieldItem[] = Object.entries(data).map(([key, item]) =>
        typeof item === 'string' ? item : key
      )
      if (!multiple && !required) {
        selectedData.unshift('')
      }
      if (multiple && selectAll) {
        selectedData.unshift(selectAllKey)
      }
      setOptions(selectedData)
    }
  }, [data, options, multiple, required, selectAll])

  const getOptionLabel = (item: any) =>
    item === ''
      ? t('Please select')
      : item === selectAllKey
      ? `${selectAllLabel || t('Select all')}`
      : data[item]
      ? `${(data[item] as AutocompleteSelectFieldDataItem).label}`
      : item
  const getOptionSelected = (option: string, value: string) => option === value
  const onChange = (e: any, value: string) => {
    form.setFieldTouched(field.name, true, true)
    if (!!value && isArray(value) && value.includes(selectAllKey)) {
      if (field.value && field.value.length === Object.entries(data).length) {
        form.setFieldValue(field.name, [], true)
      } else {
        const selectedItems = Object.entries(data).map(([key, item]) => (typeof item === 'string' ? item : key))
        form.setFieldValue(field.name, selectedItems, true)
      }
    } else {
      form.setFieldValue(field.name, value, true)
    }
  }

  const ownRenderOption = (item: any, { selected }: { selected: any }) => (
    <>
      {multiple && (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={
            item === selectAllKey && field.value && field.value.length === Object.entries(data).length ? true : selected
          }
        />
      )}
      {hasFlag && data[item] ? (
        <FlagContainer>
          <Flag code={(data[item] as AutocompleteSelectFieldDataItem).flag as string} size={20} />
        </FlagContainer>
      ) : (
        ''
      )}
      {getOptionLabel(item)}
    </>
  )

  const renderTags = (value: any[], getTagProps: any) => (
    <ChipContainer>
      {(value || []).map((item: any, index: number) => (
        <Chip
          {...getTagProps({ index })}
          key={index}
          disabled={isDisabled}
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ flexWrap: 'nowrap' }}>
              {hasFlag && data[item] ? (
                <Grid item>
                  <FlagContainer>
                    <Flag code={(data[item] as AutocompleteSelectFieldDataItem).flag as string} size={20} />
                  </FlagContainer>
                </Grid>
              ) : undefined}
              <Grid item>
                {chipLabel ? chipLabel(data[item] as AutoCompleteSelectFieldItem) : getOptionLabel(item)}
              </Grid>
            </Grid>
          }
        />
      ))}
    </ChipContainer>
  )

  return (
    <EnhancedFormikField
      {...otherProps}
      label={undefined}
      field={field}
      form={form}
      helperText={helperText}
      inputLabelProps={inputLabelProps}
    >
      {() => (
        <Autocomplete
          fullWidth
          disableClearable
          {...(otherProps as any)}
          {...(selectProps as any)}
          multiple={multiple}
          options={(options as any) || []}
          disabled={isDisabled}
          value={field.value || []}
          getOptionSelected={getOptionSelected}
          renderTags={renderTags}
          getOptionLabel={getOptionLabel}
          renderOption={selectProps && selectProps.renderOption ? selectProps.renderOption : ownRenderOption}
          onChange={onChange as any}
          renderInput={(params) => (
            <TextField
              {...params}
              // variant="standard"
              disabled={isDisabled}
              error={hasError}
              label={`${label}${label && required ? ' *' : ''}`}
              placeholder={placeholder || t('Select or start typing')}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  !multiple && hasFlag && data[field.value] ? (
                    <FlagContainer>
                      <Flag code={(data[field.value] as AutocompleteSelectFieldDataItem).flag as string} size={20} />
                    </FlagContainer>
                  ) : (
                    params.InputProps.startAdornment
                  ),
              }}
            />
          )}
        />
      )}
    </EnhancedFormikField>
  )
}

export default FormikMultiSelectAutocomplete
