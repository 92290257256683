import React from 'react'
import { Dialog, DialogContent, Fade, Typography, Paper, Grid, DialogActions } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { InfoOutlined } from '@material-ui/icons'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  open: boolean
  setOpen?: (open: boolean) => void
}

const Title = styled(Typography)`
  margin-bottom: 24px;
`

const Description = styled(Typography)`
  margin-bottom: 16px;
`
const StyledButton = styled(Button)`
  width: 150px;
  margin-right: 24px;
  margin-bottom: 24px;
`

const StyledUL = styled.ul`
  text-align: left;
  font-size: 14px;
  & > li {
    margin-bottom: 8px;
  }
`
const GrindContainer = styled(Typography)`
  text-align: center;
  padding-top: 48px;
  padding-bottom: 0px;
`

const StyledInfoOutlined = styled(InfoOutlined)`
  color: ${(props: any) => props.theme.palette.success.main};
  font-size: 74px;
`

const TaskRejectConfirmation = (props: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={props.open}
      scroll="paper"
      onClose={() => props.setOpen && props.setOpen(false)}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogContent>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <GrindContainer variantMapping={{ body1: 'div' }}>
            <StyledInfoOutlined />
            <Title variant="h5">{t('Thanks for your review and comments')}</Title>
            <Description variant="body2">{t('Here’s what happens next')}</Description>
            <StyledUL>
              <li>
                {t('The product will not be published until all your comments have been addressed and changes made')}
              </li>
              <li>
                {t(
                  'The product may be re-submitted for approval so that you can check that your comments have been addressed'
                )}
              </li>
              <li>{t('If you have any queries you should contact the product owner')}</li>
            </StyledUL>
          </GrindContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <StyledButton
          disabled={false}
          onClick={() => props.setOpen && props.setOpen(false)}
          color={ColorType.BUTTON}
          variant="contained"
        >
          {t('Ok, got it')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
export default TaskRejectConfirmation
