import Typography from '@material-ui/core/Typography'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
import ProductKeyRow from './ProductKeyRow'
import { ContextualHelp } from 'components'
import { Product, ProductGroup, ProductLine } from 'store/products/types'
import { InfoPanel, ReviewRows } from 'dls'

interface OwnProps {
  product: Product
}

const TooltipStyle = styled.aside`
  margin-left: 4px;
`

const StyledWrapper = styled.div`
  width: 100%;
`
export const getTaxonomyLabel = (product: Product | null, t: TFunction): string => {
  if (!product) {
    return ''
  }

  const { productSegment, productGroup, productLine } = product
  return `${t(productSegment)}: ${t(productGroup)}: ${t(productLine)}`
}

const ViewProductCore = (props: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const { product } = props
  const { productName, productKey, productDescription, productLine, productGroup, productSegment } = product

  const productDefinitions = [
    productName && {
      term: t('Name'),
      definitions: [productName],
    },
    productKey && {
      term: t('Product key'),
      definitions: [<ProductKeyRow key={productKey} productKey={productKey} />],
    },
    productSegment &&
      productGroup &&
      productLine && {
        term: t('Type'),
        definitions: [
          <Box key="type" display="flex">
            {getTaxonomyLabel(product, t)}
            <TooltipStyle>
              <ContextualHelp
                title={t(`Type is constructed from the API fields; Segment, Product group and Product line`)}
              />
            </TooltipStyle>
          </Box>,
        ],
      },
    productDescription && {
      term: t('Description'),
      definitions: [productDescription],
    },
  ].filter((item) => item)

  const prodDetails = [
    {
      definitions: productDefinitions,
    },
  ]

  return (
    <StyledWrapper>
      {/* // @TODO: Find a better way to detect a invalid product, maybe checking productMatrix? */}
      {((productGroup &&
        !productGroup.includes(ProductGroup.CURRENT) &&
        !productGroup.includes(ProductGroup.LOANS) &&
        !productGroup.includes(ProductGroup.MORTGAGES) &&
        !productGroup.includes(ProductGroup.SAVINGS)) ||
        (productLine &&
          !productLine.includes(ProductLine.DEPOSITS) &&
          !productLine.includes(ProductLine.LENDING) &&
          !productLine.includes(ProductLine.ACCOUNTS))) && (
        <Grid container xs={12}>
          <aside
            css={`
              padding: 0 10px;
            `}
          >
            <InfoPanel
              color="error"
              title={t('Configuration not recognised ')}
              body={
                <>
                  <Typography variant="body2">
                    {t(
                      `This product configuration has been created outside of Bank Manager and we can’t verify it’s support on the platform. Please check the configuration below.`
                    )}
                  </Typography>
                </>
              }
            />
          </aside>
        </Grid>
      )}
      <ReviewRows definitionsList={prodDetails as any} />
    </StyledWrapper>
  )
}

export default ViewProductCore
