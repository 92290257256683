import React from 'react'
import { Grid } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { ContextualHelp, SelectFieldComplex } from 'components'
import {
  ApplicationFrequency,
  WeeklyFrequencyItems,
  MonthlyFrequencyItems,
  YearlyFrequencyItems,
  CalendarDay,
  CalendarMonth,
} from 'store/products/types'
import { FrequencyFormValues } from 'store/products/typings/frequencySelector'
import { calculateDaysInMonth } from 'utils/date.utils'

interface Props {
  disabled?: boolean
  disableTooltip?: boolean
  frequencyFieldLabel?: string
}

const ISOFrequencySelector = ({ disabled, disableTooltip, frequencyFieldLabel }: Props) => {
  const { t } = useTranslation()
  const { values, setValues } = useFormikContext<FrequencyFormValues>()

  const onFieldChange = (e: string): void => {
    switch (true) {
      case Object.values(ApplicationFrequency).includes(e as ApplicationFrequency):
        setValues({
          ...values,
          frequency: e as ApplicationFrequency,
          weeklyOccurence: undefined,
          monthlyOccurence: undefined,
          monthlySpecificDay: undefined,
          annualOccurence: undefined,
          annualSpecificMonth: undefined,
          annualSpecificDay: undefined,
        })
        break
      case Object.values(MonthlyFrequencyItems).includes(e as MonthlyFrequencyItems):
        setValues({
          ...values,
          monthlyOccurence: e as MonthlyFrequencyItems,
          monthlySpecificDay: undefined,
        })
        break
      case Object.values(YearlyFrequencyItems).includes(e as YearlyFrequencyItems):
        setValues({
          ...values,
          annualOccurence: e as YearlyFrequencyItems,
          annualSpecificMonth: undefined,
          annualSpecificDay: undefined,
        })
        break
      case Object.values(CalendarMonth).includes(e as CalendarMonth):
        setValues({
          ...values,
          annualSpecificMonth: e as CalendarMonth,
          annualSpecificDay: undefined,
        })
        break
      default:
        break
    }
  }

  const renderWeeklyField = (): JSX.Element => (
    <Grid item xs={4}>
      <Field
        name="weeklyOccurence"
        fullWidth
        label={t('Day')}
        placeholder={t('Please select')}
        data={WeeklyFrequencyItems}
        component={SelectFieldComplex}
        selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
        inputLabelProps={{ shrink: true }}
        required
        disabled={disabled}
      />
    </Grid>
  )

  const renderMonthlyFields = (): JSX.Element => {
    const SecondarySelectItemsWithLabels = {
      [MonthlyFrequencyItems.BEGINNING]: {
        name: 'BEGINNING',
        label: 'MONTH.BEGINNING',
      },
      [MonthlyFrequencyItems.END]: {
        name: 'END',
        label: 'MONTH.END',
      },
      [MonthlyFrequencyItems.ANNIVERSARY]: {
        name: 'ANNIVERSARY',
        label: 'MONTH.ANNIVERSARY',
      },
      [MonthlyFrequencyItems.SPECIFIC_DAY]: 'SPECIFIC_DAY',
    }

    return (
      <>
        <Grid item xs={4}>
          <Field
            name="monthlyOccurence"
            fullWidth
            label={t('Occurrence')}
            placeholder={t('Please select')}
            data={SecondarySelectItemsWithLabels}
            component={SelectFieldComplex}
            selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
            inputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
            onChange={onFieldChange}
          />
        </Grid>
        {values.monthlyOccurence === MonthlyFrequencyItems.SPECIFIC_DAY ? (
          <Grid item xs={4}>
            <Field
              name="monthlySpecificDay"
              fullWidth
              label={t('Choose day')}
              placeholder={t('Please select')}
              data={CalendarDay}
              translationPrefix="CALENDAR.MONTHLY_GENERIC"
              component={SelectFieldComplex}
              selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
              inputLabelProps={{ shrink: true }}
              required
              disabled={disabled}
            />
          </Grid>
        ) : null}
      </>
    )
  }

  const renderAnnualFields = (): JSX.Element => (
    <>
      <Grid item xs={4}>
        <Field
          name="annualOccurence"
          fullWidth
          label={t('Occurrence')}
          placeholder={t('Please select')}
          data={YearlyFrequencyItems}
          component={SelectFieldComplex}
          onChange={onFieldChange}
          selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
          inputLabelProps={{ shrink: true }}
          required
          disabled={disabled}
        />
      </Grid>
      {values.annualOccurence === YearlyFrequencyItems.SPECIFIC_DATE ? (
        <>
          <Grid item xs={4}>
            <Field
              name="annualSpecificMonth"
              fullWidth
              label={t('Choose month')}
              placeholder={t('Please select')}
              data={CalendarMonth}
              translationPrefix="CALENDAR"
              component={SelectFieldComplex}
              onChange={onFieldChange}
              selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
              inputLabelProps={{ shrink: true }}
              required
              disabled={disabled}
            />
          </Grid>
          {values.annualSpecificMonth && (
            <Grid item xs={4}>
              <Field
                name="annualSpecificDay"
                fullWidth
                label={t('Choose day')}
                placeholder={t('Please select')}
                data={calculateDaysInMonth(parseInt(values.annualSpecificMonth, 10))}
                translationPrefix="CALENDAR"
                component={SelectFieldComplex}
                selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
                inputLabelProps={{ shrink: true }}
                required
                disabled={disabled}
              />
            </Grid>
          )}
        </>
      ) : null}
    </>
  )

  const renderFrequencyTypeField = (): JSX.Element => {
    const field = (
      <>
        <Grid item xs={4}>
          <Field
            name="frequency"
            fullWidth
            label={t(frequencyFieldLabel || 'Accrual period ends')}
            placeholder={t('Please select')}
            data={ApplicationFrequency}
            translationPrefix="VIEW_FREQUENCY"
            component={SelectFieldComplex}
            onChange={onFieldChange}
            selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
            inputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
          />
        </Grid>
        {!disableTooltip && (
          <Grid item xs={2} style={{ paddingLeft: '10px' }}>
            <ContextualHelp
              placement="top"
              title={<>{t('VIEW_FREQUENCY.ACCRUAL_PERIOD_HELP')}</>}
              style={{ marginTop: 16 }}
            />
          </Grid>
        )}
      </>
    )

    if (disableTooltip) {
      return field
    }

    return (
      <Grid container item direction="row" xs={12} spacing={1}>
        {field}
      </Grid>
    )
  }

  const renderTopLevelFrequencyField = (): JSX.Element | null => {
    switch (values.frequency) {
      case ApplicationFrequency.DAILY:
        return null
      case ApplicationFrequency.WEEKLY:
        return renderWeeklyField()
      case ApplicationFrequency.MONTHLY:
        return renderMonthlyFields()
      case ApplicationFrequency.ANNUALLY:
        return renderAnnualFields()
      default:
        return null
    }
  }

  return (
    <Grid container spacing={2}>
      {renderFrequencyTypeField()}
      {renderTopLevelFrequencyField()}
    </Grid>
  )
}

export default ISOFrequencySelector
