import React from 'react'
import { Grid } from '@material-ui/core'
import { FastField, Field, Form, FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { InfoOutlined } from '@material-ui/icons'
import TiersBandsList from '../../shared/TiersBandsList'
import { effectiveDateText } from '../../hooks/utils'
import { ContentPaperForStickyBottomBar } from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import {
  rateYupValidation,
  stopPropagation,
  validationForTierBandStartRangeEndRangeTax,
  withFormikSimple,
  Yip,
} from 'utils/form.utils'
import { DateTimePickerComponent, FieldPercentage, FormBottomBar, RadioGroupFieldComplex } from 'components'
import { RateTierBand } from 'store/products/types'
import { WithholdingTaxRule, WithholdingRule, WithholdingTaxRuleFormValues } from 'store/withholdingTax/types'
import { toBankTz } from 'utils/date.utils'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { InfoPanel, H2, Body2 } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  onCancelClick?: () => void
  currencyCode?: string | null
  withholdingRule: WithholdingRule
}

function AddFutureRuleForm(props: Props & FormikProps<WithholdingTaxRule>) {
  const { t } = useTranslation()

  const selectTierBandCalcMethod = props.values.tierBandCalcMethod

  const { showInfographicModal } = useModal()

  const effectiveDates =
    !!props.withholdingRule && !!props.withholdingRule.taxRules
      ? props.withholdingRule.taxRules.map((i) => i.effectiveDate)
      : []
  return (
    <Form>
      <ContentPaperForStickyBottomBar elevation={1} style={{ padding: 24 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InfoPanel
              title={t('Make sure the effective date is correct – you can’t change it later')}
              body={
                <Body2>{t(`The rate can be changed later provided that the effective date is in the future`)}</Body2>
              }
            />
          </Grid>

          {props.withholdingRule?.name && (
            <Grid item xs={12}>
              <H2>{props.withholdingRule.name}</H2>
            </Grid>
          )}

          <Grid item xs={12}>
            <Body2>
              {t('There are 3 options for how withheld tax will be calculated:', { nsSeparator: '||' })}{' '}
              <LinkWithText
                onClick={(e: React.MouseEvent) => {
                  stopPropagation(e)
                  showInfographicModal(HelpModalType.INTEREST_RATES)
                }}
                aria-haspopup="dialog"
                aria-controls="more-about-dialog"
                endIcon={<InfoOutlined />}
              >
                {t('see how these work')}
              </LinkWithText>
            </Body2>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item>
                <Field
                  name="tierBandCalcMethod"
                  component={RadioGroupFieldComplex}
                  onChange={(value: any) => {
                    if (value === RateTierBand.FLAT) {
                      props.setFieldValue('taxTierBand', [], true)
                      props.setFieldTouched('taxTierBand', true, true)
                      props.setFieldValue('flatRate', null)
                      props.setFieldTouched('flatRate', false, true)
                    } else {
                      props.setFieldValue('taxTierBand', [{ startRange: 0 }], true)
                      props.setFieldTouched('taxTierBand', true, true)
                      props.setFieldValue('flatRate', null, true)
                      props.setFieldTouched('flatRate', true, true)
                    }
                  }}
                  data={RateTierBand}
                  required
                  validate={Yip(Yup.string().required())}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {selectTierBandCalcMethod === RateTierBand.FLAT && (
              <Grid item xs={12} style={{ marginTop: 40 }}>
                <Field
                  name="flatRate"
                  label={t('Tax rate is')}
                  required
                  placeholder={t('Please enter')}
                  validate={Yip(rateYupValidation(2, 1000).required())}
                  component={FieldPercentage}
                  InputProps={{ style: { width: 220 } }}
                  numberFormatProps={{ decimalScale: 2 }}
                />
              </Grid>
            )}
            {[RateTierBand.TIER, RateTierBand.BAND].includes(selectTierBandCalcMethod as RateTierBand) && (
              <TiersBandsList name="taxTierBand" currencyCode={props.currencyCode} />
            )}
          </Grid>
          {selectTierBandCalcMethod && (
            <Grid item xs={4} style={{ paddingRight: 35 }}>
              <FastField
                placeholder={t('Select date')}
                name="effectiveDate"
                type="text"
                clearable
                variant="inline"
                touchedNotNeeded
                component={DateTimePickerComponent}
                label={`${'Effective date'} (${toBankTz(getIn(props.values, `effectiveDate`)).tz})`}
                InputLabelProps={{ style: { width: 300 } }}
                dateTimePickerProps={{
                  shouldDisableDate: (date: moment.Moment) =>
                    date && (!moment().isSameOrBefore(date) || effectiveDates.includes(date.format('YYYY-MM-DD'))),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormBottomBar inlineNavigation disabled={!props.dirty} hideSubmit>
              <Button
                variant="text"
                color={ColorType.BUTTON}
                onClick={props.onCancelClick}
                style={{ marginRight: '12px' }}
                aria-controls="main"
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color={ColorType.BUTTON}
                type="submit"
                disabled={!props.isValid || props.isValidating}
              >
                {t('Submit')}
              </Button>
            </FormBottomBar>
          </Grid>
        </Grid>
      </ContentPaperForStickyBottomBar>
    </Form>
  )
}

const RuleSchema = Yup.object().shape({
  taxTierBand: Yup.array(
    validationForTierBandStartRangeEndRangeTax('This value must be greater than the same field in the preceding row')
  ).nullable(),
  effectiveDate: Yup.string().test(
    'startRange-endRange-check',
    'Effective date is today + 1 or further into future',
    effectiveDateText
  ),
})

export default withFormikSimple<Props, WithholdingTaxRuleFormValues>(AddFutureRuleForm as any, {
  validationSchema: RuleSchema,
  isInitialValid: () => false,
})
