import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import styled from 'styled-components/macro'
import { Checkbox } from '@material-ui/core'
import { TableCommonHeaderProps } from './types'
import { stopPropagation } from 'utils/form.utils'

const StyledTableCell = styled(TableCell)<{ $isFirstRow?: boolean; $disableSelectAll?: boolean }>`
  width: 40%;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: baseline !important;
  ${({ $isFirstRow, $disableSelectAll }) => $isFirstRow && $disableSelectAll && `padding-left: 0;`}
`

const TableHeader = (props: TableCommonHeaderProps) => {
  const {
    orderAscDesc,
    orderByKey,
    onRequestSort,
    isSelectableRow,
    numSelecableRows,
    onClickSelectAll,
    isDisabled,
    numSelected,
    fullCellSelectable,
    disableSelectAll,
  } = props

  const onClickSelectAllCaller = (e: React.MouseEvent<HTMLElement>) => {
    stopPropagation(e)
    onClickSelectAll(numSelected !== numSelecableRows)
  }

  return (
    <TableHead>
      <TableRow>
        {isSelectableRow && !disableSelectAll && (
          <StyledTableCell
            padding="checkbox"
            css="width: 45px;"
            {...(fullCellSelectable && fullCellSelectable.includes(-1)
              ? {
                  onClick: onClickSelectAllCaller,
                }
              : {})}
          >
            {numSelecableRows > 0 && (
              <Checkbox
                checked={numSelected === numSelecableRows}
                onClick={onClickSelectAllCaller}
                disabled={isDisabled}
                inputProps={{ 'aria-label': 'select all' }}
              />
            )}
          </StyledTableCell>
        )}
        {props.headRows &&
          props.headRows.map(({ id, align, css, label, disableOrder = false }, index) =>
            disableOrder ? (
              <StyledTableCell
                $isFirstRow={index === 0}
                $disableSelectAll={disableSelectAll}
                key={id}
                align={align}
                css={css}
                {...(fullCellSelectable && fullCellSelectable.includes(index)
                  ? { onClick: () => onClickSelectAll(numSelected !== numSelecableRows) }
                  : {})}
              >
                {label}
              </StyledTableCell>
            ) : (
              <StyledTableCell
                $isFirstRow={index === 0}
                colSpan={disableSelectAll ? 2 : 1}
                key={id}
                align={align}
                sortDirection={orderByKey === id ? orderAscDesc : false}
                css={css}
              >
                <TableSortLabel active={orderByKey === id} direction={orderAscDesc} onClick={() => onRequestSort(id)}>
                  {label}
                </TableSortLabel>
              </StyledTableCell>
            )
          )}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
