import React from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConditionValuesList from './ConditionValuesList'
import { BuilderCondition, ConditionOperator } from 'store/products/typings/conditionBuilder'
import { Body2 } from 'dls/atoms/Typography'

const ConditionContent = ({ condition, isFirst }: { condition: BuilderCondition; isFirst: boolean }): JSX.Element => {
  const { t } = useTranslation()
  const { dataPoint, dataPointFirstOperator, dataPointSecondOperator, operator, values } = condition

  const renderOperatorText = (): string => {
    if (isFirst && (!operator || operator === ConditionOperator.AND)) {
      // API issue, empty conditionsOperator in API response
      return ConditionOperator.IF
    }

    return operator
  }

  const renderConditionText = (): string => {
    const name = `${dataPoint.name}`

    const firstOperator = dataPointFirstOperator
      ? `${t(`CONDITION_BUILDER.${dataPointFirstOperator}`)?.toLocaleLowerCase()}`
      : ''

    const secondOperator = dataPointSecondOperator
      ? `${t(`CONDITION_BUILDER.${dataPointSecondOperator}`)?.toLocaleLowerCase()}`
      : ''

    const semicolon = values.length > 1 ? ':' : ' '

    return `${name} ${firstOperator} ${secondOperator}${semicolon}`
  }
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" height="100%">
      <div>
        <Body2 variantMapping={{ body2: 'span' }} css="display: inline; font-weight: bold;">
          {`${t(renderOperatorText())} `}
        </Body2>
        <Body2 variantMapping={{ body2: 'span' }} css="display: inline;">
          {renderConditionText()}
        </Body2>
        <ConditionValuesList
          dataPoint={dataPoint}
          values={values}
          operator={dataPointSecondOperator || dataPointFirstOperator}
        />
      </div>
    </Box>
  )
}

export default ConditionContent
