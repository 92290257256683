import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { groupBy } from 'lodash'
import CreatePackageForm from './CreatePackageForm'
import PackageSidebar from './PackageSidebar'
import FormPage from 'components/FormPage/FormPage'
import PackageActions from 'store/packages/actions'
import PackageSelectors from 'store/packages/selectors'
import {
  AvailableProducts,
  PackageCreationOrUpdateRequestPayload,
  PackagesWithProductsFormValue,
} from 'store/packages/types'
import { RootState } from 'store'
import { Loader } from 'components/'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'

export const StyledConversationGrid = styled(Grid)`
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: 16px;
  }
`

const mapStateToProps = (state: RootState) => ({
  availableProducts: PackageSelectors.selectAvailableProducts(state),
  packageName: PackageSelectors.selectPackageName(state),
  getPackageType: PackageSelectors.selectPackageType(state),
  getCountryOfResidence: PackageSelectors.selectCountryOfResidence(state),
  getBrandId: PackageSelectors.selectBrandId(state),

  loading: PackageSelectors.selectLoading(state),
})
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createPackage: (packageFormValues: PackageCreationOrUpdateRequestPayload) =>
    dispatch(PackageActions.createPackage({ ...packageFormValues })),
  getAvailableProducts: () => dispatch(PackageActions.availableProducts()),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const CreatePackagePage = ({
  createPackage,
  getAvailableProducts,
  packageName,
  getPackageType,
  getCountryOfResidence,
  getBrandId,
  availableProducts,
  loading,
}: Props): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  let initialValues: Partial<PackagesWithProductsFormValue> = {}
  if (getPackageType && packageName) {
    initialValues = {
      productPackageName: packageName,
      distribution: {
        packageType: getPackageType,
        countryOfResidence: getCountryOfResidence,
        brandId: getBrandId,
      },
    }
  }

  useEffect(() => {
    if (!packageName) {
      navigate(`/packages`)
    }
  }, [packageName])

  useEffect(() => {
    getAvailableProducts()
  }, [getAvailableProducts])

  const availableGroupedProducts: AvailableProducts[] = Object.entries(groupBy(availableProducts, 'productKey')).map(
    ([, value]) => ({
      ...value[0],
      productName: value.length > 1 ? `${value[0].productName} (${t('latest name')})` : value[0].productName,
      versions: value,
    })
  )

  if (loading) {
    return <Loader />
  }
  return (
    <FormPage
      Sidebar={PackageSidebar}
      title={t('Products in this package')}
      subheading={t(
        'Here you can choose the products that you want to included in the package. Scheduled products will only be available in the package after the effective date and time has been reached. '
      )}
      breadcrumbs={[
        { title: t('{{tenantName}} packages', { tenantName: currentTenant?.name }), path: `packages/` },
        { title: packageName, path: '' },
      ]}
      containerTestId="package-page"
      formTestId="package-form"
    >
      <CreatePackageForm
        availableProducts={availableGroupedProducts || []}
        onSubmit={createPackage}
        initialValues={initialValues}
      />
    </FormPage>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePackagePage)
