import { UserSearchActions, ActionTypes, FilterAction, SearchAction } from './actions'
import getFullName from 'pages/Users/utils/getFullName'
import { Colleague, UserFilters, UserRoles } from 'store/user/types'

interface UserSearchState {
  allUsers: Colleague[]
  searchResults: Colleague[]
  paginatedResults: Colleague[]
  searchTerm: string
  selectedFilter: number
  page: number
  filters: UserFilters
}

export const INITIAL_STATE: UserSearchState = {
  allUsers: [],
  searchResults: [],
  paginatedResults: [],
  searchTerm: '',
  selectedFilter: 0,
  filters: {
    userRole: UserRoles.ALL_ROLES,
  },
  page: 0,
}

const searchQueryFilterPredicate = (searchQuery: string) => (user: Colleague) =>
  `${getFullName(user)}`.toLowerCase().includes(searchQuery)

const filterQueryPredicate = (userRole: number) => (user: Colleague) => user.userRole === UserRoles[userRole]

const filterByUserRole = (state: UserSearchState, action: FilterAction) => {
  const { allUsers, searchTerm } = state

  if (action.payload.userRole === UserRoles.ALL_ROLES) {
    if (searchTerm !== '') {
      return {
        ...state,
        filters: {
          ...state.filters,
          userRole: action.payload.userRole,
        },
        searchResults: allUsers.filter(searchQueryFilterPredicate(searchTerm)),
      }
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        userRole: action.payload.userRole,
      },
      searchResults: [...allUsers],
    }
  }
  if (searchTerm !== '') {
    return {
      ...state,
      filters: {
        ...state.filters,
        userRole: action.payload.userRole,
      },
      searchResults: allUsers
        .filter(searchQueryFilterPredicate(searchTerm))
        .filter(filterQueryPredicate(action.payload.userRole)),
    }
  }
  return {
    ...state,
    filters: {
      ...state.filters,
      userRole: action.payload.userRole,
    },
    searchResults: allUsers.filter(filterQueryPredicate(action.payload.userRole)),
  }
}

const filterBySearchQuery = (state: UserSearchState, action: SearchAction) => {
  const {
    allUsers,
    filters: { userRole },
  } = state

  if (userRole === UserRoles.ALL_ROLES) {
    if (action.payload !== '') {
      return {
        ...state,
        searchTerm: action.payload,
        searchResults: allUsers.filter(searchQueryFilterPredicate(action.payload)),
      }
    }
    return {
      ...state,
      searchTerm: action.payload,
      searchResults: [...allUsers],
    }
  }
  if (action.payload !== '') {
    return {
      ...state,
      searchTerm: action.payload,
      searchResults: allUsers.filter(filterQueryPredicate(userRole)).filter(searchQueryFilterPredicate(action.payload)),
    }
  }
  return {
    ...state,
    searchTerm: action.payload,
    searchResults: allUsers.filter(filterQueryPredicate(userRole)),
  }
}

const userReducer = (state: UserSearchState, action: UserSearchActions) => {
  switch (action.type) {
    case ActionTypes.SEARCH_QUERY:
      return filterBySearchQuery(state, action)
    case ActionTypes.FILTER_QUERY:
      return filterByUserRole(state, action)
    case ActionTypes.LOAD_MORE:
      return state
    case ActionTypes.RESET_SEARCH:
      return state
    default:
      return state
  }
}

export default userReducer
