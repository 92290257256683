import { createAction } from '@reduxjs/toolkit'
import {
  SuspenseAccounts,
  SubscriptionKey,
  SuspenseTransactionsResponse,
  SuspenseAccountResponse,
  SuspenseTransactionsResult,
  GetSuspenseTransactionsRequest,
  TransactionTransferRequest,
  updateParentId,
  ReconcileTransferRequest,
  WriteOffAndWriteBackTransactionTransferRequest,
  ReverseWriteOffAndWriteBackTransactionTransferRequest,
} from './types'
import { ShouldRedirectOnFail } from 'store/utils/types'

const actions = {
  fetchSuspenseAccounts: createAction<ShouldRedirectOnFail | undefined>('SUSPENSEACCOUNT/FETCH'),
  fetchSuspenseAccountsSuccess: createAction<SuspenseAccounts>('SUSPENSEACCOUNT/FETCH/SUCCESS'),
  fetchSuspenseAccountsFail: createAction<Error>('SUSPENSEACCOUNT/FETCH/FAIL'),

  getSuspenseTransactions: createAction<GetSuspenseTransactionsRequest>('SUSPENSE/TRANSACTIONS/GET'),
  getSuspenseTransactionsLoadMore: createAction<GetSuspenseTransactionsRequest>('SUSPENSE/TRANSACTIONSMORE/GET'),
  getSuspenseTransactionsClear: createAction('SUSPENSE/TRANSACTIONS/CLEAR'),
  getSuspenseTransactionsSuccess: createAction<SuspenseTransactionsResponse>('SUSPENSE/TRANSACTIONS/GET/SUCCESS'),
  getSuspenseTransactionsSuccessLoadMore: createAction<SuspenseTransactionsResponse>(
    'SUSPENSE/TRANSACTIONSMORE/GET/SUCCESS'
  ),
  getSuspenseTransactionsFail: createAction<Error>('SUSPENSE/TRANSACTIONS/GET/FAIL'),

  getSuspenseAccount: createAction<SubscriptionKey>('SUSPENSEACCOUNT/ACCOUNT/GET'),
  getSuspenseAccountSuccess: createAction<SuspenseAccountResponse>('SUSPENSEACCOUNT/ACCOUNT/GET/SUCCESS'),
  getSuspenseAccountFail: createAction<Error>('SUSPENSEACCOUNT/ACCOUNT/GET/FAIL'),

  getSuspenseTransactionsForStat: createAction<SubscriptionKey>('SUSPENSE/TRANSACTIONSFORSTAT/GET'),
  getSuspenseTransactionsForStatSuccess: createAction<SuspenseTransactionsResult>(
    'SUSPENSE/TRANSACTIONSFORSTAT/GET/SUCCESS'
  ),
  getSuspenseTransactionsForStatFail: createAction<SuspenseTransactionsResult>('SUSPENSE/TRANSACTIONSFORSTAT/GET/FAIL'),

  confirmTransaction: createAction<TransactionTransferRequest>('CONFIRM/TRANSACTIONS'),
  confirmTransactionSuccess: createAction('CONFIRM/TRANSACTIONS/SUCCESS'),
  confirmTransactionFail: createAction<Error>('CONFIRM/TRANSACTIONS/FAIL'),

  confirmReconcileTransaction: createAction<updateParentId>('CONFIRM/RECONCILE_TRANSACTIONS'),
  confirmReconcileTransactionSuccess: createAction<ReconcileTransferRequest>('CONFIRM/RECONCILE_TRANSACTIONS/SUCCESS'),
  confirmReconcileTransactionFail: createAction<Error>('CONFIRM/RECONCILE_TRANSACTIONS/FAIL'),

  confirmWriteOffAndWriteBackTransaction: createAction<WriteOffAndWriteBackTransactionTransferRequest>(
    'CONFIRM/WRITEOFFANDWRITEBACK/TRANSACTIONS'
  ),
  confirmWriteOffAndWriteBackTransactionSuccess: createAction('CONFIRM/WRITEOFFANDWRITEBACK/TRANSACTIONS/SUCCESS'),
  confirmWriteOffAndWriteBackTransactionFail: createAction<Error>('CONFIRM/WRITEOFFANDWRITEBACK/TRANSACTIONS/FAIL'),

  confirmNostroWashTransaction: createAction<WriteOffAndWriteBackTransactionTransferRequest>(
    'CONFIRM/NOSTROWASH/TRANSACTIONS'
  ),
  confirmNostroWashTransactionSuccess: createAction('CONFIRM/NOSTROWASH/TRANSACTIONS/SUCCESS'),
  confirmNostroWashTransactionFail: createAction<Error>('CONFIRM/NOSTROWASH/TRANSACTIONS/FAIL'),

  reverseWriteOffAndWriteBackTransaction: createAction<ReverseWriteOffAndWriteBackTransactionTransferRequest>(
    'CONFIRM/REVERSE_WRITE_OFF_WRITE_BACK//TRANSACTIONS'
  ),

  reverseWriteOffAndWriteBackTransactionSuccess: createAction(
    'CONFIRM/REVERSE_WRITE_OFF_WRITE_BACK//TRANSACTIONS/SUCCESS'
  ),
  reverseWriteOffAndWriteBackTransactionFail: createAction<Error>(
    'CONFIRM/REVERSE_WRITE_OFF_WRITE_BACK//TRANSACTIONS/FAIL'
  ),

  confirmTransactionResultClear: createAction('CONFIRM/TRANSACTIONS/RESULTCLEAR'),
}

export default actions
