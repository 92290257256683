import React, { useEffect, useState, useReducer, useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import UserTable from '../components/UserTable'
import { ZeroStateLandingPage, CatalogHeader } from 'components'
import { NoUsersFound } from 'assets'
import { Colleague } from 'store/user/types'
import ContentContainer from 'pages/Layout/ContentContainer'
import userReducer, { INITIAL_STATE } from 'pages/Users/Users/reducer'
import { ActionTypes } from 'pages/Users/Users/reducer/actions'
import UserManagementDialog from 'pages/Users/components/UserManagementDialog'

interface Props {
  users?: Colleague[]
}

const Users = ({ users }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')

  const initialState = useMemo(() => {
    if (users) {
      return {
        ...INITIAL_STATE,
        allUsers: [...users],
        searchResults: [...users],
      }
    }

    return INITIAL_STATE
  }, [users?.length])

  const [searchState, dispatchSearch] = useReducer(userReducer, initialState)
  const { searchResults, filters } = searchState

  useEffect(() => {
    const timerId = setTimeout(() => {
      dispatchSearch({ type: ActionTypes.SEARCH_QUERY, payload: searchText })
    }, 500)

    return () => clearTimeout(timerId)
  }, [searchText])

  return (
    <ContentContainer>
      <CatalogHeader
        title={t('Users')}
        info={t('Showing {{start}} - {{end}} of {{limit}} users', {
          start: searchResults.length ? 1 : 0,
          end: searchResults.length,
          limit: searchResults.length,
        })}
        icons={[<UserManagementDialog key="user-management-icon" />]}
        search={(text) => setSearchText(text.toLowerCase())}
        isSearch
        value={searchText}
        searchFieldAriaLabel={t('Search users')}
        searchFieldAriaControls="table-list-section"
        searchButtonAriaLabel={t('Search')}
      />

      {!isEmpty(users) ? (
        <Grid xs={12} item>
          <UserTable users={searchResults} searchText={searchText} setFilters={dispatchSearch} filters={filters} />
        </Grid>
      ) : (
        <ZeroStateLandingPage image={NoUsersFound} title={t('There are no users')} />
      )}
    </ContentContainer>
  )
}

export default Users
