import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Grid, Typography } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { AccessDenied } from 'assets'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const DivEmptyLanding = styled.div`
  height: 440px;
`
const MainZeroContainer = styled(Grid)`
  height: 100%;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  function MoveToProduct() {
    navigate(`/`)
  }
  const getEntityName = (): string => {
    if (`${location.pathname}`.includes('dashboard')) {
      return 'Dashboard'
    }
    if (`${location.pathname}`.includes('products')) {
      return 'Products'
    }
    if (`${location.pathname}`.includes('packages')) {
      return 'Packages'
    }
    if (`${location.pathname}`.includes('reference')) {
      return 'Reference data'
    }
    if (`${location.pathname}`.includes('governance')) {
      return 'Governance'
    }
    if (`${location.pathname}`.includes('accounts')) {
      return 'Accounts'
    }
    if (`${location.pathname}`.includes('templates')) {
      return 'Templates'
    }
    return t('this page')
  }

  return (
    <DivEmptyLanding id="main_not_found">
      <MainZeroContainer container direction="row" alignItems="center" justifyContent="center">
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <img src={AccessDenied} alt="Sorry, access has been denied" />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
              <Grid item>
                <Typography variant="h6">{t('Sorry, access has been denied')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ maxWidth: 667, textAlign: 'center', paddingLeft: 12 }}>
                  {t(
                    'You don’t have permission to view {{entityName}}, permissions are controlled in your organisation’s access management system',
                    { entityName: getEntityName(), interpolation: { escapeValue: false } }
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={ColorType.BUTTON}
              onClick={MoveToProduct}
              css="text-transform: uppercase"
              aria-controls="main"
              data-test-id="ok-got-it-button"
            >
              {t('Ok, got it')}
            </Button>
          </Grid>
        </Grid>
      </MainZeroContainer>
    </DivEmptyLanding>
  )
}

export default NotFound
