import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import CreditInterestForm from './CreditInterestForm'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Feature } from 'store/products/types'
import useInterestRates from 'pages/features/CreditInterest/hooks/useInterestRates'

const CreditInterestFeaturePage = () => {
  const { t } = useTranslation()
  const { productKey = '' } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)
  const interestRates = useInterestRates(productKey, product)
  const {
    isNotAllowed,
    setCreditInterest,
    initialValues,
    bankInterestRates,
    enableWithholdingTaxRule,
    isReferenceDataEnabled,
    selectTaxRules,
  } = interestRates

  useUpdateBreadcrumbs({ productKey, context: Feature.CREDITINTEREST })
  const navigate = useNavigate()

  if (isNotAllowed) navigate(`./../not-allowed`)

  if (isLoading) {
    return <Loader />
  }

  return product && !isEmpty(interestRates) ? (
    <CreditInterestForm
      t={t}
      productKey={productKey}
      product={product}
      initialValues={initialValues as any}
      onSubmit={setCreditInterest as any}
      bankInterestRates={bankInterestRates}
      enableWithholdingTaxRule={enableWithholdingTaxRule}
      isReferenceDataEnabled={isReferenceDataEnabled as any}
      selectTaxRules={selectTaxRules as any}
    />
  ) : null
}
export default CreditInterestFeaturePage
