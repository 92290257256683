import axios from 'axios'
import { TenantConfig } from 'store/tenant-config/types'

async function getTenantConfig() {
  const { data } = await axios.get<TenantConfig>(
    process.env.NODE_ENV === 'development' ? `config/tenantConfig.dev.json` : `config/tenantConfig.json`
  )
  return data
}

export default { getTenantConfig }
