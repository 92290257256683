/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { SuspenseTransactionItem } from 'store/accounts/types'

interface ExpandableProps {
  unSettledTransaction?: SuspenseTransactionItem[]
  content: string
  error?: boolean
  onClick: any
}

export const DataGrid = styled(Grid)`
  cursor: pointer;
  padding: 4px;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `};
`

const StyledSpan = styled.span`
  font-weight: 600;
`

export const ExpandableDetails = ({
  unSettledTransaction,
  content,
  error,
  onClick,
}: ExpandableProps): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <DataGrid
      container
      spacing={0}
      direction="column"
      justifyContent="flex-end"
      alignItems="flex-start"
      wrap="nowrap"
      onClick={onClick}
      aria-controls="main"
    >
      {unSettledTransaction && unSettledTransaction.filter((item) => !item.isSettled).length >= 100 ? (
        <Grid item>
          <Typography
            variant="h3"
            variantMapping={{ h3: 'div' }}
            css="font-weight:300;font-size: 3rem; margin-bottom:12px;"
            color="secondary"
          >
            100+
          </Typography>
        </Grid>
      ) : unSettledTransaction && unSettledTransaction.filter((item) => !item.isSettled).length === 0 ? (
        <Grid item>
          <Typography
            variant="h3"
            variantMapping={{ h3: 'div' }}
            color="initial"
            css="font-weight:300;font-size: 3rem; margin-bottom:12px;"
          >
            0
          </Typography>
        </Grid>
      ) : (
        <Grid item>
          <Typography
            variant="h3"
            variantMapping={{ h3: 'div' }}
            color={
              unSettledTransaction && unSettledTransaction.filter((item) => !item.isSettled).length > 0
                ? 'secondary'
                : 'inherit'
            }
            css="font-weight:300;font-size: 3rem; margin-bottom:12px;"
          >
            {unSettledTransaction && unSettledTransaction.filter((item) => !item.isSettled).length}
          </Typography>
        </Grid>
      )}

      <Grid item>
        <Typography
          variant="body1"
          css="font-weight:300"
          variantMapping={{ body1: 'div' }}
          color={
            unSettledTransaction && unSettledTransaction.filter((item) => !item.isSettled).length > 0 && error
              ? 'error'
              : 'inherit'
          }
        >
          {error && unSettledTransaction && unSettledTransaction.filter((item) => !item.isSettled).length > 0 ? (
            <StyledSpan>{t('Overdue')}</StyledSpan>
          ) : error && unSettledTransaction && unSettledTransaction.filter((item) => !item.isSettled).length === 0 ? (
            <span>{t('Overdue')}</span>
          ) : null}
          {t(content)}
        </Typography>
      </Grid>
    </DataGrid>
  )
}

export default ExpandableDetails
