import React from 'react'
import styled from 'styled-components/macro'
import { DataPickerRow } from 'dls/shared/types'
import { stopPropagation } from 'utils/form.utils'
import { Caption, ACheckbox } from 'dls/'
import { SubdirectoryArrowRightIcon } from 'dls/atoms/Icons/SVGIcons'
import { slug } from 'utils/common.util'

const StyledCaption = styled(Caption)`
  font-size: 14px;
  color: ${(props: any) => props.theme.palette.text.secondary};
`
const StyledTitle = styled.span<{ bold: boolean }>`
  ${({ bold }) => bold && `font-weight: bold`}
`

const StyledLabel = styled.label`
  display: block;
  width: 100%;
  padding: 8px 0 8px 20px;
  border-bottom: 1px solid ${(props: any) => `${props.theme.palette.background.medium}`};
`

interface OwnProps {
  dataPickerRow: DataPickerRow
  isLeaf: boolean
  index: number
  handleClick: (path: string) => void
  path: string
  depth: number
}

export const ListItem = ({ dataPickerRow, index, isLeaf, handleClick, path = '', depth }: OwnProps) => {
  const location = path ? `${path}.${index}` : `${index}`

  return (
    <StyledLabel
      css={depth && { paddingLeft: depth * 60 }}
      key={`${dataPickerRow.code}-${path}-${depth || '0'}-label-${index}-row-label`}
    >
      {!!depth && (
        <SubdirectoryArrowRightIcon
          className="icon"
          css={{ marginTop: 7, marginRight: 2, float: 'left' }}
          aria-hidden
        />
      )}
      <ACheckbox
        {...(isLeaf && { className: 'isLeaf' })}
        name={`${dataPickerRow.code}-${path}-${depth || '0'}-checkbox-${index}`}
        data-location={location}
        onClick={(e: React.MouseEvent): void => {
          stopPropagation(e)
          handleClick(location)
        }}
        aria-checked={!!dataPickerRow.checked}
        checked={!!dataPickerRow.checked}
        value={dataPickerRow.code}
        id={slug(dataPickerRow.description)}
      />
      {!!dataPickerRow.checked}
      <StyledTitle bold={depth === 0}>{dataPickerRow.description}</StyledTitle>{' '}
      <StyledCaption>{`(${dataPickerRow.code})`}</StyledCaption>
    </StyledLabel>
  )
}
