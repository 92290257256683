import { useDispatch, useSelector } from 'react-redux'
import Actions from 'store/productSyndications/actions'
import Selectors from 'store/productSyndications/selectors'

const useTenantProductSyndication = () => {
  const dispatch = useDispatch()
  const fetchTenantProductSyndication = () => {
    dispatch(Actions.getProductSyndications())
  }
  const syndicationProducts = useSelector(Selectors.selectProductSyndications)
  const syndicationProductsLoading = useSelector(Selectors.selectLoading)

  return {
    fetchTenantProductSyndication,
    syndicationProducts,
    syndicationProductsLoading,
  }
}

export default useTenantProductSyndication
