import { call, put, takeLatest, takeEvery, delay } from 'redux-saga/effects'
import i18next from 'i18next'
import SuspenseAccountsActions from './actions'
import { SuspenseTransactionsResult, updateParentId } from './types'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import ModalActions from 'store/modal/actions'
import { navigateToUnavailable } from 'utils/url.utils'
import { showPendingActionModalSaga } from 'store/modal/sagas'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { AccountsApi } from 'api'
import NotificationActions from 'store/notifications/actions'

function* getSuspenseAccounts(action: ReturnType<typeof SuspenseAccountsActions.fetchSuspenseAccounts>) {
  try {
    const response: ReturnTypePromise<typeof AccountsApi.fetchSuspenseAccounts> = yield call(
      AccountsApi.fetchSuspenseAccounts
    )
    yield put(SuspenseAccountsActions.fetchSuspenseAccountsSuccess(response))
  } catch (e: any) {
    yield put(SuspenseAccountsActions.fetchSuspenseAccountsFail(e))
    if (action?.payload?.shouldRedirect ?? true) {
      yield call(navigateToUnavailable)
    }
  }
}

function* getSuspenseAccount(action: ReturnType<typeof SuspenseAccountsActions.getSuspenseAccount>) {
  try {
    const response: ReturnTypePromise<typeof AccountsApi.getSuspenseAccount> = yield call(
      AccountsApi.getSuspenseAccount,
      action.payload
    )
    yield put(SuspenseAccountsActions.getSuspenseAccountSuccess(response))
  } catch (e: any) {
    yield put(SuspenseAccountsActions.getSuspenseAccountFail(e))
    yield call(navigateToUnavailable)
  }
}

function* getSuspenseTransactions(action: ReturnType<typeof SuspenseAccountsActions.getSuspenseTransactions>) {
  try {
    const response: ReturnTypePromise<typeof AccountsApi.getSuspenseTransactions> = yield call(
      AccountsApi.getSuspenseTransactions,
      action.payload
    )
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(SuspenseAccountsActions.getSuspenseTransactionsSuccess(response))
  } catch (e: any) {
    yield put(SuspenseAccountsActions.getSuspenseTransactionsFail(e))
    yield put(NotificationActions.getSuspenseTransactionsFail(e))
  }
}

function* getSuspenseTransactionsLoadMore(action: ReturnType<typeof SuspenseAccountsActions.getSuspenseTransactions>) {
  try {
    const response: ReturnTypePromise<typeof AccountsApi.getSuspenseTransactions> = yield call(
      AccountsApi.getSuspenseTransactions,
      action.payload
    )
    yield put(SuspenseAccountsActions.getSuspenseTransactionsSuccessLoadMore(response))
  } catch (e: any) {
    yield put(SuspenseAccountsActions.getSuspenseTransactionsFail(e))
    yield put(NotificationActions.getSuspenseTransactionsFail(e))
  }
}

function* getSuspenseTransactionsForStat(
  action: ReturnType<typeof SuspenseAccountsActions.getSuspenseTransactionsForStat>
) {
  const finalPayload: SuspenseTransactionsResult = {
    key: action.payload,
  }
  try {
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    const responseFor90Days: ReturnTypePromise<typeof AccountsApi.getSuspenseTransactionsFor90Days> = yield call(
      AccountsApi.getSuspenseTransactionsFor90Days,
      action.payload
    )
    const responseFor60Days: ReturnTypePromise<typeof AccountsApi.getSuspenseTransactionsFor60Days> = yield call(
      AccountsApi.getSuspenseTransactionsFor60Days,
      action.payload
    )
    const responseFor30Days: ReturnTypePromise<typeof AccountsApi.getSuspenseTransactionsFor30Days> = yield call(
      AccountsApi.getSuspenseTransactionsFor30Days,
      action.payload
    )
    if (responseFor90Days && responseFor60Days && responseFor30Days) {
      finalPayload.data = {
        selectedSuspenseTransactionsFor90Days: responseFor90Days,
        selectedSuspenseTransactionsFor60Days: responseFor60Days,
        selectedSuspenseTransactionsFor30Days: responseFor30Days,
        isLoading: false,
      }
      yield put(SuspenseAccountsActions.getSuspenseTransactionsForStatSuccess(finalPayload))
    }
  } catch (e: any) {
    finalPayload.data = {
      selectedSuspenseTransactionsFor90Days: undefined,
      selectedSuspenseTransactionsFor60Days: undefined,
      selectedSuspenseTransactionsFor30Days: undefined,
      isLoading: false,
      error: e,
    }
    yield put(SuspenseAccountsActions.getSuspenseTransactionsForStatFail(finalPayload))
  }
}

function* confirmReconcileTransactions(action: ReturnType<typeof SuspenseAccountsActions.confirmReconcileTransaction>) {
  try {
    const payload = action.payload as updateParentId

    const t = i18next.t.bind(i18next)

    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: t('Are you sure?'),
      message: t(
        `You’re adding a parent transaction ID to an existing ${payload.SuspenseTransactionItem?.transactionStatus} ${payload.SuspenseTransactionItem?.subscriptionName} transaction for ${payload.SuspenseTransactionItem?.amount.amount} ${payload.SuspenseTransactionItem?.amount.currency}.`
      ),
      pendingAction: ModalPendingAction.PRODUCT_NEW_CREATE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())
      yield call(AccountsApi.reconcileTransaction, payload)
      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: 'Parent ID successfully submitted',
        })
      )
      yield put(
        SuspenseAccountsActions.confirmReconcileTransactionSuccess({
          status: payload?.SuspenseTransactionItem?.parentTransactionId,
          selectedSuspenseTransactions: payload.selectedSuspenseTransactions,
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

function* confirmTransactions(action: ReturnType<typeof SuspenseAccountsActions.confirmTransaction>) {
  try {
    const { payload } = action
    yield call(AccountsApi.confirmTransaction, payload)
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(SuspenseAccountsActions.confirmTransactionSuccess())
  } catch (e: any) {
    yield put(SuspenseAccountsActions.confirmTransactionFail(e))
  }
}

function* confirmWriteOffAndWriteBackTransactions(
  action: ReturnType<typeof SuspenseAccountsActions.confirmWriteOffAndWriteBackTransaction>
) {
  try {
    const { payload } = action
    yield call(AccountsApi.confirmWriteOffAndWriteBackTransaction, payload)
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(SuspenseAccountsActions.confirmWriteOffAndWriteBackTransactionSuccess())
  } catch (e: any) {
    yield put(SuspenseAccountsActions.confirmWriteOffAndWriteBackTransactionFail(e))
  }
}

function* confirmNostroWashTransaction(
  action: ReturnType<typeof SuspenseAccountsActions.confirmNostroWashTransaction>
) {
  try {
    const { payload } = action
    yield call(AccountsApi.confirmNostroWashTransaction, payload)
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(SuspenseAccountsActions.confirmNostroWashTransactionSuccess())
  } catch (e: any) {
    yield put(SuspenseAccountsActions.confirmNostroWashTransactionFail(e))
  }
}

function* reverseWriteOffAndWriteBackTransactions(
  action: ReturnType<typeof SuspenseAccountsActions.reverseWriteOffAndWriteBackTransaction>
) {
  try {
    const { payload } = action
    yield call(AccountsApi.reverseWriteOffAndWriteBackTransaction, payload)
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(SuspenseAccountsActions.reverseWriteOffAndWriteBackTransactionSuccess())
  } catch (e: any) {
    yield put(SuspenseAccountsActions.reverseWriteOffAndWriteBackTransactionFail(e))
  }
}

export default function* () {
  yield takeLatest(SuspenseAccountsActions.fetchSuspenseAccounts.type, getSuspenseAccounts)
  yield takeLatest(SuspenseAccountsActions.getSuspenseTransactions.type, getSuspenseTransactions)
  yield takeLatest(SuspenseAccountsActions.getSuspenseTransactionsLoadMore.type, getSuspenseTransactionsLoadMore)
  yield takeLatest(SuspenseAccountsActions.getSuspenseAccount.type, getSuspenseAccount)
  yield takeEvery(SuspenseAccountsActions.getSuspenseTransactionsForStat.type, getSuspenseTransactionsForStat)
  yield takeEvery(SuspenseAccountsActions.confirmTransaction.type, confirmTransactions)
  yield takeEvery(SuspenseAccountsActions.confirmReconcileTransaction.type, confirmReconcileTransactions)

  yield takeEvery(
    SuspenseAccountsActions.confirmWriteOffAndWriteBackTransaction.type,
    confirmWriteOffAndWriteBackTransactions
  )
  yield takeEvery(SuspenseAccountsActions.confirmNostroWashTransaction.type, confirmNostroWashTransaction)
  yield takeEvery(
    SuspenseAccountsActions.reverseWriteOffAndWriteBackTransaction.type,
    reverseWriteOffAndWriteBackTransactions
  )
}
