import React from 'react'
import { DefinitionTerm, DefinitionRow, Definition } from './styles'
import { DefinitionRowItem } from './types'
import { Body2 } from 'dls/atoms/Typography'

const FeatureDefinitionRow = ({ term, definition, hasSectionSeparator = false }: DefinitionRowItem) => (
  <DefinitionRow hasSectionSeparator={hasSectionSeparator} data-dls="molecules-FeatureDefinitionRow">
    <DefinitionTerm>
      {typeof term === 'string' ? <Body2 variantMapping={{ body2: 'span' }}>{term}</Body2> : <>{definition}</>}
    </DefinitionTerm>
    {definition.map((definitionItem, idx) => (
      // index used for the lack of a better key, as item could be a string or a
      // component
      <Definition key={idx}>
        {typeof definitionItem === 'string' ? (
          <Body2 variantMapping={{ body2: 'span' }}>{definitionItem}</Body2>
        ) : (
          <>{definitionItem}</>
        )}
      </Definition>
    ))}
  </DefinitionRow>
)

export default FeatureDefinitionRow
