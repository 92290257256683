export enum MultiPartyAllowedRoles {
  PRIMARY_OWNER = 'PrimaryOwner',
  ADMINISTRATOR = 'Administrator',
  APPROVER = 'Approver',
  USER = 'User',
  NONE = 'None',
  READ_ONLY = 'ReadOnly',
}

export interface MultiParty {
  maximumParties: number
  invitationExpiryHours: number
  inviterAllowedRoles: MultiPartyAllowedRoles[]
  inviteeAllowedRoles: MultiPartyAllowedRoles[]
}

export interface MultiPartyFormValues extends MultiParty {}
