import { TextField, ClickAwayListener } from '@material-ui/core'
import { DatePicker, DateTimePicker, DateTimePickerProps } from '@material-ui/pickers'
import { FieldProps } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { InputProps as MuiInputProps } from '@material-ui/core/Input'
import styled from 'styled-components/macro'
import { InputLabelProps as MUIInputLabelProps } from '@material-ui/core/InputLabel'
import { FilledInputProps } from '@material-ui/core/FilledInput'
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput'
import moment, { Moment } from 'moment-timezone'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'
import { DATETIME_FORMAT, DATE_FORMAT, floorTimestamp } from 'utils/date.utils'
import { FloorType } from 'utils/types'

export interface DateTimePickerComplexOwnProps {
  dateTimePickerProps?: Omit<DateTimePickerProps, 'css'>
  label?: string
  type?: string
  format?: string
  margin?: 'none' | 'dense' | 'normal' | undefined
  disablePast?: boolean | undefined
  disableToolbar?: boolean | undefined
  autoOk?: boolean | undefined
  InputLabelProps?: Partial<MUIInputLabelProps> | undefined
  InputProps?: Partial<MuiInputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined
  disabled?: boolean
  defaultIsNow?: boolean
  round?: FloorType
  pickerType: 'DateTime' | 'Date'
  required?: boolean
}

export type DateTimePickerComplexProps = FieldProps & DateTimePickerComplexOwnProps & EnhancedFormikFieldProps

const BMDateTimePicker = styled(({ pickerType, ...props }) =>
  pickerType === 'DateTime' ? <DateTimePicker {...props} /> : <DatePicker {...props} />
)`
  margin-top: -14px;
  & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
    display: none;
  }
  .MuiPickersCalendar-root {
    min-height: 210px;
  }
`

// Please Note: If used inside a Dialog, please add `disableEnforceFocus` to the Dialog call
// For more info: https://github.com/mui-org/material-ui/issues/10341
const DateTimePickerComponent = ({
  format,
  disablePast = true,
  helperText,
  dateTimePickerProps,
  label,
  defaultIsNow = true,
  required = false,
  round,
  pickerType = 'Date',
  ...otherProps
}: DateTimePickerComplexProps) => {
  const { field, form } = otherProps
  const currentError = form.errors[field.name]
  const { value } = otherProps.field
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (defaultIsNow && !value) {
      form.setFieldValue(field.name, moment())
    }
  }, [value, defaultIsNow, field.name, form])

  const setFieldValue = (date: Moment) => {
    if (date) {
      form.setFieldTouched(field.name)
      form.setFieldValue(field.name, round ? floorTimestamp(date as Moment, round) : date, true)
    }
  }

  const setFieldValueDebounced = useCallback(setFieldValue, [])
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <EnhancedFormikField {...otherProps} helperText={helperText}>
          {({ hasError }) => (
            <BMDateTimePicker
              {...dateTimePickerProps}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              pickerType={pickerType}
              disablePast={disablePast}
              label={!required ? label : `${label} *`}
              disabled={otherProps.disabled}
              value={[undefined, null, ''].includes(field.value) ? null : field.value}
              onChange={setFieldValueDebounced}
              onError={(error: any) => error && error !== currentError && form.setFieldError(field.name, `${error}`)}
              inputFormat={format || pickerType === 'DateTime' ? DATETIME_FORMAT : DATE_FORMAT}
              mask="__/___/____"
              disableMaskedInput
              renderInput={(props: any) => (
                <TextField
                  {...props}
                  onClick={() => setOpen(true)}
                  error={hasError}
                  helperText=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          )}
        </EnhancedFormikField>
      </div>
    </ClickAwayListener>
  )
}

export default DateTimePickerComponent
