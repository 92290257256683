/* eslint-disable no-nested-ternary */
import { Dialog, Fade } from '@material-ui/core'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { find, isEmpty, orderBy } from 'lodash'
// eslint-disable-next-line import/no-cycle
import PublishProductForm, {
  getLatestPublishedVersion,
  isLatestVersionEffective,
  validateEffectiveDateMaintenanceForm,
} from './PublishProductForm'
import ProductsActions from 'store/products/actions'
import { Product, PublishProductFormValues } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import ProductsSelectors from 'store/products/selectors'
import { RootState } from 'store'
import { selectConfig, selectMinProductRepublishDelay } from 'store/tenant-config/selectors'
import GovernanceSelector from 'store/governance/selectors'
import GovernanceActions from 'store/governance/actions'
import TasksSelectors from 'store/tasks/selectors'
import TaskActions from 'store/tasks/actions'
import { toBankTz } from 'utils/date.utils'
import { isVersionFirst } from 'utils/productVersion.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  product: Product
}

const mapStateToProps = (state: RootState) => ({
  updating: ProductsSelectors.selectUpdating(state),
  tenantConfig: selectConfig(state),
  processExecution: GovernanceSelector.selectprocessExecutions(state),
  assignees: ProductsSelectors.selectColleagues(state),
  tasks: TasksSelectors.selectTasksForProduct(state),
  versions: ProductsSelectors.selectVersions(state),
  minProductRepublishDelay: selectMinProductRepublishDelay(state),
})

const mapDispatchToProps = {
  publish: ProductsActions.publish,
  openAssigneeModal: ProductsActions.openAssigneeModal,
  getProcessexecution: GovernanceActions.processExecution,
  taskForProducts: TaskActions.tasksExecutionsForProduct,
  getVersionsForPublish: ProductsActions.getVersionsForPublish,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps & WithTranslation

const PublishProduct = (props: Props) => {
  const {
    publish,
    product,
    updating,
    tenantConfig,
    processExecution,
    openAssigneeModal,
    taskForProducts,
    getVersionsForPublish,
    versions,
    minProductRepublishDelay,
  } = props
  const [open, setOpen] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const { t } = useTranslation()

  const process = processExecution ? processExecution.filter((i) => i.status !== 'CLOSED')[0] : undefined
  const processExecutionKey = process ? process.key : undefined

  const onPublish = ({ effectiveDate, message }: PublishProductFormValues) => {
    publish({
      productKey: product.productKey,
      message,
      effectiveDate,
    })
    // Instead of closing the modal, we set publisehed to true
    setPublishing(true)
  }
  const tasksData = orderBy(
    props.tasks
      .map((task) => ({ assignee: find(props.assignees, { userKey: task.assigneeKey }), task }))
      .filter((task) => task),
    ['task.sequence', 'task.context'],
    ['asc', 'asc']
  )

  let assigned = false
  if (!isEmpty(tasksData)) {
    assigned = true
    tasksData.forEach(({ task }) => {
      if (!task.assigneeKey) {
        assigned = false
      }
    })
  }

  // Close modal after request starts, and request finishes
  useEffect(() => {
    if (publishing) {
      setOpen(false)
    }
  }, [publishing])

  useEffect(() => {
    if (processExecutionKey) {
      taskForProducts(processExecutionKey)
    }
  }, [taskForProducts, processExecutionKey])

  const publishClickHandler = () => {
    setOpen(true)
    getVersionsForPublish(product.productKey)
  }
  const latestVersion = getLatestPublishedVersion(versions)
  const isPrevVersionEffective = !!versions && isLatestVersionEffective(getLatestPublishedVersion(versions))
  let earliestValidEffectiveDate
  if (!isVersionFirst(product)) {
    if (!isPrevVersionEffective) {
      earliestValidEffectiveDate = !minProductRepublishDelay
        ? toBankTz(latestVersion?.effectiveDate || '').mDate.add(24, 'hours')
        : toBankTz(latestVersion?.effectiveDate || '').mDate.add(minProductRepublishDelay, 'minutes')
    } else {
      earliestValidEffectiveDate = !minProductRepublishDelay
        ? moment().add(24, 'hours')
        : moment().add(minProductRepublishDelay, 'minutes')
    }
  } else {
    earliestValidEffectiveDate = moment().add(5, 'minutes')
  }

  if (!validateEffectiveDateMaintenanceForm(earliestValidEffectiveDate)) {
    if (earliestValidEffectiveDate.hours() > 21) {
      earliestValidEffectiveDate.add(1, 'days')
    }
    earliestValidEffectiveDate.hours(3)
    earliestValidEffectiveDate.minutes(1)
  }

  return (
    <div>
      {tenantConfig &&
        tenantConfig.features.governance &&
        (processExecutionKey || (product.status && [ProductStatus.APPROVED].includes(product.status))) &&
        !updating && (
          <Button
            onClick={publishClickHandler}
            variant="contained"
            color={ColorType.BUTTON}
            data-test-id="publish-button"
            disabled={!product.status || ![ProductStatus.APPROVED].includes(product.status)}
          >
            {t('Publish')}
          </Button>
        )}
      {tenantConfig &&
        tenantConfig.features.governance &&
        !assigned &&
        !process &&
        !(product.status && [ProductStatus.APPROVED].includes(product.status)) &&
        !updating && (
          <>
            <Button
              onClick={() => {
                if (!process) {
                  props.getProcessexecution({ entityKey: product.productKey })
                } else {
                  openAssigneeModal()
                }
              }}
              variant="contained"
              color={ColorType.BUTTON}
              data-test-id="send-for-approval-button"
            >
              {t('Send for Approval')}
            </Button>
          </>
        )}
      {tenantConfig && !tenantConfig.features.governance && !updating && (
        <Button
          data-test-id="publish-enabled"
          onClick={publishClickHandler}
          variant="contained"
          color={ColorType.BUTTON}
        >
          {t('Publish')}
        </Button>
      )}
      {open && (isVersionFirst(product) || (!!versions && latestVersion)) && (
        <Dialog
          open={open}
          scroll="paper"
          onClose={() => setOpen(false)}
          TransitionComponent={Fade}
          fullWidth
          disableEnforceFocus
        >
          <PublishProductForm
            initialValues={{
              effectiveDate: earliestValidEffectiveDate,
              message: '',
            }}
            earliestValidEffectiveDate={earliestValidEffectiveDate}
            onSubmit={onPublish}
            updating={updating}
            setOpen={setOpen}
            versions={versions || []}
            product={product}
            minProductRepublishDelay={minProductRepublishDelay}
          />
        </Dialog>
      )}
    </div>
  )
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PublishProduct))
