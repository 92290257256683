import { useDispatch, useSelector } from 'react-redux'
import { selectConfig } from 'store/tenant-config/selectors'
import {
  ProductKey,
  DebitInterestFormValues,
  DebitInterestCommonConfigFormValues,
  LendingRateFormValues,
  Feature,
  ProductLine,
  LendingRateType,
} from 'store/products/types'
import ProductsActions from 'store/products/actions'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'

const useDebitInterest = ({
  productKey,
  version,
  productLine,
}: {
  productKey: ProductKey
  version?: number
  productLine?: ProductLine
}) => {
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectConfig)

  const isLending: boolean = productLine === ProductLine.LENDING

  const submitDebitInterestSetup = (formValues: DebitInterestFormValues, rateType: LendingRateType) => {
    if (productLine && version) {
      dispatch(ProductsActions.setupDebitInterest({ ...formValues, productKey, version, rateType }))
    }
  }

  const submitDebitInterestUpdate = (
    values: DebitInterestCommonConfigFormValues | LendingRateFormValues,
    entity: 'commonConfig' | 'rateWithinLimit' | 'rateAboveLimit' | 'rateAboveLimitOverride'
  ) => {
    if (productLine && version) {
      dispatch(
        ProductsActions.updateDebitInterest({
          ...values,
          entity,
          productKey,
          version,
          operation: 'replace',
        })
      )
    }
  }

  const submitDebitInterestRateRemoval = (
    rateType: 'rateWithinLimit' | 'rateAboveLimit' | 'rateAboveLimitOverride'
  ) => {
    if (version) {
      dispatch(
        ProductsActions.removeDebitInterestRate({
          rateType,
          productKey,
          version,
        })
      )
    }
  }

  return {
    isNotAllowed: !isFeatureAllowedByConfig(tenantConfig, Feature.DEBIT_INTEREST),
    isLending,
    submitDebitInterestSetup,
    submitDebitInterestUpdate,
    submitDebitInterestRateRemoval,
  }
}

export default useDebitInterest
