import React, { useCallback, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'
import Lazyload, { forceVisible } from 'react-lazyload'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getText, getVersionAndlink } from './version.utils'
import { BackButton, VersionCard } from 'components'
import { Product } from 'store/products/types'
import ContentContainer from 'pages/Layout/ContentContainer'

interface Props {
  products: Product[]
  currentProduct: Product
}

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 24px;
`
const ButtonContainer = styled.div`
  align-items: center;
  padding-left: 8px;
  padding-top: 0px;
`
const Products = ({ products, currentProduct }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onPressBack = useCallback(() => {
    navigate(`/products/${currentProduct.productKey}`)
  }, [currentProduct])
  useEffect(() => {
    forceVisible()
  }, [])

  return (
    <ContentContainer>
      <ButtonContainer>
        <BackButton onPressBack={onPressBack} aria-controls="main" />
      </ButtonContainer>
      <StyledTypography variant="h5">{t('Versions')}</StyledTypography>
      {!isEmpty(products) &&
        products.map((product, index) => (
          <Lazyload height={90} key={product.productKey}>
            <VersionCard
              {...getVersionAndlink(index, product, t)}
              modifiedOrPublished={getText(product)}
              status={product.status}
              publishedDate={product.effectiveDate}
            />
          </Lazyload>
        ))}
    </ContentContainer>
  )
}

export default Products
