import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectActivitiesState = (state: RootState) => state.activity

const selectAllActivities = createSelector(selectActivitiesState, (state) => Object.values(state.data))

const selectLoading = createSelector(selectActivitiesState, (state) => state.loading)

export default {
  selectActivitiesState,
  selectAllActivities,
  selectLoading,
}
