import React from 'react'
import {
  IconTerms,
  IconTermAmount,
  IconTaxRules,
  IconStatements,
  IconSettings,
  IconRepaymentSchedule,
  IconProducts,
  IconProductDetails,
  IconProductCode,
  IconProductCardLimit,
  IconPaymentLimits,
  IconPackages,
  IconIndexRates,
  IconHistory,
  IconHelp,
  IconGovernance,
  IconFutureRate,
  IconFees,
  IconExternalIds,
  IconElgibility,
  IconDevices,
  IconDebitInterest,
  IconCurrency,
  IconCreditLimit,
  IconCreditInterest,
  IconColleague,
  IconCardLimit,
  IconAnySource,
  IconAccounts,
  IconTransactionRules,
  IconCopied,
  IconCopy,
  IconCards,
  IconDocuments,
  IconSubscriptionRules,
  IconEditAvatar,
  GridView,
  IconNoPreview,
  MultiParty,
  IconPreviewLaunch,
  IconSubdirectoryArrowRight,
  IconSyndication,
  IconTemplates,
  IconCommonFileTextSettings,
  IconReferenceData,
  IconSyndicateProducts,
  IconRewards,
} from 'assets'
import { AIconProps, SVGIcon } from 'dls/atoms/Icons/Generator/svg'
import { iconSVGFillType } from 'dls/shared/types'

export const CopiedIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-copied" {...props} Icon={IconCopied} />

export const CopyIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-copy" {...props} Icon={IconCopy} />

export const GridViewIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-grid-view" {...props} Icon={GridView} />

export const InputSettings = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-input" {...props} Icon={IconSettings} />
)

export const TaxRules = (props: AIconProps) => <SVGIcon data-dls="atom-icon-tax-rules" {...props} Icon={IconTaxRules} />

export const HistoryIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-history" {...props} Icon={IconHistory} />

export const CurrencyIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-currency" {...props} Icon={IconCurrency} />
)

export const ProductDetailsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-product-details" {...props} Icon={IconProductDetails} />
)

export const ExternalIdsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-external-ids" {...props} Icon={IconExternalIds} />
)

export const RepaymentScheduleIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-repayment-schedule" {...props} Icon={IconRepaymentSchedule} />
)

export const ElgibilityIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-eligibility" {...props} Icon={IconElgibility} />
)

export const ProductCodeIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-product-code" {...props} Icon={IconProductCode} />
)

export const ProductsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-products" {...props} Icon={IconProducts} />
)

export const PaymentLimitsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-payment-limits" {...props} Icon={IconPaymentLimits} />
)

export const CardLimitIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-card-limit" {...props} Icon={IconCardLimit} />
)

export const ProductCardLimitIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-product-card" {...props} Icon={IconProductCardLimit} />
)

export const FeesIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-fees" {...props} Icon={IconFees} />

export const RewardsIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-rewards" {...props} Icon={IconRewards} />

export const CreditInterestIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-credit-interest" {...props} Icon={IconCreditInterest} />
)

export const TermAmountIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-amount" {...props} Icon={IconTermAmount} />
)

export const PackagesIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-packages" {...props} Icon={IconPackages} />
)

export const IndexRatesIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-index-rates" {...props} Icon={IconIndexRates} />
)

export const HelpIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-replayment-schdule" {...props} Icon={IconHelp} />
)

export const StatementsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-statement" {...props} Icon={IconStatements} />
)

export const TermsIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-terms" {...props} Icon={IconTerms} />

export const DevicesIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-device" {...props} Icon={IconDevices} />

export const ColleagueIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-colleague" {...props} Icon={IconColleague} />
)

export const DebitInterestIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-debit-interest" {...props} Icon={IconDebitInterest} />
)

export const CreditLimitIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-credit-limits" {...props} Icon={IconCreditLimit} />
)

export const GovernanceIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-governance" {...props} Icon={IconGovernance} />
)

export const AnySourceIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-any-source" {...props} Icon={IconAnySource} />
)

export const AccountsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-credit-card" {...props} Icon={IconAccounts} />
)

export const FutureRateIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-future-rate" {...props} Icon={IconFutureRate} />
)

export const TransactionRulesIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-transaction-rules" {...props} Icon={IconTransactionRules} />
)

export const CardsIcon = (props: AIconProps) => <SVGIcon data-dls="atom-icon-cards" {...props} Icon={IconCards} />

export const DocumentsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-documents" {...props} Icon={IconDocuments} />
)

export const SubscriptionRulesIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-subscription-rules" {...props} Icon={IconSubscriptionRules} />
)

export const EditAvatarIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-edit-avatar" {...props} IconSVGType={iconSVGFillType.STROKE} Icon={IconEditAvatar} />
)

export const NoPreviewIcon = (props: AIconProps) => (
  <SVGIcon
    data-dls="atom-icon-no-preview-avatar"
    {...props}
    IconSVGType={iconSVGFillType.STROKE}
    Icon={IconNoPreview}
  />
)

export const PreviewIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-preview" {...props} defaultColor Icon={IconPreviewLaunch} />
)
export const MultiPartyIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-multi-party" {...props} Icon={MultiParty} />
)

export const SubdirectoryArrowRightIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-subdirectory-arrow-right" {...props} Icon={IconSubdirectoryArrowRight} />
)

export const SyndicationIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-syndication" {...props} Icon={IconSyndication} />
)

export const TemplatesIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-templates" {...props} Icon={IconTemplates} />
)

export const CommonFileTextSettingsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-file-text" {...props} Icon={IconCommonFileTextSettings} />
)

export const ReferenceDataIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-file-text" {...props} Icon={IconReferenceData} />
)

export const ProductSyndicationIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-syndicate-products" {...props} Icon={IconSyndicateProducts} />
)
