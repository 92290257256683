import React from 'react'
import { Fade, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ClearIcon from '@material-ui/icons/Clear'
import styled from 'styled-components/macro'
import { StyledDialog } from '../Styles'
import { DialogStyleContent, IconGrid, ModalItems, Title } from './styles'
import { stopPropagation } from 'utils/form.utils'
import { AccessibleLink } from 'components/AccessibleLink/AccessibleLink'

interface AboutCell {
  title: string
  description: string
}

interface AboutImage {
  src: string
  alt: string
}

const DialogContainer = styled(DialogStyleContent)`
  padding: 24px !important;
`
interface OwnProps {
  onPressCancel: () => void
  heading: string
  image: AboutImage
  open: boolean
  items: AboutCell[]
  id?: string
}

const AboutDialog = ({ onPressCancel, heading, image, open, items, id }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <StyledDialog
      open={open}
      scroll="paper"
      onClose={onPressCancel}
      TransitionProps={{ onExit: onPressCancel }}
      TransitionComponent={Fade}
      fullWidth
    >
      <DialogContainer>
        <Grid container direction="row" justifyContent="space-between" spacing={7}>
          <Grid item>
            <Title variant="h4" gutterBottom id={id} variantMapping={{ h4: 'h3' }}>
              {t(heading)}
            </Title>
          </Grid>
          <Grid item>
            <AccessibleLink
              onClick={(e: React.MouseEvent) => {
                stopPropagation(e)
                onPressCancel()
              }}
              arial-label="close"
            >
              <ClearIcon css="margin-top:5px" color="action" />
            </AccessibleLink>
          </Grid>
        </Grid>
        <Grid container>
          <ModalItems container spacing={4}>
            {items.map((item, index) => (
              <Grid item md={4} key={item.title} style={{ paddingRight: index === items.length - 1 ? 10 : 16 }}>
                <Typography variant="subtitle2" variantMapping={{ subtitle2: 'h4' }}>
                  {t(item.title)}
                </Typography>
                <Typography variant="body2">{t(item.description)}</Typography>
              </Grid>
            ))}
          </ModalItems>
          <IconGrid item>
            <img src={image.src} alt={t(image.alt)} aria-hidden="true" css="max-width:100%" />
          </IconGrid>
        </Grid>
      </DialogContainer>
    </StyledDialog>
  )
}

export default AboutDialog
