import { Grid } from '@material-ui/core'
import { connect as connectFormik, Field, getIn } from 'formik'
import range from 'lodash/range'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import SelectFieldComplex from '../SelectFieldComplex'
import { TopLevelFrequency, MonthlyFrequencyItems } from 'store/products/types'
import { Yip } from 'utils/form.utils'
import { If } from 'components/ControlComponents'

const NestedMonthlyFields = connectFormik<NestedFieldsProps>(({ rootName, formik: { values }, disable }) => {
  const { t } = useTranslation()

  const SecondarySelectItemsWithLabels = {
    [MonthlyFrequencyItems.END]: {
      name: 'END',
      label: 'MONTH.END',
    },
    [MonthlyFrequencyItems.BEGINNING]: {
      name: 'BEGINNING',
      label: 'MONTH.BEGINNING',
    },
    [MonthlyFrequencyItems.ANNIVERSARY]: {
      name: 'ANNIVERSARY',
      label: 'MONTH.ANNIVERSARY',
    },
    [MonthlyFrequencyItems.SPECIFIC_DAY]: 'SPECIFIC_DAY',
  }

  return (
    <>
      <Grid item xs={4}>
        <Field
          label={t(`${TopLevelFrequency.MONTH}.secondary.label`)}
          name={`${rootName}.${TopLevelFrequency.MONTH}.secondary`}
          validate={Yip(Yup.string().required())}
          required
          data={SecondarySelectItemsWithLabels}
          component={SelectFieldComplex}
          displayEmpty
          fullWidth
          disabled={disable}
        />
      </Grid>
      <If on={getIn(values, `${rootName}.${TopLevelFrequency.MONTH}.secondary`) === MonthlyFrequencyItems.SPECIFIC_DAY}>
        <Grid item xs={3}>
          <Field
            label={t(`${TopLevelFrequency.MONTH}.tertiary.label`)}
            component={SelectFieldComplex}
            data={range(1, 29).reduce((acc, curr) => ({ ...acc, [curr]: curr }), {})}
            required
            validate={Yip(Yup.string().required())}
            name={`${rootName}.${TopLevelFrequency.MONTH}.tertiary`}
            disabled={disable}
          />
        </Grid>
      </If>
    </>
  )
})

export default NestedMonthlyFields
