import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CardsForm from './CardsForm'
import { hasCardsFeature, toCardsFormValues } from './cardsTransformers'
import ProductsActions from 'store/products/actions'
import { CardsFormValues, Feature } from 'store/products/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { RootState } from 'store/types'
import { ClientConfig } from 'store/tenant-config/types'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useFeatureAvailable from 'pages/Product/hooks/useFeatureAvailable'

interface OwnProps {}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCards: (productKey: string) => (cardsFormValues: CardsFormValues) =>
    dispatch(ProductsActions.setCards({ ...cardsFormValues, productKey })),
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const CardsPage = (props: Props): React.ReactElement | null => {
  const { setCards, tenantConfig } = props
  const { t } = useTranslation()
  const { productKey } = useParams()

  const [product, isLoading] = useSelectedProduct(productKey || '')
  useUpdateBreadcrumbs({ productKey: productKey || '', context: Feature.CARDS })
  useFeatureAvailable(productKey || '', Feature.CARDS)
  const clientConfig = ((tenantConfig && tenantConfig.clientConfig) as Partial<ClientConfig>) || {}

  if (isLoading) {
    return <Loader />
  }

  if (product) {
    return (
      <CardsForm
        onSubmit={setCards(productKey || '')}
        initialValues={toCardsFormValues(product)}
        product={product}
        hasFeature={hasCardsFeature(product)}
        clientConfig={clientConfig}
        productKey={productKey || ''}
        t={t}
      />
    )
  }

  return null
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsPage)
