import { Form, FormikProps, getIn } from 'formik'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { isEmpty, isUndefined, reject, sum, set } from 'lodash'
import { Paper } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { ResidencyEligibilityElement } from './ResidencyEligibilityElement'
import { AgeEligibilityElement } from './AgeEligibilityElement'
import { IncomeEligibilityElement } from './IncomeEligibilityElement'
import { ProductDependencyEligibilityElement } from './ProductDependencyEligibilityElement'
import { TaxResidencyEligibilityElement } from './TaxResidencyEligibilityElement'
import TaxIdentificationEligibilityElement from './TaxIdentificationEligibilityElement'
import { StudentAccountEligibilityElement } from './StudentAccountEligibilityElement'
import {
  FormBottomBar,
  SectionArray,
  ZeroStateLandingPage,
  FormAddFeatureButton,
  ConfirmModalDeprecated,
} from 'components'
import { EligibilityFormValues, ProductKey, ExtendedProduct, ProductSegment } from 'store/products/types'
import { withFormikSimple, stopPropagation } from 'utils/form.utils'
import { NoEligibility, DeleteEligibility } from 'assets'
import { DeleteIconButton, Accordion, Body1 } from 'dls'
import { featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'
import ProductSelectors from 'store/products/selectors'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  productKey: ProductKey
  product: ExtendedProduct
}
interface EligibilityFormProps extends FormikProps<EligibilityFormValues>, OwnProps {}

const EligibilityForm = (props: EligibilityFormProps) => {
  const {
    values: {
      ageEligibility,
      incomeEligibility,
      residencyEligibility,
      officerEligibility,
      taxResidencyEligibility,
      taxIdentificationEligibility,
      studentAccountEligibility,
      productDependencyEligibility,
    },
    productKey,
    product,
  } = props
  const eligibilityTypes = Object.keys(props.values).sort((a, b) => a.localeCompare(b))
  const { t } = useTranslation()
  const navigate = useNavigate()

  const productSegment = useSelector(ProductSelectors.selectSelectedProductSegment)

  const isEmptyOrUndefined = (value: EligibilityFormValues) => isEmpty(value) || isUndefined(value)

  const [openConfirm, setOpenConfirm] = useState<string | null>(null)

  const onPressBack = useCallback(() => {
    navigate(`/products/${props.productKey}`)
  }, [props.productKey])

  const getAvailableDropdownElements = () => {
    const defaultElements: Record<string, object> = {
      ageEligibility: { defaultValue: { formType: 'ageEligibility' }, multiple: false },
      productDependencyEligibility: {
        defaultValue: { formType: 'productDependencyEligibility' },
        multiple: false,
      },
      residencyEligibility: { defaultValue: { formType: 'residencyEligibility' }, multiple: false },
      // For time being, we are commenting this code as these are not collected by the platform
      // officerEligibility: { defaultValue: defaultValues.officerEligibility, multiple: false },
      taxResidencyEligibility: { defaultValue: { formType: 'taxResidencyEligibility' }, multiple: false },
      incomeEligibility: {
        defaultValue: { period: 'ANNUAL', formType: 'incomeEligibility' },
        multiple: false,
        overrideLabel: productSegment === ProductSegment.BUSINESS && t(`Annual turnover`),
      },
      studentAccountEligibility: {
        defaultValue: { formType: 'studentAccountEligibility', studentIdRequired: true },
        multiple: false,
      },
      taxIdentificationEligibility: {
        defaultValue: { formType: 'taxIdentificationEligibility' },
        multiple: false,
      },
    }
    return Object.fromEntries(Object.entries(defaultElements).sort())
  }

  const renderEligibilitySections = (type: string) => {
    const value = getIn(props.values, type)
    const empty = !!(value.length && value[0].formType)

    switch (type) {
      case 'ageEligibility':
        return (
          <SectionArray name="ageEligibility">
            {({ remove, name, index }) => (
              <>
                <ConfirmModalDeprecated
                  id={`delete-eligibility-${index}`}
                  onCancelClick={() => setOpenConfirm(null)}
                  onConfirmClick={{
                    action: () => {
                      setOpenConfirm(null)
                      remove(index)
                    },
                    title: t('Ok, got it'),
                  }}
                  open={openConfirm === name}
                  title={t(`{{cardName}} will only be deleted when you submit the form`, {
                    cardName: t('Age'),
                  })}
                  image={{ src: DeleteEligibility, alt: t('Delete Icon') }}
                  imageStyle={{ marginBottom: '24px' }}
                  cancelText="Cancel"
                />
                <Accordion
                  title={<Body1>{t('Age')}</Body1>}
                  headerIcon={featureIconsCreator(featureSVGIcons.eligibility)}
                  headerButtonGroup={
                    <DeleteIconButton
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        setOpenConfirm(name)
                      }}
                    />
                  }
                  defaultExpanded={empty}
                >
                  <AgeEligibilityElement name={name} />
                </Accordion>
              </>
            )}
          </SectionArray>
        )
      case 'incomeEligibility':
        return (
          <SectionArray name="incomeEligibility">
            {({ remove, name, index }) => (
              <>
                <ConfirmModalDeprecated
                  id={`delete-eligibility-${index}`}
                  onCancelClick={() => setOpenConfirm(null)}
                  onConfirmClick={{
                    action: () => {
                      setOpenConfirm(null)
                      remove(index)
                    },
                    title: t('Ok, got it'),
                  }}
                  open={openConfirm === name}
                  title={t(`{{cardName}} will only be deleted when you submit the form`, {
                    cardName: t('Annual income'),
                  })}
                  image={{ src: DeleteEligibility, alt: t('Delete Icon') }}
                  imageStyle={{ marginBottom: '24px' }}
                  cancelText="Cancel"
                />
                <Accordion
                  title={
                    <Body1>
                      {productSegment === ProductSegment.BUSINESS ? t(`Annual turnover`) : t('Annual income')}
                    </Body1>
                  }
                  headerIcon={featureIconsCreator(featureSVGIcons.eligibility)}
                  headerButtonGroup={
                    <DeleteIconButton
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        setOpenConfirm(name)
                      }}
                    />
                  }
                  defaultExpanded={empty}
                >
                  {productSegment && (
                    <IncomeEligibilityElement
                      name={name}
                      currencyCode={product.currency.currencyCode}
                      productSegment={productSegment}
                    />
                  )}
                </Accordion>
              </>
            )}
          </SectionArray>
        )
      case 'residencyEligibility':
        return (
          <SectionArray name="residencyEligibility">
            {({ remove, name, index }) => (
              <>
                <ConfirmModalDeprecated
                  id={`delete-eligibility-${index}`}
                  onCancelClick={() => setOpenConfirm(null)}
                  onConfirmClick={{
                    action: () => {
                      setOpenConfirm(null)
                      remove(index)
                    },
                    title: t('Ok, got it'),
                  }}
                  open={openConfirm === name}
                  title={t(`{{cardName}} will only be deleted when you submit the form`, {
                    cardName: t('Residency'),
                  })}
                  image={{ src: DeleteEligibility, alt: t('Delete Icon') }}
                  imageStyle={{ marginBottom: '24px' }}
                  cancelText="Cancel"
                />
                <Accordion
                  title={<Body1>{t('Residency')}</Body1>}
                  headerIcon={featureIconsCreator(featureSVGIcons.eligibility)}
                  headerButtonGroup={
                    <DeleteIconButton
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        setOpenConfirm(name)
                      }}
                    />
                  }
                  defaultExpanded={empty}
                >
                  <ResidencyEligibilityElement name={name} />
                </Accordion>
              </>
            )}
          </SectionArray>
        )
      case 'officerEligibility':
        return null
      case 'studentAccountEligibility':
        return (
          <SectionArray name="studentAccountEligibility">
            {({ remove, name, index }) => (
              <>
                <ConfirmModalDeprecated
                  id={`delete-eligibility-${index}`}
                  onCancelClick={() => setOpenConfirm(null)}
                  onConfirmClick={{
                    action: () => {
                      setOpenConfirm(null)
                      remove(index)
                    },
                    title: t('Ok, got it'),
                  }}
                  open={openConfirm === name}
                  title={t(`{{cardName}} will only be deleted when you submit the form`, {
                    cardName: t('Student ID'),
                  })}
                  image={{ src: DeleteEligibility, alt: t('Delete Icon') }}
                  imageStyle={{ marginBottom: '24px' }}
                  cancelText="Cancel"
                />
                <Accordion
                  title={<Body1>{t('Student ID')}</Body1>}
                  headerIcon={featureIconsCreator(featureSVGIcons.eligibility)}
                  headerButtonGroup={
                    <DeleteIconButton
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        setOpenConfirm(name)
                      }}
                    />
                  }
                  defaultExpanded={empty}
                >
                  <StudentAccountEligibilityElement name={name} />
                </Accordion>
              </>
            )}
          </SectionArray>
        )
      case 'taxResidencyEligibility':
        return (
          <SectionArray name="taxResidencyEligibility">
            {({ remove, name, index }) => (
              <>
                <ConfirmModalDeprecated
                  id={`delete-eligibility-${index}`}
                  onCancelClick={() => setOpenConfirm(null)}
                  onConfirmClick={{
                    action: () => {
                      setOpenConfirm(null)
                      remove(index)
                    },
                    title: t('Ok, got it'),
                  }}
                  open={openConfirm === name}
                  title={t(`{{cardName}} will only be deleted when you submit the form`, {
                    cardName: t('Tax residency'),
                  })}
                  image={{ src: DeleteEligibility, alt: t('Delete Icon') }}
                  imageStyle={{ marginBottom: '24px' }}
                  cancelText="Cancel"
                />
                <Accordion
                  title={<Body1>{t('Tax residency')}</Body1>}
                  headerIcon={featureIconsCreator(featureSVGIcons.eligibility)}
                  headerButtonGroup={
                    <DeleteIconButton
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        setOpenConfirm(name)
                      }}
                    />
                  }
                  defaultExpanded={empty}
                >
                  <TaxResidencyEligibilityElement name={name} />
                </Accordion>
              </>
            )}
          </SectionArray>
        )
      case 'taxIdentificationEligibility':
        return (
          <SectionArray name="taxIdentificationEligibility">
            {({ remove, name, index }) => (
              <>
                <ConfirmModalDeprecated
                  id={`delete-eligibility-${index}`}
                  onCancelClick={() => setOpenConfirm(null)}
                  onConfirmClick={{
                    action: () => {
                      setOpenConfirm(null)
                      remove(index)
                    },
                    title: t('Ok, got it'),
                  }}
                  open={openConfirm === name}
                  title={t(`{{cardName}} will only be deleted when you submit the form`, {
                    cardName: t('Tax ID number'),
                  })}
                  image={{ src: DeleteEligibility, alt: t('Delete Icon') }}
                  imageStyle={{ marginBottom: '24px' }}
                  cancelText="Cancel"
                />
                <Accordion
                  title={<Body1>{t('Tax ID number')}</Body1>}
                  headerIcon={featureIconsCreator(featureSVGIcons.eligibility)}
                  headerButtonGroup={
                    <DeleteIconButton
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        setOpenConfirm(name)
                      }}
                    />
                  }
                  defaultExpanded={empty}
                >
                  <TaxIdentificationEligibilityElement name={name} />
                </Accordion>
              </>
            )}
          </SectionArray>
        )
      case 'productDependencyEligibility':
        return (
          <SectionArray name="productDependencyEligibility">
            {({ remove, name, index }) => (
              <>
                <ConfirmModalDeprecated
                  id={`delete-eligibility-${index}`}
                  onCancelClick={() => setOpenConfirm(null)}
                  onConfirmClick={{
                    action: () => {
                      setOpenConfirm(null)
                      remove(index)
                    },
                    title: t('Ok, got it'),
                  }}
                  open={openConfirm === name}
                  title={t(`{{cardName}} will only be deleted when you submit the form`, {
                    cardName: t('Dependent product'),
                  })}
                  image={{ src: DeleteEligibility, alt: t('Delete Icon') }}
                  imageStyle={{ marginBottom: '24px' }}
                  cancelText="Cancel"
                />
                <Accordion
                  title={<Body1>{t('Dependent product')}</Body1>}
                  headerIcon={featureIconsCreator(featureSVGIcons.eligibility)}
                  headerButtonGroup={
                    <DeleteIconButton
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        setOpenConfirm(name)
                      }}
                    />
                  }
                  defaultExpanded={empty}
                >
                  <ProductDependencyEligibilityElement name={name} productKey={productKey} />
                </Accordion>
              </>
            )}
          </SectionArray>
        )
      default:
        return null
    }
  }

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Eligibility')}
      description={t('Here you can set the eligibility rules for this product.')}
      rightArea={<FormAddFeatureButton addLabel={t('Add eligibility')} elements={getAvailableDropdownElements()} />}
    >
      <Form>
        {!isEmpty(
          reject(
            [
              ageEligibility,
              incomeEligibility,
              residencyEligibility,
              officerEligibility,
              taxResidencyEligibility,
              taxIdentificationEligibility,
              studentAccountEligibility,
              productDependencyEligibility,
            ],
            isEmptyOrUndefined
          )
        ) ? (
          <>
            {eligibilityTypes.map((type) => (
              <div key={type} style={{ marginBottom: 16 }}>
                {renderEligibilitySections(type)}
              </div>
            ))}
          </>
        ) : (
          <Paper style={{ paddingTop: 40, marginBottom: 15 }}>
            <ZeroStateLandingPage
              image={NoEligibility}
              title={t('No eligibility rules have been added')}
              description={t('Select “Add eligibility” above to get started ')}
            />
          </Paper>
        )}

        <FormBottomBar position="bottom">
          <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
            {t('Cancel')}
          </Button>
        </FormBottomBar>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export const eligibilityValidation = (values: EligibilityFormValues) => {
  const errors = {}
  const itemNumbers = sum(Object.entries(values).map(([i]) => i && values[i] && values[i].length))
  if (!(itemNumbers > 0)) set(errors, `eligibility`, 'At least one eligibility must be added')
  return errors
}

export default withFormikSimple<OwnProps, EligibilityFormValues>(EligibilityForm, {
  validate: eligibilityValidation,
  validationSchema: Yup.object<Partial<EligibilityFormValues>>({
    ageEligibility: Yup.array(
      Yup.object({
        minimumAge: Yup.number()
          .required('Required field, you can enter 0 for no minimum age')
          .min(0, 'Required field, you can enter 0 for no minimum age')
          .max(999, 'Must be less than 999')
          .integer('This field must be a number'),
        maximumAge: Yup.number()
          .min(0, 'Must be positive')
          .max(999, 'Must be less than 999')
          .integer('This field must be a number')
          .when(['minimumAge'], {
            is: (minimumAge) => !!minimumAge,
            then: (maximumAge: Yup.NumberSchema) =>
              maximumAge.moreThan(Yup.ref('minimumAge'), 'Maximum age must be greater than minimum age'),
          })
          .nullable(),
      })
    ),
    incomeEligibility: Yup.array(
      Yup.object({
        minimumIncome: Yup.number()
          .required('Required field, you can enter 0 for no minimum annual income')
          .min(0, 'Required field, you can enter 0 for no minimum annual income')
          .integer('This field must be a whole number without decimals'),
        maximumIncome: Yup.number()
          .min(0, 'Must be positive')
          .integer('This field must be a whole number without decimals')
          .when(['minimumIncome'], {
            is: (minimumIncome) => !!minimumIncome,
            then: (maximumIncome: Yup.NumberSchema) =>
              maximumIncome.min(
                Yup.ref('minimumIncome'),
                'Maximum annual income must be greater than minimum annual income'
              ),
          })
          .nullable(),
      })
    ),
    officerEligibility: Yup.array(
      Yup.object({
        officerType: Yup.mixed(),
        maxAmount: Yup.number().typeError('This field must be a number'),
        minAmount: Yup.number()
          .when(['maxAmount'], {
            is: (maxAmount) => !!maxAmount,
            then: (minAmount: Yup.NumberSchema) =>
              minAmount.max(Yup.ref('maxAmount'), 'Minimum amount must be lesser than maximum amount'),
          })
          .typeError('This field must be a number'),
      })
    ),
    productDependencyEligibility: Yup.array(
      Yup.object({
        dependency: Yup.object({
          productKey: Yup.string().required('This is a required field'),
          subscriptionStatus: Yup.mixed().required('This is a required field'),
        }),
      })
    ),
    residencyEligibility: Yup.array(
      Yup.object({
        countryIncluded: Yup.array().of(Yup.string()).required('This is a required field'),
      })
    ),
    taxResidencyEligibility: Yup.array(
      Yup.object({
        countryIncluded: Yup.array().of(Yup.string()).required('This is a required field'),
      })
    ),
    taxIdentificationEligibility: Yup.array(
      Yup.object({
        countryCode: Yup.array().of(Yup.string()).required('This is a required field'),
      })
    ),
  }).required(),
})
