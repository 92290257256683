import React from 'react'
import { Grid } from '@material-ui/core'
import { Main } from 'dls/shared/styled'

interface OwnProps {
  header: React.ReactNode
  main: React.ReactNode
  headerProps?: React.HTMLAttributes<HTMLDivElement>
  mainProps?: React.HTMLAttributes<HTMLDivElement>
}

const TemplateHub = ({ header, main, headerProps, mainProps }: OwnProps) => (
  <Grid container direction="column" spacing={4} data-dls="template-template-hub">
    <Grid item sm={12}>
      <header
        {...headerProps}
        css={
          headerProps?.css
            ? `
          ${headerProps.css}
        `
            : undefined
        }
      >
        {header}
      </header>
    </Grid>
    <Grid item sm={12}>
      <Main
        {...mainProps}
        css={
          mainProps?.css
            ? `
          ${mainProps.css}
        `
            : undefined
        }
      >
        {main}
      </Main>
    </Grid>
  </Grid>
)

export default TemplateHub
