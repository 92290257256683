import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, replace, upperFirst } from 'lodash'
import Lazyload, { forceVisible } from 'react-lazyload'
import ReactList from 'react-list'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { CatalogListContainer } from './Style'
import CatalogHeader from 'dls/organisms/CatalogHeader/CatalogHeader'
import { NoResult } from 'dls/molecules/NoResult'
import { ListItem } from 'dls/shared/styled'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  entityName: string
  list: any[]
  icons?: React.ReactNode[]
  action?: React.ReactNode
  searchFilter: (item: any, searchText: string) => boolean
  cardRenderer: (
    filteredList: any[],
    idx: string | number,
    key: string | number,
    expanded?: boolean,
    setExpanded?: (newState: boolean) => void
  ) => React.ReactNode
  placeHolder?: string
  addExpandedButton?: boolean
  listContainerStyle?: React.CSSProperties
  useReactList?: boolean
  searchButtonAriaLabel?: string
}

const Catalog = ({
  entityName,
  list,
  icons,
  action,
  cardRenderer,
  searchFilter,
  placeHolder,
  addExpandedButton,
  listContainerStyle,
  useReactList = true,
  searchButtonAriaLabel,
}: OwnProps) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState<string>('')
  const [expandedRules, setExpandedRules] = useState<string[]>([])

  const expandRules = (featureName: string) => (expand: boolean): void => {
    setExpandedRules(expand ? [featureName, ...expandedRules] : expandedRules.filter((i) => i !== featureName))
  }
  const filteredList = useMemo(
    () => (searchText === '' ? list : list.filter((item) => searchFilter(item, searchText))),
    [list, searchText]
  )

  useEffect(() => {
    forceVisible()
  }, [searchText, expandedRules, list])

  const isExpanded =
    Array.isArray(expandedRules) && Array.isArray(filteredList) && expandedRules.length === filteredList.length
  const onExpandClick = () =>
    expandedRules.length === filteredList.length
      ? setExpandedRules([])
      : setExpandedRules(filteredList.map((i) => i.ruleId))
  const expandRowIcon = isExpanded ? <ExpandLess /> : <ExpandMore />
  const AllExpandedButton = Array.isArray(filteredList) ? (
    <Button color={ColorType.NONE} css="opacity: 0.87;" startIcon={expandRowIcon} onClick={onExpandClick}>
      {isExpanded ? t('Collapse All') : t('Expand All')}
    </Button>
  ) : null

  const renderTable = (items: any, ref: any) => <CatalogListContainer ref={ref}>{items}</CatalogListContainer>

  return (
    <>
      <CatalogHeader
        title={t(upperFirst(`${entityName}`))}
        info={`${t('Showing')} ${(filteredList || []).length ? 1 : 0} – ${(filteredList || []).length} of ${
          (filteredList || []).length
        } ${entityName.toLowerCase()}`}
        search={(value) => setSearchText(value.toLowerCase())}
        isSearch={!isEmpty(list)}
        icons={icons}
        expandedButton={addExpandedButton ? AllExpandedButton : null}
        actions={action}
        value={searchText}
        searchFieldAriaLabel={`search ${entityName.toLowerCase()}`}
        searchButtonAriaLabel={searchButtonAriaLabel ?? t('Search')}
        searchFieldAriaControls={replace(`${entityName.toLowerCase()}-list-section`, ' ', '-')}
        placeHolder={placeHolder}
      />
      <main
        id={replace(`${entityName.toLowerCase()}-list-section`, ' ', '-')}
        role="region"
        aria-live="polite"
        aria-atomic="true"
        style={{
          width: '100%',
          ...listContainerStyle,
        }}
      >
        {!isEmpty(filteredList) ? (
          <ReactList
            itemsRenderer={(items: any[], ref: any) => renderTable(items, ref)}
            itemRenderer={(idx: any, key: any) => (
              <ListItem key={`catalog-list-items-wrapper-${key}`}>
                {useReactList ? (
                  <Lazyload height={88} key={key}>
                    {cardRenderer(
                      filteredList,
                      idx,
                      key,
                      expandedRules.includes(list[idx].ruleId),
                      expandRules(list[idx].ruleId)
                    )}
                  </Lazyload>
                ) : (
                  cardRenderer(
                    filteredList,
                    idx,
                    key,
                    expandedRules.includes(list[idx].ruleId),
                    expandRules(list[idx].ruleId)
                  )
                )}
              </ListItem>
            )}
            type="uniform"
            length={filteredList.length}
          />
        ) : (
          <div
            style={{
              height: 'calc(100vh - 300px)',
              justifyContent: 'center',
              alignContent: 'center',
              display: 'flex',
            }}
          >
            <NoResult searchText={searchText} id="NoResult" style={{ margin: 'auto' }} />
          </div>
        )}
      </main>
    </>
  )
}

export default Catalog
