import React from 'react'
import { ReviewRowItem } from './types'
import ReviewRow from './ReviewRow'
import { FeatureDefinitionList } from './styles'

interface OwnProps {
  definitionList: Array<ReviewRowItem>
  ariaLabelledBy?: string
}

const ReviewDefinition = ({ definitionList, ariaLabelledBy }: OwnProps) => (
  <>
    {definitionList.length > 0 && (
      <FeatureDefinitionList data-dls="molecules-ReviewRow" aria-labelledby={ariaLabelledBy}>
        {definitionList.map((listItem, idx) => {
          const { term, definitions, itemSeparator } = listItem
          const isTermString = typeof term === 'string'
          return (
            <ReviewRow
              key={isTermString ? `definition-row-${term}-${idx}` : `definition-row-${definitions.length}-${idx}`}
              term={term}
              definitions={definitions}
              itemSeparator={itemSeparator}
            />
          )
        })}
      </FeatureDefinitionList>
    )}
  </>
)

export default ReviewDefinition
