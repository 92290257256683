import { createAction } from '@reduxjs/toolkit'
import { Message, ActivityPayload } from './types'

const actions = {
  fetch: createAction<ActivityPayload>('MESSAGES/FETCH'),
  fetchEmpty: createAction<ActivityPayload>('MESSAGES/FETCH/EMPTY'),
  fetchSuccess: createAction<Message[]>('MESSAGES/FETCH/SUCCESS'),
  fetchFail: createAction<Error>('MESSAGES/FETCH/FAIL'),
}

export default actions
