import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import DashboardHeader from './DashboardHeader'
import LoaderSuspense from './components/LoaderSuspense'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import { UserInformation, UserRole } from 'store/user/types'
import { Product } from 'store/products/types'
import { TenantConfig, Tenant } from 'store/tenant-config/types'
import { selectUserRole } from 'store/user/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'

const AccountsRoleDashboard = React.lazy(() => import('./components/AccountRoleDashboard'))
const MultiTenantParentDashboard = React.lazy(() => import('./components/MultiTenantParentDashboard'))
const MultiTenantSingleDashboard = React.lazy(() => import('./components/MultiTenantSingleDashboard'))
const SingleTenantDashboard = React.lazy(() => import('./components/SingleTenantDashboard'))

export interface Props {
  userInformation?: UserInformation
  allProductData?: Product[]
  loadProduct?: boolean
  tenantConfig?: TenantConfig
  selectSelectedTenantIndex?: number
}

const Dashboard = ({ userInformation, tenantConfig }: Props) => {
  const isMultiTenant = useSelector(TenantConfigSelectors.isMultiTenant)
  const isParent = useSelector(TenantConfigSelectors.isParentTenant)
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)
  const userRole = useSelector(selectUserRole)

  const renderDashboard = ({
    role,
    hasMultiTenant,
    isParentTenant,
    tenants,
  }: {
    role?: UserRole
    hasMultiTenant: boolean
    isParentTenant: boolean
    tenants: Tenant[]
  }) => {
    if (!role) {
      return null
    }

    if (role === UserRole.ACCOUNTS) {
      return <AccountsRoleDashboard userRole={role} />
    }

    if (hasMultiTenant && isParentTenant) {
      return (
        <MultiTenantParentDashboard
          userRole={role}
          tenants={tenants}
          showWithHoldingTax={tenantConfig?.features?.withholding_tax as boolean}
        />
      )
    }
    if (hasMultiTenant && !isParentTenant) {
      return <MultiTenantSingleDashboard userRole={role} />
    }
    if (!hasMultiTenant) {
      return (
        <SingleTenantDashboard
          userRole={role}
          showWithHoldingTax={tenantConfig?.features?.withholding_tax as boolean}
        />
      )
    }

    return null
  }

  return (
    <div data-test-id="dashboard-page">
      {userInformation && (
        <DashboardHeader
          firstName={userInformation.firstName}
          lastName={userInformation.lastName}
          tenantName={isMultiTenant ? currentTenant?.name : ''}
          isParent={!!isParent}
        />
      )}
      <ContentContainer style={{ paddingBottom: 32 }}>
        <Suspense fallback={<LoaderSuspense />}>
          {renderDashboard({
            role: userRole,
            hasMultiTenant: isMultiTenant,
            isParentTenant: !!isParent,
            tenants: tenantConfig?.tenants ?? [],
          })}
        </Suspense>
      </ContentContainer>
    </div>
  )
}

export default Dashboard
