import React from 'react'
import styled from 'styled-components/macro'
import { CircularProgress, CircularProgressProps } from '@material-ui/core'
import { loaderColorSelector } from 'dls/shared/styled'
import { GenericSize, ColorType } from 'dls/shared/types'

export const loaderSizes = {
  [GenericSize.ES]: 16,
  [GenericSize.SM]: 24,
  [GenericSize.MD]: 36,
  [GenericSize.LG]: 48,
  [GenericSize.XL]: 75,
}

interface OwnProps extends Omit<CircularProgressProps, 'color'> {
  shrink?: boolean
  color?: ColorType
  style?: React.CSSProperties
  value?: number
}

const LoaderWrapper = styled.div<OwnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ shrink }) =>
    shrink
      ? 'inherit'
      : '100vh'}; /* this just expands the body height so the vertical alignment is visible in the snippet */
`

const StyledCircularProgress = styled(CircularProgress)<{ $color?: ColorType }>`
  color: ${loaderColorSelector};
`

const Loader = ({
  shrink = false,
  size = GenericSize.XL,
  color = ColorType.PRIMARY,
  value = 25,
  style,
}: OwnProps): React.ReactElement => {
  const customSize = loaderSizes[size]

  return (
    <LoaderWrapper shrink={shrink} style={style} data-dls="atom-loader">
      <StyledCircularProgress size={customSize} $color={color} value={value} />
    </LoaderWrapper>
  )
}

export default Loader
