import { createReducer } from '@reduxjs/toolkit'
import { UserState } from './types'
import actions from './actions'
import { AUTH_TYPE } from 'api/client/authAwareAxios'

const initialState = {
  loading: false,
  authenticated: false,
  error: undefined,
  data: undefined,
  users: [],
  teams: [],
  selectedUser: undefined,
  selectedTeam: undefined,
  selectedTenantIndex: undefined,
  authType: AUTH_TYPE.SSO,
}

const reducer = createReducer<UserState>(initialState, {
  [actions.login.type]: (state) => ({ ...state, loading: true }),
  [actions.loginSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    authenticated: true,
    data: action.payload.userData,
    authType: action.payload.authType,
  }),
  [actions.loginFail.type]: (state, action) => ({ ...state, loading: false, error: action.payload }),
  [actions.loginReset.type]: (state) => ({ ...state, loading: false, error: undefined }),
  [actions.getUser.type]: (state) => ({ ...state, loadingUser: true }),
  [actions.getUserSuccess.type]: (state, action) => ({
    ...state,
    loadingUser: false,
    users: action.payload,
  }),
  [actions.getUserFail.type]: (state, action) => ({
    ...state,
    loadingUser: false,
    error: action.payload,
  }),
  [actions.createUser.type]: (state) => ({ ...state, loading: false }),
  [actions.createUserSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    selectedUser: action.payload,
  }),
  [actions.createUserFail.type]: (state) => ({ ...state, loading: false }),

  [actions.logout.type]: () => initialState,
  [actions.logoutFail.type]: (state, action) => ({ ...state, loading: false, error: action.payload }),

  [actions.getTeams.type]: (state) => ({ ...state, loading: true }),
  [actions.getTeamsSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    teams: action.payload,
  }),
  [actions.getTeamsFail.type]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [actions.createTeam.type]: (state) => ({ ...state, loading: false }),
  [actions.createTeamSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    selectedTeam: action.payload,
  }),
  [actions.createTeamFail.type]: (state) => ({ ...state, selectedUser: null, loading: false }),
  [actions.getUserDetails.type]: (state) => ({ ...state, loading: true }),
  [actions.getUserDetailsSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    selectedUser: action.payload,
  }),
  [actions.getUserDetailsFail.type]: (state) => ({ ...state, loading: false }),
  [actions.setSelectedTenantIndexSuccess.type]: (state, action) => ({
    ...state,
    selectedTenantIndex: action.payload,
    loading: false,
  }),
})

export default reducer
