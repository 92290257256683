import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductsSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'
import { CardImage } from 'store/products/types'

export default (currentImage: CardImage | null) => {
  const cardImages = useSelector(ProductsSelectors.selectCardImage)
  const isLoading = useSelector(ProductsSelectors.selectCardImageLoading)
  const error = useSelector(ProductsSelectors.selectCardImageError)
  const dispatch = useDispatch()
  const [selectedImage, setSelectedImage] = useState<CardImage | null>(currentImage)

  useEffect(() => {
    dispatch(ProductsActions.fetchCardImages())
  }, [dispatch])

  return { cardImages, isLoading, error, selectedImage, setSelectedImage }
}
