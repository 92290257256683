import React from 'react'
import styled from 'styled-components/macro'
import { List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FilterList } from '@material-ui/icons'
import { CreditOrDebit, TransactionStatus } from 'store/accounts/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { filterSelectedBackgroundColor } from 'utils/customColors'
import { FilterListButton } from 'dls/molecules/IconButtons/IconButtons'

export interface FilterOptionsProps {
  label?: React.ReactNode | string | React.FunctionComponent<{ isActive: boolean }>
  from?: number
  to?: number
  is?: boolean
  status?: Array<TransactionStatus | ProductStatus | PackagesStatus>
  data?: CreditOrDebit
  isScheduled?: boolean
}

interface FilterMenuProps {
  filter: number
  setFilter: (filter: number) => void
  filterOptions: FilterOptionsProps[]
  labelPrefix?: React.ReactNode | string
  labelPostfix?: React.ReactNode | string
  showAllButton?: boolean
  ariaControls?: string
  ariaLabel?: string
}

const StyledList = styled(List)`
  margin-left: -16px;
`

const FilterMenu = ({
  filter,
  setFilter,
  filterOptions,
  labelPrefix,
  labelPostfix,
  showAllButton = true,
  ariaControls,
  ariaLabel,
}: FilterMenuProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl(null)
    setFilter(index)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const LabelElement = filterOptions[filter]?.label as React.FunctionComponent<{ isActive: boolean }>

  const shouldShowSelectedItem = showAllButton || filter !== 0

  return (
    <>
      {shouldShowSelectedItem ? (
        <StyledList>
          <ListItem button aria-haspopup="true" aria-controls="filter-menu" onClick={handleClickListItem}>
            <ListItemText
              primary={
                <>
                  {labelPrefix}
                  {typeof LabelElement === 'string' ? LabelElement : <LabelElement isActive />}
                </>
              }
              style={{ whiteSpace: 'nowrap', flex: 'unset' }}
              primaryTypographyProps={{ style: { fontSize: 14 } }}
            />
            <FilterList style={{ verticalAlign: 'middle', marginLeft: '8px' }} />
            {labelPostfix}
          </ListItem>
        </StyledList>
      ) : (
        <FilterListButton
          aria-haspopup="true"
          aria-controls="filter-menu"
          aria-label={ariaLabel}
          onClick={handleClickListItem}
        />
      )}
      <Menu id="filter-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {filterOptions &&
          filterOptions.map((option, index) => {
            const LabelOptionElement = option.label as React.FunctionComponent<{ isActive: boolean }>
            return (
              <MenuItem
                key={index}
                style={index === filter ? { fontWeight: 'bold', backgroundColor: filterSelectedBackgroundColor } : {}}
                onClick={(event) => handleMenuItemClick(event, index)}
                aria-controls={ariaControls}
              >
                {typeof option.label === 'string' ? (
                  t(option.label)
                ) : (
                  <LabelOptionElement isActive={index === filter} />
                )}
              </MenuItem>
            )
          })}
      </Menu>
    </>
  )
}

export default FilterMenu
