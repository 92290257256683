/* eslint-disable no-nested-ternary */
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { GenerationStrategy, Process, ProcessFormValues } from 'store/governance/types'
import { LifeCycle } from 'store/governance/typings/lifeCycle'
import { ProcessExecution } from 'store/tasks/types'
import { ProductKey } from 'store/products/types'

const createGovernanceFetch = async () => createAsyncAuthAwareAxios('/v1/governance')

async function getProcessExecutions(entityKey: ProductKey): Promise<ProcessExecution[]> {
  const { data } = await (await createGovernanceFetch()).get<ProcessExecution[]>(`/processExecutions`, {
    params: {
      entityKey,
    },
  })
  return data
}

async function getProcess(): Promise<Process[]> {
  const { data } = await (await createGovernanceFetch()).get<Process[]>('/processDefinitions')
  return data
}

async function createProcess(payload: Partial<ProcessFormValues>) {
  const updatedData = {
    entityLifecycle: payload.entityLifecycle,
    entityType: payload.entityType,
    featureTasksGenerationStrategy: payload.featureTasksGenerationStrategy
      ? GenerationStrategy.ONE_FOR_EACH
      : GenerationStrategy.NONE,
    name:
      payload.entityLifecycle === LifeCycle.CREATE
        ? 'For creating a product'
        : payload.entityLifecycle === LifeCycle.UPDATE
        ? 'For updating a product'
        : '',
  }
  const { data } = await (await createGovernanceFetch()).post<Process>(`/processDefinitions`, updatedData)
  return data
}

async function updateProcess(payload: ProcessFormValues) {
  const updatedData = {
    entityLifecycle: payload.entityLifecycle,
    entityType: payload.entityType,
    featureTasksGenerationStrategy: payload.featureTasksGenerationStrategy
      ? GenerationStrategy.ONE_FOR_EACH
      : GenerationStrategy.NONE,
    name: payload.name,
  }
  const { data } = await (await createGovernanceFetch()).patch<ProcessFormValues>(
    `/processDefinitions/${payload.key}`,
    updatedData
  )
  return data
}

export default { getProcessExecutions, getProcess, createProcess, updateProcess }
