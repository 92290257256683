import React from 'react'
import { Field, useFormikContext, getIn } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link as LinkIcon } from '@material-ui/icons'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextFieldComplex from '../../../components/form/TextFieldComplex'
import { HeadingTypography } from '../Styled'
import { SelectFieldComplex, RepeaterLayout } from 'components'
import { TermsAndConditionsFileFormat } from 'store/products/types'
import { DarkDivider } from 'components/form/TableComp/TableComp'

interface Props {
  name: string
  isDisabled?: boolean
}

const CustomDocument = ({ name, isDisabled = false }: Props) => {
  const { t } = useTranslation()
  const { isValid, values } = useFormikContext()
  const files = getIn(values, `${name}.files`)
  const lastFileIndex = files ? files.length - 1 : 0
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          label={t('Name')}
          name={`${name}.name`}
          component={TextFieldComplex}
          required
          fullWidth
          disabled={isDisabled}
          placeholder={t('Enter a name')}
          inputLabelProps={{ shrink: true }}
          id={`${name}.name`}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('Description')}
          name={`${name}.description`}
          component={TextFieldComplex}
          required
          fullWidth
          disabled={isDisabled}
          placeholder={t('Enter a description')}
          inputLabelProps={{ shrink: true }}
          id={`${name}.description`}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          label={t('Version')}
          name={`${name}.version`}
          component={TextFieldComplex}
          required
          fullWidth
          disabled={isDisabled}
          placeholder={t('Enter a version e.g. 1.0')}
          inputLabelProps={{ shrink: true }}
          id={`${name}.version`}
        />
      </Grid>
      <Grid container item xs={12} direction="column">
        <Grid item>
          <HeadingTypography variant="h6">{t('Files')}</HeadingTypography>
        </Grid>
        <Grid item>
          <RepeaterLayout
            name={`${name}.files`}
            labelDelete={t('Delete file')}
            labelNewItem={t('Add another file')}
            newItem={{
              url: '',
              label: '',
              format: null,
            }}
            isItemDeletable={() => lastFileIndex > 0}
            isAddDisabled={!isValid}
            deleteButtonStyle={{ marginTop: 70, top: 7 }}
            newItemButtonStyle={{ marginBottom: 22 }}
          >
            {({ name: itemName, index }) => (
              <Grid container direction="row" spacing={2} key={`${itemName}.${lastFileIndex}.${index}`}>
                <Grid item xs={6} key={`${itemName}.${index}.label`}>
                  <Field
                    label={t('Label')}
                    name={`${itemName}.label`}
                    id={`${itemName}.label`}
                    component={TextFieldComplex}
                    required
                    fullWidth
                    placeholder={t('Enter a label')}
                  />
                </Grid>
                <Grid item xs={6} key={`${itemName}.${index}.format`}>
                  <Field
                    label={t('Format')}
                    name={`${itemName}.format`}
                    id={`${itemName}.format`}
                    component={SelectFieldComplex}
                    required
                    fullWidth
                    disabled={isDisabled}
                    placeholder={t('Please select')}
                    data={TermsAndConditionsFileFormat}
                    inputLabelProps={{ shrink: true }}
                    selectProps={{ displayEmpty: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={t('URL')}
                    name={`${itemName}.url`}
                    id={`${itemName}.url`}
                    component={TextFieldComplex}
                    required
                    fullWidth
                    disabled={isDisabled}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={`${t('Enter a URL e.g.')} https://www.mydomain.com`}
                    inputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {files.length > 1 && index !== files.length - 1 && (
                  <Grid xs={12} item>
                    <DarkDivider />
                  </Grid>
                )}
              </Grid>
            )}
          </RepeaterLayout>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomDocument
