import React from 'react'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AccessibleMenu, { AccessibleMenuItem } from 'components/AccessibleMenu/AccessibleMenu'
import { stopPropagation } from 'utils/form.utils'
import { DeleteOrRetire } from 'store/products/types'
import { MoreVertButton } from 'dls/molecules/IconButtons/IconButtons'
import { ColorType } from 'dls/shared/types'
import { ProductSyndication } from 'store/products/typings/productSyndication'
import { LinkWithText } from 'dls/molecules/Buttons'
import { ArchiveIcon, DeleteIcon, ExpandMoreIcon, VisibilityIcon } from 'dls/atoms/Icons/MUIIcons'
import { ProductStatus } from 'store/products/typings/productStatus'

interface Props {
  itemStatus: ProductStatus
  productSyndication: ProductSyndication
  iconButton?: boolean
  openConfirm: (newActonType: DeleteOrRetire) => any
  closeConfirmAndMenu: (e: React.MouseEvent) => void
  anchorEl: null | HTMLElement
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>
}

const SyndicationCatalogItemActions = ({
  itemStatus,
  productSyndication,
  iconButton = false,
  closeConfirmAndMenu,
  openConfirm,
  anchorEl,
  setAnchorEl,
}: Props) => {
  const { t } = useTranslation()

  function openMenu(e: React.MouseEvent<HTMLButtonElement>) {
    stopPropagation(e)
    setAnchorEl(e.currentTarget)
  }

  const id = `syndication-menu-item-${productSyndication.productKey}`

  let showButtonsMatrix = {
    edit: true,
    delete: true,
    retire: true,
  }

  switch (itemStatus) {
    case ProductStatus.DESIGN:
      showButtonsMatrix = {
        edit: true,
        delete: true,
        retire: false,
      }
      break
    case ProductStatus.LIVE:
      showButtonsMatrix = {
        edit: true,
        delete: false,
        retire: true,
      }
      break
    case ProductStatus.CLOSED:
      showButtonsMatrix = {
        edit: true,
        delete: false,
        retire: false,
      }

      break
    default:
      break
  }

  const menuItems: AccessibleMenuItem[] = []

  if (showButtonsMatrix.edit) {
    menuItems.push({
      internalUrl: `${productSyndication.productKey}?mode=edit`,
      testId: 'product-action-edit-button',
      children: (
        <>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary={t('View')} />
        </>
      ),
    })
  }
  if (showButtonsMatrix.delete) {
    menuItems.push({
      // onClick: openConfirm(DeleteOrRetire.DELETEPRODUCT),
      onClick: () => {
        // Temp need to declare as its declared on line 94 the above one,
        // but Jon doesn't want it to click yet
        // hack so that the openconfirm function is used otherwise eslint and ts
        // complain on line 30.
        const disableClick = true
        if (!disableClick) {
          openConfirm(DeleteOrRetire.DELETEPRODUCT)
        }
      },
      testId: 'product-action-edit-button',
      children: (
        <>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={t('Delete')} />
        </>
      ),
    })
  }

  if (showButtonsMatrix.retire) {
    menuItems.push({
      // onClick: openConfirm(DeleteOrRetire.RETIREPRODUCT),
      onClick: () => {
        // Temp need the above one, but Jon doesn't want it to click yet
      },
      testId: 'product-action-edit-button',
      children: (
        <>
          <ListItemIcon>
            <ArchiveIcon />
          </ListItemIcon>
          <ListItemText primary={t('Retire')} />
        </>
      ),
    })
  }
  const menuButton = iconButton ? (
    <MoreVertButton
      size="medium"
      onClick={openMenu}
      aria-label="More actions"
      aria-controls={id}
      aria-haspopup="true"
      disabled={menuItems.length === 0}
    />
  ) : (
    <>
      <LinkWithText
        onClick={openMenu}
        aria-label="More actions"
        aria-controls={id}
        aria-haspopup="true"
        color={ColorType.BUTTON}
        disableElevation
        disableFocusRipple
        disableRipple
        endIcon={<ExpandMoreIcon color={ColorType.BUTTON} />}
        disabled={menuItems.length === 0}
        style={{
          backgroundColor: 'transparent',
          fontSize: 'inherits',
          margin: '0 3px',
          padding: 0,
        }}
        variant="text"
      >
        {t('ACTIONS')}
      </LinkWithText>
    </>
  )
  return menuItems.length > 0 ? (
    <>
      <AccessibleMenu
        id={id}
        anchorEl={anchorEl as any}
        menuItems={menuItems}
        menuButton={menuButton}
        onClose={closeConfirmAndMenu}
      />
    </>
  ) : null
}

export default SyndicationCatalogItemActions
