import React, { useCallback, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import ProductsSelectors from 'store/products/selectors'
import { BackButton, Loader, NoActivityHelpPanel } from 'components'
import { ExtendedProduct } from 'store/products/types'
import ContentContainer from 'pages/Layout/ContentContainer'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import BreadcrumbsActions from 'store/breadcrumbs/actions'

export interface FeatureFormPageProps {
  product?: ExtendedProduct
}
interface Props {
  FormPage: React.ElementType<FeatureFormPageProps>
  context: string
  browserHistoryBackLink?: boolean
  customBreadcrumbs?: boolean
}

const StyledFeaturePageGridActivity = styled(Grid)`
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: 16px;
  }
`

const StyledFeaturePageGrid = styled(Grid)`
  padding-top: 0;
  margin-top: 0;
`

const ButtonFeaturePageContainer = styled.div`
  align-items: center;
  padding-left: 0;
  padding-top: 0;
  margin-bottom: -20px;
`

export const FeatureContainer = (props: Props) => {
  const { context, browserHistoryBackLink, FormPage, customBreadcrumbs } = props
  const navigate = useNavigate()
  const updating = useSelector(ProductsSelectors.selectUpdating)
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  const { productKey = '' } = useParams()

  if (!productKey) throw new Error('productKey not found')
  const [selectedProduct, isLoading] = useSelectedProduct(productKey)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onPressBack = useCallback(() => {
    navigate(browserHistoryBackLink ? (-1 as any) : `/products/${productKey}`)
  }, [productKey])

  useEffect(() => {
    if (!selectedProduct || customBreadcrumbs) return

    dispatch(
      BreadcrumbsActions.updateBreadCrumbs([
        { title: t('{{tenantName}} products', { tenantName: currentTenant?.name }), path: `/products/` },
        {
          title: selectedProduct.productName || productKey || '',
          path: `/products/${productKey}`,
        },
        { title: t(context) || '', path: '' },
      ])
    )
  }, [selectedProduct, t, context, productKey])

  if (isLoading) {
    return <Loader />
  }

  if (!selectedProduct) return null

  return (
    <ContentContainer>
      <ButtonFeaturePageContainer>
        <BackButton onPressBack={onPressBack} aria-controls="main" />
      </ButtonFeaturePageContainer>
      <StyledFeaturePageGrid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {updating ? <Loader /> : <FormPage product={selectedProduct} />}
        </Grid>
        <StyledFeaturePageGridActivity xs={12} item sm={12} md={12} lg={4}>
          <NoActivityHelpPanel id={`${context}-help-panel`} />
        </StyledFeaturePageGridActivity>
      </StyledFeaturePageGrid>
    </ContentContainer>
  )
}

export default FeatureContainer
