import styled from 'styled-components/macro'
import { DialogContent, Grid, Typography } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'

export const IconGrid = styled(Grid)`
  margin-top: 40px !important;
`
export const DialogStyleContent = styled(DialogContent)`
  padding: 24px 32px;
  overflow-x: hidden;
`
export const Title = styled(Typography)`
  font-size: 18px;
  padding-bottom: 6px;
`

export const StyledTableCell1 = styled(TableCell)`
  width: 28%;
  border-bottom: none;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding: 8px 0 0;
`
export const StyledTableCell2 = styled(TableCell)`
  width: 36%;
  border-bottom: none;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding: 8px 16px 0;
`

export const ModalItems = styled(Grid)`
  margin-top: -8px !important;
  .MuiTypography-subtitle2 {
    font-weight: 500;
    margin-bottom: 8px;
  }
`
