import { TableBody, TableRow, TableCell, Checkbox, Collapse, Box } from '@material-ui/core'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { isEmpty, get, omit, kebabCase } from 'lodash'
import { TableCommonBodyProps, TableCommonRowType, TableParam } from './types'
import { NoResult } from 'dls/molecules/NoResult'
import { stopPropagation } from 'utils/form.utils'
import { useTableDispatch } from 'context/TableContext'

const StyledTableCell = styled(TableCell)``
const StyledTableRow = styled(TableRow)`
  &.Mui-selected {
    background-color: ${(props: any) => props.theme.palette.background.default};
  }
`

const TTRow = (props: TableCommonRowType) => {
  const {
    row,
    selectAll,
    mode,
    tableProps: {
      headRows,
      isSelectableRow,
      isDisabled,
      rowCount,
      onClickSelectRow,
      isSelected,
      selectableCellProps,
      getExpandedContent,
      fullCellSelectable,
      isThisRowSelectable,
      rowSelectableComponent,
    },
  } = props
  const [rowExpanded, setRowExpanded] = React.useState(false)
  const dispatch = mode === TableParam.OPTIMIZE ? useTableDispatch() : false
  const [checkItem, setCheckItem] = React.useState(false)
  const tableRowId = `table_row_${row.transactionId}`
  const expandedRowId = `expanded_content_${row.transactionId}`
  const isSelectableRowComponent = rowSelectableComponent ? rowSelectableComponent(row) : false

  const onCheck = (e: React.MouseEvent) => {
    stopPropagation(e)
    if (row._key && (!isThisRowSelectable || isThisRowSelectable(row))) {
      if (mode === TableParam.OPTIMIZE && !selectAll && dispatch) {
        if (checkItem) {
          setCheckItem(false)
          dispatch({
            type: 'REMOVE',
            id: row._key,
          })
        } else {
          setCheckItem(true)
          dispatch({
            type: 'CREATE',
            id: row._key,
          })
        }
      } else {
        onClickSelectRow(row._key)
      }
    }
  }

  const Selector = () => {
    const dataTestId = kebabCase(`package-product-checkbox-${row.productName}`)
    return (
      isSelectableRowComponent || (
        <Checkbox
          checked={selectAll || mode !== TableParam.OPTIMIZE ? isSelected(row._key || '') : checkItem}
          onClick={onCheck}
          disabled={isDisabled}
          inputProps={
            {
              'data-test-id': dataTestId,
              'aria-label': `Select product`,
            } as any
          }
        />
      )
    )
  }

  useEffect(() => {
    if (mode === TableParam.OPTIMIZE && selectAll) {
      setCheckItem(false)
    }
  }, [selectAll, mode])

  return (
    <>
      <StyledTableRow
        hover
        tabIndex={-1}
        selected={rowExpanded || isSelected(row._key || '')}
        onClick={() => {
          setRowExpanded(!rowExpanded)
        }}
        data-test-id="package-product-row"
        {...(isSelectableRow && { role: 'button', 'aria-expanded': rowExpanded, 'aria-controls': expandedRowId })}
        id={tableRowId}
      >
        {isSelectableRow && (
          <TableCell
            padding="checkbox"
            {...selectableCellProps}
            {...(fullCellSelectable && fullCellSelectable.includes(-1) && !rowSelectableComponent
              ? { onClick: onCheck }
              : {})}
          >
            {rowCount > 0 && (!isThisRowSelectable || isThisRowSelectable(row)) && Selector()}
          </TableCell>
        )}
        {headRows &&
          headRows.map((column, key) => (
            <StyledTableCell
              key={key}
              {...omit(column, ['getCellContent', 'getCellOrderString', 'orderKey', 'disableOrder'])}
              css={column.css}
              {...(!isSelectableRowComponent && fullCellSelectable && fullCellSelectable.includes(key)
                ? { onClick: onCheck }
                : {})}
            >
              {column.getCellContent
                ? column.getCellContent(row, { rowExpanded, setRowExpanded })
                : get(row, column.id)}
            </StyledTableCell>
          ))}
      </StyledTableRow>
      {getExpandedContent && (
        <StyledTableRow
          hover
          tabIndex={-1}
          selected={rowExpanded}
          id={expandedRowId}
          role="region"
          aria-labelledby={tableRowId}
        >
          <TableCell
            style={rowExpanded ? { paddingBottom: 0, paddingTop: 0 } : { paddingBottom: 0, paddingTop: 0, border: 0 }}
            colSpan={headRows.length + (isSelectableRow ? 1 : 0)}
          >
            <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
              <Box margin={1}>{getExpandedContent(row)}</Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      )}
    </>
  )
}

function areEqual(prevProps: TableCommonRowType, nextProps: TableCommonRowType) {
  return !!(
    prevProps.row &&
    nextProps.row &&
    nextProps.row._key &&
    prevProps.row._key &&
    prevProps.row._key === nextProps.row._key &&
    prevProps.selectAll === nextProps.selectAll &&
    prevProps.tableProps &&
    nextProps.tableProps &&
    prevProps.tableProps.isSelected &&
    nextProps.tableProps.isSelected &&
    prevProps.tableProps.isThisRowSelectable &&
    nextProps.tableProps.isThisRowSelectable &&
    prevProps.tableProps.value &&
    nextProps.tableProps.value &&
    prevProps.tableProps.isSelected(prevProps.row._key || '') ===
      nextProps.tableProps.isSelected(nextProps.row._key || '') &&
    prevProps.tableProps.isThisRowSelectable(prevProps.row) ===
      nextProps.tableProps.isThisRowSelectable(nextProps.row) &&
    prevProps.tableProps.value === nextProps.tableProps.value
  )
}

const TTRowMemo = React.memo(TTRow, areEqual)

const TTableBody = (props: TableCommonBodyProps) => {
  const {
    rows,
    page,
    mode,
    rowsPerPage,
    emptyRows,
    disableInsideSortPagination,
    headRows,
    isSelectableRow,
    searchText,
    selectAll,
  } = props

  return (
    <TableBody>
      {!isEmpty(rows)
        ? rows
            ?.slice(
              disableInsideSortPagination ? 0 : page * rowsPerPage,
              disableInsideSortPagination ? rows.length : page * rowsPerPage + rowsPerPage
            )
            .map((row, index) => (
              <TTRowMemo selectAll={selectAll} mode={mode} key={row._key || index} row={row} tableProps={props} />
            ))
        : null}
      {isEmpty(rows) ? (
        <StyledTableRow>
          <TableCell colSpan={headRows.length + (isSelectableRow ? 1 : 0)} style={{ textAlign: 'center' }}>
            <div
              style={{
                height: 'calc(100vh - 370px)',
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
              }}
            >
              <NoResult searchText={searchText} id="NoResult" style={{ margin: 'auto' }} />
            </div>
          </TableCell>
        </StyledTableRow>
      ) : null}
      {emptyRows > 0 ? (
        <StyledTableRow style={{ height: 49 * emptyRows }}>
          <TableCell colSpan={headRows.length + (isSelectableRow ? 1 : 0)} />
        </StyledTableRow>
      ) : null}
    </TableBody>
  )
}

export default TTableBody
