import React from 'react'
import { DialogContent } from '@material-ui/core'
import styled from 'styled-components/macro'

interface Props {
  title: React.ReactNode
  children: React.ReactNode
}

const Wrapper = styled(DialogContent)`
  padding: 24px !important;
`

const Title = styled.div`
  padding-bottom: 6px;
`

const ModalWrapper = (props: Props) => {
  const { title, children } = props

  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  )
}

export default ModalWrapper
