import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { PackagesWithProducts } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { LogoType } from 'store/tenant-config/types'
import { HeaderTenantLogo } from 'components/ActualTenantLogo/ActualTenantLogo'
import { toBankTz } from 'utils/date.utils'
import { H1 } from 'dls'

interface Props {
  selectedPackage: PackagesWithProducts
}

const ProductNameDiv = styled.div`
  overflow-wrap: anywhere;
`

const HeaderGrid = styled(Grid)`
  padding: 40px 0;
  margin-top: 16px;
  margin-bottom: 20px;

  ${(props: any) => `${props.theme.breakpoints.down('sm')}`} {
    padding: 8px 0;
  }
`

const PackageHeader = ({ selectedPackage }: Props): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <Grid container direction="row" justifyContent="space-between" spacing={2} alignItems="center" id="productHeader">
      <HeaderGrid item xs={12}>
        <ProductNameDiv>
          <H1 data-test-id="package-name" gutterBottom>
            <HeaderTenantLogo type={LogoType.COLLAPSE} showIcon={false} />
            {selectedPackage && selectedPackage.productPackageName}
          </H1>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
            <Grid item>
              <Typography data-test-id="package-published-date" variant="subtitle1">
                {selectedPackage &&
                  selectedPackage.publicationDate &&
                  [PackagesStatus.LIVE].includes(selectedPackage.packageStatus) && (
                    <>
                      {t('Effective date')} : {toBankTz(selectedPackage.publicationDate).dateTime}
                    </>
                  )}{' '}
              </Typography>
            </Grid>
          </Grid>
        </ProductNameDiv>
      </HeaderGrid>
    </Grid>
  )
}

export default PackageHeader
