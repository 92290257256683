import React from 'react'
import { Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import { kebabCase } from 'lodash'
import { EntityBadgeGovernance } from 'dls/atoms'
import { Process } from 'store/governance/types'
import GovernanceActions from 'store/governance/actions'
import TaskAction from 'store/tasks/actions'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { Body1 } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  currentProcess: Process
}

const GovernanceCard = ({ currentProcess }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleViewPackage = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(`/governance/${currentProcess.key}`)
    dispatch(GovernanceActions.selectProcessDetails(currentProcess))
    dispatch(TaskAction.getTasksDefinition(currentProcess.key))
  }

  return (
    <CatalogCard
      region1={[
        <EntityBadgeGovernance key="1" />,
        <Body1 variantMapping={{ body1: 'div' }} key="2">
          {currentProcess.name}
        </Body1>,
      ]}
      region3={[
        <Typography style={{ display: 'inline-flex', whiteSpace: 'nowrap' }} component="div" key="1">
          {currentProcess.featureTasksGenerationStrategy !== 'NONE' ? (
            <CheckIcon style={{ marginRight: 8 }} />
          ) : (
            <ClearIcon style={{ marginRight: 8 }} />
          )}
          {t('Feature approval')}
        </Typography>,
      ]}
      region4={[
        <Button color={ColorType.BUTTON} size="small" onClick={handleViewPackage} aria-controls="main" key="1">
          {t('View')}
        </Button>,
      ]}
      onClickOnCard={handleViewPackage}
      id={`governance-card-${kebabCase(currentProcess.name)}`}
    />
  )
}

export default GovernanceCard
