import React, { useCallback } from 'react'
import { Grid, Typography, Link } from '@material-ui/core'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { find, isEmpty } from 'lodash'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { StyledAvatar } from 'components/StatusBadge/Styled'
import { BackButton, SidePanel, FormContainer } from 'components'
import { Product } from 'store/products/types'
import { Colleague } from 'store/user/types'
import { getVersionAndlink, getVersionStatus, getVersionDateStatus } from 'pages/Versions/version.utils'
import { ProductVersionsInfoGraphic } from 'assets'
import { getProductStatusLabel } from 'pages/Product/product.utils'
import { toBankTz } from 'utils/date.utils'
import { useUserNameWithRole } from 'utils/common.util'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'
import { H1, Body2, Caption } from 'dls/atoms/Typography'

interface Props {
  productVersions: Product[]
  currentProduct: Product
  users: Colleague[]
}

const ButtonContainer = styled.div`
  align-items: center;
  padding-left: 8px;
  padding-top: 0;
`

const StyledGrid = styled(Grid)`
  padding-top: 0 !important;
  margin-top: 0;
`

const StyledGridActivity = styled(Grid)`
  padding-top: 0 !important;
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: 16px;
  }
`

const ContentGridContainer = styled(Grid)`
  padding: 0 24px 24px;
`

const StyleSeparator = styled.span`
  color: ${(props: any) => props.theme.palette.text.secondary};
  margin-left: 8px;
  margin-right: 8px;
`
const GridBorder = styled(Grid)`
  border-left: 1px solid ${(props: any) => props.theme.palette.primary.greyLight};
  margin-left: 10px;
`
export const DateTypography = styled(Caption)`
  color: ${(props: any) => props.theme.palette.text.secondary};
`

const Products = ({ productVersions, currentProduct, users }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const getUserNameWithRole = useUserNameWithRole()
  const onPressBack = useCallback(() => {
    navigate(-1)
  }, [])

  const renderVersionHistory = () => {
    if (isEmpty(productVersions)) {
      return null
    }

    let prevVersion: any = null
    const productVersionLength = productVersions.length
    const firstVersion = productVersions[productVersionLength - 1].version

    return productVersions.map((productVersion, index) => {
      const getParsedDate = () => {
        if (productVersion.createdDate) {
          return toBankTz(productVersion.effectiveDate || productVersion.updatedDate || productVersion.createdDate)
        }

        return null
      }
      const parsedDate = getParsedDate()
      const updatedByUser = find(users, { userKey: productVersion.updatedBy })
      const createdByUser = find(users, { userKey: productVersion.createdBy })
      const isHeader = productVersion && productVersion.version !== prevVersion

      const renderVersionStatus = (): JSX.Element => {
        const VersionStatus = ({ version, user }: { version: Product; user: Colleague }) => (
          <>
            {isHeader && version.productName}
            {user?.profile && (
              <>
                <StyleSeparator> | </StyleSeparator>
                {t(getVersionStatus(version.status) || '')} {getUserNameWithRole(user.profile)}
              </>
            )}
          </>
        )

        const publishedByUser = find(users, { userKey: productVersion.publishedBy })
        const user = publishedByUser || updatedByUser || createdByUser

        if (productVersion && productVersion.version !== prevVersion) {
          return (
            <>
              <VersionStatus version={productVersion} user={user as any} />
              {!!(parsedDate && parsedDate.dateTime) && (
                <>
                  <StyleSeparator> | </StyleSeparator>
                  <DateTypography variantMapping={{ caption: 'span' }}>
                    {getVersionDateStatus(productVersion.status)} {parsedDate.dateTime}
                  </DateTypography>
                </>
              )}
            </>
          )
        }

        return (
          <>
            <VersionStatus version={productVersion} user={user as any} />{' '}
            {!!(parsedDate && parsedDate.dateTime) && (
              <>
                <StyleSeparator> | </StyleSeparator>
                <DateTypography variantMapping={{ caption: 'span' }}>{parsedDate.dateTime}</DateTypography>
              </>
            )}
          </>
        )
      }

      const renderHeaderVersion = (): JSX.Element => (
        <>
          <Grid
            item
            style={{
              marginTop: index > 0 ? 26 : 12,
              marginBottom: 12,
            }}
            className="headerVersion"
          >
            <StyledAvatar status={productVersion.status} parsedDate={parsedDate} />
          </Grid>
          <Grid
            item
            style={{
              marginTop: index > 0 ? 16 : 0,
              marginBottom: 12,
            }}
          >
            <Typography>
              <Link
                color="secondary"
                component={RouterLink}
                to={`/products/${getVersionAndlink(index, productVersion, t).path}`}
              >
                v{productVersion.version}: {getProductStatusLabel(productVersion.status, t, parsedDate as any)}
              </Link>
            </Typography>
            <Body2>{renderVersionStatus()}</Body2>
          </Grid>
        </>
      )

      const renderInterimVersion = (): JSX.Element => (
        <>
          <StyledGrid item css="margin:10px 4px 25px 40px;padding-top: 12px">
            <StyledAvatar status={productVersion.status} parsedDate={parsedDate} />
          </StyledGrid>
          <Grid item>
            <Body2 css="padding-top: 0">
              {getProductStatusLabel(productVersion.status, t, parsedDate as any)}
              {renderVersionStatus()}
            </Body2>
            <Body2>{productVersion.productName}</Body2>
          </Grid>
        </>
      )

      const headerVersion = renderHeaderVersion()
      const interimVersion = renderInterimVersion()
      prevVersion = productVersion.version

      return (
        <>
          {isHeader && index > 0 && index < productVersionLength - 1 ? (
            <GridBorder
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              wrap="nowrap"
              key={index}
              style={index > 1 ? { height: 20 } : { height: 40 }}
            >
              {' '}
            </GridBorder>
          ) : null}
          <GridBorder
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="nowrap"
            key={index}
            style={
              isHeader || productVersion.version === firstVersion
                ? { border: 0, paddingTop: 0, marginLeft: 0 }
                : { marginLeft: 10 }
            }
          >
            {isHeader ? headerVersion : interimVersion}
          </GridBorder>
          {isHeader && productVersionLength !== 1 ? (
            <GridBorder
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              wrap="nowrap"
              key={index}
              style={productVersion.version !== firstVersion ? { height: 30 } : { height: 20, border: 0 }}
            >
              {' '}
            </GridBorder>
          ) : null}
        </>
      )
    })
  }

  return (
    <ContentContainer>
      <ButtonContainer>
        <BackButton onPressBack={onPressBack} aria-controls="main" />
      </ButtonContainer>
      <StyledGrid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <StyledGrid item xs={12} sm={12} md={12} lg={8}>
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ margin: '0px 1px 0px' }}
          >
            <Grid item>
              <H1 style={{ marginBottom: 10 }}>{t('Version history')}</H1>
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: 24 }}>
            <Body2>{currentProduct.productName}</Body2>
          </Grid>
          <FormContainer>
            <ContentGridContainer container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item xs={12}>
                {renderVersionHistory()}
              </Grid>
            </ContentGridContainer>
          </FormContainer>
        </StyledGrid>
        <StyledGridActivity xs={12} item sm={12} md={12} lg={4}>
          <SidePanel
            heading={t('ABOUT PRODUCT VERSIONS')}
            image={ProductVersionsInfoGraphic}
            imageAlt={t(
              'Illustration of a product owner on their laptop managing a  product through the product lifecycle'
            )}
            details={t(
              'New versions are created everytime something is changed on a product. Whole versions only change when a product is either published or edited'
            )}
            linkToOpenModal={t('More about product versions')}
            infographicType={HelpModalType.PRODUCT_VERSIONING}
          />
        </StyledGridActivity>
      </StyledGrid>
    </ContentContainer>
  )
}

export default Products
