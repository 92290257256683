import { CardActionArea, Grid, Paper, Tooltip, Typography, Link } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import StatusBadge from '../StatusBadge/StatusBadge'
import { Task, TaskType } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { getStatusColor } from 'pages/Product/product.utils'
import { relativeDate } from 'utils/date.utils'
import { stopPropagation } from 'utils/form.utils'

const StyledContainerPaper = styled(Paper)<{ taskStatus: string }>`
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  width: 304px;
  border-left: ${(props: any) => `4px solid ${getStatusColor({ text: props.taskStatus, theme: props.theme })}`};
  border-radius: 4px;
  background-color: ${(props: any) => props.theme.palette.background.default};
  &:hover {
    box-shadow: ${(props: any) => `${props.theme.shadows[8]}`};
  }
`

const TaskContentContainer = styled.div`
  padding: 4px;
`

const TaskCard = styled(CardActionArea)`
  width: 100%;
  height: 100%;
  padding-left: 16px;
  text-transform: initial;
  background-color: ${(props: any) => props.theme.palette.background.default};
`

const TaskBadgeContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

interface Props {
  task: Task
  products: Product[]

  handleDrawerOpenClose(): void
}

const TaskItem = ({ task, products, handleDrawerOpenClose }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToTaskEntity = (entityType: string, id: string, feature?: string | null | undefined) => {
    handleDrawerOpenClose()
    switch (entityType) {
      case 'product':
        return navigate(`/products/${id}${feature ? '#' : ''}${feature || ''}`)
      case 'package':
        return navigate(`/packages/${id}`)
      default:
        return navigate('/products')
    }
  }

  let taskStatus: ProductStatus.APPROVED | string
  if (task.status !== TaskStatus.PENDING) {
    taskStatus = task.status === TaskStatus.CLOSED ? ProductStatus.APPROVED : ''
  } else {
    taskStatus = ProductStatus.REVIEW
  }

  const createdAt = relativeDate(task.createdAt)

  return (
    <StyledContainerPaper elevation={1} key={task.key} taskStatus={taskStatus}>
      <CardActionArea
        component="div"
        css={`
          &.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
            opacity: 0;
            width: 100%;
          }
        `}
      >
        <TaskCard
          onClick={() =>
            navigateToTaskEntity(
              task.entityType,
              task.entityKey,
              task.type && task.type === TaskType.FEATURE ? task.context : undefined
            )
          }
          aria-controls="main"
        >
          <TaskContentContainer>
            <Grid key={task.key} container direction="column" justifyContent="flex-start" alignItems="stretch">
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={10}>
                  <Typography variant="subtitle2" gutterBottom component="span">
                    {task.name}
                  </Typography>
                  {products.filter((item) => item.productKey === task.entityKey).length > 0 && (
                    <Link
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        navigateToTaskEntity(
                          task.entityType,
                          task.entityKey,
                          task.type && task.type === TaskType.FEATURE ? task.context : undefined
                        )
                      }}
                      color="secondary"
                      href="#top"
                    >
                      <Typography variant="subtitle2" gutterBottom>
                        {`${t('For')} ${products.filter((item) => item.productKey === task.entityKey)[0].productName}`}
                      </Typography>
                    </Link>
                  )}

                  <Typography variant="body2" gutterBottom variantMapping={{ body2: 'div' }}>
                    {task.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid css="margin-top:20px" item>
                <Tooltip
                  placement="right"
                  title={createdAt.dateTime}
                  aria-label={createdAt.dateTime}
                  disableHoverListener={createdAt.isOver5Days}
                >
                  <Typography variant="caption" component="span">
                    {createdAt.relativeDateTime}
                  </Typography>
                </Tooltip>
              </Grid>
              <TaskBadgeContainer>
                <Grid item>
                  <StatusBadge isTaskDrawer status={task.status} />
                </Grid>
              </TaskBadgeContainer>
            </Grid>
          </TaskContentContainer>
        </TaskCard>
      </CardActionArea>
    </StyledContainerPaper>
  )
}

export default TaskItem
