import React from 'react'
import styled from 'styled-components/macro'
import RadioMui, { RadioProps } from '@material-ui/core/Radio'

const StyledRadioMui = styled(RadioMui)`
  & .Mui-disabled {
    color: ${(props: any) => `${props.theme.palette.text.disabled}`};
  }
`
export interface ARadioProps extends RadioProps {}

const ARadio = (props: ARadioProps) => <StyledRadioMui data-dls="atom-radio" {...props} />

export default ARadio
