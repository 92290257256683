import actions from '../actions'
import { transformProduct } from '../transformers'
import { ProductsState } from '../types'

const reducer = {
  [actions.getProductDetails.type]: (state: any) => ({ ...state, loading: true }),
  [actions.getProductDetailsFail.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.getProductDetailsFail>
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [actions.updateProductSuccess.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.updateProductSuccess>
  ) => ({
    ...state,
    loading: false,
    selectedProduct: transformProduct(action.payload),
  }),
}
export default reducer
