import React from 'react'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import { Delete as RemoveIcon, Edit as EditIcon, KeyboardArrowDown } from '@material-ui/icons'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AccessibleMenu, { AccessibleMenuItem } from '../AccessibleMenu/AccessibleMenu'
import { PackagesWithProducts, ProductPackageId } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { isPackageEditable } from 'utils/common.util'
import { MoreVertButton } from 'dls/molecules/IconButtons/IconButtons'
import Button from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const WiderButton = styled(Button)`
  width: 91px;
`

interface Props {
  currentPackage: PackagesWithProducts
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  productPackageName: string
  anchorEl: HTMLElement
  onClose: (e: React.MouseEvent) => void
  productPackageId: ProductPackageId
  onDelete: (e: React.MouseEvent) => void
  onView?: (e: React.MouseEvent) => void

  iconButton?: boolean
  isOnPackagePage?: boolean
}

const PackageActions = ({
  productPackageName,
  onClick,
  onClose,
  productPackageId,
  currentPackage,
  onView,
  onDelete,
  anchorEl,
  iconButton = false,
  isOnPackagePage = false,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const id = `package-menu-${productPackageName}`

  const menuItems: AccessibleMenuItem[] = []

  if (
    !isOnPackagePage ||
    (currentPackage &&
      ![PackagesStatus.LIVE, PackagesStatus.CLOSED].includes(currentPackage.packageStatus) &&
      currentPackage.products &&
      currentPackage.products.length > 0)
  ) {
    menuItems.push({
      onClick: () => navigate(`/packages/${productPackageId}/edit`),
      testId: 'package-action-edit-button',
      ariaControls: 'main',
      children: (
        <>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary={t('Edit')} />
        </>
      ),
    })
  }
  if (!isOnPackagePage || (currentPackage && ![PackagesStatus.CLOSED].includes(currentPackage.packageStatus))) {
    menuItems.push({
      onClick: onDelete,
      testId: 'package-action-delete-button',
      children: (
        <>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary={t('Delete')} />
        </>
      ),
    })
  }
  const menuButton = iconButton ? (
    <MoreVertButton
      color="default"
      onClick={onClick}
      disabled={menuItems.length === 0}
      aria-label="More actions"
      aria-controls={`package-menu-${currentPackage.productPackageId}`}
      aria-haspopup="true"
    />
  ) : (
    <Button
      color={ColorType.BUTTON}
      size="small"
      onClick={onClick}
      disabled={menuItems.length === 0}
      aria-label="More actions"
      aria-controls={`package-menu-${currentPackage.productPackageId}`}
      aria-haspopup="true"
    >
      {t('ACTIONS')}
      <KeyboardArrowDown />
    </Button>
  )
  return (
    <>
      {isPackageEditable(currentPackage) || (isOnPackagePage && menuItems.length > 0) ? (
        <>
          <AccessibleMenu id={id} anchorEl={anchorEl} menuItems={menuItems} menuButton={menuButton} onClose={onClose} />
        </>
      ) : (
        <>
          {onView && (
            <WiderButton color={ColorType.BUTTON} size="small" onClick={onView}>
              {t('View')}
            </WiderButton>
          )}
        </>
      )}
    </>
  )
}

export default PackageActions
