/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import React from 'react'
import { Avatar, DialogActions, DialogContent, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Field, Form, FormikProps, getIn } from 'formik'
import styled from 'styled-components/macro'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { camelCase, isArray, isEmpty } from 'lodash'
import { PersonOutline } from '@material-ui/icons/'
import * as Yup from 'yup'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from 'dls/atoms/Link'
import { withFormikSimple, Yip } from 'utils/form.utils'
import { Colleague } from 'store/user/types'
import { SelectFieldComplex, SubmitButton } from 'components'
import { Task } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { getStatusColor, getStatusIcon, featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'
import { ProductStatus } from 'store/products/typings/productStatus'
import { useUserNameWithRole } from 'utils/common.util'
import { InfoPanel } from 'dls'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { CustomTheme } from 'dls/shared/styled'

interface UserSelectData {
  [key: string]: { name: Colleague; label?: string } | string
}

interface OwnProps {
  assigned: boolean
  somestatus: ProductStatus
  selectAssigneeData: UserSelectData
  closeAssigneeModal: () => void
  setOpenExpandable?: (openExpandable: boolean) => void
}

interface ColleagueFormItem {
  colleague?: Colleague
  task: Task
}

interface ColleagueFormValues {
  profile?: ColleagueFormItem[]
}

const Title = styled(Typography)`
  margin-bottom: 24px;
`
const Description = styled(Typography)`
  margin-bottom: 16px;
`
const ImgDiv = styled.div`
  padding: 8px;
  padding-left: 2px;
  & .MuiSvgIcon-root {
    margin-top: 3px;
  }
  display: inline;
  vertical-align: middle;
  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      vertical-align: -webkit-baseline-middle;
    }
  }
`
const StyledDiv = styled.div`
  display: flex;
`

const StyledAvatar = styled(Avatar)`
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  background-color: ${(props: any) => `${props.theme.palette.divider}`};
  color: ${(props: any) => `${props.theme.palette.text.secondary}`};
  display: inline-flex;
`
const StyledSpan = styled.span`
  align-self: center;
`
const StyledDialogActions = styled(DialogActions)`
  margin: 16px;
`

const StyledButton = styled(Button)`
  width: 110px;
`

const StyledSubmitButton = styled(SubmitButton)`
  width: 150px;
`

const StyledPersonOutline = styled(PersonOutline)`
  color: ${(props: any) => props.theme.palette.background.paper};
`

interface ProductApproveModalProps extends FormikProps<ColleagueFormValues>, OwnProps {}

const AssigneeModalForm = (props: ProductApproveModalProps) => {
  const { t } = useTranslation()
  const getUserNameWithRole = useUserNameWithRole()
  const pleaseSelectgLabel = (
    <StyledDiv>
      <StyledAvatar>
        <StyledPersonOutline />
      </StyledAvatar>
      <StyledSpan>{t('Please select')}</StyledSpan>
    </StyledDiv>
  )
  const productTask =
    props.values && props.values.profile
      ? // eslint-disable-next-line consistent-return
        props.values.profile.filter((item1) => {
          if (item1.task && item1.task.type === 'PREDEFINED') return item1
        })
      : undefined

  const featureTask =
    props.values && props.values.profile
      ? // eslint-disable-next-line consistent-return
        props.values.profile.filter((item1) => {
          if (item1.task && item1.task.type === 'FEATURE') return item1
        })
      : undefined

  const taskStatusColor = (item: ColleagueFormItem) => {
    if ([TaskStatus.CLOSED, TaskStatus.REJECTED].includes(item.task.status)) {
      return ProductStatus.APPROVED
    }
    if (item.task?.assigneeKey && props.somestatus === ProductStatus.REVIEW) {
      return ProductStatus.REVIEW
    }
    return TaskStatus.PENDING
  }

  return (
    <Form>
      <DialogContent>
        <Title variant="h6" data-test-id="send-approval-header">
          {props.assigned ? t('Edit & resend for approval') : t('Send for approval')}
        </Title>
        {props.assigned && (
          <InfoPanel
            body={t(
              'New assignees will receive a notification requesting their review. This does not affect unchanged assignees or existing approvals.'
            )}
          />
        )}
        {!props.assigned && (
          <Description variant="body2" variantMapping={{ body2: 'div' }}>
            {t('Choose an assignee for each task. You can add, edit and delete tasks in the ')}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            <Link component={RouterLink} to="/governance" color="secondary" href="#top">
              {t('governance section')}
            </Link>
            {t(' or find out how it works.')}
          </Description>
        )}
        <TableContainer>
          {featureTask && isArray(featureTask) && !isEmpty(featureTask) && (
            <Table data-test-id="feature-approval-table">
              <TableHead>
                <TableRow>
                  <TableCell css="width:50%; padding-left: 0px;padding-right: 0px;">
                    {t('Feature approval tasks')}
                  </TableCell>
                  <TableCell css="width:50%; padding-left: 0px;padding-right: 0px;" align="left">
                    {t('Assignee')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-test-id="feature-approval-table-body">
                {featureTask.map((item, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableRow key={index}>
                    <TableCell css="width:50%;padding-left: 0px;padding-right: 0px;">
                      {item && item.task && item.task.context && item.task.context !== null && (
                        <ImgDiv>
                          {item.task.context === 'productCore'
                            ? featureIconsCreator(featureSVGIcons.coreProduct)
                            : featureIconsCreator(featureSVGIcons[camelCase(item.task.context)])}
                        </ImgDiv>
                      )}
                      {item && item.task ? item.task.name : ''}
                    </TableCell>
                    <TableCell css="width:50%;padding-left: 0px;padding-right: 0px;padding-top: 0px;" align="left">
                      {item && item.task && item.task.status === TaskStatus.PENDING ? (
                        <Field
                          name={`profile.${index}.colleague`}
                          data={props.selectAssigneeData}
                          isUserSelector
                          component={SelectFieldComplex}
                          fullWidth
                          selectProps={{ displayEmpty: true }}
                          inputLabelProps={{ shrink: true }}
                          placeholder={pleaseSelectgLabel}
                          required={item.task.status === TaskStatus.PENDING}
                          validate={item.task.status === TaskStatus.PENDING && Yip(Yup.string().required())}
                          disabled={item.task.status !== TaskStatus.PENDING}
                        />
                      ) : (
                        <StyledDiv css="padding-top: 20px;">
                          <StyledSpan>
                            {item && item.task && (
                              <StyledAvatar
                                css={`
                                  ${({ theme }: { theme: CustomTheme }) => `
                                background-color: ${getStatusColor({
                                  text: taskStatusColor(item),
                                  theme,
                                })};
                              `};

                                  color: white;
                                  vertical-align: middle;
                                `}
                              >
                                {getStatusIcon(taskStatusColor(item))}
                              </StyledAvatar>
                            )}
                            {item.task && TaskStatus.CLOSED === item.task.status && t(' Approved by ')}
                            {item.task && TaskStatus.REJECTED === item.task.status && t(' Rejected by ')}
                            {getUserNameWithRole(getIn(props.values, `profile.${index}.colleague.profile`))}
                          </StyledSpan>
                        </StyledDiv>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {productTask && (
            <Table css="margin-top:24px" data-test-id="product-approval-tasks-table">
              <TableHead>
                <TableRow>
                  <TableCell css="width:50% ;padding-left: 0px;padding-right: 0px;">
                    {t('Product approval tasks')}
                  </TableCell>
                  <TableCell css="width:50%; padding-left: 0px;padding-right: 0px;" align="left">
                    {t('Assignee')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-test-id="product-approval-tasks-table-body">
                {productTask.map((item, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableRow key={index}>
                    <TableCell css="width:50%; padding-left: 0px;padding-right: 0px;">
                      {item && item.task && item.task.context && item.task.context !== null && (
                        <ImgDiv>
                          {item.task.context === 'productCore'
                            ? featureIconsCreator(featureSVGIcons.coreProduct)
                            : featureIconsCreator(featureSVGIcons[camelCase(item.task.context)])}
                        </ImgDiv>
                      )}
                      {item && item.task ? item.task.name : ''}
                    </TableCell>
                    <TableCell css="width:50%; padding-left: 0px;padding-right: 0px; padding-top: 0px;" align="left">
                      {item && item.task && item.task.status === TaskStatus.PENDING ? (
                        <Field
                          name={`profile.${featureTask ? featureTask.length + index : index}.colleague`}
                          data={props.selectAssigneeData}
                          isUserSelector
                          component={SelectFieldComplex}
                          fullWidth
                          selectProps={{ displayEmpty: true }}
                          inputLabelProps={{ shrink: true }}
                          placeholder={pleaseSelectgLabel}
                          required={item.task.status === TaskStatus.PENDING}
                          validate={item.task.status === TaskStatus.PENDING && Yip(Yup.string().required())}
                          disabled={item.task.status !== TaskStatus.PENDING}
                        />
                      ) : (
                        <StyledDiv css="padding-top: 20px;">
                          <StyledSpan>
                            {item && item.task && (
                              <StyledAvatar
                                css={`
                                  background-color: ${({ theme }: { theme: CustomTheme }) =>
                                    getStatusColor({
                                      text: taskStatusColor(item),
                                      theme,
                                    })};
                                  color: white;
                                  vertical-align: middle;
                                `}
                              >
                                {getStatusIcon(taskStatusColor(item))}
                              </StyledAvatar>
                            )}
                            {item.task && TaskStatus.CLOSED === item.task.status && t(' Approved by ')}
                            {item.task && TaskStatus.REJECTED === item.task.status && t(' Rejected by ')}
                            {getUserNameWithRole(
                              getIn(
                                props.values,
                                `profile.${featureTask ? featureTask.length + index : index}.colleague.profile`
                              )
                            )}
                          </StyledSpan>
                        </StyledDiv>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </DialogContent>
      <StyledDialogActions>
        <StyledButton
          disabled={false}
          onClick={() => {
            props.closeAssigneeModal()
            if (props.setOpenExpandable) props.setOpenExpandable(false)
          }}
          color={ColorType.BUTTON}
          data-test-id="approval-tasks-cancel-button"
        >
          {t('Cancel')}
        </StyledButton>
        <StyledSubmitButton
          type="submit"
          color={ColorType.BUTTON}
          variant="contained"
          data-test-id="approval-send-button"
        >
          {t('Send')}
        </StyledSubmitButton>
      </StyledDialogActions>
    </Form>
  )
}

export default withFormikSimple<OwnProps, ColleagueFormValues>(AssigneeModalForm, {})
