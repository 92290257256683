import styled, { css } from 'styled-components/macro'
import { InfoOutlined, ArrowForward, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Grid, Paper } from '@material-ui/core'
import AccessibleMenuButton from '../AccessibleMenu/AccessibleMenuButton'
import { Button } from 'dls/atoms/Button'

const defaultLineHeight = css`
  line-height: 24px;
`
export const selectorColor = css<{ $isOnProductPage: boolean }>`
  color: ${({ $isOnProductPage, theme }: any) =>
    `${$isOnProductPage ? theme.palette.text.primary : theme.palette.text.primary}`};
`

export const OldVersionNotice = styled(Paper)`
  padding: 12px;
  font-weight: bold;
  ${defaultLineHeight}
`
export const ThemeInfoOutlinedIcon = styled(InfoOutlined)`
  color: ${({ theme }: any) => `${theme.palette.primary.main}`};
  vertical-align: middle;
  margin-right: 18px;
`
export const ThemeArrowUpIcon = styled(KeyboardArrowDown)<{ $isOnProductPage: boolean }>`
  color: ${({ $isOnProductPage, theme }: any) =>
    `${$isOnProductPage ? theme.palette.text.primary : theme.palette.secondary.main}`};
`
export const ThemeArrowDownIcon = styled(KeyboardArrowUp)<{ $isOnProductPage: boolean }>`
  color: ${({ $isOnProductPage, theme }: any) =>
    `${$isOnProductPage ? theme.palette.text.primary : theme.palette.secondary.main}`};
`
export const ProductVersionGrid = styled(Grid)<{ $isOnProductPage: boolean }>`
  margin-right: ${({ $isOnProductPage }: any) => ($isOnProductPage ? 0 : '16px')};
  ${selectorColor}
`
export const ProductVersionGridItem = styled(Grid)`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`
export const ProductVersionButton: any = styled(AccessibleMenuButton)`
  padding-left: ${({ $isOnProductPage }: any) => ($isOnProductPage ? 0 : '8px')};
  font-weight: ${({ $isOnProductPage }: any) => ($isOnProductPage ? 500 : 400)};
  font-size: ${({ $isOnProductPage }: any) => ($isOnProductPage ? '16px' : '14px')};
  text-transform: none;
  white-space: nowrap;
  padding: ${({ $iconButton }: any) => $iconButton && 0};
  min-width: ${({ $iconButton }: any) => $iconButton && '15px'};
  ${selectorColor}
  ${defaultLineHeight}
  .MuiButton-endIcon {
    margin-left: 0;
  }
  ${({ theme }) => `
  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  },
`}
`
export const LoadingContainer = styled(Grid)<{ error: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ error }) => `${error ? '25px 55px' : '65px 75px'}`};
  line-height: 36px;
`
export const ReloadButton = styled(Button)`
  margin-top: 2px;
  color: ${({ theme }: any) => `${theme.palette.secondary.main}`};
`
export const ArrowForwardGray = styled(ArrowForward)`
  color: ${({ theme }: any) => theme.palette.text.secondary};
  height: 24px;
  margin-left: 8px;
`
