import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box, useTheme } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { ReviewSubheader, ReviewRows, Line, ContextualHelp } from 'dls'
import { PaymentLimitsConfig } from 'store/products/typings/paymentLimits'
import { formatConfigTaxYear } from 'pages/features/PaymentLimits/utilities/transformers'

interface OwnProp {
  config: PaymentLimitsConfig
}

const ConfigRow = ({ config: { yearType, taxYearStart } }: OwnProp) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Grid item>
        <Line type="light" />
      </Grid>
      <Grid item>
        <ReviewSubheader
          title={<Box style={{ fontWeight: 'bold' }}>{t('Year definition')}</Box>}
          IconButtonProps={[
            <ContextualHelp
              key="year-definition-help"
              Icon={HelpOutlineIcon}
              ariaProps={{
                'aria-hidden': false,
                'aria-label': 'Help',
              }}
              id="ContextualHelp"
              interactive
              style={{
                maxWidth: 100,
                outline: 'none',
                color: theme.palette.text.primary,
              }}
              title="Any payment limits set for 'year' will use this year
definition. You can change this to Anniversary date of account opening, calendar year or tax year"
            />,
          ]}
        />
      </Grid>
      <Grid item>
        <ReviewRows
          definitionsList={[
            {
              definitions: [
                {
                  term: t('Year definition for limits'),
                  definitions: [
                    <span key={yearType} style={{ fontWeight: 'bold' }}>
                      {t(`PAYMENT_LIMITS.${yearType}`, {
                        taxYearStart: t(`starting ${formatConfigTaxYear(taxYearStart || '')}`),
                      })}
                    </span>,
                  ],
                },
              ],
            },
          ]}
        />
      </Grid>
      <Grid item>
        <Line type="strong" />
      </Grid>
    </>
  )
}

export default ConfigRow
