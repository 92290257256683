/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { Done, Clear } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { Flag } from 'components'
import { Eligibility, ProductSegment } from 'store/products/types'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import initialState from 'store/products/reducers/initialState'
import ProductsSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'
import { formatIncomeEligibilityAmount } from 'pages/Product/product.utils'
import countriesJson from 'static/countries.json'
import { ReviewRows } from 'dls'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'

interface OwnProps {
  eligibility: Eligibility
  currencyOfProduct?: string | null
}

const StyledWrapper = styled.div`
  width: 100%;
  [data-dls='molecules-review-row']:last-child hr {
    display: none;
  }
`
const IconContainer = styled(Grid)`
  margin-right: 8px;
  float: left;
`

const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ViewEligibility = (props: OwnProps): React.ReactElement => {
  const {
    ageEligibility,
    incomeEligibility,
    residencyEligibility,
    officerEligibility,
    taxResidencyEligibility,
    taxIdentificationEligibility,
    productDependencyEligibility,
    studentAccountEligibility,
  } = props.eligibility

  const selectAllProductsOrigin = useSelector(ProductsSelectors.selectAllProductsOrigin)
  const selectDataLoading = useSelector(ProductsSelectors.selectDataLoading)
  const selectError = useSelector(ProductsSelectors.selectError)
  const dispatch = useDispatch()
  const productSegment = useSelector(ProductsSelectors.selectSelectedProductSegment)

  const eligibilityTypes: (keyof Eligibility)[] = Object.keys(props.eligibility).sort((a, b) =>
    a.localeCompare(b)
  ) as (keyof Eligibility)[]

  useEffect(() => {
    if (selectAllProductsOrigin === initialState.data && !selectDataLoading && !selectError) {
      dispatch(ProductsActions.fetch())
    }
  }, [selectDataLoading, selectAllProductsOrigin, dispatch, selectError])

  const { t } = useTranslation()

  const prodDetails: ReviewRowGroup[] = [
    {
      definitions: [],
    },
  ]

  eligibilityTypes.forEach((type) => {
    switch (type) {
      case 'ageEligibility':
        return (
          ageEligibility &&
          prodDetails[0].definitions.push({
            term: t('Age'),
            itemSeparator: 'light',
            definitions: [
              <ReviewRows
                key="age-eligibility"
                definitionsList={[
                  {
                    definitions: [
                      {
                        term: t('Min age'),
                        definitions: [`${ageEligibility.minimumAge}`],
                      },
                      {
                        term: t('Max age'),
                        definitions: [ageEligibility.maximumAge || t('N/A')],
                      },
                    ],
                  },
                ]}
              />,
            ],
          })
        )

      case 'incomeEligibility':
        return (
          incomeEligibility &&
          prodDetails[0].definitions.push({
            term: productSegment === ProductSegment.BUSINESS ? t(`Annual turnover`) : t('Annual income'),
            itemSeparator: 'light',
            definitions: [
              <ReviewRows
                key="income-eligibility"
                definitionsList={[
                  {
                    definitions: [
                      {
                        term: t('Min annual {{entity}}', {
                          entity: productSegment === ProductSegment.BUSINESS ? t(`turnover`) : t('income'),
                        }),
                        definitions: [
                          formatIncomeEligibilityAmount(incomeEligibility.minimumIncome, props.currencyOfProduct || ''),
                        ],
                      },
                      {
                        term: t('Max annual {{entity}}', {
                          entity: productSegment === ProductSegment.BUSINESS ? t(`turnover`) : t('income'),
                        }),
                        definitions: [
                          incomeEligibility.maximumIncome
                            ? formatIncomeEligibilityAmount(
                                incomeEligibility.maximumIncome,
                                props.currencyOfProduct || ''
                              )
                            : t('N/A'),
                        ],
                      },
                    ],
                  },
                ]}
              />,
            ],
          })
        )

      case 'officerEligibility':
        return (
          officerEligibility &&
          prodDetails[0].definitions.push({
            term: t('Officer'),
            itemSeparator: 'light',
            definitions: [
              <ReviewRows
                key="officer-eligibility"
                definitionsList={[
                  {
                    definitions: [
                      officerEligibility.minAmount && {
                        term: t('Min amount'),
                        definitions: [
                          <>
                            <CurrencySymbol
                              typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                              currencyCode={props.currencyOfProduct}
                            />
                            {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(
                              officerEligibility.minAmount
                            )}
                          </>,
                        ],
                      },
                      officerEligibility.maxAmount && {
                        term: t('Max amount'),
                        definitions: [
                          <>
                            <CurrencySymbol
                              typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                              currencyCode={props.currencyOfProduct}
                            />
                            {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(
                              officerEligibility.maxAmount
                            )}
                          </>,
                        ],
                      },
                    ].filter((item) => item) as any,
                  },
                ]}
              />,
            ],
          })
        )
        break

      case 'residencyEligibility':
        return (
          residencyEligibility &&
          !isEmpty(residencyEligibility?.[0].countryIncluded) &&
          residencyEligibility[0].countryIncluded &&
          prodDetails[0].definitions.push({
            term: t('Residency'),
            itemSeparator: 'light',
            definitions: [
              t('Countries of residency'),
              residencyEligibility && residencyEligibility[0].countryIncluded && (
                <StyledUl>
                  {residencyEligibility[0].countryIncluded.map((code) => {
                    const alpha2Code = countriesJson[code]?.alpha2Code
                    return (
                      <li key={`country-residency-${code}`}>
                        <Flag code={alpha2Code || code} size={20} />
                        <span>{t(`COUNTRIES.${code}`)}</span>
                      </li>
                    )
                  })}
                </StyledUl>
              ),
            ],
          })
        )

      case 'studentAccountEligibility':
        return (
          studentAccountEligibility &&
          prodDetails[0].definitions.push({
            term: t('Student ID'),
            itemSeparator: 'light',
            definitions: [
              studentAccountEligibility.studentIdRequired ? (
                <>
                  <IconContainer item>
                    <Done aria-label={t('Student ID verification required')} />
                  </IconContainer>
                  <Grid item>{t('Student ID verification required')}</Grid>
                </>
              ) : (
                <>
                  <IconContainer item>
                    <Clear aria-label={t('Student ID verification not required')} />
                  </IconContainer>
                  <Grid item>{t('Student ID verification not required')}</Grid>
                </>
              ),
            ],
          })
        )

      case 'taxResidencyEligibility':
        return (
          taxResidencyEligibility?.countryIncluded &&
          prodDetails[0].definitions.push({
            term: t('Tax residency'),
            itemSeparator: 'light',
            definitions: [
              t('Countries of tax residency'),
              taxResidencyEligibility.countryIncluded && (
                <StyledUl>
                  {taxResidencyEligibility.countryIncluded.map((code) => {
                    const alpha2Code = countriesJson[code]?.alpha2Code
                    return (
                      <li key={`tax-residency-${code}`}>
                        <Flag code={alpha2Code || code} size={20} />
                        <span>{t(`COUNTRIES.${code}`)}</span>
                      </li>
                    )
                  })}
                </StyledUl>
              ),
            ],
          })
        )

      case 'taxIdentificationEligibility':
        return (
          taxIdentificationEligibility &&
          !isEmpty(taxIdentificationEligibility.countryCode) &&
          taxIdentificationEligibility.countryCode &&
          prodDetails[0].definitions.push({
            term: t('Tax ID number'),
            itemSeparator: 'light',
            definitions: [
              t('Any countries for which a tax ID must be held by a party'),
              <StyledUl key="tax-identification-eligibility">
                {taxIdentificationEligibility.countryCode.map((code) => {
                  const alpha2Code = countriesJson[code]?.alpha2Code
                  return (
                    <li key={`tax-identification-elgibility-${code}`}>
                      <Flag code={alpha2Code || code} size={20} />
                      <span>{t(`COUNTRIES.${code}`)}</span>
                    </li>
                  )
                })}
              </StyledUl>,
            ],
          })
        )

      case 'productDependencyEligibility':
        return (
          productDependencyEligibility?.dependency &&
          prodDetails[0].definitions.push({
            term: t('Dependent product'),
            itemSeparator: 'light',
            definitions: [
              !!selectAllProductsOrigin &&
              !!selectAllProductsOrigin[productDependencyEligibility.dependency.productKey || '']
                ? selectAllProductsOrigin[productDependencyEligibility.dependency.productKey || ''].productName
                : productDependencyEligibility.dependency.productKey,
              t(productDependencyEligibility.dependency.subscriptionStatus || ''),
            ],
          })
        )

      default:
        return null
    }
  })

  return (
    <StyledWrapper>
      <ReviewRows definitionsList={prodDetails} />
    </StyledWrapper>
  )
}

export default ViewEligibility
