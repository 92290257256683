import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { ListSubheader, useMediaQuery } from '@material-ui/core'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { LISTBOX_PADDING } from 'components/form/MultiSelectAutocomplete/constants'

export function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = React.createContext({})
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

export const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (row: any) => {
    if (React.isValidElement(row) && row.type === ListSubheader) {
      return 48
    }

    const text = document.getElementById(row.props.id)?.innerText

    // if no text, or text is short, don't bother measuring.
    if (!text || text.length < 15) return itemSize

    // attempt to measure height by writing text to a, kind of hidden element.
    const hiddenElement = document.getElementById('js-hidden-virtual-list-item')
    if (hiddenElement) {
      hiddenElement.textContent = text
      const ret = hiddenElement.offsetHeight
      hiddenElement.textContent = ''

      if (ret > 0) return Math.max(ret, itemSize)
    }

    // fallback
    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <div id="js-hidden-virtual-list-item" style={{ visibility: 'visible', whiteSpace: 'normal' }} />
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})
