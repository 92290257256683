import React, { Suspense } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import navigationMenu from './pages'
import { Loader } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import TemplateSingleColumn from 'dls/templates/TemplateSingleColumn/TemplateSingleColumn'
import { HelpMenu } from 'dls'
import getHelpSections from 'pages/Dashboard/utils/getHelpSections'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'
import { selectUserRole } from 'store/user/selectors'

function HelpContentPage() {
  const { t } = useTranslation()
  const { page } = useParams()
  const pages = navigationMenu()
  const pageContent = page && pages[page]

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Help'), path: 'help' },
      { title: pageContent?.title.props.children, path: '' },
    ],
    trigger: true,
  })
  const userRole = useSelector(selectUserRole)
  const helpSections = getHelpSections(userRole)

  return (
    <ContentContainer data-test-id="help-page-content" style={{ maxWidth: 1000, paddingBottom: 32 }}>
      <TemplateSingleColumn
        main={
          <>
            <Suspense fallback={<Loader />}>
              <pageContent.page />
            </Suspense>
          </>
        }
        aside={
          <HelpMenu
            showEntityIcons
            path={page}
            widgetTitle="All help topics"
            helpSections={helpSections}
            buttonComponentType={RouterLink}
          />
        }
      />
    </ContentContainer>
  )
}

export default HelpContentPage
