import { createAction } from '@reduxjs/toolkit'
import { FeeReward } from './types'
import { FeeRewardFeatureType, ProductKey } from 'store/products/types'

const actions = {
  setFeeReward: createAction<
    FeeReward & { productKey: ProductKey; version: number; featureType: FeeRewardFeatureType }
  >('PRODUCTS/FEATURE/SET/FEE'),
  removeFeeReward: createAction<
    FeeReward & {
      featureType: FeeRewardFeatureType
      id: string
      isLast: boolean
      productKey: ProductKey
      version: number
    }
  >('PRODUCTS/FEATURE/REMOVE/FEE'),
}

export default actions
