import React, { useEffect, useState } from 'react'
import { Card, Paper, List, ListItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { groupBy, isEmpty, sortBy } from 'lodash'
import { connect, useDispatch, useSelector } from 'react-redux'
import Sticky from 'react-sticky-el'
import ProductHeader from './ProductHeader'
import { getFeatures, isFeatureAllowedByConfig } from './product.utils'
import ProductFeatureViewMode, { mapStateToProductProps } from './ProductFeatureViewMode/ProductFeatureViewMode'
import ProductReviewButtons, { isInReview } from './ProductReviewButtons'
import ProductStepperCard from './ProductStepperCard'
import ProductListHeader from './ProductListHeader'
import {
  DeleteFeaturePayload,
  ExtendedProduct,
  Feature,
  UpdateProductFormValues,
  AccountWithFeaturesMap,
} from 'store/products/types'
import { TemplateReview } from 'components'
import { getAssigneeData } from 'components/AssigneeBox/AssigneeBoxUtil'
import { Task } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { Colleague } from 'store/user/types'
import { BankInterestRate } from 'store/referenceData/types'
import ProductsActions from 'store/products/actions'
import initialState from 'store/products/reducers/initialState'
import ProductsSelectors from 'store/products/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'

interface OwnProps {
  product: ExtendedProduct
  isOldVersion?: boolean
  open: boolean
  editProduct: (payload: UpdateProductFormValues) => void
  onClickCancel: () => void
  currencyOfProduct?: string | null
  selectSelectedTenantIndex?: number
  bankIndexRates?: BankInterestRate[]
  onDeleteFeature(deleteFeaturePayload: DeleteFeaturePayload): void
}

type Props = ReturnType<typeof mapStateToProductProps> & OwnProps

const Product = ({
  product,
  open,
  onClickCancel,
  editProduct,
  onDeleteFeature,
  tasks,
  selectActiveprocessExecutions,
  userInformation,
  assignees,
  currencyOfProduct,
  tenantConfig,
  selectSelectedTenantIndex,
  bankIndexRates,
  isOldVersion,
}: Props): React.ReactElement => {
  const [expandedFeatures, setExpandedFeatures] = useState<string[]>([])

  const assigneeData = getAssigneeData(assignees, tasks)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectAllProductsOrigin = useSelector(ProductsSelectors.selectAllProductsOrigin)
  const selectDataLoading = useSelector(ProductsSelectors.selectDataLoading)
  const selectError = useSelector(ProductsSelectors.selectError)

  useEffect(() => {
    if (
      product?.eligibility?.productDependencyEligibility?.dependency?.productKey &&
      selectAllProductsOrigin === initialState.data &&
      !selectDataLoading &&
      !selectError
    ) {
      dispatch(ProductsActions.fetch())
    }
  }, [selectDataLoading, selectAllProductsOrigin, dispatch, selectError, product])

  const addedFeatures = [Feature.PRODUCTCORE, ...sortBy(getFeatures({ ...product }, 'added'), (i) => t(i))].filter(
    (i) =>
      isFeatureAllowedByConfig(tenantConfig, i as Feature) && AccountWithFeaturesMap[product?.productType].includes(i)
  )

  const expandFeature = (featureName: string) => (expand: boolean): void => {
    setExpandedFeatures(expand ? [featureName, ...expandedFeatures] : expandedFeatures.filter((i) => i !== featureName))
  }

  const isFeatureOnReview = (featureName: string) =>
    isInReview(product, tasks, selectActiveprocessExecutions, userInformation, featureName)

  const tasksRelatedFeatures = assigneeData.filter((item) => item.task.type === 'FEATURE')

  const tasksGroupByFeatureName = groupBy(
    tasksRelatedFeatures,
    (item1: { task: Task; assignee: Colleague | undefined }) => item1.task.context
  )

  const getTasksRelatedFeature = (featureName: string): { task: Task; assignee: Colleague | undefined } | undefined =>
    tasksGroupByFeatureName &&
    tasksGroupByFeatureName[featureName] &&
    tasksGroupByFeatureName[featureName][0] &&
    !isEmpty(tasksGroupByFeatureName[featureName][0]) &&
    tasksRelatedFeatures.filter((item) => item.task.status === TaskStatus.REJECTED).length === 0 &&
    tasksRelatedFeatures.length !== tasksRelatedFeatures.filter((item) => item.task.status === TaskStatus.CLOSED).length
      ? tasksGroupByFeatureName[featureName][0]
      : undefined

  const getAssigneeForFeature = (featureName: string) => {
    const task = getTasksRelatedFeature(featureName)
    return task !== undefined && task.assignee !== undefined && !isEmpty(task.assignee) ? task.assignee : undefined
  }

  const getTaskForFeature = (featureName: string) => {
    const task = getTasksRelatedFeature(featureName)
    return task !== undefined && task.assignee !== undefined && !isEmpty(task.task) ? task.task : undefined
  }

  useEffect(() => {
    addedFeatures.map((featureName) => isFeatureOnReview(featureName) && expandFeature(featureName)(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, selectActiveprocessExecutions, product])

  return (
    <ContentContainer data-test-id="product-page">
      <TemplateReview
        header={
          <>
            <ProductHeader product={product} isOldVersion={isOldVersion} />
            <ProductStepperCard product={product} isOldVersion={isOldVersion} />
          </>
        }
        toolbar={
          <ProductListHeader
            titleId="product-features"
            product={product}
            isOldVersion={isOldVersion}
            editProduct={editProduct}
            availableFeatures={addedFeatures}
            open={open}
            currencyOfProduct={currencyOfProduct}
            onClickCancel={onClickCancel}
            selectSelectedTenantIndex={selectSelectedTenantIndex}
            bankIndexRates={bankIndexRates}
            expandedFeatures={expandedFeatures}
            setExpandedFeatures={setExpandedFeatures}
          />
        }
        disableToolbarRole
        main={
          <>
            {!!addedFeatures && (
              <List
                component="ol"
                aria-labelledby="product-features-heading"
                style={{ padding: 0, marginTop: -8 }}
                aria-live="polite"
                role="region"
                id="feature-list"
              >
                {addedFeatures.map((featureName, index) => (
                  <ListItem key={`${featureName}-${index}`} disableGutters>
                    <ProductFeatureViewMode
                      key={featureName}
                      featureName={featureName}
                      deleteFeature={onDeleteFeature}
                      expanded={expandedFeatures.includes(featureName)}
                      setExpanded={expandFeature(featureName)}
                      featureAssigneeData={getAssigneeForFeature(featureName)}
                      featureTaskData={getTaskForFeature(featureName)}
                      currencyOfProduct={currencyOfProduct}
                      isOldVersion={isOldVersion}
                      product={product}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <Sticky mode="bottom" positionRecheckInterval={500} holderCmp="div">
              <Paper elevation={1}>
                <Card>
                  <ProductReviewButtons product={product} />
                </Card>
              </Paper>
            </Sticky>
          </>
        }
      />
    </ContentContainer>
  )
}

export default connect(mapStateToProductProps)(Product)
