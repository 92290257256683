/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment-timezone'
import { v4 } from 'uuid'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { bankTzToIso } from 'utils/date.utils'
import { hashObject } from 'utils/api.utils'
import {
  GetSuspenseTransactionsRequest,
  SubscriptionKey,
  SuspenseAccountResponse,
  SuspenseAccounts,
  SuspenseTransactionsResponse,
  TransactionTransferRequest,
  WriteOffAndWriteBackTransactionTransferRequest,
  ReverseWriteOffAndWriteBackTransactionTransferRequest,
  NostroWashTransactionTransferRequest,
  TransactionStatus,
  SuspenseTransactionItem,
  updateParentId,
} from 'store/accounts/types'

async function fetchSuspenseAccounts() {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<SuspenseAccounts>('/v1/suspenseSubscriptions')
  return data
}

async function getSuspenseTransactions({
  subscriptionKey,
  limit,
  f_dt,
  t_dt,
  cursor,
  f_amt,
  t_amt,
  cr_dr,
  transactionStatus,
  customerSubscriptionKey,
  transactionId,
  correlationId,
}: GetSuspenseTransactionsRequest) {
  const Query = {
    'f-dt': f_dt || undefined,
    limit: limit || 100,
    't-dt': t_dt || undefined,
    cursor: cursor || undefined,
    'f-amt': f_amt || undefined,
    't-amt': t_amt || undefined,
    'cr-dr': cr_dr || undefined,
    transactionStatus: transactionStatus || undefined,
    customerSubscriptionKey: customerSubscriptionKey || undefined,
    transactionId: transactionId || undefined,
    correlationId: correlationId || undefined,
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<SuspenseTransactionsResponse>(
    `/v1/suspenseSubscriptions/${subscriptionKey}/transactions`,
    {
      params: Query,
    }
  )
  return data
}

async function getSuspenseAccount(subscriptionKey: SubscriptionKey) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<SuspenseAccountResponse>(
    `/v1/suspenseSubscriptions/${subscriptionKey}`
  )
  return data
}

async function getSuspenseTransactionsFor90Days(subscriptionKey: SubscriptionKey) {
  const Query = {
    limit: 100,
    't-dt': bankTzToIso(moment().startOf('day').subtract(89, 'd').subtract(1, 's')),
    transactionStatus: [TransactionStatus.PARTIALLY_CLEARED, TransactionStatus.PENDING].join(','),
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<SuspenseTransactionsResponse>(
    `/v1/suspenseSubscriptions/${subscriptionKey}/transactions`,
    {
      params: Query,
    }
  )
  return data
}

async function getSuspenseTransactionsFor60Days(subscriptionKey: SubscriptionKey) {
  const Query = {
    limit: 100,
    't-dt': bankTzToIso(moment().startOf('day').subtract(59, 'd').subtract(1, 's')),
    transactionStatus: [TransactionStatus.PARTIALLY_CLEARED, TransactionStatus.PENDING].join(','),
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<SuspenseTransactionsResponse>(
    `/v1/suspenseSubscriptions/${subscriptionKey}/transactions`,
    {
      params: Query,
    }
  )
  return data
}

async function getSuspenseTransactionsFor30Days(subscriptionKey: SubscriptionKey) {
  const Query = {
    limit: 100,
    't-dt': bankTzToIso(moment().startOf('day').subtract(29, 'd').subtract(1, 's')),
    transactionStatus: [TransactionStatus.PARTIALLY_CLEARED, TransactionStatus.PENDING].join(','),
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<SuspenseTransactionsResponse>(
    `/v1/suspenseSubscriptions/${subscriptionKey}/transactions`,
    {
      params: Query,
    }
  )
  return data
}

async function getSuspenseTransactionsForOverdue(subscriptionKey: SubscriptionKey) {
  const Query = {
    'f-dt': bankTzToIso(moment().startOf('year')),
    limit: 100,
    't-dt': bankTzToIso(moment().subtract(90, 'd')),
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<SuspenseTransactionsResponse>(
    `/v1/suspenseSubscriptions/${subscriptionKey}/transactions`,
    {
      params: Query,
    }
  )
  return data
}

async function confirmTransaction(payload: TransactionTransferRequest) {
  const hash = hashObject('transfer', payload)
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<TransactionTransferRequest>(
    `/v1/suspenseSubscriptions/transfer`,
    payload,
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )

  return data
}

async function confirmWriteOffAndWriteBackTransaction(payload: WriteOffAndWriteBackTransactionTransferRequest) {
  const hash = hashObject('writeoffWriteback', payload)
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<TransactionTransferRequest>(
    `/v1/suspenseSubscriptions/writeoffWriteback`,
    payload,
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )

  return data
}

async function confirmNostroWashTransaction(payload: NostroWashTransactionTransferRequest) {
  const hash = hashObject('washTransfer', payload)
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<TransactionTransferRequest>(
    `/v1/suspenseSubscriptions/washTransfer`,
    payload,
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )

  return data
}

async function reverseWriteOffAndWriteBackTransaction(payload: ReverseWriteOffAndWriteBackTransactionTransferRequest) {
  const hash = hashObject('reverseWriteOffWriteBack', payload)
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<TransactionTransferRequest>(
    `/v1/suspenseSubscriptions/reverseWriteOffWriteBack`,
    payload,
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )

  return data
}

async function reverseTransaction(payload: TransactionTransferRequest) {
  const hash = hashObject('transfer', payload)

  const { data } = await (await createAsyncAuthAwareAxios(null)).post<TransactionTransferRequest>(
    `/v1/suspenseSubscriptions/transfer`,
    payload,
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )

  return data
}
async function reconcileTransaction(payload: updateParentId) {
  const reconcileTransactionPayload = {
    transactions: [
      {
        parentTransactionId: payload?.SuspenseTransactionItem?.parentTransactionId,
        transactionId: payload?.SuspenseTransactionItem?.transactionId,
      },
    ],
  }

  const { data } = await (await createAsyncAuthAwareAxios(null, false)).post<SuspenseTransactionItem>(
    `/v1/suspenseSubscriptions/reconcile`,
    reconcileTransactionPayload,
    {
      headers: {
        'Idempotency-Key': v4(),
      },
    }
  )

  return data
}

export default {
  getSuspenseAccount,
  fetchSuspenseAccounts,
  getSuspenseTransactions,
  getSuspenseTransactionsFor90Days,
  getSuspenseTransactionsFor60Days,
  getSuspenseTransactionsFor30Days,
  getSuspenseTransactionsForOverdue,
  confirmTransaction,
  confirmWriteOffAndWriteBackTransaction,
  reverseWriteOffAndWriteBackTransaction,
  confirmNostroWashTransaction,
  reverseTransaction,
  reconcileTransaction,
}
