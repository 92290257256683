import React from 'react'
import { Dialog, DialogContent, Fade, Typography, Paper, Grid, DialogActions } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { AnimatedTick } from '../Modal/variants/SuccessModal/AnimatedTick'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  open: boolean
  setOpen?: (open: boolean) => void
}

const Title = styled(Typography)`
  margin-bottom: 24px;
`

const Description = styled(Typography)`
  margin-bottom: 16px;
`
const StyledButton = styled(Button)`
  width: 110px;
  margin-right: 24px;
  margin-bottom: 24px;
`

const StyledUL = styled.ul`
  text-align: left;
  font-size: 14px;
`
const GrindContainer = styled(Typography)`
  text-align: center;
  padding-top: 48px;
  padding-bottom: 0px;
`

const TaskApprovalsSentDialog = (props: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={props.open}
      scroll="paper"
      onClose={() => props.setOpen && props.setOpen(false)}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogContent>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <GrindContainer variantMapping={{ body1: 'div' }}>
            <AnimatedTick />
            <Title variant="h5">{t('Your approval tasks were successfully assigned')}</Title>
            <Description variant="body2">{t('Here’s what happens next')}</Description>
            <StyledUL>
              <li>{t('Each assignee will receive a notification')}</li>
              <li>{t('Each assignee can approve or reject a product with comments')}</li>
              <li>{t('If all assignees approve you’ll be able to publish the product')}</li>
              <li>
                {t(
                  'If 1 or more reject you’ll be notified and the product will be moved to the design stage so you can make changes'
                )}
              </li>
              <li>
                {t(
                  'You can view approvals to check on progress and, if need be, reassign tasks to different colleagues'
                )}
              </li>
            </StyledUL>
          </GrindContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <StyledButton
          disabled={false}
          onClick={() => props.setOpen && props.setOpen(false)}
          color={ColorType.BUTTON}
          variant="contained"
          data-test-id="ok-got-it-approvals-sent"
        >
          {t('Ok, got it')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
export default TaskApprovalsSentDialog
