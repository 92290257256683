import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toRateFormValues } from './transformer'
import EditInterestRateForm from './EditInterestRateForm'
import ReferenceDataActions from 'store/referenceData/actions'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import { Loader } from 'components'
import { EditOrAddIndexRate, BankIndexKey } from 'store/referenceData/types'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export const EditInterestRatePage = () => {
  const { t } = useTranslation()
  const { bankIndexKey } = useParams()

  const dispatch = useDispatch()
  const getIndexRateDetails = (indexKey: BankIndexKey) =>
    dispatch(ReferenceDataActions.getBankIndexRateDetails(indexKey))
  const editRate = (rate: EditOrAddIndexRate) => dispatch(ReferenceDataActions.editRate({ ...rate, bankIndexKey }))

  const selectedIndexRate = useSelector(ReferenceDataSelectors.selectSelectedIndexRate)
  const isLoading = useSelector(ReferenceDataSelectors.selectLoading)
  const isUpdating = useSelector(ReferenceDataSelectors.selectIsUpdating)

  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Index rates'), path: 'reference-data/index-rates' },
      { title: `${selectedIndexRate?.name}`, path: `reference-data/index-rates/${selectedIndexRate?.bankIndexKey}` },
      { title: t('Edit future rate'), path: '' },
    ],
    trigger: !!selectedIndexRate,
  })

  useEffect(() => {
    getIndexRateDetails(`${bankIndexKey}`)
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <EditInterestRateForm
      onSubmit={editRate}
      bankName={selectedIndexRate ? selectedIndexRate.name : ''}
      initialValues={
        selectedIndexRate
          ? toRateFormValues(selectedIndexRate)
          : {
              editOrAddIndexRate: {
                rates: [{}],
              },
            }
      }
      openConfirm={openConfirm}
      setOpenConfirm={setOpenConfirm}
      isUpdating={isUpdating}
      bankIndexKey={bankIndexKey}
    />
  )
}

export default EditInterestRatePage
