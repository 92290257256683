import { Grid } from '@material-ui/core'
import { connect as connectFormik, Field, getIn } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import styled from 'styled-components/macro'
import NestedAnnualFields from './NestedAnnualFields'
import NestedMonthlyFields from './NestedMonthlyFields'
import NestedWeeklyFields from './NestedWeeklyFields'
import { constructRootName, Yip } from 'utils/form.utils'
import { ChargeTemplateNames, FrequencyType, TopLevelFrequency, TopLevelFrequencyPerType } from 'store/products/types'
import { ContextualHelp, SelectFieldComplex, TextFieldComplex } from 'components'
import { Case, SwitchOn } from 'components/ControlComponents'

interface FrequencySelectorProps {
  rootName?: string
  frequencyType: FrequencyType
  variant?: any
  disable?: boolean
}

interface TopLevelSelectorProps {
  name: string
  frequencyType: FrequencyType
  variant?: any
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disable?: boolean
}

const TooltipStyle = styled.div`
  margin-top: 16px;
`
const TopLevelSelector = (props: TopLevelSelectorProps) => {
  const { frequencyType, name, variant, handleOnChange } = props
  const topLevelFrequencyForType = TopLevelFrequencyPerType[frequencyType]
  const { t } = useTranslation()

  return (
    <Grid item xs={4}>
      <Field
        required
        fullWidth
        displayEmpty
        data={topLevelFrequencyForType}
        label={t(`${frequencyType}`)}
        component={SelectFieldComplex}
        name={name}
        validate={Yip(Yup.string().required())}
        selectProps={{ displayEmpty: true }}
        inputLabelProps={{ shrink: true }}
        onClick={handleOnChange}
        disabled={variant === ChargeTemplateNames.TRANSACTION_CHARGE || props.disable}
      />
    </Grid>
  )
}

const CalculationFrequencySelector = connectFormik<FrequencySelectorProps>((props) => {
  const { rootName, frequencyType, variant, formik } = props
  const topLevelName = variant
    ? constructRootName(`${variant}.${frequencyType}.ROOT`, rootName)
    : constructRootName(`${frequencyType}.ROOT`, rootName)
  const nestedLevelName = variant
    ? constructRootName(`${variant}.${frequencyType}`, rootName)
    : constructRootName(`${frequencyType}`, rootName)
  const handleOnchangeMethod = () => {
    if (!props.disable) {
      formik.setFieldValue(`applicationFrequency`, '')
      formik.setFieldTouched(`applicationFrequency`, false)
    }
  }

  const { t } = useTranslation()

  return (
    <Grid container direction="row" spacing={2} alignItems="stretch">
      <TopLevelSelector
        variant={variant}
        name={topLevelName}
        frequencyType={frequencyType}
        handleOnChange={handleOnchangeMethod}
        disable={props.disable}
      />
      <SwitchOn on={getIn(formik.values, topLevelName)}>
        <Case on={TopLevelFrequency.ANNUAL}>
          <NestedAnnualFields rootName={nestedLevelName} frequencyName={frequencyType} disable={props.disable} />
        </Case>
        <Case on={TopLevelFrequency.MONTH}>
          <NestedMonthlyFields rootName={nestedLevelName} frequencyName={frequencyType} disable={props.disable} />
        </Case>
        <Case on={TopLevelFrequency.WEEKLY}>
          <NestedWeeklyFields rootName={nestedLevelName} frequencyName={frequencyType} disable={props.disable} />
        </Case>
        <Case on={TopLevelFrequency.DAYS_FROM_CALCULATION}>
          <Grid item xs={4}>
            <Field
              disable={props.disable}
              label={t('DAYS_FROM_CALCULATION.label')}
              name={`${nestedLevelName}.DAYS_FROM_CALCULATION.secondary`}
              required
              type="number"
              validate={Yip(
                Yup.number()
                  .min(1)
                  .max(365)
                  .required()
                  .integer('This field must be an integer')
                  .typeError(t('This field must be a number'))
              )}
              fullWidth
              component={TextFieldComplex}
            />
          </Grid>
        </Case>
      </SwitchOn>
      <Grid item xs={1}>
        <TooltipStyle>
          <ContextualHelp
            title={
              variant
                ? t(
                    `How often the fee/charge is calculated. Monthly, for example, means that the fee/charge is calculated every month`
                  )
                : t(
                    `Calculation frequency refers to how often interest accrues. Currently only daily accrual is supported. This means interest is calculated on the account balance every day.`
                  )
            }
          />
        </TooltipStyle>
      </Grid>
    </Grid>
  )
})

export default CalculationFrequencySelector
