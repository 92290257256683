import { FieldType, RuleType } from './constants'

const useCreateRuleField = (nameField: any, operatorField: any, valueField: any) => {
  const [nameData, , nameHelpers] = nameField
  const [operatorData, , operatorHelpers] = operatorField
  const [valueData, , valueHelpers] = valueField

  const isMultiSelect = (value: any) => value === 'ANY_OF' || value === 'NOT_ANY_OF'
  const ruleValueFieldSize: any = isMultiSelect(operatorData.value) ? 11 : 4

  const onFieldReset = () => {
    nameHelpers.setValue('')
    operatorHelpers.setValue('')
    valueHelpers.setValue([])
  }

  const onRuleTypeChange = ({ target: { value } }: any) => {
    nameHelpers.setValue(value)
    operatorHelpers.setValue(value && RuleType[value].type !== FieldType.TIN ? '' : 'IS')
    valueHelpers.setValue(isMultiSelect(value) ? [] : '')
  }
  const onRuleOperatorChange = ({ target: { value } }: any) => {
    valueHelpers.setValue(isMultiSelect(operatorData.value) ? [] : '')
    operatorHelpers.setValue(value)
  }

  const onRuleValueChange = ({ target: { value } }: any) => {
    let sanitisedValue: any[]
    if (Array.isArray(value)) {
      sanitisedValue = value
    } else {
      sanitisedValue = [value]
    }
    valueHelpers.setValue(sanitisedValue)
  }

  return {
    ruleType: nameData.value,
    ruleOperator: operatorData.value,
    ruleValue: valueData.value,
    onRuleTypeChange,
    onRuleOperatorChange,
    onRuleValueChange,
    onFieldReset,
    ruleValueFieldSize,
  }
}

export default useCreateRuleField
