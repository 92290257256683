export const args = {
  fontSize: 'small',
  ariaProps: {},
  color: 'primary',
}
export const argTypes = {
  fontSize: {
    type: { name: 'select', required: false },
    control: {
      type: 'select',
      options: ['small', 'default', 'inherit', 'medium', 'large'],
    },
  },
  color: {
    type: { name: 'select', required: false },
    control: {
      type: 'select',
      options: ['primary', 'secondary', 'inherit', 'success', 'error', 'info', 'warning'],
    },
  },
  ariaProps: {
    type: { name: 'object', required: false },
    control: {
      type: 'object',
    },
  },
  title: {
    type: { name: 'text', required: false },
    control: {
      type: 'text',
    },
  },
}

export const parameters = {
  controls: {
    exclude: ['color'],
  },
}
