import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { find } from 'lodash'
import ProcessDetail from './Process'
import { getTasksToPayload } from './taskTransformers'
import GovernanceActions from 'store/governance/actions'
import TaskActions from 'store/tasks/actions'
import { RootState } from 'store'
import GovernanceSelector from 'store/governance/selectors'
import TaskSelector from 'store/tasks/selectors'
import { ProcessFormValues, ProcessKey } from 'store/governance/types'
import { TaskDefinitionFormValue } from 'store/tasks/types'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProcess: () => dispatch(GovernanceActions.getProcess()),
  updateProcess: (updateProcess: ProcessFormValues) => dispatch(GovernanceActions.updateSelectedProcess(updateProcess)),
  saveTask: (processKey: string) => (taskDefinitionFormValue: TaskDefinitionFormValue) =>
    dispatch(TaskActions.createTasksDefinition({ ...getTasksToPayload(taskDefinitionFormValue), processKey })),
  updateTask: (processKey: string) => (taskDefinitionFormValue: TaskDefinitionFormValue) =>
    dispatch(TaskActions.updateTasksDefinition({ ...getTasksToPayload(taskDefinitionFormValue), processKey })),
  getTask: (key: ProcessKey) => dispatch(TaskActions.getTasksDefinition(key)),
})

const mapStateToProps = (state: RootState) => ({
  selectedProcess: GovernanceSelector.selectProcessDetails(state),
  process: GovernanceSelector.selectAllProcess(state),
  selectAllTask: TaskSelector.selectAllTask(state),
  taskLoader: TaskSelector.selectIsLoading(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

export const ProcessPage = ({
  process,
  getProcess,
  selectedProcess,
  updateProcess,
  saveTask,
  getTask,
  selectAllTask,
  taskLoader,
  updateTask,
}: Props) => {
  const { processKey } = useParams()
  if (!processKey) throw new Error('processKey not found')

  const [open, setOpen] = useState(false)
  const onClickCancel = useCallback(() => setOpen(false), [setOpen])
  const onPressUpdate = useCallback(() => setOpen(false), [setOpen])
  useEffect(() => {
    if (!selectedProcess || (selectedProcess && selectedProcess.key !== processKey)) {
      getProcess()
      getTask(processKey)
    }
  }, [getTask, processKey, getProcess, selectedProcess])

  const { t } = useTranslation()
  let currentProcess = selectedProcess

  if (!currentProcess || currentProcess.key !== processKey) {
    if (process) {
      currentProcess = find(process, ['key', processKey])
    }
  }

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Governance'), path: `governance/` },
      { title: `${currentProcess?.name}`, path: '' },
    ],
    trigger: !!currentProcess,
  })

  if (!currentProcess) {
    return null
  }

  return (
    <ProcessDetail
      updateSelectedProcess={updateProcess}
      selectedProcess={currentProcess}
      open={open}
      onClickCancel={onClickCancel}
      onPressUpdate={onPressUpdate}
      saveTask={saveTask(processKey)}
      availableTasks={selectAllTask}
      isLoading={taskLoader}
      updateTask={updateTask(processKey)}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessPage)
