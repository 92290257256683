import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Control, Controller, useWatch } from 'react-hook-form'
import { uniq } from 'lodash'
import { internalMdPadding } from 'dls/shared/styleVar'
import { Subtitle2, Subtitle1, Body2, H3, H4 } from 'dls'
import { CheckIcon } from 'dls/atoms/Icons/MUIIcons'
import CustomCheckbox from 'components/form/CustomCheckbox'
import { MultiParty, MultiPartyAllowedRoles } from 'store/products/typings/multiParty'

const TableContainerNew = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: [col1] 50% [col2] 25% [col3] 25%;
  & > div {
    padding: ${internalMdPadding};
    border: 1px solid ${({ theme }) => theme.palette.primary.greyLight};
  }
  & > div.titleRoles {
    background-color: ${({ theme }) => theme.palette.background.main};
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  & > div.titleWhoCanBeInvited {
    background-color: ${({ theme }) => theme.palette.background.main};
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  & > div.titleWhoCanBeInviteOthers {
    background-color: ${({ theme }) => theme.palette.background.main};
    grid-column-start: 3;
    grid-column-end: end;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  & > div.descOwners {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  & > div.invitedOwners {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  & > div.inviterOwners {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 3;
    grid-column-end: end;
    grid-row-start: 2;
    grid-row-end: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  & > div.descAdmins {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
  & > div.invitedAdmins {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  & > div.inviterAdmins {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 3;
    grid-column-end: end;
    grid-row-start: 3;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }

  & > div.descViewers {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 4;
  }
  & > div.invitedViewers {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  & > div.inviterViewers {
    background-color: ${({ theme }) => theme.palette.background.paper};
    grid-column-start: 3;
    grid-column-end: end;
    grid-row-start: 4;
    grid-row-end: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      grid-template-columns: [col1] 33% [col2] 33% [col3] 33%;
      & > div.titleRoles {
        background-color: ${theme.palette.background.main};
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 1;
        grid-row-end: 1;
        border-bottom: 0;
      }
      & > div.descOwners {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 2;
        grid-row-end: 2;
        border-bottom: 0;
      }
      & > div.descAdmins {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 3;
        grid-row-end: 3;
        border-bottom: 0;
      }
      & > div.descViewers {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 4;
        grid-row-end: 4;
        border-bottom: 0;
      }
      & > div.titleWhoCanBeInvited {
        background-color: ${theme.palette.background.main};
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 5;
        grid-row-end: 5;
        border-bottom: 0;
      }
      & > div.invitedOwners {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 6;
        grid-row-end: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        border-right: 0;
      }
      & > div.invitedAdmins {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 6;
        grid-row-end: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        border-left: 0;
        border-right: 0;
      }
      & > div.invitedViewers {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 3;
        grid-column-end: end;
        grid-row-start: 6;
        grid-row-end: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        border-left: 0;
      }
      & > div.titleWhoCanBeInviteOthers {
        background-color: ${theme.palette.background.main};
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 7;
        grid-row-end: 7;
        border-top: 0;
        border-bottom: 0;
      }
      & > div.inviterOwners {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 8;
        grid-row-end: 8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        border-right: 0;
      }
      & > div.inviterAdmins {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 8;
        grid-row-end: 8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        border-left: 0;
        border-right: 0;
      }

      & > div.inviterViewers {
        background-color: ${theme.palette.background.paper};
        grid-column-start: 3;
        grid-column-end: end;
        grid-row-start: 8;
        grid-row-end: 8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        border-left: 0;
      }
    }








    ${theme.breakpoints.up('sm')} {
      & > div.titleRoles {
        border-right: 0;
      }
      & > div.titleWhoCanBeInvited {
        border-right: 0;
      }
      & > div.titleWhoCanBeInviteOthers {
      }
      & > div.descOwners {
        border-right: 0;
        border-top: 0;
      }
      & > div.invitedOwners {
        border-right: 0;
        border-top: 0;
      }
      & > div.inviterOwners {
        border-top: 0;
      }
      & > div.descAdmins {
        border-right: 0;
        border-top: 0;
      }
      & > div.invitedAdmins {
        border-right: 0;
        border-top: 0;
      }
      & > div.inviterAdmins {
        border-top: 0;
      }
    
      & > div.descViewers {
        border-right: 0;
        border-top: 0;
      }
      & > div.invitedViewers {
        border-right: 0;
        border-top: 0;
      }
      & > div.inviterViewers {
        border-top: 0;
      }
    }
  `};
 
}
`

const CheckboxLabel = styled(Subtitle1)`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`
interface MultiPartyTableProps {
  control: Control<MultiParty, object>
}

const MultiPartyTable = ({ control }: MultiPartyTableProps) => {
  const { t } = useTranslation()

  const inviterAllowedRoles = useWatch({
    control,
    name: 'inviterAllowedRoles',
  })

  const inviteeAllowedRoles = useWatch({
    control,
    name: 'inviteeAllowedRoles',
  })

  const rolesToggle = (name: string, prevValue: MultiPartyAllowedRoles[], value: MultiPartyAllowedRoles) =>
    (({
      target: {
        name,
        value: (prevValue || [])?.includes(value)
          ? [...(prevValue || [])].filter((i) => i !== value)
          : uniq([...(prevValue || []), value]),
      },
    } as unknown) as React.ChangeEvent<HTMLInputElement>)

  const invitedAdmins = (
    <Controller
      name="inviteeAllowedRoles"
      control={control}
      render={({ field }) => (
        <CustomCheckbox
          id="inviteeAllowedRolesAdmins"
          checked={field.value?.includes(MultiPartyAllowedRoles.ADMINISTRATOR)}
          label={t('Admins')}
          {...field}
          onChange={() =>
            field.onChange(
              rolesToggle('inviteeAllowedRoles', inviteeAllowedRoles, MultiPartyAllowedRoles.ADMINISTRATOR)
            )
          }
        />
      )}
    />
  )
  const inviterAdmins = (
    <Controller
      name="inviterAllowedRoles"
      control={control}
      render={({ field }) => (
        <CustomCheckbox
          id="inviterAllowedRolesAdmins"
          checked={field.value?.includes(MultiPartyAllowedRoles.ADMINISTRATOR)}
          label={t('Admins')}
          {...field}
          onChange={() =>
            field.onChange(
              rolesToggle('inviterAllowedRoles', inviterAllowedRoles, MultiPartyAllowedRoles.ADMINISTRATOR)
            )
          }
        />
      )}
    />
  )
  const invitedViewers = (
    <Controller
      name="inviteeAllowedRoles"
      control={control}
      render={({ field }) => (
        <CustomCheckbox
          id="inviteeAllowedRolesViewers"
          checked={field.value?.includes(MultiPartyAllowedRoles.READ_ONLY)}
          label={t('Viewers')}
          {...field}
          onChange={() =>
            field.onChange(rolesToggle('inviteeAllowedRoles', inviteeAllowedRoles, MultiPartyAllowedRoles.READ_ONLY))
          }
        />
      )}
    />
  )
  const inviterViewers = (
    <Controller
      name="inviterAllowedRoles"
      control={control}
      render={({ field }) => (
        <CustomCheckbox
          id="inviterAllowedRolesViewers"
          checked={field.value?.includes(MultiPartyAllowedRoles.READ_ONLY)}
          label={t('Viewers')}
          {...field}
          onChange={() =>
            field.onChange(rolesToggle('inviterAllowedRoles', inviterAllowedRoles, MultiPartyAllowedRoles.READ_ONLY))
          }
        />
      )}
    />
  )
  const titleRoles = (
    <H3
      css={`
        font-weight: 700;
        font-size: 14px;
      `}
    >
      {t('Roles')}
    </H3>
  )
  const titleWhoCanBeInvited = (
    <H3
      css={`
        font-weight: 700;
        font-size: 14px;
      `}
    >
      <Body2 variantMapping={{ body2: 'span' }}>{t('1. Who')}</Body2>{' '}
      <Subtitle2 variantMapping={{ subtitle2: 'span' }} style={{ fontWeight: 700 }}>
        {t('can be invited? *')}
      </Subtitle2>
    </H3>
  )
  const titleWhoCanBeInviteOthers = (
    <H3
      css={`
        font-weight: 700;
        font-size: 14px;
      `}
    >
      <Body2 variantMapping={{ body2: 'span' }}>{t('2. Who')}</Body2>{' '}
      <Subtitle2 variantMapping={{ subtitle2: 'span' }} style={{ fontWeight: 700 }}>
        {t('can invite others? (optional)')}
      </Subtitle2>
    </H3>
  )
  const descOwners = (
    <>
      <H4
        css={`
          font-weight: 700;
          font-size: 14px;
        `}
      >
        {t('Owners')}
      </H4>
      <Body2>
        {t(
          'These are the individuals who open the account and are therefore unrestricted. Owners are responsible for all deposits and any lending liabilities.'
        )}
      </Body2>
    </>
  )
  const descAdmins = (
    <>
      <H4
        css={`
          font-weight: 700;
          font-size: 14px;
        `}
      >
        {t('Admins')}
      </H4>
      <Body2>{t("Admins can fully transact on the account but can't close it.")}</Body2>
    </>
  )
  const descViewers = (
    <>
      <H4
        css={`
          font-weight: 700;
          font-size: 14px;
        `}
      >
        {t('Viewers')}
      </H4>
      <Body2>{t('Viewers only have a read-only view of the account.')}</Body2>
    </>
  )
  const invitedOwners = <CheckboxLabel variantMapping={{ subtitle1: 'div' }}>{t('N/A')}</CheckboxLabel>
  const inviterOwners = (
    <>
      <CheckboxLabel variantMapping={{ subtitle1: 'div' }}>
        <CheckIcon />
        {t('Owners')}
      </CheckboxLabel>
    </>
  )
  return (
    <TableContainerNew>
      <div className="titleRoles">{titleRoles}</div>
      <div className="titleWhoCanBeInvited">{titleWhoCanBeInvited}</div>
      <div className="titleWhoCanBeInviteOthers">{titleWhoCanBeInviteOthers}</div>
      <div className="descOwners">{descOwners}</div>
      <div className="invitedOwners">{invitedOwners}</div>
      <div className="inviterOwners">{inviterOwners}</div>
      <div className="descAdmins">{descAdmins}</div>
      <div className="invitedAdmins">{invitedAdmins}</div>
      <div className="inviterAdmins">{inviterAdmins}</div>
      <div className="descViewers">{descViewers}</div>
      <div className="invitedViewers">{invitedViewers}</div>
      <div className="inviterViewers">{inviterViewers}</div>
    </TableContainerNew>
  )
}

export default MultiPartyTable
