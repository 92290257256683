import { createReducer } from '@reduxjs/toolkit'
import { TenantConfigState } from './types'
import actions from './actions'

const initialState: TenantConfigState = {
  loading: true,
  error: undefined,
  data: undefined,
}

// todo for whatever reason, action typings are broken here...
const reducer = createReducer(initialState, {
  [actions.fetch.type]: (state) => {
    state.loading = true
  },
  [actions.fetchSuccess.type]: (_state, action) => ({
    error: undefined,
    data: action.payload,
    loading: false,
  }),
  [actions.fetchFail.type]: (_state, action) => ({ loading: false, error: action.payload }),
})

export default reducer
