import React from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { TFunction } from 'i18next'
import downloadTemplatePdf from '../utils/downloadTemplatePdf'
import StatementPreviewImage from '../StatementPreviewImage'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'
import PreviewConfigurationDialog from 'pages/features/Statements/PreviewConfigurationDialog'
import { generateMonth } from 'utils/form.utils'
import { StatementType, ProductSegment } from 'store/products/types'
import { Paper, Body2, ARadio, DownloadIconButton } from 'dls'
import { useDialogs } from 'components'

type InputProps = {
  checked: boolean
  value: string
  label: string
  config: StatementConfiguration
  segment?: ProductSegment
  t: TFunction
}

const RadioPaper = styled(Paper)<{ checked: boolean }>`
  ${({ theme }) => `
    box-shadow: ${theme.palette.text.disabled} 0px 2px 2px;
  `}
  ${({ theme, checked }) => `
    border-color: ${checked ? theme.palette.secondary.main : theme.palette.background.paper}
  `}
`

const StatementRadio = React.forwardRef((props: InputProps, ref) => {
  const { config, segment, t, ...inputProps } = props
  const { isDialogOpen, onOpenDialog, onCloseDialog } = useDialogs()

  return (
    <>
      <RadioPaper variant="outlined" square checked={inputProps.checked}>
        <label css="cursor: pointer" htmlFor={config.statementConfigKey}>
          <Box display="flex" alignItems="center">
            <ARadio {...inputProps} id={config.statementConfigKey} inputRef={ref} />
            <Body2>{config.name}</Body2>
          </Box>
          <Box display="grid" gridTemplateColumns="160px 1fr" gridGap={24} mt={1}>
            <Box width={169} height={240}>
              {segment && (
                <StatementPreviewImage
                  template={{
                    templateGroupId: config.template?.id as string,
                    templateName: config.template?.name as string,
                    isDefault: false,
                  }}
                  templateType={StatementType.INTEREST_STATEMENT}
                  productSegment={segment}
                  previewTemplateOpen={onOpenDialog}
                />
              )}
            </Box>

            <Box display="flex" flexDirection="column">
              <Body2 gutterBottom color="textSecondary">
                {t('Segment and description')}
              </Body2>
              <Body2 css="margin-bottom: 16px;">{`${t(config.productSegment)}: ${config.statementDescription}`}</Body2>
              <Body2 gutterBottom color="textSecondary">
                {t('Statement generation')}
              </Body2>
              <Body2 gutterBottom>{`${t('PERIOD.YEAR')}: ${config.startDay} ${generateMonth(
                config.startMonth || 1
              )} `}</Body2>
              <Body2>
                {`${t('Offset')}: ${t(`{{offset}} days`, {
                  offset: config.offsetDays,
                })}
                `}
              </Body2>
            </Box>
          </Box>
        </label>
        <Box position="absolute" right={24} bottom={24}>
          <DownloadIconButton
            aria-label={t('Download statement as a PDF')}
            onClick={() =>
              segment &&
              downloadTemplatePdf(segment, StatementType.INTEREST_STATEMENT, {
                id: config.template?.id as string,
                name: config.template?.name as string,
              })
            }
          />
        </Box>
      </RadioPaper>

      {isDialogOpen && (
        <PreviewConfigurationDialog
          title="Statement template"
          open={isDialogOpen}
          onClose={onCloseDialog}
          templateType={StatementType.INTEREST_STATEMENT}
          productSegment={segment ?? ('' as ProductSegment)}
          statementConfig={config}
          displayConfigInfo
        />
      )}
    </>
  )
})

export default StatementRadio
