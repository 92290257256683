import { useDispatch, useSelector } from 'react-redux'
import { convertValuesToPaymentLimitPayload, convertValuesToPaymentLimitsConfigPayload } from './transformers'
import { selectConfig } from 'store/tenant-config/selectors'
import { Feature, ProductKey } from 'store/products/types'
import {
  PaymentLimitFormValues,
  PaymentLimitURLValues,
  PaymentLimitsConfigFormValues,
} from 'store/products/typings/paymentLimits'
import ProductsActions from 'store/products/actions'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'

const usePaymentLimits = ({ productKey, version }: { productKey: ProductKey; version?: number }) => {
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectConfig)

  const submitPaymentLimitsConfig = (values: PaymentLimitsConfigFormValues) => {
    if (version) {
      dispatch(
        ProductsActions.setPaymentLimitsConfig({
          ...convertValuesToPaymentLimitsConfigPayload(values),
          productKey,
          version,
        })
      )
    }
  }

  const submitPaymentLimit = (values: PaymentLimitFormValues & PaymentLimitURLValues) => {
    if (values.limitName && version) {
      dispatch(
        ProductsActions.setPaymentLimit({
          ...convertValuesToPaymentLimitPayload(values),
          limitName: values.limitName,
          productKey,
          version,
        })
      )
    }
  }

  const deletePaymentLimit = ({ limitRuleId, limitName }: { limitRuleId: string; limitName: string }) => {
    if (version) {
      dispatch(ProductsActions.deletePaymentLimit({ limitRuleId, limitName, productKey, version }))
    }
  }

  return {
    isNotAllowed: !isFeatureAllowedByConfig(tenantConfig, Feature.PAYMENT_LIMITS),
    submitPaymentLimitsConfig,
    submitPaymentLimit,
    deletePaymentLimit,
  }
}

export default usePaymentLimits
