import { red, blueGrey, orange, green, blue, purple, grey } from '@material-ui/core/colors'
import { Theme } from '@material-ui/core/styles'
import { css } from 'styled-components/macro'
import { BankDateTime } from './date.utils'
import { ProductStatus } from 'store/products/typings/productStatus'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { AvatarProps, AvatarColor } from 'dls/shared/types'
import { CustomTheme } from 'dls/shared/styled'

export const EntityColorSelector = css<AvatarProps>`
  ${({ color, theme }: any) => {
    switch (color) {
      case AvatarColor.PRODUCT:
      case AvatarColor.CURRENCY:
      case AvatarColor.ELIGIBILITY:
      case AvatarColor.TRANSACTIONRULES:
      case AvatarColor.REPAYMENTSCHEDULE:
        return {
          background: `${theme.palette.orange.light}`,
          color: `${theme.palette.orange.dark}`,
        }
      case AvatarColor.CREDITINTEREST:
      case AvatarColor.PAYMENTLIMITS:
      case AvatarColor.PRODUCTDETAILS:
      case AvatarColor.COLLEAGUE:
      case AvatarColor.DOCUMENTS:
      case AvatarColor.PACKAGE:
      case AvatarColor.PRODUCTSYNDICATION:
        return {
          background: `${theme.palette.magenta.light}`,
          color: `${theme.palette.magenta.dark}`,
        }

      case AvatarColor.ACCOUNT:
      case AvatarColor.INDEXRATES:
      case AvatarColor.EXTERNALIDS:
      case AvatarColor.CARDS:
      case AvatarColor.TERMAMOUNT:
      case AvatarColor.STATEMENTS:
      case AvatarColor.ANYSOURCE:
      case AvatarColor.SUSPENSEACCOUNT:
        return {
          background: `${theme.palette.blue.light}`,
          color: `${theme.palette.blue.dark}`,
        }
      case AvatarColor.TAXRULES:
      case AvatarColor.DEBITINTEREST:
      case AvatarColor.TERMS:
      case AvatarColor.DEVICES:
      case AvatarColor.REFERENCEDATA:
      case AvatarColor.CARDLIMIT:
        return {
          background: `${theme.palette.green.light}`,
          color: `${theme.palette.green.dark}`,
        }
      case AvatarColor.GOVERNANCE:
      case AvatarColor.PRODUCTCARDLIMIT:
      case AvatarColor.CREDITLINES:
        return {
          background: `${theme.palette.yellow.light}`,
          color: `${theme.palette.yellow.dark}`,
        }
      case AvatarColor.HELP:
      case AvatarColor.HISTORY:
      case AvatarColor.PRODUCTCODE:
      case AvatarColor.FEES:
        return {
          background: `${theme.palette.teal.light}`,
          color: `${theme.palette.teal.dark}`,
        }
      case AvatarColor.REWARDS:
        return {
          background: `${theme.palette.yellow.light}`,
          color: `${theme.palette.yellow.dark}`,
        }
      case AvatarColor.DISABLED:
        return {
          background: `${theme.palette.background.dark}`,
          color: `${theme.palette.text.primary}`,
        }
      case AvatarColor.TenX:
        return {
          background: `${theme.palette.primary.main}`,
          color: `${theme.palette.primary.light}`,
        }
      case AvatarColor.PRODUCTS:
        return {
          background: `${theme.palette.primary.dark}`,
          color: `${theme.palette.primary.light}`,
        }

      case AvatarColor.ORANGE:
        return {
          background: `${theme.palette.orange.light}`,
          color: `${theme.palette.orange.dark}`,
        }

      case AvatarColor.MAGENTA:
        return {
          background: `${theme.palette.magenta.light}`,
          color: `${theme.palette.magenta.dark}`,
        }

      case AvatarColor.BLUE:
        return {
          background: `${theme.palette.blue.light}`,
          color: `${theme.palette.blue.dark}`,
        }

      case AvatarColor.GREEN:
        return {
          background: `${theme.palette.green.light}`,
          color: `${theme.palette.green.dark}`,
        }

      case AvatarColor.YELLOW:
        return {
          background: `${theme.palette.yellow.light}`,
          color: `${theme.palette.yellow.dark}`,
        }
      case AvatarColor.TEAL:
        return {
          background: `${theme.palette.teal.light}`,
          color: `${theme.palette.teal.dark}`,
        }
      default:
        return 'unset'
    }
  }}
`

export const notificationColors = (theme: Theme) => ({
  success: theme.palette.success.main,
  error: theme.palette.error.dark,
  warning: theme.palette.warning.dark,
  info: theme.palette.primary.main,
})

export const colorsForPackagesWidget = [blueGrey[300], orange[600], green[300], red[300]]
export const colorsForProductsWidget = [
  blueGrey[300],
  purple[300],
  blue[300],
  orange[600],
  green[300],
  orange[300],
  red[300],
]

export const filterSelectedBackgroundColor = grey[100]

export const getStatusColor = ({ text, date, theme }: { text: string; date?: BankDateTime; theme: CustomTheme }) => {
  switch (text) {
    case ProductStatus.LIVE:
    case PackagesStatus.LIVE:
      if (date && date.isAfterNow) {
        return theme.palette.scheduled.main
      }
      return theme.palette.live.main
    case ProductStatus.APPROVED:
    case PackagesStatus.APPROVED:
    case TaskStatus.CLOSED:
      return theme.palette.approved.main
    case ProductStatus.DESIGN:
    case PackagesStatus.DESIGN:
      return theme.palette.design.main
    case ProductStatus.REVIEW:
    case TaskStatus.ACTIVE:
    case PackagesStatus.REVIEW:
    case TaskStatus.PENDING:
    case 'Pending review':
      return theme.palette.review.main
    case ProductStatus.UPDATED:
    case PackagesStatus.UPDATED:
      return theme.palette.updated.main
    case TaskStatus.REJECTED:
    case ProductStatus.CLOSED:
    case PackagesStatus.CLOSED:
    case PackagesStatus.RETIRED:
      return theme.palette.closed.main
    default:
      return theme.palette.background.dark
  }
}
