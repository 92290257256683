import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import PaymentLimitsPageContent from './components/PaymentLimitsPageContent'
import { BackButton, TemplateSubReview, H1, Body2, Loader, NoActivityHelpPanel } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Feature } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { PaymentLimitsConfigYearType } from 'store/products/typings/paymentLimits'
import AboutPaymentLimitsCTA from 'pages/features/PaymentLimits/components/shared/AboutPaymentLimitsCTA'

const ViewPaymentLimitsPage = () => {
  const { t } = useTranslation()
  const { productKey = '' } = useParams()
  const navigate = useNavigate()
  const [product, isLoading] = useSelectedProduct(productKey)

  useUpdateBreadcrumbs({ productKey, context: Feature.PAYMENT_LIMITS })

  if (isLoading || !product) {
    return <Loader />
  }

  const onPressBack = () => {
    navigate(`/products/${productKey}`)
  }

  return (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={onPressBack} aria-controls="main" />
              </Grid>
              <Grid item>
                <div style={{ gridArea: 'infoArea', textAlign: 'right' }}>
                  <AboutPaymentLimitsCTA />
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <H1 gutterBottom>{t('Payment limits')}</H1>
              </Grid>
            </Grid>
            <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
              {t(
                'Here you can view and manage inbound and outbound payment limits. You can also change your definition of ‘year’ by choosing between calendar year, tax year or anniversary of account opening.'
              )}
            </Body2>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <PaymentLimitsPageContent
            config={product.paymentLimits?.config || { yearType: PaymentLimitsConfigYearType.ANNIVERSARY }}
            paymentLimits={product.paymentLimits?.paymentLimits || []}
            currencyCode={product.currency.currencyCode}
            productKey={product.productKey}
          />
        }
      />
    </ContentContainer>
  )
}

export default ViewPaymentLimitsPage
