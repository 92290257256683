import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReviewTransactions from './components/ReviewTransaction'
import {
  SubscriptionKey,
  TransactionTransferRequest,
  WriteOffAndWriteBackTransactionTransferRequest,
  SuspenseTransactionsTransferResult,
} from 'store/accounts/types'
import BreadcrumbsActions from 'store/breadcrumbs/actions'
import { RootState } from 'store/types'
import SuspenseAccountsSelectors from 'store/accounts/selectors'
import { Loader } from 'components'
import SuspenseAccountsActions from 'store/accounts/actions'
import { PLACCOUNTNAME, NOSTRO_WASH_ACCOUNTNAME } from 'store/utils/constants'

interface OwnProps {}

interface LocationStateType {
  subscriptionKey: SubscriptionKey
  suspenseTransactionsTransferResult: SuspenseTransactionsTransferResult
}

const mapDispatchToProps = {
  confirmTransactions: SuspenseAccountsActions.confirmTransaction,
  confirmWriteOffAndWriteBackTransactions: SuspenseAccountsActions.confirmWriteOffAndWriteBackTransaction,
  confirmNostroWashTransaction: SuspenseAccountsActions.confirmNostroWashTransaction,
  confirmTransactionResultClear: SuspenseAccountsActions.confirmTransactionResultClear,
}

const mapStateToProps = (state: RootState) => ({
  selectedConfirmTransactionLoading: SuspenseAccountsSelectors.selectedConfirmTransactionLoading(state),
  selectedConfirmTransactionResult: SuspenseAccountsSelectors.selectedConfirmTransactionResult(state),
})

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps

const ReviewTransactionsPage = ({
  confirmTransactions,

  selectedConfirmTransactionLoading,
  selectedConfirmTransactionResult,
  confirmTransactionResultClear,
  confirmWriteOffAndWriteBackTransactions,
  confirmNostroWashTransaction,
}: // searchTransaction,
Props): React.ReactElement => {
  const { t } = useTranslation()
  const { subscriptionKey } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const transferResult = (state as LocationStateType).suspenseTransactionsTransferResult

  useEffect(() => {
    if (location && transferResult) {
      const suspenseAccountName = transferResult.suspenseAccount ? transferResult.suspenseAccount.name : ''
      dispatch(
        BreadcrumbsActions.updateBreadCrumbs([
          { title: t('Accounts'), path: 'accounts/' },
          { title: suspenseAccountName, path: `accounts/${subscriptionKey}` },
          { title: t(' Confirm transfer'), path: '' },
        ])
      )
    } else {
      navigate(`/accounts/${subscriptionKey}`)
    }
  }, [t, location, subscriptionKey, transferResult])

  let confirmPayload: TransactionTransferRequest
  let confirmWriteOffAndWriteBackPayload: WriteOffAndWriteBackTransactionTransferRequest

  const onClickSubmit = () => {
    if (location && transferResult) {
      const suspenseTransactionIdsArray: string[] = transferResult.selectedTransactions.map(
        (item: any) => item.transactionId
      )
      if (transferResult.destinationAccount === PLACCOUNTNAME) {
        confirmWriteOffAndWriteBackPayload = {
          suspenseTransactionIds: suspenseTransactionIdsArray,
        }
        confirmWriteOffAndWriteBackTransactions(confirmWriteOffAndWriteBackPayload)
      } else if (transferResult.destinationAccount === NOSTRO_WASH_ACCOUNTNAME) {
        confirmWriteOffAndWriteBackPayload = {
          suspenseTransactionIds: suspenseTransactionIdsArray,
        }
        confirmNostroWashTransaction(confirmWriteOffAndWriteBackPayload)
      } else if (transferResult.destinationAccount) {
        if (
          typeof transferResult.destinationAccount !== 'string' &&
          'subscriptionKey' in transferResult.destinationAccount
        ) {
          confirmPayload = {
            destinationSuspenseSubscription: transferResult.destinationAccount.subscriptionKey || '',
            suspenseTransactionIds: suspenseTransactionIdsArray,
          }
        }
        confirmTransactions(confirmPayload)
      }
    }
  }

  if (!location) {
    return <Loader />
  }
  return (
    <ReviewTransactions
      selectedSuspenseTransactionsTransferResult={transferResult}
      initialValues={{}}
      onSubmit={onClickSubmit}
      subscriptionKey={subscriptionKey}
      selectedConfirmTransactionLoading={selectedConfirmTransactionLoading}
      selectedConfirmTransactionResult={selectedConfirmTransactionResult}
      confirmTransactionResultClear={confirmTransactionResultClear}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTransactionsPage)
