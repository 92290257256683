import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalize } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import TransactionRulesForm from './TransactionRulesForm'
import ProductsActions from 'store/products/actions'
import {
  Feature,
  TransactionExceptionType,
  TransactionRuleAccess,
  TransactionRuleItemRule,
  TransactionRules,
} from 'store/products/types'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useFeatureAvailable from 'pages/Product/hooks/useFeatureAvailable'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import { selectConfig } from 'store/tenant-config/selectors'

interface OwnProps {
  transactionExceptionType: TransactionExceptionType
}

export const initialRule = (order: any): TransactionRuleItemRule => ({
  key: uuidv4() as string,
  access: undefined,
  applicableCriteria: [
    {
      domain: 'PMNT',
    },
    {
      domain: 'CAMT',
    },
  ],
  matchCriteria: [
    {
      operator: '' as any,
      type: '' as any,
      values: '' as any,
    },
  ],
  order,
})

const TransactionRulesPage = (props: OwnProps): React.ReactElement | null => {
  const { productKey = '', index } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectConfig)
  const { t } = useTranslation()
  const [product, isLoading] = useSelectedProduct(productKey)
  useUpdateBreadcrumbs({
    productKey,
    context: `Transaction rules: ${props.transactionExceptionType} exceptions`,
    useTranslate: false,
  })
  useFeatureAvailable(productKey, Feature.TRANSACTIONRULES)
  useUpdateBreadcrumbs({
    productKey,
    context: `Transaction Rules: ${capitalize(props.transactionExceptionType)} exceptions`,
    useTranslate: false,
  })
  if (!isFeatureAllowedByConfig(tenantConfig, Feature.TRANSACTIONRULES)) navigate(`./../not-allowed`)

  if (!product) return null

  const rules = product.transactionRules?.[props.transactionExceptionType]?.rules
  const onSubmit = (formValues: any) => {
    let order: number
    if (index) {
      order = Number.parseInt(index, 10)
    } else {
      order = Array.isArray(rules) ? rules.length : 0
    }
    const transactionRules: TransactionRules = { ...product.transactionRules }
    const updatedRules = Array.isArray(rules) ? [...rules] : []
    updatedRules[order] = formValues
    transactionRules[props.transactionExceptionType] = {
      ...transactionRules[props.transactionExceptionType],
      rules: updatedRules,
    } as any
    return dispatch(
      ProductsActions.setTransactionRules({
        ...transactionRules,
        productKey,
        transactionRuleMessage: t(`${props.transactionExceptionType.toUpperCase()}_TRANSACTION`),
      })
    )
  }

  const transactionRules = index && rules ? rules[index] : initialRule(rules ? rules.length : 0)
  const initialValues = {
    ...transactionRules,
    access: transactionRules.access || ('' as TransactionRuleAccess),
  }
  return isLoading || !product ? (
    <Loader />
  ) : (
    <TransactionRulesForm
      productKey={productKey}
      initialValues={initialValues}
      onSubmit={onSubmit}
      transactionExceptionType={props.transactionExceptionType}
      defaultAccess={product.transactionRules?.[props.transactionExceptionType]?.defaultAccess as any}
    />
  )
}

export default TransactionRulesPage
