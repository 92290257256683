import React from 'react'
import { Grid } from '@material-ui/core'
import { Field, FormikProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import moment from 'moment-timezone'
import { findIndex } from 'lodash'
import { RowGrid } from '../styles'
import { DATE_FORMAT } from 'utils/date.utils'
import { FieldPercentage, RepeaterLayout, ContextualHelp } from 'components'
import { EditOrAddIndexRate, InterestRate } from 'store/referenceData/types'
import DateTimePickerComponent from 'components/form/DateTimePickerComponent'

interface OwnProps {
  name: string
  futureDate: InterestRate[]
}

interface RatesFormProps extends FormikProps<EditOrAddIndexRate>, OwnProps {}

const TooltipStyle = styled.div`
  margin-left: 10px;
  margin-top: 19px;
  color: ${(props: any) => `${props.theme.palette.text.secondary}`};
`
const AddInterestRateList = (_props: RatesFormProps) => {
  const { t } = useTranslation()
  const { name, values, isValid, futureDate } = _props
  const {
    editOrAddIndexRate: { rates },
  } = values
  const datesInList = [...futureDate].map((i) => i && i.effectiveDate && moment(i.effectiveDate).format(DATE_FORMAT))
  const ratesList = rates && [...rates].map((i) => i && i.effectiveDate && moment(i.effectiveDate).format(DATE_FORMAT))
  const ratesLength = rates?.length

  const lastTierBandIndex = getIn(values, `${name}`).length - 1
  return (
    <RepeaterLayout
      name={name}
      labelDelete={t('Delete Rate')}
      labelNewItem={t('Add a Rate')}
      newItem={{ indexRate: null, effectiveDate: null }}
      isItemDeletable={() => lastTierBandIndex > 0}
      isAddDisabled={!isValid}
      deleteButtonStyle={{ top: 7, marginLeft: 10 }}
    >
      {({ name: itemName, index }) => (
        <Grid container direction="row" spacing={2} key={index}>
          <RowGrid item xs={6}>
            <Field
              label={t(`Rate`)}
              name={`${itemName}.indexRate`}
              key={`${itemName}.${index}.${ratesLength}.indexRate`}
              required
              fullWidth
              percentageIsPrefix
              component={FieldPercentage}
              helperText={t('E.g. 1.25')}
              inputLabelProps={{ shrink: true }}
              numberFormatProps={{
                isNumericString: true,
              }}
            />
          </RowGrid>
          <RowGrid item xs={6} css="display: -webkit-inline-box;">
            <Field
              required
              label={t('Effective date')}
              pickerType="date"
              name={`${itemName}.effectiveDate`}
              key={`${itemName}.${index}.${ratesLength}.effectiveDate`}
              component={DateTimePickerComponent}
              disablePast
              fullWidth
              defaultIsNow={false}
              dateTimePickerProps={{
                shouldDisableDate: (date: moment.Moment) => {
                  if (date) {
                    const foundedDatesInListIndex = findIndex(datesInList, (o) => o === date.format(DATE_FORMAT))
                    const foundedRatesListIndex = findIndex(
                      ratesList,
                      (o, innerIndex) => o === date.format(DATE_FORMAT) && index !== innerIndex
                    )

                    if (foundedDatesInListIndex !== -1 || foundedRatesListIndex !== -1) {
                      return true
                    }
                  }

                  return false
                },
              }}
            />
            <TooltipStyle>
              <ContextualHelp
                title={t(
                  'The date from which the rate will be used in interest calculations. Rates cannot be added with historic effective dates and effective dates cannot be changed once submitted'
                )}
              />
            </TooltipStyle>
          </RowGrid>
        </Grid>
      )}
    </RepeaterLayout>
  )
}
export default AddInterestRateList
