import { Grid, Typography } from '@material-ui/core'
import { FastField, Field, getIn, connect as connectFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { range } from 'lodash'
import SelectImageTemplate from './SelectImageTemplate'
import { ExtendedProduct, StatementPeriod, StartMonth, StatementType, FrequencyTypeValue } from 'store/products/types'
import { Yip } from 'utils/form.utils'
import SelectFieldComplex, { SelectFieldData } from 'components/form/SelectFieldComplex'
import { TextFieldComplex, CheckboxSimple } from 'components'
import { StatementAnniversaryOfAccount } from 'api/products.transformers'
import { InfoPanel } from 'dls'

const StyledInfoPanel = styled(InfoPanel)`
  margin-bottom: 24px;
`

interface StatementFormProps {
  name: string
  product: ExtendedProduct
}

const offsetDays = range(1, 29)

const offsetDaysData: SelectFieldData = {}
offsetDays.forEach((key) => {
  offsetDaysData[key] = { name: key }
})

const StatementPeriodData = {
  MONTH: { name: StatementPeriod.MONTH, label: `Monthly` },
  YEAR: { name: StatementPeriod.YEAR, label: `Annually` },
  // DEFAULT: { name: StatementPeriod.DEFAULT, label: `Annually on day of account opening` },
}

const getMaxDay = (statementPeriod: StatementPeriod, startMonth: number): number => {
  if ([2].includes(startMonth) || statementPeriod === StatementPeriod.MONTH) return 28
  if ([1, 3, 5, 7, 8, 10, 12].includes(startMonth)) return 31
  return 30
}

export const StatementsElement = connectFormik<StatementFormProps>((props) => {
  const { t } = useTranslation()
  const {
    name,
    formik: { values, setFieldValue, setFieldTouched },
    product,
  } = props
  const statementPeriod = getIn(values, `${name}.statementPeriod`)
  const startMonth = getIn(values, `${name}.startMonth`)
  const startMonthsheme: SelectFieldData = {}
  startMonthsheme[t(`STATEMENTS.${FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT}`)] = {
    name: StatementAnniversaryOfAccount,
    index: -1,
  }
  startMonthsheme.ANNIVERSARY = {
    name: '',
    header: true,
    label: t('ANNIVERSARY'),
    index: -2,
  }
  startMonthsheme.SPECIFIC_MONTH = {
    name: '',
    header: true,
    label: t('SPECIFIC MONTH'),
    index: 0,
  }
  Object.keys(StartMonth).forEach((key, index) => {
    startMonthsheme[`${t(key)}`] = { name: index + 1, index: index + 1 }
  })

  const statementDays = range(1, getMaxDay(statementPeriod, startMonth) + 1)
  const startDayData: SelectFieldData = {}
  if (statementPeriod !== StatementPeriod.YEAR) {
    startDayData[t(`STATEMENTS.${FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT}`)] = {
      name: StatementAnniversaryOfAccount,
      index: -1,
    }
    startDayData.ANNIVERSARY = {
      name: '',
      header: true,
      label: t('ANNIVERSARY'),
      index: -2,
    }
    startDayData.SPECIFIC_DAYS = {
      name: '',
      header: true,
      label: t('SPECIFIC DAY'),
      index: 0,
    }
  }
  statementDays.forEach((key, index) => {
    startDayData[key] = { name: key, index: index + 1 }
  })
  const statementType = getIn(values, `${name}.statementType`)

  const handleStatementPeriodChange = (value: any) => {
    setFieldValue(`${name}.statementPeriod`, value)
    setFieldValue(`${name}.startDay`, undefined)
    setFieldValue(`${name}.startMonth`, undefined)
    setFieldTouched(`${name}.statementPeriod`, true)
  }
  const resetStartDay = () => {
    setFieldValue(`${name}.startDay`, undefined)
  }
  return (
    <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          {t('Frequency, start & generation date')}
        </Typography>
        {statementType === StatementType.INTEREST_SUMMARY ? (
          <InfoPanel title={t('Credit Interest Summaries are currently only available annually')} />
        ) : null}
      </Grid>
      <Grid item sm={12} md={4}>
        <Field
          name={`${name}.statementPeriod`}
          component={SelectFieldComplex}
          onChange={handleStatementPeriodChange}
          data={StatementPeriodData}
          required
          validate={Yip(Yup.string().required())}
          placeholder={t('Please select')}
          label={t('Statement frequency')}
          selectProps={{ displayEmpty: true }}
          inputLabelProps={{ shrink: true }}
        />
      </Grid>

      {statementPeriod === StatementPeriod.MONTH ||
      statementPeriod === StatementPeriod.YEAR ||
      statementType === StatementType.INTEREST_SUMMARY ? (
        <>
          {statementPeriod === StatementPeriod.YEAR || statementType === StatementType.INTEREST_SUMMARY ? (
            <Grid item sm={12} md={4}>
              <Field
                name={`${name}.startMonth`}
                label={t('Start month')}
                required
                orderByIndex
                data={startMonthsheme}
                selectProps={{ displayEmpty: true }}
                inputLabelProps={{ shrink: true }}
                component={SelectFieldComplex}
                onChange={resetStartDay}
                validate={Yip(Yup.string().required('This is a required field').nullable())}
              />
            </Grid>
          ) : null}
          {(startMonth || statementPeriod === StatementPeriod.MONTH) && startMonth !== StatementAnniversaryOfAccount ? (
            <Grid item sm={12} md={4}>
              <Field
                name={`${name}.startDay`}
                label={t('Start day')}
                required={startMonth !== StatementAnniversaryOfAccount}
                orderByIndex
                data={startDayData}
                selectProps={{ displayEmpty: true }}
                inputLabelProps={{ shrink: true }}
                component={SelectFieldComplex}
              />
            </Grid>
          ) : null}
        </>
      ) : null}
      <Grid item xs={12}>
        <StyledInfoPanel
          body={t(
            'Statement generation happens the day after the above frequency period ends, you can use the generation offset to increase this period.'
          )}
        />
        <StyledInfoPanel
          body={t(
            'Example: A monthly statement with a start day of the 1st and a generation offset of 1 will be generated on the 2nd of the next month.'
          )}
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item sm={12} md={4}>
            <FastField
              name={`${name}.offsetDays`}
              label={t('Generation offset (days)')}
              required
              data={offsetDaysData}
              selectProps={{ displayEmpty: true }}
              inputLabelProps={{ shrink: true }}
              component={SelectFieldComplex}
              validate={Yip(Yup.string().required('This is a required field'))}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom style={{ marginTop: 12 }}>
          {t('Statement design')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('Internal description (optional – will not appear on statement)')}
          name={`${name}.statementDescription`}
          component={TextFieldComplex}
          fullWidth
          inputLabelProps={{ shrink: true }}
          placeholder={t('Enter description')}
        />
      </Grid>
      {statementPeriod === StatementPeriod.MONTH ||
      statementPeriod === StatementPeriod.YEAR ||
      statementType === StatementType.INTEREST_SUMMARY ? (
        <>
          <Grid item xs={12}>
            <FastField
              name={`${name}.template`}
              component={SelectImageTemplate}
              inputProps={{
                'data-test-id': 'statement-item-template',
              }}
              product={product}
              templateType={statementType}
              additionalContent={
                <div style={{ marginLeft: -12, marginTop: 20 }}>
                  <Field
                    name={`${name}.hideExternalIdentifiers`}
                    component={CheckboxSimple}
                    opposite
                    description={t('Include account identifiers on statement')}
                    type="checkbox"
                  />
                </div>
              }
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  )
})
