import React, { Suspense } from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import tenantConfig from 'config/tenantConfig.json'
import { Loader } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'

const ReactJson = React.lazy(() => import('searchable-react-json-view'))

const TypographyStyle = styled(Typography)`
  margin-top: 24px;
  margin-bottom: 24px;
`

const About = () => {
  const { t } = useTranslation()

  return (
    <ContentContainer data-test-id="about-page">
      <TypographyStyle variant="h1" gutterBottom>
        {t('Tenant configuration details.')}
      </TypographyStyle>
      <Suspense fallback={<Loader />}>
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          collapsed={2}
          indentWidth={4}
          enableClipboard={false}
          src={tenantConfig}
        />
      </Suspense>
    </ContentContainer>
  )
}

export default About
