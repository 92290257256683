import { TaskDefinitionFormValue, TaskDefinitionValue } from 'store/tasks/types'

export const getTasksToPayload = (payload: TaskDefinitionFormValue): TaskDefinitionFormValue => {
  if (payload && payload.taskDefinitions) {
    const taskDefinitions = payload.taskDefinitions.map((i: TaskDefinitionValue, index: number) => ({
      ...i,
      sequence: index + 1,
    }))
    return { taskDefinitions }
  }
  return { taskDefinitions: [] }
}
