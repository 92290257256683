import React from 'react'
import { CircularProgress } from '@material-ui/core'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { IconPreview, StatementNoPreview } from 'assets'
import { ProductSegment, StatementType } from 'store/products/types'
import { Template } from 'store/statement/types'
import { Button } from 'dls'
import useTemplateImage from 'pages/features/Statements/utils/useTemplateImage'
import { TemplateImage } from 'pages/Templates/_shared/styled'

interface Props {
  template: Template
  templateType: StatementType
  productSegment: ProductSegment
  previewTemplateOpen?: VoidFunction
}

const StatementConfigurationSampleImage = ({ template, productSegment, templateType, previewTemplateOpen }: Props) => {
  const { t } = useTranslation()
  const { templateGroupId, templateName } = template
  const { src, error } = useTemplateImage(productSegment, templateType, templateGroupId)

  if (src || error) {
    if (previewTemplateOpen && !error) {
      return (
        <Button onClick={previewTemplateOpen} css="position: relative;">
          <img src={IconPreview} alt={t('preview')} css="position: relative; left: calc(50% - 12px)" />
          <TemplateImage
            width="100%"
            src={error && !src ? StatementNoPreview : src}
            alt={error && !src ? 'No preview available ' : templateName}
            css="margin-left: -24px"
          />
        </Button>
      )
    }

    return (
      <TemplateImage
        width="100%"
        src={error && !src ? StatementNoPreview : src}
        alt={error && !src ? 'No preview available' : templateName}
      />
    )
  }

  return (
    <div css="width: 100%; min-width: 170; text-align: center">
      <CircularProgress />
    </div>
  )
}
export default StatementConfigurationSampleImage
