import { Box, Grid } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckboxSimple } from 'components'

export const StudentAccountEligibilityElement = ({ name }: { name: string }) => {
  const { t } = useTranslation()
  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Box css={{ marginBottom: '20px' }}>
        <Field
          name={`${name}.studentIdRequired`}
          description={t('Student ID verification required')}
          component={CheckboxSimple}
          type="checkbox"
        />
      </Box>
    </Grid>
  )
}
