import { createAction } from '@reduxjs/toolkit'
import { Modal, HelpModalType } from './types'
import { ExtendedProduct } from 'store/products/types'

const actions = {
  showFeatureProgressModal: createAction('MODAL/PROCESS'),
  showFeatureSuccessModal: createAction<ExtendedProduct>('MODAL/SUCCESS'),
  showFeatureDeletedModal: createAction<ExtendedProduct>('MODAL/DELETE'),
  showFeatureFailureModal: createAction<Error>('MODAL/FAIL'),
  showInfographicModal: createAction<HelpModalType>('MODAL/INFOGRAPHIC'),
  closeModal: createAction('MODAL/CLOSE'),
  showModal: createAction<Modal>('MODAL/SHOW'),
  confirmModalAction: createAction('MODAL/CONFIRM_ACTION'),
  cancelModalAction: createAction('MODAL/CANCEL_ACTION'),
}

export default actions
