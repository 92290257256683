import React from 'react'
import { capitalize } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import {
  Archive,
  Check,
  Clear,
  Create,
  ErrorOutline,
  Event,
  PlaylistAddCheck,
  SettingsSystemDaydream,
  Spellcheck,
  TrendingDown,
} from '@material-ui/icons'
import moment from 'moment'
import { ProductStatus } from 'store/products/typings/productStatus'
import { BadgeSvgElement, BadgeProps } from 'dls/shared/types'
import {
  CurrencyIcon,
  ExternalIdsIcon,
  RepaymentScheduleIcon,
  ElgibilityIcon,
  ProductCodeIcon,
  PaymentLimitsIcon,
  FeesIcon,
  CreditInterestIcon,
  StatementsIcon,
  TermsIcon,
  TransactionRulesIcon,
  CardsIcon,
  CardLimitIcon,
  ProductCardLimitIcon,
  DocumentsIcon,
  SubscriptionRulesIcon,
  InputSettings,
  CreditLimitIcon,
  GovernanceIcon,
  ProductDetailsIcon,
  MultiPartyIcon,
  SyndicationIcon,
  RewardsIcon,
} from 'dls/atoms/Icons/SVGIcons'
import {
  EntityBadgeProduct,
  EntityBadgeCurrency,
  EntityBadgeExternalIds,
  EntityBadgeRepaymentSchedule,
  EntityBadgeElgibility,
  EntityBadgePaymentLimits,
  EntityBadgeFees,
  EntityBadgeCreditInterest,
  EntityBadgeStatements,
  EntityBadgeTerms,
  EntityBadgeTransactionRules,
  EntityBadgeCards,
  EntityBadgeDocuments,
  EntityBadgeSubscriptionRules,
  EntityBadgeCardLimit,
  EntityBadgeProductCode,
  EntityBadgeProductCardLimit,
  EntityBadgeCreditLimit,
  EntityBadgeGovernance,
  EntityBadgeDebitInterest,
  EntityBadgeProductDetails,
  EntityBadgeMultiParty,
  EntityBadgeProductSyndication,
} from 'dls/atoms'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { LifeCycle } from 'store/governance/typings/lifeCycle'
import { BankDateTime } from 'utils/date.utils'
import {
  ExtendedProduct,
  Feature,
  Product,
  CalendarDay,
  CalendarMonth,
  MonthlyFrequencyItems,
  ApplicationFrequency,
  WeeklyFrequencyItems,
  YearlyFrequencyItems,
} from 'store/products/types'
import { FrequencyFormValues } from 'store/products/typings/frequencySelector'
import { Task } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { TenantConfig } from 'store/tenant-config/types'
import { getFormattedCurrency } from 'utils/ui.utils'
import { isVersionFirst } from 'utils/productVersion.utils'
import { EntityBadgeRewards } from 'dls/atoms/EntityBadges/EntityBadge'

export { getStatusColor } from 'utils/customColors'

export const featureNames = Object.values(Feature)
export const CheckIcon = <Check />

export const featurePrint: { [s: string]: React.ReactElement | any } = {
  statements: <StatementsIcon />,
  termsAndConditions: <TermsIcon />,
  eligibility: <ElgibilityIcon />,
  creditInterest: <CreditInterestIcon />,
  limits: <ProductCardLimitIcon />,
  currency: <CurrencyIcon />,
  cards: <CardsIcon />,
  requiredExternalId: <ExternalIdsIcon />,
  documents: <DocumentsIcon />,
  supportedScheme: <InputSettings />,
  coreProduct: <ProductCardLimitIcon />,
  productCore: <ProductDetailsIcon />,
  productDetails: <ProductDetailsIcon />,
  identification: <ProductCodeIcon />,
  subscriptionCreationRule: <SubscriptionRulesIcon />,
  transactionRules: <TransactionRulesIcon />,
  paymentSchedule: <RepaymentScheduleIcon />,
  governance: <GovernanceIcon />,
  [Feature.DEBIT_INTEREST]: <CreditInterestIcon />,
  [Feature.FEES]: <FeesIcon />,
  [Feature.REWARDS]: <RewardsIcon />,
  [Feature.PAYMENT_LIMITS]: <PaymentLimitsIcon />,
  [Feature.CARD_TRANSACTION_LIMITS]: <CardLimitIcon />,
  [Feature.CREDIT_LIMIT]: <CreditLimitIcon />,
  [Feature.MULTI_PARTY]: <MultiPartyIcon />,
  [Feature.PRODUCT_SYNDICATION]: <SyndicationIcon />,
}

export const featureIconsCreator = (EntityBadge: BadgeSvgElement, props?: BadgeProps): React.ReactElement => (
  <EntityBadge {...props} />
)

export const featureSVGIcons: { [s: string]: React.ReactElement | any } = {
  statements: EntityBadgeStatements,
  termsAndConditions: EntityBadgeTerms,
  eligibility: EntityBadgeElgibility,
  creditInterest: EntityBadgeCreditInterest,
  limits: EntityBadgeProductCardLimit,
  currency: EntityBadgeCurrency,
  cards: EntityBadgeCards,
  requiredExternalId: EntityBadgeExternalIds,
  documents: EntityBadgeDocuments,
  supportedScheme: SettingsSystemDaydream,
  coreProduct: EntityBadgeProduct,
  productCore: EntityBadgeProductDetails,
  productDetails: EntityBadgeProductDetails,
  identification: EntityBadgeProductCode,
  subscriptionCreationRule: EntityBadgeSubscriptionRules,
  transactionRules: EntityBadgeTransactionRules,
  paymentSchedule: EntityBadgeRepaymentSchedule,
  governance: EntityBadgeGovernance,
  [Feature.DEBIT_INTEREST]: EntityBadgeDebitInterest,
  [Feature.MULTI_PARTY]: EntityBadgeMultiParty,
  [Feature.FEES]: EntityBadgeFees,
  [Feature.REWARDS]: EntityBadgeRewards,
  [Feature.PRODUCT_SYNDICATION]: EntityBadgeProductSyndication,
  [Feature.PAYMENT_LIMITS]: EntityBadgePaymentLimits,
  [Feature.CARD_TRANSACTION_LIMITS]: EntityBadgeCardLimit,
  [Feature.CREDIT_LIMIT]: EntityBadgeCreditLimit,
  [Feature.MULTI_PARTY]: EntityBadgeMultiParty,
}

export const hasGovernanceEnabled = (tenantConfig?: TenantConfig) => tenantConfig && tenantConfig.features.governance

export const getFeatures = (product: ExtendedProduct, featureStatus: 'added' | 'empty'): Feature[] => {
  const iterator = (i: any) => (featureStatus === 'empty' ? isEmpty(i) : !isEmpty(i))

  return featureNames.filter((featureName) => {
    const feature = product[featureName]

    // ByDefault ProuctSyndication will be null and will start loading within the tile after loading of all features.
    if (featureName === Feature.PRODUCT_SYNDICATION) {
      return true
    }

    if (feature?.fees) {
      return iterator(feature.fees)
    }

    if (feature?.rewards) {
      return iterator(feature.rewards)
    }

    if (feature?.paymentLimits) {
      return iterator(feature.paymentLimits)
    }

    if (feature?.cardLimits) {
      return iterator(feature.cardLimits)
    }

    return iterator(feature)
  })
}

export const getProductStatusLabel = (statusText: string, translate: (key: string) => null, date?: BankDateTime) => {
  const isLive = capitalize(statusText) === ProductStatus.LIVE
  const isClosed = capitalize(statusText) === ProductStatus.CLOSED
  if (isClosed) return translate('Retired')
  if ((!date || !isLive) && !isClosed) return translate(statusText)
  const isScheduled = isLive && date?.isAfterNow
  return isScheduled ? translate('Scheduled') : translate(statusText)
}

export const getStatusIcon = (text: string, date?: BankDateTime) => {
  switch (text.toUpperCase()) {
    case ProductStatus.LIVE:
    case PackagesStatus.LIVE:
      if (date && date.isAfterNow) {
        return <Event style={{ fontSize: 16 }} />
      }
      return <Check style={{ fontSize: 16 }} />
    case ProductStatus.APPROVED:
    case PackagesStatus.APPROVED:
      return <Spellcheck style={{ fontSize: 16 }} />
    case ProductStatus.DESIGN:
    case PackagesStatus.DESIGN:
      return <Create style={{ fontSize: 16 }} />
    case ProductStatus.REVIEW:
    case TaskStatus.ACTIVE:
    case PackagesStatus.REVIEW:
    case TaskStatus.PENDING:
    case 'Pending review':
      return <PlaylistAddCheck style={{ fontSize: 16 }} />
    case ProductStatus.UPDATED:
    case PackagesStatus.UPDATED:
      return <Create style={{ fontSize: 16 }} />
    case TaskStatus.REJECTED:
    case ProductStatus.CLOSED:
    case PackagesStatus.CLOSED:
    case PackagesStatus.RETIRED:
      return <Archive style={{ fontSize: 16 }} />
    default:
      return <ErrorOutline style={{ fontSize: 16 }} />
  }
}

export const getIcon = (iconName: string): JSX.Element => {
  switch (iconName) {
    case 'check':
      return <Check />
    case 'clear':
      return <Clear />
    case 'trending_down':
      return <TrendingDown />
    default:
      return <></>
  }
}

export const showMissionWIPFeatures = (tenantConfig: TenantConfig | undefined, featureName: Feature): boolean => {
  if (
    !tenantConfig?.features?.mission_wip &&
    [Feature.PAYMENT_SCHEDULE, Feature.CARD_TRANSACTION_LIMITS].includes(featureName)
  ) {
    return false
  }

  return true
}

export const showSyndicationFeatures = (tenantConfig: TenantConfig | undefined, featureName: Feature): boolean =>
  !(!tenantConfig?.features?.productSyndication && featureName === Feature.PRODUCT_SYNDICATION)

export const featureReadOnly = (featureName: Feature): boolean => Feature.PRODUCT_SYNDICATION !== featureName

export const showFeaturesInProduct = (tenantConfig: TenantConfig | undefined, featureName: Feature): boolean =>
  !(
    Feature.TRANSACTIONRULES === featureName &&
    tenantConfig &&
    tenantConfig.features &&
    tenantConfig.features[featureName] !== undefined &&
    tenantConfig.features[featureName] !== null &&
    !!tenantConfig.features[featureName] === false
  )

export const isFeatureAllowedByConfig = (tenantConfig: TenantConfig | undefined, featureName: Feature): boolean =>
  !(
    (!!featureName &&
      !!tenantConfig &&
      !!tenantConfig.availableProductFeatures &&
      tenantConfig.availableProductFeatures[featureName] !== undefined &&
      tenantConfig.availableProductFeatures[featureName] !== null &&
      !!tenantConfig.availableProductFeatures[featureName] === false) ||
    !showFeaturesInProduct(tenantConfig, featureName)
  ) &&
  showMissionWIPFeatures(tenantConfig, featureName) &&
  showSyndicationFeatures(tenantConfig, featureName)

export const isFeatureEditableAfterLiveProduct = (
  tenantConfig: TenantConfig | undefined,
  product: Product,
  featureName: Feature
): boolean =>
  !!featureName &&
  !!product &&
  ((isVersionFirst(product) && product.status === ProductStatus.DESIGN) ||
    (product.status === ProductStatus.UPDATED &&
      !!tenantConfig &&
      !!tenantConfig.editableFeaturesAfterLiveProduct &&
      tenantConfig.editableFeaturesAfterLiveProduct.includes(featureName)))

export const getStatus = (
  productStatus: ProductStatus | TaskStatus | PackagesStatus | LifeCycle,
  task?: Task,
  isTaskDrawer?: boolean
) => {
  let status = capitalize(productStatus) || 'N/A'

  if (task && task.assigneeKey && task.status === TaskStatus.PENDING) {
    status = ProductStatus.REVIEW
  }
  if (task && task.assigneeKey && task.status === TaskStatus.CLOSED) {
    status = ProductStatus.APPROVED
  }

  if (isTaskDrawer && status === 'Closed') {
    status = ProductStatus.APPROVED
  }

  if (isTaskDrawer && (status === 'Pending' || status === 'Review')) {
    status = 'Pending review'
  }
  return status
}

export const formatIncomeEligibilityAmount = (amount: number, currency: string) =>
  getFormattedCurrency(amount, currency, true, {
    thousandSeparator: true,
    maximumFractionDigits: 0,
  })

const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
const allDays = Object.values(CalendarDay)
const months = Object.values(CalendarMonth)

export const convertISOFrequencyToFormValues = (isoFrequency?: string): FrequencyFormValues => {
  let frequency: ApplicationFrequency | undefined
  let weeklyOccurence: WeeklyFrequencyItems | undefined
  let monthlyOccurence: MonthlyFrequencyItems | undefined
  let monthlySpecificDay: CalendarDay | undefined
  let annualOccurence: YearlyFrequencyItems | undefined
  let annualSpecificMonth: CalendarMonth | undefined
  let annualSpecificDay: CalendarDay | undefined

  if (isoFrequency && isoFrequency !== '') {
    const parts = isoFrequency.split('/')

    if (parts && parts[0] === 'R' && parts.length === 3) {
      if (parts[2] === 'P1D') {
        frequency = ApplicationFrequency.DAILY
      }

      if (parts[2] === 'P7D') {
        const date = moment(parts[1], 'YYYY-MM-DD')

        if (date && date.isValid()) {
          frequency = ApplicationFrequency.WEEKLY
          weeklyOccurence = weekdays[date.day() - 1] as WeeklyFrequencyItems
        }
      }

      if (parts[2] === 'P1M') {
        const date = moment(parts[1], 'YYYY-MM-DD')

        if (date && date.isValid()) {
          frequency = ApplicationFrequency.MONTHLY

          if (date.date() === 1) {
            monthlyOccurence = MonthlyFrequencyItems.BEGINNING
          } else if (date.date() === 31) {
            monthlyOccurence = MonthlyFrequencyItems.END
          } else {
            monthlyOccurence = MonthlyFrequencyItems.SPECIFIC_DAY
            monthlySpecificDay = allDays[date.date() - 1] as CalendarDay
          }
        }
      }

      if (parts[2] === 'P1Y') {
        const date = moment(parts[1], 'YYYY-MM-DD')

        if (date && date.isValid()) {
          frequency = ApplicationFrequency.ANNUALLY
          annualOccurence = YearlyFrequencyItems.SPECIFIC_DATE
          annualSpecificMonth = months[date.month()] as CalendarMonth
          annualSpecificDay = `${allDays[date.date() - 1]}` as CalendarDay
        }
      }
    } else if (parts && parts[0] === 'R' && parts.length === 2) {
      if (parts[1] === 'P1M') {
        frequency = ApplicationFrequency.MONTHLY
        monthlyOccurence = MonthlyFrequencyItems.ANNIVERSARY
      }

      if (parts[1] === 'P1Y') {
        frequency = ApplicationFrequency.ANNUALLY
        annualOccurence = YearlyFrequencyItems.DAY_OF_OPENING
      }
    }
  }

  return {
    frequency,
    weeklyOccurence,
    monthlyOccurence,
    monthlySpecificDay,
    annualOccurence,
    annualSpecificMonth,
    annualSpecificDay,
  }
}

export const convertFormValuesToISOFrequency = ({
  frequency,
  weeklyOccurence,
  monthlyOccurence,
  monthlySpecificDay,
  annualOccurence,
  annualSpecificMonth,
  annualSpecificDay,
}: FrequencyFormValues): string => {
  let date = ''
  let periodCode = ''

  const formattedDate = moment(moment(), 'YYYY-MM-DD')

  const calculateLastWeekdayDate = (): string => {
    const weekdayCodes = [-6, -5, -4, -3, -2, -1, 0]
    const weekdayCodeIndex = weeklyOccurence && weekdayCodes[weekdays.indexOf(weeklyOccurence)]

    if (weekdayCodeIndex !== undefined && weeklyOccurence && weekdays.indexOf(weeklyOccurence) !== -1) {
      return `${formattedDate.isoWeekday(weekdayCodeIndex).format('YYYY-MM-DD')}`
    }

    return ''
  }

  const calculateMonthlyDate = (): string => {
    switch (monthlyOccurence) {
      case MonthlyFrequencyItems.BEGINNING:
        return `${moment().year() - 1}-01-01`
      case MonthlyFrequencyItems.END:
        return `${moment().year() - 1}-01-31`
      case MonthlyFrequencyItems.ANNIVERSARY:
        return ''
      case MonthlyFrequencyItems.SPECIFIC_DAY:
        return `${moment().year() - 1}-01-${monthlySpecificDay?.replace('DAY', '').padStart(2, '0')}`
      default:
        return ''
    }
  }

  const calculateAnnualDate = (): string => {
    switch (annualOccurence) {
      case YearlyFrequencyItems.DAY_OF_OPENING:
        return ''
      case YearlyFrequencyItems.SPECIFIC_DATE:
        return `${moment().year() - 1}-${annualSpecificMonth
          ?.replace('MONTH', '')
          .padStart(2, '0')}-${annualSpecificDay?.replace('DAY', '').padStart(2, '0')}`
      default:
        return ''
    }
  }

  switch (frequency) {
    case ApplicationFrequency.DAILY:
      periodCode = 'P1D'
      date = `${moment().year() - 1}-01-01`
      break
    case ApplicationFrequency.WEEKLY:
      periodCode = 'P7D'
      date = calculateLastWeekdayDate()
      break
    case ApplicationFrequency.MONTHLY:
      periodCode = 'P1M'
      date = calculateMonthlyDate()
      break
    case ApplicationFrequency.ANNUALLY:
      periodCode = 'P1Y'
      date = calculateAnnualDate()
      break
    default:
      break
  }

  if (!periodCode) {
    return ''
  }

  if (!date) {
    return `R/${periodCode}`
  }

  return `R/${date}/${periodCode}`
}
