import styled from 'styled-components/macro'
import { CardActionArea, Paper, Typography } from '@material-ui/core'
import React from 'react'

export const StyledContainerPaper = styled(Paper)`
  &:hover {
    background-color: white;
    box-shadow: ${(props: any) => `${props.theme.shadows[8]}`};
  }
`

export const StyledCardActionArea = styled((props) => <CardActionArea component="div" {...props} />)`
  min-height: 116px;
  width: 100%;
  display: inline-flex;
  padding: 8px;
  padding-right: 14px;
  padding-left: 26px;
  text-transform: initial;
  &.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
    opacity: 0;
  }
  transition: min-height ease-in-out 0.5s;
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      min-height: 88px;
      padding: 8px;
      padding-right: 14px;
      padding-left: 26px;
    }
    ${theme.breakpoints.down('sm')} {
      padding-right: 4px;
      padding-left: 13px;
    }
  }
`}
`

export const ProductName = styled((props) => <Typography component="div" {...props} />)`
  ${({ theme }) => `
  ${theme.breakpoints.down('md')} {
    padding: 8px 0 16px 8px;
  }
`};
`
