import React from 'react'
import { FieldProps } from 'formik'
import EnhancedFormikField from './EnhancedFormikField'
import { Product, ProductKey } from 'store/products/types'
import Table from 'components/TableCommon/Table'
import { TableCommonOwnProps, TableCommonFormValue } from 'components/TableCommon/types'

export interface Props {
  selectedProducts: ProductKey[]
  products: Product[]
}

const EnhancedTableField = (props: Props & FieldProps & TableCommonOwnProps) => {
  const updateForm = (_data: TableCommonFormValue[]) => {
    if (props.field) {
      props.field.onChange({ target: { name: props.field.name, value: _data } })
    }
    if (props.onChange) {
      props.onChange(_data)
    }
  }

  return (
    <EnhancedFormikField {...props}>
      {({ isDisabled }) => (
        <Table
          isDisabled={isDisabled}
          updateForm={updateForm}
          value={props.field.value}
          rowSelectableComponent={props.rowSelectableComponent}
          isSelectableRow
          {...props}
        />
      )}
    </EnhancedFormikField>
  )
}
export default EnhancedTableField
