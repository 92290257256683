import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Add } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import useTaxRule from './hooks/useTaxRule'
import { MainGrid } from './styled'
import WithholdingTaxCard from './view/WithholdingTaxCard/WithholdingTaxCard'
import { selectConfig, selectFeatures } from 'store/tenant-config/selectors'
import { hasWithholdingTaxRule } from 'utils/common.util'
import { Catalog, Loader, ZeroStateLandingPage } from 'components'
import { WithholdingRule } from 'store/withholdingTax/types'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import { InfoIconButton, H1, Button } from 'dls'
import { WithholdingTaxNoItems } from 'assets'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export function WithholdingTaxRuleCatalogPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const tenantConfig = useSelector(selectConfig)
  const enableWithholdingTaxRule = useMemo(() => {
    if (tenantConfig) {
      return hasWithholdingTaxRule(tenantConfig)
    }

    return false
  }, [tenantConfig])

  const { getAllTaxRules, taxRules, isLoading } = useTaxRule()

  const features = useSelector(selectFeatures)
  const showWithholdTaxesWIP = Boolean(features.withholding_tax)

  const onAddRulesClick = () => navigate('/reference-data/withholding-tax/create')

  const { showInfographicModal } = useModal()

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Rules for withholding tax'), path: '' },
    ],
    trigger: true,
  })

  useEffect(() => {
    if (enableWithholdingTaxRule) {
      getAllTaxRules()
    }
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <ContentContainer maxWidth="lg" data-test-id="products-page">
      {(taxRules?.length || 0) > 0 ? (
        <Catalog
          entityName="Rules for withholding tax"
          list={taxRules || []}
          action={
            showWithholdTaxesWIP && (
              <Button
                variant="outlined"
                fullWidth
                data-test-id="create-new-withholdingtaxrule"
                onClick={() => navigate('/reference-data/withholding-tax/create')}
                aria-controls="main"
              >
                <Add />
                {t('New Rule')}
              </Button>
            )
          }
          searchFilter={(item, text) => item.name.toLowerCase().includes(text)}
          cardRenderer={(list, idx, key) => (
            <CatalogCardContainer>
              <WithholdingTaxCard key={key} rule={list[idx] as WithholdingRule} />
            </CatalogCardContainer>
          )}
          useReactList={false}
          icons={[
            <InfoIconButton
              key="more-about-icon"
              ariaProps={{
                'aria-label': `${t('More about withholding tax rules')}`,
                'aria-haspopup': 'dialog',
                'aria-controls': 'more-about-dialog',
              }}
              onClick={() => showInfographicModal(HelpModalType.PRODUCT_LIFECYCLE)}
              fontSize="medium"
              title={t('More about withholding tax rules')}
            />,
          ]}
        />
      ) : (
        <ContentContainer data-test-id="interest-rate-page">
          <MainGrid container direction="row" justifyContent="space-between" spacing={1} alignItems="flex-end">
            <Grid item>
              <H1>{t('Rules for withholding tax')}</H1>
            </Grid>
            <Grid item>
              <InfoIconButton
                key="more-about-icon"
                ariaProps={{
                  'aria-label': `${t('More about withholding tax rules')}`,
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                }}
                onClick={showInfographicModal}
                fontSize="medium"
                title={t('More about withholding tax rules')}
              />
              {showWithholdTaxesWIP && (
                <Button variant="outlined" onClick={onAddRulesClick} aria-controls="main">
                  <Add />
                  {t('New Rule')}
                </Button>
              )}
            </Grid>
          </MainGrid>
          <ZeroStateLandingPage
            image={WithholdingTaxNoItems}
            title={t('No withholding tax rules have been created')}
            definition={t(
              'Here you can create rules for when tax and the amount that should be withheld on customers credit interest.'
            )}
            location="withholdTax"
            buttonLink={t('How withholding tax works')}
            description={t('Choose ‘Add rule’ above to get started')}
          />
        </ContentContainer>
      )}
    </ContentContainer>
  )
}

export default WithholdingTaxRuleCatalogPage
