import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useTemplateImage from './utils/useTemplateImage'
import { IconPreview, StatementNoPreview } from 'assets'
import { ProductSegment, StatementType } from 'store/products/types'
import { Template } from 'store/statement/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  template: Template
  templateType: StatementType
  productSegment: ProductSegment
  previewTemplateOpen?: VoidFunction
}

export function StatementSampleImage({ template, productSegment, templateType, previewTemplateOpen }: OwnProps) {
  const { t } = useTranslation()
  const { templateGroupId, templateName } = template
  const { src, error } = useTemplateImage(productSegment, templateType, templateGroupId)

  if (src || error) {
    if (previewTemplateOpen && !error) {
      return (
        <Button onClick={previewTemplateOpen} color={ColorType.NONE}>
          <div style={{ position: 'relative', bottom: 12, left: 64, height: 0, width: 0 }}>
            <img src={IconPreview} alt={t('preview')} style={{ marginRight: 8 }} />
          </div>
          <img
            width="100%"
            src={error && !src ? StatementNoPreview : src}
            alt={error && !src ? 'No preview available ' : templateName}
          />
        </Button>
      )
    }
    return (
      <img
        width="100%"
        src={error && !src ? StatementNoPreview : src}
        alt={error && !src ? 'No preview available' : templateName}
      />
    )
  }

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
    </div>
  )
}
export default React.memo(StatementSampleImage)
