import React from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { CardLimitContainer } from './styles'
import { CardTransactionLimit, CardTransactionLimitsRoutingDestination } from 'store/products/typings/cardLimits'
import { H6, CardHeader, ReviewRows } from 'dls'
import {
  getPerCashTransaction,
  getCumulativeCashDaily,
} from 'pages/features/CardTransactionLimits/utilities/transformers'
import { getCurrencyInfo } from 'api/utils'
import buildDefinitionsForCash from 'pages/features/CardTransactionLimits/utilities/buildDefinitionsForCash'

interface OwnProps {
  cardTransactionLimits: CardTransactionLimit[]
  currencyCode: string
  showActions?: boolean
  hasSeparators?: boolean
  productKey?: string
  inline?: boolean
}

const CashWithdrawalLimitsRow = ({
  cardTransactionLimits,
  currencyCode,
  showActions = false,
  hasSeparators = false,
  productKey = '',
  inline = false,
}: OwnProps) => {
  const { t } = useTranslation()

  const preFilteredTransactions = cardTransactionLimits.filter((limit) =>
    limit.routingDestinations.some((dest) => dest === CardTransactionLimitsRoutingDestination.CARD_CASH)
  )
  const definitions = buildDefinitionsForCash({
    perCashTransaction: getPerCashTransaction(preFilteredTransactions),
    cumulativeCashDaily: getCumulativeCashDaily(preFilteredTransactions),
    currencySymbol: getCurrencyInfo(currencyCode)?.nativeSymbol,
    productKey,
    t,
    hasSeparators,
    showActions,
    ariaLabelledBy: 'cash-transaction-limits-heading',
  })
  return (
    <CardLimitContainer>
      <Box mb={3}>
        <CardHeader
          title={
            <H6 variantMapping={{ h6: 'h4' }} id="cash-transaction-limits-heading" style={{ fontWeight: 'bold' }}>
              {t('Cash ATM/POS withdrawal limits')}
            </H6>
          }
          headerIcon={inline ? <PersonOutlineIcon /> : undefined}
        />
      </Box>
      <ReviewRows definitionsList={definitions} />
    </CardLimitContainer>
  )
}

export default CashWithdrawalLimitsRow
