import { call, put, takeLatest } from 'redux-saga/effects'
import ActivityActions from './actions'
import { ActivityApi } from 'api'

// eslint-disable-next-line
function* fetchMessages(action: ReturnType<typeof ActivityActions.fetch>) {
  try {
    const response: ReturnTypePromise<typeof ActivityApi.fetchMessages> = yield call(
      ActivityApi.fetchMessages,
      action.payload
    )
    yield put(ActivityActions.fetchSuccess(response))
  } catch (e: any) {
    yield put(ActivityActions.fetchFail(e))
  }
}

// @TODO: Revert back to show activity
function* fetchEmptyMessages() {
  try {
    yield put(ActivityActions.fetchSuccess([]))
  } catch (e: any) {
    yield put(ActivityActions.fetchFail(e))
  }
}

export default function* () {
  // @TODO: Use fetchMessages to show activity
  yield takeLatest(ActivityActions.fetch.type, fetchMessages)
  yield takeLatest(ActivityActions.fetchEmpty.type, fetchEmptyMessages)
}
