import React from 'react'
import { Dialog, DialogProps, DialogTitle, Fade, Tooltip, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { HelpOutline } from '@material-ui/icons'
import { CloseButton, HelpOutlineSharpButton } from 'dls/molecules/IconButtons/IconButtons'

interface Props extends DialogProps {
  title: string
  open: boolean
  onClose: VoidFunction
  infoText?: string
  children: React.ReactElement
}

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px 6px;
`

const StyledTitle = styled(Typography)`
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
`

const StandardModal = ({ title, open, onClose, infoText, children, maxWidth, fullWidth }: Props) => (
  <Dialog open={open} onClose={onClose} TransitionComponent={Fade} maxWidth={maxWidth || 'md'} fullWidth={fullWidth}>
    <StyledDialogTitle disableTypography>
      <StyledTitle variant="h5">{title}</StyledTitle>
      <div
        style={{
          transform: 'translateX(14px)',
        }}
      >
        {infoText && (
          <Tooltip title={infoText} aria-label={infoText} placement="left">
            <HelpOutlineSharpButton
              color="inherit"
              aria-label={infoText}
              css={`
                transform: translate(14px, 0);
              `}
            >
              <HelpOutline />
            </HelpOutlineSharpButton>
          </Tooltip>
        )}
        <CloseButton color="inherit" aria-label="close" onClick={onClose} />
      </div>
    </StyledDialogTitle>
    {children}
  </Dialog>
)
export default StandardModal
