import { TFunction } from 'i18next'
import ReactDOMServer from 'react-dom/server'
import { camelCase } from 'lodash'
import { featurePrint } from '../product.utils'
import { TenantConfig } from 'store/tenant-config/types'
import { getCurrencyInfo } from 'api/utils'
import { CurrencyItem } from 'store/utils/types'
import { Feature } from 'store/products/types'
import { SelectedRateAndBankName } from 'store/referenceData/types'

export const getCurrencySymbol = (
  currencyCode: string | null | undefined,
  tenantConfig: TenantConfig | undefined
): string => {
  const currencyInfo: CurrencyItem | null = getCurrencyInfo(
    currencyCode || ((tenantConfig || ({} as TenantConfig)).localisation || {}).currencyCode || 'GBP'
  )
  return `${currencyInfo ? currencyInfo.nativeSymbol : '£'}`
}

export const FEATURE_TOP_BOTTOM_MARGIN = 8
export const FEATURE_ICON_RIGHT_MARGIN = 4

const getTopMarginForTitle = (iconName: string) => {
  switch (iconName) {
    case 'currency':
    case 'limits':
    case 'requiredExternalId':
    case 'statements':
    case 'termsAndConditions':
    case 'transactionRules':
    case 'multiParty':
    case 'productSyndication':
      return 5
    case 'cards':
    case 'documents':
    case 'eligibility':
    case 'identification':
    case 'productCore':
    case 'subscriptionCreationRule':
    case 'creditInterest':
    case Feature.FEES:
    case Feature.REWARDS:
    case Feature.DEBIT_INTEREST:
    case Feature.PAYMENT_LIMITS:
    case Feature.CARD_TRANSACTION_LIMITS:
      return 4
    case Feature.PAYMENT_SCHEDULE:
    case Feature.CREDIT_LIMIT:
      return 3
    default:
      return 400
  }
}
const getTopMarginForIcon = (iconName: string) => {
  switch (iconName) {
    case 'currency':
    case 'paymentSchedule':
      return 2

    default:
      return 0
  }
}

export const getFeatureTitleAndIcon = (
  title: string,
  iconName: string,
  t: TFunction,
  margin: number[] = [0, 0, 0, 6]
) => ({
  stack: [
    {
      layout: 'noBorders',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              svg: ReactDOMServer.renderToString(featurePrint[camelCase(iconName)]),
              margin: [0, getTopMarginForIcon(iconName), FEATURE_ICON_RIGHT_MARGIN, 0],
            },
            { text: t(title), fontSize: 16, margin: [0, getTopMarginForTitle(iconName), 0, 0] },
          ],
        ],
      },
    },
  ],
  margin,
})

export const getSubtitle = (subtitle: string) => ({
  stack: [
    {
      layout: 'noBorders',
      table: {
        widths: ['100%'],
        body: [[{ text: subtitle, fontSize: 16 }]],
      },
    },
  ],
  margin: [0, 20, 0, 6],
})

export const featureSeparator = () => ({
  margin: [0, 24, 0, 0],
  canvas: [{ type: 'line', x1: 0, y1: 5, x2: (514 / 100) * 103.5, y2: 5, lineWidth: 1 }],
})

export const itemSeparator = () => ({
  margin: [0, 0, 0, 8],
  canvas: [{ type: 'line', x1: 0, y1: 5, x2: (514 / 100) * 103.5, y2: 5, lineWidth: 0.2 }],
})

export const line = () => ({
  margin: [0, 0, 0, 8],
  canvas: [{ type: 'line', x1: 0, y1: 5, x2: (514 / 100) * 103.5, y2: 5, lineWidth: 0.75, lineColor: '#757575' }],
})

export const lineStrong = () => ({
  margin: [0, 0, 0, 8],
  canvas: [{ type: 'line', x1: 0, y1: 5, x2: (514 / 100) * 103.5, y2: 5, lineWidth: 1.5, lineColor: '#757575' }],
})

export const objHasBankNameAndRate = (selectedRateAndBankObj: SelectedRateAndBankName) =>
  selectedRateAndBankObj !== null && selectedRateAndBankObj.bankName && selectedRateAndBankObj.rate
