import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import { Dialog } from 'dls/atoms/Dialog/Dialog'
import { DialogActions } from 'dls/atoms/Dialog/DialogActions'
import { DialogContent } from 'dls/atoms/Dialog/DialogContent'
import { H3, Button } from 'dls/atoms'
import { extraPadding, gutterSpacing } from 'dls/shared/styleVar'

export const StyledButton = styled(Button)`
  min-width: 110px;
`

export const DialogStyledContainer = styled(DialogContent)`
  padding: ${extraPadding};
  overflow-x: hidden;
  &:first-child {
    padding-top: ${extraPadding};
  }
`

export const DialogStyledActions = styled(DialogActions)`
  padding: 0;
`

export const HeadingGrid = styled(Grid)`
  &.MuiGrid-item {
    margin-right: 0;
    padding-right: 0;
    width: calc(100% - 160px);
  }
`

export const CloseIconGrid = styled(Grid)`
  &.MuiGrid-item {
    margin-left: 0;
    padding: 14px;
  }
`

export const StyledFullScreenDialog = styled(Dialog)`
  margin: 0px;
  ${({ theme }) => `
  ${theme.breakpoints.up('md')} {
    margin: ${gutterSpacing};
    & .MuiDialog-paper {
      border-radius: 4px;
    }
  }
`};
`

export const StyledDialog = styled(Dialog)<{ $width: string }>`
  & .MuiDialog-paperWidthSm {
    ${({ $width }) => $width && `width: ${$width};`}
    margin: 32px;
    max-width: 95%;
  }
`

export const ModalTitle = styled(H3)`
  padding: 0 0 ${extraPadding} 0;
`

export default {
  ModalTitle,
  DialogStyledContainer,
  HeadingGrid,
  CloseIconGrid,
  StyledDialog,
  StyledFullScreenDialog,
}
