/* eslint-disable no-nested-ternary */
import {
  StatusDesignStepIcon,
  StatusScheduledStepIcon,
  StatusLiveStepIcon,
  StatusRetiredStepIcon,
  CompletedIcon,
} from './StepIcons'
import { Steps } from './ProductSteps'
import { BankDateTime } from 'utils/date.utils'
import { PackagesStatus } from 'store/packages/typings/packageStatus'

export const getSteps = (status: PackagesStatus, currentStep: number): Steps[] => {
  const steps: Steps[] = []
  steps.push({
    status: PackagesStatus.DESIGN,
    label: 'Design',
    completed: currentStep > steps.length,
    labelProps: {
      StepIconComponent:
        currentStep === steps.length ? StatusDesignStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
      StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
    },
  })
  steps.push({
    status: PackagesStatus.LIVE,
    label: 'Scheduled',
    completed: currentStep > steps.length,
    labelProps: {
      StepIconComponent:
        currentStep === steps.length ? StatusScheduledStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
      StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
    },
  })
  steps.push({
    status: PackagesStatus.LIVE,
    label: 'Live',
    completed: currentStep > steps.length,
    labelProps: {
      StepIconComponent:
        currentStep === steps.length ? StatusLiveStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
      StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
    },
  })
  if (status === PackagesStatus.CLOSED) {
    steps.push({
      status: PackagesStatus.CLOSED,
      label: 'Retired',
      completed: currentStep > steps.length,
      labelProps: {
        StepIconComponent:
          currentStep === steps.length ? StatusRetiredStepIcon : currentStep > steps.length ? CompletedIcon : undefined,
        StepIconProps: { active: currentStep === steps.length, completed: currentStep > steps.length },
      },
    })
  }

  return steps
}

export const getActiveStep = (status: PackagesStatus, date?: BankDateTime): number => {
  if ([PackagesStatus.DESIGN, PackagesStatus.UPDATED].includes(status)) return 0
  if ([PackagesStatus.LIVE].includes(status) && date && date.isAfterNow) return 1
  if ([PackagesStatus.LIVE].includes(status) && !(date && date.isAfterNow)) return 2
  if ([PackagesStatus.CLOSED].includes(status)) return 3
  return 0
}
