import React, { useCallback } from 'react'
import { Grid, Fade, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Form, FormikProps, getIn } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import * as Yup from 'yup'
import { ButtonContainer, FormBottom, ContentPaperContainer, TypographyStyle, StyledDialog } from '../styles'
import AddInterestRateList from './AddInterestRateList'
import AddConfirmModal from './ConfirmInterestRateCreationModal'
import { EditOrAddIndexRate, InterestRate } from 'store/referenceData/types'
import { IndexRatesInfoGraphic } from 'assets'
import { BackButton, SidePanel, FormBottomBar, TemplateFeatureForm as TemplateFeatureFormComp } from 'components'
import { withFormikSimple, rateYupValidation } from 'utils/form.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  bankName: string
  bankIndexKey: string
  openConfirm: boolean
  isUpdating?: boolean
  setOpenConfirm: (open: boolean) => void
  futureDate: InterestRate[]
}

interface AddIndexProps extends FormikProps<EditOrAddIndexRate>, OwnProps {}

const AddInterestRate = (props: AddIndexProps): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onClose = () => {
    if (!props.isUpdating) {
      props.setOpenConfirm(false)
    }
  }

  const initialValue = getIn(props.values, 'editOrAddIndexRate.rates[0]')
  const onPressBack = useCallback(() => {
    navigate(`/reference-data/index-rates/${props.bankIndexKey}`)
  }, [])

  return (
    <ContentContainer data-test-id="add-rate-page">
      <TemplateFeatureFormComp
        main={
          <Form>
            <StyledDialog
              open={props.openConfirm}
              scroll="paper"
              onClose={onClose}
              TransitionComponent={Fade}
              fullWidth
            >
              <AddConfirmModal closeDialog={onClose} confirmEdit={props.submitForm} isUpdating={props.isUpdating} />
            </StyledDialog>
            <ContentPaperContainer elevation={1}>
              <TypographyStyle variant="h2">{t(props.bankName)}</TypographyStyle>
              <AddInterestRateList {...props} name="editOrAddIndexRate.rates" futureDate={props.futureDate} />
              <FormBottom>
                <FormBottomBar hideSubmit inlineNavigation spacing={1}>
                  <Grid
                    item
                    style={{
                      marginRight: 14,
                    }}
                  >
                    <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                      {t('Cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color={ColorType.BUTTON}
                      onClick={() => props.setOpenConfirm(true)}
                      disabled={isEmpty(initialValue) || !isEmpty(props.errors)}
                    >
                      {t('Submit')}
                    </Button>
                  </Grid>
                </FormBottomBar>
              </FormBottom>
            </ContentPaperContainer>
          </Form>
        }
        header={
          <>
            <ButtonContainer>
              <BackButton onPressBack={onPressBack} aria-controls="main" />
            </ButtonContainer>
            <Typography variant="h1" gutterBottom>
              {t('Add future rate')}
            </Typography>
            <Typography variant="body2" variantMapping={{ body2: 'p' }} gutterBottom>
              {t(
                'Add new rates here, make sure the effective date is correct as you can’t change this later. You won’t be able to change the rate once the effective date has passed.'
              )}
            </Typography>
          </>
        }
        aside={
          <SidePanel
            heading={t('ABOUT INDEX RATES')}
            image={IndexRatesInfoGraphic}
            imageAlt={t('Illustration of a laptop showing a bar chart of changing index rate.')}
            details={t(
              'Index rates can be created, set and managed here. These rates can be selected when building a product to configure indexed interest rates.'
            )}
            linkToOpenModal={t('More about index rates')}
            infographicType={HelpModalType.INDEX_RATES}
          />
        }
      />
    </ContentContainer>
  )
}

export default withFormikSimple<OwnProps, EditOrAddIndexRate>(AddInterestRate, {
  validationSchema: Yup.object<Partial<EditOrAddIndexRate>>({
    editOrAddIndexRate: Yup.object({
      rates: Yup.array(
        Yup.object({
          effectiveDate: Yup.date()
            .typeError('Please select a valid date')
            .default(() => new Date().toISOString())
            .required('Date is required')
            .min(moment().toISOString(), 'Should be after today'),
          indexRate: rateYupValidation(4, 1000).required('Rate is required'),
        }).nullable()
      ),
    }) as any,
  }).required(),
})
