import React from 'react'
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type Props = {
  id: string
  label: string
  required?: boolean
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const CustomCheckbox = React.forwardRef(
  ({ id, label, required, checked, ...props }: Props, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const { t } = useTranslation()
    return (
      <FormControl>
        <FormControlLabel
          label={t(label) + (required ? ' *' : '')}
          htmlFor={id}
          control={<Checkbox id={id} checked={checked} required={required} inputRef={ref} {...props} />}
          labelPlacement="end"
        />
      </FormControl>
    )
  }
)

export default CustomCheckbox
