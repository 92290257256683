import React from 'react'
import { isEqual } from 'lodash'
import { Grid, Typography, Box } from '@material-ui/core'
import { Field, getIn, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ThickDivider } from 'pages/features/CreditInterest/styles'
import {
  FieldAmount,
  FieldPercentage,
  FieldRateCalculator,
  FieldStaticRateCalculator,
  RepeaterLayout,
} from 'components'
import { CreditInterestFormValues, Operand, RateTierBand, TypeOfIndexRate } from 'store/products/types'
import { ratePositiveYupValidation, validationForTierBandStartRangeEndRangeDebitInterest, Yip } from 'utils/form.utils'
import { SelectedRateAndBankName } from 'store/referenceData/types'

interface OwnProps {
  name: string
  currencyOfProduct?: string | null
  selectedRateAndBankNameObject?: SelectedRateAndBankName
  selectTypeOfIndexRate: TypeOfIndexRate
  customTierBandLabel?: string
}

const LendingTiersBandsList = (_props: OwnProps & FormikProps<CreditInterestFormValues>) => {
  const { t } = useTranslation()

  const {
    name,
    selectedRateAndBankNameObject,
    selectTypeOfIndexRate,
    customTierBandLabel,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
  } = _props

  const selectCalculationMethod = getIn(values, 'calculationMethod')
  const lastTierBandIndex = getIn(values, `${name}`).length - 1
  const currentTierBandValues = getIn(values, `${name}[${lastTierBandIndex}]`)
  const endRange = getIn(values, `${name}[${lastTierBandIndex}].endRange`)
  const spread = getIn(values, `${name}[${lastTierBandIndex}].spread`)
  const interestRate = getIn(values, `${name}[${lastTierBandIndex}].interestRate`)
  const operator = getIn(values, `${name}[${lastTierBandIndex}].operator`)
  const canAddNewTierBand: boolean =
    selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
      ? !!endRange && !!spread && !!operator
      : !!endRange && !!interestRate

  const handleEndRangeChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(`${name}[${index}].endRange`)
    setFieldValue(`${name}[${index}].endRange`, e.target.value)
    if (lastTierBandIndex > index && e.target.value !== '') {
      setFieldTouched(`${name}[${index}].startRange`)
      setFieldValue(`${name}[${index + 1}].startRange`, e.target.value)
    }
  }

  return (
    <Box mb={2}>
      <RepeaterLayout
        name={name}
        labelDelete={t('Delete Band')}
        labelNewItem={selectCalculationMethod === RateTierBand.TIER ? t('Add a Tier') : t('Add a Band')}
        isAddDisabled={
          (!canAddNewTierBand && lastTierBandIndex !== -1) || !!getIn(errors, `${name}[${lastTierBandIndex}]`)
        }
        newItem={
          getIn(values, `${name}`).length > 0
            ? {
                startRange: currentTierBandValues.endRange,
                operator:
                  selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN ? Operand.ADD : undefined,
              }
            : {}
        }
        isItemDeletable={({ index }) => index === lastTierBandIndex && index !== 0}
        deleteButtonStyle={{ marginTop: 70, top: 7 }}
      >
        {({ name: itemName, index }) => (
          <Grid container direction="row" spacing={2} style={{ paddingTop: index ? '24px' : 'inherit' }}>
            {index > 0 && (
              <Grid item xs={12}>
                <ThickDivider />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t(customTierBandLabel || 'If the balance is...')}</Typography>
            </Grid>
            <Grid item xs={selectTypeOfIndexRate === TypeOfIndexRate.INDEPENDENT_INDEXED_RATE ? 4 : 6}>
              <Field
                id={`${itemName}.startRange`}
                label={t(`Greater than (pre-set)`)}
                name={`${itemName}.startRange`}
                component={FieldAmount}
                currencyCode={_props.currencyOfProduct}
                fullWidth
                readOnly
                disabled
                placeholder={t('Please enter')}
              />
            </Grid>
            <Grid item xs={selectTypeOfIndexRate === TypeOfIndexRate.INDEPENDENT_INDEXED_RATE ? 4 : 6}>
              <Field
                label={t(`And equal to or less than`)}
                id={`${itemName}.endRange`}
                name={`${itemName}.endRange`}
                required
                fullWidth
                component={FieldAmount}
                currencyCode={_props.currencyOfProduct}
                InputProps={{ onChange: handleEndRangeChange(index) }}
                placeholder={t('Please enter')}
              />
            </Grid>
            {selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN &&
              selectedRateAndBankNameObject && (
                <Grid item xs={12}>
                  <Field
                    id={`${itemName}.interestRate`}
                    label={t(`Rate`)}
                    name={`${itemName}.interestRate`}
                    required
                    fullWidth
                    placeholder={t('Please enter')}
                    validate={Yip(ratePositiveYupValidation(10, 1000, true).required())}
                    component={FieldRateCalculator}
                    indexRate={selectedRateAndBankNameObject?.rate || 0}
                    numberFormatProps={{
                      decimalScale: 13,
                    }}
                  />
                </Grid>
              )}
            {selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN && selectedRateAndBankNameObject && (
              <Grid item xs={12}>
                <Field
                  id={`${itemName}.spread`}
                  label={t('Your margin')}
                  name={`${itemName}.spread`}
                  operandName={`${itemName}.operator`}
                  required
                  fullWidth
                  placeholder={t('Please enter')}
                  validate={Yip(ratePositiveYupValidation(10, 1000, true).required())}
                  component={FieldStaticRateCalculator}
                  indexRate={selectedRateAndBankNameObject?.rate || 0}
                  numberFormatProps={{
                    decimalScale: 13,
                  }}
                />
              </Grid>
            )}
            {selectTypeOfIndexRate !== TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN &&
              selectTypeOfIndexRate !== TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN && (
                <Grid item xs={4}>
                  <Field
                    id={`${itemName}.interestRate`}
                    label={t(`Interest rate is`)}
                    name={`${itemName}.interestRate`}
                    required
                    fullWidth
                    component={FieldPercentage}
                    validate={Yip(ratePositiveYupValidation(10, 1000, true).required())}
                    placeholder={t('Please enter')}
                    numberFormatProps={{
                      decimalScale: 13,
                    }}
                  />
                </Grid>
              )}
          </Grid>
        )}
      </RepeaterLayout>
    </Box>
  )
}

export default React.memo(
  LendingTiersBandsList,
  (prev, next) =>
    isEqual(prev.values, next.values) && isEqual(prev.errors, next.errors) && isEqual(prev.touched, next.touched)
)

export const yupValidationForTiersBands = (t: TFunction, currencyCode: string) =>
  Yup.array(
    validationForTierBandStartRangeEndRangeDebitInterest(
      t,
      currencyCode,
      'The upper balance must be greater than the lower balance'
    )
  )
