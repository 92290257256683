import React from 'react'
import { Grid, InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import { Field, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import BigNumber from 'bignumber.js'
import FieldPercentage, { FieldPercentageProps } from './FieldPercentage'
import { Operand } from 'store/products/types'
import { SelectFieldComplex } from 'components'
import { preciseFixRateNumber, Yip } from 'utils/form.utils'

interface OwnProps {
  indexRateProps?: TextFieldProps
  calculatedProps?: TextFieldProps
  indexRate: number
  operandName: string
}

interface FieldStaticRateCalculatorProps extends FieldPercentageProps, OwnProps {}

const FieldStaticRateCalculator = ({
  indexRateProps,
  calculatedProps,
  indexRate,
  operandName,
  ...percentageProps
}: FieldStaticRateCalculatorProps) => {
  const { t } = useTranslation()

  const marginValue = percentageProps.form.values && getIn(percentageProps.form.values, percentageProps.field.name)
  const operandValue = percentageProps.form.values && getIn(percentageProps.form.values, operandName)

  const fieldValue = () => {
    if (marginValue != null && operandValue && marginValue !== '') {
      if (operandValue === Operand.SUBTRACT) {
        return preciseFixRateNumber(new BigNumber(indexRate).minus(marginValue).toFixed(), 10)
      }

      return preciseFixRateNumber(new BigNumber(indexRate).plus(marginValue).toFixed(), 10)
    }

    return ''
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={3}>
        <TextField
          value={indexRate}
          disabled
          fullWidth
          label={t('Index rate')}
          InputLabelProps={{ shrink: true }}
          {...indexRateProps}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          label={t('Plus / minus')}
          name={operandName}
          data={Operand}
          component={SelectFieldComplex}
          validate={Yip(Yup.string().required())}
          required
          fullWidth
          selectProps={{ displayEmpty: false }}
          inputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={3}>
        <FieldPercentage {...percentageProps} inputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={fieldValue()}
          label="Rate"
          disabled
          fullWidth
          helperText=""
          placeholder={t('Calculated')}
          InputLabelProps={{ shrink: true }}
          {...calculatedProps}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        />
      </Grid>
    </Grid>
  )
}

export default FieldStaticRateCalculator
