import { v4 as uuidv4 } from 'uuid'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { FeaturePostResponse, ProductKey } from 'store/products/types'
import { CardTransactionLimit } from 'store/products/typings/cardLimits'

async function setCardTransactionLimit(productKey: ProductKey, version: number, payload: CardTransactionLimit) {
  const newPayload = { ...payload }
  delete newPayload.limitRuleId

  const { data } = await (await createAsyncAuthAwareAxios(null, false)).post<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/cardLimits/v1`,
    newPayload,
    {
      headers: {
        'Idempotency-Key': uuidv4(),
      },
    }
  )
  return data
}

async function updateCardTransactionLimit(productKey: ProductKey, version: number, payload: CardTransactionLimit) {
  const { limitRuleId, ...restPayload } = payload
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).patch<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/cardLimits/v1/${limitRuleId}`,

    {
      amount: { minimum: restPayload?.amount?.minimum, maximum: restPayload?.amount?.maximum },
      enableSubscriptionOverride: restPayload?.enableSubscriptionOverride,
      subscriptionOverrideMaximum: restPayload?.subscriptionOverrideMaximum,
    }
  )
  return data
}

async function fetchCardTransactionLimits(productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).get(
    `/v1/products/${productKey}/versions/${version || 1}/features/cardLimits/v1`
  )
  return data
}

async function deleteCardTransactionLimit(limitRuleId: string, productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).delete(
    `/v1/products/${productKey}/versions/${version || 1}/features/cardLimits/v1/${limitRuleId}`
  )
  return data
}

export default {
  setCardTransactionLimit,
  updateCardTransactionLimit,
  fetchCardTransactionLimits,
  deleteCardTransactionLimit,
}
