import React, { useEffect, useMemo } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { defineLimitType, convertCardTransactionLimitToFormValues } from '../../utilities/transformers'
import useCardTransactionLimit from '../../utilities/useCardTransactionLimits'
import { Paper, Input, ContextualHelp } from 'dls'
import { BackButton, TemplateSubReview, H1, Loader, NoActivityHelpPanel } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Feature } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Flexbox } from 'pages/Layout/Styled'
import {
  CardTransactionLimitsAmountType,
  CardTransactionLimitsChannel,
  CardTransactionLimitsDirection,
  CardTransactionLimitsRange,
  CardTransactionLimitsRoutingDestination,
} from 'store/products/typings/cardLimits'
import CustomCheckbox from 'components/form/CustomCheckbox'
import { getQuerySearchParameter } from 'utils/url.utils'
import { hexCodeToString } from 'utils/common.util'
import { LinkWithText } from 'dls/molecules/Buttons'
import { ClearIcon } from 'dls/atoms/Icons/MUIIcons'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const UpdateCardTransactionLimitPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { productKey = '', limitId } = useParams()

  const [product, isLoading] = useSelectedProduct(productKey)
  const { submitCardTransactionLimit, deleteCardTransactionLimit } = useCardTransactionLimit({
    productKey,
    version: product?.version || 1,
  })

  const editableLimit = useMemo(() => product?.cardLimits?.cardLimits?.find((limit) => limit.limitRuleId === limitId), [
    product,
    limitId,
  ])

  const defaultValues = convertCardTransactionLimitToFormValues(editableLimit)
  const direction = (getQuerySearchParameter('direction') as CardTransactionLimitsDirection) || editableLimit?.direction
  const channel = (getQuerySearchParameter('channel') as CardTransactionLimitsChannel) || editableLimit?.channel
  const routingDestinations =
    (getQuerySearchParameter('routingDestinations')?.split(',') as CardTransactionLimitsRoutingDestination[]) ||
    editableLimit?.routingDestinations
  const range = (getQuerySearchParameter('range') as CardTransactionLimitsRange) || editableLimit?.range
  const amountType =
    (getQuerySearchParameter('amountType') as CardTransactionLimitsAmountType) || editableLimit?.amount.type

  const transactionType = defineLimitType({
    channel,
    direction,
    routingDestination: routingDestinations?.[0] || undefined,
    range,
    amountType,
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        maximum: Yup.number()
          .typeError('This is not number')
          .positive()
          .integer('This field must be an integer')
          .required()
          .lessThan(9999999),
        subscriptionOverrideMaximum: Yup.number()
          .nullable()
          .typeError('This is not number')
          .positive()
          .integer('This field must be an integer')
          .lessThan(10000000)
          .when(['maximum', 'enableSubscriptionOverride'], {
            is: (maximum, enableSubscriptionOverride) => enableSubscriptionOverride && !!maximum,
            then: (subscriptionOverrideMaximum: Yup.NumberSchema) =>
              subscriptionOverrideMaximum.min(
                Yup.ref('maximum'),
                'Max limit must be equal to or more than the default limit'
              ),
          }),
      }).required()
    ),
    mode: 'onBlur',
    defaultValues,
  })
  const enableSubscriptionOverride = watch('enableSubscriptionOverride')

  useUpdateBreadcrumbs({
    productKey,
    context: Feature.CARD_TRANSACTION_LIMITS,
    subcontext: t(transactionType ? `CARD_TRANSACTION_LIMITS.FORM_TITLE_${transactionType}` : ''),
  })
  const onPressBack = (): void => {
    navigate(`/products/${productKey}/features/card-limits`)
  }

  useEffect(() => {
    reset(defaultValues)
  }, [product])

  if (isLoading || !product) {
    return <Loader />
  }

  if (!isLoading && !transactionType) {
    navigate('/not-found')
  }

  return (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={onPressBack} aria-controls="main" />
              </Grid>
              <Grid item>
                <div style={{ gridArea: 'infoArea', textAlign: 'right' }} />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <H1 gutterBottom>{t(`CARD_TRANSACTION_LIMITS.FORM_TITLE_${transactionType}`)}</H1>
              </Grid>
            </Grid>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <Paper>
            <form
              onSubmit={handleSubmit((values) =>
                submitCardTransactionLimit({
                  ...values,
                  limitRuleId: defaultValues?.limitRuleId,
                  limitName: t(`CARD_TRANSACTION_LIMITS.FORM_TITLE_${transactionType}`),
                  direction,
                  channel,
                  range,
                  amountType,
                  routingDestinations,
                } as any)
              )}
            >
              <Box display="flex" flexDirection="column" gridRowGap={24}>
                <Controller
                  name="maximum"
                  control={control}
                  rules={{ deps: ['subscriptionOverrideMaximum'] }}
                  render={({ field }) => (
                    <Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid item>
                        <Input
                          id="maximum"
                          type="currency"
                          label={t('Default limit amount')}
                          placeholder={t('Enter amount')}
                          currencyCode={product.currency?.currencyCode}
                          style={{ width: 220 }}
                          numberFormatProps={{ thousandSeparator: true }}
                          positiveIntegersOnly
                          required
                          error={!!errors.maximum}
                          errorText={errors.maximum?.message}
                          {...field}
                        />
                      </Grid>
                      <Grid item>
                        <ContextualHelp
                          title={hexCodeToString(
                            t('This is the default {{transactionType}} for all cards associated with this product ', {
                              transactionType: t(`CARD_TRANSACTION_LIMITS.FORM_TITLE_${transactionType}`),
                              nsSeparator: '||',
                            })
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
                <Controller
                  name="enableSubscriptionOverride"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox
                      id="enableSubscriptionOverride"
                      checked={field.value}
                      label={t('Customer can manage their limit (within a range defined by you)')}
                      {...field}
                    />
                  )}
                />
              </Box>
              {(enableSubscriptionOverride !== undefined && enableSubscriptionOverride !== null
                ? enableSubscriptionOverride
                : defaultValues?.enableSubscriptionOverride) && (
                <Flexbox direction="column">
                  <Flexbox direction="row" justifyContent="flex-start" pb={24}>
                    <Controller
                      name="minimum"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="minimum"
                          type="currency"
                          label={t('Min limit (preset)')}
                          placeholder={t('Enter amount')}
                          style={{ width: 220 }}
                          disabled
                          positiveIntegersOnly
                          currencyCode={product.currency?.currencyCode}
                          error={!!errors.minimum}
                          numberFormatProps={{ thousandSeparator: true }}
                          errorText={errors.minimum?.message}
                          {...field}
                        />
                      )}
                    />
                    <Box style={{ width: 16 }} />
                    <Controller
                      name="subscriptionOverrideMaximum"
                      control={control}
                      rules={{ deps: ['maximum'] }}
                      render={({ field }) => (
                        <Input
                          id="subscriptionOverrideMaximum"
                          type="currency"
                          required
                          label={t('Max limit')}
                          placeholder={t('Enter amount')}
                          style={{ width: 220 }}
                          positiveIntegersOnly
                          currencyCode={product.currency?.currencyCode}
                          error={!!errors.subscriptionOverrideMaximum}
                          errorText={errors.subscriptionOverrideMaximum?.message}
                          numberFormatProps={{ thousandSeparator: true }}
                          {...field}
                        />
                      )}
                    />
                  </Flexbox>
                </Flexbox>
              )}
              <Flexbox
                direction="row"
                justifyContent={editableLimit ? 'space-between' : 'flex-end'}
                style={{ marginTop: 50 }}
              >
                {editableLimit && (
                  <Flexbox direction="row" alignItems="center">
                    <LinkWithText
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      startIcon={<ClearIcon />}
                      endIcon={null}
                      onClick={() =>
                        defaultValues?.limitRuleId &&
                        deleteCardTransactionLimit({
                          limitRuleId: defaultValues?.limitRuleId,
                          limitName: t(transactionType ? `CARD_TRANSACTION_LIMITS.FORM_TITLE_${transactionType}` : ''),
                        })
                      }
                      style={{
                        backgroundColor: 'transparent',
                        fontSize: 'inherits',
                        margin: '0 3px',
                        padding: 0,
                      }}
                      variant="text"
                    >
                      {t('REMOVE THIS LIMIT')}
                    </LinkWithText>
                  </Flexbox>
                )}
                <div>
                  <Button
                    color={ColorType.BUTTON}
                    onClick={onPressBack}
                    style={{
                      marginRight: 16,
                    }}
                    aria-controls="main"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button variant="contained" color={ColorType.BUTTON} type="submit" disabled={!isValid || !isDirty}>
                    {t('Submit')}
                  </Button>
                </div>
              </Flexbox>
            </form>
          </Paper>
        }
      />
    </ContentContainer>
  )
}

export default UpdateCardTransactionLimitPage
