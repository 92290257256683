import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import StatementConfigurationCatalog from './StatementConfigurationCatalog'
import useStatementConfiguration from './utils/useStatementConfiguration'
import { Loader } from 'components'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export const StatementConfigurationCatalogPage = () => {
  const { t } = useTranslation()
  const {
    fetchStatementConfigurations,
    statementConfigurations,
    statementConfigurationsLoading,
    draftStatementConfigurationFormValues,
    deleteDraftStatementConfigurationFormValues,
  } = useStatementConfiguration()
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('{{tenantName}} Templates', { tenantName: currentTenant?.name }), path: 'templates/' },
      { title: t('Interest statements'), path: '' },
    ],
    trigger: true,
  })

  useEffect(() => {
    fetchStatementConfigurations()

    if (draftStatementConfigurationFormValues) {
      deleteDraftStatementConfigurationFormValues()
    }
  }, [])

  if (statementConfigurationsLoading) {
    return <Loader />
  }

  return <StatementConfigurationCatalog statementConfigurations={statementConfigurations} />
}

export default StatementConfigurationCatalogPage
