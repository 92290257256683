import React from 'react'
import { PackagesWithProducts } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import AllStatuses from 'pages/Products/FilterMenuItems/AllStatuses'
import FilterMenuItem from 'pages/Products/FilterMenuItems/FilterMenuItem'
import ScheduledMenuItem from 'pages/Packages/FilterMenuItems/ScheduledMenuItem'

const getPackageStatusFilterOptions = (packages: PackagesWithProducts[]) => [
  {
    label: ({ isActive }: { isActive: boolean }) => <AllStatuses isActive={isActive} items={packages} />,
    status: undefined,
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem
        items={packages}
        itemStatus={PackagesStatus.DESIGN}
        isActive={isActive}
        statusPropName="packageStatus"
      />
    ),
    status: [PackagesStatus.DESIGN],
  },
  {
    label: ({ isActive }: { isActive: boolean }) => <ScheduledMenuItem isActive={isActive} items={packages} />,
    status: [PackagesStatus.LIVE],
    isScheduled: true,
  },
  {
    label: ({ isActive }: { isActive: boolean }) => <ScheduledMenuItem items={packages} isActive={isActive} isLive />,
    status: [PackagesStatus.LIVE],
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem
        items={packages}
        itemStatus={PackagesStatus.RETIRED}
        isActive={isActive}
        statusPropName="packageStatus"
      />
    ),
    status: [PackagesStatus.RETIRED],
  },
]

export default getPackageStatusFilterOptions
