import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import AddInterestRate from './AddInterestRate'
import ReferenceDataActions from 'store/referenceData/actions'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import { Loader } from 'components'
import { EditOrAddIndexRate, BankIndexKey } from 'store/referenceData/types'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export const AddInterestRatePage = () => {
  const { t } = useTranslation()
  const { bankIndexKey } = useParams()

  const dispatch = useDispatch()
  const getIndexRateDetails = (indexKey: BankIndexKey) =>
    dispatch(ReferenceDataActions.getBankIndexRateDetails(indexKey))
  const addRate = (rate: EditOrAddIndexRate) => dispatch(ReferenceDataActions.addRate({ ...rate, bankIndexKey }))

  const selectedIndexRate = useSelector(ReferenceDataSelectors.selectSelectedIndexRate)
  const isLoading = useSelector(ReferenceDataSelectors.selectLoading)
  const isUpdating = useSelector(ReferenceDataSelectors.selectIsUpdating)

  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const editableSelectedIndexRate = selectedIndexRate
    ? selectedIndexRate.rates.filter(
        (item) => item.effectiveDate && moment(item.effectiveDate).isSameOrAfter(moment(), 'day')
      )
    : []

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Index rates'), path: 'reference-data/index-rates' },
      { title: selectedIndexRate?.name || '', path: `reference-data/index-rates/${selectedIndexRate?.bankIndexKey}` },
      { title: t('Add future rate'), path: '' },
    ],
    trigger: !!selectedIndexRate,
  })

  useEffect(() => {
    getIndexRateDetails(`${bankIndexKey}`)
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <AddInterestRate
      onSubmit={addRate}
      bankName={selectedIndexRate ? selectedIndexRate.name : ''}
      initialValues={{
        editOrAddIndexRate: {
          rates: [{}],
        },
      }}
      openConfirm={openConfirm}
      setOpenConfirm={setOpenConfirm}
      isUpdating={isUpdating}
      futureDate={editableSelectedIndexRate}
      bankIndexKey={bankIndexKey || ''}
    />
  )
}

export default AddInterestRatePage
