import { createAction } from '@reduxjs/toolkit'
import { PageTitleAndUrl } from './types'

const actions = {
  updateBreadCrumbs: createAction<PageTitleAndUrl[]>('BREADCRUMBS/UPDATE/PAGE'),
  updateBreadCrumbsSuccess: createAction<PageTitleAndUrl[]>('BREADCRUMBS/UPDATE/PAGE/SUCCESS'),
  updateBreadCrumbsFail: createAction<PageTitleAndUrl[]>('BREADCRUMBS/UPDATE/PAGE/FAIL'),
}

export default actions
