/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import sortBy from 'lodash/sortBy'
import TemplateArrayOptionCard from './components/TemplateArrayOptionCard'
import { H1, Body2, Button } from 'dls/atoms'
import { Flexbox } from 'pages/Layout/Styled'
import { ArrayFormOption } from 'dls/shared/types'
import { OrderedList, ResponsiveFlexbox, Main } from 'dls/shared/styled'
import CallToActionBar from 'dls/organisms/CallToActionBar/CallToActionBar'
import { EntityBadgeIndexRate } from 'dls/atoms/EntityBadges/EntityBadge'

const EmptyOptionsHeader = styled(Body2)`
  padding-top: 32px;
  padding-bottom: 28px;
`
const DescriptionBar = styled.div`
  display: grid;
  grid-gap: 12px;
  margin-bottom: 12px;
  grid-template-columns: 1fr auto;
  align-items: center;
`

export interface Props {
  id: string
  icon: React.ReactNode
  title: string
  description: string
  emptyOptionsDescription: string
  options: Record<string, ArrayFormOption>
  toolbarButtons?: React.ReactNode
  headerProps?: React.HTMLAttributes<HTMLDivElement>
  iconProps?: React.HTMLAttributes<HTMLDivElement>
  onCancel?: () => void
  onSubmit?: () => void
}

const TemplateArrayForm = ({
  id,
  title,
  description,
  emptyOptionsDescription,
  options,
  headerProps,
  icon,
  iconProps,
  toolbarButtons,
  onCancel,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const [arrayOptions, setArrayOptions] = useState(options)

  const onClick = (mode: 'add' | 'remove', optionId: string) => {
    let updatedOptions = {
      ...arrayOptions,
      [optionId]: { ...arrayOptions[optionId], added: mode === 'add' },
    }

    if (mode === 'add') {
      updatedOptions = {
        ...updatedOptions,
        [optionId]: { ...updatedOptions[optionId], scrolled: false },
      }
    }
    setArrayOptions(updatedOptions)
  }

  const addedOptions = useMemo(
    () =>
      sortBy(
        Object.entries(arrayOptions).filter(([_, option]) => option.added),
        [([_, op]) => op.name]
      ),
    [arrayOptions]
  )

  const emptyOptions = Object.entries(arrayOptions).filter(([_, option]) => !option.added)

  useEffect(() => {
    if (addedOptions?.length) {
      const op = addedOptions?.find(([_, option]) => option.scrolled === false)
      if (op) {
        const [optionId] = op
        const el = document.getElementById(optionId)
        if (el) {
          el.scrollIntoView()
          const updatedOptions = {
            ...arrayOptions,
            [optionId]: { ...arrayOptions[optionId], scrolled: true },
          }
          setArrayOptions(updatedOptions)
        }
      }
    }
  }, [addedOptions])

  return (
    <Flexbox direction="column" justifyContent="center" alignItems="center" data-dls="template-form">
      <ResponsiveFlexbox direction="column" width="100%">
        <header {...headerProps} css={headerProps?.css}>
          {icon && <div style={{ marginBottom: 32 }}>{icon}</div>}
          {title && (
            <H1 id={`${id}-heading`} gutter>
              {title}
            </H1>
          )}
          {(description || toolbarButtons) && (
            <DescriptionBar>
              {description && <Body2 css={{ 'margin-bottom': 12 }}>{t(description)}</Body2>}
              {toolbarButtons}
            </DescriptionBar>
          )}
        </header>
        <Main id="main" aria-labelledby={`${id}-heading`}>
          <Grid container direction="column" xs={12}>
            <Grid item>
              <OrderedList>
                {addedOptions.map(([optionId, option]) => (
                  <TemplateArrayOptionCard
                    key={optionId}
                    optionId={optionId}
                    option={option}
                    onAdd={(idx: string) => onClick('add', idx)}
                    onRemove={(idx: string) => onClick('remove', idx)}
                  />
                ))}
              </OrderedList>
            </Grid>
            {!!addedOptions.length && (
              <Grid item css="padding: 8px 24px 24px 24px">
                <CallToActionBar
                  rightRegion={[
                    <Button key={1} onClick={onCancel}>
                      CANCEL
                    </Button>,
                    <Button key={2} variant="contained" onClick={onSubmit}>
                      SUBMIT
                    </Button>,
                  ]}
                />
              </Grid>
            )}
            {!!addedOptions.length && emptyOptions.length > 0 && (
              <Grid item>
                <EmptyOptionsHeader variantMapping={{ body2: 'h2' }}>{emptyOptionsDescription}</EmptyOptionsHeader>
              </Grid>
            )}
            <Grid item>
              <OrderedList>
                {emptyOptions.map(([optionId, option]) => (
                  <TemplateArrayOptionCard
                    key={optionId}
                    optionId={optionId}
                    option={option}
                    onAdd={(idx) => onClick('add', idx)}
                    onRemove={(idx) => onClick('remove', idx)}
                  />
                ))}
              </OrderedList>
            </Grid>
          </Grid>
        </Main>
      </ResponsiveFlexbox>
    </Flexbox>
  )
}

export default TemplateArrayForm
