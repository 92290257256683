import { Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { kebabCase } from 'lodash'
import Layout from './pages/Layout/Layout'
import UserActions from 'store/user/actions'
import * as UserSelector from 'store/user/selectors'
import { Loader } from 'components'
import {
  AboutPage,
  AddInterestRatePage,
  ReferenceDataPage,
  CardsFeaturePage,
  TransactionRulesFeaturePage,
  CreatePackagePage,
  CreateInterestRatePage,
  CreditInterestFeaturePage,
  CurrencyFeaturePage,
  DashboardPage,
  Documents,
  EditInterestRatePage,
  EligibilityFeaturePage,
  FeaturePage,
  GovernancePage,
  HelpContentPage,
  HelpPage,
  InterestRateHistoryPage,
  LimitsFeaturePage,
  LoginPage,
  NotAllowedFeaturePage,
  NotAllowedPage,
  NotFoundPage,
  NotFoundRoute,
  PackagePage,
  PackagesPage,
  ProcessPage,
  ProductCodes,
  ProductCorePage,
  ProductPage,
  ProductsPage,
  ProductVersionHistory,
  ProductVersionPage,
  RequiredExternalIdFeaturePage,
  InterestRateCatalogPage,
  SingleInterestRatePage,
  ReviewTransactionsPage,
  SSOLogin,
  StatementFormFeaturePage,
  SelectStatementPage,
  SubscriptionCreationRulePage,
  SuspenseAccountsPage,
  TermsAndConditionsPage,
  Transactions,
  UnavailablePage,
  UserPage,
  Versions,
  WithholdingTaxRuleCatalogPage,
  SingleTaxRulePage,
  CreateTaxRulePage,
  TaxRuleHistoryPage,
  AddTaxRulePage,
  EditTaxRulePage,
  AccessDenied,
  EditTransactionRulesDefaultAccessPage,
  EditPackagePage,
  PaymentSchedulePage,
  SetupDebitInterestPage,
  UpdateDebitInterestPage,
  ViewDebitInterestPage,
  UsersPage,
  TaxRuleProductsPage,
  ViewFeesRewardsPage,
  UpdateFeeRewardPage,
  ViewPaymentLimitsPage,
  UpdatePaymentLimitsYearDefinitionPage,
  UpdatePaymentLimitPage,
  ViewCardTransactionLimitsPage,
  UpdateCardTransactionLimitPage,
  UpdateCreditLimitPage,
  TemplatesPage,
  StatementConfigurationCatalogPage,
  UpdateStatementConfigurationPage,
  SelectStatementTemplatePage,
  SingleStatementConfigurationPage,
  UpdateMultiPartyPage,
  ProductSyndicationPage,
  ProductSyndicationCatalogPage,
} from 'pages'
import { store } from 'store'
import { selectAuthenticated, selectUserRole } from 'store/user/selectors'
import { selectFeatures, selectTenants, selectLoginPage } from 'store/tenant-config/selectors'
import { hasUserRightForPath } from 'utils/common.util'
import { TenantStateContext } from 'context/tenantSelectorContext'
import { TENANT_URL_PREFIX } from 'utils/constants'
import { Feature } from 'store/products/types'

let globalTenantPath: string = ''

const ProtectedRoute = ({ children }: { children?: JSX.Element }) => {
  const location = useLocation()

  const userRole = useSelector(selectUserRole)
  const features = useSelector(selectFeatures)
  const param = useParams()
  const dispatch = useDispatch()

  const tenants = selectTenants(store.getState())
  const { tenantName = '' } = param || {}
  const tenantIndex = tenantName ? tenants?.findIndex((tenant) => kebabCase(tenant.name) === tenantName) : 0
  const selectSelectedTenantIndex = useSelector(UserSelector.selectSelectedTenantIndex)

  // Redux state has been used everywhere to read the tenantIndex.
  useEffect(() => {
    dispatch(UserActions.setTenantIndexAndApiKey(tenantIndex || 0))
  }, [tenantIndex, tenantName])

  if (!hasUserRightForPath(userRole, location.pathname, features.role_check)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <Navigate to="/login" state={{ from: location }} replace />
    return <AccessDenied />
  }
  // Will make sure to render component only after tenantindex set
  return selectSelectedTenantIndex === tenantIndex ? (
    <TenantStateContext.Provider value={{ tenantName, tenantPath: globalTenantPath, tenantIndex }}>
      {children}
    </TenantStateContext.Provider>
  ) : (
    <Loader />
  )
}

const RouterHelper = ({ authenticated = false }: { authenticated?: boolean }) => {
  const location = useLocation()
  const features = useSelector(selectFeatures)
  const loginPage = useSelector(selectLoginPage)
  const homePage = features.home ? (features.home as string) : `dashboard`
  const isPublicPage: boolean =
    window.location.pathname.indexOf('login') > 0 ||
    window.location.pathname.indexOf('sso-flow-init') > 0 ||
    window.location.pathname.indexOf('sso-oauth-init') > 0
  return (
    <>
      {!authenticated && !isPublicPage && (
        <>
          <Navigate to={`${loginPage}`} state={{ from: location }} replace />
        </>
      )}
      {location && location.pathname.indexOf('/landingPage') > 0 && <Navigate to="/" replace />}
      {location && location.pathname.indexOf('/') > 0 && !(location.pathname.indexOf(homePage) > 0) && (
        <Navigate to={homePage} replace />
      )}
    </>
  )
}
const MultiTenantNavigator = ({ globalTenantPathParam }: { globalTenantPathParam?: string }) => {
  const location = useLocation()

  return (
    <Navigate to={`/${globalTenantPathParam}${location.pathname}${location.search}`} replace state={location?.state} />
  )
}
const Router = () => {
  const features = useSelector(selectFeatures)
  const authenticated = useSelector(selectAuthenticated)
  const tenants = selectTenants(store.getState())
  const selectSelectedTenantIndex = useSelector(UserSelector.selectSelectedTenantIndex)

  globalTenantPath = `${TENANT_URL_PREFIX}/${kebabCase(tenants?.[selectSelectedTenantIndex || 0].name)}/`

  return (
    <>
      {/* Redirect to login when not authenticated */}
      <RouterHelper authenticated={authenticated} />
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="sso-login" element={<SSOLogin />} />
        <Route path="sso-flow-init" element={<SSOLogin />} />
        <Route path="sso-oauth-init" element={<SSOLogin />} />

        <Route path={`/${TENANT_URL_PREFIX}/:tenantName`} element={<Layout />}>
          <Route path="landingPage" element={<Navigate to="/" replace />} />
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route
            element={
              <ProtectedRoute>
                <ReferenceDataPage />
              </ProtectedRoute>
            }
            path="reference-data"
          />
          <Route
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
            path="dashboard"
          />
          <Route
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
            index
          />
          <Route
            element={
              <ProtectedRoute>
                <SuspenseAccountsPage />
              </ProtectedRoute>
            }
            path="accounts"
          />
          <Route
            element={
              <ProtectedRoute>
                <Transactions />
              </ProtectedRoute>
            }
            path="accounts/:subscriptionKey"
          />
          <Route
            element={
              <ProtectedRoute>
                <Transactions isSearchPage />
              </ProtectedRoute>
            }
            path="accounts/:subscriptionKey/search"
          />
          <Route
            element={
              <ProtectedRoute>
                <ReviewTransactionsPage />
              </ProtectedRoute>
            }
            path="accounts/:subscriptionKey/confirm-transfer"
          />
          <Route
            element={
              <ProtectedRoute>
                <CreateInterestRatePage />
              </ProtectedRoute>
            }
            path="reference-data/index-rates/create"
          />
          <Route
            element={
              <ProtectedRoute>
                <EditInterestRatePage />
              </ProtectedRoute>
            }
            path="reference-data/index-rates/:bankIndexKey/edit"
          />
          <Route
            element={
              <ProtectedRoute>
                <AddInterestRatePage />
              </ProtectedRoute>
            }
            path="reference-data/index-rates/:bankIndexKey/add"
          />
          <Route
            element={
              <ProtectedRoute>
                <InterestRateHistoryPage />
              </ProtectedRoute>
            }
            path="reference-data/index-rates/:bankIndexKey/history"
          />
          <Route
            element={
              <ProtectedRoute>
                <SingleInterestRatePage />
              </ProtectedRoute>
            }
            path="reference-data/index-rates/:bankIndexKey"
          />
          <Route
            element={
              <ProtectedRoute>
                <InterestRateCatalogPage />
              </ProtectedRoute>
            }
            path="reference-data/index-rates"
          />
          <Route
            element={
              <ProtectedRoute>
                <NotFoundRoute />
              </ProtectedRoute>
            }
            path="not-found"
          />
          <Route
            element={
              <ProtectedRoute>
                <NotAllowedPage />
              </ProtectedRoute>
            }
            path="not-allowed"
          />
          <Route
            element={
              <ProtectedRoute>
                <NotAllowedFeaturePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/not-allowed"
          />
          <Route
            element={
              <ProtectedRoute>
                <UnavailablePage />
              </ProtectedRoute>
            }
            path="unavailable/:path"
          />
          <Route
            element={
              <ProtectedRoute>
                <PackagesPage />
              </ProtectedRoute>
            }
            path="packages"
          />
          <Route
            element={
              <ProtectedRoute>
                <GovernancePage />
              </ProtectedRoute>
            }
            path="governance"
          />
          <Route
            element={
              <ProtectedRoute>
                <HelpPage />
              </ProtectedRoute>
            }
            path="help"
          />
          <Route
            element={
              <ProtectedRoute>
                <HelpContentPage />
              </ProtectedRoute>
            }
            path="help/:page"
          />
          <Route
            element={
              <ProtectedRoute>
                <AboutPage />
              </ProtectedRoute>
            }
            path="about"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProductsPage />
              </ProtectedRoute>
            }
            path="products"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProductPage />
              </ProtectedRoute>
            }
            path="products/:productKey"
          />
          <Route
            element={
              <ProtectedRoute>
                <PackagePage />
              </ProtectedRoute>
            }
            path="packages/:productPackageId"
          />
          <Route
            element={
              <ProtectedRoute>
                <EditPackagePage />
              </ProtectedRoute>
            }
            path="packages/:productPackageId/edit"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProcessPage />
              </ProtectedRoute>
            }
            path="governance/:processKey"
          />
          <Route
            element={
              <ProtectedRoute>
                <CreatePackagePage />
              </ProtectedRoute>
            }
            path="packages/create"
          />
          <Route
            element={
              <ProtectedRoute>
                <EligibilityFeaturePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/eligibility"
          />
          <Route
            element={
              <ProtectedRoute>
                <CardsFeaturePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/cards"
          />
          <Route
            element={
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            }
            path="users"
          />
          <Route
            element={
              <ProtectedRoute>
                <ViewFeesRewardsPage featureType={Feature.FEES} />
              </ProtectedRoute>
            }
            path="products/:productKey/features/fees"
          />
          <Route
            element={
              <ProtectedRoute>
                <ViewFeesRewardsPage featureType={Feature.REWARDS} />
              </ProtectedRoute>
            }
            path="products/:productKey/features/rewards"
          />
          <Route
            element={
              <ProtectedRoute>
                <UpdateDebitInterestPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/debit-interest/:actionType/:rateType"
          />
          <Route
            element={
              <ProtectedRoute>
                <SetupDebitInterestPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/debit-interest-common-config"
          />
          <Route
            element={
              <ProtectedRoute>
                <ViewDebitInterestPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/debit-interest"
          />
          <Route
            element={
              <ProtectedRoute>
                <EditTransactionRulesDefaultAccessPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/transaction-rules"
          />
          <Route
            element={
              <ProtectedRoute>
                <TransactionRulesFeaturePage transactionExceptionType="inbound" />
              </ProtectedRoute>
            }
            path="products/:productKey/features/transaction-rules-inbound-exceptions/:index"
          />
          <Route
            element={
              <ProtectedRoute>
                <TransactionRulesFeaturePage transactionExceptionType="inbound" />
              </ProtectedRoute>
            }
            path="products/:productKey/features/transaction-rules-inbound-exceptions"
          />
          <Route
            element={
              <ProtectedRoute>
                <TransactionRulesFeaturePage transactionExceptionType="outbound" />
              </ProtectedRoute>
            }
            path="products/:productKey/features/transaction-rules-outbound-exceptions"
          />
          <Route
            element={
              <ProtectedRoute>
                <TransactionRulesFeaturePage transactionExceptionType="outbound" />
              </ProtectedRoute>
            }
            path="products/:productKey/features/transaction-rules-outbound-exceptions/:index"
          />
          <Route
            element={
              <ProtectedRoute>
                <ViewPaymentLimitsPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/payment-limits"
          />
          <Route
            element={
              <ProtectedRoute>
                <UpdatePaymentLimitPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/payment-limits/add"
          />
          <Route
            element={
              <ProtectedRoute>
                <UpdatePaymentLimitPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/payment-limits/edit/:limitId"
          />
          <Route
            element={
              <ProtectedRoute>
                <UpdatePaymentLimitsYearDefinitionPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/payment-limits/year-definition"
          />
          <Route
            element={
              <ProtectedRoute>
                <ViewCardTransactionLimitsPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/card-limits"
          />
          <Route
            element={
              <ProtectedRoute>
                <UpdateCardTransactionLimitPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/card-limits/add"
          />
          <Route
            element={
              <ProtectedRoute>
                <UpdateCardTransactionLimitPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/card-limits/edit/:limitId"
          />
          <Route
            element={
              <ProtectedRoute>
                <PaymentSchedulePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/payment-schedule"
          />
          <Route
            element={
              <ProtectedRoute>
                <CreditInterestFeaturePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/credit-interest"
          />
          <Route
            element={
              <ProtectedRoute>
                <CurrencyFeaturePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/currency"
          />
          <Route
            element={
              <ProtectedRoute>
                <RequiredExternalIdFeaturePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/required-external-id"
          />
          <Route
            element={
              <ProtectedRoute>
                <FeaturePage FormPage={LimitsFeaturePage} context="limits" />
              </ProtectedRoute>
            }
            path="products/:productKey/features/limits"
          />
          <Route
            element={
              <ProtectedRoute>
                <TermsAndConditionsPage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/terms-and-conditions"
          />
          <Route
            element={
              <ProtectedRoute>
                <StatementFormFeaturePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/statements"
          />
          <Route
            element={
              <ProtectedRoute>
                <StatementConfigurationCatalogPage />
              </ProtectedRoute>
            }
            path="templates/statement-configurations"
          />
          <Route
            element={
              <ProtectedRoute>
                <TemplatesPage />
              </ProtectedRoute>
            }
            path="templates"
          />
          <Route
            element={
              <ProtectedRoute>
                <SingleStatementConfigurationPage />
              </ProtectedRoute>
            }
            path="templates/statement-configurations/:statementConfigKey"
          />
          <Route
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
            path="products/:productKey/features/documents"
          />
          <Route
            element={
              <ProtectedRoute>
                <SubscriptionCreationRulePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/subscription-creation-rule"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProductCodes />
              </ProtectedRoute>
            }
            path="products/:productKey/features/identification"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProductCorePage />
              </ProtectedRoute>
            }
            path="products/:productKey/features/product-core"
          />
          <Route
            element={
              <ProtectedRoute>
                <UserPage />
              </ProtectedRoute>
            }
            path="user/:userKey"
          />
          <Route
            element={
              <ProtectedRoute>
                <Versions />
              </ProtectedRoute>
            }
            path="products/:productKey/versionsold"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProductVersionHistory />
              </ProtectedRoute>
            }
            path="products/:productKey/versions"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProductVersionPage />
              </ProtectedRoute>
            }
            path="products/:productKey/versions/:version"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProductSyndicationCatalogPage />
              </ProtectedRoute>
            }
            path="product-syndications"
          />
          {features?.withholding_tax && (
            <>
              <Route
                element={
                  <ProtectedRoute>
                    <WithholdingTaxRuleCatalogPage />
                  </ProtectedRoute>
                }
                path="reference-data/withholding-tax"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <CreateTaxRulePage />
                  </ProtectedRoute>
                }
                path="reference-data/withholding-tax/create"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <SingleTaxRulePage />
                  </ProtectedRoute>
                }
                path="reference-data/withholding-tax/:ruleId"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <AddTaxRulePage />
                  </ProtectedRoute>
                }
                path="reference-data/withholding-tax/:ruleId/add"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <TaxRuleHistoryPage />
                  </ProtectedRoute>
                }
                path="reference-data/withholding-tax/:ruleId/history"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <EditTaxRulePage />
                  </ProtectedRoute>
                }
                path="reference-data/withholding-tax/:ruleId/:taxRuleId/edit"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <TaxRuleProductsPage />
                  </ProtectedRoute>
                }
                path="reference-data/withholding-tax/:ruleId/products"
              />
            </>
          )}
          <Route
            element={
              <ProtectedRoute>
                <StatementConfigurationCatalogPage />
              </ProtectedRoute>
            }
            path="templates/statement-configurations"
          />
          <Route
            element={
              <ProtectedRoute>
                <TemplatesPage />
              </ProtectedRoute>
            }
            path="templates"
          />
          <Route element={<NotFoundPage />} path="*" />
        </Route>
        <Route path={`/${TENANT_URL_PREFIX}/:tenantName`} element={<Layout headLessLayout />}>
          {features && (
            <>
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateFeeRewardPage featureType={Feature.FEES} />
                  </ProtectedRoute>
                }
                path="products/:productKey/features/fees/add"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateFeeRewardPage featureType={Feature.FEES} />
                  </ProtectedRoute>
                }
                path="products/:productKey/features/fees/edit/:entityId"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateFeeRewardPage featureType={Feature.REWARDS} />
                  </ProtectedRoute>
                }
                path="products/:productKey/features/rewards/add"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateFeeRewardPage featureType={Feature.REWARDS} />
                  </ProtectedRoute>
                }
                path="products/:productKey/features/rewards/edit/:entityId"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <SelectStatementPage />
                  </ProtectedRoute>
                }
                path="products/:productKey/features/statements/choose"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateCreditLimitPage />
                  </ProtectedRoute>
                }
                path="products/:productKey/features/min-max-credit-limit"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateMultiPartyPage />
                  </ProtectedRoute>
                }
                path="products/:productKey/features/multi-party"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateStatementConfigurationPage />
                  </ProtectedRoute>
                }
                path="templates/statement-configurations/create"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateStatementConfigurationPage />
                  </ProtectedRoute>
                }
                path="templates/statement-configurations/:statementConfigKey/edit"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <UpdateStatementConfigurationPage />
                  </ProtectedRoute>
                }
                path="templates/statement-configurations/create"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <SelectStatementTemplatePage />
                  </ProtectedRoute>
                }
                path="templates/statement-configurations/create/selectTemplate"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <SelectStatementTemplatePage />
                  </ProtectedRoute>
                }
                path="templates/statement-configurations/:statementConfigKey/edit/selectTemplate"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <ProductSyndicationPage />
                  </ProtectedRoute>
                }
                path="product-syndication/:productKey/versions/:version/initiate"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <ProductSyndicationPage isCreatePage />
                  </ProtectedRoute>
                }
                path="product-syndication/:productKey/versions/:version/create"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <ProductSyndicationPage isUpdatePage />
                  </ProtectedRoute>
                }
                path="product-syndication/:productKey/versions/:version/update"
              />
            </>
          )}
        </Route>
        <Route path="*" element={<MultiTenantNavigator globalTenantPathParam={globalTenantPath} />} />
      </Routes>
    </>
  )
}

export default Router
