/* eslint-disable camelcase */
export type SubscriptionKey = string | undefined
export type TransactionId = string | undefined
export type CorrelationId = string | undefined

export interface SuspenseAccountsState {
  loading: boolean
  error?: Error
  transactionError?: Error
  suspenseAccounts?: SuspenseAccounts
  selectedSuspenseAccountLoading: boolean
  suspenseAccount?: SuspenseAccount
  selectedSuspenseTransactions?: SuspenseTransactionsResponse
  selectedSuspenseTransactionsForStat?: SuspenseTransactionsForStat
  transactionsLoading: boolean
  confirmTransactionLoading: boolean
  confirmTransactionResult?: ConfirmTransactionResultType
  transactionsAppending: boolean
  confirmParentTransactionLoading?: string | boolean
}

export enum AccountState {
  ACTIVE = 'ACTIVE',
}

export enum SuspenseAccountType {
  EXGRATIA = 'EXGRATIA',
  SANCTIONS = 'SANCTIONS',
  FPS_SCHEME = 'FPS_SCHEME',
  TRANSACTION_DISPUTE = 'TRANSACTION_DISPUTE',
  SUSPENSE_ACCOUNT_GENERAL = 'SUSPENSE_ACCOUNT_GENERAL',
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  PARTIALLY_CLEARED = 'PARTIALLY_CLEARED',
  CLEARED = 'CLEARED',
  CONTRA_CLEARED = 'CONTRA_CLEARED',
  CONTRA_TRANSFER = 'CONTRA_TRANSFER',
  CONTRA_WRITE_OFF = 'CONTRA_WRITE_OFF',
  CONTRA_WRITE_BACK = 'CONTRA_WRITE_BACK',
  CONTRA_WRITE_BACK_REVERSED = 'CONTRA_WRITE_BACK_REVERSED',
  CONTRA_WRITE_OFF_REVERSED = 'CONTRA_WRITE_OFF_REVERSED',
}

export enum BalanceType {
  RUNNING = 'RUNNING',
}

export enum CreditDebitIndicator {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum CurrencyCode {
  GBP = 'GBP',
  USD = 'USD',
  AUD = 'AUD',
}

export interface Balance {
  amount: number
  balanceType: BalanceType
  creditDebitIndicator: CreditDebitIndicator
  currency: CurrencyCode
}

export interface SuspenseAccount {
  state: AccountState
  balances: Balance[]
  partyKey: string
  subscriptionKey: SubscriptionKey
  name: string
  type: SuspenseAccountType
}

export interface TransactionAmount {
  amount: number
  currency: CurrencyCode
}
export interface SuspenseTransactionItem {
  correlationId: CorrelationId
  amount: TransactionAmount
  description: string
  isCredit: boolean
  isSettled: boolean
  pagingId: string
  parentTransactionId: string
  postedTimeStamp: string
  reasonCode?: string
  subscriptionKey: SubscriptionKey
  suspenseAccountName: string
  suspenseAccountType: SuspenseAccountType
  transactionCode: string
  transactionId: TransactionId
  transactionStatus: TransactionStatus
  customerPartyKey?: string
  customerSubscriptionKey?: string
  customerTransactionId?: string
  subscriptionName?: string
  _key?: string
}

export interface updateParentId {
  SuspenseTransactionItem?: SuspenseTransactionItem
  selectedSuspenseTransactions?: any
}

export interface LinksData {
  first: string
  last: string
  next: string
  prev: string
  self: string
}

export interface SuspenseTransactionsResponse {
  transactions: SuspenseTransactionItem[]
  links: LinksData
}

export interface SuspenseTransactionsForStat {
  [subscriptionKey: string]: {
    selectedSuspenseTransactionsFor90Days: SuspenseTransactionsResponse
    selectedSuspenseTransactionsFor60Days: SuspenseTransactionsResponse
    selectedSuspenseTransactionsFor30Days: SuspenseTransactionsResponse
    isLoading: boolean
    error?: Error
  }
}

export interface SuspenseTransactionsResult {
  key?: string
  data?: {
    selectedSuspenseTransactionsFor90Days?: SuspenseTransactionsResponse
    selectedSuspenseTransactionsFor60Days?: SuspenseTransactionsResponse
    selectedSuspenseTransactionsFor30Days?: SuspenseTransactionsResponse
    isLoading: boolean
    error?: Error
  }
}

export interface SuspenseAccounts {
  subscriptions: SuspenseAccount[]
}

export interface SuspenseTransactionsForm {
  selectedTransactions: TransactionId[]
  suspenseAccount: SubscriptionKey
  destinationAccount?: string
}

export interface SuspenseTransactionsTransferResult {
  selectedTransactions: SuspenseTransactionItem[]
  suspenseAccount: SuspenseAccount | undefined
  destinationAccount: SuspenseAccount | string | undefined
}

export interface SuspenseAccountResponse extends SuspenseAccount {}

export interface GetSuspenseTransactionsRequest {
  transactionId?: TransactionId
  customerSubscriptionKey?: SubscriptionKey
  subscriptionKey?: SubscriptionKey
  limit?: string | number
  f_dt?: string | number | undefined
  t_dt?: string | number | undefined
  cursor?: string | undefined
  cr_dr?: CreditOrDebit | string | undefined
  f_amt?: number | string | undefined
  t_amt?: number | string | undefined
  transactionStatus?: string | undefined
  correlationId?: CorrelationId
}

export enum CreditOrDebit {
  CREDIT = 'CREDIT',
  CREDITANDDEBIT = 'CREDITANDDEBIT',
  DEBIT = 'DEBIT',
}

export interface TransactionTransferRequest {
  destinationSuspenseSubscription: string
  suspenseTransactionIds: string[]
}

export interface ReconcileTransferRequest {
  parentTransactionId?: string
  transactionId?: string
  status: any
  selectedSuspenseTransactions: any
}

export interface ReconcileTransferRequestTransactions {
  transactions: any
}

export enum ConfirmTransactionResultType {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export interface WriteOffAndWriteBackTransactionTransferRequest {
  suspenseTransactionIds: string[]
}
export interface NostroWashTransactionTransferRequest {
  suspenseTransactionIds: string[]
}

export interface ReverseWriteOffAndWriteBackTransactionTransferRequest {
  transactionIds: string[]
}
