import { put, takeLatest } from 'redux-saga/effects'
import BreadcrumbsActions from 'store/breadcrumbs/actions'

function* UpdateBreadCrumbs(action: ReturnType<typeof BreadcrumbsActions.updateBreadCrumbs>) {
  try {
    yield put(BreadcrumbsActions.updateBreadCrumbsSuccess(action.payload))
  } catch (e: any) {
    yield put(BreadcrumbsActions.updateBreadCrumbsFail(e))
  }
}

export default function* () {
  yield takeLatest(BreadcrumbsActions.updateBreadCrumbs.type, UpdateBreadCrumbs)
}
