/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, Typography, TableRow, TableCell, Box, Table, TableHead } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { find, kebabCase } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined'
import { StyledContainer } from './Style'
import ViewProductsBody from 'pages/ReferenceData/WithholdingTaxRuleCatalog/view/products/ViewProductsBody'
import { Body2, EntityBadgePackages, EntityBadgeProducts, Line, Subtitle1 } from 'dls/atoms'
import { Alpha3CountryCodes } from 'store/products/types'
import { TableCommonDataType } from 'components/TableCommon/types'
import { Flag } from 'components'
import { AvailableProducts, PackagesWithProducts } from 'store/packages/types'
import { NoProductIcon } from 'assets'
import { Paper, InfoPanel, ReviewRows, DesignIconButton } from 'dls'

interface OwnProps {
  availableProducts: AvailableProducts[] | undefined
  selectedPackage: PackagesWithProducts
}

const BMTable = styled(Table)`
  .MuiTableCell-root {
    padding-left: 0;
  }
`

const BoldText = styled(Typography)`
  font-weight: 500;
`

const ViewPackage = ({ selectedPackage, availableProducts }: OwnProps): React.ReactElement => {
  const { packageType, distribution, products, productPackageName } = selectedPackage
  let filteredavailableProducts: TableCommonDataType[] = []

  if (selectedPackage) {
    filteredavailableProducts = (availableProducts || []).filter((item) =>
      find(products, { productKey: item.productKey })
    )
  } else {
    filteredavailableProducts = availableProducts || []
  }
  const { t } = useTranslation()
  return (
    <>
      <StyledContainer data-test-id={kebabCase(`${'Package details'}-form-container`)}>
        <Paper elevation={1}>
          <Subtitle1 variantMapping={{ subtitle1: 'h2' }}>
            <EntityBadgePackages
              style={{
                marginRight: 8,
                verticalAlign: 'middle',
              }}
            />
            {t('Package details')}
          </Subtitle1>
          {!products ||
            (products.length === 0 && (
              <InfoPanel
                title={t('No products exist for this package')}
                body={t(
                  'A package must contain at least 1 live or scheduled product. ' +
                    'This error normally occurs when products selected for this package have been retired. ' +
                    'Choose ‘edit’ to add products or choose ‘delete’ if this package is no longer required.'
                )}
                color="error"
                css="margin 24px 0; width: auto;"
              />
            ))}
          <Box mt={3}>
            <ReviewRows
              definitionsList={[
                {
                  definitions: [
                    {
                      term: t('Package name'),
                      definitions: [productPackageName ? <>{t(productPackageName)}</> : t('N/A')],
                    },
                    {
                      term: t('Package type'),
                      definitions: [distribution && distribution.packageType ? <>{t(packageType || '')}</> : t('N/A')],
                    },
                    {
                      term: t('Country of residence'),
                      definitions: [
                        distribution && distribution.countryOfResidence ? (
                          <>
                            <Flag code={Alpha3CountryCodes[distribution.countryOfResidence]} size={20} />
                            {t(`COUNTRIES.${distribution.countryOfResidence}`)}
                          </>
                        ) : (
                          t('N/A')
                        ),
                      ],
                    },
                    {
                      term: t('Custom attribute'),
                      definitions: [
                        distribution && distribution.brandId ? <>{t(`${distribution.brandId}`)}</> : t('N/A'),
                      ],
                    },
                  ],
                },
              ]}
            />
          </Box>
          <Grid xs={12} item>
            <Box mt={4} mb={4}>
              <Line type="strong" />
            </Box>

            <Subtitle1 variantMapping={{ subtitle1: 'h2' }} gutter>
              <EntityBadgeProducts
                style={{
                  marginRight: 8,
                  verticalAlign: 'middle',
                }}
              />
              {t('Products in this package')}
            </Subtitle1>

            {!isEmpty(filteredavailableProducts) ? (
              <BMTable>
                <TableHead>
                  <TableRow>
                    <TableCell width="50%">
                      <BoldText variant="body2">{t('Product name')}</BoldText>
                    </TableCell>
                    <TableCell width="50%">
                      <Typography variant="body2">{t('Version, status and name')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <ViewProductsBody products={filteredavailableProducts} />
              </BMTable>
            ) : (
              <Grid container direction="row" alignItems="stretch" justifyContent="flex-start">
                <Grid item style={{ marginRight: 14 }}>
                  <img src={NoProductIcon} alt="No products exist" />
                </Grid>
                <Grid item>
                  <Body2>{t('No products exist')}</Body2>
                </Grid>
                <Body2
                  color="error"
                  style={{
                    marginTop: 4,
                  }}
                >
                  <ErrorOutlinedIcon
                    color="error"
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 4,
                      marginTop: -4,
                    }}
                  />
                  {t('There must be at least 1 product in a package')}
                </Body2>
              </Grid>
            )}
          </Grid>

          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <DesignIconButton component={RouterLink} to="edit" />
            </Grid>
          </Grid>
        </Paper>
      </StyledContainer>
    </>
  )
}

export default ViewPackage
