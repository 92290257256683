import React from 'react'
import { Grid } from '@material-ui/core'
import { Field, FormikProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import moment, { Moment } from 'moment-timezone'
import { RowGrid } from '../styles'
import { ContextualHelp, FieldPercentage, RepeaterLayout } from 'components'
import { BankInterestRateFormValues } from 'store/referenceData/types'
import DateTimePickerComponent from 'components/form/DateTimePickerComponent'

interface OwnProps {
  name: string
}

interface RatesFormProps extends FormikProps<BankInterestRateFormValues>, OwnProps {}

const TooltipStyle = styled.div`
  margin-left: 10px;
  margin-top: 19px;
  color: ${(props: any) => `${props.theme.palette.text.secondary}`};
`
const CreateInterestRateList = (_props: RatesFormProps) => {
  const { t } = useTranslation()
  const { name, values, isValid } = _props
  const datesInList = values.rates.map((i) => i && i.effectiveDate && moment(i.effectiveDate))
  const lastTierBandIndex = getIn(values, `${name}`).length - 1
  const { rates } = values
  const ratesLength = rates.length

  return (
    <RepeaterLayout
      name={name}
      labelDelete={t('Delete Rate')}
      labelNewItem={t('Add a Rate')}
      newItem={{
        rate: null,
        effectiveDate: datesInList.length > 0 ? moment(datesInList[datesInList.length - 1]).add(1, 'day') : moment(),
      }}
      isItemDeletable={() => lastTierBandIndex > 0}
      isAddDisabled={!isValid}
      deleteButtonStyle={{ top: 7, marginLeft: -70 }}
    >
      {({ name: itemName, index }) => (
        <Grid container direction="row" spacing={2} key={index}>
          <RowGrid item xs={4}>
            <Field
              label={t(`Rate`)}
              name={`${itemName}.indexRate`}
              key={`${itemName}.${index}.${ratesLength}.indexRate`}
              required
              fullWidth
              percentageIsPrefix
              component={FieldPercentage}
              helperText={t('E.g. 1.25')}
              inputLabelProps={{ shrink: true }}
              numberFormatProps={{
                isNumericString: true,
              }}
            />
          </RowGrid>
          <RowGrid item xs={6} css="display: -webkit-inline-box;">
            <Field
              required
              label={t('Effective date')}
              name={`${itemName}.effectiveDate`}
              key={`${itemName}.${index}.${ratesLength}.effectiveDate`}
              component={DateTimePickerComponent}
              disablePast
              defaultIsNow
              touchedNotNeeded
              showToolbar={false}
              dateTimePickerProps={{
                minDate: moment().startOf('day'),
                shouldDisableDate: (currentDate: moment.Moment) => {
                  if (currentDate && !(datesInList && (datesInList[index] as Moment)?.isSame(currentDate, 'day'))) {
                    return datesInList.find((date) => currentDate.isSame(date, 'day'))
                  }
                  return false
                },
              }}
            />
            <TooltipStyle>
              <ContextualHelp
                title={t(
                  'The date from which the rate will be used in interest calculations. Rates cannot be added with historic effective dates and effective dates cannot be changed once submitted'
                )}
              />
            </TooltipStyle>
          </RowGrid>
        </Grid>
      )}
    </RepeaterLayout>
  )
}
export default CreateInterestRateList
