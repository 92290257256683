import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { AccessValue, RuleDefinitions } from './ViewTransactionRulesComponents'
import { ReviewRows, Line, Subtitle1, DesignIconButton, AddCircleIconButton } from 'dls'
import { TransactionRuleAccess, TransactionRules } from 'store/products/types'

interface OwnProps {
  transactionRules: TransactionRules
  editable: boolean
}

const ViewTransactionRules = (props: OwnProps): React.ReactElement => {
  const { transactionRules, editable = false } = props
  const { t } = useTranslation()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box position="relative">
            <ReviewRows
              definitionsList={[
                {
                  definitions: [
                    {
                      term: t('Inbound default'),
                      definitions: [
                        <AccessValue
                          key="inboundDefault"
                          access={transactionRules?.inbound?.defaultAccess || TransactionRuleAccess.PERMIT}
                        />,
                      ],
                    },
                    {
                      term: t('Outbound default'),
                      definitions: [
                        <AccessValue
                          key="inboundDefault"
                          access={transactionRules?.outbound?.defaultAccess || TransactionRuleAccess.PERMIT}
                        />,
                      ],
                    },
                  ],
                },
              ]}
            />
            {editable && (
              <Box position="absolute" right={8} bottom={-16}>
                <DesignIconButton aria-label="edit" component={RouterLink} to="./features/transaction-rules" />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Line type="strong" />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mr={1} mt={-1.5}>
            <Subtitle1 variantMapping={{ subtitle1: 'h4' }}>{t('Inbound exceptions')}</Subtitle1>
            {editable && (
              <AddCircleIconButton
                aria-label="add"
                component={RouterLink}
                to="./features/transaction-rules-inbound-exceptions"
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {transactionRules?.inbound && (
            <RuleDefinitions item={transactionRules.inbound} transactionExceptionType="inbound" editable={editable} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Line type="strong" />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mr={1} mt={-1.5}>
            <Subtitle1 variantMapping={{ subtitle1: 'h4' }}>{t('Outbound exceptions')}</Subtitle1>
            {editable && (
              <AddCircleIconButton
                aria-label="add"
                component={RouterLink}
                to="./features/transaction-rules-outbound-exceptions"
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {transactionRules?.outbound && (
            <RuleDefinitions item={transactionRules.outbound} transactionExceptionType="outbound" editable={editable} />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ViewTransactionRules
