import { useState } from 'react'

const useDialogs = () => {
  const [isDialogOpen, setOpen] = useState(false)

  const onOpenDialog = () => {
    setOpen(true)
  }
  const onCloseDialog = () => {
    setOpen(false)
  }

  return { isDialogOpen, onOpenDialog, onCloseDialog }
}
export default useDialogs
