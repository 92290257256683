import * as Yup from 'yup'
import moment from 'moment-timezone'
import { isArray } from 'lodash'
import { WithholdingRule } from 'store/withholdingTax/types'

export const initialFact = { name: '', operator: '', value: [] }
export const effectiveDateText = (context: Yup.TestContext, value?: any): boolean =>
  !(!!value && !moment().isSameOrBefore(value))

export const testCreateWithholdingTaxRule = (context: Yup.TestContext): boolean =>
  context !== undefined && isArray(context) && context.length > 0

export const defaultValues = {
  name: '',
  description: undefined,
  taxRuleId: undefined,
  withholdTax: undefined,
  facts: [{ ...(initialFact as any) }],
  tierBandCalcMethod: undefined,
  taxTierBand: undefined,
  flatRate: undefined,
  effectiveDate: moment().add(1, 'days').toISOString(),
}

export const getNewRateEffectiveDate = (withholdingRule: WithholdingRule): string | undefined => {
  const effectiveDates =
    !!withholdingRule && !!withholdingRule.taxRules ? withholdingRule.taxRules.map((i) => i.effectiveDate) : []
  let dayOffset = 0
  let newDate
  do {
    dayOffset += 1
    newDate = moment().add(dayOffset, 'days').format('YYYY-MM-DD')
  } while (effectiveDates.includes(newDate) && dayOffset <= effectiveDates.length)
  return newDate
}
