import { FastFieldProps, useField } from 'formik'
import React, { useEffect, useState } from 'react'
import { Card, CircularProgress, Grid, Typography, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import UndoIcon from '@material-ui/icons/Undo'
import { find } from 'lodash'
import StatementSampleImage from './StatementSampleImage'
import toApiTemplate from './utils/toApiTemplate'
import toFormTemplate from './utils/toFormTemplate'
import ImageDialog from './PreviewTemplateDialog/ImageDialog'
import StatementActions from 'store/statement/actions'
import StatementSelectors from 'store/statement/selectors'
import { StatementActivity, StatementThumbnailNoImg } from 'assets'
import { ExtendedProduct, StatementType } from 'store/products/types'
import ChooseTemplateDialog from 'pages/features/Statements/ChooseTemplateDialog/ChooseTemplateDialog'
import { useDialogs } from 'components'
import { stopPropagation } from 'utils/form.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { Template } from 'store/statement/types'

const TemplateContainer = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 169px 1fr;
`
export const TryAgainTypography = styled(Typography)`
  color: ${(props: any) => `${props.theme.palette.secondary.main}`};
`

type OwnProps = FastFieldProps & {
  product: ExtendedProduct
  templateType: StatementType
  additionalContent: React.ReactNode
}

export default (props: OwnProps) => {
  const [field, , helpers] = useField(props.field.name)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const {
    isDialogOpen: isPreviewTemplateOpen,
    onOpenDialog: previewTemplateOpen,
    onCloseDialog: previewTemplateClose,
  } = useDialogs()

  const { productSegment } = props.product
  const { templateType, additionalContent } = props
  const getTemplates = () => dispatch(StatementActions.getTemplates({ productSegment, templateType }))

  useEffect(() => {
    getTemplates()
  }, [dispatch])

  const selectTemplates = useSelector(StatementSelectors.selectTemplates)
  const selectTemplatesError = useSelector(StatementSelectors.selectTemplatesError)
  const selectTemplatesLoading = useSelector(StatementSelectors.selectTemplatesLoading)
  const templates = selectTemplates[props.templateType] || []
  const statementErrors = selectTemplatesError?.[props.templateType]

  const [defaultTemplate, setDefaultTemplate] = useState<Template | null>(null)
  const [hasDefaultTemplate, setHasDefaultTemplate] = useState<boolean>(false)

  useEffect(() => {
    const defaultTemplateLookup = find(templates, { isDefault: true })
    setDefaultTemplate(defaultTemplateLookup)
    setHasDefaultTemplate(!!defaultTemplateLookup)
  }, [templates])
  const hasTemplate = !!field.value?.id
  const isUsingDefault = defaultTemplate?.templateGroupId === field.value?.id
  // is default when : no new image has been set and image in use is same as default

  const isLoading = !!selectTemplatesLoading
  const hasErrors = !selectTemplatesLoading && (statementErrors || !templates || templates.length === 0)
  const hasNoItems = !selectTemplatesLoading && !statementErrors && Array.isArray(templates) && templates.length === 0

  useEffect(() => {
    if (typeof field.value === 'undefined' && Array.isArray(templates) && !!defaultTemplate)
      helpers.setValue(toFormTemplate(defaultTemplate))
  }, [defaultTemplate])

  return (
    <span style={{ minHeight: 184, display: 'grid' }}>
      {hasErrors ? (
        <Grid container direction="column" justifyContent="center" alignItems="stretch">
          <Grid item style={{ textAlign: 'center' }}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item style={{ marginTop: 16, marginBottom: 0 }}>
                <img src={StatementActivity} alt="No Template" />
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ fontWeight: 500, marginTop: 8, marginBottom: 8 }}>
                  {hasNoItems ? t('No templates exist') : t("Oops, we couldn't retrieve the template")}
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 16 }}>
                {hasNoItems ? (
                  <Typography variant="body2">
                    {t(
                      'Templates can currently only be added by raising a Zendesk ticket. Statements must have a template to be valid.'
                    )}
                  </Typography>
                ) : (
                  <TryAgainTypography>
                    <Link
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        getTemplates()
                      }}
                      color="secondary"
                      href="#top"
                    >
                      {t('Try again')} <RefreshIcon style={{ verticalAlign: 'bottom' }} />
                    </Link>
                  </TryAgainTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        isLoading && (
          <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Grid item style={{ textAlign: 'center' }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                  <CircularProgress size={40} />
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ fontWeight: 500, marginTop: 12, marginBottom: 8 }}>
                    {t('Retrieving template')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{t('Please wait')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      )}
      {!isLoading && !hasErrors && (
        <Grid container direction="column" justifyContent="center" alignItems="stretch">
          <Grid item>
            <TemplateContainer>
              <Card>
                {hasTemplate || hasDefaultTemplate ? (
                  <StatementSampleImage
                    template={toApiTemplate(field.value)}
                    productSegment={props.product.productSegment}
                    templateType={templateType}
                    previewTemplateOpen={previewTemplateOpen}
                  />
                ) : (
                  <img width="100%" src={StatementThumbnailNoImg} alt="No preview available" />
                )}
              </Card>
              <div>
                <Button style={{ marginLeft: -8 }} onClick={() => setOpenDialog(true)} color={ColorType.NONE}>
                  <EditIcon style={{ verticalAlign: 'bottom', marginRight: 8 }} />
                  {isUsingDefault ? t('Choose your own') : t('Change Template')}
                </Button>
                {hasDefaultTemplate && !isUsingDefault && (
                  <div>
                    <Button
                      color={ColorType.NONE}
                      style={{ marginLeft: -8, marginBottom: 20 }}
                      onClick={() => defaultTemplate && helpers.setValue(toFormTemplate(defaultTemplate))}
                    >
                      <UndoIcon style={{ verticalAlign: 'bottom', marginRight: 8 }} />
                      {t('Use default')}
                    </Button>
                  </div>
                )}
                <Typography variant="subtitle2" style={{ marginTop: 16, fontSize: 12 }}>
                  {hasTemplate || hasDefaultTemplate
                    ? field.value?.name
                    : t('Oops, we couldn’t find a default template')}
                </Typography>
                <Typography variant="body2" style={{ fontSize: 12 }}>
                  {hasTemplate || hasDefaultTemplate
                    ? t(
                        isUsingDefault
                          ? 'Shown here is the default template design. \nIf you prefer you can choose a different template design for your product'
                          : 'Shown here is your chosen template design. \nIf you prefer you can use the default.'
                      )
                    : t('Don’t worry, you can choose a template using the add button.')}
                </Typography>
                {additionalContent}
              </div>
            </TemplateContainer>
          </Grid>
        </Grid>
      )}
      {openDialog && (
        <ChooseTemplateDialog
          open={openDialog}
          closeDialog={() => setOpenDialog(false)}
          productSegment={props.product?.productSegment}
          statementType={templateType}
          name={props.field.name}
        />
      )}
      {isPreviewTemplateOpen && (
        <ImageDialog
          title="Statement template"
          open={isPreviewTemplateOpen}
          template={field.value}
          onClose={previewTemplateClose}
          productSegment={props.product?.productSegment}
          templateType={templateType}
        />
      )}
    </span>
  )
}
