import { Grid, Paper, Typography } from '@material-ui/core'
import { Form, FormikProps, getIn } from 'formik'
import React, { ReactNode, useMemo, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { isEmpty, kebabCase, mapValues, every, capitalize } from 'lodash'
import { TFunction } from 'i18next'
// eslint-disable-next-line import/no-named-as-default
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { AdditionSpacing } from '../Styled'
import CustomDocument from './CustomDocument'
import AemDocumentView from './AemDocumentView'
import {
  ConfirmModalDeprecated,
  FormBottomBar,
  FormAddFeatureButton,
  SectionArray,
  ZeroStateLandingPage,
} from 'components'
import { Document, DocumentsValues, MultipleChoiceElement, Origin, ProductKey } from 'store/products/types'
import { stopPropagation, withFormikSimple } from 'utils/form.utils'
import { DeleteInfo, NoDocuments } from 'assets'
import { DeleteIconButton, Accordion, Body1 } from 'dls'
import { featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  defaultValues?: {
    customDocumentsVal?: Partial<Document>
  }
  remoteDocuments?: Document[]
  // eslint-disable-next-line react/no-unused-prop-types
  hasFeature?: boolean
  productKey?: ProductKey
}

interface Props {
  remove: (a: number) => void
  name: string
  index: number
  title: string
}

interface DocumentsFormProps extends FormikProps<DocumentsValues>, OwnProps {}

const createRemoteDocumentElements = (documents: Document[], t: TFunction): { [key: string]: MultipleChoiceElement } =>
  documents.reduce<any>(
    (acc, remoteDocument) =>
      kebabCase(remoteDocument.type) !== kebabCase(t('termsAndConditions'))
        ? [
            ...acc,
            // assertion because document types are different between services, this one SHOULD always give name
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {
              defaultValue: { ...remoteDocument, origin: Origin.AEM },
              multiple: false,
              name: 'existingDocuments',
              identifier: remoteDocument.identifier,
              identifierOriginal: remoteDocument.identifierOriginal,
              itemName: remoteDocument.name,
              disable: remoteDocument.name,
              label: `${remoteDocument.title || remoteDocument.name} v${remoteDocument.version}`,
            },
          ]
        : acc,

    []
  )

const DocumentsForm = ({ values, defaultValues = {}, remoteDocuments = [], productKey = '' }: DocumentsFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const remoteElements = useMemo(() => createRemoteDocumentElements(remoteDocuments, t), [remoteDocuments])
  const customElementsProps = {
    defaultValue: defaultValues.customDocumentsVal,
    multiple: true,
    name: 'existingDocuments',
    label: t('customDocuments'),
  }
  const elementsList =
    remoteDocuments.length === 0
      ? {
          // if AEM not availble, use custom props
          existingDocuments: customElementsProps,
        }
      : {
          existingDocuments: remoteElements,
          customDocuments: customElementsProps,
        }

  const [openConfirm, setOpenConfirm] = useState<number | null>(null)

  const getDeleteConfirm = ({ remove, name, index, title }: Props): ReactNode => (
    <ConfirmModalDeprecated
      id={`delete-card-${name}-${index}`}
      key={`delete-card-${name}-${index}`}
      onCancelClick={() => {
        setOpenConfirm(null)
      }}
      open={openConfirm === index}
      onConfirmClick={{
        action: () => {
          setOpenConfirm(null)
          remove(index)
        },
        title: t('Ok, got it'),
      }}
      title={<>{t(`${title} will only be deleted when you submit the form`)}</>}
      image={{ src: DeleteInfo, alt: t('Delete Icon') }}
      imageStyle={{ marginBottom: '24px' }}
      cancelText="Cancel"
    />
  )

  const renderDocument = (): ReactNode | null => {
    const remoteElementKey = 'existingDocuments'
    return (
      <SectionArray key={remoteElementKey} name={remoteElementKey}>
        {(props) => {
          const { remove, name, index } = props
          const item = getIn(values, name)
          const description = getIn(values, `${name}.description`)
          const version = getIn(values, `${name}.version`)
          const files = getIn(values, `${name}.files`)
          const origin = getIn(values, `${name}.origin`)
          let title = `${capitalize(`${item.name || ''}`)}`
          let keyName = kebabCase(item.name)
          if (origin === Origin.CUSTOM) {
            title = title || t('New document (from URL)')
            keyName = `${values.existingDocuments.length}`
          } else if (origin !== Origin.CUSTOM && origin !== Origin.AEM) {
            return null
          }

          return (
            <div key={`AEMorCustomDocuments-${keyName}-${index}`} style={{ marginBottom: 16 }}>
              {openConfirm === index && getDeleteConfirm({ remove, name, index, title })}
              <Accordion
                title={<Body1>{title}</Body1>}
                headerIcon={featureIconsCreator(featureSVGIcons.documents)}
                headerButtonGroup={
                  <DeleteIconButton
                    onClick={(e: React.MouseEvent) => {
                      stopPropagation(e)
                      setOpenConfirm(index)
                    }}
                  />
                }
                defaultExpanded={
                  (!description || origin === Origin.AEM) && index === values.existingDocuments?.length - 1
                }
              >
                <AdditionSpacing>
                  {origin === Origin.AEM ? (
                    <AemDocumentView files={files} description={description} version={version} />
                  ) : (
                    <CustomDocument name={`${name}`} />
                  )}
                </AdditionSpacing>
              </Accordion>
            </div>
          )
        }}
      </SectionArray>
    )
  }

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Documents')}
      description={t(
        'You can either choose documents from Adobe Experience Manager (AEM) or you can add documents from a URL. '
      )}
      rightArea={
        <FormAddFeatureButton
          addLabel={t('Add a document')}
          // This props create dropdown
          elements={elementsList as any}
        />
      }
    >
      <Form>
        {!every(values, (value) => isEmpty(value)) ? (
          renderDocument()
        ) : (
          <Paper style={{ paddingTop: 40, marginBottom: 15 }}>
            <ZeroStateLandingPage
              image={NoDocuments}
              title="No documents have been added"
              fixHeight={false}
              imageAlt="Image of a lady with Terms and conditions document"
            >
              <Typography variant="body2" style={{ marginBottom: 40, marginTop: -10 }}>
                {t('Select “Add documents" below to get started')}
              </Typography>
            </ZeroStateLandingPage>
          </Paper>
        )}
        <Grid item xs={12}>
          <FormBottomBar position="bottom">
            <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
              {t('Cancel')}
            </Button>
          </FormBottomBar>
        </Grid>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, DocumentsValues>(DocumentsForm, {
  validationSchema: Yup.lazy<object>((obj) =>
    Yup.object(
      mapValues(obj, () =>
        Yup.array(
          Yup.object({
            name: Yup.string().required('This is a required field'),
            description: Yup.string().required('This is a required field'),
            version: Yup.string().required('This is a required field').min(1).max(200),
            files: Yup.array(
              Yup.object({
                format: Yup.mixed().required('This is a required field'),
                url: Yup.string().url('This must be valid URL').required('This is a required field'),
                label: Yup.string().required('This is a required field'),
              })
            ),
          })
        )
      )
    )
  ),
  isInitialValid: (props) => !!props.hasFeature,
})
