import React from 'react'
import { Grid, Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Control, Controller } from 'react-hook-form'
import ConditionList from './components/ConditionList'
import { OrderedListItem } from './components/styled'
import { ColorType } from 'dls/shared/types'
import { BuilderCondition } from 'store/products/typings/conditionBuilder'
import { Button } from 'dls/atoms'
import { Input } from 'dls/molecules/Input'
import { AddCircleIcon } from 'dls/atoms/Icons/MUIIcons'
import { FeeRewardFeatureType } from 'store/products/types'

const BorderedGrid = styled(Grid)`
  border: 1px ${({ theme }) => theme.palette.primary.greyLight} solid;
  background-color: ${({ theme }) => theme.palette.background.paper};
`

const ConditionsGrid = styled(Grid)`
  ${({ theme }) => `${theme.breakpoints.up('sm')} {
  border-right: 1px ${theme.palette.primary.greyLight} solid;
}`}
`

const FeeGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.main};
  ${({ theme }) => `${theme.breakpoints.down('xs')} {
    border-top: 1px ${theme.palette.primary.greyLight} solid;
  }`}
`

const OrderedList = styled(Box)`
  margin: 0;
  padding: 0;
  width: 100%;
`

const ResponsiveInput = styled(Input)`
  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    max-width: 170px;
  }`}
`

interface Props {
  conditions: BuilderCondition[]
  onConditionChange: (index?: number) => void
  onConditionDelete: (index: number) => void
  currencyCode: string
  control: Control
  disable: boolean
  featureType: FeeRewardFeatureType
}

const ConditionBuilder = ({
  conditions,
  onConditionChange,
  onConditionDelete,
  currencyCode,
  control,
  disable,
  featureType,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <BorderedGrid container direction="row">
      <ConditionsGrid container item xs={12} sm={9}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent={conditions.length ? 'flex-start' : 'center'}
        >
          <OrderedList component="ol" flexDirection="column" justifyContent="flex-start">
            <ConditionList
              conditions={conditions}
              onConditionDelete={onConditionDelete}
              onConditionChange={onConditionChange}
            />
            <OrderedListItem component="li" display="flex" flexDirection="row" alignItems="center">
              <Button
                startIcon={<AddCircleIcon />}
                color={ColorType.SECONDARY}
                css="max-width: 300px;"
                onClick={() => onConditionChange()}
                ariaProps={{
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'condition-dialog',
                }}
                disabled={disable}
              >
                {t(conditions.length ? 'And if...' : `Add conditions (${disable ? 'coming soon' : 'optional'})`)}
              </Button>
            </OrderedListItem>
          </OrderedList>
        </Box>
      </ConditionsGrid>
      <FeeGrid item xs={12} sm={3}>
        <Box m={3} display="flex" flexDirection="column">
          <Controller
            name="amount"
            control={control}
            render={({ field, fieldState }) => (
              <ResponsiveInput
                id="amount"
                type="currency"
                label={t(`PRODUCT_FEATURE.${featureType}.ENTITY_IS`)}
                required
                currencyCode={currencyCode}
                error={fieldState?.invalid}
                errorText={fieldState.error?.message}
                {...field}
                positiveIntegersOnly
                numberFormatProps={{
                  decimalScale: 2,
                }}
                InputLabelProps={{
                  style: {
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            )}
          />
        </Box>
      </FeeGrid>
    </BorderedGrid>
  )
}

export default ConditionBuilder
