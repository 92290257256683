import { createAction } from '@reduxjs/toolkit'
import { NotificationTypes, TaskSuccessFailMessage } from './types'
import { Colleague, Team } from 'store/user/types'
import { ExtendedProduct } from 'store/products/types'
import { Process } from 'store/governance/types'
import { TaskDefinitionFormValue } from 'store/tasks/types'

const actions = {
  createProductSuccess: createAction<ExtendedProduct>('PRODUCTS/CREATE/SUCCESS'),
  createProductFail: createAction<Error>('PRODUCTS/CREATE/FAIL'),

  createUserSuccess: createAction<Colleague>('USERS/CREATE/SUCCESS'),
  createUserFail: createAction<Error>('USERS/CREATE/FAIL'),

  editProcessSuccess: createAction<Process>('PROCESS/EDIT/SUCCESS'),
  editProcessFail: createAction<Error>('PROCESS/EDIT/FAIL'),

  createTaskSuccess: createAction<TaskDefinitionFormValue>('TASKDEFINITIONS/CREATE/ASSIGNEES/SUCCESS'),
  createTaskFail: createAction<Error>('TASKDEFINITIONS/CREATE/ASSIGNEES/FAIL'),

  updateTaskSuccess: createAction<TaskDefinitionFormValue>('TASKDEFINITIONS/UPDATE/ASSIGNEES/SUCCESS'),
  updateTaskFail: createAction<Error>('TASKDEFINITIONS/UPDATE/ASSIGNEES/FAIL'),

  deleteProductSuccess: createAction<string>('PRODUCTS/DELETE/SUCCESS'),
  deleteProductFail: createAction<string>('PRODUCTS/DELETE/FAIL'),

  deleteFeatureSuccess: createAction<ExtendedProduct>('FEATURE/DELETE/SUCCESS'),
  deleteFeatureFail: createAction<Error>('FEATURE/DELETE/FAIL'),

  assignTaskSuccess: createAction<TaskSuccessFailMessage>('TASK/ASSIGN/SUCCESS'),
  assignTaskFail: createAction<TaskSuccessFailMessage>('TASK/ASSIGN/FAIL'),

  assignTasksSuccess: createAction('TASK/ASSIGNS/SUCCESS'),
  assignTasksFail: createAction<Error>('TASK/ASSIGNS/FAIL'),

  closeNotification: createAction<NotificationTypes>('CLOSE/NOTIFICATION'),

  sendProductToReviewSuccess: createAction('PRODUCT/SEND/REVIEW/SUCCESS'),
  sendProductToReviewFail: createAction<Error>('PRODUCT/SEND/REVIEW/FAIL'),

  createTeamSuccess: createAction<Team>('TEAMS/CREATE/SUCCESS'),
  createTeamFail: createAction<Error>('TEAMS/CREATE/FAIL'),

  retireProductSuccess: createAction<string>('PRODUCTS/RETIRE/SUCCESS'),
  retireProductFail: createAction<string>('PRODUCTS/RETIRE/FAIL'),

  getSuspenseTransactionsSuccess: createAction('SUSPENSENOTIF/TRANSACTIONS/GET/SUCCESS'),
  getSuspenseTransactionsFail: createAction<Error>('SUSPENSENOTIF/TRANSACTIONS/GET/FAIL'),
}
export default actions
