import React, { useState } from 'react'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import { KeyboardArrowDown, AddCircle, RemoveRedEye, Delete } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import useTaxRule from '../../hooks/useTaxRule'
import { EntityBadgeHistory } from 'dls/atoms'
import AccessibleMenu, { AccessibleMenuItem } from 'components/AccessibleMenu/AccessibleMenu'
import { WithholdingRule } from 'store/withholdingTax/types'
import { stopPropagation } from 'utils/form.utils'
import { Button, ButtonCustomProps } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  rule: WithholdingRule
  noArrowDown?: boolean
  buttonChildren: React.ReactNode
  buttonProps?: ButtonCustomProps
}

const WithholdingTaxCardActions = ({ rule, buttonProps, noArrowDown = false, buttonChildren }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentDate = moment()
  const { deleteRule } = useTaxRule()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const id = `withholding-tax-menu-${rule.ruleId}`

  function openMenu(e: React.MouseEvent<HTMLButtonElement>) {
    stopPropagation(e)
    setAnchorEl(e.currentTarget)
  }

  function closeMenu(e: React.MouseEvent) {
    stopPropagation(e)
    setAnchorEl(null)
  }

  function onClick(e: React.MouseEvent<Element>, url?: string | number) {
    e.preventDefault()
    setTimeout(() => navigate(`/reference-data/withholding-tax/${rule.ruleId}${url || ''}`))
  }

  const historicalDateRate = rule?.taxRules?.filter((item) =>
    item.effectiveDate ? moment(item.effectiveDate).isSameOrBefore(currentDate) : ''
  )

  const [firstRule] = rule.taxRules || []
  const isRuleLive = moment(firstRule?.effectiveDate).isSameOrBefore(moment(), 'day')

  const menuItems: AccessibleMenuItem[] = []

  if (rule) {
    menuItems.push({
      onClick,
      testId: 'withholding-tax-view-button',
      ariaControls: 'main',
      children: (
        <>
          <ListItemIcon>
            <RemoveRedEye style={{ marginRight: 8 }} />
          </ListItemIcon>
          <ListItemText primary={t('View')} />
        </>
      ),
    })
  }

  if (!isRuleLive && firstRule?.withholdTax) {
    menuItems.push({
      onClick: (e) => onClick(e, '/add'),
      testId: 'withholding-tax-add-button',
      ariaControls: 'main',
      children: (
        <>
          <ListItemIcon>
            <AddCircle style={{ marginRight: 8 }} />
          </ListItemIcon>
          <ListItemText primary={t('Add future rate')} />
        </>
      ),
    })
  }

  if (historicalDateRate?.length > 0) {
    menuItems.push({
      onClick: (e) => onClick(e, '/history'),
      testId: 'withholding-tax-historic-rate-button',
      ariaControls: 'main',
      children: (
        <>
          <ListItemIcon>
            <EntityBadgeHistory style={{ verticalAlign: 'bottom' }} />
          </ListItemIcon>
          <ListItemText primary={t('View historic rates')} />
        </>
      ),
    })
  }

  menuItems.push({
    onClick: () => deleteRule(rule),
    testId: 'withholding-tax-delete-button',
    ariaControls: 'confirmation-dialog',
    ariaHaspopup: 'dialog',
    children: (
      <>
        <ListItemIcon>
          <Delete style={{ marginRight: 8 }} />
        </ListItemIcon>
        <ListItemText primary={t('Delete')} />
      </>
    ),
  })

  const menuButton = (
    <Button
      color={ColorType.NONE}
      size="small"
      onClick={openMenu}
      {...buttonProps}
      aria-label="More actions"
      aria-controls={`withholding-tax-menu-${rule.ruleId}`}
      aria-haspopup="true"
    >
      {buttonChildren}
      {!noArrowDown && <KeyboardArrowDown />}
    </Button>
  )

  return (
    <>
      {menuItems.length > 0 ? (
        <AccessibleMenu
          id={id}
          anchorEl={anchorEl as any}
          menuItems={menuItems}
          menuButton={menuButton}
          onClose={closeMenu}
        />
      ) : (
        <Button color={ColorType.BUTTON} size="small" onClick={onClick} style={{ width: 91 }}>
          {t('View')}
        </Button>
      )}
    </>
  )
}

export default WithholdingTaxCardActions
