export type BankIndexKey = string | undefined

export interface BankInterestRateState {
  loading: boolean
  isUpdating?: boolean
  error?: Error
  bankInterestRate?: BankInterestRate[]
  selectedIndexRate?: BankInterestRate
}

export interface GetBankInterestRate {
  bankIndices: BankInterestRate[]
}

export interface BankInterestRate {
  abbreviation: string
  bankIndexKey: BankIndexKey
  name: string
  rates: InterestRate[]
}

export interface CreateBankInterestRateResult {
  body?: any
  statusCode?: string
  statusCodeValue?: number
  code?: string
  field?: string
  message?: string
}

export interface BankInterestRateFormValues {
  abbreviation?: string
  bankIndexKey?: string
  name?: string
  rates: InterestRateForm[]
}

export interface InterestRateForm {
  createdBy?: string
  createdTimestamp?: string | null
  effectiveDate?: string | null
  indexRate?: number
  rateKey?: string
}

export interface InterestRate {
  createdBy?: string
  createdTimestamp?: string | null
  effectiveDate?: Date | string | null
  indexRate?: number
  rateKey?: string
}

export interface EditOrAddIndexRate {
  editOrAddIndexRate: Partial<BankInterestRate>
}

export interface SelectedRateAndBankName {
  bankName?: string
  rate: number
}

export enum BankInterestRateStatus {
  LIVE = 'Live',
  SCHEDULED = 'Scheduled',
}
