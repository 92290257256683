import { ThemeOptions } from '@material-ui/core/styles'
import { InternalClientCode } from '../../static/transactionRules'
import { CardScheme } from 'store/products/types'

export interface Theme {
  palette: Partial<Palette>
}

export interface TenantConfigState {
  loading: boolean
  error?: Error
  data?: TenantConfig
}

export interface Primary {
  light: string
  main: string
  dark: string
  contrastText: string
}

interface Secondary {
  light: string
  main: string
  dark: string
  contrastText: string
}

interface ErrorTheme {
  light: string
  main: string
  dark: string
  contrastText: string
}

interface Background {
  paper: string
  default: string
}

interface Text {
  disabled: string
  hint: string
  primary: string
  secondary: string
}

export interface Palette {
  type: string
  primary: Primary
  secondary: Secondary
  error: ErrorTheme
  background: Background
  text: Text
  divider: string
  tonalOffset: number
  design: Primary
  scheduled: Primary
  live: Primary
  updated: Primary
  review: Primary
  approved: Primary
  rejected: Primary
  retired: Primary
  edit: Primary
  closed: Primary
  success: Primary
  info: Primary
  warning: Primary
}

export interface ColorPalette {
  palette: Palette
}

interface NotificationDuration {
  short: number
  long: number
  value: string
}

export interface Assets {
  logoURL: string
  logoCollapsedURL?: string
  timeout: number
  notificationDuration: NotificationDuration
}

export interface ApiSettings {
  apiKey: string
  contentPath: string
}

export interface AuthSettings {
  ssoUrl: string
  oAuthUrl: string
}

export enum LogoType {
  NORMAL = 'normal',
  COLLAPSE = 'collapsed',
  PRINT = 'print',
  CONTENT = 'content',
}

export interface Logo {
  [key: string]: string
}

interface Localisation {
  language: string
  countryCode?: string
  currencyCode?: string
  currencySymbol?: string
  timezone?: string
}

export interface Tenant {
  apiKey: string
  name: string
  logo: Logo
  parent: boolean
  theme: ThemeOptions
  features?: {
    [key: string]: boolean | string
  }
  localisation?: Localisation
}

export interface TenantConfig {
  icc?: InternalClientCode
  theme: ThemeOptions
  assets: Assets
  apiSettings: ApiSettings
  authSettings?: AuthSettings
  features: {
    [key: string]: boolean | string
  }
  availableProductFeatures?: {
    [key: string]: boolean
  }
  editableFeaturesAfterLiveProduct?: string[]
  minProductRepublishDelay?: number
  environment: string
  clientConfig?: ClientConfig
  localisation: Localisation
  tenants?: Tenant[]
}

export interface ClientConfig {
  currency?: {
    country?: string[]
    currencyCode?: string[]
  }
  cards?: {
    scheme?: CardScheme[]
  }
  limits?: {
    transactionLimits?: boolean
    schemeLimits?: boolean
    accountBalanceLimits?: boolean
    productLimits?: boolean
    subscriptionLimit?: boolean
  }
}
