import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { FeaturePostResponse, PaymentSchedule, ProductKey } from 'store/products/types'
import { hashObject } from 'utils/api.utils'

async function setPaymentSchedule(productKey: ProductKey, version: number, payload: PaymentSchedule) {
  const hash = hashObject('paymentSchedule', payload)
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).put<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/paymentSchedule/v1`,
    payload,
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )
  return data
}

async function fetchPaymentSchedule(productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).get(
    `/v1/products/${productKey}/versions/${version || 1}/features/paymentSchedule/v1`
  )
  return data
}

async function deletePaymentSchedule(productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).delete(
    `/v1/products/${productKey}/versions/${version || 1}/features/paymentSchedule/v1`
  )
  return data
}

export default {
  setPaymentSchedule,
  fetchPaymentSchedule,
  deletePaymentSchedule,
}
