import styled from 'styled-components/macro'

export const FeatureDefinitionList = styled.dl`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 0;
`

export const DefinitionRow = styled.div<{ hasSectionSeparator?: boolean; theme: any }>`
  display: flex;
  ${({ hasSectionSeparator, theme }) =>
    `${
      hasSectionSeparator
        ? `border-bottom: 1px solid ${theme.palette.text.secondary}; padding-bottom: 16px; margin-bottom: 8px;`
        : ''
    }
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`

export const DefinitionTerm = styled.dt`
  min-width: 30%;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      margin-bottom: 11px;
      color: ${theme.palette.text.secondary}
    }
    `}
`

export const Definition = styled.dd`
  margin: 0;
  flex: 1;
`
