import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import Product from './Product'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import BreadcrumbsActions from 'store/breadcrumbs/actions'
import ProductsActions from 'store/products/actions'
import { DeleteFeaturePayload, Feature, ProductKey, UpdateProductFormValues } from 'store/products/types'
import { RootState } from 'store'
import { selectConfig } from 'store/tenant-config/selectors'
import { getCurrencyOfProduct } from 'api/utils'
import * as UserSelector from 'store/user/selectors'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useProductGovernance from 'pages/Product/hooks/useProductGovernance'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editProduct: (productKey: ProductKey, editProductFormValue: UpdateProductFormValues) =>
    dispatch(
      ProductsActions.editProductDetails({
        ...editProductFormValue,
        productKey,
      })
    ),
  deleteFeature: (deleteFeaturePayload: DeleteFeaturePayload) => {
    switch (deleteFeaturePayload.featureName) {
      case Feature.PAYMENT_SCHEDULE:
        dispatch(
          ProductsActions.deletePaymentSchedule({
            productKey: deleteFeaturePayload.productKey,
          })
        )
        break
      case Feature.MULTI_PARTY:
        dispatch(
          ProductsActions.deleteMultiParty({
            productKey: deleteFeaturePayload.productKey,
            version: deleteFeaturePayload.productVersion,
          })
        )
        break
      case Feature.DEBIT_INTEREST:
        dispatch(
          ProductsActions.deleteDebitInterestFeature({
            productKey: deleteFeaturePayload.productKey,
            version: deleteFeaturePayload.productVersion,
          })
        )
        break
      case Feature.CREDIT_LIMIT:
        dispatch(
          ProductsActions.deleteCreditLimit({
            productKey: deleteFeaturePayload.productKey,
            version: deleteFeaturePayload.productVersion,
          })
        )
        break
      default:
        dispatch(ProductsActions.deleteFeature(deleteFeaturePayload))
        break
    }
  },
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
  selectSelectedTenantIndex: UserSelector.selectSelectedTenantIndex(state),
  bankInterestRates: ReferenceDataSelectors.selectAllBankInterestRate(state),
  currentTenant: TenantConfigSelectors.selectCurrentTenant(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

export const ProductPage = ({
  editProduct,
  deleteFeature,
  tenantConfig,
  currentTenant,
  selectSelectedTenantIndex,
  bankInterestRates,
}: Props) => {
  const { productKey } = useParams()
  const dispatch = useDispatch()
  if (!productKey) throw new Error('productKey not found')

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const [product, isLoading] = useSelectedProduct(productKey)
  useProductGovernance({ product, isLoading, tenantConfig })

  const onClickCancel = useCallback(() => setOpen(false), [setOpen])
  const onClickSubmit = useCallback(
    (payload: UpdateProductFormValues) => {
      editProduct(productKey, payload)
      setOpen(false)
    },
    [productKey, editProduct]
  )

  useEffect(() => {
    if (!!product && product.productKey === productKey) {
      dispatch(
        BreadcrumbsActions.updateBreadCrumbs([
          { title: t('{{tenantName}} products', { tenantName: currentTenant?.name }), path: `/products/` },
          { title: product.productName, path: '' },
        ])
      )
    }
  }, [product, t, productKey])

  if (isLoading) {
    return <Loader />
  }

  if (!product || product.productKey !== productKey) {
    // Stop rendering previous product
    return null
  }
  return (
    <Product
      product={product}
      open={open}
      editProduct={onClickSubmit}
      onClickCancel={onClickCancel}
      onDeleteFeature={deleteFeature}
      currencyOfProduct={getCurrencyOfProduct(product)}
      selectSelectedTenantIndex={selectSelectedTenantIndex}
      bankIndexRates={bankInterestRates}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)
