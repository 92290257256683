import { Moment } from 'moment-timezone'
import { CountryIncluded, ExtendedProduct, Product, ProductSegment } from 'store/products/types'
import { ProductKey } from 'store/contents/types'
import { Notification } from 'store/notifications/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'

export type ProductPackageId = string
export type Order = 'asc' | 'desc'

export type ProductPackageName = string
export type BrandId = string

export type IsPublishPackage = boolean

export interface PackageState {
  loading: boolean
  error?: Error
  data: PackagesWithProducts[]
  selectedPackage?: PackagesWithProducts
  availableProducts: AvailableProducts[] | undefined
  notification?: Notification
  productPackageName: ProductPackageName
  packageType?: string
  countryOfResidence?: CountryIncluded
  brandId?: BrandId
  isPublished?: boolean
  publishing: boolean
  publishSuccess: boolean
  publishError?: Error
}

export interface Distribution {
  brandId?: string
  countryOfResidence?: CountryIncluded
  packageType: string
  phoneCountryCode?: string
  stateOfResidence?: string
}

export enum PackageType {
  ACCOUNTS = 'ACCOUNTS',
  // BILLSPOOL = 'BILLSPOOL',
  BUSINESSACCOUNTS = 'BUSINESSACCOUNTS',
  BUSINESSACCOUNTSEXISTINGCUSTOMERS = 'BUSINESSACCOUNTSEXISTINGCUSTOMERS',
  // CURRENTACCOUNT = 'CURRENTACCOUNT',
  // CURRENTPOOL = 'CURRENTPOOL',
  DEFAULTONBOARDING = 'DEFAULTONBOARDING',
  OTHER = 'OTHER',
  // OVERDRAFT = 'OVERDRAFT',
  PERSONALACCOUNTS = 'PERSONALACCOUNTS',
  PERSONALACCOUNTSEXISTINGCUSTOMERS = 'PERSONALACCOUNTSEXISTINGCUSTOMERS',
  // SAVINGSPOOL = 'SAVINGSPOOL',
}

export interface Packages {
  packages: PackagesWithProducts[]
}

export interface PackagesWithProducts {
  createdBy: string
  createdDate: string
  distribution?: Distribution
  packageStatus: PackagesStatus
  packageType?: string
  productPackageId: ProductPackageId
  productPackageName: string
  productSegment?: ProductSegment
  products: ExtendedProduct[]
  publicationDate?: string
  publishedBy?: string | null
  publishedDate?: string | null
  tenantKey?: string | null
  updatedBy?: string | null
  updatedDate?: string | null
}

export interface PackagesWithProductsFormValue {
  createdBy?: string
  createdDate?: string
  distribution?: Distribution
  packageStatus?: PackagesStatus
  packageType?: string
  productPackageId?: ProductPackageId
  productPackageName: string
  productSegment?: ProductSegment
  products: ProductKey[]
  nonJoinedProducts?: ProductKey[]
  publicationDate?: string | null
  publishedBy?: string | null
  publishedDate?: string | null
  tenantKey?: string | null
  updatedBy?: string | null
  updatedDate?: string | null
}

export interface PackageFormValues {
  productPackageName: string
  distribution: Distribution
}

export interface PackageCreationOrUpdateRequestPayload {
  distribution?: Distribution
  productPackageName: ProductPackageName
  products: ProductKey[]
}

export interface PublishPackageRequest {
  publicationDate: Moment
  productPackageId: ProductPackageId
}

export interface AvailableProducts extends Product {
  tenantKey: string | null
  versions?: Product[]
}

export interface PublishPackageFormValues {
  publicationDate: Moment
}
