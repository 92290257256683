import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { kebabCase } from 'lodash'
import styled from 'styled-components/macro'
import ProductsSelectors from 'store/products/selectors'
import { BackButton, Loader, TemplateFeatureForm, Body2, H1, NoActivityHelpPanel } from 'components'
import { ProductKey } from 'store/products/types'
import ContentContainer from 'pages/Layout/ContentContainer'

interface Props {
  productKey: ProductKey
  children: React.ReactNode
  rightArea?: React.ReactNode
  title: string
  description?: string
  descriptionContent?: React.ReactNode
  onPressBackOveride?: any
}

export const FormNoPaper = styled.div`
  padding: 0;
  margin-top: 16px;
  margin-bottom: 24px;
`

export const FeaturePageWithTopAddLayout = (props: Props) => {
  const { productKey, children, rightArea, title, description, descriptionContent, onPressBackOveride } = props
  const updating = useSelector(ProductsSelectors.selectUpdating)
  if (!productKey) throw new Error('productKey not found')

  const navigate = useNavigate()
  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <ContentContainer>
      <TemplateFeatureForm
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={onPressBackOveride || onPressBack} aria-controls="main" />
              </Grid>
              {rightArea && <Grid item>{rightArea}</Grid>}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '0px 1px 0px' }}
            >
              {title && (
                <Grid item>
                  <H1 gutterBottom>{title}</H1>
                </Grid>
              )}
            </Grid>
            {description && (
              <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
                {description}
              </Body2>
            )}
            {descriptionContent}
          </>
        }
        main={
          updating ? (
            <Loader />
          ) : (
            <ContentContainer data-test-id={kebabCase(`${title}-form-container`)}>{children as any}</ContentContainer>
          )
        }
        aside={<NoActivityHelpPanel />}
      />
    </ContentContainer>
  )
}

export default FeaturePageWithTopAddLayout
