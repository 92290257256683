import React, { useMemo, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { convertPaymentLimitsConfigToFormValues } from '../../utilities/transformers'
import usePaymentLimits from '../../utilities/usePaymentLimits'
import { Paper } from 'dls'
import { BackButton, TemplateSubReview, H1, Body2, Loader, SelectFieldSimple, NoActivityHelpPanel } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Feature, CalendarMonth } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import RadioGroupFieldSimple from 'components/form/RadioGroupFieldSimple'
import { PaymentLimitsConfigYearType } from 'store/products/typings/paymentLimits'
import { Flexbox } from 'pages/Layout/Styled'
import { calculateDaysInMonth } from 'utils/date.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { SelectItem } from 'components/form/SelectFieldSimple'

const UpdatePaymentLimitYearDefinitionPage = () => {
  const { t } = useTranslation()
  const { productKey = '' } = useParams()
  const navigate = useNavigate()
  const [product, isLoading] = useSelectedProduct(productKey)
  const { submitPaymentLimitsConfig } = usePaymentLimits({ productKey, version: product?.version })
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'onChange' })

  const yearType = watch('yearType')
  const month = watch('startMonth')

  useUpdateBreadcrumbs({ productKey, context: Feature.PAYMENT_LIMITS, subcontext: t('Year definition') })

  const onPressBack = () => {
    navigate(`/products/${productKey}/features/payment-limits`)
  }

  useEffect(() => {
    if (yearType && yearType !== PaymentLimitsConfigYearType.TAX) {
      setValue('startMonth', '')
      setValue('startDay', '')
    }
  }, [yearType])

  const yearDefinitionOptions = useMemo(() => {
    const data = {}
    Object.values(PaymentLimitsConfigYearType).forEach((value: string) => {
      data[value] = {
        name: value,
        label: t(`PAYMENT_LIMITS.${value}`, { taxYearStart: '(you can define this)' }),
      }
    })

    return data
  }, [product])

  const defineOptions = (obj: Record<string, string>) => {
    const data: SelectItem[] = []
    Object.values(obj).forEach((value: string) => {
      data.push({
        value,
        label: t(`CALENDAR.${value}`),
      })
    })

    return data
  }

  const monthOptions = useMemo(() => defineOptions(CalendarMonth), [product])
  const dayOptions = useMemo(() => defineOptions(calculateDaysInMonth(month)), [product, month])

  if (isLoading || !product?.paymentLimits) {
    return <Loader />
  }

  const defaultValues = convertPaymentLimitsConfigToFormValues(product?.paymentLimits.config)

  return (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={onPressBack} aria-controls="main" />
              </Grid>
              <Grid item>
                <div style={{ gridArea: 'infoArea', textAlign: 'right' }} />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <H1 gutterBottom>{t('Year definition')}</H1>
              </Grid>
            </Grid>
            <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
              {t('Any payment limits set for ‘year’ will use the year definition you create here')}
            </Body2>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <Paper>
            <Body2 style={{ paddingBottom: 16 }}>{t('Please select a year definition *')}</Body2>
            <form onSubmit={handleSubmit(submitPaymentLimitsConfig)}>
              <Controller
                name="yearType"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues.yearType}
                render={({ field }) => <RadioGroupFieldSimple {...field} data={yearDefinitionOptions} name="" />}
              />
              {![PaymentLimitsConfigYearType.CALENDAR, PaymentLimitsConfigYearType.ANNIVERSARY].includes(yearType) && (
                <Grid container spacing={6} style={{ paddingTop: 40 }}>
                  <Grid item xs={3}>
                    <Controller
                      name="startMonth"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={defaultValues.startMonth}
                      render={({ field }) => (
                        <SelectFieldSimple
                          required
                          id="startMonth"
                          data-test-id="start-month"
                          label={t('Start month')}
                          {...field}
                          options={monthOptions}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="startDay"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={defaultValues.startDay}
                      render={({ field }) => (
                        <SelectFieldSimple
                          required
                          id="startDay"
                          data-test-id="start-day"
                          label={t('Start day')}
                          {...field}
                          options={dayOptions}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Flexbox direction="row" justifyContent="flex-end">
                <Button
                  color={ColorType.BUTTON}
                  onClick={onPressBack}
                  style={{
                    marginRight: 16,
                  }}
                  aria-controls="main"
                >
                  {t('Cancel')}
                </Button>
                <Button variant="contained" color={ColorType.BUTTON} type="submit" disabled={!isValid || !isDirty}>
                  {t('Submit')}
                </Button>
              </Flexbox>
            </form>
          </Paper>
        }
      />
    </ContentContainer>
  )
}

export default UpdatePaymentLimitYearDefinitionPage
