import styled from 'styled-components/macro'

export const FeatureDefinitionList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin: 0;
  word-break: break-word;
  // For subchild selection
  & DL DT {
    width: 50%;
  }
`

export const DefinitionRow = styled.div<{ alignItems?: string }>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${({ alignItems }) => `align-items:: ${alignItems || 'baseline'};`}
  column-gap: 8px;
`

export const DefinitionTerm = styled.dt<{ theme: any }>`
  width: 30%;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      color: ${theme.palette.text.secondary}
    }
    `};
`

export const Definition = styled.dd`
  margin: 0;
  flex: 1;
`
