import React from 'react'
import { Dialog, DialogContent, Fade, Paper, DialogActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Description, StyledButton } from '../styled'
import { AnimatedTick } from './AnimatedTick'
import { Flexbox } from 'pages/Layout/Styled'
import { H3, Body2 } from 'components'
import { ColorType } from 'dls/shared/types'
import useModal from 'components/Modal/hooks/useModal'

interface Props {
  open: boolean
  setOpen?: (open: boolean) => void
  title?: string
  description?: React.ReactNode
  buttonLabel?: string
  id?: string
}

const SuccessModal = ({ title, description, id, open, setOpen, buttonLabel }: Props) => {
  const { t } = useTranslation()
  const { confirmModalAction } = useModal()

  const confirmAction = () => {
    confirmModalAction()

    if (setOpen) {
      setOpen(false)
    }
  }

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={confirmAction}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogContent>
        <Flexbox direction="column" justifyContent="center" alignItems="center" p={40} pb={30}>
          <AnimatedTick />
          {title ? (
            <H3 data-test-id="confirm-dialog-title" id={id} style={{ marginTop: 16, textAlign: 'center' }}>
              {t(title)}
            </H3>
          ) : null}
          {description &&
            (typeof description === 'string' ? (
              <Body2
                variantMapping={{ body2: 'div' }}
                style={{ marginTop: 8, textAlign: 'center', whiteSpace: 'normal' }}
              >
                {t(description)}
              </Body2>
            ) : (
              <Description style={{ textAlign: 'center', whiteSpace: 'normal' }}>{description}</Description>
            ))}
        </Flexbox>
      </DialogContent>
      <DialogActions>
        <Flexbox direction="row" justifyContent="center" alignItems="center" width="100%" pb={40}>
          <StyledButton
            disabled={false}
            onClick={confirmAction}
            color={ColorType.BUTTON}
            variant="contained"
            data-test-id="dialog-done-button"
          >
            {buttonLabel || t('Done')}
          </StyledButton>
        </Flexbox>
      </DialogActions>
    </Dialog>
  )
}
export default SuccessModal
