import React from 'react'
import { Dialog, DialogContent, Fade, Typography, Paper, Grid, DialogActions } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { InfoOutlined } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  open: boolean
  setOpen?: (open: boolean) => void
}

const Title = styled(Typography)`
  margin-bottom: 24px;
`

const Description = styled(Typography)`
  margin-bottom: 16px;
`

const StyledButton = styled(Button)`
  margin-right: 24px;
  margin-bottom: 24px;
`

const TypographyGridContainer = styled(Typography)`
  text-align: center;
  padding-top: 48px;
  padding-bottom: 0px;
`

const StyledInfoOutlined = styled(InfoOutlined)`
  color: ${(props: any) => props.theme.palette.success.main};
  font-size: 74px;
`

const TaskRejectConfirmation = (props: OwnProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const gotToGovernance = () => {
    if (props.setOpen) {
      props.setOpen(false)
    }
    navigate(`/governance`)
  }
  return (
    <Dialog
      open={props.open}
      scroll="paper"
      onClose={() => props.setOpen && props.setOpen(false)}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogContent>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <TypographyGridContainer variantMapping={{ body1: 'div' }}>
            <StyledInfoOutlined />
            <Title variant="h5">{t('You’ll need to set-up governance before you can continue')}</Title>
            <Description variant="body2">
              {t(
                'Don’t worry you can do this in the app. You can do this in the governance section by create a process and adding tasks. Once set-up you’ll be able to continue here and assign the tasks to colleagues for them to approve'
              )}
            </Description>
          </TypographyGridContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <StyledButton disabled={false} onClick={() => props.setOpen && props.setOpen(false)} color={ColorType.BUTTON}>
          {t('Cancel')}
        </StyledButton>
        <StyledButton
          css={`
            width: 188px;
          `}
          disabled={false}
          onClick={gotToGovernance}
          color={ColorType.BUTTON}
          variant="contained"
          aria-controls="main"
        >
          {t('Set-up governance')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
export default TaskRejectConfirmation
