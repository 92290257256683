import React from 'react'
import { Dialog, DialogContent, Fade, Typography, Paper, Grid, DialogActions } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { UnableToConnectToTheServer as WrongImage } from 'assets'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  open?: boolean
  setOpen?: (open: boolean) => void
}

const Title = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 24px;
`

const Description = styled(Typography)`
  margin-bottom: 16px;
`
const StyledButton = styled(Button)`
  width: 110px;
  margin-right: 24px;
  margin-bottom: 24px;
`
const GrindContainer = styled(Typography)`
  text-align: center;
  padding-top: 64px;
  padding-bottom: 32px;
`
const ProcessExecutionFailedDialog = (props: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={props.open ? props.open : false}
      scroll="paper"
      onClose={() => props.setOpen && props.setOpen(false)}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogContent>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <GrindContainer variantMapping={{ body1: 'div' }}>
            <img src={WrongImage} alt={t('Oops, something went wrong')} />
            <Title variant="h5">{t('Oops, something went wrong preparing the product for approval')}</Title>
            <Description variant="body2">{t('tryAgain')}</Description>
          </GrindContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <StyledButton
          disabled={false}
          onClick={() => props.setOpen && props.setOpen(false)}
          color={ColorType.BUTTON}
          variant="contained"
        >
          {t('Go Back')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
export default ProcessExecutionFailedDialog
