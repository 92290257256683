import React, { useMemo } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ClearIcon from '@material-ui/icons/Clear'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { defineLimitType, convertPaymentLimitToFormValues, getLimitValue } from '../../utilities/transformers'
import usePaymentLimits from '../../utilities/usePaymentLimits'
import { Paper, Input } from 'dls'
import { BackButton, TemplateSubReview, H1, Body2, Loader, NoActivityHelpPanel } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Feature } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Flexbox } from 'pages/Layout/Styled'
import {
  PaymentLimitAmountType,
  PaymentLimitChannel,
  PaymentLimitDirection,
  PaymentLimitRange,
  PaymentLimitRoutingDestination,
} from 'store/products/typings/paymentLimits'
import CustomCheckbox from 'components/form/CustomCheckbox'
import { getQuerySearchParameter } from 'utils/url.utils'
import { LinkWithText } from 'dls/molecules/Buttons'
import {
  validateMinimum,
  validateMaximum,
  checkMinimumRequirement,
  validateLimits,
} from 'pages/features/PaymentLimits/utilities/validation'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const UpdatePaymentLimitPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { productKey = '', limitId } = useParams()

  const [product, isLoading] = useSelectedProduct(productKey)
  const { submitPaymentLimit, deletePaymentLimit } = usePaymentLimits({ productKey, version: product?.version })

  const editableLimit = useMemo(
    () => product?.paymentLimits?.paymentLimits?.find((limit) => limit.limitRuleId === limitId),
    [product, limitId]
  )

  const defaultValues = convertPaymentLimitToFormValues(editableLimit)

  const direction = (getQuerySearchParameter('direction') as PaymentLimitDirection) || editableLimit?.direction
  const channel = (getQuerySearchParameter('channel') as PaymentLimitChannel) || editableLimit?.channel
  const routingDestinations =
    (getQuerySearchParameter('routingDestinations')?.split(',') as PaymentLimitRoutingDestination[]) ||
    editableLimit?.routingDestinations
  const range = (getQuerySearchParameter('range') as PaymentLimitRange) || editableLimit?.range
  const amountType = (getQuerySearchParameter('amountType') as PaymentLimitAmountType) || editableLimit?.amount.type

  const validRoutingDestinatioForCustomerOverride = [
    PaymentLimitRoutingDestination.ON_US,
    PaymentLimitRoutingDestination.OUTBOUND_PAYMENT,
  ]

  const validRangeForCustomerOverride = [PaymentLimitRange.TRANSACTION, PaymentLimitRange.DAY]

  const paymentLimitType = defineLimitType({
    channel,
    direction,
    routingDestination: routingDestinations[0],
    range,
    amountType,
  })

  const day = getLimitValue(
    direction,
    channel,
    routingDestinations,
    PaymentLimitRange.DAY,
    amountType
  )(product?.paymentLimits?.paymentLimits || [])?.amount?.maximum

  const month = getLimitValue(
    direction,
    channel,
    routingDestinations,
    PaymentLimitRange.MONTH,
    amountType
  )(product?.paymentLimits?.paymentLimits || [])?.amount?.maximum

  const year = getLimitValue(
    direction,
    channel,
    routingDestinations,
    PaymentLimitRange.YEAR,
    amountType
  )(product?.paymentLimits?.paymentLimits || [])?.amount?.maximum

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onBlur' })

  const maximum = watch('maximum')
  const minimum = watch('minimum')

  useUpdateBreadcrumbs({
    productKey,
    context: Feature.PAYMENT_LIMITS,
    subcontext: t(paymentLimitType ? `PAYMENT_LIMITS.${paymentLimitType}` : ''),
  })

  const onPressBack = (): void => {
    navigate(`/products/${productKey}/features/payment-limits`)
  }

  if (isLoading || !product) {
    return <Loader />
  }

  if (!isLoading && !paymentLimitType) {
    navigate('/not-found')
  }

  const renderSubscriptionOverride = (): JSX.Element => (
    <>
      {validRoutingDestinatioForCustomerOverride.some((destination) => routingDestinations.includes(destination)) &&
        validRangeForCustomerOverride.includes(range) && (
          <Controller
            name="enableSubscriptionOverride"
            control={control}
            defaultValue={defaultValues.enableSubscriptionOverride}
            render={({ field }) => (
              <CustomCheckbox
                id="enableSubscriptionOverride"
                data-test-id="enable-subscription-override"
                checked={field.value}
                label={t('PAYMENT_LIMITS.CUSTOMER_OVERRIDE')}
                {...field}
              />
            )}
          />
        )}
    </>
  )

  const renderForm = (): JSX.Element | null => {
    if (range === PaymentLimitRange.TRANSACTION) {
      return (
        <Flexbox direction="column">
          <Flexbox direction="row" justifyContent="flex-start" pb={24}>
            <Controller
              name="minimum"
              control={control}
              defaultValue={defaultValues.minimum}
              rules={{ validate: validateMinimum(maximum), required: checkMinimumRequirement(maximum) }}
              render={({ field }) => (
                <Input
                  id="minimum"
                  type="currency"
                  label={t('Min amount')}
                  placeholder={t('Enter amount')}
                  style={{ width: 220 }}
                  positiveIntegersOnly
                  testId="minimum-amount"
                  currencyCode={product.currency?.currencyCode}
                  error={!!errors.minimum}
                  numberFormatProps={{ thousandSeparator: true }}
                  errorText={errors.minimum?.message}
                  {...field}
                />
              )}
            />
            <Box style={{ width: 16 }} />
            <Controller
              name="maximum"
              control={control}
              defaultValue={defaultValues.maximum}
              rules={{ validate: validateMaximum(minimum) }}
              render={({ field }) => (
                <Input
                  id="maximum"
                  type="currency"
                  label={t('Max amount')}
                  testId="maximum-amount"
                  placeholder={t('Enter amount')}
                  style={{ width: 220 }}
                  positiveIntegersOnly
                  currencyCode={product.currency?.currencyCode}
                  error={!!errors.maximum}
                  errorText={errors.maximum?.message}
                  numberFormatProps={{ thousandSeparator: true }}
                  {...field}
                />
              )}
            />
          </Flexbox>
          {renderSubscriptionOverride()}
        </Flexbox>
      )
    }

    switch (amountType) {
      case PaymentLimitAmountType.MONETARY:
        return (
          <Box display="flex" flexDirection="column" gridRowGap={24}>
            <Controller
              name="maximum"
              control={control}
              rules={{
                required: true,
                validate: { max: validateMaximum(minimum), limit: validateLimits(range, { day, month, year }) },
              }}
              defaultValue={defaultValues.maximum}
              render={({ field }) => (
                <Input
                  id="maximum"
                  type="currency"
                  label={t('PAYMENT_LIMITS.MAX_AMOUNT_LIMIT', { period: t(`PERIOD.${range}`) })}
                  placeholder={t('Enter amount')}
                  currencyCode={product.currency?.currencyCode}
                  style={{ width: 220 }}
                  numberFormatProps={{ thousandSeparator: true }}
                  positiveIntegersOnly
                  error={!!errors.maximum}
                  errorText={errors.maximum?.message}
                  {...field}
                />
              )}
            />
            {renderSubscriptionOverride()}
          </Box>
        )
      case PaymentLimitAmountType.VOLUME:
        return (
          <Controller
            name="maximum"
            control={control}
            rules={{
              required: true,
              validate: { max: validateMaximum(minimum), limit: validateLimits(range, { day, month, year }) },
            }}
            defaultValue={defaultValues.maximum}
            render={({ field }) => (
              <Input
                id="maximum"
                type="number"
                label={t(`PAYMENT_LIMITS.MAX_NUMBER`, { period: String(range).toLowerCase() })}
                placeholder={t('Enter number')}
                positiveIntegersOnly
                style={{ width: 220 }}
                numberFormatProps={{ thousandSeparator: true }}
                error={!!errors.maximum}
                errorText={errors.maximum?.message}
                {...field}
              />
            )}
          />
        )
      default:
        return null
    }
  }

  return (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={onPressBack} aria-controls="main" />
              </Grid>
              <Grid item>
                <div style={{ gridArea: 'infoArea', textAlign: 'right' }} />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <H1 gutterBottom>{t(`PAYMENT_LIMITS.${paymentLimitType}`)}</H1>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
              <Grid item>
                <Body2 variantMapping={{ body2: 'p' }} gutterBottom>{`${t('Direction')}: ${t(
                  `PAYMENT_LIMITS.${direction}`
                )}`}</Body2>
              </Grid>
              {routingDestinations && !routingDestinations.includes(PaymentLimitRoutingDestination.NOT_APPLICABLE) && (
                <Grid item>
                  <Body2 variantMapping={{ body2: 'p' }} gutterBottom>{`${t('Party')}: ${t(
                    `PAYMENT_LIMITS.${routingDestinations[0]}`
                  )}`}</Body2>
                </Grid>
              )}
            </Grid>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <Paper>
            <form
              onSubmit={handleSubmit((values) =>
                submitPaymentLimit({
                  ...values,
                  limitRuleId: defaultValues.limitRuleId,
                  limitName: t(`PAYMENT_LIMITS.${paymentLimitType}`),
                  direction,
                  channel,
                  range,
                  amountType,
                  routingDestinations,
                })
              )}
            >
              {renderForm()}
              <Flexbox
                direction="row"
                justifyContent={editableLimit ? 'space-between' : 'flex-end'}
                style={{ marginTop: 50 }}
              >
                {editableLimit && (
                  <Flexbox direction="row" alignItems="center">
                    <LinkWithText
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      startIcon={<ClearIcon />}
                      endIcon={null}
                      onClick={() =>
                        defaultValues.limitRuleId &&
                        deletePaymentLimit({
                          limitRuleId: defaultValues.limitRuleId,
                          limitName: t(`PAYMENT_LIMITS.${paymentLimitType}`),
                        })
                      }
                      style={{
                        backgroundColor: 'transparent',
                        fontSize: 'inherits',
                        margin: '0 3px',
                        padding: 0,
                      }}
                      variant="text"
                    >
                      {t('REMOVE THIS LIMIT')}
                    </LinkWithText>
                  </Flexbox>
                )}
                <div>
                  <Button
                    color={ColorType.BUTTON}
                    onClick={onPressBack}
                    style={{
                      marginRight: 16,
                    }}
                    aria-controls="main"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button variant="contained" color={ColorType.BUTTON} type="submit" disabled={!isValid || !isDirty}>
                    {t('Submit')}
                  </Button>
                </div>
              </Flexbox>
            </form>
          </Paper>
        }
      />
    </ContentContainer>
  )
}

export default UpdatePaymentLimitPage
