import { words } from 'lodash'
import moment from 'moment-timezone'
import { getCurrencyInfo } from 'api/utils'
import { CurrencyItem } from 'store/utils/types'
import { CurrencyCode } from 'store/accounts/types'
import { AvatarColor } from 'dls/shared/types'

const avatarColors: AvatarColor[] = [
  AvatarColor.REFERENCEDATA,
  AvatarColor.GOVERNANCE,
  AvatarColor.HELP,
  AvatarColor.PACKAGE,
  AvatarColor.PRODUCT,
  AvatarColor.SUSPENSEACCOUNT,
]

export const getColorFromInitials = (initials: string): AvatarColor => {
  const baseNum = 96
  const lowerInitials = initials.toLowerCase()
  const initialOneCharCode = lowerInitials.charCodeAt(0) - baseNum
  const initialTwoCharCode = lowerInitials.charCodeAt(1) - baseNum
  const index = (initialOneCharCode + initialTwoCharCode) % 6
  return avatarColors[index] || AvatarColor.PRODUCT
}

export function getInitials(names: string = '') {
  const splitNames = words(names).slice(0, 2)
  const [firstName = '', lastName = firstName[1]] = splitNames
  if (splitNames.length === 2) {
    const name = `${firstName[0]}${lastName[0]}`
    return name ? name.toUpperCase() : ''
  }
  const firstNameStr = firstName[0] ? firstName[0].toUpperCase() : ''
  const lastNameStr = lastName ? lastName.toLowerCase() : ''

  return `${firstNameStr}${lastNameStr}`
}

export const getGreetingTime = (m: moment.Moment) => {
  let greet = null // return greet

  const splitAfternoon = 12 // 24hr time to split the afternoon
  const currentHour = parseFloat(m.format('HH'))

  if (currentHour >= splitAfternoon) {
    greet = 'afternoon'
  } else {
    greet = 'morning'
  }

  return greet
}

const getFormat = (currencyCode: CurrencyCode | string) => {
  switch (currencyCode) {
    case CurrencyCode.GBP:
      return 'en-GB'
    case CurrencyCode.USD:
      return 'en-US'
    case CurrencyCode.AUD:
      return 'en-AU'
    default:
      return 'en-GB'
  }
}

export const getFormattedCurrency = (
  amount?: number,
  currencyCode?: string | CurrencyCode,
  showSymbol = true,
  numberFormatOptions: any | undefined = {}
): string => {
  if (amount != null) {
    const currencyInfo: CurrencyItem | null = getCurrencyInfo(currencyCode || 'GBP')
    return new Intl.NumberFormat(
      getFormat(currencyCode || 'GBP'),
      showSymbol
        ? {
            style: 'currency',
            currency: currencyInfo ? currencyInfo.code : 'GBP',
            maximumFractionDigits: 3,
            ...numberFormatOptions,
          }
        : {
            maximumFractionDigits: 3,
            ...numberFormatOptions,
          }
    )
      .formatToParts(amount)
      .map(({ type, value }) => {
        switch (type) {
          case 'minusSign':
            return `- `
          default:
            return value
        }
      })
      .reduce((string, part) => string + part)
  }
  return `${amount}`
}
