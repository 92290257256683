import React from 'react'
import styled from 'styled-components/macro'
import { MenuItem as MUIMenuItem, MenuItemProps as MUIMenuItemProps } from '@material-ui/core/'

export const StyledMenuItem = styled(MUIMenuItem)`
  padding: 8px;
`

interface MenuItemProps extends MUIMenuItemProps {
  button?: true
}

export const MenuItem = (Props: MenuItemProps) => <StyledMenuItem data-dls="atom-menuItem" {...Props} />

export default MenuItem
