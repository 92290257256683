import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Dashboard from './dashboard'
import { RootState } from 'store'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import * as UserSelector from 'store/user/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapStateToProps = (state: RootState) => ({
  userInformation: UserSelector.selectUserInformation(state),
  tenantConfig: TenantConfigSelectors.selectConfig(state),
  selectSelectedTenantIndex: UserSelector.selectSelectedTenantIndex(state),
  isMultiTenant: TenantConfigSelectors.isMultiTenant(state),
  currentTenant: TenantConfigSelectors.selectCurrentTenant(state),
})

type Props = ReturnType<typeof mapStateToProps>

function DashboardPage({
  userInformation,
  tenantConfig,
  selectSelectedTenantIndex,
  currentTenant,
  isMultiTenant,
}: Props) {
  const { t } = useTranslation()

  useBreadcrumbs({
    breadcrumbs: [
      {
        title: t('{{tenantName}} dashboard', { tenantName: (isMultiTenant && currentTenant?.name) || 'Your' }),
        path: '',
      },
    ],
    trigger: true,
  })

  return (
    <Dashboard
      userInformation={userInformation}
      tenantConfig={tenantConfig}
      selectSelectedTenantIndex={selectSelectedTenantIndex}
    />
  )
}

export default connect(mapStateToProps)(DashboardPage)
