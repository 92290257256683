import styled, { css } from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import {
  GridViewIconButton,
  ListIconButton,
  VisibilityIconButton,
  VisibilityOffIconButton,
} from 'dls/molecules/IconButtons/IconButtons'
import { Button } from 'dls/atoms/Button'
import { Subtitle2 } from 'dls/atoms/Typography'

export const IconMenuGb = styled.div`
  width: 76px;
  height: 40px;
  left: 0px;
  top: 0px;

  background: ${(props: any) => props.theme.palette.primary.greyLight};
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const selectableButton = css<{ $selected: boolean }>`
  ${({ theme, $selected }) =>
    $selected
      ? `
  background-color: ${theme.palette.background.paper};
  box-shadow: ${theme.shadows[1]};
  &:hover {
    background-color: ${theme.palette.background.paper};
  }
  & .MuiIconButton-root:hover {
    background-color: ${theme.palette.background.paper};
  }`
      : ''}
  & svg {
    width: 18px;
  }
  & svg > * {
    fill: ${({ theme, $selected }) => ($selected ? theme.palette.text.primary : theme.palette.text.secondary)};
  }
  &:hover svg > * {
    fill: ${({ theme }) => theme.palette.text.primary};
  }
  width: 36px;
  height: 36px;
`

export const SelectableVisibilityIconButton = styled(VisibilityIconButton)`
  ${selectableButton}
`

export const SelectableVisibilityOffIconButton = styled(VisibilityOffIconButton)`
  ${selectableButton}
`

export const SelectableGridViewIconButton = styled(GridViewIconButton)`
  ${selectableButton}
`

export const SelectableListIconButton = styled(ListIconButton)`
  ${selectableButton}
`

export const ShowIfLessXsGrid = styled(Grid)`
  display: none;
  ${({ theme }) => `
  ${theme.breakpoints.down('xs')} {
    display: block;
  }
  ${theme.breakpoints.up('md')} {
    display: block;
  }
`}
`

export const HideIfLessXsGrid = styled(Grid)`
  display: block;
  ${({ theme }) => `
  ${theme.breakpoints.down('xs')} {
    display: none;
  }
  ${theme.breakpoints.up('md')} {
    display: none;
  }
`}
`

export const FullRowOver960Grid = styled(Grid)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => `
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }
`}
`

export const SpaceBetweenUnder960Grid = styled(Grid)`
  justify-content: flex-end;
  ${({ theme }) => `
  ${theme.breakpoints.down('xs')} {
    justify-content: space-between;
  }
`}
`

export const SecondLineContainer = styled(Grid)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => `
  ${theme.breakpoints.down('xs')} {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
`}
`

export const StyledButton = styled(Button)`
  ${({ theme }) => `
  ${theme.breakpoints.down('xs')} {
    margin-right: -8px;
  }
  ${theme.breakpoints.only('sm')} {
    margin-left: -8px;
  }
`}
`
export const UnusedFeatureTitle = styled(Subtitle2)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.secondary};
`
