import { Grid, Typography } from '@material-ui/core'
import useWindowScroll from '@react-hook/window-scroll'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import { Process } from 'store/governance/types'

interface OwnProps {
  process: Process | undefined
}

const ProcessHeaderDiv = styled.div<{ moveToTop?: boolean }>`
  min-height: 120px;
  margin-top: -16px;
  z-index: ${(props: any) => (props.moveToTop ? '1101' : '1099')};
  position: relative;
`
const StyledSpan = styled.span`
  margin-right: 4px;
`

const ProcessNameDiv = styled.div`
  ${(props: any) => `${props.theme.breakpoints.down('xs')}`} {
    margin-bottom: 0px;
  }
`

const HeaderGrid = styled(Grid)`
  padding: 32px;
  padding-left: 0px;
  padding-bottom: 8px;
`
const StatusGrid = styled(Grid)`
  padding-bottom: 32px;
`

const ProcessHeader = (props: OwnProps): React.ReactElement => {
  const [moveToTop, setMoveToTop] = useState(true)
  // useWindowScroll has been added in the typings.
  const scrollY = useWindowScroll(30)
  // Temporary Solution using useEffect, need to figure out hook which listen and on condition returns.
  useEffect(() => {
    if (scrollY > 20) {
      setMoveToTop(false)
    } else {
      setMoveToTop(true)
    }
  }, [scrollY, setMoveToTop])
  const { t } = useTranslation()
  const { process } = props

  return (
    <ProcessHeaderDiv moveToTop={moveToTop}>
      {process && (
        <Grid container direction="column" justifyContent="flex-start" id="processHeader">
          <HeaderGrid item xs={12}>
            <ProcessNameDiv>
              <Typography variant="h1">
                <StyledSpan>{t(`Governance`)}</StyledSpan>
                <StyledSpan>:</StyledSpan>
                {t(process.name)}
              </Typography>
            </ProcessNameDiv>
          </HeaderGrid>
          <StatusGrid item>
            <Typography css="display: inline-flex;" variantMapping={{ body1: 'div' }}>
              {process.featureTasksGenerationStrategy !== 'NONE' ? (
                <CheckIcon css="margin-right:8px" />
              ) : (
                <ClearIcon css="margin-right:8px" />
              )}
              {t('Feature approval')}
            </Typography>
          </StatusGrid>
        </Grid>
      )}
    </ProcessHeaderDiv>
  )
}

export default ProcessHeader
