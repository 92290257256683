import React from 'react'
import useModal from './hooks/useModal'
import { PendingActionModal, SuccessModal, FailureModal, ProgressModal, InfographicModal } from 'components'
import { ModalType } from 'store/modal/types'

export const ModalContainer = () => {
  const { modal, closeModal } = useModal()

  if (modal) {
    const {
      modalType,
      helpModalType,
      title,
      message,
      messageLink,
      pendingAction,
      confirmBtnLabel,
      confirmNavigationRoute,
    } = modal

    switch (modalType) {
      case ModalType.SUCCESS:
        return <SuccessModal title={title} description={message} open setOpen={closeModal} />
      case ModalType.ERROR:
        return <FailureModal title={title} description={message} open setOpen={closeModal} />
      case ModalType.PROCESS:
        return <ProgressModal title={title} description={message} open />
      case ModalType.PENDING_ACTION:
        return (
          <PendingActionModal
            pendingAction={pendingAction}
            title={title}
            message={message}
            messageLink={messageLink}
            confirmBtnLabel={confirmBtnLabel}
            confirmNavigationRoute={confirmNavigationRoute}
          />
        )
      case ModalType.INFOGRAPHIC:
        return helpModalType ? <InfographicModal helpModalType={helpModalType} onClose={closeModal} /> : null
      default:
        return null
    }
  }

  return null
}

export default ModalContainer
