import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectModalState = (state: RootState) => state.modal

export const selectModal = createSelector(selectModalState, (state) => state.modal)

export default {
  selectModal,
}
