export interface CardTransactionLimitsResponse {
  cardLimits: CardTransactionLimit[]
}

export interface CardTransactionLimit {
  limitRuleId?: string
  direction: CardTransactionLimitsDirection
  channel: CardTransactionLimitsChannel
  routingDestinations: CardTransactionLimitsRoutingDestination[]
  range: CardTransactionLimitsRange
  amount: CardTransactionLimitsAmount
  enableSubscriptionOverride: boolean
  subscriptionOverrideMaximum?: number
}

export enum CardTransactionLimitsDirection {
  DEBIT = 'DEBIT',
}

export enum CardTransactionLimitsChannel {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum CardTransactionLimitsRoutingDestination {
  CARD_NON_CASH = 'CARD_NON_CASH',
  CARD_CASH = 'CARD_CASH',
}

export enum CardTransactionLimitsRange {
  TRANSACTION = 'TRANSACTION',
  DAY = 'DAY',
}

interface CardTransactionLimitsAmount {
  minimum?: number
  maximum?: number
  type: CardTransactionLimitsAmountType
}

export enum CardTransactionLimitsAmountType {
  VOLUME = 'VOLUME',
  MONETARY = 'MONETARY',
}

export interface CardTransactionLimitsFormValues {
  limitRuleId?: string
  limitName?: string
  enableSubscriptionOverride?: boolean
  minimum?: number
  maximum?: number
  subscriptionOverrideMaximum?: number
}

export interface CardTransactionLimitsURLValues {
  direction: CardTransactionLimitsDirection
  channel: CardTransactionLimitsChannel
  routingDestinations: CardTransactionLimitsRoutingDestination[]
  range: CardTransactionLimitsRange
  amountType: CardTransactionLimitsAmountType
}

export enum CardTransactionLimitsType {
  OUTBOUND_CASH_TRANSACTION = 'OUTBOUND_CASH_TRANSACTION',
  OUTBOUND_CASH_CUMULATIVE_DAILY = 'OUTBOUND_CASH_CUMULATIVE_DAILY',
  OUTBOUND_NON_CASH_TRANSACTION = 'OUTBOUND_NON_CASH_TRANSACTION',
  OUTBOUND_NON_CASH_DAILY = 'OUTBOUND_NON_CASH_DAILY',
}
