/* eslint-disable no-nested-ternary */
import createAsyncAuthAwareAxios, { getBase64ImageFromURL } from './client/authAwareAxios'
import { ContentType, GetSamplesParams, TemplatesRequest, TemplatesResponse } from 'store/statement/types'

async function getTemplates(params: TemplatesRequest): Promise<TemplatesResponse> {
  const { data } = await (await createAsyncAuthAwareAxios('/v1')).get<TemplatesResponse>('/statements/templates', {
    params,
  })
  return data
}

async function getSamples({ contentType, ...params }: GetSamplesParams): Promise<string> {
  const response = await (await createAsyncAuthAwareAxios('/v1')).get<Response>('/statements/samples', {
    responseType: 'blob',
    params,
    headers: { 'content-type': contentType, accept: contentType },
  })
  if (contentType === ContentType.PNG) {
    const base64imagesStr = await getBase64ImageFromURL(response)
    return base64imagesStr as string
  }
  return response.data as any
}
export default { getTemplates, getSamples }
