import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormBottomBar } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  productKey: string
}

const BottomBar = ({ productKey }: OwnProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <FormBottomBar position="bottom">
      <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
        {t('Cancel')}
      </Button>
    </FormBottomBar>
  )
}

export default BottomBar
