import { first, identity, includes, isEmpty, mapValues, omitBy, pick, union } from 'lodash'
import { ExtendedProduct, LimitsFormValues, Product } from 'store/products/types'
import { CountryItem, CurrencyItem } from 'store/utils/types'
import currenciesJson from 'static/currencies.json'
import countriesJson from 'static/countries.json'

export const removeEmptyArrays = (data: object) => mapValues(omitBy(data, isEmpty), (item) => item)

/**
 *
 * Converts form data into API consumable data:
 * - Removes empty keys
 * - Flattens first level array
 *
 * @param data: Object to sanitise
 * @param preserveAsArray array of keys to preserve as arrays. it defaults to an empty array, and therefore is optional
 */

export const prepareFormData = (data: object, preserveAsArray: string[] = ['']) =>
  mapValues(removeEmptyArrays(data), (item, key) => (includes(preserveAsArray, key) ? identity : first)(item))

export const prepareLimitsFormData = (data: LimitsFormValues) => {
  const payload = {
    schemeLimits: union(data.schemeLimitsFPS, data.schemeLimitsBACS),

    // This below commented code will be used, Dont remove!!!!!!!

    // productLimits: union(
    //   data.productLimitPersonalCurrentAccount,
    //   data.productLimitCurrentPool,
    //   data.productLimitSavingsPool,
    //   data.productLimitBillsPool,
    //   data.productLimitAccount,
    //   data.productLimitMortgage,
    //   data.productLimitLoan,
    //   data.productLimitCurrentAccount,
    //   data.productLimitOther,
    //   data.productLimitCustom
    // ),
    transactionLimits: union(data.transactionLimitsATM, data.transactionLimitsOUT),
    subscriptionLimit: data.subscriptionLimitOnly ? data.subscriptionLimitOnly[0] : [],
    cardLimit: data.cardLimitOnly ? data.cardLimitOnly[0] : [],
  }

  return removeEmptyArrays(payload)
}

export const getCurrencyInfo = (currencyCode: string): CurrencyItem | null =>
  currenciesJson[currencyCode.toUpperCase()] || null
export const getCountryInfo = (countryCode: string): CountryItem | null =>
  countriesJson[countryCode.toUpperCase()] || null

export const getCurrencyOfProduct = (product: ExtendedProduct): string | null =>
  product && product.currency ? product.currency.currencyCode : null

export const getTaxonomy = (taxonomy?: string): Partial<Product> => {
  try {
    return JSON.parse(taxonomy || '{}')
  } catch (e: any) {
    return {}
  }
}

export const setTaxonomy = (product: Partial<Product>): string => {
  try {
    return JSON.stringify(
      pick(product, ['productSegment', 'productGroup', 'productLine', 'productType', 'productCategory'])
    )
  } catch (e: any) {
    return '{}'
  }
}

export const getCompactTransactionId = (transactionId: string | null | undefined) =>
  transactionId ? transactionId.replace(/-/g, '').substr(0, 12) : 'N/A'
