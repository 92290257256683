import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { groupBy } from 'lodash'
import { AvailableProducts, PackagesWithProductsFormValue } from 'store/packages/types'
import PackagesSelectors from 'store/packages/selectors'
import PackagesActions from 'store/packages/actions'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export default (productPackageId: string) => {
  const [editMode, setStateEditMode] = useState(false)
  const [availableGroupedProducts, setAvailableGroupedProducts] = useState<AvailableProducts[]>([])
  const selectedPackage = useSelector(PackagesSelectors.selectSelectedPackage)
  const isLoading = useSelector(PackagesSelectors.selectLoading)
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)
  const dispatch = useDispatch()
  const availableProducts = useSelector(PackagesSelectors.selectAvailableProducts)
  const { t } = useTranslation()

  const createGroupedProducts = () => {
    if (selectedPackage && availableProducts) {
      const { publicationDate, products } = selectedPackage

      const isPackageLiveNow = publicationDate && moment(publicationDate).isSameOrBefore(moment())

      // if product was selected previously, then it gets added to list
      // if package is live now, then show all products before now
      // if it isn't, all products before package live date can be added
      const isProductValidInPackage = (product: AvailableProducts): boolean => {
        const isPackagePreviouslySelected = products.find(
          (selectedProduct) => selectedProduct.productKey === product.productKey
        )
        if (!publicationDate || isPackagePreviouslySelected) {
          return true
        }
        if (isPackageLiveNow) return moment(product.effectiveDate).isSameOrBefore(moment())
        return moment(product.effectiveDate).isSameOrBefore(publicationDate)
      }

      const filteredAvailableProducts = editMode ? availableProducts.filter(isProductValidInPackage) : availableProducts
      const newFilteredAvailableProducts: AvailableProducts[] = Object.entries(
        groupBy(filteredAvailableProducts, 'productKey')
      ).map(([, value]) => ({
        ...value[0],
        productName: value.length > 1 ? `${value[0].productName} (${t('latest name')})` : value[0].productName,
        versions: value,
      }))

      setAvailableGroupedProducts(newFilteredAvailableProducts)
    }
  }

  const onLoad = () => {
    dispatch(PackagesActions.getPackageDetails(productPackageId))
    dispatch(PackagesActions.availableProducts())
  }

  const updateSelectedPack = ({ products, nonJoinedProducts, ...origValues }: PackagesWithProductsFormValue) => {
    dispatch(
      PackagesActions.updateSelectedPackage({ products: [...(nonJoinedProducts || []), ...products], ...origValues })
    )
    dispatch(PackagesActions.isPublishPackage(false))
    dispatch(PackagesActions.availableProducts())

    setStateEditMode(false)
    window.scrollTo(0, 0)
  }

  useEffect(onLoad, [])

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('{{tenantName}} packages', { tenantName: currentTenant?.name }), path: `packages/` },
      { title: selectedPackage ? selectedPackage.productPackageName : '', path: '' },
    ],
    trigger: true,
  })

  useEffect(createGroupedProducts, [selectedPackage, availableProducts, editMode])

  return {
    selectedPackage,
    isLoading,
    editMode,
    setStateEditMode,
    availableGroupedProducts,
    updateSelectedPack,
  }
}
