import BigNumber from 'bignumber.js'
import { PaymentLimitRange } from 'store/products/typings/paymentLimits'

const MAX_VALUE = 9999999

export const validateMinimum = (maximumInput: number) => (value: number) => {
  if (value > MAX_VALUE) {
    return 'The maximum limit amount is 9,999,999'
  }

  if (!new BigNumber(value).isInteger()) {
    return 'The limit amount must not contain decimals'
  }

  if (new BigNumber(value).isGreaterThanOrEqualTo(maximumInput)) {
    return 'The min amount must be less than max amount'
  }
  return true
}

export const checkMinimumRequirement = (maximumInput: number) => !maximumInput

export const validateMaximum = (minimumInput: number) => (value: number) => {
  if (value > MAX_VALUE) {
    return 'The maximum limit amount is 9,999,999'
  }
  if (value <= 0) {
    return 'The limit amount must be greater than 0'
  }

  if (!new BigNumber(value).isInteger()) {
    return 'The limit amount must not contain decimals'
  }

  if (new BigNumber(minimumInput).isGreaterThanOrEqualTo(value)) {
    return 'The max amount must be greater than min amount'
  }

  return true
}

export const validateLimits = (
  range: PaymentLimitRange,
  dateValues: { day?: number; month?: number; year?: number }
) => (value: number) => {
  const { day, month, year } = dateValues
  const hasLimits = Object.keys(dateValues)
    .filter((limitValues) => limitValues !== range.toLowerCase())
    .every((limitRange) => limitRange !== undefined)

  if (!hasLimits) {
    return true
  }

  if (range === PaymentLimitRange.DAY) {
    const smallestValue = Math.min(...([month, year].filter((i) => Number.isInteger(i)) as any))

    const isMonthValid = !Number.isInteger(month) || value <= (month as number)
    const isYearValid = !Number.isInteger(year) || value <= (year as number)
    const isDayValid = isMonthValid && isYearValid

    return isDayValid || `Limit must be smaller or equal to ${smallestValue}`
  }

  if (range === PaymentLimitRange.MONTH) {
    let errorMessage = ''

    const isDayValid = !Number.isInteger(day) || value >= (day as number)
    const isYearValid = !Number.isInteger(year) || value <= (year as number)

    if (!isDayValid) {
      errorMessage = `Limit must be larger or equal to ${day}`
    }
    if (!isYearValid) {
      errorMessage = `Limit must be smaller or equal to ${year}`
    }

    if (!isDayValid || !isYearValid) {
      return errorMessage
    }
    return true
  }

  if (range === PaymentLimitRange.YEAR) {
    let errorMessage = ''

    const isDayValid = !Number.isInteger(day) || value >= (day as number)
    const isMonthValid = !Number.isInteger(month) || value >= (month as number)

    if (!isDayValid) {
      errorMessage = `Limit must be larger or equal to ${day}`
    }
    if (!isMonthValid) {
      errorMessage = `Limit must be larger or equal to ${month}`
    }

    if (!isDayValid || !isMonthValid) {
      return errorMessage
    }
    return true
  }

  return true
}
