import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { Grid, useTheme } from '@material-ui/core'
import 'styled-components/macro'
import { FormInputProps, StyledMenuItem } from './shared'
import { Input, Select } from 'dls'
import { DataPointOperator } from 'store/products/typings/conditionBuilder'
import { FeeRewardDataPointType } from 'store/products/features/feesRewards/types'
import { defineValueType, defineValueLabel } from 'pages/features/FeesRewards/utils/transformers'
import DataPicker from 'dls/organisms/DataPicker/DataPicker'
import transactionCodes from 'static/transactionCodes'
import { DataPickerRow } from 'dls/shared/types'
import { Feature, FeeRewardFeatureType } from 'store/products/types'

const ConditionValueInput = ({
  control,
  watch,
  setValue,
  currencyCode,
  defaultTransactionCodes,
  featureType,
}: Omit<FormInputProps, 'onChange'> & {
  defaultTransactionCodes: string
  currencyCode?: string
  featureType: FeeRewardFeatureType
}): JSX.Element | null => {
  const { t } = useTranslation()
  const theme = useTheme() as any

  const dataPoint = watch('dataPoint')
  const dataPointFirstOperator = watch('dataPointFirstOperator')
  const dataPointSecondOperator = watch('dataPointSecondOperator')
  const dataValue = watch('dataValue')
  const valueOperators = [...Object.values(DataPointOperator)]

  const dataRows = useMemo(() => {
    const codes = defaultTransactionCodes?.split(',') || []

    const markChecked = (rows: DataPickerRow[]): DataPickerRow[] => {
      const updatedRows = rows

      const iterate = (row: DataPickerRow) => {
        row.checked = !!codes.find((item) => row.code.indexOf(item) > -1)

        if (row.children) {
          row.children.forEach((childRow) => {
            iterate(childRow)
          })
        }
      }

      updatedRows.forEach((row) => {
        iterate(row)
      })

      return updatedRows
    }

    return markChecked(transactionCodes)
  }, [defaultTransactionCodes])

  if (dataPoint === FeeRewardDataPointType.TRANSACTION_IS_CREDIT && dataPointFirstOperator) {
    return (
      <Grid item xs={12} sm={3}>
        <Controller
          name="dataValue"
          control={control}
          render={({ field }) => (
            <Select label={t('This')} required placeholder={t('Please select')} displayEmpty fullWidth {...field}>
              {['True', 'False'].map((value) => (
                <StyledMenuItem key={value} value={value}>
                  {value}
                </StyledMenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
    )
  }

  if (
    dataPoint === FeeRewardDataPointType.TRANSACTION_ISO_CODE &&
    dataPointFirstOperator === DataPointOperator.EQUAL_TO &&
    setValue
  ) {
    return (
      <Grid item xs={12}>
        <DataPicker
          title={t('DATA_PICKER.TITLE')}
          contextualHelp={{
            ariaProps: {
              'aria-hidden': false,
              'aria-label': 'Help',
            },
            id: 'datapickerHeading',
            title: t('DATA_PICKER.CONTEXTUAL_HELP') || '',
          }}
          dataRows={dataRows}
          onChange={(val) => setValue('dataValue', val)}
          headerColor={featureType === Feature.FEES ? theme.palette.teal.light : theme.palette.yellow.light}
          hideFooter
        />
      </Grid>
    )
  }

  if (
    (valueOperators.includes(dataPointFirstOperator) || valueOperators.includes(dataPointSecondOperator)) &&
    currencyCode &&
    !Array.isArray(dataValue)
  ) {
    const valueType = defineValueType(dataPoint as FeeRewardDataPointType)
    const label = defineValueLabel(valueType)
    return (
      <Grid item xs={12} sm={6}>
        <Controller
          name="dataValue"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              id="dataValue"
              type={valueType}
              label={t(label)}
              required
              currencyCode={currencyCode}
              fullWidth
              error={fieldState.invalid}
              errorText={fieldState.error?.message}
              numberFormatProps={{
                decimalScale: 2,
              }}
              {...field}
            />
          )}
        />
      </Grid>
    )
  }

  return null
}

export default ConditionValueInput
