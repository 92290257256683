import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { GroupHeadingMenuItem, FormInputProps, StyledMenuItem } from './shared'
import { SelectOption } from 'dls/shared/types'
import { FeeRewardDataPointType } from 'store/products/features/feesRewards/types'
import { Select } from 'dls'
import { BuilderCondition } from 'store/products/typings/conditionBuilder'
import { FeeRewardFeatureType } from 'store/products/types'

type Props = FormInputProps & {
  selectLabel: string
  conditions: BuilderCondition[]
  featureType: FeeRewardFeatureType
}

const DataPointSelect = ({ control, selectLabel, conditions, featureType, onChange }: Props): JSX.Element => {
  const { t } = useTranslation()

  const hasBalanceFamily = !!conditions.find((condition) =>
    [FeeRewardDataPointType.BALANCE_END_OF_DAY_AMOUNT, FeeRewardDataPointType.BALANCE_UNIQUE_ENTRY].includes(
      condition.dataPoint?.id as FeeRewardDataPointType
    )
  )

  const hasTransactionFamily = !!conditions.find((condition) =>
    [
      FeeRewardDataPointType.TRANSACTION_AMOUNT,
      FeeRewardDataPointType.TRANSACTION_ISO_CODE,
      FeeRewardDataPointType.TRANSACTION_IS_CREDIT,
      FeeRewardDataPointType.TRANSACTION_UNIQUE_ENTRY,
    ].includes(condition.dataPoint?.id as FeeRewardDataPointType)
  )

  const options: SelectOption[] = [
    ...(hasTransactionFamily
      ? []
      : [
          {
            value: 'balance_heading',
            label: t(`PRODUCT_FEATURE.${featureType}.BALANCE_HEADING`),
            groupHeading: true,
          },
          {
            value: FeeRewardDataPointType.BALANCE_END_OF_DAY_AMOUNT,
            label: t(`PRODUCT_FEATURE.${featureType}.${FeeRewardDataPointType.BALANCE_END_OF_DAY_AMOUNT}`),
          },
          {
            value: FeeRewardDataPointType.BALANCE_UNIQUE_ENTRY,
            label: t(`PRODUCT_FEATURE.${featureType}.${FeeRewardDataPointType.BALANCE_UNIQUE_ENTRY}`),
          },
        ]),
    ...(hasBalanceFamily
      ? []
      : [
          {
            value: 'transaction_heading',
            label: t(`PRODUCT_FEATURE.${featureType}.TRANSACTION_HEADING`),
            groupHeading: true,
          },
          {
            value: FeeRewardDataPointType.TRANSACTION_AMOUNT,
            label: t(`PRODUCT_FEATURE.${featureType}.${FeeRewardDataPointType.TRANSACTION_AMOUNT}`),
          },
          {
            value: FeeRewardDataPointType.TRANSACTION_ISO_CODE,
            label: t(`PRODUCT_FEATURE.${featureType}.${FeeRewardDataPointType.TRANSACTION_ISO_CODE}`),
          },
          {
            value: FeeRewardDataPointType.TRANSACTION_IS_CREDIT,
            label: t(`PRODUCT_FEATURE.${featureType}.${FeeRewardDataPointType.TRANSACTION_IS_CREDIT}`),
          },
          {
            value: FeeRewardDataPointType.TRANSACTION_UNIQUE_ENTRY,
            label: t(`PRODUCT_FEATURE.${featureType}.${FeeRewardDataPointType.TRANSACTION_UNIQUE_ENTRY}`),
          },
        ]),
  ]

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        name="dataPoint"
        control={control}
        render={({ field }) => (
          <Select
            label={t(selectLabel)}
            required
            displayEmpty
            placeholder={t('Please select')}
            fullWidth
            {...field}
            onChange={(e) => {
              onChange(() => field.onChange(e))
            }}
          >
            {options.map(({ value, label, groupHeading }) =>
              groupHeading ? (
                <GroupHeadingMenuItem key={value} value={value}>
                  {label}
                </GroupHeadingMenuItem>
              ) : (
                <StyledMenuItem key={value} value={value}>
                  {label}
                </StyledMenuItem>
              )
            )}
          </Select>
        )}
      />
    </Grid>
  )
}

export default DataPointSelect
