import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { RuleId, WithholdingRule, WithholdingRules, TaxRuleId, WithholdingTaxRule } from 'store/withholdingTax/types'
import { SearchProductsResponse } from 'store/products/types'

async function getAllTaxRules(): Promise<WithholdingRules> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<WithholdingRules>('/v1/taxcalculations/rules')
  return data
}
async function createRule(payload: WithholdingRule): Promise<WithholdingRule> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<WithholdingRule>(
    '/v1/taxcalculations/rules',
    payload
  )
  return data
}

async function getTaxRule(ruleId: RuleId): Promise<WithholdingRule> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<WithholdingRule>(
    `/v1/taxcalculations/rules/${ruleId}`
  )
  return data
}

async function addTaxRule(payload: WithholdingTaxRule): Promise<WithholdingTaxRule> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<WithholdingTaxRule>(
    `/v1/taxcalculations/rules/${payload.ruleId}/taxrules`,
    payload
  )
  return data
}

async function updateTaxRule(payload: WithholdingTaxRule): Promise<WithholdingTaxRule> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<WithholdingTaxRule>(
    `/v1/taxcalculations/taxrules/${payload.taxRuleId}`,
    payload
  )
  return data
}

async function getTaxRuleProducts(payload: RuleId): Promise<SearchProductsResponse> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<SearchProductsResponse>(
    '/v2/products/search',
    null,
    {
      params: {
        taxRule: payload,
      },
    }
  )
  return data
}

async function deleteTaxRule(taxRuleId: TaxRuleId): Promise<TaxRuleId> {
  const { data } = await (await createAsyncAuthAwareAxios(null, true)).delete(
    `/v1/taxcalculations/taxrules/${taxRuleId}`
  )
  return data
}

async function deleteRule(ruleId: RuleId): Promise<RuleId> {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).delete(`/v1/taxcalculations/rules/${ruleId}`)
  return data
}

export default {
  getAllTaxRules,
  createRule,
  getTaxRule,
  addTaxRule,
  updateTaxRule,
  getTaxRuleProducts,
  deleteTaxRule,
  deleteRule,
}
