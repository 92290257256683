import React from 'react'
import { useSelector } from 'react-redux'
import { Tooltip, Typography, TypographyProps } from '@material-ui/core'
import { selectConfig, selectCurrentTenant } from 'store/tenant-config/selectors'
import { getCurrencyInfo } from 'api/utils'
import { CurrencyItem } from 'store/utils/types'

export interface CurrencySymbolProps {
  currencyCode?: string | null
  typographyProps?: TypographyProps
  size?: boolean
  noTypography?: boolean
  hideCurrencySymbol?: boolean
}

const CurrencySymbol = ({ currencyCode, typographyProps, size, noTypography = false }: CurrencySymbolProps) => {
  const tenantConfig = useSelector(selectConfig)
  const selectedTenant = useSelector(selectCurrentTenant)
  const currencyInfo: CurrencyItem | null = getCurrencyInfo(
    currencyCode || selectedTenant?.localisation?.currencyCode || tenantConfig?.localisation?.currencyCode || 'GBP'
  )
  if (!currencyInfo) return null
  return (
    <Tooltip placement="left" title={currencyInfo.name} aria-label={currencyInfo ? currencyInfo.name : undefined}>
      {noTypography ? (
        <>{currencyInfo ? currencyInfo.nativeSymbol : '£'}</>
      ) : (
        <Typography
          variant={size ? 'h4' : 'body1'}
          variantMapping={size ? { h4: 'span' } : { body1: 'span' }}
          style={size ? { fontWeight: 300 } : {}}
          {...typographyProps}
        >
          {currencyInfo ? currencyInfo.nativeSymbol : '£'}
        </Typography>
      )}
    </Tooltip>
  )
}

export default CurrencySymbol
