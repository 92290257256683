import assign from './assign'
import clearProductPublish from './clearProductPublish'
import create from './create'
import deleteFeature from './deleteFeature'
import deleteProduct from './deleteProduct'
import fetch from './fetch'
import productDetails from './productDetails'
import productSyndication from './productSyndication'
import publish from './publish'
import review from './review'
import saveFeature from './saveFeature'
import versions from './versions'
import cards from './cards'
import transactionRules from './transactionRules'

export default {
  ...assign,
  ...clearProductPublish,
  ...create,
  ...deleteFeature,
  ...deleteProduct,
  ...fetch,
  ...productDetails,
  ...productSyndication,
  ...publish,
  ...review,
  ...saveFeature,
  ...versions,
  ...cards,
  ...transactionRules,
}
