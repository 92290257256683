import React from 'react'
import { Typography, Theme, ButtonProps } from '@material-ui/core'
import styled, { css } from 'styled-components/macro'
import { ColorType } from 'dls/shared/types'
import { Flexbox } from 'pages/Layout/Styled'
import { extraPadding } from 'dls/shared/styleVar'
import { DialogContent } from 'dls/atoms/Dialog/DialogContent'

export const StatusContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`
export const DateTypography = styled(Typography)`
  font-size: 11px;
  color: ${(props: any) => props.theme.palette.text.secondary};
`

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`
export const ContentColumn = styled.div`
  margin: 0;
  align-items: baseline;
`

export const StyleOval = styled.span`
  width: 26px;
  height: 26px;
  border: solid 2px;
  border-radius: 50%;
  padding: 0px 5px;
  margin-left: -39px;
  margin-right: 14px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
`

export type DefinationListProps = {
  direction?: 'column' | 'row'
  width?: string
  height?: string
  justifyContent?: 'flex-start' | 'flex-end' | 'stretch' | 'center' | 'space-between'
  alignItems?: 'flex-start' | 'flex-end' | 'stretch' | 'center'
  p?: number
  pt?: number
  pb?: number
  pl?: number
  pr?: number
  lastItem?: boolean
  marginInlineStart?: number
}

export const DefinationList = styled.dl<DefinationListProps>`
  padding: 0;
  margin: 0;

  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  flex-direction: ${({ direction }) => direction};
  ${({ p }) => p && `padding: ${p}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pr }) => pr && `padding-right: ${pr}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
  ${({ pl }) => pl && `padding-left: ${pl}px;`}
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
`

export const DefinationTitle = styled.dt<DefinationListProps>`
  float: left;
  box-sizing: border-box;

  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  flex-direction: ${({ direction }) => direction};
  ${({ p }) => p && `padding: ${p}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pr }) => pr && `padding-right: ${pr}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
  ${({ pl }) => pl && `padding-left: ${pl}px;`}
`

export const DefinationData = styled.dd<DefinationListProps>`
  box-sizing: border-box;

  ${({ lastItem }) =>
    lastItem &&
    `&:after {
    content: '';
    display: block;
    clear: both;
  }`}

  ${({ marginInlineStart }) => marginInlineStart && `margin-inline-start: ${marginInlineStart}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  flex-direction: ${({ direction }) => direction};
  ${({ p }) => p && `padding: ${p}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pr }) => pr && `padding-right: ${pr}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
  ${({ pl }) => pl && `padding-left: ${pl}px;`}
`

export const ListItem = styled.li`
  list-style: none;
  list-style-type: none;
`

export const OrderedList = styled.ol`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ResponsiveFlexbox = styled(Flexbox)`
  box-sizing: border-box;
  padding-top: 40px;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      max-width: 568px;
    }
    ${theme.breakpoints.down('md')} {
    max-width: 896px;
    }
    ${theme.breakpoints.up('md')} {
    max-width: 1000px;
    }
  `}
`

export const Main = styled.main`
  padding: 0 0 40px 0;
  width: 100%;
`

export type GridProps = {
  width?: string
  height?: string
  p?: number | string
  pt?: number | string
  pb?: number | string
  pl?: number | string
  pr?: number | string
  m?: number | boolean
  mt?: number | boolean
  mb?: number | boolean
  ml?: number | boolean
  mr?: number | boolean
  rp?: number | string
  rpt?: number | string
  rpb?: number | string
  rpl?: number | string
  rpr?: number | string
  rm?: number | boolean
  rmt?: number | boolean
  rmb?: number | boolean
  rml?: number | boolean
  rmr?: number | boolean
  lastItem?: boolean
  textAlign?: 'center' | 'left' | 'right'
  marginInlineStart?: number
  gridGap?: string
  gridRowGap?: string
  gridColumnGap?: string
  rows?: boolean
  column?: boolean
  background?: string
  border?: string
  maxWidth?: string
}

export type GridBoxProps = {
  maxWidth?: string
  p?: number | string
  pt?: number | string
  pb?: number | string
  pl?: number | string
  pr?: number | string
  m?: number | boolean
  mt?: number | boolean
  mb?: number | boolean
  ml?: number | boolean
  mr?: number | boolean
  rp?: number | string
  rpt?: number | string
  rpb?: number | string
  rpl?: number | string
  rpr?: number | string
  rm?: number | boolean
  rmt?: number | boolean
  rmb?: number | boolean
  rml?: number | boolean
  rmr?: number | boolean
  lastItem?: boolean | string
  textAlign?: 'center' | 'left' | 'right'
  marginInlineStart?: number
  gridColumnSpan?: string
  gridColumnPos?: string
  width?: string
  height?: string
  alignItem?: string
  justifyItems?: string
  border?: string
  background?: string
}

export const GridContainer = css<GridProps>`
  display: grid;
  ${({ rows }) => rows && `grid-template-rows: repeat(6, 1fr);`}
  ${({ column }) => column && `grid-template-columns: repeat(12, 1fr);`}
  ${({ gridRowGap }) => `row-gap: ${gridRowGap || 0}px;`}
  ${({ gridColumnGap }) => `column-gap: ${gridColumnGap || 16}px;`}
  ${({ gridGap }) => `grid-gap: ${gridGap || 0}px;`}
  ${({ border }) => border && `border: ${border} ;`}
  ${({ background }) => background && `background: ${background}; `}
  ${({ width }) => `width: ${width || '100%'}; `}
  ${({ textAlign }) => `text-align: ${textAlign || 'left'}; `}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth || '1280px'};`}

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      min-height: 10px;
    }
  `}
`

export const GridBox = css<GridBoxProps>`
  display: grid;
  ${({ border }) => border && `border: ${border} ;`}
  ${({ background }) => background && `background: ${background}; `}
  ${({ marginInlineStart }) => marginInlineStart && `margin-inline-start: ${marginInlineStart}px;`}
  ${({ alignItem }) => alignItem && `align-items: ${alignItem};`}
  ${({ justifyItems }) => justifyItems && `justify-items: ${justifyItems};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ m }) => m && `margin: ${m}px;`}
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mr }) => mr && `margin-right: ${mr}px;`}
  ${({ mb }) => `margin-bottom:${mb || 0}px;`}
  ${({ ml }) => ml && `margin-left: ${ml}px;`}
  ${({ p }) => p && `padding: ${p}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pr }) => pr && `padding-right: ${pr}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
  ${({ pl }) => pl && `padding-left: ${pl}px;`}
  ${({ gridColumnSpan }) => `grid-column: span ${gridColumnSpan || '1'};`}
  ${({ gridColumnPos }) => gridColumnPos && `grid-column: ${gridColumnPos || '1'};`}
  @media screen and (max-width: 600px) {
    grid-column: span 12;
    ${({ rpt }) => rpt && `padding-top: ${rpt}px;`}
    ${({ rmt }) => rmt && `margin-top: ${rmt}px;`}
    ${({ rmr }) => rmr && `margin-right: ${rmr}px;`}
    ${({ rmb }) => rmb && `margin-bottom:${rmb || 0}px;`}
    ${({ rml }) => rml && `margin-left: ${rml}px;`}
    ${({ rp }) => rp && `padding: ${rp}px;`}
    ${({ rpt }) => rpt && `padding-top: ${rpt}px;`}
    ${({ rpr }) => rpr && `padding-right: ${rpr}px;`}
    ${({ rpb }) => rpb && `padding-bottom: ${rpb}px;`}
    ${({ rpl }) => rpl && `padding-left: ${rpl}px;`}
  }
`
export interface ButtonCustom extends Omit<ButtonProps, 'color'> {
  ariaProps?: Record<string, string | boolean>
  color?: ColorType
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  IconColor?: ColorType
}

export interface PaletteColor {
  light: string
  main: string
  dark: string
  contrastText: string
  primary: string
  secondary: string
  default: string
  medium: string
  paper: string
}

export interface Palette {
  primary: PaletteColor
  secondary: PaletteColor
  text: PaletteColor
  error: PaletteColor
  warning: PaletteColor
  info: PaletteColor
  success: PaletteColor
  design: PaletteColor
  scheduled: PaletteColor
  updated: PaletteColor
  review: PaletteColor
  approved: PaletteColor
  live: PaletteColor
  rejected: PaletteColor
  closed: PaletteColor
  background: PaletteColor
  paper: PaletteColor
  default: PaletteColor
  orange: PaletteColor
  magenta: PaletteColor
  blue: PaletteColor
  green: PaletteColor
  yellow: PaletteColor
  teal: PaletteColor
}

export interface StaticPalette {
  orange: PaletteColor
  magenta: PaletteColor
  blue: PaletteColor
  teal: PaletteColor
  green: PaletteColor
  yellow: PaletteColor
}

export interface CustomStaticTheme extends Omit<Theme, 'palette'> {
  palette: StaticPalette
}

export interface CustomTheme extends Omit<Theme, 'palette'> {
  palette: Palette
}

export const iconColorSelector = css<ButtonCustom>`
  ${({ color, theme }: { color?: ColorType; theme: CustomTheme }) => {
    switch (color) {
      case ColorType.BRAND:
        return `${theme.palette.primary.main}`
      case ColorType.PRIMARY:
        return `${theme.palette.text.primary}`
      case ColorType.SECONDARY:
        return `${theme.palette.text.secondary}`
      case ColorType.ERROR:
        return `${theme.palette.error.dark}`
      case ColorType.INFO:
        return `${theme.palette.info.main}`
      case ColorType.SUCCESS:
        return `${theme.palette.success.main}`
      case ColorType.WARNING:
        return `${theme.palette.warning.main}`
      case ColorType.DESIGN:
        return `${theme.palette.design.main}`
      case ColorType.SCHEDULED:
        return `${theme.palette.scheduled.main}`
      case ColorType.UPDATED:
        return `${theme.palette.updated.main}`
      case ColorType.REVIEW:
        return `${theme.palette.review.main}`
      case ColorType.APPROVED:
        return `${theme.palette.approved.main}`
      case ColorType.LIVE:
        return `${theme.palette.live.main}`
      case ColorType.REJECTED:
        return `${theme.palette.rejected.main}`
      case ColorType.CLOSED:
        return `${theme.palette.closed.main}`
      case ColorType.BUTTON:
        return `${theme.palette.secondary.main}`
      case ColorType.MEDIUM:
        return `${theme.palette.background.medium}`
      case ColorType.PAPER:
        return `${theme.palette.background.light}`
      case ColorType.ORANGE:
        return `${theme.palette.orange.dark}`
      case ColorType.MAGENTA:
        return `${theme.palette.magenta.dark}`
      case ColorType.BLUE:
        return `${theme.palette.blue.dark}`
      case ColorType.GREEN:
        return `${theme.palette.green.dark}`
      case ColorType.YELLOW:
        return `${theme.palette.yellow.dark}`
      case ColorType.TEAL:
        return `${theme.palette.teal.dark}`
      case ColorType.inherit:
        return `inherit`
      default:
        return `default`
    }
  }}
`

export const DialogStyledContainer = styled(DialogContent)`
  padding: ${extraPadding};
  overflow-x: hidden;
  &:first-child {
    padding-top: ${extraPadding};
  }
`

export const StyledResetOL = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

export const loaderColorSelector = css`
  ${({ loaderColor, theme }: { loaderColor?: ColorType; theme: CustomTheme }) => {
    switch (loaderColor) {
      case ColorType.BRAND:
        return `${theme.palette.primary.main}`
      case ColorType.PRIMARY:
        return `${theme.palette.text.primary}`
      case ColorType.SECONDARY:
        return `${theme.palette.text.secondary}`
      default:
        return `default`
    }
  }}
`

export const StyledFieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`
