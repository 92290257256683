import React from 'react'
import { TFunction } from 'i18next'
import BigNumber from 'bignumber.js'
import { isEmpty } from 'lodash'
import styled from 'styled-components/macro'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { selectedRateAndBankName } from 'pages/features/CreditInterest/utils/creditInterestTransformers'
import { CreditInterest, RateTierBand } from 'store/products/types'
import { BankInterestRate, SelectedRateAndBankName } from 'store/referenceData/types'
import { isNullOrUndefined } from 'utils/common.util'
import { RateMultiplyByHundred, preciseRateMultiplyByHundred, preciseFixRateNumber } from 'utils/form.utils'

const StyledSpan = styled.span`
  font-size: 14px;
  color: ${(props: any) => props.theme.palette.text.secondary};
`

const getVariableDefinitions = (
  { interestRate, interestTierBand, tierBandCalcMethod, bankInterestRateIndex }: CreditInterest,
  currencyOfProduct: string | null,
  bankIndexRates: BankInterestRate[],
  t: TFunction
) => {
  let selectedRateAndBankNameObject: SelectedRateAndBankName | null = null

  if (bankIndexRates && bankInterestRateIndex && bankInterestRateIndex !== '' && !isEmpty(bankIndexRates)) {
    selectedRateAndBankNameObject = selectedRateAndBankName(bankIndexRates, bankInterestRateIndex)
  }

  return [
    !isNullOrUndefined(interestRate) &&
      tierBandCalcMethod === RateTierBand.FLAT && {
        term: t('Flat'),
        definitions: [
          <React.Fragment key="margin">
            <span>{`${RateMultiplyByHundred(interestRate as number, 10)}% `}</span>
            {selectedRateAndBankNameObject !== null &&
            selectedRateAndBankNameObject.bankName &&
            selectedRateAndBankNameObject.rate ? (
              <StyledSpan>
                {`(${selectedRateAndBankNameObject.bankName}: ${selectedRateAndBankNameObject.rate}%`}
                {` + ${t('your margin')}: ${preciseFixRateNumber(
                  new BigNumber(preciseRateMultiplyByHundred(interestRate as number, 10))
                    .minus(selectedRateAndBankNameObject.rate)
                    .toFixed(),
                  10
                )}%)`}
              </StyledSpan>
            ) : null}
          </React.Fragment>,
        ],
      },

    tierBandCalcMethod &&
      [RateTierBand.BAND, RateTierBand.TIER].includes(tierBandCalcMethod) &&
      !isNullOrUndefined(interestTierBand) &&
      !isEmpty(interestTierBand) && {
        term: tierBandCalcMethod === RateTierBand.TIER ? t('Tiers') : t('Bands'),
        definitions: [
          interestTierBand?.map((item) => (
            <div key={`${item.endRange}-${item.startRange}`}>
              <CurrencySymbol
                typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                currencyCode={currencyOfProduct}
              />
              {new Intl.NumberFormat('en-GB').format(item.startRange || 0)}
              {!isNullOrUndefined(item.endRange) && (
                <>
                  {' – '}
                  <CurrencySymbol
                    typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                    currencyCode={currencyOfProduct}
                  />
                  {new Intl.NumberFormat('en-GB').format(item.endRange || 0)}
                </>
              )}
              {!isNullOrUndefined(item.tierBandRate) ? (
                <span>{` = ${preciseRateMultiplyByHundred(item.tierBandRate || 0, 10)}% `}</span>
              ) : null}
              {selectedRateAndBankNameObject !== null &&
              selectedRateAndBankNameObject.bankName &&
              selectedRateAndBankNameObject.rate &&
              !isNullOrUndefined(item.tierBandRate) ? (
                <StyledSpan>
                  {` (${selectedRateAndBankNameObject.bankName}: ${selectedRateAndBankNameObject.rate}%`}
                  {` + ${t('your margin')}: ${preciseFixRateNumber(
                    new BigNumber(preciseRateMultiplyByHundred(item.tierBandRate || 0, 10))
                      .minus(selectedRateAndBankNameObject.rate)
                      .toFixed(),
                    10
                  )}%)`}
                </StyledSpan>
              ) : null}
            </div>
          )),
        ],
      },
  ].filter((item) => item)
}

export default getVariableDefinitions
