import styled from 'styled-components/macro'
import { Stepper } from '@material-ui/core'

export const StyledStepper = styled(Stepper)`
  padding: 0;
  width: 100%;
`

export const ProductStepperContainer = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
`
