import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.review.type]: (state: ProductsState) => ({
    ...state,
    review: true,
  }),
  [actions.reviewSuccess.type]: (state: ProductsState, action: ReturnType<typeof actions.reviewSuccess>) => ({
    ...state,
    selectedProduct: action.payload,
    review: false,
  }),
  [actions.reviewFail.type]: (state: ProductsState, action: ReturnType<typeof actions.reviewFail>) => ({
    ...state,
    reviewError: action.payload,
    review: false,
  }),
  [actions.reviewGeneralFail.type]: (state: ProductsState, action: ReturnType<typeof actions.reviewGeneralFail>) => ({
    ...state,
    reviewGeneralError: action.payload,
    review: false,
  }),
  [actions.reviewFailNoUser.type]: (state: ProductsState, action: ReturnType<typeof actions.reviewFailNoUser>) => ({
    ...state,
    review: false,
    reviewNoUserError: action.payload,
  }),

  [actions.clearReviewErrors.type]: (state: ProductsState) => ({
    ...state,
    reviewNoUserError: undefined,
    reviewGeneralError: undefined,
    reviewError: undefined,
  }),
}
export default reducer
