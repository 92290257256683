import React from 'react'
import { useTheme } from '@material-ui/core'

interface OwnProps {
  type?: 'light' | 'default' | 'strong'
  customStyles?: React.CSSProperties
  sectionBrake?: boolean
  color?: 'light' | 'dark'
}

const Line = ({ type = 'default', color = 'light', customStyles = {}, sectionBrake = false, ...props }: OwnProps) => {
  const theme = useTheme()
  const lineColor = color === 'light' ? theme.palette.text.disabled : theme.palette.text.secondary
  const defaultStyles: React.CSSProperties = {
    color: lineColor,
    borderColor: lineColor,
    width: '100%',
    margin: 0,
    borderStyle: 'strong',
    ...(type !== 'strong' && { borderTopStyle: 'none' }),
    marginTop: sectionBrake ? 40 : undefined,
    marginBottom: sectionBrake ? 40 : undefined,
  }

  const styles = {
    ...defaultStyles,
    ...customStyles,
  }

  return <hr data-dls="atom-line" style={styles} {...props} />
}

export default Line
