import React from 'react'
import { TFunction } from 'i18next'
import { Link as RouterLink } from 'react-router-dom'
import { getEditUrl, addUrls } from './actionUrls'
import DefaultSubValue from 'pages/features/CardTransactionLimits/components/shared/DefaultSubValue'
import CustomerCanManageSubValue from 'pages/features/CardTransactionLimits/components/shared/CustomerCanManageSubValue'
import { CardTransactionLimit } from 'store/products/typings/cardLimits'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'
import { DesignIconButton } from 'dls'

type BuildDefinitionsConfig = {
  perNonCashTransaction?: CardTransactionLimit
  cumulativeNonCashDaily?: CardTransactionLimit
  currencySymbol?: string
  productKey: string
  t: TFunction
  hasSeparators: boolean
  showActions: boolean
  ariaLabelledBy: string
}

const buildDefinitions = ({
  perNonCashTransaction,
  cumulativeNonCashDaily,
  currencySymbol = '',
  t,
  hasSeparators,
  showActions,
  ariaLabelledBy,
}: BuildDefinitionsConfig): ReviewRowGroup[] => [
  {
    ariaLabelledBy,
    definitions: [
      {
        itemSeparator: hasSeparators ? 'light' : undefined,
        term: t('Per non-cash transaction'),
        definitions: [
          <DefaultSubValue
            key={`cumulativeDay-${perNonCashTransaction?.subscriptionOverrideMaximum}`}
            labelKey="Default"
            cardLimit={perNonCashTransaction}
            currencySymbol={currencySymbol}
          />,
          <CustomerCanManageSubValue
            key={`cumulativeDay-${perNonCashTransaction?.enableSubscriptionOverride}`}
            labelKey="Customer can manage"
            noValueContent={t('No')}
            cardLimit={perNonCashTransaction}
            currencySymbol={currencySymbol}
          />,
          <>
            {showActions && (
              <DesignIconButton
                key={`editLink-${perNonCashTransaction?.limitRuleId}`}
                aria-controls="main"
                component={RouterLink}
                to={
                  perNonCashTransaction?.limitRuleId
                    ? getEditUrl(perNonCashTransaction?.limitRuleId)
                    : addUrls.perNonCashTransaction
                }
              />
            )}
          </>,
        ],
      },
      {
        term: t('Cumulative non-cash daily '),
        definitions: [
          <DefaultSubValue
            key={`cumulativeDay-${cumulativeNonCashDaily?.subscriptionOverrideMaximum}`}
            labelKey="Default"
            cardLimit={cumulativeNonCashDaily}
            currencySymbol={currencySymbol}
          />,
          <CustomerCanManageSubValue
            key={`cumulativeDay-${cumulativeNonCashDaily?.enableSubscriptionOverride}`}
            labelKey="Customer can manage"
            noValueContent={t('No')}
            cardLimit={cumulativeNonCashDaily}
            currencySymbol={currencySymbol}
          />,
          <>
            {showActions && (
              <DesignIconButton
                key={`editLink-${cumulativeNonCashDaily?.limitRuleId}`}
                component={RouterLink}
                aria-controls="main"
                to={
                  cumulativeNonCashDaily?.limitRuleId
                    ? getEditUrl(cumulativeNonCashDaily?.limitRuleId)
                    : addUrls.cumulativeNonCashDaily
                }
              />
            )}
          </>,
        ],
      },
    ],
  },
]

export default buildDefinitions
