import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Box, useTheme } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { EntityBadgeDevices, EntityBadgeFutureRate } from 'dls/atoms'
import ColleagueOutBoundLimits from 'pages/features/PaymentLimits/components/shared/ColleagueOutBoundLimits'
import FromAnySourceRow from 'pages/features/PaymentLimits/components/shared/FromAnySourceRow'
import { InfoPanel, ReviewSubheader, Paper, H3, Body1, DesignIconButton, CardHeader, ContextualHelp } from 'dls'
import { PaymentLimit, PaymentLimitsConfig } from 'store/products/typings/paymentLimits'
import TransferToPartyRow from 'pages/features/PaymentLimits/components/shared/TransferToPartyRow'
import TransferToSelfRow from 'pages/features/PaymentLimits/components/shared/TransferToSelfRow'
import { formatConfigTaxYear } from 'pages/features/PaymentLimits/utilities/transformers'

interface OwnProps {
  config: PaymentLimitsConfig
  paymentLimits: PaymentLimit[]
  currencyCode: string
  productKey: string
}

const PaymentLimitsPageContent = ({
  config: { yearType, taxYearStart },
  paymentLimits,
  currencyCode,
  productKey,
}: OwnProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Grid container direction="column" spacing={3} style={{ paddingTop: 0 }}>
      <Grid item>
        <InfoPanel
          color="info"
          body={t('Please note – payment schemes have their own limits that will override limits set here')}
        />
      </Grid>
      <Grid item>
        <InfoPanel color="info" body={t('The card limits feature will be coming soon')} />
      </Grid>
      <Grid item>
        <Paper>
          <ReviewSubheader
            title={
              <CardHeader
                title={
                  <>
                    {t('Year definition')}:{' '}
                    {t(`PAYMENT_LIMITS.${yearType}`, {
                      taxYearStart: t(`starting ${formatConfigTaxYear(taxYearStart || '')}`),
                    })}
                  </>
                }
                headerIcon={<EntityBadgeFutureRate />}
              />
            }
            IconButtonProps={[
              <ContextualHelp
                key="year-definition-help"
                Icon={HelpOutlineIcon}
                ariaProps={{
                  'aria-hidden': false,
                  'aria-label': 'Help',
                }}
                id="ContextualHelp"
                interactive
                style={{
                  maxWidth: 100,
                  outline: 'none',
                  color: theme.palette.text.primary,
                }}
                title="Any payment limits set for 'year' will use this year
definition. You can change this to Anniversary date of account opening, calendar year or tax year"
              />,
              <DesignIconButton
                key="codeIcon"
                style={{ color: theme.palette.text.primary }}
                ariaProps={{ 'aria-label': 'Edit' }}
                title="Edit"
                data-test-id="edit-year-definition"
                color="inherit"
                component={RouterLink}
                to="year-definition"
              />,
            ]}
          />
        </Paper>
      </Grid>
      <Grid item>
        <H3 variantMapping={{ h3: 'h2' }}>{t('OUTBOUND')}</H3>
      </Grid>
      <Grid item>
        <Paper>
          <CardHeader
            title={<Body1 variantMapping={{ body1: 'h3' }}>{t('Customer outbound limits')}</Body1>}
            headerIcon={<EntityBadgeDevices />}
          />
          <Box mb={2} mt={3.5}>
            <TransferToSelfRow
              paymentLimits={paymentLimits}
              currencyCode={currencyCode}
              hasSeparators
              showActions
              productKey={productKey}
            />
          </Box>
          <TransferToPartyRow
            paymentLimits={paymentLimits}
            currencyCode={currencyCode}
            hasSeparators
            showActions
            productKey={productKey}
          />
        </Paper>
      </Grid>
      <Grid item>
        <ColleagueOutBoundLimits />
      </Grid>
      <Grid item>
        <H3 variantMapping={{ h3: 'h2' }}>{t('INBOUND')}</H3>
      </Grid>
      <Grid item>
        <FromAnySourceRow
          paymentLimits={paymentLimits}
          currencyCode={currencyCode}
          hasSeparators
          showActions
          productKey={productKey}
        />
      </Grid>
    </Grid>
  )
}

export default PaymentLimitsPageContent
