import { DialogActions, Grid, Typography } from '@material-ui/core'
import { Field, Form, FormikProps, getIn } from 'formik'
import styled from 'styled-components/macro'
import moment, { Moment } from 'moment-timezone'
import React from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { DateTimePickerComponent, ModalWrapper, SubmitButton } from 'components'
import { withFormikSimple } from 'utils/form.utils'
import { PublishPackageFormValues } from 'store/packages/types'
import { toBankTz } from 'utils/date.utils'
import { FloorType } from 'utils/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  setOpen: (value: boolean) => void
  minEffectiveDate: Moment
}

type Props = FormikProps<PublishPackageFormValues> & OwnProps

const StyledTypography = styled(Typography)`
  margin-bottom: 12px;
`

const StyledDialogActions = styled(DialogActions)`
  padding-top: 20px;
  padding-right 0px;
`
const Header = styled.div`
  padding-bottom: 6px;
`

const PublishPackageForm = (props: Props) => {
  const { setOpen, minEffectiveDate } = props
  const { t } = useTranslation()

  return (
    <Form>
      <ModalWrapper
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Header>
            <StyledTypography variant="h6" gutterBottom>
              {t('Publish package')}
            </StyledTypography>
            <StyledTypography variant="body2" gutterBottom>
              {t(
                'Enter the effective date & time (when your package will be available to customers). The date must be equal or greater than the effective date of products within this package. The time must be outside of key platform operations (23:00–03:00).',
                { nsSeparator: '||' }
              )}
            </StyledTypography>
          </Header>
        }
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Field
              helperText={t('This is when your new or updated package is available')}
              placeholder={t('Select Date')}
              name="publicationDate"
              clearable
              variant="inline"
              round={FloorType.SECONDS}
              component={DateTimePickerComponent}
              pickerType="DateTime"
              label={`${t('Effective date & time')} (${toBankTz(getIn(props.values, `publicationDate`)).tz})`}
              required
              touchedNotNeeded
              dateTimePickerProps={{
                minDateMessage: 'Date and time can’t be in the past',
                minDateTime: minEffectiveDate,
              }}
              shouldDisableDate={(date: Moment) => !(date && date.isSameOrAfter(minEffectiveDate, 'day'))}
              InputLabelProps={{ style: { width: 300 } }}
            />
          </Grid>
        </Grid>
        <StyledDialogActions>
          <Button disabled={false} onClick={() => setOpen(false)} color={ColorType.BUTTON}>
            {t('Cancel')}
          </Button>
          <SubmitButton
            css="margin-left:16px !important; width:150px;"
            variant="contained"
            color={ColorType.BUTTON}
            noDirty
          >
            {t('Publish')}
          </SubmitButton>
        </StyledDialogActions>
      </ModalWrapper>
    </Form>
  )
}

export function validatePublicationDatePast(this: Yup.TestContext, dateValue: Moment): boolean {
  return !(dateValue && dateValue.isBefore(moment()))
}

export function validatePublicationDateMaintenanceForm(dateValue: Moment): boolean {
  return !(
    dateValue &&
    (dateValue.hours() >= 23 || dateValue.hours() < 3 || (dateValue.hours() === 3 && dateValue.minutes() === 0))
  )
}

export function validatePublicationDateMaintenance(this: Yup.TestContext, dateValue: Moment): boolean {
  return validatePublicationDateMaintenanceForm(dateValue)
}

export default withFormikSimple<OwnProps, PublishPackageFormValues>(PublishPackageForm, {
  validationSchema: Yup.object<Partial<PublishPackageFormValues>>({
    publicationDate: Yup.mixed()
      .test('validationSchema-check-past', 'Date and time can’t be in the past', validatePublicationDatePast)
      .test(
        'validationSchema-check-maintenance',
        'Time must be outside of key platform operations (23:00-03:00)',
        validatePublicationDateMaintenance
      ),
  }).required(),
  isInitialValid: (props: Partial<Props>) =>
    !!props.initialValues &&
    !!props.initialValues.publicationDate &&
    validatePublicationDateMaintenanceForm(props.initialValues.publicationDate),
})
