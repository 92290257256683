import React from 'react'
import { FormHelperText } from '@material-ui/core'
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, getIn } from 'formik'
import styled from 'styled-components/macro'
import { AddCircle } from '@material-ui/icons/'
import { useTranslation } from 'react-i18next'
import SectionArray from './SectionArray'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { RemoveCircleButton } from 'dls/molecules/IconButtons/IconButtons'

const NewItemButton = styled(Button)`
  margin-top: 8px;
  padding-left: 0;
  margin-left: -2px;
  color: ${(props: any) => `${props.theme.palette.text.secondary}`};
`

const RowGridContainer = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 40px;
`

const ItemErrorMessage = ({ name }: { name: string }) => {
  const { t } = useTranslation()
  return (
    <Field name={name}>
      {({ form }: FieldProps) => {
        const error = getIn(form.errors, name)
        const touch = getIn(form.touched, name)
        return touch && error && typeof error === 'string' ? <FormHelperText error>{t(error)}</FormHelperText> : null
      }}
    </Field>
  )
}

const RepeaterLayout = ({
  name: repeaterName,
  labelDelete,
  labelNewItem,
  newItem,
  children,
  isItemDeletable,
  disabled = false,
  isAddDisabled = false,
  deleteButtonStyle,
  newItemButtonStyle,
}: {
  name: string
  labelDelete: string
  labelNewItem: string
  newItem: object // because the item can be anything
  children?: (props: FieldArrayRenderProps & { index: number }) => React.ReactNode
  isItemDeletable?: (props: FieldArrayRenderProps & { index: number }) => boolean
  disabled?: boolean
  isAddDisabled?: boolean
  deleteButtonStyle?: React.CSSProperties
  newItemButtonStyle?: React.CSSProperties
}) => (
  <>
    <SectionArray name={repeaterName}>
      {(arrayHelpers) => (
        <React.Fragment key={arrayHelpers.index}>
          <RowGridContainer>
            {children && children(arrayHelpers)}
            <div>
              {!disabled && (!isItemDeletable || isItemDeletable(arrayHelpers)) && (
                <RemoveCircleButton
                  key={arrayHelpers.index}
                  onClick={() => arrayHelpers.remove(arrayHelpers.index)}
                  aria-label={labelDelete}
                  style={deleteButtonStyle}
                />
              )}
            </div>
          </RowGridContainer>
          <ItemErrorMessage name={arrayHelpers.name} />
        </React.Fragment>
      )}
    </SectionArray>
    {!disabled && (
      <FieldArray name={repeaterName}>
        {({ push }) => (
          <NewItemButton
            disabled={isAddDisabled}
            onClick={() => push(newItem)}
            style={newItemButtonStyle}
            color={ColorType.NONE}
          >
            <AddCircle
              css={`
                margin-right: 8px;
              `}
            />
            {labelNewItem}
          </NewItemButton>
        )}
      </FieldArray>
    )}
  </>
)
export default RepeaterLayout
