import React from 'react'
import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components'
import { PackagesInfoGraphic } from 'assets'
import { HelpModalType } from 'store/modal/types'

export default () => {
  const { t } = useTranslation()
  return (
    <SidePanel
      heading={t('ABOUT PACKAGES')}
      imageAlt={t('Illustration of a customer viewing a packages in their mobile app.')}
      image={PackagesInfoGraphic}
      details={t(
        'Packages provide the capability to expose single or multiple products to a location within a distribution channel e.g. a mobile app.'
      )}
      linkToOpenModal={t('More about packages')}
      infographicType={HelpModalType.PACKAGE}
    />
  )
}
