import { CompositeComponent, FormikProps, withFormik, WithFormikConfig } from 'formik'
import BigNumber from 'bignumber.js'
import * as Yup from 'yup'
import { toPath } from 'lodash'
import { memo } from 'react'
import { TFunction } from 'i18next'
import { MAX_END_RANGE, MAX_END_RANGE_DEBIT_INTEREST } from 'store/products/types'
import { getFormattedCurrency } from 'utils/ui.utils'
import { PrecisionScaleDecimals } from 'utils/types'

export const isPromise = <T>(subject: unknown & { then?: VoidFunction }): subject is Promise<T> =>
  typeof subject.then === 'function'

export function Yip<S>(schema: Yup.Schema<S>, isAsync = true) {
  const validator = schema[isAsync ? 'validate' : 'validateSync'].bind(schema)
  return (...args: Parameters<typeof validator>) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // no idea how to fix this
      const result = validator(...args)
      return isPromise(result) ? result.then(() => undefined).catch((e) => e.errors as string[]) : undefined
    } catch (e: any) {
      return e.errors as string[]
    }
  }
}

/* istanbul ignore next */
export const withFormikSimple = <OuterProps, FormValues>(
  component: CompositeComponent<
    OuterProps & {
      initialValues: Partial<FormValues>
      onSubmit(values: FormValues): void
    } & FormikProps<FormValues>
  >,
  additionalOptions: Omit<WithFormikConfig<OuterProps, FormValues>, 'mapPropsToValues' | 'handleSubmit'> & {
    defaultValues?: Partial<FormValues>
  } = { defaultValues: {} }
) =>
  withFormik<
    OuterProps & {
      initialValues: Partial<FormValues> | null
      onSubmit(values: FormValues): void
    },
    FormValues
  >({
    mapPropsToValues: (props) =>
      // we want to hide the error here, as we don't care if it's partial
      // will cause uncontrolled => controlled warning
      // eventually we could be able to supply a full initial for all forms but it's currently not worth the effort
      // eslint-disable-next-line
      ({
        ...additionalOptions.defaultValues,
        ...(props.initialValues || {}),
      } as FormValues),
    handleSubmit: (values, { props, setSubmitting }) => {
      props.onSubmit(values)
      // eslint-disable-next-line no-console
      console.warn(
        'Calling setSubmitting(false) in `handleSubmit` until we figure out the proper way to hook it to redux / sagas'
      )
      setSubmitting(false)
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    ...additionalOptions,
  })(memo(component) as any)

/* istanbul ignore next */

export const constructRootName = (appendedString: string, rootName?: string): string => {
  if (rootName) {
    return `${rootName}.${appendedString}`
  }
  return `${appendedString}`
}

export function cascadeValidationForTierBand(
  this: Yup.TestContext,
  item: { startRange: number; endRange: number }
): boolean {
  const itemIndex = +toPath(this.path)[1]
  if (itemIndex && this.parent) {
    for (let i = 0; i < this.parent.length; i += 1) {
      if (
        itemIndex !== i &&
        itemIndex > i &&
        this.parent[i] &&
        this.parent[i].endRange &&
        this.parent[i].startRange &&
        item.endRange &&
        ((item.startRange <= this.parent[i].endRange && item.startRange >= this.parent[i].startRange) ||
          (item.endRange <= this.parent[i].endRange && item.endRange >= this.parent[i].startRange) ||
          (item.startRange <= this.parent[i].startRange && item.endRange > this.parent[i].endRange))
      ) {
        return false
      }
    }
  }
  return true
}

export const validationForTierBandStartRangeEndRangeTax = (errorMsg: string) =>
  Yup.object({
    endRange: Yup.number()
      .when(['startRange'], {
        is: (startRange) => !!startRange,
        then: (endRange: Yup.NumberSchema) => endRange.moreThan(Yup.ref('startRange'), errorMsg),
      })
      .typeError('This field must be a number'),
    startRange: Yup.number().required('This is a required field'),
    tierBandRate: Yup.number().required('This is a required field'),
  })

export const validationForTierBandStartRangeEndRangeCreditInterest = (
  t: TFunction,
  currencyCode: string,
  errorMsg: string
) =>
  Yup.object({
    endRange: Yup.number()
      .when(['startRange'], {
        is: (startRange) => new BigNumber(startRange).isGreaterThanOrEqualTo(0),
        then: (endRange: Yup.NumberSchema) => endRange.moreThan(Yup.ref('startRange'), errorMsg),
      })
      .typeError(t('An upper balance is required for the preceding tier'))
      .required('This is a required field')
      .max(
        MAX_END_RANGE,
        t('MAX_VALUE_ERROR', {
          maxValue: getFormattedCurrency(MAX_END_RANGE, currencyCode, false),
        })
      ),
    startRange: Yup.number().required('This is a required field'),
  })

export const validationForTierBandStartRangeEndRangeDebitInterest = (
  t: TFunction,
  currencyCode: string,
  errorMsg: string
) =>
  Yup.object({
    endRange: Yup.string()
      .when(['startRange'], {
        is: (startRange) => new BigNumber(startRange).isGreaterThanOrEqualTo(0),
        then: (endRange: Yup.StringSchema) =>
          endRange.test('greater-than-start', errorMsg, function (value) {
            return new BigNumber(value).isGreaterThan(new BigNumber(this.parent.startRange))
          }),
      })
      .typeError(t('An upper balance is required for the preceding tier'))
      .required('This is a required field')
      .test(
        'max',
        t('MAX_VALUE_ERROR', {
          maxValue: MAX_END_RANGE_DEBIT_INTEREST,
        }),
        (value) => new BigNumber(value).isLessThanOrEqualTo(new BigNumber(MAX_END_RANGE_DEBIT_INTEREST))
      ),
    startRange: Yup.string().required('This is a required field'),
  })

export function stopPropagation(e: null | React.MouseEvent | React.ChangeEvent<HTMLInputElement>) {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }
}

// eslint-disable-next-line consistent-return
export const generateMonth = (month: number) => {
  if (month === 1) {
    return 'January'
  }
  if (month === 2) {
    return 'February'
  }
  if (month === 3) {
    return 'March'
  }
  if (month === 4) {
    return 'April'
  }
  if (month === 5) {
    return 'May'
  }
  if (month === 6) {
    return 'June'
  }
  if (month === 7) {
    return 'July'
  }
  if (month === 8) {
    return 'August'
  }
  if (month === 9) {
    return 'September'
  }
  if (month === 10) {
    return 'October'
  }
  if (month === 11) {
    return 'November'
  }
  if (month === 12) {
    return 'December'
  }
}

export const getNumberOfDecimals = (x: string | number) => {
  const number = new BigNumber(x).toFixed()

  return number.split('.')[1]?.length || 0
}

const preciseMultiplyByHundred = (rate: string | number) => {
  const r = new BigNumber(rate)
  const multiple = new BigNumber(100)
  return r.multipliedBy(multiple)
}

const preciseDivideByHundred = (rate: string | number) => {
  const r = new BigNumber(rate)
  const devisor = new BigNumber(100)
  return r.dividedBy(devisor)
}

export function preciseRateMultiplyByHundred(rate: string | number, precision: number = 6) {
  const byHundred = preciseMultiplyByHundred(rate)
  return byHundred.dp(precision).toFixed()
}

export function preciseRateDivideByHundred(rate: string | number, precision: number = 6) {
  const quotient = preciseDivideByHundred(rate)
  return quotient.dp(precision).toFixed()
}

export function RateDividedByHundred(rate: string | number, precision: number = 6) {
  const r = new BigNumber(rate)
  const devisor = new BigNumber(100)
  const quotient = r.dividedBy(devisor)
  return quotient.dp(precision).toNumber()
}

export function RateMultiplyByHundred(rate: string | number, precision: number = 6) {
  const byHundred = preciseMultiplyByHundred(rate)
  return byHundred.dp(precision).toNumber()
}

export function fixRateNumber(rate: number, precision: number = 6) {
  return +RateDividedByHundred(RateMultiplyByHundred(rate, precision), precision)
}

export function preciseFixRateNumber(rate: string | number, precision = 6) {
  return preciseRateDivideByHundred(preciseRateMultiplyByHundred(rate, precision), precision)
}

const getMax = (precision: PrecisionScaleDecimals, max: number) => {
  const maxValue = max - 1
  const maxValueLength = `${maxValue}`.length + precision + 1 // 1 for decimal point
  return +`${maxValue}.`.padEnd(maxValueLength, '9')
}

export const rateYupValidation = (precision: PrecisionScaleDecimals = PrecisionScaleDecimals.two, maxValue = 100) =>
  Yup.number()
    .test(
      'too-bigdecimal',
      `Maximum of ${precision} decimal places`,
      (value) => getNumberOfDecimals(value) <= precision
    )
    .test('too-big', `Rate must be below ${maxValue}%`, (value) => value <= getMax(precision, maxValue))
    .test('too-small', `Rate must be greater than -${maxValue}%`, (value) => value >= -getMax(precision, maxValue))
    .typeError('This field must be numeric')

export const ratePositiveYupValidation = (
  precision: PrecisionScaleDecimals = PrecisionScaleDecimals.two,
  maxValue = 100,
  greaterThanZero = false
) =>
  Yup.number()
    .test(
      'too-bigdecimal',
      `Maximum of ${precision} decimal places`,
      (value) => getNumberOfDecimals(value) <= precision
    )
    .test('too-big', `Rate must be below ${maxValue}%`, (value) => value <= getMax(precision, maxValue))
    .test(
      'too-small',
      greaterThanZero ? 'Rate must be greater than 0%' : 'Cannot be negative',
      greaterThanZero ? (value) => value > 0 : (value) => value >= 0
    )
    .typeError('This field must be numeric')

export const convertEmptyNumberToNull = (parsedValue: any, originalValue: any) =>
  originalValue === '' ? null : parsedValue
