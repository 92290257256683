import React, { useEffect } from 'react'
import { DialogActions, DialogContent, Tooltip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useField } from 'formik'
import { grey } from '@material-ui/core/colors'
import StatementSampleImage from '../StatementSampleImage'
import downloadTemplatePdf from '../utils/downloadTemplatePdf'
import { StyledCard, StyledCardActionArea, StyledCheck, StyledGrid, TypographyItem } from 'components/ImageCard/Styled'
import ApiStatesDialogsManager from 'components/ApiStatesDialogsManager'
import { NoResults } from 'assets'
import { StandardModal } from 'components'
import useStatementTemplate from 'pages/features/Statements/ChooseTemplateDialog/useStatementTemplate'
import { ProductSegment, StatementTemplate, StatementType } from 'store/products/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { DownloadIconButton } from 'dls/molecules/IconButtons/IconButtons'
import { Template } from 'store/statement/types'

interface Props {
  open: boolean
  closeDialog: (template?: StatementTemplate) => void
  statementType: StatementType
  productSegment: ProductSegment
  name: string
}

const CardsGrid = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 13px;
  padding: 13px;
  margin: 0 24px;
  .MuiCard-root {
    width: 300px;
    height: 450px;
    button > div.MuiGrid-root {
      height: 400px;
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const StyledDialogActions = styled(DialogActions)`
  padding: 24px;
`

const CardsDialogContent = styled(DialogContent)`
  padding: 0;
`

const CardBottomBar = styled(TypographyItem)<{ $isSelected: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => `1fr repeat(${props.$isSelected ? 2 : 1}, auto) 1fr`};
  grid-column-gap: 5px;
  justify-items: center;
  border-top: ${`1px solid ${grey[400]}`};
  svg {
    grid-column-start: 2;
  }
  p {
    grid-column-start: ${(props) => (!props.$isSelected ? 2 : 'auto')};
  }
  button {
    margin-left: auto;
    color: ${(props: any) => `${props.theme.palette.text.primary}`};
  }
`

const ChooseTemplateDialog = ({ closeDialog, open, statementType, productSegment, name }: Props) => {
  const { t } = useTranslation()
  const [field, , helpers] = useField(name)
  if (!field) return null

  const { templates, isLoading, error, selectedTemplate, getTemplates, setSelectedTemplate } = useStatementTemplate(
    productSegment,
    field.value
  )
  const statementTemplates = statementType && templates[statementType]
  const onChoiceClick = () => {
    helpers.setValue({
      id: selectedTemplate?.id,
      name: selectedTemplate?.name,
    })
    closeDialog()
  }

  useEffect(() => {
    getTemplates(statementType)
  }, [])

  return (
    <ApiStatesDialogsManager
      error={error && statementType && error[statementType]}
      isLoading={isLoading}
      onBackClick={closeDialog}
      loadingDialog={{
        title: t('Retrieving statement templates'),
        subtitle: t('Please wait'),
      }}
      customDialog={{
        title: t('We’re sorry, no templates are available'),
        subtitle: t('Please go back and try again. If this problem persist please raise a \nZendesk support ticket'),
        imageSrc: NoResults,
        open: statementTemplates.length === 0,
      }}
    >
      <StandardModal
        maxWidth="xl"
        title={t('Choose template')}
        open={open}
        onClose={closeDialog}
        infoText={t(
          'Currently, templates can’t be changed or added in Bank Manager. This can be done upon request to 10x.'
        )}
      >
        <>
          <CardsDialogContent>
            <CardsGrid>
              {statementTemplates.map((template: Template) => {
                const isSelected = template.templateGroupId === selectedTemplate?.id
                const onClick = () => setSelectedTemplate({ id: template.templateGroupId, name: template.templateName })
                const title = template.templateName
                return (
                  <StyledCard key={template.templateGroupId} $isSelected={isSelected} $isCondensed={!title}>
                    <StyledCardActionArea onClick={onClick} aria-selected={isSelected}>
                      <StyledGrid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        $isCondensed={!title}
                      >
                        <StatementSampleImage
                          template={template}
                          templateType={statementType}
                          productSegment={productSegment}
                        />
                      </StyledGrid>
                      <CardBottomBar $isSelected={isSelected}>
                        {isSelected && <StyledCheck color="secondary" />}
                        <Typography variant="body2">{title}</Typography>
                        <Tooltip title={`${t('Download PDF')}`} placement="bottom">
                          <DownloadIconButton
                            aria-label={t('Download statement as a PDF')}
                            onClick={() =>
                              downloadTemplatePdf(productSegment, statementType, {
                                id: template.templateGroupId,
                                name: template.templateName,
                              })
                            }
                          />
                        </Tooltip>
                      </CardBottomBar>
                    </StyledCardActionArea>
                  </StyledCard>
                )
              })}
            </CardsGrid>
          </CardsDialogContent>
          <StyledDialogActions>
            <Button onClick={() => closeDialog()} color={ColorType.BUTTON} variant="text">
              {t('Cancel')}
            </Button>
            <Button onClick={onChoiceClick} color={ColorType.BUTTON} variant="contained" disabled={!selectedTemplate}>
              {t('Choose')}
            </Button>
          </StyledDialogActions>
        </>
      </StandardModal>
    </ApiStatesDialogsManager>
  )
}
export default ChooseTemplateDialog
