// eslint-disable-next-line import/extensions, import/no-unresolved
import { ContentCanvas, ContentStack } from 'pdfmake/interfaces'
import { TFunction } from 'react-i18next'
import {
  FEATURE_TOP_BOTTOM_MARGIN,
  featureSeparator,
  getFeatureTitleAndIcon,
  getSubtitle,
  itemSeparator,
} from './utils'
import { Mastercard, UnionPay, Visa } from 'assets'
import { TenantConfig } from 'store/tenant-config/types'

export const cardLogo = {
  VISA: Visa,
  MASTERCARD: Mastercard,
  UNIONPAY: UnionPay,
}

const getBase64ImageFromURL = (url: string) =>
  fetch(url)
    .then((response) => {
      if (response.ok === true) {
        if (response.headers.get('Content-Length') === '0') throw new Error(`Empty response`)
        return response.blob()
      }
      throw new Error(`Response failed`)
    })
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('getBase64ImageFromURL failed', err)
    })

const printFunctions = async (product: any, tenantConfig: TenantConfig, t: TFunction) => {
  const cardsRows = await Promise.all(
    [].concat(
      ...product.cards.map(async (card: any) => {
        const logoPromise = await fetch(cardLogo[card.scheme])
        const logo = await logoPromise.text()
        const cardFormType = t(`${card.formType}_LABEL`)
        const cardImageBase64 =
          card.frontTemplateImage &&
          card.frontTemplateImage.url &&
          (await getBase64ImageFromURL(card.frontTemplateImage.url))

        const name = card.name ? card.name : 'N/A'
        const formType = card.formType ? cardFormType : 'N/A'
        const description = card.description ? card.description : 'N/A'
        const scheme = card.scheme ? card.scheme : 'N/A'
        const maximumNumber = card.maximumNumber ? card.maximumNumber : 'N/A'
        const processorId = card.processorId ? card.processorId : 'N/A'
        const processorLogoId = card.processorLogoId ? card.processorLogoId : 'N/A'

        return [
          getSubtitle(`${name} - ${formType}`),
          {
            layout: 'noBorders',
            table: {
              widths: ['30%', '40%', 'auto'],
              body: [
                [
                  t('What customer will see'),
                  `${name}\n${description}`,
                  card.frontTemplateImage && card.frontTemplateImage.url && cardImageBase64
                    ? {
                        image: cardImageBase64,
                        fit: [150, 100],
                      }
                    : {},
                ],
              ],
            },
          },
          itemSeparator(),
          {
            layout: 'noBorders',
            table: {
              widths: ['30%', '70%'],
              body: [
                [
                  t('Scheme'),
                  {
                    layout: 'noBorders',
                    table: {
                      body: [[{ svg: logo, fit: [20, 16] }, scheme]],
                    },
                    margin: 0,
                    padding: 0,
                  },
                ],
                [t('Maximum number of cards'), `${maximumNumber} `],
                [t('Processor product ID'), `${processorId} `],
                [t('Processor logo ID'), `${processorLogoId} `],
              ],
            },
          },
          itemSeparator(),
          {
            layout: 'noBorders',
            table: {
              widths: ['30%', '70%'],
              body: [
                [
                  t('How the card can be used'),
                  'ATM, Chip & PIN, CNP (Card not present - online, mobile \n' +
                    'and over the phone), Contactless, International, MagStripe',
                ],
              ],
            },
          },
        ]
      })
    )
  )

  return {
    unbreakable: false,
    stack: [
      { ...getFeatureTitleAndIcon('Cards', 'cards', t) } as ContentStack,
      ...cardsRows,
      { ...featureSeparator() } as ContentCanvas,
    ],
    margin: [0, FEATURE_TOP_BOTTOM_MARGIN, 0, FEATURE_TOP_BOTTOM_MARGIN],
    style: 'featureBlock',
  }
}

export default printFunctions
