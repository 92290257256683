import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DeleteRetireForm from './DeleteRetireForm'
import { DeleteOrRetire, RetireOrDeleteProductOrPackage } from 'store/products/types'

interface DeleteProps {
  open: boolean
  productOrPackageKey: string
  productOrPackageName: string
  actionType: DeleteOrRetire

  closeDialog(e: React.MouseEvent): void

  handleDeleteOrRetire(values: RetireOrDeleteProductOrPackage): void
}

const actionLabels = {
  DELETEPRODUCT: 'Delete',
  DELETEPACKAGE: 'Delete',
  RETIREPRODUCT: 'Retire',
}
export default function ConfirmRetireProductDialog({
  open,
  productOrPackageKey,
  productOrPackageName,
  closeDialog,
  handleDeleteOrRetire,
  actionType,
}: DeleteProps) {
  const { t } = useTranslation()
  return (
    <>
      <Dialog open={open} onClose={closeDialog} aria-labelledby="confirm-delete-retire-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="confirm-retire-dialog-title">
          {t(`${actionLabels[actionType]} {{productOrPackageName}}`, {
            productOrPackageName,
            interpolation: { escapeValue: false },
          })}
        </DialogTitle>
        <DialogContent>
          {t(actionType)}
          <DeleteRetireForm
            onSubmit={handleDeleteOrRetire}
            setClosed={closeDialog}
            actionType={actionType}
            initialValues={{
              message: '',
              productOrPackageKey,
              productOrPackageName,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
