import React from 'react'
import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TaxRate from './TaxRate'
import { EntityBadgeFutureRate } from 'dls/atoms'
import EntitySectionCard from 'pages/Layout/EntityReviewPageLayout/EntitySectionCard'
import { WithholdingTaxRule } from 'store/withholdingTax/types'
import { toBankTz } from 'utils/date.utils'
import { AddCircleIconButton, Body1, ReviewRows } from 'dls'
import { Separator } from 'dls/molecules/ReviewRows/types'

interface Props {
  futureRates: WithholdingTaxRule[]
  ruleId: string
  isRuleLive: boolean
}

const FutureTaxRuleSectionCard = ({ futureRates, ruleId, isRuleLive }: Props) => {
  const { t } = useTranslation()

  const AddRateAction = () => (
    <Tooltip title={`${t('Add new rate')}`} aria-label="edit" placement="right">
      <AddCircleIconButton
        aria-label="Add new rate"
        role="button"
        href={`reference-data/withholding-tax/${ruleId}/add`}
        style={{ margin: -12 }}
      />
    </Tooltip>
  )

  const futureRatesDefinitions = [...futureRates.reverse()].map((rate, index) => ({
    groupSeparator: index < futureRates.length - 1 ? ('light' as Separator) : undefined,
    definitions: [
      { term: t('Effective date'), definitions: [rate && toBankTz(rate.effectiveDate.toString()).date] },

      ...(rate.withholdTax
        ? [
            {
              term: t('Calculation method'),
              definitions: [<div key="witholdtax">{t(rate.tierBandCalcMethod)}</div>],
            },
          ]
        : []),
      ...(rate.withholdTax
        ? [
            {
              term: t('Tax rate'),
              definitions: [<TaxRate key="tax rate" taxRule={rate} ruleId={rate.ruleId} disableEdit={isRuleLive} />],
            },
          ]
        : []),
    ],
  }))

  return (
    <EntitySectionCard
      headline="Future tax rates"
      title={
        <Body1 style={{ marginTop: 0 }}>
          {t(`{{amount}} future tax rate{{s}}`, {
            amount: futureRates.length || 'No',
            s: futureRates.length !== 1 ? 's' : '',
          })}
        </Body1>
      }
      headerIcon={<EntityBadgeFutureRate style={{ marginTop: -2 }} />}
      headerAction={<AddRateAction />}
      accordionDisabled={!futureRates.length}
      scrollEnabled
    >
      <ReviewRows definitionsList={futureRatesDefinitions} />
    </EntitySectionCard>
  )
}

export default FutureTaxRuleSectionCard
