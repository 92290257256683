import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.getProductSyndication.type]: (state: ProductsState) => ({ ...state, productSyndicationLoading: true }),
  [actions.getProductSyndicationFail.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.getProductSyndicationFail>
  ) => ({
    ...state,
    productSyndicationError: action.payload,
    productSyndicationLoading: false,
    productSyndication: null,
  }),
  [actions.getProductSyndicationSuccess.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.getProductSyndicationSuccess>
  ) => ({
    ...state,
    loading: false,
    productSyndicationError: null,
    productSyndication: action.payload,
    productSyndicationLoading: false,
  }),
}
export default reducer
