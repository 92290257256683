import React from 'react'
import { isEmpty } from 'lodash'
import { TFunction } from 'i18next'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { CreditInterest, RateTierBand, TierBand } from 'store/products/types'
import { isNullOrUndefined } from 'utils/common.util'
import { preciseRateMultiplyByHundred } from 'utils/form.utils'

const getIndependentDefinitions = (
  { interestRate, interestTierBand, tierBandCalcMethod }: CreditInterest,
  currencyOfProduct: string | null,
  t: TFunction
) =>
  [
    !isNullOrUndefined(interestRate) &&
      tierBandCalcMethod === RateTierBand.FLAT && {
        term: t('Rate'),
        definitions: [<span key="rate">{`${preciseRateMultiplyByHundred(interestRate || 0, 10)}% `}</span>],
      },
    tierBandCalcMethod &&
      [RateTierBand.BAND, RateTierBand.TIER].includes(tierBandCalcMethod) &&
      !isNullOrUndefined(interestTierBand) &&
      !isEmpty(interestTierBand) && {
        term: tierBandCalcMethod === RateTierBand.TIER ? t('Tiers') : t('Bands'),
        definitions: [
          interestTierBand?.map((item: TierBand) => (
            <div key={`${item.endRange}-${item.startRange}`}>
              <CurrencySymbol
                typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                currencyCode={currencyOfProduct}
              />
              {new Intl.NumberFormat('en-GB').format(item.startRange || 0)}
              {!isNullOrUndefined(item.endRange) && (
                <>
                  {' – '}
                  <CurrencySymbol
                    typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                    currencyCode={currencyOfProduct}
                  />
                  {new Intl.NumberFormat('en-GB').format(item.endRange || 0)}
                </>
              )}
              {!isNullOrUndefined(item.tierBandRate) ? (
                <span>{` = ${preciseRateMultiplyByHundred(item.tierBandRate || 0, 10)}% `}</span>
              ) : null}
            </div>
          )),
        ],
      },
  ].filter((item) => item)

export default getIndependentDefinitions
