import {
  PaymentLimitDirection,
  PaymentLimitRoutingDestination,
  PaymentLimitRange,
  PaymentLimitAmountType,
  PaymentLimitChannel,
} from 'store/products/typings/paymentLimits'

export const getOutBoundToSelfAddUrls = () => ({
  single: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.TRANSFER}&range=${PaymentLimitRange.TRANSACTION}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeDay: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.TRANSFER}&range=${PaymentLimitRange.DAY}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeMonth: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.TRANSFER}&range=${PaymentLimitRange.MONTH}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeYear: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.TRANSFER}&range=${PaymentLimitRange.YEAR}&amountType=${PaymentLimitAmountType.MONETARY}`,
  maxDay: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.TRANSFER}&range=${PaymentLimitRange.DAY}&amountType=${PaymentLimitAmountType.VOLUME}`,
  maxMonth: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.TRANSFER}&range=${PaymentLimitRange.MONTH}&amountType=${PaymentLimitAmountType.VOLUME}`,
  maxYear: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.TRANSFER}&range=${PaymentLimitRange.YEAR}&amountType=${PaymentLimitAmountType.VOLUME}`,
})

export const getOutBoundToPartyAddUrls = () => ({
  single: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.OUTBOUND_PAYMENT},${PaymentLimitRoutingDestination.ON_US}&range=${PaymentLimitRange.TRANSACTION}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeDay: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.OUTBOUND_PAYMENT},${PaymentLimitRoutingDestination.ON_US}&range=${PaymentLimitRange.DAY}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeMonth: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.OUTBOUND_PAYMENT},${PaymentLimitRoutingDestination.ON_US}&range=${PaymentLimitRange.MONTH}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeYear: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.OUTBOUND_PAYMENT},${PaymentLimitRoutingDestination.ON_US}&range=${PaymentLimitRange.YEAR}&amountType=${PaymentLimitAmountType.MONETARY}`,
  maxDay: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.OUTBOUND_PAYMENT},${PaymentLimitRoutingDestination.ON_US}&range=${PaymentLimitRange.DAY}&amountType=${PaymentLimitAmountType.VOLUME}`,
  maxMonth: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.OUTBOUND_PAYMENT},${PaymentLimitRoutingDestination.ON_US}&range=${PaymentLimitRange.MONTH}&amountType=${PaymentLimitAmountType.VOLUME}`,
  maxYear: `add?direction=${PaymentLimitDirection.DEBIT}&channel=${PaymentLimitChannel.MOBILE}&routingDestinations=${PaymentLimitRoutingDestination.OUTBOUND_PAYMENT},${PaymentLimitRoutingDestination.ON_US}&range=${PaymentLimitRange.YEAR}&amountType=${PaymentLimitAmountType.VOLUME}`,
})

export const getInBoundAddUrls = () => ({
  single: `add?direction=${PaymentLimitDirection.CREDIT}&channel=${PaymentLimitChannel.NOT_APPLICABLE}&routingDestinations=${PaymentLimitRoutingDestination.NOT_APPLICABLE}&range=${PaymentLimitRange.TRANSACTION}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeDay: `add?direction=${PaymentLimitDirection.CREDIT}&channel=${PaymentLimitChannel.NOT_APPLICABLE}&routingDestinations=${PaymentLimitRoutingDestination.NOT_APPLICABLE}&range=${PaymentLimitRange.DAY}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeMonth: `add?direction=${PaymentLimitDirection.CREDIT}&channel=${PaymentLimitChannel.NOT_APPLICABLE}&routingDestinations=${PaymentLimitRoutingDestination.NOT_APPLICABLE}&range=${PaymentLimitRange.MONTH}&amountType=${PaymentLimitAmountType.MONETARY}`,
  cumulativeYear: `add?direction=${PaymentLimitDirection.CREDIT}&channel=${PaymentLimitChannel.NOT_APPLICABLE}&routingDestinations=${PaymentLimitRoutingDestination.NOT_APPLICABLE}&range=${PaymentLimitRange.YEAR}&amountType=${PaymentLimitAmountType.MONETARY}`,
  maxDay: `add?direction=${PaymentLimitDirection.CREDIT}&channel=${PaymentLimitChannel.NOT_APPLICABLE}&routingDestinations=${PaymentLimitRoutingDestination.NOT_APPLICABLE}&range=${PaymentLimitRange.DAY}&amountType=${PaymentLimitAmountType.VOLUME}`,
  maxMonth: `add?direction=${PaymentLimitDirection.CREDIT}&channel=${PaymentLimitChannel.NOT_APPLICABLE}&routingDestinations=${PaymentLimitRoutingDestination.NOT_APPLICABLE}&range=${PaymentLimitRange.MONTH}&amountType=${PaymentLimitAmountType.VOLUME}`,
  maxYear: `add?direction=${PaymentLimitDirection.CREDIT}&channel=${PaymentLimitChannel.NOT_APPLICABLE}&routingDestinations=${PaymentLimitRoutingDestination.NOT_APPLICABLE}&range=${PaymentLimitRange.YEAR}&amountType=${PaymentLimitAmountType.VOLUME}`,
})

export const getEditUrl = (limitRuleId: string) => `edit/${limitRuleId}`

export const addUrls = (routingDestination: PaymentLimitRoutingDestination) => {
  switch (routingDestination) {
    case PaymentLimitRoutingDestination.TRANSFER:
      return getOutBoundToSelfAddUrls()
    case PaymentLimitRoutingDestination.OUTBOUND_PAYMENT:
    case PaymentLimitRoutingDestination.ON_US:
      return getOutBoundToPartyAddUrls()
    case PaymentLimitRoutingDestination.NOT_APPLICABLE:
      return getInBoundAddUrls()
    default:
      return {
        single: '',
      }
  }
}
