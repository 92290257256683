import React from 'react'
import { Container, ContainerProps } from '@material-ui/core'

class ContentContainer extends React.Component<ContainerProps> {
  componentDidMount() {
    // because react wont' loose focus on active component, this solution solve the problem, if is part of new page.
    ;(document.activeElement as HTMLElement).blur()
  }

  render = () => <Container disableGutters maxWidth="lg" {...this.props} />
}

export default ContentContainer
