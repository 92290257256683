import { useTranslation } from 'react-i18next'
import { Grid, Tooltip } from '@material-ui/core'
import React from 'react'
import { Field, Form, FormikProps } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getKeyString,
  getSearchString,
  INITIAL_FILTERS,
  isThisRowSelectable,
  MAX_SELECTABLE_TRANSACTIONS,
} from '../utils'
import { TransactionsModal } from '../../constants'
import { ButtonProgress } from '../Styles'
import { Filters, getTransactionsRows } from './TransactionsTableHeadRows'
import { ExpandedContent } from './ExpandedContent'
import TransactionsStickyBar from './TransactionsStickyBar'
import { stopPropagation, withFormikSimple } from 'utils/form.utils'
import {
  TableCommonDataType,
  TableCommonGetKeyString,
  TableCommonGetSearchString,
  TableParam,
} from 'components/TableCommon/types'
import {
  SubscriptionKey,
  SuspenseAccounts,
  SuspenseTransactionItem,
  SuspenseTransactionsForm,
  TransactionStatus,
} from 'store/accounts/types'
import { EnhancedTableField } from 'components'
import { TableContextProvider } from 'context/TableContext'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { UndoButton } from 'dls/molecules/IconButtons/IconButtons'

interface OwnProps {
  accounts: SuspenseAccounts
  currency: string
  setCurrentModal: (REVERSE_TRANSACTIONS: TransactionsModal) => void
  setModalItem: (item: SuspenseTransactionItem) => void
  subscriptionKey: SubscriptionKey
  transactions: SuspenseTransactionItem[]
  onTransferClick: VoidFunction
  filters: Filters
  setFilter: (filters: Filters) => void
  isSearchPage: boolean
  isLoadingMore: boolean
  loadMore: VoidFunction
  showLoadMore: boolean
  searchText?: string
}

type Props = OwnProps & FormikProps<SuspenseTransactionsForm>

function TransactionsTable({
  accounts,
  currency,
  setCurrentModal,
  setModalItem,
  subscriptionKey,
  transactions,
  values,
  onTransferClick,
  filters,
  setFilter,
  isSearchPage,
  isLoadingMore,
  loadMore,
  showLoadMore,
  searchText,
}: Props) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const reset = () => {
    navigate(location.pathname)
    setFilter(INITIAL_FILTERS)
  }

  const headRows = getTransactionsRows(
    t,
    setFilter,
    filters,
    isSearchPage,
    () => setCurrentModal(TransactionsModal.STATUS),
    currency,
    reset
  )

  const rowSelectableComponent = (item: SuspenseTransactionItem) => {
    const tooltip = t('You can reverse contra write-off or write-back transaction if you need to correct a mistake')
    const onBtnClick = (e: React.MouseEvent) => {
      stopPropagation(e)
      setCurrentModal(TransactionsModal.REVERSE_TRANSACTIONS)
      setModalItem(item)
    }
    if (
      [TransactionStatus.CONTRA_WRITE_OFF, TransactionStatus.CONTRA_WRITE_BACK].includes(
        item.transactionStatus as TransactionStatus
      )
    ) {
      return (
        <Tooltip title={tooltip} aria-label={tooltip} placement="right">
          <UndoButton onClick={onBtnClick} aria-label="reverse" />
        </Tooltip>
      )
    }
    return false
  }

  return (
    <div id="account-transactions-section" role="region" aria-live="polite" aria-atomic="true">
      <TableContextProvider>
        <Form>
          <Grid item xs={12}>
            <Field
              component={EnhancedTableField}
              mode={TableParam.OPTIMIZE}
              data={transactions as TableCommonDataType[]}
              defaultRowsPerPage={transactions.length}
              fullCellSelectable={[-1, 0]}
              getExpandedContent={(item: SuspenseTransactionItem) => <ExpandedContent row={item} />}
              getKeyString={getKeyString as TableCommonGetKeyString}
              getSearchString={getSearchString as TableCommonGetSearchString}
              headRows={headRows}
              isThisRowSelectable={isThisRowSelectable}
              maxSelectableItems={MAX_SELECTABLE_TRANSACTIONS}
              name="selectedTransactions"
              rowSelectableComponent={rowSelectableComponent}
              title={t('Transactions')}
              type="text"
              disableInsideSortPagination
              hideHeader
              hidePagination
              noEmptyRows
              required
              touchedNotNeeded
              noRowTitle={t('No results to display')}
              searchText={searchText}
            />
          </Grid>
          {showLoadMore && (
            <Grid item xs={12} style={{ margin: 24 }}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    color={ColorType.BUTTON}
                    onClick={loadMore}
                    style={{ width: 170, marginBottom: ' 16px' }}
                  >
                    {t('Load more')}
                  </Button>
                  {isLoadingMore && <ButtonProgress size={24} />}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Form>
        <TransactionsStickyBar
          values={values}
          subscriptionKey={subscriptionKey}
          accounts={accounts}
          transactions={transactions}
          onTransferClick={onTransferClick}
        />
      </TableContextProvider>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withFormikSimple<any, any>(TransactionsTable, {})
