import { Grid, Typography } from '@material-ui/core'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-cycle
import ProductVersionSelectorMenu from './ProductVersionSelectorMenu'
import useProductVersionSelector from './useProductVersionSelector'
import {
  OldVersionNotice,
  ProductVersionGrid,
  ProductVersionGridItem,
  ProductVersionButton,
  ThemeInfoOutlinedIcon,
  ThemeArrowUpIcon,
  ThemeArrowDownIcon,
} from './Styled'
import { Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { toBankTz } from 'utils/date.utils'
import { getVersionText } from 'utils/productVersion.utils'
import { HelpModalType } from 'store/modal/types'
// eslint-disable-next-line import/no-cycle
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface Props {
  product: Product
  isVersionLatest: boolean
  isOnProductPage?: boolean
}

const ProductVersionSelector = ({ isVersionLatest, product, isOnProductPage = true }: Props) => {
  const { t } = useTranslation()
  const btnRef = useRef(null)
  const { version } = product
  const { showProductVersions, anchorEl, productVersions, setAnchorEl, isLoading, error } = useProductVersionSelector({
    isVersionLatest,
    product,
    btnRef,
  })

  const { showInfographicModal } = useModal()

  return (
    <ProductVersionGrid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={0}
      $isOnProductPage={isOnProductPage}
    >
      <ProductVersionGridItem item ref={btnRef}>
        <Grid container direction="row" alignItems="center" spacing={0}>
          <Grid item>
            <ProductVersionSelectorMenu
              id={`${product.productKey}-version-control`}
              anchorEl={anchorEl as any}
              currentProduct={product}
              error={error}
              loading={isLoading}
              productVersions={productVersions}
              setAnchorEl={setAnchorEl}
              showProductVersions={showProductVersions}
              menuButton={
                <ProductVersionButton
                  $isOnProductPage={isOnProductPage}
                  onClick={showProductVersions}
                  endIcon={
                    anchorEl ? (
                      <ThemeArrowDownIcon $isOnProductPage={isOnProductPage} />
                    ) : (
                      <ThemeArrowUpIcon $isOnProductPage={isOnProductPage} />
                    )
                  }
                  $iconButton={false}
                >
                  {isVersionLatest && !isOnProductPage && `${t('Latest')} ${getVersionText(version)}`}
                  {isVersionLatest && isOnProductPage && `Version ${getVersionText(version)} (${t('latest')})`}
                  {!isVersionLatest && isOnProductPage && `Version ${getVersionText(version)}`}
                </ProductVersionButton>
              }
            />
          </Grid>
          {isOnProductPage && (
            <Grid item>
              <InfoIconButton
                key="more-about-icon"
                ariaProps={{
                  'aria-label': `${t('About product versions')}`,
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                }}
                onClick={() => showInfographicModal(HelpModalType.PRODUCT_VERSIONING)}
                fontSize="medium"
              />
            </Grid>
          )}
          {isOnProductPage && product && product.effectiveDate && [ProductStatus.LIVE].includes(product.status) && (
            <Grid item>
              <Typography variant="subtitle1" data-test-id="product-effective-date">
                {`${t('Effective date')} : `}
                <span data-test-id="product-effective-date-value">{toBankTz(product.effectiveDate).dateTime}</span>
              </Typography>
            </Grid>
          )}
        </Grid>
      </ProductVersionGridItem>

      {!isVersionLatest && (
        <Grid item>
          <OldVersionNotice variant="outlined">
            <ThemeInfoOutlinedIcon />
            {t('There are newer versions – see the version menu above')}
          </OldVersionNotice>
        </Grid>
      )}
    </ProductVersionGrid>
  )
}

export default ProductVersionSelector
