import React, { useState } from 'react'
import { Menu } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { isArray } from 'lodash'
import styled from 'styled-components/macro'
import { FieldArray } from 'formik'
import { AddElement, AddNestedElement } from 'components'
import { MultipleChoiceElement } from 'store/products/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface BottomBarProps {
  addLabel?: string
  elements?: { [key: string]: MultipleChoiceElement | MultipleChoiceElement[] }
  isAddButtonDisabled?: boolean
  readOnly?: boolean
  onClick?: () => void
  triggerAction?: (item: any) => void
  disableDropdown?: boolean
}

const ButtonStyle = styled(Button)``

const AddIcon = styled(Add)`
  margin-right: 8px;
`

const FormAddFeatureButton = ({
  addLabel,
  elements,
  readOnly,
  isAddButtonDisabled,
  disableDropdown,
  onClick,
  triggerAction,
}: BottomBarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
    if (onClick) {
      onClick()
    }
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const elementsEntries = Object.entries(elements || {})
  const isSingleElement: boolean =
    !!elements && !!elementsEntries && elementsEntries.length === 1 && !isArray(elementsEntries[0][1])
  return (
    <>
      {isSingleElement && (
        <FieldArray name={`${elementsEntries[0][0]}`}>
          {({ push }) => (
            <ButtonStyle
              aria-controls="form-bottom-bar-menu"
              aria-haspopup="true"
              variant="outlined"
              color={ColorType.BUTTON}
              disabled={(elementsEntries[0][1] as MultipleChoiceElement).disabled || readOnly}
              onClick={() => {
                if (!readOnly) {
                  push((elementsEntries[0][1] as MultipleChoiceElement).defaultValue)
                  handleClose()
                  if (triggerAction) triggerAction((elementsEntries[0][1] as MultipleChoiceElement).defaultValue)
                }
              }}
            >
              <AddIcon />
              {addLabel}
            </ButtonStyle>
          )}
        </FieldArray>
      )}
      {!isSingleElement && elements && addLabel && !isAddButtonDisabled && (
        <>
          <ButtonStyle
            aria-controls="form-bottom-bar-menu"
            aria-haspopup="true"
            onClick={handleClick}
            variant="outlined"
            color={ColorType.BUTTON}
          >
            <AddIcon />
            {addLabel}
          </ButtonStyle>
          {!disableDropdown && (
            <Menu
              id="form-bottom-bar-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {elementsEntries.map(([key, element]) => {
                /* eslint-disable no-else-return */
                if (isArray(element)) {
                  return (
                    <AddNestedElement
                      elements={element}
                      key={key}
                      name={key}
                      handleClose={handleClose}
                      parentAnchorEl={anchorEl}
                      triggerAction={triggerAction}
                    />
                  )
                } else if (element) {
                  return (
                    <AddElement
                      key={key}
                      name={element.name || key}
                      disabled={element.disabled || readOnly}
                      label={element.overrideLabel || element.label || ''}
                      defaultValue={element.defaultValue}
                      multiple={element.multiple}
                      onAfterClick={handleClose}
                      menuItemProps={element.menuItemProps}
                      triggerAction={triggerAction}
                    />
                  )
                }
                return null
                /* eslint-enable no-else-return */
              })}
            </Menu>
          )}
        </>
      )}
    </>
  )
}

export default FormAddFeatureButton
