import React from 'react'
import { Dialog, Fade, Grid } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CreateProcessDialogContent from '../Governance/CreateProcess/CreateProcessDialog'
import ProcessHeader from './ProcessHeader'
import TaskFormPage from './Task/Task.page'
import { GenerationStrategy, Process, ProcessFormValues } from 'store/governance/types'
import { TaskDefinition, TaskDefinitionFormValue } from 'store/tasks/types'
import { BackButton, SidePanel } from 'components'
import { GoveranceInfoGraphic } from 'assets'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'

interface Props {
  selectedProcess: Process
  updateSelectedProcess: (data: ProcessFormValues) => void
  open: boolean
  onClickCancel: () => void
  onPressUpdate: () => void
  saveTask: (taskDefinitionFormValue: TaskDefinitionFormValue) => void
  updateTask: (taskDefinitionFormValue: TaskDefinitionFormValue) => void
  availableTasks: TaskDefinition[]
  isLoading: boolean
}

const ButtonContainer = styled.div`
  align-items: center;
  padding-top: 16px;
`
const StyledConversationGrid = styled(Grid)`
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: 16px;
  }
`

const ProcessDetail = (props: Props): React.ReactElement => {
  const {
    selectedProcess,
    open,
    onClickCancel,
    updateSelectedProcess,
    onPressUpdate,
    saveTask,
    availableTasks,
    isLoading,
    updateTask,
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateProcess = (data: ProcessFormValues) => {
    updateSelectedProcess({ ...selectedProcess, ...data })
    onPressUpdate()
  }
  return (
    <ContentContainer>
      <ProcessHeader process={selectedProcess} />
      <Dialog open={open} scroll="paper" onClose={onClickCancel} TransitionComponent={Fade} fullWidth>
        <CreateProcessDialogContent
          onPressCancel={onClickCancel}
          onSubmit={updateProcess}
          initialValues={{
            entityLifecycle: selectedProcess.entityLifecycle,
            entityType: selectedProcess.entityType,
            featureTasksGenerationStrategy:
              selectedProcess.featureTasksGenerationStrategy === GenerationStrategy.ONE_FOR_EACH,
            name: selectedProcess.name,
          }}
          isEditmode
        />
      </Dialog>

      <ButtonContainer>
        <BackButton onPressBack={() => navigate(`/governance`)} aria-controls="main" />
      </ButtonContainer>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
        <Grid item sm={12} md={12} lg={8}>
          <TaskFormPage
            updateTask={updateTask}
            saveTask={saveTask}
            availableTask={availableTasks}
            isLoading={isLoading}
          />
        </Grid>
        <StyledConversationGrid item sm={12} md={12} lg={4}>
          <SidePanel
            heading={t('ABOUT GOVERNANCE')}
            image={GoveranceInfoGraphic}
            imageAlt={t('Illustration of a product owner configuring governance processes.')}
            details={t(
              'A governance process will allow you to assign a task to a colleague to review and approve your product'
            )}
            linkToOpenModal={t('More about governance')}
            infographicType={HelpModalType.GOVERNANCE}
          />
        </StyledConversationGrid>
      </Grid>
    </ContentContainer>
  )
}

export default ProcessDetail
