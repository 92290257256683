import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Field } from 'formik'
import CurrencySymbol, { CurrencySymbolProps } from '../CurrencySymbol/CurrencySymbol'
import { TextFieldComplexProps } from './TextFieldComplex'
import { TextFieldComplex } from 'components'

const FieldAmount = ({ currencyCode, ...props }: TextFieldComplexProps & CurrencySymbolProps) => (
  <Field
    type="currency"
    component={TextFieldComplex}
    value={props.value}
    {...props}
    {...(props.positiveIntegersOnly
      ? { onKeyDown: (e: React.KeyboardEvent) => [189, 190].includes(e.keyCode) && e.preventDefault() }
      : {})}
    InputProps={{
      ...props.InputProps,
      startAdornment: !props.hideCurrencySymbol && (
        <InputAdornment position="start">
          <CurrencySymbol currencyCode={currencyCode} />
        </InputAdornment>
      ),
      inputProps: { step: 'any' },
    }}
    numberFormatProps={{
      decimalScale: 2,
    }}
  />
)

export default FieldAmount
