import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormikProps, getIn, useFormikContext, Form } from 'formik'
import { InfoOutlined } from '@material-ui/icons'
import { TFunction } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { debitInterestRateValidationSchema } from '../../../utils/debitInterestValidationSchema'
import DebitInterestInfoButton from '../DebitInterestInfoButton'
import IndexRateForm from './IndexRateForm/IndexRateForm'
import { withFormikSimple } from 'utils/form.utils'
import RadioGroupFieldSimple from 'components/form/RadioGroupFieldSimple'
import {
  TypeOfIndexRate,
  ExtendedProduct,
  LendingRateFormValues,
  FixedVariableType,
  LendingRateType,
} from 'store/products/types'
import { Subtitle1, NoActivityHelpPanel, TemplateSubReview, BackButton } from 'components'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { Body2, H1, H2, H3, Paper } from 'dls'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Flexbox } from 'pages/Layout/Styled'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  product: ExtendedProduct
  currencyCode?: string
  initialSetup?: boolean
  isLending: boolean
  rateType: LendingRateType
  disableTierBandRadioGroup?: boolean
  t: TFunction
}

const RateForm = (props: FormikProps<LendingRateFormValues> & Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { values, setValues } = useFormikContext<LendingRateFormValues>()
  const { rateType, initialSetup, isLending, product, isValid, dirty } = props

  const selectedTypeOfIndexRate = getIn(values, 'typeOfIndexRate')

  const handleChangeTypeOfIndexRate = (value: any) => {
    const newValues = {
      ...values,
      typeOfIndexRate: value,
      calculationMethod: isLending ? values.calculationMethod : undefined,
      interestRate: undefined,
      bankInterestRateIndex: undefined,
      spread: undefined,
      operator: undefined,
      tierBands: [],
    }

    if (value === TypeOfIndexRate.INDEPENDENT_INDEXED_RATE) {
      newValues.fixedVariableType = FixedVariableType.FIXED
      newValues.partnercentricModel = undefined
    } else {
      newValues.partnercentricModel = false
      newValues.fixedVariableType = FixedVariableType.VARIABLE
      if (value === TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN) {
        newValues.partnercentricModel = true
      }
    }

    setValues(newValues)
  }

  const { showInfographicModal } = useModal()

  const renderForm = () => (
    <Grid container item direction="column" spacing={3}>
      {initialSetup && (
        <Grid item style={{ marginTop: 40 }}>
          <H2 gutterBottom>{t(`DEBIT_INTEREST.${rateType}`)}</H2>
        </Grid>
      )}
      <Grid item>
        <Box mb={3}>
          <H3>{t('Type of interest rate')}</H3>
        </Box>
        <Subtitle1 variantMapping={{ subtitle1: 'div' }}>
          {`${t('There are 3 options')} : `}
          <LinkWithText
            aria-controls="more-about-dialog"
            aria-haspopup="dialog"
            onClick={() => showInfographicModal(HelpModalType.INTEREST_RATES)}
            endIcon={<InfoOutlined />}
          >
            {t('see examples of how these work')}
          </LinkWithText>
        </Subtitle1>
      </Grid>
      <Grid item>
        <RadioGroupFieldSimple
          name="typeOfIndexRate"
          value={selectedTypeOfIndexRate}
          key="typeOfIndexRate"
          required
          data={TypeOfIndexRate}
          onChange={handleChangeTypeOfIndexRate}
        />
      </Grid>
      <Grid item>
        <IndexRateForm {...props} selectedTypeOfIndexRate={selectedTypeOfIndexRate} />
      </Grid>
    </Grid>
  )

  if (initialSetup) {
    if (isLending) {
      return renderForm()
    }

    if (rateType) {
      return renderForm()
    }

    return null
  }

  return (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={() => navigate(-1)} aria-controls="main" />
              </Grid>
              <Grid item>
                <DebitInterestInfoButton />
              </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <H1 gutterBottom>{t(`DEBIT_INTEREST.${rateType}`)}</H1>
              </Grid>
              <Grid item>
                <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
                  {t(`DEBIT_INTEREST.RATE_DESCRIPTION`)}
                </Body2>
              </Grid>
            </Grid>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <Paper>
            <Form>
              {renderForm()}
              <Grid item>
                <Flexbox width="100%" direction="row" justifyContent="flex-end" pt={36}>
                  <Button
                    color={ColorType.BUTTON}
                    onClick={() => navigate(`/products/${product.productKey}/features/debit-interest`)}
                    style={{
                      marginRight: 16,
                    }}
                    aria-controls="main"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button variant="contained" color={ColorType.BUTTON} type="submit" disabled={!isValid || !dirty}>
                    {t('Submit')}
                  </Button>
                </Flexbox>
              </Grid>
            </Form>
          </Paper>
        }
      />
    </ContentContainer>
  )
}

export const RateFormWithoutFormik = RateForm

export default withFormikSimple<Props, LendingRateFormValues>(RateForm, {
  validationSchema: ({ t, currencyCode }: { t: TFunction; currencyCode: string }) =>
    debitInterestRateValidationSchema(t, currencyCode),
})
