import React from 'react'
import { FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LightThickDivider, StyledSelect } from '../../ReferenceData/WithholdingTaxRuleCatalog/styled'
import { Direction, InternalClientCode } from '../../../static/transactionRules'
import useTransactionRuleField from './useTransactionRuleField'
import { operators, TransactionRuleType, TypeOptions } from './constants'
import TransactionRuleValueField, { LocalAutocompleteSelectFieldData } from './TransactionRuleValueField'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import { RemoveCircleButton } from 'dls/molecules/IconButtons/IconButtons'

interface Props {
  name: string
  onRemove: (index: number) => void
  hasMultipleRows: boolean
  index: number
  filteredRuleNames: string[]
  transactionExceptionType: string
  filterBySelectedTypes: (type: string, value: string) => boolean
}
export const getTypeOptions = (
  type: any,
  transactionExceptionType: string,
  filterBySelectedTypes: (type: string, value: string) => boolean,
  formValue: string | string[],
  selectedInternalClientCode?: InternalClientCode
): LocalAutocompleteSelectFieldData =>
  TypeOptions[type]
    ? TypeOptions[type]
        .filter((item: any) => filterBySelectedTypes(type, item))
        .filter((item: any) => {
          const hasSameICC = item.icc === selectedInternalClientCode || item.icc === InternalClientCode.ALL
          const hasSameDirection =
            item.direction === Direction.BOTH || item.direction.toLowerCase() === transactionExceptionType.toLowerCase()
          return hasSameICC && hasSameDirection
        })
        .reduce((allOptions: any, item: any) => {
          if (type !== 'TRANSACTION_CODE') {
            return { ...allOptions, [item.code]: { key: item.code, label: item.description } }
          }

          const { domain, family, subfamily, description } = item
          const key = `${domain.code}.${family.code}.${subfamily.code}`
          const label = description
          return { ...allOptions, [key]: { key, label } }
        }, {})
    : Array.isArray(formValue) && formValue.reduce((acc, item) => ({ ...acc, [item]: { key: item, label: item } }), {})

export default ({
  name,
  onRemove,
  hasMultipleRows,
  index,
  filteredRuleNames,
  transactionExceptionType,
  filterBySelectedTypes,
}: Props) => {
  const { t } = useTranslation()
  const createRuleField = useTransactionRuleField(name)
  const selectedInternalClientCode = useSelector(TenantConfigSelectors.selectInternalClientCode)

  const canRemove: boolean =
    index !== 0
      ? true
      : createRuleField.isValue &&
        (Array.isArray(createRuleField.thisValue) ? createRuleField.thisValue.length > 0 : !!createRuleField.thisValue)

  const onRemoveClick = () => {
    if (hasMultipleRows) {
      onRemove(index)
    } else {
      createRuleField.onFieldReset()
    }
  }
  const selectedRuleType = TransactionRuleType[createRuleField.ifValue]

  return (
    <>
      <Grid container direction="row" spacing={3} style={{ marginBottom: canRemove ? '0px' : '12px' }}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel shrink id="select-rule-type">
              {index === 0 ? t('If *') : t('And if *')}
            </InputLabel>
            <StyledSelect
              id="select-rule-type"
              value={createRuleField.ifValue}
              onChange={createRuleField.onRuleTypeChange}
              hasPlaceholder={!createRuleField.ifValue}
              displayEmpty
              fullWidth
              required
            >
              <MenuItem value="" disabled>
                {t('Please select')}
              </MenuItem>

              {Object.keys(TransactionRuleType)
                .filter((key) => filteredRuleNames.includes(key) || createRuleField.ifValue === key)
                .filter(
                  (key) =>
                    TransactionRuleType[key] &&
                    Object.keys(
                      getTypeOptions(
                        TransactionRuleType[key].value,
                        transactionExceptionType,
                        filterBySelectedTypes,
                        createRuleField.thisValue,
                        selectedInternalClientCode
                      )
                    ).length > 0
                )
                .sort((a, b) => (TransactionRuleType[a].label > TransactionRuleType[b].label ? 1 : -1))
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {TransactionRuleType[key].label}
                  </MenuItem>
                ))}
            </StyledSelect>
          </FormControl>
        </Grid>
        {createRuleField.ifValue !== '' && (
          <>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel shrink id="select-operator">
                  {t('Is')} *
                </InputLabel>
                <StyledSelect
                  id="select-operator"
                  value={createRuleField.isValue}
                  onChange={createRuleField.onRuleOperatorChange}
                  hasPlaceholder={!createRuleField.isValue}
                  displayEmpty
                  required
                >
                  <MenuItem value="" disabled>
                    {t('Please select')}
                  </MenuItem>
                  {selectedRuleType?.operators.map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(operators[key])}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </Grid>
            {selectedRuleType && createRuleField.isValue && (
              <Grid item xs={createRuleField.ruleValueFieldSize}>
                <TransactionRuleValueField
                  typeOptions={getTypeOptions(
                    selectedRuleType.value,
                    transactionExceptionType,
                    filterBySelectedTypes,
                    createRuleField.thisValue,
                    selectedInternalClientCode
                  )}
                  operator={createRuleField.isValue}
                  onChange={createRuleField.onRuleValueChange}
                  value={createRuleField.thisValue}
                />
              </Grid>
            )}
          </>
        )}
        {canRemove && (
          <Grid item xs={1}>
            <RemoveCircleButton
              aria-label={t('Remove rule')}
              onClick={onRemoveClick}
              style={{
                marginTop: createRuleField.ruleValueFieldSize > 4 ? '20px' : '6px',
                alignItems: 'flex-end',
                paddingBottom: '9px',
              }}
            />
          </Grid>
        )}
      </Grid>

      {hasMultipleRows && (
        <Grid item xs={11}>
          <LightThickDivider style={{ marginTop: 12, marginBottom: 24 }} />
        </Grid>
      )}
    </>
  )
}
