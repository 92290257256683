import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { generateFrequencyPeriodText } from 'pages/Templates/StatementConfigurationCatalog/utils/transformers'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'
import { EntityBadgeStatements, Button, Body1, Body2 } from 'dls/atoms'

export const Region2Text = styled(Body2)`
  white-space: nowrap;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-right:10px;
    },
  `};
`

const StatementConfigurationCard = ({
  statementConfiguration,
}: {
  statementConfiguration: StatementConfiguration
}): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { name, statementConfigKey, productSegment, statementPeriod, startDay, startMonth } = statementConfiguration

  const generateProductSegmentText = (segment: string): string => {
    if (segment) {
      return `${t(segment?.toUpperCase())} : `
    }

    return ''
  }

  return (
    <CatalogCard
      region1={[
        <EntityBadgeStatements key="1" />,
        <Body1 variantMapping={{ body1: 'div' }} key="2">
          {name}
        </Body1>,
      ]}
      region3={[
        <Body2 key="3" css="white-space:nowrap;">
          {generateProductSegmentText(productSegment)}
          {generateFrequencyPeriodText({ period: statementPeriod, day: startDay, month: startMonth ?? 0, t })}
        </Body2>,
      ]}
      region4={[
        <Button aria-controls="main" variant="text" key="1">
          {t('View')}
        </Button>,
      ]}
      onClickOnCard={() => navigate(`/templates/statement-configurations/${statementConfigKey}`)}
      id={statementConfigKey || ''}
    />
  )
}

export default StatementConfigurationCard
