import React from 'react'
import { Grid } from '@material-ui/core'
import CopyButton from 'components/CopyButton/CopyButton'

interface OwnProps {
  email: string
}
const UserEmailCellContent = ({ email }: OwnProps) => (
  <Grid container alignItems="center" spacing={3} wrap="nowrap">
    <Grid item style={{ flex: 1 }}>
      {email}
    </Grid>
    <Grid item style={{ minWidth: '110px' }}>
      <CopyButton
        payload={email}
        size="small"
        extraButtonProps={{ style: { whiteSpace: 'nowrap', margin: '0 0 0 2px' } }}
      />
    </Grid>
  </Grid>
)

export default UserEmailCellContent
