import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import orderBy from 'lodash/orderBy'
import { useParams } from 'react-router-dom'
import ProductVersionHistory from './ProductVersionHistory'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { RootState } from 'store'
import ProductsActions from 'store/products/actions'
import UserActions from 'store/user/actions'
import ProductsSelectors from 'store/products/selectors'
import * as UsersSelectors from 'store/user/selectors'
import { Loader } from 'components/'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = {
  fetchVersions: ProductsActions.getVersions,
  getUsers: UserActions.getUser,
}

const mapStateToProps = (state: RootState) => ({
  productVersions: ProductsSelectors.selectVersions(state),
  loadingProduct: ProductsSelectors.selectLoading(state),
  loadingUsers: UsersSelectors.selectLoading(state),
  loadingVersions: ProductsSelectors.selectProductVersionsLoading(state),
  users: UsersSelectors.selectUsers(state),
})

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

export function ProductVersionHistoryPage({
  loadingProduct,
  loadingUsers,
  productVersions,
  fetchVersions,
  getUsers,
  loadingVersions,
  users,
}: Props) {
  const { t } = useTranslation()
  const { productKey } = useParams()
  const [product] = useSelectedProduct(productKey || '')
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('{{tenantName}} products', { tenantName: currentTenant?.name }), path: `/products/` },
      { title: product ? product.productName : '', path: `/products/${productKey}` },
      { title: t('Version history'), path: '' },
    ],
    trigger: !!productKey,
  })

  useEffect(() => {
    if (productKey) {
      fetchVersions(productKey)
      getUsers()
    }
  }, [fetchVersions, productKey, getUsers])

  if (loadingProduct || loadingUsers || loadingVersions) {
    return <Loader />
  }

  if (!productVersions || !product) return null
  const orderByVersionAndStatusProduct = orderBy(
    productVersions,
    ['version', 'publishedDate', 'createdDate'],
    ['asc', 'desc', 'desc']
  )
  return (
    <ProductVersionHistory
      productVersions={orderByVersionAndStatusProduct.reverse()}
      currentProduct={product}
      users={users}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVersionHistoryPage)
