import { call, put, delay, takeLatest } from 'redux-saga/effects'
import { find } from 'lodash'
import ProductsActions from '../products/actions'
import GovernanceActions from './actions'
import { navigate } from 'store/router/actions'
import { navigateToUnavailable } from 'utils/url.utils'
import { GovernanceApi } from 'api'
import NotificationActions from 'store/notifications/actions'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import ModalActions from 'store/modal/actions'

function* showInProgress() {
  yield put(ModalActions.showFeatureProgressModal())
}

function* showSuccess(successResponse: any) {
  yield delay(UI_DELAY_TO_SHOW_LOADING)
  yield put(ModalActions.closeModal())
  if (successResponse) yield put(ModalActions.showFeatureSuccessModal(successResponse))
}

function* showError(e: Error) {
  yield put(ModalActions.closeModal())
  yield put(ModalActions.showFeatureFailureModal(e))
}

function* getProcessDetails(action: ReturnType<typeof GovernanceActions.getProcess>) {
  try {
    const response: ReturnTypePromise<typeof GovernanceApi.getProcess> = yield call(GovernanceApi.getProcess)
    yield put(GovernanceActions.getProcessSuccess(response))
  } catch (e: any) {
    yield put(GovernanceActions.getProcessFail(e))
    if (action?.payload?.shouldRedirect ?? true) {
      yield call(navigateToUnavailable)
    }
  }
}

function* getProcessExecution(action: ReturnType<typeof GovernanceActions.processExecution>) {
  try {
    const response: ReturnTypePromise<typeof GovernanceApi.getProcessExecutions> = yield call(
      GovernanceApi.getProcessExecutions,
      action.payload.entityKey
    )
    yield put(GovernanceActions.processExecutionSuccess(response))
    yield put(ProductsActions.openAssigneeModal())
  } catch (e: any) {
    yield put(GovernanceActions.processExecutionFail(e))
  }
}

function* createProcess(action: ReturnType<typeof GovernanceActions.createProcess>) {
  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof GovernanceApi.createProcess> = yield call(
      GovernanceApi.createProcess,
      action.payload
    )
    yield put(navigate(`/governance/${response.key}` as any))
    const process: ReturnTypePromise<typeof GovernanceApi.getProcess> = yield call(GovernanceApi.getProcess)
    yield put(GovernanceActions.createProcessSuccess(process))
    const response2: ReturnTypePromise<typeof GovernanceApi.getProcess> = yield call(GovernanceApi.getProcess)
    yield put(GovernanceActions.getProcessSuccess(response2))
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield showSuccess({ ...response, featureName: 'Governance' })
  } catch (e: any) {
    yield showError(e)
  }
}

function* updateSelectedProcess(action: ReturnType<typeof GovernanceActions.updateSelectedProcess>) {
  try {
    yield call(GovernanceApi.updateProcess, action.payload)
    try {
      const resp: ReturnTypePromise<typeof GovernanceApi.getProcess> = yield call(GovernanceApi.getProcess)
      const currentProcess = find(resp, ['key', action.payload.key])
      yield put(GovernanceActions.createProcessSuccess(currentProcess as any))
      yield put(NotificationActions.editProcessSuccess(currentProcess as any))
    } catch (e: any) {
      yield put(GovernanceActions.getProcessFail(e))
      yield call(navigateToUnavailable)
    }
  } catch (e: any) {
    yield put(NotificationActions.editProcessFail(e))
    yield put(GovernanceActions.updateSelectedProcessFail(e))
  }
}

export default function* () {
  yield takeLatest(GovernanceActions.getProcess.type, getProcessDetails)
  yield takeLatest(GovernanceActions.createProcess.type, createProcess)
  yield takeLatest(GovernanceActions.updateSelectedProcess.type, updateSelectedProcess)
  yield takeLatest(GovernanceActions.processExecution.type, getProcessExecution)
}
