/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isArray } from 'lodash'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

interface Props {
  title?: string

  valuesDisplay: string | object | number | boolean
}

const StyledGrid = styled(Grid)`
  margin-left: 8px;
  margin-top: -16px;
  margin-bottom: 0px;
`

const DisplayTitleValue = (props: Props) => {
  const { title, valuesDisplay } = props
  const { t } = useTranslation()

  return (
    <StyledGrid container direction="column" spacing={4}>
      <Grid item xs={12} sm={12}>
        <Typography variant="body2" color="textSecondary" gutterBottom component="div">
          {title}
        </Typography>
        {typeof valuesDisplay === 'string' && (
          <Typography
            variant="subtitle1"
            css={`
              word-break: break-all;
            `}
            gutterBottom
          >
            {t(valuesDisplay)}
          </Typography>
        )}
        {typeof valuesDisplay === 'number' && (
          <Typography variant="subtitle1" gutterBottom variantMapping={{ subtitle1: 'div' }}>
            {valuesDisplay}
          </Typography>
        )}
        {typeof valuesDisplay === 'boolean' && valuesDisplay && (
          <Typography variant="subtitle1" gutterBottom>
            {t('Yes')}
          </Typography>
        )}
        {typeof valuesDisplay === 'boolean' && !valuesDisplay && (
          <Typography variant="subtitle1" gutterBottom>
            {t('No')}
          </Typography>
        )}
        {isArray(valuesDisplay) &&
          valuesDisplay !== null &&
          typeof valuesDisplay === 'object' &&
          typeof valuesDisplay[1] !== 'object' &&
          valuesDisplay.map((valueDisplay, index) => (
            <Typography variant="subtitle1" gutterBottom key={index}>
              {t(valueDisplay)}
            </Typography>
          ))}
        {isArray(valuesDisplay) &&
        valuesDisplay !== null &&
        typeof valuesDisplay === 'object' &&
        typeof valuesDisplay[1] === 'object' &&
        valuesDisplay[1] !== null ? (
          <Typography variant="subtitle1" gutterBottom>
            {t(valuesDisplay[0], { param1: valuesDisplay[1].param1, param2: valuesDisplay[1].param2 })}
          </Typography>
        ) : isArray(valuesDisplay) &&
          typeof valuesDisplay === 'object' &&
          typeof valuesDisplay[1] === 'object' &&
          valuesDisplay[1] === null ? (
          // eslint-disable-next-line react/jsx-indent
          <Typography variant="subtitle1" gutterBottom>
            {t(valuesDisplay[0])}
          </Typography>
        ) : (
          ''
        )}
      </Grid>
    </StyledGrid>
  )
}

export default DisplayTitleValue
