import React from 'react'
import { FastField } from 'formik'
import * as Yup from 'yup'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TextFieldComplex } from 'components'
import { Yip } from 'utils/form.utils'

export const SubscriptionLimitElement = ({ name, description }: { name: string; description?: string }) => {
  const { t } = useTranslation()
  return (
    <Grid container spacing={3}>
      {description && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom variantMapping={{ subtitle2: 'div' }}>
            {description}
          </Typography>
        </Grid>
      )}
      <Grid item xs={3}>
        <FastField
          name={`${name}.maximumNumber`}
          label={t('Maximum number')}
          required
          type="number"
          validate={Yip(Yup.number().typeError(t('This field must be a number')).required().moreThan(0).lessThan(26))}
          component={TextFieldComplex}
          fullWidth
          placeholder={t('Enter number')}
          inputLabelProps={{ shrink: true }}
          numberFormatProps={{ decimalScale: 0, decimalSeparator: false }}
        />
      </Grid>
    </Grid>
  )
}
