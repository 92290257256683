import React from 'react'
import { FlagIcon as FlagIconComp, FlagIconCode, FlagIconProps as FlagProps } from 'react-flag-kit'
import {
  AntarticaFlagImage,
  BonaireFlagImage,
  NetherlandsAntilesFlagImage,
  SaintHelenaFlagImage,
  SaintMartinFlagImage,
  SerbiaMontengeroFlagImage,
  WesternSaharaFlagImage,
} from 'assets'

interface FlagImgProps {
  FlagIconProps?: FlagProps
  code: string
  size?: number
  style?: React.CSSProperties
  className?: string
}

export interface CountryCodeCustomInt {
  [key: string]: string
}

const countryCodeCustom: CountryCodeCustomInt = {
  AQ: AntarticaFlagImage,
  AN: NetherlandsAntilesFlagImage,
  SH: SaintHelenaFlagImage,
  EH: WesternSaharaFlagImage,
  CS: SerbiaMontengeroFlagImage,
  MF: SaintMartinFlagImage,
  BQ: BonaireFlagImage, // TODO: WRONG, NEED to looking for a flag to it
}

const Flag = ({ code, size = 24, style, className = '', FlagIconProps }: FlagImgProps): React.ReactElement => {
  const defaultImgStyles = {
    marginRight: 8,
    verticalAlign: 'text-bottom',
  }
  const defaultFlagStyles = {
    marginRight: 12,
    verticalAlign: 'sub',
  }
  const flagStyles = {
    ...defaultFlagStyles,
    ...FlagIconProps?.style,
  }
  const imgStyles = {
    ...defaultImgStyles,
    ...FlagIconProps?.style,
  }

  if (countryCodeCustom[code]) {
    return (
      <img
        data-dls="atom-Flag"
        src={countryCodeCustom[code]}
        alt=""
        {...FlagIconProps}
        style={{ ...imgStyles, ...style }}
        width={`${size}px`}
        aria-hidden
      />
    )
  }
  return (
    <FlagIconComp
      data-dls="atom-Flag"
      {...FlagIconProps}
      style={{ ...flagStyles, ...style }}
      code={code as FlagIconCode}
      size={size}
      aria-hidden
      className={className}
    />
  )
}

export default Flag
