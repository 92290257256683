import React from 'react'
import { Tooltip, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { EntityBadgeFutureRate } from 'dls/atoms'
import EntitySectionCard from 'pages/Layout/EntityReviewPageLayout/EntitySectionCard'
import { toBankTz } from 'utils/date.utils'
import { InterestRate } from 'store/referenceData/types'
import { RateMultiplyByHundred } from 'utils/form.utils'
import { AddCircleIconButton, Body1, DesignIconButton, ReviewRows } from 'dls'
import { Separator } from 'dls/molecules/ReviewRows/types'

interface Props {
  futureRates: InterestRate[]
  bankIndexKey: string
}

const FutureInterestRateSectionCard = ({ futureRates, bankIndexKey }: Props) => {
  const { t } = useTranslation()

  const AddRateAction = () => (
    <Tooltip title={`${t('Add new rate')}`} aria-label="edit" placement="right">
      <AddCircleIconButton
        aria-label="Add new rate"
        role="button"
        href={`reference-data/index-rates/${bankIndexKey}/add`}
        style={{ margin: -12 }}
      />
    </Tooltip>
  )

  const futureRatesDefinitions = futureRates.map((rate, index) => ({
    groupSeparator: index < futureRates.length - 1 ? ('light' as Separator) : undefined,
    definitions: [
      { term: t('Rate'), definitions: [`${RateMultiplyByHundred(rate.indexRate || 0)}%`] },
      { term: t('Effective date'), definitions: [toBankTz(rate?.effectiveDate?.toString()).date] },
    ],
  }))
  return (
    <EntitySectionCard
      headline="Future rates"
      title={<Body1>{t(`${futureRates.length || 'No'} future rate${futureRates.length !== 1 ? 's' : ''}`)}</Body1>}
      headerIcon={<EntityBadgeFutureRate />}
      headerAction={<AddRateAction />}
      accordionDisabled={!futureRates.length}
    >
      <ReviewRows definitionsList={futureRatesDefinitions} />
      {!!futureRates.length && (
        <Box display="flex" alignSelf="flex-end" margin={-0.5}>
          <Tooltip title={`${t('Edit future rate(s)')}`} aria-label="edit" placement="right">
            <DesignIconButton
              aria-label="Edit future rate(s)"
              role="button"
              href={`reference-data/index-rates/${bankIndexKey}/edit`}
            />
          </Tooltip>
        </Box>
      )}
    </EntitySectionCard>
  )
}

export default FutureInterestRateSectionCard
