import { createSelector } from 'reselect'
import { EntityType } from './types'
import { RootState } from 'store'

const selectGovernanceState = (state: RootState) => state.governance

const selectAllProcess = createSelector(selectGovernanceState, (state) =>
  (state.processes || []).filter((process) => process.entityType === EntityType.PRODUCT)
)
const selectprocessExecutions = createSelector(selectGovernanceState, (state) => state.processExecutions)
const selectActiveprocessExecutions = createSelector(selectGovernanceState, (state) =>
  (state.processExecutions || []).filter((process) => !process.status || process.status !== 'CLOSED')
)

const selectError = createSelector(selectGovernanceState, (governance) => governance?.error)
const selectIsLoading = createSelector(selectGovernanceState, (state) => state.isLoading)

const selectProcessExecutionsLoading = createSelector(selectGovernanceState, (state) => state.processExecutionsLoading)
const selectprocessExecutionsError = createSelector(selectGovernanceState, (state) => state.processExecutionsError)

const selectProcessDetails = createSelector(selectGovernanceState, (state) => state.selectedProcess)

export default {
  selectprocessExecutions,
  selectActiveprocessExecutions,
  selectIsLoading,
  selectError,
  selectAllProcess,
  selectProcessDetails,
  selectProcessExecutionsLoading,
  selectprocessExecutionsError,
}
