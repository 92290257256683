import styled from 'styled-components/macro'
import { Card, CardActionArea, CardMedia, Grid } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'

export const StyledCard = styled(Card)<{ $isSelected: boolean; $isCondensed: boolean }>`
  max-width: 300px;
  border: ${(props: any) =>
    props.$isSelected ? `solid 2px ${props.theme.palette.secondary.main}` : 'solid 2px transparent'};
  height: ${(props: any) => (props.$isCondensed ? `250px` : '350px')};
`

export const StyledGrid = styled(Grid)<{ $isCondensed: boolean }>`
  height: ${(props: any) => (props.$isCondensed ? `260px` : '300px')};
  padding: 0 10px;
`
export const TypographyItem = styled.div`
  border-top: ${(props: any) => `1px solid ${props.theme.palette.background.default}`};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiTypography-root {
    line-height: 50px;
  }
`
export const CardImageMedia = styled(CardMedia)<{ component: string }>`
  width: 274px;
  height: 274px;
  object-fit: scale-down !important;
`
export const StyledCheck = styled(CheckCircle)`
  margin-right: 5px;
`

export const CircleIcon = styled.div<{ padding?: 'small' | 'large' }>`
  .MuiSvgIcon-root {
    height: 40px;
    width: 40px;
    fill: white;
    background: ${(props: any) => `${props.theme.palette.text.secondary}`};
    border: 2px solid white;
    padding: ${(props: any) => (props.padding === 'small' ? '4px' : '8px')};
    border-radius: 50%;
  }
`

export const HoverEditIcon = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.1);
  justify-content: center;
  align-items: center;
  display: inline-flex;
`
export const StyledCardActionArea = styled(CardActionArea)`
  &:hover ${HoverEditIcon}, &:focus ${HoverEditIcon} {
    opacity: 1;
  }
`
