import React from 'react'
import { Grid, Typography, DialogContent, Fade, Dialog } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import ClearIcon from '@material-ui/icons/Clear'
import { Pending, Cleared, ContraCleared, ContraTransfer, PartiallyCleared, ContraReversedIcon } from 'assets'
import { TransactionStatus } from 'store/accounts/types'
import { stopPropagation } from 'utils/form.utils'
import { AccessibleLink } from 'components'

interface OwnProps {
  onPressCancel: () => void
  heading: string
  open: boolean
}

const StyledImg = styled.img`
  margin-right: 8px;
  vertical-align: middle;
`

const DialogStyleContent = styled(DialogContent)`
  padding: 24px;
  padding-left: 32px;
  padding-right: 32px;
  overflow-x: hidden;
`
const Title = styled(Typography)`
  padding-bottom: 6px;
`

const StyledTableCell1 = styled(TableCell)`
  width: 25%;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding-left: 0px;
  padding-right: 0px;
`
const StyledTableCell2 = styled(TableCell)`
  width: 75%;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding-left: 0;
  padding-right: 0;
`

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`

const StatusInformationModal = ({ onPressCancel, heading, open }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <StyledDialog open={open} scroll="paper" onClose={onPressCancel} TransitionComponent={Fade} fullWidth>
      <DialogStyleContent>
        <Grid container direction="row" justifyContent="space-between" spacing={7}>
          <Grid item>
            <Title variant="h6" gutterBottom id="transactions-status-information-dialog">
              {t(heading)}
            </Title>
          </Grid>
          <Grid item>
            <AccessibleLink
              arial-label="close"
              onClick={(e: React.MouseEvent) => {
                stopPropagation(e)
                onPressCancel()
              }}
            >
              <ClearIcon css="margin-top:5px" color="action" />
            </AccessibleLink>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Table aria-label="caption table">
            <TableBody>
              <TableRow key={TransactionStatus.CLEARED}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={Cleared} alt="icon_cleared" aria-hidden="true" />
                    {t(TransactionStatus.CLEARED)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">
                    {t('These transactions have been cleared out of the suspense account to a General Ledger account')}
                  </Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.CONTRA_CLEARED}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={ContraCleared} alt="icon_contra_cleared" aria-hidden="true" />
                    {t(TransactionStatus.CONTRA_CLEARED)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">
                    {t(
                      'These transactions have cleared out other suspense transactions via a direct credit/debit or a topic (these actions cannot be performed in Bank Manager)'
                    )}
                  </Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.CONTRA_TRANSFER}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={ContraTransfer} alt="icon_contra_transfer" aria-hidden="true" />
                    {t(TransactionStatus.CONTRA_TRANSFER)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">
                    {t(
                      'These transactions have cleared out other suspense transactions by moving them to another suspense account'
                    )}
                  </Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.CONTRA_WRITE_BACK}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={ContraCleared} alt="icon_contra_write_back" aria-hidden="true" />
                    {t(TransactionStatus.CONTRA_WRITE_BACK)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">
                    {t(
                      'These transactions have cleared out other credit suspense transactions to a General Ledger account'
                    )}
                  </Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.CONTRA_WRITE_BACK_REVERSED}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={ContraReversedIcon} alt="icon_contra_write_back" aria-hidden="true" />
                    {t(TransactionStatus.CONTRA_WRITE_BACK_REVERSED)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">{t('These are reversed contra write-back transactions')}</Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.CONTRA_WRITE_OFF}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={ContraCleared} alt="icon_contra_write_off" aria-hidden="true" />
                    {t(TransactionStatus.CONTRA_WRITE_OFF)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">
                    {t(
                      'These transactions have cleared out other debit suspense transactions to a General Ledger account'
                    )}
                  </Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.CONTRA_WRITE_OFF_REVERSED}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={ContraReversedIcon} alt="icon_contra_write_back" aria-hidden="true" />
                    {t(TransactionStatus.CONTRA_WRITE_OFF_REVERSED)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">{t('These are reversed contra write-off transactions')}</Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.PARTIALLY_CLEARED}>
                <StyledTableCell1 align="left">
                  <span>
                    <StyledImg src={PartiallyCleared} alt="icon_partially_cleared" aria-hidden="true" />
                    {t(TransactionStatus.PARTIALLY_CLEARED)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 align="left">
                  <Typography variant="body2">
                    {t(
                      'These transactions have only been partially cleared by another suspense transaction entry and must be fully cleared'
                    )}
                  </Typography>
                </StyledTableCell2>
              </TableRow>
              <TableRow key={TransactionStatus.PENDING}>
                <StyledTableCell1 css="border-bottom: none;" align="left">
                  <span>
                    <StyledImg src={Pending} alt="icon_pending" aria-hidden="true" />
                    {t(TransactionStatus.PENDING)}
                  </span>
                </StyledTableCell1>
                <StyledTableCell2 css="border-bottom: none;" align="left">
                  <Typography variant="body2">
                    {t('These transactions are outstanding in the suspense account and must be cleared')}
                  </Typography>
                </StyledTableCell2>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </DialogStyleContent>
    </StyledDialog>
  )
}

export default StatusInformationModal
