import React from 'react'
import { Step, StepLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'
import { connect } from 'react-redux'
import { getSteps as getProductSteps, getActiveStep as getProductActiveStep } from './ProductSteps'
import { getSteps as getPackageSteps, getActiveStep as getPackageActiveStep } from './PackageSteps'
import { StyledStepper } from './Style'
import { ProductStatus } from 'store/products/typings/productStatus'
import { RootState } from 'store/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { hasGovernanceEnabled } from 'pages/Product/product.utils'
import { toBankTz } from 'utils/date.utils'
import { PackagesStatus } from 'store/packages/typings/packageStatus'

interface OwnProps {
  isOldVersion?: boolean
  effectiveDate?: string
  status: ProductStatus | PackagesStatus
  type: 'PRODUCT' | 'PACKAGE'
}

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapStateToProps> & OwnProps

const ProductPackageStepper = ({ tenantConfig, status, effectiveDate, type }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const isGovernanceEnabled = hasGovernanceEnabled(tenantConfig) || false
  const parsedDate = effectiveDate ? toBankTz(effectiveDate) : undefined
  const currentStep =
    type === 'PRODUCT'
      ? getProductActiveStep(status as ProductStatus, isGovernanceEnabled, parsedDate)
      : getPackageActiveStep(status as PackagesStatus, parsedDate)

  const steps =
    type === 'PRODUCT'
      ? getProductSteps(status as ProductStatus, isGovernanceEnabled, currentStep)
      : getPackageSteps(status as PackagesStatus, currentStep)
  return (
    <StyledStepper activeStep={currentStep} elevation={0}>
      {isArray(steps) &&
        steps.map(({ labelProps, label, ...otherProps }, index) => (
          <Step key={index} {...otherProps}>
            <StepLabel {...labelProps} StepIconProps={{ 'aria-hidden': false }}>
              {t(label)}
            </StepLabel>
          </Step>
        ))}
    </StyledStepper>
  )
}

export default connect(mapStateToProps)(ProductPackageStepper)
