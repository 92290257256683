import React, { useEffect } from 'react'
import TasksDrawer from './TasksDrawer'
import { Task } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { Order } from 'store/packages/types'
import { Product } from 'store/products/types'
import { NotificationIconButton } from 'dls'

interface OwnProps {
  tasks: Task[]
  isLoading: boolean
  products: Product[]
}

const TasksBadge = ({ tasks, isLoading, products }: OwnProps): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [isFilterListOpen, setIsFilterListOpen] = React.useState<boolean>(false)
  const [status, setStatus] = React.useState('')
  const [dateOrder, setDateOrder] = React.useState<Order>('desc')
  const [filteredTasks, setFilteredTasks] = React.useState(tasks)

  useEffect(() => {
    setFilteredTasks(tasks)
  }, [tasks, isLoading])

  const showTasks = filteredTasks.length > 0

  const handleDrawerOpenClose = () => {
    setOpen(!open)
  }

  const handleStatusFilter = (event: React.ChangeEvent<{ value: TaskStatus }>) => {
    setStatus(event.target.value)
  }

  const handleDateFilter = (event: React.ChangeEvent<{ value: Order }>) => {
    setDateOrder(event.target.value)
  }

  const handleFilterListOpenClose = () => {
    if (showTasks) setIsFilterListOpen(!isFilterListOpen)
  }
  return (
    <>
      <NotificationIconButton
        aria-controls="notificationDrawer"
        aria-haspopup="true"
        onClick={handleDrawerOpenClose}
        badgeContent={
          filteredTasks.filter(
            (task) =>
              ![TaskStatus.CLOSED, TaskStatus.REJECTED].includes(task.status) && task.entityKey && task.entityType
          ).length
        }
        max={99}
        badgeColor="error"
      />
      <TasksDrawer
        handleDrawerOpenClose={handleDrawerOpenClose}
        handleFilterListOpenClose={handleFilterListOpenClose}
        isFilterListOpen={isFilterListOpen === true}
        open={open}
        tasks={filteredTasks}
        isLoading={isLoading}
        status={status}
        handleStatusFilter={handleStatusFilter}
        date={dateOrder}
        handleDateFilter={handleDateFilter}
        products={products}
      />
    </>
  )
}

export default TasksBadge
