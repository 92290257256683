import { Grid } from '@material-ui/core'
import { Field, getIn, connect as connectFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { TopLevelFrequency, YearlyFrequencyItems } from 'store/products/types'
import DateTimePickerComponent from 'components/form/DateTimePickerComponent'
import { If } from 'components/ControlComponents'
import { Yip } from 'utils/form.utils'
import { SelectFieldComplex } from 'components'

const NestedAnnualFields = connectFormik<NestedFieldsProps>(({ rootName, formik: { values }, disable }) => {
  const { t } = useTranslation()
  return (
    <>
      <Grid item xs={4}>
        <Field
          label={t(`${TopLevelFrequency.ANNUAL}.secondary.label`)}
          validate={Yip(Yup.string().required())}
          name={`${rootName}.${TopLevelFrequency.ANNUAL}.secondary`}
          required
          data={YearlyFrequencyItems}
          component={SelectFieldComplex}
          displayEmpty
          fullWidth
          selectProps={{ displayEmpty: true }}
          inputLabelProps={{ shrink: true }}
          disabled={disable}
        />
      </Grid>
      <If
        on={getIn(values, `${rootName}.${TopLevelFrequency.ANNUAL}.secondary`) === YearlyFrequencyItems.SPECIFIC_DATE}
      >
        <Grid item xs={3}>
          <Field
            name={`${rootName}.${TopLevelFrequency.ANNUAL}.tertiary`}
            type="text"
            required
            validate={Yip(Yup.string().required())}
            helperText={t(`${TopLevelFrequency.ANNUAL}.tertiary.helper`)}
            component={DateTimePickerComponent}
            label={t(`${TopLevelFrequency.ANNUAL}.tertiary.label`)}
            dateTimePickerProps={{
              shouldDisableDate: (date: moment.Moment) => date && date.date() > 28,
            }}
            disabled={disable}
          />
        </Grid>
      </If>
    </>
  )
})

export default NestedAnnualFields
