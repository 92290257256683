import React from 'react'
import { PaperProps, Paper as MUIPaper } from '@material-ui/core'
import styled from 'styled-components/macro'
import { internalPadding, internalMdPadding } from 'dls/shared/styleVar'

export interface OwnProps extends PaperProps {
  defaultSpacing?: boolean
  responsivePadding?: boolean
  ariaProps?: Record<string, string | boolean>
  radius?: number
}

export const StyledMUIPaper = styled(MUIPaper)<{
  $defaultSpacing: boolean
  $variant?: string
  radius?: number
  $responsivePadding: boolean
  square?: boolean
}>`
  ${({ radius, square }) =>
    radius &&
    !square &&
    `
    border-radius: ${radius}px;
    `};
  ${({ $variant }) =>
    $variant !== 'elevation' &&
    `
      box-shadow: none;
    `};
  ${({ $defaultSpacing, $responsivePadding, theme }) =>
    $defaultSpacing &&
    `
   padding: ${internalMdPadding};
   ${theme.breakpoints.up('md')} {
     padding: ${$responsivePadding ? internalPadding : internalMdPadding};
    }
  `};
`

const Paper = ({
  elevation = 1,
  defaultSpacing = true,
  responsivePadding = false,
  ariaProps,
  variant,
  radius = 8,
  ...props
}: OwnProps) => (
  <StyledMUIPaper
    elevation={elevation}
    data-dls="atom-paper"
    {...props}
    {...ariaProps}
    {...(variant && { $variant: variant })}
    radius={radius}
    $defaultSpacing={defaultSpacing}
    $responsivePadding={responsivePadding}
  />
)

export default Paper
