import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InterestRateCatalog from './InterestRateCatalog'
import ReferenceDataActions from 'store/referenceData/actions'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import { Loader } from 'components'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export const InterestRateCatalogPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const fetchBankInterestRates = () => dispatch(ReferenceDataActions.fetchBankInterestRate())

  const bankInterestRates = useSelector(ReferenceDataSelectors.selectAllBankInterestRate)
  const loading = useSelector(ReferenceDataSelectors.selectLoading)

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Index rates'), path: '' },
    ],
    trigger: true,
  })

  useEffect(() => {
    fetchBankInterestRates()
  }, [])

  if (loading) {
    return <Loader />
  }

  return <InterestRateCatalog bankInterestRates={bankInterestRates} />
}

export default InterestRateCatalogPage
