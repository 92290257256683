import React, { CSSProperties } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Graphic } from 'dls/atoms/Graphic'
import { Body1 } from 'dls/atoms/Typography'
import { NoResultImage } from 'assets'
import { ImageTypes } from 'dls/shared/types'

interface Props {
  id?: string
  style?: CSSProperties
  copy?: React.ReactNode
  showCopy?: boolean
  searchText?: React.ReactNode
  placement?: 'center' | 'left' | 'right'
  image?: string
  imageProps?: ImageTypes
  ariaProps?: Record<string, string | boolean>
  value?: React.ReactNode
}

const NoResultContainer = styled.div`
  vertical-align: middle;
`

export const NoResult = ({
  id,
  style,
  copy,
  placement = 'center',
  showCopy = true,
  imageProps,
  searchText,
  ariaProps,
  image = NoResultImage,
  ...props
}: Props) => {
  const { t } = useTranslation()
  return (
    <NoResultContainer
      data-dls="molecule-result-container"
      style={{ textAlign: placement, ...style }}
      id={id}
      {...props}
      {...ariaProps}
    >
      <Graphic
        alt={t('Illustration of a man looking into an empty box')}
        style={{ marginBottom: 12, ...style }}
        src={image}
        {...imageProps}
      />
      {showCopy && (
        <Body1>
          {copy || (
            <>
              {`${t('Oops we couldn’t find any results')}`}
              {!!searchText && ` ${t('for')} "${searchText}"`}
              <br />
              {t('clear your search and try again')}
            </>
          )}
        </Body1>
      )}
    </NoResultContainer>
  )
}

export default NoResult
