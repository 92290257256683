import { RateTierBand, TierBand } from '../products/types'

export type RuleId = string
export type TaxRuleId = string
export type AssigneeKey = string
export type ProcessExecutionKey = string

export type EntityUpdateMode = 'add' | 'edit' | 'create'

export interface WithholdingRule {
  ruleId: RuleId
  name: string
  taxRules: WithholdingTaxRule[]
}

export interface WithholdingRuleFormValues extends WithholdingTaxRuleFormValues {
  ruleId: RuleId
  name: string
}

export interface WithholdingRules {
  rules: WithholdingRule[]
}

export interface WithholdingTaxRule {
  ruleId?: string
  taxRuleId?: string
  withholdTax: boolean
  facts?: WithholdingTaxFact[]
  tierBandCalcMethod: string
  effectiveDate: string
  taxTierBand?: TierBand[]
  flatRate?: number
  createdTimestamp?: string
  updatedTimestamp?: string
}

export interface WithholdingTaxRuleFormValues {
  ruleId?: string
  taxRuleId?: string
  withholdTax: boolean
  facts?: WithholdingTaxFact[]
  tierBandCalcMethod: RateTierBand
  effectiveDate: string
  taxTierBand?: TierBand[]
  flatRate?: number
  createdTimestamp?: string
  updatedTimestamp?: string
}

export interface WithholdingTaxFact {
  name: WithholdingTaxFactName
  operator: WithholdingTaxFactOperator
  value: (string | number)[]
}

export enum WithholdingTaxFactName {
  AGE = 'AGE',
  EXEMPTION_REASON = 'EXEMPTION_REASON',
  NATIONALITY = 'NATIONALITY',
  COUNTRY_OF_RESIDENCE = 'COUNTRY_OF_RESIDENCE',
  TIN_AVAILABLE = 'TIN_AVAILABLE',
  TAX_RESIDENCY_APPLIED = 'TAX_RESIDENCY_APPLIED',
  INTEREST_EARNED_IN_A_PERIOD = 'INTEREST_EARNED_IN_A_PERIOD',
  PROJECTED_ANNUAL_INCOME = 'PROJECTED_ANNUAL_INCOME',
}

export enum WithholdingTaxFactOperator {
  ANY_OF = 'ANY_OF',
  EQUAL_OR_GREATER_THAN = 'EQUAL_OR_GREATER_THAN',
  EQUAL_OR_LESSER_THAN = 'EQUAL_OR_LESSER_THAN',
  EQUAL_TO = 'EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  IS = 'IS',
  LESSER_THAN = 'LESSER_THAN',
  NOT_ANY_OF = 'NOT_ANY_OF',
}

export interface WithholdingTaxRuleState {
  isLoading: boolean
  rules: WithholdingRule[] | null
  selectedRule: null | WithholdingRule
  error?: Error
  selectedRuleProducts: object
  selectedRuleProductsLoading: boolean
  selectedRuleProductsError: boolean
}
