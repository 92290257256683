import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ProductSyndicationCatalog from 'pages/ProductSyndication/ProductSyndicationCatalog/ProductSyndicationCatalog'
import useTenantProductSyndication from 'pages/ProductSyndication/ProductSyndicationCatalog/utils/useTenantProductSyndication'
import { Loader } from 'components'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export const ProductSyndicationCatalogPage = () => {
  const { t } = useTranslation()
  const {
    fetchTenantProductSyndication,
    syndicationProducts,
    syndicationProductsLoading,
  } = useTenantProductSyndication()

  useBreadcrumbs({
    breadcrumbs: [{ title: t('Product syndications'), path: '' }],
    trigger: true,
  })

  useEffect(() => {
    fetchTenantProductSyndication()
  }, [])

  if (syndicationProductsLoading) {
    return <Loader />
  }

  return <ProductSyndicationCatalog syndicationProducts={syndicationProducts} statusQueryParam="" />
}

export default ProductSyndicationCatalogPage
