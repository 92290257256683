import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ExtendedProduct } from 'store/products/types'
import ProductsSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'

export default (productKey: string): [ExtendedProduct | undefined, boolean] => {
  const selectedProduct = useSelector(ProductsSelectors.selectSelectedProduct)
  const isLoading = useSelector(ProductsSelectors.selectLoading)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(ProductsActions.getProductDetails({ productKey }))
  }, [productKey, dispatch])
  return [selectedProduct, isLoading]
}
