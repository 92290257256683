/* eslint-disable no-nested-ternary */

// Some codes are commented out, As we need to implement the same code in future. That is why we have not deleted instead just commented

import React, { useState } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Divider,
} from '@material-ui/core'
import styled from 'styled-components/macro'
import { ExpandMore as ExpandMoreIcon, Cached } from '@material-ui/icons/'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { StyledTrendingUp, StyledTrendingDown, StyledTrendingFlat } from './SuspenseAccountsComps'
import ExpandableDetails, { DataGrid } from './ExpandableDetails'
import { BalanceType, CreditDebitIndicator, CurrencyCode, SuspenseAccount } from 'store/accounts/types'
import SuspenseAccountsSelectors from 'store/accounts/selectors'
import SuspenseAccountsActions from 'store/accounts/actions'
import { stopPropagation } from 'utils/form.utils'
import { PendingAndPartialImg, ProcessingImg } from 'assets'
import { ReloadButton } from 'components/ProductVersionSelector/Styled'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { CustomTheme } from 'dls/shared/styled'

interface Props {
  suspenseAccount: SuspenseAccount
  subscriptionKey: string
}

const StyledContainerPaper = styled(Paper)`
  margin: 8px 0px;
  cursor: pointer;
  &:hover {
    background-color: white;
    box-shadow: ${(props: any) => `${props.theme.shadows[8]}`};
  }
`
const SomethingWentWrongImage = styled.img`
  width: 85px;
  margin-bottom: 16px;
`

const StyledAccordion = styled(Accordion)`
  padding: 16px;
  padding-left: 8px;
  padding-right: 8px;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
    & .MuiIconButton-root {
      align-self: flex-start;
    }
  `};
`

const DivContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledTypography = styled(Typography)`
  display: inline-flex;
  font-weight: 300;
`

const StyledSummaryGrid = styled(Grid)`
  padding: 28px;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      padding: 8px;
    }
  `};
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding: 4px;
    }
  `};
`

const StyledSummaryContainer = styled(Grid)`
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: calc(100% + 56px);
  margin: -28px;
  margin-right: 0;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      width: calc(100% + 16px);
      margin: -8px;
    }
    ${theme.breakpoints.down('xs')} {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: calc(100% + 8px);
      margin: -4px;
    }
  `};
`

const StyledImg = styled.img`
  margin-right: 8px;
  vertical-align: middle;
`

const StyledButton = styled(Button)`
  width: 170px;
`
const getFormat = (currencyCode: CurrencyCode) => {
  switch (currencyCode) {
    case CurrencyCode.GBP:
      return 'en-GB'
    case CurrencyCode.USD:
      return 'en-US'
    case CurrencyCode.AUD:
      return 'en-AU'
    default:
      return 'en-GB'
  }
}

const SuspenseAccountPanel = ({ suspenseAccount, subscriptionKey }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const suspenseTransactions = useSelector(SuspenseAccountsSelectors.selectedSuspenseTransactionsforStat)

  const [expansionState, setExpansionState] = useState<boolean>(false)

  const totalBalance = suspenseAccount.balances.reduce((a, b) => a + b.amount, 0)
  const runningBalance = suspenseAccount.balances.filter((item) => item.balanceType === BalanceType.RUNNING)

  const totalBalanceSplit = totalBalance.toString().split('.')
  const { currency } = suspenseAccount.balances[0]
  const goTransactionsPage = (e: React.MouseEvent) => {
    stopPropagation(e)
    navigate(`/accounts/${suspenseAccount.subscriptionKey}`)
  }
  const dispatch = useDispatch()

  const handleExpand = (e: React.ChangeEvent<{}>, expanded: boolean): void => {
    stopPropagation(e as any)
    setExpansionState(expanded)
    if (expanded) {
      dispatch(SuspenseAccountsActions.getSuspenseTransactionsForStat(suspenseAccount.subscriptionKey))
    }
  }

  return (
    <StyledContainerPaper
      elevation={0}
      key={suspenseAccount.subscriptionKey}
      id={suspenseAccount.subscriptionKey}
      data-test-id="suspense-account-panel"
    >
      <StyledAccordion expanded={expansionState} onChange={handleExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <StyledSummaryContainer container onClick={goTransactionsPage} aria-controls="main">
            <StyledSummaryGrid item zeroMinWidth>
              <Typography variant="body1">{suspenseAccount.name}</Typography>
            </StyledSummaryGrid>
            <StyledSummaryGrid
              item
              css={`
                white-space: nowrap;
                margin-left: auto;
                ${({ theme }: { theme: CustomTheme }) => `
                ${theme.breakpoints.down('xs')} {
                  margin: 0;
                  margin-top:8px;
                }
              `};
              `}
            >
              <StyledTypography variant="h4" variantMapping={{ h4: 'div' }} style={{ fontSize: '2.125rem' }}>
                {runningBalance[0].creditDebitIndicator === CreditDebitIndicator.DEBIT ? <>{`-  `}</> : null}

                {Intl.NumberFormat(`${getFormat(currency)}`, { style: 'currency', currency: `${currency}` })
                  .format(Number(totalBalanceSplit[0]))
                  .toString()
                  .replace('.00', '')}
              </StyledTypography>
              <StyledTypography variant="h5" variantMapping={{ h5: 'div' }} style={{ fontSize: '1.125rem' }}>
                {totalBalanceSplit[1] && totalBalanceSplit[1].length > 1
                  ? `.${totalBalanceSplit[1]}`
                  : totalBalanceSplit[1] && totalBalanceSplit[1].length === 1
                  ? `.${totalBalanceSplit[1]}0`
                  : '.00'}
              </StyledTypography>
              {totalBalance > 0 && runningBalance[0].creditDebitIndicator === CreditDebitIndicator.DEBIT ? (
                <StyledTrendingDown />
              ) : totalBalance > 0 && runningBalance[0].creditDebitIndicator === CreditDebitIndicator.CREDIT ? (
                <StyledTrendingUp />
              ) : (
                <StyledTrendingFlat />
              )}
            </StyledSummaryGrid>
            <StyledSummaryGrid item>
              <StyledButton
                onClick={goTransactionsPage}
                variant="outlined"
                color={ColorType.BUTTON}
                aria-controls="main"
              >
                {t('Transactions')}
              </StyledButton>
            </StyledSummaryGrid>
          </StyledSummaryContainer>
        </AccordionSummary>
        <AccordionDetails style={{ minHeight: 138, cursor: 'auto', paddingBottom: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Divider style={{ marginBottom: 8 }} />
            </Grid>
            <Grid item xs={12}>
              {suspenseTransactions &&
              (!suspenseTransactions[subscriptionKey] || suspenseTransactions[subscriptionKey].isLoading) ? (
                <DivContainer>
                  <CircularProgress size={75} />
                </DivContainer>
              ) : suspenseTransactions &&
                suspenseTransactions[subscriptionKey] &&
                suspenseTransactions[subscriptionKey].error ? (
                <DivContainer css="justify-content: flex-start;">
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <SomethingWentWrongImage
                        src={ProcessingImg}
                        alt="Oops, something went wrong"
                        height={60}
                        width={60}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>{t('Oops, something went wrong')}</Typography>
                      <ReloadButton
                        onClick={(e) => handleExpand(e, true)}
                        startIcon={<Cached />}
                        color={ColorType.NONE}
                      >
                        {t('Try again')}
                      </ReloadButton>
                    </Grid>
                  </Grid>
                </DivContainer>
              ) : (
                <Grid container alignItems="flex-end" spacing={4}>
                  <Grid item sm={3} xs={6}>
                    <DataGrid
                      container
                      spacing={0}
                      direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <StyledImg
                          src={PendingAndPartialImg}
                          alt="pending and partial"
                          aria-hidden="true"
                          width={48}
                          height={48}
                          style={{ marginBottom: 4 }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          css="font-weight:300"
                          variantMapping={{ body1: 'div' }}
                          color="inherit"
                        >
                          Pending & Partially cleared
                        </Typography>
                      </Grid>
                    </DataGrid>
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <ExpandableDetails
                      onClick={() => navigate(`/accounts/${suspenseAccount.subscriptionKey}?time=30`)}
                      unSettledTransaction={
                        suspenseTransactions &&
                        suspenseTransactions[subscriptionKey] &&
                        suspenseTransactions[subscriptionKey].selectedSuspenseTransactionsFor30Days.transactions
                      }
                      content="Over 30 days old"
                    />
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <ExpandableDetails
                      onClick={() => navigate(`/accounts/${suspenseAccount.subscriptionKey}?time=60`)}
                      unSettledTransaction={
                        suspenseTransactions &&
                        suspenseTransactions[subscriptionKey] &&
                        suspenseTransactions[subscriptionKey].selectedSuspenseTransactionsFor60Days.transactions
                      }
                      content="Over 60 days old"
                    />
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <ExpandableDetails
                      onClick={() => navigate(`/accounts/${suspenseAccount.subscriptionKey}?time=90`)}
                      unSettledTransaction={
                        suspenseTransactions &&
                        suspenseTransactions[subscriptionKey] &&
                        suspenseTransactions[subscriptionKey].selectedSuspenseTransactionsFor90Days.transactions
                      }
                      content="Over 90 days old"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </StyledContainerPaper>
  )
}

export default SuspenseAccountPanel
