/* eslint-disable no-nested-ternary */
import { CircularProgress, DialogActions, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Field, Form, FormikProps, FormikValues, getIn } from 'formik'
import moment, { Moment } from 'moment-timezone'
import React from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { orderBy, set, find } from 'lodash'
import { PublishProductFormValues, Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
// eslint-disable-next-line import/no-cycle
import { DateTimePickerComponent, ModalWrapper, TextFieldComplex } from 'components'
import { withFormikSimple, Yip } from 'utils/form.utils'
import SubmitButton from 'components/form/SubmitButton'
import { toBankTz, DATETIME_FORMAT } from 'utils/date.utils'
import { hexCodeToString } from 'utils/common.util'
import { FloorType } from 'utils/types'
import { isVersionFirst } from 'utils/productVersion.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  setOpen: (value: boolean) => void
  updating: boolean
  versions: Product[]
  product: Product
  minProductRepublishDelay: number
  earliestValidEffectiveDate: Moment
}

type Props = FormikProps<PublishProductFormValues> & OwnProps

const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  color: ${(props: any) => props.theme.palette.secondary};
  top: 50%;
  left: 88%;
  margin-top: -7px;
  margin-left: -12px;
`

const StyledDialogActions = styled(DialogActions)`
  padding-top: 20px;
  padding-right 0;
  position: relative;
`

export const getLatestPublishedVersion = (versions?: Product[]): Product | undefined => {
  const orderedVersions = orderBy(versions, ['version'], ['desc'])
  return find(orderedVersions, function (product) {
    return product.status === ProductStatus.LIVE
  })
}

export const isLatestVersionEffective = (latestVersion?: Product): boolean =>
  !!latestVersion && latestVersion.effectiveDate ? !toBankTz(latestVersion.effectiveDate).isAfterNow : true

const PublishProductForm = (props: Props) => {
  const { updating, setOpen, versions, product } = props
  const { t } = useTranslation()
  const isPrevVersionEffective = !!versions && isLatestVersionEffective(getLatestPublishedVersion(versions))
  const latestVersion = getLatestPublishedVersion(versions)
  const delayInHours = props.minProductRepublishDelay ? props.minProductRepublishDelay / 60 : 24
  return (
    <Form>
      <ModalWrapper
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography variant="h6" gutterBottom>
            {t('Publish product')}
          </Typography>
        }
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="body2" gutterBottom>
              {!isVersionFirst(product) && !!isPrevVersionEffective
                ? t(
                    'Choose the effective date & time (when your product will be live). The time must be at least {{delayInHours}} hours in the future so subscriptions from the earlier version can be migrated. And the time must be outside of key platform operations (23:00–3:00).',
                    {
                      nsSeparator: '||',
                      delayInHours,
                    }
                  )
                : !isVersionFirst(product) && !isPrevVersionEffective
                ? `${hexCodeToString(
                    t(
                      'Choose the effective date & time (when your product will be live). The time must be at least {{delayInHours}} hours later than the previous versions effective date which is {{effectiveDate}}. This is necessary so subscriptions from the earlier version can be migrated. The time must also be outside of key platform operations (23:00–3:00).',
                      {
                        nsSeparator: '||',
                        effectiveDate: latestVersion
                          ? toBankTz(latestVersion?.effectiveDate || '')
                              .mDate.add(props.minProductRepublishDelay, 'minutes')
                              .format(DATETIME_FORMAT)
                          : t('N/A'),
                        delayInHours,
                      }
                    )
                  )}`
                : t(
                    'Enter the effective date & time (when you product will be live). The time must be outside of key platform operations (23:00-03:00).',
                    { nsSeparator: '||' }
                  )}
            </Typography>
          </Grid>
          <Grid item>
            <Field
              helperText={t('This is when your new or updated product is available')}
              placeholder={t('Select date')}
              name="effectiveDate"
              type="text"
              clearable
              variant="inline"
              touchedNotNeeded
              round={FloorType.SECONDS}
              component={DateTimePickerComponent}
              pickerType="DateTime"
              label={`${t('Effective date & time')} (${toBankTz(getIn(props.values, `effectiveDate`)).tz})`}
              InputLabelProps={{ style: { width: 300 } }}
              dateTimePickerProps={{
                minDate: props.earliestValidEffectiveDate,
                minDateMessage: 'Date and time can’t be in the past',
                maxTime: moment('22:59', 'HH:MM'),
                minTime: moment('03:01', 'HH:MM'),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              label={t('Reason for publishing')}
              name="message"
              type="text"
              component={TextFieldComplex}
              helperText={t('Maximum character limit is 1000 characters')}
              fullWidth
              required
              multiline
              rows={5}
              validate={Yip(Yup.string().typeError(t('This is mandatory field')).required())}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <StyledDialogActions>
          <Button disabled={false} onClick={() => setOpen(false)} color={ColorType.BUTTON}>
            {t('Cancel')}
          </Button>
          <SubmitButton
            css="margin-left:16px !important; width:150px;"
            disabled={updating}
            variant="contained"
            color={ColorType.BUTTON}
            data-test-id="product-publish-button"
          >
            {t('Publish')}
          </SubmitButton>
          {updating && <ButtonProgress size={24} />}
        </StyledDialogActions>
      </ModalWrapper>
    </Form>
  )
}
export function validateEffectiveDateMaintenanceForm(dateValue: Moment): boolean {
  if (
    dateValue &&
    (dateValue.hours() >= 23 || dateValue.hours() < 3 || (dateValue.hours() === 3 && dateValue.minutes() === 0))
  ) {
    return false
  }
  return true
}

export const publishValidation = (values: FormikValues, props: OwnProps) => {
  const errors = {}
  if (!values.effectiveDate) {
    return set(errors, 'effectiveDate', 'This field is mandatory.')
  }
  if (values.effectiveDate.isBefore(moment())) {
    return set(errors, 'effectiveDate', 'The time must be in the future')
  }
  if (
    values.effectiveDate.hours() >= 23 ||
    values.effectiveDate.hours() < 3 ||
    (values.effectiveDate.hours() === 3 && values.effectiveDate.minutes() === 0)
  ) {
    return set(errors, 'effectiveDate', 'Time must be outside of key platform operations (23:00-03:00)')
  }

  if (!isVersionFirst(props.product)) {
    const latestVersion = getLatestPublishedVersion(props.versions) || ({} as Product)
    const isLive = moment().isAfter(latestVersion.effectiveDate)
    if (
      isLive &&
      props.minProductRepublishDelay &&
      values.effectiveDate.isBefore(moment().add(props.minProductRepublishDelay - 1, 'minutes'))
    ) {
      return set(
        errors,
        'effectiveDate',
        `This field must be at least ${props.minProductRepublishDelay / 60} hours from now`
      )
    }
    if (
      !isLive && // is scheduled
      props.minProductRepublishDelay &&
      values.effectiveDate.isBefore(moment(latestVersion.effectiveDate).add(props.minProductRepublishDelay, 'minutes'))
    ) {
      return set(
        errors,
        'effectiveDate',
        `This field must be at least ${
          props.minProductRepublishDelay / 60
        } hours after the previous versions effective date`
      )
    }
    if (!props.minProductRepublishDelay && values.effectiveDate.isBefore(moment().add(24, 'hours'))) {
      return set(errors, 'effectiveDate', 'The time must be at least 24 hours in the future')
    }
    if (
      !props.minProductRepublishDelay &&
      values.effectiveDate.isBefore(moment(latestVersion.effectiveDate).add(24, 'hours'))
    ) {
      return set(
        errors,
        'effectiveDate',
        'The time must be at least 24 hours after the previous versions effective date'
      )
    }
  }
  return errors
}

export default withFormikSimple<OwnProps, PublishProductFormValues>(PublishProductForm, {
  validate: publishValidation,
  isInitialValid: (props: Partial<Props>) =>
    !!props.initialValues &&
    !!props.initialValues.effectiveDate &&
    validateEffectiveDateMaintenanceForm(props.initialValues.effectiveDate),
})
