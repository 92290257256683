import React from 'react'
import {
  ExpandLess,
  ExpandMore,
  History,
  Code,
  Add,
  GetApp,
  InfoOutlined,
  Clear,
  ErrorOutline,
  Settings,
  AccountBalance,
  Check,
  HelpOutline,
  CardTravel,
  Assignment,
  AssignmentInd,
  BusinessCenterOutlined,
  Create,
  Description,
  Event,
  FileCopy,
  Language,
  PanTool,
  PlaylistAddCheck,
  PowerInput,
  Receipt,
  SettingsSystemDaydream,
  ShowChart,
  Spellcheck,
  ToggleOn,
  Traffic,
  TrendingDown,
  AttachMoney,
  Archive,
  Block,
  NavigateNext,
  NavigateBefore,
  Face,
  Home,
  CheckCircle,
  CancelRounded,
  Launch,
  ArrowForward,
  Visibility,
  VisibilityOff,
  List,
  ArrowBack,
  AddCircle,
  Cached,
  Delete,
} from '@material-ui/icons'
import { ColorType } from 'dls/shared/types'
import { AIconProps, SVGIcon } from 'dls/atoms/Icons/Generator/svg'

export const ArrowBackIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-arrow-back" color={ColorType.SECONDARY} {...props} Icon={ArrowBack as any} />
)
export const ListIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-list" color={ColorType.SECONDARY} {...props} Icon={List as any} />
)

export const VisibilityOffIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-visibilityOff" color={ColorType.SECONDARY} {...props} Icon={VisibilityOff as any} />
)

export const VisibilityIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-visibility" color={ColorType.SECONDARY} {...props} Icon={Visibility as any} />
)

export const ArrowForwardIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-arrowForward" color={ColorType.SECONDARY} {...props} Icon={ArrowForward as any} />
)

export const CancelRoundedIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-cancelRounded" color={ColorType.SECONDARY} {...props} Icon={CancelRounded as any} />
)

export const FaceIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-face" color={ColorType.SECONDARY} {...props} Icon={Face as any} />
)

export const HomeIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-home" color={ColorType.SECONDARY} {...props} Icon={Home as any} />
)

export const CheckCircleIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-checkCircle" color={ColorType.SECONDARY} {...props} Icon={CheckCircle as any} />
)

export const ShowChartIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-showChart" color={ColorType.SECONDARY} {...props} Icon={ShowChart as any} />
)

export const TrendingDownIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-trendingDown" color={ColorType.SECONDARY} {...props} Icon={TrendingDown as any} />
)

export const AttachMoneyIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-attachMoney" color={ColorType.SECONDARY} {...props} Icon={AttachMoney as any} />
)

export const ArchiveIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-archive" color={ColorType.SECONDARY} {...props} Icon={Archive as any} />
)

export const BlockIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-block" color={ColorType.SECONDARY} {...props} Icon={Block as any} />
)

export const CreateIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-create" color={ColorType.SECONDARY} {...props} Icon={Create as any} />
)

export const NavigateBeforeIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-navigateBefore" color={ColorType.SECONDARY} {...props} Icon={NavigateBefore as any} />
)

export const NavigateNextIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-navigateNext" color={ColorType.SECONDARY} {...props} Icon={NavigateNext as any} />
)

export const TrafficIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-traffic" color={ColorType.SECONDARY} {...props} Icon={Traffic as any} />
)

export const ToggleOnIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-toggleOn" color={ColorType.SECONDARY} {...props} Icon={ToggleOn as any} />
)

export const SpellcheckIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-Spellcheck" color={ColorType.SECONDARY} {...props} Icon={Spellcheck as any} />
)

export const SettingsSystemDaydreamIcon = (props: AIconProps) => (
  <SVGIcon
    data-dls="atom-icon-settings-system-daydream"
    color={ColorType.SECONDARY}
    {...props}
    Icon={SettingsSystemDaydream as any}
  />
)

export const ReceiptIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-receipt" color={ColorType.SECONDARY} {...props} Icon={Receipt as any} />
)

export const PowerInputIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-powerInput" color={ColorType.SECONDARY} {...props} Icon={PowerInput as any} />
)

export const PlaylistAddCheckIcon = (props: AIconProps) => (
  <SVGIcon
    data-dls="atom-icon-playlist-addCheck"
    color={ColorType.SECONDARY}
    {...props}
    Icon={PlaylistAddCheck as any}
  />
)

export const PanToolIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-pan-tool" color={ColorType.SECONDARY} {...props} Icon={PanTool as any} />
)

export const LanguageIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-Language" color={ColorType.SECONDARY} {...props} Icon={Language as any} />
)

export const FileCopyIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-FileCopy" color={ColorType.SECONDARY} {...props} Icon={FileCopy as any} />
)

export const EventIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-Event" color={ColorType.SECONDARY} {...props} Icon={Event as any} />
)

export const DescriptionIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-description" color={ColorType.SECONDARY} {...props} Icon={Description as any} />
)

export const BusinessCenterOutlinedIcon = (props: AIconProps) => (
  <SVGIcon
    data-dls="atom-icon-business-center-outlined"
    color={ColorType.SECONDARY}
    {...props}
    Icon={BusinessCenterOutlined as any}
  />
)

export const AssignmentIndIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-assignmentInd" color={ColorType.SECONDARY} {...props} Icon={AssignmentInd as any} />
)

export const AssignmentIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-assignment" color={ColorType.SECONDARY} {...props} Icon={Assignment as any} />
)

export const CardTravelIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-cardTravel" color={ColorType.SECONDARY} {...props} Icon={CardTravel as any} />
)

export const HelpOutlineIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-helpOutline" color={ColorType.SECONDARY} {...props} Icon={HelpOutline as any} />
)

export const CheckIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-check" color={ColorType.SECONDARY} {...props} Icon={Check as any} />
)

export const AccountBalanceIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-accountBalanace" color={ColorType.SECONDARY} {...props} Icon={AccountBalance as any} />
)

export const SettingsIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-settings" color={ColorType.SECONDARY} {...props} Icon={Settings as any} />
)

export const ErrorOutlineIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-errorOutline" color={ColorType.SECONDARY} {...props} Icon={ErrorOutline as any} />
)

export const ClearIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-clear" color={ColorType.SECONDARY} {...props} Icon={Clear as any} />
)

export const InfoOutlinedIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-infoOutlined" color={ColorType.SECONDARY} {...props} Icon={InfoOutlined as any} />
)

export const GetAppIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-getApp" color={ColorType.SECONDARY} {...props} Icon={GetApp as any} />
)

export const AddIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-add" color={ColorType.SECONDARY} {...props} Icon={Add as any} />
)

export const AddCircleIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-add-circle" color={ColorType.SECONDARY} {...props} Icon={AddCircle as any} />
)

export const CodeIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-code" color={ColorType.SECONDARY} {...props} Icon={Code as any} />
)

export const ExpandMoreIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-expandMore" color={ColorType.SECONDARY} {...props} Icon={ExpandMore as any} />
)

export const ExpandLessIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-expandLess" color={ColorType.SECONDARY} {...props} Icon={ExpandLess as any} />
)

export const LaunchIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-launch" color={ColorType.SECONDARY} {...props} Icon={Launch as any} />
)

export const HistoryIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-history" color={ColorType.SECONDARY} {...props} Icon={History as any} />
)

export const CachedIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-cached" color={ColorType.SECONDARY} {...props} Icon={Cached as any} />
)

export const DeleteIcon = (props: AIconProps) => (
  <SVGIcon data-dls="atom-icon-delete" color={ColorType.SECONDARY} {...props} Icon={Delete as any} />
)
