import { ProductKey } from 'store/products/types'
import { Colleague } from 'store/user/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'

export type TaskKey = string
export type AssigneeKey = string
export type ProcessExecutionKey = string

export interface RejectTask {
  taskKey: TaskKey
  message: string
  productKey: ProductKey
  processExecutionKey?: string
}

export interface RejectTaskPayload {
  message: string
}

export interface ApproveTask {
  taskKey: TaskKey
  productKey: ProductKey
  message?: string
  processExecutionKey?: string
}

export interface ApproveTaskPayload {
  message?: string
}

export interface GetTasksPayload {
  assigneeKey?: AssigneeKey
  processExecutionKey?: ProcessExecutionKey
}

export enum AssignTasksResultType {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum TasksResultType {
  APPROVE_SUCCESS = 'APPROVE_SUCCESS',
  REJECT_SUCCESS = 'REJECT_SUCCESS',
  FAIL = 'FAIL',
}

export interface TasksState {
  isLoading: boolean
  isAssigning: boolean
  updating: boolean
  error?: Error
  taksForProduct: Task[]
  taksForAssignee: Task[]
  selectedTask?: Task
  assignee?: Colleague
  taskDefinitions: TaskDefinition[]
  assignTasksResult: AssignTasksResultType | null
  taskActionResult: TasksResultType | null
}

export enum FieldType {
  button,
  checkbox,
  color,
  date,
  datetime,
  'datetime-local',
  email,
  file,
  hidden,
  image,
  month,
  number,
  password,
  radio,
  range,
  reset,
  search,
  submit,
  tel,
  text,
  time,
  url,
  week,
}

export enum Method {
  GET,
  PUT,
  POST,
  DELETE,
  PATCH,
}

export enum DateFilter {
  NEWEST = 'Newest first',
  OLDEST = 'Oldest first',
}

export enum AssigneeType {
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum AvailableActions {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export enum TaskType {
  PREDEFINED = 'PREDEFINED',
  FEATURE = 'FEATURE',
}

export interface Task {
  key: string
  sequence: number
  name: string
  description: string
  status: TaskStatus
  processExecutionKey: string
  assigneeKey: string
  assigneeType: AssigneeKey
  createdAt: string
  createdBy?: string
  context: string | null
  type: TaskType
  currentProcessSequence: number
  availableActions?: AvailableActions[]
  entityType: string
  entityKey: string
}

export interface TaskDefinition {
  sequence: number
  name: string
  description: string
  key: string
  defaultAssigneeKey?: string
}

export interface TaskDefinitionValue {
  sequence: number
  name: string
  description: string
  defaultAssigneeKey?: string
  key?: string
}

export interface TaskDefinitionFormValue {
  taskDefinitions: TaskDefinitionValue[]
}

export interface ProcessExecution {
  status: string
  entityType: string
  tenantKey: string
  entityLifecycle: string
  entityKey: string
  processDefinitionKey: string
  featureTasksGenerationStrategy: string
  createdAt: string
  name: string
  key: string
}

export interface AssignTaskPayload {
  assigneeKey: string
  assigneeType: AssigneeType
  assigneeName: string
  task: Task
  processExecutionKey?: string
}
