import React from 'react'
import MUISelect, { SelectProps as MUISelectProps } from '@material-ui/core/Select'
import styled from 'styled-components/macro'
import GeneralFieldWrapper, { GeneralFieldWrapperProps } from '../GeneralFieldWrapper'
import { FieldLabelCustomProps } from 'dls/atoms/FieldLabel'
import { Option } from 'dls/atoms/Option'
import { FormHelperTextCustomProps } from 'dls/atoms/FormHelperText'

export const StyledSelect = styled(MUISelect)<{ hasPlaceholder?: boolean }>`
  min-width: 100px;
  .MuiSelect-select.MuiSelect-select {
    color: ${({ hasPlaceholder, theme }) =>
      hasPlaceholder ? theme.palette.text.disabled : theme.palette.text.primary};
  & .MuiInput-input {
    color: ${(props: any) => props.theme.palette.text.secondary};
    & .MuiBox-root {
      color: ${(props: any) => props.theme.palette.text.primary};
    }
  }
  &.Mui-error {
    color: ${(props: any) => props.theme.palette.error.main};
    & .MuiSelect-icon {
      color: ${(props: any) => props.theme.palette.error.main};
    }
  }

  &.Mui-focused {
    color: ${(props: any) => props.theme.palette.secondary.main};
    & .MuiFormLabel-asterisk {
      color: ${(props: any) => props.theme.palette.secondary.main};
    }
    &.Mui-error {
      color: ${(props: any) => props.theme.palette.secondary.main};
      & .MuiSelect-icon {
        color: ${(props: any) => props.theme.palette.secondary.main};
      }
    }
  }

  &.MuiInput-underline {
    &:after {
      border-color: ${(props: any) => props.theme.palette.secondary.main};
      & .MuiSelect-icon {
        color: ${(props: any) => props.theme.palette.secondary.main};
      }
    }
    &.Mui-error {
      &:after {
        border-color: ${(props: any) => props.theme.palette.error.main};
      }
    }
    &.Mui-disabled:before {
      border-bottom-style: solid;
    }
  }
  
  &.Mui-disabled {
    color: ${(props: any) => props.theme.palette.text.disabled};
    background-color: ${(props: any) => props.theme.palette.primary.greyLight};
    padding-left: 4px;
  }
`

export const StyledOption = styled.option`
  padding: 8px;
`

export interface FormErrorCustomProps {
  errorText?: string
  ariaProps?: Record<string, string | boolean>
}

export interface MTextFieldProps extends MUISelectProps {
  label?: React.ReactNode
  InputLabelProps?: FieldLabelCustomProps
  FormHelperTextProps?: FormHelperTextCustomProps
  FormErrorTextProps?: FormErrorCustomProps
  name: string
  value?: string | number
  required?: boolean
  disabled?: boolean
  error?: boolean
  placeholder?: string
  helperText?: React.ReactNode
  errorText?: React.ReactNode
}

export type TextFieldComplexProps = MTextFieldProps & GeneralFieldWrapperProps

export const Select = ({
  label,
  InputLabelProps,
  FormHelperTextProps,
  FormErrorTextProps,
  name,
  helperText,
  errorText,
  required,
  disabled,
  error,
  value = '',
  children,
  placeholder,
  fullWidth,
  ...mSelectFieldProps
}: TextFieldComplexProps) => (
  <GeneralFieldWrapper
    label={label}
    FormHelperTextProps={FormHelperTextProps}
    FormErrorTextProps={FormErrorTextProps}
    helperText={helperText}
    errorText={errorText}
    InputLabelProps={{ ...InputLabelProps, shrink: true }}
    required={required}
    disabled={disabled}
    error={error}
    name={name}
    fullWidth={fullWidth}
  >
    <StyledSelect
      data-dls="molecules-select"
      name={name}
      required={required}
      disabled={disabled}
      error={error}
      value={value}
      aria-describedby={name}
      hasPlaceholder={!value}
      {...mSelectFieldProps}
      id={name}
    >
      {placeholder && (
        <Option value="" disabled>
          {placeholder}
        </Option>
      )}
      {children}
    </StyledSelect>
  </GeneralFieldWrapper>
)

export default Select
