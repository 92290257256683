import { FastField } from 'formik'
import { Grid, Typography, Paper } from '@material-ui/core'
import styled from 'styled-components/macro'

export const ContentGridContainer = styled(Grid)`
  padding: 24px;
`

export const ContentPaperContainer = styled(Paper)`
  margin: 24px 0 25px;
`
export const ContentFormRow = styled(Grid)`
  margin-bottom: 24px;
`
export const InputType = styled.div`
  padding-left: 12px;
  padding-top: 8px;
`

export const FooterAdditionalSpaceGrid = styled(Grid)`
  margin: 15px 0 10px;
`

export const TypographyStyle = styled(Typography)`
  margin: 0 0 24px;
  padding: 0;
`

export const HeadingTypography = styled(Typography)`
  margin: 0 0 24px;
  padding: 0;
`

export const FastFieldStyle = styled(FastField)`
  margin: 0 0 24px;
  padding: 0;
`

export const AdditionSpacing = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`
