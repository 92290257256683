import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { find, isEmpty, orderBy } from 'lodash'
import { connect, useDispatch, useSelector } from 'react-redux'
import ViewPayloadDialog from './components/ViewPayloadDialog/ViewPayloadDialog'
import CreateFeatureModal from './CreateFeatureModal'
import { mapStateToProductProps } from './ProductFeatureViewMode/ProductFeatureViewMode'
import { ExtendedProduct, StatementType, UpdateProductFormValues } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import EditProduct from 'pages/EditProduct/EditProduct'
import { BankInterestRate } from 'store/referenceData/types'
import ProductsActions from 'store/products/actions'
import useDialogs from 'components/useDialogs'
import initialState from 'store/products/reducers/initialState'
import ProductsSelectors from 'store/products/selectors'
import { isProductInDesign } from 'utils/productVersion.utils'
import { EntityToolbar } from 'dls/'
import { ProductApprovals } from 'components'
import useStatementConfiguration from 'pages/Templates/StatementConfigurationCatalog/utils/useStatementConfiguration'
import StatementConfigurationSelectors from 'store/templates/statementConfiguration/selectors'

interface OwnProps {
  titleId?: string
  product: ExtendedProduct
  isOldVersion?: boolean
  open: boolean
  editProduct: (payload: UpdateProductFormValues) => void
  onClickCancel: () => void
  currencyOfProduct?: string | null
  selectSelectedTenantIndex?: number
  bankIndexRates?: BankInterestRate[]
  availableFeatures: string[]
  expandedFeatures: string[]
  setExpandedFeatures: React.Dispatch<React.SetStateAction<string[]>>
}

const mapDispatchToProps = {
  deleteProduct: ProductsActions.deleteProduct,
  retireProduct: ProductsActions.retireProduct,
}
type Props = ReturnType<typeof mapStateToProductProps> & typeof mapDispatchToProps & OwnProps

const ProductListHeader = (props: Props): React.ReactElement => {
  const {
    titleId,
    product,
    open,
    onClickCancel,
    editProduct,
    tasks,
    assignees,
    currencyOfProduct,
    tenantConfig,
    selectSelectedTenantIndex,
    bankIndexRates,
    isOldVersion,
    taxRules,
    availableFeatures,
    expandedFeatures,
    setExpandedFeatures,
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [openFeatureDialog, setFeatureDialogOpen] = useState(false)
  const { fetchStatementConfigurations } = useStatementConfiguration()

  const interestStatementConfigs = useSelector(StatementConfigurationSelectors.selectInterestStatementConfigs)

  const selectAllProductsOrigin = useSelector(ProductsSelectors.selectAllProductsOrigin)
  const selectDataLoading = useSelector(ProductsSelectors.selectDataLoading)
  const selectError = useSelector(ProductsSelectors.selectError)

  const productSyndicateResponse = useSelector(ProductsSelectors.selectProductSyndication)
  const productSyndicationError = useSelector(ProductsSelectors.selectProductSyndicationError)
  const productSyndicationLoading = useSelector(ProductsSelectors.selectProductSyndicationLoading)

  useEffect(() => {
    if (
      product &&
      product.eligibility &&
      product.eligibility.productDependencyEligibility &&
      product.eligibility.productDependencyEligibility.dependency &&
      product.eligibility.productDependencyEligibility.dependency.productKey &&
      selectAllProductsOrigin === initialState.data &&
      !selectDataLoading &&
      !selectError
    ) {
      dispatch(ProductsActions.fetch())
    }
  }, [selectDataLoading, selectAllProductsOrigin, dispatch, selectError, product])

  const getInterestStatementConfig = () => {
    const hasInterestStatement = product?.statements?.some(
      (statement) => statement.statementType === StatementType.INTEREST_STATEMENT
    )

    if (hasInterestStatement) {
      fetchStatementConfigurations()
    }
  }

  const tasksData = orderBy(
    tasks.map((task) => ({ assignee: find(assignees, { userKey: task.assigneeKey }), task })).filter((task) => task),
    ['task.sequence', 'task.context'],
    ['asc', 'asc']
  )

  let assigned = false

  if (!isEmpty(tasksData)) {
    assigned = true
    tasksData.forEach(({ task }) => {
      if (!task.assigneeKey) {
        assigned = false
      }
    })
  }

  const createPDF = async () => {
    const utils = await import('./Pdf/print.utils')
    if (!interestStatementConfigs) {
      getInterestStatementConfig()
    }

    const interestStatementConfigId = product?.statements?.find(
      (statement) => statement.statementType === StatementType.INTEREST_STATEMENT
    )?.statementConfigKey

    const interestStatementConfig = interestStatementConfigs?.find(
      (configs) => configs.statementConfigKey === interestStatementConfigId
    )

    const productSyndication = {
      productSyndicateResponse,
      productSyndicationError,
      productSyndicationLoading,
    }

    utils.generateProductPrintPage(
      product,
      productSyndication,
      tenantConfig,
      t,
      currencyOfProduct,
      selectSelectedTenantIndex,
      taxRules,
      bankIndexRates,
      selectAllProductsOrigin,
      interestStatementConfig
    )
  }

  const handleFeatureDialog = (openDialog: boolean) => {
    setFeatureDialogOpen(openDialog)
  }

  const inDesign = !isOldVersion && !!product.status && isProductInDesign(product.status)

  const { isDialogOpen, onOpenDialog, onCloseDialog } = useDialogs()
  const title =
    tenantConfig && tenantConfig.features.governance && assigned && [ProductStatus.REVIEW].includes(product.status)
      ? t('Product features to be reviewed')
      : t('Product features')
  return (
    <div style={{ marginTop: -6, marginBottom: -6 }}>
      {tenantConfig && tenantConfig.features.governance && [ProductStatus.REVIEW].includes(product.status) && (
        <ProductApprovals product={product} />
      )}
      <EntityToolbar
        id={titleId}
        title={title}
        codeButtonProps={{
          ariaProps: {
            'aria-label': 'View JSON Payload',
            'aria-haspopup': 'dialog',
            'aria-controls': 'menu-appbar',
          },
          title: 'View JSON Payload',
          onClick: onOpenDialog,
        }}
        downloadButtonProps={{
          ariaProps: {
            'aria-label': 'Download as PDF',
            'data-test-id': 'product-download-link',
          },
          disabled: tenantConfig?.features?.productSyndication && productSyndicationLoading,
          title: 'Download as PDF',
          onClick: createPDF,
        }}
        expandCollapseButtonProps={{
          ariaProps: {
            'aria-label': 'View JSON Payload',
            'aria-controls': 'feature-list',
          },
          expandCopy: 'EXPAND ALL',
          collapseCopy: 'COLLAPSE ALL',
          open: expandedFeatures.length === availableFeatures?.length,
          onClick: () =>
            expandedFeatures.length === availableFeatures?.length
              ? setExpandedFeatures([])
              : setExpandedFeatures(availableFeatures),
        }}
        addFeatureButtonProps={
          inDesign
            ? {
                ariaProps: {
                  'aria-controls': 'form-bottom-bar-menu',
                  'aria-haspopup': 'true',
                },
                copy: 'ADD FEATURE',
                onClick: () => handleFeatureDialog(true),
              }
            : {}
        }
      />
      <ViewPayloadDialog open={isDialogOpen} onCancelClick={onCloseDialog} payload={product} />
      {inDesign && (
        <span>
          <CreateFeatureModal
            product={product}
            openFeatureDialog={openFeatureDialog}
            onClose={() => handleFeatureDialog(false)}
          />
        </span>
      )}
      {!isOldVersion && (
        <EditProduct open={open} product={product} onPressCancel={onClickCancel} onSubmit={editProduct} />
      )}
    </div>
  )
}

export default connect(mapStateToProductProps, mapDispatchToProps)(ProductListHeader)
