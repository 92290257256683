import { Grid } from '@material-ui/core'
import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'

const GridContainer = styled(Grid)<{ $rightOnly: boolean }>`
  align-items: flex-end;
  flex-direction: column;
  ${({ theme, $rightOnly }) => `
    ${theme.breakpoints.up('sm')} {
      justify-content: ${$rightOnly ? 'flex-end' : 'space-between'};
      flex-direction: row;
    }
  `}
`

const RightGrid = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      order: 2;
    }
  `}
`

const LeftGrid = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      order: 1;
    }
  `}
`

type Props =
  | { leftRegion: ReactElement[]; rightRegion?: ReactElement[] }
  | { leftRegion?: ReactElement[]; rightRegion: ReactElement[] }

const CallToActionBar = ({ leftRegion, rightRegion }: Props) => {
  const rightOnly = !leftRegion?.length

  return (
    <GridContainer data-dls="organism-call-to-action-bar" container spacing={2} $rightOnly={rightOnly}>
      {Boolean(rightRegion?.length) && (
        <RightGrid item>
          <Grid container spacing={2} justifyContent="flex-end">
            {rightRegion?.map((element, i) => (
              <Grid key={`grid-${i}`} item>
                {element}
              </Grid>
            ))}
          </Grid>
        </RightGrid>
      )}
      {Boolean(leftRegion?.length) && (
        <LeftGrid item>
          <Grid container spacing={2}>
            {leftRegion?.map((element, i) => (
              <Grid key={`grid-${i}`} item>
                {element}
              </Grid>
            ))}
          </Grid>
        </LeftGrid>
      )}
    </GridContainer>
  )
}

export default CallToActionBar
