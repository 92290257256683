import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { isEqual } from 'lodash'
import * as Sentry from '@sentry/react'
import TasksActions from './actions'
import { Task } from './types'
import { navigate } from 'store/router/actions'
import { TasksApi, UserApi } from 'api'
import NotificationActions from 'store/notifications/actions'
import ProductsActions from 'store/products/actions'
import { selectUserInformation } from 'store/user/selectors'
import { UserInformation } from 'store/user/types'
import { UI_DELAY_TO_SHOW_LOADING, NUMBEROFTRIES } from 'store/utils/constants'
import ModalActions from 'store/modal/actions'
import { navigateToUnavailable } from 'utils/url.utils'

function* showInProgress() {
  yield put(ModalActions.showFeatureProgressModal())
}

function* showSuccess(successResponse: any) {
  yield delay(UI_DELAY_TO_SHOW_LOADING)
  yield put(ModalActions.closeModal())
  if (successResponse) yield put(ModalActions.showFeatureSuccessModal(successResponse))
}

function* showError(e: Error) {
  yield put(ModalActions.closeModal())
  yield put(ModalActions.showFeatureFailureModal(e))
}

function* rejectTask(action: ReturnType<typeof TasksActions.reject>) {
  const {
    payload: { taskKey, productKey, processExecutionKey, ...data },
  } = action
  try {
    let isDone = false
    const tasksSnapshot: ReturnTypePromise<typeof TasksApi.tasksExecutionsForProduct> = yield call(
      TasksApi.tasksExecutionsForProduct,
      processExecutionKey
    )
    yield call(TasksApi.rejectTask, taskKey, data)
    for (let i = 0; i < NUMBEROFTRIES; i += 1) {
      yield delay(UI_DELAY_TO_SHOW_LOADING)
      const actualTasks: Task[] = yield call(TasksApi.tasksExecutionsForProduct, processExecutionKey)
      if (!isEqual(tasksSnapshot, actualTasks)) {
        isDone = true
        yield put(TasksActions.tasksExecutionsForProductSuccess(actualTasks))
        break
      }
    }
    if (!isDone) {
      const errorText = 'Reject task failed'
      Sentry.captureException(new Error(errorText))
      throw new Error(errorText)
    }
    yield put(navigate(`/products/${productKey}` as any))
    yield put(TasksActions.rejectSuccess())
    yield put(ProductsActions.getProductDetails({ productKey }))
    yield put(ProductsActions.getAllGovernenceDataForProduct({ productKey }))

    const userSelector: ReturnType<typeof selectUserInformation> = yield select(selectUserInformation)
    if (userSelector) {
      const user = userSelector as UserInformation
      yield put(TasksActions.tasksExecutionsForAssignee(user.userKey))
    }
  } catch (e: any) {
    yield put(TasksActions.rejectFail(e))
  }
}

function* approveTask(action: ReturnType<typeof TasksActions.approve>) {
  const {
    payload: { taskKey, productKey, processExecutionKey, ...data },
  } = action
  try {
    let isDone = false
    const tasksSnapshot: ReturnTypePromise<typeof TasksApi.tasksExecutionsForProduct> = yield call(
      TasksApi.tasksExecutionsForProduct,
      processExecutionKey
    )
    yield call(TasksApi.approveTask, taskKey, data)
    for (let i = 0; i < NUMBEROFTRIES; i += 1) {
      yield delay(UI_DELAY_TO_SHOW_LOADING)
      const actualTasks: Task[] = yield call(TasksApi.tasksExecutionsForProduct, processExecutionKey)
      if (!isEqual(tasksSnapshot, actualTasks)) {
        isDone = true
        yield put(TasksActions.tasksExecutionsForProductSuccess(actualTasks))
        break
      }
    }
    if (!isDone) {
      Sentry.captureException(new Error('Reject task failed'))
      throw new Error('Approve task failed')
    }
    yield put(navigate(`/products/${productKey}` as any))
    yield put(TasksActions.approveSuccess())
    yield put(ProductsActions.getProductDetails({ productKey }))
    const userSelector: ReturnType<typeof selectUserInformation> = yield select(selectUserInformation)
    if (userSelector) {
      const user = userSelector as UserInformation
      yield put(TasksActions.tasksExecutionsForAssignee(user.userKey))
    }
  } catch (e: any) {
    yield put(TasksActions.approveFail(e))
  }
}

function* assignTask(action: ReturnType<typeof TasksActions.assignTask>) {
  const { assigneeName, task, processExecutionKey } = action.payload
  try {
    yield call(TasksApi.assignTask, action.payload, task.key)
    yield put(NotificationActions.assignTaskSuccess({ taskName: task.name, assigneeName }))
  } catch (e: any) {
    yield put(TasksActions.assignTaskFail(e))
  }
  try {
    const userSelector: ReturnType<typeof selectUserInformation> = yield select(selectUserInformation)
    if (userSelector) {
      const user = userSelector as UserInformation
      yield put(TasksActions.tasksExecutionsForAssignee(user.userKey))
    }
    if (processExecutionKey) {
      yield put(TasksActions.tasksExecutionsForProduct(processExecutionKey))
    }
  } catch (e: any) {
    yield put(TasksActions.tasksExecutionsForAssigneeFail(e))
  }
}

function* assignTasks(action: ReturnType<typeof TasksActions.assignTasks>) {
  if (action.payload) {
    try {
      let isDone = false
      const { processExecutionKey } = action.payload[0]

      let tasksSnapshot: ReturnTypePromise<typeof TasksApi.tasksExecutionsForProduct> = []
      if (processExecutionKey) {
        tasksSnapshot = yield call(TasksApi.tasksExecutionsForProduct, processExecutionKey)
      }
      const parallelTasks: any = []
      action.payload.forEach((item) => {
        parallelTasks.push(call(TasksApi.assignTask, item, item.task.key))
      })
      yield all(parallelTasks)
      if (processExecutionKey) {
        for (let i = 0; i < NUMBEROFTRIES; i += 1) {
          yield delay(UI_DELAY_TO_SHOW_LOADING)
          const actualTasks: Task[] = yield call(TasksApi.tasksExecutionsForProduct, processExecutionKey)
          if (!isEqual(tasksSnapshot, actualTasks)) {
            isDone = true
            yield put(TasksActions.assignTasksSuccesss())
            yield put(TasksActions.tasksExecutionsForProductSuccess(actualTasks))
            const userSelector: ReturnType<typeof selectUserInformation> = yield select(selectUserInformation)
            if (userSelector) {
              const user = userSelector as UserInformation
              yield put(TasksActions.tasksExecutionsForAssignee(user.userKey))
            }
            break
          }
        }
      }
      if (!isDone) {
        Sentry.captureException(new Error('Assign Tasks attempt maximum number'))
        throw new Error('Assign Tasks attempt maximum number')
      }
    } catch (e: any) {
      yield put(TasksActions.assignTasksFails(e))
    }
  }
}

function* tasksExecutionsForAssignee(action: ReturnType<typeof TasksActions.tasksExecutionsForAssignee>) {
  try {
    const response: ReturnTypePromise<typeof TasksApi.tasksExecutionsForAssignee> = yield call(
      TasksApi.tasksExecutionsForAssignee,
      action.payload
    )
    yield put(TasksActions.tasksExecutionsForAssigneeSuccess(response))
  } catch (e: any) {
    yield put(TasksActions.tasksExecutionsForAssigneeFail(e))
  }
}

function* tasksExecutionsForProduct(action: ReturnType<typeof TasksActions.tasksExecutionsForProduct>) {
  try {
    const response: ReturnTypePromise<typeof TasksApi.tasksExecutionsForProduct> = yield call(
      TasksApi.tasksExecutionsForProduct,
      action.payload
    )
    yield put(TasksActions.tasksExecutionsForProductSuccess(response))
  } catch (e: any) {
    yield put(TasksActions.tasksExecutionsForProductFail(e))
  }
}

function* getAssigneeForProductTask(action: ReturnType<typeof TasksActions.getAssigneeForProductTasks>) {
  try {
    const response: ReturnTypePromise<typeof UserApi.getUserByKey> = yield call(UserApi.getUserByKey, action.payload)
    yield put(TasksActions.getAssigneeForProductTaskSuccess(response))
  } catch (e: any) {
    yield put(TasksActions.getAssigneeForProductTasks(e))
  }
}

function* getTaskDefinitions(action: ReturnType<typeof TasksActions.getTasksDefinition>) {
  try {
    yield call(TasksApi.getTaskDefinitions, action.payload)
    const response: ReturnTypePromise<typeof TasksApi.getTaskDefinitions> = yield call(
      TasksApi.getTaskDefinitions,
      action.payload
    )
    yield put(TasksActions.getTasksDefinitionSuccess(response))
  } catch (e: any) {
    yield put(TasksActions.getTasksDefinitionFail(e))
    yield call(navigateToUnavailable)
  }
}

function* createTaskDefinitions(action: ReturnType<typeof TasksActions.createTasksDefinition>) {
  try {
    yield showInProgress()
    const {
      payload: { processKey, ...data },
    } = action
    const response: ReturnTypePromise<typeof TasksApi.createTaskDefinitions> = yield call(
      TasksApi.createTaskDefinitions,
      processKey,
      data
    )
    yield put(navigate(`/governance` as any))
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield showSuccess({ ...response, featureName: 'Task' })
  } catch (e: any) {
    yield showError(e)
    yield put(TasksActions.createTasksDefinitionFail(e))
  }
}

function* updateTaskDefinitions(action: ReturnType<typeof TasksActions.updateTasksDefinition>) {
  try {
    yield showInProgress()

    const {
      payload: { processKey, ...data },
    } = action
    const response: ReturnTypePromise<typeof TasksApi.updateTaskDefinitions> = yield call(
      TasksApi.updateTaskDefinitions,
      processKey,
      data
    )
    yield put(navigate(`/governance` as any))
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield showSuccess({ ...response, featureName: 'Task' })
  } catch (e: any) {
    yield showError(e)
    yield put(TasksActions.updateTasksDefinitionFail(e))
  }
}

export default function* () {
  yield takeLatest(TasksActions.reject.type, rejectTask)
  yield takeLatest(TasksActions.approve.type, approveTask)
  yield takeLatest(TasksActions.assignTask.type, assignTask)
  yield takeLatest(TasksActions.assignTasks.type, assignTasks)
  yield takeLatest(TasksActions.tasksExecutionsForAssignee.type, tasksExecutionsForAssignee)
  yield takeLatest(TasksActions.tasksExecutionsForProduct.type, tasksExecutionsForProduct)
  yield takeLatest(TasksActions.getAssigneeForProductTasks.type, getAssigneeForProductTask)
  yield takeLatest(TasksActions.getTasksDefinition.type, getTaskDefinitions)
  yield takeLatest(TasksActions.createTasksDefinition.type, createTaskDefinitions)
  yield takeLatest(TasksActions.updateTasksDefinition.type, updateTaskDefinitions)
}
