import { orderBy } from 'lodash'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import config from 'config'
import { ActivityPayload, Message } from 'store/activities/types'

const createActiviesFetch = async () => createAsyncAuthAwareAxios(config.baseUrl)

async function fetchMessages(activityPayload: ActivityPayload): Promise<Message[]> {
  const { data } = await (await createActiviesFetch()).get('/v1/recorder/messages', {
    params: {
      entityKey: activityPayload.productKey,
    },
  })
  return orderBy(data, ['createdDate'], ['desc'])
}

export default { fetchMessages }
