import React from 'react'
import { FieldProps } from 'formik'
import { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch'
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'

export interface SwitchFieldData {
  [key: string]: { name: string | number; label?: string; disabled?: boolean } | string
}

export interface SwitchFieldComplexProps {
  required?: boolean
  switchProps?: Omit<MuiSwitchProps, 'value'>
  opposite?: boolean
}

type Props = FieldProps & SwitchFieldComplexProps & EnhancedFormikFieldProps

const SwitchFieldComplex = (props: Props) => {
  const { switchProps, field, form, inputLabelProps, label, disabled, opposite = false, ...otherProps } = props

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(field.name, (opposite ? !e.target.checked : (e.target.checked as unknown)) as string)
    form.setFieldTouched(field.name, false, false)
  }

  return (
    <EnhancedFormikField {...props} inputLabelProps={inputLabelProps} required={false} label={undefined}>
      {() => (
        <FormControlLabel
          disabled={disabled}
          control={
            <MuiSwitch
              {...otherProps}
              {...switchProps}
              form={undefined}
              checked={opposite ? !field.value : field.value}
              onChange={onChange}
            />
          }
          label={label}
          name={field.name}
        />
      )}
    </EnhancedFormikField>
  )
}
export default SwitchFieldComplex
