import { cloneDeep, flatten, values, isArray } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment-timezone'
import { getTaxonomy, prepareFormData, prepareLimitsFormData } from './utils'
import {
  CardsFormValues,
  CreditInterest,
  Currency,
  Document,
  DocumentsValues,
  EligibilityFormValues,
  Frequency,
  FrequencyTypeValue,
  TierBand,
  LimitsFormValues,
  MAX_END_RANGE,
  ProductFormValues,
  RequiredExternalIdFormValues,
  StatementFormValues,
  StatementPeriod,
  StatementType,
  SubcriptionCreationPayload,
  CommonFeaturePageValues,
  UpdateProductFormValues,
  Eligibility,
  CreditInterestPayloadValues,
} from 'store/products/types'
import { getFrequencyString } from 'utils/api.utils'
import { StatementLevel } from 'store/templates/statementConfiguration/types'

export const convertTierBand = (data: CreditInterestPayloadValues | null): TierBand[] | undefined => {
  if (data && data.interestTierBand) {
    const tierBands: TierBand[] = cloneDeep(data.interestTierBand)
    if (
      (!tierBands[tierBands.length - 1].endRange ||
        tierBands[tierBands.length - 1].endRange === null ||
        tierBands[tierBands.length - 1].endRange === undefined) &&
      tierBands[tierBands.length - 1].startRange !== null &&
      tierBands[tierBands.length - 1].startRange !== undefined
    ) {
      tierBands[tierBands.length - 1].endRange = MAX_END_RANGE
    }
    return tierBands
  }
  return undefined
}

export const featureCreditInterestToPayload = (data: CreditInterestPayloadValues | null): CreditInterest | null =>
  data
    ? {
        ...data,
        interestTierBand: convertTierBand(data),
        interestRate:
          (data && data.interestTierBand && data.interestTierBand[0].startRange) ||
          !data ||
          (data && !data.interestRate)
            ? 0
            : data.interestRate,
        applicationFrequency:
          getFrequencyString(data.applicationFrequency) ||
          ({ frequencyType: FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT } as Frequency),
        calculationFrequency:
          getFrequencyString(data.calculationFrequency) ||
          ({ frequencyType: FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT } as Frequency),
        compoundingFrequency:
          getFrequencyString(data.applicationFrequency) ||
          ({ frequencyType: FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT } as Frequency),
      }
    : null
// This is to make sure products can be published
// If version isn't supplied, causes a 500 on publish
export const addRequiredDocumentFields = (document?: Document) =>
  document && {
    ...document,
    identifier: document.identifier || document.name || uuidv4(),
    identifierOriginal: document.identifierOriginal,
    version: document.version || '1',
    publishedDate: document.publishedDate || moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
  }

export const featureTermsAndConditionsToPayload = (data: CommonFeaturePageValues) =>
  flatten(values(data)).map(addRequiredDocumentFields)

export const featureDocumentsToPayload = (data: DocumentsValues) => flatten(values(data)).map(addRequiredDocumentFields)

export const subscriptionCreationRuleToPayload = (payload: SubcriptionCreationPayload | null) => {
  if (!payload) return null
  return {
    defaultSubscriptionStatus: payload.defaultSubscriptionStatus.toLowerCase(),
    lockedStatus: payload.lockedStatus,
  }
}

export const featureProductCoreToPayload = (payload: ProductFormValues | UpdateProductFormValues) => {
  const taxonomy = getTaxonomy(payload.productTaxonomy)
  delete payload.productTaxonomy
  return {
    ...payload,
    ...taxonomy,
  }
}

export const featureCardsToPayload = (payload: CardsFormValues) =>
  payload.cards && isArray(payload.cards)
    ? payload.cards.map((item, index) => ({
        ...item,
        defaultCard: index === 0,
        cardId: item.cardId ? item.cardId : uuidv4(),
      }))
    : payload.cards
export const featureCurrencyToPayload = (payload: Currency) => payload
export const featureEligibilityToPayload = (payload: EligibilityFormValues | null) => {
  const result: EligibilityFormValues | Eligibility | null =
    payload && prepareFormData(payload, ['residencyEligibility'])
  if (
    payload &&
    result?.taxResidencyEligibility?.countryIncluded &&
    !isArray(result.taxResidencyEligibility.countryIncluded)
  ) {
    result.taxResidencyEligibility.countryIncluded = [result.taxResidencyEligibility.countryIncluded]
  }
  return result
}

export const featureLimitsToPayload = (payload: LimitsFormValues | null) => payload && prepareLimitsFormData(payload)

export const featureRequiredExternalIdToPayload = (payload: RequiredExternalIdFormValues | null) =>
  payload && payload.requiredExternalId

export const StatementAnniversaryOfAccount = 99999

export const featureStatementToPayload = (payload: StatementFormValues | null) => {
  if (payload?.statementValue) {
    payload.statementValue.forEach((item) => {
      if (item.statementType === StatementType.INTEREST_SUMMARY) {
        item.statementPeriod = StatementPeriod.YEAR
      }
      if (item.startDay === StatementAnniversaryOfAccount) {
        item.accountAnniversary = true
        item.startDay = undefined
      } else if (item.startMonth === StatementAnniversaryOfAccount) {
        item.accountAnniversary = true
        item.startMonth = undefined
        item.startDay = undefined
      } else {
        item.accountAnniversary = undefined
      }
      item.level =
        item.statementType === StatementType.INTEREST_STATEMENT ? StatementLevel.PARTY : StatementLevel.SUBSCRIPTION
    })
    return payload.statementValue
  }
  return null
}
