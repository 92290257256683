import React from 'react'
import { TFunction } from 'i18next'
import { Caption } from 'components'
import { Body2 } from 'dls/atoms/Typography'
import {
  Pending,
  Cleared,
  ContraCleared,
  ContraTransfer,
  PartiallyCleared,
  ContraReversedIcon,
  IndexRatePlusVariableMargin,
  IndependentIndexRate,
  IndexRatePlusStaticMargin,
} from 'assets'
import { HelpModalType, MoreAboutModalType } from 'store/modal/types'

export const infographicMappedWithLocation = (location: MoreAboutModalType) => {
  switch (location) {
    case 'packages':
      return HelpModalType.PACKAGE
    case 'governance':
      return HelpModalType.GOVERNANCE
    case 'indexRate':
      return HelpModalType.INDEX_RATES
    case 'withholdTax':
      return HelpModalType.REFERENCE_DATA
    case 'tax-rate-options':
      return HelpModalType.PRODUCT_LIFECYCLE
    case 'interest-statements':
      return HelpModalType.INTEREST_STATEMENTS
    case 'product-syndication':
      return HelpModalType.PRODUCT_SYNDICATION
    default:
      return null
  }
}

export const moreAboutPackagesInfo = [
  {
    infoStatement: 'Add one or more published products to a package',
  },
  {
    infoStatement: 'Add an attribute to target customer journeys, demographics or segments',
  },
  {
    infoStatement: 'Specify an effective date for when the package will be available to customers',
  },
  {
    infoStatement: 'From the effective date the products will be available in customers’ apps',
  },
  {
    infoStatement:
      'Packages will automatically update to contain the latest version of a product when it becomes effective',
  },
]

export const moreAboutGovernanceInfo = [
  {
    infoStatement: 'Create a governance process and tasks – currently only for product creation and updates',
  },
  {
    infoStatement: 'When you ‘Complete design‘ for a product you’ll be asked to ‘Send for approval‘',
  },
  {
    infoStatement: 'Choose an assignee for each task',
  },
  {
    infoStatement: 'Assignees will receive an in-app notification',
  },
  {
    infoStatement:
      'Assignees will be asked to approve or reject with a reason, if a task is rejected the product will automatically move back to design where you’ll be able to make changes before sending for aproval again. You’ll be notified in-app.',
  },
]

export const moreAboutIndexRates = [
  {
    infoStatement: 'Here you can create global configuration which can be used elsewhere in Bank Manager. ',
  },
  {
    infoStatement:
      'For example, you can create and manage index rates which can be chosen in the configuration of any product’s credit and debit interest features.    ',
  },
  {
    infoStatement: 'This allows you to centrally manage your index rates outside of product configurations.',
  },
]

export const moreAboutReferenceData = [
  {
    infoStatement: 'Here you can create global configuration which can be used elsewhere in Bank Manager.',
  },
  {
    infoStatement:
      'For example, you can create and manage index rates which can be chosen in the configuration of any product’s credit and debit interest features.    ',
  },
  {
    infoStatement: 'This allows you to centrally manage your index rates outside of product configurations.',
  },
]

export const moreAboutWithholdTax = [
  {
    showIndex: true,
    infoTitle: 'Why withhold tax',
    infoStatement:
      'Tax authorities in some jurisdictions require banks to withhold tax from their customers credit interest.',
  },
  {
    showIndex: true,
    infoTitle: 'Create & apply rules',
    infoStatement: (
      <>
        You can create rules for when tax should be withheld from credit interest and the amount that should be
        withheld.
        <br />
        <br />
        You can apply the rules to product’s credit interest feature.
      </>
    ),
  },
  {
    showIndex: true,
    infoTitle: 'Managing your rules & rates',
    infoStatement: (
      <>
        You can add future rates and edit rates which haven’t become effective.
        <br />
        <br />
        Rules can only be deleted if they’re not used by a product and none of their rates are effective.
        <br />
        {/* <br />
        Rates can only be deleted if there’s more than one rate for the rule and if they’re not effective.
        <br /> */}
      </>
    ),
  },
]

export const aboutUserManagement = (t: TFunction) => [
  {
    infoStatement: (
      <>
        {t(
          'Users are created and managed in your organisations Active Directory system. This allows you to manage access and provide your colleagues with quick, easy and secure single sign-on.'
        )}
      </>
    ),
  },
  {
    infoStatement: (
      <>
        {t(
          "In Bank Manager we provide a list of who has access based on the fact they've logged in at least once. We also show you the colleagues details provided by your Active Directory."
        )}
      </>
    ),
  },
  {
    infoStatement: (
      <>
        {t(
          "We use the 'BM_role' assigned to colleagues in your Active Directory to govern the privileges and features they can access in Bank Manager."
        )}
      </>
    ),
  },
]

export const aboutAmortisationMethods = (t: TFunction) => [
  {
    infoTitle: t('Straight line method'),
    infoStatement: (
      <>
        {t(
          'Payments, interest and principal (principal is the amount left to pay on the amount borrowed) are constant for the entire term of the loan.'
        )}
        <br />
        <br />
        <Caption>{t('Example £95k loan over 10 instalments:')}</Caption>
        <br />
        <Caption>{t('Payment £19k per instalment: £10k principal + £9k interest')}</Caption>
      </>
    ),
  },
  {
    infoTitle: t('Declining balance method'),
    infoStatement: (
      <>
        {t(
          'The payment is constant with the interest portion of the payment decreasing over the term and the principal portion increasing over the term.'
        )}
        <br />
        <br />
        <Caption>{t('Example £95k loan over 10 instalments:')}</Caption>
        <br />
        <Caption>{t('Payment £19k per instalment: Increasing principal + decreasing interest')}</Caption>
      </>
    ),
  },
]

export const moreAboutRewards = [
  {
    infoStatement: 'You can create recurring scheduled rewards or rewards based on conditions you can create here.',
  },
  {
    infoStatement: (
      <>
        <Body2 css="font-weight: bold; display: inline;">Rewards without conditions </Body2>
        will be applied based on then frequency of the reward. Example: A monthly or annual customer loyalty reward can
        be created.
      </>
    ),
  },
  {
    infoStatement: (
      <>
        <Body2 css="font-weight: bold; display: inline;">Rewards with conditions </Body2>
        will only be applied when the conditions are met. And they will be applied based on the frequency of the reward.
        Example: A $10 monthly customer loyalty reward can be created and only credited if the end of day balance
        average remains above $3,000.
      </>
    ),
  },
]

export const aboutFees = (t: TFunction) => [
  {
    infoStatement: (
      <>
        {t(
          'You can create basic account fees, by providing a fee name, choosing a schedule frequency of daily, weekly, monthly or annually and an fee amount. Customers will be charged in line with your configuration.'
        )}
      </>
    ),
  },
]

export const aboutDebitInterest = [
  {
    infoTitle: 'What debit interest is and types',
    infoStatement:
      'Debit interest defines the interest a customer must pay when they have a negative balance. You can choose if a customer must apply (be ‘authorised’) or benefit from an ‘unauthorised’ borrowing (not previously agreed). If you choose ‘authorised’, you can additionally set a ‘beyond the customer limit’ if you want to give customers a little extra.',
  },
  {
    infoTitle: 'Debit interest configration',
    infoStatement: (
      <div>
        {`You can configure independent or indexed rates (index rates can be managed in Reference Datas). For a loan,
        Interest can be accrued using a flat rate only. For bank and saving products, flat rate interest can be applied
        or different interest rates can be applied to portions of the borrowing using tiers and bands. `}
        <Body2 css="font-weight: 700; display: inline;">
          The fee and notification must be configured outside of this feature.
        </Body2>
      </div>
    ),
  },
]

export const howSuspenseAccountsWork = [
  {
    infoTitle: '1. Transaction sources',
    infoStatement: 'Unallocated transactions will be placed in suspense accounts.',
  },
  {
    infoTitle: '2. Transaction allocation & clearing',
    infoStatement:
      'Pending and partially cleared transactions must be cleared to another account. Partially cleared transactions can only be cleared to a General Ledger (GL) account.',
  },
  {
    infoTitle: '3. Contra entries',
    infoStatement:
      'Cleared transactions automatically have a contra entry created in the same account. Contra entries for transfers to the General Ledger (GL) account can be reversed if you need to correct a mistake.',
  },
]

export const moreAboutMultiParty = [
  {
    infoStatement:
      'This feature enables you to allow customers to add parties to their subscription. This allows customers to make their accounts joint or shared with a group of friends.',
  },
  {
    infoStatement:
      'The account ‘Owner’ is the customer who first subscribes to the product. Once subscribed, they can invite other parties within the parameters you set here.',
  },
  {
    infoStatement:
      'You can set a maximum number of parties and use the predefined roles to choose what permissions they can have and which roles they can invite.',
  },
]
export const productVersion = [
  {
    infoTitle: '1. Statuses',
    infoStatement:
      'Product statuses reflect the steps involved in creating a product from initial design, through governance and finally to scheduling a product with a live effective date. ',
  },
  {
    infoTitle: '2. Cycle and versions',
    infoStatement:
      'Once a product is scheduled or live a new incremental version can be created e.g. v2. Each version number remains constant throughout the cycle from Design to Live. ',
  },
  {
    infoTitle: '3. Subscription migrations',
    infoStatement:
      'When a new version becomes effective, subscriptions are either automatically or manually migrated onto the new version based on your preference.',
  },
]

export const typeOfInterestRatesMarkup = [
  {
    infoTitle: 'Independent of index rate',
    infoStatement: 'The customer always receives a static rate of interest, the bank margin is variable',
    infoImage: {
      alt: 'IndependentIndexRate',
      src: IndependentIndexRate,
    },
  },
  {
    infoTitle: 'Index rate with a static margin',
    infoStatement: 'The customer receives variable interest and the bank has a static margin over the index rate',
    infoImage: {
      alt: 'IndexRatePlusStaticMargin',
      src: IndexRatePlusStaticMargin,
    },
  },
  {
    infoTitle: 'Index rate with a variable margin',
    infoStatement: 'The customer receives static interest and the bank has a variable margin over the index rate',
    infoImage: {
      alt: 'IndexRatePlusVariableMargin',
      src: IndexRatePlusVariableMargin,
    },
  },
]

export const typeOfInterestRatesOption = [
  {
    infoTitle: 'Flat rate',
    infoStatement: 'A single interest rate is applied regardless of the balance',
  },
  {
    infoTitle: 'Tiers',
    infoStatement: 'The interest rate is applied using the rate of the tier which the balance falls into',
  },
  {
    infoTitle: 'Bands',
    infoStatement: 'The interest rate is applied to the balance for each band separately',
  },
]

export const transactionStatusMarkup = [
  {
    infoTitle: {
      image: {
        alt: 'Clear',
        src: Cleared,
        'aria-hidden': true,
      },
      copy: 'Cleared',
    },
    infoStatement: 'These transactions have been cleared out of the suspense account to a General Ledger account',
  },
  {
    infoTitle: {
      image: {
        alt: 'Contra cleared',
        src: ContraCleared,
        'aria-hidden': true,
      },
      copy: 'Contra cleared',
    },
    infoStatement:
      'These transactions have cleared out other suspense transactions via a direct credit/debit or a topic (these actions cannot be performed in Bank Manager)',
  },
  {
    infoTitle: {
      image: {
        alt: 'Contra transfer',
        src: ContraTransfer,
        'aria-hidden': true,
      },
      copy: 'Contra transfer',
    },
    infoStatement:
      'These transactions have cleared out other suspense transactions by moving them to another suspense account',
  },
  {
    infoTitle: {
      image: {
        alt: 'Contra write back',
        src: ContraTransfer,
        'aria-hidden': true,
      },
      copy: 'Contra write-back',
    },
    infoStatement: 'These transactions have cleared out other credit suspense transactions to a General Ledger account',
  },
  {
    infoTitle: {
      image: {
        alt: 'Contra write-back reversed',
        src: ContraReversedIcon,
        'aria-hidden': true,
      },
      copy: 'Contra write-back reversed',
    },
    infoStatement: 'These are reversed contra write-back transactions',
  },
  {
    infoTitle: {
      image: {
        alt: 'Contra write-off',
        src: ContraCleared,
        'aria-hidden': true,
      },
      copy: 'Contra write-off',
    },
    infoStatement: 'These transactions have cleared out other debit suspense transactions to a General Ledger account',
  },
  {
    infoTitle: {
      image: {
        alt: 'Contra write-off reversed',
        src: ContraReversedIcon,
        'aria-hidden': true,
      },
      copy: 'Contra write-off reversed',
    },
    infoStatement: 'These are reversed contra write-off transactions',
  },
  {
    infoTitle: {
      image: {
        alt: 'Partially cleared',
        src: PartiallyCleared,
        'aria-hidden': true,
      },
      copy: 'Partially cleared',
    },
    infoStatement:
      'These transactions have only been partially cleared by another suspense transaction entry and must be fully cleared',
  },
  {
    infoTitle: {
      image: {
        alt: 'Pending',
        src: Pending,
        'aria-hidden': true,
      },
      copy: 'Pending',
    },
    infoStatement: 'These transactions are outstanding in the suspense account and must be cleare',
  },
]

export const transactionRules = [
  {
    infoTitle: 'Default setting',
    infoStatement:
      'The default settings for inbound and outbound transactions should reflect the main defaults for the product.',
  },
  {
    infoTitle: 'Adding exceptions',
    infoStatement:
      'Exception rules override the default setting. To create an exception rule you’ll be able to choose the conditions under which the rule should be applied.',
  },
  {
    infoTitle: 'Allow or block exceptions',
    infoStatement:
      'For each exception rule you’ll be able to choose whether you want to allow or block transactions matching the rule.',
  },
]

export const moreAboutProducts = [
  {
    infoStatement:
      'Create a product in seconds! Choose options from a few fields and we’ll give you a basic product configuration.',
  },
  {
    infoStatement:
      'Add more features to suit your needs. Submit the product to colleagues for approval using the built-in governance process and product lifecycle. Colleagues can approve or reject a product with feedback.',
  },
  {
    infoStatement:
      'Once approved, you can set an effective date for your product and publish it. Published products can be added to Packages to expose to customers in specific journeys/channels.',
  },
]

export const moreAmountPaymentSchedule = [
  {
    infoStatement: 'This feature allows you to configure the amount a customer can borrow and the term of the lending.',
  },
  {
    infoStatement:
      'You’ll also need to choose how the interest and repayments are applied – known as the amortisation method. And you can choose  how rounding should be applied.',
  },
  {
    infoStatement: 'Finally, you’ll be able to choose the repayment frequencies that you want to offer to customers.',
  },
]

export const moreAboutPaymentLimits = [
  {
    infoStatement:
      'Here you can set payment limits for inbound and outbound transactions. Limits can be for single transactions or be based on cumulative volume or value over days, months, or a year. You can define the year allowing you to configure tax years or the year of account anniversary.',
  },
  {
    infoStatement:
      'This feature can be useful as a fraud control, as a spending control or as a way of limiting how customers can transact on a product. You can also allow users to change certain limits themselves within the boundaries you set here.',
  },
  {
    infoStatement:
      'Once active, the limits will be assessed each time a transaction is made to ensure they are adhered to in your customer channels.',
  },
]

export const moreAboutCardLimits = [
  {
    infoStatement:
      'Here you can set transaction limits for your cards. The limits you set here will be applied to all cards configured for any given product.',
  },
  {
    infoStatement:
      'Limits can be set for cash ATM/POS and non-cash transactions. For each type, you can set limits per transaction or limit the cumulative total per day. You can also allow your customers to set their own limit within the boundaries you define here.',
  },
  {
    infoStatement:
      'Every time a card transaction is made it will be assessed agasint the limits to ensure they are adhered to.',
  },
]

export const moreAboutCreditLimit = [
  {
    infoStatement:
      'Here you can define the min and max borrowing facility for the product. It’s important to remember that the actual amount a customer can borrow may be less that the maximum amount you specify here due to credit decision based on the customer’s specific circumstance.',
  },
]

export const moreAboutInterestStatements = [
  {
    infoStatement:
      'Interest statements provide customers with details about the credit interest accrued on their accounts and any amount of tax that’s been deducted.',
  },
  {
    infoStatement:
      'Create an interest statement by choosing a template design and defining the generation settings. Once created, you’ll be able to choose the statement from within your products statement feature.',
  },
  {
    infoStatement:
      'Interest statements are at a party level, this means they can show many products  and subscriptions aggregated onto the same statement.',
  },
]

export const moreAboutInterestStatementsProduct = [
  {
    infoStatement:
      'Here you can configure and choose different types of statements for this product. There are 3 types of statement; Account, Credit, interest and fees.',
  },
  {
    infoStatement:
      'Credit interest statements are at a party level, this means that they can show many product and product subscriptions aggregated onto the same statement.',
  },
  {
    infoStatement:
      'Account and fee statements are specific to the product subscription, so a computer will receive a statement for each product subcription.',
  },
]

export const moreAboutFees = [
  {
    infoStatement: 'You can create recurring scheduled fees or fees based on conditions that you define.',
  },
  {
    infoStatement: (
      <>
        <Body2 css="font-weight: 700; display: inline;">Fees without conditions</Body2> will be applied to all
        subscriptions based on the frequency that you specify. Example: A monthly or annual account maintenace fee can
        be created.
      </>
    ),
  },
  {
    infoStatement: (
      <>
        <Body2 css="font-weight: 700; display: inline;">Fees with conditions</Body2> will only be applied if the
        conditions are met. Example: You can specify that a $10 monthly account maintenace fee is only charged if the
        end of day balance falls below $3,000.
      </>
    ),
  },
]

export const moreAboutProductSyndication = [
  {
    infoStatement:
      'Product syndication allows you to share your product with third parties. You can create a syndication, add meta data within the product syndication area of Bank Manager.',
  },
  {
    infoStatement:
      'Within Bank Manger you’ll be able to see if syndication is added to your product as a feature. But the configuration and management will be within the Product syndication area.',
  },
  {
    infoStatement:
      'There’s no governance and only a basic checks made by Bank Manager. Syndication can only be published after the product has been published. The syndication will use the products effective date. Only the latest version will be available to third parties.',
  },
]
