import { Box } from '@material-ui/core'
import styled from 'styled-components/macro'

const HelpUl = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto;

  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      width: calc(100% - 24px);
      grid-template-columns: 50% 50%;
      column-gap: 24px;
    }
    `}
`

const WidgetLi = styled.li`
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.greyLight};
`

const LinksWrapper = styled(Box)`
  width: 100%;
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.greyLight};
`

export { HelpUl, WidgetLi, LinksWrapper }
