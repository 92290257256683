import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { DateTypography, StatusContainer, StyledAvatar } from './Styled'
import { findEarliestDate } from './utils'
import { BankInterestRateStatus, InterestRate } from 'store/referenceData/types'
import { toBankTz } from 'utils/date.utils'
import { Body2 } from 'dls/atoms/Typography'
import { WithholdingTaxRule } from 'store/withholdingTax/types'

interface Props {
  rates: InterestRate[] | WithholdingTaxRule[]
}

export default ({ rates }: Props): React.ReactElement => {
  const { t } = useTranslation()

  const earliestDate = findEarliestDate(rates)
  const isScheduled = earliestDate && moment().isSameOrBefore(earliestDate)
  const status = isScheduled ? BankInterestRateStatus.SCHEDULED : BankInterestRateStatus.LIVE
  return (
    <StatusContainer data-dls="molecules-statusBadgeIndexRate">
      <StyledAvatar status={status} />
      <Body2 align="left" variantMapping={{ body2: 'div' }}>
        {t(status)}
        <DateTypography variantMapping={{ caption: 'div' }}>
          {earliestDate && toBankTz(earliestDate.toString()).date}
        </DateTypography>
      </Body2>
    </StatusContainer>
  )
}
