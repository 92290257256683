import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { FieldAmount } from 'components'
import { ProductSegment } from 'store/products/types'

export const IncomeEligibilityElement = ({
  name,
  currencyCode,
  productSegment,
}: {
  name: string
  currencyCode: string
  productSegment: ProductSegment
}) => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ marginBottom: 4 }}
    >
      <Box p={2} width="45%">
        <Field
          name={`${name}.minimumIncome`}
          required
          label={t('Minimum annual {{entity}}', {
            entity: productSegment === ProductSegment.BUSINESS ? 'turnover' : 'income',
          })}
          type="number"
          component={FieldAmount}
          currencyCode={currencyCode}
          placeholder={t('Enter amount')}
          numberFormatProps={{
            thousandSeparator: true,
          }}
        />
      </Box>
      <Box p={2} width="45%">
        <Field
          name={`${name}.maximumIncome`}
          fullWidth
          label={t('Maximum annual {{entity}}', {
            entity: productSegment === ProductSegment.BUSINESS ? 'turnover' : 'income',
          })}
          type="number"
          component={FieldAmount}
          currencyCode={currencyCode}
          placeholder={t('Enter amount or leave empty')}
          numberFormatProps={{
            thousandSeparator: true,
          }}
        />
      </Box>
    </Grid>
  )
}
