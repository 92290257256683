import React from 'react'
import styled from 'styled-components/macro'
import GeneralFieldWrapper, { GeneralFieldWrapperProps } from './GeneralFieldWrapper'
import Field, { AFieldProps, NUMBER_FORMAT_PREFIX } from 'dls/atoms/Field'

export interface MInputProps extends AFieldProps, GeneralFieldWrapperProps {
  name: string
  value?: string | number
  readOnly?: boolean
  leadingElement?: React.ReactNode
  onClick?: (val: any) => void
  onChange?: (val: any) => void
  onBlur?: (val: any) => void
  onFocus?: (val: any) => void
  ariaProps?: Record<string, string | boolean>
  testId?: string
}

export const StyledField = styled(Field)`
  &.Mui-error {
    color: ${(props: any) => props.theme.palette.error.main};
    & .MuiSelect-icon {
      color: ${(props: any) => props.theme.palette.error.main};
    }
  }

  &.Mui-focused {
    color: ${(props: any) => props.theme.palette.secondary.main};
    & .MuiFormLabel-asterisk {
      color: ${(props: any) => props.theme.palette.secondary.main};
    }
    &.Mui-error {
      color: ${(props: any) => props.theme.palette.secondary.main};
      & .MuiSelect-icon {
        color: ${(props: any) => props.theme.palette.secondary.main};
      }
    }
  }

  &.MuiInput-underline {
    &:after {
      border-color: ${(props: any) => props.theme.palette.secondary.main};
      & .MuiSelect-icon {
        color: ${(props: any) => props.theme.palette.secondary.main};
      }
    }
    &.Mui-error {
      &:after {
        border-color: ${(props: any) => props.theme.palette.error.main};
      }
    }
    &.Mui-disabled:before {
      border-bottom-style: solid;
    }
  }

  & .MuiSelect-select.Mui-disabled {
    background-color: ${(props: any) => props.theme.palette.primary.greyLight};
    padding-left: 4px;
  }
`

export const Input = React.forwardRef(
  (
    {
      InputLabelProps,
      FormHelperTextProps,
      positiveIntegersOnly,
      numberFormatProps,
      leadingElement,
      helperText,
      errorText,
      type,
      readOnly,
      label,
      currencyCode,
      hideCurrencySymbol,
      placeholder,
      ariaProps,
      testId,
      ...mInputFieldProps
    }: MInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <>
      {leadingElement}
      <GeneralFieldWrapper
        InputLabelProps={InputLabelProps}
        FormHelperTextProps={FormHelperTextProps}
        helperText={helperText}
        errorText={errorText}
        label={label}
        {...mInputFieldProps}
      >
        <StyledField
          data-dls="molecule-input"
          readOnly={readOnly}
          data-test-id={testId}
          placeholder={placeholder}
          numberFormatProps={numberFormatProps}
          positiveIntegersOnly={positiveIntegersOnly}
          type={type}
          currencyCode={currencyCode}
          hideCurrencySymbol={hideCurrencySymbol}
          ariaProps={ariaProps}
          {...mInputFieldProps}
          id={mInputFieldProps.name}
          ref={ref}
        />
      </GeneralFieldWrapper>
    </>
  )
)

const InputWrapper = React.forwardRef((props: MInputProps, ref: React.Ref<HTMLInputElement>) => (
  <Input
    {...props}
    onChange={(e: React.ChangeEvent<{ value: string }>) => {
      if (
        !((props.type && ['currency', 'number', 'percentage'].includes(props.type)) || props.positiveIntegersOnly) ||
        e?.target?.value.startsWith(NUMBER_FORMAT_PREFIX)
      ) {
        if (e?.target?.value.startsWith(NUMBER_FORMAT_PREFIX)) {
          props?.onChange?.({
            ...e,
            target: { ...e?.target, value: e?.target?.value.slice(NUMBER_FORMAT_PREFIX.length) },
          } as any)
        } else {
          props?.onChange?.(e as React.ChangeEvent<{ value: string }>)
        }
      }
    }}
    ref={ref}
  />
))
export default InputWrapper
