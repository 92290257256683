import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.setTransactionRulesClearErrors.type]: (state: ProductsState) => ({
    ...state,
    transactionRulesDefaultAccessPutting: false,
    transactionRulesDefaultAccessPuttingSuccess: false,
    transactionRulesDefaultAccessPuttingError: undefined,
  }),
  [actions.setTransactionRulesClearSuccess.type]: (state: ProductsState) => ({
    ...state,
    transactionRulesDefaultAccessPutting: false,
    transactionRulesDefaultAccessPuttingSuccess: false,
    transactionRulesDefaultAccessPuttingError: undefined,
  }),

  [actions.deleteTransactionRules.type]: (state: ProductsState) => ({
    ...state,
    transactionRuleDeleting: true,
    transactionRulesDefaultAccessPuttingSuccess: false,
    transactionRulesDefaultAccessPuttingError: undefined,
  }),
  [actions.deleteTransactionRulesSuccess.type]: (state: ProductsState) => ({
    ...state,
    transactionRuleDeleting: false,
    transactionRuleDeletingSuccess: true,
    transactionRuleDeletingError: undefined,
  }),
  [actions.deleteTransactionRulesFail.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.deleteTransactionRulesFail>
  ) => ({
    ...state,
    transactionRuleDeleting: false,
    transactionRuleDeletingSuccess: false,
    transactionRuleDeletingError: action.payload,
  }),
  [actions.deleteTransactionRulesClear.type]: (state: ProductsState) => ({
    ...state,
    transactionRuleDeleting: false,
    transactionRuleDeletingSuccess: false,
    transactionRuleDeletingError: undefined,
  }),
}
export default reducer
