import React from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'
import { CardHeader, H6, ReviewRows } from 'dls'
import {
  getOutBoundSingleToParty,
  getOutBoundCumulativeToPartyDay,
  getOutBoundCumulativeToPartyMonth,
  getOutBoundCumulativeToPartyYear,
  getOutBoundMaxToPartyDay,
  getOutBoundMaxToPartyMonth,
  getOutBoundMaxToPartyYear,
} from 'pages/features/PaymentLimits/utilities/transformers'
import buildDefinitions from 'pages/features/PaymentLimits/utilities/buildDefinitions'
import { PaymentLimit, PaymentLimitRoutingDestination } from 'store/products/typings/paymentLimits'
import { getCurrencyInfo } from 'api/utils'

interface OwnProps {
  paymentLimits: PaymentLimit[]
  currencyCode: string
  showActions?: boolean
  hasSeparators?: boolean
  productKey?: string
  inline?: boolean
}

const TransferToPartyRow = ({
  paymentLimits,
  currencyCode,
  showActions = false,
  hasSeparators = false,
  productKey = '',
  inline = false,
}: OwnProps) => {
  const { t } = useTranslation()

  const definitions = buildDefinitions({
    single: getOutBoundSingleToParty(paymentLimits) as any,
    cumulativeDay: getOutBoundCumulativeToPartyDay(paymentLimits) as any,
    cumulativeMonth: getOutBoundCumulativeToPartyMonth(paymentLimits) as any,
    cumulativeYear: getOutBoundCumulativeToPartyYear(paymentLimits) as any,
    maxTransactionsDay: getOutBoundMaxToPartyDay(paymentLimits) as any,
    maxTransactionsMonth: getOutBoundMaxToPartyMonth(paymentLimits) as any,
    maxTransactionsYear: getOutBoundMaxToPartyYear(paymentLimits) as any,
    currencySymbol: getCurrencyInfo(currencyCode)?.nativeSymbol || '',
    productKey,
    t,
    hasSeparators,
    showActions,
    routingDestination: PaymentLimitRoutingDestination.OUTBOUND_PAYMENT,
    ariaLabelledBy: 'other-party-heading',
    allowCustomerOverride: true,
    testId: 'outbound-other-party',
  })

  return (
    <>
      <Box mb={3}>
        <CardHeader
          title={
            inline ? (
              <H6 variantMapping={{ h6: 'h5' }} id="other-party-heading" style={{ fontWeight: 'bold' }}>
                {t('Customer to any other party')}
              </H6>
            ) : (
              <H6 variantMapping={{ h6: 'h4' }} id="other-party-heading">
                {t('To any other party')}
              </H6>
            )
          }
          headerIcon={inline ? <PeopleOutlineOutlinedIcon /> : undefined}
        />
      </Box>
      <ReviewRows definitionsList={definitions} />
    </>
  )
}

export default TransferToPartyRow
