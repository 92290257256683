import { Grid, TableCell, Paper } from '@material-ui/core'
import styled from 'styled-components/macro'

export const StyledTableCell = styled(TableCell)`
  border-bottom: unset;
`

export const ContentGridContainer = styled(Grid)`
  padding: 24px;
  padding-top: 16px;
`

export const StyledContainer = styled.div`
  padding-left: 0;
  padding-right: 0;
`

export const FormPaper = styled(Paper)`
  padding: 24px 0 0;
  margin-top: 0px;
  margin-bottom: 24px;
  padding-bottom: 24px;
`
