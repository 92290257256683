import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box } from '@material-ui/core'
import { Button, CallToActionBar } from 'dls'

interface OwnProps {
  callToActionBar?: {
    cancelButton?: {
      label: string
      onClick: (e: React.MouseEvent) => void
    }
    submitButton?: {
      label: string
      onClick: (e: React.MouseEvent) => void
    }
  }
  disableSubmit: boolean
}

export const Footer = ({ callToActionBar, disableSubmit }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={12}>
        <Box p="24px" display="grid" alignItems="stretch" height="100%">
          <CallToActionBar
            rightRegion={[
              <Button variant="text" key="data-picker-cancel" onClick={callToActionBar?.cancelButton?.onClick}>
                {t(callToActionBar?.cancelButton?.label || 'CANCEL')}
              </Button>,
              <Button
                disabled={disableSubmit}
                variant="contained"
                key="data-picker-submit"
                onClick={callToActionBar?.submitButton?.onClick}
              >
                {t(callToActionBar?.submitButton?.label || 'DONE')}
              </Button>,
            ]}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(Footer)
