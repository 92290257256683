import { call, put, delay, takeEvery, takeLatest } from 'redux-saga/effects'
import i18next from 'i18next'
import RulesActions from './actions'
import { navigate } from 'store/router/actions'
import { WithholdingTaxApi } from 'api'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { navigateToUnavailable } from 'utils/url.utils'
import ModalActions from 'store/modal/actions'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import { showPendingActionModalSaga } from 'store/modal/sagas'

function* getAllTaxRulesSaga(action: ReturnType<typeof RulesActions.getAllTaxRules>) {
  try {
    const response: ReturnTypePromise<typeof WithholdingTaxApi.getAllTaxRules> = yield call(
      WithholdingTaxApi.getAllTaxRules
    )
    yield put(RulesActions.getAllTaxRulesSuccess(response ? response.rules : []))
  } catch (e: any) {
    if (action?.payload?.shouldRedirect ?? true) {
      yield call(navigateToUnavailable)
    } else {
      yield put(
        ModalActions.showModal({
          modalType: ModalType.ERROR,
        })
      )
    }
  }
}

function* getAllTaxRulesWithoutErrorNavigationSaga() {
  try {
    const response: ReturnTypePromise<typeof WithholdingTaxApi.getAllTaxRules> = yield call(
      WithholdingTaxApi.getAllTaxRules
    )
    yield put(RulesActions.getAllTaxRulesSuccess(response ? response.rules : []))
  } catch (e: any) {
    yield put(
      ModalActions.showModal({
        modalType: ModalType.ERROR,
      })
    )
  }
}

function* createRuleSaga(action: ReturnType<typeof RulesActions.createRule>) {
  try {
    const response: ReturnTypePromise<typeof WithholdingTaxApi.createRule> = yield call(
      WithholdingTaxApi.createRule,
      action.payload
    )

    yield put(
      ModalActions.showModal({
        modalType: ModalType.PROCESS,
        title: `Processing request`,
        message: 'Please wait',
      })
    )

    yield put(RulesActions.getAllTaxRules())
    yield delay(UI_DELAY_TO_SHOW_LOADING)

    yield put(
      ModalActions.showModal({
        modalType: ModalType.SUCCESS,
        title: `Rule successfully submitted`,
        message:
          'The rule was successfully submitted and will be available to use in any products credit interest feature',
      })
    )

    yield put(navigate(`/reference-data/withholding-tax/${response.ruleId}`))
  } catch (e: any) {
    yield put(
      ModalActions.showModal({
        modalType: ModalType.ERROR,
      })
    )
  }
}

function* getTaxRuleSaga(action: ReturnType<typeof RulesActions.getTaxRule>) {
  try {
    const response: ReturnTypePromise<typeof WithholdingTaxApi.getTaxRule> = yield call(
      WithholdingTaxApi.getTaxRule,
      action.payload
    )
    yield put(RulesActions.getTaxRuleSuccess(response))
  } catch (e: any) {
    yield call(navigateToUnavailable)
  }
}

function* updateTaxRuleSaga(action: ReturnType<typeof RulesActions.updateTaxRule>) {
  try {
    const t = i18next.t.bind(i18next)
    const { mode } = action.payload
    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: t(`You’re ${mode}ing a future rate – are you sure?`),
      message: t(
        'Changes you have made will automatically impact any product which use the rule, in accordance with the effective date. Are you sure you want to proceed? If in doubt, go back and check.'
      ),
      messageLink: {
        label: 'See products using this rule.',
        url: `/reference-data/withholding-tax/${action.payload.ruleId}/products`,
      },
      pendingAction: ModalPendingAction.CREATE,
    })

    if (isConfirmed) {
      yield put(
        ModalActions.showModal({
          modalType: ModalType.PROCESS,
          title: `Processing request`,
          message: 'Please wait',
        })
      )

      if (mode === 'edit') {
        yield call(WithholdingTaxApi.updateTaxRule, action.payload)
      } else {
        yield call(WithholdingTaxApi.addTaxRule, action.payload)
      }

      yield delay(UI_DELAY_TO_SHOW_LOADING)

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: `Rate successfully submitted`,
          message:
            'The rate was successfully submitted and will be available to use in any products credit interest feature',
        })
      )

      yield put(navigate(`/reference-data/withholding-tax/${action.payload.ruleId}`))
    }
  } catch (e: any) {
    yield put(
      ModalActions.showModal({
        modalType: ModalType.ERROR,
      })
    )
  }
}

function* getTaxRuleProductsSaga(action: ReturnType<typeof RulesActions.getTaxRuleProducts>) {
  try {
    const response: ReturnTypePromise<typeof WithholdingTaxApi.getTaxRuleProducts> = yield call(
      WithholdingTaxApi.getTaxRuleProducts,
      action.payload
    )
    yield put(RulesActions.getTaxRuleProductsSuccess({ key: action.payload, products: response.content }))
  } catch (e: any) {
    yield put(RulesActions.getTaxRuleProductsError())
  }
}

function* deleteRuleSaga({ payload: rule }: ReturnType<typeof RulesActions.deleteRule>) {
  try {
    const t = i18next.t.bind(i18next)
    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: t('You’re deleting {{ruleName}}', { ruleName: rule.name }),
      message: t('Are you sure? This can’t be undone.'),
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(
        ModalActions.showModal({
          modalType: ModalType.PROCESS,
          title: `Processing request`,
          message: 'Please wait',
        })
      )
      yield call(WithholdingTaxApi.deleteRule, rule.ruleId)
      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(RulesActions.getAllTaxRules())
      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: t('{{ruleName}} successfully deleted', { ruleName: rule.name }),
          pendingAction: ModalPendingAction.REMOVE,
        })
      )
    }
  } catch (e: any) {
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    const errorStatus = e?.response?.status
    switch (errorStatus) {
      case 422:
        yield put(
          ModalActions.showModal({
            modalType: ModalType.PENDING_ACTION,
            title: `Oops, this rule is in use`,
            message:
              'You can’t delete a rule that’s being used by 1 or more products. You’ll need to remove the rule from products before you can delete it.',
            pendingAction: ModalPendingAction.REMOVE,
            confirmBtnLabel: 'SEE PRODUCTS',
            confirmNavigationRoute: `/reference-data/withholding-tax/${rule.ruleId}/products`,
          })
        )
        break
      case 400:
        yield put(
          ModalActions.showModal({
            modalType: ModalType.ERROR,
            title: `Oops, this rule cannot be removed`,
            message: 'Effective date of tax rule is not in future so rule can not be deleted',
          })
        )
        break
      default:
        yield put(
          ModalActions.showModal({
            modalType: ModalType.ERROR,
          })
        )
        break
    }
  }
}

export default function* () {
  yield takeLatest(RulesActions.getAllTaxRules.type, getAllTaxRulesSaga)
  yield takeLatest(RulesActions.getAllTaxRulesWithoutErrorNavigation.type, getAllTaxRulesWithoutErrorNavigationSaga)
  yield takeLatest(RulesActions.createRule.type, createRuleSaga)
  yield takeLatest(RulesActions.getTaxRule.type, getTaxRuleSaga)
  yield takeLatest(RulesActions.updateTaxRule.type, updateTaxRuleSaga)
  yield takeEvery(RulesActions.getTaxRuleProducts.type, getTaxRuleProductsSaga)
  yield takeLatest(RulesActions.deleteRule.type, deleteRuleSaga)
}
