import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { ContentState } from './types'

const initialState: ContentState = {
  loading: false,
  error: undefined,
  documents: undefined,
}

const reducer = createReducer<ContentState>(initialState, {
  [actions.getRemoteDocuments.type]: (state) => ({ ...state, loading: true }),
  [actions.getRemoteDocumentsSuccess.type]: (_state, action) => ({
    documents: action.payload,
    loading: false,
  }),
  [actions.getRemoteDocumentsFail.type]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
})

export default reducer
