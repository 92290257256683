import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Feature, ProductKey } from 'store/products/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import { TenantConfig } from 'store/tenant-config/types'

export default (productKey: ProductKey, context: Feature): void => {
  const tenantConfig: TenantConfig | undefined = useSelector(selectConfig)
  const navigate = useNavigate()

  if (!isFeatureAllowedByConfig(tenantConfig, context)) navigate(`./../not-allowed`)
}
