/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import {
  TaskRejectConfirmation,
  TaskApproveConfirmation,
  ProgressModal,
  FailureModal,
  ProcessExecutionFailedDialog,
  SuccessModal,
  AssigneeModal,
  NoSetUpGovernanceDialog,
  NoSetUpUserForReviewDialog,
} from 'components'
import { TasksResultType } from 'store/tasks/types'
import TasksActions from 'store/tasks/actions'
import ProductsActions from 'store/products/actions'
import PackagesActions from 'store/packages/actions'
import { RootState } from 'store'
import TasksSelectors from 'store/tasks/selectors'
import ProductsSelectors from 'store/products/selectors'
import PackagesSelectors from 'store/packages/selectors'
import GovernanceActions from 'store/governance/actions'
import GovernanceSelectors from 'store/governance/selectors'
import { toBankTz } from 'utils/date.utils'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  taskActionResultClear: () => dispatch(TasksActions.taskActionResultClear()),
  clearReviewErrors: () => dispatch(ProductsActions.clearReviewErrors()),
  proccessExecutionFailCloseModal: () => dispatch(GovernanceActions.processExecutionFailCloseModal()),
  clearProductPublishSuccess: () => dispatch(ProductsActions.clearPublishSuccess()),
  clearPackagesPublishSuccess: () => dispatch(PackagesActions.clearPublishSuccess()),
  clearPackagePublishError: () => dispatch(PackagesActions.clearPublishError()),
  clearProductPublishError: () => dispatch(ProductsActions.clearProductPublishError()),
  clearGetVersionsFailForPublish: () => dispatch(ProductsActions.clearGetVersionsFailForPublish()),
  setTransactionRulesClearErrors: () => dispatch(ProductsActions.setTransactionRulesClearErrors()),
  deleteTransactionRulesClear: () => dispatch(ProductsActions.deleteTransactionRulesClear()),
})

export const mapStateToProductProps = (state: RootState) => ({
  selectTaskActionResult: TasksSelectors.selectTaskActionResult(state),
  openTaskLoader: ProductsSelectors.selectTaskLoaderModal(state),
  productReviewError: ProductsSelectors.selectReviewError(state),
  productPublishError: ProductsSelectors.selectPublishError(state),
  processExecutionsLoading: GovernanceSelectors.selectProcessExecutionsLoading(state),
  processExecutionsError: GovernanceSelectors.selectprocessExecutionsError(state),
  productReviewing: ProductsSelectors.selectReview(state),
  selectProductPublishing: ProductsSelectors.selectPublishing(state),
  selectProductPublishSuccess: ProductsSelectors.selectPublishSuccess(state),
  selectPackagePublishing: PackagesSelectors.selectPublishing(state),
  selectPackagePublishSuccess: PackagesSelectors.selectPublishSuccess(state),
  selectPackagePublishError: PackagesSelectors.selectPublishError(state),
  selectSelectedProduct: ProductsSelectors.selectSelectedProduct(state),
  selectSelectedPackage: PackagesSelectors.selectSelectedPackage(state),
  selectReviewNoUserError: ProductsSelectors.selectReviewNoUserError(state),
  selectReviewGeneralError: ProductsSelectors.selectReviewGeneralError(state),
  selectProductVersionsFail: ProductsSelectors.selectProductVersionsFail(state),
  selectTransactionRulesDefaultAccessPutting: ProductsSelectors.selectTransactionRulesDefaultAccessPutting(state),
  selectTransactionRulesDefaultAccessPuttingError: ProductsSelectors.selectTransactionRulesDefaultAccessPuttingError(
    state
  ),
  selectTransactionRuleDeleting: ProductsSelectors.selectTransactionRuleDeleting(state),
  selectTransactionRuleDeletingSuccess: ProductsSelectors.selectTransactionRuleDeletingSuccess(state),
  selectTransactionRuleDeletingError: ProductsSelectors.selectTransactionRuleDeletingError(state),
  selectCreateNewProductVersionLoading: ProductsSelectors.selectCreateNewProductVersionLoading(state),
})

type DialogProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProductProps>

const Dialogs = (props: DialogProps) => {
  const { t } = useTranslation()
  return (
    <>
      <TaskRejectConfirmation
        open={props.selectTaskActionResult === TasksResultType.REJECT_SUCCESS}
        setOpen={props.taskActionResultClear}
      />
      <TaskApproveConfirmation
        open={props.selectTaskActionResult === TasksResultType.APPROVE_SUCCESS}
        setOpen={props.taskActionResultClear}
      />
      <FailureModal
        open={props.selectTaskActionResult === TasksResultType.FAIL}
        description={t('tryAgain')}
        setOpen={props.taskActionResultClear}
      />
      <AssigneeModal />
      <ProgressModal
        title={
          props.productReviewing
            ? t('Changing product status to in review')
            : props.processExecutionsLoading
            ? t('Preparing product for approval')
            : props.selectProductPublishing
            ? t('Publishing product')
            : props.selectPackagePublishing
            ? t('Publishing package')
            : props.selectTransactionRulesDefaultAccessPutting || props.selectTransactionRuleDeleting
            ? t('Processing')
            : ''
        }
        description={
          props.productReviewing
            ? t('Please wait while we prepare your product for review')
            : props.processExecutionsLoading
            ? t('Please wait while we prepare your product for approval')
            : props.selectProductPublishing || props.selectPackagePublishing
            ? t('Please wait')
            : props.selectTransactionRulesDefaultAccessPutting || props.selectTransactionRuleDeleting
            ? t('Please wait while we process that')
            : props.selectCreateNewProductVersionLoading
            ? t('Please wait while we process the request')
            : ''
        }
        open={
          props.productReviewing ||
          props.processExecutionsLoading ||
          props.selectProductPublishing ||
          props.selectPackagePublishing ||
          props.selectTransactionRulesDefaultAccessPutting ||
          props.selectTransactionRuleDeleting
        }
      />
      <SuccessModal
        title={
          props.selectProductPublishSuccess
            ? t('Your product was successfully published')
            : props.selectPackagePublishSuccess
            ? t('Your package was successfully published')
            : props.selectTransactionRuleDeletingSuccess
            ? t('Successfully deleted')
            : ''
        }
        description={
          props.selectProductPublishSuccess ? (
            <>
              <Typography variant="body2">{t('Your product will go live on the effective date')}</Typography>
              <Typography variant="body2">
                {props.selectSelectedProduct && props.selectSelectedProduct.effectiveDate
                  ? toBankTz(props.selectSelectedProduct.effectiveDate).dateTime
                  : ''}
              </Typography>
            </>
          ) : props.selectPackagePublishSuccess ? (
            <>
              <Typography variant="body2">{t('Your package will go live on the effective date')}</Typography>
              <Typography variant="body2">
                {props.selectSelectedPackage && props.selectSelectedPackage.publicationDate
                  ? toBankTz(props.selectSelectedPackage.publicationDate).dateTime
                  : ''}
              </Typography>
            </>
          ) : props.selectTransactionRuleDeletingSuccess ? (
            <Typography variant="body2">{t('The exception rule has been deleted')}</Typography>
          ) : (
            ''
          )
        }
        open={
          props.selectProductPublishSuccess ||
          props.selectPackagePublishSuccess ||
          props.selectTransactionRuleDeletingSuccess
        }
        setOpen={
          props.selectProductPublishSuccess
            ? props.clearProductPublishSuccess
            : props.selectPackagePublishSuccess
            ? props.clearPackagesPublishSuccess
            : props.selectTransactionRuleDeletingSuccess
            ? props.deleteTransactionRulesClear
            : () => {
                // do nothing
              }
        }
      />
      <FailureModal
        open={
          !!props.productPublishError ||
          (props.selectPackagePublishError &&
            !isEmpty(props.selectPackagePublishError) &&
            props.selectPackagePublishError !== null) ||
          !!props.selectReviewGeneralError ||
          !!props.selectTransactionRulesDefaultAccessPuttingError ||
          !!props.selectTransactionRuleDeletingError
        }
        description={t('tryAgain')}
        setOpen={() =>
          props.selectPackagePublishError
            ? props.clearPackagePublishError()
            : props.selectReviewGeneralError
            ? props.clearReviewErrors()
            : props.selectProductVersionsFail
            ? props.clearGetVersionsFailForPublish()
            : props.selectTransactionRulesDefaultAccessPuttingError
            ? props.setTransactionRulesClearErrors()
            : props.selectTransactionRuleDeletingError
            ? props.deleteTransactionRulesClear()
            : props.clearProductPublishError()
        }
      />
      <NoSetUpGovernanceDialog
        open={!!props.productReviewError}
        setOpen={() => {
          props.clearReviewErrors()
        }}
      />
      <NoSetUpUserForReviewDialog
        open={!!props.selectReviewNoUserError}
        setOpen={() => {
          props.clearReviewErrors()
        }}
      />
      <ProcessExecutionFailedDialog
        open={
          props.processExecutionsError &&
          !isEmpty(props.processExecutionsError) &&
          props.processExecutionsError !== null
        }
        setOpen={() => {
          props.proccessExecutionFailCloseModal()
        }}
      />
    </>
  )
}

export default connect(mapStateToProductProps, mapDispatchToProps)(Dialogs)
