/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Field, FormikProps, getIn } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import {
  mapBankInterestRatesToFieldData,
  selectedRateAndBankName,
} from 'pages/features/CreditInterest/utils/creditInterestTransformers'
import CommonCreditInterest from 'pages/features/CreditInterest/components/Common'
import { ContentSpace, TooltipStyle } from 'pages/features/CreditInterest/styles'
import { CreditInterestFormValues, ExtendedProduct, TypeOfIndexRate } from 'store/products/types'
import { BankInterestRate, SelectedRateAndBankName } from 'store/referenceData/types'
import SelectFieldComplex, { SelectFieldData } from 'components/form/SelectFieldComplex'
import { Yip } from 'utils/form.utils'
import { AccessibleLink, ContextualHelp } from 'components'
import { H3, InfoPanel } from 'dls'

interface OwnProps {
  hasFeature?: boolean
  currencyOfProduct?: string | null
  bankInterestRates?: BankInterestRate[]
  selectTypeOfIndexRate: TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN
  product: ExtendedProduct
}

interface StaticMarginProps extends FormikProps<CreditInterestFormValues>, OwnProps {}

const VariableMargin = (props: StaticMarginProps): React.ReactElement => {
  const [selectDataRateSource, setSelectDataRateSource] = useState<SelectFieldData>({})
  const { t } = useTranslation()
  const { values, bankInterestRates, selectTypeOfIndexRate, product } = props

  useEffect(() => {
    if (bankInterestRates && bankInterestRates.length) {
      setSelectDataRateSource(mapBankInterestRatesToFieldData(bankInterestRates))
    }
  }, [bankInterestRates])

  const selectedbankInterestRateIndex = getIn(values, 'bankInterestRateIndex')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let selectedRateAndBankNameObject: SelectedRateAndBankName | null = null
  if (selectedbankInterestRateIndex && bankInterestRates) {
    selectedRateAndBankNameObject = selectedRateAndBankName(bankInterestRates, selectedbankInterestRateIndex)
  }

  return (
    <ContentSpace>
      <H3>{t('Index rate source')}</H3>
      {!isEmpty(bankInterestRates) ? (
        <Grid container direction="row" spacing={3}>
          <Grid item xs={7}>
            <Field
              name="bankInterestRateIndex"
              label={t('Rate source')}
              required
              key="variable-margin-rate-source"
              data={selectDataRateSource}
              selectProps={{ displayEmpty: true }}
              inputLabelProps={{ shrink: true }}
              component={SelectFieldComplex}
              placeholder={t('Please select')}
              validate={Yip(Yup.string().required('This field is required').nullable())}
              helperText={
                <>
                  Add new index rate in&nbsp;
                  <AccessibleLink color="secondary" to="/reference-data/index-rates">
                    reference data
                  </AccessibleLink>
                </>
              }
            />
          </Grid>
          <Grid item xs={1}>
            <TooltipStyle>
              <ContextualHelp
                title={t(`You’ll only see the index rates which have been
                          created in Reference data. Also, you’ll only see index
                          rates which have an effective date of today or earlier`)}
              />
            </TooltipStyle>
          </Grid>
          {selectedbankInterestRateIndex && selectedRateAndBankNameObject && (
            <Grid item xs={12}>
              <CommonCreditInterest
                {...props}
                selectedRateAndBankNameObject={selectedRateAndBankNameObject}
                selectTypeOfIndexRate={selectTypeOfIndexRate}
                product={product}
                currencyOfProduct={product.currency.currencyCode || undefined}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <InfoPanel
          title={t('Please create an index rate with an effective date of today or earlier')}
          body={
            <>
              <Typography variant="body2" variantMapping={{ body2: 'div' }}>
                {t('You can create an index rate in ')}
                <AccessibleLink color="secondary" to="/reference-data/index-rates">
                  {t('Reference data.')}
                </AccessibleLink>
              </Typography>
              <Typography variant="body2">
                {t(
                  'As an example, you could create a source as the Bank of England and set the appropriate rate. Or you can create your own sources.'
                )}
              </Typography>
            </>
          }
        />
      )}
    </ContentSpace>
  )
}

export default React.memo(VariableMargin)
