import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserRole } from 'store/user/types'

interface OwnProps {
  userRole: UserRole
}

const UserRoleLabel = ({ userRole }: OwnProps) => {
  const { t } = useTranslation()
  return <>{t(userRole.toString())}</>
}

export default UserRoleLabel
