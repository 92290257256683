import React from 'react'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { Badge } from '@material-ui/core'
import {
  ExpandLess,
  InfoOutlined,
  Edit,
  ExpandMore,
  GetApp,
  Code,
  Add,
  AddCircle,
  Clear,
  HelpOutlineSharp,
  Notifications,
  AccountCircle,
  Search,
  Refresh,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Undo,
  Menu,
  RemoveCircle,
  Close,
  HelpOutline,
  FilterList,
  Visibility,
  VisibilityOff,
  MoreVert,
  DeleteOutline,
} from '@material-ui/icons'
import styled from 'styled-components/macro'
import { fontSizeTypes, ImageSize, ColorType } from 'dls/shared/types'
import { CopyIcon, GridViewIcon, CopiedIcon, HistoryIcon, EditAvatarIcon, PreviewIcon } from 'dls/atoms/Icons/SVGIcons'
import { ListIcon, VisibilityOffIcon, VisibilityIcon } from 'dls/atoms/Icons/MUIIcons'
import { iconColorSelector } from 'dls/shared/styled'

interface IconButtonPropsExtended<C> {
  fontSize?: fontSizeTypes
  ariaProps?: Record<string, string | boolean>
  imgProps?: Record<any, any>
  component?: C
}

const StyledIconButton: any = styled(IconButton)<{ color: ColorType }>`
  text-transform: none;
  ${({ color }) =>
    color &&
    `
    color: ${iconColorSelector};
    & svg > * {
      fill:  ${iconColorSelector};
      &:hover {
        fill: ${iconColorSelector};
      }
    }  `}
`

export const MoreVertButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="MoreVert" data-dls="molecule-MoreVertButton" {...props} {...ariaProps} color={color}>
    <MoreVert fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const VisibilityButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="Visibility"
    data-dls="molecule-VisibilityButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <Visibility fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const VisibilityOffButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="VisibilityOff"
    data-dls="molecule-VisibilityOffButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <VisibilityOff fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const FilterListButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="FilterList"
    data-dls="molecule-FilterListButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <FilterList fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const HelpOutlineButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="HelpOutline"
    data-dls="molecule-HelpOutlineButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <HelpOutline fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const CloseButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Close" data-dls="molecule-CloseButton" {...props} {...ariaProps} color={color}>
    <Close fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const RemoveCircleButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="RemoveCircle"
    data-dls="molecule-RemoveCircleButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <RemoveCircle fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const MenuButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Menu" data-dls="molecule-MenuButton" {...props} {...ariaProps} color={color}>
    <Menu fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const UndoButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Undo" data-dls="molecule-UndoButton" {...props} {...ariaProps} color={color}>
    <Undo fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const KeyboardArrowUpButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="KeyboardArrowUp"
    data-dls="molecule-KeyboardArrowUpButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <KeyboardArrowUp fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const KeyboardArrowDownButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="KeyboardArrowDown"
    data-dls="molecule-KeyboardArrowDownButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <KeyboardArrowDown fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)
export const HistoryIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton data-dls="molecule-HistoryIconButton" {...props} {...ariaProps} $color={color}>
    <HistoryIcon size={fontSize as ImageSize} color={ColorType.SECONDARY} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const VisibilityIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton data-dls="molecule-VisibilityIconButton" {...props} {...ariaProps} $color={color}>
    <VisibilityIcon size={fontSize as ImageSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const VisibilityOffIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton data-dls="molecule-VisibilityOffIconButton" {...props} {...ariaProps} $color={color}>
    <VisibilityOffIcon size={fontSize as ImageSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const GridViewIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton data-dls="molecule-GridViewIconButton" {...props} {...ariaProps} $color={color}>
    <GridViewIcon size={fontSize as ImageSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const ListIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton data-dls="molecule-ListIconButton" {...props} {...ariaProps} $color={color}>
    <ListIcon size={fontSize as ImageSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const CopyIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Copy" data-dls="molecule-CopyIconButton" {...props} {...ariaProps} color={color}>
    <CopyIcon size={fontSize as ImageSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const CopiedIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Copied" data-dls="molecule-CopiedIconButton" {...props} {...ariaProps} color={color}>
    <CopiedIcon size={fontSize as ImageSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)
export const InfoIconButton = <C extends React.ElementType>({
  fontSize = 'medium',
  ariaProps,
  imgProps = {},
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Info" data-dls="molecule-InfoIconButton" {...props} {...ariaProps} color={color}>
    <InfoOutlined fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const CodeIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Code" data-dls="molecule-CodeIconButton" {...props} {...ariaProps} color={color}>
    <Code fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const DownloadIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="Download"
    data-dls="molecule-DownloadIconButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <GetApp fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const AddIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Add" data-dls="molecule-AddIconButton" {...props} {...ariaProps} color={color}>
    <Add fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const NotificationIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  badgeContent,
  max,
  badgeColor,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="Notification"
    data-dls="molecule-NotificationIconButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    {badgeContent ? (
      <Badge badgeContent={badgeContent} max={max} color={badgeColor}>
        <Notifications fontSize={fontSize} {...imgProps} />
      </Badge>
    ) : (
      <Notifications fontSize={fontSize} {...imgProps} />
    )}
  </StyledIconButton>
)

export const AddCircleIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Add" data-dls="molecule-AddCircleIconButton" {...props} {...ariaProps} color={color}>
    <AddCircle fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const CollapseIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="collapse"
    data-dls="molecule-CollapseIconButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <ExpandLess fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const UserAccountIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="User-account"
    data-dls="molecule-UserAccountIconButton"
    {...props}
    {...ariaProps}
    color={color}
  >
    <AccountCircle fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const SearchIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Search" data-dls="molecule-SearchIconButton" {...props} {...ariaProps} color={color}>
    <Search fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const RefreshButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Refresh" data-dls="molecule-RefreshButton" {...props} {...ariaProps} color={color}>
    <Refresh fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const ExpandIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Expand" data-dls="molecule-ExpandIconButton" {...props} {...ariaProps} color={color}>
    <ExpandMore fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const CloseIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Close" data-dls="molecule-CloseIconButton" {...props} {...ariaProps} color={color}>
    <Clear fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const DeleteIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Delete" data-dls="molecule-DeleteIconButton" {...props} {...ariaProps} color={color}>
    <DeleteOutline fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)
export const DesignIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Edit" data-dls="molecule-DesignIconButton" {...props} {...ariaProps} color={color}>
    <Edit fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)
export const HelpOutlineSharpButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton aria-label="Help" data-dls="molecule-HelpOutlineIconButton" {...props} {...ariaProps} color={color}>
    <HelpOutlineSharp fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export const EditAvatarIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="Copy"
    data-dls="molecule-HelpOutlineIconButton"
    {...props}
    {...ariaProps}
    $color={color}
  >
    <EditAvatarIcon fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)
export const PreviewLaunchIconButton = <C extends React.ElementType>({
  fontSize,
  ariaProps,
  imgProps,
  color,
  ...props
}: IconButtonProps<C, IconButtonPropsExtended<C>>) => (
  <StyledIconButton
    aria-label="Copy"
    data-dls="molecule-PreviewLaunchIconButton"
    {...props}
    {...ariaProps}
    $color={color}
  >
    <PreviewIcon fontSize={fontSize} aria-hidden {...imgProps} />
  </StyledIconButton>
)

export default {
  CopyIconButton,
  DownloadIconButton,
  CodeIconButton,
  InfoIconButton,
  ExpandIconButton,
  CollapseIconButton,
  CloseIconButton,
  AddCircleIconButton,
  DeleteIconButton,
  DesignIconButton,
  HelpOutlineButton,
  EditAvatarIconButton,
  PreviewLaunchIconButton,
  VisibilityIconButton,
  VisibilityOffIconButton,
  GridViewIconButton,
  ListIconButton,
}
