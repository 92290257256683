import React from 'react'
import { ProductStatus } from 'store/products/typings/productStatus'
import { StyledAvatar, StyledDoneStepAvatar } from 'components/StatusBadge/Styled'
import { BankDateTime } from 'utils/date.utils'

export const CompletedIcon = () => <StyledDoneStepAvatar status={ProductStatus.DESIGN} />
export const StatusDesignStepIcon = () => <StyledAvatar status={ProductStatus.DESIGN} />
export const StatusReviewStepIcon = () => <StyledAvatar status={ProductStatus.REVIEW} />
export const StatusApprovedStepIcon = () => <StyledAvatar status={ProductStatus.APPROVED} />
export const StatusScheduledStepIcon = () => (
  <StyledAvatar status={ProductStatus.LIVE} parsedDate={{ isAfterNow: true } as BankDateTime} />
)
export const StatusLiveStepIcon = () => (
  <StyledAvatar status={ProductStatus.LIVE} parsedDate={{ isAfterNow: false } as BankDateTime} />
)
export const StatusRetiredStepIcon = () => <StyledAvatar status={ProductStatus.CLOSED} />
