import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FieldValues } from 'react-hook-form'
import { toFeeFormValues } from '../utils/transformers'
import useFeesRewards from '../utils/useFeesRewards'
import UpdateForm from 'pages/features/FeesRewards/update/components/UpdateForm'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { FeeRewardFeatureType } from 'store/products/types'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import { FeeReward } from 'store/products/features/feesRewards/types'

const UpdateFeeRewardPage = ({ featureType }: { featureType: FeeRewardFeatureType }) => {
  const { productKey = '', entityId } = useParams()
  const { t } = useTranslation()

  const [product, isLoading] = useSelectedProduct(productKey)

  const breadcrumbs = entityId
    ? { productKey, context: featureType, subcontext: t(`PRODUCT_FEATURE.${featureType}.EDIT_ENTITY`) }
    : { productKey, context: t(`PRODUCT_FEATURE.${featureType}.EDIT_ENTITY`) }

  const { submitFeeReward } = useFeesRewards({
    productKey,
    product,
    featureType,
  })

  useUpdateBreadcrumbs(breadcrumbs)

  const defaultValues: FieldValues | null = toFeeFormValues({
    entity: product?.[featureType]?.[featureType]?.find((entity: FeeReward) => entity.id === entityId) || null,
    featureType,
  })

  if (isLoading) {
    return <Loader />
  }

  return product ? (
    <UpdateForm
      productKey={productKey}
      product={product}
      onSubmit={submitFeeReward}
      defaultValues={defaultValues}
      featureType={featureType}
    />
  ) : null
}

export default UpdateFeeRewardPage
