import { TFunction } from 'i18next'
import { find } from 'lodash'

export const getReason = (reasonCode: string, reasonCodeMappings: any, t: TFunction) => {
  const selected = find(reasonCodeMappings, { REASONCODE: reasonCode })
  if (!selected || !selected.NAME || selected.NAME === '') return t('N/A')
  return `${t(selected.NAME)} (${reasonCode})`
}
export const getReasonDescription = (reasonCode: string, reasonCodeMappings: any, t: TFunction) => {
  const selected = find(reasonCodeMappings, { REASONCODE: reasonCode })
  if (!selected || !selected.NAME || selected.NAME === '') return ''
  return `${selected && t(selected.DEFINITION)}.`
}
