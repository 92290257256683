import { TFunction } from 'react-i18next'
import { StatementConfigurationFormValues, StatementConfiguration } from 'store/templates/statementConfiguration/types'

export const toStatementConfiguration = (values: StatementConfigurationFormValues): StatementConfiguration => {
  const {
    name,
    statementDescription,
    statementType,
    offsetDays,
    startDay,
    startMonth,
    statementPeriod,
    level,
    productSegment,
    statementConfigKey,
    template,
  } = values

  const configuration: StatementConfiguration = {
    name,
    statementDescription,
    statementType,
    offsetDays: parseInt(offsetDays, 10),
    startDay: parseInt(startDay?.replace('DAY', ''), 10),
    startMonth: startMonth ? parseInt(startMonth?.replace('MONTH', ''), 10) : undefined,
    statementPeriod,
    level,
    productSegment,
    statementConfigKey,
  }

  if (template) {
    configuration.template = {
      name: template?.templateName,
      id: template?.templateGroupId,
    }
  }

  return configuration
}

export const toStatementConfigurationFormValues = (
  statementConfiguration?: StatementConfiguration
): StatementConfigurationFormValues | {} => {
  if (!statementConfiguration) {
    return {}
  }

  const {
    name,
    statementDescription,
    statementType,
    offsetDays,
    startDay,
    startMonth,
    statementPeriod,
    level,
    productSegment,
    statementConfigKey,
    template,
  } = statementConfiguration

  const formValues: StatementConfigurationFormValues = {
    name,
    statementDescription,
    statementType,
    offsetDays: `${offsetDays}`,
    startDay: `DAY${startDay}`,
    startMonth: `MONTH${startMonth && startMonth < 10 ? '0' : ''}${startMonth}`,
    statementPeriod,
    level,
    productSegment,
    statementConfigKey,
    template: null,
  }

  if (template) {
    formValues.template = {
      isDefault: true,
      templateGroupId: template.id,
      templateName: template.name,
    }
  }

  return formValues
}

export const generateFrequencyPeriodText = ({
  period,
  day,
  month,
  t,
}: {
  period: string
  day: number
  month: number
  t: TFunction
}): string => {
  if (period && day) {
    if (period.toLowerCase() === 'year') {
      return `${t(`PERIOD.${period}`)} : ${t(`CALENDAR.DAY${day}`)} ${t(
        `CALENDAR.MONTH${month < 10 ? `0${month}` : month}`
      )}`
    }
    return `${t(`PERIOD.${period}`)} : ${t(`CALENDAR.DAY${day}`)}`
  }

  return ''
}
