import React from 'react'
import { Form, FormikProps } from 'formik'
import { useTranslation, TFunction } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { debitInterestSetupValidationSchema } from '../../../utils/debitInterestValidationSchema'
import DebitInterestInfoButton from '../DebitInterestInfoButton'
import DebitInterestTypeSelector from '../DebitInterestTypeSelector'
import { RateFormWithoutFormik } from './RateForm'
import { CommonConfigFormWithoutFormik } from './CommonConfigForm'
import { Paper, Body2 } from 'dls'
import { NoActivityHelpPanel, TemplateSubReview, H1, BackButton } from 'components'
import {
  DayCount,
  ExtendedProduct,
  FixedVariableType,
  BalanceCriteria,
  RateTierBand,
  DebitInterestFormValues,
  LendingRateType,
} from 'store/products/types'
import { withFormikSimple } from 'utils/form.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Flexbox } from 'pages/Layout/Styled'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

type Props = {
  product: ExtendedProduct
  productKey: string
  isLending: boolean
  rateType?: LendingRateType
  setRateType: (rateType: LendingRateType) => void
  onSubmit: (values: DebitInterestFormValues) => void
  t: TFunction
}

const SetupForm = (props: FormikProps<DebitInterestFormValues> & Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { rateType, setRateType, isLending, productKey, isValid } = props

  const isSubmitButtonDisabled = (): boolean => {
    if (isLending) {
      return !isValid
    }

    return !isValid || !rateType
  }

  return (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={() => navigate(-1)} aria-controls="main" />
              </Grid>
              <Grid item>
                <DebitInterestInfoButton />
              </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <H1 gutterBottom>{t(`DEBIT_INTEREST.SETUP_HEADER`)}</H1>
              </Grid>
              <Grid item>
                <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
                  {t(`DEBIT_INTEREST.${isLending ? 'LOAN' : 'CURRENT_SAVINGS'}.SETUP_DESCRIPTION`)}
                </Body2>
              </Grid>
            </Grid>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <Paper>
            <Form>
              <Grid container spacing={3}>
                <CommonConfigFormWithoutFormik {...props} initialSetup isLending={isLending} />
                {!isLending && <DebitInterestTypeSelector rateType={rateType} onChange={setRateType} />}
                <RateFormWithoutFormik
                  {...(props as any)}
                  initialSetup
                  disableTierBandRadioGroup={isLending}
                  rateType={isLending ? LendingRateType.rateWithinLimit : rateType}
                />
                <Grid container item justifyContent="flex-end">
                  <Flexbox width="100%" direction="row" justifyContent="flex-end">
                    <Button
                      color={ColorType.BUTTON}
                      onClick={() => navigate(`/products/${productKey}`)}
                      style={{
                        marginRight: 16,
                      }}
                      aria-controls="main"
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      variant="contained"
                      color={ColorType.BUTTON}
                      type="submit"
                      disabled={isSubmitButtonDisabled()}
                    >
                      {t('Submit')}
                    </Button>
                  </Flexbox>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        }
        mainProps={{
          style: {
            marginBottom: 32,
          },
        }}
      />
    </ContentContainer>
  )
}

export default withFormikSimple<Props, DebitInterestFormValues>(SetupForm, {
  defaultValues: {
    fixedVariableType: FixedVariableType.FIXED,
    dayCount: DayCount.ACT365,
    tierBands: [],
    balanceCriteria: BalanceCriteria.ENDOFDAY,
    calculationMethod: RateTierBand.FLAT,
  },
  validationSchema: ({ t, currencyCode }: { t: TFunction; currencyCode: string }) =>
    debitInterestSetupValidationSchema(t, currencyCode),
})
