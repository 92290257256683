import React from 'react'
import { FieldProps } from 'formik'
import RadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup'
import { FormControlLabel, Radio } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'

export interface RadioGroupFieldData {
  [key: string]: { name: string | number; label?: string; disabled?: boolean } | string
}

export interface ResetFormValues {
  fieldName: string
  newValues: any
}

export interface RadioGroupFieldComplexProps {
  required?: boolean
  resetFieldValues?: ResetFormValues | ResetFormValues[]
  onChange?: (value: any) => void
  radioGroupProps?: Omit<MuiRadioGroupProps, 'value'>
  data: RadioGroupFieldData
}

type Props = FieldProps & RadioGroupFieldComplexProps & EnhancedFormikFieldProps

const RadioGroupFieldComplex = (props: Props) => {
  const {
    radioGroupProps,
    data,
    field,
    form,
    inputLabelProps,
    children,
    resetFieldValues,
    onChange,
    ...otherProps
  } = props

  const { t } = useTranslation()

  const setNewFormValues = (fieldName: string, newValues: any) => {
    form.setFieldTouched(fieldName, false, false)
    form.setFieldValue(fieldName, newValues)
  }

  return (
    <>
      <EnhancedFormikField {...props} inputLabelProps={inputLabelProps} required={false}>
        {() => (
          <RadioGroup
            {...otherProps}
            {...radioGroupProps}
            value={field.value || ''}
            name={field.name}
            onChange={field.onChange}
          >
            {Object.entries(data).map(([key, item]) => (
              <FormControlLabel
                key={key}
                value={typeof item === 'string' ? item : item.name}
                control={<Radio />}
                label={typeof item !== 'string' && item.label ? t(item.label) : t(key)}
                onClick={() => {
                  field.onChange(typeof item === 'string' ? item : item.name)
                  if (resetFieldValues) {
                    if (isArray(resetFieldValues)) {
                      resetFieldValues.forEach((i) => setNewFormValues(i.fieldName, i.newValues))
                    } else {
                      setNewFormValues(resetFieldValues.fieldName, resetFieldValues.newValues)
                    }
                  }
                  if (onChange) {
                    onChange(typeof item === 'string' ? item : item.name)
                  }
                }}
                disabled={typeof item === 'object' ? item.disabled : false}
              />
            ))}
            {children}
          </RadioGroup>
        )}
      </EnhancedFormikField>
    </>
  )
}
export default RadioGroupFieldComplex
