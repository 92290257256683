import React from 'react'
import { Grid, Tab, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormatListBulleted, Search } from '@material-ui/icons'
import styled from 'styled-components/macro'
import { TabList } from '@material-ui/lab'
import { stopPropagation } from 'utils/form.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

const IconTab = ({ title, Icon, ...props }: Record<any, any>) => (
  <Tab
    {...props}
    label={
      <div>
        <Icon style={{ verticalAlign: 'middle' }} />
        <Typography
          css={`
            padding: 0 20px;
            font-size: 13px;
            font-weight: 500;
          `}
        >
          {title}
        </Typography>
      </div>
    }
  />
)

const StyledIconTab = styled(IconTab)`
  font-size: 13px;
  font-weight: 500;
  .MuiTab-wrapper div {
    align-items: center;
    display: flex;
    svg {
      padding-right: 5px;
    }
  }
`

export function TransactionTabs(props: { searchPage: boolean }) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { showInfographicModal } = useModal()

  const handleTabChange = (e: React.ChangeEvent<{}>, goto: string) => {
    if (goto === 'search' && !props.searchPage) {
      navigate(`./search`)
    } else if (goto === 'listing') {
      navigate(pathname.replace('/search', ''))
    }
  }

  return (
    <ContentContainer>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <TabList
            onChange={handleTabChange}
            aria-label="transaction search selector"
            indicatorColor="primary"
            textColor="primary"
            selectionFollowsFocus
            aria-controls="main"
          >
            <StyledIconTab title={t('Transactions')} Icon={FormatListBulleted} value="listing" />
            <StyledIconTab title={t('Search')} Icon={Search} value="search" />
          </TabList>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" spacing={2} alignItems="flex-end">
            <Grid item>
              <InfoIconButton
                key="more-about-icon"
                ariaProps={{
                  'aria-label': `${t('More about suspense accounts')}`,
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                }}
                onClick={(e: React.MouseEvent) => {
                  stopPropagation(e)
                  showInfographicModal(HelpModalType.SUSPENSE_ACCOUNTS)
                }}
                fontSize="medium"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentContainer>
  )
}
