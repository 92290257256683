import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConfigRow from './components/ConfigRow'
import ColleagueOutBoundLimits from 'pages/features/PaymentLimits/components/shared/ColleagueOutBoundLimits'
import FromAnySourceRow from 'pages/features/PaymentLimits/components/shared/FromAnySourceRow'
import TransferToPartyRow from 'pages/features/PaymentLimits/components/shared/TransferToPartyRow'
import TransferToSelfRow from 'pages/features/PaymentLimits/components/shared/TransferToSelfRow'
import { PaymentLimitsResponse } from 'store/products/typings/paymentLimits'
import { InfoPanel, Line, ReviewSubheader } from 'dls'

interface OwnProps {
  paymentLimits: PaymentLimitsResponse
  currencyCode: string
}

const ViewPaymentLimits = ({
  paymentLimits: { config, paymentLimits },
  currencyCode,
}: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <Grid container direction="column" spacing={3} style={{ marginTop: 0 }}>
      <Grid item>
        <InfoPanel
          color="info"
          body={t('Please note – payment schemes have their own limits that will override limits set here')}
        />
      </Grid>
      <Grid item>
        <InfoPanel color="info" body={t('The card limits feature will be coming soon')} />
      </Grid>

      <ConfigRow config={config} />

      <Grid item>
        <ReviewSubheader title={t('OUTBOUND')} />
      </Grid>
      <Grid item>
        <Line type="light" />
      </Grid>
      <Grid item>
        <TransferToSelfRow paymentLimits={paymentLimits} currencyCode={currencyCode} inline />
      </Grid>
      <Grid item>
        <Line type="light" />
      </Grid>
      <Grid item>
        <TransferToPartyRow paymentLimits={paymentLimits} currencyCode={currencyCode} inline />
      </Grid>
      <Grid item>
        <Line type="light" />
      </Grid>
      <Grid item>
        <ColleagueOutBoundLimits inline />
      </Grid>
      <Grid item>
        <Line type="strong" />
      </Grid>
      <Grid item>
        <ReviewSubheader title={t('INBOUND')} />
      </Grid>
      <Grid item>
        <Line type="light" />
      </Grid>
      <Grid item>
        <FromAnySourceRow paymentLimits={paymentLimits} currencyCode={currencyCode} inline />
      </Grid>
    </Grid>
  )
}

export default ViewPaymentLimits
