import React from 'react'
import { isEmpty, isEqual } from 'lodash'
import { Grid, Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import DataList from './DataList'
import { DataPickerRow } from 'dls/shared/types'
import { StyledResetOL } from 'dls/shared/styled'

const StyledGridForList = styled(Grid)`
  border-radius: 0 0 8px 8px;
  overflow: scroll;
  height: calc(100vh - 370px);
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      height: calc(100vh - 445px);
    }
  `};
  border: 1px solid ${(props: any) => `${props.theme.palette.background.medium}`};
`

const StyledWhiteGrid = styled(Grid)`
  background-color: ${(props: any) => props.theme.palette.background.paper};
  text-align: left;
`

interface Props {
  dataRows: DataPickerRow[]
  formChanged: () => void
}

export const DataListsWrapperComponent = ({ dataRows, formChanged }: Props) =>
  isEmpty(dataRows) ? (
    <StyledResetOL role="region" aria-live="polite">
      <li>No Data</li>
    </StyledResetOL>
  ) : (
    <StyledGridForList container direction="row">
      <StyledWhiteGrid item xs={12}>
        <Box className="datapicker-list-container">
          <form>
            <DataList dataPickerRowsData={dataRows} depth={0} path="" formChanged={formChanged} />
          </form>
        </Box>
      </StyledWhiteGrid>
    </StyledGridForList>
  )

export const DataListsWrapper = React.memo(DataListsWrapperComponent, isEqual)
