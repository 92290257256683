import styled from 'styled-components/macro'
import Avatar from '@material-ui/core/Avatar'
import React from 'react'
import { getBankInterestRateStatusIcon, getBankInterestRateStatusColor } from './utils'
import { BankInterestRateStatus } from 'store/referenceData/types'
// eslint-disable-next-line import/no-cycle
import { Caption } from 'dls/atoms/Typography'
import { extraPadding } from 'dls/shared/styleVar'

export const StatusContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 168px;
`
export const DateTypography = styled(Caption)`
  font-size: 11px;
  color: ${(props: any) => props.theme.palette.text.secondary};
`
export const StyledAvatar = styled((props) => (
  <Avatar {...props}>{getBankInterestRateStatusIcon(props.status)}</Avatar>
))<{ status: BankInterestRateStatus }>`
  width: ${extraPadding};
  height: ${extraPadding};
  margin-right: 8px;
  background-color: ${(props) => getBankInterestRateStatusColor(props.status, props.theme)};
`
