import React, { useContext } from 'react'
import styled, { keyframes, ThemeContext } from 'styled-components/macro'

const Icon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``

const animation = keyframes`
 0% {
    stroke-dashoffset: 640;
  }
  10% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
`

const Svg = styled(Icon)`
  width: 100px;
  margin-bottom: 30px;
  stroke-dasharray: 640;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${animation} 2s 1;
`

export const AnimatedTick = () => {
  const themeContext = useContext(ThemeContext)
  return (
    <Svg viewBox="0 0 245 173">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polyline
          stroke={themeContext.palette.success.main}
          strokeWidth="25"
          points="5.640625 83.7607422 83.2539062 161.663086 238.97168 6.11328125"
        />
      </g>
    </Svg>
  )
}
