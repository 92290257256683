import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  getCreditInterestToPayload,
  getDefaultCalcMethod,
  getTypeOfIndexRate,
  toCreditInterestFormValues,
} from '../utils/creditInterestTransformers'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import TaxRulesSelectors from 'store/withholdingTax/selectors'
import ReferenceDataActions from 'store/referenceData/actions'
import TaxRulesActions from 'store/withholdingTax/actions'
import { selectConfig } from 'store/tenant-config/selectors'
import { hasTaxRules, hasWithholdingTaxRule } from 'utils/common.util'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import { BalanceCriteria, ExtendedProduct, Feature, ProductKey } from 'store/products/types'
import ProductsActions from 'store/products/actions'

const useInterestRates = (productKey: ProductKey, product?: ExtendedProduct) => {
  const dispatch = useDispatch()
  const selectInterestRatesLoading = useSelector(ReferenceDataSelectors.selectLoading)
  const selectTaxRulesIsLoading = useSelector(TaxRulesSelectors.isLoading)
  const selectTaxRules = useSelector(TaxRulesSelectors.taxRules)
  const tenantConfig = useSelector(selectConfig)
  const bankInterestRates = useSelector(ReferenceDataSelectors.selectAllBankInterestRate)
  const isReferenceDataEnabled = (tenantConfig && tenantConfig.features.index_rates) || false
  const enableWithholdingTaxRule = useMemo(() => tenantConfig && hasWithholdingTaxRule(tenantConfig), [tenantConfig])
  const availableHoldingTaxRules = useMemo(() => hasTaxRules(selectTaxRules), [selectTaxRules])

  useEffect(() => {
    if (isReferenceDataEnabled && isEmpty(bankInterestRates)) {
      dispatch(ReferenceDataActions.fetchBankInterestRate())
    }

    if (enableWithholdingTaxRule && isEmpty(selectTaxRules)) {
      dispatch(TaxRulesActions.getAllTaxRules())
    }
  }, [dispatch, isReferenceDataEnabled, enableWithholdingTaxRule, product])

  const setCreditInterest = (formValues: any) =>
    dispatch(
      ProductsActions.setCreditInterest({
        ...getCreditInterestToPayload(formValues),
        productKey,
      } as any)
    )

  const initialValues = useMemo(() => {
    if (product && !isEmpty(product)) {
      const formValuesFromApi = toCreditInterestFormValues(product)
      return {
        ...formValuesFromApi,
        interestTierBand:
          !!formValuesFromApi &&
          !!formValuesFromApi.interestTierBand &&
          formValuesFromApi.interestTierBand[0].startRange === 0
            ? formValuesFromApi.interestTierBand
            : [{}],
        tierBandCalcMethod: getDefaultCalcMethod(product),
        balanceCriteria: BalanceCriteria.ENDOFDAY,
        typeOfIndexRate: getTypeOfIndexRate(product),
        showWithholdingTax:
          enableWithholdingTaxRule &&
          !!formValuesFromApi &&
          !!formValuesFromApi.withholdingTax &&
          !!formValuesFromApi.withholdingTax.taxRules &&
          formValuesFromApi.withholdingTax.taxRules.length > 0,
        availableHoldingTaxRules,
      }
    }
    return {}
  }, [product, availableHoldingTaxRules, enableWithholdingTaxRule])

  if (isEmpty(product)) {
    return {}
  }

  return {
    isLoading: selectTaxRulesIsLoading || selectInterestRatesLoading,
    isNotAllowed: !isFeatureAllowedByConfig(tenantConfig, Feature.CREDITINTEREST),
    setCreditInterest,
    initialValues,
    bankInterestRates,
    enableWithholdingTaxRule,
    isReferenceDataEnabled,
    selectTaxRules,
  }
}

export default useInterestRates
