import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { kebabCase } from 'lodash'
import { getNewRateEffectiveDate } from '../../hooks/utils'
import WithholdingTaxRuleSidePanel from '../../shared/WithholdingTaxRuleSidePanel'
import useTaxRule from '../../hooks/useTaxRule'
import AddFutureRateForm from './AddTaxRuleForm'
import { Loader, TemplateFeatureForm, H1, Body2 } from 'components'
import { selectTenantCurrencyCode } from 'store/tenant-config/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export function AddTaxRulePage() {
  const { t } = useTranslation()
  const { ruleId } = useParams()
  const navigate = useNavigate()

  const { selectedTaxRule, isLoading, getTaxRule, updateTaxRule } = useTaxRule()

  const currencyCode = useSelector(selectTenantCurrencyCode)

  const allRulesPageUrl = '/reference-data/withholding-tax'
  const withholdingRulePageUrl = `${allRulesPageUrl}/${selectedTaxRule?.ruleId}`

  const isValidRule =
    !!selectedTaxRule &&
    !!selectedTaxRule.taxRules &&
    !!selectedTaxRule.taxRules[0] &&
    selectedTaxRule.taxRules[0].withholdTax === true

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Rules for withholding tax'), path: allRulesPageUrl },
      { title: `${selectedTaxRule?.name}`, path: withholdingRulePageUrl },
      { title: t('Add future rate'), path: '' },
    ],
    trigger: !!selectedTaxRule,
  })

  useEffect(() => {
    if (ruleId) {
      getTaxRule(ruleId)
    }
  }, [ruleId])

  useEffect(() => {
    if (!isLoading && !!selectedTaxRule && !isValidRule) {
      navigate(allRulesPageUrl)
    }
  }, [isLoading, selectedTaxRule, isValidRule])

  if (isLoading) {
    return <Loader />
  }

  if (!selectedTaxRule) {
    return null
  }

  return (
    <ContentContainer data-test-id={kebabCase(`Rule for withholding tax-form-container`)}>
      <TemplateFeatureForm
        header={
          <>
            <H1 gutterBottom>{t('Add future rate')}</H1>
            <Body2 gutterBottom>{t('Here you can set a future rate for the tax rule')}</Body2>
          </>
        }
        main={
          <AddFutureRateForm
            currencyCode={currencyCode}
            withholdingRule={selectedTaxRule}
            initialValues={{
              withholdTax: selectedTaxRule?.taxRules?.[0]?.withholdTax,
              facts: selectedTaxRule?.taxRules?.[0]?.facts,
              effectiveDate: getNewRateEffectiveDate(selectedTaxRule),
            }}
            onSubmit={(values) => updateTaxRule({ ruleId, ...values }, 'add')}
            onCancelClick={() => navigate(withholdingRulePageUrl)}
          />
        }
        aside={<WithholdingTaxRuleSidePanel />}
      />
    </ContentContainer>
  )
}

export default AddTaxRulePage
