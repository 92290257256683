/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowForward, HelpOutline } from '@material-ui/icons'
import { Tooltip, Link, TableBody, TableHead } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useNavigate } from 'react-router'
import { getProductVersions } from '../../ReferenceData/WithholdingTaxRuleCatalog/view/products/ViewProductsBody'
import EntityCardFooterLink from 'pages/ReferenceData/_shared/EntityCardFooterLink'
import {
  StyledError,
  StyledSpan,
  ConditionalTableCell,
  BMTable,
} from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import EntitySectionCard from 'pages/Layout/EntityReviewPageLayout/EntitySectionCard'
import { Loader, VersionStatusBadge, Body1, Body2 } from 'components'
import { stopPropagation } from 'utils/form.utils'
import { Flexbox } from 'pages/Layout/Styled'
import { InfoPanel } from 'dls'
import { EntityBadgeProduct } from 'dls/atoms'
import { LinkWithText } from 'dls/molecules/Buttons'
import { ProductVersion } from 'store/products/types'

interface Props {
  loading: boolean
  error: boolean
  products: object
  getProducts: (id: string) => void
  entityId: string
  entityName: string
  productsPageLink?: string
  infoPanelText?: string
  tooltipText?: string
  disableCardBorder?: boolean
  shouldLinkProducts?: boolean
}

const ProductUsageSectionCard = ({
  loading,
  error,
  products,
  getProducts,
  entityId,
  entityName,
  productsPageLink,
  infoPanelText,
  tooltipText,
  disableCardBorder,
  shouldLinkProducts,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    getProducts(entityId)
  }, [])

  const existingProducts: ProductVersion[] = (products && products[entityId]) || []

  const renderTooltip = (): JSX.Element | undefined => {
    const title = t(`${tooltipText}`)
    return tooltipText ? (
      <Tooltip title={title} aria-label={title} placement="right">
        <HelpOutline onClick={(e) => stopPropagation(e)} />
      </Tooltip>
    ) : undefined
  }

  const renderTitle = (): JSX.Element => {
    if (loading) {
      return <Body1>{t('Looking for products...')}</Body1>
    }

    if (error) {
      return (
        <Flexbox direction="row">
          <Body1 color="error">{t('Error getting products')}</Body1>
          <Link
            color="secondary"
            aria-label={t('try again')}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              getProducts(entityId)
            }}
          >
            <StyledSpan style={{ cursor: 'pointer', paddingLeft: 12 }}>{t('try again')}</StyledSpan>
          </Link>
        </Flexbox>
      )
    }

    return (
      <>
        {t(
          `${existingProducts.length || 'No'} product${
            existingProducts.length !== 1 ? 's' : ''
          } using this {{entityName}}`,
          { entityName }
        )}
      </>
    )
  }

  const renderHeaderIcon = (): React.ReactElement => {
    if (loading) {
      return <Loader size={20} color="inherit" shrink />
    }

    if (error) {
      return <StyledError color="error" />
    }

    return <EntityBadgeProduct style={{ marginTop: -4 }} />
  }

  return (
    <EntitySectionCard
      headline={t('Products using this {{entityName}}', { entityName })}
      title={renderTitle()}
      headerIcon={renderHeaderIcon()}
      headerAction={renderTooltip()}
      accordionDisabled={!existingProducts.length}
      fixedBottomContent={
        existingProducts.length > 10 && productsPageLink ? (
          <EntityCardFooterLink label={t('SEE ALL')} Icon={ArrowForward} href={productsPageLink} />
        ) : undefined
      }
      disableCardBorder={!!disableCardBorder}
    >
      {!!existingProducts.length && (
        <>
          {infoPanelText && <InfoPanel body={t(infoPanelText)} css="marginBottom: 8px" />}
          <BMTable>
            <TableHead>
              <TableCell width="50%">
                <Body2 style={{ fontWeight: 'bold' }} variantMapping={{ body2: 'span' }}>
                  {t('Product name')}
                </Body2>
              </TableCell>
              <TableCell width="50%">
                <Body2 style={{ fontWeight: 'bold' }} variantMapping={{ body2: 'span' }}>
                  {t('Version, status and name')}
                </Body2>
              </TableCell>
            </TableHead>
            <TableBody>
              {existingProducts.length > 0 &&
                existingProducts
                  .slice()
                  .sort((productA, productB) => {
                    if (!productA.versions || !productB.versions) return 0
                    if (productA.versions?.length === 0 || productB.versions?.length === 0) return 0
                    if (productA.versions[0].productName > productB.versions[0].productName) {
                      return 1
                    }
                    return -1
                  })
                  .slice(0, 10)
                  .map((product, productIndex) => {
                    if (!product) return null
                    const productVersions = getProductVersions(product.versions || [])
                    const shouldShowName = productVersions.length > 1
                    const productNameText = (
                      <>
                        {productVersions[0]?.productName} {shouldShowName && `(${t('latest name')})`}
                      </>
                    )

                    return (
                      <React.Fragment key={productIndex}>
                        <TableRow>
                          <ConditionalTableCell
                            rowSpan={productVersions.length + 1}
                            showBorder={false}
                            $topPaddingThick
                          >
                            {shouldLinkProducts ? (
                              <LinkWithText
                                endIcon={null}
                                onClick={() => navigate(`/products/${productVersions[0]?.productKey}`)}
                              >
                                {productNameText}
                              </LinkWithText>
                            ) : (
                              <Body2>{productNameText}</Body2>
                            )}
                          </ConditionalTableCell>
                        </TableRow>
                        {productVersions.map((item, index) => (
                          <TableRow key={`${item.version}${index}`}>
                            <ConditionalTableCell
                              showBorder={false}
                              $topPaddingThick={index === 0}
                              $bottomPaddingThick={false}
                            >
                              <VersionStatusBadge
                                status={item.status}
                                date={item.effectiveDate}
                                version={item.version}
                                name={shouldShowName ? item.productName : undefined}
                              />
                            </ConditionalTableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )
                  })}
            </TableBody>
          </BMTable>
        </>
      )}
    </EntitySectionCard>
  )
}

export default ProductUsageSectionCard
