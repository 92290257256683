import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid } from '@material-ui/core'
import { Block, Done } from '@material-ui/icons'
import styled from 'styled-components/macro'
import { isArray, orderBy, upperFirst } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { operators, TypeOptions } from './constants'
import { ConfirmModalDeprecated, useDialogs } from 'components'
import { TransactionExceptionType, TransactionRuleAccess, TransactionRuleItem } from 'store/products/types'
import ProductActions from 'store/products/actions'
import { DeleteTransactionRulesImg } from 'assets'
import { DeleteIconButton, DesignIconButton, ReviewRows } from 'dls'

export const StyledWrapper = styled.div`
  width: 100%;
  margin-top: -10px;
`
export const MainDivider = styled(Divider)`
  margin-bottom: 0;
  background-color: ${(props: any) => `${props.theme.palette.text.primary}`};
`
const ConditionParagraph = styled.p`
  line-height: 1.2;
`
const ConditionLi = styled.li`
  line-height: 1.6;
`

interface OwnProps {
  item: TransactionRuleItem
  transactionExceptionType: TransactionExceptionType
  editable: boolean
}
export const getRuleValue = (t: any, type: any, value: any) => {
  if (type === 'TRANSACTION_CODE') {
    const tokens = `${value}`.split('.')
    const obj = TypeOptions[type].find(
      ({ domain, family, subfamily }: any) =>
        domain.code === tokens[0] && family.code === tokens[1] && subfamily.code === tokens[2]
    )
    if (obj) return t(obj.description)
  } else {
    const optionType = TypeOptions[type]
    const obj = optionType ? optionType.find(({ code }: any) => code === value) : {}
    if (obj) return t(obj.description)
    return t(value)
  }
  return t(value)
}

export const RuleDefinitions = ({ item, transactionExceptionType, editable }: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const { isDialogOpen, onOpenDialog, onCloseDialog } = useDialogs()
  const [deleteRuleKey, setDeleteRuleKey] = useState('')
  const dispatch = useDispatch()
  const ruleDefinitions: any[] = []

  const deleteRuleHandlerFactory = (ruleKey: string) => () => {
    setDeleteRuleKey(ruleKey)
    onOpenDialog()
  }

  if (!item?.rules?.length) {
    return (
      <ReviewRows
        definitionsList={[
          {
            definitions: [
              {
                term: t('Exceptions'),
                definitions: [t('None')],
              },
            ],
          },
        ]}
      />
    )
  }

  orderBy(item.rules, ['order'], ['asc']).forEach((rule, ruleIndex, arr) => {
    ruleDefinitions.push({
      ...(ruleIndex < arr.length - 1 && { groupSeparator: 'light' }),
      definitions: [
        {
          term: t('Name'),
          definitions: [rule.name || t('N/A')],
        },
        {
          term: t('If'),
          definitions: [
            rule.matchCriteria.map((i, matchIndex) => (
              <>
                <ConditionParagraph key={matchIndex}>
                  {matchIndex > 0
                    ? `${t('And')} ${t(i.type).toLowerCase()} is ${t(operators[i.operator]).toLowerCase()} `
                    : upperFirst(`${t(i.type).toLowerCase()} is ${t(operators[i.operator]).toLowerCase()} `)}
                  {isArray(i.values) && i.values.length === 1 && (
                    <>{i.values && i.values[0] ? getRuleValue(t, i.type, i.values[0]) : t('N/A')}</>
                  )}
                </ConditionParagraph>
                {isArray(i.values) && i.values.length > 1 && (
                  <ul>
                    {i.values.map((valueItem, valueIndex) => (
                      <ConditionLi key={valueIndex}>{getRuleValue(t, i.type, valueItem)}</ConditionLi>
                    ))}
                  </ul>
                )}
              </>
            )),
          ],
        },
        {
          term: t('Then'),
          definitions: [
            <Box key={rule.key} display="flex" justifyContent="space-between">
              {rule.access === TransactionRuleAccess.DENY ? t('Block transaction') : t('Allow transaction')}
              {editable && (
                <Box mr={2} mt={-2}>
                  <DeleteIconButton aria-label="delete" onClick={deleteRuleHandlerFactory(rule?.key || '')} />
                  <DesignIconButton
                    aria-label="edit"
                    component={RouterLink}
                    to={`./features/transaction-rules-${transactionExceptionType}-exceptions/${ruleIndex}`}
                  />
                </Box>
              )}
            </Box>,
          ],
        },
      ],
    })
  })

  return (
    <Box>
      <ReviewRows definitionsList={ruleDefinitions} />
      <ConfirmModalDeprecated
        image={{ src: DeleteTransactionRulesImg, alt: t('Delete Icon') }}
        onConfirmClick={{
          action: () => {
            onCloseDialog()
            dispatch(ProductActions.deleteTransactionRules(deleteRuleKey))
          },
          title: t('Confirm'),
        }}
        onCancelClick={onCloseDialog}
        open={isDialogOpen}
        title={t('You’re deleting an exception rule')}
        subtitle={t('Are you sure? This can’t be undone.')}
        imageStyle={{
          height: '140px',
          marginBottom: '24px',
        }}
        titleStyle={{
          marginBottom: '8px',
        }}
      />
    </Box>
  )
}

export const AccessValue = ({ access }: { access: TransactionRuleAccess }): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{ position: 'relative', bottom: -6 }}
    >
      <Grid item>
        {access === TransactionRuleAccess.DENY ? (
          <Block style={{ marginRight: 8 }} aria-label={t('Block transactions')} />
        ) : (
          <Done style={{ marginRight: 8 }} aria-label={t('Allow transactions')} />
        )}
      </Grid>
      <Grid item>{access === TransactionRuleAccess.DENY ? t('Block transactions') : t('Allow transactions')}</Grid>
    </Grid>
  )
}
