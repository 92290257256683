import { createSelector } from 'reselect'
import { Template } from './types'
import { RootState } from 'store'
import { StatementType } from 'store/products/types'

const selectTemplatesState = (state: RootState) => state.statement

const selectTemplates = createSelector(selectTemplatesState, (state) => state.templates || {})

const selectTemplatesLoading = createSelector(selectTemplatesState, (state) => state.templatesLoading)

const selectTemplatesError = createSelector(selectTemplatesState, (state) => state.templatesError || null)

const selectInterestTemplateFromConfig = createSelector(
  selectTemplatesState,
  (_: any, configId: string) => configId,
  (statementState, configId) =>
    statementState?.templates?.[StatementType.INTEREST_STATEMENT]?.find(
      (template: Template) => template.templateGroupId === configId
    )
)

export default {
  selectTemplates,
  selectTemplatesLoading,
  selectTemplatesError,
  selectInterestTemplateFromConfig,
}
