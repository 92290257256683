import React from 'react'
import { FastField } from 'formik'
import { Grid, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Yip } from 'utils/form.utils'
import { SchemeName } from 'store/products/types'
import { SelectFieldData } from 'components/form/SelectFieldComplex'
import { FieldAmount } from 'components'

const selectDataSchemeName: SelectFieldData = {}
Object.entries(SchemeName).forEach(([key, value]) => {
  selectDataSchemeName[key] = { name: value }
})

export const SchemeLimitElement = ({
  name,
  description,
  currencyOfProduct,
}: {
  name: string
  description?: string
  currencyOfProduct?: string | null
}) => {
  const { t } = useTranslation()
  return (
    <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="flex-start">
      {description && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom variantMapping={{ subtitle2: 'div' }}>
            {description}
          </Typography>
        </Grid>
      )}
      <Grid item xs={6}>
        <FastField
          name={`${name}.minimumAmount`}
          required
          label={t('Minimum amount')}
          validate={Yip(Yup.number().typeError(t('This field must be a number')).required().min(0))}
          component={FieldAmount}
          currencyCode={currencyOfProduct}
          fullWidth
          placeholder={t('Enter amount')}
        />
      </Grid>
      <Grid item xs={6}>
        <FastField
          name={`${name}.maximumAmount`}
          label={t('Maximum amount (optional)')}
          validate={Yip(
            Yup.number()
              .typeError(t('This field must be a number'))
              .min(0)
              .nullable()
              .transform((value: string, originalValue: string) => (originalValue.trim() === '' ? null : value))
          )}
          component={FieldAmount}
          currencyCode={currencyOfProduct}
          fullWidth
          placeholder={t('Enter amount')}
        />
      </Grid>
    </Grid>
  )
}
