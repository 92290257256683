import * as React from 'react'
import { connect, useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { selectConfig, selectFeatures } from 'store/tenant-config/selectors'
import { selectUserRole } from 'store/user/selectors'
import { hasUserRightForPath } from 'utils/common.util'

interface IfProps {
  on: any
}

export const If: React.SFC<IfProps> = ({ on, children }) => (on ? children : null) as React.ReactElement | null

const SwitchContext = React.createContext<string | undefined>(undefined)

interface SwitchProps<T extends string | undefined> extends React.PropsWithChildren<{ on: T }> {}

export const SwitchOn = <T extends string | undefined>({ on, children }: SwitchProps<T>) => (
  <SwitchContext.Provider value={on}>{children}</SwitchContext.Provider>
)

interface CaseProps<T extends string | undefined>
  extends React.PropsWithChildren<{
    on: T
  }> {}

export const Case = <T extends string | undefined>({ on, children }: CaseProps<T>) => (
  <SwitchContext.Consumer>{(switchOnValue) => <If on={switchOnValue === on}>{children}</If>}</SwitchContext.Consumer>
)

export const NoRender = (props: { msg: string; children: React.ReactElement | React.ReactElement[] }) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.log(`Not rendering '${props.msg}'. Remove 'NoRender' tag to show`)
  return null
}

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type FeatureFlagProps = React.PropsWithChildren<{ name: string; children: React.ReactNode }> &
  ReturnType<typeof mapStateToProps>

export const FeatureFlagComponent = (props: FeatureFlagProps) => {
  const { name, tenantConfig, children } = props
  if (!tenantConfig) return null
  const { features } = tenantConfig
  if (!features || features[name] === undefined) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(`The feature ${name} is not declared in the 'tenantConfig'!`)
    }
    return null
  }
  return <If on={features[name]}>{children}</If>
}

type RoleCheckComponentProps = React.PropsWithChildren<{ path?: string; children?: React.ReactNode }>

export const RoleCheckComponent = ({ path, children }: RoleCheckComponentProps) => {
  const userRole = useSelector(selectUserRole)
  const features = useSelector(selectFeatures)

  return hasUserRightForPath(userRole, path, features.role_check) ? <>{children}</> : null
}

export const FeatureFlag = connect(mapStateToProps)(FeatureFlagComponent)
