import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import { Grid, Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { PaymentScheduleTerm } from 'store/products/types'
import { H2, TextFieldComplex, SelectFieldComplex } from 'components'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'

interface OwnProps {
  currencyCode: string
  onTermChange: () => void
  termType: PaymentScheduleTerm
}

const CurrencyBox = styled(Box)`
  position: relative;
  top: 27px;
`

const AboutAndTermForm = ({ currencyCode, onTermChange, termType }: OwnProps) => {
  const { t } = useTranslation()

  const CurrencySign = (
    <CurrencyBox>
      <CurrencySymbol typographyProps={{ style: { fontWeight: 500 } }} currencyCode={currencyCode} />
    </CurrencyBox>
  )

  const minimumTerm = termType === PaymentScheduleTerm.DAYS ? 7 : 1
  return (
    <>
      <Grid item>
        <H2 gutterBottom>{t('Amount and term')}</H2>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ marginBottom: 4 }}
        >
          <Box p={2} width="50%">
            <Field
              name="amount.minimum"
              label={t('Minimum amount')}
              type="number"
              component={TextFieldComplex}
              css={{ paddingLeft: '16px' }}
              leadingElement={CurrencySign}
              placeholder={t('Enter amount')}
              numberFormatProps={{
                thousandSeparator: true,
                decimalScale: 2,
              }}
              required
            />
          </Box>
          <Box p={2} width="50%">
            <Field
              name="amount.maximum"
              fullWidth
              label={t('Maximum amount')}
              type="number"
              component={TextFieldComplex}
              css={{ paddingLeft: '16px' }}
              leadingElement={CurrencySign}
              placeholder={t('Enter amount')}
              numberFormatProps={{
                thousandSeparator: true,
                decimalScale: 2,
              }}
              required
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ marginBottom: 4 }}
        >
          <Box p={2} width="33%">
            <Field
              name="term.type"
              fullWidth
              label={t('Term type')}
              placeholder={t('Please select')}
              data={PaymentScheduleTerm}
              component={SelectFieldComplex}
              selectProps={{ displayEmpty: true, style: { marginTop: 16 } }}
              inputLabelProps={{ shrink: true }}
              required
              onChange={onTermChange}
            />
          </Box>
          <Box p={2} width="33%">
            <Field
              name="term.minimum"
              label={t('Minimum term')}
              type="number"
              component={TextFieldComplex}
              placeholder={t('Enter term')}
              required
              inputProps={{ onChange: onTermChange, min: minimumTerm }}
            />
          </Box>
          <Box p={2} width="33%">
            <Field
              name="term.maximum"
              fullWidth
              label={t('Maximum term')}
              type="number"
              component={TextFieldComplex}
              placeholder={t('Enter term')}
              inputProps={{ min: '1' }}
              required
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AboutAndTermForm
