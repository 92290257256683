import { useDispatch, useSelector } from 'react-redux'
import { convertValuesToCardTransactionLimitPayload } from './transformers'
import { selectConfig } from 'store/tenant-config/selectors'
import { Feature, ProductKey } from 'store/products/types'
import { CardTransactionLimitsFormValues, CardTransactionLimitsURLValues } from 'store/products/typings/cardLimits'
import ProductsActions from 'store/products/actions'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'

const useCardTransactionLimits = ({ productKey, version }: { productKey: ProductKey; version: number }) => {
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectConfig)

  const submitCardTransactionLimit = (values: CardTransactionLimitsFormValues & CardTransactionLimitsURLValues) => {
    dispatch(
      ProductsActions.setCardTransactionLimit({
        ...convertValuesToCardTransactionLimitPayload(values),
        limitName: values.limitName || '',
        productKey,
        version,
      })
    )
  }

  const deleteCardTransactionLimit = ({ limitRuleId, limitName }: { limitRuleId: string; limitName: string }) => {
    dispatch(ProductsActions.deleteCardTransactionLimit({ limitRuleId, limitName, productKey, version }))
  }

  return {
    isNotAllowed: !isFeatureAllowedByConfig(tenantConfig, Feature.CARD_TRANSACTION_LIMITS),
    submitCardTransactionLimit,
    deleteCardTransactionLimit,
  }
}

export default useCardTransactionLimits
