import React, { useEffect } from 'react'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import VariableMarginForm from './variants/VariableMarginForm'
import StaticMarginForm from './variants/StaticMarginForm'
import IndependentRateForm from './variants/IndependentRateForm'
import { TypeOfIndexRate, ExtendedProduct, LendingRateFormValues } from 'store/products/types'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import ReferenceDataActions from 'store/referenceData/actions'

type Props = {
  product: ExtendedProduct
  productKey?: string
  selectedTypeOfIndexRate: TypeOfIndexRate
  disableTierBandRadioGroup?: boolean
}

const IndexRateForm = (props: Props & FormikProps<LendingRateFormValues>) => {
  const bankInterestRates = useSelector(ReferenceDataSelectors.selectAllBankInterestRate)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ReferenceDataActions.fetchBankInterestRate())
  }, [])

  const { selectedTypeOfIndexRate } = props
  switch (selectedTypeOfIndexRate) {
    case TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN:
      return (
        <VariableMarginForm
          {...props}
          selectTypeOfIndexRate={TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN}
          bankInterestRates={bankInterestRates}
        />
      )
    case TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN:
      return (
        <StaticMarginForm
          {...props}
          selectTypeOfIndexRate={TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN}
          bankInterestRates={bankInterestRates}
        />
      )
    case TypeOfIndexRate.INDEPENDENT_INDEXED_RATE:
      return (
        <IndependentRateForm
          {...props}
          selectTypeOfIndexRate={TypeOfIndexRate.INDEPENDENT_INDEXED_RATE}
          product={props.product}
          bankInterestRates={bankInterestRates}
        />
      )
    default:
      return null
  }
}

export default IndexRateForm
