import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Grid, Typography } from '@material-ui/core'
import { NotFoundImage } from 'assets'

const DivEmptyLanding = styled.div`
  height: 440px;
`
const MainZeroContainer = styled(Grid)`
  height: 100%;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <DivEmptyLanding id="main_not_found">
      <MainZeroContainer container direction="row" alignItems="center" justifyContent="center">
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <img src={NotFoundImage} alt="Page Not Found" />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
              <Grid item>
                <Typography variant="h6">{t('Page not found')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{t('Sorry the page you are looking for doesn’t exist.')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainZeroContainer>
    </DivEmptyLanding>
  )
}

export default NotFoundPage
