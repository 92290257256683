import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useSelectedPackage from '../hooks/useSelectedPackage'
import PackageSidebar from '../../Packages/CreatePackage/PackageSidebar'
import EditPackageForm from './EditPackageForm'
import { Loader } from 'components/'
import FormPage from 'components/FormPage/FormPage'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'

function EditPackagePage() {
  const { t } = useTranslation()
  const { productPackageId } = useParams()
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  if (!productPackageId) throw new Error('productPackageId not found')
  const { selectedPackage, isLoading, availableGroupedProducts, updateSelectedPack } = useSelectedPackage(
    productPackageId
  )

  if (isLoading || !selectedPackage) {
    return <Loader />
  }

  return (
    <FormPage
      Sidebar={PackageSidebar}
      title={t('Edit package')}
      subheading={t(
        'Here you can choose the products that you want to included in the package. Scheduled products will only be available in the package after the effective date and time has been reached.'
      )}
      breadcrumbs={[
        { title: t('{{tenantName}} packages', { tenantName: currentTenant?.name }), path: `packages/` },
        { title: selectedPackage.productPackageName, path: `packages/${productPackageId}` },
        { title: t('Edit Package'), path: `` },
      ]}
      containerTestId="package-page"
      formTestId="package-form"
      contentPaperStyle={{
        padding: 24,
        paddingTop: 29,
        overflow: 'auto',
      }}
    >
      <EditPackageForm
        selectedPackage={selectedPackage}
        availableProducts={availableGroupedProducts}
        onSubmit={updateSelectedPack}
        initialValues={{
          ...selectedPackage,
          products:
            selectedPackage && selectedPackage.products ? selectedPackage.products.map((p) => p.productKey) : [],
          nonJoinedProducts: [],
        }}
        t={t}
      />
    </FormPage>
  )
}

export default EditPackagePage
