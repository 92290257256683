import React, { CSSProperties } from 'react'
import styled from 'styled-components/macro'

export const StyledOption = styled('option')`
  padding: 8px;
`

interface OptionProps {
  value?: string | number
  disabled?: boolean
  selected?: boolean
  children?: string | number
  ariaProps?: Record<string, string | boolean>
  className?: string
  style?: CSSProperties
}

export const Option = (props: OptionProps) => <StyledOption data-dls="atom-option" {...props} />

export default { Option }
