import React from 'react'
import { useTranslation } from 'react-i18next'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import { useTheme } from '@material-ui/core'
import StyledIconWrapper from './Styled/StyledIconWrapper'

const ProductCreatorsMenuItem = ({ isActive }: { isActive: boolean }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const inActiveColor = theme.palette.text.secondary
  return (
    <div>
      <StyledIconWrapper>
        <CardTravelIcon
          aria-hidden="true"
          style={{ verticalAlign: 'bottom', color: !isActive ? inActiveColor : 'inherit' }}
        />
      </StyledIconWrapper>
      {t('Product creator')}
    </div>
  )
}

export default ProductCreatorsMenuItem
