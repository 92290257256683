import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import FeatureTiles from './FeatureTiles'
import { slug, getCustomInitialFeatureRoute } from 'utils/common.util'
import { ExtendedProduct } from 'store/products/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  product: ExtendedProduct
  openFeatureDialog?: boolean
  onClose?: () => void
}

const StyledDialog = styled(Dialog)`
  & .muidialogpaper {
    max-width: 624px;
  }
`

const CreateFeatureModal = (props: Props): React.ReactElement => {
  const { product, openFeatureDialog, onClose } = props
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [selectedFeature, setSelectedFeature] = useState<string | null>(null)

  const handleClose = (featureName?: string | null) => () => {
    if (featureName) {
      navigate(`/products/${product.productKey}/features/${slug(getCustomInitialFeatureRoute(featureName))}`)
    } else {
      setOpen(false)
      if (onClose) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (!open) {
      setSelectedFeature(null)
    }
    if (openFeatureDialog) {
      setOpen(openFeatureDialog)
    }
  }, [openFeatureDialog])

  const { t } = useTranslation()
  return (
    <>
      <StyledDialog
        open={open}
        scroll="paper"
        onClose={handleClose()}
        TransitionComponent={Fade}
        fullWidth
        maxWidth="md"
        classes={{ paper: 'muidialogpaper' }}
      >
        <DialogTitle>{t('Add feature')}</DialogTitle>
        <DialogContent>
          <DialogContentText
            css={`
              overflow-y: -webkit-paged-x;
            `}
          >
            {t('Which feature would you like to add?')}
          </DialogContentText>
          <FeatureTiles product={product} setSelected={setSelectedFeature} selectedFeature={selectedFeature} />
        </DialogContent>
        <DialogActions css="padding: 24px !important;">
          <Button color={ColorType.BUTTON} onClick={handleClose()} aria-controls="main">
            {t('Cancel')}
          </Button>
          <Button
            css="margin-left:16px !important"
            variant="contained"
            color={ColorType.BUTTON}
            disabled={!selectedFeature}
            onClick={handleClose(selectedFeature)}
          >
            {t('Add')}
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  )
}

export default CreateFeatureModal
