import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import User from './User'
import { selectLoading, selectUserByKey } from 'store/user/selectors'
import UserActions from 'store/user/actions'
import { Loader, ZeroStateLandingPage } from 'components'
import { NoUser } from 'assets'
import { RootState } from 'store/types'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const UserPage = () => {
  const dispatch = useDispatch()
  const { userKey = '' } = useParams()
  const isLoading = useSelector(selectLoading)
  const selectedUser = useSelector((state: RootState) => selectUserByKey(state, userKey))
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)
  const { t } = useTranslation()

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('{{tenantName}} users', { tenantName: currentTenant?.name }), path: 'users/' },
      {
        title: `${selectedUser?.profile?.firstName} ${selectedUser?.profile?.lastName}`,
        path: `user/${userKey}`,
      },
    ],
    trigger: !!selectedUser,
  })

  useEffect(() => {
    dispatch(UserActions.getUserDetails(`${userKey}`))
  }, [userKey, UserActions.getUserDetails, dispatch])

  if (isLoading && !selectedUser) return <Loader />

  if (!selectedUser)
    return <ZeroStateLandingPage image={NoUser} title={t('No user information found')} description={t('')} />

  return <User selectedUser={selectedUser} />
}

export default UserPage
