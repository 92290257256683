import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'
import { TenXIcon } from 'assets'

interface IconProps extends SvgIconProps {
  disabled: boolean
}
const TenX = (props: IconProps) => <SvgIcon component={TenXIcon} {...props} />

export default TenX
