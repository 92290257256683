import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.openAssigneeModal.type]: (state: ProductsState) => ({
    ...state,
    assigneeModalOpen: true,
  }),
  [actions.closeAssigneeModal.type]: (state: ProductsState) => ({
    ...state,
    assigneeModalOpen: false,
  }),
  [actions.getAssigneesSuccess.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.getAssigneesSuccess>
  ) => ({
    ...state,
    loading: false,
    colleagues: action.payload,
  }),
  [actions.getAssigneesFail.type]: (state: ProductsState, action: ReturnType<typeof actions.getAssigneesFail>) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
}
export default reducer
