import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Accordion as MUIAccordion, AccordionSummary, AccordionDetails, AccordionActions } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import Paper from '../atoms/Paper/Paper'
import { Flexbox } from 'pages/Layout/Styled'
import { slug } from 'utils/common.util'
import { hashObject } from 'utils/api.utils'

const StyledAccordionSummary = styled(AccordionSummary)`
  padding-left: 0px;
  padding-right: 24px;
  pointer-events: none;
  .MuiAccordionSummary-expandIcon {
    pointer-events: auto;
  }
  & .MuiAccordionSummary-content {
    margin: 0;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`

const StyledAccordion = styled(MUIAccordion)<{ disableCardBorder?: boolean }>`
  ${({ disableCardBorder }) => (disableCardBorder ? 'box-shadow: none;' : '')}
`

type Props = React.PropsWithChildren<{
  title: React.ReactElement
  headerIcon?: React.ReactElement
  headerButtonGroup?: React.ReactElement
  actions?: React.ReactElement
  scrollEnabled?: boolean
  defaultExpanded?: boolean
  externalExpanded?: boolean
  setExternalExpanded?: () => void
  style?: React.CSSProperties
  testId?: string
  disableCardBorder?: boolean
}>

const Accordion = ({
  children,
  title,
  headerIcon,
  headerButtonGroup,
  actions,
  defaultExpanded,
  externalExpanded,
  setExternalExpanded,
  style,
  testId,
  disableCardBorder,
}: Props): React.ReactElement => {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const id = `accordion-${slug(typeof title === 'string' ? title : hashObject('title', title))}`

  useEffect(() => {
    switch (externalExpanded) {
      case true:
        setExpanded(true)
        break
      case false:
        setExpanded(false)
        break
      default:
        break
    }
  }, [externalExpanded])

  useEffect(() => {
    if (expanded && setExternalExpanded) {
      setExternalExpanded()
    }
  }, [expanded])

  return (
    <Paper
      data-dls="organism-accordion"
      data-test-id={testId}
      style={{ padding: 0, margin: 0, width: '100%', ...style }}
    >
      <StyledAccordion defaultExpanded expanded={expanded} disableCardBorder={disableCardBorder}>
        <StyledAccordionSummary
          role={undefined}
          aria-expanded={undefined}
          tabIndex={undefined}
          expandIcon={<ExpandMore />}
          IconButtonProps={{
            onClick: () => setExpanded((previousExpanded) => !previousExpanded),
          }}
        >
          <Flexbox direction="row" width="100%" pl={24} pr={24} pt={16} pb={16} style={{ minHeight: 80 }}>
            <Flexbox
              direction="row"
              width="100%"
              onClick={() => setExpanded(!expanded)}
              style={{ pointerEvents: 'auto' }}
              role="button"
              aria-expanded={expanded}
              aria-controls={id}
              tabIndex={0}
              alignItems="center"
            >
              {headerIcon}
              <div
                style={{
                  paddingLeft: headerIcon ? 22 : 0,
                  flexGrow: 2,
                }}
              >
                {title}
              </div>
            </Flexbox>
            {headerButtonGroup && (
              <Flexbox
                direction="column"
                justifyContent="center"
                style={{ position: 'relative', pointerEvents: 'auto', right: -24 }}
              >
                {headerButtonGroup}
              </Flexbox>
            )}
          </Flexbox>
        </StyledAccordionSummary>
        <AccordionDetails style={{ minHeight: 100, padding: 0, paddingBottom: 24 }}>
          <Flexbox
            direction="column"
            width="100%"
            style={{
              paddingLeft: 24,
              paddingRight: 24,
            }}
            id={id}
          >
            {children}
          </Flexbox>
        </AccordionDetails>
        {actions && <AccordionActions>{actions}</AccordionActions>}
      </StyledAccordion>
    </Paper>
  )
}

export default Accordion
