import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.clearProductPublishError.type]: (state: ProductsState) => ({
    ...state,
    publishError: undefined,
  }),

  [actions.clearPublishSuccess.type]: (state: ProductsState) => ({
    ...state,
    publishSuccess: false,
  }),
}
export default reducer
