import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import BreadcrumbsSelectors from 'store/breadcrumbs/selectors'

export const PageTitle = () => {
  const location = useLocation()
  const pageTitleAndUrl = useSelector(BreadcrumbsSelectors.selectBreadcrumbs)
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  const pageTitle =
    pageTitleAndUrl.length > 0 ? `${pageTitleAndUrl.map(({ title }) => title).join(' - ')}` : 'Bank Manager'
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default PageTitle
