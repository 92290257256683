import React from 'react'
import { FieldProps } from 'formik'
import FormHelperText, { FormHelperTextProps } from '@material-ui/core/FormHelperText'
import FormControl, { FormControlProps } from '@material-ui/core/FormControl'
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel'
import { useTranslation } from 'react-i18next'
import { Error } from '@material-ui/icons'

export interface EnhancedFormikFieldChildrenProps {
  isDisabled: boolean
  hasError?: boolean
  fieldError?: string | string[]
}

export interface EnhancedFormikFieldProps {
  label?: React.ReactNode
  disabled?: boolean
  required?: boolean
  helperText?: React.ReactNode
  formControlProps?: FormControlProps
  inputLabelProps?: InputLabelProps
  formHelperTextProps?: FormHelperTextProps
  children: (props: EnhancedFormikFieldChildrenProps) => React.ReactNode
  touchedNotNeeded?: boolean
}

type Props = FieldProps & EnhancedFormikFieldProps

export const getFormFieldCalculatedProps = (props: Partial<Props>) => {
  const { disabled = false, field, form, touchedNotNeeded = false } = props
  const { name } = field as any
  const { isSubmitting } = form as any

  const meta = form?.getFieldMeta(name)
  const fieldError = meta?.error
  const hasError = (touchedNotNeeded || meta?.touched) && !!fieldError
  const isDisabled = isSubmitting || (disabled as boolean)
  return { fieldError, hasError, isDisabled }
}

// todo rename to stg like MaterialFieldWrapper
// todo this could also work as a HOC to wrap components passed to `Field`
const EnhancedFormikField = (props: Props) => {
  const { required, formControlProps, inputLabelProps, formHelperTextProps, field, label, helperText } = props
  const { fieldError, hasError, isDisabled } = getFormFieldCalculatedProps(props)
  const { t } = useTranslation()

  return (
    <FormControl fullWidth {...formControlProps} error={hasError} disabled={isDisabled} required={required}>
      <InputLabel
        htmlFor={field.name}
        {...inputLabelProps}
        error={hasError}
        disabled={isDisabled}
        required={!!(label && required)}
      >
        {label}
      </InputLabel>
      {props.children({ isDisabled, fieldError, hasError })}
      <FormHelperText {...formHelperTextProps} error={hasError} disabled={isDisabled} required={required}>
        {(hasError && (
          <>
            <Error
              style={{
                verticalAlign: 'bottom',
                marginRight: 4,
                width: 20,
                height: 20,
              }}
              width={20}
              height={20}
              aria-label={t('Error icon')}
            />
            {t(fieldError || '', { nsSeparator: '||' })}
          </>
        )) ||
          helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default EnhancedFormikField
