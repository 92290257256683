import { createAction } from '@reduxjs/toolkit'
import { WithholdingRule, RuleId, WithholdingTaxRule, EntityUpdateMode } from './types'
import { ShouldRedirectOnFail } from 'store/utils/types'
import { SearchProduct } from 'store/products/types'

const actions = {
  getAllTaxRules: createAction<ShouldRedirectOnFail | undefined>('RULES/GET/ALL'),
  getAllTaxRulesWithoutErrorNavigation: createAction('RULES/GET/ALL/NONAVIGATE'),
  getAllTaxRulesSuccess: createAction<WithholdingRule[]>('RULES/GET/ALL/SUCCESS'),
  createRule: createAction<WithholdingRule>('RULES/CREATE'),
  createRuleSuccess: createAction('RULES/CREATE/SUCCESS'),
  getTaxRule: createAction<RuleId>('RULES/GET'),
  getTaxRuleSuccess: createAction<WithholdingRule>('RULES/GET/SUCCESS'),
  getTaxRuleProducts: createAction<RuleId>('RULES/GET/PRODUCTS'),
  getTaxRuleProductsSuccess: createAction<SearchProduct>('RULES/GET/PRODUCTS/SUCCESS'),
  getTaxRuleProductsError: createAction('RULES/GET/PRODUCTS/ERROR'),
  updateTaxRule: createAction<WithholdingTaxRule & { mode: EntityUpdateMode }>('RULES/UPDATE'),
  updateTaxRuleSuccess: createAction('RULES/SAVE/SUCCESS'),
  deleteRule: createAction<WithholdingRule>('RULES/DELETE'),
}

export default actions
