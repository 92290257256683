import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'
import * as Yup from 'yup'
import 'styled-components/macro'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import useStatementConfiguration from '../utils/useStatementConfiguration'
import { toStatementConfigurationFormValues, toStatementConfiguration } from '../utils/transformers'
import {
  StatementConfiguration,
  StatementConfigurationFormValues,
  StatementLevel,
} from 'store/templates/statementConfiguration/types'
import StatementConfigActions from 'store/templates/statementConfiguration/actions'
import ContentContainer from 'pages/Layout/ContentContainer'
import {
  TemplateForm,
  Input,
  Select,
  Paper,
  H2,
  Body2,
  EntityBadgeStatements,
  InfoPanel,
  Button,
  ContextualHelp,
  InfoIconButton,
} from 'dls'
import { AvatarSize, ColorType } from 'dls/shared/types'
import { Flexbox } from 'pages/Layout/Styled'
import { MenuItem } from 'dls/atoms/MenuItem'
import { CalendarMonth, ProductSegment, StatementPeriod, StatementType } from 'store/products/types'
import { calculateDaysInMonth } from 'utils/date.utils'
import { StatementEmptyTemplate } from 'assets'
import { CreateIcon } from 'dls/atoms/Icons/MUIIcons'
import useModal from 'components/Modal/hooks/useModal'
import { HelpModalType } from 'store/modal/types'
import StatementPreviewImage from 'pages/features/Statements/StatementPreviewImage'
import PreviewConfigurationDialog from 'pages/features/Statements/PreviewConfigurationDialog'

const UpdateStatementConfigurationForm = ({
  editableStatementConfiguration,
}: {
  editableStatementConfiguration?: StatementConfiguration
}): React.ReactElement => {
  const [isPreviewTemplateOpen, setPreviewTemplateOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showInfographicModal } = useModal()

  const {
    saveDraftStatementConfigurationFormValues,
    draftStatementConfigurationFormValues,
    deleteDraftStatementConfigurationTemplate,
  } = useStatementConfiguration()
  const dispatch = useDispatch()

  const {
    control,
    watch,
    getValues,
    formState: { isValid, isDirty },
  } = useForm<StatementConfigurationFormValues>({
    mode: 'onBlur',
    defaultValues:
      draftStatementConfigurationFormValues || toStatementConfigurationFormValues(editableStatementConfiguration),
    resolver: yupResolver(
      Yup.object({
        statementPeriod: Yup.string().required(),
        startMonth: Yup.string().when(['statementPeriod'], {
          is: (statementPeriod) => statementPeriod === StatementPeriod.YEAR,
          then: (statementPeriod: Yup.StringSchema) => statementPeriod.required('This is required'),
        }),
        startDay: Yup.string().when(['statementPeriod'], {
          is: (statementPeriod) => statementPeriod === StatementPeriod.MONTH,
          then: (statementPeriod: Yup.StringSchema) => statementPeriod.required('This is required'),
        }),
        statementDescription: Yup.string().required(),
        name: Yup.string().required(),
        productSegment: Yup.string().required(),
        offsetDays: Yup.string().required(),
      }).required()
    ),
  })

  const statementPeriod: string = watch('statementPeriod')
  const startMonth: string | undefined = watch('startMonth')
  const productSegment: string = watch('productSegment')

  const templateName =
    draftStatementConfigurationFormValues?.template?.templateName ?? editableStatementConfiguration?.template?.name

  useEffect(() => {
    if (isDirty && productSegment) {
      deleteDraftStatementConfigurationTemplate()
    }
  }, [productSegment])

  const onCancel = () => {
    navigate('/templates/statement-configurations')
  }

  const renderFrequencyTypeSelect = () => {
    const options = [
      { value: 'YEAR', label: 'Annually' },
      {
        value: 'MONTH',
        label: 'Monthly',
      },
    ]

    return (
      <Controller
        name="statementPeriod"
        control={control}
        render={({ field }) => (
          <Grid item xs={12} sm={4}>
            <Select
              id="statementPeriod"
              label={t('Statement frequency')}
              {...field}
              required
              placeholder={t('Please select')}
              displayEmpty
              fullWidth
            >
              {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  <Box display="flex" alignItems="center" ml={1} mr={2}>
                    {t(label)}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      />
    )
  }

  const renderMonthSelect = () => {
    if (statementPeriod === StatementPeriod.YEAR) {
      return (
        <Controller
          name="startMonth"
          control={control}
          render={({ field }) => (
            <Grid item xs={12} sm={4}>
              <Select
                id="startMonth"
                label={t('Start month')}
                {...field}
                required
                placeholder={t('Please select')}
                displayEmpty
                fullWidth
              >
                {Object.keys(CalendarMonth).map((month) => (
                  <MenuItem key={month} value={month}>
                    <Box display="flex" alignItems="center" ml={1} mr={2}>
                      {t(`CALENDAR.${month}`)}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        />
      )
    }

    return null
  }

  const renderDaySelect = () => {
    if ((statementPeriod === StatementPeriod.YEAR && startMonth) || statementPeriod === StatementPeriod.MONTH) {
      const days = Object.keys(calculateDaysInMonth(parseInt(startMonth ?? '0', 10)))

      return (
        <Controller
          name="startDay"
          control={control}
          render={({ field }) => (
            <Grid item xs={12} sm={4}>
              <Select
                id="startDay"
                label={t('Start day')}
                {...field}
                required
                placeholder={t('Please select')}
                displayEmpty
                fullWidth
              >
                {days.map((day) => (
                  <MenuItem key={day} value={day}>
                    <Box display="flex" alignItems="center" ml={1} mr={2}>
                      {t(`CALENDAR.${day}`)}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        />
      )
    }

    return null
  }

  const selectTemplate = () => {
    saveDraftStatementConfigurationFormValues(getValues())
    navigate('selectTemplate')
  }

  const renderTemplateImage = () => {
    const template = draftStatementConfigurationFormValues?.template?.templateGroupId
      ? {
          templateGroupId: draftStatementConfigurationFormValues?.template?.templateGroupId as string,
          templateName: draftStatementConfigurationFormValues?.template?.templateName as string,
          isDefault: false,
        }
      : {
          templateGroupId: editableStatementConfiguration?.template?.id as string,
          templateName: editableStatementConfiguration?.template?.name as string,
          isDefault: false,
        }

    const segment =
      ProductSegment[
        draftStatementConfigurationFormValues?.productSegment ?? (editableStatementConfiguration?.productSegment || '')
      ]

    if (template?.templateGroupId) {
      const configuration = draftStatementConfigurationFormValues
        ? toStatementConfiguration(draftStatementConfigurationFormValues)
        : editableStatementConfiguration ?? ({} as StatementConfiguration)
      return (
        <>
          <StatementPreviewImage
            template={template}
            productSegment={segment}
            templateType={StatementType.INTEREST_STATEMENT}
            previewTemplateOpen={() => setPreviewTemplateOpen(true)}
          />
          {isPreviewTemplateOpen && (
            <PreviewConfigurationDialog
              statementConfig={configuration}
              title={draftStatementConfigurationFormValues?.template?.templateName || 'Statement template'}
              open={isPreviewTemplateOpen}
              onClose={() => setPreviewTemplateOpen(false)}
              productSegment={segment}
              templateType={StatementType.INTEREST_STATEMENT}
            />
          )}
        </>
      )
    }

    return (
      <Button onClick={selectTemplate} css="width: 170px">
        <img src={StatementEmptyTemplate} alt={t('select template design')} width={160} />
      </Button>
    )
  }

  const renderTemplateText = () => {
    const title = templateName || 'STATEMENT_CONFIGURATION.NO_TEMPLATE_DESIGN_CHOSEN_NAME'
    const description = templateName ? 'CHOSEN_TEMPLATE_DESCRIPTION' : 'NO_TEMPLATE_DESIGN_CHOSEN_DESCRIPTION'
    return (
      <Flexbox direction="column" justifyContent="center" height="100%" pl={24} css="max-width: 50%">
        <Body2 variantMapping={{ body2: 'h4' }} css="font-weight: 700;">
          {t(title)} *
        </Body2>
        {description && <Body2>{t(`STATEMENT_CONFIGURATION.${description}`)}</Body2>}
        {templateName && (
          <Button
            startIcon={<CreateIcon />}
            onClick={selectTemplate}
            css="max-width: 200px; margin-top: 10px;"
            color={ColorType.SECONDARY}
          >
            {t('STATEMENT_CONFIGURATION.CHANGE_TEMPLATE')}
          </Button>
        )}
      </Flexbox>
    )
  }

  const onSubmitHandler = () => {
    const payload = toStatementConfiguration({
      ...getValues(),
      level: StatementLevel.PARTY,
      statementType: StatementType.INTEREST_STATEMENT,
    })
    return dispatch(StatementConfigActions.updateStatementConfiguration(payload))
  }

  return (
    <ContentContainer data-test-id="update-interest-statements-page">
      <TemplateForm
        icon={<EntityBadgeStatements size={AvatarSize.LARGE} />}
        title={t('Interest statement')}
        description={t(
          'Here you can create and manage interest statements which can be cover multiple products in order to generate an aggregated statement for a customer.'
        )}
        toolbarButtons={
          <div style={{ marginTop: -24, marginRight: -12 }}>
            <InfoIconButton
              key="more-about-icon"
              ariaProps={{
                'aria-label': `${t('More about interest statements')}`,
                'aria-haspopup': 'dialog',
                'aria-controls': 'more-about-dialog',
              }}
              onClick={() => showInfographicModal(HelpModalType.INTEREST_STATEMENTS)}
              fontSize="medium"
            />
          </div>
        }
        main={
          <form>
            <section aria-labelledby="about-the-statement">
              <Box mb={3}>
                <Paper responsivePadding>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <Box mb={4}>
                        <H2 id="about-the-statement">{t('About the statement')}</H2>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Box mb={3}>
                            <Input
                              id="name"
                              type="string"
                              label={t('Statement name (internal use)')}
                              css="width:100%"
                              placeholder={t('Enter name')}
                              required
                              fullWidth
                              {...field}
                            />
                          </Box>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="statementDescription"
                        control={control}
                        render={({ field }) => (
                          <Box mb={3}>
                            <Input
                              id="name"
                              type="string"
                              label={t('Statement description (internal use)')}
                              placeholder={t('Enter description')}
                              {...field}
                              fullWidth
                              required
                              css="width:100%"
                            />
                          </Box>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Flexbox direction="row" alignItems="center" width="100%">
                        <Controller
                          name="productSegment"
                          control={control}
                          render={({ field }) => (
                            <Box mb={3} css={{ flexGrow: 2 }}>
                              <Select
                                id="productSegment"
                                label={t('Segment')}
                                {...field}
                                required
                                fullWidth
                                placeholder={t('Please select')}
                                displayEmpty
                              >
                                {['PERSONAL', 'BUSINESS'].map((option) => (
                                  <MenuItem key={option} value={option}>
                                    <Box display="flex" alignItems="center" ml={1} mr={2}>
                                      {t(option)}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          )}
                        />
                        <ContextualHelp
                          title={`${t(`STATEMENT_CONFIGURATION.PRODUCT_SEGMENT_TOOLTIP`)}`}
                          css="margin-left: 16px;"
                        />
                      </Flexbox>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </section>
            <section aria-labelledby="statement-generation">
              <Box mb={productSegment ? 3 : 0}>
                <Paper responsivePadding>
                  <Flexbox direction="column">
                    <Box mb={4}>
                      <H2 id="statement-generation">{t('Statement generation')}</H2>
                    </Box>

                    <Grid container direction="row" spacing={3}>
                      {renderFrequencyTypeSelect()}
                      {renderMonthSelect()}
                      {renderDaySelect()}
                    </Grid>
                    <Box mt={3}>
                      <InfoPanel
                        body={t(
                          'Statement generation happens the day after the above frequency period ends, you can use the generation offset to increase this period.'
                        )}
                      />
                    </Box>
                    <Grid container direction="row" spacing={3}>
                      <Controller
                        name="offsetDays"
                        control={control}
                        render={({ field }) => (
                          <Grid item xs={12} sm={6} md={4}>
                            <Box mt={3} mb={3}>
                              <Select
                                id="offsetDays"
                                fullWidth
                                label={t('Generation offset (days)')}
                                {...field}
                                required
                                placeholder={t('Please select')}
                                displayEmpty
                              >
                                {Array.from(Array(29).keys())
                                  .slice(1)
                                  .map((num) => (
                                    <MenuItem key={num} value={num}>
                                      <Box display="flex" alignItems="center" ml={1} mr={2}>
                                        {num}
                                      </Box>
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Box>
                          </Grid>
                        )}
                      />
                    </Grid>
                  </Flexbox>
                </Paper>
              </Box>
            </section>
            {productSegment && (
              <section aria-labelledby="design">
                <Paper responsivePadding>
                  <Flexbox direction="column">
                    <Box mb={4}>
                      <H2 id="design">{t('Design')}</H2>
                    </Box>
                    <Flexbox direction="row" alignItems="center">
                      <Box width="170px">{renderTemplateImage()}</Box>
                      {renderTemplateText()}
                    </Flexbox>
                  </Flexbox>
                </Paper>
              </section>
            )}
          </form>
        }
        onCancel={onCancel}
        submitButtonDisabled={!isValid || !templateName}
        onSubmit={onSubmitHandler}
      />
    </ContentContainer>
  )
}

export default UpdateStatementConfigurationForm
