/* eslint-disable no-nested-ternary */
import React, { CSSProperties } from 'react'
import { SvgIconTypeMap, Tooltip } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { hashObject } from 'utils/api.utils'

interface Props {
  style?: CSSProperties
  title: string | JSX.Element
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  Icon?: OverridableComponent<SvgIconTypeMap>
}

const ContextualHelp = ({ style, title, placement = 'right', Icon = HelpOutlineIcon }: Props) => (
  <Tooltip title={title} placement={placement} data-test-id={`tooltip-${hashObject('tooltip', { title })}`}>
    <Icon style={{ ...style, cursor: 'pointer' }} aria-label={title as string} />
  </Tooltip>
)

export default ContextualHelp
