import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StatementSelectors from 'store/statement/selectors'
import StatementActions from 'store/statement/actions'
import { ProductSegment, StatementTemplate, StatementType } from 'store/products/types'

export default (productSegment: ProductSegment, template: StatementTemplate | null) => {
  const dispatch = useDispatch()
  const getTemplates: (StatementType: any) => void = (statementType: StatementType) => {
    if (statementType && productSegment)
      return dispatch(StatementActions.getTemplates({ templateType: statementType, productSegment }))
    return null
  }
  const templates = useSelector(StatementSelectors.selectTemplates) || {}
  const isLoading = useSelector(StatementSelectors.selectTemplatesLoading)
  const error = useSelector(StatementSelectors.selectTemplatesError)
  const [selectedTemplate, setSelectedTemplate] = useState<StatementTemplate | null>(template)
  useEffect(() => {
    setSelectedTemplate(template)
  }, [template])

  return { templates, isLoading, error, selectedTemplate, setSelectedTemplate, getTemplates }
}
