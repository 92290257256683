import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CreateInterestRateForm from './CreateInterestRateForm'
import ReferenceDataActions from 'store/referenceData/actions'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import { Loader } from 'components'
import { BankInterestRateFormValues } from 'store/referenceData/types'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const CreateRatePage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const createBankInterestRate = (bankInterest: BankInterestRateFormValues) =>
    dispatch(ReferenceDataActions.createBankInterestRate(bankInterest))

  const bankInterestRates = useSelector(ReferenceDataSelectors.selectAllBankInterestRate)
  const loading = useSelector(ReferenceDataSelectors.selectLoading)

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Index rates'), path: '' },
    ],
    trigger: true,
  })

  useEffect(() => {
    dispatch(ReferenceDataActions.fetchBankInterestRate())
  }, [dispatch])

  if (loading) {
    return <Loader />
  }

  const onSubmit = (values: BankInterestRateFormValues) => values && createBankInterestRate(values)
  const initialValues = {
    abbreviation: '',
    bankIndexKey: '',
    name: '',
    rates: [{}],
  }

  return (
    <CreateInterestRateForm bankInterestRates={bankInterestRates} initialValues={initialValues} onSubmit={onSubmit} />
  )
}

export default CreateRatePage
