import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTypography, StatusContainer, StyledAvatar } from './Styled'
import { ProductVersionStatus } from 'store/products/typings/productVersionStatus'
import { toBankTz } from 'utils/date.utils'
import { ProductStatus } from 'store/products/typings/productStatus'
import { getProductStatusLabel, getStatus } from 'pages/Product/product.utils'
import { Task } from 'store/tasks/types'
import { Flexbox } from 'pages/Layout/Styled'
import { Body2 } from 'dls/atoms/Typography'
import { getVersionText } from 'utils/productVersion.utils'

interface Props {
  status: ProductVersionStatus
  date?: string | null
  task?: Task
  isTaskDrawer?: boolean
  postfixText?: string
  version?: number
  showDate?: boolean
  horizontalStatus?: boolean
}

const StatusBadge = ({
  isTaskDrawer,
  status: origStatus,
  task,
  date,
  postfixText,
  version,
  showDate,
  horizontalStatus,
}: Props): React.ReactElement => {
  const { t } = useTranslation()
  const status = getStatus(origStatus, task, isTaskDrawer)
  const parsedDate = date ? toBankTz(date) : undefined

  const versionText = version ? `${getVersionText(version)}: ` : ''
  const statusText = `${getProductStatusLabel(status, t, parsedDate)} ${postfixText || ''}`
  const dateText =
    (origStatus && status === ProductStatus.LIVE) || showDate ? (
      <DateTypography variant="body2" variantMapping={{ body2: 'div' }}>
        {!!parsedDate && parsedDate.dateTime}
      </DateTypography>
    ) : null

  const renderStatus = () =>
    horizontalStatus ? (
      <Flexbox direction="row">
        <Body2 align="left" variantMapping={{ body2: 'div' }} style={{ paddingRight: 12 }}>
          {versionText}
          {statusText}
        </Body2>
        {dateText}
      </Flexbox>
    ) : (
      <Body2 align="left" variantMapping={{ body2: 'div' }}>
        {versionText}
        {statusText}
        {dateText}
      </Body2>
    )

  return (
    <StatusContainer style={{ width: horizontalStatus ? '300px' : 'inherit' }}>
      <StyledAvatar status={origStatus} parsedDate={parsedDate} />
      {renderStatus()}
    </StatusContainer>
  )
}

export default StatusBadge
