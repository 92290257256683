import React from 'react'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import { Archive as ArchiveIcon, Delete as RemoveIcon, KeyboardArrowDown } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import AccessibleMenu, { AccessibleMenuItem } from '../AccessibleMenu/AccessibleMenu'
import { stopPropagation } from 'utils/form.utils'
import { IconPreview } from 'assets'
import { Product, DeleteOrRetire } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { isVersionFirst } from 'utils/productVersion.utils'
import { MoreVertButton } from 'dls/molecules/IconButtons/IconButtons'
import { ColorType } from 'dls/shared/types'
import Button from 'dls/atoms/Button'

interface Props {
  product: Product
  searchElement: ProductStatus
  iconButton?: boolean
  isOnProductPage?: boolean
  openConfirm: (newActonType: DeleteOrRetire) => any
  closeConfirmAndMenu: (e: React.MouseEvent) => void
  anchorEl: null | HTMLElement
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>
}

const ProductActions = function ProductActions({
  product,
  searchElement,
  iconButton = false,
  isOnProductPage = false,
  closeConfirmAndMenu,
  openConfirm,
  anchorEl,
  setAnchorEl,
}: Props) {
  const { t } = useTranslation()

  function openMenu(e: React.MouseEvent<HTMLButtonElement>) {
    stopPropagation(e)
    setAnchorEl(e.currentTarget)
  }

  const id = `products-menu-${product.productKey}`

  const showButtonsMatrix = {
    edit: !isOnProductPage,
    delete: isVersionFirst(product) && ProductStatus.DESIGN === searchElement,
    retire: false, // Don't delete, temporarly disabled. ProductStatus.LIVE === searchElement,
  }

  const menuItems: AccessibleMenuItem[] = []

  if (showButtonsMatrix.edit) {
    menuItems.push({
      internalUrl: `${product.productKey}?mode=edit`,
      testId: 'product-action-edit-button',
      children: (
        <>
          <ListItemIcon>
            <img src={IconPreview} alt={t('view')} />
          </ListItemIcon>
          <ListItemText primary={t('View')} />
        </>
      ),
    })
  }
  if (showButtonsMatrix.delete) {
    menuItems.push({
      onClick: openConfirm(DeleteOrRetire.DELETEPRODUCT),
      testId: 'product-action-edit-button',
      children: (
        <>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary={t('Delete')} />
        </>
      ),
    })
  }

  if (showButtonsMatrix.retire) {
    menuItems.push({
      onClick: openConfirm(DeleteOrRetire.RETIREPRODUCT),
      testId: 'product-action-edit-button',
      children: (
        <>
          <ListItemIcon>
            <ArchiveIcon />
          </ListItemIcon>
          <ListItemText primary={t('Retire')} />
        </>
      ),
    })
  }
  const menuButton = iconButton ? (
    <MoreVertButton
      size="medium"
      onClick={openMenu}
      aria-label="More actions"
      aria-controls={`products-menu-${product.productKey}`}
      aria-haspopup="true"
      disabled={menuItems.length === 0}
    />
  ) : (
    <Button
      color={ColorType.BUTTON}
      size="small"
      onClick={openMenu}
      aria-label="More actions"
      aria-controls={`products-menu-${product.productKey}`}
      aria-haspopup="true"
      disabled={menuItems.length === 0}
    >
      {t('ACTIONS')}
      <KeyboardArrowDown />
    </Button>
  )
  return menuItems.length > 0 || !isOnProductPage ? (
    <>
      <AccessibleMenu
        id={id}
        anchorEl={anchorEl as any}
        menuItems={menuItems}
        menuButton={menuButton}
        onClose={closeConfirmAndMenu}
      />
    </>
  ) : null
}

export default ProductActions
