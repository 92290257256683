import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FeatureFormPageProps } from '../FeaturePage.page'
import LimitsForm from './LimitsForm'
import { toLimitsFormValues } from './limitsTransformers'
import ProductsActions from 'store/products/actions'
import { Limits, Feature } from 'store/products/types'
import { getCurrencyOfProduct } from 'api/utils'
import { RootState } from 'store/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import { ClientConfig } from 'store/tenant-config/types'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLimits: (productKey: string) => (limitsFormValues: Limits) =>
    dispatch(ProductsActions.setLimits({ ...limitsFormValues, productKey })),
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const LimitsFormPage = (props: Props) => {
  const { setLimits, product, tenantConfig } = props
  const { t } = useTranslation()
  const { productKey = '' } = useParams()
  const navigate = useNavigate()

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.LIMITS)) navigate(`./../not-allowed`)

  const clientConfig = ((tenantConfig && tenantConfig.clientConfig) as Partial<ClientConfig>) || {}

  if (product) {
    return (
      <LimitsForm
        onSubmit={setLimits(productKey)}
        initialValues={toLimitsFormValues(product)}
        defaultValues={{}}
        currencyOfProduct={getCurrencyOfProduct(product)}
        clientConfig={clientConfig}
        product={product}
        t={t}
      />
    )
  }

  return null
}

export default connect(mapStateToProps, mapDispatchToProps)(LimitsFormPage)
