import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { StyledAvatar } from 'components/StatusBadge/Styled'
import { getProductStatusLabel } from 'pages/Product/product.utils'
import { Body2 } from 'components'

interface OwnProps<FilterItem, statuses> {
  itemStatus: statuses
  isActive: boolean
  items: FilterItem[]
  statusPropName?: string
}

const FilterMenuItem = <FilterItem extends object, statuses>({
  itemStatus,
  items,
  isActive,
  statusPropName = 'status',
}: OwnProps<FilterItem, statuses>) => {
  const { t } = useTranslation()
  return (
    <Box component="span" display="flex">
      <StyledAvatar status={itemStatus} />
      <Body2 color={!isActive ? 'textPrimary' : undefined}>
        <span style={{ textTransform: 'capitalize' }}>
          {(getProductStatusLabel(`${itemStatus}`, t) ?? '').toLowerCase()}
        </span>{' '}
        ({items.filter((item) => item[statusPropName] === itemStatus).length})
      </Body2>
    </Box>
  )
}

export default FilterMenuItem
