import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { ActivityState } from './types'

const initialState = {
  loading: false,
  error: undefined,
  data: [],
}

const reducer = createReducer<ActivityState>(initialState, {
  [actions.fetch.type]: (state) => ({ ...state, loading: false }),
  [actions.fetchSuccess.type]: (state, action) => ({ ...state, loading: false, data: action.payload }),
  [actions.fetchFail.type]: (state, action) => ({ ...state, loading: false, error: action.payload }),
})

export default reducer
