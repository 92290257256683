import React, { ReactElement } from 'react'
import InputLabelMui, { InputLabelProps } from '@material-ui/core/InputLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components/macro'
import { LabelMode, FieldLabelPlacement } from 'dls/shared/types'
import { Body2 } from 'dls/atoms/Typography'

export interface FieldLabelCustomProps extends InputLabelProps {
  labelMode?: LabelMode
  control?: React.ReactElement | undefined
  labelPlacement?: FieldLabelPlacement
  disabled?: boolean
  value?: string | boolean
}

const StyledFormControlLabel = styled(FormControlLabel)`
  ${(props: any) =>
    props.error
      ? `
    color: ${props.theme.palette.error.main};
  `
      : `
      color: ${props.theme.palette.text.primary};`}
`

const FieldLabel = ({
  labelMode = LabelMode.INPUT_FIELD,
  control,
  value,
  children,
  htmlFor,
  onChange,
  ...props
}: FieldLabelCustomProps) => {
  if (labelMode === LabelMode.INPUT_FIELD) {
    return (
      <InputLabelMui shrink data-dls="atom-inputlabel" color="secondary" htmlFor={htmlFor} {...props}>
        <Body2 variantMapping={{ body2: 'span' }} color="inherit">
          {children}
        </Body2>
      </InputLabelMui>
    )
  }
  if (labelMode === LabelMode.RIGHT_LABEL) {
    return (
      <StyledFormControlLabel
        data-dls="atom-inputlabel"
        color="secondary"
        label={
          <Body2 variantMapping={{ body2: 'span' }} color="inherit">
            {children}
          </Body2>
        }
        control={control as ReactElement<any, any>}
        value={value}
        onChange={onChange as any}
        htmlFor={htmlFor}
        {...props}
      />
    )
  }
  return (
    <FormLabel data-dls="atom-inputlabel" color="secondary" htmlFor={htmlFor} {...props}>
      <Body2 variantMapping={{ body2: 'span' }} color="inherit">
        {children}
      </Body2>
    </FormLabel>
  )
}

export default FieldLabel
