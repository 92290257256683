import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import 'styled-components/macro'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ExtendedProduct } from 'store/products/types'
import { AddCircleIconButton, Body1, Body2 } from 'dls'
import { Link } from 'dls/atoms/Link'
import { StatementSkeleton } from 'assets'

type OwnProps = {
  product: ExtendedProduct
  additionalContent: React.ReactNode
  updateDraftFormValues: () => void
}

const SelectStatementTemplate = ({ product, additionalContent, updateDraftFormValues }: OwnProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="stretch">
        <Grid item>
          <Box display="grid" gridTemplateColumns="160px 1fr" alignItems="center" gridGap={24}>
            <Box position="relative">
              <img src={StatementSkeleton} alt="wireframe of statement template" />
              <div css="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <AddCircleIconButton
                  title="Add statement"
                  color="inherit"
                  onClick={() => {
                    updateDraftFormValues()
                    navigate(`/products/${product.productKey}/features/statements/choose`)
                  }}
                  ariaProps={{ 'aria-label': 'Add statement', 'aria-controls': 'main', 'aria-role': 'link' }}
                />
              </div>
            </Box>
            <div>
              <Body1 css="font-weight: bold">{t('No statement chosen *')}</Body1>
              <Body2>{t('Tap the add button on the thumbnail image to choose a statement.')}</Body2>
              <Body2>
                {t('New templates can be created in ')}
                <Link component={RouterLink} to="/templates" color="secondary">
                  {t('templates')}
                </Link>
              </Body2>
              {additionalContent}
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default SelectStatementTemplate
