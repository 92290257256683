import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Users from './Users/Users'
import { Loader } from 'components/'
import { selectLoadingUser, selectUsers } from 'store/user/selectors'
import UserActions from 'store/user/actions'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const UsersPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const users = useSelector(selectUsers)
  const loading = useSelector(selectLoadingUser)
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)
  const [userData, setUser] = useState(users)

  useBreadcrumbs({
    breadcrumbs: [{ title: t('{{tenantName}} users', { tenantName: currentTenant?.name }), path: 'users/' }],
    trigger: true,
  })

  useEffect(() => {
    dispatch(UserActions.getUser())
  }, [UserActions.getUser])

  useEffect(() => {
    setUser(userData)
  }, [users, loading])

  if (loading) {
    return <Loader />
  }
  return <Users users={users} />
}

export default UsersPage
