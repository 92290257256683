import React, { useMemo } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { HelpModalType } from 'store/modal/types'
import { FeatureDefinitions, H2, H1, TemplateReview } from 'components'
import { Colleague } from 'store/user/types'
import { getInitials, getColorFromInitials } from 'utils/ui.utils'
import CopyButton from 'components/CopyButton/CopyButton'
import ContentContainer from 'pages/Layout/ContentContainer'
import { InfoIconButton, OAvatar } from 'dls'
import { AvatarSize, AvatarColor } from 'dls/shared/types'
import useModal from 'components/Modal/hooks/useModal'

export interface Props {
  selectedUser: Colleague
}

const User = ({ selectedUser }: Props) => {
  const { t } = useTranslation()
  const { showInfographicModal } = useModal()
  const {
    userKey,
    email,
    userRole,
    profile: { title, firstName, lastName },
  } = selectedUser

  const definitions = useMemo(
    () => [
      ...(title ? [{ term: t('Title'), definition: [title] }] : []),
      { term: t('First name'), definition: [firstName] },
      { term: t('Last name'), definition: [lastName] },
      {
        term: t('Email'),
        definition: [
          <Box key={email} display="flex" alignItems="center">
            <span style={{ wordBreak: 'break-all' }}>{email}</span>
            <CopyButton payload={email} extraButtonProps={{ style: { marginLeft: 12 } }} />
          </Box>,
        ],
      },
      { term: t('Role'), definition: [t(userRole)] },
    ],
    [userKey]
  )

  const initials = getInitials(`${firstName} ${lastName}`)
  const avatarColor = getColorFromInitials(initials)

  return (
    <ContentContainer data-test-id="view-user-page">
      <TemplateReview
        header={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gridGap={16} alignItems="center">
              <OAvatar color={avatarColor as AvatarColor} size={AvatarSize.LARGE}>
                {initials}
              </OAvatar>
              <H1 align="justify">{`${firstName} ${lastName}`}</H1>
            </Box>
            <Box>
              <InfoIconButton
                key="more-about-icon"
                ariaProps={{
                  'aria-label': `${t('More about users')}`,
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                }}
                onClick={() => showInfographicModal(HelpModalType.USERS)}
                fontSize="medium"
              />
            </Box>
          </Box>
        }
        main={
          <Paper style={{ padding: 24, minHeight: 380 }}>
            <Grid item xs={12}>
              <H2 style={{ marginBottom: 24 }}>{t('Profile details')}</H2>
            </Grid>
            <Grid item>
              <FeatureDefinitions definitions={definitions} />
            </Grid>
          </Paper>
        }
      />
    </ContentContainer>
  )
}

export default User
