import moment from 'moment-timezone'
import BigNumber from 'bignumber.js'
import {
  EntityUpdateMode,
  WithholdingRule,
  WithholdingRuleFormValues,
  WithholdingTaxRule,
  WithholdingTaxRuleFormValues,
} from 'store/withholdingTax/types'
import { RateTierBand } from 'store/products/types'
import { RateDividedByHundred, RateMultiplyByHundred } from 'utils/form.utils'

export const toTaxRuleFormValues = (taxRule: WithholdingTaxRule): WithholdingTaxRuleFormValues | null => ({
  ...taxRule,
  flatRate: taxRule.flatRate ? RateMultiplyByHundred(taxRule.flatRate) : taxRule.flatRate,
  tierBandCalcMethod: taxRule.tierBandCalcMethod as RateTierBand,
  taxTierBand: taxRule.taxTierBand
    ? taxRule.taxTierBand.map((i) => ({
        ...i,
        tierBandRate: i.tierBandRate ? RateMultiplyByHundred(i.tierBandRate) : i.tierBandRate,
        endRange: new BigNumber('100000000000000000000').isLessThanOrEqualTo(i.endRange as any)
          ? undefined
          : i.endRange,
      }))
    : taxRule.taxTierBand,
})

const checkInt = (values: (string | number)[]): (string | number)[] =>
  values.map((value) => parseInt(value as string, 10) || value)

export const toTaxRule = ({
  formValues,
  mode,
}: {
  formValues: WithholdingTaxRuleFormValues
  mode: EntityUpdateMode
}): WithholdingTaxRule & { mode: EntityUpdateMode } => {
  const taxTierBand =
    formValues.tierBandCalcMethod !== RateTierBand.FLAT && formValues?.taxTierBand && formValues.taxTierBand.length > 0
      ? formValues.taxTierBand?.map((i) => ({
          ...i,
          tierBandRate: i.tierBandRate ? RateDividedByHundred(i.tierBandRate, 4) : i.tierBandRate,
        }))
      : undefined

  const facts =
    formValues?.facts && formValues.facts.length > 0
      ? formValues.facts?.map((i) => ({
          ...i,
          value: i.value[0] === 'HELD_BY_BANK' ? [true] : checkInt(i.value),
        }))
      : formValues.facts

  const flatRate = formValues.flatRate ? RateDividedByHundred(formValues.flatRate, 4) : formValues.flatRate

  return {
    ...formValues,
    taxTierBand,
    flatRate,
    facts:
      mode === 'edit'
        ? undefined
        : facts?.map((fact) => ({
            ...fact,
            value: (fact.value as any[]).map((val) => {
              try {
                return JSON.parse(val as string)
              } catch (e: any) {
                return val
              }
            }),
          })),
    effectiveDate: formValues.effectiveDate
      ? moment(formValues.effectiveDate).format('YYYY-MM-DD')
      : formValues.effectiveDate,
    mode,
  }
}

export const toRule = (formValues: WithholdingRuleFormValues): WithholdingRule =>
  ({
    ...formValues,
    ...toTaxRule({ formValues, mode: 'create' }),
  } as any)
