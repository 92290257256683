import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { LoaderContainer } from '../Styles'

export default function TransactionsLoader() {
  const { t } = useTranslation()
  return (
    <LoaderContainer container direction="column" justifyContent="center" alignItems="center" justify-content="center">
      <Grid item>
        <CircularProgress size={75} />
      </Grid>
      <Grid item>
        <Typography variant="h5">{t('Loading transactions')}</Typography>
      </Grid>
    </LoaderContainer>
  )
}
