import styled from 'styled-components/macro'

export const CardLimitContainer = styled.div`
  & dd {
    flex: initial;
  }
  & dd:nth-last-of-type(3) {
    min-width: 150px;
  }
  & dd:nth-last-of-type(2) {
    flex: 1;
  }
  & dd:nth-last-of-type(1) {
    text-align: right;
    max-width: fit-content;
    align-self: auto;
    flex: 1;
  }
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      & dd:nth-last-of-type(2) {
      }
      & dd:nth-last-of-type(1) {
        align-self: center;
      }
      & dd > span {
        display: grid;
      }
    }
    ${theme.breakpoints.down('sm')} {
      & dt {
        min-width: 100%;
      }
    }
    ${theme.breakpoints.only('sm')} {
     
      & hr {
        margin-top: 12px !important;
      }
    }
  `};
`
