import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import { Dialog } from 'dls/atoms/Dialog/Dialog'
import { H3, H6 } from 'dls/atoms'
import { spacingBlockOutBoxItems, extraPadding } from 'dls/shared/styleVar'

export const HeadingGrid = styled(Grid)`
  &.MuiGrid-item {
    margin-right: 0;
    padding-right: 0;
    width: calc(100% - 80px);
  }
`
export const CloseIconGrid = styled(Grid)`
  &.MuiGrid-item {
    margin-left: 0;
    padding: 14px;
  }
`
export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1000px;
    margin: 32px;
  }
`
export const DescriptionSection = styled.div`
  margin: ${spacingBlockOutBoxItems};
`
export const ContentColumn = styled.div`
  ${({ theme }) => `
    margin: 0;
    ${theme.breakpoints.up('sm')} {
      flex: 1 1 2em;
      margin: 0 16px 0 0;
    }
  `};
  & ${DescriptionSection}: last-child {
    margin-right: 0;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & ${ContentColumn}: last-child {
    margin-right: 0;
  }
`
export const ContentRowContainer = styled.div`
  & ${ContentColumn}: last-child {
    margin-right: 0;
  }
`

export const ImageColumn = styled.div`
  ${({ theme }) => `
    margin: 0 ;
    ${theme.breakpoints.up('sm')} {
      flex: 1 1 2em;
    }
  `};
`
export const H3Styled = styled(H3)`
  padding: 0 0 ${extraPadding} 0;
`

export const H6Styled = styled(H6)`
  padding: 0 0 8px 0;
`

export const ImageSection = styled.div`
  margin: 0;
`

export default {
  H3Styled,
  H6Styled,
  DescriptionSection,
  HeadingGrid,
  CloseIconGrid,
  StyledDialog,
  ContentContainer,
  ContentRowContainer,
  ContentColumn,
  ImageSection,
  ImageColumn,
}
