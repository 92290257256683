import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { createRouterReducer, ReduxRouterState, RouterActions } from '@lagunovsky/redux-react-router'
import { Reducer } from 'react'
import products from './products/reducer'
import user from './user/reducer'
import tenantConfig from './tenant-config/reducer'
import activity from './activities/reducer'
import breadcrumbs from './breadcrumbs/reducer'
import contents from './contents/reducer'
import packages from './packages/reducer'
import notification from './notifications/reducer'
import modal from './modal/reducer'
import tasks from './tasks/reducer'
import governance from './governance/reducer'
import referenceData from './referenceData/reducer'
import suspenseAccounts from './accounts/reducer'
import taxRules from './withholdingTax/reducer'
import statement from './statement/reducer'
import { browserHistory } from './router/history'
import statementConfiguration from './templates/statementConfiguration/reducer'
import productSyndications from 'store/productSyndications/reducer'
import { RootState } from 'store'

const userPersistConfig = {
  storage,
  key: 'userdata',
  whitelist: ['authenticated', 'data', 'authType'],
}

const configPersistConfig = {
  storage,
  key: 'theme',
  whitelist: ['data'],
}

const router: Reducer<ReduxRouterState, RouterActions> = createRouterReducer(browserHistory)

const rootReducer = combineReducers<RootState>({
  products,
  activity,
  breadcrumbs,
  user: persistReducer(userPersistConfig, user),
  tenantConfig: persistReducer(configPersistConfig, tenantConfig),
  contents,
  packages,
  notification,
  modal,
  tasks,
  governance,
  referenceData,
  suspenseAccounts,
  taxRules,
  statement,
  router: router as any,
  statementConfiguration,
  productSyndications,
})

export default rootReducer
