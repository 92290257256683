import { call, put, delay, takeLatest } from 'redux-saga/effects'
import BankInterestRateActions from './actions'
import { navigate } from 'store/router/actions'
import { navigateToUnavailable } from 'utils/url.utils'
import ModalActions from 'store/modal/actions'
import { ReferenceDataApi } from 'api'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'

function* showInProgress() {
  yield put(ModalActions.showFeatureProgressModal())
}

function* showSuccess(successResponse: { featureName: string; bankIndexKey: string }) {
  yield put(navigate(`/reference-data/index-rates/${successResponse.bankIndexKey || ''}` as any))
  yield delay(UI_DELAY_TO_SHOW_LOADING)
  yield put(ModalActions.closeModal())
  if (successResponse) yield put(ModalActions.showFeatureSuccessModal(successResponse as any))
}

function* showError(e: Error) {
  yield put(ModalActions.closeModal())
  yield put(ModalActions.showFeatureFailureModal(e))
}

function* getBankInterestRate(action: ReturnType<typeof BankInterestRateActions.fetchBankInterestRate>) {
  try {
    const response: ReturnTypePromise<typeof ReferenceDataApi.fetchBankInterestRate> = yield call(
      ReferenceDataApi.fetchBankInterestRate
    )
    yield put(BankInterestRateActions.fetchBankInterestRateSuccess(response))
  } catch (e: any) {
    yield put(BankInterestRateActions.fetchBankInterestRateFail(e))
    if (action?.payload?.shouldRedirect ?? true) {
      yield call(navigateToUnavailable)
    }
  }
}

function* getIndexRateDetails(action: ReturnType<typeof BankInterestRateActions.getBankIndexRateDetails>) {
  try {
    const response: ReturnTypePromise<typeof ReferenceDataApi.getIndexRateDetails> = yield call(
      ReferenceDataApi.getIndexRateDetails,
      action.payload
    )
    yield put(BankInterestRateActions.getBankIndexRateDetailsSuccess(response))
  } catch (e: any) {
    yield put(BankInterestRateActions.getBankIndexRateDetailsFail(e))
    yield put(navigate(`/not-found` as any))
  }
}

function* editRate(action: ReturnType<typeof BankInterestRateActions.editRate>) {
  const {
    payload: { bankIndexKey, ...data },
  } = action

  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof ReferenceDataApi.editRate> = yield call(
      ReferenceDataApi.editRate,
      bankIndexKey,
      data
    )
    const updatedRate: ReturnTypePromise<typeof ReferenceDataApi.getIndexRateDetails> = yield call(
      ReferenceDataApi.getIndexRateDetails,
      bankIndexKey
    )
    yield put(BankInterestRateActions.editRateSuccess(updatedRate))
    yield showSuccess({ featureName: 'Index rates', bankIndexKey: response.bankIndexKey || '' })
  } catch (e: any) {
    yield put(BankInterestRateActions.editRateFail(e))
    yield showError(e)
  }
}

function* createBankInterestRate({ payload }: ReturnType<typeof BankInterestRateActions.createBankInterestRate>) {
  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof ReferenceDataApi.createBankInterestRate> = yield call(
      ReferenceDataApi.createBankInterestRate,
      payload
    )
    yield put(BankInterestRateActions.createBankInterestRateSuccess(response))
    yield showSuccess({ featureName: 'Index rates', bankIndexKey: response.bankIndexKey || '' })
  } catch (e: any) {
    yield put(BankInterestRateActions.createBankInterestRateFail(e))
    yield showError(e)
  }
}

function* addRate(action: ReturnType<typeof BankInterestRateActions.addRate>) {
  const {
    payload: { bankIndexKey, ...data },
  } = action

  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof ReferenceDataApi.addRate> = yield call(
      ReferenceDataApi.addRate,
      bankIndexKey,
      data
    )
    const updatedRate: ReturnTypePromise<typeof ReferenceDataApi.getIndexRateDetails> = yield call(
      ReferenceDataApi.getIndexRateDetails,
      bankIndexKey
    )
    yield put(BankInterestRateActions.addRateSuccess(updatedRate))
    yield showSuccess({ featureName: 'Index rates', bankIndexKey: response.bankIndexKey || '' })
  } catch (e: any) {
    yield put(BankInterestRateActions.addRateFail(e))
    yield showError(e)
  }
}

export default function* () {
  yield takeLatest(BankInterestRateActions.fetchBankInterestRate.type, getBankInterestRate)
  yield takeLatest(BankInterestRateActions.getBankIndexRateDetails.type, getIndexRateDetails)
  yield takeLatest(BankInterestRateActions.editRate.type, editRate)
  yield takeLatest(BankInterestRateActions.createBankInterestRate.type, createBankInterestRate)
  yield takeLatest(BankInterestRateActions.addRate.type, addRate)
}
