import React from 'react'
import styled from 'styled-components/macro'
import { CircularProgress } from '@material-ui/core'

const LoaderWrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ shrink }) =>
    shrink
      ? 'inherit'
      : '100vh'}; /* this just expands the body height so the vertical alignment is visible in the snippet */
`

type Props = {
  shrink?: boolean
  size?: number
  color?: 'inherit' | 'primary' | 'secondary'
  style?: React.CSSProperties
}

const Loader = ({ shrink = false, size = 75, color = 'primary', style }: Props): React.ReactElement => (
  <LoaderWrapper shrink={shrink} style={style}>
    <CircularProgress size={size} color={color} />
  </LoaderWrapper>
)

export default Loader
