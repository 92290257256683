import { Grid, Typography } from '@material-ui/core'
import { Field, Form } from 'formik'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { ContentGridContainer, InputType, ContentPaperContainer } from '../Styled'
import { SubscriptionStatus, SubcriptionCreationPayload, ProductKey } from 'store/products/types'
import { withFormikSimple } from 'utils/form.utils'
import { FormBottomBar, RadioGroupFieldComplex } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  productKey: ProductKey
}

const SubscriptionCreationRulesForm = (props: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { productKey } = props

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Subscription creation rules')}
      description={t('Define the account creation rules for this product')}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                {t('Account status')}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {t(
                  "'Active' means the account will be ready for the customer to use once it’s opened. 'Provisioned' means that the account is available for the customer to use if they wish, the customer can activate a provisioned account themselves."
                )}
              </Typography>
              <InputType>
                <Field
                  name="defaultSubscriptionStatus"
                  component={RadioGroupFieldComplex}
                  required
                  data={SubscriptionStatus}
                />
              </InputType>
            </Grid>
            <Grid item xs={12}>
              <FormBottomBar inlineNavigation>
                <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                  {t('Cancel')}
                </Button>
              </FormBottomBar>
            </Grid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, SubcriptionCreationPayload>(SubscriptionCreationRulesForm)
