import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import useCreditLimit from './utils/useCreditLimit'
import { TemplateForm, InfoIconButton, Input } from 'dls'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { H2, EntityBadgeCreditLimit, Paper } from 'dls/atoms'
import { Loader } from 'components'
import { internalMdPadding } from 'dls/shared/styleVar'
import { AvatarSize } from 'dls/shared/types'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'

const CreditLimitForm = () => {
  const { productKey = '' } = useParams()

  const [product, isLoading] = useSelectedProduct(productKey)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { submitCreditLimit } = useCreditLimit({
    productKey,
    version: product?.version || 1,
  })

  const { showInfographicModal } = useModal()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        minMaxCreditLimit: Yup.object({
          maximum: Yup.number()
            .typeError('This is not number')
            .required('Value required')
            .positive('Must be greater than 0')
            .integer('Max amount must be an integer')
            .lessThan(9999999, 'Must be less than 9999999')
            .min(Yup.ref('minimum'), 'The max amount must be greater than or equal to the min amount'),
          minimum: Yup.number()
            .typeError('This is not number')
            .required('Value required')
            .positive('Must be greater than 0')
            .integer('Min amount must be an integer')
            .lessThan(9999999, 'Must be less than 9999999'),
        }),
      }).required()
    ),
    mode: 'onBlur',
    defaultValues: product?.minMaxCreditLimit,
  })

  useEffect(() => {
    reset(product?.minMaxCreditLimit)
  }, [product])

  if (isLoading || !product) {
    return <Loader />
  }
  const onSubmit = handleSubmit((values) => submitCreditLimit(values))
  return (
    <>
      <TemplateForm
        id="credit-limit-min-max"
        main={
          <section aria-labelledby="credit-limit-min-max-heading">
            <Paper>
              <H2 gutter id="credit-limit-min-max-heading">
                {t('Min and max amount')}
              </H2>
              <form onSubmit={onSubmit}>
                <Grid container spacing={3} style={{ marginBottom: internalMdPadding }}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="minMaxCreditLimit.minimum"
                      control={control}
                      rules={{ deps: ['minMaxCreditLimit.maximum'] }}
                      render={({ field }) => (
                        <Input
                          id="minimum"
                          type="currency"
                          label={t('Min amount')}
                          placeholder={t('Enter amount')}
                          currencyCode={product.currency?.currencyCode}
                          numberFormatProps={{ thousandSeparator: true }}
                          positiveIntegersOnly
                          required
                          fullWidth
                          error={!!errors.minMaxCreditLimit?.minimum}
                          errorText={errors.minMaxCreditLimit?.minimum?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="minMaxCreditLimit.maximum"
                      control={control}
                      rules={{ deps: ['minMaxCreditLimit.minimum'] }}
                      render={({ field }) => (
                        <Input
                          id="maximum"
                          type="currency"
                          label={t('Max amount')}
                          placeholder={t('Enter amount')}
                          currencyCode={product.currency?.currencyCode}
                          numberFormatProps={{ thousandSeparator: true }}
                          positiveIntegersOnly
                          required
                          fullWidth
                          error={!!errors.minMaxCreditLimit?.maximum}
                          errorText={errors.minMaxCreditLimit?.maximum?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </section>
        }
        title={t('Credit limit')}
        description={t('Here you can set the min and max amounts of credit for your product')}
        toolbarButtons={
          <Grid container direction="row" justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <InfoIconButton
                ariaProps={{ 'aria-label': 'About credit limit' }}
                onClick={() => showInfographicModal(HelpModalType.CREDIT_LIMIT)}
              />
            </Grid>
          </Grid>
        }
        icon={<EntityBadgeCreditLimit size={AvatarSize.LARGE} />}
        mainProps={{ style: {} }}
        headerProps={{ style: {}, 'aria-label': 'Credit limit' }}
        submitButtonDisabled={!isValid || !isDirty}
        onSubmit={onSubmit}
        onCancel={() => navigate('./../..')}
      />
    </>
  )
}

export default CreditLimitForm
