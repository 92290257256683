import React from 'react'
import { ComponentStory } from '@storybook/react'

interface OwnProps {
  component: any
  args?: any
  argTypes?: any
  parameters?: any
}

export const GenericTemplateCreator = ({ component: ReactComponent, args, argTypes, parameters }: OwnProps) => {
  const Template: ComponentStory<typeof ReactComponent> = (props: any) => <ReactComponent {...props} />
  const ComponentWithSB = Template.bind({})

  if (args) ComponentWithSB.args = args
  if (argTypes) ComponentWithSB.argTypes = argTypes
  if (parameters) ComponentWithSB.parameters = parameters

  return ComponentWithSB
}
