import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectBreadcrumbsState = (state: RootState) => state.breadcrumbs

const selectBreadcrumbs = createSelector(selectBreadcrumbsState, (state) => state.pageTitleAndUrl)

export default {
  selectBreadcrumbs,
}
