import React, { FunctionComponent } from 'react'
import { Box, useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { HelpUl, WidgetLi, LinksWrapper } from './styles'
import { Link } from 'dls/atoms/Link'
import WidgetContainer from 'dls/organisms/WidgetContainer'
import { EntityBadgeHelp, Body2 } from 'dls/atoms'

interface OwnProps {
  widgetTitle: string
  showViewAll?: boolean
  showEntityIcons?: boolean
  path?: string
  helpSections: {
    label: string
    url: string
    icon?: FunctionComponent
  }[]
  buttonComponentType: React.ElementType<any>
}

const HelpMenu = ({
  widgetTitle,
  helpSections,
  showViewAll = false,
  showEntityIcons = false,
  path = '',
  buttonComponentType: ButtonComponentType,
}: OwnProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <WidgetContainer
      title={widgetTitle}
      icon={<EntityBadgeHelp />}
      stretchHeightToContainer
      style={{ minHeight: 'unset' }}
      viewAllCta={showViewAll ? '/help' : undefined}
      widgetId="help-widget"
      buttonComponentType={ButtonComponentType}
    >
      <HelpUl data-dls="organism-help-menu">
        {helpSections.map(({ label, url, icon: HelpBadge }, index) => {
          const isFirst = index === 0
          const isFirstTwo = index <= 1
          const isLast = index === helpSections.length - 1
          const isLastTwo = index >= helpSections.length - 2
          const isEven = helpSections.length % 2 === 0
          const LiContentWrapper = isFirstTwo ? LinksWrapper : Box
          const noBorder = (isLarge && isLastTwo && isEven) || (isLarge && !isEven && isLast) || (!isLarge && isLast)
          const isCurrentPage = !!path && !!url && url.split('/').includes(path)
          return (
            <WidgetLi key={`${label}-${index}`} style={noBorder ? { borderBottom: 0 } : {}}>
              <LiContentWrapper
                style={{
                  ...(isLarge && isFirstTwo
                    ? { marginTop: 8 }
                    : { marginTop: 0, ...(!isFirst ? { borderTop: 'none', paddingTop: 0 } : { marginTop: 12 }) }),
                }}
              >
                <Box display="flex" alignItems="center" gridColumnGap={16}>
                  {showEntityIcons && HelpBadge && <HelpBadge />}
                  {isCurrentPage ? (
                    <Body2 color="textSecondary">{t(label)}</Body2>
                  ) : (
                    <Link
                      component={ButtonComponentType}
                      color="secondary"
                      variant="body2"
                      to={url}
                      aria-controls="main"
                    >
                      {t(label)}
                    </Link>
                  )}
                </Box>
              </LiContentWrapper>
            </WidgetLi>
          )
        })}
      </HelpUl>
    </WidgetContainer>
  )
}

export default HelpMenu
