import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { isEmpty } from 'lodash'
import { DisplayTitleValue } from 'components'
import CopyButton from 'components/CopyButton/CopyButton'

const GridStyle = styled(Grid)`
  margin-left: -24px;
`

const StyledGrid = styled(Grid)`
  margin-left: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
`
const AemDocumentView = ({ description, version, files }: { description: string; version: string; files: any[] }) => {
  const { t } = useTranslation()
  return (
    <Grid container direction="column">
      <GridStyle container>
        {description !== null && description && (
          <DisplayTitleValue title={t('Description')} valuesDisplay={description} />
        )}
        {version !== null && version && <DisplayTitleValue title={t('Version')} valuesDisplay={version} />}

        {files && files[0] !== null && !isEmpty(files) && (
          <StyledGrid>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {t('PDF label and URL')}
            </Typography>
            <Typography
              variant="subtitle1"
              css={`
                word-break: break-all;
              `}
              gutterBottom
            >
              {t(files[0].label)}
            </Typography>
            <Typography
              display="inline"
              variant="subtitle1"
              css={`
                word-break: break-all;
              `}
              gutterBottom
            >
              {t(files[0].url)}
            </Typography>
            <CopyButton
              payload={files[0].url}
              extraButtonProps={{ style: { whiteSpace: 'nowrap', margin: '0 0 0 2px', float: 'none' } }}
              size="small"
            />
          </StyledGrid>
        )}
        {files && files[1] !== null && !isEmpty(files) && (
          <StyledGrid>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {t('HTML label and URL')}
            </Typography>
            <Typography
              variant="subtitle1"
              css={`
                word-break: break-all;
              `}
              gutterBottom
            >
              {files[1].label}
            </Typography>
            <Typography
              display="inline"
              variant="subtitle1"
              css={`
                word-break: break-all;
              `}
              gutterBottom
            >
              {files[1].url}
            </Typography>
            <CopyButton
              payload={files[1].url}
              extraButtonProps={{ style: { whiteSpace: 'nowrap', margin: '0 0 0 2px', float: 'none' } }}
              size="small"
            />
          </StyledGrid>
        )}
      </GridStyle>
    </Grid>
  )
}

export default AemDocumentView
