import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { GroupHeadingMenuItem, FormInputProps, StyledMenuItem } from './shared'
import { Select } from 'dls'
import { FeeRewardDataPointType } from 'store/products/features/feesRewards/types'
import { SelectOption } from 'dls/shared/types'
import { DataPointAggregatedOperator, DataPointOperator } from 'store/products/typings/conditionBuilder'
import { FeeRewardFeatureType } from 'store/products/types'

const SecondOperatorSelect = ({
  control,
  watch,
  onChange,
  featureType,
}: FormInputProps & { featureType: FeeRewardFeatureType }): JSX.Element | null => {
  const { t } = useTranslation()

  const dataPoint = watch('dataPoint')
  const dataPointFirstOperator = watch('dataPointFirstOperator')

  const options = useMemo((): SelectOption[] | null => {
    if (
      Object.values(DataPointAggregatedOperator).includes(dataPointFirstOperator as DataPointAggregatedOperator) ||
      [FeeRewardDataPointType.TRANSACTION_ISO_CODE].includes(dataPoint)
    ) {
      switch (dataPoint) {
        case FeeRewardDataPointType.BALANCE_END_OF_DAY_AMOUNT:
        case FeeRewardDataPointType.BALANCE_UNIQUE_ENTRY:
        case FeeRewardDataPointType.TRANSACTION_AMOUNT:
        case FeeRewardDataPointType.TRANSACTION_UNIQUE_ENTRY:
          return [
            ...Object.values(DataPointOperator).map((op) => ({
              value: op,
              label: t(`CONDITION_BUILDER.${op}`),
            })),
          ]
        case FeeRewardDataPointType.TRANSACTION_IS_CREDIT:
        default:
          return null
      }
    }

    return null
  }, [dataPoint, dataPointFirstOperator])

  if (options) {
    return (
      <Grid item xs={12} sm={6}>
        <Controller
          name="dataPointSecondOperator"
          control={control}
          render={({ field }) => (
            <Select
              label={t(`PRODUCT_FEATURE.${featureType}.IS`)?.toLocaleLowerCase()}
              placeholder={t('Please select')}
              required
              displayEmpty
              fullWidth
              {...field}
              onChange={(e) => {
                onChange(() => field.onChange(e))
              }}
            >
              {options.map(({ value, label, groupHeading }) =>
                groupHeading ? (
                  <GroupHeadingMenuItem key={value} value={value}>
                    {label}
                  </GroupHeadingMenuItem>
                ) : (
                  <StyledMenuItem key={value} value={value}>
                    {label}
                  </StyledMenuItem>
                )
              )}
            </Select>
          )}
        />
      </Grid>
    )
  }

  return null
}

export default SecondOperatorSelect
