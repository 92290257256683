import { TextField } from '@material-ui/core'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { exceptionReasons, FieldType, tinOptions } from '../../../hooks/constants'
import { NumberFormatCustom } from 'components/form/TextFieldComplex'
import MultiSelectAutocomplete from 'components/form/MultiSelectAutocomplete/'
import { allCountriesWithFlags } from 'utils/constants'
import SelectFieldSimple from 'components/form/SelectFieldSimple'
import AmountFieldSimple from 'components/form/AmountFieldSimple'
import { AutoCompleteSelectFieldItem } from 'components/form/MultiSelectAutocomplete/types'

interface Props {
  type: FieldType
  onChange: (e: ChangeEvent, value?: string) => void
  value: string | string[]
  currencyCode: string
  operator: string
  error: boolean
  helperText: string
}

const TaxRuleValueField = ({ currencyCode, onChange, operator, type, value: formValue, error, helperText }: Props) => {
  const { t } = useTranslation()
  const shouldShowMultiple = operator !== 'IS'
  const multiSelectTitle = shouldShowMultiple ? t('These') : t('This')
  let sanitisedValue: null | string[] | string
  if (formValue === [] || formValue === undefined || formValue === '') {
    sanitisedValue = shouldShowMultiple ? formValue : null
  } else {
    sanitisedValue = formValue
  }

  switch (type) {
    case FieldType.Countries:
    case FieldType.ExceptionReasons:
      return (
        <MultiSelectAutocomplete
          chipLabel={(item: AutoCompleteSelectFieldItem) => (typeof item === 'string' ? item : item.key)}
          data={(type === FieldType.Countries ? allCountriesWithFlags : exceptionReasons) as any}
          disabled={operator === ''}
          hasFlag={type === FieldType.Countries}
          label={multiSelectTitle}
          multiple={shouldShowMultiple}
          onChange={(e, value) => onChange({ target: { value } } as any)}
          value={sanitisedValue as any}
          inputProps={{ style: { minHeight: shouldShowMultiple ? 55 : 32 } }}
          required
        />
      )
    case FieldType.Amount:
      return (
        <AmountFieldSimple
          id="rule-value"
          label={t('This')}
          onChange={onChange}
          value={formValue[0]}
          currencyCode={currencyCode}
          required
        />
      )
    case FieldType.TIN:
      return (
        <SelectFieldSimple
          id="rule-value"
          label={t('Is')}
          value={formValue as string}
          onChange={onChange}
          options={tinOptions}
          required
        />
      )
    case FieldType.Number:
      return (
        <TextField
          id="rule-value"
          label={t('This')}
          type="text"
          InputProps={
            {
              inputProps: { min: 0, decimalSeparator: false },
              inputComponent: NumberFormatCustom,
            } as any
          }
          InputLabelProps={{ shrink: true }}
          onChange={onChange}
          value={formValue[0]}
          error={error}
          helperText={helperText}
          fullWidth
          required
        />
      )
    case FieldType.Text:
    default:
      return (
        <TextField
          id="rule-value"
          label={t('This')}
          type="text"
          InputProps={{ inputProps: { min: 0 } }}
          InputLabelProps={{ shrink: true }}
          onChange={onChange}
          value={formValue}
          error={error}
          helperText={helperText}
          fullWidth
          required
        />
      )
  }
}

export default TaxRuleValueField
