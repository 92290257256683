import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom'
import { Link } from 'dls/atoms/Link'
import { TemplateForm, InfoIconButton } from 'dls'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { H2, EntityBadgeProductSyndication, Body2, Paper } from 'dls/atoms'
import { Loader } from 'components'
import { AvatarSize } from 'dls/shared/types'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'

const SetupProductSyndicationPage = () => {
  const { productKey = '', version } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showInfographicModal } = useModal()

  if (isLoading || !product) {
    return <Loader />
  }

  return (
    <TemplateForm
      id="product-syndication"
      main={
        <section aria-labelledby="product-syndication-heading">
          <Paper elevation={0} responsivePadding>
            <H2 style={{ marginBottom: 24 }} id="product-syndication-heading">
              {t('How it works')}
            </H2>
            <Body2>
              {t(
                'Choose ‘Create’ to be directed into the  product syndication product syndication area of Bank Manager where you can configure and manage the product’s meta data.'
              )}
            </Body2>
          </Paper>
        </section>
      }
      cancelButtonText="Cancel"
      submitButtonText="Create"
      title={t('Product syndication')}
      description={
        <>
          {t(
            'This feature allows you to create product syndication meta data so that you can syndicate your product to third parties. The meta data will be created in the '
          )}
          <Link component={RouterLink} color="secondary" to={`/products/${productKey}`}>
            {t('product syndication')}
          </Link>
          {t(' area of bank manager and contextually shown along-side the product.')}
        </>
      }
      toolbarButtons={
        <Grid container direction="row" justifyContent="flex-end" wrap="nowrap">
          <Grid item>
            <InfoIconButton
              ariaProps={{
                'aria-haspopup': 'dialog',
                'aria-controls': 'more-about-dialog',
                'aria-label': 'About product syndication',
              }}
              onClick={() => showInfographicModal(HelpModalType.PRODUCT_SYNDICATION)}
            />
          </Grid>
        </Grid>
      }
      icon={<EntityBadgeProductSyndication size={AvatarSize.LARGE} />}
      headerProps={{ 'aria-label': 'Product syndication' }}
      onCancel={() => navigate(`/products/${productKey}`)}
      onSubmit={() => navigate(`/product-syndication/${productKey}/versions/${version}/create`)}
    />
  )
}

export default SetupProductSyndicationPage
