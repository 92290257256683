import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Form, Field, FormikProps } from 'formik'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { withFormikSimple, Yip } from 'utils/form.utils'
import { ApproveTask } from 'store/tasks/types'
import { SubmitButton, TextFieldComplex } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  setClosed: () => void
  updating: boolean
}

const ButtonProgress = styled(CircularProgress)`
  position: relative;
  color: ${(props: any) => props.theme.palette.secondary};
  top: 8px;
  left: -46%;
  margin-top: -12px;
  margin-left: -12px;
  z-index: 999999;
`
const ActionContainer = styled(Grid)`
  padding-top: 20px;
  padding-right 0px;
  padding-bottom 6px;
  position: relative;
`

const StyledSubmitButton = styled(SubmitButton)`
  width: 150px;
`

interface ProductApproveModalProps extends FormikProps<ApproveTask>, OwnProps {}

const ProductApproveModalForm = (props: ProductApproveModalProps) => {
  const { t } = useTranslation()

  const { setClosed } = props
  return (
    <Form>
      <Grid container spacing={0} direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid>
          <Field
            name="message"
            label={t('Reason for approval')}
            fullWidth
            type="text"
            component={TextFieldComplex}
            inputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={5}
            // validate={Yip(Yup.string().required().max(10))}
            validate={Yip(
              Yup.string()
                .required()
                .test(
                  'len',
                  'You’ve exceed the 1000 character limit – please reduce the number of characters',
                  (val) => val.length <= 1000
                )
            )}
            required
            disabled={props.updating}
            placeholder={t('Enter a reason')}
            helperText={t('Maximum character limit is 1000 characters')}
            margin="none"
            css="margin: 0.2em 0"
          />
        </Grid>
      </Grid>
      <ActionContainer container spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid item>
          <Button color={ColorType.BUTTON} onClick={setClosed} disabled={props.updating}>
            {t('Cancel')}
          </Button>
        </Grid>
        <Grid item>
          <StyledSubmitButton
            variant="contained"
            data-test-id="submit"
            color={ColorType.BUTTON}
            disabled={props.updating}
          >
            {t('Submit')}
          </StyledSubmitButton>
          {props.updating && <ButtonProgress size={24} />}
        </Grid>
      </ActionContainer>
    </Form>
  )
}

export default withFormikSimple<OwnProps, ApproveTask>(ProductApproveModalForm, {})
