import React, { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import userTableHeaderRows from '../utils/getUserTableHeaderRows'
import { Colleague, UserFilters } from 'store/user/types'
import { TableCommonDataType, TableCommonGetKeyString, TableCommonGetSearchString } from 'components/TableCommon/types'
import Table from 'components/TableCommon/Table'
import getSearchString from 'pages/Users/utils/getSearchString'
import getKeyString from 'pages/Users/utils/getkeyString'
import { UserSearchActions } from 'pages/Users/Users/reducer/actions'
import { NoUsersFound } from 'assets'

interface OwnProps {
  users: Colleague[]
  setFilters: Dispatch<UserSearchActions>
  filters: UserFilters
  searchText?: string
}

const UserTable = ({ users, setFilters, searchText, filters }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Table
      data={users as TableCommonDataType[]}
      headRows={userTableHeaderRows(setFilters, filters, t)}
      getSearchString={getSearchString as TableCommonGetSearchString}
      getKeyString={getKeyString as TableCommonGetKeyString}
      hideHeader
      hidePagination
      disableInsideSortPagination
      NoRowImage={NoUsersFound}
      searchText={searchText}
      noRowImageAlt={t('Illustration of a man looking into an empty box')}
      noRowDescription={t('Oops we couldn’t find any users – clear your search/filters to see all')}
      noEmptyRows
    />
  )
}
export default UserTable
