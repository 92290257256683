/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import { Field, FormikProps } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardActions,
  Dialog,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import styled from 'styled-components/macro'
import { TFunction } from 'i18next'
import Sticky from 'react-sticky-el'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useNavigate } from 'react-router-dom'
import StatusInformationModal from '../TransactionsModal/StatusInformationModal'
import { StyledTrendingUp, StyledTrendingDown, StyledTrendingFlat } from '../../SuspenseAccountsComps'
import { ExpandedContent } from './ExpandedContent'
import { getTransactionStatus } from './TransactionsTableHeadRows'
import { toBankTz } from 'utils/date.utils'
import { withFormikSimple } from 'utils/form.utils'
import {
  TransactionTransferRequest,
  SuspenseTransactionsTransferResult,
  SuspenseTransactionItem,
  SubscriptionKey,
  ConfirmTransactionResultType,
  SuspenseAccount,
} from 'store/accounts/types'
import { EnhancedTableField, FailureModal, ProgressModal, SuccessModal } from 'components'
import {
  TableCommonDataType,
  TableCommonGetKeyString,
  TableCommonGetSearchString,
  TableCommonHeaderType,
} from 'components/TableCommon/types'
import { getFormattedCurrency } from 'utils/ui.utils'
import { PLACCOUNTNAME, NOSTRO_WASH_ACCOUNTNAME } from 'store/utils/constants'
import { getCompactTransactionId } from 'api/utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { KeyboardArrowDownButton, KeyboardArrowUpButton } from 'dls/molecules/IconButtons/IconButtons'

interface OwnProps {
  selectedSuspenseTransactionsTransferResult?: SuspenseTransactionsTransferResult
  subscriptionKey: SubscriptionKey
  selectedConfirmTransactionLoading: boolean
  selectedConfirmTransactionResult: ConfirmTransactionResultType | undefined
  confirmTransactionResultClear: () => void
}

type Props = FormikProps<TransactionTransferRequest>

const PositivAmount = styled.span`
  margin-left: 8px;
`
const StyledGrid = styled(Grid)`
  width: 100%;
`
const StyledList = styled(List)`
  margin-left: -16px;
`
const StyledContainerPaper = styled(Paper)`
  margin: 8px 0;
`
const StyledCard = styled.div`
  padding: 24px 8px 24px 24px;
`

const FormBottom = styled.div``
const BottomButtonContainer = styled(Grid)`
  margin: 16px 8px 16px 16px;
`
const StyledWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`

const StyledTypography = styled(Typography)`
  font-weight: 300;
  font-size: 3rem;
  line-height: 1.167;
`

const StyledTableCell1 = styled(TableCell)`
  width: 23%;
  border-bottom: none;
  border-left: 4px solid;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding: 0 16px 0 10px;
  border-left-color: ${(props: any) => props.theme.palette.text.secondary};
`
const StyledTableCell2 = styled(TableCell)`
  width: 23%;
  border-bottom: none;
  border-left: 4px solid;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding: 0 16px 0 10px;
  border-left-color: ${(props: any) => props.theme.palette.error.dark};
`
const StyledTableCell3 = styled(TableCell)`
  width: 23%;
  border-bottom: none;
  border-left: 4px solid;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding: 0 16px 0 10px;
  border-left-color: ${(props: any) => props.theme.palette.success.main};
`
const StyledTableCell4 = styled(TableCell)`
  width: 31%;
  border-bottom: none;
  text-align: end;
  overflow-wrap: anywhere;
  line-height: 1.2;
  vertical-align: baseline;
  padding: 0 16px 0 10px;
`

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`
const StyleDigit = styled.span`
  font-size: 2rem;
`

const getSearchString = (item: SuspenseTransactionItem) => `${item.transactionId}`
const getKeyString = (item: SuspenseTransactionItem) => `${item.transactionId}`

const ReviewTransactions = (props: OwnProps & Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openStatusInfo, setOpenStatusInfo] = useState<boolean>(false)
  const suspenseAccountName =
    props.selectedSuspenseTransactionsTransferResult && props.selectedSuspenseTransactionsTransferResult.suspenseAccount
      ? props.selectedSuspenseTransactionsTransferResult.suspenseAccount.name
      : ''
  const destinationAccountName =
    props.selectedSuspenseTransactionsTransferResult?.destinationAccount &&
    [PLACCOUNTNAME, NOSTRO_WASH_ACCOUNTNAME].includes(
      props.selectedSuspenseTransactionsTransferResult.destinationAccount as string
    )
      ? t(props.selectedSuspenseTransactionsTransferResult.destinationAccount as string)
      : props.selectedSuspenseTransactionsTransferResult?.destinationAccount
      ? (props.selectedSuspenseTransactionsTransferResult.destinationAccount as SuspenseAccount).name
      : ''
  const totalTransactions =
    props.selectedSuspenseTransactionsTransferResult &&
    props.selectedSuspenseTransactionsTransferResult.selectedTransactions.length
  const debitTransactions =
    props.selectedSuspenseTransactionsTransferResult &&
    props.selectedSuspenseTransactionsTransferResult.selectedTransactions.filter((item) => !item.isCredit).length
  const creditTransactions =
    props.selectedSuspenseTransactionsTransferResult &&
    props.selectedSuspenseTransactionsTransferResult.selectedTransactions.filter((item) => item.isCredit).length

  const totalBalance = props.selectedSuspenseTransactionsTransferResult?.selectedTransactions.reduce(
    (a, b) => (b.isCredit ? a + b.amount.amount : a - b.amount.amount),
    0
  )

  const currency =
    props.selectedSuspenseTransactionsTransferResult &&
    props.selectedSuspenseTransactionsTransferResult.selectedTransactions[0].amount.currency

  const handleCancel = () => {
    navigate(`/accounts/${props.subscriptionKey}`)
  }
  const postedTimestampToDate = (timestamp: string) => toBankTz(timestamp).date

  const onCloseStatusModal = () => {
    setOpenStatusInfo(false)
  }

  const headRows = ({
    t: t2,
    setOpenStatusInfo: setOpenStatusInfoInner,
  }: {
    t: TFunction
    setOpenStatusInfo: (status: boolean) => void
  }): TableCommonHeaderType[] => [
    {
      id: 'transactionId',
      align: 'left',
      label: 'Reference',
      disableOrder: true,
      css: 'width: 20%; padding-left:24px',
      getCellContent: (row) => {
        const { transactionId } = row as SuspenseTransactionItem
        return (
          <Tooltip title={`${transactionId}`} placement="right">
            <span>{getCompactTransactionId(transactionId)}</span>
          </Tooltip>
        )
      },
    },
    {
      id: 'postedTimeStamp',
      align: 'left',
      label: 'All dates',
      disableOrder: true,
      css: 'width: 20%;',
      getCellContent: (row) => {
        const { postedTimeStamp } = row as SuspenseTransactionItem
        return postedTimeStamp ? postedTimestampToDate(postedTimeStamp) : ''
      },
    },
    {
      id: 'transactionStatus',
      align: 'left',
      label: (
        <StyledList onClick={() => setOpenStatusInfoInner(true)}>
          <ListItem button aria-haspopup="true" aria-controls="filter-menu">
            <ListItemText
              primary={
                <span arial-label="Information about statuses">
                  {t('Status')}
                  <HelpOutlineIcon css="vertical-align:middle;margin-left:8px" />
                </span>
              }
              style={{ whiteSpace: 'nowrap' }}
              primaryTypographyProps={{ style: { fontSize: 14 } }}
            />
          </ListItem>
        </StyledList>
      ),
      css: 'width: 20%;',
      disableOrder: true,
      getCellContent: (row) => {
        const { transactionStatus } = row as SuspenseTransactionItem
        return getTransactionStatus(transactionStatus, t2)
      },
    },
    {
      id: 'isCredit',
      align: 'left',
      disableOrder: true,
      label: 'Credits & debits',
      css: 'width: 20%;',
      getCellContent: (row) => {
        const { isCredit } = row as SuspenseTransactionItem
        return isCredit ? t2('Credit') : t2('Debit')
      },
    },
    {
      id: 'amount',
      align: 'left',
      label: 'All amounts',
      disableOrder: true,
      css: 'width: 20%;',
      getCellContent: (row) => {
        const { amount, isCredit } = row as SuspenseTransactionItem
        return isCredit ? (
          <PositivAmount>{getFormattedCurrency(amount.amount, amount.currency)}</PositivAmount>
        ) : (
          getFormattedCurrency(amount.amount * -1, amount.currency)
        )
      },
    },
    {
      id: 'reset',
      align: 'right',
      label: '',
      disableOrder: true,
      css: 'width: 48px;',
      getCellContent: (_, settings) => {
        if (settings) {
          const { rowExpanded, setRowExpanded } = settings
          return rowExpanded ? (
            <KeyboardArrowUpButton aria-label="expand row" size="small" onClick={() => setRowExpanded(!rowExpanded)} />
          ) : (
            <KeyboardArrowDownButton
              aria-label="expand row"
              size="small"
              onClick={() => setRowExpanded(!rowExpanded)}
            />
          )
        }
        return null
      },
    },
  ]

  const calculateTotalStyle = (): React.ReactElement => {
    if (typeof totalBalance === 'number' && totalBalance < 0) {
      return (
        <Typography variant="body2" variantMapping={{ body2: 'div' }}>
          <StyledTrendingDown style={{ marginRight: 8 }} />
          {t('Debit total')}
        </Typography>
      )
    }
    if (totalBalance === 0) {
      return (
        <Typography variant="body2" variantMapping={{ body2: 'div' }}>
          <StyledTrendingFlat style={{ marginRight: 8 }} />
          {t('Total')}
        </Typography>
      )
    }
    return (
      <Typography variant="body1" variantMapping={{ body1: 'div' }}>
        <StyledTrendingUp style={{ marginRight: 8 }} />
        {t('Credit total')}
      </Typography>
    )
  }

  const calculateTotalBalance = (): React.ReactElement => {
    const totalBalanceVal = getFormattedCurrency(totalBalance, currency)

    return (
      <StyledTableCell4 scope="row">
        <StyledTypography variant="h3" variantMapping={{ h4: 'div' }}>
          {totalBalance === 0 ? (
            <>
              <span>{`${totalBalanceVal[0]}0`}</span>.<StyleDigit>00</StyleDigit>
            </>
          ) : (
            totalBalanceVal
          )}
        </StyledTypography>
        {calculateTotalStyle()}
      </StyledTableCell4>
    )
  }

  const getHeadRows = headRows({ t, setOpenStatusInfo })
  return (
    <ContentContainer>
      <StyledDialog
        open={openStatusInfo}
        scroll="paper"
        onClose={onCloseStatusModal}
        TransitionComponent={Fade}
        fullWidth
      >
        <StatusInformationModal onPressCancel={onCloseStatusModal} heading="Statuses" open />
      </StyledDialog>
      <ProgressModal
        title={t('Processing transfer')}
        description={t('Please wait while we process the selected transactions')}
        open={props.selectedConfirmTransactionLoading}
      />
      <SuccessModal
        open={props.selectedConfirmTransactionResult === ConfirmTransactionResultType.SUCCESS}
        title={t('Your transfer was successful')}
        description={t(
          'Your selected transaction(s) have been successfully transferred to your chosen destination account'
        )}
        setOpen={() => {
          props.confirmTransactionResultClear()
          navigate(`/accounts/${props.subscriptionKey}`)
        }}
        aria-controls="main"
      />
      <FailureModal
        open={props.selectedConfirmTransactionResult === ConfirmTransactionResultType.FAIL}
        description={t('Please go back and try again')}
        setOpen={() => props.confirmTransactionResultClear()}
        buttonLabel={t('GO BACK')}
      />
      <StyledContainerPaper>
        <StyledCard>
          <StyledGrid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={4}
              alignContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {t('Confirm transfer')}
                </Typography>
                <Typography variant="h5" gutterBottom variantMapping={{ h5: 'div' }}>
                  {`${t('From')} ${suspenseAccountName} ${t('to')} ${destinationAccountName} `}
                </Typography>
              </Grid>
            </Grid>
          </StyledGrid>
          <StyledWrapper>
            <Table aria-label="caption table">
              <TableBody>
                <TableRow>
                  <StyledTableCell1 scope="row">
                    <>
                      <StyledTypography variant="h3" variantMapping={{ h3: 'div' }}>
                        {totalTransactions}
                      </StyledTypography>
                      <Typography css="margin-top:4px" variant="body2">
                        {t('Transaction')}
                      </Typography>
                    </>
                  </StyledTableCell1>
                  <StyledTableCell2 scope="row">
                    <>
                      <StyledTypography variant="h3" variantMapping={{ h3: 'div' }}>
                        {debitTransactions}
                      </StyledTypography>
                      <Typography variant="body2" variantMapping={{ body2: 'div' }}>
                        <StyledTrendingDown style={{ marginRight: 8 }} />
                        {t('Debit')}
                      </Typography>
                    </>
                  </StyledTableCell2>
                  <StyledTableCell3 scope="row">
                    <>
                      <StyledTypography variant="h3" variantMapping={{ h3: 'div' }}>
                        {creditTransactions}
                      </StyledTypography>
                      <Typography variant="body2" variantMapping={{ body2: 'div' }}>
                        <StyledTrendingUp style={{ marginRight: 8 }} />
                        {t('Credit')}
                      </Typography>
                    </>
                  </StyledTableCell3>
                  {(totalBalance || totalBalance === 0) && calculateTotalBalance()}
                </TableRow>
              </TableBody>
            </Table>
          </StyledWrapper>
        </StyledCard>
        {props.selectedSuspenseTransactionsTransferResult && (
          <Grid item xs={12}>
            <Field
              name="selectedTransactions"
              hideHeader
              hidePagination
              defaultRowsPerPage={props.selectedSuspenseTransactionsTransferResult.selectedTransactions.length}
              component={EnhancedTableField}
              headRows={getHeadRows}
              touchedNotNeeded
              getExpandedContent={(item: SuspenseTransactionItem) => (
                <ExpandedContent css="margin : 16px ; border:4px solid" row={item} />
              )}
              getSearchString={getSearchString as TableCommonGetSearchString}
              getKeyString={getKeyString as TableCommonGetKeyString}
              data={props.selectedSuspenseTransactionsTransferResult.selectedTransactions as TableCommonDataType[]}
              noEmptyRows
              isSelectableRow={false}
              cardElevation={0}
            />
          </Grid>
        )}
        <FormBottom>
          <Sticky mode="bottom" positionRecheckInterval={500} holderCmp="div">
            <Card elevation={4}>
              <CardActions disableSpacing>
                <BottomButtonContainer
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Button onClick={handleCancel} color={ColorType.BUTTON} aria-controls="main">
                      {t('Cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color={ColorType.BUTTON} onClick={props.submitForm} type="submit">
                      {t('Confirm & submit')}
                    </Button>
                  </Grid>
                </BottomButtonContainer>
              </CardActions>
            </Card>
          </Sticky>
        </FormBottom>
      </StyledContainerPaper>
    </ContentContainer>
  )
}

export default withFormikSimple<OwnProps, TransactionTransferRequest>(ReviewTransactions, {})
