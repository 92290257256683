import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { DeleteIconButton } from 'dls/molecules/IconButtons/IconButtons'
import Accordion from 'dls/organisms/Accordion'
import { ArrayFormOption, AvatarSize } from 'dls/shared/types'
import { Flexbox } from 'pages/Layout/Styled'
import { Body1, Button } from 'dls/atoms'
import { stopPropagation } from 'utils/form.utils'
import { ListItem } from 'dls/shared/styled'
import { EntityBadgeIndexRate } from 'dls/atoms/EntityBadges/EntityBadge'

const DottedBox = styled(Flexbox)`
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.palette.text.hint};
  background-color: white;
  padding: 24px;
  margin-bottom: 16px;
`

const Title = styled(Body1)`
  display: flex;
  flex-grow: 2;
  padding: 0 12px;
`

interface Props {
  optionId: string
  option: ArrayFormOption
  onAdd: (optionId: string) => void
  onRemove: (optionId: string) => void
}

const TemplateArrayOptionCard = ({ optionId, option: { name, ctaText, added, component }, onAdd, onRemove }: Props) => {
  const { t } = useTranslation()

  const headerIcon = <EntityBadgeIndexRate size={AvatarSize.SMALL} />

  const renderCard = () => {
    if (added) {
      return (
        <div id={optionId}>
          <Accordion
            title={<Body1>{t(name)}</Body1>}
            headerIcon={headerIcon}
            headerButtonGroup={
              <DeleteIconButton
                onClick={(e: React.MouseEvent) => {
                  stopPropagation(e)
                  onRemove(optionId)
                }}
              />
            }
            defaultExpanded
            style={{ marginBottom: 16 }}
          >
            {component}
          </Accordion>
        </div>
      )
    }

    return (
      <DottedBox direction="row" width="100%" height="100px" justifyContent="stretch" alignItems="center">
        {headerIcon}
        <Title>{name}</Title>
        <Button
          variant="outlined"
          style={{ borderRadius: 100, width: 80 }}
          onClick={() => onAdd(optionId)}
          aria-controls="main"
        >
          {ctaText}
        </Button>
      </DottedBox>
    )
  }

  return <ListItem>{renderCard()}</ListItem>
}

export default TemplateArrayOptionCard
