import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModalDeprecated, FailureModal, ProgressModal } from 'components/index'

interface BasicDialogProps {
  title: string
  subtitle?: string
}

interface CustomDialogProps extends BasicDialogProps {
  open: boolean
  imageSrc: string
}

interface Props {
  error: boolean
  isLoading: boolean
  onBackClick: VoidFunction
  loadingDialog?: BasicDialogProps
  customDialog?: CustomDialogProps
  children: ReactElement
}

export default ({ error, isLoading, onBackClick, loadingDialog, customDialog, children }: Props) => {
  const { t } = useTranslation()

  if (error) {
    return <FailureModal open description={t('tryAgain')} setOpen={onBackClick} />
  }
  if (loadingDialog && isLoading) {
    return <ProgressModal open title={loadingDialog.title} description={loadingDialog.subtitle} />
  }
  if (customDialog && customDialog.open) {
    return (
      <ConfirmModalDeprecated
        onCancelClick={onBackClick}
        onConfirmClick={{ action: onBackClick, title: 'Go Back' }}
        title={customDialog.title}
        subtitle={customDialog.subtitle}
        image={{ alt: customDialog.title, src: customDialog.imageSrc }}
        hideCancelButton
        open
      />
    )
  }
  return children
}
