import React, { useState } from 'react'
import { Dialog, Fade } from '@material-ui/core'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import ProductCore from './ProductCore/ProductCore'
import ProductsActions from 'store/products/actions'
import defaultFeatures from 'static/productRules'
import { selectCurrentTenant, selectConfig } from 'store/tenant-config/selectors'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const StyledCreateButton = styled(Button)`
  text-transform: uppercase;
  float: right;
`

const mapDispatchToProps = {
  createProduct: ProductsActions.create,
}

type Props = WithTranslation & typeof mapDispatchToProps

const CreateProduct = (props: Props) => {
  const { t } = useTranslation()
  const selectedTenant = useSelector(selectCurrentTenant)
  const selectedConfig = useSelector(selectConfig)

  const { createProduct } = props
  const [open, setOpen] = useState(false)

  const onOpen = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <StyledCreateButton
        variant="contained"
        color={ColorType.BUTTON}
        data-test-id="create-product-button"
        onClick={onOpen}
        fullWidth
      >
        {t('Create Product')}
      </StyledCreateButton>
      <Dialog
        open={open}
        scroll="paper"
        onClose={onClose}
        TransitionProps={{ onExit: onClose }}
        TransitionComponent={Fade}
        fullWidth
      >
        <ProductCore
          onSubmit={(values) => {
            const { productTaxonomy = '{}' } = values
            const { productType } = JSON.parse(productTaxonomy)
            const defaultFeatureValue = defaultFeatures(selectedTenant as any, selectedConfig as any, productType)

            return createProduct({ body: { ...values, ...defaultFeatureValue } })
          }}
          onPressCancel={onClose}
          isNewCustomProduct
        />
      </Dialog>
    </>
  )
}

export default withTranslation()(connect(null, mapDispatchToProps)(CreateProduct))
