import React from 'react'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import { connect } from 'react-redux'
import NotificationSelectors from 'store/notifications/selectors'
import { RootState } from 'store'

const mapStateToProps = (state: RootState) => ({
  data: NotificationSelectors.selectNotification(state),
})
type OwnProps = ReturnType<typeof mapStateToProps> & WithSnackbarProps

const MultipleMessage = ({ data, enqueueSnackbar }: OwnProps) => {
  enqueueSnackbar(data.notification.message, { variant: data.notification.type })

  return null
}

const MultipleMessageContainer = connect(mapStateToProps, null)(withSnackbar(MultipleMessage))

const MultipleMessageWrapper = (props: any) => <MultipleMessageContainer {...props} />

export default MultipleMessageWrapper
