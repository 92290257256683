import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { orderBy, groupBy } from 'lodash'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'utils/date.utils'
import { BankInterestRate } from 'store/referenceData/types'
import { IndexRatesInfoGraphic } from 'assets'
import { SidePanel, BackButton, IndexRateIcon } from 'components'
import { RateMultiplyByHundred } from 'utils/form.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'

type RouteOwnProps = {
  selectedIndexRate: BankInterestRate
}

const StyledGrid = styled(Grid)``

const StyledGridActivity = styled(Grid)`
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: 16px;
  }
`
const Hline = styled(Grid)`
  height: 36px;
  width: 1px;
  background-color: ${(props: any) => props.theme.palette.divider};
  margin-left: 6px;
  margin-top: 4px;
  margin-bottom: 6px;
`
const Content = styled.header`
  margin: 24px;
  margin-top: 0px;
  margin-bottom: 80px;
`
const Header = styled.header`
  margin: 24px;
  margin-bottom: 18px;
`

const ContentPaper = styled(Paper)`
  padding: 0px;
  margin-top: 16px;
  margin-bottom: 24px;
  overflow: auto;
`
const StyledContainer = styled.div`
  padding-left: 0px;
  padding-right: 0px;
`

const ButtonContainer = styled.div`
  align-items: center;
  padding-left: 0px;
  padding-top: 0px;
  margin-bottom: -20px;
`

export function InterestRateHistory({ selectedIndexRate }: RouteOwnProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onPressBack = useCallback(() => {
    navigate(`/reference-data/index-rates/${selectedIndexRate?.bankIndexKey}`)
  }, [])

  const rates =
    selectedIndexRate.rates &&
    orderBy(
      Object.entries(
        groupBy(
          orderBy(
            selectedIndexRate.rates.filter(
              (i) => i.effectiveDate && !moment(i.effectiveDate, 'YYYY-MM-DD').isAfter(moment())
            ),
            ['effectiveDate'],
            ['desc']
          ),
          (i) => parseInt(moment(`${i.effectiveDate}`).format('YYYY'), 10)
        )
      ).map(([key, value]) => ({ key, value })),
      ['key'],
      ['desc']
    )

  return (
    <ContentContainer data-test-id="historic-rate-page">
      <ButtonContainer>
        <BackButton onPressBack={onPressBack} aria-controls="main" />
      </ButtonContainer>
      <StyledGrid
        container
        spacing={4}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        css={`
          width: 100%;
        `}
      >
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <StyledContainer>
            <ContentPaper elevation={1}>
              <Header>
                <Typography variant="h1" gutterBottom>
                  {t('Historic rates – {{name}}', { name: selectedIndexRate.name })}
                </Typography>
              </Header>
              <Content>
                <StyledGrid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  {rates &&
                    rates.map((i) => (
                      <React.Fragment key={i.key}>
                        <StyledGrid item xs={12} css="margin-bottom:16px;margin-top:16px">
                          <Typography variant="h2">{i.key}</Typography>
                        </StyledGrid>
                        {i.value &&
                          i.value.map((indexRate, index) => (
                            <StyledGrid item xs={12} key={indexRate.rateKey} css="padding-left:16px">
                              <StyledGrid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <StyledGrid item css="margin-right:16px">
                                  {indexRate.indexRate && (
                                    <>
                                      <IndexRateIcon indexRate={indexRate.indexRate} />
                                      {i.value.length > index + 1 ? <Hline /> : <></>}
                                    </>
                                  )}
                                </StyledGrid>

                                <StyledGrid item>
                                  <Typography variant="subtitle2">
                                    {indexRate.indexRate && RateMultiplyByHundred(indexRate.indexRate)}
                                    {' %'}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {t(`Effective from `)}
                                    {indexRate.effectiveDate ? moment(indexRate.effectiveDate).format(DATE_FORMAT) : ''}
                                  </Typography>
                                </StyledGrid>
                              </StyledGrid>
                            </StyledGrid>
                          ))}
                      </React.Fragment>
                    ))}
                </StyledGrid>
              </Content>
            </ContentPaper>
          </StyledContainer>
        </Grid>
        <StyledGridActivity xs={12} item sm={12} md={12} lg={4}>
          <SidePanel
            heading={t('ABOUT INDEX RATES')}
            image={IndexRatesInfoGraphic}
            imageAlt={t('Illustration of a laptop showing a bar chart of changing index rate.')}
            details={t(
              'Index rates can be created, set and managed here. These rates can be selected when building a product to configure indexed interest rates.'
            )}
            linkToOpenModal={t('More about index rates')}
            infographicType={HelpModalType.INDEX_RATES}
          />
        </StyledGridActivity>
      </StyledGrid>
    </ContentContainer>
  )
}

export default InterestRateHistory
