import { createAction } from '@reduxjs/toolkit'
import {
  CardsFormValues,
  Currency,
  FeatureId,
  PaymentSchedule,
  Product,
  ProductKey,
  TransactionRule,
  ProductPermissions,
  RetireOrDeleteProductOrPackage,
  TransactionRules,
  DebitInterestCommonConfigFormValues,
  LendingRateFormValues,
  LendingRateType,
  CreditInterestPayloadValues,
} from './types'
import { PaymentLimit, PaymentLimitsConfig } from './typings/paymentLimits'
import { CardTransactionLimit } from './typings/cardLimits'
import { CreditLimitFormValues } from './typings/creditLimit'
import { MultiParty } from './typings/multiParty'
import {
  CreateProductWithTemplate,
  DebitInterestFormValues,
  DeleteFeaturePayload,
  DocumentsValues,
  EligibilityFormValues,
  ExtendedProduct,
  Identification,
  Limits,
  PublishProductPayload,
  RequiredExternalIdFormValues,
  ReviewProductPayload4Action,
  StatementFormValues,
  SubcriptionCreationPayload,
  CommonFeaturePageValues,
  UpdateProductFormValues,
} from 'store/products/types'
import { Colleague } from 'store/user/types'
import { ShouldRedirectOnFail } from 'store/utils/types'
import { ProductSyndicateResponse } from 'store/products/typings/productSyndication'

const actions = {
  create: createAction<CreateProductWithTemplate>('PRODUCTS/CREATE'),
  createSuccess: createAction<ExtendedProduct>('PRODUCTS/CREATE/SUCCESS'),
  createFail: createAction<Error>('PRODUCTS/CREATE/FAIL'),

  fetch: createAction<ShouldRedirectOnFail | undefined>('PRODUCTS/FETCH'),
  fetchSuccess: createAction<Product[]>('PRODUCTS/FETCH/SUCCESS'),
  fetchFail: createAction<Error>('PRODUCTS/FETCH/FAIL'),

  getProductSyndication: createAction<{ productKey: ProductKey; version: number }>('PRODUCTS/SYNDICATION/FETCH'),
  getProductSyndicationSuccess: createAction<ProductSyndicateResponse>('PRODUCTS/SYNDICATION/SUCCESS'),
  getProductSyndicationFail: createAction<Error>('PRODUCTS/SYNDICATION/FAIL'),

  getProductDetails: createAction<{ version?: string; productKey: ProductKey }>('PRODUCTS/DETAILS/FETCH'),
  getProductDetailsSuccess: createAction<ExtendedProduct>('PRODUCTS/DETAILS/FETCH/SUCCESS'),
  getProductDetailsFail: createAction<Error>('PRODUCTS/DETAILS/FETCH/FAIL'),

  editProductDetails: createAction<{ productKey: ProductKey } & UpdateProductFormValues>('PRODUCTS/DETAILS/EDIT'),

  publish: createAction<PublishProductPayload>('PRODUCTS/PUBLISH'),
  publishSuccess: createAction<ExtendedProduct>('PRODUCTS/PUBLISH/SUCCESS'),
  publishFail: createAction<Error>('PRODUCTS/PUBLISH/FAIL'),
  clearProductPublishError: createAction('PRODUCTS/PUBLISH/FAIL/CLEAR'),
  clearPublishSuccess: createAction('PRODUCTS/PUBLISH/SUCCESS/CLEAR'),

  review: createAction<ReviewProductPayload4Action>('PRODUCTS/REVIEW'),
  reviewSuccess: createAction<ExtendedProduct>('PRODUCTS/REVIEW/SUCCESS'),
  reviewFail: createAction<Error>('PRODUCTS/REVIEW/FAIL'),
  reviewGeneralFail: createAction<Error>('PRODUCTS/REVIEW/GENERAL/FAIL'),
  reviewFailNoUser: createAction<Error>('PRODUCTS/REVIEW/FAIL/NOUSER'),

  clearReviewErrors: createAction('PRODUCTS/REVIEW/FAIL/CLEAR'),

  deleteProduct: createAction<RetireOrDeleteProductOrPackage>('PRODUCTS/DELETE'),
  deleteProductSuccess: createAction<ProductKey>('PRODUCTS/DELETE/SUCCESS'),
  deleteProductFail: createAction<Error>('PRODUCTS/DELETE/FAIL'),

  setEligibility: createAction<EligibilityFormValues & { productKey: ProductKey }>('PRODUCTS/FEATURE/SET/ELIGIBILITY'),

  setCreditInterest: createAction<CreditInterestPayloadValues & { productKey: ProductKey }>(
    'PRODUCTS/FEATURE/SET/CreditInterest'
  ),

  setupDebitInterest: createAction<
    DebitInterestFormValues & {
      productKey: ProductKey
      version: number
      rateType: LendingRateType
    }
  >('PRODUCTS/FEATURE/SETUP/DEBIT_INTEREST'),

  updateDebitInterest: createAction<
    (LendingRateFormValues | DebitInterestCommonConfigFormValues) & {
      entity: 'commonConfig' | 'rateWithinLimit' | 'rateAboveLimit' | 'rateAboveLimitOverride'
      operation: 'remove' | 'replace'
      productKey: ProductKey
      version: number
    }
  >('PRODUCTS/FEATURE/UPDATE/DEBIT_INTEREST'),

  removeDebitInterestRate: createAction<{
    rateType: 'rateWithinLimit' | 'rateAboveLimit' | 'rateAboveLimitOverride'
    productKey: ProductKey
    version: number
  }>('PRODUCTS/FEATURE/REMOVE/DEBIT_INTEREST_RATE'),

  deleteDebitInterestFeature: createAction<{
    productKey: ProductKey
    version: number
  }>('PRODUCTS/FEATURE/DELETE/DEBIT_INTEREST_FEATURE'),

  setCurrency: createAction<Currency & { productKey: ProductKey }>('PRODUCTS/FEATURE/SET/CURRENCY'),

  setLimits: createAction<Limits & { productKey: ProductKey }>('PRODUCTS/FEATURE/SET/LIMITS'),

  setRequiredExternalId: createAction<RequiredExternalIdFormValues & { productKey: ProductKey }>(
    'PRODUCTS/FEATURE/SET/REQUIREDEXTERNALID'
  ),
  setRequiredExternalIdFail: createAction<Error>('PRODUCTS/FEATURE/SET/REQUIREDEXTERNALID/FAIL'),

  setTermsAndConditions: createAction<{
    formData: CommonFeaturePageValues
    productKey: ProductKey
  }>('PRODUCTS/FEATURE/SET/TERMSANDCONDITIONS'),

  setStatement: createAction<StatementFormValues & { productKey: ProductKey }>('PRODUCTS/FEATURE/SET/STATEMENT'),

  setDocuments: createAction<{ formData: DocumentsValues; productKey: ProductKey }>('PRODUCTS/FEATURE/SET/DOCUMENTS'),

  setPaymentLimitsConfig: createAction<PaymentLimitsConfig & { version: number; productKey: ProductKey }>(
    'PRODUCTS/FEATURE/SET/PAYMENT_LIMITS/PRODUCT_CONFIG'
  ),
  setPaymentLimit: createAction<PaymentLimit & { limitName: string; version: number; productKey: ProductKey }>(
    'PRODUCTS/FEATURE/SET/PAYMENT_LIMITS/PAYMENT_LIMIT'
  ),
  deletePaymentLimit: createAction<{ limitName: string; limitRuleId: string; version: number; productKey: ProductKey }>(
    'PRODUCTS/FEATURE/DELETE/PAYMENT_LIMITS/PAYMENT_LIMIT'
  ),

  setSubscriptionCreationRule: createAction<SubcriptionCreationPayload & { productKey: ProductKey }>(
    'PRODUCTS/FEATURE/SET/SUBSCRIPTIONCREATIONRULE'
  ),

  setIdentification: createAction<Identification & { productKey: ProductKey }>('PRODUCTS/FEATURE/SET/IDENTIFICATION'),

  updateProductSuccess: createAction<ExtendedProduct>('PRODUCTS/DETAILS/UPDATED/SUCCESS'),

  saveFeature: createAction('FEATURE/SAVED'),

  deleteFeature: createAction<DeleteFeaturePayload>('FEATURE/DELETE'),
  deleteFeatureSuccess: createAction<ExtendedProduct>('FEATURE/DELETE/SUCCESS'),
  deleteFeatureFail: createAction<Error>('FEATURE/DELETE/FAIL'),

  getAssignees: createAction('PRODUCTS/GET/ASSIGNEES'),
  getAssigneesSuccess: createAction<Colleague[]>('PRODUCTS/GET/ASSIGNEES/SUCCESS'),
  getAssigneesFail: createAction<Error>('PRODUCTS/GET/ASSIGNEES/FAIL'),

  getVersions: createAction<ProductKey>('PRODUCTS/GET/VERSIONS'),
  getVersionsSuccess: createAction<Product[]>('PRODUCTS/GET/VERSIONS/SUCCESS'),
  getVersionsFail: createAction<Error>('PRODUCTS/GET/VERSIONS/FAIL'),

  getVersionsForPublish: createAction<ProductKey>('PRODUCTS/GET/VERSIONS_FOR_PUBLISH'),
  getVersionsSuccessForPublish: createAction<Product[]>('PRODUCTS/GET/VERSIONS_FOR_PUBLISH/SUCCESS'),
  getVersionsFailForPublish: createAction<Error>('PRODUCTS/GET/VERSIONS_FOR_PUBLISH/FAIL'),
  clearGetVersionsFailForPublish: createAction('PRODUCTS/GET/VERSIONS_FOR_PUBLISH/FAIL/CLEAR'),
  getProductByVersion: createAction<{ version: string; productKey: ProductKey }>('PRODUCTS/GET/BY/VERSIONS'),

  getAllGovernenceDataForProduct: createAction<{ productKey: ProductKey }>('PRODUCTS/GET/PRODUCT_ASSIGNEESS'),
  getAllGovernenceDataForProductSuccess: createAction('PRODUCTS/GET/PRODUCT_ASSIGNEESS/SUCCESS'),
  getAllGovernenceDataForProductFail: createAction<Error>('PRODUCTS/GET/PRODUCT_ASSIGNEESS/FAIL'),

  retireProduct: createAction<RetireOrDeleteProductOrPackage>('PRODUCTS/RETIRE'),
  retireProductSuccess: createAction<ExtendedProduct>('PRODUCTS/RETIRE/SUCCESS'),
  openAssigneeModal: createAction('PRODUCTS/ASSIGNEEMODAL/OPEN'),
  closeAssigneeModal: createAction('PRODUCTS/ASSIGNEEMODAL/CLOSE'),

  setCards: createAction<CardsFormValues & { productKey: ProductKey }>('PRODUCTS/FEATURE/SET/CARDS'),
  fetchCardImages: createAction('PRODUCTS/FEATURE/GET/CARD_IMAGES'),
  fetchCardImagesSuccessful: createAction<CardsFormValues & { productKey: ProductKey }>(
    'PRODUCTS/FEATURE/GET/CARD_IMAGES/SUCCESS'
  ),
  fetchCardImagesFail: createAction<CardsFormValues & { productKey: ProductKey }>(
    'PRODUCTS/FEATURE/GET/CARD_IMAGES/FAIL'
  ),
  setProductPermissions: createAction<ProductPermissions>('PRODUCT/SET/PERMISSIONS'),
  setTransactionRules: createAction<
    TransactionRules & { productKey: ProductKey; transactionRuleMessage: TransactionRule }
  >('PRODUCTS/FEATURE/SET/TRANSACTIONRULES/DEFAULTACCESS'),
  setTransactionRulesClearSuccess: createAction('PRODUCTS/FEATURE/SET/TRANSACTIONRULES/DEFAULTACCESS/SUCCESS/CLEAR'),
  setTransactionRulesClearErrors: createAction('PRODUCTS/FEATURE/SET/TRANSACTIONRULES/DEFAULTACCESS/FAIL/CLEAR'),

  deleteTransactionRules: createAction<string>('PRODUCTS/FEATURE/DELETE/TRANSACTIONRULES'),
  deleteTransactionRulesSuccess: createAction('PRODUCTS/FEATURE/DELETE/TRANSACTIONRULES/SUCCESS'),
  deleteTransactionRulesFail: createAction<Error>('PRODUCTS/FEATURE/DELETE/TRANSACTIONRULES/FAIL'),
  deleteTransactionRulesClear: createAction('PRODUCTS/FEATURE/DELETE/TRANSACTIONRULES/CLEAR'),

  setPaymentSchedule: createAction<PaymentSchedule & { productKey: ProductKey; version: number }>(
    'PRODUCTS/FEATURE/SET/PAYMENTSCHEDULE'
  ),
  getPaymentSchedule: createAction<{ productKey: ProductKey; featureId: FeatureId }>(
    'PRODUCTS/FEATURE/GET/PAYMENTSCHEDULE'
  ),
  getPaymentSchedulesSuccess: createAction<PaymentSchedule>('PRODUCTS/FEATURE/GET/PAYMENTSCHEDULES/SUCCESS'),
  getPaymentSchedulesFail: createAction<Error>('PRODUCTS/FEATURE/GET/PAYMENTSCHEDULES/FAIL'),

  createNewProductVersion: createAction<ProductKey>('PRODUCTS/VERSIONS/NEW'),
  createNewProductVersionSuccess: createAction<ExtendedProduct>('PRODUCTS/VERSIONS/NEW/SUCCESS'),
  createNewProductVersionFail: createAction<Error>('PRODUCTS/VERSIONS/NEW/FAIL'),
  createNewProductVersionClearSuccess: createAction('PRODUCTS/VERSIONS/NEW/SUCCESS/CLEAR'),
  createNewProductVersionClearErrors: createAction('PRODUCTS/VERSIONS/NEW/FAIL/CLEAR'),
  deletePaymentSchedule: createAction<{ productKey: ProductKey }>('PRODUCTS/FEATURE/DELETE/PAYMENTSCHEDULE'),
  setCardTransactionLimit: createAction<
    CardTransactionLimit & { limitName: string; version: number; productKey: ProductKey }
  >('PRODUCTS/FEATURE/SET/CARD_TRANSACTION_LIMITS'),
  deleteCardTransactionLimit: createAction<{
    limitName: string
    limitRuleId: string
    version: number
    productKey: ProductKey
  }>('PRODUCTS/FEATURE/DELETE/CARD_TRANSACTION_LIMITS'),

  updateCreditLimit: createAction<CreditLimitFormValues & { version: number; productKey: ProductKey }>(
    'PRODUCTS/FEATURE/UPDATE/CREDIT_LIMIT'
  ),
  deleteCreditLimit: createAction<{
    version: number
    productKey: ProductKey
  }>('PRODUCTS/FEATURE/DELETE/CREDIT_LIMIT'),

  setMultiParty: createAction<MultiParty & { version: number; productKey: ProductKey }>(
    'PRODUCTS/FEATURE/SET/MULTI_PARTY'
  ),
  deleteMultiParty: createAction<{
    version: number
    productKey: ProductKey
  }>('PRODUCTS/FEATURE/DELETE/MULTI_PARTY'),
}

export default actions
