import React from 'react'
import { AccordionDetails, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import Loader from 'dls/atoms/Loader'
import { Paper, Body1, Body2, ContextualHelp } from 'dls'
import { EntityBadgeProductSyndication } from 'dls/atoms/'
import { ColorType, GenericSize } from 'dls/shared/types'
import { StyledButtonWithFocusUnderline } from 'dls/molecules/Buttons'
import { useProductSyndication } from 'pages/Product/hooks/useProductSyndication'
import { CustomTheme } from 'dls/shared/styled'
import { ProductFeatureViewModeProps } from 'pages/Product/ProductFeatureViewMode/ProductFeatureViewMode'
import { FeaturePaper } from 'pages/Product/ProductFeatureViewMode/FeaturePaper'
import { LaunchIcon, ErrorOutlineIcon } from 'dls/atoms/Icons/MUIIcons'
import ViewProductSyndication from 'pages/features/ProductSyndication/components/view/productPage/components/ViewProductSyndication'

const StyledErrorLink = styled(Body2)`
  color: ${({ theme }: { theme: CustomTheme }) => `${theme.palette.error.dark}`};
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 18px;
  padding-top: 0;
  padding-bottom: 4px;
`

interface OwnFeaturePaperProps {
  productKey: string
  version: number
}

type FeaturePaperProps = ProductFeatureViewModeProps & OwnFeaturePaperProps

export const FeatureProductSyndication = (props: FeaturePaperProps): React.ReactElement | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { productKey, version } = props
  const { productSyndication, productSyndicationError, isLoading, getProductSyndication } = useProductSyndication(
    productKey,
    version
  )

  const handleReload = () => {
    getProductSyndication()
  }

  const renderNonSyndication = () => {
    if (isLoading) {
      return (
        <>
          <Loader size={GenericSize.SM} color={ColorType.BRAND} shrink style={{ marginRight: 10, marginTop: 2 }} />{' '}
          <Body2>{t('Checking if a syndication exists')}</Body2>
        </>
      )
    }
    if (productSyndicationError?.nonSyndicated) {
      return (
        <Body2>
          {t('No syndication exist - ')}
          <StyledButtonWithFocusUnderline
            color={ColorType.BUTTON}
            component="a"
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={() => navigate(`/product-syndication/${productKey}/versions/${version}/initiate`)}
            style={{ padding: '0px 3px', marginTop: -1, marginRight: -4, textTransform: 'initial' }}
            variant="text"
            tabIndex={0}
          >
            {t('add feature')}
          </StyledButtonWithFocusUnderline>
          <ContextualHelp
            title={
              <>
                {t(
                  'This feature allows you to create product syndication meta data so that you can syndicate your product to third parties.'
                )}
              </>
            }
            style={{ marginRight: 0 }}
          />
        </Body2>
      )
    }

    return (
      <StyledErrorLink>
        <ErrorOutlineIcon color={ColorType.ERROR} style={{ float: 'left', margin: '-2px 10px 0' }} />
        {t('Oops, something went wrong ')}
        <StyledButtonWithFocusUnderline
          color={ColorType.BUTTON}
          component="a"
          disableElevation
          disableFocusRipple
          disableRipple
          onClick={() => handleReload()}
          style={{ padding: '0px 3px', marginTop: -1, marginRight: -4, textTransform: 'initial' }}
          variant="text"
          tabIndex={0}
        >
          {t('try again')}
        </StyledButtonWithFocusUnderline>
      </StyledErrorLink>
    )
  }

  if (!productSyndication || productSyndicationError) {
    return (
      <Paper
        ariaProps={{
          ariaLabelledBy: 'heading-id',
          role: 'group',
        }}
        radius={4}
        variant="elevation"
        elevation={1}
        css={{ width: '100%' }}
      >
        <Box display="flex" width="100%">
          <Box display="flex" width="50%" role="button" tabIndex={0} alignItems="center">
            <EntityBadgeProductSyndication />
            <Body1
              variantMapping={{
                body1: 'h3',
              }}
              css={{ flexGrow: 2, paddingLeft: 22 }}
            >
              {t('Product syndication')}{' '}
            </Body1>
          </Box>
          <Box
            display="flex"
            width="50%"
            alignItems="flex-start"
            justifyContent="flex-end"
            style={{ position: 'relative', float: 'right', marginTop: 6, pointerEvents: 'auto' }}
          >
            {renderNonSyndication()}
          </Box>
        </Box>
      </Paper>
    )
  }

  return (
    <FeaturePaper
      {...props}
      featureTitle={t('Product syndication')}
      hideBottomBar
      customFooterNavigation={
        <StyledButtonWithFocusUnderline
          aria-controls="more-about-dialog"
          aria-haspopup="dialog"
          color={ColorType.BUTTON}
          component="a"
          endIcon={<LaunchIcon color={ColorType.BUTTON} />}
          onClick={() => navigate(`/products/${productKey}`)}
          tabIndex={0}
          style={{
            backgroundColor: 'transparent',
            fontSize: 'inherits',
            margin: '0 3px',
            padding: '0px 3px',
          }}
          variant="text"
        >
          {t('View meta data')}
        </StyledButtonWithFocusUnderline>
      }
      featureID="productSyndication"
    >
      <ViewProductSyndication productSyndication={productSyndication} productKey={productKey} />
    </FeaturePaper>
  )
}
