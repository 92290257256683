import { ExtendedProduct, EligibilityFormValues } from 'store/products/types'

export const toEligibilityFormValues = (product: ExtendedProduct): EligibilityFormValues | null =>
  product.eligibility && {
    ageEligibility: product.eligibility.ageEligibility ? [product.eligibility.ageEligibility] : [],
    incomeEligibility: product.eligibility.incomeEligibility ? [product.eligibility.incomeEligibility] : [],
    residencyEligibility: product.eligibility.residencyEligibility || [],
    officerEligibility: product.eligibility.officerEligibility ? [product.eligibility.officerEligibility] : [],
    studentAccountEligibility: product.eligibility.studentAccountEligibility
      ? [product.eligibility.studentAccountEligibility]
      : [],
    taxResidencyEligibility: product.eligibility.taxResidencyEligibility
      ? [product.eligibility.taxResidencyEligibility]
      : [],
    taxIdentificationEligibility: product.eligibility.taxIdentificationEligibility
      ? [product.eligibility.taxIdentificationEligibility]
      : [],
    productDependencyEligibility: product.eligibility.productDependencyEligibility
      ? [product.eligibility.productDependencyEligibility]
      : [],
  }
