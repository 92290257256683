import React from 'react'
import { css as styledCss } from 'styled-components/macro'
import { Typography, TypographyProps } from '@material-ui/core'

const bottomMarginLarge = styledCss`
  margin-bottom: 32px;
`
const bottomMarginDefault = styledCss`
  margin-bottom: 24px;
`

const typographyGutters = styledCss`
  ${bottomMarginDefault}
`

const typographyGuttersCaption = styledCss`
  ${typographyGutters}
  display: inline-block
`

interface HasGutters {
  gutter?: true
}

type TypographyWithGutters = TypographyProps & HasGutters

export const H1 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="h1"
    data-dls="atom-h1"
    {...restProps}
    css={`
      ${gutter ? bottomMarginLarge : undefined}
      ${css}
    `}
  />
)

export const H2 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="h2"
    data-dls="atom-h2"
    {...restProps}
    css={`
      ${gutter ? bottomMarginDefault : undefined}
      ${css}
    `}
  />
)

export const H3 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="h3"
    data-dls="atom-H3"
    {...restProps}
    css={`
      ${gutter ? bottomMarginDefault : undefined}
      ${css}
    `}
  />
)

export const H4 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="h4"
    data-dls="atom-H4"
    {...restProps}
    css={`
      ${gutter ? bottomMarginDefault : undefined}
      ${css}
    `}
  />
)

export const H5 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="h5"
    data-dls="atom-H5"
    {...restProps}
    css={`
      ${gutter ? bottomMarginDefault : undefined}
      ${css}
    `}
  />
)

export const H6 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="h6"
    data-dls="atom-H6"
    {...restProps}
    css={`
      ${gutter ? bottomMarginDefault : undefined}
      ${css}
    `}
  />
)

export const Subtitle1 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="subtitle1"
    data-dls="atom-Subtitle1"
    {...restProps}
    css={`
      ${gutter ? bottomMarginDefault : undefined}
      ${css}
    `}
  />
)

export const Subtitle2 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="subtitle2"
    data-dls="atom-Subtitle2"
    {...restProps}
    css={`
      ${gutter ? bottomMarginDefault : undefined}
      ${css}
    `}
  />
)

export const Body1 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="body1"
    data-dls="atom-Body1"
    {...restProps}
    css={`
      ${gutter ? typographyGutters : undefined}
      ${css}
    `}
  />
)

export const Body2 = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="body2"
    data-dls="atom-Body2"
    {...restProps}
    css={`
      ${gutter ? typographyGutters : undefined}
      ${css}
    `}
  />
)

export const Caption = ({ gutter, css, ...restProps }: TypographyWithGutters) => (
  <Typography
    variant="caption"
    data-dls="atom-caption"
    {...restProps}
    css={`
      ${gutter ? typographyGuttersCaption : undefined}
      ${css}
    `}
  />
)
