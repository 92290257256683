import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import BreadcrumbsActions from 'store/breadcrumbs/actions'
import { PageTitleAndUrl } from 'store/breadcrumbs/types'

const useBreadcrumbs = ({ breadcrumbs, trigger }: { breadcrumbs: PageTitleAndUrl[]; trigger: boolean }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const updateBreadcrumbs = (updatedBreacrumbs: PageTitleAndUrl[]) =>
    dispatch(BreadcrumbsActions.updateBreadCrumbs(updatedBreacrumbs))

  useEffect(() => {
    if (trigger) {
      updateBreadcrumbs(breadcrumbs)
    }
  }, [updateBreadcrumbs, t, breadcrumbs, trigger])
}

export default useBreadcrumbs
