import { useState, useMemo } from 'react'
import { findIndex as _findIndex } from 'lodash'
import { toBankTz } from 'utils/date.utils'
import { PackagesWithProducts } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import getPackageStatusFilterOptions from 'pages/Packages/FilterMenuItems/getPackageStatusFilterOptions'

const usePackageFilter = (items: PackagesWithProducts[], statusQueryParam: string) => {
  const statusFilterOptions = getPackageStatusFilterOptions(items)

  let initialSelectedFilter = 0

  if (statusQueryParam) {
    if (statusQueryParam.toLowerCase() === 'scheduled') {
      initialSelectedFilter = _findIndex(statusFilterOptions, (option: any) => option.isScheduled)
    } else {
      initialSelectedFilter = _findIndex(statusFilterOptions, (option: any) =>
        option?.status?.some(
          (item: any) => item.toLowerCase() === statusQueryParam.toLowerCase() && !option.isScheduled
        )
      )
    }
  }

  const preSelectFilter = initialSelectedFilter > -1 ? initialSelectedFilter : 0

  const [selectedFilter, setSelectedFilter] = useState(preSelectFilter)

  const filteredItems = useMemo(() => {
    if (selectedFilter === 0) {
      return items
    }

    const selectedItem = statusFilterOptions[selectedFilter]
    const isLiveStatus = selectedItem.status?.includes(PackagesStatus.LIVE)

    if (isLiveStatus) {
      const isScheduledStatus = selectedItem?.isScheduled
      if (isScheduledStatus) {
        return items.filter(
          (item) =>
            selectedItem?.status?.includes(item.packageStatus) &&
            item.publicationDate &&
            toBankTz(item.publicationDate).isAfterNow
        )
      }
      return items.filter(
        (item) =>
          selectedItem.status?.includes(item.packageStatus) &&
          item.publicationDate &&
          !toBankTz(item.publicationDate).isAfterNow
      )
    }
    return items.filter((item) => selectedItem.status?.includes(item.packageStatus))
  }, [items, selectedFilter])

  return {
    selectedFilter,
    setSelectedFilter,
    filteredItems,
    statusFilterOptions,
  }
}

export default usePackageFilter
