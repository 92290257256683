import React from 'react'
import { FieldProps } from 'formik'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'

export interface SelectFieldComplexProps {
  required?: boolean
}

type Props = FieldProps & SelectFieldComplexProps & EnhancedFormikFieldProps

const StyledTypography = styled(Typography)`
  margin-top: 18px;
`
const DisabledTypography = styled(Typography)`
  color: ${(props: any) => `${props.theme.palette.text.disabled}`};
  border-bottom: 1px solid;
  border-bottom-style: dotted;
  padding-bottom: 5px;
`

const StaticFieldComplex = (props: Props) => {
  const { field, disabled } = props

  const { t } = useTranslation()
  return (
    <EnhancedFormikField {...props} inputLabelProps={{ ...props.inputLabelProps, shrink: true }} touchedNotNeeded>
      {({ hasError }) => (
        <StyledTypography
          variant="subtitle1"
          color={hasError ? 'error' : 'initial'}
          variantMapping={{ subtitle1: 'div' }}
        >
          {disabled ? (
            <DisabledTypography>{t(field.value)}</DisabledTypography>
          ) : (
            <Typography>{t(field.value)}</Typography>
          )}
        </StyledTypography>
      )}
    </EnhancedFormikField>
  )
}
export default StaticFieldComplex
