import memoize from 'lodash/memoize'
import moment from 'moment-timezone'
import { sortBy } from 'lodash'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { Document, File, FileFormat } from 'store/products/types'
import { TenantConfig } from 'store/tenant-config/types'

const axiosInstance = async (contentPath: string, country: string, language: string) =>
  createAsyncAuthAwareAxios(`${contentPath}${country}/${language}/`)

// todo contentPath, country, language could all go in generic `createAsyncAuthAwareAxios` from redux
export const createContentFetch = memoize(axiosInstance, (...args) => args.join('.'))

const remoteDocumentsFromData = (documents: any) =>
  documents.map((document: Document) => ({
    ...document,
    identifier: document.name,
    identifierOriginal: document.identifier,
    name: document.title,
    files: (document.files || []).map(
      (file: { url: string; format: string }): File => ({
        ...file,
        format: file.format.toUpperCase() as FileFormat,
        label: `${document.title} (${file.format.toUpperCase()})`,
      })
    ),
    publishedDate: document.publishedDate && moment(document.publishedDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
  }))

async function getRemoteDocuments({
  apiSettings: { contentPath },
  localisation: { countryCode = 'GB', language },
}: TenantConfig) {
  const {
    data: { documents },
  } = await (await createContentFetch(contentPath, countryCode.substr(0, 2).toLowerCase(), language)).get(
    'documents.getDocumentList.json'
  )

  return sortBy(remoteDocumentsFromData(documents), ['name'])
}

export default {
  getRemoteDocuments,
}
