/* eslint-disable no-nested-ternary */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { HelpModalType } from 'store/modal/types'
import HelpPanel from 'dls/organisms/HelpPanel/HelpPanel'
import useModal from 'components/Modal/hooks/useModal'

interface OwnProps {
  heading: string
  image: string
  details: string
  linkToOpenModal: string
  infographicType: HelpModalType
  imageAlt?: string
}

const SidePanel = ({ heading, image, details, linkToOpenModal, infographicType, imageAlt }: OwnProps) => {
  const { t } = useTranslation()
  const { showInfographicModal } = useModal()

  return (
    <>
      <HelpPanel
        buttonProps={{
          'aria-controls': 'more-about-dialog',
          'aria-haspopup': 'dialog',
          copy: t(linkToOpenModal),
          style: { verticalAlign: 'middle', display: 'inline' },
          onClick: () => showInfographicModal(infographicType),
        }}
        description={t(details)}
        imageProps={{
          alt: imageAlt,
          ariaProps: {
            'aria-hidden': false,
          },

          src: image,
        }}
        title={t(heading)}
      />
    </>
  )
}

export default SidePanel
