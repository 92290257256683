import React from 'react'
import { ProductStatus } from 'store/products/typings/productStatus'
import ScheduledMenuItem from 'pages/Products/FilterMenuItems/ScheduledMenuItem'
import AllStatuses from 'pages/Products/FilterMenuItems/AllStatuses'
import FilterMenuItem from 'pages/Products/FilterMenuItems/FilterMenuItem'
import { ProductSyndication } from 'store/products/typings/productSyndication'

const getSyndicationStatusFilterOptions = (products: ProductSyndication[]) => [
  {
    label: ({ isActive }: { isActive: boolean }) => <AllStatuses isActive={isActive} items={products} />,
    status: undefined,
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem items={products} itemStatus={ProductStatus.DESIGN} isActive={isActive} />
    ),
    status: [ProductStatus.DESIGN],
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <ScheduledMenuItem items={products} effectiveDatePropName="effectiveFrom" isActive={isActive} isLive />
    ),
    status: [ProductStatus.LIVE],
  },
  {
    label: () => <ScheduledMenuItem items={products} effectiveDatePropName="effectiveFrom" isActive />,
    status: [ProductStatus.LIVE],
    isScheduled: true,
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem items={products} itemStatus={ProductStatus.CLOSED} isActive={isActive} />
    ),
    status: [ProductStatus.CLOSED],
  },
]

export default getSyndicationStatusFilterOptions
