import { maxBy } from 'lodash'
import { ExtendedProduct, LimitsFormValues, SchemeName, TransactionName } from 'store/products/types'

export const toLimitsFormValues = (product: ExtendedProduct): LimitsFormValues | null =>
  product.limits && {
    // This below commented code will be used, Dont remove!!!!!!!

    // productLimitPersonalCurrentAccount: (product.limits.productLimits || []).filter(
    //   limit => limit.productType === ProductType.PERSONAL_CURRENT_ACCOUNT
    // ),
    // productLimitCurrentPool: (product.limits.productLimits || []).filter(
    //   limit => limit.productType === ProductType.CURRENT_POOL
    // ),
    // productLimitSavingsPool: (product.limits.productLimits || []).filter(
    //   limit => limit.productType === ProductType.SAVINGS_POOL
    // ),
    // productLimitBillsPool: (product.limits.productLimits || []).filter(
    //   limit => limit.productType === ProductType.BILLS_POOL
    // ),
    // productLimitAccount: (product.limits.productLimits || []).filter(
    //   limit => limit.productType === ProductType.ACCOUNT
    // ),
    // productLimitMortgage: (product.limits.productLimits || []).filter(
    //   limit => limit.productType === ProductType.MORTGAGE
    // ),
    // productLimitLoan: (product.limits.productLimits || []).filter(limit => limit.productType === ProductType.LOAN),
    // productLimitCurrentAccount: (product.limits.productLimits || []).filter(
    //   limit => limit.productType === ProductType.CURRENT_ACCOUNT
    // ),
    // productLimitOther: (product.limits.productLimits || []).filter(limit => limit.productType === ProductType.OTHER),
    // productLimitCustom: (product.limits.productLimits || []).filter(limit => limit.productType === ProductType.CUSTOM),
    schemeLimitsFPS: (product.limits.schemeLimits || []).filter((limit) => limit.schemeName === SchemeName.FPS),
    schemeLimitsBACS: (product.limits.schemeLimits || []).filter((limit) => limit.schemeName === SchemeName.BACS),

    subscriptionLimitOnly: product.limits.subscriptionLimit ? [product.limits.subscriptionLimit] : [],
    cardLimitOnly: product.limits.cardLimit ? [product.limits.cardLimit] : [],

    transactionLimitsATM: (product.limits.transactionLimits || []).filter(
      (limit) => limit.transactionName === TransactionName.ATM_WITHDRAWAL
    ),
    transactionLimitsOUT: (product.limits.transactionLimits || []).filter(
      (limit) => limit.transactionName === TransactionName.TRANSFER_OUT
    ),
  }

export const cardLimitMaximumNumberValidation = (limitMaximumNumber?: number, maximumNumber?: number): boolean =>
  !limitMaximumNumber || !maximumNumber || limitMaximumNumber < maximumNumber

export const getMaxCardMaximumNumber = (product: ExtendedProduct): number | undefined => {
  const { cards } = product
  if (!!cards && cards.length > 0) {
    const max = maxBy(cards, 'maximumNumber')
    return max?.maximumNumber
  }
  return undefined
}
