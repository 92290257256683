import React from 'react'
import styled from 'styled-components/macro'
import { Grid, Typography, Dialog, Paper, Divider, Link, Select } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Error } from '@material-ui/icons'
import AccordionActions from '@material-ui/core/AccordionActions'
import { EntityBadgeHistory } from 'dls/atoms'
import { SelectFieldComplex } from 'components'

export const MainGrid = styled(Grid)`
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledGridActivity = styled(({ paddingTop, ...props }) => <Grid {...props} />)<{
  thinTopPadding?: boolean
}>`
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: ${(props: any) => (props.paddingTop ? props.paddingTop : '43px')};
  }
`

export const StyledInfoGrid = styled.div`
  margin-top: 20px;
  margin-bottom: 18px;
`
export const BoldText = styled(Typography)`
  font-weight: 500;
`
export const BMTable = styled(Table)`
  .MuiTableCell-root {
    padding-left: 0;
  }
`

export const ContentPaperForStickyBottomBar = styled(Paper)`
  margin-bottom: 24px;
  overflow: auto;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConditionalTableCell = styled(({ showBorder, $topPaddingThick, $bottomPaddingThick, ...props }) => (
  <TableCell {...props} />
))<{
  showBorder?: boolean
  $topPaddingThick?: boolean
  $topPaddingThin?: boolean
  $bottomPaddingThick?: boolean
}>`
  border-bottom: ${(props: any) => (props.showBorder ? 'auto' : 'none')};
  padding-top: ${(props: any) => {
    if (props.$topPaddingThick && props.$topPaddingThin) {
      return '20px'
    }
    if (props.$topPaddingThick) {
      return '15px'
    }
    return props.$topPaddingThin ? '5px' : '0px'
  }};
  padding-bottom: 16px;
  vertical-align: top;

  .MuiLink-underlineHover {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

export const ContentPaper = styled(Paper)`
  padding: 24px;
  margin-bottom: 24px;
  overflow: auto;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSelectFieldComplex = styled(({ hasPlaceholder, ...rest }) => (
  <SelectFieldComplex {...rest}>{rest.children}</SelectFieldComplex>
))<{
  hasPlaceholder: boolean
}>`
  .MuiSelect-select.MuiSelect-select {
    color: ${(props) => (props.hasPlaceholder ? props.theme.palette.text.disabled : props.theme.palette.text.primary)};
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSelect = styled(({ hasPlaceholder, ...rest }) => <Select {...rest}>{rest.children}</Select>)<{
  hasPlaceholder: boolean
}>`
  .MuiSelect-select.MuiSelect-select {
    color: ${(props) => (props.hasPlaceholder ? props.theme.palette.text.disabled : props.theme.palette.text.primary)};
  }
`

export const ThickDivider = styled(Divider)`
  height: 3px;
  background-color: ${(props: any) => `${props.theme.palette.text.secondary}`};
  margin-bottom: 8px;
`

export const LightThickDivider = styled(ThickDivider)`
  background-color: ${(props) => props.theme.palette.divider};
  margin-bottom: 20px;
`

export const Header = styled(Typography)`
  margin-top: 24px;
`
export const ContentSpace = styled.div`
  padding-top: 16px;
  width: 100%;
`
export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`
export const SubField = styled.div`
  padding-top: 16px;
`
export const InputType = styled.div`
  padding-top: 8px;
`

export const TooltipStyle = styled.div`
  margin-top: 16px;
`
export const RoundingTypographyStyle = styled(Typography)`
  font-size: 10px;
`

export const StyledContainerPaper = styled(Paper)`
  margin: 1em 0;
  &:hover {
    background-color: white;
    box-shadow: ${(props: any) => `${props.theme.shadows[8]}`};
  }
`
export const MultiElementDiv = styled.div<{ noPadding?: boolean }>`
  margin-bottom: ${(props: any) => (props.noPadding ? `0` : '8px')};
`

export const StyledAccordionActions = styled(AccordionActions)`
  margin-right: 24px;
  margin-bottom: 16px;
  padding-right: 0;
  margin-top: 14px;
`
export const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 18px;
  padding-top: 0;
  padding-bottom: 4px;
  display: block;
`
export const StyledSpan = styled(Typography)`
  padding-left: 6px;
  vertical-align: middle;
  display: inline;
  font-size: 14px;
`
export const StyledHistory = styled(EntityBadgeHistory)`
  vertical-align: middle;
`

export const StyledError = styled(Error)`
  font-size: 20px;
  vertical-align: middle;
`
export const SpaceBetweenGrid = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: flex-start;
  height: 23px;
}
`

export const CursorLink = styled(Link)`
  cursor: pointer;
  padding-top: 5px;
`

export const ActionsGrid = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: normal;
  width: inherit;
  height: 48px;
`
