import React, { ElementType } from 'react'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import styled, { css } from 'styled-components/macro'
import { Button } from 'dls/atoms/Button'
import { ButtonCustom } from 'dls/shared/styled'
import { ColorType } from 'dls/shared/types'

interface ButtonExpandCollapseProps extends ButtonCustom {
  open: boolean
}

interface ButtonWithText extends ButtonCustom {
  component?: string | ElementType
  to?: string
  color?: ColorType
}

const DLSButton = styled(Button)`
  margin-right: 24px;

  &:hover {
    background-color: 'transparent';
  }
  & .MuiButton-endIcon {
    margin-right: -2px;
    margin-left: 4px;
  }
  & svg {
    vertical-align: middle;
  }
`

export const buttonColorSelector = css<ButtonCustom>`
  ${({ color, theme }: any) => {
    switch (color) {
      case ColorType.BRAND:
        return {
          color: `${theme.palette.primary.main}`,
        }
      case ColorType.PRIMARY:
        return {
          color: `${theme.palette.text.primary}`,
        }
      case ColorType.SECONDARY:
        return {
          color: `${theme.palette.text.secondary}`,
        }
      case ColorType.ERROR:
        return {
          color: `${theme.palette.error.dark}`,
        }
      case ColorType.INFO:
        return {
          color: `${theme.palette.info.main}`,
        }
      case ColorType.SUCCESS:
        return {
          color: `${theme.palette.success.main}`,
        }
      case ColorType.BUTTON:
        return {
          color: `${theme.palette.secondary.dark}`,
        }
      case ColorType.PAPER:
        return {
          color: `${theme.palette.background.light}`,
        }
      default:
        return {}
    }
  }}
`

const DLSButtonWithHover = styled(DLSButton)`
  text-transform: initial;
  ${({ theme }) => `
    &:focus {
      border-bottom: 1px solid ${theme.palette.secondary.main};
      border-radius: 0
    }
  `};
  &.MuiButton-text {
    ${buttonColorSelector}
  }
`

export const StyledButtonWithFocusUnderline = styled(DLSButtonWithHover)`
  &:focus {
    text-decoration: underline;
    border-bottom: 0;
  }
`

export const ButtonExpandCollapse = ({ open, ...props }: ButtonExpandCollapseProps) => (
  <DLSButton
    variant="text"
    {...props}
    data-dls="molecules-ButtonExpandCollapse"
    endIcon={open ? <ExpandLess data-dls="molecules-ButtonExpandCollapse" /> : <ExpandMore />}
  />
)

export const LinkWithText = ({ style, color, ...props }: ButtonWithText) => (
  <DLSButtonWithHover
    variant="text"
    data-dls="molecules-linkWithText"
    endIcon={props.endIcon}
    color={color || ColorType.BUTTON}
    disableElevation
    disableFocusRipple
    disableRipple
    role="button"
    style={{
      backgroundColor: 'transparent',
      fontSize: 'inherit',
      margin: '0 4px 0 0',
      padding: 0,
      fontWeight: 'inherit',
      lineHeight: 0,
      display: 'line-block',
      ...style,
    }}
    {...props}
  />
)

export default { ButtonExpandCollapse }
