import React from 'react'
import { useParams } from 'react-router-dom'
import ProductSyndicationForm from 'pages/features/ProductSyndication/components/update/shared/SyndicationForm'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import { getProductCategoryFromSegment } from 'pages/features/ProductSyndication/components/update/shared/utils'

const CreateProductSyndicationForm = () => {
  const { productKey = '' } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)

  if (isLoading || !product) {
    return <Loader />
  }

  const defaultValues = {
    productKey: product.productKey,
    productDescription: product.productDescription,
    productName: product.productName,
    productCategory: getProductCategoryFromSegment({
      productGroup: product.productGroup,
      segment: product.productSegment,
    }),
  }

  return <ProductSyndicationForm defaultValues={defaultValues} />
}

export default CreateProductSyndicationForm
