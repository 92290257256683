import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import { Cached } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { LoadingContainer, ReloadButton } from './Styled'
import { UnableToConnectToTheServer as WrongImage } from 'assets'
import { ColorType } from 'dls/shared/types'

const SomethingWentWrongImage = styled.img`
  width: 85px;
  margin-bottom: 16px;
`

export function ProductVersionLoader(props: {
  loading: boolean
  error: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}) {
  const { t } = useTranslation()

  return (
    <LoadingContainer container direction="column" justifyContent="center" alignItems="center" error={props.error}>
      {props.loading && (
        <>
          <CircularProgress />
          Loading Versions
        </>
      )}
      {props.error && (
        <>
          <SomethingWentWrongImage src={WrongImage} alt="Oops, something went wrong" />
          <Typography>{t('Oops, something went wrong')}</Typography>
          <ReloadButton onClick={props.onClick} startIcon={<Cached />} color={ColorType.NONE}>
            {t('Try again')}
          </ReloadButton>
        </>
      )}
    </LoadingContainer>
  )
}
