import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import {
  WithholdingTaxInfo,
  // don't delete, temporarly disabled HowProductVersionsWork,
  HowProductVersionsWorkNoRetire,
  MoreAboutPackage,
  MoreAboutSuspenseAccount,
  MoreAboutIndexRate,
  MoreAboutGovernance,
  UserManagement,
  RestrictTransactions,
  AmortisationStraightLine,
  AmortisationDecline,
  MoreAboutBankSettings,
  FlatRate,
  TiersRate,
  BandsRate,
  DebitInterestTypesImage,
  DebitInterestConfigurationImage,
  MoreAboutAboutProductsImage,
  MoreAboutAmountScheduleImage,
  MoreAboutPaymentLimitsImage,
  MoreAboutCardLimitsImage,
  MoreAboutCreditLimitImage,
  MoreAboutInterestStatementsImage,
  MultiPartyInfographicImage,
  MoreAboutInterestStatementsProductImage,
  FeesImage,
  RewardsImage,
  MoreAboutProductSyndicationImage,
} from 'assets'
import {
  moreAboutWithholdTax,
  productVersion,
  moreAboutPackagesInfo,
  howSuspenseAccountsWork,
  moreAboutIndexRates,
  moreAboutGovernanceInfo,
  aboutUserManagement,
  transactionRules,
  typeOfInterestRatesMarkup,
  transactionStatusMarkup,
  aboutAmortisationMethods,
  typeOfInterestRatesOption,
  aboutDebitInterest,
  moreAboutProducts,
  moreAmountPaymentSchedule,
  moreAboutPaymentLimits,
  moreAboutCardLimits,
  moreAboutCreditLimit,
  moreAboutInterestStatements,
  moreAboutMultiParty,
  moreAboutInterestStatementsProduct,
  moreAboutProductSyndication,
  moreAboutFees,
  moreAboutRewards,
} from 'utils/moreAbout.util'
import InfoGraphic from 'dls/organisms/InfoGraphic/InfoGraphic'
import { HelpModalType } from 'store/modal/types'
import { Body2, Subtitle2, Graphic } from 'dls'

const StyledImg = styled.img`
  margin-right: 8px;
  vertical-align: middle;
`

const DataContainer = styled.dd`
  margin: 0;
`
const DataCell = styled.div`
  ${({ theme }) => `
    margin: 0;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    ${theme.breakpoints.up('sm')} {
      flex: 1 1 2em;
      padding: 0 16px 30px 0;
    }
  `};
`

const DataRow = styled.div`
  ${({ theme }) => `
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid ${theme.palette.divider};
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      & ${DataCell} {
        padding: 0;
        ${theme.breakpoints.up('sm')} {
          flex: 1 1 2em;
          padding: 0 16px 0 0;
        }
      }
    }
    ${theme.breakpoints.up('sm')} {
      display: flex;
      flex-wrap: wrap;
    }
    &:first-child {
      padding: 0 0 20px;
    }
    & ${DataCell}: first-child {
      flex: 1 1 36%;       
      padding: 0;
    }
    & ${DataCell}: last-child {
      flex: 1 1 62%;        
      padding: 0;    
      text-align: right;
    }
  `}
`

const DLTextImageTitle = styled.dt`
  ${({ theme }) => `
    padding: 18px 0;
    border-bottom: 1px solid ${theme.palette.divider};

    ${theme.breakpoints.up('sm')} {
      flex: 1 1 20%;       
    }
    &:last-of-type {
      border: 0;
      padding-bottom: 0;
      & ${DataCell} {
        padding: 0;
        ${theme.breakpoints.up('sm')} {
          flex: 1 1 2em;
          padding: 0 4px 0 0;
        }
      }
    }
  `};
`

const DLTextImageDesc = styled.dd`
  ${({ theme }) => `
    padding: 18px 0;
    border-bottom: 1px solid ${theme.palette.divider};
    ${theme.breakpoints.up('sm')} {
      flex: 1 1 70%;       
      margin: 0
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      & ${DataCell} {
        padding: 0;
        ${theme.breakpoints.up('sm')} {
          flex: 1 1 2em;
          padding: 0 4px 0 0;
        }
      }
    }
  `};
`

const DLTextImage = styled.dl`
  ${({ theme }) => `
    margin: 0;
    padding: 0;

    ${theme.breakpoints.up('sm')} {
      display: flex;
      flex-wrap: wrap;
    }
    & ${DataCell}: first-child {
      flex: 1 1 2em;       
      padding: 0;
    }
    & ${DataCell}: last-child {
      flex: 1 1;        
      padding: 0;    
      text-align: left;
    }
  `}
`

interface Props {
  onClose: () => void
  helpModalType: HelpModalType
}

export const InfographicModal = ({ helpModalType, onClose }: Props) => {
  const { t } = useTranslation()

  switch (helpModalType) {
    case HelpModalType.PRODUCT_LIFECYCLE:
      return (
        <InfoGraphic
          id="more-about"
          title={t('How withholding tax works')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutWithholdTax}
          imageProps={[
            {
              src: WithholdingTaxInfo,
              alt: t('Illustration showing a rule being created and then the rule being managed once created'),
            },
          ]}
        />
      )
    case HelpModalType.PRODUCT_VERSIONING:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About product versions')}
          onClose={onClose}
          outsideClick={onClose}
          details={productVersion}
          imageProps={[
            {
              src: HowProductVersionsWorkNoRetire, // don't delete, temporarly disabled HowProductVersionsWork,
              title: t('Graphic for how product versions work.'),
              alt: t('Graphic for how product versions work.'),
            },
          ]}
        />
      )
    case HelpModalType.PACKAGE:
      return (
        <InfoGraphic
          showInRows
          showIndex
          id="more-about"
          title={t('How packages work')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutPackagesInfo}
          imageProps={[
            {
              src: MoreAboutPackage,
              title: t(
                'Illustration of a Product Manager creating a package of products, adding attributes to the packages, adding an effective date and a customer seeing the live packages products in their app'
              ),
              alt: t(
                'Illustration of a Product Manager creating a package of products, adding attributes to the packages, adding an effective date and a customer seeing the live packages products in their app'
              ),
            },
          ]}
        />
      )

    case HelpModalType.SIDE_PANEL:
      return (
        <InfoGraphic
          showInRows
          showIndex
          id="more-about"
          title={t('How packages work')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutPackagesInfo}
          imageProps={[
            {
              src: MoreAboutPackage,
              title: t('More about package'),
              alt: t('More about package'),
            },
          ]}
        />
      )

    case HelpModalType.SUSPENSE_ACCOUNTS:
      return (
        <InfoGraphic
          id="more-about"
          title={t('How suspense accounts work')}
          onClose={onClose}
          outsideClick={onClose}
          details={howSuspenseAccountsWork}
          imageProps={[
            {
              src: MoreAboutSuspenseAccount,
              title: t(
                'Illustration showing pending and partially cleared transactions being cleared to other accounts and the status changes and contra entries as a result'
              ),
              alt: t(
                'Illustration showing pending and partially cleared transactions being cleared to other accounts and the status changes and contra entries as a result'
              ),
            },
          ]}
        />
      )

    case HelpModalType.GOVERNANCE:
      return (
        <InfoGraphic
          showInRows
          showIndex
          id="more-about"
          title={t('How governance works')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutGovernanceInfo}
          imageProps={[
            {
              src: MoreAboutGovernance,
              title: t(
                'Illustration showing governance task being created and used later to manage products in Bank Manager and showing how assignees can approve or reject a product'
              ),
              alt: t(
                'Illustration showing governance task being created and used later to manage products in Bank Manager and showing how assignees can approve or reject a product'
              ),
            },
          ]}
        />
      )

    case HelpModalType.INDEX_RATES:
      return (
        <InfoGraphic
          showInRows
          showIndex
          id="more-about"
          title={t('How index rates work')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutIndexRates}
          imageProps={[
            {
              src: MoreAboutIndexRate,
              title: t(
                'Illustration showing a central bank rate being created with an effective date and the rate being show on a product in the customers app'
              ),
              alt: t(
                'Illustration showing a central bank rate being created with an effective date and the rate being show on a product in the customers app'
              ),
            },
          ]}
        />
      )

    case HelpModalType.REFERENCE_DATA:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About reference data')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutIndexRates}
          imageProps={[
            {
              src: MoreAboutBankSettings,
              title: t(
                'Illustration showing a central bank rate being created with an effective date and the rate being show on a product in the customers app'
              ),
              alt: t(
                'Illustration showing a central bank rate being created with an effective date and the rate being show on a product in the customers app'
              ),
            },
          ]}
        />
      )

    case HelpModalType.USERS:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About user management')}
          onClose={onClose}
          outsideClick={onClose}
          details={aboutUserManagement(t)}
          imageProps={[
            {
              src: UserManagement,
              title: t(
                'Diagram showing users and roles being created in Active Directory and used with Single Sign On'
              ),
              alt: t('Diagram showing users and roles being created in Active Directory and used with Single Sign On'),
            },
          ]}
        />
      )

    case HelpModalType.TRANSACTION_STATUS:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About transaction rules')}
          onClose={onClose}
          outsideClick={onClose}
          details={transactionRules}
          imageProps={[
            {
              src: RestrictTransactions,
              title: t('Illustration showing the product Transaction Rules'),
              alt: t('Illustration showing the product Transaction Rules'),
            },
          ]}
        />
      )

    case HelpModalType.DEBIT_INTEREST:
      return (
        <InfoGraphic
          id="more-about"
          title={t('How debit interest works')}
          onClose={onClose}
          outsideClick={onClose}
          details={aboutDebitInterest}
          imageProps={[
            {
              src: DebitInterestTypesImage,
              alt: t('Diagram illustrating debit interest types'),
            },
            {
              src: DebitInterestConfigurationImage,
              alt: t('Diagram illustrating debit interest configuration'),
            },
          ]}
        />
      )

    case HelpModalType.PAYMENT:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About amortisation methods')}
          onClose={onClose}
          outsideClick={onClose}
          details={aboutAmortisationMethods(t)}
          imageProps={[
            {
              src: AmortisationStraightLine,
              alt: t('Bar chart showing the straight line amortisation method'),
            },
            {
              src: AmortisationDecline,
              alt: t('Bar chart showing the declining balance amortisation method'),
            },
          ]}
        />
      )

    case HelpModalType.INTEREST_RATE_OPTION:
      return (
        <InfoGraphic
          id="more-about"
          title={t('Interest rate options')}
          onClose={onClose}
          outsideClick={onClose}
          layout="imageFirst"
          details={typeOfInterestRatesOption}
          imageProps={[
            {
              src: FlatRate,
              alt: t('Illustration of flat interest'),
              styleColumn: { 'text-align': 'left' },
            },
            {
              src: TiersRate,
              alt: t('Illustration of tiered interest'),
              styleColumn: { textAlign: 'left' },
            },
            {
              src: BandsRate,
              alt: t('Illustration of banded interest'),
              styleColumn: { textAlign: 'left' },
            },
          ]}
        />
      )

    case HelpModalType.INTEREST_RATES:
      return (
        <InfoGraphic
          id="more-about"
          title={t('Types of interest rate')}
          onClose={onClose}
          outsideClick={onClose}
          details={
            Array.isArray(typeOfInterestRatesMarkup) ? (
              <dl>
                {typeOfInterestRatesMarkup.map((item, index) => (
                  <React.Fragment key={`${item.infoTitle}${item.infoStatement}${item.infoImage}`}>
                    {item.infoTitle && (
                      <dt key={`interestRateTile-${index}`}>
                        <Subtitle2>{t(item.infoTitle)}</Subtitle2>
                      </dt>
                    )}
                    <DataContainer>
                      <DataRow>
                        {item.infoStatement && (
                          <DataCell>
                            <Body2>{t(item.infoStatement)}</Body2>
                          </DataCell>
                        )}
                        {item.infoImage && (
                          <DataCell>
                            <Graphic
                              alt="Enter a description of the graphic"
                              ariaProps={{
                                'aria-hidden': true,
                              }}
                              src={item.infoImage.src}
                            />
                          </DataCell>
                        )}
                      </DataRow>
                    </DataContainer>
                  </React.Fragment>
                ))}
              </dl>
            ) : null
          }
        />
      )
    case HelpModalType.STATUS_TABLE:
      return (
        <InfoGraphic
          id="more-about"
          title={t('Status')}
          onClose={onClose}
          outsideClick={onClose}
          details={
            Array.isArray(transactionStatusMarkup) ? (
              <DLTextImage>
                {transactionStatusMarkup.map((item, index) => (
                  <>
                    {item.infoTitle && (
                      <DLTextImageTitle key={`interestRateTile-${index}`}>
                        {item.infoTitle.image && <StyledImg {...item.infoTitle.image} />}
                        {t(item.infoTitle.copy)}
                      </DLTextImageTitle>
                    )}
                    <DLTextImageDesc>{item.infoStatement}</DLTextImageDesc>
                  </>
                ))}
              </DLTextImage>
            ) : null
          }
        />
      )

    case HelpModalType.PRODUCTS:
      return (
        <InfoGraphic
          showInRows={false}
          id="more-about"
          title={t('About products')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutProducts}
          imageProps={[
            {
              src: MoreAboutAboutProductsImage,
              alt: t(
                'Illustration showing a product being created, features added, lifecycle statuses from design to live and the product deployed as a package and available in the customers app.'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )
    case HelpModalType.AMOUNT_PAYMENT_SCHEDULE:
      return (
        <InfoGraphic
          showInRows={false}
          id="more-about"
          title={t('About amount and payment schedule')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAmountPaymentSchedule}
          imageProps={[
            {
              src: MoreAboutAmountScheduleImage,
              alt: t(
                'Illustration showing the amount, term and amortisation method being set for a product, how amortisation methods work, and the setting of repayment frequencies.'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    case HelpModalType.PAYMENT_LIMITS:
      return (
        <InfoGraphic
          showInRows={false}
          id="more-about"
          title={t('Payment limits')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutPaymentLimits}
          imageProps={[
            {
              src: MoreAboutPaymentLimitsImage,
              alt: t(
                'Illustration showing payment limits being configured with a customer override enabled and a customer view and adjusting a limit in their app.'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    case HelpModalType.CARD_LIMITS:
      return (
        <InfoGraphic
          showInRows={false}
          id="more-about"
          title={t('Card transaction limits')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutCardLimits}
          imageProps={[
            {
              src: MoreAboutCardLimitsImage,
              alt: t(
                'Illustration showing card transaction limits being configured and a customer viewing and adjusting limits in their mobile app.'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    case HelpModalType.CREDIT_LIMIT:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About credit limit')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutCreditLimit}
          imageProps={[
            {
              src: MoreAboutCreditLimitImage,
              alt: t(
                'Illustration showing a colleague configuring the minimum and maximum credit limit and a customer choosing the amount they want to borrow within that range within their app.'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )
    case HelpModalType.MULTI_PARTY:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About multi-party')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutMultiParty}
          imageProps={[
            {
              src: MultiPartyInfographicImage,
              alt: t(
                'Illustration showing a colleague configuring multi-party in Bank Manager and a customer inviting a friend to their account in their app.'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    case HelpModalType.INTEREST_STATEMENTS:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About interest statements')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutInterestStatements}
          imageProps={[
            {
              src: MoreAboutInterestStatementsImage,
              alt: t(
                'Illustration showing an interest statement configuration and the statement being viewed in the customers app'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    case HelpModalType.INTEREST_STATEMENTS_PRODUCT:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About statements')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutInterestStatementsProduct}
          imageProps={[
            {
              src: MoreAboutInterestStatementsProductImage,
              alt: t('Diagram showing a Credit interest statement being used by one or many product subscriptions'),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    case HelpModalType.PRODUCT_SYNDICATION:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About product syndication')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutProductSyndication}
          imageProps={[
            {
              src: MoreAboutProductSyndicationImage,
              alt: t(
                'Diagram showing meta data being added to a product syndication and the syndication being exposed on a third-party web app'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    case HelpModalType.FEES:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About fees')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutFees}
          imageProps={[
            {
              src: FeesImage,
              alt: t('About fees'),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )
    case HelpModalType.REWARDS:
      return (
        <InfoGraphic
          id="more-about"
          title={t('About rewards')}
          onClose={onClose}
          outsideClick={onClose}
          details={moreAboutRewards}
          imageProps={[
            {
              src: RewardsImage,
              alt: t(
                'Illustration showing conditional and unconditional scheduled rewards being configured by a colleague and appearing in a customer’s app'
              ),
              styleColumn: { 'text-align': 'left' },
            },
          ]}
        />
      )

    default:
      return null
  }
}
export default InfographicModal
