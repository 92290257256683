import React from 'react'
import { DialogActions, DialogContent, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import useCardImages from './useCardImages'
import ImageCard from 'components/ImageCard'
import ApiStatesDialogsManager from 'components/ApiStatesDialogsManager'
import { NoResults } from 'assets'
import { StandardModal } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  open: boolean
  closeDialog: (newImage?: string) => void
  currentImage?: string
}

const CardsGrid = styled(Grid)`
  background-color: ${(props) => props.theme.palette.background.default};
  padding-top: 5px;
`

const StyledDialogActions = styled(DialogActions)`
  padding: 24px;
`

const AddCardDialog = ({ closeDialog, open, currentImage }: Props) => {
  const { t } = useTranslation()
  const { cardImages, isLoading, error, selectedImage, setSelectedImage } = useCardImages(
    currentImage ? { alt: '', url: currentImage } : null
  )

  const onChoiceClick = () => {
    closeDialog(selectedImage?.url)
  }

  return (
    <ApiStatesDialogsManager
      error={!!error}
      isLoading={isLoading}
      onBackClick={closeDialog}
      loadingDialog={{
        title: t('Retrieving card images'),
        subtitle: t('Please wait while we retrieve card images from \nAdobe Experience Manager'),
      }}
      customDialog={{
        title: t('We’re sorry, no card images are available'),
        subtitle: t(
          'Don’t worry, you can upload card images in Adobe \nExperience Manager. Once you’ve uploaded one you’ll be able\n to choose it here.'
        ),
        imageSrc: NoResults,
        open: !cardImages.length,
      }}
    >
      <StandardModal
        title={t('Choose image')}
        open={open}
        onClose={closeDialog}
        infoText={t(
          'Images are uploaded and managed in Adobe \n' +
            'Experience Manager (AEM). If you need to add a \n' +
            'new image or to change an existing one, log in\n' +
            'to AEM. '
        )}
      >
        <>
          <DialogContent>
            <CardsGrid direction="row" container spacing={2} justifyContent="flex-start">
              {cardImages.map((image) => (
                <ImageCard
                  key={image.url}
                  imageUrl={image.url}
                  title={image.alt}
                  onClick={() => setSelectedImage(image)}
                  isSelected={selectedImage?.url === image.url}
                />
              ))}
            </CardsGrid>
          </DialogContent>
          <StyledDialogActions>
            <Button onClick={() => closeDialog()} color={ColorType.BUTTON} variant="text">
              {t('Cancel')}
            </Button>
            <Button onClick={onChoiceClick} color={ColorType.BUTTON} variant="contained" disabled={!selectedImage}>
              {t('Choose')}
            </Button>
          </StyledDialogActions>
        </>
      </StandardModal>
    </ApiStatesDialogsManager>
  )
}
export default AddCardDialog
