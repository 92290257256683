import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { Body1, Body2, EntityBadgeProductSyndication } from 'dls/atoms'
import { ProductSyndication } from 'store/products/typings/productSyndication'
import { StatusBadge } from 'components'
import DeleteRetireDialog from 'components/DeleteRetireDialog/DeleteRetireDialog'
import useDeleteRetireDialog from 'pages/ProductSyndication/ProductSyndicationCatalog/hooks/useDeleteRetireDialog'
import SyndicationCatalogItemActions from 'pages/ProductSyndication/ProductSyndicationCatalog/components/SyndicationCatalogItemActions'
import { ProductStatus } from 'store/products/typings/productStatus'
import { getVersionText } from 'utils/productVersion.utils'

export const Region2Text = styled(Body2)`
  white-space: nowrap;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-right:10px;
    },
  `};
`

const StyledStatusBadge = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      width: 168px;
    }
    ${theme.breakpoints.down('md')} {
      & > span {
        width: 100%;
        .MuiTypography-root {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  `};
`
const ProductSyndicationCard = ({
  productSyndication,
}: {
  productSyndication: ProductSyndication
}): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { name, productKey, version, status, effectiveFrom, effectiveTo } = productSyndication
  const {
    open,
    closeConfirmAndMenu,
    handleDeleteOrRetire,
    actionType,
    openConfirm,
    anchorEl,
    setAnchorEl,
  } = useDeleteRetireDialog()

  return (
    <>
      <CatalogCard
        region1={[
          <EntityBadgeProductSyndication key="1" />,
          <Body1 key="2" style={{ wordBreak: 'break-word' }}>
            {name}
          </Body1>,
        ]}
        region2={[
          <Body2 css="white-space: nowrap" key={`version-${version}`}>
            {t('Version {{version}}', { version: getVersionText(version) })}
          </Body2>,
        ]}
        region3={[
          <StyledStatusBadge key={status}>
            <StatusBadge
              status={status}
              date={status === ProductStatus.CLOSED ? effectiveTo : effectiveFrom}
              showDate={status === ProductStatus.CLOSED}
            />
          </StyledStatusBadge>,
        ]}
        region4={[
          <SyndicationCatalogItemActions
            key="actions"
            itemStatus={status as ProductStatus}
            productSyndication={productSyndication}
            openConfirm={openConfirm}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl as any}
            closeConfirmAndMenu={closeConfirmAndMenu}
          />,
        ]}
        onClickOnCard={() => navigate(`/product-syndications/${productKey}`)}
        id={productKey}
      />
      <DeleteRetireDialog
        open={open}
        productOrPackageKey={productKey}
        closeDialog={closeConfirmAndMenu}
        handleDeleteOrRetire={handleDeleteOrRetire}
        productOrPackageName={name}
        actionType={actionType}
      />
    </>
  )
}

export default ProductSyndicationCard
