import React, { memo } from 'react'
import { Grid, Typography } from '@material-ui/core'

interface OwnProps {
  transactionCount: number
  maxSelectableTransactions: number
  transactionText: string
}

const SelectedTransactionCount = ({ transactionCount, maxSelectableTransactions, transactionText }: OwnProps) => (
  <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
    <Grid item>
      <Typography
        variant="h2"
        variantMapping={{ h2: 'div' }}
        style={{ fontSize: '3.75rem', lineHeight: '4.25rem' }}
        {...(transactionCount > maxSelectableTransactions ? { color: 'error' } : {})}
      >
        {transactionCount}
      </Typography>
    </Grid>
    <Grid item md={7}>
      <Typography
        variant="subtitle1"
        variantMapping={{ subtitle1: 'div' }}
        {...(transactionCount > maxSelectableTransactions ? { color: 'error' } : {})}
      >
        {transactionText}
      </Typography>
    </Grid>
  </Grid>
)

export default memo(SelectedTransactionCount)
