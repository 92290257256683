import { AUTH_TYPE } from 'api/client/authAwareAxios'

export enum UserActionType {
  LOGIN = 'USER/LOGIN',
  LOGIN_SUCCESS = 'USER/LOGIN/SUCCESS',
  LOGIN_FAIL = 'USER/LOGIN/FAIL',
  LOGOUT = 'USER/LOGOUT',
}

export interface UserState {
  loading: boolean
  loadingUser?: boolean
  authenticated: boolean
  error?: Error
  data?: UserData
  users: Colleague[]
  teams: Team[]
  selectedUser?: Colleague
  selectedTenantIndex?: number
  authType?: AUTH_TYPE
}

export interface UserData {
  accessToken: string
  refreshToken: string
  tenxToken: string
  scope: string
  tokenType: string
  expiresIn: string
}

export interface UserSSOLoginRequest {
  code: string
}

export interface UserLoginRequest {
  email: string
  password: string
}

export interface UserInformation {
  firstName: string | null
  lastName: string | null
  tenantKey: string | null
  userKey: string
  userName: string | null
  userRole?: UserRole
  colleagueRole?: UserRole
  userStatus: string | null
  email: string | null
  actions?: string
}

export interface TeamInformation {
  teamName: string | null
  teamEmail: string | null
  users: number | null
}

export interface UserProfile {
  firstName: string
  lastName: string
  title?: string
}

export type UserKey = string

export interface Colleague {
  userKey: string
  avatarURL?: string
  userRole: UserRole
  profile: UserProfile
  userGroups?: string[]
  email: string
}

export interface Team {
  scope?: string
  teamCreatedBy?: string
  teamEmail: string
  teamKey: string
  teamName: string
  tenantKey: string
  users?: Colleague[]
}

export interface TeamFormValues {
  scope?: string
  teamEmail: string
  teamKey?: string
  teamName: string
  users?: Colleague[]
}

export interface UserFormValues {
  email: string
  password: string
  profile: UserProfile
  userName: string
  userRole: UserRole
  userStatus: string
  passwordConfirmation?: string
}

export enum SelectUserTitle {
  MR = 'Mr',
  MRS = 'Mrs',
  MS = 'Ms',
  MX = 'Mx',
  MISS = 'Miss',
  DR = 'Dr',
}

export enum SelectUserRole {
  ADMIN = 'Admin',
  CREATOR = 'Creator',
  APPROVER = 'Approver',
}

export enum UserRole {
  ADMIN = 'BM_ADMIN',
  PRODUCT = 'BM_PRODUCT_CREATOR',
  ACCOUNTS = 'BM_ACCOUNTS_CREATOR',
}

export enum UserRoles {
  ALL_ROLES,
  BM_ACCOUNTS_CREATOR,
  BM_ADMIN,
  BM_PRODUCT_CREATOR,
}

export interface UserFilters {
  userRole: UserRoles
}
