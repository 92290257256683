import moment from 'moment-timezone'
import { Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { getProductVersionURLPostfix, getVersionText } from 'utils/productVersion.utils'

const relativeDate = (date: string) =>
  moment.duration(moment().diff(moment(date))).asDays() > 5 ? new Date(date).toLocaleString() : moment(date).fromNow()
export const getText = (product: Product) => {
  if (product.publishedDate) {
    return `Published: ${relativeDate(product.publishedDate)}`
  }
  if (product.updatedDate) {
    return `Modified: ${relativeDate(product.updatedDate)}`
  }
  if (product.status === ProductStatus.DESIGN && product.createdDate) {
    return `Created: ${relativeDate(product.createdDate)}`
  }
  return 'N/A'
}

export const getVersionAndlink = (index: number, product: Product, t: (text: string) => string) =>
  index === 0
    ? {
        version: `${t('CURRENT VERSION')}(${product.productName} ${getVersionText(product.version)}`,
        path: product.productKey,
      }
    : {
        version: `${product.productName} ${getVersionText(product.version)}`,
        path: `${product.productKey}/${getProductVersionURLPostfix(product)}`,
      }

export const getVersionStatus = (status: ProductStatus): string | null => {
  switch (status) {
    case ProductStatus.APPROVED:
      return 'Approved by'
    case ProductStatus.LIVE:
      return 'Published by'
    case ProductStatus.REVIEW:
      return 'Sent for review by'
    case ProductStatus.CLOSED:
      return 'Retired by'
    default:
      return null
  }
}

export const getVersionDateStatus = (status: ProductStatus): string | null => {
  switch (status) {
    case ProductStatus.SCHEDULED:
    case ProductStatus.LIVE:
      return 'Effective date'
    default:
      return null
  }
}
