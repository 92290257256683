import { createAction } from '@reduxjs/toolkit'
import {
  PackagesWithProducts,
  PublishPackageRequest,
  PackagesWithProductsFormValue,
  PackageCreationOrUpdateRequestPayload,
  ProductPackageName,
  ProductPackageId,
  BrandId,
  // eslint-disable-next-line import/named
  IsPublishPackage,
} from './types'
import { Product, RetireOrDeleteProductOrPackage, CountryIncluded } from 'store/products/types'
import { ShouldRedirectOnFail } from 'store/utils/types'

const actions = {
  getPackages: createAction<ShouldRedirectOnFail | undefined>('PACKAGES/FETCH'),
  getPackagesSuccess: createAction<PackagesWithProducts[]>('PACKAGES/FETCH/SUCCESS'),
  getPackagesFail: createAction<PackagesWithProducts[]>('PACKAGES/FETCH/FAIL'),

  packageName: createAction<ProductPackageName>('PACKAGES/NAME'),
  packageType: createAction<string>('PACKAGES/TYPE'),
  countryOfResidence: createAction<CountryIncluded | null>('PACKAGES/COUNTRYINCLUDED'),
  brandId: createAction<BrandId>('PACKAGES/BRANDID'),

  isPublishPackage: createAction<IsPublishPackage>('ISPUBLISH/PACKAGE'),

  publishPackage: createAction<PublishPackageRequest>('PACKAGES/PUBLISH'),
  publishPackageSuccess: createAction<PackagesWithProducts>('PACKAGES/PUBLISH/SUCCESS'),
  publishPackageFail: createAction<Error>('PACKAGES/PUBLISH/FAIL'),
  clearPublishSuccess: createAction('PACKAGES/PUBLISH/SUCCESS/CLEAR'),
  clearPublishError: createAction('PACKAGES/PUBLISH/FAIL/CLEAR'),

  createPackage: createAction<PackageCreationOrUpdateRequestPayload>('PACKAGES/CREATE'),
  createPackageSuccess: createAction<PackagesWithProducts>('PACKAGES/CREATE/SUCCESS'),
  createPackageFail: createAction<PackagesWithProducts>('PACKAGES/CREATE/FAIL'),

  availableProducts: createAction('PACKAGES/AVAILABLE/PRODUCT'),
  availableProductsSuccess: createAction<Product[]>('PACKAGES/AVAILABLE/PRODUCT/SUCCCESS'),
  availableProductsFail: createAction<Error>('PACKAGES/AVAILABLE/PRODUCT/FAIL'),

  updateSelectedPackage: createAction<PackagesWithProductsFormValue>('UPDATE/SELECTED/PACKAGE'),
  updateSelectedPackageSuccess: createAction<PackagesWithProducts>('UPDATE/SELECTED/SUCCESS'),
  updateSelectedPackageFail: createAction<PackagesWithProducts>('UPDATE/SELECTED/FAIL'),

  getPackageDetails: createAction<ProductPackageId>('PACKAGES/DETAILS/FETCH'),
  getPackageDetailsSuccess: createAction<PackagesWithProducts>('PACKAGES/DETAILS/FETCH/SUCCESS'),
  getPackageDetailsFail: createAction<PackagesWithProducts>('PACKAGES/DETAILS/FETCH/FAIL'),

  deletePackage: createAction<RetireOrDeleteProductOrPackage>('DELETE/PACKAGES'),
  deletePackageFail: createAction<Error>('DELETE/PACKAGES/FAIL'),
}
export default actions
