import React from 'react'
import { Divider, Grid, Paper, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import AssigneeBoxContainerPage from '../AssigneeBox/AssigneeBoxContainerPage'
import { MessageList, ProfileSection } from './Message'
import NoContentPlaceholder from './NoContentPlaceholder'
import { Message } from 'store/activities/types'
import { ProductStatus } from 'store/products/typings/productStatus'

export interface Props {
  messages: Message[]
  context?: string
  productStatus: ProductStatus
}

const StyledDiv = styled.div`
  max-height: 536px;
  overflow-y: auto;
  overflow-x: hidden;
`

const PaperMain = styled(Paper)`
  min-height: 378px;
  border-radius: 4px;
`
const GridSection = styled(Grid)`
  margin-top: 16px;
`

const StyleDiv = styled.div`
  padding-left: 16px;
  padding-top: 18px;
  padding-bottom: 17px;
  font-size: 16px;
`

const Conversation = ({ messages, context, productStatus }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {context === 'product' && <AssigneeBoxContainerPage productStatus={productStatus} />}
      <PaperMain elevation={1}>
        <StyleDiv>
          <Typography variant="body2" variantMapping={{ body2: 'h4' }}>
            {t('Activity').toUpperCase()}
          </Typography>
        </StyleDiv>
        <Divider />
        <StyledDiv>
          {messages.map((message, index) => (
            <GridSection item key={index}>
              <Grid container direction="column" justifyContent="flex-start" spacing={3}>
                <Grid item>
                  <ProfileSection message={message} />
                </Grid>
                <Grid item>
                  <MessageList message={message} />
                </Grid>
              </Grid>
            </GridSection>
          ))}
        </StyledDiv>
        <NoContentPlaceholder messages={messages} />
      </PaperMain>
    </>
  )
}

export default Conversation
