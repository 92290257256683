import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReviewRows } from 'dls'
import { SubscriptionCreationRule } from 'store/products/types'

interface OwnProps {
  subscriptionCreationRule: SubscriptionCreationRule
}

const ViewSubscriptionRule = (props: OwnProps): React.ReactElement => {
  const { defaultSubscriptionStatus } = props.subscriptionCreationRule

  const { t } = useTranslation()

  const ruleDefinitions = [
    defaultSubscriptionStatus && { term: t('Account status'), definitions: [t(defaultSubscriptionStatus)] },
  ].filter((item) => item)

  const ruleDetails = [
    {
      definitions: ruleDefinitions,
    },
  ]

  return <ReviewRows definitionsList={ruleDetails} />
}

export default ViewSubscriptionRule
