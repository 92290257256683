import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Identification } from 'store/products/types'
import { ReviewRows } from 'dls'
import { ReviewRowGroup, Separator } from 'dls/molecules/ReviewRows/types'

const StyledWrapper = styled.div`
  width: 100%;
  [data-dls='molecules-review-row']:last-child hr {
    display: none;
  }
`

const ViewProductCodes = (identification: Identification): React.ReactElement => {
  const { t } = useTranslation()

  const productDefinitions = [
    identification.productCode && {
      term: t('Product code'),
      itemSeparator: !identification.productCodeDescription ? ('light' as Separator) : null,
      definitions: [identification.productCode],
    },
    identification.productCodeDescription && {
      term: t('Description'),
      itemSeparator: 'light' as Separator,
      definitions: [identification.productCodeDescription],
    },
    identification.subProductCode && {
      term: t('Sub product code'),
      itemSeparator: !identification.subProductCodeDescription ? ('light' as Separator) : null,
      definitions: [identification.subProductCode],
    },
    identification.subProductCodeDescription && {
      term: t('Description'),
      itemSeparator: 'light' as Separator,
      definitions: [identification.subProductCodeDescription],
    },
  ].filter((item) => item)

  const prodDetails: ReviewRowGroup[] = [
    {
      definitions: productDefinitions as any,
    },
  ]

  return (
    <StyledWrapper>
      <ReviewRows definitionsList={prodDetails} />
    </StyledWrapper>
  )
}

export default ViewProductCodes
