import React from 'react'
import TaskForm from './Task'
import { TaskDefinition, TaskDefinitionFormValue } from 'store/tasks/types'
import { Loader } from 'components/'

interface Ownprops {
  saveTask: (taskDefinitionFormValue: TaskDefinitionFormValue) => void
  updateTask: (taskDefinitionFormValue: TaskDefinitionFormValue) => void

  availableTask: TaskDefinition[]
  isLoading: boolean
}

const TaskFormPage = (props: Ownprops) => {
  const { saveTask, availableTask, isLoading, updateTask } = props
  const data = {
    taskDefinitions: availableTask,
  }
  if (isLoading) {
    return <Loader />
  }
  return <TaskForm onSubmit={availableTask ? updateTask : saveTask} initialValues={data} />
}

export default TaskFormPage
