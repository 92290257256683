import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { EntityBadgeAnySource } from 'dls/atoms'
import { PaymentLimit, PaymentLimitRoutingDestination } from 'store/products/typings/paymentLimits'
import { CardHeader, ReviewRows, H6, Paper, Body1 } from 'dls'
import {
  getInBoundSingle,
  getInBoundCumulativeDay,
  getInBoundCumulativeMonth,
  getInBoundCumulativeYear,
  getInBoundMaxMonth,
  getInBoundMaxYear,
  getInBoundMaxDay,
} from 'pages/features/PaymentLimits/utilities/transformers'
import { getCurrencyInfo } from 'api/utils'
import buildDefinitions from 'pages/features/PaymentLimits/utilities/buildDefinitions'

interface OwnProps {
  paymentLimits: PaymentLimit[]
  currencyCode: string
  inline?: boolean
  showActions?: boolean
  hasSeparators?: boolean
  productKey?: string
}

const FromAnySourceRow = ({
  paymentLimits,
  currencyCode,
  inline = false,
  showActions = false,
  hasSeparators = false,
  productKey = '',
}: OwnProps) => {
  const { t } = useTranslation()

  const Wrapper = inline ? React.Fragment : Paper

  const definitions = buildDefinitions({
    single: getInBoundSingle(paymentLimits) as any,
    cumulativeDay: getInBoundCumulativeDay(paymentLimits) as any,
    cumulativeMonth: getInBoundCumulativeMonth(paymentLimits) as any,
    cumulativeYear: getInBoundCumulativeYear(paymentLimits) as any,
    maxTransactionsDay: getInBoundMaxDay(paymentLimits) as any,
    maxTransactionsMonth: getInBoundMaxMonth(paymentLimits) as any,
    maxTransactionsYear: getInBoundMaxYear(paymentLimits) as any,
    currencySymbol: getCurrencyInfo(currencyCode)?.nativeSymbol as any,
    productKey,
    t,
    hasSeparators,
    showActions,
    routingDestination: PaymentLimitRoutingDestination.NOT_APPLICABLE,
    ariaLabelledBy: 'any-source',
    allowCustomerOverride: false,
    testId: 'inbound-any-source',
  })

  return (
    <Wrapper>
      <Box mb={3}>
        <CardHeader
          title={
            inline ? (
              <H6 variantMapping={{ h6: 'h5' }} id="any-source" style={{ fontWeight: 'bold' }}>
                {t('From any source')}
              </H6>
            ) : (
              <Body1 variantMapping={{ body1: 'h3' }} id="any-source">
                {t('From any source')}
              </Body1>
            )
          }
          headerIcon={<EntityBadgeAnySource />}
        />
      </Box>
      <ReviewRows definitionsList={definitions} />
    </Wrapper>
  )
}

export default FromAnySourceRow
