import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  hasPaymentScheduleFeature,
  defaultPaymentScheduleValue,
} from 'pages/features/PaymentSchedule/utils/paymentScheduleTransformers'
import SetupForm from 'pages/features/PaymentSchedule/components/update/forms/SetupForm'
import { Feature, PaymentSchedule } from 'store/products/types'
import { selectConfig } from 'store/tenant-config/selectors'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useFeatureAvailable from 'pages/Product/hooks/useFeatureAvailable'
import ProductsActions from 'store/products/actions'

const PaymentSchedulePage = (): React.ReactElement | null => {
  const { productKey = '' } = useParams()
  const dispatch = useDispatch()
  const [product, isLoading] = useSelectedProduct(productKey)

  const setPaymentSchedule = (values: PaymentSchedule) => {
    if (product) {
      dispatch(ProductsActions.setPaymentSchedule({ ...values, productKey, version: product.version }))
    }
  }

  const tenantConfig = useSelector(selectConfig)

  useUpdateBreadcrumbs({ productKey, context: Feature.PAYMENT_SCHEDULE })
  useFeatureAvailable(productKey, Feature.PAYMENT_SCHEDULE)

  if (isLoading) {
    return <Loader />
  }

  return product ? (
    <SetupForm
      onSubmit={setPaymentSchedule}
      initialValues={product.paymentSchedule || defaultPaymentScheduleValue}
      hasFeature={hasPaymentScheduleFeature(product)}
      tenantConfig={tenantConfig}
      product={product}
      productKey={productKey}
    />
  ) : null
}

export default PaymentSchedulePage
