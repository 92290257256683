import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Add, ExpandLess, ExpandMore } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import { Button } from 'dls/atoms/Button'
import { CodeIconButton, DownloadIconButton } from 'dls/molecules/IconButtons/IconButtons'
import { H3 } from 'dls/atoms/Typography'
import { ContentContainer, ContentColumn } from 'dls/shared/styled'
import { ColorType } from 'dls/shared/types'

interface expandCollapseType extends Record<any, any> {
  open?: boolean
  expandCopy?: string
  collapseCopy?: string
}

interface OwnProps {
  title?: string
  codeButtonProps?: Record<any, any>
  downloadButtonProps?: Record<any, any>
  expandCollapseButtonProps?: expandCollapseType
  addFeatureButtonProps?: Record<any, any>
  id?: string
  className?: string
  entityAriaProps?: Record<string, string | boolean>
}

const EntityToolbar = ({
  title,
  codeButtonProps,
  downloadButtonProps,
  expandCollapseButtonProps,
  addFeatureButtonProps,
  id,
  className,
  entityAriaProps,
}: OwnProps) => {
  const { t } = useTranslation()

  const CodeButtonRender = () => {
    const { ariaProps, ...codeButtonAriaRest } = codeButtonProps as any
    return (
      <ContentColumn data-dls="organism-ExpandCollapseButtonRender">
        <CodeIconButton {...codeButtonAriaRest} {...ariaProps} />
      </ContentColumn>
    )
  }

  const DownloadButtonRender = () => {
    const { ariaProps, ...DownloadButtonRest } = downloadButtonProps as any
    return (
      <ContentColumn data-dls="organism-ExpandCollapseButtonRender">
        <DownloadIconButton {...DownloadButtonRest} {...ariaProps} />
      </ContentColumn>
    )
  }

  const ExpandCollapseButtonRender = () => {
    const {
      ariaProps,
      expandCopy = 'Expand All',
      collapseCopy = 'Collapse All',
      open: ExpandCollapseStatus,
      ...ExpandCollapseRest
    } = expandCollapseButtonProps as any
    return (
      <ContentColumn data-dls="organism-ExpandCollapseButtonRender">
        <Button
          endIcon={ExpandCollapseStatus ? <ExpandLess /> : <ExpandMore />}
          variant="text"
          color={ColorType.SECONDARY}
          {...ExpandCollapseRest}
          {...ariaProps}
        >
          {ExpandCollapseStatus ? t(collapseCopy) : t(expandCopy)}
        </Button>
      </ContentColumn>
    )
  }

  const AddFeatureButtonRender = () => {
    const { ariaProps, copy: AddFeatureButtonCopy, ...AddFeatureButtonRest } = addFeatureButtonProps as any

    return (
      <ContentColumn style={{ marginLeft: '16px' }} data-dls="organism-AddFeatureButtonRender">
        <Button color="secondary" startIcon={<Add />} variant="outlined" {...AddFeatureButtonRest} {...ariaProps}>
          {AddFeatureButtonCopy}
        </Button>
      </ContentColumn>
    )
  }

  return (
    <div role="toolbar" {...entityAriaProps} data-dls="organism-EntityToolbar">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        id={`${id}-container`}
        className={className}
      >
        <Grid item>
          {title && (
            <H3 id={`${id}-heading`} variantMapping={{ h3: 'h2' }}>
              {title}
            </H3>
          )}
        </Grid>
        <Grid item>
          <ContentContainer>
            {!isEmpty(codeButtonProps) && CodeButtonRender()}
            {!isEmpty(downloadButtonProps) && DownloadButtonRender()}
            {!isEmpty(expandCollapseButtonProps) && ExpandCollapseButtonRender()}
            {!isEmpty(addFeatureButtonProps) && AddFeatureButtonRender()}
          </ContentContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default EntityToolbar
