import React from 'react'
import { TFunction } from 'i18next'
import SingleLimitSubValue from 'pages/features/PaymentLimits/components/shared/SingleLimitSubValue'
import CumulativeLimitSubValue from 'pages/features/PaymentLimits/components/shared/CumulativeLimitSubValue'
import MaxTransactionsLimitSubValue from 'pages/features/PaymentLimits/components/shared/MaxTransactionsLimitSubValue'
import { PaymentLimit, PaymentLimitRoutingDestination } from 'store/products/typings/paymentLimits'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'

type BuildDefinitionsConfig = {
  single: PaymentLimit
  cumulativeDay: PaymentLimit
  cumulativeMonth: PaymentLimit
  cumulativeYear: PaymentLimit
  maxTransactionsDay: PaymentLimit
  maxTransactionsMonth: PaymentLimit
  maxTransactionsYear: PaymentLimit
  currencySymbol: string
  productKey: string
  t: TFunction
  hasSeparators: boolean
  showActions: boolean
  routingDestination: PaymentLimitRoutingDestination
  ariaLabelledBy: string
  allowCustomerOverride: boolean
  testId: string
}

const buildDefinitions = ({
  single,
  cumulativeDay,
  cumulativeMonth,
  cumulativeYear,
  maxTransactionsDay,
  maxTransactionsMonth,
  maxTransactionsYear,
  currencySymbol,
  t,
  hasSeparators,
  showActions,
  routingDestination,
  ariaLabelledBy,
  allowCustomerOverride,
  testId,
}: BuildDefinitionsConfig): ReviewRowGroup[] => [
  {
    ariaLabelledBy,
    definitions: [
      {
        itemSeparator: hasSeparators ? 'light' : undefined,
        term: t('Single transaction amount'),
        definitions: [
          <SingleLimitSubValue
            key={`singleTransation-${single?.amount?.maximum}`}
            paymentLimit={single}
            testId={`${testId}-single-transaction`}
            showActions={showActions}
            currencySymbol={currencySymbol}
            routingDestination={routingDestination}
            allowCustomerOverride={allowCustomerOverride}
          />,
        ],
      },
      {
        itemSeparator: hasSeparators ? 'light' : undefined,
        term: t('Cumulative max amount'),
        definitions: [
          <CumulativeLimitSubValue
            key={`cumulativeDay-${cumulativeDay?.amount?.maximum}`}
            labelKey="Day"
            testId={`${testId}-day`}
            paymentLimit={cumulativeDay}
            showActions={showActions}
            currencySymbol={currencySymbol}
            routingDestination={routingDestination}
            addUrlCtaKey="cumulativeDay"
            allowCustomerOverride={allowCustomerOverride}
          />,
          <CumulativeLimitSubValue
            key={`cumulativeMonth-${cumulativeMonth?.amount?.maximum}`}
            labelKey="Month"
            testId={`${testId}-month`}
            paymentLimit={cumulativeMonth}
            showActions={showActions}
            currencySymbol={currencySymbol}
            routingDestination={routingDestination}
            addUrlCtaKey="cumulativeMonth"
            allowCustomerOverride={false}
          />,
          <CumulativeLimitSubValue
            key={`cumulativeYear-${cumulativeYear?.amount?.maximum}`}
            labelKey="Year"
            testId={`${testId}-year`}
            paymentLimit={cumulativeYear}
            showActions={showActions}
            currencySymbol={currencySymbol}
            routingDestination={routingDestination}
            addUrlCtaKey="cumulativeYear"
            allowCustomerOverride={false}
          />,
        ],
      },
      {
        term: t('Max number of transactions'),
        definitions: [
          <MaxTransactionsLimitSubValue
            key={`maxDay-${maxTransactionsDay?.amount?.maximum}`}
            labelKey="Day"
            testId={`${testId}-day`}
            paymentLimit={maxTransactionsDay}
            showActions={showActions}
            routingDestination={routingDestination}
            addUrlCtaKey="maxDay"
          />,
          <MaxTransactionsLimitSubValue
            key={`maxMonth-${maxTransactionsMonth?.amount?.maximum}`}
            labelKey="Month"
            testId={`${testId}-month`}
            paymentLimit={maxTransactionsMonth}
            showActions={showActions}
            routingDestination={routingDestination}
            addUrlCtaKey="maxMonth"
          />,
          <MaxTransactionsLimitSubValue
            key={`maxYear-${maxTransactionsYear?.amount?.maximum}`}
            labelKey="Year"
            testId={`${testId}-year`}
            paymentLimit={maxTransactionsYear}
            showActions={showActions}
            addUrlCtaKey="maxYear"
            routingDestination={routingDestination}
          />,
        ],
      },
    ],
  },
]

export default buildDefinitions
