import React from 'react'
import { connect } from 'react-redux'
import Login from './LoginForm'
import { RootState } from 'store'
import { selectError, selectLoading } from 'store/user/selectors'
import UserActions from 'store/user/actions'

const mapDispatchToProps = {
  login: UserActions.login,
}

const mapStateToProps = (state: RootState) => ({
  error: selectError(state),
  loading: selectLoading(state),
})

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

const LoginPage = ({ error, login, loading }: Props) => (
  <Login
    onSubmit={login}
    error={error}
    loading={loading}
    initialValues={{
      email: '',
      password: '',
    }}
  />
)

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
