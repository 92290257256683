import { Box, Grid } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextFieldComplex } from 'components'

export const AgeEligibilityElement = ({ name }: { name: string }) => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ marginBottom: 4 }}
    >
      <Box p={2} width="30%">
        <Field
          name={`${name}.minimumAge`}
          required
          fullWidth
          label={t('Minimum age')}
          type="number"
          component={TextFieldComplex}
          placeholder={t('Enter age')}
          numberFormatProps={{
            decimalSeparator: false,
          }}
        />
      </Box>
      <Box p={2} width="30%">
        <Field
          name={`${name}.maximumAge`}
          fullWidth
          label={t('Maximum age')}
          type="number"
          component={TextFieldComplex}
          placeholder={t('Enter age or leave empty')}
          numberFormatProps={{
            decimalSeparator: false,
          }}
        />
      </Box>
    </Grid>
  )
}
