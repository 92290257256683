import React from 'react'
import { connect } from 'react-redux'
import { isFeatureEditableAfterLiveProduct } from '../product.utils'
import { RootState } from 'store/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { ExtendedProduct, Feature } from 'store/products/types'
import { isProductInGovernanceStatus } from 'utils/productVersion.utils'
import EditDeleteCardButtonGroup from 'pages/Layout/EditDeleteCardButtonGroup'

interface BootomContainerProps {
  featureName: string
  hide: boolean
  product: ExtendedProduct
  isCreditInterestEnabled?: boolean
  isDebitInterestEnabled?: boolean
  editPath: string
  deleteFeature(): void
}

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapStateToProps> & BootomContainerProps

const defaultFeatures = ['subscriptionCreationRule', 'currency', 'productCore', 'transactionRules']

function FeatureBottomContainer({
  tenantConfig,
  product,
  featureName,
  isDebitInterestEnabled,
  isCreditInterestEnabled,
  deleteFeature,
  editPath,
  hide,
}: Props) {
  if (
    hide ||
    isProductInGovernanceStatus(product) ||
    !isFeatureEditableAfterLiveProduct(tenantConfig, product, featureName as Feature)
  )
    return null

  let canDelete = false
  if (featureName === Feature.CREDITINTEREST) {
    canDelete = !!isCreditInterestEnabled
  } else if (featureName === Feature.DEBIT_INTEREST) {
    canDelete = !!isDebitInterestEnabled
  } else if (featureName === Feature.CARD_TRANSACTION_LIMITS) {
    canDelete = !!isDebitInterestEnabled
  } else if ([Feature.PAYMENT_LIMITS, Feature.FEES, Feature.REWARDS].includes(featureName as Feature)) {
    canDelete = false
  } else {
    canDelete = !defaultFeatures.includes(featureName)
  }

  return (
    <EditDeleteCardButtonGroup
      showDeleteIcon={canDelete}
      showEditIcon
      editPath={editPath}
      removeEntity={deleteFeature}
      ariaControlsLabel="menu-appbar"
    />
  )
}

export default connect(mapStateToProps)(FeatureBottomContainer)
