import { call, delay, put, takeLatest } from 'redux-saga/effects'
import TemplatesActions from './actions'
import { StatementApi } from 'api'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'

function* getTemplates(action: ReturnType<typeof TemplatesActions.getTemplates>) {
  const {
    payload: { productSegment, templateType },
  } = action
  try {
    const response: ReturnTypePromise<typeof StatementApi.getTemplates> = yield call(StatementApi.getTemplates, {
      productSegment,
      templateType,
    })
    yield delay(UI_DELAY_TO_SHOW_LOADING)

    yield put(
      TemplatesActions.getTemplatesSuccess({
        response: response && response.availableTemplates ? response.availableTemplates : [],
        templateType,
      })
    )
  } catch (e: any) {
    yield put(TemplatesActions.getTemplatesFail(templateType))
  }
}

export default function* () {
  yield takeLatest(TemplatesActions.getTemplates.type, getTemplates)
}
