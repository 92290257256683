import React from 'react'
import { Grid } from '@material-ui/core'
import { Main } from 'dls/shared/styled'

export interface TemplateFeatureFormProps {
  main: React.ReactNode
  header: React.ReactNode
  aside: React.ReactNode
  headerProps?: React.HTMLAttributes<HTMLDivElement>
  mainProps?: React.HTMLAttributes<HTMLDivElement>
  asideProps?: React.HTMLAttributes<HTMLDivElement>
}

const TemplateFeatureForm = ({ main, mainProps, headerProps, header, aside, asideProps }: TemplateFeatureFormProps) => (
  <Grid container direction="column" spacing={3} data-dls="template-feature-form">
    <Grid item>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item sm={12} md={12} lg={8}>
          <Grid container direction="column" spacing={3}>
            <Grid item sm={12}>
              <header
                {...headerProps}
                css={
                  headerProps?.css
                    ? `
          ${headerProps.css}
        `
                    : undefined
                }
              >
                {header}
              </header>
            </Grid>
            <Grid item sm={12}>
              <Main
                {...mainProps}
                css={
                  mainProps?.css
                    ? `
          ${mainProps.css}
        `
                    : undefined
                }
              >
                {main}
              </Main>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <aside
            {...asideProps}
            css={
              asideProps?.css
                ? `
          ${asideProps.css}
        `
                : undefined
            }
          >
            {aside}
          </aside>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

export default TemplateFeatureForm
