import { ProductGroup, ProductSegment } from 'store/products/types'
import { ProductSyndicationCategory } from 'store/products/typings/productSyndication'

export const getProductCategoryFromSegment = ({
  productGroup,
  segment,
}: {
  productGroup: ProductGroup
  segment: ProductSegment
}) => {
  if (productGroup === ProductGroup.LOANS) {
    switch (segment) {
      case ProductSegment.BUSINESS:
        return ProductSyndicationCategory.BUSINESS_LOANS
      case ProductSegment.PERSONAL:
        return ProductSyndicationCategory.PERS_LOANS
      default:
        return ProductSyndicationCategory.TRANS_OR_SAVINGS_ACCOUNTS
    }
  }
  return ProductSyndicationCategory.TRANS_OR_SAVINGS_ACCOUNTS
}
