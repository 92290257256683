import React from 'react'
import { CircularProgress, Grid, InputLabel, MenuItem, Select, SwipeableDrawer, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import FilterListIcon from '@material-ui/icons/FilterList'
import { isEmpty, orderBy } from 'lodash'
import styled from 'styled-components/macro'
import { Spring } from 'react-spring'
import { useTranslation } from 'react-i18next'
import TaskItem from './TaskItem'
import { Task } from 'store/tasks/types'
import { Order } from 'store/packages/types'
import { NoNotification } from 'assets'
import { Product } from 'store/products/types'

interface Props {
  products: Product[]
  open: boolean
  isFilterListOpen: boolean
  tasks: Task[]
  isLoading: boolean
  status: string
  date: Order

  handleDrawerOpenClose(): void

  handleFilterListOpenClose(): void

  handleDateFilter(event: React.ChangeEvent<{ value: unknown }>): void

  handleStatusFilter(event: React.ChangeEvent<{ value: unknown }>): void
}

const TasksDrawerHeader = styled.div`
  margin: 24px;
  margin-bottom: 8px;
`

const CloseWrapper = styled.span`
  text-align: right;
`

const Divider = styled.div`
  width: 100%;
  border: ${(props: any) => `2px solid ${props.theme.palette.background.default}`};
  box-shadow: ${(props: any) => `0 1px 0 0 ${props.theme.palette.divider}`};
`

const ZeroStateContainer = styled.div`
  margin-top: 48px;
  margin-left: 16px;
  margin-right: 16px;
  width: 304px;
  padding: 4px;
`

const StyledFilterListIcon = styled(FilterListIcon)<{ className: string }>`
  cursor: pointer;
  color: ${(props: any) => `${props.className === 'FilterListOpen' ? props.theme.palette.primary.main : ''}`};
`

const SelectGrid = styled(Grid)`
  margin-bottom: 1rem;
`

const AnimatedDiv = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`

const DivEmptyLanding = styled.div`
  height: 440px;
`

const GridMaxContentStyle = styled(Grid)`
  width: max-content;
`

const MainZeroContainer = styled(Grid)`
  height: 100%;
`

export enum TaskFILTER {
  PENDING = 'Pending review',
  CLOSED = 'Approved',
  REJECTED = 'Rejected',
}

const TasksCircularProgress = styled(CircularProgress)`
  position: relative;
  color: ${(props: any) => props.theme.palette.secondary};
  top: -25%;
  left: -12%;
  z-index: 1501;
`
const TasksDrawer = ({
  tasks,
  open,
  handleDrawerOpenClose,
  isLoading,
  handleFilterListOpenClose,
  isFilterListOpen,
  status,
  handleStatusFilter,
  date,
  handleDateFilter,
  products,
}: Props) => {
  const { t } = useTranslation()
  const filteredTask = status ? tasks.filter((task) => task.status === status) : tasks
  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={handleDrawerOpenClose}
        onOpen={handleDrawerOpenClose}
        role="presentation"
        id="notificationDrawer"
      >
        <TasksDrawerHeader>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} />
            <Grid item xs={9}>
              <Typography>{t('TASKS')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <CloseWrapper>
                <StyledFilterListIcon
                  className={isFilterListOpen ? 'FilterListOpen' : 'FilterListNotOpen'}
                  onClick={handleFilterListOpenClose}
                />
              </CloseWrapper>
            </Grid>
            <Grid item xs={1}>
              <CloseWrapper>
                <Close
                  onClick={handleDrawerOpenClose}
                  css={`
                    cursor: pointer;
                  `}
                />
              </CloseWrapper>
            </Grid>
          </Grid>
          {isFilterListOpen && (
            <Grid container spacing={3} justifyContent="space-between">
              <Spring from={{ marginTop: 0, opacity: 0 }} to={{ marginTop: 25, opacity: 1 }}>
                {(props) => (
                  <AnimatedDiv style={props}>
                    <SelectGrid item xs={12}>
                      <InputLabel shrink>{t('Status')}</InputLabel>
                      <Select value={status} displayEmpty onChange={handleStatusFilter} fullWidth>
                        <MenuItem value="">{t('All')}</MenuItem>
                        {Object.entries(TaskFILTER).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </SelectGrid>
                    <SelectGrid item xs={12}>
                      <InputLabel shrink>{t('Date')}</InputLabel>
                      <Select value={date} onChange={handleDateFilter} fullWidth>
                        <MenuItem value="desc">{t('Newest first')}</MenuItem>
                        <MenuItem value="asc">{t('Oldest first')}</MenuItem>
                      </Select>
                    </SelectGrid>
                  </AnimatedDiv>
                )}
              </Spring>
            </Grid>
          )}
        </TasksDrawerHeader>
        <Divider />
        {!isEmpty(filteredTask) && !isLoading ? (
          orderBy(filteredTask, 'createdAt', date).map((task) => (
            <TaskItem key={task.key} task={task} handleDrawerOpenClose={handleDrawerOpenClose} products={products} />
          ))
        ) : (
          <ZeroStateContainer>
            <DivEmptyLanding id="main_zero_landing">
              <MainZeroContainer container direction="row" alignItems="center" justifyContent="center">
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
                  {isLoading ? (
                    <Grid item>
                      <TasksCircularProgress />
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <img src={NoNotification} alt="landing page" />
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
                          <GridMaxContentStyle item>
                            <Typography variant="h4">{t('No tasks')}</Typography>
                          </GridMaxContentStyle>
                          <GridMaxContentStyle item>
                            <Typography variant="body2">{t('Relax and enjoy the moment')}</Typography>
                          </GridMaxContentStyle>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </MainZeroContainer>
            </DivEmptyLanding>
          </ZeroStateContainer>
        )}
      </SwipeableDrawer>
    </>
  )
}

export default TasksDrawer
