import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import AddRateCard from '../featurePage/AddRateCard'
import {
  buildIndependentRateDefinitions,
  buildStaticMarginRateDefinitions,
  buildVariableMarginRateDefinitions,
} from '../../utils/transformers'
import InterestRateDefinition from './InterestRateDefinition'
import { TypeOfIndexRate, LendingRateType, LendingRate, ExtendedProduct } from 'store/products/types'
import {
  getTypeOfIndexRateForView,
  selectedRateAndBankName,
} from 'pages/features/CreditInterest/utils/creditInterestTransformers'
import ProductsSelectors from 'store/products/selectors'
import EditDeleteCardButtonGroup from 'pages/Layout/EditDeleteCardButtonGroup'
import { Paper, Subtitle1 } from 'dls'
import { SelectedRateAndBankName } from 'store/referenceData/types'
import useInterestRates from 'pages/features/CreditInterest/hooks/useInterestRates'

interface Props {
  product: ExtendedProduct
  rateWithinLimit?: LendingRate
  rateAboveLimit?: LendingRate
  rateAboveLimitOverride?: LendingRate
  isLending: boolean
  title?: string
  position: 'featurePage' | 'productPage'
  currencyCode: string
  removeRate?: (rateType: LendingRateType) => void
}

const DebitInterestRateDefinitions = ({
  product,
  rateWithinLimit,
  rateAboveLimit,
  rateAboveLimitOverride,
  title,
  position,
  currencyCode,
  isLending,
  removeRate,
}: Props) => {
  const { t } = useTranslation()
  const { publishedDebitInterestRates } = useSelector(ProductsSelectors.selectProductPermissions)

  const { bankInterestRates } = useInterestRates(product.productKey, product)

  const isDeletionAllowed = (type: LendingRateType) => {
    const existingRatesAmount = [rateWithinLimit, rateAboveLimit, rateAboveLimitOverride].filter((rate) => rate).length

    if (existingRatesAmount === 1) {
      return false
    }

    if (type === LendingRateType.rateWithinLimit && !rateAboveLimit) {
      return false
    }

    if (type === LendingRateType.rateWithinLimit && rateAboveLimitOverride) {
      return false
    }

    if (type === LendingRateType.rateAboveLimit && !rateWithinLimit) {
      return false
    }
    if (publishedDebitInterestRates?.includes(type)) {
      return false
    }

    return true
  }

  const renderRate = ({ rate, type }: { rate?: LendingRate; type: LendingRateType }): React.ReactElement | null => {
    if (!rate) {
      if (position === 'featurePage' && !isLending) {
        return (
          <Box mb={2}>
            <AddRateCard
              rateType={type}
              disableAdd={type === LendingRateType.rateAboveLimitOverride && !rateWithinLimit}
            />
          </Box>
        )
      }

      return null
    }

    const { fixedVariableType, partnercentricModel } = rate
    const typeOfIndexRate = getTypeOfIndexRateForView(fixedVariableType, partnercentricModel)

    const renderIndexRate = () => {
      let bankRate: SelectedRateAndBankName | null = null

      if (bankInterestRates && rate.bankInterestRateIndex) {
        bankRate = selectedRateAndBankName(bankInterestRates, rate.bankInterestRateIndex)
      }

      switch (typeOfIndexRate) {
        case TypeOfIndexRate.INDEPENDENT_INDEXED_RATE:
          return (
            <InterestRateDefinition
              rateType={type}
              rate={rate}
              position={position}
              indexRateTypeText={t('Independent of index rate')}
              rateDefinitions={buildIndependentRateDefinitions({
                rate,
                currencyCode,
                t,
                showSeparator: position === 'productPage',
              })}
            />
          )
        case TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN:
          return (
            <InterestRateDefinition
              rate={rate}
              rateType={type}
              position={position}
              indexRateTypeText={t('Index rate + static margin = variable rate to customer')}
              rateDefinitions={buildStaticMarginRateDefinitions({
                rate,
                bankRate,
                currencyCode,
                t,
                showSeparator: position === 'productPage',
              })}
            />
          )
        case TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN:
          return (
            <InterestRateDefinition
              rate={rate}
              rateType={type}
              position={position}
              indexRateTypeText={t('Index rate + variable margin = static rate to customer')}
              rateDefinitions={buildVariableMarginRateDefinitions({
                rate,
                bankRate,
                currencyCode,
                t,
                showSeparator: position === 'productPage',
              })}
            />
          )
        default:
          return null
      }
    }

    switch (position) {
      case 'featurePage':
        return (
          <Box mb={2} mt={isLending ? 4 : 0}>
            <Paper>
              {renderIndexRate()}
              <EditDeleteCardButtonGroup
                showDeleteIcon={isDeletionAllowed(type)}
                showEditIcon
                editPath={`edit/${type}`}
                removeEntity={() => removeRate && removeRate(type)}
              />
            </Paper>
          </Box>
        )
      case 'productPage':
        return renderIndexRate()
      default:
        return null
    }
  }

  return (
    <>
      {title ? (
        <Subtitle1 variantMapping={{ subtitle1: 'h2' }} gutter style={{ marginTop: 24 }}>
          {t(title)}
        </Subtitle1>
      ) : null}
      {renderRate({ rate: rateAboveLimit, type: LendingRateType.rateAboveLimit })}
      {renderRate({ rate: rateWithinLimit, type: LendingRateType.rateWithinLimit })}
      {renderRate({ rate: rateAboveLimitOverride, type: LendingRateType.rateAboveLimitOverride })}
    </>
  )
}

export default DebitInterestRateDefinitions
