import React from 'react'
import { Grid, Fade } from '@material-ui/core'
import {
  HeadingGrid,
  CloseIconGrid,
  ContentRowContainer,
  StyledDialog,
  ContentContainer,
  ContentColumn,
  DescriptionSection,
  ImageSection,
  H3Styled,
  H6Styled,
  ImageColumn,
} from './styles'
import { stopPropagation } from 'utils/form.utils'
import { Graphic } from 'dls/atoms'
import { StyleOval, DialogStyledContainer } from 'dls/shared/styled'
import { ImageTypes } from 'dls/shared/types'
import { CloseIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface DetailsType {
  infoTitle: number | string
  infoStatement: React.ReactNode | string
}

interface OwnProps {
  id?: string
  title?: string
  ariaProps?: Record<string, string | boolean>
  outsideClick?: () => void
  imageProps?: ImageTypes[]
  details?: DetailsType[] | React.ReactNode
  layout?: 'default' | 'imageFirst'
  onClose?: () => void
  showInRows?: boolean
  showIndex?: boolean
  fullWidth?: boolean
}

const InfoGraphic = ({
  title,
  ariaProps,
  outsideClick,
  onClose,
  showInRows,
  id,
  showIndex,
  imageProps,
  details,
  fullWidth,
  layout = 'default',
}: OwnProps) => {
  const renderDefaultColumn = (iterator: any) => {
    if (Array.isArray(iterator) && iterator[0].infoStatement) {
      return showInRows ? (
        <ContentRowContainer>
          {iterator.map((item, index) => (
            <div key={`description-coloumn-${index}`} style={{ margin: 0 }}>
              {item.infoTitle && <H6Styled variantMapping={{ h6: 'h4' }}>{item.infoTitle}</H6Styled>}{' '}
              {(item.infoStatement || showIndex) && (
                <DescriptionSection style={showIndex ? { paddingLeft: 42 } : {}}>
                  {showIndex && <StyleOval>{index + 1}</StyleOval>} {item.infoStatement}
                </DescriptionSection>
              )}
            </div>
          ))}
        </ContentRowContainer>
      ) : (
        <ContentContainer>
          {iterator.map((item, index) => (
            <ContentColumn key={`description-coloumn-${index}`}>
              <H6Styled variantMapping={{ h6: 'h4' }}>{item.infoTitle}</H6Styled>
              <DescriptionSection>{item.infoStatement}</DescriptionSection>
            </ContentColumn>
          ))}
        </ContentContainer>
      )
    }
    if (Array.isArray(iterator) && iterator[0].src) {
      return (
        <ContentContainer>
          {iterator.map((item, index) => (
            <ImageColumn
              key={`graphic-coloumn-${index}`}
              style={{ textAlign: 'center', marginBottom: 12, ...item.styleColumn }}
            >
              <ImageSection>
                <Graphic src={item?.src} alt={item?.alt} {...item?.ariaProps} style={{ marginTop: 24 }} />
              </ImageSection>
            </ImageColumn>
          ))}
        </ContentContainer>
      )
    }
    if (iterator) {
      return (
        <ContentContainer>
          <ContentColumn key="description-coloumn">
            <DescriptionSection>{iterator}</DescriptionSection>
          </ContentColumn>
        </ContentContainer>
      )
    }

    return null
  }

  const renderDefaultLayout = () => (
    <>
      {details && renderDefaultColumn(details)}
      {imageProps && renderDefaultColumn(imageProps)}
    </>
  )
  const renderImageFirstLayout = () => (
    <>
      {imageProps && renderDefaultColumn(imageProps)}
      {details && renderDefaultColumn(details)}
    </>
  )

  return (
    <StyledDialog
      open
      role="dialog"
      scroll="paper"
      onClose={outsideClick}
      TransitionComponent={Fade}
      fullWidth={fullWidth}
      id={`${id}-dialog`}
      max-width="1230"
      aria-labelledby="infographic-heading"
      {...ariaProps}
      data-dls="organism-InfoGraphic"
    >
      <DialogStyledContainer id={`${id}-container`}>
        <Grid container direction="row" justifyContent="space-between" spacing={7}>
          <HeadingGrid item>
            <H3Styled id="infographic-heading">{title}</H3Styled>
          </HeadingGrid>
          <CloseIconGrid item>
            <CloseIconButton
              onClick={(e: any) => {
                stopPropagation(e)
                if (onClose) {
                  onClose()
                }
              }}
              aria-label="Close"
              data-test-id="dialog-close-button"
            />
          </CloseIconGrid>
        </Grid>
        {layout === 'imageFirst' ? renderImageFirstLayout() : renderDefaultLayout()}
      </DialogStyledContainer>
    </StyledDialog>
  )
}

export default InfoGraphic
