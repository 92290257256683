import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import sortBy from 'lodash/sortBy'
import { featureSVGIcons, featureNames, getFeatures, isFeatureAllowedByConfig, featureReadOnly } from './product.utils'
import { ExtendedProduct, Feature, AccountWithFeaturesMap } from 'store/products/types'
import Tile from 'components/ProductBuilder/Tile'
import { selectConfig } from 'store/tenant-config/selectors'
import { isVersionFirst } from 'utils/productVersion.utils'

interface OwnProps {
  product: ExtendedProduct
  setSelected: (featureName: string | null) => void
  selectedFeature: string | null
}

const FeatureTiles = (props: OwnProps): React.ReactElement => {
  const { product, selectedFeature, setSelected } = props
  const { t } = useTranslation()

  const tenantConfig = useSelector(selectConfig)

  const emptyFeatures = getFeatures(product, 'empty')
  const editableFeatures = (tenantConfig && tenantConfig.editableFeaturesAfterLiveProduct) || []

  const isFeatureAddible = (featureName: Feature) => {
    if (featureName === Feature.PRODUCTCORE || (!isVersionFirst(product) && !editableFeatures.includes(featureName))) {
      return false
    }

    return emptyFeatures.includes(featureName)
  }

  const features = sortBy(
    featureNames.filter(
      (i: Feature) =>
        AccountWithFeaturesMap[product.productType].includes(i) &&
        isFeatureAllowedByConfig(tenantConfig, i) &&
        featureReadOnly(i)
    ),
    [(i) => !isFeatureAddible(i), (i) => t(i)]
  )

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      {features.map((featureName) => (
        <Grid item key={featureName} id={featureName}>
          <Tile
            title={t(featureName)}
            SVGIcon={featureSVGIcons[featureName]}
            disabled={!isFeatureAddible(featureName)}
            selected={selectedFeature === featureName}
            toggleSelect={() => setSelected(featureName)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default FeatureTiles
