import React from 'react'
import CheckboxMui, { CheckboxProps } from '@material-ui/core/Checkbox'

export interface ACheckboxProps extends CheckboxProps {
  data?: string[]
}

const ACheckbox = (props: ACheckboxProps) => <CheckboxMui data-dls="atom-checkbox" color="secondary" {...props} />

export default ACheckbox
