import moment from 'moment-timezone'
import { DATE_FORMAT } from 'utils/date.utils'
import { BankInterestRate } from 'store/referenceData/types'
import { RateMultiplyByHundred } from 'utils/form.utils'

// eslint-disable-next-line consistent-return
export const toRateFormValues = (formVal: BankInterestRate) => {
  if (formVal) {
    const editableSelectedIndexRate = formVal
      ? formVal.rates.filter((item) => item.effectiveDate && moment(item.effectiveDate).isSameOrAfter(moment(), 'day'))
      : []
    return {
      editOrAddIndexRate: {
        rates: editableSelectedIndexRate.map((item) => {
          const itemNew = { ...item }
          itemNew.indexRate = itemNew.indexRate && RateMultiplyByHundred(itemNew.indexRate)
          itemNew.effectiveDate = itemNew.effectiveDate
            ? moment(itemNew.effectiveDate).format(DATE_FORMAT)
            : itemNew.effectiveDate
          return itemNew
        }),
      },
    }
  }
  return null
}
