import { ProcessExecution, Task } from 'store/tasks/types'
import { LifeCycle } from 'store/governance/typings/lifeCycle'

export type ProcessKey = string | undefined

export interface GovernanceState {
  isLoading: boolean
  error?: Error
  data: Task[]
  processExecutions: ProcessExecution[]
  processes: Process[]
  selectedProcess?: Process
  processExecutionsLoading: boolean
  processExecutionsError?: Error
}

export enum GenerationStrategy {
  ONE_FOR_EACH = 'ONE_FOR_EACH',
  NONE = 'NONE',
}

export enum EntityType {
  PRODUCT = 'product',
}

export interface ProcessFormValues {
  entityLifecycle: LifeCycle
  entityType: EntityType
  featureTasksGenerationStrategy: boolean
  name: string
  key: ProcessKey
}

export interface Process {
  createdAt: string
  createdBy: string
  entityLifecycle: LifeCycle
  entityType: EntityType
  featureTasksGenerationStrategy: GenerationStrategy
  name: string
  key: ProcessKey
  tenantKey: string
}
