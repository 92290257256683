import React from 'react'
import { connect } from 'react-redux'
import { AccordionActions, Grid, Tooltip, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { filter, find, isArray, isEmpty } from 'lodash'
import { HelpOutline } from '@material-ui/icons'
import GovernanceSelectors from 'store/governance/selectors'
import TasksSelectors from 'store/tasks/selectors'
import { ExtendedProduct } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { RootState } from 'store'
import { selectUserInformation } from 'store/user/selectors'
import { UserInformation } from 'store/user/types'
import ProductRejectModal from 'components/ProductRejectModal/ProductRejectModal'
import ProductApproveModal from 'components/ProductApproveModal/ProductApproveModal'
import { AssigneeType, AvailableActions, ProcessExecution, Task } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'

interface OwnProps {
  featureName?: string
  product?: ExtendedProduct
}

const mapDispatchToProps = () => ({})

const mapStateToProps = (state: RootState) => ({
  userInformation: selectUserInformation(state) as UserInformation,
  tasks: TasksSelectors.selectTasksForProduct(state),
  isTasksLoading: TasksSelectors.selectIsLoading(state),
  isGovernanceLoading: GovernanceSelectors.selectIsLoading(state),
  selectActiveprocessExecutions: GovernanceSelectors.selectActiveprocessExecutions(state),
})

export const getAssignedTask = (
  tasks: Task[],
  processes: ProcessExecution[],
  userInformation: UserInformation,
  featureName?: string
): Task[] =>
  filter(
    tasks,
    (task) =>
      !!task.status &&
      ![TaskStatus.CLOSED, TaskStatus.REJECTED].includes(task.status) &&
      task.assigneeType === AssigneeType.USER &&
      task.assigneeKey === userInformation.userKey &&
      isArray(task.availableActions) &&
      !isEmpty(task.availableActions) &&
      find(processes, (k) => k.status !== 'CLOSED' && k.key === task.processExecutionKey) !== undefined &&
      ((!!featureName && task.type === 'FEATURE' && task.context === featureName) ||
        (!featureName && task.type !== 'FEATURE' && task.context === null))
  ).slice(0, 1) // TODO: just the first task have to show

type Props = OwnProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

export const isInReview = (
  product: ExtendedProduct,
  tasks: Task[],
  processes: ProcessExecution[],
  userInformation: UserInformation,
  featureName?: string
): boolean => {
  const res = !!product && !!product.status && [ProductStatus.REVIEW].includes(product.status) && !!tasks && !!processes
  if (res === false) return false
  const tasksOfProduct = getAssignedTask(tasks, processes, userInformation, featureName)
  if (!(tasksOfProduct && tasksOfProduct.length > 0)) return false
  return true
}

const StyledHelpOutline = styled(HelpOutline)`
  margin: 12px;
  vertical-align: middle;
`

const StyledAccordionActions = styled(AccordionActions)`
  padding: 0px;
  box-shadow: 0 5px 5px -3px ${(props: any) => `${props.theme.palette.divider}`},
    0 8px 10px 1px ${(props: any) => `${props.theme.palette.divider}`},
    0 3px 14px 2px ${(props: any) => `${props.theme.palette.divider}`};
`

export const ProductReviewButton = (props: Props) => {
  const {
    product,
    userInformation,
    tasks,
    isTasksLoading,
    isGovernanceLoading,
    selectActiveprocessExecutions,
    featureName,
  } = props

  if (!product) throw new Error('product not found')
  if (
    !isInReview(product, tasks, selectActiveprocessExecutions, userInformation, featureName) ||
    isTasksLoading ||
    isGovernanceLoading
  ) {
    return null
  }
  const tasksButtons = (filteredTasks: Task[]) =>
    filteredTasks.map((task) => (
      <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="center" key={task.key}>
        <Grid item css="padding-left:16px">
          <Typography variant="subtitle1" css="font-weight: 400;" variantMapping={{ subtitle1: 'div' }}>
            {task.name}
            <Tooltip title={task.description} placement="top">
              <StyledHelpOutline />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={0} direction="row" justifyContent="flex-end" alignItems="flex-start">
            {task.availableActions &&
              [...task.availableActions]
                .sort()
                .reverse()
                .map((action, ind) => (
                  <Grid item key={ind}>
                    {action === AvailableActions.REJECT && (
                      <ProductRejectModal
                        taskKey={task.key}
                        productKey={product.productKey}
                        processExecutionKey={task.processExecutionKey}
                      />
                    )}
                    {action === AvailableActions.APPROVE && (
                      <ProductApproveModal
                        taskKey={task.key}
                        processExecutionKey={task.processExecutionKey}
                        productKey={product.productKey}
                      />
                    )}
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Grid>
    ))
  const assignedTasks: Task[] = getAssignedTask(tasks, selectActiveprocessExecutions, userInformation, featureName)
  if (featureName) {
    return <StyledAccordionActions>{tasksButtons(assignedTasks)}</StyledAccordionActions>
  }
  return <>{tasksButtons(assignedTasks)}</>
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewButton)
