import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { StatementState } from './types'

const initialState: StatementState = {
  templatesLoading: false,
  templatesError: undefined,
  templates: null,
}

const reducer = createReducer<StatementState>(initialState, {
  [actions.getTemplates.type]: (state: StatementState) => ({
    ...state,
    templatesLoading: true,
    templatesError: undefined,
  }),
  [actions.getTemplatesSuccess.type]: (
    state: StatementState,
    action: ReturnType<typeof actions.getTemplatesSuccess>
  ) => ({
    ...state,
    templates: { ...state.templates, [action.payload.templateType]: action.payload.response },
    templatesLoading: false,
    templatesError: null,
  }),
  [actions.getTemplatesFail.type]: (state: StatementState, action: ReturnType<typeof actions.getTemplatesFail>) => ({
    ...state,
    templates: { ...state.templates, [action.payload]: [] },
    templatesLoading: false,
    templatesError: { ...state.templatesError, [action.payload]: true },
  }),
} as any)

export default reducer
