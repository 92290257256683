import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FeatureFormPageProps } from '../FeaturePage.page'
import { isFeatureAllowedByConfig } from '../../Product/product.utils'
import CurrenciesForm, { CurrencyFormValues } from './CurrenciesForm'
import { toCurrencyFormValues } from './currencyTransformers'
import ProductsActions from 'store/products/actions'
import { selectConfig } from 'store/tenant-config/selectors'
import { RootState } from 'store/types'
import { Feature } from 'store/products/types'
import { ClientConfig } from 'store/tenant-config/types'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import { Loader } from 'components'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCurrency: (productKey: string) => (currencyFormValues: CurrencyFormValues) =>
    dispatch(ProductsActions.setCurrency({ ...currencyFormValues, productKey })),
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const CurrenciesFormPage = (props: Props): React.ReactElement | null => {
  const { setCurrency, tenantConfig } = props
  const { productKey = '' } = useParams()

  const [product, isLoading] = useSelectedProduct(productKey)
  const navigate = useNavigate()

  useUpdateBreadcrumbs({ productKey, context: Feature.CURRENCY })

  const clientConfig = ((tenantConfig && tenantConfig.clientConfig) as Partial<ClientConfig>) || {}

  if (!isFeatureAllowedByConfig(tenantConfig, Feature.CURRENCY)) navigate(`./../not-allowed`)

  if (isLoading) {
    return <Loader />
  }

  return product ? (
    <CurrenciesForm
      onSubmit={setCurrency(productKey)}
      productKey={productKey}
      initialValues={toCurrencyFormValues(product)}
      clientConfig={clientConfig}
    />
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrenciesFormPage)
