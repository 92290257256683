import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toDebitInterestCommonConfigFormValues, toDebitInterestRateFormValues } from '../utils/transformers'
import useDebitInterest from '../utils/useDebitInterest'
import RateForm from './components/forms/RateForm'
import CommonConfigForm from './components/forms/CommonConfigForm'
import { LendingRateType, Feature } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'

const UpdateDebitInterestPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { productKey = '', rateType, actionType } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)

  const { submitDebitInterestUpdate, isNotAllowed, isLending } = useDebitInterest({
    productKey,
    version: product?.version,
    productLine: product?.productLine,
  })

  const getDebitInterestBreadcrumb = useCallback((): string | undefined => {
    switch (rateType) {
      case LendingRateType.rateAboveLimit:
      case LendingRateType.rateWithinLimit:
      case LendingRateType.rateAboveLimitOverride:
        return t(`DEBIT_INTEREST.${rateType}`)
      case 'common':
        return t('Common config')
      default:
        return undefined
    }
  }, [rateType])

  useUpdateBreadcrumbs({ productKey, context: Feature.DEBIT_INTEREST, subcontext: getDebitInterestBreadcrumb() })

  if (isNotAllowed || !getDebitInterestBreadcrumb()) {
    navigate(`./../not-allowed`)
  }

  if (isLoading || !product) {
    return <Loader />
  }

  switch (rateType) {
    case LendingRateType.rateAboveLimit:
    case LendingRateType.rateWithinLimit:
    case LendingRateType.rateAboveLimitOverride:
      return (
        <RateForm
          product={product}
          currencyCode={product?.currency?.currencyCode}
          rateType={rateType}
          isLending={isLending}
          initialValues={actionType === 'edit' ? toDebitInterestRateFormValues(product, rateType) : {}}
          onSubmit={(values) => submitDebitInterestUpdate(values, rateType)}
          disableTierBandRadioGroup={isLending}
          t={t}
        />
      )
    case 'common':
      return (
        <CommonConfigForm
          isLending={isLending}
          productKey={productKey}
          initialValues={actionType === 'edit' ? toDebitInterestCommonConfigFormValues(product) : {}}
          onSubmit={(values) => submitDebitInterestUpdate(values, 'commonConfig')}
        />
      )
    default:
      return null
  }
}

export default UpdateDebitInterestPage
