import React from 'react'
import Providers from './providers'
import Router from './Router'
import Dialogs from './Dialogs'

export default function App() {
  return (
    <Providers>
      <Router />
      <Dialogs />
    </Providers>
  )
}
