import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import moment from 'moment-timezone'
import { getInitials, getGreetingTime, getColorFromInitials } from 'utils/ui.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Body2, H3, OAvatar } from 'dls'
import { AvatarSize } from 'dls/shared/types'

interface Props {
  firstName: string | null
  lastName: string | null
  tenantName?: string
  isParent: boolean
}

const GrayBody2 = styled(Body2)`
  color: ${(props: any) => props.theme.palette.text.secondary};
`

const DashboardHeader = ({ firstName, lastName, tenantName, isParent }: Props) => {
  const { t } = useTranslation()
  const initials = getInitials(`${firstName} ${lastName}`)

  const header = (
    <H3 style={{ fontWeight: 500 }} variantMapping={{ h3: 'h1' }}>
      {t('{{tenantName}} dashboard', { tenantName: tenantName || 'Your' })}
    </H3>
  )
  const avatarColor = getColorFromInitials(initials)

  return (
    <ContentContainer disableGutters={false} style={{ marginTop: 20, marginBottom: 48, padding: 0 }}>
      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
        {isParent ? (
          <>
            <Grid item>
              <OAvatar color={avatarColor} size={AvatarSize.LARGE}>
                {initials}
              </OAvatar>
            </Grid>
            <Grid item>
              <GrayBody2>
                {t(`GREET ${getGreetingTime(moment())}`, {
                  param1: firstName,
                })}
              </GrayBody2>
              {header}
            </Grid>
          </>
        ) : (
          <Grid item>{header}</Grid>
        )}
      </Grid>
    </ContentContainer>
  )
}

export default DashboardHeader
