import React, { useState, useCallback } from 'react'
import { Fade, Dialog, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Done } from '@material-ui/icons'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ProductApproveModalForm from './ProductApproveModalForm'
import { ApproveTask, TaskKey } from 'store/tasks/types'
import TasksActions from 'store/tasks/actions'
import { ProductKey } from 'store/products/types'
import { ModalWrapper } from 'components'
import { RootState } from 'store/types'
import TasksSelectors from 'store/tasks/selectors'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const ApproveButton = styled(Button)`
  &.MuiButton-contained {
    background-color: ${(props: any) => `${props.theme.palette.success.main}`};
    color: ${(props: any) => `${props.theme.palette.success.contrastText}`};
    margin: 16px;
    &:hover {
      background-color: ${(props: any) => `${props.theme.palette.success.dark}`};
    }
  }
`

const StyledTypography = styled(Typography)`
  margin-bottom 24px;
`

const mapDispatchToProps = (dispatch: Dispatch, { taskKey }: { taskKey: TaskKey }) => ({
  approve: (approveFormValues: ApproveTask, productKey: ProductKey, processExecutionKey: string) =>
    dispatch(TasksActions.approve({ ...approveFormValues, taskKey, productKey, processExecutionKey })),
})

const mapStateToProps = (state: RootState) => ({
  taskloading: TasksSelectors.selectIsLoading(state),
  updating: TasksSelectors.selectUpdating(state),
})

interface ProductApproveModalProps {
  taskKey: TaskKey
  productKey: ProductKey
  processExecutionKey: string
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ProductApproveModalProps

const ProductApproveModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const setOpen = useCallback(() => setIsOpen(true), [setIsOpen])
  const setClosed = useCallback(() => setIsOpen(false), [setIsOpen])
  const { t } = useTranslation()

  const { approve, productKey, taskloading, processExecutionKey, updating } = props
  const onSubmit = (data: ApproveTask) => {
    approve(data, productKey, processExecutionKey)
  }

  return (
    <>
      <ApproveButton
        onClick={setOpen}
        data-test-id="approve"
        variant="contained"
        disabled={taskloading || updating}
        color={ColorType.BUTTON}
      >
        <Done />
        {t('Approve')}
      </ApproveButton>
      <Dialog open={isOpen} scroll="paper" onClose={setClosed} TransitionComponent={Fade} fullWidth>
        <ModalWrapper
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <StyledTypography variant="h6" css="margin-bottom 24px;">
              {t('Approve product')}
            </StyledTypography>
          }
        >
          <>
            <StyledTypography variant="body2" gutterBottom css="margin-bottom 24px;">
              {t('Please provide any approver’s comments for the product owner.')}
            </StyledTypography>
            <ProductApproveModalForm
              onSubmit={onSubmit}
              setClosed={setClosed}
              updating={updating}
              initialValues={{ taskKey: props.taskKey, message: '' }}
            />
          </>
        </ModalWrapper>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductApproveModal)
