import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import { all, call } from 'redux-saga/effects'
import { createRouterMiddleware, ReduxRouterState } from '@lagunovsky/redux-react-router'
import reducers from './reducers'
import userSagas from './user/sagas'
import tenantConfigSagas from './tenant-config/sagas'
import activitySagas from './activities/sagas'
import breadcrumbs from './breadcrumbs/sagas'
import contentsSagas from './contents/sagas'
import packageSagas from './packages/sagas'
import governanceSagas from './governance/sagas'
import tasksSagas from './tasks/sagas'
import referenceDataSagas from './referenceData/sagas'
import suspenseAccountsSagas from './accounts/sagas'
import productSagas from './products/sagas'
import rulesSagas from './withholdingTax/sagas'
import statementSagas from './statement/sagas'
import routerSagas from './router/sagas'
import statementConfigurationSagas from './templates/statementConfiguration/sagas'
import productSyndicationSagas from './productSyndications/sagas'
import { browserHistory } from './router/history'

const sagaMiddleware = createSagaMiddleware()

export type RouterState = { router: ReduxRouterState }

const routerMiddleware = createRouterMiddleware(browserHistory)
const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware, routerMiddleware],
})

function* rootSaga() {
  if (process.env.NODE_ENV !== 'test')
    yield all([
      call(productSagas),
      call(userSagas),
      call(tenantConfigSagas),
      call(activitySagas),
      call(breadcrumbs),
      call(contentsSagas),
      call(packageSagas),
      call(governanceSagas),
      call(tasksSagas),
      call(referenceDataSagas),
      call(suspenseAccountsSagas),
      call(rulesSagas),
      call(statementSagas),
      call(statementConfigurationSagas),
      call(productSyndicationSagas),
      call(routerSagas),
    ])
}

// Configure sagas
sagaMiddleware.run(rootSaga)

const persistor = persistStore(store)

export { store, persistor }
