import React from 'react'
import { CircularProgress, Grid, Typography, Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Error } from '@material-ui/icons'
import LoginLayout from '../LoginLayout'
import { LegacyLoginButton as LoginButton } from 'pages/Login/styles'
import { TextFieldComplex } from 'components'
import { withFormikSimple } from 'utils/form.utils'
import { ColorType } from 'dls/shared/types'

export interface LoginRequest {
  email: string
  password: string
}

function getErrorMessage(message: string) {
  const messageList = [{ message: 'Seems like something is wrong', id: 1 }]
  if (message.indexOf('401') !== -1) {
    messageList.push({ message: 'Please re-enter your email and password', id: 2 })
  } else {
    messageList.push({ message: 'Application is temporarily unavailable', id: 2 })
  }
  return messageList
}

const ErrorGridMain = styled(Grid)`
  background-color: ${(props: any) => props.theme.palette.error.light};
  margin-bottom: 24px;
  border-radius: 3px;
  min-width: 300px;
  min-height: 50px;
`

const ErrorMessageGrid = styled(Grid)`
  width: 100%;
  margin-left: 0px;
  padding: 12px;
`

const LoginIconBox = styled(Grid)`
  padding: 12px;
  width: 36px;
  height: 100%;
  color: ${(props: any) => props.theme.palette.error.dark};
`

const MessageTypography = styled(Typography)`
  color: ${(props: any) => props.theme.palette.error.dark};
  padding-bottom: 0px;
`

const SupportLabel = styled(Typography)`
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: -10px;
    margin-bottom: 30px;
  }
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: -30px;
    margin-bottom: 20px;
  }
`

interface OwnProps {
  loading?: boolean
  error?: Error
}

type LoginFormProps = FormikProps<LoginRequest> & OwnProps

const Login = ({ error, loading, isValid, submitForm }: LoginFormProps): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <LoginLayout>
      <SupportLabel variant="body2">{t('For help logging in, please raise a Zendesk ticket')}</SupportLabel>
      {error && (
        <ErrorGridMain container direction="row" justifyContent="flex-start" alignItems="stretch">
          <Grid item>
            <LoginIconBox container direction="column" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Error />
              </Grid>
            </LoginIconBox>
          </Grid>
          <Grid item>
            <ErrorMessageGrid container justifyContent="center" alignItems="flex-start" direction="column">
              {getErrorMessage(error.message).map((p) => (
                <MessageTypography key={`${p.id}message`} id={`error_text_${p.id}`} variant="body2">
                  {t(p.message)}
                </MessageTypography>
              ))}
            </ErrorMessageGrid>
          </Grid>
        </ErrorGridMain>
      )}
      <Grid container direction="column">
        <Box pb={3}>
          <Field
            name="email"
            type="email"
            id="email"
            label={t('Email')}
            placeholder={t('Email')}
            required
            autoFocus
            autoComplete="email"
            fullWidth
            component={TextFieldComplex}
          />
        </Box>
        <Box pb={3}>
          <Field
            name="password"
            type="password"
            id="password"
            label={t('Password')}
            placeholder={t('Password')}
            required
            fullWidth
            autoComplete="current-password"
            component={TextFieldComplex}
          />
        </Box>
      </Grid>
      <Grid container justifyContent="flex-end" direction="row">
        <LoginButton onClick={submitForm} variant="contained" color={ColorType.BUTTON} disabled={!isValid || loading}>
          {t('LOG IN')}
          {loading && <CircularProgress size={24} style={{ marginLeft: '8px' }} />}
        </LoginButton>
      </Grid>
    </LoginLayout>
  )
}

export default withFormikSimple<OwnProps, LoginRequest>(Login, {
  validationSchema: Yup.object().shape({
    email: Yup.string().email('Please enter valid email').required('Please enter valid email'),
    password: Yup.string().required('Please enter your password'),
  }),
  isInitialValid: true,
})
