import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'

const LoaderSuspense = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <Grid item xs={3}>
      <CircularProgress size={75} />
    </Grid>
  </Grid>
)

export default LoaderSuspense
