import React from 'react'
import { Dialog, DialogContent, Fade, Paper, DialogActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { StyledButton } from './styled'
import { UnableToConnectToTheServer as WrongImage } from 'assets'
import { H3, Body2 } from 'components'
import { Flexbox } from 'pages/Layout/Styled'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  open: boolean
  setOpen?: (open: boolean) => void
  title?: string
  description?: string
  buttonLabel?: string
}

const FailureModal = ({ title, description, open, setOpen, buttonLabel }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={() => setOpen && setOpen(false)}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogContent>
        <Flexbox direction="column" justifyContent="center" alignItems="center" p={40} pb={30}>
          <img src={WrongImage} alt="Oops, something went wrong" />
          <H3 data-test-id="failed-dialog-title" style={{ marginTop: 16 }}>
            {t(title || 'Oops, something went wrong')}
          </H3>
          {description && (
            <Body2 variantMapping={{ body2: 'div' }} style={{ marginTop: 8 }}>
              {t(description)}
            </Body2>
          )}
        </Flexbox>
      </DialogContent>
      <DialogActions>
        <Flexbox direction="row" justifyContent="center" width="100%" pb={40}>
          <StyledButton
            disabled={false}
            onClick={() => setOpen && setOpen(false)}
            color={ColorType.BUTTON}
            variant="contained"
            data-test-id="dialog-back-button"
          >
            {buttonLabel || t('Go Back')}
          </StyledButton>
        </Flexbox>
      </DialogActions>
    </Dialog>
  )
}
export default FailureModal
