import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams, useNavigate } from 'react-router-dom'
import { FeatureFormPageProps } from '../FeaturePage.page'
import { toDocumentBasedFormValues, hasDocumentBasedFeature } from '../common/commonTransformers'
import DocumentsForm from './DocumentsForm'
import ProductsActions from 'store/products/actions'
import ContentsActions from 'store/contents/actions'
import ContentsSelectors from 'store/contents/selectors'
import { RootState } from 'store'
import { DocumentsValues, FileFormat, Origin, Feature } from 'store/products/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDocuments: (productKey: string) => (documentsValues: DocumentsValues) =>
    dispatch(ProductsActions.setDocuments({ formData: documentsValues, productKey })),
  getRemoteDocuments: () => dispatch(ContentsActions.getRemoteDocuments()),
})
const mapStateToProps = (state: RootState) => ({
  remoteDocuments: ContentsSelectors.selectRemoteDocuments(state),
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

export const DocumentsPage = (props: Props) => {
  const { setDocuments, getRemoteDocuments, remoteDocuments } = props
  const { productKey = '' } = useParams()

  useEffect(() => {
    getRemoteDocuments()
  }, [getRemoteDocuments])

  const navigate = useNavigate()
  const [product, isLoading] = useSelectedProduct(productKey)

  useUpdateBreadcrumbs({ productKey, context: Feature.DOCUMENTS })

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.DOCUMENTS)) navigate(`./../not-allowed`)

  if (isLoading) {
    return <Loader />
  }

  return product ? (
    <DocumentsForm
      productKey={productKey}
      onSubmit={setDocuments(productKey)}
      initialValues={toDocumentBasedFormValues(product.documents, 'existingDocuments')}
      remoteDocuments={remoteDocuments}
      hasFeature={hasDocumentBasedFeature(product.documents)}
      defaultValues={{
        customDocumentsVal: {
          origin: Origin.CUSTOM,
          files: [
            {
              format: FileFormat.OTHER,
              url: '',
              label: '',
            },
          ],
        },
      }}
    />
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage)
