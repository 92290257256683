export const spacingBlockInBoxItem = '0 0 12px 0'
export const spacingBlockOutBoxItems = '0 0 16px 0'
export const gutterSpacing = '24px'
export const dialogPadding = '24px'
export const extraPadding = '24px'
export const internalPadding = '40px'
export const internalMdPadding = '24px'

export default {
  spacingBlockInBoxItem,
  spacingBlockOutBoxItems,
  gutterSpacing,
  dialogPadding,
  extraPadding,
  internalPadding,
  internalMdPadding,
}
