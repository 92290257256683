import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useStatementConfiguration from '../utils/useStatementConfiguration'
import UpdateStatementConfigurationForm from './UpdateStatementConfigurationForm'
import { Loader } from 'components'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const UpdateStatementConfigurationPage = () => {
  const { t } = useTranslation()
  const { statementConfigKey } = useParams()

  const {
    fetchStatementConfigurations,
    statementConfigurations,
    statementConfigurationsLoading,
  } = useStatementConfiguration()

  const editableStatementConfiguration = statementConfigurations?.find(
    (statement) => statement.statementConfigKey === statementConfigKey
  )

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Templates'), path: 'templates/' },
      { title: t('Interest statements'), path: 'templates/statement-configurations/' },
      { title: t('{{action}} statement', { action: editableStatementConfiguration ? 'Edit' : 'Add' }), path: '' },
    ],
    trigger: true,
  })

  useEffect(() => {
    fetchStatementConfigurations()
  }, [])

  if (statementConfigurationsLoading) {
    return <Loader />
  }

  return <UpdateStatementConfigurationForm editableStatementConfiguration={editableStatementConfiguration} />
}

export default UpdateStatementConfigurationPage
