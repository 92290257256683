import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { FeaturePostResponse, ProductKey } from 'store/products/types'
import { MultiParty } from 'store/products/typings/multiParty'

async function setMultiParty(productKey: ProductKey, version: number, payload: MultiParty) {
  const newPayload = { ...payload }

  const { data } = await (await createAsyncAuthAwareAxios(null, false)).put<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/multiParty/v1`,
    newPayload
  )
  return data
}
async function fetchMultiParty(productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).get(
    `/v1/products/${productKey}/versions/${version || 1}/features/multiParty/v1`
  )
  return data
}

async function deleteMultiParty(productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).delete(
    `/v1/products/${productKey}/versions/${version || 1}/features/multiParty/v1`
  )
  return data
}

export default {
  setMultiParty,
  fetchMultiParty,
  deleteMultiParty,
}
