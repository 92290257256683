import { FastFieldProps, useField } from 'formik'
import React, { useState } from 'react'
import { MenuItem, Menu } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AddCardFromURLDialog from 'pages/features/Cards/AddCardFormURL'
import useDialogs from 'components/useDialogs'
import ImageCard from 'components/ImageCard'
import AddCardImageDialog from 'pages/features/Cards/AddCardImageDialog/AddCardImageDialog'
import ImageCardWithActions from 'components/ImageCardWithActions'

enum IMAGE_SOURCE {
  AEM = 'aem',
  URL = 'url',
}

export default function SelectImageCard(props: FastFieldProps) {
  const { isDialogOpen, onOpenDialog, onCloseDialog } = useDialogs()
  const [, , helpers] = useField(props.field.name)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [imageSource, setimageSource] = useState<IMAGE_SOURCE | undefined>(undefined)
  const { t } = useTranslation()

  const handleMenuClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const menuItemClicked = (val: IMAGE_SOURCE) => {
    setimageSource(val)
    handleMenuClose()
    onOpenDialog()
  }

  const onImageChange = (newImageSrc: string | null = null) => {
    onCloseDialog()
    if (newImageSrc && typeof newImageSrc === 'string') helpers.setValue({ url: newImageSrc })
  }

  const imageUrl = props.field.value ? props.field.value.url : null

  return (
    <>
      {!imageUrl ? (
        <>
          <ImageCard imageUrl={imageUrl} onClick={handleMenuClick} isSelected={false} />
        </>
      ) : (
        <ImageCardWithActions
          imageUrl={imageUrl}
          onEditClick={handleMenuClick}
          onDeleteClick={() => helpers.setValue(undefined)}
          isSelected={false}
        />
      )}
      <Menu
        id="cards-menu"
        anchorEl={anchorEl}
        variant="menu"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={
          !imageUrl
            ? {
                vertical: 'center',
                horizontal: 'right',
              }
            : {
                vertical: 'bottom',
                horizontal: 'left',
              }
        }
        transformOrigin={
          !imageUrl
            ? {
                vertical: 'center',
                horizontal: 'center',
              }
            : {
                vertical: 'top',
                horizontal: 'left',
              }
        }
      >
        <MenuItem onClick={() => menuItemClicked(IMAGE_SOURCE.AEM)}>
          {t('From Adobe Experience Manager (AEM)')}
        </MenuItem>
        <MenuItem onClick={() => menuItemClicked(IMAGE_SOURCE.URL)}>{t('From your own URL')}</MenuItem>
      </Menu>
      {isDialogOpen && (
        <>
          {imageSource === IMAGE_SOURCE.AEM || !imageSource ? (
            <AddCardImageDialog open closeDialog={onImageChange} currentImage={imageUrl} />
          ) : (
            <AddCardFromURLDialog open closeDialog={onImageChange} />
          )}
        </>
      )}
    </>
  )
}
