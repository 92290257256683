import { call, put, takeLatest, select } from 'redux-saga/effects'
import { selectConfig } from '../tenant-config/selectors'
import { ContentsApi } from 'api'
import ContentActions from 'store/contents/actions'
import { TenantConfig } from 'store/tenant-config/types'

function* getRemoteDocuments() {
  try {
    const tenantConfig: TenantConfig = yield select(selectConfig)
    const response: ReturnTypePromise<typeof ContentsApi.getRemoteDocuments> = yield call(
      ContentsApi.getRemoteDocuments,
      tenantConfig
    )
    yield put(ContentActions.getRemoteDocumentsSuccess(response))
  } catch (e: any) {
    yield put(ContentActions.getRemoteDocumentsFail(e))
  }
}

export default function* () {
  yield takeLatest(ContentActions.getRemoteDocuments.type, getRemoteDocuments)
}
