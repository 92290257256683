import { Grid, CircularProgress, Typography, Box } from '@material-ui/core'
import { Field } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { orderBy } from 'lodash'
import { FormikMultiSelectAutocomplete, SelectFieldComplex, ContextualHelp } from 'components'
import ProductsSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'
import { Yip } from 'utils/form.utils'
import { AutocompleteSelectFieldData } from 'components/form/FormikMultiSelectAutocomplete'
import initialState from 'store/products/reducers/initialState'
import { ProductKey, ProductDependencySubscriptionStatus } from 'store/products/types'

export const ProductDependencyEligibilityElement = ({
  name,
  productKey,
}: {
  name: string
  productKey?: ProductKey
}) => {
  const { t } = useTranslation()
  const selectAllProducts = useSelector(ProductsSelectors.selectAllProducts)
  const selectAllProductsOrigin = useSelector(ProductsSelectors.selectAllProductsOrigin)
  const selectDataLoading = useSelector(ProductsSelectors.selectDataLoading)
  const selectError = useSelector(ProductsSelectors.selectError)

  const dispatch = useDispatch()

  useEffect(() => {
    if (selectAllProductsOrigin === initialState.data && !selectDataLoading && !selectError) {
      dispatch(ProductsActions.fetch())
    }
  }, [selectDataLoading, selectAllProductsOrigin, dispatch, selectError])
  const selectTaxRulesData: AutocompleteSelectFieldData = {}
  orderBy(selectAllProducts, ['name'], ['asc']).forEach((item) => {
    if (productKey !== item.productKey) {
      selectTaxRulesData[item.productKey] = { label: item.productName, key: item.productKey }
    }
  })

  if (selectDataLoading) {
    return <CircularProgress size={75} />
  }
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      style={{ marginBottom: 12 }}
    >
      <Grid item xs={12}>
        <Box p={1} display="flex" alignItems="flex-end">
          <Typography variant="body2">
            {t(
              'Choose the product a customer must hold and the subscription status in order to be eligible for this product.'
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={1} display="flex" alignItems="flex-end">
          <Field
            label={t('Product')}
            name={`${name}.dependency.productKey`}
            aria-label="product"
            data={selectTaxRulesData}
            data-test-id="productDependencyEligibility.dependency.productKey"
            component={FormikMultiSelectAutocomplete}
            validate={Yip(Yup.string().notOneOf([productKey]).required('This is a required field'))}
            required
            displayEmpty
            fullWidth
            selectAll={false}
            placeholder={t('Select or start typing')}
            selectProps={{ displayEmpty: true }}
            inputLabelProps={{ shrink: true }}
          />
          <ContextualHelp
            title={t('The product a customer must hold in order to be eligible')}
            style={{ marginBottom: 4, marginLeft: 8 }}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box p={1} display="flex" alignItems="flex-end">
          <Field
            label={t('Subscription status')}
            name={`${name}.dependency.subscriptionStatus`}
            data={ProductDependencySubscriptionStatus}
            component={SelectFieldComplex}
            fullWidth
            selectProps={{ displayEmpty: true }}
            inputLabelProps={{ shrink: true }}
            placeholder={t('Please select')}
            required
            validate={Yip(Yup.string().required('This is a required field'))}
          />
          <ContextualHelp
            title={t('The status of the customers’ subscription to the product')}
            style={{ marginBottom: 4, marginLeft: 8 }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
