import { Grid } from '@material-ui/core'
import { connect as connectFormik, Field, getIn } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import styled from 'styled-components/macro'
import NestedAnnualFields from './NestedAnnualFields'
import NestedMonthlyFields from './NestedMonthlyFields'
import NestedWeeklyFields from './NestedWeeklyFields'
import { constructRootName, Yip } from 'utils/form.utils'
import {
  FrequencyType,
  FrequencyValues,
  TopLevelApplicationFrequencyPerType,
  TopLevelFrequency,
} from 'store/products/types'
import { ContextualHelp, SelectFieldComplex, TextFieldComplex } from 'components'
import { Case, SwitchOn } from 'components/ControlComponents'

interface ApplicationFrequencySelectorProps {
  rootName?: string
  frequencyType: FrequencyType
  calculationTopSelectedVal?: FrequencyValues
  selectedFee?: string
  disable?: boolean
  customLabel?: string
  customTooltipText?: string
}

interface TopLevelSelectorProps {
  name: string
  frequencyType: FrequencyType
  calculationTopSelectedVal: string
  selectedFee?: string
  disable?: boolean
  customLabel?: string
}

const TooltipStyle = styled.div`
  margin-top: 16px;
`

const TopLevelSelector = (props: TopLevelSelectorProps) => {
  const { frequencyType, name, calculationTopSelectedVal, selectedFee, customLabel } = props
  let lookupKey = ''
  if (selectedFee) {
    lookupKey = `${frequencyType}--TYPE_${selectedFee}`
  }
  if (calculationTopSelectedVal) {
    lookupKey = `${frequencyType}--TYPE_${calculationTopSelectedVal}`
  }

  const topLevelFrequencyForType = TopLevelApplicationFrequencyPerType[lookupKey]
  const { t } = useTranslation()
  return (
    <Grid item xs={4}>
      <Field
        required
        fullWidth
        displayEmpty
        data={topLevelFrequencyForType}
        label={t(`${customLabel || frequencyType}`)}
        component={SelectFieldComplex}
        name={name}
        validate={Yip(Yup.string().required())}
        selectProps={{ displayEmpty: true }}
        inputLabelProps={{ shrink: true }}
        disabled={props.disable}
      />
    </Grid>
  )
}

const ApplicationFrequencySelector = connectFormik<ApplicationFrequencySelectorProps>((props) => {
  const {
    rootName,
    frequencyType,
    calculationTopSelectedVal,
    selectedFee,
    formik: { values },
    disable,
    customLabel,
    customTooltipText,
  } = props
  const topLevelName = constructRootName(`${frequencyType}.ROOT`, rootName)
  const nestedLevelName = constructRootName(`${frequencyType}`, rootName)

  let calculationTopSelectedValue = ''
  if (calculationTopSelectedVal) {
    calculationTopSelectedValue = getIn(calculationTopSelectedVal, 'ROOT')
  }
  const { t } = useTranslation()

  return (
    <Grid container direction="row" spacing={2} alignItems="stretch">
      <TopLevelSelector
        name={topLevelName}
        selectedFee={selectedFee}
        frequencyType={frequencyType}
        calculationTopSelectedVal={calculationTopSelectedValue}
        disable={disable}
        customLabel={customLabel}
      />
      <SwitchOn on={getIn(values, topLevelName)}>
        <Case on={TopLevelFrequency.ANNUAL}>
          <NestedAnnualFields rootName={nestedLevelName} frequencyName={frequencyType} disable={disable} />
        </Case>
        <Case on={TopLevelFrequency.MONTH}>
          <NestedMonthlyFields rootName={nestedLevelName} frequencyName={frequencyType} disable={disable} />
        </Case>
        <Case on={TopLevelFrequency.WEEKLY}>
          <NestedWeeklyFields rootName={nestedLevelName} frequencyName={frequencyType} disable={disable} />
        </Case>
        <Case on={TopLevelFrequency.DAYS_FROM_CALCULATION}>
          <Grid item xs={4}>
            <Field
              label={t('DAYS_FROM_CALCULATION.label')}
              name={`${nestedLevelName}.DAYS_FROM_CALCULATION.secondary`}
              validate={Yip(
                Yup.number()
                  .required()
                  .min(1)
                  .max(365)
                  .integer('This field must be an integer')
                  .typeError(t('This field must be a number'))
              )}
              type="number"
              required
              fullWidth
              component={TextFieldComplex}
              disable={disable}
            />
          </Grid>
        </Case>
      </SwitchOn>
      <Grid item xs={1}>
        <TooltipStyle>
          <ContextualHelp
            title={t(
              customTooltipText ||
                `How frequently interest is paid. This must be equal
                            or greater than the calculation frequency`
            )}
          />
        </TooltipStyle>
      </Grid>
    </Grid>
  )
})

export default ApplicationFrequencySelector
