import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'
import ImageDialog from './PreviewTemplateDialog/ImageDialog'
import StatementConfigLoader from './StatementConfigLoader'
import PreviewConfigurationDialog from './PreviewConfigurationDialog'
import useDialogs from 'components/useDialogs'
import { CheckIcon, ClearIcon } from 'dls/atoms/Icons/MUIIcons'
import { ExtendedProduct, Statement, StatementType } from 'store/products/types'
import { generateMonth } from 'utils/form.utils'
import { ContextualHelp } from 'components'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'
import { Subtitle1, ReviewRows, Line, InfoPanel, IconLabel } from 'dls'
import { ColorType } from 'dls/shared/types'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'
import { PreviewLaunchIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface OwnProps {
  statement: Statement[]
  product: ExtendedProduct
}

const StyledWrapper = styled.div`
  width: 100%;
  [data-dls='molecules-ReviewRow']:last-child hr {
    display: none;
  }
  [data-dls='molecules-review-row']:last-child hr {
    display: none;
  }
`

const sharedIconStyle = css`
  margin-top: 0;
`
const StyledClearIcon = styled(ClearIcon)`
  ${sharedIconStyle}
`

const StyledCheckIcon = styled(CheckIcon)`
  ${sharedIconStyle}
`

const ViewStatement = (props: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const { isDialogOpen, onOpenDialog, onCloseDialog } = useDialogs()
  const {
    isDialogOpen: isConfigDialogOpen,
    onOpenDialog: onOpenConfigDialog,
    onCloseDialog: onCloseConfigDialog,
  } = useDialogs()
  const [selectedItem, setSelectedItem] = useState<Statement | StatementConfiguration | null>(null)
  const onCloseClick = () => {
    setSelectedItem(null)
    onCloseDialog()
  }
  const onOpenClick = (item: Statement | StatementConfiguration) => {
    setSelectedItem(item)
    onOpenDialog()
  }

  const onConfigOpenClick = (item: Statement | StatementConfiguration) => {
    setSelectedItem(item)
    onOpenConfigDialog()
  }

  const prodDetails: ReviewRowGroup[] = []

  const interestStatement = props?.statement?.filter(
    (statement) => statement.statementType === StatementType.INTEREST_STATEMENT
  )?.[0]

  const statementsWithoutInterestStatement = props.statement.filter(
    (statement) => statement.statementType !== StatementType.INTEREST_STATEMENT
  )

  if (!isEmpty(props.statement)) {
    const statementLength = statementsWithoutInterestStatement.length - 1

    statementsWithoutInterestStatement.forEach((item, index) => {
      prodDetails[index] = {
        ariaLabelledBy: `statements-list-${index}`,
        definitions: [],
      }
      if (item.statementType) {
        prodDetails[index] = {
          ...prodDetails[index],
          heading: (
            <Subtitle1 gutter id={`statements-list-${index}`}>
              {t(item.statementType)}
            </Subtitle1>
          ),
        }

        if (index !== statementLength) prodDetails[index].groupSeparator = 'strong'

        if (
          item.statementPeriod ||
          [StatementType.FEES_STATEMENT, StatementType.INTEREST_SUMMARY, StatementType.ACCOUNT_STATEMENT].includes(
            item.statementType
          )
        ) {
          prodDetails[index].definitions.push({
            term: t('Frequency & start day'),
            definitions: [
              item.statementType === StatementType.INTEREST_SUMMARY ? (
                item.startDay &&
                item.startMonth &&
                `${t(`PERIOD.YEAR`)} : ${item.startDay} ${generateMonth(item.startMonth)}`
              ) : (
                <>
                  {`${t(`PERIOD.${item.statementPeriod}`)} : `}
                  {item.accountAnniversary !== true && item.startDay && !item.startMonth && `${item.startDay}`}
                  {item.accountAnniversary !== true &&
                    item.startDay &&
                    item.startMonth &&
                    `${item.startDay} ${generateMonth(item.startMonth)}`}
                  {item.accountAnniversary === true && `${t('STATEMENTS.ANNUAL_ANNIVERSARY_OF_ACCOUNT')}`}
                </>
              ),
            ],
          })
          if (item.offsetDays !== null) {
            prodDetails[index].definitions.push({
              term: (
                <>
                  {t('Generation offset')}{' '}
                  <ContextualHelp
                    title={t(
                      `Statement generation happens the day after the frequency period ends, the generation offset increases this period.`
                    )}
                    style={{ verticalAlign: 'middle', marginLeft: '8', marginTop: '-2' }}
                  />
                </>
              ),
              definitions: [
                <>
                  {t(`{{offset}} days`, {
                    offset: item.offsetDays,
                  })}
                </>,
              ],
            })
          }

          if (item.statementDescription !== null) {
            prodDetails[index].definitions.push({
              term: t('Description'),
              definitions: [
                item.statementDescription !== null && item.statementDescription
                  ? item.statementDescription
                  : t('Not provided'),
              ],
            })
          }

          if (!!item.template && item.template.id && item.template.name) {
            prodDetails[index].definitions.push({
              term: t('Template design'),
              definitions: [
                <Box key={`${item.template.name}-template-design`} display="flex" alignItems="center" gridGap={8}>
                  <PreviewLaunchIconButton
                    onClick={() => onOpenClick(item)}
                    aria-haspopup="dialog"
                    aria-label={t('preview')}
                    aria-controls="menu-appbar"
                    size="small"
                  />

                  {item.template.name}
                </Box>,
              ],
            })
          }

          if (item.hideExternalIdentifiers !== null) {
            prodDetails[index].definitions.push({
              term: t('Include account identifiers on statement'),
              definitions: [
                item.hideExternalIdentifiers ? (
                  <StyledClearIcon color={ColorType.PRIMARY} />
                ) : (
                  <StyledCheckIcon color={ColorType.PRIMARY} />
                ),
              ],
            })
          }
        }
      }
    })
  }

  return (
    <StyledWrapper id="statements-review">
      <ReviewRows definitionsList={prodDetails} />
      {interestStatement && (
        <>
          {!!statementsWithoutInterestStatement.length && (
            <Box mt={3} mb={3}>
              <Line type="strong" />
            </Box>
          )}
          <Subtitle1 gutter>{t(StatementType.INTEREST_STATEMENT)}</Subtitle1>

          <StatementConfigLoader product={props.product} smallView>
            {(configs) => {
              const statement = configs.find(
                (config) => config.statementConfigKey === interestStatement.statementConfigKey
              )
              return (
                <>
                  <Box mb={3}>
                    <InfoPanel body={t('INTERESTSTATEMENTINFO')} />
                  </Box>
                  <ReviewRows
                    definitionsList={[
                      {
                        definitions: [
                          { term: t('Statement name'), definitions: [statement?.name] },
                          { term: t('Description'), definitions: [statement?.statementDescription] },
                          { term: t('Segment'), definitions: [t(statement?.productSegment ?? '')] },
                          {
                            term: (
                              <>
                                {t('Statement generation')}
                                <ContextualHelp
                                  title={t(
                                    `Statement generation happens the day after the frequency period ends, the generation offset increases this period.`
                                  )}
                                  style={{ verticalAlign: 'middle', marginLeft: '8', marginTop: '-2' }}
                                />
                              </>
                            ),
                            definitions: [
                              `${t(`PERIOD.${statement?.statementPeriod}`)} : ${statement?.startDay} ${generateMonth(
                                statement?.startMonth || 1
                              )}`,
                            ],
                          },
                          {
                            term: t('Offset'),
                            definitions: [
                              <>
                                {t(`{{offset}} days`, {
                                  offset: statement?.offsetDays,
                                })}
                              </>,
                            ],
                          },
                          {
                            term: t('Include account identifiers'),
                            definitions: [
                              !interestStatement.hideExternalIdentifiers ? (
                                <IconLabel Icon={<CheckIcon />} label={t('Yes')} />
                              ) : (
                                <IconLabel Icon={<ClearIcon />} label={t('No')} />
                              ),
                            ],
                          },
                          {
                            term: t('Template design'),
                            definitions: [
                              <Box
                                key={`${statement?.template?.name}-template-design`}
                                display="flex"
                                alignItems="center"
                                gridGap={8}
                              >
                                <PreviewLaunchIconButton
                                  onClick={() => statement && onConfigOpenClick(statement)}
                                  aria-haspopup="dialog"
                                  aria-label={t('preview')}
                                  aria-controls="menu-appbar"
                                  size="small"
                                />

                                {statement?.template?.name}
                              </Box>,
                            ],
                          },
                        ],
                      },
                    ]}
                  />
                </>
              )
            }}
          </StatementConfigLoader>
        </>
      )}
      {isDialogOpen && selectedItem?.template && selectedItem.statementType && (
        <ImageDialog
          title="Statement template"
          open={isDialogOpen}
          template={selectedItem.template}
          onClose={onCloseClick}
          productSegment={props.product?.productSegment}
          templateType={selectedItem.statementType as StatementType}
          id="view-statement-template-dialog"
        />
      )}
      {isConfigDialogOpen && selectedItem?.template && selectedItem.statementType && (
        <PreviewConfigurationDialog
          title="Statement template"
          open={isConfigDialogOpen}
          onClose={onCloseConfigDialog}
          templateType={StatementType.INTEREST_STATEMENT}
          productSegment={props.product.productSegment}
          statementConfig={selectedItem as StatementConfiguration}
          displayConfigInfo
        />
      )}
    </StyledWrapper>
  )
}

export default ViewStatement
