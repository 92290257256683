import { ProductSegment, StatementType } from 'store/products/types'

export interface StatementState {
  templatesLoading: boolean
  templatesError?: Error
  templates: { [key in keyof typeof StatementType]: Template[] } | null
}

export interface TemplatesResponse {
  availableTemplates: Template[]
}

export interface Template {
  templateName: string
  isDefault: boolean
  templateGroupId: string
}

export enum ContentType {
  PNG = 'image/png',
  PDF = 'application/pdf',
}
export interface GetSamplesParams {
  productSegment: ProductSegment
  templateType: StatementType
  templateGroupId: string
  contentType: ContentType
}

export interface TemplatesRequest {
  productSegment: ProductSegment
  templateType: StatementType
}
