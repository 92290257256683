import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SuspenseAccountsLanding from './SuspenseAccounts'
import { RootState } from 'store'
import SuspenseAccountsActions from 'store/accounts/actions'
import SuspenseAccountsSelectors from 'store/accounts/selectors'
import { Loader } from 'components/'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = {
  fetchSuspenseAccounts: SuspenseAccountsActions.fetchSuspenseAccounts,
}

const mapStateToProps = (state: RootState) => ({
  suspenseAccounts: SuspenseAccountsSelectors.selectAllSuspenseAccounts(state),
  loading: SuspenseAccountsSelectors.selectLoading(state),
})

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

export function SuspenseAccountsPage({ loading, suspenseAccounts, fetchSuspenseAccounts }: Props) {
  const { t } = useTranslation()

  useBreadcrumbs({ breadcrumbs: [{ title: t('Accounts'), path: '' }], trigger: true })

  useEffect(() => {
    fetchSuspenseAccounts()
  }, [fetchSuspenseAccounts])

  if (loading) {
    return <Loader />
  }
  return <SuspenseAccountsLanding suspenseAccounts={suspenseAccounts} />
}

export default connect(mapStateToProps, mapDispatchToProps)(SuspenseAccountsPage)
