import React from 'react'
import styled from 'styled-components/macro'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined'
import { Box } from '@material-ui/core'
import { Body2 } from 'dls/atoms/Typography'

export interface InfoPanelProps {
  title?: React.ReactNode
  body?: React.ReactNode
  color?: 'info' | 'error'
  titleHTMLTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'span' | 'div' | 'p'
}

const InfoGrid = styled.div<InfoPanelProps>`
  background-color: ${(props: any) => {
    if (props['data-mode'] === 'error') return props.theme.palette.error.light
    return props.theme.palette.yellow.light
  }};
  display: grid;
  grid-template-columns: auto 1fr;
  border-radius: 4px;
`

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)<InfoPanelProps>`
  color: ${(props: any) => {
    if (props['data-mode'] === 'error') return props.theme.palette.error.main
    return props.theme.palette.info.main
  }};
`

// Figma name: m_info_panel
const InfoPanel = ({
  title,
  body,
  color = 'info',
  titleHTMLTag = 'p',
  ...props
}: InfoPanelProps): React.ReactElement => (
  <InfoGrid data-dls="molecule-info-grid" data-mode={color} {...props}>
    <Box m={1.5} mr={0.75} mb={0.75}>
      {color === 'error' ? <ErrorOutlinedIcon color={color} /> : <StyledInfoOutlinedIcon data-mode={color} />}
    </Box>
    <Box m={1.25} ml={0.5} mb={0.85} mr={1.5} mt={1.5} data-mode={color}>
      {title && typeof title === 'string' ? (
        <Body2
          gutterBottom
          color={color === 'error' ? 'error' : 'initial'}
          style={{ fontWeight: 600 }}
          variantMapping={{ body2: titleHTMLTag }}
        >
          {title}
        </Body2>
      ) : (
        title
      )}
      {body && typeof title === 'string' ? (
        <Body2 gutterBottom color={color === 'error' ? 'error' : undefined}>
          {body}
        </Body2>
      ) : (
        body
      )}
    </Box>
  </InfoGrid>
)

export default InfoPanel
