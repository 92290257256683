import styled from 'styled-components/macro'
import { TrendingUp, TrendingDown, TrendingFlat } from '@material-ui/icons'

export const StyledTrendingUp = styled(TrendingUp)`
  background-color: ${(props: any) => `${props.theme.palette.success.main}`};
  color: ${(props: any) => `${props.theme.palette.success.contrastText}`};
  font-size: 24px;
  border-radius: 20px;
  padding: 1px;
  margin-left: 8px;
`

export const StyledTrendingDown = styled(TrendingDown)`
  background-color: ${(props: any) => `${props.theme.palette.error.dark}`};
  color: ${(props: any) => `${props.theme.palette.error.contrastText}`};
  font-size: 24px;
  border-radius: 20px;
  padding: 1px;
  margin-left: 8px;
`

export const StyledTrendingFlat = styled(TrendingFlat)`
  background-color: ${(props: any) => `${props.theme.palette.text.disabled}`};
  color: ${(props: any) => `${props.theme.palette.error.contrastText}`};
  font-size: 22px;
  border-radius: 20px;
  padding: 1px;
  margin-left: 8px;
  vertical-align: middle;
`
