/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableCellProps } from '@material-ui/core'
import { Order } from 'store/packages/types'

export type TableCommonCellContentType = string | number | null | undefined | React.ReactElement | object

export type TableCommonDataType = Record<string, any>

export interface TableCommonCellType {
  _searchString: string
  _orderCell: {
    [key: string]: string
  }
  _key?: string

  [key: string]: TableCommonCellContentType
}

export interface TableSetting {
  setRowExpanded: (rowExpanded: boolean) => void
  rowExpanded: boolean
}

export enum TableParam {
  OPTIMIZE = 'OPTIMIZE',
}

export interface TableCommonBodyProps {
  rows: TableCommonCellType[] | undefined
  orderAscDesc: Order
  orderByKey?: string | undefined
  page: number
  rowsPerPage: number
  emptyRows: number
  rowCount: number
  headRows: TableCommonHeaderType[]
  isDisabled?: boolean
  isSelectableRow?: boolean
  fullCellSelectable?: number[]
  onClickSelectRow: (key: string) => void
  selectableCellProps?: TableCellProps
  getExpandedContent?: (item: TableCommonDataType, settings?: TableSetting) => TableCommonCellContentType
  disableInsideSortPagination?: boolean
  isThisRowSelectable?: (item: TableCommonCellType) => boolean
  NoRowImage?: string
  noRowImageAlt?: string
  noRowTitle?: string
  searchText?: string
  noRowDescription?: string
  value?: TableCommonFormValue[]
  rowSelectableComponent?: (row: TableCommonCellType) => JSX.Element
  isSelected: (key: string) => boolean
  selectAll?: boolean
  mode?: string
}

export interface TableCommonRowType {
  row: TableCommonCellType
  selectAll?: boolean
  tableProps: TableCommonBodyProps
  mode?: string
}

export interface TableCommonHeaderType {
  id: string
  align: 'left' | 'right' | 'center'
  label: string | React.ReactElement
  getCellContent?: (item: any, settings?: TableSetting) => TableCommonCellContentType
  getCellOrderString?: (item: any) => string
  css?: string
  disableOrder?: boolean
}

export interface TableCommonHeaderProps {
  orderAscDesc: Order
  orderByKey: string | undefined
  onRequestSort: (property: string) => void
  numSelecableRows: number
  numSelected: number
  headRows: TableCommonHeaderType[]
  onClickSelectAll: (checked: boolean) => void
  isDisabled?: boolean
  isSelectableRow?: boolean
  fullCellSelectable?: number[]
  disableSelectAll?: boolean
}

export type TableCommonGetSearchString = (item: TableCommonDataType) => string
export type TableCommonGetKeyString = (item: TableCommonDataType) => string

export type TableCommonFormValue = object | string
export type TableCommonUpdateForm = (value: TableCommonFormValue[]) => void

export interface TableCommonOwnProps {
  name?: string
  required?: boolean
  data: TableCommonDataType[]
  headRows: TableCommonHeaderType[]
  getSearchString: TableCommonGetSearchString
  getKeyString: TableCommonGetKeyString
  isDisabled?: boolean
  isSelectableRow?: boolean
  title?: string
  updateForm?: TableCommonUpdateForm
  value?: TableCommonFormValue[]
  defaultRowsPerPage?: number
  noEmptyRows?: boolean
  selectableCellProps?: TableCellProps
  hideHeader?: boolean
  hidePagination?: boolean
  getExpandedContent?: (item: TableCommonDataType, settings?: TableSetting) => TableCommonCellContentType
  disableInsideSortPagination?: boolean
  fullCellSelectable?: number[]
  isThisRowSelectable?: (item: TableCommonCellType) => boolean
  onChange?: TableCommonUpdateForm
  NoRowImage?: string
  noRowImageAlt?: string
  noRowTitle?: string
  noRowDescription?: string
  cardElevation?: number
  rowSelectableComponent?: (row: TableCommonCellType) => JSX.Element
  maxSelectableItems?: number
  disableSelectAll?: boolean
  searchPlaceholderText?: string
  searchAria?: string
  mode?: string
  searchText?: string
}

export interface TableCommonFieldOwnProps extends TableCommonOwnProps {}
