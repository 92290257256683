import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { isEmpty } from 'lodash'
import { Document } from 'store/products/types'
import { CopyComponent } from 'dls/molecules/Copy'
import { ReviewRows } from 'dls'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'

interface OwnProps {
  document: Document[]
}

const StyledWrapper = styled.div`
  width: 100%;

  [data-dls='molecules-reviewrow'] hr[data-dls='atom-line'] {
    display: block;
  }
  [data-dls='molecules-review-row'] hr[data-dls='atom-line'] {
    display: block;
  }
  [data-dls='atom-line']:last-child {
    display: none;
  }
`
const UrlWrapper = styled.span`
  font-size: 12px !important;
`

const ViewDocument = (props: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const prodDetails: ReviewRowGroup[] = []

  if (!isEmpty(props.document)) {
    props.document.forEach((item, index) => {
      prodDetails[index] = {
        groupSeparator: 'strong',
        definitions: [],
      }
      if (item.name && item.version) {
        prodDetails[index].definitions.push({
          term: t('Name & version'),
          itemSeparator: !item.description ? 'light' : undefined,
          definitions: [
            <strong key={`document-name-${index}`}>
              {item.name} (v{item.version})
            </strong>,
          ],
        })
      }
      if (item.description) {
        prodDetails[index].definitions.push({
          term: t('Description'),
          itemSeparator: 'light',
          definitions: [item.description],
        })
      }
      if (item.files) {
        item.files.forEach((file, fileIndex) => {
          prodDetails[index].definitions.push(
            {
              term: t('Label'),
              definitions: [file.label],
            },
            {
              term: t('Format'),
              definitions: [file.format],
            },
            {
              term: t('URL'),
              itemSeparator: fileIndex === (item?.files?.length || 0) - 1 ? undefined : 'light',
              definitions: [
                <>
                  <UrlWrapper>{file.url}</UrlWrapper>
                  <CopyComponent
                    onClickTitle="Copied"
                    payload={file.url}
                    tabIndex="0"
                    title="Copy"
                    style={{ top: -3 }}
                  />
                </>,
              ],
            }
          )
        })
      }
    })
  }

  return (
    <StyledWrapper>
      <ReviewRows definitionsList={prodDetails} />
    </StyledWrapper>
  )
}

export default ViewDocument
