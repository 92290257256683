import React from 'react'
import { TFunction } from 'i18next'
import { Link as RouterLink } from 'react-router-dom'
import { addUrls, getEditUrl } from './actionUrls'
import DefaultSubValue from 'pages/features/CardTransactionLimits/components/shared/DefaultSubValue'
import CustomerCanManageSubValue from 'pages/features/CardTransactionLimits/components/shared/CustomerCanManageSubValue'
import { CardTransactionLimit } from 'store/products/typings/cardLimits'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'
import { DesignIconButton } from 'dls'

type BuildDefinitionsConfig = {
  perCashTransaction?: CardTransactionLimit
  cumulativeCashDaily?: CardTransactionLimit
  currencySymbol?: string
  productKey: string
  t: TFunction
  hasSeparators: boolean
  showActions: boolean
  ariaLabelledBy: string
}

const buildDefinitions = ({
  perCashTransaction,
  cumulativeCashDaily,
  currencySymbol = '',
  t,
  hasSeparators,
  showActions,
  ariaLabelledBy,
}: BuildDefinitionsConfig): ReviewRowGroup[] => [
  {
    ariaLabelledBy,
    definitions: [
      {
        itemSeparator: hasSeparators ? 'light' : undefined,
        term: t('Per cash transaction'),
        definitions: [
          <DefaultSubValue
            key={`cumulativeDay-${perCashTransaction?.subscriptionOverrideMaximum}`}
            labelKey="Default"
            cardLimit={perCashTransaction}
            currencySymbol={currencySymbol}
          />,
          <CustomerCanManageSubValue
            key={`cumulativeDay-${perCashTransaction?.enableSubscriptionOverride}`}
            labelKey="Customer can manage"
            noValueContent={t('No')}
            cardLimit={perCashTransaction}
            currencySymbol={currencySymbol}
          />,
          <>
            {showActions && (
              <DesignIconButton
                key={`editLink-${perCashTransaction?.limitRuleId}`}
                aria-controls="main"
                component={RouterLink}
                to={
                  perCashTransaction?.limitRuleId
                    ? getEditUrl(perCashTransaction?.limitRuleId)
                    : addUrls.perCashTransaction
                }
              />
            )}
          </>,
        ],
      },
      {
        term: t('Cumulative cash daily'),
        definitions: [
          <DefaultSubValue
            key={`cumulativeDay-${cumulativeCashDaily?.subscriptionOverrideMaximum}`}
            labelKey="Default"
            cardLimit={cumulativeCashDaily}
            currencySymbol={currencySymbol}
          />,
          <CustomerCanManageSubValue
            key={`cumulativeDay-${cumulativeCashDaily?.enableSubscriptionOverride}`}
            labelKey="Customer can manage"
            noValueContent={t('No')}
            cardLimit={cumulativeCashDaily}
            currencySymbol={currencySymbol}
          />,
          <>
            {showActions && (
              <DesignIconButton
                aria-controls="main"
                key={`editLink-${cumulativeCashDaily?.limitRuleId}`}
                component={RouterLink}
                to={
                  cumulativeCashDaily?.limitRuleId
                    ? getEditUrl(cumulativeCashDaily?.limitRuleId)
                    : addUrls.cumulativeCashDaily
                }
              />
            )}
          </>,
        ],
      },
    ],
  },
]

export default buildDefinitions
