import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Tooltip } from '@material-ui/core'
import { EntityBadgeIndexRate } from 'dls/atoms/EntityBadges/EntityBadge'
import EntitySectionCard from 'pages/Layout/EntityReviewPageLayout/EntitySectionCard'
import { toBankTz } from 'utils/date.utils'
import { Flexbox } from 'pages/Layout/Styled'
import { BankInterestRate, InterestRate } from 'store/referenceData/types'
import { RateMultiplyByHundred } from 'utils/form.utils'
import { Body1, DesignIconButton, ReviewRows } from 'dls'
import { Separator } from 'dls/molecules/ReviewRows/types'

interface Props {
  selectedInterestRate: BankInterestRate
  mostRecentPastRate: InterestRate
  nearestFutureRate: InterestRate
}

const CurrentInterestRateSectionCard = ({ selectedInterestRate, mostRecentPastRate, nearestFutureRate }: Props) => {
  const { t } = useTranslation()
  const effectiveRate = mostRecentPastRate || nearestFutureRate

  return (
    <EntitySectionCard
      headline="Index rate details"
      title={<Body1>{t(`Details and ${mostRecentPastRate ? 'current' : 'next effective'} rate`)}</Body1>}
      headerIcon={<EntityBadgeIndexRate style={{ marginTop: -4 }} />}
      accordionDisabled
    >
      <Flexbox direction="column" p={24} pt={8}>
        <ReviewRows
          definitionsList={[
            {
              groupSeparator: 'light' as Separator,
              definitions: [
                {
                  term: t('Index rate name'),
                  definitions: [t(selectedInterestRate.name)],
                },
                {
                  term: t('Abbreviation'),
                  definitions: [t(selectedInterestRate.abbreviation)],
                },
              ],
            },
            effectiveRate && {
              definitions: [
                {
                  term: t('Rate'),
                  definitions: [`${RateMultiplyByHundred(effectiveRate.indexRate || 0)}%`],
                },
                {
                  term: t('Effective date'),
                  definitions: [
                    <Box key="effectiveDate">
                      {toBankTz(effectiveRate?.effectiveDate?.toString()).date}
                      {!mostRecentPastRate && (
                        <Flexbox direction="row" width="100%" justifyContent="flex-end">
                          <Tooltip title={`${t('Edit this rate')}`} aria-label="edit" placement="right">
                            <DesignIconButton
                              color="inherit"
                              aria-label="Edit this rate"
                              role="button"
                              href={`reference-data/index-rates/${selectedInterestRate.bankIndexKey}/edit`}
                            />
                          </Tooltip>
                        </Flexbox>
                      )}
                    </Box>,
                  ],
                },
              ],
            },
          ].filter((i) => i)}
        />
      </Flexbox>
    </EntitySectionCard>
  )
}

export default CurrentInterestRateSectionCard
