import React from 'react'
import { Avatar } from '@material-ui/core'
import styled from 'styled-components/macro'
import { ImageSize, AvatarColor, ColorType, BadgeProps, AvatarSize } from 'dls/shared/types'
import { EntityColorSelector } from 'utils/customColors'
import { TenXIcon } from 'components/customIcons'
import {
  IndexRatesIcon,
  HistoryIcon,
  CurrencyIcon,
  ExternalIdsIcon,
  RepaymentScheduleIcon,
  ElgibilityIcon,
  ProductCodeIcon,
  ProductsIcon,
  PaymentLimitsIcon,
  FeesIcon,
  CreditInterestIcon,
  TermAmountIcon,
  PackagesIcon,
  HelpIcon,
  StatementsIcon,
  TermsIcon,
  ColleagueIcon,
  DebitInterestIcon,
  GovernanceIcon,
  AnySourceIcon,
  AccountsIcon,
  FutureRateIcon,
  TransactionRulesIcon,
  TaxRules,
  CardsIcon,
  CardLimitIcon,
  ProductCardLimitIcon,
  CreditLimitIcon,
  DocumentsIcon,
  DevicesIcon,
  SubscriptionRulesIcon,
  InputSettings,
  ProductDetailsIcon,
  MultiPartyIcon,
  SyndicationIcon,
  TemplatesIcon,
  ReferenceDataIcon,
  RewardsIcon,
} from 'dls/atoms/Icons/SVGIcons'

export const StyledAvatar = styled(Avatar)<{ $size?: AvatarSize }>`
  display: inline-grid;
  ${EntityColorSelector as any}

  ${({ $size }: any) =>
    $size === ImageSize.LARGE &&
    `
      width:  48px;
      height:  48px;
      & svg {
        width:  36px;
        height:  36px;
      }
    `}
  ${({ $size }: any) =>
    $size === ImageSize.MEDIUM &&
    `
      width:  40px;
      height:  40px;
      & svg {
        width:  28px;
        height:  28px;
      }
    `}
    ${({ $size }: any) =>
    $size === ImageSize.SMALL &&
    `
        width:  32px;
        height:  32px;
        & svg {
        width:  24px;
        height:  24px;
        }
      `};
`

export const EntityBadge10x = ({ size = AvatarSize.SMALL, disabled = false, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-bank-settings"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PRODUCTDETAILS}
    {...ariaProps}
    {...props}
  >
    <TenXIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeBankSettings = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-bank-settings"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.BANKSETTINGS}
    {...ariaProps}
    {...props}
  >
    <InputSettings disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeCreditLimit = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-credit-limits"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.CREDITLINES}
    {...ariaProps}
    {...props}
  >
    <CreditLimitIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeProductCardLimit = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-product-cards-limit"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PRODUCTCARDLIMIT}
    {...ariaProps}
    {...props}
  >
    <ProductCardLimitIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgePackage = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-package"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PACKAGE}
    {...ariaProps}
    {...props}
  >
    <PackagesIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeProduct = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-product"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PRODUCT}
    {...ariaProps}
    {...props}
  >
    <ProductsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeProductDetails = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-product-details"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PRODUCTDETAILS}
    {...ariaProps}
    {...props}
  >
    <ProductDetailsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeSuspenseAccount = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-badge-suspense-account"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.SUSPENSEACCOUNT}
    {...ariaProps}
    {...props}
  >
    <AccountsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeIndexRate = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-index-rate"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.INDEXRATES}
    {...ariaProps}
    {...props}
  >
    <IndexRatesIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeHistory = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-history"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.HISTORY}
    {...ariaProps}
    {...props}
  >
    <HistoryIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeCurrency = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-currency"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.CURRENCY}
    {...ariaProps}
    {...props}
  >
    <CurrencyIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeExternalIds = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-external-ids"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.EXTERNALIDS}
    {...ariaProps}
    {...props}
  >
    <ExternalIdsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeRepaymentSchedule = ({
  size = AvatarSize.SMALL,
  disabled,
  ariaProps,
  ...props
}: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-repayment-schedule"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.REPAYMENTSCHEDULE}
    {...ariaProps}
    {...props}
  >
    <RepaymentScheduleIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeElgibility = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-eligibility"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.ELIGIBILITY}
    {...ariaProps}
    {...props}
  >
    <ElgibilityIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeProductCode = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-product-code"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PRODUCTCODE}
    {...ariaProps}
    {...props}
  >
    <ProductCodeIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeProducts = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-products"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PRODUCT}
    {...ariaProps}
    {...props}
  >
    <ProductsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgePaymentLimits = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-payment-limits"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PAYMENTLIMITS}
    {...ariaProps}
    {...props}
  >
    <PaymentLimitsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeFees = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-fees"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.FEES}
    {...ariaProps}
    {...props}
  >
    <FeesIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeRewards = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-rewards"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.REWARDS}
    {...ariaProps}
    {...props}
  >
    <RewardsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeCreditInterest = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-credit-interest"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.CREDITINTEREST}
    {...ariaProps}
    {...props}
  >
    <CreditInterestIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeTermAmount = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-term-amount"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.TERMAMOUNT}
    {...ariaProps}
    {...props}
  >
    <TermAmountIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgePackages = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-index-rate"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PACKAGE}
    {...ariaProps}
    {...props}
  >
    <PackagesIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeHelp = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-help"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.HELP}
    {...ariaProps}
    {...props}
  >
    <HelpIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeStatements = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-statements"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.STATEMENTS}
    {...ariaProps}
    {...props}
  >
    <StatementsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeTerms = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-badge-terms"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.TERMS}
    {...ariaProps}
    {...props}
  >
    <TermsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeColleague = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-index-rate"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.COLLEAGUE}
    {...ariaProps}
    {...props}
  >
    <ColleagueIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeDebitInterest = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-debit-interest"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.DEBITINTEREST}
    {...ariaProps}
    {...props}
  >
    <DebitInterestIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeGovernance = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-governance"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.GOVERNANCE}
    {...ariaProps}
    {...props}
  >
    <GovernanceIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeAnySource = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-any-source"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.ANYSOURCE}
    {...ariaProps}
    {...props}
  >
    <AnySourceIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeAccounts = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-index-rate"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.ACCOUNT}
    {...ariaProps}
    {...props}
  >
    <AccountsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeFutureRate = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-index-rate"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.REPAYMENTSCHEDULE}
    {...ariaProps}
    {...props}
  >
    <FutureRateIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeTaxRules = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-tax-rules"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.TAXRULES}
    {...ariaProps}
    {...props}
  >
    <TaxRules disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeTransactionRules = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-transaction-rules"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.TRANSACTIONRULES}
    {...ariaProps}
    {...props}
  >
    <TransactionRulesIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeCards = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-cards"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.CARDS}
    {...ariaProps}
    {...props}
  >
    <CardsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeCardLimit = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-card-limit"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.CARDLIMIT}
    {...ariaProps}
    {...props}
  >
    <CardLimitIcon disabled={disabled} />
  </StyledAvatar>
)
export const EntityBadgeDocuments = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-index-rate"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.DOCUMENTS}
    {...ariaProps}
    {...props}
  >
    <DocumentsIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeDevices = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-badge-devices"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.DEVICES}
    {...ariaProps}
    {...props}
  >
    <DevicesIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeSubscriptionRules = ({
  size = AvatarSize.SMALL,
  disabled,
  ariaProps,
  ...props
}: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-subscription-rules"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.GREEN}
    {...ariaProps}
    {...props}
  >
    <SubscriptionRulesIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeMultiParty = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-multi-party"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.GREEN}
    {...ariaProps}
    {...props}
  >
    <MultiPartyIcon disabled={disabled} />
  </StyledAvatar>
)

export const EntityTemplates = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-templates-entity"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.TEAL}
    {...ariaProps}
    {...props}
  >
    <TemplatesIcon disabled={disabled} />
  </StyledAvatar>
)
export const EntityReferenceData = ({ size = AvatarSize.SMALL, disabled, ariaProps, ...props }: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-reference-data-entity"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.GREEN}
    {...ariaProps}
    {...props}
  >
    <ReferenceDataIcon color={ColorType.GREEN} disabled={disabled} />
  </StyledAvatar>
)

export const EntityBadgeProductSyndication = ({
  size = AvatarSize.SMALL,
  disabled,
  ariaProps,
  ...props
}: BadgeProps) => (
  <StyledAvatar
    data-dls="atom-entity-product-syndication"
    $size={size}
    aria-hidden
    color={disabled ? AvatarColor.DISABLED : AvatarColor.PRODUCTSYNDICATION}
    {...ariaProps}
    {...props}
  >
    <SyndicationIcon disabled={disabled} />
  </StyledAvatar>
)
