/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import useWindowScroll from '@react-hook/window-scroll'
import { useTranslation } from 'react-i18next'
import { BalanceType, CreditDebitIndicator, SuspenseAccount } from 'store/accounts/types'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { getFormattedCurrency } from 'utils/ui.utils'

interface SubProductFormProps {
  suspenseAccount?: SuspenseAccount
}

const ProductHeaderDiv = styled.div<{ moveToTop?: boolean }>`
  min-height: 120px;
  margin-top: -16px;
  z-index: ${(props: any) => (props.moveToTop ? '1101' : '1099')};
  padding-top: 24px;
  position: relative;
`

const AccountName = styled.div`
  overflow-wrap: anywhere;
  padding-top: 14px;
`

const HeaderGrid = styled(Grid)`
  padding: 40px 0;

  ${(props: any) => `${props.theme.breakpoints.down('sm')}`} {
    padding: 8px 0;
  }
`

const StyledTypography = styled(Typography)`
  display: inline-flex;
  font-weight: 300;
  font-size: 48px;
`

const StyledBody = styled(Typography)`
  display: inline-flex;
`
const StyledBodyGrid = styled(Grid)`
  margin-bottom: 12px;
  margin-right: 4px;
`

const StyledDecimal = styled.span`
  font-size: 30px;
  line-height: 1.4;
`

const TransactionsHeader = ({ suspenseAccount }: SubProductFormProps) => {
  const { t } = useTranslation()
  const [moveToTop, setMoveToTop] = useState(true)
  const scrollY = useWindowScroll(30)
  // useWindowScroll has been added in the typings.
  useEffect(() => {
    if (scrollY > 20) {
      setMoveToTop(false)
    } else {
      setMoveToTop(true)
    }
  }, [scrollY, setMoveToTop])

  if (!suspenseAccount) {
    return <ProductHeaderDiv />
  }

  const { balances, name } = suspenseAccount
  const totalBalance = balances.reduce((a, b) => a + b.amount, 0)
  const runningBalance = balances.filter((item) => item.balanceType === BalanceType.RUNNING)
  const totalBalanceSplit = totalBalance.toString().split('.')
  const { currency } = balances[0]

  return (
    <ProductHeaderDiv moveToTop={moveToTop}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        alignItems="flex-start"
        id="productHeader"
      >
        <HeaderGrid item xs={12} sm={5} md={7} lg={8}>
          <AccountName>
            <Typography variant="h1">{name || t('Loading')}</Typography>
          </AccountName>
        </HeaderGrid>
        <HeaderGrid
          item
          xs={12}
          sm={7}
          md={5}
          lg={4}
          css={`
            margin-top: 22px;
          `}
        >
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" wrap="nowrap">
            <Grid item style={{ display: 'flex' }}>
              {runningBalance[0].creditDebitIndicator === CreditDebitIndicator.DEBIT && (
                <StyledTypography
                  css="margin-right:8px"
                  variant="h4"
                  variantMapping={{ h4: 'span' }}
                >{`- `}</StyledTypography>
              )}
              <CurrencySymbol
                size
                currencyCode={currency}
                typographyProps={{ style: { display: 'inline-flex', fontWeight: 300, fontSize: 48 } }}
              />
              <StyledTypography variant="h4" variantMapping={{ h4: 'span' }}>
                {getFormattedCurrency(parseInt(totalBalanceSplit[0], 10), currency, false)}
              </StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography variant="h6" variantMapping={{ h6: 'span' }} style={{ marginTop: -4 }}>
                {totalBalanceSplit[1] && totalBalanceSplit[1].length > 1 ? (
                  <StyledDecimal>{`.${totalBalanceSplit[1]}`}</StyledDecimal>
                ) : totalBalanceSplit[1] && totalBalanceSplit[1].length === 1 ? (
                  <StyledDecimal>{`.${totalBalanceSplit[1]}0`}</StyledDecimal>
                ) : (
                  <StyledDecimal>.00</StyledDecimal>
                )}
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" wrap="nowrap">
            {totalBalance > 0 ? (
              <StyledBodyGrid item>
                {runningBalance[0] ? (
                  <StyledBody variant="body1">
                    {runningBalance[0].creditDebitIndicator === CreditDebitIndicator.DEBIT
                      ? t('Debit balance')
                      : t('Credit balance')}
                  </StyledBody>
                ) : (
                  <StyledBody variant="body1">{t('N/A')}</StyledBody>
                )}
              </StyledBodyGrid>
            ) : (
              <StyledBodyGrid item>
                <StyledBody variant="body1">{t('Balance')}</StyledBody>
              </StyledBodyGrid>
            )}
          </Grid>
        </HeaderGrid>
      </Grid>
    </ProductHeaderDiv>
  )
}

export default TransactionsHeader
