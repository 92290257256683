import { createAction } from '@reduxjs/toolkit'
import { BankInterestRate, BankIndexKey, BankInterestRateFormValues, EditOrAddIndexRate } from './types'
import { ShouldRedirectOnFail } from 'store/utils/types'

const actions = {
  fetchBankInterestRate: createAction<ShouldRedirectOnFail | undefined>('BANK_INTEREST_RATE/FETCH'),
  fetchBankInterestRateSuccess: createAction<BankInterestRate[]>('BANK_INTEREST_RATE/FETCH/SUCCESS'),
  fetchBankInterestRateFail: createAction<Error>('BANK_INTEREST_RATE/FETCH/FAIL'),

  getBankIndexRateDetails: createAction<BankIndexKey>('INDEX_RATE/GET/DETAILS'),
  getBankIndexRateDetailsSuccess: createAction<BankInterestRate>('INDEX_RATE/GET/DETAILS/SUCCESS'),
  getBankIndexRateDetailsFail: createAction<Error>('INDEX_RATE/GET/DETAILS/FAIL'),

  editRate: createAction<EditOrAddIndexRate & { bankIndexKey: BankIndexKey }>('RATE/EDIT'),
  editRateSuccess: createAction<BankInterestRate>('RATE/EDIT/SUCCESS'),
  editRateFail: createAction<Error>('RATE/EDIT/FAIL'),
  createBankInterestRate: createAction<Partial<BankInterestRateFormValues>>('BANK_INTEREST_RATE/CREATE'),
  createBankInterestRateSuccess: createAction<BankInterestRate>('BANK_INTEREST_RATE/CREATE/SUCCESS'),
  createBankInterestRateFail: createAction<Error>('BANK_INTEREST_RATE/CREATE/FAIL'),

  addRate: createAction<EditOrAddIndexRate & { bankIndexKey: BankIndexKey }>('RATE/ADD'),
  addRateSuccess: createAction<BankInterestRate>('RATE/ADD/SUCCESS'),
  addRateFail: createAction<Error>('RATE/ADD/FAIL'),
}

export default actions
