import { isEmpty } from 'lodash'
import { ExtendedProduct, RequiredExternalId, RequiredExternalIdFormValues } from 'store/products/types'

export const toRequiredExternalIdFormValues = (product: ExtendedProduct): RequiredExternalIdFormValues | null => {
  const externalIds = isEmpty(product.requiredExternalId)
    ? [RequiredExternalId.AIIN]
    : [...(product.requiredExternalId || [])]

  return {
    requiredExternalId: externalIds,
  }
}
