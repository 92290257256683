import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectBankInterestRateState = (state: RootState) => state.referenceData

const selectAllBankInterestRate = createSelector(selectBankInterestRateState, (state) => state.bankInterestRate)

const selectSelectedIndexRate = createSelector(selectBankInterestRateState, (state) => state.selectedIndexRate)

const selectError = createSelector(selectBankInterestRateState, (bankInterestRateState) => bankInterestRateState?.error)
const selectLoading = createSelector(selectBankInterestRateState, (state) => state.loading)
const selectIsUpdating = createSelector(selectBankInterestRateState, (state) => state.isUpdating)

export default {
  selectAllBankInterestRate,
  selectLoading,
  selectError,
  selectSelectedIndexRate,
  selectIsUpdating,
}
