import { ProductVersionStatus } from 'store/products/typings/productVersionStatus'

export enum ProductSyndicationCategory {
  BUSINESS_LOANS = 'BUSINESS_LOANS',
  CRED_AND_CHRG_CARDS = 'CRED_AND_CHRG_CARDS',
  LEASES = 'LEASES',
  MARGIN_LOANS = 'MARGIN_LOANS',
  OVERDRAFTS = 'OVERDRAFTS',
  PERS_LOANS = 'PERS_LOANS',
  REGULATED_TRUST_ACCOUNTS = 'REGULATED_TRUST_ACCOUNTS',
  RESIDENTIAL_MORTGAGES = 'RESIDENTIAL_MORTGAGES',
  TERM_DEPOSITS = 'TERM_DEPOSITS',
  TRADE_FINANCE = 'TRADE_FINANCE',
  TRANS_OR_SAVINGS_ACCOUNTS = 'TRANS_OR_SAVINGS_ACCOUNTS',
  TRAVEL_CARDS = 'TRAVEL_CARDS',
}

export interface ProductSyndication {
  productKey: string
  version: number
  name: string
  description: string
  productCategory: ProductSyndicationCategory
  effectiveFrom?: string
  effectiveTo?: string
  status: ProductVersionStatus
}

export interface ProductSyndicationsResponse {
  products: ProductSyndication[]
}

// TODO:  Rename to product syndication detail response
export interface ProductSyndicateResponse extends ProductSyndication {
  brand: string
  brandName: string
  applicationUri: string
  isTailored: boolean
  additionalInformation: AdditionalInformation
  cardArt: ArtType[]
  features: Features[]
  constraints: Constraints[]
  eligibilities: Eligibilities[]
  fees: Fees[]
  depositRates: DepositRates[]
  lendingRates: LendingRates[]
}

export interface ProductSyndicationFormValues {
  productName: string
  productKey: string
  productDescription: string
  productCategory?: ProductSyndicationCategory
  applicationUri?: string
  brand?: string
  isTailored?: boolean
  brandName?: string
}

export interface ProductSyndicationError extends Error {
  nonSyndicated: boolean
  error: boolean
}

export enum PaymentDue {
  IN_ADVANCE,
  IN_ARREARS,
}

export enum LoanPurpose {
  INTEREST_ONLY,
  PRINCIPAL_AND_INTEREST,
}

export enum RateApplicationMethod {
  PER_TIER,
  WHOLE_BALANCE,
}
export enum UnitOfMeasure {
  DAY,
  DOLLAR,
  MONTH,
  PERCENT,
}

export enum DiscountType {
  BALANCE,
  DEPOSITS,
  ELIGIBILITY_ONLY,
  FEE_CAP,
  PAYMENTS,
}
export enum ConstraintType {
  MAX_BALANCE,
  MAX_LIMIT,
  MIN_BALANCE,
  MIN_LIMIT,
  OPENING_BALANCE,
}

export enum EligibilityType {
  BUSINESS,
  EMPLOYMENT_STATUS,
  MAX_AGE,
  MIN_AGE,
  MIN_INCOME,
  MIN_TURNOVER,
  NATURAL_PERSON,
  OTHER,
  PENSION_RECIPIENT,
  RESIDENCY_STATUS,
  STAFF,
  STUDENT,
}

export enum FeatureType {
  ADDITIONAL_CARDS,
  BALANCE_TRANSFERS,
  BILL_PAYMENT,
  BONUS_REWARDS,
  CARD_ACCESS,
  CASHBACK_OFFER,
  COMPLEMENTARY_PRODUCT_DISCOUNTS,
  DIGITAL_BANKING,
  DIGITAL_WALLET,
  DONATE_INTEREST,
  EXTRA_REPAYMENTS,
  FRAUD_PROTECTION,
  FREE_TXNS,
  FREE_TXNS_ALLOWANCE,
  GUARANTOR,
  INSURANCE,
  INSTALMENT_PLAN,
  INTEREST_FREE,
  INTEREST_FREE_TRANSFERS,
  LOYALTY_PROGRAM,
  NOTIFICATIONS,
  NPP_ENABLED,
  NPP_PAYID,
  OFFSET,
  OTHER,
  OVERDRAFT,
  REDRAW,
  RELATIONSHIP_MANAGEMENT,
  UNLIMITED_TXNS,
}

export type AdditionalInformation = {
  overviewUri: string
  termsUri: string
  eligibilityUri: string
  feesAndPricingUri: string
  bundleUri: string
  additionalOverviewUris: AdditionalDescription[]
  additionalTermUris: AdditionalDescription[]
  additionalEligibilityUris: AdditionalDescription[]
  additionalFeesAndPricingUris: AdditionalDescription[]
  additionalBundleUris: AdditionalDescription[]
}

interface ProductTypeCommonProp {
  additionalValue: string
  additionalInfo: string
  additionalInfoUri: string
}

export type Features = ProductTypeCommonProp & {
  featureType: FeatureType
}
export type Constraints = ProductTypeCommonProp & {
  constraintType: ConstraintType
}

export type Eligibilities = ProductTypeCommonProp & {
  eligibilityType: EligibilityType
}

export type LendingRates = ProductTypeCommonProp & {
  lendingRateType: string
  rate: number
  comparisonRate: number
  calculationFrequency: string
  applicationFrequency: string
  interestPaymentDue: PaymentDue
  loanPurpose: LoanPurpose
  repaymentType: string
  tiers: Tiers[]
}

export type DepositRates = ProductTypeCommonProp & {
  depositRateType: string
  rate: number
  calculationFrequency: string
  applicationFrequency: string
  tiers: Tiers[]
}

export type Tiers = ProductTypeCommonProp & {
  unitOfMeasure: UnitOfMeasure
  name: string
  minimumValue: number
  maximumValue: number
  rateApplicationMethod: RateApplicationMethod
  applicabilityConditions: ProductTypeCommonProp
}
export type Fees = ProductTypeCommonProp & {
  feeType: string
  amount: number
  balanceRate: number
  transactionRate: number
  accruedRate: number
  accrualFrequency: string
  currency: string
  feeDiscounts: FeeDiscounts[]
}

export type FeeDiscounts = ProductTypeCommonProp & {
  description: string
  discountType: DiscountType[]
  amount: number
  balanceRate: number
  transactionRate: number
  accruedRate: number
  feeRate: number
  feesDiscountEligibilities: FeesDiscountEligibilities[]
}

export type FeesDiscountEligibilities = ProductTypeCommonProp & {
  discountEligibilityType: string
}

export type AdditionalDescription = {
  description: string
  additionalInfoUri: string
}

export type ArtType = {
  title: string
  imageUri: string
}
