import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteRetireDialog from '../DeleteRetireDialog/DeleteRetireDialog'
import PackageActions from './PackageActions'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { Body1, StatusBadge } from 'components'
import { PackageCreationOrUpdateRequestPayload, PackagesWithProducts } from 'store/packages/types'
import { stopPropagation } from 'utils/form.utils'
import { DeleteOrRetire, RetireOrDeleteProductOrPackage } from 'store/products/types'
import { EntityBadgePackage } from 'dls/atoms/EntityBadges/EntityBadge'

interface Props {
  currentPackage: PackagesWithProducts
  deletePackage(values: RetireOrDeleteProductOrPackage & PackageCreationOrUpdateRequestPayload): void
}

const PackageCard = ({ currentPackage, deletePackage }: Props): React.ReactElement | null => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const [actionType, setActionType] = useState<DeleteOrRetire>(DeleteOrRetire.DELETEPACKAGE)

  const handleViewPackage = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(`/packages/${currentPackage.productPackageId}`)
  }

  function openMenu(e: React.MouseEvent<HTMLButtonElement>) {
    stopPropagation(e)
    setAnchorEl(e.currentTarget)
  }

  const openConfirm = (newActionType: DeleteOrRetire) => (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setActionType(newActionType)
    setOpen(true)
  }

  function closeConfirmAndMenu(e: React.MouseEvent) {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(false)
  }

  const handleDeleteOrRetire = (values: RetireOrDeleteProductOrPackage) => {
    setAnchorEl(null)
    setOpen(false)
    const productIds = currentPackage.products ? currentPackage.products.map((prod) => prod.productKey) : []
    const updatePackageRequest = {
      products: productIds,
      distribution: currentPackage.distribution,
      productPackageName: currentPackage.productPackageName,
    }
    switch (actionType) {
      case DeleteOrRetire.DELETEPACKAGE:
        return deletePackage({ ...values, ...updatePackageRequest })
      default:
        throw new Error('Unknown delete action')
    }
  }

  return currentPackage ? (
    <>
      <CatalogCard
        region1={[
          <EntityBadgePackage key={1} />,
          <Body1 variantMapping={{ body1: 'div' }} key="2">
            {currentPackage.productPackageName}
          </Body1>,
        ]}
        region3={[
          <div key={0}>
            <StatusBadge status={currentPackage.packageStatus} date={currentPackage.publicationDate} />{' '}
          </div>,
        ]}
        region4={[
          <PackageActions
            key="4"
            currentPackage={currentPackage}
            onClick={openMenu}
            productPackageName={currentPackage.productPackageName}
            anchorEl={anchorEl as any}
            onClose={closeConfirmAndMenu}
            productPackageId={currentPackage.productPackageId}
            onDelete={openConfirm(DeleteOrRetire.DELETEPACKAGE)}
            onView={handleViewPackage}
          />,
        ]}
        onClickOnCard={handleViewPackage}
        id={currentPackage.productPackageName}
      />
      <DeleteRetireDialog
        open={open}
        productOrPackageKey={currentPackage.productPackageId}
        closeDialog={closeConfirmAndMenu}
        handleDeleteOrRetire={handleDeleteOrRetire}
        productOrPackageName={currentPackage.productPackageName}
        actionType={actionType}
      />
    </>
  ) : null
}

export default PackageCard
