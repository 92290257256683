import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { forceVisible } from 'react-lazyload'
import SuspenseAccountPanel from './SuspenseAccountsPanel'
import { SuspenseAccounts } from 'store/accounts/types'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Catalog } from 'components'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'

interface OwnProps {
  suspenseAccounts?: SuspenseAccounts
}

const SuspenseAccountsLanding = (props: OwnProps): React.ReactElement | null => {
  const { suspenseAccounts } = props

  if (!(suspenseAccounts && !isEmpty(suspenseAccounts) && !isEmpty(suspenseAccounts.subscriptions))) return null

  useEffect(() => {
    forceVisible()
  }, [])

  return (
    <>
      <ContentContainer data-test-id="suspense-page">
        <Catalog
          entityName="Suspense accounts"
          list={suspenseAccounts.subscriptions}
          icons={[]}
          searchFilter={(item, text) => item.name.toLowerCase().includes(text)}
          cardRenderer={(list, idx, key) => (
            <CatalogCardContainer key={`${key}-catalog-container`}>
              <SuspenseAccountPanel
                key={key}
                suspenseAccount={list[idx]}
                subscriptionKey={list[idx].subscriptionKey || 'empty'}
              />
            </CatalogCardContainer>
          )}
        />
      </ContentContainer>
    </>
  )
}

export default SuspenseAccountsLanding
