import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Field, Form, FormikProps } from 'formik'
import ArrowDownwardIcon from '@material-ui/icons/Archive'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Delete } from '@material-ui/icons'
import TextFieldComplex from 'components/form/TextFieldComplex'
import { DeleteOrRetire, RetireOrDeleteProductOrPackage } from 'store/products/types'
import { withFormikSimple, Yip } from 'utils/form.utils'
import SubmitButton from 'components/form/SubmitButton'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  setClosed: (e: React.MouseEvent) => void
  actionType: DeleteOrRetire
}

const ActionContainer = styled(Grid)`
  margin-top: 24px;
  margin-bottom: 8px;
`
const StyledGrid = styled(Grid)`
  padding-top: 24px;
`

interface ProductApproveModalProps extends FormikProps<RetireOrDeleteProductOrPackage>, OwnProps {}

const ProductApproveModalForm = (props: ProductApproveModalProps) => {
  const { t } = useTranslation()

  const { setClosed, actionType } = props
  return (
    <Form>
      <Grid container spacing={0} direction="column" justifyContent="flex-start" alignItems="stretch">
        {DeleteOrRetire.RETIREPRODUCT === actionType && (
          <StyledGrid>
            <Field
              name="message"
              label={t('Comments')}
              fullWidth
              component={TextFieldComplex}
              required
              validate={Yip(Yup.string().typeError(t('This is mandatory field')).required())}
              placeholder={t('Reason for retiring product is')}
              inputLabelProps={{ shrink: true }}
            />
          </StyledGrid>
        )}
      </Grid>
      <ActionContainer container spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid item>
          <Button color={ColorType.BUTTON} onClick={setClosed}>
            {t('Cancel')}
          </Button>
        </Grid>
        <Grid item>
          <SubmitButton variant="contained" isValidating={false} noDirty color={ColorType.ERROR}>
            {DeleteOrRetire.RETIREPRODUCT === actionType && <ArrowDownwardIcon />}
            {DeleteOrRetire.RETIREPRODUCT !== actionType && <Delete />}
            {t('Confirm')}
          </SubmitButton>
        </Grid>
      </ActionContainer>
    </Form>
  )
}

export default withFormikSimple<OwnProps, RetireOrDeleteProductOrPackage>(ProductApproveModalForm, {})
