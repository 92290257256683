import React, { useCallback, useState, useEffect } from 'react'
import { Dialog, Fade, Grid, Typography } from '@material-ui/core'
import moment from 'moment-timezone'
import { Field, Form, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { find, isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import { headRows } from '../../Packages/CreatePackage/TableRows'
import { FormBottom, ButtonContainer, TooltipStyle, StyledDialogActions, Header, ListSeparatorDivider } from './Style'
import { DarkDivider } from 'components/form/TableComp/TableComp'
import { ContextualHelp, EnhancedTableField, ModalWrapper, SubmitButton, TextFieldComplex } from 'components'
import { withFormikSimple, Yip } from 'utils/form.utils'
import SelectFieldComplex, { SelectFieldData } from 'components/form/SelectFieldComplex'
import {
  AvailableProducts,
  PackagesWithProducts,
  PackagesWithProductsFormValue,
  PackageType,
} from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import countriesJson from 'static/countries.json'
import { TableCommonDataType, TableCommonGetKeyString, TableCommonGetSearchString } from 'components/TableCommon/types'
import { CountryItem } from 'store/utils/types'
import PackagesActions from 'store/packages/actions'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  availableProducts: AvailableProducts[] | undefined
  selectedPackage?: PackagesWithProducts
  // eslint-disable-next-line react/no-unused-prop-types
  t: TFunction
}

interface Props extends FormikProps<PackagesWithProductsFormValue>, OwnProps {}

const getSearchString = (item: AvailableProducts) => `${item.productName}`
const getKeyString = (item: AvailableProducts) => `${item.productKey}`

const EditPackageForm = (props: Props): React.ReactElement => {
  const { availableProducts, selectedPackage, handleSubmit, initialValues, values } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      dispatch(PackagesActions.isPublishPackage(true))
    }
    if (isEqual(initialValues, values)) {
      dispatch(PackagesActions.isPublishPackage(false))
    }
  }, [initialValues, values, dispatch])

  const { t } = useTranslation()
  const [openConfirmPublish, setOpenConfirmPublish] = useState(false)
  const onClickConfirmPublish = useCallback(() => setOpenConfirmPublish(true), [setOpenConfirmPublish])
  const onCloseConfirmPublish = () => setOpenConfirmPublish(false)
  const selectDataCountriesJson: SelectFieldData = {}
  Object.entries(countriesJson).forEach(([, item]: [string, CountryItem]) => {
    selectDataCountriesJson[`${item.alpha3Code}`] = {
      name: item.alpha3Code,
      label: item.name,
      flag: item.alpha2Code,
    }
  })

  const selectDataPackageType: SelectFieldData = {}
  Object.entries(PackageType).forEach(([key, value]) => {
    selectDataPackageType[key] = { name: value }
  })

  const confirmPublish = () => {
    onClickConfirmPublish()
  }
  let filteredJoinedProducts: TableCommonDataType[] = []
  let filteredNonJoinedProducts: TableCommonDataType[] = []

  if (selectedPackage) {
    filteredJoinedProducts = (availableProducts || []).filter((item) =>
      find(selectedPackage.products, { productKey: item.productKey })
    )

    filteredNonJoinedProducts = (availableProducts || []).filter((item) => {
      if (selectedPackage.packageStatus !== PackagesStatus.DESIGN && selectedPackage.products.length > 0) {
        const nonJoinedProductWithFirstVersion = item.versions?.[(item?.versions?.length || 0) - 1]

        // Product effective date hould be either a past date  or
        // Product effective date should be earlier than selected Package Publication date
        // And its product key should not be already existing in selected package
        return (
          (moment().isAfter(nonJoinedProductWithFirstVersion?.effectiveDate) ||
            moment(selectedPackage.publicationDate).isSameOrAfter(nonJoinedProductWithFirstVersion?.effectiveDate)) &&
          !find(selectedPackage.products, { productKey: item.productKey })
        )
      }
      return !find(selectedPackage.products, { productKey: item.productKey })
    })
  } else {
    filteredJoinedProducts = []
    filteredNonJoinedProducts = availableProducts || []
  }
  return (
    <>
      <Form>
        <Dialog
          open={openConfirmPublish}
          scroll="paper"
          onClose={onCloseConfirmPublish}
          TransitionComponent={Fade}
          fullWidth
        >
          <ModalWrapper
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Header>
                <Typography variant="h6" gutterBottom>
                  {t('Publish package to live?')}
                </Typography>
                <Typography variant="body1">
                  {t(
                    'Are you sure you want to publish these changes to live? There isn’t an approval step for packages and if your package is live to customers, these changes will happen immediately.'
                  )}
                </Typography>
              </Header>
            }
          >
            <StyledDialogActions>
              <Button color={ColorType.BUTTON} type="button" onClick={onCloseConfirmPublish}>
                {t('No, Cancel')}
              </Button>
              <Button
                style={{ marginLeft: '16px !important' }}
                type="submit"
                color={ColorType.BUTTON}
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {t('Yes, Publish')}
              </Button>
            </StyledDialogActions>
          </ModalWrapper>
        </Dialog>
        <Grid container direction="row" spacing={3} justifyContent="center">
          <Grid xs={12} item>
            <Typography variant="h2">{t('Package details')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="productPackageName"
              type="name"
              label={t('Package name')}
              validate={Yip(Yup.string().required())}
              required
              fullWidth
              component={TextFieldComplex}
              margin="none"
              inputProps={{
                'data-test-id': 'package-name-field',
              }}
              inputLabelProps={{
                shrink: true,
              }}
              placeholder={t('Enter name')}
            />
          </Grid>
          <Grid xs={11} sm={6} item>
            <Field
              required
              name="distribution.packageType"
              label={t('Package type')}
              data={selectDataPackageType}
              placeholder={t('Please select')}
              component={SelectFieldComplex}
              selectProps={{ displayEmpty: true }}
              inputProps={{
                'data-test-id': 'package-type-field',
              }}
              inputLabelProps={{ shrink: true }}
              validate={Yip(Yup.string().required())}
            />
          </Grid>
          <Grid item xs={1} sm={6}>
            <TooltipStyle>
              <ContextualHelp
                title={t(
                  `The package type is used by the mobile SDK and will determine where the products within the package are shown to the customer`
                )}
              />
            </TooltipStyle>
          </Grid>
          <Grid xs={11} sm={6} item>
            <Field
              name="distribution.countryOfResidence"
              label={t('Country of residence')}
              data={selectDataCountriesJson}
              placeholder={t('Please select')}
              component={SelectFieldComplex}
              hasFlag
              selectProps={{ displayEmpty: true }}
              inputProps={{
                'data-test-id': 'country-of-residence-field',
              }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={1} sm={6}>
            <TooltipStyle>
              <ContextualHelp
                title={t(
                  `The country of residence is optional. It can be used to only offer the products to customers of this country of residence`
                )}
              />
            </TooltipStyle>
          </Grid>
          <Grid xs={11} sm={6} item>
            <Field
              name="distribution.brandId"
              label={t('Custom attribute')}
              component={TextFieldComplex}
              placeholder={t('Please enter')}
              fullWidth
              inputProps={{
                'data-test-id': 'custom-attribute-field',
              }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={1} sm={6}>
            <TooltipStyle>
              <ContextualHelp
                title={t(
                  `A custom attribute can be added and used by your  developers to determine who can apply or at what point they can apply`
                )}
              />
            </TooltipStyle>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="h2" style={{ marginTop: 15 }}>
              {t('Products in this package')}
            </Typography>
          </Grid>

          <Grid xs={12} item>
            {filteredJoinedProducts && (
              <Field
                name="products"
                label=""
                type="text"
                component={EnhancedTableField}
                headRows={headRows}
                getSearchString={getSearchString as TableCommonGetSearchString}
                getKeyString={getKeyString as TableCommonGetKeyString}
                data={filteredJoinedProducts as TableCommonDataType[]}
                defaultRowsPerPage={9999999}
                hideHeader
                hidePagination
                title=" "
                noEmptyRows
                cardElevation={0}
                touchedNotNeeded
                disableSelectAll
                selectableCellProps={{ style: { verticalAlign: 'text-top' } }}
              />
            )}
          </Grid>
          <Grid xs={12} item>
            <ListSeparatorDivider />
          </Grid>
          <Grid xs={12} item>
            <Typography variant="h3" gutterBottom>
              {t(`Add more products`)}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {filteredJoinedProducts && (
              <Field
                name="nonJoinedProducts"
                label=""
                type="text"
                component={EnhancedTableField}
                headRows={headRows}
                getSearchString={getSearchString as TableCommonGetSearchString}
                getKeyString={getKeyString as TableCommonGetKeyString}
                data={filteredNonJoinedProducts as TableCommonDataType[]}
                title={t('Choose more products')}
                defaultRowsPerPage={10}
                noEmptyRows
                cardElevation={0}
                touchedNotNeeded
                disableSelectAll
                selectableCellProps={{ style: { verticalAlign: 'text-top' } }}
              />
            )}
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <DarkDivider />
        </Grid>

        <FormBottom>
          <Grid container spacing={3} direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <ButtonContainer>
                <Button
                  variant="text"
                  color={ColorType.BUTTON}
                  onClick={() => navigate(`/packages/${selectedPackage?.productPackageId}`)}
                  disabled={!props.isValid || props.isValidating}
                  aria-controls="main"
                >
                  {t('Cancel')}
                </Button>
              </ButtonContainer>
            </Grid>
            <Grid item>
              <ButtonContainer>
                {selectedPackage && selectedPackage.packageStatus === PackagesStatus.LIVE ? (
                  <Button
                    variant="contained"
                    color={ColorType.BUTTON}
                    onClick={confirmPublish}
                    disabled={!props.isValid || props.isValidating}
                  >
                    {t('PUBLISH')}
                  </Button>
                ) : (
                  <SubmitButton variant="contained" color={ColorType.BUTTON}>
                    {t('Submit')}
                  </SubmitButton>
                )}
              </ButtonContainer>
            </Grid>
          </Grid>
        </FormBottom>
      </Form>
    </>
  )
}

const editPackageValidation = (values: PackagesWithProductsFormValue, props: any) => {
  const errors: any = {}
  if (!((values.products || []).length + (values.nonJoinedProducts || []).length > 0)) {
    errors.products = props.t('Must have at least 1 items')
    errors.nonJoinedProducts = props.t('Must have at least 1 items')
  }

  return errors
}

export default withFormikSimple<OwnProps, PackagesWithProductsFormValue>(EditPackageForm, {
  isInitialValid: () => true,
  enableReinitialize: true,
  validate: editPackageValidation,
})
