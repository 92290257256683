/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { LendingRateType } from 'store/products/types'
import { ContextualHelp, Subtitle1 } from 'dls'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

export const StyledCard = styled(Paper)`
  padding: 0;
  padding-right: 20px;
  border: 1px dashed ${({ theme }) => theme.palette.text.disabled};
  box-shadow: none;
  border-radius: 6px;
`

interface Props {
  rateType?: LendingRateType
  disableAdd?: boolean
}

const AddRateCard = ({ rateType, disableAdd }: Props): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <StyledCard elevation={1}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        aria-label={t(`DEBIT_INTEREST.${rateType}`)}
        style={{ height: '80px' }}
      >
        <Grid item>
          <Subtitle1
            style={{
              paddingLeft: 24,
            }}
          >
            {t(`DEBIT_INTEREST.${rateType}`)}
            <ContextualHelp
              placement="top"
              style={{ verticalAlign: 'middle', marginLeft: '8', marginTop: '-2' }}
              title={`${t(`DEBIT_INTEREST.${rateType}_help`)}`}
            />
          </Subtitle1>
        </Grid>
        <Grid
          alignItems="flex-end"
          style={{ gridArea: 'infoArea', textAlign: 'right', float: 'right', marginLeft: '120px' }}
          spacing={10}
        >
          <Button
            variant="outlined"
            color={ColorType.BUTTON}
            disabled={disableAdd}
            component={RouterLink}
            to={`add/${rateType}`}
          >
            {t('ADDTEXT')}
          </Button>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default AddRateCard
