import React, { CSSProperties } from 'react'
import styled from 'styled-components/macro'
import { SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { ImageTypes } from 'dls/shared/types'

export const StyledImg = styled.img`
  object-fit: contain;
  max-width: 100%;
`
interface ImageTypesCustomeProp extends Omit<ImageTypes, 'src'> {
  className?: string
  style?: CSSProperties
  component?: string
  Icon?: OverridableComponent<SvgIconTypeMap>
  src?: string
  fontSize?: string
}

export const Graphic = ({ ariaProps, src, Icon, fontSize, style, className, ...props }: ImageTypesCustomeProp) => {
  if (Icon) {
    return (
      <Icon
        data-dls="atom-graphic"
        title={props.title || props.alt}
        className={className}
        style={{ fontSize, ...style }}
        {...props}
        {...ariaProps}
      />
    )
  }
  if (src) {
    return (
      <StyledImg
        data-dls="atom-graphic"
        title={props.title || props.alt}
        className={className}
        src={src}
        style={style}
        {...props}
        {...ariaProps}
      />
    )
  }
  return null
}

export default Graphic
