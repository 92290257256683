import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { find } from 'lodash'
import PackageActions from './actions'
import { navigateToUnavailable } from 'utils/url.utils'
import { PackageApi } from 'api'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import ModalActions from 'store/modal/actions'
import { navigate } from 'store/router/actions'

function* showInProgress() {
  yield put(ModalActions.showFeatureProgressModal())
}

function* showSuccess(successResponse: any) {
  yield delay(UI_DELAY_TO_SHOW_LOADING)
  yield put(ModalActions.closeModal())
  if (successResponse) yield put(ModalActions.showFeatureSuccessModal(successResponse))
}

function* showDeletion(successResponse: any) {
  yield delay(UI_DELAY_TO_SHOW_LOADING)
  yield put(ModalActions.closeModal())
  if (successResponse) yield put(ModalActions.showFeatureDeletedModal(successResponse))
}

function* showError(e: Error) {
  yield put(ModalActions.closeModal())
  yield put(ModalActions.showFeatureFailureModal(e))
}

function* getPackages(action: ReturnType<typeof PackageActions.getPackages>) {
  try {
    const response: ReturnTypePromise<typeof PackageApi.getPackages> = yield call(PackageApi.getPackages)
    yield put(PackageActions.getPackagesSuccess(response))
  } catch (e: any) {
    yield put(PackageActions.getPackagesFail(e))
    if (action?.payload?.shouldRedirect ?? true) {
      yield call(navigateToUnavailable)
    }
  }
}

function* createPackages(action: ReturnType<typeof PackageActions.createPackage>) {
  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof PackageApi.createPackage> = yield call(
      PackageApi.createPackage,
      action.payload
    )
    if (response.productPackageId) {
      const packages: ReturnTypePromise<typeof PackageApi.getPackages> = yield call(PackageApi.getPackages)
      yield put(PackageActions.getPackagesSuccess(packages))
      // Create package returns package with products whithout productName, status, effective date. It's temperary solution
      // Until we get full products summary
      const selectedPackage = find(packages, (current) => current.productPackageId === response.productPackageId)
      if (selectedPackage) {
        yield put(PackageActions.createPackageSuccess(selectedPackage))
      }

      yield put(navigate(`/packages/${response.productPackageId}` as any))
    } else {
      yield put(navigate(`/packages/create` as any))
    }
    yield showSuccess({ ...response, featureName: 'Package' })
  } catch (e: any) {
    yield put(PackageActions.createPackageFail(e))
    yield showError(e)
  }
}

function* publishPackage(action: ReturnType<typeof PackageActions.publishPackage>) {
  try {
    const { payload } = action
    const response: ReturnTypePromise<typeof PackageApi.publishPackage> = yield call(PackageApi.publishPackage, payload)
    const packages: ReturnTypePromise<typeof PackageApi.getPackages> = yield call(PackageApi.getPackages)
    yield put(PackageActions.getPackagesSuccess(packages))
    // publish package returns package with products whithout productName, status, effective date. It's temperary solution
    // Until we get full products summary
    const selectedPackage = find(packages, (current) => current.productPackageId === response.productPackageId)
    if (selectedPackage) {
      yield put(PackageActions.publishPackageSuccess(selectedPackage))
    }
  } catch (e: any) {
    yield put(PackageActions.publishPackageFail(e))
  }
}

function* availableProducts() {
  try {
    const response: ReturnTypePromise<typeof PackageApi.availableProducts> = yield call(PackageApi.availableProducts)
    yield put(PackageActions.availableProductsSuccess(response))
  } catch (e: any) {
    yield put(PackageActions.availableProductsFail(e))
  }
}

function* getPackageDetails(action: ReturnType<typeof PackageActions.getPackageDetails>) {
  try {
    const response: ReturnTypePromise<typeof PackageApi.getPackageDetails> = yield call(
      PackageApi.getPackageDetails,
      action.payload
    )
    yield put(PackageActions.getPackageDetailsSuccess(response))
  } catch (e: any) {
    yield put(PackageActions.getPackageDetailsFail(e))
    yield put(navigate(`/not-found` as any))
  }
}

function* updateSelectedPackage(action: ReturnType<typeof PackageActions.updateSelectedPackage>) {
  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof PackageApi.updatePackage> = yield call(
      PackageApi.updatePackage,
      action.payload
    )
    yield put(PackageActions.updateSelectedPackageSuccess(response as any))
    yield put(navigate(`/packages/${action.payload.productPackageId}` as any))
    yield showSuccess({ ...response, featureName: 'Package' })
  } catch (e: any) {
    yield showError(e)
    yield put(PackageActions.updateSelectedPackageFail(e))
  }
}

function* deletePackage(action: ReturnType<typeof PackageActions.deletePackage>) {
  try {
    yield showInProgress()
    yield call(PackageApi.deletePackage, action.payload.productOrPackageKey)
    yield put(navigate(`/packages`))
    yield put(PackageActions.getPackages())
    yield showDeletion({ featureName: 'Package' })
  } catch (e: any) {
    yield showError(e)
    yield put(PackageActions.deletePackageFail(e))
  }
}

export default function* () {
  yield takeLatest(PackageActions.getPackages.type, getPackages)
  yield takeLatest(PackageActions.createPackage.type, createPackages)
  yield takeLatest(PackageActions.publishPackage.type, publishPackage)
  yield takeLatest(PackageActions.availableProducts.type, availableProducts)
  yield takeLatest(PackageActions.getPackageDetails.type, getPackageDetails)
  yield takeLatest(PackageActions.updateSelectedPackage.type, updateSelectedPackage)
  yield takeLatest(PackageActions.deletePackage.type, deletePackage)
}
