import React from 'react'
import { Dialog, DialogContent, Fade, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { StyledCircularProgress } from './styled'
import { Flexbox } from 'pages/Layout/Styled'
import { H3, Body2 } from 'components'

interface Props {
  title?: string
  description?: string
  open: boolean
  setOpen?: (open: boolean) => void
}

const ProgressModal = ({ title, description, open, setOpen }: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={() => setOpen && setOpen(false)}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogContent>
        <Flexbox direction="column" justifyContent="center" alignItems="center" p={40} pb={60}>
          <StyledCircularProgress size={90} />
          {title ? (
            <H3 data-test-id="progress-dialog-title" style={{ marginTop: 16, textAlign: 'center' }}>
              {t(title)}
            </H3>
          ) : null}
          {description ? (
            <Body2 variantMapping={{ body2: 'div' }} style={{ marginTop: 8 }}>
              {t(description)}
            </Body2>
          ) : null}
        </Flexbox>
      </DialogContent>
    </Dialog>
  )
}

export default ProgressModal
