import React from 'react'
import { FieldProps } from 'formik'
import { ListSubheader, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Select, { SelectProps as MuiSelectProps } from '@material-ui/core/Select'
import styled from 'styled-components/macro'
import { orderBy } from 'lodash'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'
import { Flag } from 'components'
import { OAvatar } from 'dls'
import { AvatarSize } from 'dls/shared/types'
import { getInitials, getColorFromInitials } from 'utils/ui.utils'

export interface SelectFieldDataItem {
  name: string | number
  label?: string
  flag?: string
  header?: boolean
  generatedItem?: React.ReactNode | (() => React.ReactNode)
  index?: number
}

export interface SelectFieldData {
  [key: string]: SelectFieldDataItem | string
}

export interface SelectFieldComplexProps {
  required?: boolean
  isUserSelector?: boolean
  selectProps?: Omit<MuiSelectProps, 'value'>
  data: SelectFieldData
  hasFlag?: false
  placeholder?: string
  hasPlaceholder?: boolean
  translationPrefix?: string
  onChange: (e: any) => void
  orderByIndex?: boolean
}

type Props = FieldProps & SelectFieldComplexProps & EnhancedFormikFieldProps

const StyledListSubheader = styled(ListSubheader)`
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props: any) => props.theme.palette.primary.main};
  background-color: ${(props: any) => `${props.theme.palette.background.paper}`};
  font-size: 16px;
`

const StyledDiv = styled.div`
  display: flex;
`

const StyledSpan = styled.span`
  align-self: center;
`

const SelectFieldComplex = (props: Props) => {
  const {
    selectProps,
    data,
    field,
    inputLabelProps,
    isUserSelector,
    hasFlag,
    helperText,
    placeholder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasPlaceholder,
    onChange,
    translationPrefix,
    orderByIndex = false,
    ...otherProps
  } = props
  const { t } = useTranslation()

  const onChangeOwn = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange({ ...e, target: { ...e.target, value: e.target.value === '' ? null : e.target.value } })
    if (onChange) {
      onChange(e.target.value)
    }
  }
  const fakeTranslateOptions = { nsSeparator: '||' }

  let orderedData = Object.entries(data)
  if (orderByIndex === true && orderedData[0][1] && typeof orderedData[0][1] !== 'string' && orderedData[0][1].index) {
    orderedData = orderBy(orderedData, ([, item]) => item && typeof item !== 'string' && item.index)
  }

  const selectOptions = orderedData?.map(([key, item]) => {
    const flag = typeof item === 'string' ? item : item.flag
    const initials = getInitials(typeof item === 'string' ? item : item.label)
    const avatarColor = getColorFromInitials(initials)
    if (item && typeof item !== 'string' && item.header)
      return (
        <StyledListSubheader key={key + item.label}>
          {typeof item !== 'string' && item.label ? t(item.label, fakeTranslateOptions) : t(key, fakeTranslateOptions)}
        </StyledListSubheader>
      )

    if (item && typeof item !== 'string' && item.generatedItem) {
      return (
        <MenuItem key={key + item.name} value={typeof item === 'string' ? item : item.name}>
          <StyledDiv>{typeof item.generatedItem === 'function' ? item.generatedItem() : item.generatedItem}</StyledDiv>
        </MenuItem>
      )
    }

    return (
      <MenuItem
        key={typeof item === 'string' || typeof item === 'number' ? key + item : key + item.name}
        value={typeof item === 'string' || typeof item === 'number' ? item : item.name}
      >
        <StyledDiv>
          {hasFlag && <Flag code={flag as string} size={20} />}
          {isUserSelector && (
            <div style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}>
              <OAvatar color={avatarColor} size={AvatarSize.NORMAL}>
                {initials}
              </OAvatar>
            </div>
          )}
          <StyledSpan>
            {typeof item !== 'string' && item.label
              ? t(item.label, fakeTranslateOptions)
              : t(`${translationPrefix ? `${translationPrefix}.` : ''}${key}`, fakeTranslateOptions)}
          </StyledSpan>
        </StyledDiv>
      </MenuItem>
    )
  })

  const currentValue = selectOptions?.find((menuItem) => menuItem.props.value === field.value)?.props?.value ?? ''

  return (
    <EnhancedFormikField {...props} inputLabelProps={inputLabelProps} helperText={helperText}>
      {() => (
        <Select
          {...otherProps}
          {...selectProps}
          value={currentValue}
          name={field.name}
          onChange={onChangeOwn as any}
          inputProps={{
            name: field.name,
            id: field.name,
            ...((selectProps || {}).inputProps || {}),
          }}
        >
          {
            // this lets us use the material label and avoids dupe-visual bug
            props.inputLabelProps && props.inputLabelProps.shrink && (
              <MenuItem value="" disabled>
                {placeholder || t('Please select')}
              </MenuItem>
            )
          }
          {selectOptions}
        </Select>
      )}
    </EnhancedFormikField>
  )
}

export default SelectFieldComplex
