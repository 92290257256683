import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Body2 } from 'components'
import { toBankTz } from 'utils/date.utils'
import { StyledAvatar } from 'components/StatusBadge/Styled'
import { PackagesWithProducts } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'

interface OwnProps {
  isActive: boolean
  items: PackagesWithProducts[]
  isLive?: boolean
}

const ScheduledMenuItem = ({ isActive, items, isLive = false }: OwnProps) => {
  const { t } = useTranslation()

  const itemsLength = useMemo(
    () =>
      isLive
        ? items.filter(
            (item) =>
              item.packageStatus === PackagesStatus.LIVE &&
              item.publicationDate &&
              !toBankTz(item.publicationDate).isAfterNow
          ).length
        : items.filter(
            (item) =>
              item.packageStatus === PackagesStatus.LIVE &&
              item.publicationDate &&
              toBankTz(item.publicationDate).isAfterNow
          ).length,
    [items]
  )

  // we need a date that is always in the future to trigger scheduled icon
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth()
  const day = today.getDate()
  const futureDate = new Date(year, month + 1, day).toUTCString()

  const label = isLive ? t('Live') : t('Scheduled')

  return (
    <Box component="span" display="flex">
      <StyledAvatar status={PackagesStatus.LIVE} parsedDate={!isLive ? toBankTz(futureDate) : undefined} />
      <Body2 color={!isActive ? 'textPrimary' : undefined}>
        {label} ({itemsLength})
      </Body2>
    </Box>
  )
}
export default ScheduledMenuItem
