import { Grid, Table, TableBody } from '@material-ui/core'
import { FastField, Form } from 'formik'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import TableRow from '@material-ui/core/TableRow'
import { useNavigate } from 'react-router-dom'
import ProductKeyRow from '../../Products/CreateProductDialog/ProductCore/ProductKeyRow'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { ContentGridContainer, ContentPaperContainer } from '../Styled'
import { withFormikSimple, Yip } from 'utils/form.utils'
import { FormBottomBar, SelectFieldComplex, StyledTableCell1, StyledTableCell2, TextFieldComplex } from 'components'
import { selectConfig } from 'store/tenant-config/selectors'
import { productMatrix } from 'pages/Products/CreateProductDialog/ProductCore/productMatrix'
import { ProductKey, UpdateProductFormValues } from 'store/products/types'
import { InfoPanel } from 'dls'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  type: string
  productKey: ProductKey
  isEditMode?: boolean
  defaultValues?: {
    productName?: string
    productCategory?: string
    tags?: string[]
  }
}

const ProductCoreForm = ({ isEditMode = false, productKey = '', ...props }: OwnProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const tenantConfig = useSelector(selectConfig)
  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Product details')}
      description={t(
        'Here you can edit the product details. Once a product has been published some details can’t be edited, instead you’ll need to create a new product from scratch.'
      )}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <FastField
                name="productName"
                type="name"
                label={t('Product name')}
                validate={Yip(Yup.string().required().max(100))}
                required
                fullWidth
                component={TextFieldComplex}
                css="margin: 0.2em 0"
                inputLabelProps={{
                  shrink: true,
                }}
                placeholder={t('Enter Name')}
                margin="none"
              />
            </Grid>
            {!isEditMode && (
              <Grid item xs={6}>
                <FastField
                  name="productTaxonomy"
                  label={t('Product type')}
                  validate={Yip(Yup.string().required())}
                  required
                  data={productMatrix(tenantConfig)}
                  selectProps={{ displayEmpty: true }}
                  inputLabelProps={{ shrink: true }}
                  component={SelectFieldComplex}
                  css="margin: 0.2em 0"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FastField
                name="productDescription"
                type="description"
                placeholder={t('Enter description')}
                label={t('Product description')}
                validate={Yip(Yup.string().nullable().max(200))}
                fullWidth
                component={TextFieldComplex}
                css="margin: 1em 0"
                inputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {isEditMode && (
              <Grid item xs={12}>
                <InfoPanel body={t("The product key and product type can't be edited")} css="margin: 0" />
                <Table>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell1 scope="row">{t('Product key')}</StyledTableCell1>
                      <StyledTableCell2 align="left">
                        <ProductKeyRow productKey={productKey} />
                      </StyledTableCell2>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell1 scope="row">{t('Type')}</StyledTableCell1>
                      <StyledTableCell2 align="left">
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                          {props.type}
                        </Grid>
                      </StyledTableCell2>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormBottomBar inlineNavigation>
                <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                  {t('Cancel')}
                </Button>
              </FormBottomBar>
            </Grid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, UpdateProductFormValues>(ProductCoreForm)
