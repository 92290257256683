import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Products from './Products'
import { RootState } from 'store'
import ProductsActions from 'store/products/actions'
import ProductsSelectors from 'store/products/selectors'
import { Loader } from 'components/'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = {
  fetchProducts: ProductsActions.fetch,
}

const mapStateToProps = (state: RootState) => ({
  products: ProductsSelectors.selectAllProducts(state),
  loading: ProductsSelectors.selectDataLoading(state),
})

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

export function ProductsPage({ loading, products, fetchProducts }: Props) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)
  const statusParam = new URLSearchParams(searchParams).get('status') || ''

  useBreadcrumbs({
    breadcrumbs: [{ title: t('{{tenantName}} products', { tenantName: currentTenant?.name }), path: '' }],
    trigger: true,
  })

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  if (loading) {
    return <Loader />
  }

  // @TODO: Remove take as soon as the products db is in better shape
  return <Products products={products} statusQueryParam={statusParam} />
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage)
