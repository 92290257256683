import { omit } from 'lodash'
import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.deleteProduct.type]: (state: ProductsState) => ({ ...state, loading: true }),
  [actions.deleteProductSuccess.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.deleteProductSuccess>
  ) => ({
    ...state,
    loading: false,
    data: omit(state.data, action.payload),
  }),
  [actions.deleteProductFail.type]: (state: ProductsState) => ({ ...state, loading: false }),
  [actions.retireProductSuccess.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.retireProductSuccess>
  ) => ({
    ...state,
    selectedProduct: action.payload,
  }),
}
export default reducer
