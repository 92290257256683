import React from 'react'
import { Grid } from '@material-ui/core'
import { Main } from 'dls/shared/styled'

interface OwnProps {
  header: React.ReactNode
  main: React.ReactNode
  toolbar?: React.ReactNode
  disableToolbarRole?: boolean
  headerProps?: React.HTMLAttributes<HTMLDivElement>
  mainProps?: React.HTMLAttributes<HTMLDivElement>
  toolbarProps?: React.HTMLAttributes<HTMLDivElement>
}

const TemplateReview = ({
  header,
  main,
  headerProps,
  mainProps,
  toolbarProps,
  toolbar,
  disableToolbarRole,
}: OwnProps) => (
  <Grid container direction="column" spacing={3} data-dls="template-review">
    <Grid item sm={12}>
      <header
        {...headerProps}
        css={
          headerProps?.css
            ? `
          ${headerProps.css}
        `
            : undefined
        }
      >
        {header}
      </header>
    </Grid>
    <Grid item>
      <Grid container direction="column" spacing={3}>
        {toolbar && (
          <Grid item sm={12}>
            <div
              role={!disableToolbarRole ? 'toolbar' : undefined}
              {...toolbarProps}
              css={
                toolbarProps?.css
                  ? `
          ${toolbarProps.css}
        `
                  : undefined
              }
            >
              {toolbar}
            </div>
          </Grid>
        )}
        <Grid item sm={12}>
          <Main
            {...mainProps}
            css={
              mainProps?.css
                ? `
          ${mainProps.css}
        `
                : undefined
            }
          >
            {main}
          </Main>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)
export default TemplateReview
