import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import History from './InterestRateHistory'
import ReferenceDataActions from 'store/referenceData/actions'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import { Loader } from 'components'
import { BankIndexKey } from 'store/referenceData/types'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const InterestRateHistoryPage = () => {
  const { t } = useTranslation()
  const { bankIndexKey } = useParams()

  const dispatch = useDispatch()
  const getIndexRateDetails = (indexKey: BankIndexKey) =>
    dispatch(ReferenceDataActions.getBankIndexRateDetails(indexKey))

  const selectedIndexRate = useSelector(ReferenceDataSelectors.selectSelectedIndexRate)
  const loading = useSelector(ReferenceDataSelectors.selectLoading)

  useEffect(() => {
    getIndexRateDetails(`${bankIndexKey}`)
  }, [])

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference dataa'), path: 'reference-data/' },
      { title: t('Index rates'), path: 'index-rates/' },
      { title: selectedIndexRate?.name || '', path: `reference-data/index-rates/${selectedIndexRate?.bankIndexKey}` },
      { title: t('Historic rates'), path: '' },
    ],
    trigger: true,
  })

  if (loading || !selectedIndexRate) {
    return <Loader />
  }

  return <History selectedIndexRate={selectedIndexRate} />
}

export default InterestRateHistoryPage
