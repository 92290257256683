import { ProductType, ProductSegment, ProductLine, ProductGroup, ProductCategory } from 'store/products/types'
import { TenantConfig } from 'store/tenant-config/types'

export const productMatrix = (tenantConfig: TenantConfig | undefined) => ({
  PersonalHeader: { type: 'header', header: true, label: 'PERSONAL:' },
  PersonalCurrentAccount: {
    label: 'Current : Accounts',
    name: JSON.stringify({
      productSegment: ProductSegment.PERSONAL,
      productGroup: ProductGroup.CURRENT,
      productLine: ProductLine.ACCOUNTS,

      productType: ProductType.PERSONAL_CURRENT_ACCOUNT,
      productCategory: ProductCategory.Basic,
    }),
  },

  SavingsPersonal: {
    label: 'Savings : Deposits',
    name: JSON.stringify({
      productSegment: ProductSegment.PERSONAL,
      productGroup: ProductGroup.SAVINGS,
      productLine: ProductLine.DEPOSITS,

      productType: ProductType.SAVINGS,
      productCategory: ProductCategory.Basic,
    }),
  },
  ...(tenantConfig?.features?.mission_wip
    ? {
        LoansPersonal: {
          label: 'Loans : Lending',
          name: JSON.stringify({
            productSegment: ProductSegment.PERSONAL,
            productLine: ProductLine.LENDING,
            productGroup: ProductGroup.LOANS,
            productType: ProductType.LOANS,
            productCategory: ProductCategory.Basic,
          }),
        },
        MortgagesPersonal: {
          label: 'Mortgages : Lending',
          name: JSON.stringify({
            productSegment: ProductSegment.PERSONAL,
            productLine: ProductLine.LENDING,
            productGroup: ProductGroup.MORTGAGES,
            productType: ProductType.MORTGAGES,
            productCategory: ProductCategory.Basic,
          }),
        },
      }
    : {}),
  // BillsPool: {
  //   label: 'Bills Pool',
  //   name: JSON.stringify({
  //     productSegment: ProductSegment.PERSONAL,
  //     productLine: ProductLine.ACCOUNTS,
  //     productGroup: ProductGroup.POOLS,
  //     productType: ProductType.BILLS_POOL,
  //     productCategory: ProductCategory.Basic,
  //   }),
  // },
  // SavingsPool: {
  //   label: 'Savings Pool',
  //   name: JSON.stringify({
  //     productSegment: ProductSegment.PERSONAL,
  //     productLine: ProductLine.ACCOUNTS,
  //     productGroup: ProductGroup.POOLS,
  //     productType: ProductType.SAVINGS_POOL,
  //     productCategory: ProductCategory.Basic,
  //   }),
  // },
  // CurrentPool: {
  //   label: 'Current Pool',
  //   name: JSON.stringify({
  //     productSegment: ProductSegment.PERSONAL,
  //     productLine: ProductLine.ACCOUNTS,
  //     productGroup: ProductGroup.POOLS,
  //     productType: ProductType.CURRENT_POOL,
  //     productCategory: ProductCategory.Basic,
  //   }),
  // },
  // Mortgages: {
  //   label: 'Mortgages',
  //   name: JSON.stringify({
  //     productSegment: ProductSegment.PERSONAL,
  //     productLine: ProductLine.LENDING,
  //     productGroup: ProductGroup.MORTGAGES,
  //     productType: ProductType.MORTGAGE,
  //     productCategory: ProductCategory.Basic,
  //   }),
  // },
  // SavingsPersonal: {
  //   label: 'Savings',
  //   name: JSON.stringify({
  //     productSegment: ProductSegment.PERSONAL,
  //     productLine: ProductLine.DEPOSITS,
  //     productGroup: ProductGroup.SAVINGS,
  //     productType: ProductType.SAVINGS,
  //     productCategory: ProductCategory.Basic,
  //   }),
  // },
  BusinessHeader: { type: 'header', header: true, label: 'BUSINESS:' },
  CurrentAccount: {
    label: 'Current : Accounts',
    name: JSON.stringify({
      productSegment: ProductSegment.BUSINESS,
      productGroup: ProductGroup.CURRENT,
      productLine: ProductLine.ACCOUNTS,

      productType: ProductType.CURRENT_ACCOUNT,
      productCategory: ProductCategory.Premium,
    }),
  },
  // LoansBusiness: {
  //   label: 'Loans',
  //   name: JSON.stringify({
  //     productSegment: ProductSegment.BUSINESS,
  //     productLine: ProductLine.LENDING,
  //     productGroup: ProductGroup.LOANS,
  //     productType: ProductType.LOAN,
  //     productCategory: ProductCategory.Premium,
  //   }),
  // },
  // ReserveAccount: {
  //   label: 'Reserve Account',
  //   name: JSON.stringify({
  //     productSegment: ProductSegment.BUSINESS,
  //     productLine: ProductLine.DEPOSITS,
  //     productGroup: ProductGroup.SAVINGS,
  //     productType: ProductType.RESERVEACCOUNT,
  //     productCategory: ProductCategory.Premium,
  //   }),
  // },
  SavingsBusiness: {
    label: 'Savings : Deposits',
    name: JSON.stringify({
      productSegment: ProductSegment.BUSINESS,
      productGroup: ProductGroup.SAVINGS,
      productLine: ProductLine.DEPOSITS,

      productType: ProductType.SAVINGS,
      productCategory: ProductCategory.Premium,
    }),
  },
  ...(tenantConfig?.features?.mission_wip
    ? {
        LoansBusiness: {
          label: 'Loans : Lending',
          name: JSON.stringify({
            productSegment: ProductSegment.BUSINESS,
            productLine: ProductLine.LENDING,
            productGroup: ProductGroup.LOANS,
            productType: ProductType.LOANS,
            productCategory: ProductCategory.Basic,
          }),
        },
        MortgagesBusiness: {
          label: 'Mortgages : Lending',
          name: JSON.stringify({
            productSegment: ProductSegment.BUSINESS,
            productLine: ProductLine.LENDING,
            productGroup: ProductGroup.MORTGAGES,
            productType: ProductType.MORTGAGES,
            productCategory: ProductCategory.Basic,
          }),
        },
      }
    : {}),
})
