import React from 'react'
import { LinkProps, Link } from '@material-ui/core'
import { Launch } from '@material-ui/icons/'
import styled from 'styled-components/macro'
import { Link as RouterLink } from 'react-router-dom'

interface Props {
  dialog?: boolean
  to?: string
  component?: any
  ariaLabel?: string
  'aria-controls'?: string
}

const StyledLink = styled(Link)`
  svg {
    color: ${(props: any) => `${props.theme.palette.secondary.main}`};
  }
  color: ${(props: any) => `${props.theme.palette.secondary.main}`};
`

const StyledSpan = styled.span`
  svg {
    color: ${(props: any) => `${props.theme.palette.secondary.main}`};
  }
  color: ${(props: any) => `${props.theme.palette.secondary.main}`};
  cursor: pointer;
`

export const AccessibleLink = ({ dialog = false, children, ariaLabel, to, ...props }: Props & LinkProps) => {
  const isExternalLink = props.href && `${props.href}`.startsWith('http')

  const Comp = dialog ? StyledSpan : StyledLink
  const ownprops: any = {}
  if (dialog) {
    ownprops['aria-haspopup'] = 'dialog'
    ownprops.role = 'button'
    ownprops.href = 'undefined'
  }
  if (!isExternalLink) {
    ownprops.component = RouterLink
    ownprops.to = to
  }
  return (
    <Comp
      aria-controls={dialog ? 'more-about-dialog' : 'main'}
      color="secondary"
      {...props}
      {...ownprops}
      target={props.target || (isExternalLink ? '_blank' : undefined)}
      aria-label={ariaLabel}
    >
      {children}
      {isExternalLink && (
        <>
          {' '}
          <Launch style={{ fontSize: 19, verticalAlign: 'middle' }} />
        </>
      )}
    </Comp>
  )
}

export default AccessibleLink
