import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import { useTranslation } from 'react-i18next'
import {
  CardImageMedia,
  CircleIcon,
  StyledCard,
  StyledCardActionArea,
  StyledCheck,
  StyledGrid,
  TypographyItem,
} from './Styled'

interface Props {
  imageUrl?: string
  title?: string
  onClick: (e: React.MouseEvent) => void
  isSelected: boolean
}

const ImageCard = ({ imageUrl, title, onClick, isSelected }: Props): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <StyledCard $isSelected={isSelected} $isCondensed={!title}>
        <StyledCardActionArea onClick={onClick}>
          <>
            <StyledGrid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              $isCondensed={!title}
            >
              {imageUrl ? (
                <CardImageMedia src={imageUrl} title={title} component="img" aria-hidden />
              ) : (
                <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center">
                  <CircleIcon padding="small">
                    <AddCircleOutline />
                  </CircleIcon>
                  <Typography
                    aria-label={t('Choose card image')}
                    variant="body2"
                    style={{ whiteSpace: 'pre', textAlign: 'center' }}
                  >
                    {t('Choose card image\n(optional)')}
                  </Typography>
                </Grid>
              )}
            </StyledGrid>
            {title && (
              <TypographyItem>
                {isSelected && <StyledCheck color="secondary" />}
                <Typography variant="body2">{title}</Typography>
              </TypographyItem>
            )}
          </>
        </StyledCardActionArea>
      </StyledCard>
    </>
  )
}

export default ImageCard
