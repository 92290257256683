import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectSuspenseAccounts = (state: RootState) => state.suspenseAccounts

const selectAllSuspenseAccounts = createSelector(selectSuspenseAccounts, (state) => state.suspenseAccounts)
const selectLoading = createSelector(selectSuspenseAccounts, (state) => state.loading)
const selectError = createSelector(selectSuspenseAccounts, (state) => state.error)
const selectTransactionLoading = createSelector(selectSuspenseAccounts, (state) => state.transactionsLoading)

const selectedSuspenseAccount = createSelector(selectSuspenseAccounts, (state) => state.suspenseAccount)

const selectTransactionsLoading = createSelector(selectSuspenseAccounts, (state) => state.transactionsLoading)
const selectTransactionsAppending = createSelector(selectSuspenseAccounts, (state) => state.transactionsAppending)

const selectedSuspenseTransactions = createSelector(
  selectSuspenseAccounts,
  (state) => state.selectedSuspenseTransactions
)

const selectedSuspenseTransactionsforStat = createSelector(
  selectSuspenseAccounts,
  (state) => state.selectedSuspenseTransactionsForStat
)

const selectedConfirmTransactionLoading = createSelector(
  selectSuspenseAccounts,
  (state) => state.confirmTransactionLoading
)

const selectedConfirmParentTransactionLoading = createSelector(
  selectSuspenseAccounts,
  (state) => state.confirmParentTransactionLoading
)

const selectedConfirmTransactionResult = createSelector(
  selectSuspenseAccounts,
  (state) => state.confirmTransactionResult
)

const selectedSuspenseAccountLoading = createSelector(
  selectSuspenseAccounts,
  (state) => state.selectedSuspenseAccountLoading
)

const selectedTransactionError = createSelector(selectSuspenseAccounts, (state) => state.transactionError)

export default {
  selectAllSuspenseAccounts,
  selectLoading,
  selectedSuspenseTransactions,
  selectedSuspenseTransactionsforStat,
  selectedSuspenseAccount,
  selectTransactionsLoading,
  selectTransactionLoading,
  selectedConfirmTransactionLoading,
  selectedConfirmParentTransactionLoading,
  selectedConfirmTransactionResult,
  selectedSuspenseAccountLoading,
  selectedTransactionError,
  selectTransactionsAppending,
  selectError,
}
