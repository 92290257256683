import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import { toEligibilityFormValues } from './eligibilityTransformers'
import EligibilityForm from './EligibilityForm'
import ProductsActions from 'store/products/actions'
import { EligibilityFormValues, Feature } from 'store/products/types'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useFeatureAvailable from 'pages/Product/hooks/useFeatureAvailable'
import { Loader } from 'components'

interface RouteOwnProps {}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setEligibility: (productKey: string) => (eligibilityFormValues: EligibilityFormValues) =>
    dispatch(ProductsActions.setEligibility({ ...eligibilityFormValues, productKey })),
})

type Props = ReturnType<typeof mapDispatchToProps> & RouteOwnProps

const EligibilityFormPage = (props: Props) => {
  const { setEligibility } = props
  const { productKey = '' } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)
  useUpdateBreadcrumbs({ productKey, context: Feature.ELIGIBILITY })
  useFeatureAvailable(productKey, Feature.ELIGIBILITY)

  if (isLoading) {
    return <Loader />
  }

  if (product) {
    return (
      <EligibilityForm
        productKey={productKey}
        product={product}
        initialValues={toEligibilityFormValues(product)}
        onSubmit={setEligibility(productKey)}
      />
    )
  }

  return null
}

export default connect(null, mapDispatchToProps)(EligibilityFormPage)
