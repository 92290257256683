import { call, put, delay, takeLatest } from 'redux-saga/effects'
import actions from './actions'
import { StatementConfiguration } from './types'
import { navigate } from 'store/router/actions'
import { StatementConfigurationAPI } from 'api'
import ModalActions from 'store/modal/actions'
import ProductApi from 'api/products'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import StatementConfigActions from 'store/templates/statementConfiguration/actions'

export function* fetchStatementConfigurationsSaga() {
  try {
    const response: ReturnTypePromise<typeof StatementConfigurationAPI.getStatementConfigurations> = yield call(
      StatementConfigurationAPI.getStatementConfigurations
    )
    yield put(actions.fetchStatementConfigurationSuccess(response.statementConfigs))
  } catch (e: any) {
    yield put(actions.fetchStatementConfigurationError(e))
  }
}

export function* updateStatementConfigurationSaga(action: ReturnType<typeof actions.updateStatementConfiguration>) {
  try {
    const payload = action.payload as StatementConfiguration

    yield put(ModalActions.showFeatureProgressModal())

    if (payload.statementConfigKey) {
      yield call(StatementConfigurationAPI.updateStatementConfiguration, payload.statementConfigKey, payload)
    } else {
      yield call(StatementConfigurationAPI.createStatementConfiguration, payload)
    }
    yield put(navigate('templates/statement-configurations'))
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureSuccessModal({ featureName: 'Statements' } as any))
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* getStatementProductsSaga(action: ReturnType<typeof StatementConfigActions.getStatementProducts>) {
  try {
    const response: ReturnTypePromise<typeof ProductApi.getStatementProducts> = yield call(
      ProductApi.getStatementProducts,
      action.payload
    )
    yield put(StatementConfigActions.getStatementProductsSuccess({ key: action.payload, products: response.content }))
  } catch (e: any) {
    yield put(StatementConfigActions.getStatementProductsError(e))
  }
}

export default function* () {
  yield takeLatest(actions.fetchStatementConfigurations.type, fetchStatementConfigurationsSaga)
  yield takeLatest(actions.updateStatementConfiguration.type, updateStatementConfigurationSaga)
  yield takeLatest(StatementConfigActions.getStatementProducts.type, getStatementProductsSaga)
}
