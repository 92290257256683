import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CashWithdrawalLimitsRow from 'pages/features/CardTransactionLimits/components/shared/CashWithdrawalLimitsRow'
import NonCashTransactionLimitsRow from 'pages/features/CardTransactionLimits/components/shared/NonCashTransactionLimitsRow'
import { CardTransactionLimitsResponse } from 'store/products/typings/cardLimits'
import { InfoPanel } from 'dls'
import { ProductKey } from 'store/products/types'
import Line from 'dls/atoms/Line'

interface OwnProps {
  cardTransactionLimits: CardTransactionLimitsResponse
  currencyCode: string
  productKey: ProductKey
}

const ViewCardTransactionLimits = ({
  cardTransactionLimits: { cardLimits },
  currencyCode,
  productKey,
}: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <Grid container direction="column" spacing={3} style={{ marginTop: 0, padding: '0 8px' }}>
      <Grid item>
        <InfoPanel
          color="info"
          body={t(
            'Please note – local card schemes/acquirers may have their own limits. These will be enforced outside of this banking platform.'
          )}
        />

        <Line type="light" customStyles={{ marginTop: 24, marginBottom: 16, width: '100%' }} />

        <CashWithdrawalLimitsRow
          cardTransactionLimits={cardLimits}
          currencyCode={currencyCode}
          hasSeparators={false}
          showActions={false}
          productKey={productKey}
        />

        <Line type="light" customStyles={{ marginTop: 24, marginBottom: 16, width: '100%' }} />

        <NonCashTransactionLimitsRow
          cardTransactions={cardLimits}
          currencyCode={currencyCode}
          hasSeparators={false}
          showActions={false}
          productKey={productKey}
        />
      </Grid>
    </Grid>
  )
}

export default ViewCardTransactionLimits
