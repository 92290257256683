import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Flexbox } from 'pages/Layout/Styled'
import { LinkWithText } from 'dls/molecules/Buttons'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  label: string
  href: string
  Icon: React.FunctionComponent<any>
}

const EntityCardFooterLink = ({ label, href, Icon }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Flexbox direction="row" alignItems="center" justifyContent="flex-end" pt={10}>
      <LinkWithText
        component={RouterLink}
        disableElevation
        disableFocusRipple
        disableRipple
        color={ColorType.SECONDARY}
        endIcon={<Icon color="textSecondary" />}
        style={{
          backgroundColor: 'transparent',
          fontSize: 'inherits',
          margin: '0 3px',
          padding: 0,
        }}
        variant="text"
        to={href}
      >
        <Box style={{ textTransform: 'uppercase' }}>{t(label)}</Box>
      </LinkWithText>
    </Flexbox>
  )
}

export default EntityCardFooterLink
