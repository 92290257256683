import { ExtendedProduct, LendingRateType } from './types'

export const transformProduct = (product: ExtendedProduct) => {
  const res = product

  if (res.debitInterest) {
    Object.values(LendingRateType).forEach((rate) => {
      if (res?.debitInterest?.[rate]) {
        ;(res.debitInterest[rate] as any).type = rate
      }
    })
  }

  return res
}
