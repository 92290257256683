import styled from 'styled-components/macro'
import { Card, Grid } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Button } from 'dls/atoms/Button'

export const StyledCard = styled(Card)<{ $isSelected: boolean }>`
  border: ${(props: any) =>
    props.$isSelected ? `solid 2px ${props.theme.palette.secondary.main}` : 'solid 2px transparent'};
`

export const StyledGrid = styled(Grid)<{ isCondensed: boolean }>`
  height: ${(props: any) => (props.isCondensed ? `260px` : '300px')};
  padding: 0 10px;
`

export const CardImage = styled.img`
  width: 274px;
  min-height: 100px;
`

export const HoverEditIcon = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  justify-content: start;
  align-items: top;
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`

export const IconedButtons = styled(Button)`
  color: ${grey[600]};
  font-size: 14px;
`

export const CardWithActionsDiv = styled.div`
  display: flex;
`
