import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { buildDebitInterestCommonConfigFeatureDefinitions } from '../../utils/transformers'
import EditDeleteCardButtonGroup from 'pages/Layout/EditDeleteCardButtonGroup'
import { DebitInterestCommonConfig } from 'store/products/types'
import { FeatureDefinitions, Paper, H5 } from 'dls'

interface Props {
  description: string
  commonConfig: DebitInterestCommonConfig
  title?: string
}

const CommonConfigPaper = ({ description, commonConfig, title }: Props): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Paper>
      <Box mb={3}>{title && <H5 variantMapping={{ h5: 'h2' }}>{t(title)}</H5>}</Box>
      <FeatureDefinitions
        definitions={buildDebitInterestCommonConfigFeatureDefinitions({
          commonConfig,
          description,
          t,
          bottomSeparator: false,
        })}
      />
      <EditDeleteCardButtonGroup showEditIcon editPath="edit/common" />
    </Paper>
  )
}

export default CommonConfigPaper
