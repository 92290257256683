import { v4 } from 'uuid'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { FeaturePostResponse, ProductKey } from 'store/products/types'
import { PaymentLimit, PaymentLimitsConfig } from 'store/products/typings/paymentLimits'

async function setPaymentLimit(productKey: ProductKey, version: number, payload: PaymentLimit) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).post<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/paymentLimits/v1`,
    payload,
    {
      headers: {
        'Idempotency-Key': v4(), // @INFO: We use a new UUID always, so they can create a limit with the same amout several times
      },
    }
  )
  return data
}

async function setPaymentLimitsConfig(productKey: ProductKey, version: number, config: PaymentLimitsConfig) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).put<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/paymentLimits/v1/config`,
    config
  )
  return data
}

async function updatePaymentLimit(productKey: ProductKey, version: number, payload: PaymentLimit) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).patch<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/paymentLimits/v1/${payload.limitRuleId}`,
    {
      amount: { minimum: payload?.amount?.minimum, maximum: payload?.amount?.maximum },
      enableSubscriptionOverride: payload?.enableSubscriptionOverride,
    }
  )
  return data
}

async function fetchPaymentLimits(productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).get(
    `/v1/products/${productKey}/versions/${version || 1}/features/paymentLimits/v1`
  )
  return data
}

async function deletePaymentLimit(limitRuleId: string, productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).delete(
    `/v1/products/${productKey}/versions/${version || 1}/features/paymentLimits/v1/${limitRuleId}`
  )
  return data
}

export default {
  setPaymentLimitsConfig,
  setPaymentLimit,
  updatePaymentLimit,
  fetchPaymentLimits,
  deletePaymentLimit,
}
