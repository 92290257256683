import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { CardTransactionLimit } from 'store/products/typings/cardLimits'
import { ReviewSubLabel } from 'dls'

interface OwnProps {
  labelKey: string
  cardLimit?: CardTransactionLimit
  currencySymbol: string
  noValueContent?: React.ReactNode
}

const DefaultLimitSubValue = ({ labelKey, cardLimit, currencySymbol, noValueContent }: OwnProps) => {
  const { t } = useTranslation()
  if (!cardLimit) {
    return null
  }

  return (
    <ReviewSubLabel
      label={t(labelKey)}
      buttonComponentType={RouterLink}
      noValueContent={noValueContent}
      value={
        cardLimit?.amount?.maximum ? (
          <>
            {currencySymbol}
            {new Intl.NumberFormat('en-GB').format(cardLimit.amount.maximum)}
          </>
        ) : null
      }
    />
  )
}

export default DefaultLimitSubValue
