import React from 'react'
import { DialogActions, Grid, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { Field, Form, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { withFormikSimple, Yip } from 'utils/form.utils'
import { ContextualHelp, ModalWrapper, SubmitButton, TextFieldComplex } from 'components'
import { PackageFormValues, PackageType } from 'store/packages/types'
import SelectFieldComplex, { SelectFieldData } from 'components/form/SelectFieldComplex'
import { CountryItem } from 'store/utils/types'
import countriesJson from 'static/countries.json'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const StyledDialogActions = styled(DialogActions)`
  padding-top: 20px;
  padding-right 0px;
`
const TooltipStyle = styled.div`
  margin-top: 16px;
`

interface OwnProps {
  onPressCancel: () => void
}

const selectDataCountriesJson: SelectFieldData = {}
Object.entries(countriesJson).forEach(([, item]: [string, CountryItem]) => {
  selectDataCountriesJson[`${item.alpha3Code}`] = {
    name: item.alpha3Code,
    label: item.name,
    flag: item.alpha2Code,
  }
})

const selectDataPackageType: SelectFieldData = {}
Object.entries(PackageType).forEach(([key, value]) => {
  selectDataPackageType[key] = { name: value }
})

interface PackageFormProps extends FormikProps<PackageFormValues>, OwnProps {}

const CreatePackageDialogForm = ({ onPressCancel, dirty }: PackageFormProps) => {
  const { t } = useTranslation()
  return (
    <Form>
      <ModalWrapper
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography variant="h6" gutterBottom>
            {t('Create package')}
          </Typography>
        }
      >
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Field
              name="productPackageName"
              type="name"
              label={t('Package name')}
              validate={Yip(Yup.string().required())}
              required
              fullWidth
              component={TextFieldComplex}
              margin="none"
              css="margin: 0.2em 0"
              inputProps={{
                'data-test-id': 'package-name-field',
              }}
              inputLabelProps={{
                shrink: true,
              }}
              placeholder={t('Enter name')}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              required
              name="distribution.packageType"
              label={t('Package type')}
              data={selectDataPackageType}
              placeholder={t('Please select')}
              component={SelectFieldComplex}
              selectProps={{ displayEmpty: true }}
              inputLabelProps={{ shrink: true }}
              data-test-id="package-type-field"
              validate={Yip(Yup.string().required())}
            />
          </Grid>
          <Grid item xs={6}>
            <TooltipStyle>
              <ContextualHelp
                title={t(
                  `The package type is used by the mobile SDK and will determine where the products within the package are shown to the customer`
                )}
              />
            </TooltipStyle>
          </Grid>
          <Grid xs={6} item>
            <Field
              name="distribution.countryOfResidence"
              label={t('Country of residence')}
              data={selectDataCountriesJson}
              placeholder={t('Please select')}
              component={SelectFieldComplex}
              selectProps={{ displayEmpty: true }}
              inputLabelProps={{ shrink: true }}
              data-test-id="country-of-residence-field"
              hasFlag
            />
          </Grid>
          <Grid item xs={6}>
            <TooltipStyle>
              <ContextualHelp
                title={t(
                  `The country of residence is optional. It can be used to only offer the products to customers of this country of residence`
                )}
              />
            </TooltipStyle>
          </Grid>
          <Grid xs={11} item>
            <Field
              name="distribution.brandId"
              label={t('Custom attribute')}
              component={TextFieldComplex}
              fullWidth
              inputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                'data-test-id': 'custom-attribute-field',
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <TooltipStyle css="margin-left:10px">
              <ContextualHelp
                title={t(
                  `A custom attribute can be added and used by your  developers to determine who can apply or at what point they can apply`
                )}
              />
            </TooltipStyle>
          </Grid>
        </Grid>
        <StyledDialogActions>
          <Button color={ColorType.BUTTON} type="button" onClick={onPressCancel}>
            {t('Cancel')}
          </Button>
          <SubmitButton
            disabled={!dirty}
            css="margin-left:16px !important"
            type="submit"
            color={ColorType.BUTTON}
            variant="contained"
            data-test-id="package-custom-create-button"
            aria-controls="main"
          >
            {t('Next')}
          </SubmitButton>
        </StyledDialogActions>
      </ModalWrapper>
    </Form>
  )
}

export default withFormikSimple<OwnProps, PackageFormValues>(CreatePackageDialogForm)
