import React from 'react'
import {
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Fade,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import downloadTemplatePdf from '../utils/downloadTemplatePdf'
import useTemplateImage from '../utils/useTemplateImage'
import { StatementNoPreview } from 'assets'
import { ProductSegment, StatementTemplate, StatementType } from 'store/products/types'
import { CloseButton, DownloadIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface OwnProps {
  title: string
  open: boolean
  onClose: VoidFunction
  template?: StatementTemplate
  productSegment: ProductSegment
  templateType: StatementType
  id?: string
}

const StyledGrid = styled(Grid)`
  overflow: scroll;
  .MuiCard-root {
    width: 100%;
    max-width: 658px;
    margin: 2px;
    min-height: 50vh;
    display: flex;
    align-items: center;
  }
  img {
    max-width: 658px;
  }
`

const Content = styled(DialogContent)`
  padding: 0 24px 24px;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperFullWidth {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
`

const ImageDialog = ({ title, open, onClose, productSegment, templateType, template, id }: OwnProps) => {
  const { t } = useTranslation()
  if (!template) {
    return null
  }

  const { src, error } = useTemplateImage(productSegment, templateType, template?.id)

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      onClose={onClose}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="xl"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignContent="flex-end"
        css={`
          padding: 22px 22px 14px;
          align-items: flex-end;
        `}
      >
        <Grid item>
          <Typography variant="h6" id={id}>
            {t(title)}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title={`${t('Download PDF')}`} placement="left">
            <DownloadIconButton
              aria-label={t('Download statement as a PDF')}
              onClick={() => downloadTemplatePdf(productSegment, templateType, template)}
              css={`
                margin: -10px -20px;
                color: ${(props: any) => `${props.theme.palette.text.primary}`};
                float: left;
              `}
            />
          </Tooltip>
          <CloseButton
            onClick={onClose}
            aria-label={t('Close')}
            css={`
              float: right;
              margin: -12px -12px -12px 12px;
              color: ${(props: any) => `${props.theme.palette.text.primary}`};
            `}
          />
        </Grid>
      </Grid>
      <Content>
        <StyledGrid container direction="row" justifyContent="center" alignItems="center">
          {src || error ? (
            <Card>
              <img
                width="100%"
                src={error && !src ? StatementNoPreview : src}
                alt={error && !src ? 'No preview available' : template.name}
              />
            </Card>
          ) : (
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh',
              }}
            >
              <CircularProgress size={75} />
            </div>
          )}
        </StyledGrid>
      </Content>
    </StyledDialog>
  )
}
export default React.memo(ImageDialog)
