import createAsyncAuthAwareAxios from './client/authAwareAxios'
import {
  AvailableProducts,
  PackageCreationOrUpdateRequestPayload,
  Packages,
  PackagesWithProducts,
  PackagesWithProductsFormValue,
  ProductPackageId,
  PublishPackageRequest,
} from 'store/packages/types'
import { bankTzToIso } from 'utils/date.utils'

async function getPackages() {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<Packages>('/v1/packages', {
    headers: {
      Accept: 'application/vnd.10x.package.product.full.v1+json',
    },
    params: {
      sort: 'DESC',
    },
  })
  return data.packages
}

async function createPackage(payload: Partial<PackageCreationOrUpdateRequestPayload>) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<PackagesWithProducts>('/v1/packages', payload)
  return data
}

async function publishPackage(payload: PublishPackageRequest) {
  const { productPackageId, ...request } = payload
  const payloadData = {
    ...request,
    publicationDate: bankTzToIso(request.publicationDate),
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<PackagesWithProducts>(
    `/v1/packages/${productPackageId}/publish`,
    payloadData
  )
  return data
}

async function availableProducts() {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<AvailableProducts[]>(
    '/v1/packages/availableProducts'
  )
  return data
}

async function getPackageDetails(productPackageId: ProductPackageId) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<PackagesWithProducts>(
    `/v1/packages/${productPackageId}`
  )
  return data
}

async function updatePackage(payload: PackagesWithProductsFormValue) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<PackagesWithProductsFormValue>(
    `/v1/packages/${payload.productPackageId}`,
    payload
  )
  return data
}

async function deletePackage(productPackageId: ProductPackageId) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).delete<void>(`/v1/packages/${productPackageId}`)
  return data
}

export default {
  getPackages,
  createPackage,
  publishPackage,
  availableProducts,
  getPackageDetails,
  updatePackage,
  deletePackage,
}
