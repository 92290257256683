import {
  BuilderCondition,
  ConditionOperator,
  DataPointAggregatedOperator,
  DataPointOperator,
} from '../../typings/conditionBuilder'
import { FrequencyFormValues } from '../../typings/frequencySelector'

export enum FeeRewardDataPointType {
  BALANCE_END_OF_DAY_AMOUNT = 'BALANCE_END_OF_DAY_AMOUNT',
  BALANCE_UNIQUE_ENTRY = 'BALANCE_UNIQUE_ENTRY',
  TRANSACTION_AMOUNT = 'TRANSACTION_AMOUNT',
  TRANSACTION_ISO_CODE = 'TRANSACTION_ISO_CODE',
  TRANSACTION_IS_CREDIT = 'TRANSACTION_IS_CREDIT',
  TRANSACTION_UNIQUE_ENTRY = 'TRANSACTION_UNIQUE_ENTRY',
}

export interface Fees {
  fees: FeeReward[]
}

export interface Rewards {
  rewards: FeeReward[]
}

export interface FeeReward {
  id?: string
  name: string
  applicationFrequency: string
  rules: FeeRewardRule[]
  category?: string
}

export interface FeeRewardFormValues extends FrequencyFormValues {
  id?: string
  name: string
  amount: number
  dataValue?: number | null
  conditions?: BuilderCondition[]
}

export interface FeeRewardRule {
  description?: string
  id?: string
  conditionsOperator?: ConditionOperator
  conditions?: FeeRewardCondition[]
  consequences: FeeRewardConsequence[]
}

export interface ConditionFilter {
  description?: string
  datapoint: FeeRewardDataPointType
  operator: DataPointOperator
  value: string
}

export interface ConditionAggregateFilter {
  description?: string
  aggregateFunction: DataPointAggregatedOperator
  datapoint: FeeRewardDataPointType
  operator: DataPointOperator
  value: string
}

export interface FeeRewardCondition {
  description?: string
  filters?: ConditionFilter[]
  aggregateFilters?: ConditionAggregateFilter[]
}

export interface FeeRewardConsequence {
  description?: string
  name: FeeRewardConsequenceType
  value: string
}

export enum FeeRewardConsequenceType {
  CHARGE_FIXED_AMOUNT = 'CHARGE_FIXED_AMOUNT',
}
