import { call, put, delay } from 'redux-saga/effects'
import i18next from 'i18next'
import { showPendingActionModalSaga } from '../../../modal/sagas'
import { navigate } from 'store/router/actions'
import ModalActions from 'store/modal/actions'
import FeeRewardActions from 'store/products/features/feesRewards/actions'
import ProductActions from 'store/products/actions'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import { FeesRewardsApi } from 'api'
import { Feature } from 'store/products/types'

export function* setFeeRewardSaga(action: ReturnType<typeof FeeRewardActions.setFeeReward>) {
  const t = i18next.t.bind(i18next)
  try {
    const { productKey, version, featureType, ...entity } = action.payload
    yield put(ModalActions.showFeatureProgressModal())

    if (entity.id) {
      yield call(FeesRewardsApi.updateFeeReward, productKey, version, entity, featureType)
    } else {
      yield call(FeesRewardsApi.createFeeReward, productKey, version, entity, featureType)
    }
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showModal({
        modalType: ModalType.SUCCESS,
        title: t('{{name}} successfully submitted', { name: entity.name }),
      })
    )
    yield put(navigate(`/products/${productKey}/features/${featureType}`))
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* removeFeeRewardSaga(action: ReturnType<typeof FeeRewardActions.removeFeeReward>) {
  try {
    const {
      payload: { productKey, version, name, id, isLast, featureType },
    } = action

    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: `You're deleting ${name}`,
      message: isLast
        ? `Because this is the only ${
            featureType === Feature.FEES ? 'fee' : 'reward'
          } it will mean the feature will also be deleted. Are you sure? This can’t be undone and you’d need to add the feature again.`
        : "Are you sure? This can't be undone.",
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())
      yield put(ProductActions.getProductDetails({ productKey }))
      yield call(FeesRewardsApi.deleteFeeReward, productKey, version, id, featureType)
      yield put(navigate(`/products/${productKey}/features/${featureType}`))

      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: `${featureType === Feature.FEES ? 'Fee' : 'Reward'} successfully deleted`,
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}
