export interface NotificationState {
  data: Notification
}

export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface Notification {
  notificationType: NotificationTypes
  open: boolean
  notificationMessage: string
  isMultipleMessage: boolean
  notification: MultipleNotification
}

export interface TaskSuccessFailMessage {
  taskName: string
  assigneeName: string
}

export interface MultipleNotification {
  key?: number
  message: string
  type: NotificationTypes
}
