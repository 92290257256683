import React from 'react'
import styled from 'styled-components/macro'
import SwitchMui, { SwitchProps } from '@material-ui/core/Switch'

const StyledSwitchMui = styled(SwitchMui)`
  & .Mui-disabled {
    color: ${(props: any) => `${props.theme.palette.text.disabled}`};
  }
`
export interface AToggleProps extends SwitchProps {}

const AToggle = (props: AToggleProps) => <StyledSwitchMui data-dls="atom-toggle" color="secondary" {...props} />

export default AToggle
