import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams, useNavigate } from 'react-router-dom'
import { FeatureFormPageProps } from '../FeaturePage.page'
import SubscriptionCreationRulesForm from './SubscriptionCreationRulesForm'
import { toSubscriptionFormValues } from './subscriptionTransformers'
import { SubcriptionCreationPayload, Feature } from 'store/products/types'
import ProductActions from 'store/products/actions'
import { RootState } from 'store/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import { Loader } from 'components'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSettings: (productKey: string) => (payload: SubcriptionCreationPayload) =>
    dispatch(
      ProductActions.setSubscriptionCreationRule({
        productKey,
        ...payload,
      })
    ),
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const SubscriptionCreationRulePage = (props: Props) => {
  const { updateSettings } = props
  const { productKey = '' } = useParams()

  const [product, isLoading] = useSelectedProduct(productKey)
  const navigate = useNavigate()

  useUpdateBreadcrumbs({ productKey, context: Feature.SUBSCRIPTIONCREATIONRULE })

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.SUBSCRIPTIONCREATIONRULE)) navigate(`./../not-allowed`)

  if (isLoading) {
    return <Loader />
  }
  return product ? (
    <SubscriptionCreationRulesForm
      onSubmit={updateSettings(productKey)}
      productKey={productKey}
      initialValues={{ ...toSubscriptionFormValues(product) }}
    />
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCreationRulePage)
