import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { FastField } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { cardImages, ImageStyle, StyledDone, StyledSpan, TooltipStyle } from './Styled'
import { ContextualHelp, H5, SelectFieldComplex, TextFieldComplex } from 'components'
import { Yip } from 'utils/form.utils'
import { CardScheme } from 'store/products/types'
import SelectImageCard from 'pages/features/Cards/SelectImageCard'
import { SelectFieldData } from 'components/form/SelectFieldComplex'
import { InfoPanel } from 'dls'

const fakeTranslateOptions = { nsSeparator: '||' }

const SchemeItem = ({ value }: { value: any }) => {
  const { t } = useTranslation()

  return (
    <>
      <ImageStyle src={cardImages[value]} height={20} alt="card" />
      <StyledSpan>{t(value, fakeTranslateOptions)}</StyledSpan>
    </>
  )
}

const selectFeeOutputName: SelectFieldData = {}
Object.entries(CardScheme).forEach(([key, value]) => {
  selectFeeOutputName[key] = {
    name: value,
    generatedItem: <SchemeItem value={value} />,
  }
})

const CardsElement = ({ name }: { name: string }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="flex-start">
      <Grid item xs={12}>
        <H5 style={{ marginBottom: 0 }}>{t('What the customer will see in their channel')}</H5>
      </Grid>
      <Grid item xs={12}>
        <FastField
          name={`${name}.name`}
          required
          label={t('Card name')}
          type="text"
          component={TextFieldComplex}
          inputLabelProps={{ shrink: true }}
          inputProps={{
            'data-test-id': 'card-item-name',
          }}
          fullWidth
          placeholder={t('Enter name')}
          helperText={t(
            'Card name – what the customer will see in their channel e.g. Platinum card (max 255 characters)'
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FastField
          name={`${name}.description`}
          label={t('Card description for customer')}
          type="text"
          validate={Yip(Yup.string().typeError(t('This is mandatory field')).required())}
          component={TextFieldComplex}
          inputLabelProps={{ shrink: true }}
          inputProps={{
            'data-test-id': 'card-item-description',
          }}
          fullWidth
          required
          multiline
          placeholder={t('Enter description')}
        />
      </Grid>
      <Grid item xs={12}>
        <FastField
          name={`${name}.frontTemplateImage`}
          component={SelectImageCard}
          inputProps={{
            'data-test-id': 'card-item-frontTemplateImage',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ marginBottom: 10, marginTop: 15 }}>
          {t('Card provisioning configuration')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FastField
          name={`${name}.scheme`}
          label={t('Scheme')}
          component={SelectFieldComplex}
          selectProps={{ displayEmpty: true }}
          inputLabelProps={{ shrink: true }}
          inputProps={{
            'data-test-id': 'card-item-scheme',
          }}
          data={selectFeeOutputName}
          validate={Yip(Yup.string().typeError(t('This is mandatory field')).required())}
          fullWidth
          required
          placeholder={t('Please select')}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item md={4} sm={6} xs={6} style={{ whiteSpace: 'nowrap' }}>
        <FastField
          name={`${name}.maximumNumber`}
          label={t('Maximum number of cards')}
          type="number"
          numberFormatProps={{
            decimalSeparator: false,
          }}
          required
          inputLabelProps={{ shrink: true }}
          inputProps={{
            'data-test-id': 'card-item-maximumNumber',
          }}
          component={TextFieldComplex}
          validate={Yip(Yup.number().typeError(t('This is mandatory field')).required().moreThan(0).lessThan(100))}
          formControlProps={{
            style: {
              width: 'calc(100% - 50px)',
            },
          }}
          placeholder={t('Enter number')}
        />
        <TooltipStyle>
          <ContextualHelp
            title={t(
              'This is the maximum number of card instances that a customer can hold. The platform maximum is 99.'
            )}
          />
        </TooltipStyle>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12} style={{ whiteSpace: 'nowrap' }}>
        <FastField
          name={`${name}.processorId`}
          required
          validate={Yip(Yup.string().typeError(t('This is mandatory field')).required().max(255))}
          label={t('Processor product ID')}
          type="text"
          component={TextFieldComplex}
          inputLabelProps={{ shrink: true }}
          inputProps={{
            'data-test-id': 'card-item-processorId',
          }}
          fullWidth
          placeholder={t('Please enter')}
          formControlProps={{
            style: {
              width: 'calc(100% - 50px)',
            },
          }}
          helperText={t('E.g. 901 – ID corresponding with the product within the integrated card processor')}
        />
        <TooltipStyle>
          <ContextualHelp
            title={t(
              'Card processor specific value which corresponds to the product information required to be passed to the card fulfilment vendor e.g. IDEMIA'
            )}
          />
        </TooltipStyle>
      </Grid>
      <Grid item xs={12} style={{ whiteSpace: 'nowrap' }}>
        <FastField
          name={`${name}.processorLogoId`}
          required
          validate={Yip(Yup.string().typeError(t('This is mandatory field')).required().max(255))}
          label={t('Processor logo ID')}
          type="text"
          component={TextFieldComplex}
          inputLabelProps={{ shrink: true }}
          inputProps={{
            'data-test-id': 'card-item-processorLogoId',
          }}
          fullWidth
          placeholder={t('Please enter')}
          formControlProps={{
            style: {
              width: 'calc(100% - 50px)',
            },
          }}
          helperText={t('E.g. MCRDDBT – identifying the corresponding logo within the integrated card processor ')}
        />
        <TooltipStyle>
          <ContextualHelp
            title={t(
              'Card processor specific value which corresponds to the personalisation of the card linked to the product, which is in turn passed to the card fulfilment vendor e.g. IDEMIA'
            )}
          />
        </TooltipStyle>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ marginTop: 10 }}>
          {t('How the card can be used')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <InfoPanel title={t('You can’t currently change how the card is used in Bank Manager')} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20, marginTop: -10 }}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item>
            <StyledDone style={{ marginRight: 8 }} />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {t(
                'ATM, Chip & PIN, CNP (card not present – online, mobile, phone), Contactless, International, MagStripe'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CardsElement
