import React from 'react'
import { FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useField } from 'formik'
import { FieldType, operators, RuleType } from '../../../hooks/constants'
import useCreateRuleField from '../../../hooks/useCreateRuleField'
import { StyledSelect } from '../../../styled'
import TaxRuleValueField from './TaxRuleValueField'
import { LightThickDivider } from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import { selectTenantCurrencyCode } from 'store/tenant-config/selectors'
import { RemoveCircleButton } from 'dls/molecules/IconButtons/IconButtons'
import { WithholdingTaxFactName } from 'store/withholdingTax/types'

interface Props {
  name: string
  onRemove: (index: number) => void
  hasMultipleRows: boolean
  ruleTypesUsed: WithholdingTaxFactName[]
  index: number
}

const CreateTaxRuleField = ({ name, onRemove, hasMultipleRows, ruleTypesUsed, index }: Props) => {
  const { t } = useTranslation()
  const nameField = useField(`${name}.name`)
  const operatorField = useField(`${name}.operator`)
  const valueField = useField(`${name}.value`)

  const createRuleField = useCreateRuleField(nameField, operatorField, valueField)
  const currencyCode = useSelector(selectTenantCurrencyCode)
  const selectedRuleType = RuleType[createRuleField.ruleType]
  const canRemove = createRuleField.ruleOperator && createRuleField.ruleValue
  const onRemoveClick = () => {
    if (hasMultipleRows) {
      onRemove(index)
    } else {
      createRuleField.onFieldReset()
    }
  }

  return (
    <>
      <Grid container direction="row" spacing={2} style={{ marginBottom: canRemove ? '14px' : '8px' }}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel shrink id="select-rule-type">
              {index === 0 ? t('If *') : t('And if *')}
            </InputLabel>
            <StyledSelect
              id="select-rule-type"
              data-test-id="select-rule-type-dropdown"
              value={createRuleField.ruleType}
              onChange={createRuleField.onRuleTypeChange}
              hasPlaceholder={!createRuleField.ruleType}
              displayEmpty
              fullWidth
              required
            >
              <MenuItem value="" disabled>
                {t('Please select')}
              </MenuItem>
              {Object.keys(RuleType)
                .filter((key: any) => {
                  // PROJECTED_ANNUAL_INCOME and INTEREST_EARNED_IN_A_PERIOD are mutually exclusive
                  if (
                    (key === RuleType.PROJECTED_ANNUAL_INCOME.value &&
                      ruleTypesUsed.indexOf(RuleType.INTEREST_EARNED_IN_A_PERIOD.value as WithholdingTaxFactName) >
                        -1) ||
                    (key === RuleType.INTEREST_EARNED_IN_A_PERIOD.value &&
                      ruleTypesUsed.indexOf(RuleType.PROJECTED_ANNUAL_INCOME.value as WithholdingTaxFactName) > -1)
                  ) {
                    return false
                  }
                  return key === createRuleField.ruleType || ruleTypesUsed.indexOf(key) === -1
                })
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {RuleType[key].label}
                  </MenuItem>
                ))}
            </StyledSelect>
          </FormControl>
        </Grid>
        {createRuleField.ruleType !== '' && (
          <>
            {selectedRuleType.type !== FieldType.TIN && (
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel shrink id="select-operator">
                    {t('Is *')}
                  </InputLabel>
                  <StyledSelect
                    id="select-operator"
                    data-test-id="select-operator-dropdown"
                    value={createRuleField.ruleOperator}
                    onChange={createRuleField.onRuleOperatorChange}
                    hasPlaceholder={!createRuleField.ruleOperator}
                    displayEmpty
                    required
                  >
                    <MenuItem value="" disabled>
                      {t('Please select')}
                    </MenuItem>
                    {selectedRuleType &&
                      selectedRuleType.operators.map((key: number) => (
                        <MenuItem key={key} value={key}>
                          {operators[key]}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                </FormControl>
              </Grid>
            )}
            {createRuleField.ruleOperator && (
              <Grid item xs={createRuleField.ruleValueFieldSize}>
                <TaxRuleValueField
                  type={selectedRuleType.type}
                  operator={createRuleField.ruleOperator}
                  onChange={createRuleField.onRuleValueChange}
                  value={createRuleField.ruleValue}
                  currencyCode={currencyCode}
                  error={!!valueField[1].error}
                  helperText={valueField[1].error || ''}
                />
              </Grid>
            )}
          </>
        )}
        {index === 0 ? (
          canRemove && (
            <Grid item xs={1}>
              <RemoveCircleButton
                aria-label={t('Remove rule')}
                onClick={onRemoveClick}
                style={{
                  marginTop: createRuleField.ruleValueFieldSize > 4 ? '20px' : '6px',
                  alignItems: 'flex-end',
                  paddingBottom: '9px',
                }}
              />
            </Grid>
          )
        ) : (
          <Grid item xs={1}>
            <RemoveCircleButton
              onClick={onRemoveClick}
              style={{
                marginTop: createRuleField.ruleValueFieldSize > 4 ? '20px' : '6px',
                alignItems: 'flex-end',
                paddingBottom: '9px',
              }}
            />
          </Grid>
        )}
      </Grid>
      {hasMultipleRows && (
        <Grid item xs={11}>
          <LightThickDivider />
        </Grid>
      )}
    </>
  )
}

export default CreateTaxRuleField
