import React from 'react'
import { Tooltip, List, ListItem, ListItemText } from '@material-ui/core'
import styled from 'styled-components/macro'
import { InfoOutlined } from '@material-ui/icons/'
import { TFunction } from 'i18next'
import { INITIAL_FILTERS } from '../utils'
import { toBankTz } from 'utils/date.utils'
import { TableCommonHeaderType } from 'components/TableCommon/types'
import { SuspenseTransactionItem, CreditOrDebit, TransactionStatus } from 'store/accounts/types'
import { getFormattedCurrency } from 'utils/ui.utils'
import {
  Pending,
  Cleared,
  ContraCleared,
  ContraTransfer,
  PartiallyCleared,
  PendingAndPartialImg,
  AllStatusesImg,
  ContraReversedIcon,
} from 'assets'
import { getCompactTransactionId } from 'api/utils'
import { stopPropagation } from 'utils/form.utils'
import { FilterMenu } from 'components'
import { FilterOptionsProps } from 'components/FilterMenu'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { KeyboardArrowDownButton, KeyboardArrowUpButton, RefreshButton } from 'dls/molecules/IconButtons/IconButtons'

export interface Filters {
  timestamp?: number
  amount?: number
  credit?: number
  status?: number
}

const StyledList = styled(List)`
  margin-left: -16px;
`

const StyledImg = styled.img`
  margin-right: 8px;
  vertical-align: middle;
`

const postedTimestampToDate = (timestamp: string) => toBankTz(timestamp).date

export const amountFilterOptions = (accountsCurrency: string): FilterOptionsProps[] => [
  { label: `All amounts`, from: undefined, to: undefined },
  {
    label: `Up to ${getFormattedCurrency(20, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 0.01,
    to: 20,
  },
  {
    label: `Up to ${getFormattedCurrency(50, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 0.01,
    to: 50,
  },
  {
    label: `Up to ${getFormattedCurrency(100, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 0.01,
    to: 100,
  },
  {
    label: `Up to ${getFormattedCurrency(200, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 0.01,
    to: 200,
  },
  {
    label: `Up to ${getFormattedCurrency(500, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 0.01,
    to: 500,
  },
  {
    label: `Up to ${getFormattedCurrency(1000, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 0.01,
    to: 1000,
  },
  {
    label: `Up to ${getFormattedCurrency(5000, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 0.01,
    to: 5000,
  },
  {
    label: `Over ${getFormattedCurrency(5000, accountsCurrency, true, { minimumFractionDigits: 0 })}`,
    from: 5000.01,
    to: undefined,
  },
]

export const postedTimeStampFilterOptions: FilterOptionsProps[] = [
  { label: 'All dates', from: undefined, to: undefined },
  { label: 'Over 90 days', from: undefined, to: -90 },
  { label: 'Over 60 days', from: undefined, to: -60 },
  { label: 'Over 30 days', from: undefined, to: -30 },
  { label: 'Over 7 days', from: undefined, to: -7 },
  { label: 'Up to 7 days', from: -6, to: 0 },
]

export const isCreditFilterOptions: FilterOptionsProps[] = [
  { label: 'Credits & debits', is: undefined, data: undefined },
  { label: 'Only Credits', is: true, data: CreditOrDebit.CREDIT },
  { label: 'Only Debits', is: false, data: CreditOrDebit.DEBIT },
]
export const statusFilterOptions = (t: TFunction): FilterOptionsProps[] => [
  {
    label: () => (
      <span>
        <StyledImg src={AllStatusesImg} alt="icon_pending" aria-hidden="true" />
        {t('All statuses')}
      </span>
    ),
    status: undefined,
  },
  {
    label: () => (
      <span>
        <StyledImg src={PendingAndPartialImg} alt="icon_pending" aria-hidden="true" />
        {t('Pending & partially cleared')}
      </span>
    ),
    status: [TransactionStatus.PENDING, TransactionStatus.PARTIALLY_CLEARED],
  },
  {
    label: () => (
      <span>
        <StyledImg src={Pending} alt="icon_pending" aria-hidden="true" />
        {t(TransactionStatus.PENDING)}
      </span>
    ),
    status: [TransactionStatus.PENDING],
  },
  {
    label: () => (
      <span>
        <StyledImg src={PartiallyCleared} alt="icon_partially cleared" aria-hidden="true" />
        {t(TransactionStatus.PARTIALLY_CLEARED)}
      </span>
    ),
    status: [TransactionStatus.PARTIALLY_CLEARED],
  },
  {
    label: () => (
      <span>
        <StyledImg src={ContraCleared} alt="icon_contra write off" aria-hidden="true" />
        {t(TransactionStatus.CONTRA_WRITE_OFF)}
      </span>
    ),
    status: [TransactionStatus.CONTRA_WRITE_OFF],
  },
  {
    label: () => (
      <span>
        <StyledImg src={ContraReversedIcon} alt="icon_contra write off" aria-hidden="true" />
        {t(TransactionStatus.CONTRA_WRITE_OFF_REVERSED)}
      </span>
    ),
    status: [TransactionStatus.CONTRA_WRITE_OFF_REVERSED],
  },
  {
    label: () => (
      <span>
        <StyledImg src={ContraReversedIcon} alt="icon_contra write off" aria-hidden="true" />
        {t(TransactionStatus.CONTRA_WRITE_BACK_REVERSED)}
      </span>
    ),
    status: [TransactionStatus.CONTRA_WRITE_BACK_REVERSED],
  },
  {
    label: () => (
      <span>
        <StyledImg src={ContraTransfer} alt="icon_contra write back" aria-hidden="true" />
        {t(TransactionStatus.CONTRA_WRITE_BACK)}
      </span>
    ),
    status: [TransactionStatus.CONTRA_WRITE_BACK],
  },
  {
    label: () => (
      <span>
        <StyledImg src={ContraTransfer} alt="icon_contra transfer" aria-hidden="true" />
        {t(TransactionStatus.CONTRA_TRANSFER)}
      </span>
    ),
    status: [TransactionStatus.CONTRA_TRANSFER],
  },
  {
    label: () => (
      <span>
        <StyledImg src={ContraCleared} alt="icon_contra cleared" aria-hidden="true" />
        {t(TransactionStatus.CONTRA_CLEARED)}
      </span>
    ),
    status: [TransactionStatus.CONTRA_CLEARED],
  },
  {
    label: () => (
      <span>
        <StyledImg src={Cleared} alt="icon_cleared" aria-hidden="true" />
        {t(TransactionStatus.CLEARED)}
      </span>
    ),
    status: [TransactionStatus.CLEARED],
  },
]

export const getTransactionStatus = (transactionStatus: TransactionStatus, t: TFunction) => {
  switch (transactionStatus) {
    case TransactionStatus.PENDING:
      return (
        <span>
          <StyledImg src={Pending} alt="icon_pending" aria-hidden="true" />
          {t(TransactionStatus.PENDING)}
        </span>
      )
    case TransactionStatus.CLEARED:
      return (
        <span>
          <StyledImg src={Cleared} alt="icon_cleared" aria-hidden="true" />
          {t(TransactionStatus.CLEARED)}
        </span>
      )
    case TransactionStatus.PARTIALLY_CLEARED:
      return (
        <span>
          <StyledImg src={PartiallyCleared} alt="icon_partially cleared" aria-hidden="true" />
          {t(TransactionStatus.PARTIALLY_CLEARED)}
        </span>
      )
    case TransactionStatus.CONTRA_CLEARED:
      return (
        <span>
          <StyledImg src={ContraCleared} alt="icon_contra cleared" aria-hidden="true" />
          {t(TransactionStatus.CONTRA_CLEARED)}
        </span>
      )
    case TransactionStatus.CONTRA_TRANSFER:
      return (
        <span>
          <StyledImg src={ContraTransfer} alt="icon_contra transfer" aria-hidden="true" />
          {t(TransactionStatus.CONTRA_TRANSFER)}
        </span>
      )
    case TransactionStatus.CONTRA_WRITE_BACK:
      return (
        <span>
          <StyledImg src={ContraTransfer} alt="icon_contra write back" aria-hidden="true" />
          {t(TransactionStatus.CONTRA_WRITE_BACK)}
        </span>
      )
    case TransactionStatus.CONTRA_WRITE_OFF:
      return (
        <span>
          <StyledImg src={ContraCleared} alt="icon_contra write off" aria-hidden="true" />
          {t(TransactionStatus.CONTRA_WRITE_OFF)}
        </span>
      )
    case TransactionStatus.CONTRA_WRITE_BACK_REVERSED:
      return (
        <span>
          <StyledImg src={ContraReversedIcon} alt="icon_contra write off" aria-hidden="true" />
          {t(TransactionStatus.CONTRA_WRITE_BACK_REVERSED)}
        </span>
      )
    case TransactionStatus.CONTRA_WRITE_OFF_REVERSED:
      return (
        <span>
          <StyledImg src={ContraReversedIcon} alt="icon_contra write off" aria-hidden="true" />
          {t(TransactionStatus.CONTRA_WRITE_OFF_REVERSED)}
        </span>
      )
    default:
      return <span>N/A</span>
  }
}

export const getTransactionsRows = (
  t: TFunction,
  setFilters: (filters: Filters) => void,
  filters: Filters,
  isSearchResults: boolean,
  openInfoModal: () => void,
  accountsCurrency: string,
  reset: () => void
): TableCommonHeaderType[] => {
  const { showInfographicModal } = useModal()

  return [
    {
      id: 'transactionId',
      align: 'left',
      label: (
        <StyledList>
          <ListItem button aria-haspopup="true" aria-controls="filter-menu">
            <ListItemText
              primary={t('Reference')}
              style={{ whiteSpace: 'nowrap', cursor: 'default' }}
              primaryTypographyProps={{ style: { fontSize: 14 } }}
            />
          </ListItem>
        </StyledList>
      ),
      disableOrder: true,
      css: 'width: 20%; cursor: default;',
      getCellContent: (row) => {
        const { transactionId } = row as SuspenseTransactionItem
        return (
          transactionId && (
            <Tooltip title={transactionId} placement="right">
              <span>{getCompactTransactionId(transactionId)}</span>
            </Tooltip>
          )
        )
      },
    },
    {
      id: 'postedTimeStamp',
      align: 'left',
      label: isSearchResults ? (
        <StyledList>
          <ListItem button aria-haspopup="true" aria-controls="filter-menu">
            <ListItemText
              primary={t('All dates')}
              style={{ whiteSpace: 'nowrap' }}
              primaryTypographyProps={{ style: { fontSize: 14 } }}
            />
          </ListItem>
        </StyledList>
      ) : (
        <FilterMenu
          filter={filters.timestamp ? filters.timestamp : 0}
          setFilter={(index: number) =>
            setFilters({
              ...filters,
              timestamp: index,
            })
          }
          filterOptions={postedTimeStampFilterOptions}
        />
      ),
      disableOrder: true,
      css: 'width: 20%; cursor:pointer;',
      getCellContent: (row) => postedTimestampToDate((row as SuspenseTransactionItem).postedTimeStamp),
    },
    {
      id: 'transactionStatus',
      align: 'left',
      label: isSearchResults ? (
        <StyledList>
          <ListItem button aria-haspopup="true" aria-controls="filter-menu">
            <ListItemText
              primary={
                <span arial-label="Information about statuses">
                  {t('Status')}
                  <LinkWithText
                    aria-controls="more-about-dialog"
                    aria-haspopup="dialog"
                    onClick={(e: React.MouseEvent) => {
                      stopPropagation(e)
                      showInfographicModal(HelpModalType.STATUS_TABLE)
                    }}
                    endIcon={<InfoOutlined />}
                  />
                </span>
              }
              style={{ whiteSpace: 'nowrap' }}
              primaryTypographyProps={{ style: { fontSize: 14 } }}
            />
          </ListItem>
        </StyledList>
      ) : (
        <FilterMenu
          filter={filters.status ? filters.status : 0}
          setFilter={(index) => setFilters({ ...filters, status: index })}
          filterOptions={statusFilterOptions(t)}
          labelPostfix={
            <InfoIconButton
              key="more-about-icon"
              ariaProps={{
                'aria-label': `${t('More about suspense statuses')}`,
                'aria-haspopup': 'dialog',
                'aria-controls': 'more-about-dialog',
              }}
              style={{ backgroundColor: 'transparent' }}
              onClick={(e: React.MouseEvent) => {
                stopPropagation(e)
                showInfographicModal(HelpModalType.STATUS_TABLE)
              }}
              fontSize="medium"
            />
          }
        />
      ),
      css: 'width: 30%; cursor:pointer;',
      disableOrder: true,
      getCellContent: (row) => {
        const { transactionStatus } = row as SuspenseTransactionItem
        return getTransactionStatus(transactionStatus, t)
      },
    },
    {
      id: 'isCredit',
      align: 'left',
      disableOrder: true,
      label: isSearchResults ? (
        <StyledList>
          <ListItem button aria-haspopup="true" aria-controls="filter-menu">
            <ListItemText
              primary={t('Credits & debits')}
              style={{ whiteSpace: 'nowrap' }}
              primaryTypographyProps={{ style: { fontSize: 14 } }}
            />
          </ListItem>
        </StyledList>
      ) : (
        <FilterMenu
          filter={filters.credit ? filters.credit : 0}
          setFilter={(index) => setFilters({ ...filters, credit: index })}
          filterOptions={isCreditFilterOptions}
        />
      ),
      css: 'width: 20%; cursor:pointer;',
      getCellContent: (row) => {
        const { isCredit } = row as SuspenseTransactionItem
        return isCredit ? t('Credit') : t('Debit')
      },
    },
    {
      id: 'amount',
      align: 'left',
      label: isSearchResults ? (
        <StyledList>
          <ListItem button aria-haspopup="true" aria-controls="filter-menu">
            <ListItemText
              primary={t('All amounts')}
              style={{ whiteSpace: 'nowrap' }}
              primaryTypographyProps={{ style: { fontSize: 14 } }}
            />
          </ListItem>
        </StyledList>
      ) : (
        <FilterMenu
          filter={filters.amount ? filters.amount : 0}
          setFilter={(index) =>
            setFilters({
              ...filters,
              amount: index,
            })
          }
          filterOptions={amountFilterOptions(accountsCurrency)}
        />
      ),
      disableOrder: true,
      css: 'width: 20%; cursor:pointer;',
      getCellContent: (row) => {
        const { amount } = row as SuspenseTransactionItem
        return getFormattedCurrency(amount.amount, amount.currency)
      },
    },
    {
      id: 'reset',
      align: 'right',
      label:
        JSON.stringify(INITIAL_FILTERS) !== JSON.stringify(filters) ? (
          <RefreshButton aria-label="reset filters" size="small" onClick={reset} />
        ) : (
          ''
        ),
      disableOrder: true,
      css: 'width: 48px; cursor:pointer;',
      getCellContent: (_, settings) => {
        if (settings) {
          const { rowExpanded, setRowExpanded } = settings
          return rowExpanded ? (
            <KeyboardArrowUpButton aria-label="expand row" size="small" onClick={() => setRowExpanded(!rowExpanded)} />
          ) : (
            <KeyboardArrowDownButton
              aria-label="expand row"
              size="small"
              onClick={() => setRowExpanded(!rowExpanded)}
            />
          )
        }
        return null
      },
    },
  ]
}
