import React from 'react'
import { ArrowUpward } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  elementRef: React.MutableRefObject<HTMLSpanElement>
}

const TopLink = ({ elementRef }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Button
      component="a"
      href="#top"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault()
        elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        elementRef.current.focus()
      }}
      startIcon={<ArrowUpward />}
      aria-controls="main"
      color={ColorType.BUTTON}
    >
      {t('GO TO TOP')}
    </Button>
  )
}
export default TopLink
