import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import downloadTemplatePdf from '../utils/downloadTemplatePdf'
import useTemplateImage from '../utils/useTemplateImage'
import { FullScreenModalContainer, FullScreenModalContent } from 'dls/organisms/Modal/FullScreenModal'
import { StatementNoPreview } from 'assets'
import { ProductSegment, StatementTemplate, StatementType } from 'store/products/types'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'
import { ReviewRows } from 'dls'
import { generateMonth } from 'utils/form.utils'

interface OwnProps {
  title: string
  open: boolean
  onClose: VoidFunction
  productSegment: ProductSegment
  templateType: StatementType
  id?: string
  statementConfig: StatementConfiguration
  displayConfigInfo?: boolean
}

const ContentContainer = styled(Box)`
  flex-direction: column;
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
    }
    `}
`

const PreviewImg = styled.img<{ $displayConfigInfo?: boolean }>`
  ${({ theme, $displayConfigInfo }) => `
    border: 1px solid ${theme.palette.background.medium};

    ${theme.breakpoints.up('sm')} {
      max-width: ${$displayConfigInfo ? '444px' : '100%'};
      max-height: 80vh;
      width: ${$displayConfigInfo ? '50%' : 'auto'};
    }
    ${theme.breakpoints.up('md')} {
      width: auto;
    }
    ${theme.breakpoints.up('lg')} {
      max-width: 666px;
    }
  `}
`

const PreviewConfigurationDialog = ({
  title,
  open,
  onClose,
  productSegment,
  templateType,
  id,
  statementConfig,
  displayConfigInfo,
}: OwnProps) => {
  const { t } = useTranslation()
  if (!statementConfig.template) {
    return null
  }

  const { src, error } = useTemplateImage(productSegment, templateType, statementConfig.template?.id)

  return (
    <FullScreenModalContainer onClose={onClose} open={open} id={id || ''}>
      <FullScreenModalContent
        title={t(title)}
        onClose={onClose}
        download={{
          action: () =>
            downloadTemplatePdf(productSegment, templateType, statementConfig.template as StatementTemplate),
          ariaLabel: t('Download statement as a PDF'),
        }}
      >
        <ContentContainer display="flex" justifyContent="center" gridGap={24}>
          {src || error ? (
            <PreviewImg
              $displayConfigInfo={displayConfigInfo}
              src={error && !src ? StatementNoPreview : src}
              alt={error && !src ? 'No preview available' : statementConfig.template.name}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh',
              }}
            >
              <CircularProgress size={75} />
            </div>
          )}
          {displayConfigInfo && (
            <Box mt={3}>
              <ReviewRows
                definitionsList={[
                  {
                    groupSeparator: 'light',
                    definitions: [
                      { term: t('Statement name'), definitions: [statementConfig.name] },
                      { term: t('Description'), definitions: [statementConfig.statementDescription] },
                      { term: t('Segment'), definitions: [t(statementConfig.productSegment)] },
                    ],
                  },
                  {
                    definitions: [
                      {
                        term: t('Statement generation'),
                        definitions: [
                          `${t('PERIOD.YEAR')}: ${statementConfig.startDay} ${generateMonth(
                            statementConfig.startMonth || 1
                          )} `,
                        ],
                      },
                      {
                        term: t('Offset'),
                        definitions: [
                          `${t('Offset')}: ${t(`{{offset}} days`, {
                            offset: statementConfig.offsetDays,
                          })}
                          `,
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Box>
          )}
        </ContentContainer>
      </FullScreenModalContent>
    </FullScreenModalContainer>
  )
}
export default React.memo(PreviewConfigurationDialog)
