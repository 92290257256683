import { DataPickerRow } from 'dls/shared/types'

export const transactionCodes: DataPickerRow[] = [
  {
    code: 'PMNT',
    description: 'Payments',
    children: [
      {
        code: 'PMNT.ICDT',
        description: 'Issued Credit Transfers',
        children: [
          {
            code: 'PMNT.ICDT.DMCT',
            description: 'Domestic Credit Transfer',
          },
          {
            code: 'PMNT.ICDT.RRTN',
            description: 'Reversal Due To Payment Return',
          },
          {
            code: 'PMNT.ICDT.ESCT',
            description: 'SEPA Credit Transfer',
          },
        ],
      },
      {
        code: 'PMNT.IDDT',
        description: 'Issued Direct Debits',
        children: [
          {
            code: 'PMNT.IDDT.UPDD',
            description: 'Reversal Due To Return/Unpaid Direct Debit',
          },
          {
            code: 'PMNT.IDDT.AREV',
            description: 'ACH Reversal',
          },
          {
            code: 'PMNT.IDDT.PMDD',
            description: 'Direct Debit',
          },
        ],
      },
      {
        code: 'PMNT.IRCT',
        description: 'Issued Real-Time Credit Transfers',
        children: [
          {
            code: 'PMNT.IRCT.RRTN',
            description: 'Reversal Due To Payment Return',
          },
          {
            code: 'PMNT.IRCT.ESCT',
            description: 'SEPA Credit Transfer',
          },
        ],
      },
      {
        code: 'PMNT.RRCT',
        description: 'Received Real-Time Credit Transfers',
        children: [
          {
            code: 'PMNT.RRCT.BOOK',
            description: 'Internal Book Transfer',
          },
          {
            code: 'PMNT.RRCT.ESCT',
            description: 'SEPA Credit Transfer',
          },
          {
            code: 'PMNT.RRCT.RPCR',
            description: 'Reversal Due To Payment Cancellation Request',
          },
          {
            code: 'PMNT.RRCT.RRTN',
            description: 'Reversal Due To Payment Return',
          },
          {
            code: 'PMNT.RRCT.AREV',
            description: 'ACH Reversal',
          },
        ],
      },
      {
        code: 'PMNT.RCDT',
        description: 'Received Credit Transfers',
        children: [
          {
            code: 'PMNT.RCDT.ESCT',
            description: 'SEPA Credit Transfer',
          },
          {
            code: 'PMNT.RCDT.DMCT',
            description: 'Domestic Credit Transfer',
          },
          {
            code: 'PMNT.RCDT.BOOK',
            description: 'Internal Book Transfer',
          },
          {
            code: 'PMNT.RCDT.RPCR',
            description: 'Reversal Due To Payment Cancellation Request',
          },
          {
            code: 'PMNT.RCDT.RRTN',
            description: 'Reversal Due To Payment Return',
          },
          {
            code: 'PMNT.RCDT.AREV',
            description: 'ACH Reversal',
          },
        ],
      },
      {
        code: 'PMNT.RDDT',
        description: 'Received Direct Debits',
        children: [
          {
            code: 'PMNT.RDDT.BBDD',
            description: 'SEPA B2B Direct Debit',
          },
          {
            code: 'PMNT.RDDT.ESDD',
            description: 'SEPA Core Direct Debit',
          },
          {
            code: 'PMNT.RDDT.UPDD',
            description: 'Reversal Due To Return/Unpaid Direct Debit',
          },
        ],
      },
      {
        code: 'PMNT.CCRD',
        description: 'Customer Card Transactions',
        children: [
          {
            code: 'PMNT.CCRD.POSD',
            description: 'Point-of-Sale (POS) Payment  - Debit Card',
          },
          {
            code: 'PMNT.CCRD.CWDL',
            description: 'Cash Withdrawal',
          },
          {
            code: 'PMNT.CCRD.CDPT',
            description: 'Cash Deposit',
          },
          {
            code: 'PMNT.CCRD.RIMB',
            description: 'Reimbursement (Generic)',
          },
          {
            code: 'PMNT.CCRD.XBCW',
            description: 'Cross-Border Cash Withdrawal',
          },
          {
            code: 'PMNT.CCRD.CAJT',
            description: 'Credit Adjustments (Generic)',
          },
          {
            code: 'PMNT.CCRD.DAJT',
            description: 'Debit Adjustments (Generic)',
          },
        ],
      },
    ],
  },
]

export default transactionCodes

export const transactionCodeMap = {
  'PMNT.ICDT.DMCT': 'Domestic Credit Transfer',
  'PMNT.ICDT.RRTN': 'Reversal Due To Payment Return',
  'PMNT.ICDT.ESCT': 'SEPA Credit Transfer',
  'PMNT.IDDT.UPDD': 'Reversal Due To Return/Unpaid Direct Debit',
  'PMNT.IDDT.AREV': 'ACH Reversal',
  'PMNT.IDDT.PMDD': 'Direct Debit',
  'PMNT.IRCT.RRTN': 'Reversal Due To Payment Return',
  'PMNT.IRCT.ESCT': 'SEPA Credit Transfer',
  'PMNT.RRCT.BOOK': 'Internal Book Transfer',
  'PMNT.RRCT.ESCT': 'SEPA Credit Transfer',
  'PMNT.RRCT.RPCR': 'Reversal Due To Payment Cancellation Request',
  'PMNT.RRCT.RRTN': 'Reversal Due To Payment Return',
  'PMNT.RRCT.AREV': 'ACH Reversal',
  'PMNT.RCDT.ESCT': 'SEPA Credit Transfer',
  'PMNT.RCDT.DMCT': 'Domestic Credit Transfer',
  'PMNT.RCDT.BOOK': 'Internal Book Transfer',
  'PMNT.RCDT.RPCR': 'Reversal Due To Payment Cancellation Request',
  'PMNT.RCDT.RRTN': 'Reversal Due To Payment Return',
  'PMNT.RCDT.AREV': 'ACH Reversal',
  'PMNT.RDDT.BBDD': 'SEPA B2B Direct Debit',
  'PMNT.RDDT.ESDD': 'SEPA Core Direct Debit',
  'PMNT.RDDT.UPDD': 'Reversal Due To Return/Unpaid Direct Debit',
  'PMNT.CCRD.POSD': 'Point-of-Sale (POS) Payment  - Debit Card',
  'PMNT.CCRD.CWDL': 'Cash Withdrawal',
  'PMNT.CCRD.CDPT': 'Cash Deposit',
  'PMNT.CCRD.RIMB': 'Reimbursement (Generic)',
  'PMNT.CCRD.XBCW': 'Cross-Border Cash Withdrawal',
  'PMNT.CCRD.CAJT': 'Credit Adjustments (Generic)',
  'PMNT.CCRD.DAJT': 'Debit Adjustments (Generic)',
}
