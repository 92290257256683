/* eslint-disable jsx-a11y/anchor-is-valid */
import { capitalize, Grid } from '@material-ui/core'
import { FastField, Field, FieldArray, Form, FormikProps } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AddCircle } from '@material-ui/icons'
import * as Yup from 'yup'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { StyledSelectFieldComplex } from '../../ReferenceData/WithholdingTaxRuleCatalog/styled'
import { ContentGridContainer, ContentPaperContainer } from '../Styled'
import { TransactionThenOptions, TypeOptions } from './constants'
import TransactionRuleField from './TransactionRuleField'
import { FormBottomBar, TextFieldComplex } from 'components'
import {
  ProductKey,
  TransactionExceptionType,
  TransactionRuleAccess,
  TransactionRuleItemRule,
  TransactionRuleOperator,
  TransactionRuleType,
} from 'store/products/types'
import { withFormikSimple } from 'utils/form.utils'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton, H2 } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  productKey: ProductKey
  transactionExceptionType: TransactionExceptionType
  defaultAccess: TransactionRuleAccess
}

type CardFormProps = FormikProps<TransactionRuleItemRule> & OwnProps

export const initialMatchCriteria = {
  operator: '',
  type: '',
  values: '',
}

const TransactionRulesForm = (props: CardFormProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onPressBack = useCallback(() => {
    navigate(`/products/${props.productKey}`)
  }, [props.productKey])

  const { showInfographicModal } = useModal()

  const { matchCriteria } = props.values
  const matchCriteriaTypeNames: string[] = matchCriteria.map((item) => item.type)
  const filteredRuleNames = Object.keys(TransactionRuleType).filter((key) => !matchCriteriaTypeNames.includes(key))
  const canAddMoreFacts = filteredRuleNames.length === 0
  const areFactsInvalid = (props?.errors?.matchCriteria?.length || 0) > 0
  const clearTierCalc = (newValue: any) => {
    props.setFieldTouched('access', true, true)
    props.setFieldValue('access', newValue)
  }
  // To filter based on options selected by user
  // this creates a Object where the key is TypeOptions
  // and value are the valid options user can select in that type
  const [filteredTypeOptions, setFilteredTypeOptions] = useState<any[]>([])
  useEffect(() => {
    let newOptions: any[] = Array.isArray(props.values.matchCriteria)
      ? props.values.matchCriteria
          .filter((filteredItem) => !!filteredItem.operator)
          .map(({ type, values }) => {
            const typeOptions: any = TypeOptions[type]
            return Array.isArray(typeOptions) ? typeOptions.filter((typeItem) => values.includes(typeItem.code)) : []
          })
          .map((item) => item?.map((filteredItem: any) => filteredItem.mapping))
          .filter((filteredItem: any) => filteredItem[0] !== undefined)
      : []
    if (newOptions.length !== 0) {
      newOptions = newOptions.reduce((allItems, newItem) => {
        newItem.forEach((item: any) =>
          Object.keys(item).forEach((key) => {
            allItems[key] = Array.isArray(allItems[key]) ? [...allItems[key], ...item[key]] : item[key]
          })
        )
        return allItems
      }, {})
    }
    setFilteredTypeOptions(newOptions as any)
  }, [matchCriteria])

  const filterBySelectedTypes = (type: any, item: any) => {
    if (!filteredTypeOptions[type]) {
      return true
    }
    return filteredTypeOptions[type].find((filter: any) => filter === item.code)
  }

  return (
    <FeaturePageWithTopAddLayout
      productKey={props.productKey}
      title={t(`${capitalize(props.transactionExceptionType)} transaction exception`)}
      description={t(
        `Here you can create an exception to override your default setting for ${
          props.transactionExceptionType
        } transactions – your default ${props.transactionExceptionType} transactions are set to '${
          props.defaultAccess === TransactionRuleAccess.PERMIT ? 'Allow' : 'Block'
        }'.`
      )}
      rightArea={[
        <InfoIconButton
          key="edit-transaction-access-key"
          ariaProps={{
            'aria-label': `${t('About transaction rules')}`,
            'data-test-id': 'settings-info-dialog',
            'aria-haspopup': 'dialog',
            'aria-controls': 'more-about-dialog',
          }}
          onClick={() => showInfographicModal(HelpModalType.TRANSACTION_STATUS)}
          fontSize="medium"
        />,
      ]}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer container direction="column">
            <Grid item>
              <H2 gutter>{t('Rule')}</H2>
            </Grid>
            <Grid item style={{ marginBottom: '24px' }}>
              <FastField
                name="name"
                label={t('Rule name')}
                component={TextFieldComplex}
                placeholder={t('Enter a meaningful name')}
              />
            </Grid>
            <Grid item>
              <FieldArray name="matchCriteria">
                {(arrayHelpers) => (
                  <>
                    {Array.isArray(matchCriteria) &&
                      matchCriteria.map((value, index) => (
                        <TransactionRuleField
                          key={index}
                          name={`matchCriteria.${index}`}
                          onRemove={arrayHelpers.remove}
                          hasMultipleRows={matchCriteria.length > 1}
                          index={index}
                          filteredRuleNames={filteredRuleNames}
                          transactionExceptionType={props.transactionExceptionType}
                          filterBySelectedTypes={filterBySelectedTypes}
                        />
                      ))}
                    {!(areFactsInvalid || canAddMoreFacts) ? (
                      <Button
                        color={ColorType.NONE}
                        startIcon={<AddCircle />}
                        onClick={() => arrayHelpers.push(initialMatchCriteria)}
                        disableRipple
                        style={{
                          left: '-6px',
                          margin: '0 0 24px 0',
                        }}
                      >
                        {t('AND...')}
                      </Button>
                    ) : null}
                  </>
                )}
              </FieldArray>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Field
                  name="access"
                  label="Then"
                  required
                  component={StyledSelectFieldComplex}
                  placeholder={t('Please select')}
                  data={TransactionThenOptions}
                  inputLabelProps={{ shrink: true }}
                  displayEmpty
                  hasPlaceholder={props.values.access === undefined}
                  disabled={areFactsInvalid}
                  onChange={clearTierCalc}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormBottomBar position="bottom" inlineNavigation disabled={!props.dirty} spacing={1}>
                <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                  {t('Cancel')}
                </Button>
              </FormBottomBar>
            </Grid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

const TransactionRuleItemSchema = Yup.object<Partial<TransactionRuleItemRule>>({
  name: Yup.string().matches(/^[a-zA-Z ]*$/, 'Only letters (A-Z) and spaces accepted'),
  access: Yup.mixed<TransactionRuleAccess>().oneOf(Object.values(TransactionRuleAccess)).required(),
  applicableCriteria: Yup.mixed(),
  order: Yup.number().required(),
  matchCriteria: Yup.array()
    .of(
      Yup.object()
        .shape({
          operator: Yup.mixed<TransactionRuleOperator>().oneOf(Object.values(TransactionRuleOperator)).required(),
          type: Yup.mixed<TransactionRuleType>().oneOf(Object.values(TransactionRuleType)).required(),
          values: Yup.mixed()
            .test('oneOfRequired', 'Field is required', (item) => (Array.isArray(item) ? !!item.length : !!item))
            .required(),
        })
        .required()
    )
    .required(),
}).required()

export default withFormikSimple<OwnProps, TransactionRuleItemRule>(TransactionRulesForm, {
  validationSchema: TransactionRuleItemSchema,
})
