import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { FeeRewardFormValues } from 'store/products/features/feesRewards/types'
import { FrequencyFormValues } from 'store/products/typings/frequencySelector'
import { ApplicationFrequency, MonthlyFrequencyItems, YearlyFrequencyItems } from 'store/products/types'

export const validationSchema = (t: TFunction) =>
  Yup.object<FeeRewardFormValues & FrequencyFormValues>({
    name: Yup.string().min(3).max(100).required(t('Name must be at least 3 characters and less than 100')),
    amount: Yup.number()
      .typeError('Required field')
      .required('Required field')
      .moreThan(0, 'Must be greater than 0')
      .max(100000000000000000, 'Must be less than 100,000,000,000,000,000'),
    dataValue: Yup.mixed()
      .test('min', 'Must be greater than -100,000,000,000,000,000', (value: string) => {
        const number = parseInt(value, 10)
        if (Number.isNaN(number)) {
          return true
        }

        return number > -100000000000000000
      })
      .test('max', 'Must be less than 100,000,000,000,000,000', (value: string) => {
        const number = parseInt(value, 10)
        if (Number.isNaN(number)) {
          return true
        }

        return number < 100000000000000000
      }),
    frequency: Yup.string().required(),
    weeklyOccurence: Yup.string().when(['frequency'], {
      is: (frequency) => frequency === ApplicationFrequency.WEEKLY,
      then: (weeklyOccurence: Yup.StringSchema) => weeklyOccurence.required('This is required'),
    }),
    monthlyOccurence: Yup.string().when(['frequency'], {
      is: (frequency) => frequency === ApplicationFrequency.MONTHLY,
      then: (monthlyOccurence: Yup.StringSchema) => monthlyOccurence.required('This is required'),
    }),
    monthlySpecificDay: Yup.string().when(['monthlyOccurence'], {
      is: (monthlyOccurence) => monthlyOccurence === MonthlyFrequencyItems.SPECIFIC_DAY,
      then: (monthlySpecificDay: Yup.StringSchema) => monthlySpecificDay.required('This is required'),
    }),
    annualOccurence: Yup.string().when(['frequency'], {
      is: (frequency) => frequency === ApplicationFrequency.ANNUALLY,
      then: (annualOccurence: Yup.StringSchema) => annualOccurence.required('This is required'),
    }),
    annualSpecificMonth: Yup.string().when(['annualOccurence'], {
      is: (annualOccurence) => annualOccurence === YearlyFrequencyItems.SPECIFIC_DATE,
      then: (annualSpecificMonth: Yup.StringSchema) => annualSpecificMonth.required('This is required'),
    }),
    annualSpecificDay: Yup.string().when(['annualSpecificMonth'], {
      is: (annualSpecificMonth) => annualSpecificMonth,
      then: (annualSpecificDay: Yup.StringSchema) => annualSpecificDay.required('This is required'),
    }),
  })
