import React, { Dispatch } from 'react'
import { FilterMenu } from 'components'
import getUserRoleFilterOptions from 'pages/Users/utils/getUserRoleFilterOptions'
import { UserFilters, UserRoles } from 'store/user/types'
import { UserSearchActions, ActionTypes } from 'pages/Users/Users/reducer/actions'

interface OwnProps {
  setFilters: Dispatch<UserSearchActions>
  filters: UserFilters
}

const UserFilterLabel = ({ setFilters, filters }: OwnProps) => (
  <FilterMenu
    filter={filters?.userRole ?? UserRoles.ALL_ROLES}
    setFilter={(index) =>
      setFilters({ type: ActionTypes.FILTER_QUERY, payload: { userRole: UserRoles[UserRoles[index]] } })
    }
    filterOptions={getUserRoleFilterOptions()}
  />
)

export default UserFilterLabel
