/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { RootState } from 'store'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import { LogoType } from 'store/tenant-config/types'
import { EntityBadgeGovernance, EntityBadgeProducts } from 'dls/atoms'
import { AvatarSize } from 'dls/shared/types'

const mapDispatchToProps = {}
const mapStateToProps = (state: RootState) => ({
  selectCurrentTenant: TenantConfigSelectors.selectCurrentTenant(state),
})

interface OwnProps {
  type?: LogoType
  entityType?: 'product' | 'package'
  showIcon?: boolean
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  OwnProps &
  Partial<React.HTMLProps<HTMLImageElement>>
const ActualTenantLogo = ({
  selectCurrentTenant,
  type = LogoType.COLLAPSE,
  style,
  className,
  height,
  width,
  entityType,
  showIcon = true,
}: Props): JSX.Element | null => {
  const logos = selectCurrentTenant && selectCurrentTenant.logo ? selectCurrentTenant.logo : ''
  const logoAltName = selectCurrentTenant && selectCurrentTenant.name ? selectCurrentTenant.name : undefined
  const isMultiTenant = useSelector(TenantConfigSelectors.isMultiTenant) || false

  if (!showIcon && !isMultiTenant) return null

  if (!logos || logos[type] === '' || !isMultiTenant) {
    return entityType === 'product' ? (
      <EntityBadgeProducts
        size={AvatarSize.LARGE}
        style={{
          marginRight: 14,
          ...style,
          border: 'none',
          borderRadius: 0,
          marginTop: -2,
        }}
      />
    ) : (
      <EntityBadgeGovernance
        size={AvatarSize.LARGE}
        style={{
          marginRight: 14,
          ...style,
          border: 'none',
          borderRadius: 0,
          marginTop: -2,
        }}
      />
    )
  }

  return (
    <img
      src={logos[type]}
      alt={`${logoAltName} logo`}
      style={style}
      className={className}
      height={height}
      width={width}
    />
  )
}

const WrappedTenantLogo = connect(mapStateToProps, mapDispatchToProps)(ActualTenantLogo)

export const StyledActualTenantLogo = styled((props) => <WrappedTenantLogo {...props} />)`
  max-height: 56px;
  max-width: 56px;
  margin-right: 16px;
  vertical-align: middle;
  padding: 4px;
  border: 1px solid ${(props: any) => props.theme.palette.primary.greyLight};
  border-radius: 28px;
  background-color: ${(props: any) => props.theme.palette.primary.contrastText};
`

export const HeaderTenantLogo = styled((props) => <WrappedTenantLogo {...props} />)`
  max-height: 56px;
  max-width: 56px;
  margin-right: 12px;
  vertical-align: middle;
  padding: 4px;
  border: 1px solid ${(props: any) => props.theme.palette.primary.greyLight};
  border-radius: 28px;
  background-color: ${(props: any) => props.theme.palette.primary.contrastText};
`
export default WrappedTenantLogo
