/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FastField, Field, FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import { InfoOutlined } from '@material-ui/icons'
import LendingTiersBandsList from '../../LendingTiersBandsList'
import { LendingRateFormValues, ExtendedProduct, Operand, RateTierBand, TypeOfIndexRate } from 'store/products/types'
import { BankInterestRate, SelectedRateAndBankName } from 'store/referenceData/types'
import { ratePositiveYupValidation, Yip } from 'utils/form.utils'
import { FieldPercentage, FieldRateCalculator, FieldStaticRateCalculator, RadioGroupFieldComplex } from 'components'
import { Case, SwitchOn } from 'components/ControlComponents'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { H2, Subtitle1 } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface OwnProps {
  hasFeature?: boolean
  bankInterestRates?: BankInterestRate[]
  selectedRateAndBankNameObject?: SelectedRateAndBankName
  selectTypeOfIndexRate: TypeOfIndexRate
  product: ExtendedProduct
  disableTierBandRadioGroup?: boolean
}

interface StaticMarginProps extends FormikProps<LendingRateFormValues>, OwnProps {}

const IndependentRateForm = (props: StaticMarginProps): React.ReactElement => {
  const { t } = useTranslation()
  const {
    values,
    selectedRateAndBankNameObject,
    selectTypeOfIndexRate,
    touched,
    disableTierBandRadioGroup,
    product,
  } = props
  const selectCalculationMethod = getIn(values, 'calculationMethod')
  const handleCalculationMethodOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === values.calculationMethod) {
      return
    }

    const newValues = { ...values, interestRate: undefined, spread: undefined }
    const newTouched = { ...touched, interestRate: false, partnercentricModel: false }

    if (
      e.target?.value === RateTierBand.FLAT &&
      values.typeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
    ) {
      newValues.operator = Operand.ADD
      newTouched.operator = true
    } else {
      newValues.operator = undefined
      newTouched.operator = true
    }

    props.setValues(newValues)
    props.setTouched(newTouched)
  }

  const onTierBandCalcChange = (value: any) => {
    const newValues = { ...values, interestRate: undefined }
    const newTouched = { ...touched, tierBands: true, interestRate: !value === (RateTierBand.FLAT as any) }

    if (value === RateTierBand.FLAT) {
      newValues.tierBands = []
    } else {
      values.tierBands = [
        selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
          ? { startRange: 0, operator: Operand.ADD, spread: undefined }
          : { startRange: 0, spread: undefined },
      ]
      newValues.interestRate = undefined
    }

    props.setValues(newValues)
    props.setTouched(newTouched as any)
  }

  const { showInfographicModal } = useModal()

  return (
    <Box mb={1}>
      <Grid container direction="column">
        {!disableTierBandRadioGroup && (
          <>
            <Grid item xs={12}>
              <H2 gutter>{t('How interest is applied')}</H2>
              <Subtitle1 gutterBottom variantMapping={{ subtitle1: 'div' }}>
                {`${t('There are 3 options for how to apply interest')} : `}
                <LinkWithText
                  onClick={() => showInfographicModal(HelpModalType.DEBIT_INTEREST)}
                  aria-controls="more-about-dialog"
                  aria-haspopup="dialog"
                  endIcon={<InfoOutlined />}
                >
                  {t('see how these work')}
                </LinkWithText>
              </Subtitle1>
            </Grid>
            <Grid item style={{ paddingTop: '0px' }}>
              <Field
                name="calculationMethod"
                component={RadioGroupFieldComplex}
                onChange={onTierBandCalcChange}
                data={RateTierBand}
                required
                validate={Yip(Yup.string().required().nullable())}
                radioGroupProps={{ onClick: handleCalculationMethodOnChange }}
              />
            </Grid>
          </>
        )}
        <Grid item>
          <SwitchOn on={getIn(values, 'calculationMethod')}>
            <Case on={RateTierBand.FLAT}>
              <Box mt={2}>
                {selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN &&
                  selectedRateAndBankNameObject && (
                    <>
                      <Grid item xs={12}>
                        <Subtitle1 gutterBottom>
                          {t('Enter the rate to the customer (your margin will be calculated)')}
                        </Subtitle1>
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          name="interestRate"
                          label={t('Rate')}
                          placeholder={t('Please enter')}
                          required
                          validate={Yip(ratePositiveYupValidation(10, 1000, true).required())}
                          component={FieldRateCalculator}
                          indexRate={selectedRateAndBankNameObject.rate}
                          numberFormatProps={{
                            decimalScale: 13,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                {selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN &&
                  selectedRateAndBankNameObject && (
                    <>
                      <Grid item xs={12}>
                        <Subtitle1 gutterBottom>
                          {t('Enter your margin above or below the {{name}} rate…', {
                            name: selectedRateAndBankNameObject.bankName,
                          })}
                        </Subtitle1>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="spread"
                          operandName="operator"
                          label={t('Your margin')}
                          placeholder={t('Please enter')}
                          required
                          validate={Yip(ratePositiveYupValidation(10, 1000, true).required())}
                          component={FieldStaticRateCalculator}
                          indexRate={selectedRateAndBankNameObject.rate}
                          numberFormatProps={{
                            decimalScale: 13,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                {selectTypeOfIndexRate !== TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN &&
                  selectTypeOfIndexRate !== TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN && (
                    <Grid item xs={12} sm={4}>
                      <FastField
                        id="interestRate"
                        name="interestRate"
                        label={t('Interest rate')}
                        placeholder={t('Please enter')}
                        validate={Yip(ratePositiveYupValidation(10, 1000, true).required())}
                        component={FieldPercentage}
                        helperText="Nominal yearly interest rate"
                        numberFormatProps={{
                          decimalScale: 13,
                        }}
                        required
                      />
                    </Grid>
                  )}
              </Box>
            </Case>
          </SwitchOn>
          {(selectCalculationMethod === RateTierBand.TIER || selectCalculationMethod === RateTierBand.BAND) && (
            <Box mt={2}>
              <LendingTiersBandsList
                {...(props as any)}
                name="tierBands"
                currencyOfProduct={product?.currency?.currencyCode}
                selectTypeOfIndexRate={selectTypeOfIndexRate}
                selectedRateAndBankNameObject={selectedRateAndBankNameObject}
                customTierBandLabel="If the debit balance is..."
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(IndependentRateForm)
