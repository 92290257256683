import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import {
  CursorLink,
  MultiElementDiv,
  SpaceBetweenGrid,
  StyledHistory,
  StyledSpan,
  ActionsGrid,
} from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import { RuleId, TaxRuleId, WithholdingTaxRule } from 'store/withholdingTax/types'
import { selectCurrencySymbol } from 'store/tenant-config/selectors'
import { TierBand } from 'store/products/types'
import { RateMultiplyByHundred } from 'utils/form.utils'
import { DesignIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface Props {
  taxRule: WithholdingTaxRule
  showHistoric?: boolean
  ruleId?: RuleId
  disableEdit?: boolean
}

function DesignIconButtonComp(props: { taxRuleId: TaxRuleId; ruleId: RuleId }) {
  const { t } = useTranslation()
  const { taxRuleId, ruleId } = props
  return (
    <Tooltip title={`${t('Edit future rate(s)')}`} aria-label="edit" placement="right">
      <DesignIconButton
        color="secondary"
        aria-label="Edit future rate(s)"
        role="button"
        href={`reference-data/withholding-tax/${ruleId}/${taxRuleId}/edit`}
      />
    </Tooltip>
  )
}

export default ({ taxRule, showHistoric, ruleId, disableEdit }: Props) => {
  const { t } = useTranslation()
  const currencySymbol = useSelector(selectCurrencySymbol)

  const getRate = (rate: TierBand): string => {
    if (
      currencySymbol &&
      rate &&
      typeof rate.startRange === 'number' &&
      rate.endRange &&
      typeof rate.tierBandRate === 'number'
    ) {
      const startRange = currencySymbol + rate.startRange
      const endRange = rate.endRange > Number.MAX_SAFE_INTEGER ? t('Unlimited') : currencySymbol + rate.endRange
      return `${RateMultiplyByHundred(rate.tierBandRate, 4)} (when income is ${startRange} - ${endRange})`
    }

    return ''
  }

  return (
    <>
      <>
        {taxRule &&
          (taxRule.tierBandCalcMethod === 'TIER' || taxRule.tierBandCalcMethod === 'BAND') &&
          taxRule?.taxTierBand?.map((rate, i) => (
            <SpaceBetweenGrid key={i}>
              <MultiElementDiv noPadding={!showHistoric}>{getRate(rate)}</MultiElementDiv>
            </SpaceBetweenGrid>
          ))}
        {taxRule?.tierBandCalcMethod === 'FLAT' && (
          <SpaceBetweenGrid>
            <MultiElementDiv noPadding={!showHistoric}>
              {RateMultiplyByHundred(taxRule?.flatRate || 0, 4)}
            </MultiElementDiv>
          </SpaceBetweenGrid>
        )}
        {showHistoric && (
          <CursorLink color="secondary" href={`reference-data/withholding-tax/${ruleId}/history`}>
            <StyledHistory />
            <StyledSpan>{t('Historic rates')}</StyledSpan>
          </CursorLink>
        )}
      </>
      {ruleId && !disableEdit && (
        <ActionsGrid>
          <DesignIconButtonComp taxRuleId={taxRule.taxRuleId || ''} ruleId={ruleId} />
        </ActionsGrid>
      )}
    </>
  )
}
