import { put, take, race } from 'redux-saga/effects'
import { Modal } from './types'
import ModalActions from './actions'

export function* showPendingActionModalSaga(modal: Modal) {
  try {
    yield put(ModalActions.showModal(modal))
    const { yes } = yield race({
      yes: take(ModalActions.confirmModalAction.type),
      no: take(ModalActions.cancelModalAction.type),
    })

    yield put(ModalActions.closeModal())
    return Boolean(yes)
  } catch (e: any) {
    return false
  }
}
