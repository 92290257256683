import React from 'react'
import { InfoOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { HelpModalType } from 'store/modal/types'
import { LinkWithText } from 'dls/molecules/Buttons'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface OwnProps {
  textButton?: boolean
}

const AboutCardLimitsCTA = ({ textButton = false }: OwnProps) => {
  const { t } = useTranslation()

  const { showInfographicModal } = useModal()

  return textButton ? (
    <LinkWithText
      onClick={() => showInfographicModal(HelpModalType.CARD_LIMITS)}
      component="span"
      endIcon={<InfoOutlined />}
      aria-controls="more-about-dialog"
      aria-haspopup="dialog"
      style={{ verticalAlign: 'middle', display: 'inline' }}
    >
      {t('Card transaction limits infographic')}
    </LinkWithText>
  ) : (
    <InfoIconButton
      key="more-about-icon"
      ariaProps={{
        'aria-label': `${t('About card transaction limits')}`,
        'aria-haspopup': 'dialog',
        'aria-controls': 'more-about-dialog',
      }}
      onClick={showInfographicModal}
      fontSize="medium"
    />
  )
}

export default AboutCardLimitsCTA
