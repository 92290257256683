import { createContext } from 'react'

export type Tenant = string

export type TenantState = {
  tenantName?: string
  tenantPath?: string
  tenantIndex?: number
}

export const TenantStateContext = createContext<TenantState | undefined>(undefined)
