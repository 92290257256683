import React from 'react'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { FeeRewardRule } from 'store/products/features/feesRewards/types'

const FeeRewardAmount = ({ rules, currencyCode }: { rules: FeeRewardRule[]; currencyCode: string }) => {
  if (!rules?.length || !rules[0].consequences.length) {
    return null
  }

  return (
    <>
      <CurrencySymbol currencyCode={currencyCode} noTypography />
      {rules[0].consequences[0].value}
    </>
  )
}

export default FeeRewardAmount
