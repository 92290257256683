import moment from 'moment-timezone'
import { BankInterestRateFormValues } from 'store/referenceData/types'
import { RateDividedByHundred } from 'utils/form.utils'

export const createBankInterestRateToPayload = (payload: BankInterestRateFormValues) => ({
  ...payload,
  rates: payload.rates.map((i) => ({
    ...i,
    indexRate: i.indexRate ? RateDividedByHundred(i.indexRate) : i.indexRate,
    effectiveDate: i.effectiveDate ? moment(i.effectiveDate).format('YYYY-MM-DD') : i.effectiveDate,
  })),
})
