import React, { Dispatch, SetStateAction, useState } from 'react'
import { TransactionsModal } from '../../constants'
import ReverseTransactionModal from '../TransactionsModal/ReverseTransactionModal'
import HowSuspenseAccountsWorkModal from '../TransactionsModal/HowSuspenseAccountsWorkModal'
import StatusInformationModal from '../TransactionsModal/StatusInformationModal'

export interface InjectedProps {
  currentModal: TransactionsModal | null | undefined
  setCurrentModal: Dispatch<SetStateAction<TransactionsModal | null | undefined>>
  modalItem: any
  setModalItem: any
  onCloseModal: () => void
}

export function withTransactionModals<TOriginalProps>(
  Component: React.FC<TOriginalProps & InjectedProps>
): React.FC<TOriginalProps> {
  return (props) => {
    const [currentModal, setCurrentModal] = useState<TransactionsModal | null>()
    const [modalItem, setModalItem] = useState<any>()

    const onCloseModal = () => {
      setModalItem(null)
      setCurrentModal(null)
    }

    const injectedProps = { ...props, currentModal, setCurrentModal, modalItem, setModalItem, onCloseModal }

    return (
      <>
        <Component {...injectedProps} />
        {modalItem && (
          <ReverseTransactionModal
            item={modalItem}
            onFinish={onCloseModal}
            onCancel={onCloseModal}
            open={currentModal === TransactionsModal.REVERSE_TRANSACTIONS}
          />
        )}
        <HowSuspenseAccountsWorkModal
          onPressCancel={onCloseModal}
          open={currentModal === TransactionsModal.HOW_IT_WORKS}
        />
        <StatusInformationModal
          onPressCancel={onCloseModal}
          heading="Status"
          open={currentModal === TransactionsModal.STATUS}
        />
      </>
    )
  }
}

export default withTransactionModals
