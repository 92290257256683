import Grid from '@material-ui/core/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContextualHelp } from 'components'
import CopyButton from 'components/CopyButton/CopyButton'
import { ProductKey } from 'store/contents/types'

export default ({ productKey }: { productKey: ProductKey }) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>{productKey}</Grid>
      <Grid item>
        <ContextualHelp
          placement="top"
          title={t(
            'This is the unique identifier that was assigned to the product when it was automatically created. It remains the constant identifier even when the product name is changed or when a new version is created. '
          )}
        />
      </Grid>
      <Grid item>
        <CopyButton payload={productKey} size="small" extraButtonProps={{ style: { margin: 0 } }} />
      </Grid>
    </Grid>
  )
}
