import {
  ExtendedProduct,
  PaymentSchedule,
  PaymentScheduleTerm,
  PaymentTermTypeDays,
  AllowedRepaymentFrequencies,
} from 'store/products/types'

export const defaultPaymentScheduleValue: PaymentSchedule = {
  amortisationMethod: null as any,
  calculationRoundingAdjustment: null as any,
  allowedRepaymentFrequencies: [],
  term: {
    minimum: '',
    maximum: '',
    type: null as any,
  },
  amount: {
    minimum: '',
    maximum: '',
  },
}
export const hasPaymentScheduleFeature = (product: ExtendedProduct): boolean => product && !!product.paymentSchedule

export const getMinimumTermInDays = (termType: PaymentScheduleTerm, duration: string) =>
  PaymentTermTypeDays[termType] * parseInt(duration, 10)

export const getAllowedPaymentFrequecies = (minimumTermInDays: number) => {
  const paymentFrequencies: AllowedRepaymentFrequencies[] = []

  if (minimumTermInDays >= PaymentTermTypeDays.WEEKS) {
    paymentFrequencies.push(AllowedRepaymentFrequencies.WEEKLY)
  }
  if (minimumTermInDays >= PaymentTermTypeDays.FORTNIGHT) {
    paymentFrequencies.push(AllowedRepaymentFrequencies.FORTNIGHTLY)
  }
  if (minimumTermInDays >= PaymentTermTypeDays.MONTHS) {
    paymentFrequencies.push(AllowedRepaymentFrequencies.MONTHLY)
  }
  if (minimumTermInDays >= PaymentTermTypeDays.QUARTERLY) {
    paymentFrequencies.push(AllowedRepaymentFrequencies.QUARTERLY)
  }
  if (minimumTermInDays >= PaymentTermTypeDays.YEARS) {
    paymentFrequencies.push(AllowedRepaymentFrequencies.ANNUALLY)
  }

  return paymentFrequencies
}
