import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'

export const StepperContainer = styled.div`
display: grid;
grid-template-columns: 1fr 120px min-content;
grid-template-rows: auto;
grid-template-areas: 'stepperArea infoArea actionArea';
align-items: center;
& .MuiStep-horizontal{
  padding-left: 8px;
  padding-right: 8px;
}
${({ theme }) => `
  ${theme.breakpoints.down('xs')} {
    grid-template-columns: 1fr 56px;
    grid-template-rows: auto;
    grid-template-areas: 'stepperArea infoArea'
    'actionArea actionArea';
    & .stepperArea {
      margin-right: 30px;
      margin-left: -8px;
    }
  }
`}
${({ theme }) => `
  ${theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr 56px min-content;
    & .MuiStepLabel-label {
      display:none;
    }
    & .MuiStepLabel-label.MuiStepLabel-active {
      display:block;
      padding-left: 8px;
    }
    & .MuiStepLabel-iconContainer{
      padding-right: 0px;
    }
    & .stepperArea {
      margin-right: 30px;
    }
  }
`}
}
`

export const StyledConversationGrid = styled(Grid)`
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: 34px;
  }
`
