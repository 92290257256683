/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider, Grid } from '@material-ui/core'
import { FastField, Form, FormikProps, getIn } from 'formik'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, pick } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { ContentGridContainer, ContentPaperContainer } from '../Styled'
import { hasTransactionRulesFeature } from './transactionRulesTransformers'
import { withFormikSimple } from 'utils/form.utils'
import { SelectFieldData } from 'components/form/SelectFieldComplex'
import { CardScheme, TransactionRules, ExtendedProduct, TransactionRuleAccess, ProductKey } from 'store/products/types'
import { FormBottomBar } from 'components'
import { ClientConfig } from 'store/tenant-config/types'
import RadioGroupFieldSimple from 'components/form/RadioGroupFieldSimple'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton, Body1, Body2, H2, InfoPanel } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  // eslint-disable-next-line react/no-unused-prop-types
  hasFeature?: boolean
  clientConfig?: Partial<ClientConfig>
  product: ExtendedProduct
  productKey: ProductKey
  // eslint-disable-next-line react/no-unused-prop-types
  t: TFunction
}

export const StyledDivider = styled(Divider)`
  margin-top: 14px;
  margin-bottom: 16px;
  background-color: ${(props: any) => `${props.theme.palette.text.disabled}`};
`

type TransactionRulesFormProps = FormikProps<TransactionRules> & OwnProps

const EditDefaultAccessForm = (props: TransactionRulesFormProps) => {
  const availableSchemes =
    (props && props.clientConfig && props.clientConfig.cards && props.clientConfig.cards.scheme) || null
  const navigate = useNavigate()

  const { showInfographicModal } = useModal()

  let schemeList = {}

  if (availableSchemes && !isEmpty(availableSchemes)) {
    schemeList = pick(CardScheme, availableSchemes)
  } else {
    schemeList = CardScheme
  }

  const selectDataCardScheme: SelectFieldData = {}
  Object.entries(schemeList as CardScheme).forEach(([key, value]) => {
    selectDataCardScheme[key] = { name: value }
  })
  const { t } = useTranslation()

  const onPressBack = useCallback(() => {
    navigate(`/products/${props.productKey}`)
  }, [props.productKey])

  const handleOnchangeDefaultAccess = (name: string) => (value: string) => {
    props.setFieldValue(name, value)
    props.setFieldTouched(name)
  }
  const inboundValue = getIn(props.values, 'inbound.defaultAccess')
  const outboundValue = getIn(props.values, 'outbound.defaultAccess')
  const inboundTouched = props.dirty && getIn(props.touched, 'inbound.defaultAccess')
  const outboundTouched = props.dirty && getIn(props.touched, 'outbound.defaultAccess')

  return (
    <FeaturePageWithTopAddLayout
      productKey={props.productKey}
      title={t('Transaction rules')}
      descriptionContent={
        <Body2 gutterBottom>
          {t(
            'Here you can set the default behavior to permit or deny inbound and outbound transactions for your product.'
          )}
        </Body2>
      }
      rightArea={[
        <InfoIconButton
          key="edit-default-access-key"
          ariaProps={{
            'aria-label': `${t('About transaction rules')}`,
            'data-test-id': 'settings-info-dialog',
            'aria-haspopup': 'dialog',
            'aria-controls': 'more-about-dialog',
          }}
          onClick={() => showInfographicModal(HelpModalType.TRANSACTION_STATUS)}
          fontSize="medium"
        />,
      ]}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer container direction="column">
            <Grid item>
              <H2>{t('Default setting')}</H2>
            </Grid>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={8}>
                <Body1>{t('Inbound default access')}</Body1>
              </Grid>
              <Grid item xs>
                <FastField
                  name="inbound.defaultAccess"
                  component={RadioGroupFieldSimple}
                  row
                  data={{
                    [`${t('Allow')}`]: TransactionRuleAccess.PERMIT,
                  }}
                  onChange={handleOnchangeDefaultAccess('inbound.defaultAccess')}
                  value={inboundValue}
                />
              </Grid>
              <Grid item xs>
                <FastField
                  name="inbound.defaultAccess"
                  component={RadioGroupFieldSimple}
                  row
                  data={{
                    [`${t('Block')}`]: TransactionRuleAccess.DENY,
                  }}
                  onChange={handleOnchangeDefaultAccess('inbound.defaultAccess')}
                  value={inboundValue}
                />
              </Grid>
            </Grid>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={8}>
                <Body1>{t('Outbound default access')}</Body1>
              </Grid>
              <Grid item xs>
                <FastField
                  name="outbound.defaultAccess"
                  component={RadioGroupFieldSimple}
                  row
                  data={{
                    [`${t('Allow')}`]: TransactionRuleAccess.PERMIT,
                  }}
                  onChange={handleOnchangeDefaultAccess('outbound.defaultAccess')}
                  value={outboundValue}
                />
              </Grid>
              <Grid item xs>
                <FastField
                  name="outbound.defaultAccess"
                  component={RadioGroupFieldSimple}
                  row
                  data={{
                    [`${t('Block')}`]: TransactionRuleAccess.DENY,
                  }}
                  onChange={handleOnchangeDefaultAccess('outbound.defaultAccess')}
                  value={outboundValue}
                />
              </Grid>
            </Grid>
            <Grid item>
              <StyledDivider />
            </Grid>
            {(!hasTransactionRulesFeature(props.product) || inboundTouched || outboundTouched) && (
              <Grid item>
                <InfoPanel
                  title={
                    hasTransactionRulesFeature(props.product)
                      ? t(`You're changing the default setting – are you sure?`)
                      : null
                  }
                  body={
                    hasTransactionRulesFeature(props.product)
                      ? t(
                          `These changes will mean that any existing exceptions you've created need to be reviewed and updated`
                        )
                      : t(`You can create exceptions to the default settings once they have been submitted`)
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormBottomBar inlineNavigation spacing={3} noDirty>
                <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                  {t('Cancel')}
                </Button>
              </FormBottomBar>
            </Grid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, TransactionRules>(EditDefaultAccessForm, {
  validateOnMount: true,
  validationSchema: Yup.object({
    inbound: Yup.object({
      defaultAccess: Yup.string().required(),
    }),
    outbound: Yup.object({
      defaultAccess: Yup.string().required(),
    }),
  }).required(),
})
