import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'
import { Checkbox, Grid, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import {
  AutocompleteSelectFieldDataItem,
  AutoCompleteSelectFieldItem,
  AutoCompleteSelectProps,
} from 'components/form/MultiSelectAutocomplete/types'
import { checkedIcon, icon, selectAllKey } from 'components/form/MultiSelectAutocomplete/constants'
import { ChipContainer, FlagContainer, SelectLabel, StyledChip } from 'components/form/MultiSelectAutocomplete/Styled'
import { ListboxComponent } from 'components/form/MultiSelectAutocomplete/VirtualListHelpers'
import { Flag } from 'components'

const MultiSelectAutocomplete = (props: AutoCompleteSelectProps) => {
  const {
    selectProps,
    data,
    placeholder,
    label,
    required,
    hasFlag = false,
    multiple = false,
    chipLabel,
    selectAll = false,
    selectAllLabel,
    value,
    disabled,
    hasError,
    inputProps,
    reloadOptionsOnChange = false,
    ...otherProps
  } = props

  const { t } = useTranslation()

  const [options, setOptions] = React.useState<AutoCompleteSelectFieldItem[] | undefined>([])

  useEffect(() => {
    const shouldReload = reloadOptionsOnChange || options?.length === 0
    if (shouldReload && data) {
      const newOptions = Object.keys(data)
        .sort((a, b) => {
          const first: AutocompleteSelectFieldDataItem = data[a] as any
          const sec: AutocompleteSelectFieldDataItem = data[b] as any
          if (typeof first === 'string' && typeof sec === 'string') {
            if (first < sec) {
              return -1
            }
            return first > sec ? 1 : 0
          }
          if (first?.label && sec?.label && first.label < sec.label) {
            return -1
          }
          return first?.label > sec?.label ? 1 : 0
        })
        .map((key) => {
          const item = data[key]
          return typeof item === 'string' ? item : key
        })

      if (!multiple && !required) {
        newOptions.unshift('')
      }
      if (multiple && selectAll) {
        newOptions.unshift(selectAllKey)
      }

      const areSame = JSON.stringify(newOptions) === JSON.stringify(options)
      if (!areSame) setOptions(newOptions)
    }
  }, [data, multiple, required, selectAll])

  const getOptionLabel = (item: any) => {
    if (item === '') {
      return t('Please select')
    }
    if (item === selectAllKey) {
      return `${selectAllLabel || t('Select all')}`
    }
    if (data[item]) {
      return `${(data[item] as AutocompleteSelectFieldDataItem).label}`
    }
    if (Array.isArray(item) && item.length === 0) {
      return ''
    }
    return item
  }
  const getOptionSelected = (option: any, val: any) => (multiple ? option === val : option === val[0])

  const ownRenderOption = (item: any, { selected }: { selected: any }) => (
    <SelectLabel multiple={multiple}>
      {multiple && (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          checked={item === selectAllKey && value && value.length === Object.entries(data).length ? true : selected}
        />
      )}
      {hasFlag && data[item] ? (
        <FlagContainer>
          <Flag code={(data[item] as AutocompleteSelectFieldDataItem).flag as string} size={24} />
        </FlagContainer>
      ) : (
        ''
      )}
      {getOptionLabel(item)}
    </SelectLabel>
  )

  const renderTags = (val: any, getTagProps: any) => (
    <ChipContainer>
      {(val || []).map((item: any, index: number) => (
        <StyledChip
          {...getTagProps({ index })}
          key={index}
          disabled={disabled}
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ flexWrap: 'nowrap' }}>
              {hasFlag && data[item] ? (
                <Grid item>
                  <FlagContainer>
                    <Flag code={(data[item] as AutocompleteSelectFieldDataItem).flag as string} size={20} />
                  </FlagContainer>
                </Grid>
              ) : undefined}
              <Grid item>
                {chipLabel ? chipLabel(data[item] as AutoCompleteSelectFieldItem) : getOptionLabel(item)}
              </Grid>
            </Grid>
          }
        />
      ))}
    </ChipContainer>
  )
  let cleanValue: string[] | string | null
  if (multiple) {
    cleanValue = Array.isArray(value) ? value : []
  } else if (Array.isArray(value) && value.length > 0) {
    cleanValue = value
  } else {
    cleanValue = null
  }
  return (
    <Autocomplete
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      fullWidth
      disableClearable
      disableCloseOnSelect={multiple}
      openOnFocus
      {...otherProps}
      {...selectProps}
      multiple={multiple}
      options={options as any}
      disabled={disabled}
      value={cleanValue}
      getOptionSelected={getOptionSelected}
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      renderOption={(selectProps && selectProps.renderOption ? selectProps.renderOption : ownRenderOption) as any}
      onChange={props.onChange as any}
      style={{ fontSize: 14, padding: 0 }}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          error={hasError}
          helperText={props.helperText}
          label={`${label}${label && required ? ' *' : ''}`}
          placeholder={placeholder || t('Please select or start typing')}
          InputProps={{
            ...inputProps,
            ...params.InputProps,
            startAdornment:
              !multiple && hasFlag && data[value as string] ? (
                <FlagContainer>
                  <Flag code={(data[value as string] as AutocompleteSelectFieldDataItem).flag as string} size={20} />
                </FlagContainer>
              ) : (
                params.InputProps.startAdornment
              ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}

export default MultiSelectAutocomplete
