import { useDispatch, useSelector } from 'react-redux'
import { HelpModalType, Modal } from 'store/modal/types'
import actions from 'store/modal/actions'
import { selectModal } from 'store/modal/selectors'

const useModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(selectModal)
  const showModal = (_modal: Modal) => dispatch(actions.showModal(_modal))
  const showInfographicModal = (type: HelpModalType) => dispatch(actions.showInfographicModal(type))
  const confirmModalAction = () => dispatch(actions.confirmModalAction())
  const cancelModalAction = () => dispatch(actions.cancelModalAction())
  const closeModal = () => dispatch(actions.closeModal())

  return {
    modal,
    showModal,
    showInfographicModal,
    closeModal,
    confirmModalAction,
    cancelModalAction,
  }
}

export default useModal
