import React from 'react'
import { DialogActions as MUIDialogActions, DialogActionsProps as MUIDialogActionsProps } from '@material-ui/core'

export interface DialogActionsProps extends MUIDialogActionsProps {}

export const DialogActions = (props: DialogActionsProps) => (
  <MUIDialogActions data-dls="atom-dialog-action" {...props} />
)

export default DialogActions
