import React, { CSSProperties } from 'react'
import { ProductVersionStatus as ProdVersionStatus } from 'store/products/typings/productVersionStatus'

export type FlexboxProps = {
  direction: 'column' | 'row'
  width?: string
  height?: string
  justifyContent?: 'flex-start' | 'flex-end' | 'stretch' | 'center' | 'space-between'
  alignItems?: 'flex-start' | 'flex-end' | 'stretch' | 'center'
  p?: number
  pt?: number
  pb?: number
  pl?: number
  pr?: number
}

export type ProductVersionStatus = ProdVersionStatus

export enum ColorType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BRAND = 'BRAND',
  ERROR = 'ERROR',
  INFO = 'INFO',
  NONE = 'none',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DESIGN = 'DESIGN',
  SCHEDULED = 'SCHEDULED',
  UPDATED = 'UPDATED',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  LIVE = 'LIVE',
  BUTTON = 'BUTTON',
  MEDIUM = 'MEDIUM',
  PAPER = 'PAPER',
  inherit = 'inherit',
  default = 'default',
  ORANGE = 'Orange',
  MAGENTA = 'Magenta',
  BLUE = 'Blue',
  GREEN = 'Green',
  YELLOW = 'Yellow',
  TEAL = 'Teal',
}

export enum iconSVGFillType {
  FILL = 'FILL',
  STROKE = 'STROKE',
}

export enum GenericSize {
  XL = 'extraLarge',
  LG = 'large',
  MD = 'medium',
  SM = 'small',
  ES = 'extraSmall',
}

export enum ImageSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum AvatarSize {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum AvatarColor {
  PRODUCT = 'PRODUCT',
  PACKAGE = 'PACKAGE',
  SUSPENSEACCOUNT = 'SUSPENSE_ACCOUNT',
  BANKSETTINGS = 'BANK_SETTINGS',
  REFERENCEDATA = 'REFERENCE_DATA',
  GOVERNANCE = 'GOVERNANCE',
  HELP = 'HELP',
  DISABLED = 'DISABLED',
  TenX = 'TenX',
  ACCOUNT = 'ACCOUNT',
  COLLEAGUE = 'COLLEAGUE',
  TAXRULES = 'TAX_RULES',
  INDEXRATES = 'INDEX_RATES',
  PRODUCTDETAILS = 'PRODUCT_DETAILS',
  HISTORY = 'HISTORY',
  CURRENCY = 'CURRENCY',
  EXTERNALIDS = 'EXTERNAL_IDS',
  PRODUCTCODE = 'PRODUCT_CODE',
  PRODUCTS = 'PRODUCTS',
  ELIGIBILITY = 'ELIGIBILITY',
  TRANSACTIONRULES = 'TRANSACTION_RULES',
  PAYMENTLIMITS = 'PAYMENT_LIMITS',
  CARDS = 'CARDS',
  CARDLIMIT = 'CARD_LIMIT',
  PRODUCTCARDLIMIT = 'PRODUCT_CARD_LIMIT',
  FEES = 'FEES',
  REWARDS = 'REWARDS',
  CREDITINTEREST = 'CREDIT_INTEREST',
  TERMAMOUNT = 'TERM_AMOUNT',
  DEBITINTEREST = 'DEBIT_INTEREST',
  CREDITLINES = 'CREDIT_LINES',
  REPAYMENTSCHEDULE = 'REPAYMENT_SCHEDULE',
  DOCUMENTS = 'DOCUMENTS',
  STATEMENTS = 'STATEMENTS',
  PRODUCTSYNDICATION = 'PRODUCT_SYNDICATION',
  TERMS = 'TERMS',
  DEVICES = 'DEVICES',
  ANYSOURCE = 'ANY_SOURCE',
  ORANGE = 'Orange',
  MAGENTA = 'Magenta',
  BLUE = 'Blue',
  GREEN = 'Green',
  YELLOW = 'Yellow',
  TEAL = 'Teal',
}

export interface AvatarProps {
  size?: AvatarSize
  color?: AvatarColor
  children: React.ReactNode
}

export enum IconSize {
  small = '24px',
  medium = '28px',
  large = '48px',
}

export type fontSizeTypes = 'small' | 'default' | 'inherit' | 'medium' | 'large'

export interface ImageTypes {
  src?: string
  title?: string
  alt?: string
  ariaProps?: Record<string, string | boolean>
  size?: GenericSize
  styleColumn?: any
}

export interface ImageType {
  path: string
  title: string
}

export enum LabelMode {
  INPUT_FIELD = 'INPUT_FIELD',
  RIGHT_LABEL = 'RIGHT_LABEL',
  TOP_LABEL = 'TOP_LABEL',
}

export enum KeyCode {
  COMMA = 189,
}

export type FieldLabelPlacement = 'bottom' | 'end' | 'start' | 'top'

export interface ArrayFormOption {
  added?: boolean
  scrolled?: boolean
  name: string
  icon: React.ReactNode
  ctaText: string
  component: React.ReactNode
}

export interface BadgeProps {
  size?: AvatarSize
  style?: CSSProperties
  ariaProps?: Record<string, string | boolean>
  className?: string
  disabled?: boolean
}

export type BadgeSvgElement = React.JSXElementConstructor<BadgeProps>

export interface SelectOption {
  label: string
  value: string
  groupHeading?: boolean
}

export interface DataPickerRow {
  code: string
  description: string
  checked?: boolean
  children?: DataPickerRow[]
}
