/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FastField, Field, FormikProps, getIn } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import { InfoOutlined } from '@material-ui/icons'
import TiersBandsList from 'pages/features/CreditInterest/components/TiersBandsList'
import { ContentSpace, InputType, SubField, TooltipStyle } from 'pages/features/CreditInterest/styles'
import {
  CreditInterestFormValues,
  ExtendedProduct,
  FrequencyType,
  Operand,
  RateTierBand,
  RoundingMethod,
  TypeOfIndexRate,
} from 'store/products/types'
import { BankInterestRate, SelectedRateAndBankName } from 'store/referenceData/types'
import SelectFieldComplex from 'components/form/SelectFieldComplex'
import { ratePositiveYupValidation, rateYupValidation, Yip, stopPropagation } from 'utils/form.utils'
import {
  ContextualHelp,
  FieldPercentage,
  FieldRateCalculator,
  FieldStaticRateCalculator,
  RadioGroupFieldComplex,
} from 'components'
import ApplicationFrequencySelector from 'components/form/Frequency/ApplicationFrequencySelector'
import CalculationFrequencySelector from 'components/form/Frequency/CalculationFrequencySelector'
import { Case, SwitchOn } from 'components/ControlComponents'
import ProductsSelectors from 'store/products/selectors'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { H2, Subtitle1, InfoPanel } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface OwnProps {
  hasFeature?: boolean
  currencyOfProduct?: string | null
  bankInterestRates?: BankInterestRate[]
  selectedRateAndBankNameObject?: SelectedRateAndBankName
  selectTypeOfIndexRate: TypeOfIndexRate
  product: ExtendedProduct
}

interface StaticMarginProps extends FormikProps<CreditInterestFormValues>, OwnProps {}

const Common = (props: StaticMarginProps): React.ReactElement => {
  const { t } = useTranslation()
  const { values, selectedRateAndBankNameObject, currencyOfProduct, selectTypeOfIndexRate, touched } = props
  const selectTierBandCalcMethod = getIn(values, 'tierBandCalcMethod')
  const { isCreditInterestEnabled } = useSelector(ProductsSelectors.selectProductPermissions)

  const { showInfographicModal } = useModal()

  const handleTierBandCalcMethodOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = { ...values, roundingMethod: undefined, interestRate: undefined, spread: undefined }
    const newTouched = {
      ...touched,
      roundingMethod: false,
      interestRate: false,
      partnercentricModel: false,
      interestTierBand: [{ endRange: false }],
    }

    if (isCreditInterestEnabled) {
      newValues.applicationFrequency = ''
      newValues.calculationFrequency = ''
      newTouched.applicationFrequency = false
      newTouched.calculationFrequency = false
    }

    if (
      e.target &&
      e.target.value &&
      e.target.value === RateTierBand.FLAT &&
      values.typeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
    ) {
      newValues.operand = Operand.ADD
      newTouched.operand = true
    } else {
      newValues.operand = undefined
      newTouched.operand = true
    }

    newValues.partnercentricModel = {
      [TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN]: false,
      [TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN]: true,
    }[values.typeOfIndexRate as any]

    props.setValues(newValues)
    props.setTouched(newTouched as any)
  }
  const onTierBandCalcChange = (value: any) => {
    const newValues = { ...values, interestRate: null }
    const newTouched = {
      ...touched,
      interestTierBand: [{ endRange: false }],
      interestRate: !value === (RateTierBand.FLAT as any),
    }

    if (value === RateTierBand.FLAT) {
      newValues.interestTierBand = []
    } else {
      values.interestTierBand = [
        selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
          ? { startRange: 0, tierBandOperand: Operand.ADD, tierBandSpread: undefined }
          : { startRange: 0, tierBandSpread: undefined },
      ]
      newValues.interestRate = null
    }

    props.setValues(newValues as any)
    props.setTouched(newTouched as any)
  }

  const roundingTooltip = (
    <>
      <>{t('Round up – always round up the 3rd decimal')}</>
      <br />
      <>{t('Round down – always round down the 3rd decimal')}</>
      <br />
      <>
        {t(`Standard rounding – if the 3rd decimal place is equal or greater than 5 the value will
    round up, otherwise it will round down.`)}
      </>
    </>
  )
  return (
    <>
      <ContentSpace>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <H2>{t('How interest is applied')}</H2>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom variantMapping={{ subtitle1: 'div' }}>
              {`${t('There are 3 options for how to apply interest')} : `}
              <LinkWithText
                aria-controls="more-about-dialog"
                aria-haspopup="dialog"
                onClick={(e: React.MouseEvent) => {
                  stopPropagation(e)
                  showInfographicModal(HelpModalType.INTEREST_RATE_OPTION)
                }}
                endIcon={<InfoOutlined />}
              >
                {t('see how these work')}
              </LinkWithText>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3} justifyContent="flex-start" alignItems="flex-start">
          <Grid item>
            <InputType>
              <Field
                name="tierBandCalcMethod"
                component={RadioGroupFieldComplex}
                onChange={onTierBandCalcChange}
                data={RateTierBand}
                required
                validate={Yip(Yup.string().required())}
                radioGroupProps={{ onClick: handleTierBandCalcMethodOnChange }}
              />
            </InputType>
          </Grid>
        </Grid>
        <SwitchOn on={getIn(values, 'tierBandCalcMethod')}>
          <Case on={RateTierBand.FLAT}>
            {selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN &&
              selectedRateAndBankNameObject && (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      css={`
                        margin-top: 24px;
                        margin-bottom: 16px;
                      `}
                    >
                      {t('Enter the rate to the customer (your margin will be calculated)')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="interestRate"
                      label={t('Rate')}
                      helperText="Nominal yearly interest rate"
                      placeholder={t('Please enter')}
                      required
                      validate={Yip(rateYupValidation(10, 1000).required())}
                      component={FieldRateCalculator}
                      indexRate={selectedRateAndBankNameObject.rate}
                      numberFormatProps={{
                        decimalScale: 13,
                      }}
                    />
                  </Grid>
                </>
              )}
            {selectTypeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN && selectedRateAndBankNameObject && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    css={`
                      margin-top: 24px;
                      margin-bottom: 16px;
                    `}
                  >
                    {t('Enter your margin above or below the {{name}} rate…', {
                      name: selectedRateAndBankNameObject.bankName,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 8 }}>
                  <Field
                    name="spread"
                    operandName="operand"
                    label={t('Your margin')}
                    placeholder={t('Please enter')}
                    required
                    validate={Yip(ratePositiveYupValidation(4, 1000).required())}
                    component={FieldStaticRateCalculator}
                    indexRate={selectedRateAndBankNameObject.rate}
                    numberFormatProps={{
                      decimalScale: 13,
                    }}
                  />
                </Grid>
              </>
            )}
            {selectTypeOfIndexRate !== TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN &&
              selectTypeOfIndexRate !== TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN && (
                <>
                  <Grid item xs={12} sm={4}>
                    <FastField
                      name="interestRate"
                      label={t('Interest rate')}
                      placeholder={t('Please enter')}
                      validate={Yip(rateYupValidation(10, 1000).required())}
                      component={FieldPercentage}
                      helperText="Nominal yearly interest rate"
                      numberFormatProps={{
                        decimalScale: 13,
                      }}
                      required
                    />
                  </Grid>
                </>
              )}
          </Case>
        </SwitchOn>
        {(selectTierBandCalcMethod === RateTierBand.TIER || selectTierBandCalcMethod === RateTierBand.BAND) && (
          <TiersBandsList
            {...props}
            name="interestTierBand"
            currencyOfProduct={currencyOfProduct}
            selectTypeOfIndexRate={selectTypeOfIndexRate}
            selectedRateAndBankNameObject={selectedRateAndBankNameObject}
          />
        )}
      </ContentSpace>
      <ContentSpace>
        {!!selectTierBandCalcMethod && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <H2>{t('How interest is calculated & paid')}</H2>
              </Grid>
              <Grid item xs={12}>
                <InfoPanel
                  title={t('Default settings')}
                  body={
                    <>
                      <Typography variant="body2">
                        {t(`Interest rounding always happens at the end of the day.`)}
                      </Typography>
                      <Typography variant="body2">
                        {t(`Compounding frequency (how often interest is calculated on accrued interest)
            will be set to the same as what you choose for application frequency.`)}
                      </Typography>
                    </>
                  }
                />
              </Grid>
            </Grid>
            <ContentSpace>
              <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Grid item xs={4}>
                  <FastField
                    label={t('Rounding method')}
                    name="roundingMethod"
                    data={RoundingMethod}
                    component={SelectFieldComplex}
                    validate={Yip(Yup.string().required())}
                    required
                    displayEmpty
                    fullWidth
                    placeholder={t('Please select')}
                    selectProps={{ displayEmpty: true }}
                    inputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TooltipStyle>
                    <ContextualHelp title={roundingTooltip} />
                  </TooltipStyle>
                </Grid>
              </Grid>
            </ContentSpace>
            <SubField>
              <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <InfoPanel
                    title={t(
                      isCreditInterestEnabled ? 'Important' : 'Calculation & application frequency can’t be changed'
                    )}
                    body={t(
                      isCreditInterestEnabled
                        ? `The calculation and application frequencies can’t be changed after this product has been published `
                        : 'These frequencies can’t be changed because a version of this product has already been published'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CalculationFrequencySelector
                    key={FrequencyType.CALCULATION}
                    frequencyType={FrequencyType.CALCULATION}
                    disable={!isCreditInterestEnabled}
                  />
                </Grid>
              </Grid>
              {values.calculationFrequency && !isEmpty(values.calculationFrequency) ? (
                <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Subtitle1>
                        {t(
                          'Choose how often interest should be paid, this must be equal or greater than the calculation frequency.'
                        )}
                      </Subtitle1>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ApplicationFrequencySelector
                      key={FrequencyType.APPLICATION}
                      frequencyType={FrequencyType.APPLICATION}
                      calculationTopSelectedVal={values.calculationFrequency}
                      disable={!isCreditInterestEnabled}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </SubField>
          </>
        )}
      </ContentSpace>
    </>
  )
}

export default React.memo(Common)
