import React from 'react'
import { DialogActions, Grid, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { Field, Form, FormikProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Sticky from 'react-sticky-el'
import { Process, ProcessFormValues } from 'store/governance/types'
import { LifeCycle } from 'store/governance/typings/lifeCycle'
import { ModalWrapper, RadioGroupFieldComplex, SubmitButton } from 'components'
import { RadioGroupFieldData } from 'components/form/RadioGroupFieldComplex'
import { withFormikSimple, Yip } from 'utils/form.utils'
import { InfoPanel } from 'dls'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const SubtitleStyle = styled(Typography)`
  color: ${(props: any) => props.theme.palette.text};
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.14px;
`

const StyledDialogActions = styled(DialogActions)`
  padding-top: 20px;
  padding-right 0px;
`

interface OwnProps {
  onPressCancel: () => void
  availableProcess?: Process[]
  isEditmode?: boolean
}

interface ProcessFormProps extends FormikProps<ProcessFormValues>, OwnProps {}

const CreateProcessDialogContent = ({ onPressCancel, availableProcess, isEditmode, values }: ProcessFormProps) => {
  const { t } = useTranslation()
  const selectDataLifeCycle: RadioGroupFieldData = {}
  Object.entries(LifeCycle).forEach(([, item]) => {
    selectDataLifeCycle[t(`PROCESS_ENTITY_LIFECYCLE.${item.toUpperCase()}`)] = {
      name: item,
      label: t(`PROCESS_ENTITY_LIFECYCLE.${item.toUpperCase()}`),
      disabled: !!isEditmode,
    }
  })
  const checkLifeCycle: any = {}
  if (availableProcess) {
    availableProcess.map((item) => {
      if (item.entityLifecycle.toLowerCase() === LifeCycle.CREATE) {
        checkLifeCycle[`PROCESS_ENTITY_LIFECYCLE.CREATE`] = {
          name: LifeCycle.CREATE,
          label: t(`PROCESS_ENTITY_LIFECYCLE.CREATE`),
          disabled: true,
        }
        checkLifeCycle[`PROCESS_ENTITY_LIFECYCLE.UPDATE`] = {
          name: LifeCycle.UPDATE,
          label: t(`PROCESS_ENTITY_LIFECYCLE.UPDATE`),
          disabled: false,
        }
      }
      if (item.entityLifecycle.toLowerCase() === LifeCycle.UPDATE) {
        checkLifeCycle[`PROCESS_ENTITY_LIFECYCLE.UPDATE`] = {
          name: LifeCycle.UPDATE,
          label: t(`PROCESS_ENTITY_LIFECYCLE.UPDATE`),
          disabled: true,
        }
        checkLifeCycle[`PROCESS_ENTITY_LIFECYCLE.CREATE`] = {
          name: LifeCycle.CREATE,
          label: t(`PROCESS_ENTITY_LIFECYCLE.CREATE`),
          disabled: false,
        }
      }
      return checkLifeCycle
    })
  }
  const selectedEntityValue = getIn(values, 'entityLifecycle')

  return (
    <Form>
      <ModalWrapper
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography variant="h6">{t('Create a new approval process')}</Typography>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoPanel
              title={t(
                'Currently you can only create one process for creating a product and a second process for updating a product'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <SubtitleStyle gutterBottom>{t('What is the governance process for?')}</SubtitleStyle>
            <Field
              name="entityLifecycle"
              component={RadioGroupFieldComplex}
              required
              validate={Yip(Yup.string().required())}
              data={availableProcess && availableProcess.length >= 1 ? checkLifeCycle : selectDataLifeCycle}
            />
          </Grid>

          {/* Temporarily removal of feature approval, So just commenting the code */}

          {/* <Grid css="padding-top: 0px !important" container item xs={12}>
            <Grid item xs={1}>
              <PlaylistAddCheckIcon fontSize="large" />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h6" gutterBottom>
                {t('Feature approval')}
              </Typography>
              <SubtitleStyle gutterBottom>
                {t(
                  'Feature approval will automatically create a task for every feature in your product. This is ideal if you have specific product team leads. Be aware that this will lengthen your process, but makes it more comprehensive. '
                )}
              </SubtitleStyle>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end" alignItems="center" direction="row">
                <ToggleGridStyle item>
                  <Field name="featureTasksGenerationStrategy" component={SwitchFieldComplex} />
                </ToggleGridStyle>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <Sticky>
          <StyledDialogActions>
            <Button color={ColorType.BUTTON} type="button" onClick={onPressCancel}>
              {t('Cancel')}
            </Button>
            <SubmitButton
              css="margin-left:16px !important"
              disabled={!selectedEntityValue}
              type="submit"
              color={ColorType.BUTTON}
              variant="contained"
            >
              {t('Create')}
            </SubmitButton>
          </StyledDialogActions>
        </Sticky>
      </ModalWrapper>
    </Form>
  )
}

export default withFormikSimple<OwnProps, ProcessFormValues>(CreateProcessDialogContent)
