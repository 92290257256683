import React from 'react'
import { Typography, Paper, Step, StepLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ContentContainer from '../ContentContainer'
import { StyledStepper } from 'components/ProductPackageStepper/Style'
import {
  StatusDesignStepIcon,
  StatusLiveStepIcon,
  StatusScheduledStepIcon,
} from 'components/ProductPackageStepper/StepIcons'
import { Flexbox } from 'pages/Layout/Styled'
import { TemplateReview } from 'components'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

type Props = React.PropsWithChildren<{
  name: string
  activeStep: number
  ariaLabel?: string
  infoIconAlt?: string
  infographicType?: HelpModalType
}>

const EntityReviewPageLayout = ({
  children,
  name,
  activeStep = 0,
  infoIconAlt = 'How packages work',
  infographicType = HelpModalType.PRODUCT_LIFECYCLE,
}: Props): React.ReactElement => {
  const { t } = useTranslation()

  const steps = [
    { label: 'Design', icon: StatusDesignStepIcon },
    { label: 'Scheduled', icon: StatusScheduledStepIcon },
    { label: 'Live', icon: StatusLiveStepIcon },
  ]

  const { showInfographicModal } = useModal()

  return (
    <ContentContainer data-test-id="entity-review-page">
      <TemplateReview
        header={
          <>
            <Typography variant="h1" style={{ paddingBottom: 24 }}>
              {name}
            </Typography>
            <Paper style={{ padding: '20px 16px' }}>
              <Flexbox direction="row" width="100%">
                <StyledStepper activeStep={activeStep} elevation={0}>
                  {steps.map(({ label, icon, ...otherProps }, index) => (
                    <Step key={index} {...otherProps}>
                      <StepLabel
                        StepIconComponent={activeStep === index ? icon : undefined}
                        StepIconProps={{
                          'aria-hidden': false,
                          active: activeStep === index,
                          completed: activeStep > index,
                        }}
                      >
                        {t(label)}
                      </StepLabel>
                    </Step>
                  ))}
                </StyledStepper>
                <InfoIconButton
                  key="more-about-icon"
                  ariaProps={{
                    'aria-label': `${t(infoIconAlt)}`,
                    'aria-haspopup': 'dialog',
                    'aria-controls': 'more-about-dialog',
                    title: `${t(infoIconAlt)}`,
                  }}
                  onClick={() => showInfographicModal(infographicType)}
                  fontSize="medium"
                />
              </Flexbox>
            </Paper>
          </>
        }
        main={children}
      />
    </ContentContainer>
  )
}

export default EntityReviewPageLayout
