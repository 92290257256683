import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { ModalState, ModalType } from './types'
import { hexCodeToString } from 'utils/common.util'

const initialState: ModalState = {
  modal: null,
}

const reducer = createReducer<ModalState>(initialState, {
  [actions.closeModal.type]: () => ({
    modal: null,
  }),
  [actions.showModal.type]: (_state, action) => ({
    modal: action.payload,
  }),
  [actions.showFeatureProgressModal.type]: () => ({
    modal: {
      modalType: ModalType.PROCESS,
      title: 'Processing...',
      message: 'Please wait while we process the request',
    },
  }),
  [actions.showInfographicModal.type]: (_state, action: ReturnType<typeof actions.showInfographicModal>) => ({
    modal: {
      helpModalType: action.payload,
      modalType: ModalType.INFOGRAPHIC,
    },
  }),
  [actions.showFeatureSuccessModal.type]: (_state, action) => ({
    modal: {
      modalType: ModalType.SUCCESS,
      title: action.payload.title || `${hexCodeToString(action.payload.featureName)} successfully submitted`,
      message: action.payload.message,
    },
  }),
  [actions.showFeatureDeletedModal.type]: (_state, action) => ({
    modal: {
      modalType: ModalType.SUCCESS,
      title: `${hexCodeToString(action.payload.featureName)} successfully deleted`,
    },
  }),
  [actions.showFeatureFailureModal.type]: () => ({
    modal: {
      modalType: ModalType.ERROR,
      title: 'Oops, something went wrong',
      message: 'Please go back and try again',
    },
  }),
})

export default reducer
