import React, { CSSProperties } from 'react'
import styled from 'styled-components/macro'
import { ImageTypes } from 'dls/shared/types'

export const StyledImg = styled.img`
  object-fit: contain;
  max-width: 100%;
`

interface Props extends ImageTypes {
  className?: string
  style?: CSSProperties
  component?: string
}

export const LogoLarge = ({ ariaProps, style, className, ...props }: Props) => (
  <StyledImg
    data-dls="atom-logo-large"
    title={props.title || props.alt}
    style={{ ...style, width: 212, height: 54, boxSizing: 'content-box' }}
    className={className}
    {...props}
    {...ariaProps}
  />
)

export default LogoLarge
