export default {
  loading: false,
  updating: false,
  error: undefined,
  data: {},
  dataLoading: false,
  selectedProduct: undefined,
  colleagues: [],
  assigneeModalOpen: false,
  review: false,
  reviewError: undefined,
  taskLoader: false,
  publishing: false,
  publishSuccess: false,
  publishError: undefined,
  reviewNoUserError: undefined,
  reviewGeneralError: undefined,
  getVersionsLoading: false,
  getVersionsFail: false,
  productVersionsLoading: false,
  productVersionsFail: false,
  versions: [],
  cardImages: [],
  cardImagesLoading: false,
  cardImagesError: undefined,
  productPermissions: { isCreditInterestEnabled: true },
  transactionRulesDefaultAccessPutting: false,
  transactionRulesDefaultAccessPuttingSuccess: false,
  transactionRulesDefaultAccessPuttingError: undefined,
  transactionRuleDeleting: false,
  transactionRuleDeletingSuccess: false,
  transactionRuleDeletingError: undefined,
  paymentSchedulesDefaultAccessSetting: false,
  paymentSchedulesDefaultAccessSettingSuccess: false,
  paymentSchedulesDefaultAccessSettingError: undefined,
  selectedPaymentSchedules: undefined,
  createNewProductVersionLoading: false,
  createNewProductVersionFail: false,
  createNewProductSuccess: false,
  productSyndication: null,
  productSyndicationLoading: false,
}
