import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ConfigurationCard from './TemplatesCard'
import { Catalog } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import { EntityBadgeStatements } from 'dls/atoms'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { HelpModalType } from 'store/modal/types'

const TemplatesPage = () => {
  const { t } = useTranslation()
  const { showInfographicModal } = useModal()

  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  useBreadcrumbs({
    breadcrumbs: [{ title: t('{{tenantName}} Templates', { tenantName: currentTenant?.name }), path: '' }],
    trigger: true,
  })

  const settings = [
    {
      title: 'Interest statements',
      goto: '/templates/statement-configurations',
      show: true,
      icon: <EntityBadgeStatements />,
    },
  ]

  const infoButton = (
    <InfoIconButton
      key="more-about-icon"
      ariaProps={{
        'aria-label': `${t('About interest statements')}`,
        'aria-haspopup': 'dialog',
        'aria-controls': 'more-about-dialog',
      }}
      onClick={() => showInfographicModal(HelpModalType.INTEREST_STATEMENTS)}
      fontSize="medium"
      css="margin-right:6px;"
    />
  )

  return (
    <ContentContainer data-test-id="templates">
      <Catalog
        entityName="Templates"
        list={settings}
        searchFilter={(item, text) => item.title.toLowerCase().includes(text)}
        action={infoButton}
        searchButtonAriaLabel={t('Search templates')}
        cardRenderer={(list, idx, key) => (
          <CatalogCardContainer>
            {list[idx].show && (
              <ConfigurationCard key={key} goto={list[idx].goto} title={list[idx].title} icon={list[idx].icon} />
            )}
          </CatalogCardContainer>
        )}
      />
    </ContentContainer>
  )
}

export default TemplatesPage
