import { Typography } from '@material-ui/core'
import { orderBy } from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { TableCommonDataType, TableCommonHeaderType } from 'components/TableCommon/types'
import { AvailableProducts } from 'store/packages/types'
import { VersionStatusBadge } from 'components'
import { Product } from 'store/products/types'
import { orderProductSecondCondition } from 'utils/productVersion.utils'

const VersionBadgeMargins = styled.div<{ $hasMargin: boolean }>`
  margin-bottom: ${(props: any) => (props.$hasMargin ? `15px` : null)};
`

export const headRows: TableCommonHeaderType[] = [
  {
    id: 'productName',
    align: 'left',
    label: (
      <Typography variant="body2" style={{ fontWeight: 500 }}>
        Product name
      </Typography>
    ),
    css: 'width: 50%; vertical-align: text-top;',
    getCellContent: (row: TableCommonDataType) => {
      const product = row as AvailableProducts
      return (
        <Typography variant="body2" style={{ marginLeft: -16 }}>
          {product.productName}
        </Typography>
      )
    },
  },
  {
    id: 'version',
    align: 'left',
    label: (
      <Typography variant="body2" style={{ marginBottom: 12 }}>
        Version, status and name
      </Typography>
    ),
    css: 'width: 50%; font-weight: normal;',
    disableOrder: true,
    getCellContent: (row: TableCommonDataType) => {
      const { versions } = row as AvailableProducts
      return orderBy(versions || [], ['version', orderProductSecondCondition], ['desc', 'desc']).map(
        (i: Product, index) => {
          const shouldShowName = (versions?.length || 0) > 1

          return (
            <VersionBadgeMargins
              key={i.productKey}
              $hasMargin={shouldShowName && index !== (versions?.length || 0) - 1}
            >
              <VersionStatusBadge
                version={i.version}
                status={i.status}
                name={shouldShowName ? i.productName : ''}
                date={i.effectiveDate}
              />
            </VersionBadgeMargins>
          )
        }
      )
    },
  },
]
