export interface PaymentLimitsResponse {
  paymentLimits: PaymentLimit[]
  config: PaymentLimitsConfig
}

export interface PaymentLimit {
  limitRuleId?: string
  direction: PaymentLimitDirection
  channel: PaymentLimitChannel
  routingDestinations: PaymentLimitRoutingDestination[]
  range: PaymentLimitRange
  amount: PaymentLimitAmount
  enableSubscriptionOverride: boolean
}

export enum PaymentLimitDirection {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum PaymentLimitChannel {
  MOBILE = 'MOBILE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum PaymentLimitRoutingDestination {
  ON_US = 'ON_US',
  OUTBOUND_PAYMENT = 'OUTBOUND_PAYMENT',
  TRANSFER = 'TRANSFER',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum PaymentLimitRange {
  TRANSACTION = 'TRANSACTION',
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

interface PaymentLimitAmount {
  minimum?: number
  maximum?: number
  type: PaymentLimitAmountType
}

export enum PaymentLimitAmountType {
  VOLUME = 'VOLUME',
  MONETARY = 'MONETARY',
}

export type PaymentLimitsConfig = {
  yearType: PaymentLimitsConfigYearType
  taxYearStart?: string
}

export interface PaymentLimitsConfigFormValues {
  yearType?: string
  startMonth?: string
  startDay?: string
}

export interface PaymentLimitFormValues {
  limitRuleId?: string
  limitName?: string
  enableSubscriptionOverride?: boolean
  minimum?: number
  maximum?: number
}

export interface PaymentLimitURLValues {
  direction: PaymentLimitDirection
  channel: PaymentLimitChannel
  routingDestinations: PaymentLimitRoutingDestination[]
  range: PaymentLimitRange
  amountType: PaymentLimitAmountType
}

export enum PaymentLimitsConfigYearType {
  ANNIVERSARY = 'ANNIVERSARY',
  CALENDAR = 'CALENDAR',
  TAX = 'TAX',
}

export enum PaymentLimitType {
  OUTBOUND_SINGLE_SELF = 'OUTBOUND_SINGLE_SELF',

  OUTBOUND_CUMULATIVE_SELF_DAY = 'OUTBOUND_CUMULATIVE_SELF_DAY',
  OUTBOUND_CUMULATIVE_SELF_MONTH = 'OUTBOUND_CUMULATIVE_SELF_MONTH',
  OUTBOUND_CUMULATIVE_SELF_YEAR = 'OUTBOUND_CUMULATIVE_SELF_YEAR',

  OUTBOUND_MAX_SELF_DAY = 'OUTBOUND_MAX_SELF_DAY',
  OUTBOUND_MAX_SELF_MONTH = 'OUTBOUND_MAX_SELF_MONTH',
  OUTBOUND_MAX_SELF_YEAR = 'OUTBOUND_MAX_SELF_YEAR',

  OUTBOUND_SINGLE_PARTY = 'OUTBOUND_SINGLE_PARTY',

  OUTBOUND_CUMULATIVE_PARTY_DAY = 'OUTBOUND_CUMULATIVE_PARTY_DAY',
  OUTBOUND_CUMULATIVE_PARTY_MONTH = 'OUTBOUND_CUMULATIVE_PARTY_MONTH',
  OUTBOUND_CUMULATIVE_PARTY_YEAR = 'OUTBOUND_CUMULATIVE_PARTY_YEAR',

  OUTBOUND_MAX_PARTY_DAY = 'OUTBOUND_MAX_PARTY_DAY',
  OUTBOUND_MAX_PARTY_MONTH = 'OUTBOUND_MAX_PARTY_MONTH',
  OUTBOUND_MAX_PARTY_YEAR = 'OUTBOUND_MAX_PARTY_YEAR',

  INBOUND_SINGLE = 'INBOUND_SINGLE', //

  INBOUND_CUMULATIVE_DAY = 'INBOUND_CUMULATIVE_DAY', //
  INBOUND_CUMULATIVE_MONTH = 'INBOUND_CUMULATIVE_MONTH', //
  INBOUND_CUMULATIVE_YEAR = 'INBOUND_CUMULATIVE_YEAR', //

  INBOUND_MAX_DAY = 'INBOUND_MAX_DAY', //
  INBOUND_MAX_MONTH = 'INBOUND_MAX_MONTH', //
  INBOUND_MAX_YEAR = 'INBOUND_MAX_YEAR', //
}
