import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { PaymentLimit, PaymentLimitRoutingDestination } from 'store/products/typings/paymentLimits'
import { Caption, ReviewSubLabel } from 'dls'
import { getEditUrl, addUrls } from 'pages/features/PaymentLimits/utilities/actionUrls'

interface OwnProps {
  labelKey: string
  paymentLimit: PaymentLimit
  currencySymbol: string
  showActions: boolean
  addUrlCtaKey: 'cumulativeDay' | 'cumulativeMonth' | 'cumulativeYear'
  routingDestination: PaymentLimitRoutingDestination
  allowCustomerOverride: boolean
  testId?: string
}

const CumulativeLimitSubValue = ({
  labelKey,
  paymentLimit,
  currencySymbol,
  showActions = false,
  routingDestination,
  addUrlCtaKey,
  allowCustomerOverride,
  testId,
}: OwnProps) => {
  const { t } = useTranslation()
  return (
    <>
      <ReviewSubLabel
        label={t(labelKey)}
        buttonComponentType={RouterLink}
        testId={`${testId}-cumulative-limit`}
        value={
          paymentLimit?.amount?.maximum ? (
            <>
              {currencySymbol}
              {paymentLimit.amount.maximum}
            </>
          ) : null
        }
        editCtaLink={showActions ? getEditUrl(paymentLimit?.limitRuleId || '') : undefined}
        addCtaLink={showActions ? addUrls(routingDestination)[addUrlCtaKey] : null}
      />
      {paymentLimit?.enableSubscriptionOverride && allowCustomerOverride && (
        <Caption variantMapping={{ caption: 'div' }} style={{ marginBottom: 8 }}>
          {t('Customer override')}: <span style={{ fontWeight: 'bold' }}>Yes</span>
        </Caption>
      )}
    </>
  )
}

export default CumulativeLimitSubValue
