import { call, put, delay } from 'redux-saga/effects'
import { ExtendedProduct, FeatureName } from '../types'
import { navigate } from 'store/router/actions'
import ModalActions from 'store/modal/actions'
import { PaymentScheduleApi } from 'api'
import ProductsActions from 'store/products/actions'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'

export function* setPaymentScheduleSaga(action: ReturnType<typeof ProductsActions.setPaymentSchedule>) {
  try {
    const {
      payload: { productKey, version, ...formData },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    yield call(PaymentScheduleApi.setPaymentSchedule, productKey, version, formData)
    yield put(navigate(`/products/${productKey}`))
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showFeatureSuccessModal({
        featureName: FeatureName.PAYMENT_SCHEDULE,
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* deletePaymentScheduleSaga(action: ReturnType<typeof ProductsActions.deletePaymentSchedule>) {
  try {
    const {
      payload: { productKey },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    yield call(PaymentScheduleApi.deletePaymentSchedule, productKey)
    yield put(ProductsActions.getProductDetails({ productKey }))
    yield put(navigate(`/products/${productKey}`))
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showFeatureDeletedModal({
        featureName: FeatureName.PAYMENT_SCHEDULE,
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}
