import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box } from '@material-ui/core'
import { EntityBadgeCardLimit } from 'dls/atoms'
import { InfoPanel, Paper, Body1, CardHeader } from 'dls'
import { CardTransactionLimit } from 'store/products/typings/cardLimits'
import CashWithdrawalLimitsRow from 'pages/features/CardTransactionLimits/components/shared/CashWithdrawalLimitsRow'
import NonCashTransactionLimitsRow from 'pages/features/CardTransactionLimits/components/shared/NonCashTransactionLimitsRow'

interface OwnProps {
  cardTransactionLimits: CardTransactionLimit[]
  currencyCode: string
  productKey: string
}

const CardTransactionLimitsPageContent = ({ cardTransactionLimits, currencyCode, productKey }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="column" spacing={3} style={{ paddingTop: 0 }}>
      <Grid item>
        <InfoPanel
          color="info"
          body={t(
            'Please note – local card schemes/acquirers may have their own limits. These will be enforced outside of this banking platform.'
          )}
        />
      </Grid>
      <Grid item>
        <Paper>
          <CardHeader
            title={<Body1 variantMapping={{ body1: 'h3' }}>{t('Card transaction limits')}</Body1>}
            headerIcon={<EntityBadgeCardLimit />}
          />
          <Box mb={2} mt={3.5}>
            <CashWithdrawalLimitsRow
              cardTransactionLimits={cardTransactionLimits}
              currencyCode={currencyCode}
              hasSeparators
              showActions
              productKey={productKey}
            />
          </Box>
          <NonCashTransactionLimitsRow
            cardTransactions={cardTransactionLimits}
            currencyCode={currencyCode}
            hasSeparators
            showActions
            productKey={productKey}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CardTransactionLimitsPageContent
