import { Box, Grid, Paper } from '@material-ui/core'
import { Form, FormikProps, getIn } from 'formik'
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { TFunction } from 'i18next'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { InfoOutlined } from '@material-ui/icons'
import { StatementsElement } from './StatementsElement'
import InterestStatement from './InterestStatement'
import {
  FormAddFeatureButton,
  FormBottomBar,
  SectionArray,
  ZeroStateLandingPage,
  ConfirmModalDeprecated,
} from 'components'
import { MultipleChoiceElement, StatementFormValues, StatementType } from 'store/products/types'
import { stopPropagation, withFormikSimple } from 'utils/form.utils'
import { NoInterestStatementsProduct, DeleteInfo } from 'assets'
import FeaturePageWithTopAddLayout from 'pages/features/FeaturePageWithTopAddLayout'
import { FeatureFormPageProps } from 'pages/features/FeaturePage.page'
import { DeleteIconButton, Accordion, Body1, Body2, InfoIconButton } from 'dls'
import ProductsActions from 'store/products/actions'
import { featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'
import { selectFeatures } from 'store/tenant-config/selectors'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'
import StatementConfigActions from 'store/templates/statementConfiguration/actions'

type StatementFormProps = FormikProps<StatementFormValues> & FeatureFormPageProps & OwnProps

interface OwnProps {
  // eslint-disable-next-line react/no-unused-prop-types
  hasFeature?: boolean
}

const isFeesChargesMenuItemDisabled = (values: StatementFormValues, statementType: StatementType) =>
  find(values.statementValue, { statementType }) !== undefined

const getStatementsMenuItem = (
  statementType: StatementType,
  values: StatementFormValues,
  t: TFunction,
  statementConfigKey?: string | null
): MultipleChoiceElement => ({
  defaultValue: {
    statementType,
    hideExternalIdentifiers: false,
    offsetDays: statementType === StatementType.INTEREST_STATEMENT ? null : 1,
    statementConfigKey,
  },
  multiple: true,
  name: 'statementValue',
  label: t(statementType),
  disabled: isFeesChargesMenuItemDisabled(values, statementType),
})

const StatementForm = (props: StatementFormProps) => {
  const { productKey = '' } = useParams()
  const dispatch = useDispatch()
  const { values, product, validateForm } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = useState<number | null>(null)
  const isInterestStatementsEnabled = useSelector(selectFeatures)?.menu_templates
  const { showInfographicModal } = useModal()

  useEffect(() => {
    validateForm()
  }, [values.statementValue?.length])

  const clearDraftStatementFormValues = () => dispatch(StatementConfigActions.updateDraftStatementFormValues({}))

  const onPressBack = useCallback(() => {
    clearDraftStatementFormValues()
    navigate(`/products/${productKey}`)
  }, [productKey])

  const productInterestStatement = product?.statements?.find(
    (statement) => statement.statementType === StatementType.INTEREST_STATEMENT
  )

  const updateDraftFormValues = () => dispatch(StatementConfigActions.updateDraftStatementFormValues(values))

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Statements')}
      description={t('INTERESTSTATEMENTDESCRIPTION')}
      rightArea={
        <div>
          <InfoIconButton
            key="more-about-icon"
            ariaProps={{
              'aria-label': `${t('About statements')}`,
              'aria-haspopup': 'dialog',
              'aria-controls': 'more-about-dialog',
            }}
            onClick={() => showInfographicModal(HelpModalType.INTEREST_STATEMENTS_PRODUCT)}
            fontSize="medium"
            style={{ marginRight: 8 }}
          />
          <FormAddFeatureButton
            addLabel={t('Add statement')}
            elements={{
              [StatementType.ACCOUNT_STATEMENT]: {
                ...getStatementsMenuItem(StatementType.ACCOUNT_STATEMENT, values, t),
              },
              [StatementType.FEES_STATEMENT]: {
                ...getStatementsMenuItem(StatementType.FEES_STATEMENT, values, t),
              },
              ...(isInterestStatementsEnabled
                ? {
                    [StatementType.INTEREST_STATEMENT]: {
                      ...getStatementsMenuItem(
                        StatementType.INTEREST_STATEMENT,
                        values,
                        t,
                        productInterestStatement?.statementConfigKey
                      ),
                    },
                  }
                : {}),
            }}
          />
        </div>
      }
    >
      <Form>
        {product && values.statementValue && values.statementValue.length ? (
          <div style={{ marginBottom: 16 }}>
            <SectionArray name="statementValue" validateOnChange>
              {({ remove, name, index }) => {
                const statementType: StatementType = getIn(values, `${name}.statementType`)
                const draftStatementConfigKey = getIn(values, `${name}.statementConfigKey`)
                const empty = !getIn(values, `${name}.statementPeriod`)
                const statementsLength = values?.statementValue?.length ?? 0
                const shouldDeleteFeature = statementsLength === 1 && props.hasFeature
                return (
                  <div key={`${statementType}-${draftStatementConfigKey}`} style={{ marginBottom: 16 }}>
                    <ConfirmModalDeprecated
                      id={`delete-statement-${index}`}
                      onCancelClick={() => setOpenConfirm(null)}
                      onConfirmClick={{
                        action: () => {
                          setOpenConfirm(null)
                          remove(index)
                        },
                        title: t('Ok, got it'),
                      }}
                      open={openConfirm === index}
                      title={t(`{{name}} will only be deleted when you submit the form`, {
                        name: t(statementType),
                      })}
                      image={{ src: DeleteInfo, alt: t('Delete Icon') }}
                      imageStyle={{ marginBottom: '24px' }}
                      cancelText="Cancel"
                    />
                    <Accordion
                      title={<Body1>{t(statementType)}</Body1>}
                      headerIcon={featureIconsCreator(featureSVGIcons.statements)}
                      headerButtonGroup={
                        <DeleteIconButton
                          onClick={(e: React.MouseEvent) => {
                            stopPropagation(e)
                            if (shouldDeleteFeature) {
                              dispatch(
                                ProductsActions.deleteFeature({
                                  featureName: 'statements',
                                  productVersion: product.version,
                                  productKey,
                                })
                              )
                            } else {
                              setOpenConfirm(index)
                            }
                          }}
                        />
                      }
                      defaultExpanded={empty}
                    >
                      {statementType === StatementType.INTEREST_STATEMENT ? (
                        <InterestStatement
                          name={name}
                          product={product}
                          draftStatementConfigKey={draftStatementConfigKey}
                          updateDraftFormValues={updateDraftFormValues}
                        />
                      ) : (
                        <StatementsElement name={name} product={product} />
                      )}
                    </Accordion>
                  </div>
                )
              }}
            </SectionArray>
          </div>
        ) : (
          <Paper style={{ paddingTop: 40, marginBottom: 15 }}>
            <ZeroStateLandingPage
              image={NoInterestStatementsProduct}
              fixHeight={false}
              title={t('No statements have been added or configured')}
              imageAlt={t('Illustration of a folder containing statements')}
            >
              <Body2 variant="body2" style={{ marginTop: -10 }}>
                {t('Select "Add statement" above to get started or')}
              </Body2>
              <Box display="flex" justifyContent="center" mb={12.5}>
                <Body2>find out </Body2>
                <LinkWithText
                  onClick={() => showInfographicModal(HelpModalType.INTEREST_STATEMENTS_PRODUCT)}
                  component="span"
                  role="button"
                  endIcon={<InfoOutlined />}
                  aria-controls="more-about-dialog"
                  aria-haspopup="dialog"
                  style={{ verticalAlign: 'middle', display: 'line-block' }}
                >
                  {t('more about statements')}
                </LinkWithText>
              </Box>
            </ZeroStateLandingPage>
          </Paper>
        )}
        <Grid item xs={12}>
          <FormBottomBar position="bottom" noDirty>
            <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
              {t('Cancel')}
            </Button>
          </FormBottomBar>
        </Grid>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

const validationSchema = Yup.object().shape({
  statementValue: Yup.array()
    .of(
      Yup.object().shape({
        statementPeriod: Yup.string().when('statementType', {
          is: StatementType.ACCOUNT_STATEMENT,
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        statementDescription: Yup.string()
          .nullable()
          .matches(/^[^%]*$/, '% character is not allowed')
          .max(200, 'must be at most 200 characters'),
        template: Yup.object().when('statementType', {
          is: (value) => value === StatementType.ACCOUNT_STATEMENT || value === StatementType.FEES_STATEMENT,
          then: Yup.object({ id: Yup.string().required() }).required(),
          otherwise: Yup.object().nullable(),
        }),
        statementConfigKey: Yup.string().when('statementType', {
          is: (value) => value === StatementType.INTEREST_STATEMENT,
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
      })
    )
    .nullable(),
})

export default withFormikSimple<FeatureFormPageProps & OwnProps, StatementFormValues>(StatementForm, {
  isInitialValid: (props) => !!props.hasFeature,
  validationSchema,
})
