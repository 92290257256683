import React from 'react'
import styled from 'styled-components/macro'
import { Box } from '@material-ui/core'
import {
  ConditionDataPoint,
  ConditionValue,
  DataPointAggregatedOperator,
  DataPointOperator,
} from 'store/products/typings/conditionBuilder'
import { Body2 } from 'dls/atoms/Typography'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { FeeRewardDataPointType } from 'store/products/features/feesRewards/types'
import { transactionCodeMap } from 'static/transactionCodes'

const UnorderedList = styled(Box)`
  list-style-type: disc;
  margin: 16px 0 0 0;
  padding-left: 14px;
`

interface Props {
  values: ConditionValue[]
  dataPoint: ConditionDataPoint
  operator: DataPointOperator | DataPointAggregatedOperator
}

const ConditionValuesList = ({ values, operator, dataPoint }: Props): JSX.Element | null => {
  if (dataPoint.id === FeeRewardDataPointType.TRANSACTION_ISO_CODE) {
    return (
      <UnorderedList component="ul" flexDirection="column" justifyContent="flex-start">
        {values.map((item) => (
          <li key={item.value}>
            <Body2 variantMapping={{ body2: 'span' }}>{transactionCodeMap[item.value]}</Body2>
            <Body2 variantMapping={{ body2: 'span' }} color="textSecondary">{` (${item.value})`}</Body2>
          </li>
        ))}
      </UnorderedList>
    )
  }

  switch (operator) {
    // case DataPointOperator.IN:
    // API Bug, it returns with EQUAL_TO instead of IN
    case DataPointOperator.EQUAL_TO:
    case DataPointOperator.LESS_THAN:
    case DataPointOperator.LESS_THAN_OR_EQUAL_TO:
    case DataPointOperator.GREATER_THAN:
    case DataPointOperator.GREATER_THAN_OR_EQUAL_TO:
      switch (values[0]?.type) {
        case 'currency':
          return (
            <Body2 variantMapping={{ body2: 'span' }}>
              <CurrencySymbol noTypography currencyCode={values[0]?.currencyCode} />
              {values[0]?.value}
            </Body2>
          )
        case 'number':
          return (
            <Body2 variantMapping={{ body2: 'span' }} css="text-transform: lowercase;">
              {values[0]?.value}
            </Body2>
          )
        default:
          return null
      }
    default:
      return null
  }
}

export default ConditionValuesList
