import React from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { PaymentLimit, PaymentLimitRoutingDestination } from 'store/products/typings/paymentLimits'
import { H6, CardHeader, ReviewRows } from 'dls'
import {
  getOutBoundSingleToSelf,
  getOutBoundCumulativeToSelfDay,
  getOutBoundCumulativeToSelfMonth,
  getOutBoundCumulativeToSelfYear,
  getOutBoundMaxToSelfDay,
  getOutBoundMaxToSelfMonth,
  getOutBoundMaxToSelfYear,
} from 'pages/features/PaymentLimits/utilities/transformers'
import { getCurrencyInfo } from 'api/utils'
import buildDefinitions from 'pages/features/PaymentLimits/utilities/buildDefinitions'

interface OwnProps {
  paymentLimits: PaymentLimit[]
  currencyCode: string
  showActions?: boolean
  hasSeparators?: boolean
  productKey?: string
  inline?: boolean
}

const TransferToSelfRow = ({
  paymentLimits,
  currencyCode,
  showActions = false,
  hasSeparators = false,
  productKey = '',
  inline = false,
}: OwnProps) => {
  const { t } = useTranslation()

  const definitions = buildDefinitions({
    single: getOutBoundSingleToSelf(paymentLimits) as any,
    cumulativeDay: getOutBoundCumulativeToSelfDay(paymentLimits) as any,
    cumulativeMonth: getOutBoundCumulativeToSelfMonth(paymentLimits) as any,
    cumulativeYear: getOutBoundCumulativeToSelfYear(paymentLimits) as any,
    maxTransactionsDay: getOutBoundMaxToSelfDay(paymentLimits) as any,
    maxTransactionsMonth: getOutBoundMaxToSelfMonth(paymentLimits) as any,
    maxTransactionsYear: getOutBoundMaxToSelfYear(paymentLimits) as any,
    currencySymbol: getCurrencyInfo(currencyCode)?.nativeSymbol || '',
    productKey,
    t,
    hasSeparators,
    showActions,
    routingDestination: PaymentLimitRoutingDestination.TRANSFER,
    ariaLabelledBy: 'to-self-heading',
    allowCustomerOverride: false,
    testId: 'outbound-to-self',
  })

  return (
    <>
      <Box mb={3}>
        <CardHeader
          title={
            inline ? (
              <H6 variantMapping={{ h6: 'h5' }} id="to-self-heading" style={{ fontWeight: 'bold' }}>
                {t('Customer transfer to themself within bank')}
              </H6>
            ) : (
              <H6 variantMapping={{ h6: 'h4' }} id="to-self-heading">
                {t('Transfer to themself within bank')}
              </H6>
            )
          }
          headerIcon={inline ? <PersonOutlineIcon /> : undefined}
        />
      </Box>
      <ReviewRows definitionsList={definitions} />
    </>
  )
}

export default TransferToSelfRow
