import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Grid, Box } from '@material-ui/core'
import PreviewConfigurationDialog from './PreviewConfigurationDialog'
import StatementPreviewImage from 'pages/features/Statements/StatementPreviewImage'
import { Body2 } from 'dls'
import { ExtendedProduct, StatementType } from 'store/products/types'
import { generateMonth } from 'utils/form.utils'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'
import { useDialogs } from 'components'

type Props = {
  product: ExtendedProduct
  additionalContent: React.ReactNode
  configuration: StatementConfiguration
  updateDraftFormValues: () => void
}

const SelectedTemplateSummary = ({ product, additionalContent, configuration, updateDraftFormValues }: Props) => {
  const { t } = useTranslation()
  const { isDialogOpen, onOpenDialog, onCloseDialog } = useDialogs()

  const template = {
    templateGroupId: configuration.template?.id ?? '',
    templateName: configuration.template?.name ?? '',
    isDefault: false,
  }

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="stretch">
        <Grid item>
          <Box display="grid" gridTemplateColumns="160px 1fr" alignItems="center" gridGap={24}>
            <Box position="relative">
              <StatementPreviewImage
                template={template}
                productSegment={product.productSegment}
                templateType={StatementType.INTEREST_STATEMENT}
                previewTemplateOpen={onOpenDialog}
                editPath={`/products/${product.productKey}/features/statements/choose`}
                updateDraftFormValues={updateDraftFormValues}
              />
            </Box>
            <div>
              <Body2 css="font-weight: bold; margin-bottom: 16px" variantMapping={{ body2: 'h4' }}>
                {t(configuration.name)}
              </Body2>
              <Body2 gutterBottom color="textSecondary">
                {t('Segment and description')}
              </Body2>
              <Body2 css="margin-bottom: 16px;">{`${t(configuration.productSegment)}: ${
                configuration.statementDescription
              }`}</Body2>
              <Body2 gutterBottom color="textSecondary">
                {t('Statement generation')}
              </Body2>
              <Box display="flex" gridGap={24}>
                <Body2>
                  {`${t('PERIOD.YEAR')}: ${configuration.startDay} ${generateMonth(configuration.startMonth || 1)} `}
                </Body2>
                <Body2>
                  {`${t('Offset')}: ${t(`{{offset}} days`, {
                    offset: configuration.offsetDays,
                  })}
                `}
                </Body2>
              </Box>
              {additionalContent}
            </div>
          </Box>
        </Grid>
      </Grid>
      {isDialogOpen && (
        <PreviewConfigurationDialog
          title="Statement template"
          open={isDialogOpen}
          onClose={onCloseDialog}
          templateType={StatementType.INTEREST_STATEMENT}
          productSegment={product.productSegment}
          statementConfig={configuration}
          displayConfigInfo
        />
      )}
    </>
  )
}

export default SelectedTemplateSummary
