import React, { ReactElement } from 'react'
import { Menu } from '@material-ui/core'
import { Link, useNavigate } from 'react-router-dom'
import { ModalProps } from '@material-ui/core/Modal'
import styled from 'styled-components/macro'
import { PopoverProps } from '@material-ui/core/Popover/Popover'
import { Button } from 'dls/atoms/Button'
import { MenuItem } from 'dls/atoms/MenuItem'
import { Link as DLSLink } from 'dls/atoms/Link'

export const StyledMenuItem = styled(MenuItem)`
  padding: 0;
  width: 100%;
  border-radius: 0;
  button,
  a {
    width: 100%;
    font-size: 16px;
    padding: 8px 16px;
    text-transform: none;
    font-weight: 400;
    text-align: left;
    justify-content: left;
  }
`

export interface AccessibleMenuItem {
  children: ReactElement
  testId: string
  onClick?: (event: React.MouseEvent, index: number | string) => void
  internalUrl?: string
  externalUrl?: string
  ariaControls?: string
  ariaHaspopup?: string
  extraMenuItemProps?: {
    padding?: string
    disabled?: boolean
  }
}

interface Props {
  id: string
  menuItems: AccessibleMenuItem[]
  isLoading?: boolean
  loadingComponent?: ReactElement
  onClose?: ModalProps['onClose']
  anchorEl?: HTMLElement
  menuButton: ReactElement
  popoverProps?: Partial<PopoverProps>
  disablePadding?: boolean
  enableDivider?: boolean
}

const AccessibleMenu = ({
  id,
  anchorEl,
  menuButton,
  isLoading,
  loadingComponent,
  menuItems,
  onClose,
  popoverProps,
  disablePadding,
  enableDivider,
}: Props) => {
  const navigate = useNavigate()

  const menuId = `${id}-menu`
  const buttonId = `${id}-button`

  const buttonTabIndex = anchorEl ? { tabIndex: menuItems.length } : {}

  const accessibleMenuButton = React.cloneElement(menuButton, {
    'aria-controls': menuId,
    'aria-haspopup': 'menu',
    id: buttonId,
    ...buttonTabIndex,
  })

  return (
    <>
      {accessibleMenuButton}
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        {...popoverProps}
        aria-controls="main"
        MenuListProps={
          disablePadding
            ? {
                style: {
                  padding: 0,
                },
              }
            : {}
        }
      >
        {isLoading
          ? loadingComponent
          : menuItems.map(
              (
                {
                  children,
                  onClick,
                  internalUrl,
                  externalUrl,
                  testId,
                  extraMenuItemProps = {},
                  ariaControls,
                  ariaHaspopup,
                },
                index
              ) => {
                const isButton = !externalUrl

                const baseItemProps = {
                  'data-test-id': testId,
                  role: 'menuitem',
                  ListItemClasses: {},
                }

                const onMenuItemClick = (e: React.MouseEvent) => {
                  if (onClose) {
                    onClose(e, 'backdropClick')
                    if (isButton && onClick) onClick(e, index)
                    else if (internalUrl) navigate(internalUrl)
                  }
                }

                const internalLinkProps: any =
                  isButton && onClick
                    ? { onClick: (e: React.MouseEvent) => onClick(e, index) }
                    : { component: Link, to: internalUrl }

                return (
                  <StyledMenuItem
                    key={index}
                    {...(isButton || internalUrl ? { onClick: onMenuItemClick } : {})}
                    disabled={extraMenuItemProps.disabled}
                    aria-controls={ariaControls || 'main'}
                    {...baseItemProps}
                    divider={enableDivider}
                  >
                    {externalUrl ? (
                      <DLSLink color="inherit" href={externalUrl} key={`tenant-selector-${index}`} underline="none">
                        {children}
                      </DLSLink>
                    ) : (
                      <Button
                        {...{ ...extraMenuItemProps, ...internalLinkProps }}
                        ariaProps={{
                          'aria-controls': ariaControls,
                          'aria-haspopup': ariaHaspopup,
                        }}
                      >
                        {children}
                      </Button>
                    )}
                  </StyledMenuItem>
                )
              }
            )}
      </Menu>
    </>
  )
}

export default AccessibleMenu
