import { call, put, takeLatest } from 'redux-saga/effects'
import actions from 'store/productSyndications/actions'
import { ProductSyndicationApi } from 'api'
import { ProductSyndicationsResponse } from 'store/products/typings/productSyndication'
import { ProductStatus } from 'store/products/typings/productStatus'

export function* fetchProductSyndicationsSaga() {
  try {
    const response: ProductSyndicationsResponse = yield call(ProductSyndicationApi.fetchProductSyndications)
    const filteredSyndicationStatuses = response.products.filter((product) =>
      [ProductStatus.DESIGN, ProductStatus.LIVE, ProductStatus.CLOSED].includes(product.status as ProductStatus)
    )
    const data = {
      products: filteredSyndicationStatuses,
    }
    yield put(actions.getProductSyndicationsSuccess(data))
  } catch (e: any) {
    yield put(actions.getProductSyndicationsFail(e))
  }
}

export default function* () {
  yield takeLatest(actions.getProductSyndications.type, fetchProductSyndicationsSaga)
}
