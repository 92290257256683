import { Box, Card, CardActions, Grid } from '@material-ui/core'
import { FieldArray, Form, FormikProps } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { isEmpty, isUndefined, reject } from 'lodash'
import { Add } from '@material-ui/icons'
import Sticky from 'react-sticky-el'
import * as Yup from 'yup'
import TaskElement from './TaskElement'
import { FormContainer, SectionArray, SubmitButton, ZeroStateLandingPage } from 'components'
import { TaskDefinitionFormValue, TaskDefinitionValue } from 'store/tasks/types'
import { stopPropagation, withFormikSimple } from 'utils/form.utils'
import { NoStatements } from 'assets'
import { DeleteIconButton, Accordion, Body1 } from 'dls'
import { featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {}

interface TaskFormProps extends FormikProps<TaskDefinitionFormValue>, OwnProps {}

const FormBottom = styled.div`
  padding-top: 16px;
`
const AddIcon = styled(Add)`
  margin-right: 8px;
`
const ContentGridContainer = styled(Grid)`
  padding: 24px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 12px;
`
const ButtonStyle = styled(Button)`
  margin: 12px;
`

const TaskForm = ({ values: { taskDefinitions } }: TaskFormProps) => {
  const { t } = useTranslation()
  const availableTasks = [taskDefinitions]

  const [expandTask, setExpandTask] = useState<number[]>([])
  const isEmptyOrUndefined = (value: TaskDefinitionFormValue) => isEmpty(value) || isUndefined(value)

  return (
    <FormContainer
      title={t('Approval tasks')}
      description={t(
        'Here you can define approval tasks. The task you create here will be used when you need to send a product for approval. When you send a product for approval you’ll be asked to assign these tasks to colleagues.'
      )}
      titleVariant="h2"
    >
      <Form>
        <ContentGridContainer container direction="column">
          {!isEmpty(reject(availableTasks, isEmptyOrUndefined)) ? (
            <>
              <SectionArray name="taskDefinitions">
                {({ remove, index }) => (
                  <Box mb={3} key={`task-accordion-${taskDefinitions[index]?.key}-${index}`}>
                    <Accordion
                      testId="task-count"
                      title={
                        <Body1>
                          {taskDefinitions[index].name ? `${t(`Task:`)} ${taskDefinitions[index].name}` : t(`New task`)}
                        </Body1>
                      }
                      headerIcon={featureIconsCreator(featureSVGIcons.governance)}
                      headerButtonGroup={
                        taskDefinitions.length > 1 ? (
                          <DeleteIconButton
                            onClick={(e: React.MouseEvent) => {
                              stopPropagation(e)
                              setExpandTask(expandTask.splice(expandTask.findIndex((item) => item === index)))
                              remove(index)
                            }}
                          />
                        ) : undefined
                      }
                      defaultExpanded={!taskDefinitions[index].name}
                    >
                      <TaskElement index={index} />
                    </Accordion>
                  </Box>
                )}
              </SectionArray>
            </>
          ) : (
            <ZeroStateLandingPage
              image={NoStatements}
              title={t('There are no tasks defined')}
              description={t('Select "Add steps" below to get started')}
            />
          )}
        </ContentGridContainer>

        <FormBottom>
          <Sticky mode="bottom" positionRecheckInterval={500} holderCmp="div">
            <Card elevation={4}>
              <CardActions disableSpacing>
                <Grid container spacing={4} direction="row">
                  <Grid item sm={6}>
                    <>
                      <FieldArray name="taskDefinitions">
                        {({ push }) => (
                          <ButtonStyle
                            color={ColorType.BUTTON}
                            variant="outlined"
                            onClick={() => {
                              push({
                                name: '',
                                description: '',
                              } as TaskDefinitionValue)
                              if (taskDefinitions) {
                                setExpandTask([taskDefinitions.length])
                              } else {
                                setExpandTask([0])
                              }
                            }}
                            aria-controls="form-bottom-bar-menu"
                            aria-haspopup="true"
                            data-test-id="add-task-button"
                          >
                            <AddIcon />
                            {t('Add task')}
                          </ButtonStyle>
                        )}
                      </FieldArray>
                    </>
                  </Grid>
                  <Grid item sm={6}>
                    <ButtonContainer>
                      <SubmitButton
                        disabled={isEmpty(taskDefinitions)}
                        variant="contained"
                        color={ColorType.BUTTON}
                        data-test-id="task-submit-button"
                      >
                        {t('Submit')}
                      </SubmitButton>
                    </ButtonContainer>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Sticky>
        </FormBottom>
      </Form>
    </FormContainer>
  )
}

const validationSchema = Yup.object().shape({
  taskDefinitions: Yup.array().of(
    Yup.object({
      name: Yup.string().min(3, 'must be at least 3 characters').required('This is a required field'),
      description: Yup.string().required('This is a required field'),
    })
  ),
})

export default withFormikSimple<OwnProps, TaskDefinitionFormValue>(TaskForm, {
  enableReinitialize: true,
  validationSchema,
})
