import React from 'react'
import { Dialog, Fade } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreatePackageNameDialog from 'pages/Package/CreatePackageNameDialog'
import { PackageFormValues } from 'store/packages/types'
import { CountryIncluded } from 'store/products/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

export enum CreateProductView {
  'CreatePackageForm',
}

interface OwnProps {
  setName: (name: string) => void
  setPackageType: (type: string) => void
  setCountryOfResidence: (country: CountryIncluded | null) => void
  setBrandId: (brandId: string) => void
  view: CreateProductView
  open: boolean
  setOpen: (open: boolean) => void
}

const CreateProduct = (props: OwnProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setName, setPackageType, setCountryOfResidence, setBrandId, view, open, setOpen } = props

  const onOpen = () => setOpen(true)

  const onClose = () => setOpen(false)

  const createPackage = (values: PackageFormValues) => {
    setName(values.productPackageName)
    if (values.distribution.packageType) setPackageType(values.distribution.packageType)
    setCountryOfResidence(values.distribution.countryOfResidence ? values.distribution.countryOfResidence : null)
    setBrandId(values.distribution.brandId ? values.distribution.brandId : '')
    navigate(`/packages/create`)
  }

  const initialValues = {
    productPackageName: '',
    distribution: { packageType: '', countryOfResidence: '' as any, brandId: '' },
  }

  const renderContent = () => {
    switch (view) {
      case CreateProductView.CreatePackageForm:
        return (
          <CreatePackageNameDialog onPressCancel={onClose} onSubmit={createPackage} initialValues={initialValues} />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color={ColorType.BUTTON}
        onClick={onOpen}
        fullWidth
        data-test-id="create-package-button"
        style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}
      >
        {t('Create Package')}
      </Button>

      <Dialog open={open} scroll="paper" onClose={onClose} TransitionComponent={Fade} fullWidth>
        {renderContent()}
      </Dialog>
    </>
  )
}

export default CreateProduct
