import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams, useNavigate } from 'react-router-dom'
import { FeatureFormPageProps } from '../FeaturePage.page'
import ProductCodesForm from './ProductCodesForm'
import ProductsActions from 'store/products/actions'
import { Identification, Feature } from 'store/products/types'
import { RootState } from 'store/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import { Loader } from 'components'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIdentification: (productKey: string) => (cardsFormValues: Identification) =>
    dispatch(ProductsActions.setIdentification({ ...cardsFormValues, productKey })),
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const IdentificationFormPage = (props: Props): React.ReactElement | null => {
  const { setIdentification } = props
  const { productKey = '' } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)
  const navigate = useNavigate()

  useUpdateBreadcrumbs({ productKey, context: Feature.IDENTIFICATION })

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.IDENTIFICATION)) navigate(`./../not-allowed`)

  if (isLoading) {
    return <Loader />
  }

  const defaultIdentification = product?.identification ?? {
    productCode: '',
    productCodeDescription: '',
    subProductCode: '',
    subProductCodeDescription: '',
  }

  return product ? (
    <ProductCodesForm
      productKey={productKey}
      onSubmit={setIdentification(productKey)}
      initialValues={defaultIdentification}
    />
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentificationFormPage)
