import { call, put, takeLatest, select } from 'redux-saga/effects'
import { selectCurrentTenant } from './selectors'
import { Tenant } from './types'
import { setTimeZone } from 'utils/date.utils'
import { TenantConfigApi } from 'api'
import TenantConfigActions from 'store/tenant-config/actions'
import UserActions from 'store/user/actions'
import { setApiKey } from 'api/client/authAwareAxios'
import { selectUserRole } from 'store/user/selectors'
import { UserRole } from 'store/user/types'

function* getConfig() {
  try {
    const response: ReturnTypePromise<typeof TenantConfigApi.getTenantConfig> = yield call(
      TenantConfigApi.getTenantConfig
    )
    const selectSelectedTenant: Tenant = yield select(selectCurrentTenant)

    const userRole: UserRole = yield select(selectUserRole)

    if (userRole === UserRole.ACCOUNTS) {
      setApiKey(response?.tenants?.[0].apiKey || '')
      yield put(UserActions.setSelectedTenantIndexSuccess(0))
    } else {
      setApiKey(selectSelectedTenant ? selectSelectedTenant.apiKey : response?.tenants?.[0].apiKey || '')
    }
    setTimeZone(response.localisation.timezone)
    yield put(TenantConfigActions.fetchSuccess(response))
  } catch (e: any) {
    yield put(TenantConfigActions.fetchFail(e))
  }
}

export default function* () {
  yield takeLatest(TenantConfigActions.fetch.type, getConfig)
}
