import React from 'react'
import { Grid } from '@material-ui/core'
import { Form, FormikProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { isEmpty, isUndefined, reject, set } from 'lodash'
import styled from 'styled-components/macro'
import { TFunction } from 'i18next'
import { SchemeLimitElement } from './SchemeLimitElement'
import { SubscriptionLimitElement } from './SubscriptionLimitElement'
import { CardLimitElement } from './CardLimitElement'
import { FPSCumulativeDailyPaymentOutLimitElement } from './FPSCumulativeDailyPaymentOutLimitElement'
import { cardLimitMaximumNumberValidation, getMaxCardMaximumNumber } from './limitsTransformers'
import { FormBottomBar, FormContainer, SectionArray, ZeroStateLandingPage } from 'components'
import {
  Limits,
  LimitsFormValues,
  // ResetPeriod,
  SchemeLimit,
  SchemeName,
  SubscriptionLimit,
  TransactionLimit,
  // TransactionName,
  CardLimit,
  ExtendedProduct,
} from 'store/products/types'
import { withFormikSimple } from 'utils/form.utils'
import { NoLimits } from 'assets'
import { ClientConfig } from 'store/tenant-config/types'
import { isClientConfigAvailable } from 'utils/common.util'
import { DeleteIconButton, Accordion, Body1 } from 'dls'

interface OwnProps {
  defaultValues?: {
    schemeLimitsFPS?: Partial<SchemeLimit>
    schemeLimitsBACS?: Partial<SchemeLimit>
    transactionLimitsATM?: Partial<TransactionLimit>
    transactionLimitsOUT?: Partial<TransactionLimit>
    subscriptionLimitOnly?: Partial<SubscriptionLimit>
    cardLimitOnly?: Partial<CardLimit>
  }
  currencyOfProduct?: string | null
  clientConfig?: Partial<ClientConfig>
  product: ExtendedProduct
  // eslint-disable-next-line react/no-unused-prop-types
  t: TFunction
}

const FormBottom = styled.div`
  padding-top: 16px;
`

const ContentGridContainer = styled(Grid)`
  padding: 24px;
`

interface LimitsFormProps extends FormikProps<LimitsFormValues>, OwnProps {}

const LimitsForm = ({ values, defaultValues = {}, currencyOfProduct, clientConfig, product }: LimitsFormProps) => {
  const { t } = useTranslation()

  const {
    transactionLimitsATM,
    transactionLimitsOUT,
    schemeLimitsFPS,
    schemeLimitsBACS,
    // This below commented code will be used, Dont remove!!!!!!!

    // productLimitPersonalCurrentAccount,
    // productLimitCurrentPool,
    // productLimitSavingsPool,
    // productLimitBillsPool,
    // productLimitAccount,
    // productLimitMortgage,
    // productLimitLoan,
    // productLimitCurrentAccount,
    // productLimitOther,
    // productLimitCustom,
    subscriptionLimitOnly,
    cardLimitOnly,
  } = values

  const availableLimits = [
    transactionLimitsATM,
    transactionLimitsOUT,
    schemeLimitsBACS,
    schemeLimitsFPS,
    // productLimitPersonalCurrentAccount,
    // productLimitCurrentPool,
    // productLimitSavingsPool,
    // productLimitBillsPool,
    // productLimitAccount,
    // productLimitMortgage,
    // productLimitLoan,
    // productLimitCurrentAccount,
    // productLimitOther,
    // productLimitCustom,
    subscriptionLimitOnly,
    cardLimitOnly,
  ]

  const isDisabled =
    transactionLimitsATM &&
    !!transactionLimitsATM.length &&
    transactionLimitsOUT &&
    !!transactionLimitsOUT.length &&
    schemeLimitsFPS &&
    !!schemeLimitsFPS.length &&
    schemeLimitsBACS &&
    !!schemeLimitsBACS.length

  // This below commented code will be used, Dont remove!!!!!!!

  // productLimitPersonalCurrentAccount &&
  // !!productLimitPersonalCurrentAccount.length &&
  // productLimitCurrentPool &&
  // !!productLimitCurrentPool.length &&
  // productLimitSavingsPool &&
  // !!productLimitSavingsPool.length &&
  // productLimitBillsPool &&
  // !!productLimitBillsPool.length &&
  // productLimitAccount &&
  // !!productLimitAccount.length &&
  // productLimitMortgage &&
  // !!productLimitMortgage.length &&
  // productLimitLoan &&
  // !!productLimitLoan.length &&
  // productLimitCurrentAccount &&
  // !!productLimitCurrentAccount.length &&
  // productLimitOther &&
  // !!productLimitOther.length &&
  // productLimitCustom &&
  // !!productLimitCustom.length

  const isEmptyOrUndefined = (value: LimitsFormValues) => isEmpty(value) || isUndefined(value)

  const isFormEmpty = (name: string): boolean => !!getIn(values, name)?.formType

  return (
    <FormContainer
      title={t('Limits')}
      description={t(
        'Here you can define the limits for the product, including payments in/out, payment services and the number of accounts that a customer can hold.'
      )}
    >
      <Form>
        <ContentGridContainer container direction="column">
          {!isEmpty(reject(availableLimits, isEmptyOrUndefined)) ? (
            <>
              <SectionArray name="schemeLimitsFPS">
                {({ remove, name, index }) => (
                  <Accordion
                    title={<Body1>{t('Faster Payment Service limit')}</Body1>}
                    headerButtonGroup={
                      <DeleteIconButton
                        onClick={() => {
                          remove(index)
                        }}
                      />
                    }
                    defaultExpanded={isFormEmpty(name)}
                    style={{ marginBottom: 16 }}
                  >
                    <SchemeLimitElement
                      name={name}
                      description={t(
                        "This is the scheme limit for the FPS service and should be updated in line with changes by the scheme. If this isn't set, the default limit will be set at £250,000 per payment."
                      )}
                      currencyOfProduct={currencyOfProduct}
                    />
                  </Accordion>
                )}
              </SectionArray>
              <SectionArray name="transactionLimitsOUT">
                {({ remove, name, index }) => (
                  <Accordion
                    title={<Body1>{t('FPS cumulative daily payment out limit')}</Body1>}
                    headerButtonGroup={
                      <DeleteIconButton
                        onClick={() => {
                          remove(index)
                        }}
                      />
                    }
                    defaultExpanded={isFormEmpty(name)}
                    style={{ marginBottom: 16 }}
                  >
                    <FPSCumulativeDailyPaymentOutLimitElement
                      name={name}
                      description={t(
                        'This is the limit on the total cumulative daily payment out for the Faster Payments Scheme (FPS).'
                      )}
                      currencyOfProduct={currencyOfProduct}
                    />
                  </Accordion>
                )}
              </SectionArray>
              <SectionArray name="subscriptionLimitOnly">
                {({ remove, name, index }) => (
                  <Accordion
                    title={<Body1>{t('Number of accounts')}</Body1>}
                    headerButtonGroup={
                      <DeleteIconButton
                        onClick={() => {
                          remove(index)
                        }}
                      />
                    }
                    defaultExpanded={isFormEmpty(name)}
                    style={{ marginBottom: 16 }}
                  >
                    <SubscriptionLimitElement
                      name={name}
                      description={t('This is the maximum number of instances of this product a customer can hold.')}
                    />
                  </Accordion>
                )}
              </SectionArray>
              <SectionArray name="cardLimitOnly">
                {({ remove, name, index }) => (
                  <Accordion
                    title={<Body1>{t('Cards')}</Body1>}
                    headerButtonGroup={
                      <DeleteIconButton
                        onClick={() => {
                          remove(index)
                        }}
                      />
                    }
                    defaultExpanded={isFormEmpty(name)}
                    style={{ marginBottom: 16 }}
                  >
                    <CardLimitElement
                      name={name}
                      productKey={product.productKey}
                      maximumNumber={getMaxCardMaximumNumber(product)}
                      description={t(
                        'Here you can limit the number of cards that can be issued per party for this product.'
                      )}
                    />
                  </Accordion>
                )}
              </SectionArray>
            </>
          ) : (
            <ZeroStateLandingPage
              image={NoLimits}
              title={t('No limits have been set')}
              description={t('Select “Add a limit” below to get started')}
            />
          )}
        </ContentGridContainer>
        <FormBottom>
          <FormBottomBar
            position="bottom"
            addLabel={t('Add a limit')}
            isAddButtonDisabled={isDisabled}
            elements={{
              ...(isClientConfigAvailable(clientConfig, 'limits.cardLimit')
                ? {
                    cardLimit: [
                      {
                        defaultValue: { ...defaultValues.cardLimitOnly, formType: 'cardLimitOnly' },
                        multiple: false,
                        name: 'cardLimitOnly',
                        label: t('Cards'),
                      },
                    ],
                  }
                : {}),
              // ...(isClientConfigAvailable(clientConfig, 'limits.transactionLimits')
              //   ? {
              //       transactionLimits: [
              //         {
              //           defaultValue: {
              //             ...defaultValues.transactionLimitsOUT,
              //             resetPeriod: ResetPeriod.DAY,
              //             transactionName: TransactionName.TRANSFER_OUT,
              //             formType: 'transactionLimitsOUT',
              //           },
              //           multiple: false,
              //           name: 'transactionLimitsOUT',
              //           label: t('FPS cumulative daily'),
              //         },
              //       ],
              //     }
              //   : {}),
              ...(isClientConfigAvailable(clientConfig, 'limits.schemeLimits')
                ? {
                    schemeLimit: [
                      {
                        defaultValue: {
                          ...defaultValues.schemeLimitsFPS,
                          schemeName: SchemeName.FPS,
                          formType: 'schemeLimitsFPS',
                        },
                        multiple: false,
                        name: 'schemeLimitsFPS',
                        label: t('Faster Payment Service (FPS)'),
                      },
                    ],
                  }
                : {}),
              ...(isClientConfigAvailable(clientConfig, 'limits.productLimits')
                ? {
                    productLimit: [
                      {
                        defaultValue: { ...defaultValues.subscriptionLimitOnly, formType: 'subscriptionLimitOnly' },
                        multiple: false,
                        name: 'subscriptionLimitOnly',
                        label: t('Number of accounts'),
                      },
                    ],
                  }
                : {}),
            }}
          />
        </FormBottom>
      </Form>
    </FormContainer>
  )
}

export const cardsValidation = (values: LimitsFormValues, props: any) => {
  const errors = {}
  if (
    values &&
    values.cardLimitOnly &&
    values.cardLimitOnly[0] &&
    values.cardLimitOnly[0].maximumNumber &&
    values.cardLimitOnly[0].maximumNumber > 0
  ) {
    const maximumNumber = getMaxCardMaximumNumber(props.product)
    if (maximumNumber && cardLimitMaximumNumberValidation(values.cardLimitOnly[0].maximumNumber, maximumNumber)) {
      set(
        errors,
        `cardLimitOnly[0].maximumNumber`,
        props.t('Must be equal or greater than {{maximumNumber}} and less than 100', {
          maximumNumber,
        })
      )
    }
  }
  return errors
}

// @TODO: Make this thing more DRY
export default withFormikSimple<OwnProps, Limits>(LimitsForm, {
  validate: cardsValidation,
  validationSchema: Yup.object<Partial<LimitsFormValues>>({
    schemeLimitsFPS: Yup.array(
      Yup.object({
        minimumAmount: Yup.number()
          .when(['maximumAmount'], {
            is: (maximumAmount) => !!maximumAmount,
            then: (minimumAmount: Yup.NumberSchema) =>
              minimumAmount.max(Yup.ref('maximumAmount'), 'Minimum amount must be smaller than Maximum amount'),
          })
          .typeError('This field must be a number'),
      })
    ),
    schemeLimitsBACS: Yup.array(
      Yup.object({
        minimumAmount: Yup.number()
          .when(['maximumAmount'], {
            is: (maximumAmount) => !!maximumAmount,
            then: (minimumAmount: Yup.NumberSchema) =>
              minimumAmount.max(Yup.ref('maximumAmount'), 'Minimum amount must be smaller than Maximum amount'),
          })
          .typeError('This field must be a number'),
      })
    ),
    transactionLimitsATM: Yup.array(
      Yup.object({
        minimumAmount: Yup.number()
          .when(['maximumAmount'], {
            is: (maximumAmount) => !!maximumAmount,
            then: (minimumAmount: Yup.NumberSchema) =>
              minimumAmount.max(Yup.ref('maximumAmount'), 'Minimum amount must be smaller than Maximum amount'),
          })
          .typeError('This field must be a number'),
      })
    ),
    transactionLimitsOUT: Yup.array(
      Yup.object({
        minimumAmount: Yup.number()
          .when(['maximumAmount'], {
            is: (maximumAmount) => !!maximumAmount,
            then: (minimumAmount: Yup.NumberSchema) =>
              minimumAmount.max(Yup.ref('maximumAmount'), 'Minimum amount must be smaller than Maximum amount'),
          })
          .typeError('This field must be a number'),
      })
    ),
  }).required(),
})
