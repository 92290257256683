import React, { Dispatch } from 'react'
import { TFunction } from 'i18next'
import UserNameCellContent from '../components/UserNameCellContent'
import UserFilterLabel from '../components/UserFilterLabel'
import UserEmailCellContent from '../components/UserEmailCellContent'
import UserRoleLabel from '../components/UserRoleLabel'
import getFullName from './getFullName'
import { Colleague, UserFilters } from 'store/user/types'
import { TableCommonHeaderType } from 'components/TableCommon/types'
import { UserSearchActions } from 'pages/Users/Users/reducer/actions'

const userTableHeaderRows = (
  setFilters: Dispatch<UserSearchActions>,
  filters: UserFilters,
  t: TFunction
): TableCommonHeaderType[] => [
  {
    id: 'fullName',
    align: 'left',
    label: t('Name'),
    disableOrder: true,
    getCellContent: (user: Colleague) => <UserNameCellContent user={user} />,
    getCellOrderString: (user: Colleague) => getFullName(user),
  },
  {
    id: 'userRoles',
    align: 'left',
    label: <UserFilterLabel setFilters={setFilters} filters={filters} />,
    disableOrder: true,
    getCellContent: (user: Colleague) => <UserRoleLabel userRole={user.userRole} />,
  },
  {
    id: 'email',
    align: 'left',
    disableOrder: true,
    label: t('Email'),
    getCellContent: (user: Colleague) => <UserEmailCellContent email={user.email} />,
  },
]

export default userTableHeaderRows
