import React, { useEffect, useCallback } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { Form, FormikProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import BottomBar from '../../BottomBar'
import AboutAndTermForm from './AboutAndTermForm'
import AmortisationMethodForm from './AmortisationMethodForm'
import RoundingAdjustmentForm from 'pages/features/PaymentSchedule/components/update/forms/RoundingAdjustmentForm'
import RepaymentFrequencyForm from 'pages/features/PaymentSchedule/components/update/forms/RepaymentFrequencyForm'
import { withFormikSimple } from 'utils/form.utils'
import { ExtendedProduct, ProductKey, PaymentSchedule, AllowedRepaymentFrequencies } from 'store/products/types'
import { TenantConfig } from 'store/tenant-config/types'
import FeaturePageWithTopAddLayout from 'pages/features/FeaturePageWithTopAddLayout'
import { InfoIconButton } from 'dls'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'

interface OwnProps {
  // eslint-disable-next-line react/no-unused-prop-types
  hasFeature?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  tenantConfig?: Partial<TenantConfig>
  product: ExtendedProduct
  productKey: ProductKey
}

type PaymentScheduleFormProps = FormikProps<PaymentSchedule> & OwnProps

const EditDefaultAccessForm = (props: PaymentScheduleFormProps) => {
  const { productKey, product, values, setFieldValue, setFieldTouched, getFieldMeta } = props
  const { t } = useTranslation()

  const selectedAmortisationMethod = getIn(values, 'amortisationMethod')
  const term = getIn(values, 'term')

  const onAmortisationChange = useCallback(() => {
    setFieldValue('calculationRoundingAdjustment', null)
    setFieldValue('allowedRepaymentFrequencies', [])
  }, [])

  const onTermChange = useCallback(() => {
    setFieldValue('allowedRepaymentFrequencies', [])
  }, [])

  const { showInfographicModal } = useModal()

  useEffect(() => {
    const { touched } = getFieldMeta('term.minimum')
    if (touched) {
      setFieldTouched('term.maximum')
    }
  }, [term.type, setFieldTouched, getFieldMeta])

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('paymentSchedule')}
      descriptionContent={t(
        'Here you can define the amount and term of the lending, as well as how it must be repaid.'
      )}
      rightArea={
        <InfoIconButton
          key="more-about-icon"
          ariaProps={{
            'aria-label': `${t('About amount and payment schedule')}`,
            'aria-haspopup': 'dialog',
            'aria-controls': 'more-about-dialog',
          }}
          onClick={() => showInfographicModal(HelpModalType.AMOUNT_PAYMENT_SCHEDULE)}
          fontSize="medium"
        />
      }
    >
      <Form>
        <Paper style={{ padding: 28 }}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <AboutAndTermForm
              currencyCode={product.currency.currencyCode}
              onTermChange={onTermChange}
              termType={term.type}
            />
            <AmortisationMethodForm onMethodChange={onAmortisationChange} />
            {selectedAmortisationMethod && (
              <>
                <RoundingAdjustmentForm amortisationMethod={selectedAmortisationMethod} />
                <RepaymentFrequencyForm term={term} />
              </>
            )}
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <BottomBar productKey={productKey} />
        </Grid>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, PaymentSchedule>(EditDefaultAccessForm, {
  validateOnMount: true,
  validationSchema: Yup.object<Partial<PaymentSchedule>>({
    amount: Yup.object({
      minimum: Yup.number().required('Required field').min(1, 'Must be greater than 0'),
      maximum: Yup.number().min(Yup.ref('minimum'), 'Maximum should be equal or greater than minimum'),
    }),
    term: Yup.object({
      minimum: Yup.number()
        .required('Required field')
        .when('type', {
          is: 'DAYS',
          then: Yup.number().min(7, 'The minimum day term is 7 days').max(36135, 'The maximum year term is 99 years'),
        })
        .when('type', {
          is: 'YEARS',
          then: Yup.number().max(99, 'The maximum year term is 99 years'),
        })
        .when('type', {
          is: 'MONTHS',
          then: Yup.number().max(1188, 'The maximum year term is 99 years'),
        })
        .when('type', {
          is: 'WEEKS',
          then: Yup.number().max(5148, 'The maximum year term is 99 years'),
        })
        .min(1, 'Must be greater than 0')
        .integer('This field must be an integer'),
      maximum: Yup.number()
        .required('Required field')
        .integer('This field must be an integer')
        .min(Yup.ref('minimum'), 'Maximum should be equal or greater than minimum')
        .when('type', {
          is: 'YEARS',
          then: Yup.number().max(99, 'The maximum year term is 99 years'),
        })
        .when('type', {
          is: 'MONTHS',
          then: Yup.number().max(1188, 'The maximum year term is 99 years'),
        })
        .when('type', {
          is: 'WEEKS',
          then: Yup.number().max(5148, 'The maximum year term is 99 years'),
        })
        .when('type', {
          is: 'DAYS',
          then: Yup.number().max(36135, 'The maximum year term is 99 years'),
        }),
      type: Yup.mixed().required(),
    }),
    amortisationMethod: Yup.mixed().required(),
    calculationRoundingAdjustment: Yup.mixed().required(),
    allowedRepaymentFrequencies: Yup.array<AllowedRepaymentFrequencies>().min(1),
  }).required(),
})
