import { ProductSegment, StatementTemplate, StatementType } from 'store/products/types'
import { ContentType } from 'store/statement/types'
import { StatementApi } from 'api'

const downloadTemplatePdf = (
  productSegment: ProductSegment,
  templateType: StatementType,
  template: StatementTemplate
) => {
  StatementApi.getSamples({
    productSegment,
    templateType,
    templateGroupId: template.id,
    contentType: ContentType.PDF,
  }).then((data) => {
    const blob = new Blob([data as any], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `${template.name}.pdf`
    link.click()
  })
}
export default downloadTemplatePdf
