import React from 'react'
import { Breadcrumbs } from '@material-ui/core'
import { MainDiv, StyledNavigateNextIcon, StyledSpan, StyledLink } from './Styled'
import BreadcrumbsSelectors from 'store/breadcrumbs/selectors'

interface Props {
  pageTitleAndUrl: ReturnType<typeof BreadcrumbsSelectors.selectBreadcrumbs>
}

export const BreadcrumbsSection = ({ pageTitleAndUrl }: Props) => (
  <MainDiv>
    <Breadcrumbs aria-label="breadcrumbs" separator={<StyledNavigateNextIcon fontSize="small" />}>
      {pageTitleAndUrl.map((page, index) =>
        index === pageTitleAndUrl.length - 1 ? (
          <StyledSpan key={index} aria-current="page">
            {page.title}
          </StyledSpan>
        ) : (
          <StyledLink to={page.path} key={page.path} aria-current="page">
            {page.title}
          </StyledLink>
        )
      )}
    </Breadcrumbs>
  </MainDiv>
)

export default BreadcrumbsSection
