import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import StatementActions from 'store/statement/actions'
import { WidgetErrorImage, Unavailable } from 'assets'
import { H3, Body2, Body1 } from 'dls'
import { Link } from 'dls/atoms/Link'
import { ProductSegment, StatementType } from 'store/products/types'
import { stopPropagation } from 'utils/form.utils'
import { LinkWithText } from 'dls/molecules/Buttons'
import { CachedIcon } from 'dls/atoms/Icons/MUIIcons'
import { ColorType } from 'dls/shared/types'

type Props =
  | {
      productSegment?: never
      reason: 'no-items'
      statementType?: never
      smallView?: boolean
      message: React.ReactNode
      messageTitle: React.ReactNode
    }
  | {
      productSegment?: ProductSegment
      reason: 'template-error'
      statementType: StatementType
      smallView?: boolean
      message?: never
      messageTitle?: never
    }

const StatementTemplateError = ({ reason, statementType, smallView, productSegment, messageTitle, message }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const retryAction = (e: React.MouseEvent) => {
    stopPropagation(e)
    if (productSegment && statementType) {
      dispatch(
        StatementActions.getTemplates({
          productSegment,
          templateType: statementType,
        })
      )
    }
  }

  return (
    <>
      {smallView ? (
        <Box display="flex" flexDirection="row" gridGap={20} alignItems="center" justifyContent="left">
          <img src={WidgetErrorImage} alt="No Template" />
          <Box textAlign="left">
            <Body1 gutterBottom variantMapping={{ body1: 'h3' }}>
              {reason === 'no-items' ? <>{messageTitle}</> : t('Oops, something went wrong')}
            </Body1>
            {reason === 'no-items' ? (
              <>{message}</>
            ) : (
              <LinkWithText onClick={retryAction} endIcon={null} startIcon={<CachedIcon color={ColorType.default} />}>
                {t('Try again')}
              </LinkWithText>
            )}
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="489px">
          <img
            src={reason === 'no-items' ? WidgetErrorImage : Unavailable}
            alt="No Template"
            width="180px"
            height="auto"
          />
          <H3 css="margin-top: 40px" gutterBottom>
            {reason === 'no-items' ? <>{messageTitle}</> : t('Oops, something went wrong')}
          </H3>
          {reason === 'no-items' ? (
            <>{message}</>
          ) : (
            <Body2>
              <Link onClick={retryAction} color="secondary" href="#top">
                {t('Try again')}
              </Link>
            </Body2>
          )}
        </Box>
      )}
    </>
  )
}

export default StatementTemplateError
