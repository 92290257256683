import React from 'react'
import { useTranslation } from 'react-i18next'
import TaxRate from './TaxRate'
import { EntityBadgeHistory } from 'dls/atoms'
import EntityCardFooterLink from 'pages/ReferenceData/_shared/EntityCardFooterLink'
import EntitySectionCard from 'pages/Layout/EntityReviewPageLayout/EntitySectionCard'
import { WithholdingTaxRule } from 'store/withholdingTax/types'
import { toBankTz } from 'utils/date.utils'
import { Body1, ReviewRows } from 'dls'
import { Separator } from 'dls/molecules/ReviewRows/types'
import { HistoryIcon } from 'dls/atoms/Icons/MUIIcons'

interface Props {
  historicRates: WithholdingTaxRule[]
  ruleId: string
}

const HistoricTaxRuleSectionCard = ({ historicRates, ruleId }: Props) => {
  const { t } = useTranslation()
  const historicRatesDefinitions = historicRates.map((rate, index) => ({
    groupSeparator: (index < historicRates.length - 1 ? 'light' : undefined) as Separator,
    definitions: [
      { term: t('Effective date'), definitions: [rate && toBankTz(rate.effectiveDate.toString()).date] },

      ...(rate.withholdTax
        ? [
            {
              term: t('Calculation method'),
              definitions: [<div key="witholdtax">{t(rate.tierBandCalcMethod)}</div>],
            },
          ]
        : []),
      ...(rate.withholdTax
        ? [
            {
              term: t('Tax rate'),
              definitions: [<TaxRate key="tax rate" taxRule={rate} ruleId={rate.ruleId} disableEdit />],
            },
          ]
        : []),
    ],
  }))
  return (
    <EntitySectionCard
      headline="Historic tax rates"
      title={
        <Body1 style={{ marginTop: 3 }}>
          {t(`${historicRates.length || 'No'} historic tax rate${historicRates.length !== 1 ? 's' : ''}`)}
        </Body1>
      }
      headerIcon={<EntityBadgeHistory />}
      fixedBottomContent={
        historicRates.length ? (
          <EntityCardFooterLink
            Icon={HistoryIcon}
            label="View Timeline"
            href={`/reference-data/withholding-tax/${ruleId}/history`}
          />
        ) : undefined
      }
      accordionDisabled={!historicRates.length}
      scrollEnabled
    >
      <ReviewRows definitionsList={historicRatesDefinitions} />
    </EntitySectionCard>
  )
}

export default HistoricTaxRuleSectionCard
