import React from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Yip } from 'utils/form.utils'
import { TextFieldComplex } from 'components'

const URLInput = (props: { name?: string; required?: boolean; label?: string }) => {
  const { t } = useTranslation()
  return (
    <Field
      name="email"
      type="email"
      label={t('URL')}
      validate={Yip(Yup.string().url(t('The URL isn’t in a valid format')).required())}
      required
      autoComplete="url"
      fullWidth
      component={TextFieldComplex}
      css="margin: 1em 0"
      {...props}
    />
  )
}

export default URLInput
