import * as React from 'react'
import { connect as connectFormik } from 'formik'
import { Button } from 'dls/atoms/Button'

const SubmitButton = connectFormik<React.ComponentProps<typeof Button> & { isValidating?: boolean; noDirty?: boolean }>(
  ({ formik: { isValid, isValidating, dirty }, noDirty = false, ...buttonProps }) => (
    <Button
      type="submit"
      {...buttonProps}
      disabled={!isValid || isValidating || buttonProps.disabled || (!dirty && !noDirty)}
    />
  )
)

export default SubmitButton
