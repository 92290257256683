import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { PaymentLimit, PaymentLimitRoutingDestination } from 'store/products/typings/paymentLimits'
import { getEditUrl, addUrls } from 'pages/features/PaymentLimits/utilities/actionUrls'
import { ReviewSubLabel } from 'dls'

interface OwnProps {
  labelKey: string
  paymentLimit: PaymentLimit
  showActions: boolean
  routingDestination: PaymentLimitRoutingDestination
  addUrlCtaKey: 'maxDay' | 'maxMonth' | 'maxYear'
  testId?: string
}

const MaxTransactionsLimitSubValue = ({
  labelKey,
  paymentLimit,
  showActions = false,
  addUrlCtaKey,
  routingDestination,
  testId,
}: OwnProps) => {
  const { t } = useTranslation()
  return (
    <ReviewSubLabel
      label={t(labelKey)}
      buttonComponentType={RouterLink}
      testId={`${testId}-max-transactions-limit`}
      value={paymentLimit?.amount?.maximum ? `${paymentLimit.amount.maximum}` : null}
      editCtaLink={showActions ? getEditUrl(paymentLimit?.limitRuleId || '') : undefined}
      addCtaLink={showActions ? addUrls(routingDestination)[addUrlCtaKey] : null}
    />
  )
}

export default MaxTransactionsLimitSubValue
