import React from 'react'
import AboutDialog from './AboutDialog'
import { MoreAboutSuspenseAccount } from 'assets'

interface OwnProps {
  onPressCancel: () => void
  open: boolean
}

const HowSuspenseAccountsWorkModal = ({ onPressCancel, open }: OwnProps) => (
  <AboutDialog
    onPressCancel={onPressCancel}
    open={open}
    heading="How suspense accounts work"
    items={[
      {
        title: '1. Transaction sources',
        description: 'Unallocated transactions will be placed in suspense accounts.',
      },
      {
        title: '2. Transaction allocation & clearing',
        description:
          'Pending and partially cleared transactions must be cleared to another account. Partially cleared transactions can only be cleared to a General Ledger (GL) account.',
      },
      {
        title: '3. Contra entries',
        description:
          'Cleared transactions automatically have a contra entry created in the same account. Contra entries for transfers to the General Ledger (GL) account can be reversed if you need to correct a mistake.',
      },
    ]}
    image={{ src: MoreAboutSuspenseAccount, alt: 'More about suspense account' }}
    id="how-suspense-account-dialog"
  />
)

export default HowSuspenseAccountsWorkModal
