import React, { useEffect } from 'react'
import orderBy from 'lodash/orderBy'
import { useDispatch, useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import { Product } from 'store/products/types'
import ProductsSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'
import { stopPropagation } from 'utils/form.utils'
import { orderProductSecondCondition } from 'utils/productVersion.utils'

interface Props {
  product: Product
  isVersionLatest: boolean
  btnRef: any
}

export default ({ isVersionLatest, product, btnRef }: Props) => {
  const versions = useSelector(ProductsSelectors.selectVersions)
  const isLoading = useSelector(ProductsSelectors.selectProductVersionsLoading)
  const error = useSelector(ProductsSelectors.selectProductVersionsFail)

  const dispatch = useDispatch()

  const fetchVersions = (productKey: string) => dispatch(ProductsActions.getVersions(productKey))
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isHelpOpen, setHelpOpen] = React.useState(false)
  const [productVersions, setProductVersions] = React.useState<Product[]>([])
  const hideHelp = () => setHelpOpen(false)
  const showHelp = () => setHelpOpen(true)
  const showProductVersions = (event: React.MouseEvent<HTMLButtonElement>) => {
    stopPropagation(event)
    fetchVersions(product.productKey)
    setAnchorEl(btnRef.current)
    setProductVersions([])
  }

  useEffect(() => {
    if ((product.version <= 0 && isVersionLatest) || !versions?.length) {
      setProductVersions([product])
    } else {
      const list: Product[] = []
      const [latestVersion, ...orderByVersionAndStatusProduct] = orderBy(
        versions,
        ['version', orderProductSecondCondition],
        ['asc', 'asc']
      ).reverse()
      if (latestVersion && latestVersion.version === 1) {
        list.push(latestVersion)
      } else {
        const groupByVersion = groupBy([latestVersion, ...orderByVersionAndStatusProduct], (i) => i.version)
        // filtered by the latest status of each version
        const slicedVersions = orderBy(
          Object.keys(groupByVersion).map((i) => groupByVersion[i][0]),
          ['version', orderProductSecondCondition],
          ['asc', 'asc']
        )
          .reverse()
          .slice(0, 3)
        list.push(...slicedVersions)
      }

      if (list.length === 0) {
        list.push(product)
      }
      setProductVersions(list)
    }
  }, [versions, isVersionLatest, product])
  return {
    showProductVersions,
    anchorEl,
    showHelp,
    productVersions,
    setAnchorEl,
    hideHelp,
    isHelpOpen,
    isLoading,
    error,
    versions,
  }
}
