export interface ConditionDataPoint {
  id: string
  name: string
}

export interface ConditionValue {
  type: 'currency' | 'number' | 'code'
  id: string
  value: string
  currencyCode?: string
}

export enum ConditionOperator {
  IF = 'IF',
  AND = 'AND',
  OR = 'OR',
}

export enum DataPointOperator {
  EQUAL_TO = 'EQUAL_TO',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

export enum DataPointAggregatedOperator {
  AVG = 'AVG',
  COUNT = 'COUNT',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM',
}

export interface BuilderCondition {
  operator: ConditionOperator
  dataPoint: ConditionDataPoint
  dataPointFirstOperator: DataPointOperator | DataPointAggregatedOperator
  dataPointSecondOperator?: DataPointOperator | DataPointAggregatedOperator
  values: ConditionValue[]
}

export interface BuilderConditionFormValues {
  dataPoint: string
  dataPointFirstOperator: string
  dataPointSecondOperator?: string
  dataValue: string
}
