import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import useMultiParty from './useMultiParty'
import MultiPartyTable from './MultiPartyTable'
import { MultiPartyAllowedRoles } from 'store/products/typings/multiParty'
import { TemplateForm, InfoIconButton, Paper, Input, InfoPanel, ContextualHelp } from 'dls'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { H2, Body2, EntityBadgeMultiParty } from 'dls/atoms'
import { Loader } from 'components'
import { StyledFieldContainer } from 'dls/shared/styled'
import { internalMdPadding } from 'dls/shared/styleVar'
import { AvatarSize } from 'dls/shared/types'
import { HelpModalType } from 'store/modal/types'
import { FeatureName } from 'store/products/types'
import useModal from 'components/Modal/hooks/useModal'

const MultiPartyForm = () => {
  const { productKey = '' } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { submitMultiParty } = useMultiParty({
    productKey,
    version: product?.version,
  })

  const { showInfographicModal } = useModal()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        maximumParties: Yup.number()
          .typeError('This is not number')
          .positive('Maximum must be greater than 1')
          .integer('This field must be an integer')
          .required('Enter number greater than 1 and less than 101')
          .moreThan(1, 'Maximum must be greater than 1')
          .lessThan(101, 'Must be 100 or lower'),
        invitationExpiryHours: Yup.number()
          .typeError('This is not number')
          .positive('Invite expiry must be greater than 0')
          .integer('This field must be an integer')
          .required('Must be 8760 or lower')
          .lessThan(8761, 'Must be 8760 or lower'),
        inviteeAllowedRoles: Yup.array().required().min(1),
      }).required()
    ),
    mode: 'onChange',
    defaultValues: product?.multiParty,
  })

  useEffect(() => {
    reset(product?.multiParty)
  }, [product])

  if (isLoading || !product) {
    return <Loader />
  }
  const onSubmit = handleSubmit((values) => {
    const { inviterAllowedRoles = [], ...otherValues } = values

    submitMultiParty({
      inviterAllowedRoles: inviterAllowedRoles.includes(MultiPartyAllowedRoles.PRIMARY_OWNER)
        ? inviterAllowedRoles
        : [MultiPartyAllowedRoles.PRIMARY_OWNER, ...inviterAllowedRoles],
      ...otherValues,
    })
  })

  return (
    <>
      <TemplateForm
        main={
          <form onSubmit={onSubmit}>
            <section aria-labelledby="multi-party-min-max" style={{ marginBottom: internalMdPadding }}>
              <Paper elevation={0} responsivePadding>
                <H2 gutter id="multi-party-min-max">
                  {t('Number of parties')}
                </H2>
                <InfoPanel
                  body={t(
                    'All parties will need to meet the eligibility criteria for the product (set in the eligibility feature)'
                  )}
                  css={`
                    margin-bottom: ${internalMdPadding};
                  `}
                />
                <Grid container spacing={2}>
                  <Grid item sm={6} md={3}>
                    <Input
                      id="minimumNumber"
                      name="minimumNumber"
                      label={t('Minimum number')}
                      disabled
                      fullWidth
                      value={1}
                    />
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <StyledFieldContainer>
                      <Controller
                        name="maximumParties"
                        control={control}
                        render={({ field }) => (
                          <Input
                            id="maximumParties"
                            label={t('Maximum number')}
                            placeholder={t('Enter max number')}
                            positiveIntegersOnly
                            required
                            fullWidth
                            {...field}
                            error={!!errors.maximumParties}
                            errorText={errors.maximumParties?.message}
                            aria-labelledby="maximumParties-helper"
                          />
                        )}
                      />
                      <ContextualHelp
                        title="This is the maximum cumulative number of parties that can be invited to a customers product subscription."
                        id="maximumParties-helper"
                      />
                    </StyledFieldContainer>
                  </Grid>
                </Grid>
              </Paper>
            </section>
            <section aria-labelledby="multi-party-min-max" style={{ marginBottom: internalMdPadding }}>
              <Paper elevation={0} responsivePadding>
                <H2 gutter id="multi-party-min-max">
                  {t('Roles & permissions')}
                </H2>
                <Body2 gutter>
                  {t(
                    'Roles control what parties can do. When a customer invites parties via their app, they can choose the role for each party. There are currently 3 roles. Here you can choose the roles for invitees and inviters.'
                  )}
                </Body2>
                <InfoPanel
                  body={t(
                    "The party with the role 'Owner' is responsible for all deposits and any lending liabilities – no other roles have this responsibility"
                  )}
                  css={`
                    margin-bottom: ${internalMdPadding};
                  `}
                />
                <MultiPartyTable control={control} />
              </Paper>
            </section>
            <section aria-labelledby="multi-party-min-max">
              <Paper elevation={0} responsivePadding>
                <H2 gutter id="multi-party-min-max">
                  {t('Invitation expiry')}
                </H2>
                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item sm={6} md={4} lg={3}>
                    <StyledFieldContainer>
                      <Controller
                        name="invitationExpiryHours"
                        control={control}
                        render={({ field }) => (
                          <Input
                            id="invitationExpiryHours"
                            label={t('Invite expiry (hours)')}
                            placeholder={t('Enter number')}
                            positiveIntegersOnly
                            required
                            fullWidth
                            error={!!errors.invitationExpiryHours}
                            errorText={errors.invitationExpiryHours?.message}
                            {...field}
                            aria-labelledby="invitationExpiryHours-helper"
                          />
                        )}
                      />

                      <ContextualHelp
                        title="Any invite will expire after this time and the customer will need to send a new invite to the party. This is intended to be used as a risk control."
                        id="invitationExpiryHours-helper"
                      />
                    </StyledFieldContainer>
                  </Grid>
                </Grid>
              </Paper>
            </section>
          </form>
        }
        title={t(FeatureName.MULTI_PARTY)}
        description={t(
          'Multi-party enables you to define the number of parties that can be present on a product subscription. You can define the maximum number and the roles and who can invite additional parties.'
        )}
        toolbarButtons={
          <Grid container direction="row" justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <InfoIconButton
                ariaProps={{
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                  'aria-label': 'More about Multi-party',
                }}
                onClick={() => showInfographicModal(HelpModalType.MULTI_PARTY)}
              />
            </Grid>
          </Grid>
        }
        icon={<EntityBadgeMultiParty size={AvatarSize.LARGE} />}
        mainProps={{ style: {} }}
        headerProps={{ style: {}, 'aria-label': 'Credit limit' }}
        submitButtonDisabled={!isValid || !isDirty}
        onSubmit={onSubmit}
        onCancel={() => navigate('./../..')}
      />
    </>
  )
}

export default MultiPartyForm
