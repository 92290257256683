import { StatementAnniversaryOfAccount } from 'api/products.transformers'
import { ExtendedProduct, StatementFormValues, Statement, StatementPeriod } from 'store/products/types'

export const hasStatementsFeature = (product: ExtendedProduct): boolean =>
  Array.isArray(product.statements) && product.statements.length > 0

export const toStatementFormValues = (product: ExtendedProduct): StatementFormValues => {
  if (Array.isArray(product.statements) && product.statements.length > 0) {
    return {
      statementValue: product.statements.map(
        (item: Statement): Statement => ({
          ...item,
          statementType: item.statementType,
          statementPeriod: item.statementPeriod,
          statementDescription: item.statementDescription,
          startDay:
            item.accountAnniversary && item.statementPeriod === StatementPeriod.MONTH
              ? StatementAnniversaryOfAccount
              : item.startDay,
          startMonth:
            item.accountAnniversary && item.statementPeriod === StatementPeriod.YEAR
              ? StatementAnniversaryOfAccount
              : item.startMonth,
          offsetDays: item.offsetDays,
          hideExternalIdentifiers: item.hideExternalIdentifiers,
          template: item.template,
        })
      ),
    }
  }
  return {
    statementValue: [],
  }
}
