import { call, put, delay } from 'redux-saga/effects'
import i18next from 'i18next'
import { ExtendedProduct } from '../types'
import { navigate } from 'store/router/actions'
import ModalActions from 'store/modal/actions'
import ProductsActions from 'store/products/actions'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import { showPendingActionModalSaga } from 'store/modal/sagas'
import { CreditLimitApi } from 'api'

export function* updateCreditLimitSaga(action: ReturnType<typeof ProductsActions.updateCreditLimit>) {
  try {
    const t = i18next.t.bind(i18next)
    const {
      payload: { productKey, version, ...creditLimit },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    yield call(CreditLimitApi.updateCreditLimit, productKey, version, creditLimit)
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(navigate(`/products/${productKey}`))
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showFeatureSuccessModal({
        featureName: t('Credit limit'),
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* deleteCreditLimitSaga(action: ReturnType<typeof ProductsActions.deleteCreditLimit>) {
  try {
    const {
      payload: { productKey, version },
    } = action
    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: `You're removing Credit limit feature`,
      message: "Are you sure? This can't be undone.",
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())

      yield call(CreditLimitApi.deleteCreditLimit, productKey, version)

      yield put(ProductsActions.getProductDetails({ productKey }))

      yield put(navigate(`/products/${productKey}`))

      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: `Credit limit successfully deleted`,
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}
