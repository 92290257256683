import { put, takeLatest } from 'redux-saga/effects'
import { push } from '@lagunovsky/redux-react-router'
import RouterActions, { NavigateFunctionWithOptions } from './actions'
import ModalActions from 'store/modal/actions'

function* showError(e: Error) {
  yield put(ModalActions.closeModal())
  yield put(ModalActions.showFeatureFailureModal(e))
}

function* navigate(action: ReturnType<typeof RouterActions.navigate>) {
  try {
    const basename: string = new URL(document.baseURI).pathname
    const to =
      action.payload && typeof action.payload === 'string'
        ? action.payload
        : (action?.payload as NavigateFunctionWithOptions)?.to
    const prefix = typeof to === 'string' && to.startsWith('/') ? basename.slice(0, -1) : ''

    yield put(push(`${prefix}${to}`, (action?.payload as NavigateFunctionWithOptions)?.options))
  } catch (e: any) {
    yield showError(e)
  }
}

export default function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  yield takeLatest(RouterActions.navigate.type, navigate)
}
