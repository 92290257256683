import React, { useState } from 'react'
import { DialogActions, DialogContent, Typography, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useFormikContext } from 'formik'
import axios from 'axios'
import { StandardModal } from 'components'
import URLInput from 'components/form/URLInput'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  open: boolean
  closeDialog: (newImage?: string) => void
}

const StyledDialogActions = styled(DialogActions)`
  padding: 24px;
`
const TypographyDescription = styled(Typography)`
  margin-bottom: 2px;
  margin-top: 10px;
  line-height: 1.5;
  font-size: 11px;
  color: ${(props: any) => `${props.theme.palette.text.hint}`};
  word-wrap: break-word;
  white-space: pre-line !important;
  display: inline-block !important;
`
const TypographyError = styled(Typography)`
  margin-bottom: 2px;
  margin-top: 10px;
  line-height: 1.5;
  font-size: 12px;
  color: ${(props: any) => `${props.theme.palette.rejected.main}`};
`

const AddCardFromURLDialog = ({ closeDialog, open }: Props) => {
  const { t } = useTranslation()
  const { errors, values } = useFormikContext()
  const [warning, setWarning] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const onCheckURLLink = async () => {
    if (typeof values === 'object' && values) {
      if ('imagefromurl' in values) {
        setWarning(null)
        setLoading(true)
        try {
          const resp = await axios.get((values as any).imagefromurl, { responseType: 'arraybuffer' })
          if (resp.headers['content-type'].toString().slice(0, 5) !== 'image') {
            setLoading(false)
            setWarning(t(`This URL doesn’t return an image – the URL must be to an image file`))
            return
          }
          // TODO: leaving below in case he user wants to see preview of image before cheking it in
          // const blob = new Blob([resp.data], { type: resp.headers['content-type'] })
          // const image = URL.createObjectURL(blob)
          setLoading(false)
          closeDialog((values as any).imagefromurl)
        } catch (err) {
          setLoading(false)
          setWarning(
            t(
              `Sorry, we couldn’t access this URL, this could be because the URL doesn’t exist or because the URL isn’t public`
            )
          )
        }
      }
    }
  }

  return (
    <StandardModal title={t('Enter card image URL')} open={open} onClose={closeDialog} maxWidth="sm" fullWidth>
      <>
        <DialogContent>
          <URLInput name="imagefromurl" required label="Card image URL" />
          <TypographyDescription variant="body2">
            {`Enter the full URL starting with http e.g. https://10xbanking.com/cardimage.png
            The image must be publicly available outside of your organisations file management system
            We recommend only the following formats JPEG, PNG, GIF, WEBP and SVG`}
          </TypographyDescription>
          {warning && <TypographyError variant="body2">{warning}</TypographyError>}
        </DialogContent>
        <StyledDialogActions>
          <Button onClick={() => closeDialog()} color={ColorType.BUTTON} variant="text">
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => onCheckURLLink()}
            color={ColorType.BUTTON}
            variant="contained"
            disabled={'imagefromurl' in errors || loading}
          >
            {loading ? <CircularProgress size={25} color="secondary" /> : t('Done')}
          </Button>
        </StyledDialogActions>
      </>
    </StandardModal>
  )
}
export default AddCardFromURLDialog
