import React from 'react'
import { Grid, Box } from '@material-ui/core'
import ConditionContent from './ConditionContent'
import { OrderedListItem } from './styled'
import { BuilderCondition } from 'store/products/typings/conditionBuilder'
import { DeleteIconButton, DesignIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface Props {
  conditions: BuilderCondition[]
  onConditionChange: (i: number) => void
  onConditionDelete: (i: number) => void
}

const ConditionList = ({ conditions, onConditionDelete, onConditionChange }: Props) => (
  <>
    {conditions.map((condition, i) => (
      <OrderedListItem hasBorder key={condition.dataPoint.id + i} component="li" flexDirection="column">
        <Grid container direction="row">
          <Grid item xs={12} sm={9}>
            <ConditionContent condition={condition} isFirst={i === 0} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end" height="100%">
              <DeleteIconButton onClick={() => onConditionDelete(i)} />
              <DesignIconButton
                onClick={() => onConditionChange(i)}
                ariaProps={{ 'aria-controls': 'condition-dialog', 'aria-haspopup': 'dialog' }}
              />
            </Box>
          </Grid>
        </Grid>
      </OrderedListItem>
    ))}
  </>
)

export default ConditionList
