import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { ProductSyndicationState } from './types'

const initialState: ProductSyndicationState = {
  productSyndications: [],
  loading: false,
  error: undefined,
}

const reducer = createReducer<ProductSyndicationState>(initialState, {
  [actions.getProductSyndications.type]: (state: ProductSyndicationState) => {
    state.productSyndications = []
    state.loading = true
  },
  [actions.getProductSyndicationsSuccess.type]: (
    state: ProductSyndicationState,
    action: ReturnType<typeof actions.getProductSyndicationsSuccess>
  ) => {
    state.productSyndications = action.payload.products
    state.loading = false
    state.error = undefined
  },
  [actions.getProductSyndicationsFail.type]: (
    state: ProductSyndicationState,
    action: ReturnType<typeof actions.getProductSyndicationsFail>
  ) => {
    state.loading = false
    state.error = action.payload
  },
})

export default reducer
