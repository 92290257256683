import React from 'react'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ProductsActions from 'store/products/actions'
import { Product } from 'store/products/types'
import ProductsSelectors from 'store/products/selectors'
import { RootState } from 'store'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  product: Product
}

const mapStateToProps = (state: RootState) => ({
  updating: ProductsSelectors.selectUpdating(state),
})

const mapDispatchToProps = {
  createNewProductVersion: ProductsActions.createNewProductVersion,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps & WithTranslation

const EditLiveProduct = (props: Props) => {
  const { product, updating } = props
  const { t } = useTranslation()

  return (
    <Button
      onClick={() => props.createNewProductVersion(product.productKey)}
      variant="contained"
      color={ColorType.BUTTON}
      disabled={!product.status || updating}
    >
      {t('Create new version')}
    </Button>
  )
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditLiveProduct))
