import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import Product from '../Product/Product'
import { Loader } from 'components'
import TasksActions from 'store/tasks/actions'
import GovernanceActions from 'store/governance/actions'
import ProductsActions from 'store/products/actions'
import { ProductKey } from 'store/products/types'
import { RootState } from 'store'
import ProductsSelector from 'store/products/selectors'
import { getCurrencyOfProduct } from 'api/utils'
import * as UserSelector from 'store/user/selectors'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearCurrentProductTasks: () => dispatch(TasksActions.clearTasksForProduct()),
  clearCurrentProductProcess: () => dispatch(GovernanceActions.clearProcessExecution()),
  getVersionDetail: (productKey: ProductKey, version: string) =>
    dispatch(ProductsActions.getProductByVersion({ productKey, version })),
})

const mapStateToProps = (state: RootState) => ({
  isLoading: ProductsSelector.selectLoading(state),
  product: ProductsSelector.selectSelectedProduct(state),
  selectSelectedTenantIndex: UserSelector.selectSelectedTenantIndex(state),
  bankInterestRates: ReferenceDataSelectors.selectAllBankInterestRate(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

export const ProductVersionPage = ({
  getVersionDetail,
  product,
  isLoading,
  clearCurrentProductProcess,
  clearCurrentProductTasks,
  selectSelectedTenantIndex,
  bankInterestRates,
}: Props) => {
  const { productKey, version } = useParams()

  if (!productKey) throw new Error('productKey not found')
  useEffect(() => {
    getVersionDetail(productKey, version || '')
  }, [productKey, version, getVersionDetail])
  useEffect(
    // This code is only called when component will unmount
    () => () => {
      clearCurrentProductProcess()
      clearCurrentProductTasks()
    },
    [clearCurrentProductProcess, clearCurrentProductTasks]
  )
  const { t } = useTranslation()
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  if (isLoading) {
    return <Loader />
  }

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('{{tenantName}} products', { tenantName: currentTenant?.name }), path: `/products/` },
      { title: `${product?.productName}`, path: `/products/${productKey}` },
      { title: t('Versions'), path: `/products/${productKey}/versions` },
      { title: version || '', path: '' },
    ],
    trigger: !!product,
  })

  if (!product || product.productKey !== productKey) {
    // Stop rendering previous product
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const fakeFunction = (): void => {}
  return (
    <Product
      product={product}
      isOldVersion
      open={false}
      editProduct={fakeFunction}
      onClickCancel={fakeFunction}
      onDeleteFeature={fakeFunction}
      currencyOfProduct={getCurrencyOfProduct(product)}
      selectSelectedTenantIndex={selectSelectedTenantIndex}
      bankIndexRates={bankInterestRates}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVersionPage)
