import { orderBy } from 'lodash'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { ProcessKey } from 'store/governance/types'
import {
  ApproveTaskPayload,
  AssigneeKey,
  AssignTaskPayload,
  RejectTaskPayload,
  Task,
  TaskDefinition,
  TaskDefinitionFormValue,
  TaskKey,
} from 'store/tasks/types'

const createTaskFetch = async () => createAsyncAuthAwareAxios('/v1/governance/taskExecutions')

async function rejectTask(taskKey: TaskKey, rejectTaskPayload: RejectTaskPayload) {
  const { data } = await (await createTaskFetch()).put(`/${taskKey}/reject`, rejectTaskPayload)
  return data
}

async function approveTask(taskKey: TaskKey, approveTaskPayload: ApproveTaskPayload) {
  const { data } = await (await createTaskFetch()).put(`/${taskKey}/approve`, {
    ...approveTaskPayload,
    message:
      approveTaskPayload.message && approveTaskPayload.message.length > 0 ? approveTaskPayload.message : undefined,
  })
  return data
}

async function assignTask(payload: AssignTaskPayload, taskKey: string) {
  const { data } = await (await createTaskFetch()).put(`/${taskKey}/assign`, {
    assigneeKey: payload.assigneeKey,
    assigneeType: payload.assigneeType,
  })
  return data
}

const createTasksFetch = async () => createAsyncAuthAwareAxios('/v1/governance')

async function tasksExecutionsForAssignee(assigneeKey: AssigneeKey): Promise<Task[]> {
  const { data } = await (await createTasksFetch()).get<Task[]>('taskExecutions', {
    params: {
      assigneeKey,
    },
  })
  return orderBy(data)
}

async function tasksExecutionsForProduct(processExecutionKey?: string): Promise<Task[]> {
  const { data } = await (await createTasksFetch()).get<Task[]>('taskExecutions', {
    params: {
      processExecutionKey,
    },
  })
  return orderBy(data)
}

async function getTaskDefinitions(processKey: ProcessKey) {
  const { data } = await (await createAsyncAuthAwareAxios('/v1/governance')).get<TaskDefinition[]>(
    `/processDefinitions/${processKey}/taskDefinitions`
  )
  return orderBy(data, ['sequence', 'name'])
}

async function createTaskDefinitions(processKey: ProcessKey, payload: TaskDefinitionFormValue) {
  const { data } = await (await createAsyncAuthAwareAxios('/v1/governance')).post<TaskDefinitionFormValue>(
    `/processDefinitions/${processKey}/taskDefinitions`,
    payload.taskDefinitions
  )
  return data
}

async function updateTaskDefinitions(processKey: ProcessKey, payload: TaskDefinitionFormValue) {
  const { data } = await (await createAsyncAuthAwareAxios('/v1/governance')).put<TaskDefinitionFormValue>(
    `/processDefinitions/${processKey}/taskDefinitions`,
    payload.taskDefinitions
  )
  return data
}

export default {
  rejectTask,
  approveTask,
  assignTask,
  tasksExecutionsForAssignee,
  tasksExecutionsForProduct,
  getTaskDefinitions,
  createTaskDefinitions,
  updateTaskDefinitions,
}
