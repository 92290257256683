import React, { useState } from 'react'
import Sticky from 'react-sticky-el'
import { Card, CardActions, Grid, Menu, GridSpacing, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Add } from '@material-ui/icons'
import { isArray } from 'lodash'
import styled from 'styled-components/macro'
import { FieldArray } from 'formik'
import SubmitButton from './SubmitButton'
import { AddElement, AddNestedElement } from 'components'
import { MultipleChoiceElement } from 'store/products/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface BottomBarProps {
  addLabel?: string
  elements?: { [key: string]: MultipleChoiceElement | MultipleChoiceElement[] }
  isAddButtonDisabled?: boolean
  position?: 'top' | 'bottom'
  readOnly?: boolean
  disabled?: boolean
  triggerAction?: (item: any) => void
  children?: React.ReactNode
  showCancel?: boolean
  onCancelClick?: VoidFunction
  hideSubmit?: boolean
  noDirty?: boolean
  inlineNavigation?: true
  spacing?: GridSpacing
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 3px;
  padding: 0;
`
const ButtonStyle = styled(Button)`
  margin: 6px;
`

const AddIcon = styled(Add)`
  margin-right: 8px;
`

const ButtonsContainer = styled(Grid)`
  ${(props: any) => `${props.theme.breakpoints.up('xs')}`} {
    justify-content: flex-end;
  }
`

const ButtonContainerCard = styled(Card)`
  padding: 16px 14px;
`

const FormBottomBar = ({
  addLabel,
  position,
  elements,
  spacing = 4,
  readOnly,
  isAddButtonDisabled,
  disabled = false,
  triggerAction,
  children,
  showCancel,
  onCancelClick,
  hideSubmit = false,
  noDirty,
  inlineNavigation,
}: BottomBarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation()

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const elementsEntries = Object.entries(elements || {})
  const isSingleElement: boolean =
    !!elements && !!elementsEntries && elementsEntries.length === 1 && !isArray(elementsEntries[0][1])

  const renderNavigation = () => (
    <ButtonsContainer container spacing={spacing} direction="row">
      {elements && isSingleElement && (
        <Grid item sm={6}>
          <FieldArray name={`${elementsEntries[0][0]}`}>
            {({ push }) => (
              <ButtonStyle
                aria-controls="form-bottom-bar-menu"
                aria-haspopup="true"
                variant="outlined"
                color={ColorType.BUTTON}
                disabled={(elementsEntries[0][1] as MultipleChoiceElement).disabled || readOnly}
                onClick={() => {
                  if (!readOnly) {
                    push((elementsEntries[0][1] as MultipleChoiceElement).defaultValue)
                    handleClose()
                    if (triggerAction) triggerAction((elementsEntries[0][1] as MultipleChoiceElement).defaultValue)
                  }
                }}
              >
                <AddIcon />
                {addLabel}
              </ButtonStyle>
            )}
          </FieldArray>
        </Grid>
      )}
      {!isSingleElement && elements && addLabel && !isAddButtonDisabled && (
        <Grid item sm={6}>
          <ButtonStyle
            aria-controls="form-bottom-bar-menu"
            aria-haspopup="true"
            onClick={handleClick}
            variant="outlined"
            color={ColorType.BUTTON}
          >
            <AddIcon />
            {addLabel}
          </ButtonStyle>
          <Menu
            id="form-bottom-bar-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            {elementsEntries.map(([key, element]) =>
              isArray(element) ? (
                <AddNestedElement
                  elements={element}
                  key={key}
                  name={key}
                  handleClose={handleClose}
                  parentAnchorEl={anchorEl}
                  triggerAction={triggerAction}
                />
              ) : (
                <AddElement
                  key={key}
                  name={element.name || key}
                  disabled={element.disabled || readOnly}
                  label={element.label || ''}
                  defaultValue={element.defaultValue}
                  multiple={element.multiple}
                  onAfterClick={handleClose}
                  menuItemProps={element.menuItemProps}
                  triggerAction={triggerAction}
                />
              )
            )}
          </Menu>
        </Grid>
      )}
      <Grid item sm={6}>
        <Box display="flex" justifyContent="flex-end" gridGap={16}>
          {showCancel && (
            <ButtonContainer css={{ justifyContent: 'flex-start' }}>
              <Button variant="text" color={ColorType.BUTTON} onClick={onCancelClick}>
                {t('Cancel')}
              </Button>
            </ButtonContainer>
          )}
          <ButtonContainer style={{ gap: '16px' }}>
            {children}
            {!hideSubmit && (
              <SubmitButton variant="contained" color={ColorType.BUTTON} disabled={disabled} noDirty={noDirty}>
                {t('Submit')}
              </SubmitButton>
            )}
          </ButtonContainer>
        </Box>
      </Grid>
    </ButtonsContainer>
  )

  return (
    <Sticky {...(position && { mode: position })} positionRecheckInterval={500} holderCmp="div">
      {inlineNavigation ? (
        renderNavigation()
      ) : (
        <ButtonContainerCard elevation={4}>
          <CardActions>{renderNavigation()}</CardActions>
        </ButtonContainerCard>
      )}
    </Sticky>
  )
}

export default FormBottomBar
