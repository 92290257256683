import React from 'react'
import i18next, { TFunction } from 'i18next'
import {
  FeeReward,
  ConditionAggregateFilter,
  ConditionFilter,
  FeeRewardFormValues,
  FeeRewardRule,
  FeeRewardDataPointType,
  FeeRewardConsequenceType,
  FeeRewardCondition,
} from 'store/products/features/feesRewards/types'
import 'styled-components/macro'
import FeeRewardAmount from 'pages/features/FeesRewards/_shared/FeeRewardAmount'
import { convertISOFrequencyToStr } from 'utils/viewFrequencyUtils'
import { convertFormValuesToISOFrequency, convertISOFrequencyToFormValues } from 'pages/Product/product.utils'
import { ReviewRowGroup, ReviewRowItem } from 'dls/molecules/ReviewRows/types'
import { Body2 } from 'dls'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import {
  BuilderCondition,
  BuilderConditionFormValues,
  ConditionOperator,
  ConditionValue,
  DataPointAggregatedOperator,
  DataPointOperator,
} from 'store/products/typings/conditionBuilder'
import { transactionCodeMap } from 'static/transactionCodes'
import { FeeRewardFeatureType } from 'store/products/types'

export const getFeeRuleConditionFiltersValue = (
  currencyCode: string,
  value?: string,
  datapoint?: FeeRewardDataPointType
) => {
  switch (datapoint) {
    case FeeRewardDataPointType.TRANSACTION_ISO_CODE:
      if (value) {
        const codes = value.split(',')
        return codes && codes.length > 0 ? (
          <ul style={{ margin: 0, marginBottom: 8, paddingLeft: 28, listStyle: 'disc' }}>
            {codes?.map((code) => (
              <li key={code}>
                <Body2 variantMapping={{ body2: 'span' }}>{transactionCodeMap[code]}</Body2>
                <Body2 variantMapping={{ body2: 'span' }} color="textSecondary">{` (${code})`}</Body2>
              </li>
            ))}
          </ul>
        ) : null
      }
      return <>{value}</>
    case FeeRewardDataPointType.TRANSACTION_AMOUNT:
    case FeeRewardDataPointType.BALANCE_END_OF_DAY_AMOUNT:
      return (
        <Body2 variantMapping={{ body2: 'span' }}>
          <CurrencySymbol noTypography currencyCode={currencyCode} />
          {value}
        </Body2>
      )
    default:
      return <>{value?.toLocaleLowerCase()}</>
  }
}

export const getFeeRuleConditionFilters = ({
  currencyCode,
  filters,
  featureType,
}: {
  currencyCode: string
  filters?: ConditionFilter[]
  featureType: FeeRewardFeatureType
}) => {
  const t = i18next.t.bind(i18next)
  return filters?.map((filter, index) => (
    <li key={index} css="display: block; margin-bottom: 8px; list-style: none;">
      {index === 0 && <strong> {t(`${ConditionOperator.IF}`)} </strong>}
      {index > 0 && <strong> {t(`AND`)} </strong>}
      {`${t(`PRODUCT_FEATURE.${featureType}.${filter.datapoint}`)} ${`${t('is')} ${t(
        `CONDITION_BUILDER.${filter.operator}`
      ).toLowerCase()}`} `}
      {getFeeRuleConditionFiltersValue(currencyCode, filter.value, filter.datapoint)}
    </li>
  ))
}

export const getFeeRuleConditionAggregateFilters = ({
  currencyCode,
  filters,
  filterCount,
  featureType,
}: {
  currencyCode: string
  filterCount: number
  filters?: ConditionAggregateFilter[]
  featureType: FeeRewardFeatureType
}) => {
  const t = i18next.t.bind(i18next)
  return filters?.map((filter, index) => (
    <li key={index} css="display: block; margin-bottom: 8px; list-style: none;">
      {index === 0 && !(filterCount > 0) && <strong> {t(`${ConditionOperator.IF}`)} </strong>}
      {(index > 0 || filterCount > 0) && <strong> {t(`AND`)} </strong>}
      {t(`PRODUCT_FEATURE.${featureType}.${filter.datapoint}`)}{' '}
      {filter.aggregateFunction ? t(`CONDITION_BUILDER.${filter.aggregateFunction}`).toLowerCase() : ''} {t('is')}{' '}
      {t(`CONDITION_BUILDER.${filter.operator}`).toLowerCase()}{' '}
      {getFeeRuleConditionFiltersValue(currencyCode, filter.value, filter.datapoint)}
    </li>
  ))
}

export const getFeeRules = ({
  currencyCode,
  rules,
  featureType,
}: {
  currencyCode: string
  rules: FeeRewardRule[]
  featureType: FeeRewardFeatureType
}) => (
  <>
    {rules?.map((rule) => (
      <>
        {rule.conditions?.map((condition, i) => (
          <ul key={`condition-${i}`} css="margin: 0; padding: 0;">
            {getFeeRuleConditionFilters({ currencyCode, filters: condition.filters, featureType })}{' '}
            {getFeeRuleConditionAggregateFilters({
              currencyCode,
              filterCount: condition?.filters?.length || 0,
              filters: condition.aggregateFilters,
              featureType,
            })}
          </ul>
        ))}
      </>
    ))}
  </>
)

export const buildFeeRewardDefinition = ({
  feeReward,
  currencyCode,
  featureType,
  omitName = false,
}: {
  feeReward: FeeReward
  currencyCode: string
  omitName: boolean
  featureType: FeeRewardFeatureType
}): ReviewRowItem[] => {
  const t = i18next.t.bind(i18next)
  const hasConditions = !!feeReward.rules[0]?.conditions
  return [
    ...(!omitName
      ? [
          {
            term: t('Name'),
            definitions: [feeReward.name],
          },
        ]
      : []),
    ...(hasConditions
      ? [
          {
            term: t('Conditions'),
            definitions: [getFeeRules({ currencyCode, rules: feeReward.rules, featureType })],
          },
        ]
      : []),
    {
      term: t('Amount'),
      definitions: [<FeeRewardAmount key={feeReward.name} rules={feeReward.rules} currencyCode={currencyCode} />],
    },
    {
      term: t('Frequency'),
      definitions: [convertISOFrequencyToStr(t, feeReward.applicationFrequency)],
    },
  ].filter((item) => item) as any
}

export const buildFeeRewardDefinitions = ({
  entities,
  currencyCode,
  featureType,
  omitName = false,
}: {
  entities: FeeReward[]
  currencyCode: string
  featureType: FeeRewardFeatureType
  omitName?: boolean
}) =>
  entities
    ?.reduce((acc, cur, currentIndex, arr) => {
      acc.push({
        ...(currentIndex !== arr.length - 1 && { groupSeparator: 'light' }),
        definitions: [...buildFeeRewardDefinition({ feeReward: cur, currencyCode, omitName, featureType })],
      })

      return acc
    }, [] as ReviewRowGroup[])
    .filter((item) => item)

export const toFeeRewardPayload = ({ amount, name, id, conditions, ...applicationFrequency }: FeeRewardFormValues) => {
  const toAPIConditions = (builderConditions?: BuilderCondition[]): FeeRewardCondition[] | undefined => {
    const apiConditions: FeeRewardCondition[] = [
      {
        filters: [],
        aggregateFilters: [],
      },
    ]

    builderConditions?.forEach((condition) => {
      const { dataPoint, dataPointFirstOperator, dataPointSecondOperator, values } = condition

      const operators = Object.values(DataPointOperator)

      if (Object.values(DataPointAggregatedOperator).includes(dataPointFirstOperator as DataPointAggregatedOperator)) {
        apiConditions[0].aggregateFilters?.push({
          datapoint: dataPoint.id as FeeRewardDataPointType,
          aggregateFunction: dataPointFirstOperator as DataPointAggregatedOperator,
          operator: (dataPointSecondOperator || dataPointFirstOperator) as DataPointOperator,
          value: values.map((value) => value.value).join(''),
        })

        return
      }

      if (operators.some((operator) => operator === (dataPointSecondOperator || dataPointFirstOperator))) {
        apiConditions[0].filters?.push({
          datapoint: dataPoint.id as FeeRewardDataPointType,
          operator: (dataPointSecondOperator || dataPointFirstOperator) as DataPointOperator,
          value: values.map((value) => value.value).join(','),
        })
      }
    })

    if (!apiConditions[0].filters?.length) {
      apiConditions[0].filters = undefined
    }

    if (!apiConditions[0].aggregateFilters?.length) {
      apiConditions[0].aggregateFilters = undefined
    }

    if (!apiConditions[0].filters && !apiConditions[0].aggregateFilters) {
      return undefined
    }

    return apiConditions
  }

  return {
    applicationFrequency: convertFormValuesToISOFrequency(applicationFrequency),
    id,
    name,
    rules: [
      {
        conditions: toAPIConditions(conditions),
        consequences: [
          {
            name: FeeRewardConsequenceType.CHARGE_FIXED_AMOUNT,
            value: `${amount}`,
          },
        ],
      },
    ],
  }
}

export const defineValueType = (dataPoint: FeeRewardDataPointType): 'currency' | 'number' => {
  switch (dataPoint) {
    case FeeRewardDataPointType.BALANCE_END_OF_DAY_AMOUNT:
    case FeeRewardDataPointType.TRANSACTION_AMOUNT:
      return 'currency'
    case FeeRewardDataPointType.BALANCE_UNIQUE_ENTRY:
    case FeeRewardDataPointType.TRANSACTION_UNIQUE_ENTRY:
      return 'number'
    default:
      return 'number'
  }
}

export const defineValueLabel = (valueType: 'currency' | 'number'): string => {
  switch (valueType) {
    case 'currency':
      return 'Value'
    case 'number':
      return 'Number'
    default:
      return ''
  }
}

const buildValue = (dataPoint: string, value: string | ConditionValue[]): ConditionValue[] => {
  if (Array.isArray(value)) {
    return value
  }

  return [{ type: defineValueType(dataPoint as FeeRewardDataPointType), value, id: value }]
}

export const toFeeFormValues = ({
  entity,
  featureType,
}: {
  entity: FeeReward | null
  featureType: FeeRewardFeatureType
}): FeeRewardFormValues | null => {
  if (!entity) {
    return null
  }

  const toBuilderConditions = ({
    conditionOperator,
    feeConditions,
  }: {
    conditionOperator?: ConditionOperator
    feeConditions?: FeeRewardCondition[]
  }): BuilderCondition[] => {
    const t = i18next.t.bind(i18next)
    const builderConditions: BuilderCondition[] = []

    if (!feeConditions) {
      return builderConditions
    }

    const buildFilterConditionValues = (value: string, datapoint: FeeRewardDataPointType): ConditionValue[] => {
      if (datapoint === FeeRewardDataPointType.TRANSACTION_ISO_CODE) {
        return value.split(',').map((code) => ({
          id: code,
          value: code,
          type: 'code',
        }))
      }

      return [
        {
          id: value,
          type: defineValueType(datapoint),
          value,
        },
      ]
    }

    feeConditions.forEach((condition) => {
      condition.filters?.forEach((filter) => {
        const { operator, datapoint, value } = filter
        builderConditions.push({
          operator: conditionOperator || ConditionOperator.AND,
          dataPoint: { id: datapoint, name: t(`PRODUCT_FEATURE.${featureType}.${datapoint}`) },
          dataPointFirstOperator: operator,
          values: buildFilterConditionValues(value, datapoint),
        })
      })

      condition.aggregateFilters?.forEach((aggregateFilter) => {
        const { operator, datapoint, value, aggregateFunction } = aggregateFilter
        builderConditions.push({
          operator: conditionOperator || ConditionOperator.AND,
          dataPoint: { id: datapoint, name: t(`PRODUCT_FEATURE.${featureType}.${datapoint}`) },
          dataPointFirstOperator: aggregateFunction,
          dataPointSecondOperator: operator,
          values: [
            {
              id: value,
              type: 'number',
              value,
            },
          ],
        })
      })
    })

    return builderConditions
  }

  const { name, rules, applicationFrequency, id } = entity
  const amount = Number(rules[0]?.consequences[0]?.value)

  return {
    id,
    name,
    amount,
    ...convertISOFrequencyToFormValues(applicationFrequency),
    conditions: toBuilderConditions({
      conditionOperator: rules[0].conditionsOperator,
      feeConditions: rules[0]?.conditions,
    }),
  }
}

export const toCondition = ({
  featureType,
  operator,
  dataPoint,
  dataPointFirstOperator,
  dataPointSecondOperator,
  dataValue,
  t,
}: {
  featureType: FeeRewardFeatureType
  operator: ConditionOperator
  dataPoint: string
  dataPointFirstOperator: string
  dataPointSecondOperator: string
  dataValue: string | ConditionValue[]
  t: TFunction
}): BuilderCondition => ({
  operator,
  dataPoint: {
    id: dataPoint,
    name: t(`PRODUCT_FEATURE.${featureType}.${dataPoint}`),
  },
  dataPointFirstOperator: dataPointFirstOperator as DataPointOperator,
  dataPointSecondOperator: dataPointSecondOperator as DataPointOperator,
  values: buildValue(dataPoint, dataValue),
})

export const toConditionFormValues = (condition: BuilderCondition): BuilderConditionFormValues => {
  const { dataPoint, dataPointFirstOperator, dataPointSecondOperator, values } = condition
  return {
    dataPoint: dataPoint.id,
    dataPointFirstOperator,
    dataPointSecondOperator,
    dataValue: values.map((item) => item.value).join(','),
  }
}
