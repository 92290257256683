import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import WithholdingTaxCardActions from './WithholdingTaxCardActions'
import { EntityBadgeTaxRules } from 'dls/atoms/EntityBadges/EntityBadge'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { Body1, Body2 } from 'components'
import StatusBadgeIndexRate from 'dls/molecules/StatusBadgeIndexRate/StatusBadgeIndexRate'
import { WithholdingRule } from 'store/withholdingTax/types'
import { ColorType } from 'dls/shared/types'

export const Region2Text = styled(Body2)`
  white-space: nowrap;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-right:10px;
    },
  `};
`

interface Props {
  rule: WithholdingRule
}

const WithholdingTaxCard = ({ rule }: Props): React.ReactElement | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(`/reference-data/withholding-tax/${rule.ruleId}`)
  }

  return rule ? (
    <>
      <CatalogCard
        region1={[
          <EntityBadgeTaxRules key={1} />,
          <Body1 variantMapping={{ body1: 'div' }} key="2">
            {rule.name}
          </Body1>,
        ]}
        region2={[
          <Region2Text key="1" variantMapping={{ body2: 'p' }}>
            {t(`{{number}} rates`, { number: rule?.taxRules?.length || 0 })}
          </Region2Text>,
        ]}
        region3={[
          <div key={0}>
            <StatusBadgeIndexRate rates={rule.taxRules} />
          </div>,
        ]}
        region4={[
          <WithholdingTaxCardActions
            key="4"
            rule={rule}
            buttonChildren={t('ACTIONS')}
            buttonProps={{ color: ColorType.BUTTON }}
          />,
        ]}
        onClickOnCard={onClick}
        id={rule.ruleId}
      />
    </>
  ) : null
}

export default WithholdingTaxCard
