import styled from 'styled-components/macro'
import { Body1, Body2 } from 'dls/atoms/'
import { spacingBlockInBoxItem } from 'dls/shared/styleVar'

export const CustomBody1 = styled(Body1)`
  padding: ${spacingBlockInBoxItem};
`

export const TitleSection = styled.div`
  padding: ${spacingBlockInBoxItem};
  margin: ${spacingBlockInBoxItem};
`

export const ContentSection = styled.div`
  margin: 0;
  text-align: center;
`

export const ImageSection = styled.div`
  margin: ${spacingBlockInBoxItem};
`

export const DescriptionSection = styled(Body2)`
  margin: ${spacingBlockInBoxItem};
`
