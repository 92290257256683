import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { GovernanceState } from './types'

const initialState = {
  isLoading: false,
  error: undefined,
  data: [],
  processExecutions: [],
  processes: [],
  selectedProcess: undefined,
  processExecutionsLoading: false,
  processExecutionsError: undefined,
}

const reducer = createReducer<GovernanceState>(initialState, {
  [actions.getTasks.type]: (state) => ({ ...state, isLoading: true }),
  [actions.getTasksSuccess.type]: (state, action) => ({ ...state, isLoading: false, data: action.payload }),
  [actions.getTasksFail.type]: (state) => ({ ...state, isLoading: false }),
  [actions.processExecution.type]: (state) => ({ ...state, processExecutionsLoading: true }),
  [actions.processExecutionSuccess.type]: (state, action) => ({
    ...state,
    processExecutionsLoading: false,
    processExecutions: action.payload,
  }),
  [actions.processExecutionFail.type]: (state, action) => ({
    ...state,
    processExecutionsLoading: false,
    processExecutionsError: action.payload,
  }),
  [actions.processExecutionFailCloseModal.type]: (state) => ({
    ...state,
    processExecutionsLoading: false,
    processExecutionsError: null,
  }),

  [actions.clearProcessExecution.type]: (state) => ({ ...state, processExecutions: [] }),
  [actions.getProcess.type]: (state) => ({ ...state, isLoading: true }),
  [actions.getProcessSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    processes: action.payload,
  }),
  [actions.getProcessFail.type]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }),
  [actions.createProcess.type]: (state) => ({ ...state, isLoading: false }),
  [actions.createProcessSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    selectedProcess: action.payload,
  }),
  [actions.createProcessFail.type]: (state) => ({ ...state, isLoading: false }),
  [actions.updateSelectedProcess.type]: (state) => ({ ...state, isLoading: false }),
  [actions.updateSelectedProcessSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    selectedProcess: action.payload,
  }),
  [actions.updateSelectedProcessFail.type]: (state) => ({ ...state, isLoading: false }),
  [actions.selectProcessDetails.type]: (state, action) => ({
    ...state,
    isLoading: false,
    selectedProcess: action.payload,
  }),
})

export default reducer
