import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import StatementConfigurationCard from './view/StatementConfigurationCard'
import { Catalog, ZeroStateLandingPage } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import { InfoIconButton, TemplateForm } from 'dls'
import { NoInterestStatements } from 'assets'
import { EntityBadgeStatements, Paper, Button } from 'dls/atoms'
import { AvatarSize } from 'dls/shared/types'
import { HelpModalType } from 'store/modal/types'
import { Flexbox } from 'pages/Layout/Styled'
import useModal from 'components/Modal/hooks/useModal'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'

const StatementConfigurationCatalog = ({
  statementConfigurations,
}: {
  statementConfigurations?: StatementConfiguration[]
}): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { showInfographicModal } = useModal()

  const handleAdd = () => navigate(`create`)

  const onSearch = (item: StatementConfiguration, text: string) => item.name.toLowerCase().includes(text)

  const addStatementButton = (
    <Button
      onClick={handleAdd}
      variant="contained"
      fullWidth
      data-test-id="add-statement-button"
      aria-controls="main"
      css="height:36px;"
    >
      {t('Create statement')}
    </Button>
  )

  const infoButton = (
    <InfoIconButton
      key="more-about-icon"
      ariaProps={{
        'aria-label': `${t('About interest statements')}`,
        'aria-haspopup': 'dialog',
        'aria-controls': 'more-about-dialog',
      }}
      onClick={() => showInfographicModal(HelpModalType.INTEREST_STATEMENTS)}
      fontSize="medium"
      css="margin-right:6px;"
    />
  )

  return (
    <ContentContainer data-test-id="configuration-statement-page">
      {statementConfigurations?.length ? (
        <Catalog
          entityName="Interest statement"
          list={statementConfigurations}
          action={addStatementButton}
          searchFilter={onSearch}
          cardRenderer={(list, idx, key) => (
            <CatalogCardContainer key={key}>
              <StatementConfigurationCard statementConfiguration={list[idx]} />
            </CatalogCardContainer>
          )}
          icons={[infoButton]}
          searchButtonAriaLabel={t('Search templates')}
        />
      ) : (
        <TemplateForm
          icon={<EntityBadgeStatements size={AvatarSize.LARGE} />}
          toolbarButtons={
            <Flexbox direction="row" alignItems="center">
              {infoButton}
              {addStatementButton}
            </Flexbox>
          }
          title={t('Interest statement')}
          description={t(
            'Here you can create and manage interest statements which can be cover multiple products in order to generate an aggregated statement for a customer.'
          )}
          main={
            <Paper>
              <ZeroStateLandingPage
                image={NoInterestStatements}
                imageAlt={t('Illustration of a lady stood in front of an interest statement')}
                title={t('No interest statement have been created')}
                buttonLink={t('More about interest statements')}
                description={t(`Select “Create statement” above to get started`)}
                location="interest-statements"
              />
            </Paper>
          }
          CTAHidden
        />
      )}
    </ContentContainer>
  )
}

export default StatementConfigurationCatalog
