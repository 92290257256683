import React from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons'
import { CloseIconButton, SearchIconButton } from './IconButtons/IconButtons'
import { CustomTheme } from 'dls/shared/styled'

interface SearchProps {
  value?: string
  searchFieldAriaLabel?: string
  searchButtonAriaLabel?: string
  searchFieldAriaControls?: string
  placeHolder?: string
  id?: string
  width?: string
  opaque?: boolean
}

interface OwnProps extends SearchProps {
  onClickSearchButton?(): void
  search(str: string): void
}

const SearchTextField = styled(TextField)<SearchProps>`
  min-width: 246px;
  ${({ width }: SearchProps) => width && `width: ${width}px`};
  ${({ opaque, theme }: { opaque?: boolean; theme: CustomTheme }) =>
    opaque && `background-color: ${theme.palette.background.paper}`};

  & input {
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
    padding-left: 4px;
    padding-right: 4px;
  }
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      min-width: inherit;
    }
  `};
`

export const StyledSearchIcon = styled(SearchIcon)`
  color: ${(props: any) => props.theme.palette.text.secondary};
`

export const StyledClearIcon = styled(ClearIcon)`
  color: ${(props: any) => props.theme.palette.text.secondary};
`
export const SearchField = ({
  id,
  search,
  value,
  searchFieldAriaLabel,
  searchButtonAriaLabel,
  onClickSearchButton,
  searchFieldAriaControls,
  placeHolder,
  opaque = false,
  width,
}: OwnProps) => (
  <SearchTextField
    id={id}
    placeholder={placeHolder}
    onChange={(e: React.ChangeEvent<{ value: string }>) => {
      if (search) {
        search(e.target.value)
      }
    }}
    value={value}
    fullWidth
    type="search"
    data-dls="molecule-search-text-field"
    inputProps={{
      'aria-label': searchFieldAriaLabel,
      'aria-controls': searchFieldAriaControls,
    }}
    width={width}
    opaque={opaque}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {value && (
            <CloseIconButton
              id={`${id}-button`}
              onClick={() => search && search('')}
              aria-label="Clear search terms"
              aria-controls={searchFieldAriaControls}
              size="small"
              tabIndex={0}
            />
          )}
          <SearchIconButton
            id={`${id}-button`}
            onClick={onClickSearchButton}
            aria-label={`${searchButtonAriaLabel}`}
            aria-controls={searchFieldAriaControls}
            size="small"
            tabIndex={0}
          />
        </InputAdornment>
      ),
    }}
  />
)

export default SearchField
