import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectProductSyndicationsState = (state: RootState) => state.productSyndications

const selectProductSyndications = createSelector(selectProductSyndicationsState, (state) => state.productSyndications)
const selectLoading = createSelector(selectProductSyndicationsState, (state) => state.loading)
const selectError = createSelector(selectProductSyndicationsState, (state) => state.error)

export default {
  selectProductSyndications,
  selectLoading,
  selectError,
}
