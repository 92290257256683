import React from 'react'
import { Avatar as AvatarMUI } from '@material-ui/core'
import styled from 'styled-components/macro'
import { EntityColorSelector } from 'utils/customColors'
import { Caption, H5 } from 'dls/atoms/Typography'
import { AvatarProps, AvatarSize } from 'dls/shared/types'

const sizes = {
  [AvatarSize.SMALL]: 24,
  [AvatarSize.NORMAL]: 40,
  [AvatarSize.LARGE]: 48,
}

const StyledAvatarMUI = styled(AvatarMUI)`
  ${EntityColorSelector}
`

const Avatar = ({ children, size = AvatarSize.NORMAL, color }: AvatarProps) => (
  <StyledAvatarMUI
    data-dls="atom-avatar"
    aria-hidden
    color={color}
    style={{
      width: sizes[size],
      height: sizes[size],
    }}
  >
    {size === AvatarSize.SMALL && (
      <Caption aria-hidden variantMapping={{ caption: 'span' }}>
        {children}
      </Caption>
    )}
    {size === AvatarSize.NORMAL && (
      <H5 aria-hidden variantMapping={{ h5: 'span' }}>
        {children}
      </H5>
    )}
    {size === AvatarSize.LARGE && (
      <H5 aria-hidden variantMapping={{ h5: 'span' }} style={{ fontWeight: 700 }}>
        {children}
      </H5>
    )}
  </StyledAvatarMUI>
)

export default Avatar
