import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { FastField, Field, Form, FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { InfoOutlined } from '@material-ui/icons'
import TiersBandsList from '../../shared/TiersBandsList'
import { ContentPaperForStickyBottomBar } from '../../styled'
import {
  rateYupValidation,
  stopPropagation,
  validationForTierBandStartRangeEndRangeTax,
  withFormikSimple,
  Yip,
} from 'utils/form.utils'
import { DateTimePickerComponent, FieldPercentage, FormBottomBar, RadioGroupFieldComplex, H2, Body2 } from 'components'
import { RateTierBand } from 'store/products/types'
import { FormBottom } from 'pages/SuspenseAccounts/Transactions/Styles'
import { WithholdingTaxRule, WithholdingRule, WithholdingTaxRuleFormValues } from 'store/withholdingTax/types'
import { toBankTz } from 'utils/date.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { InfoPanel } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  onCancelClick?: () => void
  currencyCode?: string | null
  selectedTaxRule: WithholdingRule
}

function EditTaxRuleForm(props: Props & FormikProps<WithholdingTaxRule>) {
  const { t } = useTranslation()

  const selectTierBandCalcMethod = props.values.tierBandCalcMethod

  const { showInfographicModal } = useModal()

  return (
    <Form>
      <ContentPaperForStickyBottomBar elevation={1}>
        <div style={{ margin: 24 }}>
          <H2 gutter>{props.selectedTaxRule.name}</H2>
          <Box mb={2}>
            <InfoPanel title={t("You can only edit rates – the effective date can't be changed")} />
          </Box>
          <Grid item xs={12}>
            <Body2>
              {t('There are 3 options for how withheld tax will be calculated:', { nsSeparator: '||' })}
              <LinkWithText
                aria-controls="more-about-dialog"
                aria-haspopup="dialog"
                onClick={(e: React.MouseEvent) => {
                  stopPropagation(e)
                  showInfographicModal(HelpModalType.INDEX_RATES)
                }}
                endIcon={<InfoOutlined />}
              >
                {t('see how these work')}
              </LinkWithText>
            </Body2>
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ marginTop: 8 }}
            xs={12}
          >
            <Grid item>
              <Field
                name="tierBandCalcMethod"
                component={RadioGroupFieldComplex}
                onChange={(value: any) => {
                  if (value === RateTierBand.FLAT) {
                    props.setFieldValue('taxTierBand', [], true)
                    props.setFieldTouched('taxTierBand', true, true)
                    props.setFieldValue('flatRate', null)
                    props.setFieldTouched('flatRate', false, true)
                  } else {
                    props.setFieldValue('taxTierBand', [{ startRange: 0 }], true)
                    props.setFieldTouched('taxTierBand', true, true)
                    props.setFieldValue('flatRate', null, true)
                    props.setFieldTouched('flatRate', true, true)
                  }
                }}
                data={RateTierBand}
                required
                validate={Yip(Yup.string().required())}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {selectTierBandCalcMethod === RateTierBand.FLAT && (
              <Grid item xs={12} style={{ marginTop: 40 }}>
                <Field
                  name="flatRate"
                  label={t('Tax rate is')}
                  required
                  placeholder={t('Please enter')}
                  validate={Yip(rateYupValidation(2, 1000).required())}
                  component={FieldPercentage}
                  InputProps={{ style: { width: 203 } }}
                  numberFormatProps={{ decimalScale: 2 }}
                />
              </Grid>
            )}
            {[RateTierBand.TIER, RateTierBand.BAND].includes(selectTierBandCalcMethod as RateTierBand) && (
              <TiersBandsList name="taxTierBand" currencyCode={props.currencyCode} />
            )}
          </Grid>
          {selectTierBandCalcMethod && (
            <ContentContainer>
              <Grid item xs={4} style={{ marginTop: 20 }}>
                <FastField
                  placeholder={t('Select date')}
                  name="effectiveDate"
                  type="text"
                  clearable
                  variant="inline"
                  component={DateTimePickerComponent}
                  disabled
                  disablePast={false}
                  label={`${'Effective date'} (${toBankTz(getIn(props.values, `effectiveDate`)).tz})`}
                />
              </Grid>
            </ContentContainer>
          )}
          <FormBottom style={{ marginTop: 24 }}>
            <FormBottomBar inlineNavigation disabled={!props.dirty} hideSubmit>
              <Button
                variant="text"
                color={ColorType.BUTTON}
                onClick={props.onCancelClick}
                style={{ marginRight: '12px' }}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color={ColorType.BUTTON}
                type="submit"
                disabled={!props.isValid || props.isValidating}
              >
                {t('Submit')}
              </Button>
            </FormBottomBar>
          </FormBottom>
        </div>
      </ContentPaperForStickyBottomBar>
    </Form>
  )
}

const RuleSchema = Yup.object().shape({
  taxTierBand: Yup.array(
    validationForTierBandStartRangeEndRangeTax('This value must be greater than the same field in the preceding row')
  ).nullable(),
})

export default withFormikSimple<Props, WithholdingTaxRuleFormValues>(EditTaxRuleForm as any, {
  validationSchema: RuleSchema,
  isInitialValid: () => false,
})
