import { FormControl, Input, InputLabel } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { NumberFormatCustom } from 'components/form/TextFieldComplex'

interface Props {
  value: string
  onChange: (e: ChangeEvent) => void
  currencyCode: string
  label: string
  id: string
  required?: boolean
}

const SimpleAmountField = ({ currencyCode, id, label, onChange, required, value }: Props) => {
  const { t } = useTranslation()

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={id}>{label + (required ? ' *' : '')}</InputLabel>
      <Input
        id={id}
        type="currency"
        placeholder={t('Enter value')}
        value={value}
        onChange={onChange}
        startAdornment={
          <InputAdornment position="start">
            <CurrencySymbol currencyCode={currencyCode} />
          </InputAdornment>
        }
        inputProps={{ step: 'any', decimalScale: 2, allowNegative: false }}
        inputComponent={NumberFormatCustom as any}
        required={required}
      />
    </FormControl>
  )
}

export default SimpleAmountField
