import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import BankInterestRateCardActions from './BankInterestRateCardActions'
import { EntityBadgeIndexRate } from 'dls/atoms/EntityBadges/EntityBadge'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { Body1, Body2 } from 'components'
import { BankInterestRate } from 'store/referenceData/types'
import StatusBadgeIndexRate from 'dls/molecules/StatusBadgeIndexRate/StatusBadgeIndexRate'
import { ColorType } from 'dls/shared/types'

export const Region2Text = styled(Body2)`
  white-space: nowrap;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-right:10px;
    },
  `};
`

interface Props {
  indexRate: BankInterestRate
}

const BankInterestRateCard = ({ indexRate }: Props): React.ReactElement | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleViewIndexRate = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(`/reference-data/index-rates/${indexRate.bankIndexKey}`)
  }

  return indexRate ? (
    <>
      <CatalogCard
        region1={[
          <EntityBadgeIndexRate key="1" />,
          <Body1 variantMapping={{ body1: 'div' }} key="2">
            {indexRate.name}
          </Body1>,
        ]}
        region2={[
          <Region2Text key="1" variantMapping={{ body2: 'p' }}>
            {t(`{{number}} rates`, { number: indexRate.rates.length })}
          </Region2Text>,
        ]}
        region3={[
          <div key={0}>
            <StatusBadgeIndexRate rates={indexRate.rates} />
          </div>,
        ]}
        region4={[
          <BankInterestRateCardActions
            key="4"
            indexRate={indexRate}
            buttonChildren={t('ACTIONS')}
            buttonProps={{ color: ColorType.BUTTON }}
          />,
        ]}
        onClickOnCard={handleViewIndexRate}
        id={indexRate.bankIndexKey || ''}
      />
    </>
  ) : null
}

export default BankInterestRateCard
