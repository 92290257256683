import React, { createContext, Dispatch, useReducer, useContext } from 'react'

export type Table = string

export type TableState = Table[]

const TableStateContext = createContext<TableState | undefined>(undefined)

type Action = { type: 'CREATE'; id: string } | { type: 'REMOVE'; id: string } | { type: 'REMOVEALL' }

type TableDispatch = Dispatch<Action>
const TableDispatchContext = createContext<TableDispatch | undefined>(undefined)

function transferTableReducer(state: TableState, action: Action): TableState {
  switch (action.type) {
    case 'CREATE':
      return [...state, action.id]
    case 'REMOVE':
      return state.filter((todo) => todo !== action.id)
    case 'REMOVEALL':
      return []
    default:
      throw new Error('Unhandled action')
  }
}

export function TableContextProvider({ children }: { children: React.ReactNode }) {
  const [transferTableData, dispatch] = useReducer(transferTableReducer, [])

  return (
    <TableDispatchContext.Provider value={dispatch}>
      <TableStateContext.Provider value={transferTableData}>{children}</TableStateContext.Provider>
    </TableDispatchContext.Provider>
  )
}

export function useTableState() {
  const state = useContext(TableStateContext)
  if (!state) throw new Error(' Table not found')
  return state
}

export function useTableDispatch() {
  return useContext(TableDispatchContext)
}
