import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Body1 } from 'dls/atoms'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'

interface Props {
  title: string
  goto: string
  icon: React.ReactElement
}

const InterestStatementsCard = ({ title, goto, icon }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <CatalogCard
      region1={[
        icon,
        <Body1 variantMapping={{ body1: 'div' }} key="1">
          {t(title)}
        </Body1>,
      ]}
      region4={[
        <Button component={Link} to={goto} aria-controls="main" variant="text" key="1">
          {t('View')}
        </Button>,
      ]}
      onClickOnCard={() => navigate(goto)}
      id=""
    />
  )
}

export default InterestStatementsCard
