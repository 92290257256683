import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import StatementTemplatesLoading from 'pages/features/Statements/StatementConfigLoader/StatementTemplatesLoading'
import StatementTemplateError from 'pages/features/Statements/StatementConfigLoader/StatementTemplateError'
import { StatementType, ExtendedProduct } from 'store/products/types'
import useStatementConfiguration from 'pages/Templates/StatementConfigurationCatalog/utils/useStatementConfiguration'
import StatementConfigurationSelectors from 'store/templates/statementConfiguration/selectors'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'
import { Body2, Link } from 'dls'

interface Props {
  product: ExtendedProduct
  children?: (configs: StatementConfiguration[]) => void
  smallView?: boolean
}

const StatementConfigLoader = ({ product, smallView, children }: Props) => {
  const {
    fetchStatementConfigurations,
    statementConfigurationsLoading: loading,
    statementConfigurationsError: error,
  } = useStatementConfiguration()
  const { t } = useTranslation()

  const interestStatementConfigurations = useSelector(StatementConfigurationSelectors.selectInterestStatementConfigs)

  const hasErrors =
    !loading && (error || !interestStatementConfigurations || interestStatementConfigurations.length === 0)
  const hasNoItems =
    !loading && !error && Array.isArray(interestStatementConfigurations) && interestStatementConfigurations.length === 0

  useEffect(() => {
    fetchStatementConfigurations()
  }, [])

  return (
    <>
      {hasErrors ? (
        <>
          {hasNoItems && (
            <StatementTemplateError
              reason="no-items"
              smallView={smallView}
              message={
                <Body2>
                  {t("Don't worry, you can create a template in ")}
                  <Link component={RouterLink} to="/templates" color="secondary">
                    {t('templates')}
                  </Link>
                </Body2>
              }
              messageTitle={t('Oops, no statements exist')}
            />
          )}
          {!hasNoItems && (
            <StatementTemplateError
              reason="template-error"
              productSegment={product.productSegment}
              statementType={StatementType.INTEREST_STATEMENT}
              smallView={smallView}
            />
          )}
        </>
      ) : (
        loading && <StatementTemplatesLoading />
      )}
      {!loading && !hasErrors && <>{children && children(interestStatementConfigurations)}</>}
    </>
  )
}

export default StatementConfigLoader
