import React, { useState, useEffect } from 'react'
import { kebabCase } from 'lodash'
import { Drawer, Fade, List, ListItem, ListItemText, Tooltip, withWidth } from '@material-ui/core'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import { Link, useMatch, useResolvedPath, LinkProps } from 'react-router-dom'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
// import SettingsIcon from '@material-ui/icons/Settings'
import InfoIcon from '@material-ui/icons/Info'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import 'styled-components/macro'
import { ExpandLessIcon1, ExpandMoreIcon1, useStyles } from './Styled'
import {
  DashboardIcon,
  DataIcon,
  HelpIcon,
  LedgerIcon,
  ModelsIcon,
  PackagesIcon,
  PlatformIcon,
  ProductsIcon,
  ReportsIcon,
  SupervisorAccount,
} from './BankmanIcons'
import { CommonFileTextSettingsIcon, ProductSyndicationIcon, ReferenceDataIcon } from 'dls/atoms/Icons/SVGIcons'
import { Graphic, LogoLarge, Logo, Body2 } from 'dls'
import { FeatureFlag, NoRender, RoleCheckComponent } from 'components/ControlComponents'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import { selectConfig, selectFeatures } from 'store/tenant-config/selectors'
import { hasUserRightForPath } from 'utils/common.util'
import * as UserSelector from 'store/user/selectors'
import { selectUserRole } from 'store/user/selectors'
import AccessibleMenu from 'components/AccessibleMenu/AccessibleMenu'
import { SuperCoreLogo } from 'assets'
import { Tenant } from 'store/tenant-config/types'
import { TENANT_URL_PREFIX } from 'utils/constants'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

function NavBar({ open, width, handleDrawerToggle }: any) {
  const { t } = useTranslation()
  const classes = useStyles()
  const tenantConfig = useSelector(selectConfig)

  const selectParentIndex = useSelector(TenantConfigSelectors.selectParentIndex)
  const tenants = useSelector(TenantConfigSelectors.selectTenants)
  const isMultiTenant = useSelector(TenantConfigSelectors.isMultiTenant)
  const isParent = useSelector(TenantConfigSelectors.isParentTenant)
  const userRole = useSelector(selectUserRole)
  const features = useSelector(selectFeatures)
  const selectSelectedTenantIndex = useSelector(UserSelector.selectSelectedTenantIndex)
  const hasUserRoleForMultiTenant = (url?: string) => hasUserRightForPath(userRole, url, features.role_check)
  const selectCurrentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)
  const [anchorElBrand, setAnchorElBrand] = React.useState<null | HTMLElement>(null)

  const [brandIndex, setBrandIndex] = useState<number | undefined>(selectParentIndex)

  const isBrand: boolean = isMultiTenant && !isParent

  const isSmallScreen = (): boolean => width === 'xs' || width === 'sm'

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBrand(event.currentTarget)
  }

  const handleCloseBrand = () => {
    setAnchorElBrand(null)
  }

  const PathSelectableListItem = ({ children, to, ...props }: LinkProps) => {
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: false })
    return (
      <ListItem
        component={Link}
        to={to}
        className={
          match
            ? `MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button Mui-selected ${classes.listItem} ${classes.selected}`
            : classes.listItem
        }
        button
        aria-controls="main"
        selected={!!match}
        {...props}
      >
        {children}
      </ListItem>
    )
  }

  const renderTenantSelector = (): JSX.Element => {
    const menuItems = tenantConfig?.tenants
      ?.filter((option, index) => index !== brandIndex)
      .map((option, index) => ({
        extraMenuItemProps: { padding: '6px 16px' },
        testId: `tenant-dropdown-list-item-${index}`,
        internalUrl: `/${TENANT_URL_PREFIX}/${kebabCase(option.name)}`,
        children: (
          <Grid
            style={{ width: 239, height: 86 }}
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={4}>
              {!option.parent ? (
                <Logo src={option.logo.collapsed} alt={`${option.name} ${t('logo')}`} aria-hidden="true" />
              ) : (
                <Logo
                  src={option.logo.collapsed}
                  alt={`${option.name} ${t('logo')}`}
                  aria-hidden="true"
                  style={{ border: 0 }}
                />
              )}
            </Grid>
            <Grid item xs={8}>
              {t(option.name)}
            </Grid>
          </Grid>
        ),
      }))

    const menuButton = (
      <Button
        variant="text"
        onClick={handleClickListItem}
        aria-label={t('Change brand')}
        color={ColorType.BUTTON}
        css={`
          height: 36px;
          min-width: 54px;
          font-size: 12px;
          text-transform: none;
          margin-left: ${open ? 8 : 0}px;
        `}
      >
        <Fade in={open} timeout={{ enter: 1000, exit: 250 }}>
          <div>{open && t('Change tenant')}</div>
        </Fade>
        {anchorElBrand === null ? <ExpandMoreIcon1 $open={open} /> : <ExpandLessIcon1 $open={open} />}
      </Button>
    )

    const renderTenantLogo = ({
      currentTenant,
      opened,
    }: {
      currentTenant: Tenant | undefined
      opened: boolean
    }): JSX.Element => {
      const defineSrc = (): string | undefined => {
        if (currentTenant?.parent) {
          return opened ? tenantConfig?.assets?.logoURL : tenantConfig?.assets?.logoCollapsedURL
        }

        return opened ? currentTenant?.logo.normal : currentTenant?.logo.collapsed
      }

      const defineAlt = (): string => {
        if (currentTenant?.parent) {
          return t('10x Banking logo')
        }
        return `${currentTenant?.name} ${t('logo')}`
      }

      return (
        <>
          {open ? (
            <Fade in={open} timeout={{ enter: 1000, exit: 250 }}>
              <LogoLarge src={defineSrc()} alt={defineAlt()} style={{ objectPosition: 'left center' }} />
            </Fade>
          ) : (
            <Fade in={!open} timeout={{ enter: 1000, exit: 250 }}>
              <Logo src={defineSrc()} alt={defineAlt()} />
            </Fade>
          )}
        </>
      )
    }

    return (
      <nav className={classes.toolbar} aria-labelledby="tenants-button">
        <Grid container direction="column" alignItems="flex-start" justifyContent="space-between" wrap="nowrap">
          <Grid item style={{ paddingTop: 48 }}>
            {renderTenantLogo({ currentTenant: selectCurrentTenant, opened: open })}
          </Grid>
          {tenants && isMultiTenant && hasUserRoleForMultiTenant('tenants') && (
            <Grid item style={{ paddingBottom: 48 }}>
              <AccessibleMenu
                id="tenants"
                anchorEl={anchorElBrand as any}
                onClose={handleCloseBrand}
                menuItems={menuItems as any}
                menuButton={menuButton}
                popoverProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  anchorReference: 'anchorPosition',
                  anchorPosition: { top: 150, left: 20 },
                }}
                disablePadding
                enableDivider
              />
            </Grid>
          )}
        </Grid>
      </nav>
    )
  }

  const dashboardText = t((isMultiTenant && selectCurrentTenant?.name) || 'Your')

  useEffect(() => {
    setBrandIndex(selectSelectedTenantIndex)
  }, [selectSelectedTenantIndex])

  return (
    <Drawer
      variant={isSmallScreen() ? 'temporary' : 'permanent'}
      open={open}
      onClose={handleDrawerToggle}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {renderTenantSelector()}
      <nav>
        <List component="ul" style={{ padding: 0 }}>
          <FeatureFlag name="dashboard">
            <RoleCheckComponent path="dashboard">
              <Tooltip
                title={`${dashboardText} dashboard`}
                placement="right"
                disableHoverListener={open}
                disableFocusListener={open}
                disableTouchListener={open}
              >
                <li>
                  <PathSelectableListItem to="dashboard">
                    <DashboardIcon />
                    <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                      <Grid container direction="column">
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                          primary={
                            <Body2
                              variantMapping={{ body2: 'span' }}
                              style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                            >
                              {t('{{tenantName}} dashboard', {
                                tenantName: (isMultiTenant && selectCurrentTenant?.name) || 'Your',
                              })}
                            </Body2>
                          }
                          style={{ marginTop: 0, marginBottom: 0 }}
                        />
                      </Grid>
                    </Fade>
                  </PathSelectableListItem>
                </li>
              </Tooltip>
            </RoleCheckComponent>
          </FeatureFlag>

          {hasUserRoleForMultiTenant('products') && (!isMultiTenant || isBrand) && (
            <FeatureFlag name="menu_products">
              <RoleCheckComponent path="products">
                <Tooltip
                  title={`${t('Products')}`}
                  placement="right"
                  disableHoverListener={open}
                  disableFocusListener={open}
                  disableTouchListener={open}
                >
                  <li>
                    <PathSelectableListItem to="products">
                      <ProductsIcon />
                      <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primary={<Body2 variantMapping={{ body2: 'span' }}>Products</Body2>}
                        />
                      </Fade>
                    </PathSelectableListItem>
                  </li>
                </Tooltip>
              </RoleCheckComponent>
            </FeatureFlag>
          )}
          {hasUserRoleForMultiTenant('product_syndication') && (!isMultiTenant || isBrand) && (
            <FeatureFlag name="menu_product_syndications">
              <RoleCheckComponent path="product-syndications">
                <Tooltip
                  title={`${t('Product syndication')}`}
                  placement="right"
                  disableHoverListener={open}
                  disableFocusListener={open}
                  disableTouchListener={open}
                >
                  <li>
                    <PathSelectableListItem to="product-syndications">
                      <ProductSyndicationIcon />
                      <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primary={<Body2 variantMapping={{ body2: 'span' }}>Product syndication</Body2>}
                        />
                      </Fade>
                    </PathSelectableListItem>
                  </li>
                </Tooltip>
              </RoleCheckComponent>
            </FeatureFlag>
          )}
          {hasUserRoleForMultiTenant('packages') && (!isMultiTenant || isBrand) && (
            <FeatureFlag name="menu_packages">
              <RoleCheckComponent path="packages">
                <Tooltip
                  title={`${t('Packages')}`}
                  placement="right"
                  disableHoverListener={open}
                  disableFocusListener={open}
                  disableTouchListener={open}
                >
                  <li>
                    <PathSelectableListItem to="packages">
                      <PackagesIcon />
                      <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primary={<Body2 variantMapping={{ body2: 'span' }}>Packages</Body2>}
                        />
                      </Fade>
                    </PathSelectableListItem>
                  </li>
                </Tooltip>
              </RoleCheckComponent>
            </FeatureFlag>
          )}
          {hasUserRoleForMultiTenant('templates') && (!isMultiTenant || isBrand) && (
            <FeatureFlag name="menu_templates">
              <RoleCheckComponent path="templates">
                <Tooltip
                  title={`${t('Templates')}`}
                  placement="right"
                  disableHoverListener={open}
                  disableFocusListener={open}
                  disableTouchListener={open}
                >
                  <li>
                    <PathSelectableListItem to="templates">
                      <CommonFileTextSettingsIcon color={ColorType.SECONDARY} />
                      <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primary={<Body2 variantMapping={{ body2: 'span' }}>Templates</Body2>}
                        />
                      </Fade>
                    </PathSelectableListItem>
                  </li>
                </Tooltip>
              </RoleCheckComponent>
            </FeatureFlag>
          )}
          {hasUserRoleForMultiTenant('governance') && (!isMultiTenant || isParent) && (
            <FeatureFlag name="governance">
              <RoleCheckComponent path="governance">
                <Tooltip
                  title={`${t('Governance')}`}
                  placement="right"
                  disableHoverListener={open}
                  disableFocusListener={open}
                  disableTouchListener={open}
                >
                  <li>
                    <PathSelectableListItem to="governance">
                      <LinearScaleIcon />
                      <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primary={<Body2 variantMapping={{ body2: 'span' }}>Governance</Body2>}
                        />
                      </Fade>
                    </PathSelectableListItem>
                  </li>
                </Tooltip>
              </RoleCheckComponent>
            </FeatureFlag>
          )}
          {hasUserRoleForMultiTenant('suspense_account') && (!isMultiTenant || isParent) && (
            <FeatureFlag name="suspense_account">
              <RoleCheckComponent path="accounts">
                <Tooltip
                  title={`${t('Accounts')}`}
                  placement="right"
                  disableHoverListener={open}
                  disableFocusListener={open}
                  disableTouchListener={open}
                >
                  <li>
                    <PathSelectableListItem to="accounts">
                      <AccountBalanceIcon />
                      <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primary={<Body2 variantMapping={{ body2: 'span' }}>Accounts</Body2>}
                        />
                      </Fade>
                    </PathSelectableListItem>
                  </li>
                </Tooltip>
              </RoleCheckComponent>
            </FeatureFlag>
          )}
          {hasUserRoleForMultiTenant('reference_data') && (!isMultiTenant || isParent) && (
            <FeatureFlag name="menu_reference">
              <RoleCheckComponent path="reference">
                <Tooltip
                  title={`${t('Reference data')}`}
                  placement="right"
                  disableHoverListener={open}
                  disableFocusListener={open}
                  disableTouchListener={open}
                >
                  <li>
                    <PathSelectableListItem to="reference-data">
                      <ReferenceDataIcon color={ColorType.SECONDARY} />
                      <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                        <ListItemText
                          disableTypography
                          className={classes.listItemText}
                          primary={<Body2 variantMapping={{ body2: 'span' }}>{t('Reference data')}</Body2>}
                        />
                      </Fade>
                    </PathSelectableListItem>
                  </li>
                </Tooltip>
              </RoleCheckComponent>
            </FeatureFlag>
          )}
          <FeatureFlag name="menu_users">
            <RoleCheckComponent path="users">
              <Tooltip
                title={`${t('Users')}`}
                placement="right"
                disableHoverListener={open}
                disableFocusListener={open}
                disableTouchListener={open}
              >
                <li>
                  <PathSelectableListItem to="users">
                    <SupervisorAccount />
                    <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                      <ListItemText
                        disableTypography
                        className={classes.listItemText}
                        primary={<Body2 variantMapping={{ body2: 'span' }}>Users</Body2>}
                      />
                    </Fade>
                  </PathSelectableListItem>
                </li>
              </Tooltip>
            </RoleCheckComponent>
          </FeatureFlag>
          <FeatureFlag name="menu_faq">
            <RoleCheckComponent path="help">
              <Tooltip
                title={`${t('Help')}`}
                placement="right"
                disableHoverListener={open}
                disableFocusListener={open}
                disableTouchListener={open}
              >
                <li>
                  <PathSelectableListItem to="help">
                    <HelpIcon />
                    <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                      <ListItemText
                        disableTypography
                        className={classes.listItemText}
                        primary={<Body2 variantMapping={{ body2: 'span' }}>Help</Body2>}
                      />
                    </Fade>
                  </PathSelectableListItem>
                </li>
              </Tooltip>
            </RoleCheckComponent>
          </FeatureFlag>
          <FeatureFlag name="menu_about">
            <RoleCheckComponent path="about">
              <Tooltip
                title={`${t('About')}`}
                placement="right"
                disableHoverListener={open}
                disableFocusListener={open}
                disableTouchListener={open}
              >
                <li>
                  <PathSelectableListItem to="about">
                    <InfoIcon />
                    <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                      <ListItemText
                        disableTypography
                        className={classes.listItemText}
                        primary={<Body2 variantMapping={{ body2: 'span' }}>About</Body2>}
                      />
                    </Fade>
                  </PathSelectableListItem>
                </li>
              </Tooltip>
            </RoleCheckComponent>
          </FeatureFlag>
          <NoRender msg="every_other_menu_button">
            <Tooltip
              title={`${t('Models')}`}
              placement="right"
              disableHoverListener={open}
              disableFocusListener={open}
              disableTouchListener={open}
            >
              <li>
                <ListItem button>
                  <ModelsIcon />
                  <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                    <ListItemText
                      disableTypography
                      className={classes.listItemText}
                      primary={<Body2 variantMapping={{ body2: 'span' }}>Models</Body2>}
                    />
                  </Fade>
                </ListItem>
              </li>
            </Tooltip>
            <Tooltip
              title={`${t('Platform')}`}
              placement="right"
              disableHoverListener={open}
              disableFocusListener={open}
              disableTouchListener={open}
            >
              <li>
                <ListItem button>
                  <PlatformIcon />
                  <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                    <ListItemText
                      disableTypography
                      className={classes.listItemText}
                      primary={<Body2 variantMapping={{ body2: 'span' }}>Platform</Body2>}
                    />
                  </Fade>
                </ListItem>
              </li>
            </Tooltip>
            <Tooltip
              title={`${t('Reports')}`}
              placement="right"
              disableHoverListener={open}
              disableFocusListener={open}
              disableTouchListener={open}
            >
              <li>
                <ListItem button>
                  <ReportsIcon />
                  <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                    <ListItemText
                      disableTypography
                      className={classes.listItemText}
                      primary={<Body2 variantMapping={{ body2: 'span' }}>Reports</Body2>}
                    />
                  </Fade>
                </ListItem>
              </li>
            </Tooltip>
            <Tooltip
              title={`${t('Data')}`}
              placement="right"
              disableHoverListener={open}
              disableFocusListener={open}
              disableTouchListener={open}
            >
              <li>
                <ListItem button>
                  <DataIcon />
                  <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                    <ListItemText
                      disableTypography
                      className={classes.listItemText}
                      primary={<Body2 variantMapping={{ body2: 'span' }}>Data</Body2>}
                    />
                  </Fade>
                </ListItem>
              </li>
            </Tooltip>
            <Tooltip
              title={`${t('Ledger')}`}
              placement="right"
              disableHoverListener={open}
              disableFocusListener={open}
              disableTouchListener={open}
            >
              <li>
                <ListItem button>
                  <LedgerIcon />
                  <Fade in={open} timeout={{ enter: 2000, exit: 250 }}>
                    <ListItemText
                      disableTypography
                      className={classes.listItemText}
                      primary={<Body2 variantMapping={{ body2: 'span' }}>Ledger</Body2>}
                    />
                  </Fade>
                </ListItem>
              </li>
            </Tooltip>
          </NoRender>
        </List>
      </nav>
      <div style={{ flexGrow: 2 }} />
      <Fade in={open} timeout={{ enter: 2000, exit: 0 }}>
        <Graphic
          src={SuperCoreLogo}
          style={{ zIndex: 1201, display: open ? 'block' : 'none' }}
          alt={t('Powered by SuperCore logo')}
        />
      </Fade>
    </Drawer>
  )
}
export default withWidth()(NavBar)
