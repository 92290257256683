import tenantConfig from 'config/tenantConfig.json'
import { SubscriptionStatus, RequiredExternalId, TransactionRuleAccess } from 'store/products/types'
import { Tenant, TenantConfig } from 'store/tenant-config/types'

export const features = (currentTenant: Tenant, selectedConfig: TenantConfig) => {
  const currencyCode =
    currentTenant?.localisation?.currencyCode ||
    selectedConfig?.localisation?.currencyCode ||
    tenantConfig?.localisation?.currencyCode ||
    'GBP'
  const country =
    currentTenant?.localisation?.countryCode ||
    selectedConfig?.localisation?.countryCode ||
    tenantConfig?.localisation?.countryCode ||
    'GBR'

  return {
    subscriptionCreationRule: {
      lockedStatus: false,
      defaultSubscriptionStatus: SubscriptionStatus.ACTIVE,
    },
    currency: { currencyCode, country },
    requiredExternalId: [RequiredExternalId.AIIN],
  }
}

const featuresWithTransaction = (currentTenant: Tenant, selectedConfig: TenantConfig) => ({
  ...features(currentTenant, selectedConfig),
  transactionRules: {
    inbound: {
      defaultAccess: TransactionRuleAccess.PERMIT,
    },
    outbound: {
      defaultAccess: TransactionRuleAccess.PERMIT,
    },
  },
})

const defaultFeatures = (
  currentTenant: Tenant,
  selectedConfig: TenantConfig,
  productType: 'Loans' | 'Savings' | 'PersonalCurrentAccount' | 'CurrentAccount' | 'Mortgages'
) => {
  const productsWithStandardFeatures = ['Loans', 'Mortgages']
  const productsWithTransactions = ['Savings', 'PersonalCurrentAccount', 'CurrentAccount']

  if (productsWithStandardFeatures.includes(productType)) {
    return { ...features(currentTenant, selectedConfig) }
  }

  if (productsWithTransactions.includes(productType)) {
    return { ...featuresWithTransaction(currentTenant, selectedConfig) }
  }

  return {}
}

export default defaultFeatures
