import React from 'react'
import { Grid, AccordionDetails, AccordionActions, Tooltip, Avatar } from '@material-ui/core'
import styled from 'styled-components/macro'
import { featureIconsCreator, featureSVGIcons } from '../product.utils'
import { Accordion, Body1 } from 'dls'
import { Loader } from 'components'
import { slug, useUserNameWithRole } from 'utils/common.util'
import FeatureBottomContainer from 'pages/Product/ProductFeatureViewMode/FeatureBottomContainer'
import ProductReviewButtons from 'pages/Product/ProductReviewButtons'
import { ProductFeatureViewModeProps } from 'pages/Product/ProductFeatureViewMode/ProductFeatureViewMode'
import { Task } from 'store/tasks/types'
import { isProductInDesign, isProductInGovernanceStatus } from 'utils/productVersion.utils'
import StatusBadge from 'components/StatusBadge/StatusBadge'
import { Colleague } from 'store/user/types'
import { getInitials } from 'utils/ui.utils'

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 18px;
  padding-top: 0;
  padding-bottom: 4px;
`

const StyledAvatar = styled(Avatar)`
  background-color: ${(props: any) => `${props.theme.palette.divider}`};
  color: ${(props: any) => `${props.theme.palette.text.primary}`};
  font-size: 16px;
`

interface OwnFeaturePaperProps {
  featureID: string
  featureTitle: string
  children: React.ReactNode
  featureAssigneeData: Colleague | undefined
  featureTaskData: Task | undefined
  expanded: boolean
  isCreditInterestEnabled?: boolean
  isDebitInterestEnabled?: boolean
  hideBottomBar?: boolean
  customFooterNavigation?: React.ReactChild
}

type FeaturePaperProps = ProductFeatureViewModeProps & OwnFeaturePaperProps

export const FeaturePaper = (props: FeaturePaperProps): React.ReactElement | null => {
  const { productName, productKey, version, status } = props.product
  const {
    isTasksLoading,
    isGovernanceLoading,
    featureID,
    children,
    featureTitle,
    featureName,
    featureAssigneeData,
    featureTaskData,
    isOldVersion,
    isCreditInterestEnabled,
    isDebitInterestEnabled,
    hideBottomBar = false,
    deleteFeature,
    product,
    expanded,
    customFooterNavigation,
  } = props

  const getUserNameWithRole = useUserNameWithRole()

  if (isTasksLoading || isGovernanceLoading) {
    return <Loader />
  }

  return productName ? (
    <Accordion
      externalExpanded={expanded}
      defaultExpanded={false}
      title={
        <Body1
          variantMapping={{
            body1: 'h3',
          }}
        >
          {featureTitle}
        </Body1>
      }
      headerIcon={featureIconsCreator(featureSVGIcons[featureName])}
      headerButtonGroup={
        <Grid container direction="row" alignContent="stretch" justifyContent="flex-start">
          <Grid item xs={1}>
            {featureAssigneeData && featureTaskData ? (
              <Tooltip title={`${getUserNameWithRole(featureAssigneeData.profile)}`} placement="top">
                <StyledAvatar alt="assignee">
                  {getInitials(`${featureAssigneeData.profile.firstName} ${featureAssigneeData.profile.lastName}`)}
                </StyledAvatar>
              </Tooltip>
            ) : null}
          </Grid>
          {featureTaskData ? (
            <Grid item xs={2} style={{ marginTop: 8 }}>
              <StatusBadge status={featureTaskData.status} task={featureTaskData} />
            </Grid>
          ) : null}
        </Grid>
      }
    >
      {children}
      <AccordionActions>
        {!isOldVersion && !hideBottomBar && (
          <FeatureBottomContainer
            featureName={featureID}
            editPath={`features/${slug(featureID)}`}
            deleteFeature={() =>
              deleteFeature({
                productKey,
                productVersion: version,
                featureName: featureID,
              })
            }
            hide={status && !isProductInDesign(status) && isProductInGovernanceStatus(product)}
            product={product}
            isCreditInterestEnabled={isCreditInterestEnabled}
            isDebitInterestEnabled={isDebitInterestEnabled}
          />
        )}
        {customFooterNavigation}
      </AccordionActions>
      {!isOldVersion && <ProductReviewButtons featureName={featureID} product={props.product} />}
    </Accordion>
  ) : null
}
