import React from 'react'
import { Check, Event, ErrorOutline } from '@material-ui/icons'
import moment from 'moment-timezone'
import { isArray } from 'lodash'
import { BankInterestRateStatus, InterestRate } from 'store/referenceData/types'
import { WithholdingTaxRule } from 'store/withholdingTax/types'
import { CustomTheme } from 'dls/shared/styled'

export const getBankInterestRateStatusIcon = (status: BankInterestRateStatus) => {
  switch (status) {
    case BankInterestRateStatus.SCHEDULED:
      return <Event style={{ fontSize: 16 }} />
    case BankInterestRateStatus.LIVE:
      return <Check style={{ fontSize: 16 }} />
    default:
      return <ErrorOutline style={{ fontSize: 16 }} />
  }
}

export const getBankInterestRateStatusColor = (status: BankInterestRateStatus, theme: CustomTheme) => {
  switch (status) {
    case BankInterestRateStatus.SCHEDULED:
      return theme.palette.scheduled.main
    case BankInterestRateStatus.LIVE:
      return theme.palette.live.main
    default:
      return theme.palette.design.main
  }
}

export const findEarliestDate = (rates: InterestRate[] | WithholdingTaxRule[]): moment.Moment | null => {
  const dates =
    rates && isArray(rates)
      ? (rates as InterestRate[])
          .map((o: InterestRate) => (o.effectiveDate ? moment(o.effectiveDate) : null))
          .filter(Boolean)
      : []
  if (dates.length === 0) {
    return null
  }
  let earliestDate = dates[0]
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < dates.length; i++) {
    const currentDate = dates[i]
    if (currentDate && earliestDate && currentDate < earliestDate) {
      earliestDate = currentDate
    }
  }
  return earliestDate
}
