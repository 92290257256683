import React from 'react'
import { useTranslation } from 'react-i18next'
import { AddCircleIconButton, DesignIconButton } from 'dls/molecules/IconButtons/IconButtons'
import { Body2, Caption } from 'dls/atoms'

interface OwnProps {
  label: string
  value?: React.ReactNode
  addCtaLink?: string
  editCtaLink?: string
  noValueContent?: React.ReactNode
  testId?: string
  buttonComponentType: React.ElementType<any>
}
const ReviewSubLabel = ({
  label,
  value,
  editCtaLink,
  addCtaLink,
  noValueContent,
  testId,
  buttonComponentType: ButtonComponentType,
}: OwnProps) => {
  const { t } = useTranslation()

  return (
    <span data-dls="molecules-ReviewSubLabel" style={{ whiteSpace: 'nowrap' }}>
      <Body2 variantMapping={{ body2: 'span' }}>{label}:</Body2>{' '}
      {!value && (
        <Caption color="textSecondary">
          {noValueContent || t('No limit')}
          {addCtaLink && (
            <AddCircleIconButton component={ButtonComponentType} to={addCtaLink} data-test-id={`${testId}-add`} />
          )}
        </Caption>
      )}
      {value && (
        <Body2 variantMapping={{ body2: 'span' }} style={{ fontWeight: 'bold' }}>
          {value}{' '}
          {editCtaLink && (
            <DesignIconButton component={ButtonComponentType} to={editCtaLink} data-test-id={`${testId}-edit`} />
          )}
        </Body2>
      )}
    </span>
  )
}

export default ReviewSubLabel
