import React from 'react'
import { Grid } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { H4 } from 'dls/atoms/Typography'
import { ContentContainer, ContentColumn } from 'dls/shared/styled'

interface OwnProps {
  title?: React.ReactElement
  titleIconButton?: React.ReactElement
  IconButtonProps?: React.ReactElement[]
  id?: string
  className?: string
  ariaProps?: Record<string, string | boolean>
}

const ReviewSubheader = ({ title, titleIconButton, IconButtonProps, id, className, ariaProps }: OwnProps) => {
  const RightButtonSetRender = () =>
    Array.isArray(IconButtonProps)
      ? IconButtonProps.map((item, index) => <ContentColumn key={`content-${index}`}>{item}</ContentColumn>)
      : null

  const headingIconRender = () => (Array.isArray(titleIconButton) ? titleIconButton.map((item) => item) : null)

  return (
    <div {...ariaProps} data-dls="organism-ReviewSubheader">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        id={`${id}-container`}
        className={className}
      >
        <Grid item>
          {title && (
            <H4 id={`${id}-heading`} variant="subtitle1" variantMapping={{ subtitle1: 'h4' }}>
              {title}
              {titleIconButton && headingIconRender()}
            </H4>
          )}
        </Grid>
        <Grid item>
          <ContentContainer>{!isEmpty(IconButtonProps) && RightButtonSetRender()}</ContentContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default ReviewSubheader
