import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import styled from 'styled-components/macro'
import ContentContainer from 'pages/Layout/ContentContainer'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const ContentPaper = styled(Paper)`
  padding: 30px 32px;
  margin-top: 16px;
  margin-bottom: 24px;
  overflow: auto;
`

interface Breadcrumb {
  title: string
  path: string
}

interface ListingProps {
  title: string
  subheading?: string
  containerTestId: string
  formTestId: string
  Sidebar: React.ComponentType
  children: React.ReactNode
  breadcrumbs: Breadcrumb[]
  contentPaperStyle?: React.CSSProperties
}

const FormPage = ({
  title,
  subheading,
  containerTestId,
  formTestId,
  Sidebar,
  children,
  breadcrumbs,
  contentPaperStyle,
}: ListingProps): React.ReactElement => {
  useBreadcrumbs({ breadcrumbs, trigger: true })

  return (
    <ContentContainer data-test-id={containerTestId}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
        <Grid item sm={12} md={12} lg={8}>
          <Typography variant="h1" gutterBottom style={{ marginBottom: 24, marginTop: 16 }}>
            {title}
          </Typography>
          {subheading && (
            <Typography
              variant="body2"
              variantMapping={{ body2: 'p' }}
              gutterBottom
              style={{ whiteSpace: 'pre-wrap', marginBottom: 24 }}
            >
              {subheading}
            </Typography>
          )}
          <ContentPaper elevation={1} data-test-id={formTestId} style={contentPaperStyle}>
            {children}
          </ContentPaper>
        </Grid>
        <Grid item sm={12} md={12} lg={4} style={{ marginTop: 14 }}>
          <Sidebar />
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

export default FormPage
