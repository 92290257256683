export enum FloorType {
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
  MILLISECONDS = 'milliseconds',
}

export interface MoreAboutType {
  showIndex?: boolean
  infoTitle?: number | string
  infoStatement?: React.ReactNode | string
}

export interface MoreAboutImageType {
  path: string
  title: string
}

export enum PrecisionScaleDecimals {
  ten = 10,
  four = 4,
  two = 2,
}
