import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Message } from 'store/activities/types'
import { NoActivityOrCommentImage } from 'assets'

interface Props {
  messages: Message[]
}

const GridEmptyLanding = styled(Grid)`
  margin-top: 32px;
  margin-bottom: 32px;
`

const NoContentPlaceholder = ({ messages }: Props) => {
  const { t } = useTranslation()
  return messages.length === 0 ? (
    <Grid item>
      <GridEmptyLanding container direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <Grid item>
          <img src={NoActivityOrCommentImage} alt="landing page" />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
            <Grid item>
              <Typography variant="h5">{t('Nothing to display')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{t('Relax and enjoy the moment!')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </GridEmptyLanding>
    </Grid>
  ) : (
    <span />
  )
}

export default NoContentPlaceholder
