import AppsMenuItem from '../components/AppsMenuItem'
import AccountCreatorsMenuItem from '../components/AccountCreatorsMenuItem'
import AdministratorsMenuItem from '../components/AdministratorsMenuItem'
import ProductCreatorsMenuItem from '../components/ProductCreatorsMenuItem'
import { FilterOptionsProps } from 'components/FilterMenu'

const getUserRoleFilterOptions = (): FilterOptionsProps[] => [
  {
    label: AppsMenuItem,
  },
  {
    label: AccountCreatorsMenuItem,
  },
  {
    label: AdministratorsMenuItem,
  },
  {
    label: ProductCreatorsMenuItem,
  },
]

export default getUserRoleFilterOptions
