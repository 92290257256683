import { Grid } from '@material-ui/core'
import { FastField, Form, FormikProps } from 'formik'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { isEmpty, pick } from 'lodash'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { ContentGridContainer, ContentPaperContainer, ContentFormRow } from '../Styled'
import countriesJson from 'static/countries.json'
import { FormBottomBar } from 'components'
import currenciesJson from 'static/currencies.json'
import { Currency, ProductKey } from 'store/products/types'
import { withFormikSimple, Yip } from 'utils/form.utils'
import SelectFieldComplex, { SelectFieldData } from 'components/form/SelectFieldComplex'
import { CountryItem, CountryList, CurrencyItem, CurrencyList } from 'store/utils/types'
import { ClientConfig } from 'store/tenant-config/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  clientConfig?: Partial<ClientConfig>
  productKey?: ProductKey
}

export interface CurrencyFormValues extends Currency {}

interface CurrencyFormProps extends FormikProps<CurrencyFormValues>, OwnProps {}

const CurrenciesForm = (props: CurrencyFormProps) => {
  const navigate = useNavigate()
  const { productKey = '' } = props

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  const availableCountries =
    (props && props.clientConfig && props.clientConfig.currency && props.clientConfig.currency.country) || null

  const availableCurrencies =
    (props && props.clientConfig && props.clientConfig.currency && props.clientConfig.currency.currencyCode) || null

  let countriesList: CountryList = {}
  let currenciesList: CurrencyList = {}

  if (availableCountries && !isEmpty(availableCountries)) {
    countriesList = pick(countriesJson as CountryList, availableCountries)
  } else {
    countriesList = countriesJson
  }

  if (availableCurrencies && !isEmpty(availableCurrencies)) {
    currenciesList = pick(currenciesJson as CurrencyList, availableCurrencies)
  } else {
    currenciesList = currenciesJson
  }

  const { t } = useTranslation()
  const selectDataCountriesJson: SelectFieldData = {}
  Object.entries(countriesList).forEach(([, item]: [string, CountryItem]) => {
    selectDataCountriesJson[`${t(item.name)}`] = {
      name: item.alpha3Code,
      label: item.name,
      flag: item.alpha2Code,
    }
  })

  const selectDataCurrenciesJson: SelectFieldData = {}
  Object.entries(currenciesList).forEach(([key, item]: [string, CurrencyItem]) => {
    selectDataCurrenciesJson[`${key} - ${t(item.name)}`] = { name: key, flag: key.substr(0, 2) }
  })

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Currency')}
      description={t('Here you can specify the currency for the product')}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer container spacing={2} justifyContent="space-between" alignItems="flex-start">
            <Grid container direction="column" item xs={9}>
              <ContentFormRow item xs={12}>
                <FastField
                  name="currencyCode"
                  label={t('Currency')}
                  required
                  data={selectDataCurrenciesJson}
                  selectProps={{ displayEmpty: true }}
                  inputLabelProps={{ shrink: true }}
                  component={SelectFieldComplex}
                  placeholder={t('Please select')}
                  validate={Yip(Yup.string().required())}
                  hasFlag
                />
              </ContentFormRow>
              <ContentFormRow item xs={12}>
                <FastField
                  name="country"
                  label={t('Country')}
                  selectProps={{ displayEmpty: true }}
                  inputLabelProps={{ shrink: true }}
                  data={selectDataCountriesJson}
                  component={SelectFieldComplex}
                  placeholder={t('Please select')}
                  hasFlag
                />
              </ContentFormRow>
            </Grid>
            <Grid item xs={12}>
              <FormBottomBar inlineNavigation>
                <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                  {t('Cancel')}
                </Button>
              </FormBottomBar>
            </Grid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, CurrencyFormValues>(CurrenciesForm)
