import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { PaymentLimit, PaymentLimitRoutingDestination } from 'store/products/typings/paymentLimits'
import { getEditUrl, addUrls } from 'pages/features/PaymentLimits/utilities/actionUrls'
import { Caption, ReviewSubLabel } from 'dls'

interface OwnProps {
  paymentLimit: PaymentLimit
  currencySymbol: string
  showActions: boolean
  routingDestination: PaymentLimitRoutingDestination
  allowCustomerOverride: boolean
  testId: string
}

const SingleLimitSubValue = ({
  paymentLimit,
  currencySymbol,
  routingDestination,
  allowCustomerOverride,
  showActions = false,
  testId,
}: OwnProps) => {
  const { t } = useTranslation()
  return (
    <>
      <ReviewSubLabel
        label={t('Min - Max')}
        buttonComponentType={RouterLink}
        testId={testId}
        value={
          paymentLimit?.amount?.maximum ? (
            <>
              {currencySymbol}
              {paymentLimit.amount.minimum ?? 0} - {currencySymbol}
              {paymentLimit.amount.maximum}
            </>
          ) : null
        }
        editCtaLink={showActions ? getEditUrl(paymentLimit?.limitRuleId || '') : undefined}
        addCtaLink={showActions ? addUrls(routingDestination).single : undefined}
      />
      {paymentLimit?.enableSubscriptionOverride && allowCustomerOverride && (
        <Caption variantMapping={{ caption: 'div' }} style={{ marginBottom: 8 }}>
          {t('Customer override')}: <span style={{ fontWeight: 'bold' }}>Yes</span>
        </Caption>
      )}
    </>
  )
}

export default SingleLimitSubValue
