/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'
import { useTranslation } from 'react-i18next'
import CreateProcess, { CreateProcessView } from './CreateProcess/CreateProcessDialog.page'
import { Process } from 'store/governance/types'
import { GovernanceCard, Catalog } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface Props {
  process: Process[]
  view: CreateProcessView
  open: boolean
  setOpen: (open: boolean) => void
}

const Governance = ({ process, view, open, setOpen }: Props): React.ReactElement => {
  const { t } = useTranslation()

  const { showInfographicModal } = useModal()

  return (
    <ContentContainer>
      <Catalog
        entityName="Governance processes"
        list={process}
        icons={[
          <InfoIconButton
            key="more-about-icon"
            ariaProps={{
              'aria-label': `${t('More about governance')}`,
              'aria-haspopup': 'dialog',
              'aria-controls': 'more-about-dialog',
            }}
            onClick={() => showInfographicModal(HelpModalType.GOVERNANCE)}
            fontSize="medium"
          />,
        ]}
        action={process.length < 2 ? <CreateProcess open={open} view={view} setOpen={setOpen} /> : null}
        searchFilter={(item, text) => item.name.toLowerCase().includes(text)}
        cardRenderer={(list, idx, key) => (
          <CatalogCardContainer key={`${key}-catalog-container`}>
            <GovernanceCard key={key} currentProcess={list[idx]} />
          </CatalogCardContainer>
        )}
      />
    </ContentContainer>
  )
}

export default Governance
