import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { grey } from '@material-ui/core/colors'
import { useLocation } from 'react-router-dom'
import LoginLayout from '../LoginLayout'
import { SSOLoginButton as LoginButton } from 'pages/Login/styles'
import { selectConfig } from 'store/tenant-config/selectors'
import { ContextualHelp } from 'components'
import UserActions from 'store/user/actions'
import { selectError } from 'store/user/selectors'
import { ColorType } from 'dls/shared/types'

enum PageType {
  LOGIN,
  SSO_FLOW_INIT,
  SSO_FLOW_GET_TOKENS,
}

const SSOLogin = (): React.ReactElement => {
  const { t } = useTranslation()
  const tenantConfig = useSelector(selectConfig)
  const loginError = useSelector(selectError)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [isPageStatusError, setPageStatusError] = useState(false)

  let currentPage: PageType = 0
  if (pathname === '/login' || pathname === '/sso-login') {
    currentPage = PageType.LOGIN
  } else if (pathname === '/sso-flow-init') {
    currentPage = PageType.SSO_FLOW_INIT
  } else if (pathname === '/sso-oauth-init') {
    currentPage = PageType.SSO_FLOW_GET_TOKENS
  }

  let pathName: string
  if (currentPage === PageType.LOGIN) {
    pathName = t('log in – Single Sign On')
  } else {
    pathName = currentPage === PageType.SSO_FLOW_INIT ? 'Redirecting...' : 'Loading...'
  }

  const gotoLoginPage = () => {
    window.location.href = tenantConfig?.authSettings?.ssoUrl || ''
  }

  useEffect(() => {
    if (currentPage === PageType.LOGIN) {
      dispatch(UserActions.loginReset())
    } else if (currentPage === PageType.SSO_FLOW_INIT) {
      const isPageError = window.location.search.indexOf('success') === -1
      if (!isPageError) {
        window.location.href = tenantConfig?.authSettings?.oAuthUrl || ''
      } else {
        setPageStatusError(isPageError)
      }
    } else if (currentPage === PageType.SSO_FLOW_GET_TOKENS) {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code') as string
      if (code) {
        dispatch(UserActions.ssoLogin({ code }))
      } else {
        setPageStatusError(true)
      }
    }
  }, [pathname, dispatch, currentPage, tenantConfig?.authSettings?.oAuthUrl, isPageStatusError, setPageStatusError])

  return (
    <LoginLayout isPageStatusError={isPageStatusError} loginError={loginError}>
      <LoginButton
        onClick={gotoLoginPage}
        variant="contained"
        color={ColorType.BUTTON}
        disabled={currentPage !== PageType.LOGIN}
      >
        {pathName}
        {currentPage !== PageType.LOGIN && <CircularProgress size={24} style={{ marginLeft: '8px' }} />}
      </LoginButton>
      <ContextualHelp
        placement="top"
        style={{ color: grey[600] }}
        title={t(
          'SSO login access is controlled by your organisation. If you experience problems logging in, please contact your IT team'
        )}
      />
    </LoginLayout>
  )
}

export default SSOLogin
