import React, { useState, useCallback } from 'react'
import { Fade, Dialog, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Clear } from '@material-ui/icons'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ProductRejectModalForm from './ProductRejectModalForm'
import { RejectTask, TaskKey } from 'store/tasks/types'
import TasksActions from 'store/tasks/actions'
import { ProductKey } from 'store/products/types'
import { ModalWrapper } from 'components'
import { RootState } from 'store/types'
import TasksSelectors from 'store/tasks/selectors'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const RejectButton = styled(Button)`
  &.MuiButton-contained {
    background-color: ${(props: any) => `${props.theme.palette.error.main}`};
    color: ${(props: any) => `${props.theme.palette.error.contrastText}`};
    margin-top: 16px;
    &:hover {
      background-color: ${(props: any) => `${props.theme.palette.error.dark}`};
    }
  }
`

const StyledTypography = styled(Typography)`
  margin-bottom 24px;
`

const mapDispatchToProps = (dispatch: Dispatch, { taskKey }: { taskKey: TaskKey }) => ({
  reject: (rejectFormValues: RejectTask, productKey: ProductKey, processExecutionKey: string) =>
    dispatch(TasksActions.reject({ ...rejectFormValues, taskKey, productKey, processExecutionKey })),
})

const mapStateToProps = (state: RootState) => ({
  updating: TasksSelectors.selectUpdating(state),
})

interface ProductRejectModalProps {
  taskKey: TaskKey
  productKey: ProductKey
  processExecutionKey: string
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ProductRejectModalProps

const ProductRejectModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const setOpen = useCallback(() => setIsOpen(true), [setIsOpen])
  const setClosed = useCallback(() => setIsOpen(false), [setIsOpen])
  const { t } = useTranslation()

  const { reject, productKey, processExecutionKey, updating } = props
  const onSubmit = (data: RejectTask) => {
    reject(data, productKey, processExecutionKey)
  }

  return (
    <div>
      <RejectButton onClick={setOpen} variant="contained" disabled={updating} color={ColorType.NONE}>
        <Clear />
        {t('Reject')}
      </RejectButton>
      <Dialog open={isOpen} scroll="paper" onClose={setClosed} TransitionComponent={Fade} fullWidth>
        <ModalWrapper
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <StyledTypography variant="h6" css="margin-bottom 24px;">
              {t('Reject product')}
            </StyledTypography>
          }
        >
          <>
            <StyledTypography variant="body2" gutterBottom css="margin-bottom 24px;">
              {t(
                'Please provide comments detailing why the product has been rejected and what needs to be changed before you can approve.'
              )}
            </StyledTypography>
            <ProductRejectModalForm
              onSubmit={onSubmit}
              setClosed={setClosed}
              updating={updating}
              initialValues={{ taskKey: props.taskKey, message: '' }}
            />
          </>
        </ModalWrapper>
      </Dialog>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductRejectModal)
