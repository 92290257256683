import { eventChannel } from 'redux-saga'
import { call, put, select, take, takeLatest } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist'
import { selectAccessToken, selectRefreshToken, selectTenxToken, authType as origAuthType } from './selectors'
import { navigate } from 'store/router/actions'
import {
  AUTH_STATUS,
  AUTH_TYPE,
  AuthEvent,
  refreshToken as origRefreshToken,
  setApiKey,
  setAuthStatusEmitter,
  setAuthTokens,
} from 'api/client/authAwareAxios'
import { navigateToUnavailable } from 'utils/url.utils'
import { setTimeZone } from 'utils/date.utils'
import NotificationActions from 'store/notifications/actions'
import { UserApi } from 'api'
import UserActions from 'store/user/actions'
import {
  selectTenants,
  selectFeatures,
  selectLoginPage,
  selectParentIndex,
  selectConfig,
} from 'store/tenant-config/selectors'
import { store } from 'store'
import { Colleague, UserRole } from 'store/user/types'
import { Tenant, TenantConfig } from 'store/tenant-config/types'

function* manageLogin(authType: AUTH_TYPE, payload: { code?: any; email?: any; password?: any }) {
  try {
    const features = selectFeatures(store.getState())
    const homePage = features.home ? (features.home as string) : '/dashboard'
    let userData: ReturnTypePromise<typeof UserApi.login> | ReturnTypePromise<typeof UserApi.getToken> | undefined
    if (authType === AUTH_TYPE.SSO) {
      userData = yield call(UserApi.getToken, payload.code)
    } else if (authType === AUTH_TYPE.LEGACY) {
      userData = yield call(UserApi.login, payload.email, payload.password)
    }

    if (userData?.accessToken && userData?.refreshToken && userData?.tenxToken) {
      setAuthTokens(userData.accessToken, userData.refreshToken, userData.tenxToken, authType)
      yield put(UserActions.setSelectedTenantIndex(selectParentIndex(store.getState())))
      yield put(UserActions.startAuthWatcher())
      yield put(UserActions.loginSuccess({ userData, authType }))
      yield put(navigate(homePage))
    } else {
      yield put(UserActions.loginFail(Error('no tokens found')))
    }
  } catch (e: any) {
    yield put(UserActions.loginFail(e))
  }
}

function ssoLogin(action: ReturnType<typeof UserActions.ssoLogin>) {
  const { code } = action.payload
  return manageLogin(AUTH_TYPE.SSO, { code })
}

function login(action: ReturnType<typeof UserActions.login>) {
  const { email, password } = action.payload
  return manageLogin(AUTH_TYPE.LEGACY, { email, password })
}

function* getUsers() {
  try {
    const response: ReturnTypePromise<typeof UserApi.getUsers> = yield call(UserApi.getUsers)
    const validUserRoles = Object.values(UserRole)
    const users = response.filter((user: Colleague) => validUserRoles.includes(user.userRole))
    yield put(UserActions.getUserSuccess(users))
  } catch (e: any) {
    yield put(UserActions.getUserFail(e))
    yield call(navigateToUnavailable)
  }
}

function* createUser(action: ReturnType<typeof UserActions.createUser>) {
  try {
    const response: ReturnTypePromise<typeof UserApi.createUser> = yield call(UserApi.createUser, action.payload)
    yield put(NotificationActions.createUserSuccess(response))
    yield put(navigate(`/users` as any))
    const users: ReturnTypePromise<typeof UserApi.getUsers> = yield call(UserApi.getUsers)
    yield put(UserActions.getUserSuccess(users))
  } catch (e: any) {
    yield put(NotificationActions.createUserFail(e))
  }
}

function* authStatusWatcher() {
  const authEventChannel = eventChannel((emitter) => {
    setAuthStatusEmitter(emitter)
    return () => {
      // this is the unsubscribe function
      // do nothing for now
    }
  })

  try {
    while (true) {
      // take(END) will cause the saga to terminate by jumping to the finally block
      const authEvent: AuthEvent = yield take(authEventChannel)
      if (authEvent.authStatus === AUTH_STATUS.UNAUTHORIZED) {
        yield put(UserActions.logout())
        authEventChannel.close()
      }
    }
  } finally {
    // No action right now
  }
}

function* logout() {
  try {
    yield call(UserApi.logout)
  } catch (e: any) {
    yield put(UserActions.logoutFail(e))
  } finally {
    const authType: AUTH_TYPE = yield select(origAuthType)
    yield call(setAuthTokens, undefined, undefined, undefined, authType)
    yield call(setTimeZone, '')
    const loginPage: string = yield select(selectLoginPage)
    yield put(navigate(loginPage))
  }
}

function* nullAccessToken() {
  const authType: AUTH_TYPE = yield select(origAuthType)
  yield call(setAuthTokens, undefined, origRefreshToken, undefined, authType)
}

function* rehydrateUser() {
  const accessToken: ReturnType<typeof selectAccessToken> = yield select(selectAccessToken)
  const refreshToken: ReturnType<typeof selectRefreshToken> = yield select(selectRefreshToken)
  const tenxToken: ReturnType<typeof selectTenxToken> = yield select(selectTenxToken)
  if (accessToken && refreshToken) {
    // ? why we need to check this
    const authType: AUTH_TYPE = yield select(origAuthType)
    setAuthTokens(accessToken, refreshToken, tenxToken, authType)
    yield put(UserActions.startAuthWatcher())
  }
}

function* getTeams() {
  try {
    const response: ReturnTypePromise<typeof UserApi.getTeams> = yield call(UserApi.getTeams)
    yield put(UserActions.getTeamsSuccess(response))
  } catch (e: any) {
    yield put(UserActions.getTeamsFail(e))
    yield call(navigateToUnavailable)
  }
}

function* createTeam(action: ReturnType<typeof UserActions.createTeam>) {
  try {
    const response: ReturnTypePromise<typeof UserApi.createTeam> = yield call(UserApi.createTeam, action.payload)
    yield put(NotificationActions.createTeamSuccess(response))
    yield put(navigate(`/teams` as any))
    const teams: ReturnTypePromise<typeof UserApi.getTeams> = yield call(UserApi.getTeams)
    yield put(UserActions.getTeamsSuccess(teams))
  } catch (e: any) {
    yield put(NotificationActions.createTeamFail(e))
  }
}

function* getUserDetails(action: ReturnType<typeof UserActions.getUserDetails>) {
  try {
    const response: ReturnTypePromise<typeof UserApi.getUserByKey> = yield call(UserApi.getUserByKey, action.payload)
    yield put(UserActions.getUserDetailsSuccess(response))
  } catch (e: any) {
    yield put(UserActions.getUserDetailsFail(e))
  }
}

// used in router
function* setTenantIndexAndApiKey(action: ReturnType<typeof UserActions.setTenantIndexAndApiKey>) {
  try {
    const tenants: Tenant[] = yield select(selectTenants)
    const tenantConfig: TenantConfig = yield select(selectConfig)
    const timeZone = tenants?.[action.payload]?.localisation?.timezone ?? tenantConfig?.localisation?.timezone

    if (!tenants || action.payload == null || action.payload === undefined || !tenants[action.payload]) {
      throw new Error('Tenant not found')
    }
    setApiKey(tenants[action.payload].apiKey)
    setTimeZone(timeZone)
    yield put(UserActions.setSelectedTenantIndexSuccess(action.payload))
  } catch (e: any) {
    yield put(UserActions.setSelectedTenantIndexFail(e))
  }
}

// Used in Login saga
function* setSelectedTenantIndex(action: ReturnType<typeof UserActions.setSelectedTenantIndex>) {
  try {
    const tenants: Tenant[] = yield select(selectTenants)
    const tenantConfig: TenantConfig = yield select(selectConfig)
    const timeZone = tenants?.[action.payload]?.localisation?.timezone ?? tenantConfig?.localisation?.timezone

    if (!tenants || action.payload == null || action.payload === undefined || !tenants[action.payload]) {
      throw new Error('Tenant not found')
    }
    setApiKey(tenants[action.payload].apiKey)
    setTimeZone(timeZone)
    yield put(UserActions.setSelectedTenantIndexSuccess(action.payload))
    yield put(navigate('/landingPage' as any))
  } catch (e: any) {
    yield put(UserActions.setSelectedTenantIndexFail(e))
  }
}

export default function* () {
  yield takeLatest(UserActions.ssoLogin.type, ssoLogin)
  yield takeLatest(UserActions.login.type, login)
  yield takeLatest(UserActions.logout.type, logout)
  yield takeLatest(UserActions.nullAccessToken.type, nullAccessToken)
  yield takeLatest(REHYDRATE, rehydrateUser)
  yield takeLatest(UserActions.startAuthWatcher.type, authStatusWatcher)
  yield takeLatest(UserActions.getUser.type, getUsers)
  yield takeLatest(UserActions.createUser.type, createUser)
  yield takeLatest(UserActions.getTeams.type, getTeams)
  yield takeLatest(UserActions.createTeam.type, createTeam)
  yield takeLatest(UserActions.getUserDetails.type, getUserDetails)
  yield takeLatest(UserActions.setSelectedTenantIndex.type, setSelectedTenantIndex)
  yield takeLatest(UserActions.setTenantIndexAndApiKey.type, setTenantIndexAndApiKey)
}
