import React from 'react'
import { Paper, Grid, Typography, CardActionArea } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import StatusBadge from '../StatusBadge/StatusBadge'
import { ProductStatus } from 'store/products/typings/productStatus'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  version: string
  modifiedOrPublished: string
  status: ProductStatus
  publishedDate?: string | null
  path: string
}

const StyledContainerPaper = styled(Paper)`
  margin: 8px 0px;
  min-height: 88px;
  &:hover {
    background-color: white;
    box-shadow: ${(props: any) => `${props.theme.shadows[8]}`};
  }
`

const ProductCard = ({ version, modifiedOrPublished, status, publishedDate, path }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate(`/products/${path}`)
  }
  return (
    <StyledContainerPaper elevation={1} key={version} id={version}>
      <CardActionArea
        component="div"
        onClick={handleOnClick}
        aria-controls="main"
        css={`
          padding: 28px;
          width: 100%;
          text-transform: initial;
          &.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
            opacity: 0;
          }
        `}
      >
        <Grid container spacing={8} direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item xs={4}>
            <Typography variantMapping={{ body1: 'div' }}>{version}</Typography>
          </Grid>
          <Grid item xs={3} css="text-align:left;">
            <Typography css="text-align:left;">{modifiedOrPublished}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={8} direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
              <Grid item>
                <StatusBadge status={status} date={publishedDate} />
              </Grid>
              <Grid item>
                <Button
                  color={ColorType.BUTTON}
                  size="small"
                  onClick={handleOnClick}
                  aria-controls={`products-menu-${version}`}
                  aria-haspopup="true"
                >
                  {t('View').toUpperCase()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </StyledContainerPaper>
  )
}

export default ProductCard
