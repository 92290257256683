import React from 'react'
import { useTranslation } from 'react-i18next'
import { Delete, Edit } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { HoverEditIcon, StyledCard, CardWithActionsDiv, IconedButtons, CardImage } from './Styled'
import { ConfirmModalDeprecated } from 'components'
import useDialogs from 'components/useDialogs'
import { DeleteInfo } from 'assets'
import { ColorType } from 'dls/shared/types'

interface Props {
  imageUrl?: string
  onEditClick: (e: React.MouseEvent) => void
  onDeleteClick: VoidFunction
  isSelected: boolean
}

const ImageCardWithActions = ({ imageUrl, onEditClick, onDeleteClick, isSelected }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const { isDialogOpen, onOpenDialog, onCloseDialog } = useDialogs()

  return (
    <>
      <StyledCard $isSelected={isSelected}>
        <CardWithActionsDiv>
          <div
            style={{
              padding: 16,
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              gridGap: 24,
            }}
          >
            <CardImage src={imageUrl} alt="card" />
            <div style={{ display: 'grid', gridGap: 2, gridTemplateRows: '0.2fr auto' }}>
              <HoverEditIcon>
                <IconedButtons
                  onClick={onEditClick}
                  aria-label={t('Remove card image')}
                  title={t('Remove card image')}
                  color={ColorType.BUTTON}
                  variant="text"
                  startIcon={<Edit />}
                >
                  {t('Replace')}
                </IconedButtons>
                <IconedButtons
                  onClick={() => onOpenDialog()}
                  aria-label={t('Remove card image')}
                  title={t('Remove card image')}
                  color={ColorType.BUTTON}
                  variant="text"
                  startIcon={<Delete />}
                >
                  {t('Delete')}
                </IconedButtons>
              </HoverEditIcon>
              <Typography style={{ wordBreak: 'break-word' }} variant="body2">
                {imageUrl}
              </Typography>
            </div>
          </div>
        </CardWithActionsDiv>
      </StyledCard>
      <ConfirmModalDeprecated
        image={{ src: DeleteInfo, alt: t('Delete Icon') }}
        onConfirmClick={{ action: onDeleteClick, title: t('ok, got it') }}
        onCancelClick={onCloseDialog}
        open={isDialogOpen}
        title={t(`The card image will only be deleted when you \n submit the form`)}
        imageStyle={{
          height: '140px',
          marginBottom: '24px',
        }}
        titleStyle={{
          marginBottom: '44px',
        }}
      />
    </>
  )
}

export default ImageCardWithActions
