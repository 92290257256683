import { TFunction } from 'i18next'
import { toBankTz } from 'utils/date.utils'
import { getCurrencyInfo } from 'api/utils'
import { TableCommonFormValue } from 'components/TableCommon/types'
import {
  SubscriptionKey,
  SuspenseTransactionItem,
  SuspenseTransactionsForm,
  TransactionStatus,
} from 'store/accounts/types'

export enum TRANSACTIONS_STATE {
  LOADING,
  SUCCESS,
  ERROR,
}

export const MAX_SELECTABLE_TRANSACTIONS = 20

export const getSearchString = (item: SuspenseTransactionItem) => `${item.transactionId}`
export const getKeyString = (item: SuspenseTransactionItem) => `${item.transactionId}`
export const isThisRowSelectable = (item: SuspenseTransactionItem) =>
  [
    TransactionStatus.PENDING,
    TransactionStatus.PARTIALLY_CLEARED,
    TransactionStatus.CONTRA_WRITE_OFF,
    TransactionStatus.CONTRA_WRITE_BACK,
  ].includes(item.transactionStatus as TransactionStatus)

export const reversalSubtitle = (item: SuspenseTransactionItem, t: TFunction) => {
  const currencyInfo = getCurrencyInfo(item.amount.currency)
  const symbol = currencyInfo ? currencyInfo.symbol : null
  return t('The reversal is for the transaction which occurred on {{date}} for {{price}}', {
    date: toBankTz(item.postedTimeStamp).dateTime,
    price: `${symbol}${item.amount.amount}`,
    interpolation: { escapeValue: false },
  })
}

export const onSelectAll = (rows: TableCommonFormValue[]) => rows.slice(0, MAX_SELECTABLE_TRANSACTIONS)

export const initialValues = (subscriptionKey: SubscriptionKey): SuspenseTransactionsForm => ({
  selectedTransactions: [],
  suspenseAccount: subscriptionKey,
})

export const INITIAL_FILTERS = {
  timestamp: undefined,
  amount: undefined,
  credit: undefined,
  status: 1,
}
