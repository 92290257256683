import { Grid } from '@material-ui/core'
import { Form, FormikValues } from 'formik'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { set } from 'lodash'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import {
  ContentGridContainer,
  ContentPaperContainer,
  FooterAdditionalSpaceGrid,
  TypographyStyle,
  FastFieldStyle,
} from '../Styled'
import { Identification, ProductKey } from 'store/products/types'
import { withFormikSimple } from 'utils/form.utils'
import { FormBottomBar, TextFieldComplex } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  productKey?: ProductKey
}

const ProductCodesValidation = (values: FormikValues) => {
  const errors = {}

  if (!(values.productCode || values.subProductCode)) {
    set(errors, `test`, 'Product Code or Sub Product have to defined')
  }
  return errors
}

const IdentificationForm = (props: OwnProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { productKey = '' } = props

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Product codes')}
      description={t(
        'Product codes is an optional feature that enables you to define product and sub product codes which can be consumed by your downstream services.'
      )}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={6}>
              <TypographyStyle variant="h2" gutterBottom>
                {t('Product code')}
              </TypographyStyle>
              <FastFieldStyle
                label={t('Product code')}
                name="productCode"
                component={TextFieldComplex}
                fullWidth
                inputLabelProps={{ shrink: true }}
                placeholder={t('Please enter')}
              />
            </Grid>
            <Grid item xs={12}>
              <FastFieldStyle
                label={t('Description')}
                name="productCodeDescription"
                component={TextFieldComplex}
                fullWidth
                inputLabelProps={{ shrink: true }}
                placeholder={t('Please enter')}
              />
            </Grid>
            <Grid item xs={6}>
              <TypographyStyle variant="h2" gutterBottom>
                {t('Sub product code')}
              </TypographyStyle>
              <FastFieldStyle
                label={t('Sub product code')}
                name="subProductCode"
                component={TextFieldComplex}
                fullWidth
                inputLabelProps={{ shrink: true }}
                placeholder={t('Please enter')}
              />
            </Grid>
            <Grid item xs={12}>
              <FastFieldStyle
                label={t('Description')}
                name="subProductCodeDescription"
                component={TextFieldComplex}
                fullWidth
                inputLabelProps={{ shrink: true }}
                placeholder={t('Please enter')}
              />
            </Grid>
            <FooterAdditionalSpaceGrid item xs={12}>
              <FormBottomBar inlineNavigation>
                <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                  {t('Cancel')}
                </Button>
              </FormBottomBar>
            </FooterAdditionalSpaceGrid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, Identification>(IdentificationForm, {
  validate: ProductCodesValidation,
})
