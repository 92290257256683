import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { isEmpty } from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
import { FeatureFormPageProps } from '../FeaturePage.page'
import RequiredExternalIdForm from './RequiredExternalIdForm'
import { toRequiredExternalIdFormValues } from './requiredExternalIdTransformers'
import { RequiredExternalIdFormValues, Feature } from 'store/products/types'
import ProductsActions from 'store/products/actions'
import { RootState } from 'store/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import { Loader } from 'components'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setRequiredExternalId: (productKey: string) => (requiredExternalIdFormValues: RequiredExternalIdFormValues) =>
    dispatch(
      ProductsActions.setRequiredExternalId({
        ...requiredExternalIdFormValues,
        productKey,
      })
    ),
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const RequiredExternalIdFormPage = (props: Props): React.ReactElement | null => {
  const { setRequiredExternalId } = props
  const { productKey = '' } = useParams()

  const [product, isLoading] = useSelectedProduct(productKey)

  useUpdateBreadcrumbs({ productKey, context: Feature.REQUIREDEXTERNALID })
  const navigate = useNavigate()

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.REQUIREDEXTERNALID)) navigate(`./../not-allowed`)

  if (isLoading) {
    return <Loader />
  }

  return product ? (
    <RequiredExternalIdForm
      onSubmit={setRequiredExternalId(productKey)}
      productKey={productKey}
      initialValues={toRequiredExternalIdFormValues(product)}
      hasFeature={isEmpty(product.requiredExternalId)}
    />
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(RequiredExternalIdFormPage)
