import { createSelector } from 'reselect'
import * as UserSelectors from '../user/selectors'
import { RootState } from 'store'

const selectTenantConfig = (state: RootState) => state.tenantConfig
export const selectConfig = createSelector(selectTenantConfig, (state) => state.data)
export const selectError = createSelector(selectTenantConfig, (state) => state.error)
export const selectLoading = createSelector(selectTenantConfig, (state) => state.loading)

export const selectCurrentTenant = createSelector(
  [selectConfig, UserSelectors.selectSelectedTenantIndex],
  (tenantConfig, selectSelectedTenantIndex) => {
    if (tenantConfig === undefined || !tenantConfig.tenants) return undefined

    let tenantIndex = selectSelectedTenantIndex
    if (tenantIndex === undefined) tenantIndex = tenantConfig.tenants.findIndex((tenant) => tenant.parent)

    if (tenantIndex === -1 || tenantIndex === undefined) return tenantConfig.tenants[0]
    return tenantConfig.tenants[tenantIndex]
  }
)

export const selectParentIndex = createSelector(
  [selectConfig, UserSelectors.selectSelectedTenantIndex],
  (tenantConfig) => {
    const tenantIndex = tenantConfig?.tenants?.findIndex((tenant) => tenant.parent)
    return tenantIndex && tenantIndex !== -1 ? tenantIndex : 0
  }
)

export const selectFeatures = createSelector([selectConfig, selectCurrentTenant], (tenantConfig, currentTenant) => {
  if (tenantConfig && currentTenant) {
    return {
      ...tenantConfig.features,
      ...currentTenant.features,
    }
  }
  return tenantConfig ? tenantConfig.features : {}
})

export const isMultiTenant = createSelector(selectConfig, (tenantConfig) =>
  tenantConfig && tenantConfig.tenants ? tenantConfig.tenants.length > 1 : false
)

export const isParentTenant = createSelector(
  selectCurrentTenant,
  (currentTenant) => currentTenant && currentTenant.parent
)

export const selectLoginPage = createSelector(selectFeatures, (features) =>
  features.sso_login ? '/sso-login' : '/login'
)

export const selectCurrencySymbol = createSelector(selectConfig, (tenantConfig) =>
  tenantConfig && tenantConfig.localisation ? tenantConfig.localisation.currencySymbol : '£'
)

export const selectTenantCurrencyCode = createSelector(
  selectConfig,
  (tenantConfig) => (tenantConfig && tenantConfig.localisation && tenantConfig.localisation.currencyCode) || 'GBP'
)

export const selectTenants = createSelector(selectConfig, (tenantConfig) => tenantConfig && tenantConfig.tenants)

export const selectMinProductRepublishDelay = createSelector(selectConfig, (tenantConfig) =>
  tenantConfig?.minProductRepublishDelay ? tenantConfig?.minProductRepublishDelay : 0
)

export const selectInternalClientCode = createSelector(selectConfig, (tenantConfig) => tenantConfig && tenantConfig.icc)
