import React from 'react'
import { Snackbar, useTheme } from '@material-ui/core'
import { CheckCircle, Error, Info, Warning } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { SnackbarProvider } from 'notistack'
import MultipleMessage from './MultipleMessage'
import { Notification, NotificationTypes } from 'store/notifications/types'
import { notificationColors } from 'utils/customColors'
import { CloseButton } from 'dls/molecules/IconButtons/IconButtons'

interface OwnProps {
  notification: Notification

  closeNotification(type: NotificationTypes): void
}

const notifactionIcons = {
  success: CheckCircle,
  error: Error,
  warning: Warning,
  info: Info,
}

const NotificationSnackbar = ({
  notification: { notificationMessage, open, notificationType, isMultipleMessage },
  closeNotification,
}: OwnProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const SelectedIcon = notifactionIcons[notificationType]
  const IconAndText = (
    <span style={{ display: 'flex', alignItems: 'center' }} id="message-id">
      <SelectedIcon style={{ opacity: 0.9, marginRight: theme.spacing(1) }} />
      {t(notificationMessage)}
    </span>
  )
  const handleCloseNotification = () => {
    closeNotification(notificationType)
  }
  if (isMultipleMessage) {
    return (
      <SnackbarProvider maxSnack={100}>
        <MultipleMessage />
      </SnackbarProvider>
    )
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseNotification}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      ContentProps={{
        style: {
          backgroundColor: notificationColors(theme)[notificationType],
        },
      }}
      message={IconAndText}
      action={[<CloseButton key="close" aria-label="close" color="inherit" onClick={handleCloseNotification} />]}
    />
  )
}

export default NotificationSnackbar
