import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { PackageState } from './types'

const initialState = {
  loading: false,
  error: undefined,
  data: [],
  availableProducts: undefined,
  productPackageName: '',
  packageType: undefined,
  countryOfResidence: undefined,
  brandId: '',
  selectedPackage: undefined,
  isPublished: false,
  publishing: false,
  publishSuccess: false,
  publishError: undefined,
}

const reducer = createReducer<PackageState>(initialState, {
  [actions.getPackages.type]: (state) => ({ ...state, loading: true }),
  [actions.getPackagesSuccess.type]: (state, action) => ({ ...state, loading: false, data: action.payload }),
  [actions.getPackagesFail.type]: (state, action) => ({ ...state, loading: false, error: action.payload }),
  [actions.createPackage.type]: (state) => ({ ...state, loading: true }),
  [actions.createPackageSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    selectedPackage: action.payload,
  }),
  [actions.createPackageFail.type]: (state) => ({ ...state, loading: false }),
  [actions.publishPackage.type]: (state) => ({ ...state, publishing: true }),
  [actions.publishPackageSuccess.type]: (state, action) => ({
    ...state,
    publishing: false,
    selectedPackage: action.payload,
    publishSuccess: true,
  }),
  [actions.publishPackageFail.type]: (state, action) => ({ ...state, publishing: false, publishError: action.payload }),
  [actions.clearPublishError.type]: (state) => ({
    ...state,
    publishError: undefined,
  }),
  [actions.clearPublishSuccess.type]: (state) => ({
    ...state,
    publishSuccess: false,
  }),
  [actions.availableProducts.type]: (state) => ({ ...state, loading: true }),
  [actions.availableProductsSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    availableProducts: action.payload,
  }),
  [actions.availableProductsFail.type]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [actions.packageName.type]: (state, action) => ({
    ...state,
    productPackageName: action.payload,
  }),
  [actions.packageType.type]: (state, action) => ({
    ...state,
    packageType: action.payload,
  }),
  [actions.countryOfResidence.type]: (state, action) => ({
    ...state,
    countryOfResidence: action.payload,
  }),
  [actions.brandId.type]: (state, action) => ({
    ...state,
    brandId: action.payload,
  }),

  [actions.isPublishPackage.type]: (state, action) => ({
    ...state,
    isPublished: action.payload,
  }),

  [actions.updateSelectedPackage.type]: (state) => ({ ...state, loading: true }),
  [actions.updateSelectedPackageSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    selectedPackage: action.payload,
  }),
  [actions.updateSelectedPackageFail.type]: (state) => ({ ...state, loading: false }),

  [actions.getPackageDetails.type]: (state) => ({ ...state, loading: true }),
  [actions.getPackageDetailsSuccess.type]: (state, action) => ({
    ...state,
    loading: false,
    selectedPackage: action.payload,
  }),
  [actions.getPackageDetailsFail.type]: (state) => ({ ...state, loading: false }),
  [actions.deletePackage.type]: (state) => ({ ...state, loading: true }),
  [actions.deletePackageFail.type]: (state) => ({ ...state, loading: false }),
})

export default reducer
