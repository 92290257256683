import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import EntityReviewPageLayout from '../../../../Layout/EntityReviewPageLayout/EntityReviewPageLayout'
import CurrentInterestRateSectionCard from './components/CurrentInterestRateSectionCard'
import HistoricInterestRateSectionCard from './components/HistoricInterestRateSectionCard'
import FutureInterestRateSectionCard from './components/FutureInterestRateSectionCard'
import { HelpModalType } from 'store/modal/types'
import ReferenceDataActions from 'store/referenceData/actions'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import { Loader } from 'components'
import { getMostRecentPastRate, getNearestFutureRate, sortByEffectiveDate } from 'utils/referenceData.utils'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const SingleInterestRatePage = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { bankIndexKey } = useParams()
  const bankInterestRates = useSelector(ReferenceDataSelectors.selectAllBankInterestRate)
  const isLoading = useSelector(ReferenceDataSelectors.selectLoading)
  const selectedRate = useSelector(ReferenceDataSelectors.selectSelectedIndexRate)

  useEffect(() => {
    if (!bankInterestRates) {
      dispatch(ReferenceDataActions.fetchBankInterestRate())
    }
  }, [dispatch, bankInterestRates])

  useEffect(() => {
    dispatch(ReferenceDataActions.getBankIndexRateDetails(bankIndexKey))
  }, [bankIndexKey])

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Index rates'), path: 'reference-data/index-rates' },
      { title: `${selectedRate?.name}`, path: `reference-data/index-rates/${selectedRate?.bankIndexKey}` },
    ],
    trigger: !!selectedRate,
  })

  if (isLoading || !selectedRate?.rates) {
    return <Loader />
  }

  const mostRecentPastRate = getMostRecentPastRate(selectedRate.rates)
  const nearestFutureRate = getNearestFutureRate(selectedRate.rates)
  const isRuleLive = moment(mostRecentPastRate?.effectiveDate || nearestFutureRate.effectiveDate).isSameOrBefore(
    moment(),
    'day'
  )

  const historicRates = sortByEffectiveDate({
    rates: selectedRate.rates.filter(({ effectiveDate }) => moment(effectiveDate).isBefore(moment(), 'day')),
  })

  const futureRates = sortByEffectiveDate({
    rates: selectedRate.rates.filter(({ effectiveDate }) => moment(effectiveDate).isAfter(moment(), 'day')),
    desc: false,
  })

  const calculateActiveStep = () => {
    if (isRuleLive) {
      return 2 // "Live" - at least one non-future rate
    }

    return 1 // "Scheduled" - as any rule has at least one future rate at creation
  }

  return (
    <EntityReviewPageLayout
      name={selectedRate.name}
      activeStep={calculateActiveStep()}
      ariaLabel="About withholding tax rules"
      infoIconAlt="More about index rates"
      infographicType={HelpModalType.INDEX_RATES}
    >
      <CurrentInterestRateSectionCard
        selectedInterestRate={selectedRate}
        mostRecentPastRate={mostRecentPastRate}
        nearestFutureRate={nearestFutureRate}
      />
      <FutureInterestRateSectionCard futureRates={futureRates} bankIndexKey={selectedRate.bankIndexKey || ''} />
      <HistoricInterestRateSectionCard historicRates={historicRates} rateId={bankIndexKey || ''} />
    </EntityReviewPageLayout>
  )
}

export default SingleInterestRatePage
