import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { H1, Body2, Button } from 'dls/atoms'
import { Flexbox } from 'pages/Layout/Styled'
import CallToActionBar from 'dls/organisms/CallToActionBar/CallToActionBar'
import { ResponsiveFlexbox, Main } from 'dls/shared/styled'
import { internalMdPadding } from 'dls/shared/styleVar'

interface Props {
  id?: string
  icon?: React.ReactNode
  title: string
  description: React.ReactNode
  cancelButtonText?: string
  submitButtonText?: string
  submitButtonDisabled?: boolean
  CTAHidden?: boolean
  main: React.ReactNode
  toolbarButtons?: React.ReactNode
  headerProps?: React.HTMLAttributes<HTMLDivElement>
  mainProps?: React.HTMLAttributes<HTMLDivElement>
  onCancel?: () => void
  onSubmit?: () => void
  submitButtonAriaProps?: Record<string, string | boolean>
}

const DescriptionBar = styled.div`
  display: grid;
  grid-gap: 12px;
  margin-bottom: 12px;
  grid-template-columns: 1fr auto;
  align-items: center;
`

const TemplateForm = ({
  main,
  mainProps,
  title,
  description,
  cancelButtonText,
  submitButtonText,
  headerProps,
  icon,
  toolbarButtons,
  onCancel,
  onSubmit,
  submitButtonDisabled = false,
  CTAHidden,
  submitButtonAriaProps,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Flexbox direction="column" justifyContent="center" alignItems="center" data-dls="template-form">
      <ResponsiveFlexbox direction="column" width="100%">
        <header
          {...headerProps}
          css={
            headerProps && headerProps.css
              ? `
          ${headerProps.css}
        `
              : undefined
          }
        >
          {icon && <div style={{ marginBottom: 32 }}>{icon}</div>}
          {title && <H1 gutter>{title}</H1>}
          {(description || toolbarButtons) && (
            <DescriptionBar>
              {description && <Body2 css={{ 'margin-bottom': 12 }}>{description}</Body2>}
              {toolbarButtons}
            </DescriptionBar>
          )}
        </header>
        <Main
          id="main"
          {...mainProps}
          css={
            mainProps && mainProps.css
              ? `
          ${mainProps.css}
        `
              : undefined
          }
        >
          {main}
          {CTAHidden ? null : (
            <div style={{ padding: internalMdPadding }}>
              <CallToActionBar
                rightRegion={[
                  <Button key={cancelButtonText || 'Cancel'} variant="text" onClick={onCancel}>
                    {t(cancelButtonText || 'Cancel')}
                  </Button>,
                  <Button
                    key={submitButtonText || 'Submit'}
                    variant="contained"
                    onClick={onSubmit}
                    disabled={submitButtonDisabled}
                    {...submitButtonAriaProps}
                  >
                    {t(submitButtonText || 'Submit')}
                  </Button>,
                ]}
              />
            </div>
          )}
        </Main>
      </ResponsiveFlexbox>
    </Flexbox>
  )
}

export default TemplateForm
