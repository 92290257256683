import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { ProductKey } from 'store/products/types'
import { ProductSyndicationsResponse } from 'store/products/typings/productSyndication'

async function fetchProductSyndications() {
  const { data } = await (await createAsyncAuthAwareAxios(null, true)).get<ProductSyndicationsResponse>(
    '/v1/productSyndications/products'
  )
  return data
}

// TODO Rename this to fetchProductSyndicationDetails?
async function fetchProductSyndication({ productKey, version }: { productKey: ProductKey; version: number }) {
  const res = await (await createAsyncAuthAwareAxios(null, true)).get(
    `/v1/productSyndications/products/${productKey}/versions/${version || 1}`
  )
  return res.data
}

export default {
  fetchProductSyndications,
  fetchProductSyndication,
}
