import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreatePackageDialog, { CreateProductView } from './CreatePackage/CreatePackageDialog'
import { PackagesWithProducts } from 'store/packages/types'
import { PackagesCard, Catalog, FilterMenu } from 'components'
import { CountryIncluded, RetireOrDeleteProductOrPackage } from 'store/products/types'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import usePackageFilter from 'pages/Packages/hooks/usePackageFilter'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface Props {
  packages: PackagesWithProducts[]
  setPackageName: (name: string) => void
  setPackageType: (type: string) => void
  setCountryOfResidence: (country: CountryIncluded | null) => void
  setBrandId: (brandId: string) => void
  statusQueryParam: string
  deletePackage(values: RetireOrDeleteProductOrPackage): void
}

const Packages = ({
  packages,
  setPackageName,
  deletePackage,
  setPackageType,
  setCountryOfResidence,
  setBrandId,
  statusQueryParam,
}: Props): React.ReactElement => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const [view] = useState<CreateProductView>(CreateProductView.CreatePackageForm)

  const { selectedFilter, setSelectedFilter, filteredItems, statusFilterOptions } = usePackageFilter(
    packages,
    statusQueryParam
  )

  const entity = 'Packages'

  const { showInfographicModal } = useModal()
  const infoIconAriaLabel = t('More about packages')

  return (
    <ContentContainer data-test-id="packages-page">
      <Catalog
        entityName={entity}
        list={filteredItems}
        icons={[
          <FilterMenu
            key="package-filter-menu"
            filter={selectedFilter}
            setFilter={setSelectedFilter}
            filterOptions={statusFilterOptions}
            showAllButton={false}
            ariaControls="packages-list-section"
            ariaLabel={t('filter packages by status')}
          />,
          <InfoIconButton
            key="more-about-icon"
            ariaProps={{
              'aria-label': infoIconAriaLabel,
              'aria-haspopup': 'dialog',
              'aria-controls': 'more-about-dialog',
              title: `${t('More about packages')}`,
            }}
            onClick={() => showInfographicModal(HelpModalType.PACKAGE)}
            fontSize="medium"
          />,
        ]}
        action={
          <CreatePackageDialog
            setName={setPackageName}
            setPackageType={setPackageType}
            setCountryOfResidence={setCountryOfResidence}
            setBrandId={setBrandId}
            open={open}
            view={view}
            setOpen={setOpen}
          />
        }
        searchFilter={(item, text) => item.productPackageName.toLowerCase().includes(text)}
        cardRenderer={(list, idx, key) => (
          <CatalogCardContainer key={`${key}-catalog-container`}>
            <PackagesCard key={key} currentPackage={list[idx]} deletePackage={deletePackage} />
          </CatalogCardContainer>
        )}
      />
    </ContentContainer>
  )
}

export default Packages
