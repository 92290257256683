import React, { useState } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StepperContainer } from '../Product/Style'
import { mapStateToProductProps } from '../Product/ProductFeatureViewMode/ProductFeatureViewMode'
import PublishPackage from './PublishPackage'
import DeleteRetireDialog from 'components/DeleteRetireDialog/DeleteRetireDialog'
import ProductStepper from 'components/ProductPackageStepper/ProductPackageStepper'
import { ProductStepperContainer } from 'components/ProductPackageStepper/Style'
import { stopPropagation } from 'utils/form.utils'
import { PackagesWithProducts } from 'store/packages/types'
import { PackagesStatus } from 'store/packages/typings/packageStatus'
import { DeleteOrRetire, RetireOrDeleteProductOrPackage } from 'store/products/types'
import PackageActions from 'components/PackagesWidgets/PackageActions'
import PackagesActions from 'store/packages/actions'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  selectedPackage: PackagesWithProducts
  isOldVersion?: boolean
}

type Props = ReturnType<typeof mapStateToProductProps> & OwnProps

const ProductStepperCard = ({ selectedPackage, isOldVersion }: Props): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [actionType, setActionType] = useState<DeleteOrRetire>(DeleteOrRetire.DELETEPACKAGE)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function openMenu(e: React.MouseEvent<HTMLButtonElement>) {
    stopPropagation(e)
    setAnchorEl(e.currentTarget)
  }
  const openConfirm = (newActionType: DeleteOrRetire) => (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setActionType(newActionType)
    setOpen(true)
  }

  function closeConfirmAndMenu(e: React.MouseEvent) {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(false)
  }

  const handleDeleteOrRetire = (values: RetireOrDeleteProductOrPackage) => {
    setAnchorEl(null)
    setOpen(false)
    const productIds = selectedPackage.products ? selectedPackage.products.map((prod) => prod.productKey) : []
    const updatePackageRequest = {
      products: productIds,
      distribution: selectedPackage.distribution,
      productPackageName: selectedPackage.productPackageName,
    }
    switch (actionType) {
      case DeleteOrRetire.DELETEPACKAGE:
        return dispatch(PackagesActions.deletePackage({ ...values, ...updatePackageRequest }))
      default:
        throw new Error('Unknown delete action')
    }
  }

  const { showInfographicModal } = useModal()

  return (
    <ProductStepperContainer>
      <ContentContainer>
        <Paper style={{ padding: 16 }}>
          <StepperContainer>
            <div style={{ gridArea: 'stepperArea' }} className="stepperArea">
              <ProductStepper
                isOldVersion={isOldVersion}
                effectiveDate={selectedPackage.publicationDate}
                status={selectedPackage.packageStatus}
                type="PACKAGE"
              />
            </div>
            <div style={{ gridArea: 'infoArea', textAlign: 'right' }}>
              <InfoIconButton
                key="more-about-icon"
                ariaProps={{
                  'aria-label': `${t('More about packages')}`,
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                }}
                onClick={() => showInfographicModal(HelpModalType.PACKAGE)}
                fontSize="medium"
              />
            </div>
            <div style={{ gridArea: 'actionArea', whiteSpace: 'nowrap' }}>
              {!isOldVersion && selectedPackage && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  style={{ flexWrap: 'nowrap' }}
                >
                  {selectedPackage.products &&
                  selectedPackage.products.length > 0 &&
                  ![PackagesStatus.LIVE, PackagesStatus.CLOSED].includes(selectedPackage.packageStatus) &&
                  selectedPackage.productPackageId ? (
                    <Grid item>
                      <PublishPackage selectedPackage={selectedPackage} />
                    </Grid>
                  ) : null}
                  <>
                    {selectedPackage.packageStatus === PackagesStatus.LIVE ||
                      (!(selectedPackage.products && selectedPackage.products.length > 0) ? (
                        <Grid item>
                          <Button
                            color={ColorType.BUTTON}
                            type="button"
                            onClick={() => {
                              navigate(`/packages/${selectedPackage.productPackageId}/edit`)
                            }}
                            variant="contained"
                            aria-controls="main"
                          >
                            {t('Edit')}
                          </Button>
                        </Grid>
                      ) : null)}
                  </>
                  <Grid item>
                    <PackageActions
                      currentPackage={selectedPackage}
                      onClick={openMenu}
                      productPackageName={selectedPackage.productPackageName}
                      anchorEl={anchorEl as any}
                      onClose={closeConfirmAndMenu}
                      productPackageId={selectedPackage.productPackageId}
                      onDelete={openConfirm(DeleteOrRetire.DELETEPACKAGE)}
                      isOnPackagePage
                      iconButton
                    />
                    <DeleteRetireDialog
                      open={open}
                      productOrPackageKey={selectedPackage.productPackageId}
                      closeDialog={closeConfirmAndMenu}
                      handleDeleteOrRetire={handleDeleteOrRetire}
                      productOrPackageName={selectedPackage.productPackageName}
                      actionType={actionType}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </StepperContainer>
        </Paper>
      </ContentContainer>
    </ProductStepperContainer>
  )
}

export default connect(mapStateToProductProps)(ProductStepperCard)
