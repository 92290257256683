import React, { useState } from 'react'
import { DeleteOrRetire, RetireOrDeleteProductOrPackage } from 'store/products/types'
import { stopPropagation } from 'utils/form.utils'

// @TODO handle delete and retire item, this code was extracted from
// component/ProductCard

const useDeleteRetireDialog = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [actionType, setActionType] = useState<DeleteOrRetire>(DeleteOrRetire.DELETEPRODUCT)

  const handleDeleteOrRetire = (values: RetireOrDeleteProductOrPackage) => {
    setAnchorEl(null)
    setOpen(false)
    switch (actionType) {
      case DeleteOrRetire.DELETEPRODUCT:
        // eslint-disable-next-line
        console.log('dispatch delete product syndication action', values)
        break
      case DeleteOrRetire.RETIREPRODUCT:
        // eslint-disable-next-line
        console.log('dispatch retire product syndication action', values)
        break
      default:
        throw new Error('Unknown delete action')
    }
  }
  const closeConfirmAndMenu = (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(false)
  }

  const openConfirm = (newActonType: DeleteOrRetire) => (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(true)
    setActionType(newActonType)
  }
  return { open, handleDeleteOrRetire, closeConfirmAndMenu, openConfirm, anchorEl, setAnchorEl, actionType }
}

export default useDeleteRetireDialog
