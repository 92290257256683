import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { InfoOutlined } from '@material-ui/icons'
import { ForestImage } from 'assets'
import { MoreAboutModalType } from 'store/modal/types'
import { stopPropagation } from 'utils/form.utils'
import { LinkWithText } from 'dls/molecules/Buttons'
import { infographicMappedWithLocation } from 'utils/moreAbout.util'
import { Body2, H4 } from 'dls'
import useModal from 'components/Modal/hooks/useModal'

interface Props {
  title?: string
  definition?: string
  description?: string
  children?: React.ReactNode
  buttonLink?: string
  image?: string
  location?: MoreAboutModalType
  fixHeight?: boolean
  imageAlt?: string
}

const DivEmptyLanding = styled.div`
  height: 440px;
  width: 100%;
`
const DefinitionGridStyle = styled(Grid)`
  width: 70%;
  text-align: center;
`
const GridMaxContentStyle = styled(Grid)`
  width: max-content;
  white-space: pre-wrap;
  text-align: center;
`
const MainZeroContainer = styled(Grid)`
  height: 100%;
`

const ZeroStateLandingPage = ({
  title = '',
  description,
  definition = '',
  buttonLink = '',
  image,
  location = '',
  children,
  fixHeight = true,
  imageAlt = 'landing page',
}: Props) => {
  const { t } = useTranslation()

  const { showInfographicModal } = useModal()
  const helpModalType = infographicMappedWithLocation(location)

  return (
    <DivEmptyLanding
      id="main_zero_landing"
      data-test-id="zero-landing-container"
      css={fixHeight ? '' : 'height:inherit'}
    >
      <MainZeroContainer container direction="row" alignItems="center" justifyContent="center">
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <img src={image || ForestImage} alt={imageAlt} data-test-id="zero-landing-img" />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
              <GridMaxContentStyle item>
                <H4 data-test-id="zero-landing-tite">{t(title)}</H4>
              </GridMaxContentStyle>
              {definition && definition !== '' ? (
                <DefinitionGridStyle item>
                  <Body2 gutterBottom data-test-id="zero-landing-definition">
                    {t(definition)}
                  </Body2>
                </DefinitionGridStyle>
              ) : null}
              {buttonLink && buttonLink !== '' ? (
                <>
                  <Body2 gutterBottom variantMapping={{ body2: 'div' }}>
                    <LinkWithText
                      onClick={(e: React.MouseEvent) => {
                        stopPropagation(e)
                        if (helpModalType) {
                          showInfographicModal(helpModalType)
                        }
                      }}
                      component="span"
                      endIcon={<InfoOutlined />}
                      aria-haspopup="dialog"
                      aria-controls="more-about-dialog"
                      style={{ verticalAlign: 'middle', display: 'inline' }}
                    >
                      {buttonLink}
                    </LinkWithText>
                  </Body2>
                </>
              ) : null}
              <GridMaxContentStyle item>
                {description && <Body2 data-test-id="zero-landing-description">{t(description)}</Body2>}
                <div data-test-id="zero-landing-children">{children}</div>
              </GridMaxContentStyle>
            </Grid>
          </Grid>
        </Grid>
      </MainZeroContainer>
    </DivEmptyLanding>
  )
}

export default ZeroStateLandingPage
