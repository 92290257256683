import React from 'react'
import { Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ButtonContainer, IconContainer, NameContainer, StyledMenu, useStyles } from './Styled'
import { ExitToAppIcon, PersonOutlineIcon } from './BankmanIcons'
import { UserRole } from 'store/user/types'
import { BreadcrumbsSection, TasksPage } from 'components'
import { getInitials, getColorFromInitials } from 'utils/ui.utils'
import { FeatureFlag } from 'components/ControlComponents'
import BreadcrumbsSelectors from 'store/breadcrumbs/selectors'
import * as UserSelector from 'store/user/selectors'
import { selectUserRole } from 'store/user/selectors'
import { useUserNameWithRole } from 'utils/common.util'
import UserActions from 'store/user/actions'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import { UserAccountIconButton, OAvatar } from 'dls'
import { AvatarSize, ColorType } from 'dls/shared/types'
import { Button } from 'dls/atoms/Button'
import { MenuButton } from 'dls/molecules/IconButtons/IconButtons'

const StyledButton = styled(Button)`
  justify-content: flex-start;
  .MuiButton-text {
    padding: 0px;
  }
  .MuiButton-root {
    padding: 0;
    width: 100%;
  }
`

export function TopBar({ open, handleDrawerToggle, anchorEl, setAnchorEl }: Record<any, any>) {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pageTitleAndUrl = useSelector(BreadcrumbsSelectors.selectBreadcrumbs)
  const userInformation = useSelector(UserSelector.selectUserInformation)
  const isMultiTenant = useSelector(TenantConfigSelectors.isMultiTenant)
  const isParent = useSelector(TenantConfigSelectors.isParentTenant)
  const userRole = useSelector(selectUserRole)

  const getUserNameWithRole = useUserNameWithRole()
  const logout = () => dispatch(UserActions.logout())
  const nullAccessToken = () => dispatch(UserActions.nullAccessToken())
  const openUserMenu = Boolean(anchorEl)
  const fullName = userInformation ? getUserNameWithRole(userInformation) : 'Loading...'

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const initials = getInitials(fullName)

  const avatarColor = getColorFromInitials(initials)
  return (
    <Toolbar>
      <MenuButton
        aria-label={open ? t('Collapse navigation drawer') : t('Expand navigation drawer')}
        onClick={handleDrawerToggle}
        edge="start"
        className={clsx(classes.menuButton)}
      />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <BreadcrumbsSection pageTitleAndUrl={pageTitleAndUrl} />
        </Grid>
        <Grid item component="div" aria-label={t('Your account')}>
          <UserAccountIconButton
            aria-label={t('Your account profile')}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          />
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={openUserMenu}
            onClose={handleClose}
          >
            <Grid container direction="column" spacing={0}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <OAvatar color={avatarColor} size={AvatarSize.LARGE}>
                    {initials}
                  </OAvatar>
                </Grid>
                <Grid item>
                  <NameContainer>
                    <Typography variant="subtitle1" align="center" noWrap>
                      {fullName}
                    </Typography>
                  </NameContainer>
                </Grid>
              </Grid>
              <ButtonContainer>
                <Grid item xs={12}>
                  <StyledButton fullWidth onClick={logout} variant="text" color={ColorType.NONE}>
                    <IconContainer>
                      <PersonOutlineIcon />
                    </IconContainer>
                    {t('Logout')}
                  </StyledButton>
                </Grid>
                <FeatureFlag name="menu_users">
                  <Grid item>
                    <StyledButton
                      onClick={() => navigate(`/user/${userInformation?.userKey}`)}
                      variant="text"
                      fullWidth
                      aria-controls="main"
                      color={ColorType.NONE}
                    >
                      <IconContainer>
                        <ExitToAppIcon />
                      </IconContainer>
                      {t('View Profile')}
                    </StyledButton>
                  </Grid>
                </FeatureFlag>
              </ButtonContainer>
              <Grid>
                {process.env.NODE_ENV === 'development' && (
                  <StyledButton onClick={nullAccessToken} variant="text" color={ColorType.NONE}>
                    {t('NullAccessToken')}
                  </StyledButton>
                )}
              </Grid>
            </Grid>
          </StyledMenu>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!isMultiTenant ? userRole !== UserRole.ACCOUNTS ? <TasksPage /> : null : !isParent ? <TasksPage /> : null}
        </Grid>
      </Grid>
    </Toolbar>
  )
}
