/* eslint-disable no-use-before-define */
import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'
import { APIKEYNUMBEROFTRIES, APIKEYMSWAIT } from './util'

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export enum TokenErrorTypes {
  ACCESS_TOKEN_EXPIRED = '401.000.22',
}

interface ErrorResponse {
  code: TokenErrorTypes
  message: string
}

type AxiosErrorWithRetryLogic = AxiosError & {
  config: AxiosRequestConfig & {
    _didRetry: boolean
  }
}

export enum AUTH_TYPE {
  SSO,
  LEGACY,
}

export enum AUTH_STATUS {
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface AuthEvent {
  authStatus: AUTH_STATUS
}

const unauthorizedEvent: AuthEvent = { authStatus: AUTH_STATUS.UNAUTHORIZED }

type SagaEmitter = (event: unknown) => void
export let apiKey: string | undefined
export let accessToken: string | undefined
export let refreshToken: string | undefined
export let tenxToken: string | undefined
export let authStatusEmitter: SagaEmitter
export let authType: AUTH_TYPE | undefined

export const setApiKey = (newApiKey: string) => {
  apiKey = newApiKey
}

export const setAuthStatusEmitter = (_authStatusEmitter: SagaEmitter) => {
  authStatusEmitter = _authStatusEmitter
}

export const setAuthTokens = (
  newAccessToken?: string,
  newRefreshToken?: string,
  newTenxToken?: string,
  newAuthType?: AUTH_TYPE
) => {
  accessToken = newAccessToken
  refreshToken = newRefreshToken
  tenxToken = newTenxToken
  authType = newAuthType
}

export const authRequestInterceptor = (axiosConfig: AxiosRequestConfig) => {
  axiosConfig.headers = {
    ...axiosConfig.headers,
    Authorization: `Bearer ${accessToken}`,
  }
  return axiosConfig
}
export const authRequestInternalInterceptor = (axiosConfig: AxiosRequestConfig) => {
  axiosConfig.headers = {
    ...axiosConfig.headers,
    Authorization: `Bearer ${tenxToken}`,
    tenxToken,
  }
  return axiosConfig
}

export const authResponseErrorInterceptor = (error: AxiosErrorWithRetryLogic) => {
  const response = error.response as AxiosResponse<ErrorResponse>
  if (response && response.status === 401) {
    const { data } = response

    if (data && 'code' in data) {
      const { code: returnedErrorCode } = data

      if (returnedErrorCode === TokenErrorTypes.ACCESS_TOKEN_EXPIRED) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return refreshAccessTokenIfNotRetry(error)
      }
    } else {
      authStatusEmitter(unauthorizedEvent)
    }
  }
  Sentry.captureException(error)

  return Promise.reject(error)
}
export const createAuthAwareAxios = (
  baseUrl: string | null,
  includeTenxToken: boolean = false,
  axiosConfig: AxiosRequestConfig = { headers: null, timeout: 30000 }
): AxiosInstance => {
  const axiosInstance = axios.create({
    ...axiosConfig,
    baseURL: baseUrl || axios.defaults.baseURL,
    headers: {
      ...axiosConfig.headers,
      'X-Apikey': apiKey,
    },
    timeout: axiosConfig.timeout,
  })

  // Add a request interceptor
  if (includeTenxToken) {
    axiosInstance.interceptors.request.use(authRequestInternalInterceptor, undefined)
  } else {
    axiosInstance.interceptors.request.use(authRequestInterceptor, undefined)
  }
  // Add a response interceptor
  axiosInstance.interceptors.response.use(undefined, authResponseErrorInterceptor)

  return axiosInstance
}
const createAsyncAuthAwareAxios = async (
  baseUrl: string | null,
  includeTenxToken?: boolean,
  axiosConfig?: AxiosRequestConfig
) => {
  for (let i = 0; i < APIKEYNUMBEROFTRIES; i += 1) {
    if (apiKey) {
      return createAuthAwareAxios(baseUrl, includeTenxToken, axiosConfig)
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(APIKEYMSWAIT)
  }
  return Promise.reject()
}

const authAwareAxios = (axiosConfig: AxiosRequestConfig): AxiosPromise => {
  axios.interceptors.request.use(authRequestInterceptor, undefined)
  axios.interceptors.response.use(undefined, authResponseErrorInterceptor)
  return axios(axiosConfig)
}

async function refreshAccessTokenIfNotRetry(axiosError: AxiosErrorWithRetryLogic) {
  const { config: originalRequest } = axiosError

  if (!originalRequest._didRetry) {
    originalRequest._didRetry = true

    const axiosInstance = createAuthAwareAxios('/v1')
    try {
      if (authType === AUTH_TYPE.LEGACY) {
        const response = await axiosInstance.post('/colleagues/refresh', { refreshToken })
        setAuthTokens(response.data.accessToken, response.data.refreshToken, response.data.tenxToken, AUTH_TYPE.LEGACY)
      } else {
        const response = await axiosInstance.post('/colleagues/oauth2/refresh', { refreshToken })
        setAuthTokens(response.data.accessToken, response.data.refreshToken, response.data.tenxToken, AUTH_TYPE.SSO)
      }
      // retry the original request
      return authAwareAxios(originalRequest)
    } catch (e: any) {
      Sentry.captureException(e)
      authStatusEmitter(unauthorizedEvent)
    }
  }

  return Promise.reject(axiosError)
}

export const getBase64ImageFromURL = (response: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(response.data)
  })

export default createAsyncAuthAwareAxios
