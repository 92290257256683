import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import orderBy from 'lodash/orderBy'
import { useParams } from 'react-router-dom'
import Versions from './Versions'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { RootState } from 'store'
import ProductsActions from 'store/products/actions'
import ProductsSelectors from 'store/products/selectors'
import { Loader } from 'components/'
import { orderProductSecondCondition } from 'utils/productVersion.utils'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = {
  fetchVersions: ProductsActions.getVersions,
}

const mapStateToProps = (state: RootState) => ({
  products: ProductsSelectors.selectVersions(state),
  getVersionsloading: ProductsSelectors.selectProductVersionsLoading(state),
})

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

export function VersionPage({ getVersionsloading, products, fetchVersions }: Props) {
  const { t } = useTranslation()
  const { productKey } = useParams()
  const [product] = useSelectedProduct(productKey || '')
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('{{tenantName}} products', { tenantName: currentTenant?.name }), path: `/products/` },
      { title: product ? product.productName : '', path: `/products/${productKey}` },
      { title: 'Versions', path: '' },
    ],
    trigger: !!productKey,
  })

  useEffect(() => {
    if (productKey) {
      fetchVersions(productKey)
    }
  }, [fetchVersions, productKey])

  if (getVersionsloading) {
    return <Loader />
  }
  if (!products || !product) return null
  const orderByVersionAndStatusProduct = orderBy(products, ['version', orderProductSecondCondition], ['asc', 'asc'])
  return <Versions products={orderByVersionAndStatusProduct.reverse()} currentProduct={product} />
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionPage)
