import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import EntityReviewPageLayout from '../../../../Layout/EntityReviewPageLayout/EntityReviewPageLayout'
import useTaxRule from '../../hooks/useTaxRule'
import ProductUsageSectionCard from '../../../../Product/_shared/ProductUsageSectionCard'
import CurrentTaxRuleSectionCard from './components/CurrentTaxRuleSectionCard'
import HistoricTaxRuleSectionCard from './components/HistoricTaxRuleSectionCard'
import FutureTaxRuleSectionCard from './components/FutureTaxRuleSectionCard'
import { Loader } from 'components'
import { getMostRecentPastRate, getNearestFutureRate } from 'utils/referenceData.utils'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const SingleTaxRulePage = (): React.ReactElement => {
  const { t } = useTranslation()
  const { ruleId } = useParams()

  const {
    selectedTaxRule,
    isLoading,
    getTaxRule,
    getTaxRuleProducts,
    productsError,
    productsLoading,
    selectedTaxRuleProducts,
  } = useTaxRule()
  const rulePageUrl = `reference-data/withholding-tax/${selectedTaxRule?.ruleId}`

  useEffect(() => {
    if (ruleId) {
      getTaxRule(ruleId)
    }
  }, [ruleId])

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Rules for withholding tax'), path: 'reference-data/withholding-tax' },
      { title: `${selectedTaxRule?.name}`, path: rulePageUrl },
    ],
    trigger: !!selectedTaxRule,
  })

  if (isLoading || !selectedTaxRule) {
    return <Loader />
  }

  const mostRecentPastRule = getMostRecentPastRate(selectedTaxRule.taxRules)
  const nearestFutureRule = getNearestFutureRate(selectedTaxRule.taxRules)
  const isRuleLive = moment(mostRecentPastRule?.effectiveDate || nearestFutureRule.effectiveDate).isSameOrBefore(
    moment(),
    'day'
  )

  const historicRates = selectedTaxRule.taxRules.filter(({ effectiveDate }) =>
    moment(effectiveDate).isBefore(moment(), 'day')
  )

  const futureRates = selectedTaxRule.taxRules.filter(({ effectiveDate }) =>
    moment(effectiveDate).isAfter(moment(), 'day')
  )

  const calculateActiveStep = () => {
    if (isRuleLive) {
      return 2 // "Live" - at least one non-future rate
    }

    return 1 // "Scheduled" - as any rule has at least one future rate at creation
  }

  return (
    <EntityReviewPageLayout
      name={selectedTaxRule.name}
      activeStep={calculateActiveStep()}
      ariaLabel="About withholding tax rules"
    >
      <CurrentTaxRuleSectionCard
        selectedTaxRule={selectedTaxRule}
        mostRecentPastRule={mostRecentPastRule}
        nearestFutureRule={nearestFutureRule}
        isRuleLive={isRuleLive}
      />
      <ProductUsageSectionCard
        loading={productsLoading}
        error={productsError}
        getProducts={getTaxRuleProducts}
        products={selectedTaxRuleProducts}
        infoPanelText="Rules are applied to products on the product's credit interest feature"
        productsPageLink={`/reference-data/withholding-tax/${selectedTaxRule.ruleId}/products`}
        entityId={selectedTaxRule.ruleId}
        entityName="rule"
        tooltipText="This is the number of products which use this rule within their credit interest feature"
      />
      <FutureTaxRuleSectionCard futureRates={futureRates} ruleId={selectedTaxRule.ruleId} isRuleLive={isRuleLive} />
      <HistoricTaxRuleSectionCard historicRates={historicRates} ruleId={ruleId || ''} />
    </EntityReviewPageLayout>
  )
}

export default SingleTaxRulePage
