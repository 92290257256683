import React from 'react'
import { useTranslation } from 'react-i18next'
import { SearchField } from 'dls'

interface Props {
  searchText: string
  onSearch: (searchText: string) => void
}

export const Search = ({ searchText, onSearch }: Props) => {
  const { t } = useTranslation()

  return (
    <SearchField
      id="search-field"
      opaque
      placeHolder={t('Enter name or code e.g. Customer card or CCRD')}
      search={onSearch}
      searchFieldAriaControls="transaction-codes"
      searchFieldAriaLabel={t('Search Transcation code')}
      searchButtonAriaLabel={t('Search')}
      value={searchText}
    />
  )
}

export default Search
