import {
  EntityReferenceData,
  EntityBadgeSuspenseAccount,
  EntityBadgePackage,
  EntityBadgeProduct,
  EntityBadgeGovernance,
  EntityBadge10x,
  EntityTemplates,
  EntityBadgeProductSyndication,
} from 'dls/atoms'
import { UserRole } from 'store/user/types'

const helpSections = {
  bankmanagerOverview: { label: 'Bank manager overview', url: '/help/overview', icon: EntityBadge10x },
  productManagement: { label: 'Product management', url: '/help/product-management', icon: EntityBadgeProduct },
  productGovernance: { label: 'Product governance', url: '/help/product-governance', icon: EntityBadgeGovernance },
  packages: { label: 'Packages', url: '/help/packages', icon: EntityBadgePackage },
  referenceData: { label: 'Reference data', url: '/help/reference-data', icon: EntityReferenceData },
  suspenseAccounts: { label: 'Suspense accounts', url: '/help/suspense-accounts', icon: EntityBadgeSuspenseAccount },
  productSyndication: {
    label: 'Product syndication',
    url: '/help/product-syndication',
    icon: EntityBadgeProductSyndication,
  },
  templates: { label: 'Templates', url: '/help/templates', icon: EntityTemplates },
}

const adminHelp = Object.keys(helpSections)
const productCreatorHelp: Array<keyof typeof helpSections> = [
  'bankmanagerOverview',
  'productManagement',
  'productGovernance',
  'productSyndication',
  'packages',
  'referenceData',
  'templates',
]
const accountCreatorHelp: Array<keyof typeof helpSections> = ['bankmanagerOverview', 'suspenseAccounts']

const getHelpSections = (userRole?: UserRole) => {
  switch (userRole) {
    case UserRole.ADMIN:
    default:
      return adminHelp.map((section) => helpSections[section])
    case UserRole.PRODUCT:
      return productCreatorHelp.map((section) => helpSections[section])
    case UserRole.ACCOUNTS:
      return accountCreatorHelp.map((section) => helpSections[section])
  }
}

export default getHelpSections
