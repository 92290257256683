import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { kebabCase } from 'lodash'
import WithholdingTaxRuleSidePanel from '../../shared/WithholdingTaxRuleSidePanel'
import useTaxRule from '../../hooks/useTaxRule'
import CreateRuleForm from './components/CreateTaxRuleForm'
import { TemplateFeatureForm, H1, Body2 } from 'components'
import { selectTenantCurrencyCode } from 'store/tenant-config/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export function CreateTaxRulePage() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const allRulesPageUrl = '/reference-data/withholding-tax'
  const gotoAllRulesPage = () => navigate(allRulesPageUrl)

  const { createRule } = useTaxRule()

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Rules for withholding tax'), path: allRulesPageUrl },
      { title: t('Create rule'), path: '' },
    ],
    trigger: true,
  })

  const currencyCode = useSelector(selectTenantCurrencyCode)

  return (
    <ContentContainer data-test-id={kebabCase(`Rule for withholding tax-form-container`)}>
      <TemplateFeatureForm
        header={
          <>
            <H1 gutterBottom>{t('Rule for withholding tax')}</H1>
            <Body2 gutterBottom>{t('Set-up and manage a rule for withholding tax on credit interest ')}</Body2>
          </>
        }
        main={
          <CreateRuleForm
            currencyCode={currencyCode}
            onCancelClick={gotoAllRulesPage}
            onSubmit={createRule}
            initialValues={null}
            aria-controls="main"
          />
        }
        aside={<WithholdingTaxRuleSidePanel />}
      />
    </ContentContainer>
  )
}

export default CreateTaxRulePage
