import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Grid, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Dashboard as DashboardIcon } from '@material-ui/icons'
import { NotFoundImage } from 'assets'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const DivEmptyLanding = styled.div`
  height: 440px;
`
const MainZeroContainer = styled(Grid)`
  height: 100%;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  function MoveToProduct() {
    navigate(`/`)
  }

  return (
    <DivEmptyLanding id="main_not_found">
      <MainZeroContainer container direction="row" alignItems="center" justifyContent="center">
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <img src={NotFoundImage} alt="Page Not Found" />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
              <Grid item>
                <Typography variant="h6">{t('Page not found')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t('Sorry the page you are looking for doesn’t exist. Please return to the dashboard page.')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={ColorType.BUTTON}
              onClick={MoveToProduct}
              css="text-transform: uppercase"
              aria-controls="main"
            >
              <DashboardIcon css="margin-right:4px" />
              {t('Dashboard')}
            </Button>
          </Grid>
        </Grid>
      </MainZeroContainer>
    </DivEmptyLanding>
  )
}

export default NotFound
