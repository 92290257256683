import { AutocompleteSelectFieldData } from 'components/form/FormikMultiSelectAutocomplete'
import countriesJson from 'static/countries.json'
import allCountriesJson from 'static/full-countries.json'
import { CountryItem } from 'store/utils/types'

const countriesWithFlags: AutocompleteSelectFieldData = {}
Object.entries(countriesJson).forEach(([, item]: [string, CountryItem]) => {
  countriesWithFlags[`${item.alpha3Code}`] = {
    key: item.alpha3Code,
    label: `${item.name}`,
    flag: `${item.alpha2Code}`.substring(0, 2),
  }
})

const allCountriesWithFlags: AutocompleteSelectFieldData = {}

Object.entries(allCountriesJson).forEach(([, item]: [string, CountryItem]) => {
  allCountriesWithFlags[`${item.alpha3Code}`] = {
    key: item.alpha3Code,
    label: item.name,
    flag: `${item.alpha2Code}`.substring(0, 2),
  }
})

export const TENANT_URL_PREFIX = 'tenant'

export { countriesWithFlags, allCountriesWithFlags }
