import { merge } from 'lodash'
import actions from '../actions'
import { ProductsState } from '../types'

interface Payload {
  ALT: string
  BANKCARD: Record<string, any>
}

const reducer = {
  [actions.fetchCardImages.type]: (state: ProductsState) => ({
    ...state,
    cardImages: [],
    cardImagesLoading: true,
    cardImagesError: undefined,
  }),

  [actions.fetchCardImagesSuccessful.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.fetchCardImagesSuccessful>
  ) => {
    const { CARDS } = merge((action.payload as any).content, (action.payload as any).images)
    const cardImages = (Object.values(CARDS) as any).map((image: Payload) => ({
      alt: image.ALT,
      url: image.BANKCARD['3x'],
    }))

    return {
      ...state,
      cardImages,
      cardImagesLoading: false,
      cardImagesError: undefined,
    }
  },
  [actions.fetchCardImagesFail.type]: (state: ProductsState) => ({
    ...state,
    cardImages: [],
    cardImagesLoading: false,
    cardImagesError: true,
  }),
}
export default reducer
