import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { FeatureFormPageProps } from '../FeaturePage.page'
import { toDocumentBasedFormValues, hasDocumentBasedFeature } from '../common/commonTransformers'
import TermsAndConditionsForm from './TermsAndConditionsForm'
import ProductsActions from 'store/products/actions'
import ContentsActions from 'store/contents/actions'
import ContentsSelectors from 'store/contents/selectors'
import { RootState } from 'store'
import { CommonFeaturePageValues, Origin, Feature } from 'store/products/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTermsAndConditions: (productKey: string) => (termsAndConditionsFormValues: CommonFeaturePageValues) =>
    dispatch(
      ProductsActions.setTermsAndConditions({
        formData: termsAndConditionsFormValues,
        productKey,
      })
    ),
  getRemoteDocuments: () => dispatch(ContentsActions.getRemoteDocuments()),
})

const mapStateToProps = (state: RootState) => ({
  remoteDocuments: ContentsSelectors.selectRemoteDocuments(state),
  tenantConfig: selectConfig(state),
})

type OwnProps = FeatureFormPageProps

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const TermsAndConditionsPage = (props: Props) => {
  const { setTermsAndConditions, getRemoteDocuments, remoteDocuments } = props
  const { productKey = '' } = useParams()
  useEffect(() => {
    getRemoteDocuments()
  }, [getRemoteDocuments])
  const [product, isLoading] = useSelectedProduct(productKey)
  const navigate = useNavigate()

  useUpdateBreadcrumbs({ productKey, context: Feature.TERMSANDCONDITIONS })

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.TERMSANDCONDITIONS)) navigate(`./../not-allowed`)

  if (isLoading) {
    return <Loader />
  }

  return product ? (
    <TermsAndConditionsForm
      productKey={productKey}
      onSubmit={setTermsAndConditions(productKey)}
      initialValues={toDocumentBasedFormValues(product.termsAndConditions, 'existingTermsAndConditions')}
      remoteDocuments={remoteDocuments}
      hasFeature={hasDocumentBasedFeature(product.termsAndConditions)}
      defaultValues={{
        termsAndConditionsVal: {
          origin: Origin.CUSTOM,
          files: [
            {
              format: null,
              url: '',
              label: '',
            },
          ],
        },
      }}
    />
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsPage)
