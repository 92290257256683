import { useField } from 'formik'

const useTransactionRuleField = (name: string) => {
  const [ifData, , ifHelpers] = useField(`${name}.type`)
  const [isData, , isHelpers] = useField(`${name}.operator`)
  const [thisData, thisMeta, thisHelpers] = useField(`${name}.values`)

  const isMultiSelect = (value: string) => value === 'ANY_OF' || value === 'NOT_ANY_OF' || value === 'IN'
  const ruleValueFieldSize: any = isMultiSelect(isData.value) ? 11 : 4

  const onFieldReset = () => {
    ifHelpers.setValue('')
    isHelpers.setValue('')
    thisHelpers.setValue([])
  }

  const onRuleTypeChange = ({ target: { value } }: any) => {
    ifHelpers.setValue(value)
    isHelpers.setValue('')
    thisHelpers.setValue(isMultiSelect(value) ? [] : '')
  }
  const onRuleOperatorChange = ({ target: { value } }: any) => {
    isHelpers.setValue(value)
    thisHelpers.setValue(isMultiSelect(isData.value) ? '' : [])
  }

  const onRuleValueChange = ({ target: { value } }: any) => {
    let sanitisedValue: any[]
    if (Array.isArray(value)) {
      sanitisedValue = value
    } else {
      sanitisedValue = [value]
    }
    thisHelpers.setValue(sanitisedValue)
  }

  return {
    ifValue: ifData.value,
    isValue: isData.value,
    thisValue: thisData.value,
    thisMeta,
    onRuleTypeChange,
    onRuleOperatorChange,
    onRuleValueChange,
    onFieldReset,
    ruleValueFieldSize,
  }
}

export default useTransactionRuleField
