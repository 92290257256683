import React from 'react'
import { Fade, Dialog } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation, useTranslation } from 'react-i18next'
import CreateProcessDialogContent from './CreateProcessDialog'
import { ProcessFormValues, EntityType } from 'store/governance/types'
import GovernanceAction from 'store/governance/actions'
import GovernanceSelectots from 'store/governance/selectors'
import { RootState } from 'store'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

export enum CreateProcessView {
  'CreateProcessForm',
}

interface OwnProps {
  view: CreateProcessView
  open: boolean
  setOpen: (open: boolean) => void
}

const mapDispatchToProps = (dispatch: Dispatch, props: OwnProps) => ({
  createProcess: (processFormValues: ProcessFormValues) => {
    props.setOpen(false)
    dispatch(GovernanceAction.createProcess({ ...processFormValues }))
  },
})
const mapStateToProps = (state: RootState) => ({
  processes: GovernanceSelectots.selectAllProcess(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps & WithTranslation

const CreateProcess = (props: Props) => {
  const { t } = useTranslation()

  const { view, open, setOpen, createProcess, processes } = props
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)
  const renderContent = () => {
    switch (view) {
      case CreateProcessView.CreateProcessForm:
        return (
          <CreateProcessDialogContent
            onPressCancel={onClose}
            onSubmit={createProcess}
            initialValues={{
              entityType: EntityType.PRODUCT,
              featureTasksGenerationStrategy: false,
            }}
            availableProcess={processes}
          />
        )
      default:
        return null
    }
  }
  return (
    <>
      {processes.length < 2 ? (
        <Button variant="contained" color={ColorType.BUTTON} onClick={onOpen} css="text-transform: uppercase">
          {t('Create Process')}
        </Button>
      ) : null}

      <Dialog open={open} scroll="paper" onClose={onClose} TransitionComponent={Fade} fullWidth>
        {renderContent()}
      </Dialog>
    </>
  )
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateProcess))
