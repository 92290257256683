import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { isEmpty } from 'lodash'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { Limits } from 'store/products/types'
import { isNullOrUndefined } from 'utils/common.util'
import { ReviewRows } from 'dls'
import { Separator } from 'dls/molecules/ReviewRows/types'

interface OwnProps {
  limits: Limits
  currencyOfProduct?: string | null
}

const StyledWrapper = styled.div`
  width: 100%;
  [data-dls='molecules-review-row']:last-child hr {
    display: none;
  }
`

const ViewLimits = (props: OwnProps): React.ReactElement => {
  const { schemeLimits, subscriptionLimit, transactionLimits, cardLimit } = props.limits
  const { t } = useTranslation()

  const buildDefinitions = () => {
    const definitions = []
    if (!isEmpty(cardLimit) && cardLimit?.maximumNumber) {
      definitions.push({
        term: t('Number of cards'),
        itemSeparator: 'light' as Separator,
        definitions: [t('Maximum number'), cardLimit.maximumNumber],
      })
    }

    if (!isEmpty(schemeLimits) && !isNullOrUndefined(schemeLimits?.[0]?.minimumAmount)) {
      const definitionsList = [
        {
          definitions: [
            {
              term: 'Minimum amount',
              definitions: [
                <>
                  <CurrencySymbol
                    typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                    currencyCode={props.currencyOfProduct}
                  />
                  {new Intl.NumberFormat('en-GB').format(schemeLimits?.[0].minimumAmount || 0)}
                </>,
              ],
            },
          ],
        },
      ]

      if (!isNullOrUndefined(schemeLimits?.[0]?.maximumAmount)) {
        definitionsList[0].definitions.push({
          term: 'Maximum amount',
          definitions: [
            <>
              <CurrencySymbol
                typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                currencyCode={props.currencyOfProduct}
              />
              {!isNullOrUndefined(schemeLimits?.[0].maximumAmount) &&
                new Intl.NumberFormat('en-GB').format(schemeLimits?.[0].maximumAmount || 0)}
            </>,
          ],
        })
      }

      definitions.push({
        term: t('Faster payment service limit'),
        itemSeparator: 'light' as Separator,
        definitions: [<ReviewRows key="sceheme-income" definitionsList={definitionsList} />],
      })
    }

    if (!isEmpty(transactionLimits) && !isNullOrUndefined(transactionLimits?.[0]?.minimumAmount)) {
      const definitionsList = [
        {
          definitions: [
            {
              term: t('Minimum amount'),
              definitions: [
                <>
                  <CurrencySymbol
                    typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                    currencyCode={props.currencyOfProduct}
                  />
                  {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(
                    transactionLimits?.[0].minimumAmount || 0
                  )}
                </>,
              ],
            },
          ],
        },
      ]

      if (!isNullOrUndefined(transactionLimits?.[0]?.maximumAmount)) {
        definitionsList[0].definitions.push({
          term: t('Maximum amount'),
          definitions: [
            <>
              <CurrencySymbol
                typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                currencyCode={props.currencyOfProduct}
              />
              {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(
                transactionLimits?.[0].maximumAmount || 0
              )}
            </>,
          ],
        })
      }

      definitions.push({
        term: t('FPS cumulative daily payment out limit'),
        itemSeparator: !isNullOrUndefined(transactionLimits?.[0]?.maximumAmount) ? ('light' as Separator) : undefined,
        definitions: [<ReviewRows key="transaction-limits" definitionsList={definitionsList} />],
      })
    }

    if (!isEmpty(subscriptionLimit) && subscriptionLimit?.maximumNumber) {
      definitions.push({
        term: t('Number of accounts'),
        itemSeparator: 'light' as Separator,
        definitions: [t('Maximum number'), subscriptionLimit.maximumNumber],
      })
    }

    return definitions
  }

  return (
    <StyledWrapper>
      <ReviewRows definitionsList={[{ definitions: buildDefinitions() }]} />
    </StyledWrapper>
  )
}

export default ViewLimits
