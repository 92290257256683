import { call } from 'redux-saga/effects'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import {
  featureCardsToPayload,
  featureCreditInterestToPayload,
  featureCurrencyToPayload,
  featureDocumentsToPayload,
  featureEligibilityToPayload,
  featureLimitsToPayload,
  featureProductCoreToPayload,
  featureRequiredExternalIdToPayload,
  featureStatementToPayload,
  featureTermsAndConditionsToPayload,
  subscriptionCreationRuleToPayload,
} from './products.transformers'
import {
  CardsFormValues,
  CreateProductWithTemplate,
  Currency,
  DeleteFeaturePayload,
  DocumentsValues,
  EligibilityFormValues,
  ExtendedProduct,
  Identification,
  Limits,
  Product,
  ProductFormValues,
  ProductKey,
  PublishProductPayload,
  RequiredExternalIdFormValues,
  RetireOrDeleteProductOrPackage,
  ReviewProductPayload,
  StatementFormValues,
  SubcriptionCreationPayload,
  CommonFeaturePageValues,
  TransactionRules,
  UpdateProductFormValues,
  CreditInterestPayloadValues,
  SearchProductsResponse,
} from 'store/products/types'
import { bankTzToIso } from 'utils/date.utils'
import { featureTransactionRulesToPayload } from 'pages/features/TransactionRules/transactionRulesTransformers'

async function fetch() {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<Product[]>('/v2/products', {
    headers: {
      Accept: 'application/vnd.10x.product.summary+json',
    },
    params: {
      sort: 'DESC',
    },
  })
  return data
}

async function updateProduct(productKey: ProductKey, updateProductPayload: UpdateProductFormValues) {
  const fixedpayload = featureProductCoreToPayload(updateProductPayload as UpdateProductFormValues)
  const { data } = await (await createAsyncAuthAwareAxios(null)).put(
    `/v2/products/${productKey}/productCore`,
    fixedpayload
  )
  return data
}

async function fetchExtendedProducts(): Promise<ExtendedProduct[]> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get('/v2/products')
  return data
}

async function getProductDetails(productKey: ProductKey) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<ExtendedProduct>(`/v2/products/${productKey}`)

  // @TODO
  // We identify the desentralised features (ie, overdraft) and get the featureId for each one
  // GET /interest-calculator/v1/products/${productKey}/features/${featureName}/${featureId} as OverdraftPayload (fetchOverdraft)
  // Merge data + OvedraftPayload

  return data // return merged data
}

async function publish(payload: PublishProductPayload) {
  const { productKey, ...request } = payload
  const transformedPayload = {
    ...request,
    effectiveDate: bankTzToIso(request.effectiveDate),
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/publish`,
    transformedPayload
  )
  return data
}

async function create(payload: Partial<CreateProductWithTemplate>) {
  if (payload.params) {
    const { data } = await (await createAsyncAuthAwareAxios(null)).post<ExtendedProduct>(
      `/v2/products`,
      payload.params.templateId ? null : payload.body,
      payload.params.templateId
        ? {
            headers: { 'content-type': 'application/json' },
            params: payload.params,
          }
        : {
            params: payload.params,
          }
    )
    return data
  }
  const fixedpayload = featureProductCoreToPayload(payload.body as ProductFormValues)
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<ExtendedProduct>(`/v2/products`, fixedpayload)
  return data
}

async function deleteProduct(productKey: ProductKey) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).delete<void>(`/v2/products/${productKey}`)
  return data
}

async function retireProduct(payload: RetireOrDeleteProductOrPackage) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${payload.productOrPackageKey}/retire`,
    {
      message: payload.message,
    }
  )
  return data
}

async function sendProductForReview(payload: ReviewProductPayload) {
  const { productKey, ...request } = payload
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/review`,
    request
  )
  return data
}

async function deleteFeature(deleteFeaturePayload: DeleteFeaturePayload) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).delete<ExtendedProduct>(
    `/v2/products/${deleteFeaturePayload.productKey}/${deleteFeaturePayload.featureName}`
  )
  return data
}

async function setFeatureEligibility(productKey: ProductKey, payload: EligibilityFormValues) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/eligibility`,
    featureEligibilityToPayload(payload)
  )
  return data
}

async function setFeatureCreditInterest(productKey: ProductKey, payload: CreditInterestPayloadValues) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/creditInterest`,
    featureCreditInterestToPayload(payload)
  )
  return data
}

async function setFeatureLimits(productKey: ProductKey, payload: Limits) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/limits`,
    featureLimitsToPayload(payload)
  )
  return data
}

async function setFeatureCards(productKey: ProductKey, payload: CardsFormValues) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/cards`,
    featureCardsToPayload(payload)
  )
  return data
}

async function setFeatureCurrency(productKey: ProductKey, payload: Currency) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/currency`,
    featureCurrencyToPayload(payload)
  )
  return data
}

async function setFeatureRequiredExternalId(productKey: ProductKey, payload: RequiredExternalIdFormValues) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/requiredExternalId`,
    featureRequiredExternalIdToPayload(payload)
  )
  return data
}

async function setFeatureTermsAndConditions(productKey: ProductKey, payload: CommonFeaturePageValues) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/termsAndConditions`,
    featureTermsAndConditionsToPayload(payload)
  )
  return data
}

async function setFeatureDocuments(productKey: ProductKey, payload: DocumentsValues) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/documents`,
    featureDocumentsToPayload(payload)
  )
  return data
}

async function setFeatureStatement(productKey: ProductKey, payload: StatementFormValues) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/statements`,
    featureStatementToPayload(payload)
  )
  return data
}

async function setSubscriptionCreationRule(productKey: string, payload: SubcriptionCreationPayload) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/subscriptionCreationRule`,
    subscriptionCreationRuleToPayload(payload)
  )
  return data
}

async function setIdentification(productKey: string, payload: Identification) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/identification`,
    payload
  )
  return data
}

async function getProductVersions(productKey: ProductKey) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<Product[]>(`/v2/products/${productKey}/versions`)
  return data
}

async function getProductByVersion(productKey: ProductKey, version: string) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<ExtendedProduct>(
    `/v2/products/${productKey}/versions/${version}`
  )
  return data
}

function* getProductByVersionSaga(productKey: ProductKey, version: string) {
  const newProduct: ReturnTypePromise<typeof getProductByVersion> = yield call(getProductByVersion, productKey, version)
  if (!newProduct) {
    throw new Error('Product not found')
  }
  return newProduct
}

async function fetchCardImages() {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<Product[]>(
    `/content/alphabank/gb/en/bank-manager.getAppContent.nested.json`
  )
  return data
}

async function setFeatureTransactionRules(productKey: ProductKey, payload: TransactionRules) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).put<ExtendedProduct>(
    `/v2/products/${productKey}/transactionRules`,
    featureTransactionRulesToPayload(payload)
  )
  return data
}

function* getLatestProduct(productKey: string) {
  const newProduct: ReturnTypePromise<typeof getProductDetails> = yield call(getProductDetails, productKey)
  if (!newProduct) {
    throw new Error('Product not found')
  }
  return newProduct
}

function* getLatestTransactionRules(productKey: string) {
  const { transactionRules } = yield getLatestProduct(productKey)
  return transactionRules
}

async function createNewProductVersion(productKey: ProductKey) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<ExtendedProduct>(
    `/v1/products/${productKey}/versions/clone`
  )
  return data
}

async function getStatementProducts(payload: string): Promise<SearchProductsResponse> {
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<SearchProductsResponse>(
    '/v2/products/search',
    null,
    {
      params: {
        statementConfigKey: payload,
      },
    }
  )
  return data
}

export default {
  fetch,
  create,
  getProductDetails,
  updateProduct,
  fetchExtendedProducts,
  publish,
  deleteProduct,
  setFeatureEligibility,
  setFeatureCreditInterest,
  setFeatureCurrency,
  setFeatureCards,
  setFeatureRequiredExternalId,
  setFeatureTermsAndConditions,
  setFeatureStatement,
  setFeatureLimits,
  setFeatureDocuments,
  setSubscriptionCreationRule,
  setIdentification,
  deleteFeature,
  sendProductForReview,
  getProductVersions,
  getProductByVersion,
  getProductByVersionSaga,
  retireProduct,
  fetchCardImages,
  setFeatureTransactionRules,
  getLatestProduct,
  getLatestTransactionRules,
  createNewProductVersion,
  getStatementProducts,
}
