import React, { useCallback, useState, useEffect } from 'react'
import { WithTheme } from '@material-ui/core/styles'
import { AppBar } from '@material-ui/core'
import { Outlet, useHref } from 'react-router-dom'
import styled from 'styled-components/macro'
import { WithWidthProps } from '@material-ui/core/withWidth'
import clsx from 'clsx'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import { SkipToContentButton, useStyles } from './Styled'
import { TopBar } from './TopBar'
import NavBar from './NavBar'
import PageTitle from 'components/PageTitle/PageTitle'
import { NotificationBar } from 'components'
import ModalContainer from 'components/Modal/ModalContainer'

type Props = WithWidthProps & Partial<WithTheme>

interface LayoutProps extends Props {
  headLessLayout?: boolean
}

export const Container = styled.div`
  flexgrow: 1;
  padding: 32px;
  height: 100%;
  width: 100%;
  outline: none;
  ${({ theme }) => `
      ${theme.breakpoints.down('xs')} {
        padding: 16px;
      }
    `};
`

const OwnAppbar = ({ open, children }: { open: boolean; children: React.ReactNode }) => {
  const classes = useStyles()
  const [pageScrolled, setPageScrolled] = useState(false)

  const onScroll = () => {
    if (window.pageYOffset > 64) {
      setPageScrolled(true)
    } else {
      setPageScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const trigger = useScrollTrigger({ target: window || undefined, threshold: 50 })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.appBarShiftSmaller]: !open,
          [classes.appBarOffset]: pageScrolled,
        })}
        role="banner"
        aria-label="Bank Manager"
      >
        {children}
      </AppBar>
    </Slide>
  )
}

const Layout: React.FunctionComponent<LayoutProps> = ({ headLessLayout }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <div style={{ display: 'flex' }}>
      <SkipToContentButton className="sr-only" href={useHref('#main')}>
        Skip to content
      </SkipToContentButton>
      {!headLessLayout && (
        <>
          <PageTitle />
          <NotificationBar />
          <OwnAppbar open={open}>
            <TopBar open={open} handleDrawerToggle={handleDrawerToggle} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          </OwnAppbar>
          <NavBar open={open} handleDrawerToggle={handleDrawerToggle} />
        </>
      )}
      <ModalContainer />
      <Container id="main" role="region" aria-live="polite" aria-atomic="true" tabIndex={-1} aria-label="Page content">
        {!headLessLayout && <div className={classes.toolbar} />}
        <Outlet />
      </Container>
    </div>
  )
}

export default Layout
