import moment, { Moment } from 'moment-timezone'
import { FloorType } from './types'

const DATE_FORMAT = 'DD/MMM/YYYY'
const TIME_FORMAT = 'HH:mm'
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
const TIMEZONE_FORMAT = 'z'
const DAY_DATE_FORMAT = 'dddd, DD MMMM'
const UTC_OFFSET = '[Etc/UTC]'

const setTimeZone = (bankTimezone: string | undefined) => {
  moment.tz.setDefault(bankTimezone)
}

const toBankTz = (isoDate?: string) => {
  let cleanIsoDate: string | undefined
  if (typeof isoDate === 'string' && isoDate.includes(UTC_OFFSET)) {
    cleanIsoDate = isoDate.replace(UTC_OFFSET, '')
  } else {
    cleanIsoDate = isoDate
  }

  const mDate = moment(cleanIsoDate)
  const date = mDate.format(DATE_FORMAT)
  const time = mDate.format(TIME_FORMAT)
  const tz = mDate.format(TIMEZONE_FORMAT)
  return {
    mDate,
    date,
    time,
    tz,
    dateTime: `${date} ${time} ${tz}`,
    isoDate: mDate.toISOString(true),
    isAfterNow: mDate.isAfter(moment()),
  }
}

const relativeDate = (date: string) => {
  const { dateTime, mDate } = toBankTz(date)
  const isOver5Days = moment().diff(mDate, 'days') > 5
  const fromNow = mDate.fromNow()
  return { isOver5Days, fromNow, dateTime, relativeDateTime: isOver5Days ? dateTime : fromNow }
}

const bankTzToIso = (bankTzDate: Moment): string => bankTzDate.toISOString().replace('Z', '+0000')

const minDate = () => toBankTz().isoDate
export type BankDateTime = ReturnType<typeof toBankTz>

const floorTimestamp = (date: moment.Moment | null, floor: FloorType): moment.Moment | null => {
  if (!date) return date
  switch (floor) {
    case FloorType.HOURS:
      return date.hour(0).minute(0).seconds(0).millisecond(0)
    case FloorType.MINUTES:
      return date.minute(0).seconds(0).millisecond(0)
    case FloorType.SECONDS:
      return date.seconds(0).millisecond(0)
    case FloorType.MILLISECONDS:
      return date.millisecond(0)
    default:
  }
  return date
}

const calculateDaysInMonth = (selectedMonth?: number): Record<string, string> => {
  const daysArr = Array.from(Array(31).keys())
  const monthSpecificAmount = moment(selectedMonth || 'MONTH01', '[MONTH]MM').daysInMonth()

  daysArr.length = monthSpecificAmount

  return daysArr.reduce((acc, cur) => {
    const dayStr = `DAY${cur + 1}`
    acc[dayStr] = dayStr
    return acc
  }, {})
}

export {
  toBankTz,
  bankTzToIso,
  setTimeZone,
  minDate,
  relativeDate,
  DATE_FORMAT,
  TIME_FORMAT,
  TIMEZONE_FORMAT,
  DATETIME_FORMAT,
  DAY_DATE_FORMAT,
  floorTimestamp,
  calculateDaysInMonth,
}
