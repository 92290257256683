import { call, put, delay } from 'redux-saga/effects'
import i18next from 'i18next'
import { ExtendedProduct } from '../types'
import { navigate } from 'store/router/actions'
import ModalActions from 'store/modal/actions'
import ProductsActions from 'store/products/actions'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import { showPendingActionModalSaga } from 'store/modal/sagas'
import { PaymentLimitsApi } from 'api'

export function* setPaymentLimitsConfigSaga(action: ReturnType<typeof ProductsActions.setPaymentLimitsConfig>) {
  try {
    const t = i18next.t.bind(i18next)
    const {
      payload: { productKey, version, ...config },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    yield call(PaymentLimitsApi.setPaymentLimitsConfig, productKey, version, config)
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(navigate(`/products/${productKey}/features/payment-limits`))
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showFeatureSuccessModal({
        featureName: t('Year definition'),
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* setPaymentLimitSaga(action: ReturnType<typeof ProductsActions.setPaymentLimit>) {
  try {
    const t = i18next.t.bind(i18next)
    const {
      payload: { productKey, version, limitName, ...paymentLimit },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    if (paymentLimit.limitRuleId) {
      yield call(PaymentLimitsApi.updatePaymentLimit, productKey, version, paymentLimit)
    } else {
      yield call(PaymentLimitsApi.setPaymentLimit, productKey, version, paymentLimit)
    }
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(navigate(`/products/${productKey}/features/payment-limits`))
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showFeatureSuccessModal({
        featureName: t('{{limitName}} limit', { limitName }),
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* deletePaymentLimitSaga(action: ReturnType<typeof ProductsActions.deletePaymentLimit>) {
  try {
    const {
      payload: { productKey, version, limitName, limitRuleId },
    } = action
    const t = i18next.t.bind(i18next)
    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: `You're removing ${t(limitName)?.toLowerCase()} limit`,
      message: "Are you sure? This can't be undone.",
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())

      yield call(PaymentLimitsApi.deletePaymentLimit, limitRuleId, productKey, version)

      yield put(ProductsActions.getProductDetails({ productKey }))

      yield put(navigate(`/products/${productKey}/features/payment-limits`))

      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: `${t(limitName)} successfully deleted`,
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}
