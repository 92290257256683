import React, { useCallback, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { orderBy, groupBy } from 'lodash'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import WithholdingTaxRuleSidePanel from '../../shared/WithholdingTaxRuleSidePanel'
import { DATE_FORMAT } from 'utils/date.utils'
import { WithholdingRule } from 'store/withholdingTax/types'
import { BackButton } from 'components'
import { RateMultiplyByHundred } from 'utils/form.utils'
import { RateTierBand } from 'store/products/types'
import { selectCurrencySymbol } from 'store/tenant-config/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'

type RouteOwnProps = {
  currentTaxRule: WithholdingRule
}

const StyledGrid = styled(Grid)``

const StyledGridActivity = styled(Grid)`
  ${(props: any) => `${props.theme.breakpoints.up('lg')}`} {
    margin-top: 16px;
  }
`
const Content = styled.header`
  margin: 24px;
  margin-top: 0px;
  margin-bottom: 80px;
`
const Header = styled.header`
  margin: 24px;
  margin-bottom: 18px;
`

const ContentPaper = styled(Paper)`
  padding: 0px;
  margin-top: 16px;
  margin-bottom: 24px;
  overflow: auto;
`

const ButtonContainer = styled.div`
  align-items: center;
  padding-left: 0px;
  padding-top: 0px;
  margin-bottom: -20px;
`
const TypographyName = styled(Typography)`
  margin-left: 24px;
`
const TypographyEffictiveDate = styled(Typography)`
  text-align: left;
  margin-bottom: 5px;
`

const StyledTimelineConnector = styled(TimelineConnector)`
  width: 1px;
  background-color: ${(props: any) => props.theme.palette.divider};
  padding-bottom: 0;
  margin-bottom: 0;
`
const StyledHiddenTimelineConnector = styled(TimelineConnector)`
  width: 0px;
  background-color: ${(props: any) => props.theme.palette.divider};
  padding-bottom: 0px;
  margin-bottom: 0px;
`
const StyledTimelineOppositeContent = styled(TimelineOppositeContent)`
  padding: 3px 16px 13px;
`
export function TaxRuleHistory({ currentTaxRule }: RouteOwnProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currencySymbol = useSelector(selectCurrencySymbol)

  const onPressBack = useCallback(() => {
    navigate(`/reference-data/withholding-tax/${currentTaxRule?.ruleId}`)
  }, [])

  const ruleGroups = orderBy(
    Object.entries(
      groupBy(
        orderBy(
          currentTaxRule?.taxRules?.filter(
            (i) => i.effectiveDate && !moment(i.effectiveDate, 'YYYY-MM-DD').isAfter(moment())
          ),
          ['effectiveDate'],
          ['desc']
        ),
        (i) => parseInt(moment(`${i.effectiveDate}`).format('YYYY'), 10)
      )
    ).map(([key, value]) => ({ key, value })),
    ['key'],
    ['desc']
  )

  const stringTemplate = '(when income is {{parm1}} – {{parm2}})'
  return (
    <ContentContainer data-test-id="historic-rate-page">
      <ButtonContainer>
        <BackButton onPressBack={onPressBack} aria-controls="main" />
      </ButtonContainer>
      <StyledGrid
        container
        spacing={4}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        css={`
          width: 100%;
        `}
      >
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <ContentContainer>
            <ContentPaper elevation={1}>
              <Header>
                <Typography variant="h1" gutterBottom>
                  {t('Historic rates')}
                </Typography>
              </Header>
              <TypographyName variant="h2" gutterBottom>
                {`${t('Rule ')} :  ${currentTaxRule.name}`}
              </TypographyName>
              <Content>
                <StyledGrid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  {ruleGroups &&
                    ruleGroups.map((rules, index: number) => (
                      <Fragment key={index}>
                        <StyledGrid item xs={12} css="margin-bottom:5px; margin-top:16px">
                          <Typography variant="h3">{rules.key}</Typography>
                        </StyledGrid>
                        <Timeline css="padding:0; margin:0;" key={index}>
                          {rules.value &&
                            rules.value.map((i, indx) => (
                              <Fragment key={indx}>
                                {i.taxTierBand && (
                                  <TimelineItem css="padding-left:16px;">
                                    <TimelineSeparator css="box-shadow:none:">
                                      <TimelineDot color="primary" css="box-shadow:none:" />
                                      {indx === rules.value.length - 1 ? (
                                        <StyledHiddenTimelineConnector />
                                      ) : (
                                        <StyledTimelineConnector />
                                      )}
                                    </TimelineSeparator>
                                    <StyledTimelineOppositeContent>
                                      {i.taxTierBand.map((tierBand, ind) => (
                                        <>
                                          {ind === 0 && (
                                            <Typography variant="body2" css="text-align:left">
                                              {i.tierBandCalcMethod === RateTierBand.TIER ? t('Tiers') : t('Bands')} :
                                            </Typography>
                                          )}
                                          <Typography variant="body2" css="text-align:left">
                                            {tierBand.tierBandRate && RateMultiplyByHundred(tierBand.tierBandRate)}%
                                            {` ${t(stringTemplate, {
                                              parm1: `${currencySymbol}${tierBand.startRange}`,
                                              parm2: `${currencySymbol}${tierBand.endRange}`,
                                            })}`}
                                          </Typography>
                                          {ind === (i.taxTierBand?.length || 0) - 1 && (
                                            <TypographyEffictiveDate variant="body2" color="textSecondary">
                                              {t(`Effective from `)}
                                              {i.effectiveDate ? moment(i.effectiveDate).format(DATE_FORMAT) : ''}
                                            </TypographyEffictiveDate>
                                          )}
                                        </>
                                      ))}
                                    </StyledTimelineOppositeContent>
                                  </TimelineItem>
                                )}
                                {i.tierBandCalcMethod === RateTierBand.FLAT && (
                                  <>
                                    <TimelineItem css="padding-left:16px;min-height:45px">
                                      <TimelineSeparator css="box-shadow:none:">
                                        <TimelineDot color="primary" css="box-shadow:none:" />
                                        {indx === rules.value.length - 1 ? (
                                          <StyledHiddenTimelineConnector />
                                        ) : (
                                          <StyledTimelineConnector />
                                        )}
                                      </TimelineSeparator>
                                      <StyledTimelineOppositeContent>
                                        <Typography variant="body2" css="text-align:left">
                                          {t('Flat rate')} : {i.flatRate && RateMultiplyByHundred(i.flatRate)}%
                                        </Typography>
                                        <TypographyEffictiveDate variant="body2" color="textSecondary">
                                          {t(`Effective from `)}
                                          {i.effectiveDate ? moment(i.effectiveDate).format(DATE_FORMAT) : ''}
                                        </TypographyEffictiveDate>
                                      </StyledTimelineOppositeContent>
                                    </TimelineItem>
                                  </>
                                )}
                              </Fragment>
                            ))}
                        </Timeline>
                      </Fragment>
                    ))}
                </StyledGrid>
              </Content>
            </ContentPaper>
          </ContentContainer>
        </Grid>
        <StyledGridActivity xs={12} item sm={12} md={12} lg={4}>
          <WithholdingTaxRuleSidePanel />
        </StyledGridActivity>
      </StyledGrid>
    </ContentContainer>
  )
}

export default TaxRuleHistory
