import { isArray } from 'lodash'
import { ExtendedProduct, CardsFormValues, CardType, Card } from 'store/products/types'

export const defaultCardsValue = {
  type: CardType.DEBIT,
}
export const hasCardsFeature = (product: ExtendedProduct): boolean =>
  isArray(product?.cards) && product.cards.length > 0

export const toCardsFormValues = (product: ExtendedProduct): CardsFormValues =>
  hasCardsFeature(product) ? { cards: product.cards } : { cards: [] as Card[] }

export const cardMaximumNumberValidation = (maximumNumber?: number, limitMaximumNumber?: number): boolean => {
  if (
    limitMaximumNumber &&
    limitMaximumNumber > 0 &&
    maximumNumber &&
    maximumNumber > 0 &&
    maximumNumber > limitMaximumNumber
  ) {
    return false
  }
  return true
}
