import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import StyledIconWrapper from './Styled/StyledIconWrapper'

const AccountCreatorsMenuItem = ({ isActive }: { isActive: boolean }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const inActiveColor = theme.palette.text.secondary
  return (
    <div>
      <StyledIconWrapper>
        <AccountBalanceIcon
          aria-hidden="true"
          style={{ verticalAlign: 'bottom', color: !isActive ? inActiveColor : 'inherit' }}
        />
      </StyledIconWrapper>
      {t('Account creator')}
    </div>
  )
}

export default AccountCreatorsMenuItem
