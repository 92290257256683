import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { StyledAvatar } from '../StatusBadge/Styled'
import { ProductVersionStatus } from 'store/products/typings/productVersionStatus'
import { toBankTz } from 'utils/date.utils'
import { Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { getStatus, getProductStatusLabel } from 'pages/Product/product.utils'
import { Task } from 'store/tasks/types'
import { getVersionText } from 'utils/productVersion.utils'

interface Props extends Pick<Product, 'version'> {
  status: ProductVersionStatus
  date?: string | null
  name?: string
  task?: Task
  isTaskDrawer?: boolean
}

const StatusContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: start;
  font-size: 14px;
`

const DateTypography = styled.span`
  color: ${(props: any) => props.theme.palette.text.secondary};
  // margin-left: 10px;
  font-size: 12px;
  white-space: nowrap;
`

const VersionTimeRow = styled(Typography)`
  display: inline-flex;
  align-items: flex-end;
  margin-top: 2px;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 0px;
`

const VersionStatusBadge = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const parsedDate = props.date ? toBankTz(props.date) : undefined
  const status = getStatus(props.status, props.task, props.isTaskDrawer)
  const isLive = props.status && status === ProductStatus.LIVE
  return (
    <StatusContainer>
      <StyledAvatar status={props.status} parsedDate={parsedDate} />
      <div>
        <VersionTimeRow variant="body2">
          <span style={{ whiteSpace: 'nowrap' }}>{`${getVersionText(props.version)}: ${getProductStatusLabel(
            status,
            t,
            parsedDate
          )}`}</span>
          {isLive && <DateTypography>{!!parsedDate && parsedDate.dateTime}</DateTypography>}
        </VersionTimeRow>
        {props.name && <Typography variant="body2">{props.name}</Typography>}
      </div>
    </StatusContainer>
  )
}

export default VersionStatusBadge
