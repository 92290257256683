import React from 'react'
import { ExtendButtonBase } from '@material-ui/core/ButtonBase'
import { ButtonTypeMap } from '@material-ui/core/Button/Button'
import { Button } from 'dls/atoms/Button'

export interface AccessibleMenuButtonProps extends ExtendButtonBase<ButtonTypeMap> {
  buttonId: string
  menuId: string
}

export default (props: AccessibleMenuButtonProps) => {
  const { buttonId, menuId, ...btnProps } = props
  return <Button aria-haspopup="menu" aria-controls={menuId} id={buttonId} {...btnProps} />
}
