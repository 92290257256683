import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { kebabCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ProductVersionSelector from '../ProductVersionSelector'
import DeleteRetireDialog from '../DeleteRetireDialog/DeleteRetireDialog'
import ProductActions from './ProductActions'
import CatalogCard from 'dls/organisms/CatalogCard/CatalogCard'
import { Body1, StatusBadge } from 'components'
import { Product, DeleteOrRetire, RetireOrDeleteProductOrPackage } from 'store/products/types'
import ProductsActions from 'store/products/actions'
import { stopPropagation } from 'utils/form.utils'
import { EntityBadgeProduct } from 'dls/atoms'

interface Props {
  product: Product
}

const StyledStatusBadge = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      width: 168px;
    }
    ${theme.breakpoints.down('md')} {
      & > span {
        width: 100%;
        .MuiTypography-root {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  `};
`

const ProductCard = ({ product }: Props): React.ReactElement | null => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [actionType, setActionType] = useState<DeleteOrRetire>(DeleteOrRetire.DELETEPRODUCT)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const viewProduct = (e: any) => {
    e.preventDefault()
    if (navigate) navigate(`/products/${product.productKey}`)
  }

  const handleDeleteOrRetire = (values: RetireOrDeleteProductOrPackage) => {
    setAnchorEl(null)
    setOpen(false)
    switch (actionType) {
      case DeleteOrRetire.DELETEPRODUCT:
        return dispatch(ProductsActions.deleteProduct(values))
      case DeleteOrRetire.RETIREPRODUCT:
        return dispatch(ProductsActions.retireProduct(values))
      default:
        throw new Error('Unknown delete action')
    }
  }
  const closeConfirmAndMenu = (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(false)
  }

  const openConfirm = (newActonType: DeleteOrRetire) => (e: React.MouseEvent) => {
    stopPropagation(e)
    setAnchorEl(null)
    setOpen(true)
    setActionType(newActonType)
  }

  return product ? (
    <>
      <CatalogCard
        region1={[
          <EntityBadgeProduct key={1} />,
          <Body1 variantMapping={{ body1: 'div' }} key="2">
            {product.productName}
          </Body1>,
        ]}
        region2={[<ProductVersionSelector key={0} product={product} isVersionLatest isOnProductPage={false} />]}
        region3={[
          <StyledStatusBadge key={1}>
            <StatusBadge status={product.status} date={product.effectiveDate} />
          </StyledStatusBadge>,
        ]}
        region4={[
          <ProductActions
            key={4}
            product={product}
            searchElement={product.status}
            openConfirm={openConfirm}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl as any}
            closeConfirmAndMenu={closeConfirmAndMenu}
          />,
        ]}
        onClickOnCard={viewProduct}
        id={`product-card-${kebabCase(product.productName)}`}
      />
      <DeleteRetireDialog
        open={open}
        productOrPackageKey={product.productKey}
        closeDialog={closeConfirmAndMenu}
        handleDeleteOrRetire={handleDeleteOrRetire}
        productOrPackageName={product.productName}
        actionType={actionType}
      />
    </>
  ) : null
}

export default ProductCard
