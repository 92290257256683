import React from 'react'
import { Dialog, Fade, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Header, StyledDialogActions } from './Styled'
import { ModalWrapper } from 'components'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

export function ConfirmPublish(props: { open: boolean; onClose: () => void; onClick: () => void }) {
  const { t } = useTranslation()

  return (
    <Dialog open={props.open} scroll="paper" onClose={props.onClose} TransitionComponent={Fade} fullWidth>
      <ModalWrapper
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Header>
            <Typography variant="h6" gutterBottom>
              {t('Publish package to live?')}
            </Typography>
            <Typography variant="body1">
              {t(
                'Are you sure you want to publish these changes to live? There isn’t an approval step for packages and if your package is live to customers, these changes will happen immediately.'
              )}
            </Typography>
          </Header>
        }
      >
        <StyledDialogActions>
          <Button color={ColorType.BUTTON} type="button" onClick={props.onClose}>
            {t('No, Cancel')}
          </Button>
          <Button
            css="margin-left:16px !important"
            type="submit"
            color={ColorType.BUTTON}
            variant="contained"
            onClick={props.onClick}
          >
            {t('Yes, Publish')}
          </Button>
        </StyledDialogActions>
      </ModalWrapper>
    </Dialog>
  )
}
