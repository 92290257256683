/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Fade, Grid } from '@material-ui/core'
import { Field, Form, FormikProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import {
  FormBottom,
  ContentPaperContainer,
  StyledInputAdornment,
  ContentGridContainer,
  TooltipStyle,
  StyledDialog,
  RowGrid,
} from '../styles'
import ConfirmModal from './ConfirmInterestRateEditingModal'
import { EntityBadgeFutureRate } from 'dls/atoms'
import { EditOrAddIndexRate } from 'store/referenceData/types'
import { IndexRatesInfoGraphic } from 'assets'
import {
  ContextualHelp,
  FieldPercentage,
  SidePanel,
  FormBottomBar,
  TextFieldComplex,
  TemplateFeatureForm as TemplateFeatureFormComp,
} from 'components'
import { rateYupValidation, withFormikSimple, Yip } from 'utils/form.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'
import { InfoPanel, H1, H2, Body2 } from 'dls'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  bankName: string
  isUpdating?: boolean
  bankIndexKey?: string
  openConfirm: boolean
  setOpenConfirm: (open: boolean) => void
}

interface EditIndexProps extends FormikProps<EditOrAddIndexRate>, OwnProps {}

const EditInterestRateForm = ({
  values,
  errors,
  isUpdating,
  submitForm,
  setOpenConfirm,
  openConfirm,
  bankName,
  bankIndexKey,
}: EditIndexProps): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const rates = getIn(values, 'editOrAddIndexRate.rates')

  const onClose = () => {
    if (!isUpdating) {
      setOpenConfirm(false)
    }
  }

  return (
    <ContentContainer data-test-id="edit-rate-page">
      <TemplateFeatureFormComp
        main={
          <>
            <Form>
              <StyledDialog open={openConfirm} scroll="paper" onClose={onClose} TransitionComponent={Fade} fullWidth>
                <ConfirmModal closeDialog={onClose} confirmEdit={submitForm} isUpdating={isUpdating} />
              </StyledDialog>
              <ContentPaperContainer elevation={1}>
                <H2 gutterBottom>{bankName}</H2>
                <InfoPanel title={t('You can only edit future rates – effective dates can’t be changed')} />
                {rates &&
                  rates.map((item: any, index: number) => (
                    <ContentGridContainer container direction="row" spacing={2} key={index}>
                      <RowGrid item xs={4}>
                        <Field
                          label={t(`Rate`)}
                          name={`editOrAddIndexRate.rates.${index}.indexRate`}
                          required
                          fullWidth
                          percentageIsPrefix
                          component={FieldPercentage}
                          validate={Yip(rateYupValidation(4, 1000).required())}
                          helperText={t('E.g. 1.25')}
                          inputLabelProps={{ shrink: true }}
                          numberFormatProps={{
                            decimalScale: 13,
                          }}
                        />
                      </RowGrid>
                      <RowGrid item xs={6} css="display: -webkit-inline-box;">
                        <div style={{ display: 'grid', gridGap: 8, gridTemplateColumns: '1fr 40px' }}>
                          <div>
                            <Field
                              label={t('Effective date')}
                              name={`editOrAddIndexRate.rates.${index}.effectiveDate`}
                              fullWidth
                              component={TextFieldComplex}
                              inputLabelProps={{ shrink: true }}
                              InputProps={{
                                startAdornment: (
                                  <StyledInputAdornment position="end">
                                    <EntityBadgeFutureRate />
                                  </StyledInputAdornment>
                                ),
                              }}
                              disabled
                            />
                          </div>
                          <div>
                            <TooltipStyle>
                              <ContextualHelp
                                title={t(
                                  'The date from which the rate will be used in interest calculations. Rates cannot be added with historic effective dates and effective dates cannot be changed once submitted'
                                )}
                              />
                            </TooltipStyle>
                          </div>
                        </div>
                      </RowGrid>
                    </ContentGridContainer>
                  ))}
                <FormBottom>
                  <FormBottomBar hideSubmit inlineNavigation spacing={1}>
                    <Grid
                      item
                      style={{
                        marginRight: 14,
                      }}
                    >
                      <Button
                        color={ColorType.BUTTON}
                        onClick={() => navigate(`/reference-data/index-rates/${bankIndexKey}`)}
                        aria-controls="main"
                      >
                        {t('Cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color={ColorType.BUTTON}
                        onClick={() => setOpenConfirm(true)}
                        disabled={!isEmpty(errors)}
                      >
                        {t('Submit')}
                      </Button>
                    </Grid>
                  </FormBottomBar>
                </FormBottom>
              </ContentPaperContainer>
            </Form>
          </>
        }
        header={
          <>
            <H1 gutterBottom>{t('Edit future rate')}</H1>
            <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
              {t('Here you can make changes to future rates.')}
            </Body2>
          </>
        }
        aside={
          <SidePanel
            heading={t('ABOUT INDEX RATES')}
            image={IndexRatesInfoGraphic}
            imageAlt={t('Illustration of a laptop showing a bar chart of changing index rate.')}
            details={t(
              'Index rates can be created, set and managed here. These rates can be selected when building a product to configure indexed interest rates.'
            )}
            linkToOpenModal={t('More about index rates')}
            infographicType={HelpModalType.INDEX_RATES}
          />
        }
      />
    </ContentContainer>
  )
}

export default withFormikSimple<OwnProps, EditOrAddIndexRate>(EditInterestRateForm, {
  defaultValues: {},
  validationSchema: Yup.object<Partial<EditOrAddIndexRate>>({}).required(),
})
