/* eslint-disable camelcase */
const translations = {
  en: {
    translation: {
      general: {
        cancel: 'Cancel',
        submit: 'Submit',
      },
      CALENDAR: {
        DAY1: '01',
        DAY2: '02',
        DAY3: '03',
        DAY4: '04',
        DAY5: '05',
        DAY6: '06',
        DAY7: '07',
        DAY8: '08',
        DAY9: '09',
        DAY10: '10',
        DAY11: '11',
        DAY12: '12',
        DAY13: '13',
        DAY14: '14',
        DAY15: '15',
        DAY16: '16',
        DAY17: '17',
        DAY18: '18',
        DAY19: '19',
        DAY20: '20',
        DAY21: '21',
        DAY22: '22',
        DAY23: '23',
        DAY24: '24',
        DAY25: '25',
        DAY26: '26',
        DAY27: '27',
        DAY28: '28',
        DAY29: '29',
        DAY30: '30',
        DAY31: '31',
        MONTH01: 'January',
        MONTH02: 'February',
        MONTH03: 'March',
        MONTH04: 'April',
        MONTH05: 'May',
        MONTH06: 'June',
        MONTH07: 'July',
        MONTH08: 'August',
        MONTH09: 'September',
        MONTH10: 'October',
        MONTH11: 'November',
        MONTH12: 'December',
        MONTHLY_GENERIC: {
          DAY1: '01',
          DAY2: '02',
          DAY3: '03',
          DAY4: '04',
          DAY5: '05',
          DAY6: '06',
          DAY7: '07',
          DAY8: '08',
          DAY9: '09',
          DAY10: '10',
          DAY11: '11',
          DAY12: '12',
          DAY13: '13',
          DAY14: '14',
          DAY15: '15',
          DAY16: '16',
          DAY17: '17',
          DAY18: '18',
          DAY19: '19',
          DAY20: '20',
          DAY21: '21',
          DAY22: '22',
          DAY23: '23',
          DAY24: '24',
          DAY25: '25',
          DAY26: '26',
          DAY27: '27',
          DAY28: '28',
          DAY29: '29 (or last for shorter months)',
          DAY30: '30 (or last for shorter months)',
          DAY31: '31 (or last for shorter months)',
        },
      },
      PAYMENT_LIMITS: {
        CALENDAR: 'Calendar year (1 Jan – 31 Dec)',
        ANNIVERSARY: 'Anniversary date of account opening',
        TAX: 'Tax year {{taxYearStart}}',
        OUTBOUND_SINGLE_SELF: 'Single transaction amount',
        OUTBOUND_CUMULATIVE_SELF_DAY: 'Cumulative max amount per day',
        OUTBOUND_CUMULATIVE_SELF_MONTH: 'Cumulative max amount per month',
        OUTBOUND_CUMULATIVE_SELF_YEAR: 'Cumulative max amount per year',
        OUTBOUND_MAX_SELF_DAY: 'Cumulative max number of transactions per day',
        OUTBOUND_MAX_SELF_MONTH: 'Cumulative max number of transactions per month',
        OUTBOUND_MAX_SELF_YEAR: 'Cumulative max number of transactions per year',
        OUTBOUND_SINGLE_PARTY: 'Single transaction amount',
        OUTBOUND_CUMULATIVE_PARTY_DAY: 'Cumulative max amount per day',
        OUTBOUND_CUMULATIVE_PARTY_MONTH: 'Cumulative max amount per month',
        OUTBOUND_CUMULATIVE_PARTY_YEAR: 'Cumulative max amount per year',
        OUTBOUND_MAX_PARTY_DAY: 'Cumulative max number of transactions per day',
        OUTBOUND_MAX_PARTY_MONTH: 'Cumulative max number of transactions per month',
        OUTBOUND_MAX_PARTY_YEAR: 'Cumulative max number of transactions per year',
        INBOUND_SINGLE: 'Single transaction amount',
        INBOUND_CUMULATIVE_DAY: 'Cumulative max amount per day',
        INBOUND_CUMULATIVE_MONTH: 'Cumulative max amount per month',
        INBOUND_CUMULATIVE_YEAR: 'Cumulative max amount per year',
        INBOUND_MAX_DAY: 'Cumulative max number of transactions per day',
        INBOUND_MAX_MONTH: 'Cumulative max number of transactions per month',
        INBOUND_MAX_YEAR: 'Cumulative max number of transactions per year',
        CREDIT: 'inbound',
        DEBIT: 'outbound',
        TRANSFER: 'same party',
        ON_US: 'any other party',
        OUTBOUND_PAYMENT: 'any other party',
        MAX_AMOUNT_LIMIT: '{{period}} limit',
        MAX_NUMBER: 'Max number per {{period}}',
        CUSTOMER_OVERRIDE: 'Customer can set their own limit (within the limit set here)',
      },
      PRODUCT_CATEGORY: {
        BUSINESS_LOANS: 'Business loans',
        CRED_AND_CHRG_CARDS: 'Credit and charging cards',
        LEASES: 'Leases',
        MARGIN_LOANS: 'Margin loans',
        OVERDRAFTS: 'Overdrafts',
        PERS_LOANS: 'Personal loans',
        REGULATED_TRUST_ACCOUNTS: 'Regulated Trust Accounts',
        RESIDENTIAL_MORTGAGES: 'Residential mortgages',
        TERM_DEPOSITS: 'Term deposits',
        TRADE_FINANCE: 'Trade finance',
        TRANS_OR_SAVINGS_ACCOUNTS: 'Transactions or savings accounts',
        TRAVEL_CARDS: 'Travel cards',
      },
      CARD_TRANSACTION_LIMITS: {
        CALENDAR: 'Calendar year (1 Jan – 31 Dec)',
        ANNIVERSARY: 'Anniversary date of account opening',
        TAX: 'Tax year {{taxYearStart}}',
        OUTBOUND_CASH_TRANSACTION: 'Per cash transaction',
        OUTBOUND_CASH_CUMULATIVE_DAILY: 'Cumulative cash daily',
        OUTBOUND_NON_CASH_TRANSACTION: 'Per non-cash transaction',
        OUTBOUND_NON_CASH_DAILY: 'Cumulative non-cash daily',
        FORM_TITLE_OUTBOUND_CASH_TRANSACTION: 'Cash ATM/POS limit per transaction',
        FORM_TITLE_OUTBOUND_CASH_CUMULATIVE_DAILY: 'Cash ATM/POS cumulative daily limit',
        FORM_TITLE_OUTBOUND_NON_CASH_TRANSACTION: 'Non-cash single transaction limit',
        FORM_TITLE_OUTBOUND_NON_CASH_DAILY: 'Non-cash cumulative daily limit',
      },
      PRODUCT_FEATURE: {
        fees: {
          FEATURE_NAME: 'Fees',
          ENTITY_NAME: 'Fee',
          ENTITY_IS: 'FEE is',
          SETUP_DESCRIPTION:
            'Here you can create and manage fees. You can create basic recurring fees such as account maintenance fees. And you can create dynamic fees where the fee will only be applied if certain conditions are met.',
          REVIEW_DESCRIPTION:
            'Here you can create and manage fees. You can create basic recurring fees, or you can create fees which will only be applied if certain conditions are met.',
          ABOUT_ENTITY_HEADING: 'About the fee',
          CONDITIONS_AND_AMOUNT_HEADING: 'Conditions and amount',
          FREQUENCY_HEADING: 'Frequency',
          ENTITY_NAME_FIELD_LABEL: "Fee name (shown on the customers' statement)",
          ENTITY_CATEGORY_FIELD_LABEL: 'Fee category (internal)',
          ENTITY_FREQUENCY_FIELD_LABEL: 'Fee frequency',
          ENTITY_CONDITION_MODAL_HEADING: 'Fee condition {{name}}',
          ADD_ENTITY: 'Add fee',
          EDIT_ENTITY: 'Edit fee',
          CREATE_ENTITY: 'Create fee',
          IF: 'If...',
          AND_IF: 'And if...',
          AND: 'And {{dataPointOperator}}',
          IS: 'Is...',
          VALUE: 'Value',
          BALANCE_HEADING: 'Balance',
          TRANSACTION_HEADING: 'Transactions',
          BALANCE_END_OF_DAY_AMOUNT: 'End of day balance amount',
          BALANCE_UNIQUE_ENTRY: 'Unique balance entries',
          TRANSACTION_AMOUNT: 'Transaction amount',
          TRANSACTION_ISO_CODE: 'Transaction code',
          TRANSACTION_IS_CREDIT: 'Transaction is a credit',
          TRANSACTION_UNIQUE_ENTRY: 'Unique transaction entries',
          COMPARISONS_HEADING: 'Comparisons...',
          CALCULATIONS_HEADING: 'Calculations...',
          ENTITY_CONDITION_MODAL_HELP:
            'Create the conditions under which the fee will be charged. You can add more than 1 condition by tapping ‘Done’ to return to the form where you can choose to add another condition.',
        },
        rewards: {
          FEATURE_NAME: 'Rewards',
          ENTITY_NAME: 'Reward',
          ENTITY_IS: 'REWARD is',
          SETUP_DESCRIPTION:
            'Here you can create and manage rewards. You can create basic scheduled rewards such as a loyalty rewards. And you can create dynamic rewards – where the reward will be applied if certain conditions are met.',
          REVIEW_DESCRIPTION:
            'Here you can create and manage rewards. You can create basic scheduled rewards such as a loyalty rewards. And you can create dynamic rewards – where the reward will be applied if certain conditions are met.',
          ABOUT_ENTITY_HEADING: 'About the reward',
          CONDITIONS_AND_AMOUNT_HEADING: 'Conditions and amount',
          FREQUENCY_HEADING: 'Frequency',
          ENTITY_NAME_FIELD_LABEL: "Reward name (shown on the customers' statement)",
          ENTITY_CATEGORY_FIELD_LABEL: 'Reward category (internal)',
          ENTITY_FREQUENCY_FIELD_LABEL: 'Reward frequency',
          ENTITY_CONDITION_MODAL_HEADING: 'Reward condition {{name}}',
          ADD_ENTITY: 'Add reward',
          EDIT_ENTITY: 'Edit reward',
          CREATE_ENTITY: 'Create reward',
          IF: 'If...',
          AND_IF: 'And if...',
          AND: 'And {{dataPointOperator}}',
          IS: 'Is...',
          VALUE: 'Value',
          BALANCE_HEADING: 'Balance',
          TRANSACTION_HEADING: 'Transactions',
          BALANCE_END_OF_DAY_AMOUNT: 'End of day balance amount',
          BALANCE_UNIQUE_ENTRY: 'Unique balance entries',
          TRANSACTION_AMOUNT: 'Transaction amount',
          TRANSACTION_ISO_CODE: 'Transaction code',
          TRANSACTION_IS_CREDIT: 'Transaction is a credit',
          TRANSACTION_UNIQUE_ENTRY: 'Unique transaction entries',
          COMPARISONS_HEADING: 'Comparisons...',
          CALCULATIONS_HEADING: 'Calculations...',
          ENTITY_CONDITION_MODAL_HELP:
            'Create the conditions under which the fee will be charged. You can add more than 1 condition by tapping ‘Done’ to return to the form where you can choose to add another condition.',
        },
      },
      CONDITION_BUILDER: {
        EQUAL_TO: 'Equal to',
        GREATER_THAN: 'Greater than',
        LESS_THAN: 'Less than',
        GREATER_THAN_OR_EQUAL_TO: 'Equal to or greater than',
        LESS_THAN_OR_EQUAL_TO: 'Equal to or less than',
        AVG: 'Average',
        IN: 'Any of',
        MAX: 'Maximum',
        MIN: 'Minimum',
        SUM: 'Sum',
        COUNT: 'Count',
      },
      DATA_PICKER: {
        TITLE: 'Transaction codes supported on the 10x platform',
        CONTEXTUAL_HELP:
          'Listed here are all of the Bank transaction codes supported on the 10x platform. These follow ISO standards, non-ISO codes are not permitted',
      },
      tryAgain: 'Please try again',
      MAX_VALUE_ERROR: 'Maximum value of {{maxValue}}',
      transactionRules: 'Transaction rules',
      EXCEEDED_MAX_SELECTABLE: "You've exceeded the limit",
      EXCEEDED_MAX_SELECTABLE_DESC:
        'The maximum number of transactions you can transfer at once is {{maxSelectableTransactions}}.',
      EXCEEDED_MAX_SELECTABLE_HINT: 'Deselect some transactions to continue',
      Centrelink_Pensioner: 'Centrelink pensioner',
      CENTRELINK_PENSIONER: 'Centrelink pensioner',
      Other_Eligible_Pensioner: 'Other eligible pensioner',
      OTHER_ELIGIBLE_PENSIONER: 'Other eligible pensioner',
      TFN_not_quoted: 'TIN not held by bank',
      TFN_NOT_QUOTED: 'TIN not held by bank',
      UNDER_AGE_16: 'Under 16 years old',
      email: 'Email',
      FIXED: 'Fixed interest',
      VARIABLE: 'Variable interest',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      password: 'Password',
      login: 'Login',
      browser: 'Your browser does not support the video tag. Please upgrade your browser',
      feesCharges: 'Fees and charges',
      subProducts: 'Subproducts',
      cards: 'Cards',
      requiredExternalId: 'External identifiers',
      multiParty: 'Multi-party',
      eligibility: 'Eligibility',
      statements: 'Statements',
      documents: 'Documents',
      termsAndConditions: 'Terms and conditions',
      creditInterest: 'Credit interest',
      debitInterest: 'Debit interest',
      fees: 'Fees',
      rewards: 'Rewards',
      scheduledFees: 'Fees',
      currency: 'Currency',
      subscriptionCreationRule: 'Subscription creation rules',
      productSyndication: 'ProductSyndication',
      limits: 'Limits',
      customAttributes: 'Custom attributes',
      ADDTEXT: 'ADD',
      identification: 'Product codes',
      ROUND_UP: 'Round up',
      ROUND_DOWN: 'Round down',
      ROUND_STD: 'Standard rounding',
      NONE: 'None',
      submit: 'Submit',
      ageEligibility: 'Age',
      incomeEligibility: 'Annual income',
      productDependencyEligibility: 'Dependent products',
      residencyEligibility: 'Residency',
      studentAccountEligibility: 'Student ID',
      ROUNDUP: 'Round up',
      ROUNDDOWN: 'Round down',
      ROUNDSTD: 'Standard rounding',
      fundingLimits: 'Funding',
      accountBalanceLimits: 'Account balance',
      paymentsInLimit: 'Payments in',
      schemeLimit: 'Scheme',
      productLimit: 'Product',
      customTermsAndConditions: 'New document (from URL)',
      existingTermsAndConditions: 'Existing document (from AEM)',
      existingDocuments: 'Existing document (from AEM)',
      remoteTermsAndConditions: 'Remote terms and conditions',
      customDocuments: 'New document (from URL)',
      ACTIVE: 'Active',
      PROVISIONED: 'Provisioned',
      active: 'Active',
      provisioned: 'Provisioned',
      DEACTIVATED: 'Deactivated (customer must activate)',
      DAY: 'Day',
      LOCKED: 'Locked',
      UNLOCKED: 'Unlocked',
      SPECIFIC_DATE: 'Specific month and day',
      ENDOFDAY: 'End of day',
      AVGDAILY: 'Daily average',
      AMOUNT: 'Amount',
      RATE: 'Fee amount (percentage rate)',
      CHARGERATE: 'Percentage rate',
      FLAT: 'Flat rate',
      AMOUNT_RATE: 'Amount and percentage rate',
      OCCURANCE: 'Occurance',
      calculationFrequency: 'Calculation frequency',
      applicationFrequency: 'Application frequency',
      compoundingFrequency: 'Compounding frequency',
      AGE: 'Age',
      CARDPAYMENT: 'Card payment',
      CARDTRANSACTIONVOLUME: 'Transaction volume',
      ATMWITHDRAWALVOLUME: 'ATM withdrawal volume',
      CARDTRANSACTIONVALUE: 'Card transaction value',
      TRANSACTIONTYPE: 'Transaction type',
      AUTHENTICATIONMETHOD: 'Authentication method',
      MERCHANTCATEGORYCODE: 'Merchant category Code',
      NUMBEROFOCCURRENCES: 'Number of occurences',
      ENDOFDAYBALANCE: 'End of day balance',
      EQUAL_TO: 'Equal to',
      GREATER_THAN: 'Greater than',
      GREATER_THAN_OR_EQUAL_TO: 'Greater than or equal to',
      LESS_THAN: 'Less than',
      LESS_THAN_OR_EQUAL_TO: 'Less than or equal to',
      GREATER_THAN_EQUAL_TO: 'Equal to or greater than',
      LESS_THAN_EQUAL_TO: 'Equal to or less than',
      IN: 'In',
      REGEX: 'Regex',
      FEEAMOUNT: 'Fee amount',
      NOTIONALVALUE: 'Fee basis',
      MINIMUMFEEAMOUNT: 'Minimum fee amount',
      MAXIMUMFEEAMOUNT: 'Maximum fee amount',
      TRANSACTION_VALUE: 'Transaction value',
      END_OF_DAY_BALANCE: 'End of day balance',
      AVERAGE_BALANCE: 'Average balance',
      AVAILABLE_BALANCE: 'Available balance',
      BOOKED_BALANCE: 'Booked balance',
      ACT365: 'Actual/365',
      YES: 'Yes',
      NO: 'No',
      ATM_WITHDRAWAL: 'ATM withdrawal',
      TRANSFER_OUT: 'Transfer out',
      schemeLimits: 'Scheme',
      transactionLimits: 'Payments out',
      cardLimit: 'Card',
      CALCULATE_FEE: 'Calculate fee',
      FEE_AMOUNT: 'Fee amount',
      MAXIMUM_FEE_AMOUNT: 'Maximum fee amount',
      MINIMUM_FEE_AMOUNT: 'Minimum fee amount',
      NOTIONAL_VALUE: 'Fee basis',
      PERSONAL_CURRENT_ACCOUNT: 'Personal Current Account',
      CURRENT_POOL: 'Current pool',
      SAVINGS_POOL: 'Savings pool',
      BILLS_POOL: 'Bills pool',
      ACCOUNT: 'Account',
      MORTGAGE: 'Mortgage',
      LOAN: 'Loan',
      CURRENT_ACCOUNT: 'Business Current Account',
      OTHER: 'Other',
      CUSTOM: 'Custom',
      PDF: 'PDF',
      HTML: 'HTML',
      CLIENT_ACCOUNT: 'Client account',
      STANDARD: 'Standard',
      NON_COMMERCIAL_CHARITIES_CLBSOC: 'Non commercial charities',
      NON_COMMERCIAL_PUBLIC_AUTH_GOVT: 'Non commercial public Auth Govt',
      RELIGIOUS: 'Religious',
      SECTOR_SPECIFIC: 'Sector specific',
      STARTUP: 'Startup',
      SWITCHER: 'Switcher',
      NON_COMMERCIAL: 'Non commercial',
      BASIC: 'Basic',
      BENEFIT_AND_REWARD: 'Benefit and reward',
      CREDIT_INTEREST: 'Credit interest',
      CASHBACK: 'Cashback',
      GENERAL: 'General',
      GRADUATE: 'Graduate',
      PACKAGED: 'Packaged',
      PREMIUM: 'Premium',
      premium: 'Premium',
      REWARD: 'Reward',
      STUDENT: 'Student',
      YOUNG_ADULT: 'Young adult',
      YOUTH: 'Youth',
      BUSINESS: 'Business',
      PERSONAL: 'Personal',
      CORPORATE: 'Corporate',
      CLEARING: 'Clearing',
      SETTLEMENT: 'Settlement',
      AGENCY: 'Agency',
      ANNUAL: {
        label: 'Annually',
        secondary: {
          label: 'Annual occurrence at',
        },
        tertiary: {
          label: 'Date',
          helper: '',
        },
      },
      MONTH: {
        label: 'Monthly',
        secondary: {
          label: 'Monthly occurrence at',
        },
        tertiary: {
          label: 'Day',
        },
        END: 'Month end',
        BEGINNING: 'Month beginning',
        ANNIVERSARY: 'Anniversary of account',
      },
      WEEKLY: {
        label: 'Weekly',
        secondary: {
          label: 'Day of the week',
        },
      },
      DAILY: {
        label: 'Daily',
      },
      DAY_OF_OPENING: 'Anniversary of account',
      SPECIFIC_DAY: 'Specific day',
      PER_OCCURRENCE: { label: 'Per occurance' },
      DAYS_FROM_CALCULATION: {
        label: 'Days from calculation',
      },
      TRIGGERED_DAILY: {
        label: 'Daily',
      },
      TIER: 'Tiers',
      BAND: 'Bands',
      END: 'End',
      BEGINNING: 'Beginning',
      ANNIVERSARY: 'Anniversary',
      accountStatement: 'Account statement',
      interestSummary: 'Credit interest summary',
      feesStatement: 'Fees statement',
      MON: 'Monday',
      TUE: 'Tuesday',
      WED: 'Wednesday',
      THU: 'Thursday',
      FRI: 'Friday',
      SAT: 'Saturday',
      SUN: 'Sunday',
      // Packagetype
      BILLSPOOL: 'Bills pool',
      SAVINGSPOOL: 'Savings pool',
      CURRENTPOOL: 'Current pool',
      ACCOUNTS: 'Accounts',
      DEFAULTONBOARDING: 'Default onboarding',
      CURRENTACCOUNT: 'Current account',
      BUSINESSACCOUNTS: 'Business accounts',
      PERSONALACCOUNTS: 'Personal accounts',
      BUSINESSACCOUNTSEXISTINGCUSTOMERS: 'Business accounts existing customers',
      PERSONALACCOUNTSEXISTINGCUSTOMERS: 'Personal accounts existing customers',
      ACCOUNTSTATEMENT: 'Account statement',
      INTERESTSTATEMENT: 'Interest statement',
      INTERESTSUMMARY: 'Credit interest summary',
      INTERESTSTATEMENTINFO:
        'Interest statements are at a party level, this means they can show many products aggregated onto the same statement',
      INTERESTSTATEMENTDESCRIPTION:
        'Here you can choose and configure the statments for this product. Interest statements are at a party level, this means they can show many products aggregated onto the same statement. Account and fee statements are specific to the product.',
      FEESSTATEMENT: 'Fees statement',
      PersonalCurrentAccount: 'Personal current account',
      CurrentPool: 'Current pool',
      SavingsPool: 'Savings pool',
      BillsPool: 'Bills pool',
      Account: 'Account',
      Mortgage: 'Mortgage',
      Loan: 'Loan',
      CurrentAccount: 'Current account',
      // Taxonomy
      LENDING: 'Lending',
      DEPOSITS: 'Deposits',
      CURRENT: 'Current',
      SAVINGS: 'Savings',
      POOLS: 'Pools',
      NOSTRO_VOSTRO: 'Nostro / Vostro',
      LOANS: 'Loans',
      MORTGAGES: 'Mortgages',
      ClientAccount: 'Client account',
      Standard: 'Standard',
      NonCommercialCharitiesClbSoc: 'Non commercial charities club society',
      NonCommercialPublicAuthGovt: 'Non commercial public authority government',
      Religious: 'Religious',
      SectorSpecific: 'Sector specific',
      Startup: 'Startup',
      Switcher: 'Switcher',
      NonCommercial: 'Non commercial',
      Basic: 'Basic',
      basic: 'Basic',
      vip: 'VIP',
      productCore: 'Product details',
      BenefitAndReward: 'Benefit and reward',
      CreditInterest: 'Credit interest',
      Cashback: 'Cashback',
      General: 'General',
      Graduate: 'Graduate',
      Packaged: 'Packaged',
      Premium: 'Premium',
      Reward: 'Reward',
      Student: 'Student',
      YoungAdult: 'Young adult',
      Youth: 'Youth',
      Business: 'Business',
      Personal: 'Personal',
      Corporate: 'Corporate',
      Clearing: 'Clearing',
      Settlement: 'Settlement',
      Agency: 'Agency',
      DIPLOMAT: 'Diplomat',
      NONRESIDENT: 'Non resident',
      NonResident: 'Non-resident',
      OFFICIAL: 'Official',
      PERMANENT: 'Permanent',
      COMPANY_TAX: 'Company tax',
      HOUSEHOLDER: 'Householder',
      INCORPORATED: 'Incorporated',
      OWNER: 'Owner',
      TRADING: 'Trading',
      TRANSACTIONVALUE: 'Transaction value',
      AVERAGEBALANCE: 'Average balance',
      AVAILABLEBALANCE: 'Available balance',
      BOOKEDBALANCE: 'Booked balance',
      AGGREGATE_TRANSACTION_VALUE: 'Aggregate transaction value',
      AGGREGATETRANSACTIONVALUE: 'Aggregate transaction value',
      CARD_PAYMENT: 'Card payment',
      contactless: 'Contactless',
      chipPin: 'Chip & PIN',
      atm: 'ATM',
      cnp: 'CNP (Card not present - online, mobile and over the phone)',
      magStripe: 'MagStripe',
      international: 'International',
      online: 'Online',
      CONTACTLESS: 'Contactless',
      CHIPPIN: 'Chip & PIN',
      ATM: 'ATM',
      CNP: 'CNP (Card Not Present - online, mobile and over the phone)',
      MAGSTRIPE: 'MagStripe',
      INTERNATIONAL: 'International',
      ONLINE: 'Online',
      VISA: 'Visa',
      MASTERCARD: 'Mastercard',
      UNIONPAY: 'UnionPay',
      AMEX: 'Amex',
      ATMWITHDRAWAL: 'ATM withdrawal',
      TRANSFEROUT: 'Transfer out',
      DEBIT: 'Debit',
      CREDIT: 'Credit',
      TRANSACTION: 'Transaction',
      EVENT: 'Event',
      SCHEDULE: 'Schedule',
      ATMWITHDRAWAL_VOLUME: 'ATM withdrawal volume',
      AUTHENTICATION_METHOD: 'Authentication method',
      CARD_TRANSACTION_VALUE: 'Card transaction value',
      CARD_TRANSACTION_VOLUME: 'Card transaction volume',
      MERCHANT_CATEGORY_CODE: 'Merchant category code',
      NUMBER_OF_OCCURRENCES: 'Number of occurrences',
      ORIGIN_STATE: 'Origin State',
      PRODUCT_GROUP: 'Product Group',
      ROUTING_DESTINATION: 'Routing Destination',
      SCHEME_NAME: 'Scheme name',
      SWEEP_TYPE: 'Sweep type',
      TRANSACTION_CODE: 'Transaction Code',
      TRANSACTION_TYPE: 'Transaction type',
      ON_ME: 'On me',
      COUNTRIES: {
        ABW: 'Aruba',
        AFG: 'Afghanistan',
        AGO: 'Angola',
        AIA: 'Anguilla',
        ALA: 'Åland Islands',
        ALB: 'Albania',
        AND: 'Andorra',
        ARE: 'United Arab Emirates',
        ARG: 'Argentina',
        ARM: 'Armenia',
        ASM: 'American Samoa',
        ATA: 'Antarctica',
        ATF: 'French Southern Territories',
        ATG: 'Antigua and Barbuda',
        AUS: 'Australia',
        AUT: 'Austria',
        AZE: 'Azerbaijan',
        BDI: 'Burundi',
        BEL: 'Belgium',
        BEN: 'Benin',
        BES: 'Bonaire, Sint Eustatius and Saba',
        BFA: 'Burkina Faso',
        BGD: 'Bangladesh',
        BGR: 'Bulgaria',
        BHR: 'Bahrain',
        BHS: 'Bahamas',
        BIH: 'Bosnia and Herzegovina',
        BLM: 'Saint Barthélemy',
        BLR: 'Belarus',
        BLZ: 'Belize',
        BMU: 'Bermuda',
        BOL: 'Bolivia (Plurinational State of)',
        BRA: 'Brazil',
        BRB: 'Barbados',
        BRN: 'Brunei Darussalam',
        BTN: 'Bhutan',
        BVT: 'Bouvet Island',
        BWA: 'Botswana',
        CAF: 'Central African Republic',
        CAN: 'Canada',
        CCK: 'Cocos (Keeling) Islands',
        CHE: 'Switzerland',
        CHL: 'Chile',
        CHN: 'China',
        CIV: "Côte d'Ivoire",
        CMR: 'Cameroon',
        COD: 'Congo, Democratic Republic of the',
        COG: 'Congo',
        COK: 'Cook Islands',
        COL: 'Colombia',
        COM: 'Comoros',
        CPV: 'Cabo Verde',
        CRI: 'Costa Rica',
        CUB: 'Cuba',
        CUW: 'Curaçao',
        CXR: 'Christmas Island',
        CYM: 'Cayman Islands',
        CYP: 'Cyprus',
        CZE: 'Czechia',
        DEU: 'Germany',
        DJI: 'Djibouti',
        DMA: 'Dominica',
        DNK: 'Denmark',
        DOM: 'Dominican Republic',
        DZA: 'Algeria',
        ECU: 'Ecuador',
        EGY: 'Egypt',
        ERI: 'Eritrea',
        ESH: 'Western Sahara',
        ESP: 'Spain',
        EST: 'Estonia',
        ETH: 'Ethiopia',
        FIN: 'Finland',
        FJI: 'Fiji',
        FLK: 'Falkland Islands (Malvinas)',
        FRA: 'France',
        FRO: 'Faroe Islands',
        FSM: 'Micronesia (Federated States of)',
        GAB: 'Gabon',
        GBR: 'United Kingdom of Great Britain and Northern Ireland',
        GEO: 'Georgia',
        GGY: 'Guernsey',
        GHA: 'Ghana',
        GIB: 'Gibraltar',
        GIN: 'Guinea',
        GLP: 'Guadeloupe',
        GMB: 'Gambia',
        GNB: 'Guinea-Bissau',
        GNQ: 'Equatorial Guinea',
        GRC: 'Greece',
        GRD: 'Grenada',
        GRL: 'Greenland',
        GTM: 'Guatemala',
        GUF: 'French Guiana',
        GUM: 'Guam',
        GUY: 'Guyana',
        HKG: 'Hong Kong',
        HMD: 'Heard Island and McDonald Islands',
        HND: 'Honduras',
        HRV: 'Croatia',
        HTI: 'Haiti',
        HUN: 'Hungary',
        IDN: 'Indonesia',
        IMN: 'Isle of Man',
        IND: 'India',
        IOT: 'British Indian Ocean Territory',
        IRL: 'Ireland',
        IRN: 'Iran (Islamic Republic of)',
        IRQ: 'Iraq',
        ISL: 'Iceland',
        ISR: 'Israel',
        ITA: 'Italy',
        JAM: 'Jamaica',
        JEY: 'Jersey',
        JOR: 'Jordan',
        JPN: 'Japan',
        KAZ: 'Kazakhstan',
        KEN: 'Kenya',
        KGZ: 'Kyrgyzstan',
        KHM: 'Cambodia',
        KIR: 'Kiribati',
        KNA: 'Saint Kitts and Nevis',
        KOR: 'Korea, Republic of',
        KWT: 'Kuwait',
        LAO: "Lao People's Democratic Republic",
        LBN: 'Lebanon',
        LBR: 'Liberia',
        LBY: 'Libya',
        LCA: 'Saint Lucia',
        LIE: 'Liechtenstein',
        LKA: 'Sri Lanka',
        LSO: 'Lesotho',
        LTU: 'Lithuania',
        LUX: 'Luxembourg',
        LVA: 'Latvia',
        MAC: 'Macao',
        MAF: 'Saint Martin (French part)',
        MAR: 'Morocco',
        MCO: 'Monaco',
        MDA: 'Moldova, Republic of',
        MDG: 'Madagascar',
        MDV: 'Maldives',
        MEX: 'Mexico',
        MHL: 'Marshall Islands',
        MKD: 'North Macedonia',
        MLI: 'Mali',
        MLT: 'Malta',
        MMR: 'Myanmar',
        MNE: 'Montenegro',
        MNG: 'Mongolia',
        MNP: 'Northern Mariana Islands',
        MOZ: 'Mozambique',
        MRT: 'Mauritania',
        MSR: 'Montserrat',
        MTQ: 'Martinique',
        MUS: 'Mauritius',
        MWI: 'Malawi',
        MYS: 'Malaysia',
        MYT: 'Mayotte',
        NAM: 'Namibia',
        NCL: 'New Caledonia',
        NER: 'Niger',
        NFK: 'Norfolk Island',
        NGA: 'Nigeria',
        NIC: 'Nicaragua',
        NIU: 'Niue',
        NLD: 'Netherlands',
        NOR: 'Norway',
        NPL: 'Nepal',
        NRU: 'Nauru',
        NZL: 'New Zealand',
        OMN: 'Oman',
        PAK: 'Pakistan',
        PAN: 'Panama',
        PCN: 'Pitcairn',
        PER: 'Peru',
        PHL: 'Philippines',
        PLW: 'Palau',
        PNG: 'Papua New Guinea',
        POL: 'Poland',
        PRI: 'Puerto Rico',
        PRK: "Korea (Democratic People's Republic of)",
        PRT: 'Portugal',
        PRY: 'Paraguay',
        PSE: 'Palestine, State of',
        PYF: 'French Polynesia',
        QAT: 'Qatar',
        REU: 'Réunion',
        ROU: 'Romania',
        RUS: 'Russian Federation',
        RWA: 'Rwanda',
        SAU: 'Saudi Arabia',
        SDN: 'Sudan',
        SEN: 'Senegal',
        SGP: 'Singapore',
        SGS: 'South Georgia and the South Sandwich Islands',
        SHN: 'Saint Helena, Ascension and Tristan da Cunha',
        SJM: 'Svalbard and Jan Mayen',
        SLB: 'Solomon Islands',
        SLE: 'Sierra Leone',
        SLV: 'El Salvador',
        SMR: 'San Marino',
        SOM: 'Somalia',
        SPM: 'Saint Pierre and Miquelon',
        SRB: 'Serbia',
        SSD: 'South Sudan',
        STP: 'Sao Tome and Principe',
        SUR: 'Suriname',
        SVK: 'Slovakia',
        SVN: 'Slovenia',
        SWE: 'Sweden',
        SWZ: 'Eswatini',
        SXM: 'Sint Maarten (Dutch part)',
        SYC: 'Seychelles',
        SYR: 'Syrian Arab Republic',
        TCA: 'Turks and Caicos Islands',
        TCD: 'Chad',
        TGO: 'Togo',
        THA: 'Thailand',
        TJK: 'Tajikistan',
        TKL: 'Tokelau',
        TKM: 'Turkmenistan',
        TLS: 'Timor-Leste',
        TON: 'Tonga',
        TTO: 'Trinidad and Tobago',
        TUN: 'Tunisia',
        TUR: 'Turkey',
        TUV: 'Tuvalu',
        TWN: 'Taiwan, Province of China',
        TZA: 'Tanzania, United Republic of',
        UGA: 'Uganda',
        UKR: 'Ukraine',
        UMI: 'United States Minor Outlying Islands',
        URY: 'Uruguay',
        USA: 'United States of America',
        UZB: 'Uzbekistan',
        VAT: 'Holy See',
        VCT: 'Saint Vincent and the Grenadines',
        VEN: 'Venezuela (Bolivarian Republic of)',
        VGB: 'Virgin Islands (British)',
        VIR: 'Virgin Islands (U.S.)',
        VNM: 'Viet Nam',
        VUT: 'Vanuatu',
        WLF: 'Wallis and Futuna',
        WSM: 'Samoa',
        YEM: 'Yemen',
        ZAF: 'South Africa',
        ZMB: 'Zambia',
        ZWE: 'Zimbabwe',
      },
      ACCOUNTMAINTENANCE: 'Account maintenance',
      ACCOUNTOPENINGFEE: 'Application/account opening',
      ATMCASHWITHDRAWALABROAD: 'ATM foreign currency withdrawal abroad',
      STATEMENTCOPYONEOFF: 'Statement copy',
      CARDREPLACEMENT: 'Card replacement',
      OVERDRAFTRENEWAL: 'Overdraft renewal',
      OVERDRAFTSETUP: 'Overdraft set-up',
      AUTOMATEDCREDIT: 'Immediate payment in',
      STOPCHEQUE: 'Stop a cheque',
      REFERENCEDOCUMENT: 'Provide a reference document',
      FOREIGNPOSSALES: 'Debit card payment in foreign currency',
      MINIMUMBALANCEFEE: 'Account minimum balance',
      DIRECTDEBITFEE: 'Direct debit out',
      DIRECTCREDITFEE: 'Direct credit',
      STANDINGORDERFEE: 'Standing order',
      ATMWITHDRAWALDOMESTICFEE: 'ATM domestic currency withdrawal',
      ATMWITHDRAWALFCYDOMESTICFEE: 'ATM foreign currency withdrawal in home country',
      DOMESTICPOSSALESFEE: 'Debit card payment in domestic currency',
      IMMPAYMENTOUTBOUNDFEE: 'Immediate payment out',
      IMMPAYMENTINBOUNDFEE: 'Immediate payment inbound',
      HIGHVALUEPAYINBOUNDFEE: 'High value paying bound',
      HIGHVALUEPAYOUTBOUNDFEE: 'High value out',
      INTLPAYMENTINBOUNDFEE: 'International payment inbound',
      INTLPAYMENTOUTBOUNDFEE: 'International payment outbound',
      SEPADDINFEE: 'SEPA DD Inbound',
      SEPADDOUTFEE: 'SEPA DD outbound',
      SEPASCTINFEE: 'SEPA credit transfer in',
      SEPATSCTOUTFEE: 'SEPA credit transfer out',
      CASHTRANSACTIONFEE: 'Cash transaction',
      CHEQUETRANSACTIONFEE: 'Cheque transaction',
      NSFDECLINEFEE: 'NSF decline',
      NSFACCEPTFEE: 'NSF accept',
      BACSBULKUPLOADFEE: 'BACS bulk upload processing',
      OVERDRAFTFEES: 'Overdraft',
      CASHDEPOSITFEE: 'Cash deposit',
      CASHWITHDRAWALFEE: 'Cash withdrawal',
      CASHWITHDRAWAL: 'Cash withdrawal',
      CHEQUEDEPOSITFEE: 'Cheque deposit',
      CHEQUEWITHDRAWALFEE: 'Cheque withdrawal',
      PENDING: 'Pending',
      CLOSED: 'Closed',
      PERIOD: {
        DAY: 'Daily',
        DAYS: 'Days',
        WEEK: 'Weekly',
        WEEKS: 'Weeks',
        MONTH: 'Monthly',
        MONTHS: 'Months',
        QUARTER: 'Quarterly',
        QUARTERS: 'Quarters',
        YEAR: 'Annually',
        YEARS: 'Years',
      },
      STATEMENTS: {
        ANNUAL_ANNIVERSARY_OF_ACCOUNT: 'Day of account anniversary',
      },
      STATEMENT_CONFIGURATION: {
        NO_TEMPLATE_DESIGN_CHOSEN_NAME: 'No template design chosen',
        NO_TEMPLATE_DESIGN_CHOSEN_DESCRIPTION:
          'Tap the add button on the template thumbnail image to choose a template. A template must be added for the statement to be valid.',
        CHOSEN_TEMPLATE_DESCRIPTION: 'This is the template design for the statement the customers will receive',
        CHANGE_TEMPLATE: 'Change template',
        PRODUCT_SEGMENT_TOOLTIP:
          'Adding a segment here will refine the available options when selecting a statement for a product',
        SELECT_TEMPLATE_PAGE_DESCRIPTION:
          'Templates here are created for you. If you need something different, please raise a Zendesk ticket.',
        STATEMENT_CONFIGURATION: 'Statement configuration',
        STATEMENT_NAME: 'Statement name',
        DESCRIPTION: 'Description',
        SEGMENT: 'Segment',
        STATEMENT_GENERATION: 'Statement generation',
        OFFSET: 'Offset',
        OFFSET_NUMBER: '{{number}} day{{s}}',
      },
      VIEW_FREQUENCY: {
        ACCRUAL_PERIOD_HELP:
          'The accrual period refers to how often it accrues. Example: if you choose daily, it is calculated on the account balance every day.',
        ANNUAL_ANNIVERSARY_OF_ACCOUNT: 'Annually : Day of opening',
        ANNUAL: 'Annually : {{param1}} : {{param2}}',
        MONTH_END: 'Monthly : End',
        MONTH_BEGINNING: 'Monthly : Beginning',
        MONTH_ANNIVERSARY: 'Monthly : Day of opening',
        MONTH: 'Monthly : Specific day : {{param1}}',
        TRIGGERED_DAILY: 'Daily',
        DAYS_FROM_CALCULATION: 'Days from calculation : {{param1}}',
        PER_OCCURRENCE: 'Instantly per occurrence',
        DAILY: 'Daily',
        WEEKLY_MON: 'Weekly : Monday',
        WEEKLY_TUE: 'Weekly : Tuesday',
        WEEKLY_WED: 'Weekly : Wednesday',
        WEEKLY_THU: 'Weekly : Thursday',
        WEEKLY_FRI: 'Weekly : Friday',
        WEEKLY_SAT: 'Weekly : Saturday',
        WEEKLY_SUN: 'Weekly : Sunday',
        FORTNIGHTLY: 'Fortnightly',
        MONTHLY: 'Monthly',
        QUARTERLY: 'Quarterly',
        ANNUALLY: 'Annually',
        WEEKLY: 'Weekly',
      },
      AIIN: 'Account identification number (account number & bank code)',
      AIIN_Tooltip:
        'This is for identifying the account and the bank/branch. Bank/branch identifiers vary between countries, in the UK it’s the sort code, in Australia it’s the BSB and in the US it’s routing number.',
      IBAN: 'IBAN (International Bank Account Number)',
      BBAN: 'BBAN (Basic Bank Account Number)',
      CPAN: 'CPAN (16 digit debit card Primary Account Number)',

      expandable: {
        actions: {
          delete: 'Submit the form after selecting delete',
        },
      },
      MR: 'Mr.',
      MRS: 'Mrs.',
      MS: 'Ms.',
      MX: 'Mx.',
      MISS: 'Miss',
      DR: 'Dr.',
      CREATOR: 'Creator',
      APPROVER: 'Approver',
      ADMIN: 'Admin',
      CURRENCIES: {
        AED: 'United Arab Emirates Dirham',
        AFN: 'Afghanistan Afghani',
        ALL: 'Albania Lek',
        AMD: 'Armenia Dram',
        ANG: 'Netherlands Antilles Guilder',
        AOA: 'Angola Kwanza',
        ARS: 'Argentina Peso',
        AUD: 'Australia Dollar',
        AWG: 'Aruba Guilder',
        AZN: 'Azerbaijan New Manat',
        BAM: 'Bosnia and Herzegovina Convertible Marka',
        BBD: 'Barbados Dollar',
        BDT: 'Bangladesh Taka',
        BGN: 'Bulgaria Lev',
        BHD: 'Bahrain Dinar',
        BIF: 'Burundi Franc',
        BMD: 'Bermuda Dollar',
        BND: 'Brunei Darussalam Dollar',
        BOB: 'Bolivia Bolíviano',
        BRL: 'Brazil Real',
        BSD: 'Bahamas Dollar',
        BTN: 'Bhutan Ngultrum',
        BWP: 'Botswana Pula',
        BYR: 'Belarus Ruble',
        BZD: 'Belize Dollar',
        CAD: 'Canada Dollar',
        CDF: 'Congo/Kinshasa Franc',
        CHF: 'Switzerland Franc',
        CLP: 'Chile Peso',
        CNY: 'China Yuan Renminbi',
        COP: 'Colombia Peso',
        CRC: 'Costa Rica Colon',
        CUC: 'Cuba Convertible Peso',
        CUP: 'Cuba Peso',
        CVE: 'Cape Verde Escudo',
        CZK: 'Czech Republic Koruna',
        DJF: 'Djibouti Franc',
        DKK: 'Denmark Krone',
        DOP: 'Dominican Republic Peso',
        DZD: 'Algeria Dinar',
        EGP: 'Egypt Pound',
        ERN: 'Eritrea Nakfa',
        ETB: 'Ethiopia Birr',
        EUR: 'Euro Member Countries',
        FJD: 'Fiji Dollar',
        FKP: 'Falkland Islands (Malvinas) Pound',
        GBP: 'British Pound Sterling',
        GEL: 'Georgia Lari',
        GGP: 'Guernsey Pound',
        GHS: 'Ghana Cedi',
        GIP: 'Gibraltar Pound',
        GMD: 'Gambia Dalasi',
        GNF: 'Guinea Franc',
        GTQ: 'Guatemala Quetzal',
        GYD: 'Guyana Dollar',
        HKD: 'Hong Kong Dollar',
        HNL: 'Honduras Lempira',
        HRK: 'Croatia Kuna',
        HTG: 'Haiti Gourde',
        HUF: 'Hungary Forint',
        IDR: 'Indonesia Rupiah',
        ILS: 'Israel Shekel',
        IMP: 'Isle of Man Pound',
        INR: 'India Rupee',
        IQD: 'Iraq Dinar',
        IRR: 'Iran Rial',
        ISK: 'Iceland Krona',
        JEP: 'Jersey Pound',
        JMD: 'Jamaica Dollar',
        JOD: 'Jordan Dinar',
        JPY: 'Japan Yen',
        KES: 'Kenya Shilling',
        KGS: 'Kyrgyzstan Som',
        KHR: 'Cambodia Riel',
        KMF: 'Comoros Franc',
        KPW: 'Korea (North) Won',
        KRW: 'Korea (South) Won',
        KWD: 'Kuwait Dinar',
        KYD: 'Cayman Islands Dollar',
        KZT: 'Kazakhstan Tenge',
        LAK: 'Laos Kip',
        LBP: 'Lebanon Pound',
        LKR: 'Sri Lanka Rupee',
        LRD: 'Liberia Dollar',
        LSL: 'Lesotho Loti',
        LYD: 'Libya Dinar',
        MAD: 'Morocco Dirham',
        MDL: 'Moldova Leu',
        MGA: 'Madagascar Ariary',
        MKD: 'Macedonia Denar',
        MMK: 'Myanmar (Burma) Kyat',
        MNT: 'Mongolia Tughrik',
        MOP: 'Macau Pataca',
        MRO: 'Mauritania Ouguiya',
        USA: 'United States of America',
        MUR: 'Mauritius Rupee',
        MVR: 'Maldives (Maldive Islands) Rufiyaa',
        MWK: 'Malawi Kwacha',
        MXN: 'Mexico Peso',
        MYR: 'Malaysia Ringgit',
        MZN: 'Mozambique Metical',
        NAD: 'Namibia Dollar',
        NGN: 'Nigeria Naira',
        NIO: 'Nicaragua Cordoba',
        NOK: 'Norway Krone',
        NPR: 'Nepal Rupee',
        NZD: 'New Zealand Dollar',
        OMR: 'Oman Rial',
        PAB: 'Panama Balboa',
        PEN: 'Peru Sol',
        PGK: 'Papua New Guinea Kina',
        PHP: 'Philippines Peso',
        PKR: 'Pakistan Rupee',
        PLN: 'Poland Zloty',
        PYG: 'Paraguay Guarani',
        QAR: 'Qatar Riyal',
        RON: 'Romania New Leu',
        RSD: 'Serbia Dinar',
        RUB: 'Russia Ruble',
        RWF: 'Rwanda Franc',
        SAR: 'Saudi Arabia Riyal',
        SBD: 'Solomon Islands Dollar',
        SCR: 'Seychelles Rupee',
        SDG: 'Sudan Pound',
        SEK: 'Sweden Krona',
        SGD: 'Singapore Dollar',
        SHP: 'Saint Helena Pound',
        SLL: 'Sierra Leone Leone',
        SOS: 'Somalia Shilling',
        SPL: 'Seborga Luigino',
        SRD: 'Suriname Dollar',
        STD: 'São Tomé and Príncipe Dobra',
        SVC: 'El Salvador Colon',
        SYP: 'Syria Pound',
        SZL: 'Swaziland Lilangeni',
        THB: 'Thailand Baht',
        TJS: 'Tajikistan Somoni',
        TMT: 'Turkmenistan Manat',
        TND: 'Tunisia Dinar',
        TOP: "Tonga Pa'anga",
        TRY: 'Turkey Lira',
        TTD: 'Trinidad and Tobago Dollar',
        TVD: 'Tuvalu Dollar',
        TWD: 'Taiwan New Dollar',
        TZS: 'Tanzania Shilling',
        UAH: 'Ukraine Hryvnia',
        UGX: 'Uganda Shilling',
        USD: 'United States Dollar',
        UYU: 'Uruguay Peso',
        UZS: 'Uzbekistan Som',
        VEF: 'Venezuela Bolivar',
        VND: 'Viet Nam Dong',
        VUV: 'Vanuatu Vatu',
        WST: 'Samoa Tala',
        XAF: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC',
        XCD: 'East Caribbean Dollar',
        XDR: 'International Monetary Fund (IMF) Special Drawing Rights',
        XOF: 'Communauté Financière Africaine (BCEAO) Franc',
        XPF: 'Comptoirs Français du Pacifique (CFP) Franc',
        YER: 'Yemen Rial',
        ZAR: 'South Africa Rand',
        ZMW: 'Zambia Kwacha',
        ZWD: 'Zimbabwe Dollar',
      },
      PROCESS_ENTITY_LIFECYCLE: {
        CREATE: 'For creating a product',
        UPDATE: 'For changing an existing product',
      },
      GOVERNANCE: { create: 'Create', update: 'Update' },
      DEBIT_INTEREST: {
        LOAN: {
          SETUP_DESCRIPTION:
            'Here you can define the rate of interest a customer will pay, how it is calculated and when it is charged',
        },
        CURRENT_SAVINGS: {
          SETUP_DESCRIPTION:
            'Here you can configure debit interest for your product. There are three interest types: Unauthorised, Within limit, Beyond limit. The min and max borrowing amount should be set separately in the Credit limit feature.',
        },
        SETUP_HEADER: 'Debit interest',
        RATE_DESCRIPTION: "This is the rate of interest which will be charged on the customer's entire debit balance",
        commonConfig: 'Debit interest common config',
        rateWithinLimit: 'Authorised borrowing within limit',
        rateWithinLimit_help:
          'Authorised borrowing (within customer limit) is also known as ‘arranged’ or ‘planned’ borrowing and defines the interest a customer will pay on debit balances within an agreed borrowing limit',
        rateAboveLimit: 'Unauthorised borrowing',
        rateAboveLimit_help:
          'Unauthorised borrowing is also known as ‘unarranged’ borrowing and defines the interest a customer will pay on debit balances where this isn’t agreed borrowing',
        rateAboveLimitOverride: 'Authorised borrowing beyond limit',
        rateAboveLimitOverride_help:
          'Authorised borrowing (beyond customer limit) define the interest a customer will pay on debit balances when they have exceeded their authorised borrowing limit.',
      },
      productLimits: 'Product Limit',
      subscriptionLimit: 'Subscription Limit',
      DELETEPRODUCT: 'Deleting a product will delete it forever. You cannot undo this deletion.',
      RETIREPRODUCT:
        'Retiring a product will make it no longer available to new customers. Once retired, you will need to migrate your customers onto a new product if you want to delete it.',
      DELETEPACKAGE: 'Deleting a package will delete it forever. You cannot undo this deletion.',
      legalStructureEligibility: 'Legal structure',
      CIO: 'Cio',
      CLUB_SOCIETY: 'Club society',
      CHARITY: 'Charity',
      LTD: 'Ltd',
      LBG: 'Lbg',
      LLP: 'Llp',
      PARTNERSHIP: 'Partnership',
      SOLE_TRADER: 'Sole trader',
      TRUST: 'Trust',
      PUBLIC_COMPANY: 'Public company',
      CORPORATION: 'Corporation',
      LIMITED_PARTNERSHIP: 'Limited partnership',
      LLC: 'Llc',
      LIMITED_LIABILITY_COMPANY: 'Limited liability company',
      officerEligibility: 'Officer',
      DIRECTOR: 'Director',
      PARTNER: 'Partner',
      ONE_FOR_EACH: 'One for each',
      taxResidencyEligibility: 'Tax residency',
      taxIdentificationEligibility: 'Tax ID number',
      'GREET morning': 'Good morning {{param1}}',
      'GREET afternoon': 'Good afternoon {{param1}}',
      ALWAYS: 'Always charge',
      ONLY: 'Only charge under certain conditions',
      CAPAMOUNT: 'Cap the amount',
      CAPOCCURANCE: 'Cap number of occurrences',
      eventTriggeredFeesAndCharges: 'Event triggered',
      scheduledPaymentsFeesAndCharges: 'Scheduled payments',
      scheduledAccountManagementFeesAndCharges: 'Scheduled account management',
      indexRates: 'Index rates',
      INDEPENDENT_INDEXED_RATE: 'Independent of index rate',
      INDEX_RATE_PLUS_STATIC_MARGIN: 'Index rate + static margin = variable rate to customer',
      INDEX_RATE_PLUS_VARIABLE_MARGIN: 'Index rate + variable margin = static rate to customer',
      CREDITANDDEBIT: 'Credit and debit',
      PARTIALLY_CLEARED: 'Partially cleared',
      CLEARED: 'Cleared',
      CONTRA_CLEARED: 'Contra cleared',
      CONTRA_TRANSFER: 'Contra transfer',
      CONTRA_WRITE_OFF: 'Contra write-off',
      CONTRA_WRITE_BACK: 'Contra write-back',
      CONTRA_WRITE_BACK_REVERSED: 'Contra write-back reversed',
      CONTRA_WRITE_OFF_REVERSED: 'Contra write-off reversed',
      ALL: 'All',
      RESULTS: 'Your search found {{param}} transactions',
      SEARCHRESULTS: '{{param}} Result found',
      SEARCHRESULTS_plural: '{{param}} Results found',
      SUSPENSEACCOUNTS: 'SUSPENSE ACCOUNTS',
      SUBTRACT: '-',
      ADD: '+',
      PHYSICAL_LABEL: 'Physical card',
      VIRTUAL_LABEL: 'Virtual card',
      NEW_TERMANDCONDITION_LABEL: 'New document (from URL)',
      EXISTING_TERMANDCONDITION_LABEL: 'Existing document (from AEM)',
      TIN_AVAILABLE: 'Party tax ID number held',
      TAX_RESIDENCY_APPLIED: 'Tax ID held for product',
      EXEMPTION_REASON: 'Exemption reason',
      NATIONALITY: 'Nationality',
      COUNTRY_OF_RESIDENCE: 'Country of tax residency',
      INTEREST_EARNED_IN_A_PERIOD: 'Interest earned in a period',
      PROJECTED_ANNUAL_INCOME: 'Projected annual interest income',
      LESSER_THAN: 'lesser than',
      EQUAL_OR_GREATER_THAN: 'equal or greater than',
      EQUAL_OR_LESSER_THAN: 'equal or lesser than',
      ANY_OF: 'any of',
      NOT_ANY_OF: 'not any of',
      IS: 'is',
      PHYSICAL: 'Physical',
      VIRTUAL: 'Virtual',
      BM_ADMIN: 'Administrator',
      BM_PRODUCT_CREATOR: 'Product creator',
      BM_ACCOUNTS_CREATOR: 'Account creator',
      CLOSURE_PENDING: 'Closure Pending',
      'Effective date': 'Effective date',
      'Product key': 'Product key',
      paymentSchedule: 'Amount and payment schedule',
      paymentLimits: 'Payment limits',
      cardLimits: 'Card transaction limits',
      DAYS: 'Days',
      WEEKS: 'Weeks',
      MONTHS: 'Months',
      YEARS: 'Years',
      STRAIGHT_LINE: 'Straight line',
      DECLINING_BALANCE_EQUAL: 'Declining balance with equal instalments',
      NO_ADJUSTMENT: 'No adjustment',
      ADJUST_LAST_INTEREST: 'Adjust last interest',
      ADJUST_LAST_REPAYMENT: 'Adjust last repayment',
      EXACT: 'Exact',
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
      DEFAULT_TRANSACTION: 'Default settings successfully submitted',
      INBOUND_TRANSACTION: 'Inbound exception  successfully submitted',
      OUTBOUND_TRANSACTION: 'Outbound exception successfully submitted',
      minMaxCreditLimit: 'Credit limit',
      FEATURE_TEASER_ITEM_COUNT: '+ {{remainingItemsCount}} more',
    },
  },
}

export default translations
