import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import Governance from './Governance'
import { CreateProcessView } from './CreateProcess/CreateProcessDialog.page'
import { RootState } from 'store'
import GovernanceActions from 'store/governance/actions'
import GovernanceSelectors from 'store/governance/selectors'
import { Loader } from 'components/'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProcess: () => dispatch(GovernanceActions.getProcess()),
})

const mapStateToProps = (state: RootState) => ({
  processes: GovernanceSelectors.selectAllProcess(state),
  loading: GovernanceSelectors.selectIsLoading(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const GovernancePage = ({ loading, processes, getProcess }: Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [view] = useState<CreateProcessView>(CreateProcessView.CreateProcessForm)

  useBreadcrumbs({ breadcrumbs: [{ title: t('Governance'), path: '' }], trigger: true })

  useEffect(() => {
    getProcess()
  }, [getProcess])

  if (loading) {
    return <Loader />
  }

  return <Governance open={open} setOpen={setOpen} view={view} process={processes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(GovernancePage)
