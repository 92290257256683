import { TFunction } from 'i18next'
import getStaticDefinitions from 'pages/features/CreditInterest/utils/getStaticDefinitions'
import getIndependentDefinitions from 'pages/features/CreditInterest/utils/getIndependentDefinitions'
import getVariableDefinitions from 'pages/features/CreditInterest/utils/getVariableDefinitions'
import { CreditInterest, TypeOfIndexRate } from 'store/products/types'
import { BankInterestRate } from 'store/referenceData/types'

const getTypeOfIndexRateDefinitions = (
  typeOfIndexRate: TypeOfIndexRate,
  creditInterest: CreditInterest,
  bankInterestRates: BankInterestRate[],
  currencyOfProduct: string | null,
  t: TFunction
) => {
  if (typeOfIndexRate === TypeOfIndexRate.INDEPENDENT_INDEXED_RATE) {
    return getIndependentDefinitions(creditInterest, currencyOfProduct, t)
  }
  if (typeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN) {
    return getStaticDefinitions(creditInterest, currencyOfProduct, bankInterestRates, t)
  }
  if (typeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN) {
    return getVariableDefinitions(creditInterest, currencyOfProduct, bankInterestRates, t)
  }
  return []
}

export default getTypeOfIndexRateDefinitions
