import React from 'react'
import { useParams } from 'react-router-dom'
import Package from './Package'
import useSelectedPackage from './hooks/useSelectedPackage'
import { Loader } from 'components/'

function PackagePage() {
  const { productPackageId } = useParams()
  if (!productPackageId) throw new Error('productPackageId not found')
  const { selectedPackage, isLoading, availableGroupedProducts } = useSelectedPackage(productPackageId)

  if (isLoading || !selectedPackage) {
    return <Loader />
  }

  return <Package selectedPackage={selectedPackage} availableProducts={availableGroupedProducts} />
}

export default PackagePage
