import { useDispatch, useSelector } from 'react-redux'
import { selectConfig } from 'store/tenant-config/selectors'
import { Feature, ProductKey } from 'store/products/types'
import { CreditLimitFormValues } from 'store/products/typings/creditLimit'
import ProductsActions from 'store/products/actions'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'

const useCreditLimit = ({ productKey, version }: { productKey: ProductKey; version: number }) => {
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectConfig)

  const submitCreditLimit = (values: CreditLimitFormValues) => {
    dispatch(
      ProductsActions.updateCreditLimit({
        ...values,
        productKey,
        version,
      })
    )
  }

  const deleteCreditLimit = () => {
    dispatch(ProductsActions.deleteCreditLimit({ productKey, version }))
  }

  return {
    isNotAllowed: !isFeatureAllowedByConfig(tenantConfig, Feature.CREDIT_LIMIT),
    submitCreditLimit,
    deleteCreditLimit,
  }
}

export default useCreditLimit
