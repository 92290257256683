import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.getVersions.type]: (state: ProductsState) => ({
    ...state,
    productVersionsLoading: true,
    productVersionsFail: false,
    versions: [],
  }),
  [actions.getVersionsSuccess.type]: (state: ProductsState, action: ReturnType<typeof actions.getVersionsSuccess>) => ({
    ...state,
    productVersionsLoading: false,
    productVersionsFail: false,
    versions: action.payload,
  }),
  [actions.getVersionsFail.type]: (state: ProductsState, action: ReturnType<typeof actions.getVersionsFail>) => ({
    ...state,
    productVersionsLoading: false,
    productVersionsFail: true,
    error: action.payload,
    versions: [],
  }),
  [actions.getVersionsForPublish.type]: (state: ProductsState) => ({
    ...state,
    getVersionsLoading: true,
    getVersionsFail: false,
    versions: [],
  }),
  [actions.getVersionsSuccessForPublish.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.getVersionsSuccessForPublish>
  ) => ({
    ...state,
    getVersionsLoading: false,
    getVersionsFail: false,
    versions: action.payload,
  }),
  [actions.getVersionsFailForPublish.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.getVersionsFailForPublish>
  ) => ({
    ...state,
    getVersionsLoading: false,
    getVersionsFail: true,
    error: action.payload,
    versions: [],
  }),
  [actions.clearGetVersionsFailForPublish.type]: (state: ProductsState) => ({
    ...state,
    getVersionsFail: false,
    productVersionsFail: false,
  }),

  [actions.createNewProductVersion.type]: (state: ProductsState) => ({
    ...state,
    createNewProductVersionLoading: true,
    createNewProductVersionFail: false,
    createNewProductSuccess: false,
  }),
  [actions.createNewProductVersionSuccess.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.createNewProductVersionSuccess>
  ) => ({
    ...state,
    createNewProductVersionLoading: false,
    createNewProductVersionFail: false,
    selectedProduct: action.payload,
    createNewProductSuccess: true,
  }),
  [actions.createNewProductVersionFail.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.getVersionsSuccess>
  ) => ({
    ...state,
    createNewProductVersionLoading: false,
    createNewProductVersionFail: true,
    error: action.payload,
    createNewProductSuccess: false,
  }),
  [actions.createNewProductVersionClearSuccess.type]: (state: ProductsState) => ({
    ...state,
    createNewProductSuccess: false,
  }),
  [actions.createNewProductVersionClearErrors.type]: (state: ProductsState) => ({
    ...state,
    createNewProductVersionFail: false,
  }),
}
export default reducer
