import React from 'react'
import { Grid } from '@material-ui/core'
import { connect as connectFormik, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import SelectFieldComplex from '../SelectFieldComplex'
import { WeeklyFrequencyItems, TopLevelFrequency } from 'store/products/types'
import { Yip } from 'utils/form.utils'

const NestedWeeklyFields = connectFormik<NestedFieldsProps>(({ rootName, disable }) => {
  const { t } = useTranslation()

  return (
    <Grid item xs={4}>
      <Field
        label={t(`${TopLevelFrequency.WEEKLY}.secondary.label`)}
        name={`${rootName}.${TopLevelFrequency.WEEKLY}.secondary`}
        validate={Yip(Yup.string().required())}
        required
        data={WeeklyFrequencyItems}
        component={SelectFieldComplex}
        displayEmpty
        fullWidth
        disabled={disable}
      />
    </Grid>
  )
})

export default NestedWeeklyFields
