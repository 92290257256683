import { ExtendedProduct, UpdateProductFormValues } from 'store/products/types'
import { setTaxonomy } from 'api/utils'

export const toProductCoreFormValues = (product: ExtendedProduct): UpdateProductFormValues => ({
  productName: product.productName,
  // productType: product.productType,
  // productSegment: product.productSegment,
  // productLine: product.productLine,
  // productGroup: product.productGroup,
  productTaxonomy: setTaxonomy(product),
  productDescription: product.productDescription,
})
