import React from 'react'
import { compact } from 'lodash'
import ReviewDefinition from './ReviewDefinition'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'
import Line from 'dls/atoms/Line'

interface OwnProps {
  definitionsList: Array<ReviewRowGroup>
}

const ReviewRows = ({ definitionsList }: OwnProps) => (
  <>
    {compact(definitionsList).map(({ definitions, groupSeparator, ariaLabelledBy, heading }, idx) => (
      <React.Fragment key={`definition-item-${definitions.length}-${idx}`}>
        {heading}
        <ReviewDefinition definitionList={definitions} ariaLabelledBy={ariaLabelledBy} />
        {groupSeparator && (
          <Line type={groupSeparator} customStyles={{ marginTop: 24, marginBottom: 24, width: '100%' }} />
        )}
      </React.Fragment>
    ))}
  </>
)

export default ReviewRows
