import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { LoginForm, LoginFormGrid, LoginImageView, Logo, StyledErrorPage, Title } from './styles'
import { LoginImage, tenxLogoImage } from 'assets'
import { UnavailablePage } from 'pages/index'

type Props = {
  isPageStatusError?: boolean
  loginError?: Error
  children: React.ReactNode
}

const LoginLayout = ({ isPageStatusError, loginError, children }: Props): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      {!isPageStatusError && loginError === undefined ? (
        <>
          <Logo src={tenxLogoImage} alt={t('10x logo')} />
          <Grid container alignItems="center" justifyContent="center">
            <LoginImageView item xs={12} sm={12} md={12} lg={8}>
              <img src={LoginImage} alt={t('Illustration showing colleagues configuring Bank Manager')} />
            </LoginImageView>
            <LoginFormGrid item xs={12} sm={12} md={12} lg={4}>
              <LoginForm>
                <Title variant="h1" variantMapping={{ h5: 'h1' }}>
                  {t('Bank Manager log in')}
                </Title>
                <Typography variant="body1" style={{ marginBottom: '40px' }}>
                  {t('Configure and manage your bank & products using our simple intuitive web-app')}
                </Typography>
                <Grid container alignItems="center">
                  {children}
                </Grid>
              </LoginForm>
            </LoginFormGrid>
          </Grid>
        </>
      ) : (
        <StyledErrorPage>
          <UnavailablePage
            title={t('Oops, something went wrong')}
            subtitle={t('Please return to the log in screen and try again')}
            buttonText="Back to log in"
            from="/sso-login"
            hideButtonIcon
          />
        </StyledErrorPage>
      )}
    </>
  )
}

export default LoginLayout
