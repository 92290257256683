import { TableBody } from '@material-ui/core'
import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import { groupBy, orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ConditionalTableCell } from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import { Product, ProductVersion } from 'store/products/types'
import { VersionStatusBadge } from 'components'
import { orderProductSecondCondition } from 'utils/productVersion.utils'

export const getProductVersions = (versions: Product[]) => {
  const list: Product[] = []
  if (versions) {
    const [latestVersion, ...orderByVersionAndStatusProduct] = orderBy(
      versions,
      ['version', orderProductSecondCondition],
      ['asc', 'asc']
    ).reverse()
    if (latestVersion && latestVersion.version === 1) {
      list.push(latestVersion)
    } else {
      const groupByVersion = groupBy([latestVersion, ...orderByVersionAndStatusProduct], (i) => i.version)
      // filtered by the latest status of each version
      const slicedVersions = orderBy(
        Object.keys(groupByVersion).map((i) => groupByVersion[i][0]),
        ['version', orderProductSecondCondition],
        ['asc', 'asc']
      ).reverse()
      list.push(...slicedVersions)
    }
  }
  return list
}

export default ({ products }: { products: ProductVersion[] }) => {
  const { t } = useTranslation()
  return (
    <TableBody>
      {products.length > 0 &&
        products
          .slice()
          .sort((productA, productB) => {
            if (!productA.versions || !productB.versions) return 0
            if (productA.versions?.length === 0 || productB.versions?.length === 0) return 0
            if (productA.versions[0].productName > productB.versions[0].productName) {
              return 1
            }
            return -1
          })
          .map((product, productIndex) => {
            const productVersions = getProductVersions(product.versions || [])
            const shouldShowName = productVersions.length > 1

            return (
              <React.Fragment key={product.productKey}>
                <TableRow>
                  <ConditionalTableCell
                    rowSpan={productVersions.length + 1}
                    showBorder={productIndex !== products.length - 1}
                    $topPaddingThick
                  >
                    {productVersions[0].productName} {shouldShowName && `(${t('latest name')})`}
                  </ConditionalTableCell>
                </TableRow>
                {productVersions.map((item, index) => {
                  const showBorder = index === productVersions.length - 1 && productIndex !== products.length - 1
                  return (
                    <TableRow key={`${item.version}${index}`}>
                      <ConditionalTableCell
                        showBorder={showBorder}
                        $topPaddingThick={index === 0}
                        $bottomPaddingThick={index === productVersions.length - 1}
                      >
                        <VersionStatusBadge
                          status={item.status}
                          date={item.effectiveDate}
                          version={item.version}
                          name={shouldShowName ? item.productName : undefined}
                        />
                      </ConditionalTableCell>
                    </TableRow>
                  )
                })}
              </React.Fragment>
            )
          })}
    </TableBody>
  )
}
