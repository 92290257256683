import React from 'react'
import styled from 'styled-components/macro'
import { Paper, Typography, Grid } from '@material-ui/core'
import { kebabCase } from 'lodash'
import { Variant } from '@material-ui/core/styles/createTypography'
import ContentContainer from 'pages/Layout/ContentContainer'

interface Props {
  title?: string
  description?: string
  descriptionContent?: React.ReactNode
  topRightHeaderContent?: React.ReactNode
  children?: React.ReactNode
  noPaper?: boolean
  headerStyle?: string
  titleVariant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | 'srOnly'
  titleVariantMapping?: Partial<Record<Variant, string>>
}

export const FormPaper = styled(Paper)`
  padding: 24px 0 0;
  margin-top: 16px;
  margin-bottom: 24px;
`

export const FormNoPaper = styled.div`
  padding: 0;
  margin-top: 16px;
  margin-bottom: 24px;
`

const Header = styled.header`
  positiion: relative;
  margin: 0 24px 18px;
`

const TopRightContainer = styled.div`
  position: relative;
  top: -48px;
`

const FormContainer = (props: Props): React.ReactElement => {
  const {
    title,
    description,
    descriptionContent,
    topRightHeaderContent,
    children = {},
    noPaper = false,
    headerStyle,
    titleVariant = 'h1',
    titleVariantMapping = undefined,
  } = props

  const header = (
    <Header
      css={`
        ${headerStyle}
      `}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        {title && (
          <Grid item>
            <Typography variant={titleVariant} gutterBottom variantMapping={titleVariantMapping}>
              {title}
            </Typography>
          </Grid>
        )}
        {topRightHeaderContent && (
          <Grid item>
            <TopRightContainer>{topRightHeaderContent}</TopRightContainer>
          </Grid>
        )}
      </Grid>
      {description && (
        <Typography variant="body2" variantMapping={{ body2: 'p' }} gutterBottom>
          {description}
        </Typography>
      )}
      {descriptionContent}
    </Header>
  )
  return (
    <ContentContainer data-test-id={kebabCase(`${title}-form-container`)}>
      {!noPaper ? (
        <FormPaper elevation={1}>
          {(title || description || descriptionContent) && header}
          {children}
        </FormPaper>
      ) : (
        <>
          {header}
          <FormNoPaper>{children}</FormNoPaper>
        </>
      )}
    </ContentContainer>
  )
}

export default FormContainer
