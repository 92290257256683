import React from 'react'
import styled from 'styled-components/macro'
import FormHelperTextMui, { FormHelperTextProps } from '@material-ui/core/FormHelperText'
import { useTranslation } from 'react-i18next'
import { Error } from '@material-ui/icons'

const StyledFormHelperText: any = styled(FormHelperTextMui)`
  color: ${(props: any) => `${props.theme.palette.text.secondary}`};
  & .Mui-error {
    color: ${(props: any) => `${props.theme.palette.error.dark}`};
  }

  & .Mui-disabled {
    color: ${(props: any) => `${props.theme.palette.text.disabled}`};
  }
  word-break: break-word;
`

export interface FormHelperTextCustomProps extends FormHelperTextProps {
  id?: string
  helperText?: React.ReactNode
  errorText?: React.ReactNode
  ariaProps?: Record<string, string | boolean>
}

const FormHelperText = ({ helperText, errorText, ariaProps, ...props }: FormHelperTextCustomProps) => {
  const { t } = useTranslation()

  if (!(helperText || (props.error && errorText))) {
    return null
  }
  return (
    <StyledFormHelperText data-dls="atom-formhelpertext" {...props} {...ariaProps}>
      {errorText && (
        <>
          <Error
            style={{
              verticalAlign: 'top',
              marginRight: 7,
              fontSize: 18,
              marginTop: -1,
            }}
            aria-label={t('Error')}
          />
          {typeof errorText === 'string' ? t(errorText) : errorText}
        </>
      )}
      {helperText}
    </StyledFormHelperText>
  )
}

export default FormHelperText
