import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import find from 'lodash/find'
import { useParams } from 'react-router-dom'
import TaxRuleHistory from './TaxRuleHistory'
import TaxActions from 'store/withholdingTax/actions'
import TaxSelectors from 'store/withholdingTax/selectors'
import { Loader } from 'components'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export function TaxRuleHistoryPage() {
  const { t } = useTranslation()
  const { ruleId } = useParams()
  const dispatch = useDispatch()

  const isLoading = useSelector(TaxSelectors.isLoading)
  const rules = useSelector(TaxSelectors.taxRules)

  const getTaxRules = () => dispatch(TaxActions.getAllTaxRules())

  const currentTaxRule = find(rules, ['ruleId', ruleId])

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Rules for withholding tax'), path: 'reference-data/withholding-tax' },
      { title: `${currentTaxRule?.name}`, path: `reference-data/withholding-tax/${currentTaxRule?.ruleId}` },
      { title: t('Historic rates'), path: '' },
    ],
    trigger: true,
  })

  useEffect(() => {
    getTaxRules()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (!rules || !currentTaxRule) return <span />

  return <TaxRuleHistory currentTaxRule={currentTaxRule} />
}

export default TaxRuleHistoryPage
