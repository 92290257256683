export enum FieldType {
  Number,
  Text,
  Countries,
  Amount,
  TIN,
  ExceptionReasons,
}

export const RuleType = {
  AGE: {
    label: 'Age',
    value: 'AGE',
    operators: ['EQUAL_OR_GREATER_THAN', 'EQUAL_OR_LESSER_THAN', 'GREATER_THAN', 'LESSER_THAN'],
    type: FieldType.Number,
  },
  COUNTRY_OF_RESIDENCE: {
    label: 'Country of tax residency',
    value: 'COUNTRY_OF_RESIDENCE',
    operators: ['ANY_OF', 'NOT_ANY_OF'],
    type: FieldType.Countries,
  },
  EXEMPTION_REASON: {
    label: 'Exemption reason',
    value: 'EXEMPTION_REASON',
    operators: ['ANY_OF', 'NOT_ANY_OF'],
    type: FieldType.ExceptionReasons,
  },
  NATIONALITY: {
    label: 'Nationality',
    value: 'NATIONALITY',
    operators: ['ANY_OF', 'NOT_ANY_OF'],
    type: FieldType.Countries,
  },
  PROJECTED_ANNUAL_INCOME: {
    label: 'Projected annual interest income',
    value: 'PROJECTED_ANNUAL_INCOME',
    operators: ['EQUAL_OR_GREATER_THAN', 'EQUAL_OR_LESSER_THAN', 'GREATER_THAN', 'LESSER_THAN'],
    type: FieldType.Amount,
  },
  TIN_AVAILABLE: {
    label: 'Party tax ID number held',
    value: 'TIN_AVAILABLE',
    operators: ['IS'],
    type: FieldType.TIN,
  },
  TAX_RESIDENCY_APPLIED: {
    label: 'Tax ID held for product',
    value: 'TAX_RESIDENCY_APPLIED',
    operators: ['IS'],
    type: FieldType.TIN,
  },
  INTEREST_EARNED_IN_A_PERIOD: {
    label: 'Total interest earnt',
    value: 'INTEREST_EARNED_IN_A_PERIOD',
    operators: ['EQUAL_OR_GREATER_THAN', 'EQUAL_OR_LESSER_THAN', 'GREATER_THAN', 'LESSER_THAN'],
    type: FieldType.Amount,
  },
}

export const operators = {
  EQUAL_OR_GREATER_THAN: 'Equal or greater than',
  EQUAL_OR_LESSER_THAN: 'Equal or less than',
  GREATER_THAN: 'Greater than',
  LESSER_THAN: 'Less than',
  ANY_OF: 'Any of',
  NOT_ANY_OF: 'Not any of',
  IS: 'Equal to',
}

export const thenOptions = [
  { name: true, label: 'Withhold tax' },
  // { name: false, label: "Don't withhold tax" },
  // @TODO: Add it back when DEV-240732 is resolved
]

export const tinOptions = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
]

// eslint-disable @typescript-eslint/camelcase
export const exceptionReasons = {
  CENTRELINK_PENSIONER: { label: 'Centrelink pensioner', key: 'Centrelink pensioner' },
  OTHER_ELIGIBLE_PENSIONER: { label: 'Other eligible pensioner', key: 'Other eligible pensioner' },
  TFN_NOT_QUOTED: { label: 'TIN not held by bank', key: 'TIN not held by bank' },
  UNDER_AGE_16: { label: 'Under 16 years old', key: 'Under 16 years old' },
}
