import { DialogActions, Divider } from '@material-ui/core'
import styled from 'styled-components/macro'

export const FormBottom = styled.div`
  padding-top: 16px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 6px;
`

export const TooltipStyle = styled.div`
  margin-top: 16px;
`

export const StyledDialogActions = styled(DialogActions)`
padding-top: 20px;
padding-right 0px;
`
export const Header = styled.div`
  padding-bottom: 0px;
`

export const ListSeparatorDivider = styled(Divider)`
  height: 1px;
  margin-top: 11px;
  margin-bottom: 2px;
  background-color: ${(props: any) => `${props.theme.palette.text.disabled}`};
`
