import { put } from 'redux-saga/effects'
import { navigate } from 'store/router/actions'

const LOGIN_URL = '/login'
const UNAVAILABLE_URL = '/unavailable'

export const encodeUrl = (urlString: string) => encodeURIComponent(urlString)
export const decodeUrl = (urlString: string) => decodeURIComponent(urlString)

export function* navigateToUnavailable() {
  const { location } = document
  const [, , ...url] = location.pathname.split('/')
  if (url.includes(LOGIN_URL)) {
    yield put(navigate(LOGIN_URL))
  } else {
    const encodedUrl = encodeUrl(url.join('/'))
    yield put(navigate(`${UNAVAILABLE_URL}/${encodedUrl}`))
  }
}

export const getQuerySearchParameter = (queryParam: string) => {
  const search = new URLSearchParams(window.location.search)
  return search.get(queryParam)
}
