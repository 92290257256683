import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { yupValidationForTiersBands } from '../update/components/forms/LendingTiersBandsList'
import { DebitInterestFormValues, FixedVariableType } from 'store/products/types'

export const debitInterestSetupValidationSchema = (t: TFunction, currencyCode: string) =>
  Yup.object<Partial<DebitInterestFormValues>>({
    fixedVariableType: Yup.mixed().required().oneOf([FixedVariableType.VARIABLE, FixedVariableType.FIXED]).nullable(),
    tierBands: Yup.mixed()
      .when(['calculationMethod'], {
        is: (calcMethod) => calcMethod !== 'FLAT',
        then: yupValidationForTiersBands(t, currencyCode),
      })
      .nullable(),
    calculationMethod: Yup.mixed().required('This is required').nullable(),
    frequency: Yup.string().required('This is required').nullable() as any,
    roundingMethod: Yup.mixed().required('This is required').nullable(),
    notificationApplication: Yup.number()
      .required('This is required')
      .min(0, 'Must be greater than zero')
      .max(365, 'Must be less than or equal 365')
      .nullable() as any,
  })

export const debitInterestRateValidationSchema = (t: TFunction, currencyCode: string) =>
  Yup.object<Partial<DebitInterestFormValues>>({
    fixedVariableType: Yup.mixed().required().oneOf([FixedVariableType.VARIABLE, FixedVariableType.FIXED]).nullable(),
    tierBands: Yup.mixed()
      .when(['calculationMethod'], {
        is: (calcMethod) => calcMethod !== 'FLAT',
        then: yupValidationForTiersBands(t, currencyCode),
      })
      .nullable(),
  })

export const debitInterestCommonConfigValidationSchema = () =>
  Yup.object<Partial<DebitInterestFormValues>>({
    frequency: Yup.string().required('This is required').nullable() as any,
    roundingMethod: Yup.mixed().required('This is required').nullable(),
    notificationApplication: Yup.number()
      .required('This is required')
      .min(0, 'Must be greater than zero')
      .max(365, 'Must be less than or equal 365')
      .nullable() as any,
  })
