import styles from 'styled-components/macro'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import AccessibleLink from 'components/AccessibleLink/AccessibleLink'

export const MainDiv = styles.div`
  justifyContent: center;
  flexWrap: wrap;
  ${(props: any) => `${props.theme.breakpoints.down('sm')}`} {
    display: none;
  }
`
export const StyledNavigateNextIcon = styles(NavigateNextIcon)`
  color: ${(props: any) => props.theme.palette.text.primary};
`

export const StyledSpan = styles.span`
  font-size: 14px;
  color: ${(props: any) => props.theme.palette.text.primary};
`

export const StyledLink = styles(AccessibleLink)`
  font-size: 14px;
  color: ${(props: any) => props.theme.palette.text.primary};
`
