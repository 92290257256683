import styled, { css } from 'styled-components/macro'
import { Popover } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const ExpandMoreIcon1 = styled(ExpandMoreIcon)<{ $open: boolean }>`
  margin-top: ${(props: any) => (!props.$open ? '4px' : 0)};
  margin-left: ${(props: any) => (props.$open ? '3px' : '0')};
`

export const ExpandLessIcon1 = styled(ExpandLessIcon)<{ $open: boolean }>`
  margin-top: ${(props: any) => (!props.$open ? '4px' : 0)};
  margin-left: ${(props: any) => (props.$open ? '3px' : '0')};
`

export const ImgStyled = styled.img`
  border: 0;
  margin-left: 8px;
  margin-right: -12px;
  margin-bottom: -5px;
  max-height: 60px;
  max-width: 140px;
  position: initial;
`

export const ImgStyledCollapsed = styled.img`
  margin-right: -12px;
  margin-bottom: -5px;
  position: initial;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid ${(props: any) => `${props.theme.palette.divider}`};
`

export const StyledMenu = styled(Popover)`
  .MuiPopover-paper {
    width: 350px;
    padding: 16px;
  }
`

export const IconContainer = styled.div`
  margin-right: 6px;
  margin-top: 2px;
`

export const NameContainer = styled.div`
  margin-top: 12px;
`

export const ButtonContainer = styled.div`
  margin-top: 16px;
`

export const drawerWidth = 250
export const drawerWidthSmaller = 72

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
      },
      appBarShift: {
        [theme.breakpoints.up('md')]: {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px) !important`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
      appBarShiftSmaller: {
        [theme.breakpoints.up('md')]: {
          marginLeft: drawerWidthSmaller,
          width: `calc(100% - ${drawerWidthSmaller + 1}px) !important`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
      appBarOffset: {
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.14)',
        backgroundColor: theme.palette.primary.contrastText,
      },
      menuButton: {
        marginRight: 4,
      },
      list: {
        padding: '0',
      },
      hide: {
        display: 'none',
      },
      drawer: {
        [theme.breakpoints.up('md')]: {
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
        },
        paddingLeft: 8,
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        border: 'none',
      },
      drawerClose: {
        [theme.breakpoints.up('md')]: {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
          width: theme.spacing(7) + 1,
          [theme.breakpoints.up('md')]: {
            width: theme.spacing(9) + 1,
          },
        },
        [theme.breakpoints.down('sm')]: {
          width: 0,
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
        border: 'none',
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
      },
      listItem: {
        height: `${theme.spacing(7)}px`,
        color: theme.palette.text.secondary,
        margin: `0 ${theme.spacing(2)}px`,
        width: `calc(100% - ${theme.spacing(4)}px)`,
        paddingLeft: `${theme.spacing(1)}px`,
        borderRadius: 8,
        '&:hover': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.contrastText,
        },
      },
      listItemText: {
        marginLeft: theme.spacing(1.75),
        fontSize: 14,
      },
      selected: {
        height: `${theme.spacing(7)}px`,
        '&.MuiListItem-root.Mui-selected': {
          backgroundColor: theme.palette.grey['100'],
        },
        color: theme.palette.text.primary,
        '&:after': {
          content: `" "`,
          position: 'absolute',
          left: -20,
          width: `${theme.spacing(1)}px`,
          height: `${theme.spacing(2.5)}px`,
          borderRadius: 8,
          background: `linear-gradient(64.83deg, ${theme.palette.secondary.dark}  ${theme.palette.secondary.main} 84.02%)`,
        },
      },
    }),
  { index: 1 }
)

export const SentenceCase = css`
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`

export const SkipToContentButton = styled.a`
  &:focus {
    top: 16px;
    transition: top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  color: ${(props: any) => props.theme.palette.primary.contrastText};
  top: -80px;
  left: 16px;
  padding: 8px;
  z-index: 1501;
  position: fixed;
  transition: top 195ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  background-color: ${(props: any) => props.theme.palette.text.primary};
`

type FlexboxProps = {
  direction: 'column' | 'row'
  width?: string
  height?: string
  justifyContent?: 'flex-start' | 'flex-end' | 'stretch' | 'center' | 'space-between'
  alignItems?: 'flex-start' | 'flex-end' | 'stretch' | 'center'
  p?: number
  pt?: number
  pb?: number
  pl?: number
  pr?: number
}

export const Flexbox = styled.div<FlexboxProps>`
  display: flex;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  flex-direction: ${({ direction }) => direction};
  ${({ p }) => p && `padding: ${p}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pr }) => pr && `padding-right: ${pr}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
  ${({ pl }) => pl && `padding-left: ${pl}px;`}
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
`
