import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ExtendedProduct } from 'store/products/types'
import ProductsSelectors from 'store/products/selectors'
import BreadcrumbsActions from 'store/breadcrumbs/actions'
import { slug } from 'utils/common.util'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'

export default ({
  productKey,
  context,
  subcontext,
  useTranslate = true,
}: {
  productKey: string
  context: string
  subcontext?: string
  useTranslate?: boolean
}): void => {
  const { t } = useTranslation()
  const selectedProduct: ExtendedProduct | undefined = useSelector(ProductsSelectors.selectSelectedProduct)
  const currentTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)

  const dispatch = useDispatch()
  useEffect(() => {
    if (!selectedProduct) return

    const breadcrumbs = [
      { title: t('{{tenantName}} products', { tenantName: currentTenant?.name }), path: `/products/` },
      {
        title: selectedProduct.productName || productKey || '',
        path: `/products/${productKey}`,
      },
      {
        title: useTranslate ? t(context) : context || '',
        path: context ? `/products/${productKey}/features/${slug(context)}` : '',
      },
    ]

    if (subcontext) {
      breadcrumbs.push({ title: useTranslate ? t(subcontext) : subcontext || '', path: '' })
    }

    dispatch(BreadcrumbsActions.updateBreadCrumbs(breadcrumbs))
  }, [t, productKey, selectedProduct, context, subcontext, dispatch])
}
