import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { ReviewRows, InfoPanel } from 'dls'
import { getTypeOfIndexRateForView } from 'pages/features/CreditInterest/utils/creditInterestTransformers'
import { CreditInterest, TypeOfIndexRate } from 'store/products/types'
import { generateFrequencyValue } from 'utils/viewFrequencyUtils'
import { BankInterestRate } from 'store/referenceData/types'
import ReferenceDataActions from 'store/referenceData/actions'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import { selectConfig } from 'store/tenant-config/selectors'
import { RootState } from 'store/types'
import { hasWithholdingTaxRule } from 'utils/common.util'
import TaxRulesSelectors from 'store/withholdingTax/selectors'
import TaxRulesActions from 'store/withholdingTax/actions'
import ProductsSelectors from 'store/products/selectors'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'
import getWithholdingTaxDefinition from 'pages/features/CreditInterest/utils/getWithHoldingTaxDefinitions'
import getTypeOfIndexRateDefinitions from 'pages/features/CreditInterest/utils/getTypeOfIndexRateDefinitions'

interface OwnProps {
  creditInterest: CreditInterest
  currencyOfProduct?: string | null
  bankIndexRates?: BankInterestRate[]
}

const StyledWrapper = styled.div`
  width: 100%;
`

const StyledInfoGrid = styled.div`
  margin-bottom: 24px;
  .MuiGrid-container {
    margin-top: 0 !important;
    margin-right: 9px;
    margin-left: 10px;
    width: fit-content;
  }
`

const mapDispatchToProps = {
  fetchBankInterestRates: ReferenceDataActions.fetchBankInterestRate,
  getAllTaxRulesWithoutErrorNavigation: TaxRulesActions.getAllTaxRulesWithoutErrorNavigation,
}

const mapStateToProps = (state: RootState) => ({
  bankInterestRates: ReferenceDataSelectors.selectAllBankInterestRate(state),
  tenantConfig: selectConfig(state),
  selectTaxRules: TaxRulesSelectors.taxRules(state),
  selectError: TaxRulesSelectors.error(state),
  selectTaxRulesIsLoading: TaxRulesSelectors.isLoading(state),
  selectProductPermissions: ProductsSelectors.selectProductPermissions(state),
})

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & OwnProps

const ViewCreditInterest = (props: Props): React.ReactElement => {
  const {
    fetchBankInterestRates,
    bankInterestRates,
    getAllTaxRulesWithoutErrorNavigation,
    creditInterest,
    tenantConfig,
    selectProductPermissions,
    selectTaxRules,
    selectError,
    selectTaxRulesIsLoading,
    bankIndexRates,
  } = props
  const { isCreditInterestEnabled } = selectProductPermissions
  const enableWithholdingTaxRule = useMemo(() => tenantConfig && hasWithholdingTaxRule(tenantConfig), [tenantConfig])
  const { t } = useTranslation()

  useEffect(() => {
    if (enableWithholdingTaxRule) {
      getAllTaxRulesWithoutErrorNavigation()
    }
  }, [enableWithholdingTaxRule, getAllTaxRulesWithoutErrorNavigation])

  const typeOfIndex = getTypeOfIndexRateForView(creditInterest.fixedVariableType, creditInterest.partnercentricModel)
  useEffect(() => {
    if (!bankInterestRates && typeOfIndex !== TypeOfIndexRate.INDEPENDENT_INDEXED_RATE) {
      fetchBankInterestRates()
    }
  }, [fetchBankInterestRates, bankInterestRates, typeOfIndex])

  const refreshTaxRules = () => {
    if (enableWithholdingTaxRule) {
      getAllTaxRulesWithoutErrorNavigation()
    }
  }

  const creditInterestDefinitions: ReviewRowGroup[] = [
    {
      groupSeparator: 'light',
      definitions: ([
        creditInterest?.fixedVariableType && {
          term: t('Type of interest rate'),
          definitions: [typeOfIndex ? t(typeOfIndex) : ''],
        },
        creditInterest?.tierBandCalcMethod && {
          term: t('How interest is applied'),
          definitions: [t(creditInterest.tierBandCalcMethod)],
        },
        ...getTypeOfIndexRateDefinitions(
          typeOfIndex as TypeOfIndexRate,
          creditInterest,
          bankIndexRates as any,
          props.currencyOfProduct as any,
          t
        ),
        creditInterest?.roundingMethod && {
          term: t('Rounding method'),
          definitions: [t(creditInterest.roundingMethod)],
        },
        creditInterest?.calculationFrequency && {
          term: t('Calculation frequency'),
          definitions: [
            <span key="calc-frequency">
              {t(
                `${generateFrequencyValue(creditInterest.calculationFrequency)[0]}`,
                generateFrequencyValue(creditInterest.calculationFrequency)[1] as any
              )}
            </span>,
          ],
        },
        creditInterest?.applicationFrequency && {
          term: t('Application frequency'),
          definitions: [
            <span key="application-frequency">
              {t(
                `${generateFrequencyValue(creditInterest.applicationFrequency)[0]}`,
                generateFrequencyValue(creditInterest.applicationFrequency)[1] as any
              )}
            </span>,
          ],
        },
      ] as any).filter((item: any) => item),
    },
    enableWithholdingTaxRule &&
      ({
        definitions: getWithholdingTaxDefinition({
          selectTaxRules: selectTaxRules as any,
          creditInterest,
          selectTaxRulesIsLoading,
          selectError,
          refreshTaxRules,
          t,
        }),
      } as any),
  ]

  return (
    <StyledWrapper>
      {!isCreditInterestEnabled && (
        <StyledInfoGrid>
          <InfoPanel
            title={t(`Credit interest has been configured on a previous version of this product`)}
            body={t(`This means you can only change the application method and rate. You can’t
delete this feature or change the calculation or application frequency.`)}
          />
        </StyledInfoGrid>
      )}

      <ReviewRows definitionsList={creditInterestDefinitions} />
    </StyledWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCreditInterest)
