/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Drafts, CheckCircle, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { Body2 } from 'dls'
import { BadgeProps, ColorType } from 'dls/shared/types'
import { Button } from 'dls/atoms/Button'

interface Props {
  title: string
  disabled: boolean
  selected?: boolean
  toggleSelect?: () => void
  SVGIcon?: React.JSXElementConstructor<BadgeProps>
  icon?: React.ReactElement
  showCheck?: boolean
  showRadioButton?: boolean
}

const Tile = styled(Button)`
  width: 170px;
  height: 170px;
  margin: 8px;
  background-color: white;
  color: inherit;
  &:hover {
    background-color: white;
    box-shadow: ${(props: any) => `${props.theme.shadows[8]}`};
  }
  &.selected {
    border: ${(props: any) => `2px solid ${props.theme.palette.secondary.main}`};
  }
  &.MuiButton-contained {
    background-color: white;
    color: inherit;
    &.Mui-disabled {
      background-color: ${({ theme }: any) => theme.palette.action.disabledBackground};
      color: ${({ theme }: any) => theme.palette.action.disabled};
    }
  }
  text-transform: none;
`

const TenxFeatureTile = ({
  title,
  disabled,
  toggleSelect,
  selected,
  SVGIcon,
  icon,
  showCheck,
  showRadioButton,
}: Props) => (
  <>
    <Tile
      variant="contained"
      color={ColorType.NONE}
      disabled={disabled}
      onClick={toggleSelect}
      className={selected ? 'selected' : ''}
    >
      <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
        <Grid container item direction="column" justifyContent="flex-end">
          <Grid item>{SVGIcon ? <SVGIcon disabled={disabled} /> : icon || <Drafts />}</Grid>
        </Grid>
        <Grid item>
          <Body2 id="feature_names_header" variantMapping={{ body2: 'div' }}>
            {title}
          </Body2>
        </Grid>
        {(selected || disabled) && showCheck && (
          <Grid item>
            <CheckCircle color={selected ? 'secondary' : 'inherit'} />
          </Grid>
        )}
        {showRadioButton && (
          <Grid item>
            {selected ? (
              <RadioButtonChecked color={selected ? 'secondary' : 'inherit'} />
            ) : (
              <RadioButtonUnchecked color={selected ? 'secondary' : 'inherit'} />
            )}
          </Grid>
        )}
      </Grid>
    </Tile>
  </>
)

export default TenxFeatureTile
