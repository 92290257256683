import React from 'react'
import { useParams } from 'react-router-dom'
import ProductSyndicationForm from 'pages/features/ProductSyndication/components/update/shared/SyndicationForm'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { useProductSyndication } from 'pages/Product/hooks/useProductSyndication'
import { Loader } from 'components'
import { getProductCategoryFromSegment } from 'pages/features/ProductSyndication/components/update/shared/utils'

const UpdateProductSyndicationForm = () => {
  const { productKey = '', version } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)
  const { productSyndication, isLoading: syndicationLoading } = useProductSyndication(productKey, Number(version))

  if (isLoading || syndicationLoading || !product || !productSyndication) {
    return <Loader />
  }

  const defaultValues = {
    productCategory: getProductCategoryFromSegment({
      productGroup: product.productGroup,
      segment: product.productSegment,
    }),
    productKey: productSyndication.productKey,
    productDescription: productSyndication.description,
    productName: productSyndication.name,
    applicationUri: productSyndication.applicationUri,
    brand: productSyndication.brand,
    isTailored: productSyndication.isTailored,
    brandName: productSyndication.brandName,
  }

  return <ProductSyndicationForm defaultValues={defaultValues} />
}

export default UpdateProductSyndicationForm
