import { Card } from '@material-ui/core'
import styled from 'styled-components/macro'

const HoverCard = styled(Card)`
  height: 100%;
  overflow: visible;
  & a {
    border-radius: 4px;
    border: 1px solid transparent;
    outline: none;
  }
  & a:hover,
  & a:focus {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  }
  & .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight,
  & .MuiCardActionArea-root:focus .MuiCardActionArea-focusHighlight {
    display: none;
  }
`

export default HoverCard
