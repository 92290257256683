import React from 'react'
import styled from 'styled-components/macro'
import { BulletIcon, NestedBulletIcon } from 'assets'

interface ListItem {
  label: React.ReactNode
  subList?: React.ReactNode[]
}

interface OwnProps {
  listItems: Array<ListItem>
}

const CustomMarkerLi = styled.li`
  &::marker {
    font-size: 1rem;
  }
`

const PrimaryLi = styled(CustomMarkerLi)`
  list-style-image: url(${BulletIcon});
`

const SecondaryLi = styled(CustomMarkerLi)`
  list-style-image: url(${NestedBulletIcon});
`

const UL = styled.ul`
  padding-left: 24px;
`

const UlList = ({ listItems }: OwnProps) => (
  <UL data-dls="molecule-UlList">
    {listItems.map((item, i) => {
      if (item.subList) {
        return (
          <PrimaryLi key={`li-${i}`}>
            {item.label}
            <UL>
              {item?.subList.map((secondaryItem, index) => (
                <SecondaryLi key={`${secondaryItem}-${index}`}>{secondaryItem}</SecondaryLi>
              ))}
            </UL>
          </PrimaryLi>
        )
      }
      return <PrimaryLi key={`${item}-${i}`}>{item.label}</PrimaryLi>
    })}
  </UL>
)

export default UlList
