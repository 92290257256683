import { connect } from 'react-redux'
import React, { useEffect, useMemo } from 'react'
import { orderBy } from 'lodash'
import Conversation from './Conversation'
import ActivityActions from 'store/activities/actions'
import ActivitySelectors from 'store/activities/selectors'
import { Message } from 'store/activities/types'
import { RootState } from 'store'
import { Loader } from 'components'
import { ProductStatus } from 'store/products/typings/productStatus'

export interface OwnProps {
  productKey?: string
  context?: string
  productStatus: ProductStatus
}

const mapDispatchToProps = {
  // @TODO Use ActivityActions.fetch to get actual messages
  getMessages: ActivityActions.fetchEmpty,
}
const mapStateToProps = (state: RootState) => ({
  messages: ActivitySelectors.selectAllActivities(state) as Message[],
  loading: ActivitySelectors.selectLoading(state),
})

const sortedByDateMsgs = (messages: Message[]) => orderBy(messages, ['createdDate'], ['desc'])

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps

const ConversationContainerPage = ({ messages, getMessages, context, productKey, productStatus, loading }: Props) => {
  const sortedMessages = useMemo(() => sortedByDateMsgs(messages), [messages])

  useEffect(() => {
    if (productKey) {
      getMessages({ productKey, context })
    }
  }, [productKey, getMessages, context])

  if (loading) {
    return <Loader />
  }
  return <Conversation productStatus={productStatus} messages={sortedMessages} context={context} />
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationContainerPage)
