import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import EditDefaultAccessForm from './EditDefaultAccessForm'
import { hasTransactionRulesFeature, toTransactionRulesFormValues } from './transactionRulesTransformers'
import ProductsActions from 'store/products/actions'
import { TransactionRules, Feature, ProductKey } from 'store/products/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { RootState } from 'store/types'
import { ClientConfig } from 'store/tenant-config/types'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useFeatureAvailable from 'pages/Product/hooks/useFeatureAvailable'

interface OwnProps {}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTransactionRules: (productKey: ProductKey, formValues: TransactionRules, t: TFunction) => {
    dispatch(
      ProductsActions.setTransactionRules({
        ...formValues,
        productKey,
        transactionRuleMessage: t(`DEFAULT_TRANSACTION`),
      })
    )
  },
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const EditDefaultAccessPage = (props: Props): React.ReactElement | null => {
  const { setTransactionRules, tenantConfig } = props
  const { productKey = '' } = useParams()
  const { t } = useTranslation()

  const [product, isLoading] = useSelectedProduct(productKey)
  useUpdateBreadcrumbs({ productKey, context: Feature.TRANSACTIONRULES })
  useFeatureAvailable(productKey, Feature.TRANSACTIONRULES)
  const clientConfig = ((tenantConfig && tenantConfig.clientConfig) as Partial<ClientConfig>) || {}

  if (isLoading) {
    return <Loader />
  }

  if (product) {
    return (
      <EditDefaultAccessForm
        onSubmit={(formValues: TransactionRules) => setTransactionRules(productKey, formValues, t)}
        initialValues={toTransactionRulesFormValues(product)}
        hasFeature={hasTransactionRulesFeature(product)}
        clientConfig={clientConfig}
        product={product}
        productKey={productKey}
        t={t}
      />
    )
  }

  return null
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDefaultAccessPage)
