import React from 'react'
import { Grid } from '@material-ui/core'
import getFullName from '../utils/getFullName'
import { Colleague } from 'store/user/types'
import { getInitials, getColorFromInitials } from 'utils/ui.utils'
import { AccessibleLink } from 'components'
import { OAvatar } from 'dls'
import { AvatarSize, AvatarColor } from 'dls/shared/types'

interface OwnProps {
  user: Colleague
}

const UserNameCellContent = ({ user }: OwnProps) => {
  const initials = getInitials(getFullName(user))
  const avatarColor = getColorFromInitials(initials)
  const userDetailPageLink = `/user/${user.userKey}`
  return (
    <Grid container spacing={2} wrap="nowrap" direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item>
        <OAvatar color={avatarColor as AvatarColor} size={AvatarSize.NORMAL}>
          {initials}
        </OAvatar>
      </Grid>
      <Grid item>
        <AccessibleLink to={userDetailPageLink}>{getFullName(user)}</AccessibleLink>
      </Grid>
    </Grid>
  )
}

export default UserNameCellContent
