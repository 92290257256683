import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentTenant } from 'store/tenant-config/selectors'
import StatementConfigurationActions from 'store/templates/statementConfiguration/actions'
import StatementConfigurationSelectors from 'store/templates/statementConfiguration/selectors'
import { StatementConfigurationFormValues } from 'store/templates/statementConfiguration/types'
import { Template } from 'store/statement/types'

const useStatementConfiguration = () => {
  const dispatch = useDispatch()
  const selectedTenant = useSelector(selectCurrentTenant)
  const statementConfigurationsLoading = useSelector(StatementConfigurationSelectors.loading)
  const statementConfigurationsError = useSelector(StatementConfigurationSelectors.error)
  const statementConfigurations = useSelector(StatementConfigurationSelectors.statementConfigurations)
  const draftStatementConfigurationFormValues = useSelector(
    StatementConfigurationSelectors.draftStatementConfigurationFormValues
  )
  const selectedStatementProducts = useSelector(StatementConfigurationSelectors.selectedStatementProducts)
  const statementProductsLoading = useSelector(StatementConfigurationSelectors.statementProductsLoading)
  const statementProductsError = useSelector(StatementConfigurationSelectors.statementProductsError)

  const fetchStatementProducts = (statementConfigKey: string) =>
    dispatch(StatementConfigurationActions.getStatementProducts(statementConfigKey))

  const fetchStatementConfigurations = () =>
    selectedTenant && dispatch(StatementConfigurationActions.fetchStatementConfigurations())

  const saveDraftStatementConfigurationFormValues = (values: StatementConfigurationFormValues) =>
    dispatch(StatementConfigurationActions.saveDraftStatementConfigurationFormValues(values))

  const deleteDraftStatementConfigurationFormValues = () =>
    dispatch(StatementConfigurationActions.deleteDraftStatementConfigurationFormValues())

  const saveDraftStatementConfigurationTemplate = (template: Template) =>
    dispatch(StatementConfigurationActions.saveDraftStatementConfigurationTemplate(template))

  const deleteDraftStatementConfigurationTemplate = () =>
    dispatch(StatementConfigurationActions.deleteDraftStatementConfigurationTemplate())

  return {
    fetchStatementConfigurations,
    draftStatementConfigurationFormValues,
    saveDraftStatementConfigurationFormValues,
    deleteDraftStatementConfigurationFormValues,
    saveDraftStatementConfigurationTemplate,
    deleteDraftStatementConfigurationTemplate,
    fetchStatementProducts,
    statementProductsLoading,
    statementProductsError,
    statementConfigurations,
    statementConfigurationsLoading,
    statementConfigurationsError,
    selectedStatementProducts,
  }
}

export default useStatementConfiguration
