import React from 'react'
import styled from 'styled-components/macro'
import { Box, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useTemplateImage from 'pages/features/Statements/utils/useTemplateImage'
import { StatementSkeleton } from 'assets'
import { ProductSegment, StatementType } from 'store/products/types'
import { Template } from 'store/statement/types'
import { EditAvatarButton, PreviewLaunchButton } from 'stories/molecules/IconButton/IconButton.stories'
import { NoPreviewIcon } from 'dls/atoms/Icons/SVGIcons'

interface OwnProps {
  template: Template
  templateType: StatementType
  productSegment: ProductSegment
  previewTemplateOpen: VoidFunction
  editPath?: string
  updateDraftFormValues?: () => void
}

const StyledBox = styled(Box)<{ $showBorder: boolean }>`
  ${({ $showBorder, theme }) => `

    border: ${$showBorder ? `1px solid ${theme.palette.background.medium}` : 'none'}

  `}
`

const StatementPreviewImage = ({
  template,
  productSegment,
  templateType,
  previewTemplateOpen,
  editPath,
  updateDraftFormValues,
}: OwnProps) => {
  const { t } = useTranslation()
  const { templateGroupId, templateName } = template
  const { src, error } = useTemplateImage(productSegment, templateType, templateGroupId)
  const navigate = useNavigate()

  if (src || error) {
    return (
      <StyledBox position="relative" $showBorder={!!(src && !error)}>
        <img
          width="100%"
          src={error && !src ? StatementSkeleton : src}
          alt={error && !src ? t('No preview available') : templateName}
        />
        <div css="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
          <Box display="flex" alignItems="center">
            {!error && src && (
              <PreviewLaunchButton
                title="preview"
                onClick={previewTemplateOpen}
                ariaProps={{
                  'aria-label': 'View statement template design',
                  'aria-haspopup': 'true',
                  'aria-controls': 'form-bottom-bar-menu',
                }}
              />
            )}
            {error && !src && (
              <Box p={1} display="flex" alignItems="center">
                <NoPreviewIcon />
              </Box>
            )}
            {editPath && (
              <EditAvatarButton
                title="edit"
                onClick={() => {
                  if (updateDraftFormValues) {
                    updateDraftFormValues()
                  }
                  navigate(editPath)
                }}
                ariaProps={{ 'aria-label': 'Change statement template', 'aria-controls': 'main', 'aria-role': 'link' }}
              />
            )}
          </Box>
        </div>
      </StyledBox>
    )
  }

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export default React.memo(StatementPreviewImage)
