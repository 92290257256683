import moment from 'moment-timezone'
import {
  CardTransactionLimitsDirection,
  CardTransactionLimit,
  CardTransactionLimitsRoutingDestination,
  CardTransactionLimitsRange,
  CardTransactionLimitsAmountType,
  CardTransactionLimitsChannel,
  CardTransactionLimitsType,
  CardTransactionLimitsFormValues,
  CardTransactionLimitsURLValues,
} from 'store/products/typings/cardLimits'

const getLimitValue = (
  direction: CardTransactionLimitsDirection,
  channel: CardTransactionLimitsChannel,
  routingDestinations: CardTransactionLimitsRoutingDestination[],
  range: CardTransactionLimitsRange,
  amountType: CardTransactionLimitsAmountType
) => (cardLimits: CardTransactionLimit[]) =>
  cardLimits?.find(
    (limit) =>
      limit.direction === direction &&
      limit.channel === channel &&
      limit.range === range &&
      limit.amount.type === amountType &&
      limit.routingDestinations.some((dest) => routingDestinations.includes(dest))
  )

// To self

export const getPerCashTransaction = getLimitValue(
  CardTransactionLimitsDirection.DEBIT,
  CardTransactionLimitsChannel.NOT_APPLICABLE,
  [CardTransactionLimitsRoutingDestination.CARD_CASH],
  CardTransactionLimitsRange.TRANSACTION,
  CardTransactionLimitsAmountType.MONETARY
)

export const getCumulativeCashDaily = getLimitValue(
  CardTransactionLimitsDirection.DEBIT,
  CardTransactionLimitsChannel.NOT_APPLICABLE,
  [CardTransactionLimitsRoutingDestination.CARD_CASH],
  CardTransactionLimitsRange.DAY,
  CardTransactionLimitsAmountType.MONETARY
)

export const getPerNonCashTransaction = getLimitValue(
  CardTransactionLimitsDirection.DEBIT,
  CardTransactionLimitsChannel.NOT_APPLICABLE,
  [CardTransactionLimitsRoutingDestination.CARD_NON_CASH],
  CardTransactionLimitsRange.TRANSACTION,
  CardTransactionLimitsAmountType.MONETARY
)

export const getCumulativeNonCashDaily = getLimitValue(
  CardTransactionLimitsDirection.DEBIT,
  CardTransactionLimitsChannel.NOT_APPLICABLE,
  [CardTransactionLimitsRoutingDestination.CARD_NON_CASH],
  CardTransactionLimitsRange.DAY,
  CardTransactionLimitsAmountType.MONETARY
)

export const defineLimitType = ({
  direction,
  channel,
  routingDestination,
  range,
  amountType,
}: {
  direction: CardTransactionLimitsDirection
  channel: CardTransactionLimitsChannel
  routingDestination?: CardTransactionLimitsRoutingDestination
  range: CardTransactionLimitsRange
  amountType: CardTransactionLimitsAmountType
}) => {
  switch (direction) {
    case CardTransactionLimitsDirection.DEBIT:
      switch (channel) {
        case CardTransactionLimitsChannel.NOT_APPLICABLE:
          switch (true) {
            case routingDestination === CardTransactionLimitsRoutingDestination.CARD_CASH:
              switch (range) {
                case CardTransactionLimitsRange.TRANSACTION:
                  switch (amountType) {
                    case CardTransactionLimitsAmountType.MONETARY:
                      return CardTransactionLimitsType.OUTBOUND_CASH_TRANSACTION
                    default:
                      return null
                  }
                case CardTransactionLimitsRange.DAY:
                  switch (amountType) {
                    case CardTransactionLimitsAmountType.MONETARY:
                      return CardTransactionLimitsType.OUTBOUND_CASH_CUMULATIVE_DAILY
                    default:
                      return null
                  }
                default:
                  return null
              }
            case routingDestination === CardTransactionLimitsRoutingDestination.CARD_NON_CASH:
              switch (range) {
                case CardTransactionLimitsRange.TRANSACTION:
                  switch (amountType) {
                    case CardTransactionLimitsAmountType.MONETARY:
                      return CardTransactionLimitsType.OUTBOUND_NON_CASH_TRANSACTION
                    default:
                      return null
                  }
                case CardTransactionLimitsRange.DAY:
                  switch (amountType) {
                    case CardTransactionLimitsAmountType.MONETARY:
                      return CardTransactionLimitsType.OUTBOUND_NON_CASH_DAILY
                    default:
                      return null
                  }
                default:
                  return null
              }
            default:
              return null
          }
        default:
          return null
      }
    default:
      return null
  }
}

export const convertCardTransactionLimitToFormValues = (
  limit?: CardTransactionLimit
): CardTransactionLimitsFormValues => ({
  limitRuleId: limit?.limitRuleId ?? '',
  minimum: limit?.amount?.minimum ? limit?.amount?.minimum : 0,
  maximum: limit?.amount?.maximum ? limit?.amount?.maximum : undefined,
  enableSubscriptionOverride: limit?.enableSubscriptionOverride ?? false,
  subscriptionOverrideMaximum: limit?.subscriptionOverrideMaximum ? limit?.subscriptionOverrideMaximum : undefined,
})

export const convertValuesToCardTransactionLimitPayload = (
  values: CardTransactionLimitsFormValues & CardTransactionLimitsURLValues
): CardTransactionLimit => {
  const {
    maximum,
    enableSubscriptionOverride,
    direction,
    channel,
    routingDestinations,
    amountType,
    range,
    limitRuleId,
    subscriptionOverrideMaximum,
  } = values

  return {
    limitRuleId,
    direction,
    channel,
    routingDestinations,
    range,
    amount: {
      minimum: undefined,
      maximum: maximum || undefined,
      type: amountType,
    },
    subscriptionOverrideMaximum:
      enableSubscriptionOverride && subscriptionOverrideMaximum ? subscriptionOverrideMaximum : undefined,
    enableSubscriptionOverride: !!enableSubscriptionOverride,
  }
}

export const formatConfigTaxYear = (taxYearStart: string): string => {
  const date = moment(taxYearStart, '[--]MM[-]DD')
  return date.format('DD MMMM')
}
