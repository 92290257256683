import React from 'react'
import moment from 'moment-timezone'
import { Grid, Box } from '@material-ui/core'
import { FastField, Field, FieldArray, Form, FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { AddCircle, InfoOutlined } from '@material-ui/icons'
import { RuleType, thenOptions } from '../../../hooks/constants'
import { StyledSelectFieldComplex } from '../../../styled'
import TiersBandsList from '../../../shared/TiersBandsList'
import { defaultValues, effectiveDateText, initialFact, testCreateWithholdingTaxRule } from '../../../hooks/utils'
import CreateRuleField from './CreateTaxRuleField'
import { ContentPaper } from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import {
  rateYupValidation,
  withFormikSimple,
  Yip,
  validationForTierBandStartRangeEndRangeTax,
  stopPropagation,
} from 'utils/form.utils'
import {
  DateTimePickerComponent,
  FieldPercentage,
  FormBottomBar,
  RadioGroupFieldComplex,
  TextFieldComplex,
} from 'components'
import { toBankTz } from 'utils/date.utils'
import { RateTierBand } from 'store/products/types'
import { FormBottom } from 'pages/SuspenseAccounts/Transactions/Styles'
import {
  WithholdingTaxFact,
  WithholdingTaxFactName,
  WithholdingTaxFactOperator,
  WithholdingRuleFormValues,
} from 'store/withholdingTax/types'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { Body2, H5, InfoPanel } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface Props {
  onCancelClick: () => void
  currencyCode?: string | null
}

function CreateTaxRuleForm(props: Props & FormikProps<WithholdingRuleFormValues>) {
  const { t } = useTranslation()
  const ruleTypesUsed = props.values?.facts?.map((fact) => fact.name)
  let areFactsInvalid =
    props.errors.facts && props.errors.facts.length > 0 && (ruleTypesUsed?.length || 0) < Object.keys(RuleType).length

  if (!areFactsInvalid) {
    const lastValue = props.values?.facts?.[props.values.facts.length - 1]
    areFactsInvalid = Array.isArray(lastValue?.value) ? lastValue?.value[0] === undefined : lastValue?.value === ''
  }

  const isMaxFactsLength = ruleTypesUsed && ruleTypesUsed.length >= Object.keys(RuleType).length - 1

  const selectTierBandCalcMethod = props.values.tierBandCalcMethod

  const clearTierCalc = (withholdTax: boolean) => {
    if (withholdTax === false) {
      props.setFieldTouched('tierBandCalcMethod', false, false)
      props.setFieldValue('tierBandCalcMethod', null)
      props.setFieldTouched('flatRate', false, false)
      props.setFieldValue('flatRate', null)
      props.setFieldTouched('taxTierBand', false, false)
      props.setFieldValue('taxTierBand', null)
    }
  }

  const { showInfographicModal } = useModal()

  return (
    <Form>
      <ContentPaper elevation={1}>
        <Box mb={3}>
          <InfoPanel
            title={t('You won’t be able to edit or delete the tax rule once it’s been submitted')}
            body={
              <>
                <Body2>
                  {t(
                    `Also, make sure the name is clear so colleagues will recognise the rule when they’re configuring credit interest for a product.`
                  )}
                </Body2>
              </>
            }
          />
        </Box>
        <Grid item>
          <FastField
            name="name"
            label={t('Rule name')}
            required
            validate={Yip(Yup.string().typeError(t('This field must contain text')).required())}
            component={TextFieldComplex}
            placeholder={t('Enter name')}
            helperText={t('E.g. Domestic residents - withhold 20% tax')}
          />
        </Grid>
        <Body2 style={{ marginTop: '33px', marginBottom: '24px' }}>{t('Rules')}</Body2>
        <FieldArray name="facts">
          {(arrayHelpers) => (
            <>
              {props.values.facts?.map((value, index) => (
                <CreateRuleField
                  key={index}
                  name={`facts.${index}`}
                  onRemove={arrayHelpers.remove}
                  hasMultipleRows={(props.values?.facts && props.values.facts.length > 1) || false}
                  ruleTypesUsed={ruleTypesUsed || []}
                  index={index}
                />
              ))}
              {!isMaxFactsLength && (
                <Button
                  color={ColorType.NONE}
                  startIcon={<AddCircle />}
                  disabled={!!areFactsInvalid}
                  onClick={() => arrayHelpers.push(initialFact)}
                  disableRipple
                  style={{
                    left: '-6px',
                    margin: '0 0 24px 0',
                  }}
                >
                  {t('AND...')}
                </Button>
              )}
            </>
          )}
        </FieldArray>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field
              name="withholdTax"
              label="Then"
              required
              component={StyledSelectFieldComplex}
              placeholder={t('Please select')}
              data={thenOptions}
              inputLabelProps={{ shrink: true }}
              displayEmpty
              hasPlaceholder={props.values.withholdTax === undefined}
              disabled={areFactsInvalid}
              onChange={clearTierCalc}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ marginTop: 20 }}>
            <FastField
              placeholder={t('Select date')}
              name="effectiveDate"
              type="text"
              clearable
              variant="inline"
              touchedNotNeeded
              component={DateTimePickerComponent}
              label={`${'Effective date'} (${toBankTz(getIn(props.values, `effectiveDate`)).tz})`}
              InputLabelProps={{ style: { width: 300 } }}
              dateTimePickerProps={{
                shouldDisableDate: (date: moment.Moment) => date && !moment().isSameOrBefore(date),
              }}
            />
          </Grid>
        </Grid>
        {props.values.withholdTax && (
          <>
            <Grid item xs={12}>
              <H5 style={{ marginTop: 33, marginBottom: 24 }}>{t('Tax amount to be withheld on credit interest')}</H5>
            </Grid>
            <Grid item xs={12}>
              <Body2>
                {t('There are 3 options for how withheld tax will be calculated:', { nsSeparator: '||' })}
                <LinkWithText
                  aria-controls="more-about-dialog"
                  aria-haspopup="dialog"
                  onClick={(e: React.MouseEvent) => {
                    stopPropagation(e)
                    showInfographicModal(HelpModalType.INTEREST_RATES)
                  }}
                  endIcon={<InfoOutlined />}
                >
                  {t('see how these work')}
                </LinkWithText>
              </Body2>
            </Grid>
            <Grid
              item
              container
              direction="row"
              spacing={3}
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ marginTop: 8 }}
              xs={12}
            >
              <Grid item>
                <Field
                  name="tierBandCalcMethod"
                  component={RadioGroupFieldComplex}
                  onChange={(value: any) => {
                    if (value === RateTierBand.FLAT) {
                      props.setFieldValue('taxTierBand', [], true)
                      props.setFieldTouched('taxTierBand', true, true)
                      props.setFieldValue('flatRate', null)
                      props.setFieldTouched('flatRate', false, true)
                    } else {
                      props.setFieldValue('taxTierBand', [{ startRange: 0 }], true)
                      props.setFieldTouched('taxTierBand', true, true)
                      props.setFieldValue('flatRate', null, true)
                      props.setFieldTouched('flatRate', true, true)
                    }
                  }}
                  data={RateTierBand}
                  required
                  validate={Yip(Yup.string().required().nullable())}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {selectTierBandCalcMethod === RateTierBand.FLAT && (
                <Grid item xs={12} sm={4} style={{ marginTop: 40 }}>
                  <Field
                    name="flatRate"
                    label={t('Tax rate is')}
                    required
                    placeholder={t('Please enter')}
                    validate={Yip(rateYupValidation(2, 1000).required())}
                    component={FieldPercentage}
                    numberFormatProps={{
                      decimalScale: 2,
                    }}
                  />
                </Grid>
              )}
              {[RateTierBand.TIER, RateTierBand.BAND].includes(selectTierBandCalcMethod as RateTierBand) && (
                <TiersBandsList name="taxTierBand" currencyCode={props.currencyCode} />
              )}
            </Grid>
          </>
        )}
        <FormBottom style={{ marginTop: 24 }}>
          <FormBottomBar inlineNavigation spacing={1} disabled={!props.dirty}>
            <Button variant="text" color={ColorType.BUTTON} onClick={props.onCancelClick}>
              {t('Cancel')}
            </Button>
          </FormBottomBar>
        </FormBottom>
      </ContentPaper>
    </Form>
  )
}

const RuleSchema = Yup.object().shape({
  name: Yup.string().required('This is a required field'),
  withholdTax: Yup.string().typeError('This field must contain text').required('This is a required field'),
  facts: Yup.array().of(
    Yup.object<WithholdingTaxFact>({
      name: Yup.mixed<WithholdingTaxFactName>()
        .oneOf(Object.values(WithholdingTaxFactName))
        .required('This is a required field'),
      operator: Yup.mixed<WithholdingTaxFactOperator>()
        .oneOf(Object.values(WithholdingTaxFactOperator))
        .required('This is a required field'),
      value: Yup.mixed().when('name', (type: any) =>
        type === 'AGE'
          ? Yup.array().of(
              Yup.number()
                .required('This field must be a number')
                .min(1, 'Must be greater than 0')
                .integer('This field must be an integer')
            )
          : Yup.mixed().test('mixed', 'This is a required field', testCreateWithholdingTaxRule)
      ),
    })
  ),
  taxTierBand: Yup.array(
    validationForTierBandStartRangeEndRangeTax('This value must be greater than the same field in the preceding row')
  ).nullable(),
  effectiveDate: Yup.string().test(
    'startRange-endRange-check',
    'Effective date is today + 1 or further into future',
    effectiveDateText as any
  ),
})

export default withFormikSimple<Props, WithholdingRuleFormValues>(CreateTaxRuleForm, {
  validationSchema: RuleSchema,
  defaultValues,
})
