import { createAction } from '@reduxjs/toolkit'
import { ProductSyndicationsResponse } from 'store/products/typings/productSyndication'

const actions = {
  getProductSyndications: createAction('PRODUCTS/SYNDICATIONS/FETCH'),
  getProductSyndicationsSuccess: createAction<ProductSyndicationsResponse>('PRODUCTS/SYNDICATIONS/SUCCESS'),
  getProductSyndicationsFail: createAction<Error>('PRODUCTS/SYNDICATIONS/FAIL'),
}

export default actions
