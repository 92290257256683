import MUIIconButton from '@material-ui/core/IconButton'
import { ComponentMeta } from '@storybook/react'
import { GenericTemplateCreator } from '../../shared/common.utils'
import { args, argTypes, parameters } from './controlConfig'
import {
  CopyIconButton,
  CopiedIconButton,
  DownloadIconButton,
  CodeIconButton,
  InfoIconButton,
  ExpandIconButton,
  CollapseIconButton,
  CloseIconButton,
  DeleteIconButton,
  AddCircleIconButton,
  DesignIconButton,
  HelpOutlineSharpButton,
  EditAvatarIconButton,
  PreviewLaunchIconButton,
  VisibilityIconButton,
  VisibilityOffIconButton,
  GridViewIconButton,
  ListIconButton,
  HistoryIconButton,
} from 'dls/molecules/IconButtons/IconButtons'
import { ColorType } from 'dls/shared/types'

export default {
  title: 'DLS/molecules/Icon Button',
  component: MUIIconButton,
} as ComponentMeta<typeof MUIIconButton>

// Copy atom
export const CopyIcon = GenericTemplateCreator({
  component: CopyIconButton,
  args: {
    ...args,
    color: 'primary',
    ariaProps: {
      'aria-label': 'Copy',
    },
    title: 'Copy',
  },
  argTypes,
  parameters,
})

// Copied atom
export const CopiedIcon = GenericTemplateCreator({
  component: CopiedIconButton,
  args: {
    ...args,
    color: 'primary',
    ariaProps: {
      'aria-label': 'Copied',
    },
    title: 'Copied',
  },
  argTypes,
  parameters,
})

// InforOutlined atom
export const DownloadIcon = GenericTemplateCreator({
  component: DownloadIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Download as a PDF',
    },
    title: 'Download as a PDF',
  },
  argTypes,
  parameters,
})

// ClearIcon atom
export const CodeIcon = GenericTemplateCreator({
  component: CodeIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'View JSON payload',
    },
    title: 'View JSON payload',
  },
  argTypes,
  parameters,
})

// ErrorOutIcon atom
export const InfoIcon = GenericTemplateCreator({
  component: InfoIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'About <featureOrEntityName>',
    },
    title: 'About <featureOrEntityName>',
  },
  argTypes,
  parameters,
})

// SettingsIcon atom
export const ExpandIcon = GenericTemplateCreator({
  component: ExpandIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Expand',
    },
    title: 'Expand',
  },
  argTypes,
  parameters,
})

// SettingsIcon atom
export const CollapseIcon = GenericTemplateCreator({
  component: CollapseIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Collapse',
    },
    title: 'Collapse',
  },
  argTypes,
  parameters,
})

// ExpandLessIcon atom
export const CloseIcon = GenericTemplateCreator({
  component: CloseIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Close',
    },
    title: 'Close',
  },
  argTypes,
  parameters,
})

// AddCircleIcon atom
export const AddCircleIcon = GenericTemplateCreator({
  component: AddCircleIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Add',
    },
    title: 'Add',
  },
  argTypes,
  parameters,
})

// EditIcon atom
export const EditIcon = GenericTemplateCreator({
  component: DesignIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Edit',
    },
    title: 'Edit',
  },
  argTypes,
  parameters,
})

// DeleteIcon atom
export const DeleteIcon = GenericTemplateCreator({
  component: DeleteIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Delete',
    },
    title: 'Delete',
  },
  argTypes,
  parameters,
})

// HelpIcon atom
export const HelpIcon = GenericTemplateCreator({
  component: HelpOutlineSharpButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Help',
    },
    title: 'Help',
  },
  argTypes,
  parameters,
})

export const VisibilityIcon = GenericTemplateCreator({
  component: VisibilityIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Visibility',
    },
    title: 'Visibility',
  },
  argTypes,
})

export const VisibilityOffIcon = GenericTemplateCreator({
  component: VisibilityOffIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'VisibilityOff',
    },
    title: 'VisibilityOff',
  },
  argTypes,
})

export const GridViewIcon = GenericTemplateCreator({
  component: GridViewIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'GridView',
    },
    title: 'GridView',
  },
  argTypes,
})

export const ListIcon = GenericTemplateCreator({
  component: ListIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'List',
    },
    title: 'List',
  },
  argTypes,
})

// Edit Avatar Icon atom
export const EditAvatarButton = GenericTemplateCreator({
  component: EditAvatarIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Help',
    },
    title: 'Help',
  },
  argTypes,
})

// Edit Preview Icon atom
export const PreviewLaunchButton = GenericTemplateCreator({
  component: PreviewLaunchIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'Help',
    },
    title: 'Help',
  },
  argTypes,
})

export const HistoryIcon = GenericTemplateCreator({
  component: HistoryIconButton,
  args: {
    ...args,
    ariaProps: {
      'aria-label': 'HistoryIcon',
    },
    imgProps: {
      color: ColorType.SECONDARY,
    },
    title: 'HistoryIcon',
  },
  argTypes,
})
