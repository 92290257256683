import React from 'react'
import { connect as connectFormik, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import SelectStatementTemplate from 'pages/features/Statements/SelectStatementTemplate'
import SelectedTemplateSummary from 'pages/features/Statements/SelectedTemplateSummary'
import StatementConfigLoader from 'pages/features/Statements/StatementConfigLoader'
import { StatementType, ExtendedProduct } from 'store/products/types'
import { ContextualHelp, InfoPanel } from 'dls'
import { CheckboxSimple } from 'components'

interface InterestStatementProps {
  name: string
  product: ExtendedProduct
  draftStatementConfigKey?: string
  updateDraftFormValues: () => void
}

const InterestStatement = ({
  name,
  product,
  draftStatementConfigKey,
  updateDraftFormValues,
}: InterestStatementProps) => {
  const { t } = useTranslation()

  const productInterestStatement = product?.statements?.find(
    (statement) => statement.statementType === StatementType.INTEREST_STATEMENT
  )

  return (
    <StatementConfigLoader product={product}>
      {(configs) => {
        const statementKey = draftStatementConfigKey || productInterestStatement?.statementConfigKey
        const interestStatementConfiguration = configs.find((config) => config.statementConfigKey === statementKey)

        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InfoPanel body={t('INTERESTSTATEMENTINFO')} />
            </Grid>
            <Grid item xs={12}>
              {interestStatementConfiguration ? (
                <SelectedTemplateSummary
                  product={product}
                  configuration={interestStatementConfiguration}
                  additionalContent={
                    <div style={{ marginLeft: -12, marginTop: 8 }}>
                      <Field
                        name={`${name}.hideExternalIdentifiers`}
                        component={CheckboxSimple}
                        opposite
                        description={t('Include account identifiers on statement')}
                        type="checkbox"
                      />
                    </div>
                  }
                  updateDraftFormValues={updateDraftFormValues}
                />
              ) : (
                <SelectStatementTemplate
                  product={product}
                  additionalContent={
                    <div style={{ marginLeft: -12, marginTop: 8 }}>
                      <Field
                        name={`${name}.hideExternalIdentifiers`}
                        component={CheckboxSimple}
                        opposite
                        description={
                          <>
                            {t('Include account identifiers on statement')}{' '}
                            <ContextualHelp
                              title={`${t(
                                'Account identifiers are the account number and bank location code, you can choose if you want these to displayed on the statement'
                              )}`}
                            />
                          </>
                        }
                        type="checkbox"
                      />
                    </div>
                  }
                  updateDraftFormValues={updateDraftFormValues}
                />
              )}
            </Grid>
          </Grid>
        )
      }}
    </StatementConfigLoader>
  )
}

export default connectFormik<InterestStatementProps>(InterestStatement)
