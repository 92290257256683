/* eslint-disable no-nested-ternary */
import { Grid, Typography, Box } from '@material-ui/core'
import { Form, FormikProps, getIn } from 'formik'
import React, { useCallback } from 'react'
import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { InfoOutlined } from '@material-ui/icons'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { ContentGridContainer, ContentPaperContainer } from '../Styled'
import WithholdingTax from './components/WithholdingTax'
import Common from './components/Common'
import RadioGroupFieldSimple from 'components/form/RadioGroupFieldSimple'
import { yupValidationForTiersBands } from 'pages/features/CreditInterest/components/TiersBandsList'
import VariableMargin from 'pages/features/CreditInterest/components/VariableMargin'
import StaticMargin from 'pages/features/CreditInterest/components/StaticMargin'
import {
  CreditInterestFormValues,
  DayCount,
  ExtendedProduct,
  FixedVariableType,
  TypeOfIndexRate,
  ProductKey,
  TypeOfIndexRateNoReferenceData,
} from 'store/products/types'
import { WithholdingRule } from 'store/withholdingTax/types'
import { withFormikSimple } from 'utils/form.utils'
import { FormBottomBar } from 'components'
import { BankInterestRate } from 'store/referenceData/types'
import { FeatureFlag } from 'components/ControlComponents'
import { InputType } from 'pages/features/CreditInterest/styles'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import { H2 } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  t: TFunction
  hasFeature?: boolean
  currencyOfProduct?: string | null
  bankInterestRates?: BankInterestRate[]
  isReferenceDataEnabled: boolean | string
  product: ExtendedProduct
  selectTaxRulesIsLoading?: boolean
  selectTaxRules?: WithholdingRule[]
  enableWithholdingTaxRule?: boolean
  productKey?: ProductKey
}

const InterestForm = (_props: FormikProps<CreditInterestFormValues> & OwnProps) => {
  const {
    t,
    values,
    touched,
    setTouched,
    isReferenceDataEnabled,
    product,
    productKey = '',
    enableWithholdingTaxRule,
    setValues,
  } = _props
  const selectTypeOfIndexRate = getIn(values, 'typeOfIndexRate')
  const isApplicationFrequencyValid = typeof values.applicationFrequency === 'object'
  const navigate = useNavigate()

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  const { showInfographicModal } = useModal()

  const handleOnchangeTypeOfIndexRate = (value: any) => {
    const newValues = { ...values, tierBandCalcMethod: null, typeOfIndexRate: value }
    const newTouched = { ...touched, fixedVariableType: false, tierBandCalcMethod: false }

    if (value === TypeOfIndexRate.INDEPENDENT_INDEXED_RATE) {
      newValues.fixedVariableType = FixedVariableType.FIXED
      newValues.partnercentricModel = undefined
      newTouched.partnercentricModel = false
    } else {
      newValues.fixedVariableType = FixedVariableType.VARIABLE
    }
    if (value !== TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN) {
      newValues.spread = undefined
      newTouched.spread = false
      newValues.operand = undefined
      newTouched.operand = false
    }
    setValues(newValues as any)
    setTouched(newTouched)
  }
  const IndexRateForm = (): JSX.Element | null => {
    switch (selectTypeOfIndexRate) {
      case TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN:
        return (
          <VariableMargin
            {..._props}
            bankInterestRates={_props.bankInterestRates}
            selectTypeOfIndexRate={selectTypeOfIndexRate}
          />
        )
      case TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN:
        return (
          <StaticMargin
            {..._props}
            bankInterestRates={_props.bankInterestRates}
            selectTypeOfIndexRate={selectTypeOfIndexRate}
          />
        )
      case TypeOfIndexRate.INDEPENDENT_INDEXED_RATE:
        return (
          <Common
            {..._props}
            bankInterestRates={_props.bankInterestRates}
            selectTypeOfIndexRate={selectTypeOfIndexRate}
            product={product}
            currencyOfProduct={product.currency.currencyCode || undefined}
          />
        )
      default:
        return null
    }
  }
  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('Credit interest')}
      description={t('Here you can set up the credit interest rules for this product.')}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <H2>{t('Type of interest rate')}</H2>
                </Grid>
                <Grid item xs={12}>
                  <FeatureFlag name="index_rates">
                    <Typography variant="subtitle1" gutterBottom variantMapping={{ subtitle1: 'div' }}>
                      {`${t('There are 3 options')} : `}
                      <LinkWithText
                        aria-controls="more-about-dialog"
                        aria-haspopup="dialog"
                        onClick={() => showInfographicModal(HelpModalType.INTEREST_RATES)}
                        endIcon={<InfoOutlined />}
                      >
                        {t('see examples of how these work ')}
                      </LinkWithText>
                    </Typography>
                  </FeatureFlag>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputType>
                <RadioGroupFieldSimple
                  name="typeOfIndexRate"
                  value={values.typeOfIndexRate || ''}
                  required
                  data={isReferenceDataEnabled ? TypeOfIndexRate : TypeOfIndexRateNoReferenceData}
                  onChange={handleOnchangeTypeOfIndexRate}
                />
              </InputType>
            </Grid>
            {IndexRateForm()}
            <Grid item xs={12}>
              {enableWithholdingTaxRule && isApplicationFrequencyValid && <WithholdingTax {..._props} />}
            </Grid>
            <Grid item xs={12}>
              <Box mt={3}>
                <FormBottomBar inlineNavigation>
                  <Button color={ColorType.BUTTON} onClick={onPressBack}>
                    {t('Cancel')}
                  </Button>
                </FormBottomBar>
              </Box>
            </Grid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, CreditInterestFormValues>(InterestForm, {
  defaultValues: {
    fixedVariableType: FixedVariableType.FIXED,
    dayCount: DayCount.ACT365,
    interestTierBand: [{ startRange: 0 }],
  },
  validationSchema: ({ t, currencyOfProduct }: { t: TFunction; currencyOfProduct: string }) =>
    Yup.object<Partial<CreditInterestFormValues>>({
      fixedVariableType: Yup.mixed().required().oneOf([FixedVariableType.VARIABLE, FixedVariableType.FIXED]),
      interestTierBand: Yup.mixed().when(['tierBandCalcMethod'], {
        is: (type) => type !== 'FLAT',
        then: yupValidationForTiersBands(t, currencyOfProduct),
      }),
      tierBandCalcMethod: Yup.mixed().required(),
      showWithholdingTax: Yup.boolean().when('availableHoldingTaxRules', {
        is: false,
        then: Yup.boolean().oneOf([false, true], 'No Rules available to select'),
        otherwise: Yup.boolean(),
      }),
      withholdingTax: Yup.mixed().when('showWithholdingTax', {
        is: true,
        then: Yup.mixed().test('taxRulesRequired', 'Tax rules are required', function (withholdingTax) {
          return !!withholdingTax && withholdingTax.taxRules.length > 0
        }),
      }),
    }).required(),
})
