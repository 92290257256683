import React from 'react'
import { get, find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { MenuItem, MenuItemProps } from '@material-ui/core'
import { FieldArray } from 'formik'
import { CommonFeaturePageValues } from 'store/products/types'

interface AddElementProps {
  name: string // might also need a key later if we want to embed multiple levels
  itemName?: string
  label?: string
  disabled?: boolean
  multiple?: boolean
  identifier?: string
  identifierOriginal?: string
  defaultValue?: unknown
  onMouseLeave?: () => void
  onAfterClick?: () => void
  forwardedRef?: React.Ref<any>
  menuItemProps?: Omit<MenuItemProps, 'button'>
  readOnly?: boolean
  triggerAction?: (item: any) => void
}

const AddElement = ({
  onAfterClick,
  name,
  itemName,
  identifierOriginal,
  identifier,
  label,
  defaultValue,
  multiple = true,
  disabled,
  menuItemProps,
  readOnly = false,
  triggerAction,
}: AddElementProps) => {
  const { t } = useTranslation()

  const hasKeyInValues = (values: CommonFeaturePageValues) =>
    itemName
      ? !!find(values[name], function (o) {
          return o.identifier === identifier || o.identifier === identifierOriginal
        })
      : !!get(values, name, []).length

  return (
    <FieldArray name={name}>
      {({ push, form: { values } }) => {
        const disabledMerged = disabled !== undefined ? disabled : !multiple && hasKeyInValues(values)
        const menuItemPropsChildren = !!menuItemProps && menuItemProps.children
        const childrenDefault = label ? t(label) : t(name)
        return (
          <MenuItem
            disabled={disabledMerged}
            onClick={() => {
              if (!readOnly) {
                push(defaultValue)
                if (onAfterClick) onAfterClick()
                if (triggerAction) triggerAction(defaultValue)
              }
            }}
            {...menuItemProps}
          >
            {menuItemPropsChildren || childrenDefault}
          </MenuItem>
        )
      }}
    </FieldArray>
  )
}

export default React.forwardRef((props: AddElementProps, ref?: React.Ref<any>) => (
  <AddElement {...props} forwardedRef={ref} />
))
