import { Field, Form, FormikProps, getIn } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import Refresh from '@material-ui/icons/Refresh'
import moment from 'moment-timezone'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { statusFilterOptions } from './TransactionsTableHeadRows'
import { toBankTz } from 'utils/date.utils'
import { withFormikSimple, Yip } from 'utils/form.utils'
import { FieldAmount, TextFieldComplex } from 'components'
import { CreditOrDebit, GetSuspenseTransactionsRequest, TransactionStatus } from 'store/accounts/types'
import DateTimePickerComponent from 'components/form/DateTimePickerComponent'
import SelectFieldComplex, { SelectFieldData } from 'components/form/SelectFieldComplex'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  currency: string
  onReset: VoidFunction
}

interface Props extends FormikProps<GetSuspenseTransactionsRequest>, OwnProps {}

const SearchContainerGrid = styled(Grid)`
  margin: 16px;
  padding-top: 8px;
  width: auto;
`
const BottomButtonContainer = styled(Grid)`
  margin: 2px 8px 2px 2px;
`

const StyledRefresh = styled(Refresh)`
  font-size: 24px;
  margin-right: 8px;
`

const selectSettle: SelectFieldData = {}
Object.entries(TransactionStatus).forEach(([key, value]) => {
  selectSettle[key] = { name: value }
})
const selectCreditOrDebit: SelectFieldData = {}
Object.entries(CreditOrDebit).forEach(([key, value]) => {
  selectCreditOrDebit[key] = { name: value }
})

const emptyStringToNull = (value: string, originalValue: string) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

const SearchForm = (props: Props) => {
  const { t } = useTranslation()

  const stopPrevent = (e: React.MouseEvent) => {
    e.preventDefault()
    props.submitForm()
  }
  const filterOptions = statusFilterOptions(t).map((i) => ({
    generatedItem: i.label,
    name: !i.status ? '' : i.status.join(','),
  }))
  return (
    <Form>
      <SearchContainerGrid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        style={{ marginTop: 0 }}
      >
        <Grid xs={12} item>
          <Grid container direction="row" justifyContent="space-between" spacing={0}>
            <Grid item>
              <Typography variant="h4" variantMapping={{ h4: 'h2' }}>
                {t('Transaction & subscription identifiers')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid item xs={6}>
            <Field
              name="transactionId"
              type="text"
              placeholder={t('Enter full suspense transaction ID')}
              label={t('Suspense transaction ID')}
              component={TextFieldComplex}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid item xs={6}>
            <Field
              name="correlationId"
              type="text"
              placeholder={t('Enter full correlation ID')}
              label={t('Correlation ID')}
              component={TextFieldComplex}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid item xs={6}>
            <Field
              name="customerSubscriptionKey"
              type="text"
              placeholder={t('Enter full customer subscription key')}
              label={t('Customer subscription key')}
              component={TextFieldComplex}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid container direction="row" justifyContent="space-between" spacing={0}>
            <Grid item>
              <Typography variant="h4" variantMapping={{ h4: 'h2' }}>
                {t('Date range')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={3} item>
          <Field
            label={`${t('From')} (${toBankTz(getIn(props.values, `f_dt`)).tz})`}
            name="f_dt"
            component={DateTimePickerComponent}
            disablePast={false}
            fullWidth
            defaultIsNow={false}
            dateTimePickerProps={{
              // eslint-disable-next-line consistent-return
              shouldDisableDate: (date: moment.Moment) => {
                if (getIn(props.values, 't_dt') && getIn(props.values, 't_dt') !== '') {
                  return !(date && date.isSameOrBefore(moment(getIn(props.values, 't_dt')), 'day'))
                }
              },
            }}
          />
        </Grid>
        <Grid xs={3} item>
          <Field
            label={`${t('To')} (${toBankTz(getIn(props.values, `t_dt`)).tz})`}
            name="t_dt"
            component={DateTimePickerComponent}
            disablePast={false}
            fullWidth
            defaultIsNow={false}
            dateTimePickerProps={{
              // eslint-disable-next-line consistent-return
              shouldDisableDate: (date: moment.Moment) => {
                if (getIn(props.values, 'f_dt') && getIn(props.values, 'f_dt') !== '') {
                  return !(date && date.isSameOrAfter(moment(getIn(props.values, 'f_dt')), 'day'))
                }
              },
            }}
          />
        </Grid>
        <Grid xs={12} item>
          <Typography variant="h4" variantMapping={{ h4: 'h2' }}>
            {t('Amount & type')}
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Field
            label={t('Amount from')}
            name="f_amt"
            type="number"
            component={FieldAmount}
            fullWidth
            inputLabelProps={{ shrink: true }}
            currencyCode={props.currency}
            placeholder={t('Enter amount')}
            validate={
              getIn(props.values, 't_amt') && getIn(props.values, 't_amt') !== ''
                ? Yip(
                    Yup.number()
                      .min(0.01)
                      .nullable()
                      .transform((value: string, originalValue: string) => (originalValue.trim() === '' ? null : value))
                  )
                : Yip(
                    Yup.number()
                      .min(0.01)
                      .nullable()
                      .transform((value: string, originalValue: string) => (originalValue.trim() === '' ? null : value))
                  )
            }
          />
        </Grid>
        <Grid xs={3} item>
          <Field
            label={t('Amount to')}
            name="t_amt"
            type="number"
            component={FieldAmount}
            fullWidth
            inputLabelProps={{ shrink: true }}
            currencyCode={props.currency}
            placeholder={t('Enter amount')}
            validate={
              getIn(props.values, 'f_amt') && getIn(props.values, 'f_amt') !== ''
                ? Yip(Yup.number().min(getIn(props.values, 'f_amt')).transform(emptyStringToNull).nullable())
                : Yip(Yup.number().min(1).transform(emptyStringToNull).nullable())
            }
          />
        </Grid>
        <Grid xs={3} item>
          <Field
            label={t('Credit/debit')}
            name="cr_dr"
            data={selectCreditOrDebit}
            component={SelectFieldComplex}
            fullWidth
            placeholder={t('Please enter')}
            selectProps={{ displayEmpty: true }}
            inputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid xs={12} item>
          <Typography variant="h4" variantMapping={{ h4: 'h2' }}>
            {t('Status')}
          </Typography>
        </Grid>
        <Grid xs={6} item>
          <Field
            label={t('Status')}
            name="transactionStatus"
            data={filterOptions}
            component={SelectFieldComplex}
            fullWidth
            placeholder={t('Please enter')}
            selectProps={{ displayEmpty: true }}
            inputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid xs={12} item>
          <BottomButtonContainer container spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Button
                color={ColorType.BUTTON}
                onClick={() => {
                  props.setValues(props.initialValues, false)
                  props.onReset()
                }}
                aria-label="reset account-transaction list"
                aria-controls="mui-p-47417-T-search"
              >
                <StyledRefresh /> {t('Reset')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                disabled={!isEmpty(props.errors)}
                onClick={stopPrevent}
                color={ColorType.BUTTON}
                type="submit"
                aria-controls="account-transactions-section"
              >
                {t('Search')}
              </Button>
            </Grid>
          </BottomButtonContainer>
        </Grid>
      </SearchContainerGrid>
    </Form>
  )
}

export default withFormikSimple<OwnProps, GetSuspenseTransactionsRequest>(SearchForm, {})
