import React from 'react'
import { FieldProps } from 'formik'
import FormGroup, { FormGroupProps as MuiFormGroupProps } from '@material-ui/core/FormGroup'
import { Box, FormControlLabel, Checkbox } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { omit } from 'lodash'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'

export interface SwitchGroupFieldData {
  [key: string]: { name: string | number; label?: string; disabled?: boolean; tooltip?: string } | string
}

export interface ResetFormValues {
  fieldName: string
  newValues: string[] // can be anything
}

export interface SwitchGroupFieldComplexProps {
  required?: boolean
  switchGroupProps?: Omit<MuiFormGroupProps, 'value'>
  data: SwitchGroupFieldData
}

const StyledFormGroup = styled(FormGroup)`
  margin-left: 8px;
`

type Props = FieldProps & SwitchGroupFieldComplexProps & EnhancedFormikFieldProps
const SwitchGroupFieldComplex = (props: Props) => {
  const { switchGroupProps, data, field, form, inputLabelProps, children, ...otherProps } = props

  const { t } = useTranslation()

  const setNewFormValues = (fieldName: string, newValues: string[]) => {
    form.setFieldValue(fieldName, newValues)
    form.setFieldTouched(fieldName, false, false)
  }

  const handleOnClick = (key: string) => () => {
    let value = [...(field.value || [])]
    if (value.includes(key)) {
      value = value.filter((i: string) => i !== key)
    } else {
      value.push(key)
    }

    setNewFormValues(field.name, value)
  }
  const _otherProps = omit(otherProps, ['formControlProps'])
  return (
    <EnhancedFormikField {...props} inputLabelProps={inputLabelProps} required={false}>
      {() => (
        <StyledFormGroup {..._otherProps} {...switchGroupProps}>
          {Object.entries(data).map(([key, item]) => {
            const switchComp = (
              <Checkbox
                value={typeof item === 'string' ? item : item.name}
                onClick={handleOnClick(key)}
                checked={(field.value || []).includes(key)}
              />
            )
            return (
              <Box display="flex" alignItems="center" key={key}>
                <FormControlLabel
                  control={switchComp}
                  label={typeof item !== 'string' && item.label ? t(item.label) : t(key)}
                  disabled={typeof item === 'object' ? item.disabled : false}
                />
              </Box>
            )
          })}
          {children}
        </StyledFormGroup>
      )}
    </EnhancedFormikField>
  )
}
export default SwitchGroupFieldComplex
