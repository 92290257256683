import React from 'react'
import { connect } from 'react-redux'
import NotificationSnackbar from './NotificationSnackbar'
import NotificationActions from 'store/notifications/actions'
import NotificationSelectors from 'store/notifications/selectors'
import { RootState } from 'store'

const mapStateToProps = (state: RootState) => ({
  notification: NotificationSelectors.selectNotification(state),
})
const mapDispatchToProps = {
  closeNotification: NotificationActions.closeNotification,
}

type OwnProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const NotificationBar = ({ notification, closeNotification }: OwnProps) => (
  <NotificationSnackbar notification={notification} closeNotification={closeNotification} />
)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar)
