import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import styled from 'styled-components/macro'
import useStatementConfiguration from '../utils/useStatementConfiguration'
import { generateFrequencyPeriodText } from '../utils/transformers'
import useInterestStatementTemplate from '../utils/useInterestStatementTemplate'
import StatementConfigurationSampleImage from '../update/components/StatementConfigurationSampleImage'
import { Paper, H1, H2, FeatureDefinitions, DesignIconButton } from 'dls'
import { DefinitionRowItem } from 'dls/molecules/FeatureDefinitions/types'
import { Loader } from 'components'
import { Flexbox } from 'pages/Layout/Styled'
import { ProductSegment, StatementType } from 'store/products/types'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'
import ProductUsageSectionCard from 'pages/Product/_shared/ProductUsageSectionCard'
import ContentContainer from 'pages/Layout/ContentContainer'
import PreviewConfigurationDialog from 'pages/features/Statements/PreviewConfigurationDialog'

const DetailsGrid = styled(Grid)`
  order: 2;
  ${({ theme }) => `
      ${theme.breakpoints.up('sm')} {
        order: 1;
      }
  `};
`

const PreviewGrid = styled(Grid)`
  order: 1;
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      order: 2;
    }

  `}
`

const SingleStatementConfigurationPage = (): React.ReactElement | null => {
  const { t } = useTranslation()
  const [isPreviewTemplateOpen, setPreviewTemplateOpen] = useState<boolean>(false)
  const prefix = 'STATEMENT_CONFIGURATION'
  const { statementConfigKey } = useParams()
  const navigate = useNavigate()
  const {
    fetchStatementConfigurations,
    statementConfigurations,
    statementConfigurationsLoading,
    fetchStatementProducts,
    statementProductsLoading,
    statementProductsError,
    selectedStatementProducts,
  } = useStatementConfiguration()

  useEffect(() => {
    fetchStatementConfigurations()
  }, [])

  const selectedConfiguration = statementConfigurations.find(
    (config) => config.statementConfigKey === statementConfigKey
  )
  const segment = selectedConfiguration?.productSegment as ProductSegment

  const { templates } = useInterestStatementTemplate(ProductSegment[segment])
  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Templates'), path: 'templates/' },
      { title: t('Interest statements'), path: 'templates/statement-configurations/' },
      { title: t(`${selectedConfiguration?.name}`), path: '' },
    ],
    trigger: !!selectedConfiguration,
  })

  if (statementConfigurationsLoading) {
    return <Loader />
  }

  if (!statementConfigurationsLoading && !selectedConfiguration) {
    navigate('not-found')
  }

  if (selectedConfiguration) {
    const {
      name,
      statementDescription,
      productSegment,
      offsetDays,
      startDay,
      startMonth,
      statementPeriod,
      template,
    } = selectedConfiguration

    const selectedTemplate = templates.find((el) => el.templateGroupId === template?.id) || {
      templateGroupId: '',
      templateName: '',
      isDefault: false,
    }

    const configurationDefinitions: DefinitionRowItem[] = [
      { term: t(`${prefix}.STATEMENT_NAME`), definition: [name] },
      { term: t(`${prefix}.DESCRIPTION`), definition: [statementDescription] },
      { term: t(`${prefix}.SEGMENT`), definition: [t(productSegment)], hasSectionSeparator: true },
      {
        term: t(`${prefix}.STATEMENT_GENERATION`),
        definition: [
          generateFrequencyPeriodText({ period: statementPeriod, day: startDay, month: startMonth ?? 0, t }),
        ],
      },
      {
        term: t(`${prefix}.OFFSET`),
        definition: [t(`${prefix}.OFFSET_NUMBER`, { s: offsetDays > 1 ? 's' : '', number: offsetDays })],
      },
    ]

    return (
      <ContentContainer data-test-id="single-configuration-statement-page">
        <Flexbox direction="column" width="100%" justifyContent="center" alignItems="center">
          <Flexbox direction="column" width="100%">
            <H1 gutter>{name}</H1>
            <H2 gutter>{t(`${prefix}.STATEMENT_CONFIGURATION`)}</H2>
            <Paper>
              <Grid container direction="row" spacing={3}>
                <DetailsGrid item xs={12} sm={8}>
                  <Flexbox direction="column" justifyContent="space-between" height="100%" css="flex-grow: 2;">
                    <FeatureDefinitions definitions={configurationDefinitions} />
                    <Flexbox direction="row" justifyContent="flex-end">
                      <DesignIconButton onClick={() => navigate('edit')} />
                    </Flexbox>
                  </Flexbox>
                </DetailsGrid>
                <PreviewGrid item xs={12} sm={4}>
                  <StatementConfigurationSampleImage
                    template={selectedTemplate}
                    templateType={StatementType.INTEREST_STATEMENT}
                    productSegment={ProductSegment[productSegment]}
                    previewTemplateOpen={() => setPreviewTemplateOpen(true)}
                  />
                </PreviewGrid>
              </Grid>
              {isPreviewTemplateOpen && (
                <PreviewConfigurationDialog
                  statementConfig={selectedConfiguration}
                  title={name}
                  open={isPreviewTemplateOpen}
                  onClose={() => setPreviewTemplateOpen(false)}
                  productSegment={ProductSegment[productSegment]}
                  templateType={StatementType.INTEREST_STATEMENT}
                />
              )}
            </Paper>
            <Box mt={3}>
              <ProductUsageSectionCard
                loading={statementProductsLoading}
                error={statementProductsError}
                getProducts={fetchStatementProducts}
                products={selectedStatementProducts}
                entityId={statementConfigKey || 'id'}
                entityName="statement"
                disableCardBorder
                shouldLinkProducts
              />
            </Box>
          </Flexbox>
        </Flexbox>
      </ContentContainer>
    )
  }

  return null
}

export default SingleStatementConfigurationPage
