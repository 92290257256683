import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { isFeatureAllowedByConfig } from '../../Product/product.utils'
import CreditLimitForm from './CreditLimitForm'
import { selectConfig } from 'store/tenant-config/selectors'
import { Feature } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'

const CreditLimitFormPage = (): React.ReactElement => {
  const tenantConfig = useSelector(selectConfig)
  const { productKey = '' } = useParams()
  const navigate = useNavigate()

  useUpdateBreadcrumbs({ productKey, context: Feature.CREDIT_LIMIT })

  if (!isFeatureAllowedByConfig(tenantConfig, Feature.CREDIT_LIMIT)) navigate(`./../not-allowed`)

  return <CreditLimitForm />
}

export default CreditLimitFormPage
