import React from 'react'
import { useTranslation } from 'react-i18next'
import { ColorType, ImageSize } from 'dls/shared/types'
import { ArrowBackIcon } from 'dls/atoms/Icons/MUIIcons'
import { Button } from 'dls/atoms/Button'

interface ButtonProps {
  onPressBack?: () => void
}

const BackButton = ({ onPressBack }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      color={ColorType.BUTTON}
      onClick={onPressBack}
      startIcon={<ArrowBackIcon size={ImageSize.SMALL} color={ColorType.BUTTON} />}
    >
      {t('Back')}
    </Button>
  )
}

export default BackButton
