import { useDispatch, useSelector } from 'react-redux'
import { toFeeRewardPayload } from './transformers'
import { selectConfig } from 'store/tenant-config/selectors'
import { ProductKey, Product, FeeRewardFeatureType } from 'store/products/types'
import FeeRewardActions from 'store/products/features/feesRewards/actions'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import { FeeRewardFormValues, FeeReward } from 'store/products/features/feesRewards/types'

const useFeesRewards = ({
  productKey,
  product,
  featureType,
}: {
  productKey: ProductKey
  product?: Product
  featureType: FeeRewardFeatureType
}) => {
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectConfig)

  const submitFeeReward = (entity: FeeRewardFormValues) => {
    if (product) {
      dispatch(
        FeeRewardActions.setFeeReward({
          ...toFeeRewardPayload({ ...entity }),
          productKey,
          version: product.version,
          featureType,
        })
      )
    }
  }

  const removeFeeReward = (entity: FeeReward, isLast: boolean) => {
    if (product && entity.id) {
      dispatch(
        FeeRewardActions.removeFeeReward({
          ...entity,
          id: entity.id,
          isLast,
          productKey,
          version: product.version,
          featureType,
        })
      )
    }
  }

  return {
    isNotAllowed: !isFeatureAllowedByConfig(tenantConfig, featureType),
    submitFeeReward,
    removeFeeReward,
  }
}

export default useFeesRewards
