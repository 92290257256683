import React from 'react'
import ContextualHelp from './ContextualHelp'
import { Flexbox } from 'pages/Layout/Styled'

type Props = {
  title: React.ReactElement
  headerIcon?: React.ReactElement
  helpText?: string
  iconJustify?: 'flex-start' | 'flex-end' | 'stretch' | 'center' | 'space-between'
}

// Figma name: m_card_header
const CardHeader = ({ title, headerIcon, helpText, iconJustify }: Props): React.ReactElement => (
  <Flexbox data-dls="molecule-card-header" direction="row" width="100%">
    {headerIcon && (
      <Flexbox direction="column" justifyContent={iconJustify || 'center'}>
        {headerIcon}
      </Flexbox>
    )}
    <Flexbox
      direction="row"
      style={{
        paddingLeft: headerIcon ? 12 : 0,
        flexGrow: 2,
      }}
    >
      <Flexbox direction="column" justifyContent="center" style={{ marginTop: 3 }}>
        {title}
      </Flexbox>
      {helpText && <ContextualHelp title={helpText} style={{ margin: '4 0 0 12' }} />}
    </Flexbox>
  </Flexbox>
)

export default CardHeader
