import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TaxRate from './TaxRate'
import { EntityBadgeTaxRules } from 'dls/atoms/EntityBadges/EntityBadge'
import EntitySectionCard from 'pages/Layout/EntityReviewPageLayout/EntitySectionCard'
import { getTaxRuleConditionsText } from 'utils/referenceData.utils'
import { toBankTz } from 'utils/date.utils'
import { WithholdingRule, WithholdingTaxRule } from 'store/withholdingTax/types'
import { Flexbox } from 'pages/Layout/Styled'
import { selectCurrencySymbol } from 'store/tenant-config/selectors'
import { Body1, ReviewRows } from 'dls'
import { ReviewRowGroup, Separator } from 'dls/molecules/ReviewRows/types'

interface Props {
  selectedTaxRule: WithholdingRule
  mostRecentPastRule: WithholdingTaxRule
  nearestFutureRule: WithholdingTaxRule
  isRuleLive: boolean
}

const CurrentTaxRuleSectionCard = ({ selectedTaxRule, mostRecentPastRule, nearestFutureRule, isRuleLive }: Props) => {
  const { t } = useTranslation()
  const effectiveRule = mostRecentPastRule || nearestFutureRule
  const currencySymbol = useSelector(selectCurrencySymbol)

  const taxRuleDefinitions: ReviewRowGroup[] = [
    {
      groupSeparator: 'light' as Separator,
      definitions: [
        {
          term: t('If'),
          definitions:
            [
              effectiveRule?.facts?.map((fact, index) => (
                <div key={index}>
                  {index > 0 && t(`And `)}
                  {`${t(fact.name)} is ${fact.operator === 'IS' ? '' : t(fact.operator)}`}{' '}
                  {fact.value.map((value, ruleValueKey) =>
                    getTaxRuleConditionsText(fact, ruleValueKey, t, value, currencySymbol || '')
                  )}
                </div>
              )),
            ]?.filter((i) => i) ?? [],
        },
        {
          term: t('Then'),
          definitions: [effectiveRule?.withholdTax ? t(`Withhold tax`) : t(`Don't withhold tax`)],
        },
      ],
    },
    {
      definitions: [
        {
          term: t('Effective date'),
          definitions: [effectiveRule && toBankTz(effectiveRule.effectiveDate.toString()).date],
        },
        ...(effectiveRule.withholdTax
          ? [
              {
                term: t('Calculation method'),
                definitions: [<div key="witholdtax">{t(effectiveRule.tierBandCalcMethod)}</div>],
              },
            ]
          : []),
        ...(effectiveRule.withholdTax
          ? [
              {
                term: t('Tax rate'),
                definitions: [
                  <TaxRate
                    key="tax rate"
                    taxRule={effectiveRule}
                    ruleId={selectedTaxRule.ruleId}
                    disableEdit={isRuleLive}
                  />,
                ],
              },
            ]
          : []),
      ].filter((i) => i),
    },
  ]

  return (
    <EntitySectionCard
      headline="Tax rule details"
      title={<Body1>{t(`Details and ${mostRecentPastRule ? 'current' : 'next effective'} rate`)}</Body1>}
      headerIcon={<EntityBadgeTaxRules style={{ marginTop: -4 }} />}
      accordionDisabled
    >
      <Flexbox direction="column" p={24} pt={8}>
        <ReviewRows definitionsList={taxRuleDefinitions} />
      </Flexbox>
    </EntitySectionCard>
  )
}

export default CurrentTaxRuleSectionCard
