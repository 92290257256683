import React from 'react'
import { FieldProps } from 'formik'
import { InputProps as MuiInputProps } from '@material-ui/core/Input'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { omit } from 'lodash'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'
import FastInputField from 'components/form/FastInputField'

export interface TextFieldComplexOwnProps {
  required?: boolean
  positiveIntegersOnly?: boolean
  InputProps?: MuiInputProps
  type?: string
  value?: string | number
  numberFormatProps?: NumberFormatProps
  leadingElement?: React.ReactNode
}

export type TextFieldComplexProps = FieldProps & TextFieldComplexOwnProps & EnhancedFormikFieldProps & MuiInputProps

export function NumberFormatCustom(props: {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  valueType: 'formattedValue' | 'floatValue' | 'value'
  inputProps?: NumberFormatProps
  name: string
}) {
  const { inputRef, onChange, inputProps = {}, name, ...other } = props
  return (
    <NumberFormat
      {...omit(other, ['onChange'])}
      {...omit(inputProps, ['css'])}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({ target: { name, value: values[inputProps.valueType || 'value'] as string } })
      }}
    />
  )
}

const TextFieldComplex = ({
  helperText,
  InputProps,
  field,
  form,
  inputLabelProps,
  type,
  numberFormatProps,
  onChange,
  leadingElement,
  ...otherProps
}: TextFieldComplexProps) => {
  const isTypeCurrencyOfNumberFormat = type === 'currency' || numberFormatProps

  return (
    <EnhancedFormikField
      {...otherProps}
      field={field}
      form={form}
      helperText={helperText}
      inputLabelProps={{ ...inputLabelProps, shrink: true }}
    >
      {({ isDisabled, hasError }) => (
        <>
          {leadingElement}
          <FastInputField
            {...otherProps}
            id={field.name}
            {...field}
            required={otherProps.required}
            {...InputProps}
            disabled={isDisabled}
            error={hasError}
            {...(isTypeCurrencyOfNumberFormat
              ? {
                  inputProps: {
                    inputProps: {
                      ...numberFormatProps,
                      name: field.name,
                      onChange,
                      valueType: type === 'number' ? 'floatValue' : 'value',
                    },
                  },
                  inputComponent: NumberFormatCustom as React.FunctionComponent,
                }
              : { type })}
          />
        </>
      )}
    </EnhancedFormikField>
  )
}
export default TextFieldComplex
