import React from 'react'
import { useTranslation } from 'react-i18next'
import CreateProductDialog from './CreateProductDialog/CreateProductDialog'
import useProductFilter from './hooks/useProductFilter'
import { Product } from 'store/products/types'
import { ProductCard, Catalog, FilterMenu } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import { InfoIconButton } from 'dls'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'

interface Props {
  products: Product[]
  statusQueryParam: string
}

const Products = (props: Props): React.ReactElement => {
  const { products, statusQueryParam } = props
  const { t } = useTranslation()

  const { selectedFilter, setSelectedFilter, filteredItems, statusFilterOptions } = useProductFilter(
    products,
    statusQueryParam
  )

  const { showInfographicModal } = useModal()

  return (
    <ContentContainer maxWidth="lg" data-test-id="products-page">
      <Catalog
        entityName="Products"
        list={filteredItems}
        action={<CreateProductDialog />}
        searchFilter={(item, text) =>
          item.productName.toLowerCase().includes(text) || item.productKey.toLowerCase().includes(text)
        }
        icons={[
          <FilterMenu
            key="prod-filter"
            filter={selectedFilter}
            setFilter={setSelectedFilter}
            filterOptions={statusFilterOptions}
            showAllButton={false}
            ariaControls="products-list-section"
            ariaLabel="filter products by status"
          />,
          <InfoIconButton
            key="more-about-icon"
            ariaProps={{
              'aria-label': `${t('About products')}`,
              'aria-haspopup': 'dialog',
              'aria-controls': 'more-about-dialog',
            }}
            onClick={() => showInfographicModal(HelpModalType.PRODUCTS)}
            fontSize="medium"
          />,
        ]}
        placeHolder={t('Search by name or key')}
        cardRenderer={(list, idx, key) => (
          <CatalogCardContainer key={`${key}-catalog-container`}>
            <ProductCard key={key} product={list[idx]} />
          </CatalogCardContainer>
        )}
      />
    </ContentContainer>
  )
}

export default Products
