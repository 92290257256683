import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { StatementConfigState } from './types'
import { Statement, StatementType } from 'store/products/types'

const initialState: StatementConfigState = {
  statementConfigurations: [],
  loading: false,
  draftStatementConfigurationFormValues: null,
  error: undefined,
  selectedStatementProducts: {},
  selectedStatementProductsLoading: false,
  selectedStatementProductsError: false,
  draftStatementsForm: undefined,
}

const reducer = createReducer<StatementConfigState>(initialState, {
  [actions.fetchStatementConfigurations.type]: (state: StatementConfigState) => {
    state.statementConfigurations = []
    state.loading = true
  },
  [actions.fetchStatementConfigurationSuccess.type]: (
    state: StatementConfigState,
    action: ReturnType<typeof actions.fetchStatementConfigurationSuccess>
  ) => {
    state.statementConfigurations = action.payload
    state.loading = false
    state.error = undefined
  },
  [actions.fetchStatementConfigurationError.type]: (
    state: StatementConfigState,
    action: ReturnType<typeof actions.fetchStatementConfigurationError>
  ) => {
    state.error = action.payload
    state.loading = false
  },
  [actions.saveDraftStatementConfigurationFormValues.type]: (
    state: StatementConfigState,
    action: ReturnType<typeof actions.saveDraftStatementConfigurationFormValues>
  ) => {
    state.draftStatementConfigurationFormValues = action.payload
  },
  [actions.saveDraftStatementConfigurationTemplate.type]: (
    state: StatementConfigState,
    action: ReturnType<typeof actions.saveDraftStatementConfigurationTemplate>
  ) => {
    if (state.draftStatementConfigurationFormValues) {
      state.draftStatementConfigurationFormValues.template = action.payload
    }
  },
  [actions.deleteDraftStatementConfigurationFormValues.type]: (state: StatementConfigState) => {
    state.draftStatementConfigurationFormValues = null
  },
  [actions.deleteDraftStatementConfigurationTemplate.type]: (state: StatementConfigState) => {
    if (state.draftStatementConfigurationFormValues) {
      state.draftStatementConfigurationFormValues.template = null
    }
  },
  [actions.getStatementProducts.type]: (state: StatementConfigState) => {
    state.selectedStatementProductsLoading = true
    state.selectedStatementProductsError = false
    state.selectedStatementProducts = {}
  },

  [actions.getStatementProductsSuccess.type]: (
    state: StatementConfigState,
    action: ReturnType<typeof actions.getStatementProductsSuccess>
  ) => {
    state.selectedStatementProductsLoading = false
    state.selectedStatementProductsError = false
    state.selectedStatementProducts = {
      ...state.selectedStatementProducts,
      [action.payload.key]: action.payload.products,
    }
  },
  [actions.getStatementProductsError.type]: (state: StatementConfigState) => {
    state.selectedStatementProductsLoading = false
    state.selectedStatementProductsError = true
    state.selectedStatementProducts = {}
  },
  [actions.updateSelectedStatementKey.type]: (
    state: StatementConfigState,
    action: ReturnType<typeof actions.updateSelectedStatementKey>
  ) => {
    state.draftStatementsForm = state.draftStatementsForm
      ? {
          statementValue: state.draftStatementsForm?.statementValue?.map((statement: Statement) => {
            if (statement.statementType === StatementType.INTEREST_STATEMENT) {
              return {
                ...statement,
                statementConfigKey: action?.payload?.statementConfigurationKey,
              }
            }
            return statement
          }),
        }
      : undefined
  },
  [actions.updateDraftStatementFormValues.type]: (
    state: StatementConfigState,
    action: ReturnType<typeof actions.updateDraftStatementFormValues>
  ) => {
    state.draftStatementsForm = action.payload?.statementValue ? action.payload : undefined
  },
})

export default reducer
