import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { InfoOutlined } from '@material-ui/icons'
import useFeesRewards from '../../utils/useFeesRewards'
import FeeRewardCard from './components/FeeRewardCard'
import { Body2, Loader, FormAddFeatureButton, ZeroStateLandingPage } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Feature, FeeRewardFeatureType } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { NoFees, NoRewards } from 'assets'
import { LinkWithText } from 'dls/molecules/Buttons'
import { InfoIconButton, TemplateForm, Paper } from 'dls'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'
import { EntityBadgeFees, EntityBadgeRewards } from 'dls/atoms'
import { AvatarSize } from 'dls/shared/types'
import { FeeReward } from 'store/products/features/feesRewards/types'

const ViewFeesRewardsPage = ({ featureType }: { featureType: FeeRewardFeatureType }) => {
  const { t } = useTranslation()
  const { productKey = '' } = useParams()
  const navigate = useNavigate()
  const { showInfographicModal } = useModal()

  const [product, isLoading] = useSelectedProduct(productKey)

  const { removeFeeReward } = useFeesRewards({
    productKey,
    product,
    featureType,
  })

  useUpdateBreadcrumbs({ productKey, context: featureType })

  if (isLoading || !product?.[featureType]) {
    return <Loader />
  }

  const entities: FeeReward[] = product[featureType]?.[featureType]

  const helpModalType = featureType === Feature.FEES ? HelpModalType.FEES : HelpModalType.REWARDS
  const entityName = featureType === Feature.FEES ? 'fee' : 'reward'
  return (
    <ContentContainer>
      <TemplateForm
        title={t(`PRODUCT_FEATURE.${featureType}.FEATURE_NAME`)}
        description={t(`PRODUCT_FEATURE.${featureType}.REVIEW_DESCRIPTION`)}
        main={
          <>
            {entities?.length ? (
              entities.map((entity) => (
                <FeeRewardCard
                  featureType={featureType}
                  key={entity.id}
                  entity={entity}
                  currencyCode={product.currency?.currencyCode}
                  removeEntity={() => removeFeeReward(entity, entities.length === 1)}
                />
              ))
            ) : (
              <Paper responsivePadding>
                <ZeroStateLandingPage
                  image={featureType === Feature.FEES ? NoFees : NoRewards}
                  title={`No ${featureType} have been created`}
                  fixHeight={false}
                  imageAlt={t(
                    featureType === Feature.FEES
                      ? `Illustration of a lady in front of a calendar holding a fee sign`
                      : 'Illustration of a Product Owner configuring a reward in Bank Manager'
                  )}
                >
                  <Body2 style={{ marginTop: -10 }}>{t(`Select “Add ${entityName}" above to get started`)}</Body2>
                  <Body2 style={{ marginBottom: 40 }}>
                    <LinkWithText
                      onClick={() => {
                        showInfographicModal(helpModalType)
                      }}
                      aria-haspopup="dialog"
                      aria-controls="more-about-dialog"
                      endIcon={<InfoOutlined />}
                    >
                      {t(`More about ${featureType}`)}
                    </LinkWithText>
                  </Body2>
                </ZeroStateLandingPage>
              </Paper>
            )}
          </>
        }
        icon={
          featureType === Feature.FEES ? (
            <EntityBadgeFees size={AvatarSize.LARGE} />
          ) : (
            <EntityBadgeRewards size={AvatarSize.LARGE} />
          )
        }
        onCancel={() => navigate(`/products/${productKey}`)}
        toolbarButtons={
          <Grid container direction="row" justifyContent="flex-end" wrap="nowrap" alignItems="center">
            <Grid item>
              <InfoIconButton
                key="more-about-icon"
                ariaProps={{
                  'aria-label': `${t(`More about ${featureType}`)}`,
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                }}
                onClick={() => showInfographicModal(helpModalType)}
                fontSize="medium"
              />
            </Grid>
            <Grid item>
              <FormAddFeatureButton
                addLabel={t(`PRODUCT_FEATURE.${featureType}.ADD_ENTITY`)}
                onClick={() => navigate(`/products/${productKey}/features/${featureType}/add`)}
                elements={{}}
                disableDropdown
              />
            </Grid>
          </Grid>
        }
        CTAHidden
      />
    </ContentContainer>
  )
}

export default ViewFeesRewardsPage
