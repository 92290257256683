import { v4 as uuidv4 } from 'uuid'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { CreditLimitResponse } from 'store/products/typings/creditLimit'
import { FeaturePostResponse, ProductKey } from 'store/products/types'

async function updateCreditLimit(productKey: ProductKey, version: number, payload: CreditLimitResponse) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).put<FeaturePostResponse>(
    `/v1/products/${productKey}/versions/${version}/features/minMaxCreditLimit/v1`,
    payload,
    {
      headers: {
        'Idempotency-Key': uuidv4(),
      },
    }
  )
  return data
}

async function deleteCreditLimit(productKey: ProductKey, version?: number) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).delete(
    `/v1/products/${productKey}/versions/${version || 1}/features/minMaxCreditLimit/v1`
  )
  return data
}

export default {
  updateCreditLimit,
  deleteCreditLimit,
}
