import { createSelector } from 'reselect'
import jwt from 'jsonwebtoken'
import { UserInformation } from './types'
import { RootState } from 'store'

export const selectUser = (state: RootState) => state.user
export const selectAuthenticated = createSelector(selectUser, (state) => state.authenticated)
export const selectError = createSelector(selectUser, (state) => state.error)
export const selectData = createSelector(selectUser, (state) => state.data)
export const authType = createSelector(selectUser, (state) => state.authType)

export const selectAccessToken = createSelector(selectData, (state) => state && state.accessToken)

export const selectTenxToken = createSelector(selectData, (state) => state && state.tenxToken)

export const selectUserInformation = createSelector(
  selectData,
  (state) => state && (jwt.decode(state.tenxToken) as UserInformation)
)
export const selectRefreshToken = createSelector(selectData, (state) => state && state.refreshToken)

export const selectUsers = createSelector(selectUser, (state) =>
  [...(state.users ?? [])].sort((a, b) => {
    const aName = `${a.profile.firstName} ${a.profile.lastName}`.toLowerCase()
    const bName = `${b.profile.firstName} ${b.profile.lastName}`.toLowerCase()
    return aName < bName ? -1 : 1
  })
)

export const selectLoading = createSelector(selectUser, (state) => state.loading)
export const selectLoadingUser = createSelector(selectUser, (state) => state.loadingUser)

export const selectTeams = createSelector(selectUser, (state) => state.teams)

export const selectSelectedUser = createSelector(selectUser, (state) => state.selectedUser)

export const selectUserByKey = createSelector(
  selectUser,
  (_state: RootState, userKey: string) => userKey,
  (user, userKey) => user.users.find((userObj) => userObj.userKey === userKey) ?? user.selectedUser
)

export const selectSelectedTenantIndex = createSelector(selectUser, (state) => state.selectedTenantIndex)

export const selectUserRole = createSelector(selectData, (state) => {
  const token = state && (jwt.decode(state.tenxToken) as UserInformation)
  return token ? token.userRole || token.colleagueRole : undefined
})
