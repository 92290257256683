import { Product, ExtendedProduct } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'

export const getProductVersionURLPostfix = (product: Product) => `versions/${product.version}`

export const getPreviousVersionToGetProduct = (product: Product) =>
  `${product.version > 1 ? product.version - 1 : product.version}`

export const orderProductSecondCondition = (product: Product): number => {
  switch (product.status) {
    case ProductStatus.DESIGN:
      return 0
    case ProductStatus.UPDATED:
      return 1
    case ProductStatus.REVIEW:
      return 2
    case ProductStatus.APPROVED:
      return 3
    case ProductStatus.LIVE:
      return 4
    case ProductStatus.CLOSED:
      return 5
    default:
      return 6
  }
}

// in publishProductForm for publish effectiveDate validation
export const isVersionFirst = (product: Product): boolean => product.version === 1

export const getVersionText = (version: number): string => `v${version}`

export const isProductInGovernanceStatus = (product: ExtendedProduct) =>
  [ProductStatus.REVIEW, ProductStatus.LIVE, ProductStatus.APPROVED].includes(product.status)

export const isProductInDesign = (status: ProductStatus) =>
  [ProductStatus.DESIGN, ProductStatus.UPDATED].includes(status)
