import React from 'react'
import { FastField } from 'formik'
import { Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormikMultiSelectAutocomplete } from 'components'
import { countriesWithFlags } from 'utils/constants'

export const ResidencyEligibilityElement = ({ name }: { name: string }) => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      justifyContent="space-between"
      alignItems="flex-start"
      style={{ marginBottom: 4 }}
    >
      <Grid item xs={12}>
        <Box p={1} display="flex" alignItems="flex-end">
          <FastField
            label={t('Countries of residency')}
            name={`${name}.countryIncluded`}
            data={countriesWithFlags}
            component={FormikMultiSelectAutocomplete}
            multiple
            hasFlag
          />
        </Box>
      </Grid>
    </Grid>
  )
}
