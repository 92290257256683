import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Link as RouterLink } from 'react-router-dom'
import { ProductSyndicateResponse } from 'store/products/typings/productSyndication'
import { Body1, Link } from 'dls'
import { StyledButtonWithFocusUnderline } from 'dls/molecules/Buttons'
import { InfoOutlinedIcon } from 'dls/atoms/Icons/MUIIcons'
import { ColorType } from 'dls/shared/types'
import useModal from 'components/Modal/hooks/useModal'
import { HelpModalType } from 'store/modal/types'

interface OwnProps {
  productSyndication: ProductSyndicateResponse
  productKey: string
}

const StyledWrapper = styled.div`
  width: 100%;

  dl {
    margin-bottom: 10px;
  }

  [data-dls='molecules-ReviewRow']:last-child {
    dl {
      margin-bottom: 0;
    }
  }
`
const ViewProductSyndication = (props: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const { productSyndication } = props
  const { showInfographicModal } = useModal()

  return (
    <StyledWrapper>
      <Body1 gutter>
        {t('Syndication meta data has been created. This can be viewed and managed in the')}{' '}
        <Link component={RouterLink} color="secondary" to={`/products/${productSyndication.productKey}`}>
          {t('product syndication area')}
        </Link>{' '}
        {t('of Bank Manager')}.
      </Body1>
      <StyledButtonWithFocusUnderline
        aria-controls="more-about-dialog"
        aria-haspopup="dialog"
        color={ColorType.BUTTON}
        component="a"
        disableElevation
        disableFocusRipple
        disableRipple
        tabIndex={0}
        endIcon={<InfoOutlinedIcon color={ColorType.BUTTON} />}
        onClick={() => showInfographicModal(HelpModalType.PRODUCT_SYNDICATION)}
        style={{
          backgroundColor: 'transparent',
          fontSize: 'inherits',
          margin: 0,
          padding: 0,
        }}
        variant="text"
      >
        {t('More about product syndication')}
      </StyledButtonWithFocusUnderline>
    </StyledWrapper>
  )
}

export default ViewProductSyndication
