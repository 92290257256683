import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import { FeatureFormPageProps } from '../FeaturePage.page'
import { getTaxonomyLabel } from '../../Products/CreateProductDialog/ProductCore/ViewProductCore'
import ProductCoreForm from './ProductCoreForm'
import { toProductCoreFormValues } from './productCoreTransformers'
import ProductsActions from 'store/products/actions'
import { ProductKey, UpdateProductFormValues, Feature } from 'store/products/types'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import { selectConfig } from 'store/tenant-config/selectors'
import { RootState } from 'store'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'

type OwnProps = FeatureFormPageProps

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editProduct: (productKey: ProductKey, editProductFormValue: UpdateProductFormValues) =>
    dispatch(
      ProductsActions.editProductDetails({
        ...editProductFormValue,
        productKey,
      })
    ),
})

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps

const ProductCorePage = (props: Props) => {
  const { editProduct } = props
  const { productKey = '' } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [product, isLoading] = useSelectedProduct(productKey)

  useUpdateBreadcrumbs({ productKey, context: Feature.PRODUCTCORE })

  const onClickSubmit = useCallback(
    (payload: UpdateProductFormValues) => {
      editProduct(productKey, payload)
    },
    [productKey, editProduct]
  )

  if (!isFeatureAllowedByConfig(props.tenantConfig, Feature.PRODUCTCORE)) navigate(`./../not-allowed`)

  if (isLoading || !product) {
    return <Loader />
  }

  return (
    <ProductCoreForm
      onSubmit={onClickSubmit}
      initialValues={toProductCoreFormValues(product)}
      productKey={productKey}
      type={getTaxonomyLabel(product, t)}
      isEditMode
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCorePage)
