import React, { useRef } from 'react'
import 'styled-components/macro'
import { Grid, Box } from '@material-ui/core'
import TopLink from 'components/TopLink'
import { Main } from 'dls/shared/styled'

interface OwnProps {
  main: React.ReactNode
  aside: React.ReactNode
  mainAttributes?: React.HTMLAttributes<HTMLElement>
  asideAttributes?: React.HTMLAttributes<HTMLElement>
}

const TemplateSingleColumn = ({ main, aside, mainAttributes, asideAttributes }: OwnProps) => {
  const topRef = useRef<HTMLSpanElement>(null)

  return (
    <Grid container direction="column" spacing={4} data-dls="template-single-column">
      <Grid item xs={12}>
        <Main {...mainAttributes} css={mainAttributes?.css ?? undefined}>
          <span tabIndex={-1} id="top" ref={topRef} />
          {main}
        </Main>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3} mb={3} ml={2}>
          <TopLink elementRef={topRef as any} />
        </Box>
        <aside {...asideAttributes} css={asideAttributes?.css ?? undefined}>
          {aside}
        </aside>
      </Grid>
    </Grid>
  )
}

export default TemplateSingleColumn
