import React from 'react'
import { Card } from '@material-ui/core'
import { Flexbox } from 'pages/Layout/Styled'
import { FeeReward } from 'store/products/features/feesRewards/types'
import { H5 } from 'components'
import EditDeleteCardButtonGroup from 'pages/Layout/EditDeleteCardButtonGroup'
import { buildFeeRewardDefinition } from 'pages/features/FeesRewards/utils/transformers'
import { ReviewRows } from 'dls'
import { FeeRewardFeatureType } from 'store/products/types'

const FeeRewardCard = ({
  entity,
  currencyCode,
  featureType,
  removeEntity,
}: {
  entity: FeeReward
  currencyCode: string
  featureType: FeeRewardFeatureType
  removeEntity: () => void
}) => (
  <Card style={{ marginBottom: 16 }}>
    <Flexbox direction="column" p={24}>
      <H5 variantMapping={{ h5: 'h2' }} style={{ paddingBottom: 28 }}>
        {entity.name}
      </H5>
      <ReviewRows
        definitionsList={[
          { definitions: buildFeeRewardDefinition({ feeReward: entity, currencyCode, omitName: true, featureType }) },
        ]}
      />
      <EditDeleteCardButtonGroup
        editPath={`edit/${entity.id}`}
        removeEntity={removeEntity}
        showDeleteIcon
        showEditIcon
        ariaControlsLabel="confirmation-dialog"
      />
    </Flexbox>
  </Card>
)

export default FeeRewardCard
