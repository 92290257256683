import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Packages from './Packages'
import { RootState } from 'store'
import PackagesActions from 'store/packages/actions'
import PackagesSelectors from 'store/packages/selectors'
import { Loader } from 'components/'
import { CountryIncluded, RetireOrDeleteProductOrPackage } from 'store/products/types'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPackages: () => dispatch(PackagesActions.getPackages()),
  deletePackage: (values: RetireOrDeleteProductOrPackage) => dispatch(PackagesActions.deletePackage(values)),
  setPackageName: (name: string): void => {
    dispatch(PackagesActions.packageName(name))
  },
  setPackageType: (type: string): void => {
    dispatch(PackagesActions.packageType(type))
  },
  setCountryOfResidence: (country: CountryIncluded | null): void => {
    dispatch(PackagesActions.countryOfResidence(country))
  },
  setBrandId: (brandId: string): void => {
    dispatch(PackagesActions.brandId(brandId))
  },
})

const mapStateToProps = (state: RootState) => ({
  packages: PackagesSelectors.selectAllPackages(state),
  loading: PackagesSelectors.selectLoading(state),
  currentTenant: TenantConfigSelectors.selectCurrentTenant(state),
})

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

export function PackagesPage({
  loading,
  packages,
  currentTenant,
  getPackages,
  setPackageName,
  setPackageType,
  setCountryOfResidence,
  setBrandId,
  deletePackage,
}: Props) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  useBreadcrumbs({
    breadcrumbs: [{ title: t('{{tenantName}} packages', { tenantName: currentTenant?.name }), path: '' }],
    trigger: !!currentTenant,
  })

  useEffect(() => {
    getPackages()
  }, [getPackages])

  if (loading) {
    return <Loader />
  }

  const statusParam = new URLSearchParams(searchParams).get('status') || ''

  return (
    <Packages
      packages={packages}
      setPackageName={setPackageName}
      setPackageType={setPackageType}
      setCountryOfResidence={setCountryOfResidence}
      setBrandId={setBrandId}
      deletePackage={deletePackage}
      statusQueryParam={statusParam}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesPage)
