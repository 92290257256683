import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import DataPointSelect from './components/DataPointSelect'
import FirstOperatorSelect from './components/FirstOperatorSelect'
import SecondOperatorSelect from './components/SecondOperatorSelect'
import ConditionValueInput from './components/ConditionValueInput'
import { FullScreenModal } from 'dls'
import { AvatarSize } from 'dls/shared/types'
import { EntityBadgeFees, EntityBadgeRewards } from 'dls/atoms'
import ProductsSelectors from 'store/products/selectors'
import { BuilderCondition, ConditionOperator } from 'store/products/typings/conditionBuilder'
import { toCondition } from 'pages/features/FeesRewards/utils/transformers'
import { Feature, FeeRewardFeatureType } from 'store/products/types'

interface Props {
  name: string
  featureType: FeeRewardFeatureType
  conditions: BuilderCondition[]
  defaultTransactionCodes: string
  operator: ConditionOperator
  selectedConditionIndex: number
  control: Control
  watch: UseFormWatch<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  onConditionSubmit: (condition: BuilderCondition) => void
  onClose: () => void
}

const ConditionModal = ({
  name,
  featureType,
  conditions,
  defaultTransactionCodes,
  operator,
  selectedConditionIndex,
  control,
  watch,
  setValue,
  onClose,
  onConditionSubmit,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const selectedProduct = useSelector(ProductsSelectors.selectSelectedProduct)

  const dataPoint = watch('dataPoint')
  const dataPointFirstOperator = watch('dataPointFirstOperator')
  const dataPointSecondOperator = watch('dataPointSecondOperator')
  const dataValue = watch('dataValue')

  const onSubmit = () => {
    onConditionSubmit(
      toCondition({ operator, dataPoint, dataPointFirstOperator, dataPointSecondOperator, dataValue, t, featureType })
    )
    onClose()
  }

  const onDataPointChange = (onChange: () => void) => {
    onChange()
    setValue('dataPointFirstOperator', undefined)
    setValue('dataPointSecondOperator', undefined)
    setValue('dataValue', '')
  }

  const onDataPointFirstOperatorChange = (onChange: () => void) => {
    onChange()
    setValue('dataPointSecondOperator', undefined)
    setValue('dataValue', '')
  }

  const onDataPointSecondOperatorChange = (onChange: () => void) => {
    onChange()
    setValue('dataValue', '')
  }

  const dataPointSelectLabel = useMemo(() => {
    if (operator === ConditionOperator.IF) {
      return `PRODUCT_FEATURE.${featureType}.IF`
    }

    if (operator === ConditionOperator.AND) {
      if (selectedConditionIndex === 0) {
        return `PRODUCT_FEATURE.${featureType}.IF`
      }

      return `PRODUCT_FEATURE.${featureType}.AND_IF`
    }

    return ''
  }, [operator, selectedConditionIndex])

  return (
    <FullScreenModal
      open
      id="condition-dialog"
      title={t(`PRODUCT_FEATURE.${featureType}.ENTITY_CONDITION_MODAL_HEADING`, { name: name ? `for ${name}` : '' })}
      headerIcon={
        featureType === Feature.FEES ? (
          <EntityBadgeFees size={AvatarSize.SMALL} />
        ) : (
          <EntityBadgeRewards size={AvatarSize.SMALL} />
        )
      }
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      submitButtonText="Done"
      submitButtonDisabled={!dataValue?.length}
    >
      <Grid container direction="column">
        <Grid item container direction="row" spacing={3}>
          <DataPointSelect
            conditions={conditions}
            control={control}
            watch={watch}
            selectLabel={dataPointSelectLabel}
            onChange={onDataPointChange}
            featureType={featureType}
          />
          <FirstOperatorSelect
            featureType={featureType}
            control={control}
            watch={watch}
            onChange={onDataPointFirstOperatorChange}
          />
          <SecondOperatorSelect
            featureType={featureType}
            control={control}
            watch={watch}
            onChange={onDataPointSecondOperatorChange}
          />
          <ConditionValueInput
            control={control}
            watch={watch}
            setValue={setValue}
            defaultTransactionCodes={defaultTransactionCodes}
            currencyCode={selectedProduct?.currency?.currencyCode}
            featureType={featureType}
          />
        </Grid>
      </Grid>
    </FullScreenModal>
  )
}

export default ConditionModal
