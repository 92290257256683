import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectContentsState = (state: RootState) => state.contents

const selectLoading = createSelector(selectContentsState, (state) => state.loading)

const selectRemoteDocuments = createSelector(selectContentsState, (state) => state.documents)

export default {
  selectLoading,
  selectRemoteDocuments,
}
