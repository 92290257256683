import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import SetupProductSyndicationPage from './components/SetupProductSyndicationPage'
import UpdateProductSyndicationForm from 'pages/features/ProductSyndication/components/update/UpdateProductSyndicationForm'
import CreateProductSyndicationForm from 'pages/features/ProductSyndication/components/update/CreateProductSyndicationForm'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'
import { selectConfig } from 'store/tenant-config/selectors'
import { Feature } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'

interface OwnProps {
  isUpdatePage?: boolean
  isCreatePage?: boolean
}

const ProductSyndicationPage = (props: OwnProps): React.ReactElement => {
  const { isUpdatePage, isCreatePage } = props
  const tenantConfig = useSelector(selectConfig)
  const { productKey = '' } = useParams()
  const navigate = useNavigate()

  useUpdateBreadcrumbs({ productKey, context: Feature.PRODUCT_SYNDICATION })

  if (!isFeatureAllowedByConfig(tenantConfig, Feature.PRODUCT_SYNDICATION)) navigate(`./../not-allowed`)

  if (isCreatePage) {
    return <CreateProductSyndicationForm />
  }
  if (isUpdatePage) {
    return <UpdateProductSyndicationForm />
  }
  return <SetupProductSyndicationPage />
}

export default ProductSyndicationPage
