import React from 'react'
import { FieldProps } from 'formik'
import { FormControl, FormControlLabel, Box } from '@material-ui/core'
import EnhancedFormikField, { EnhancedFormikFieldProps } from './EnhancedFormikField'
import FastCheckbox from 'components/form/FastCheckbox'

export interface CheckboxSimpleOwnProps {
  description: string
  value?: boolean
  opposite?: boolean
}

export type TextFieldComplexProps = FieldProps & CheckboxSimpleOwnProps & EnhancedFormikFieldProps

const CheckboxSimple = ({ helperText, field, form, opposite = false, ...otherProps }: TextFieldComplexProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(field.name, (opposite ? !e.target.checked : (e.target.checked as unknown)) as string)
    form.setFieldTouched(field.name, false, false)
  }

  return (
    <EnhancedFormikField {...otherProps} field={field} form={form} helperText={helperText}>
      {({ isDisabled, hasError }) => (
        <Box style={{ height: 36, paddingLeft: 12 }}>
          <FormControl>
            <FormControlLabel
              control={
                <FastCheckbox
                  {...field}
                  disabled={isDisabled}
                  error={hasError}
                  id={field.name}
                  checked={opposite ? !field.value : field.value}
                  onChange={onChange}
                />
              }
              label={otherProps.description}
            />
          </FormControl>
        </Box>
      )}
    </EnhancedFormikField>
  )
}
export default CheckboxSimple
