import styled from 'styled-components/macro'
import { CircularProgress, Dialog, Grid } from '@material-ui/core'
import zIndex from '@material-ui/core/styles/zIndex'

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`

export const ContentGridContainer = styled(Grid)`
  padding: 0;
`
export const FormBottom = styled.div`
  margin-top: -8px;
`

export const StyledDiv = styled.div`
  padding: 16px;
  text-align: center;
  margin: 100px auto;
`
export const CardPartially = styled.div`
  background-color: ${(props) => props.theme.palette.primary.light};
  padding: 16px;
`

export const ButtonProgress = styled(CircularProgress)`
  position: relative;
  color: ${(props) => props.theme.palette.secondary};
  top: 8px;
  left: -56%;
  margin-top: -12px;
  margin-right: -24px;
  z-index: ${zIndex.appBar - 1};
`

export const LoaderContainer = styled(Grid)`
  min-height: 60vh;
`
