import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { ContextualHelp, TextFieldComplex } from 'components'

const RowGridContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 40px;
  align-items: flex-end;
`
const FieldStyle = styled(Field)``
const TaskElement = ({ index }: { index: number }) => {
  const { t } = useTranslation()
  return (
    <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="flex-start">
      <Grid item xs={12} sm={6} md={6} lg={8} />
      <Grid item xs={12}>
        <RowGridContainer>
          <FieldStyle
            name={`taskDefinitions[${index}].name`}
            label={t('Task name')}
            required
            fullWidth
            inputLabelProps={{ shrink: true }}
            component={TextFieldComplex}
            placeholder={t('Enter a name')}
          />
          <ContextualHelp
            title={t(`It’s important it has a meaningful name as it
will be used when the task is assigned to
a colleague`)}
          />
        </RowGridContainer>
      </Grid>
      <Grid item xs={12}>
        <RowGridContainer>
          <FieldStyle
            label={t('Task description')}
            name={`taskDefinitions[${index}].description`}
            component={TextFieldComplex}
            fullWidth
            required
            multiline
            inputLabelProps={{ shrink: true }}
            placeholder={t('Enter a description for the task assignee')}
          />
          <ContextualHelp
            title={t(`Describe the task. It’s important that each
task has a meaningful description because
it will be used when the task is assigned to
a colleague`)}
          />
        </RowGridContainer>
      </Grid>
    </Grid>
  )
}

export default TaskElement
