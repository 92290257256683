import moment from 'moment-timezone'
import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { createBankInterestRateToPayload } from './referenceData.transformers'
import {
  BankIndexKey,
  BankInterestRate,
  BankInterestRateFormValues,
  EditOrAddIndexRate,
  GetBankInterestRate,
} from 'store/referenceData/types'
import { RateDividedByHundred } from 'utils/form.utils'

async function fetchBankInterestRate() {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<GetBankInterestRate>('/v1/interests/indexRates')
  return data.bankIndices
}

async function getIndexRateDetails(bankIndexKey: BankIndexKey) {
  const { data } = await (await createAsyncAuthAwareAxios(null)).get<BankInterestRate>(
    `/v1/interests/indexRates/${bankIndexKey}`
  )
  return data
}

async function createBankInterestRate(payload: Partial<BankInterestRateFormValues>) {
  const fixedpayload = createBankInterestRateToPayload(payload as BankInterestRateFormValues)
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<BankInterestRate>(
    '/v1/interests/indexRates',
    fixedpayload
  )
  return data
}

async function editRate(bankIndexKey: BankIndexKey, editRatePayload: EditOrAddIndexRate) {
  const sortedRate = editRatePayload.editOrAddIndexRate.rates
    ? editRatePayload.editOrAddIndexRate.rates.map((item) => {
        const newItem = { ...item }
        delete newItem.createdBy
        delete newItem.createdTimestamp
        delete newItem.effectiveDate
        newItem.indexRate = item.indexRate && RateDividedByHundred(item.indexRate, 6)
        return newItem
      })
    : []
  const fixedPayload = {
    rates: sortedRate,
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).patch<BankInterestRate>(
    `/v1/interests/indexRates/${bankIndexKey}/rates`,
    fixedPayload
  )
  return data
}

async function addRate(bankIndexKey: BankIndexKey, editRatePayload: EditOrAddIndexRate) {
  const finalRate = editRatePayload.editOrAddIndexRate.rates
    ? editRatePayload.editOrAddIndexRate.rates.map((item) => {
        const newItem = { ...item }
        delete newItem.createdBy
        delete newItem.createdTimestamp
        delete newItem.rateKey
        newItem.effectiveDate = newItem.effectiveDate
          ? moment(newItem.effectiveDate).format('YYYY-MM-DD')
          : newItem.effectiveDate
        newItem.indexRate = item.indexRate && RateDividedByHundred(item.indexRate, 6)
        return newItem
      })
    : []
  const fixedPayload = {
    rates: finalRate,
  }
  const { data } = await (await createAsyncAuthAwareAxios(null)).post<BankInterestRate>(
    `/v1/interests/indexRates/${bankIndexKey}/rates`,
    fixedPayload
  )
  return data
}

export default { fetchBankInterestRate, getIndexRateDetails, editRate, createBankInterestRate, addRate }
