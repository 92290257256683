import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectRulesState = (state: RootState) => state.taxRules

const taxRules = createSelector(selectRulesState, (state) => state.rules)
const selectedTaxRule = createSelector(selectRulesState, (state) => state.selectedRule)
const isLoading = createSelector(selectRulesState, (state) => state.isLoading)
const error = createSelector(selectRulesState, (state) => state.error)

const selectedTaxRuleProducts = createSelector(selectRulesState, (state) => state.selectedRuleProducts)
const selectedTaxRuleProductsLoading = createSelector(selectRulesState, (state) => state.selectedRuleProductsLoading)
const selectedRuleProductsError = createSelector(selectRulesState, (state) => state.selectedRuleProductsError)

export default {
  taxRules,
  isLoading,
  error,
  selectedTaxRule,
  selectedTaxRuleProducts,
  selectedTaxRuleProductsLoading,
  selectedRuleProductsError,
}
