import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CssBaseline } from '@material-ui/core'
import { createTheme, StylesProvider, ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles'
import { LocalizationProvider } from '@material-ui/pickers'
import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import styled, { ThemeProvider as SCThemeProvider } from 'styled-components/macro'
import moment from 'moment-timezone'
import MomentAdapter from '@material-ui/pickers/adapter/moment'
import * as TenantConfigSelectors from 'store/tenant-config/selectors'
import { Loader } from 'components'
import { RootState } from 'store'
import TenantConfigActions from 'store/tenant-config/actions'
import { selectConfig, selectLoading } from 'store/tenant-config/selectors'
import { getMergedThemeWithDefault, isLocalEnvironment, isSTBEnvironment } from 'utils/common.util'
import { getQuerySearchParameter } from 'utils/url.utils'

// To display dls fields only
const DebugDls = styled.span`
  [data-dls] {
    outline: 1px dotted red !important;
    overflow: visible;
    z-index: 1000;
    [data-dls]:after {
      content: attr(data-dls);
      position: absolute;
      width: 200px;
      margin-bottom: -30px;
      font-size: 12px;
      color: red;
      z-index: 1000;
      opacity: 1;
      height: auto;
      &:hover {
        opacity: 1;
      }
    }
  }
  [data-dls*='template'] {
    ${({ theme }) => `
      outline:1px solid ${theme.palette.magenta.dark} !important;
    `};
  }
  [data-dls*='template']:before {
    content: attr(data-dls);
    width: 200px;
    font-size: 17px;
    color: green;
    z-index: 1000;
    opacity: 1;
    height: auto;
    &:hover {
      opacity: 1;
    }
  }
  [data-dls*='organism'] {
    ${({ theme }) => `
      outline: 2px dashed ${theme.palette.green.dark} !important;
    `};
  }

  [data-dls*='molecule'] {
    ${({ theme }) => `
      outline: 2px dashed ${theme.palette.blue.dark} !important;
    `};
  }
  [data-dls*='atom'] {
    ${({ theme }) => `
      outline: 1px dotted ${theme.palette.orange.dark} !important;
      &:hover {
        [data-dls]:after {
          color: ${theme.palette.orange.dark} 
        }
      }
    `};
  }
`

const mapDispatchToProps = {
  loadTenantConfig: TenantConfigActions.fetch,
}

const mapStateToProps = (state: RootState) => ({
  tenantConfig: selectConfig(state),
  loading: selectLoading(state),
})

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & { children: React.ReactNode }

const TenantConfigProvider = ({
  loading,
  tenantConfig,
  loadTenantConfig,
  children,
  storybook = false,
}: React.PropsWithChildren<Props> & { storybook: boolean }) => {
  useEffect(() => {
    loadTenantConfig()
  }, [loadTenantConfig])

  const selectedTenant = useSelector(TenantConfigSelectors.selectCurrentTenant)
  const isMultiTenant = useSelector(TenantConfigSelectors.isMultiTenant)

  const themeTenant =
    tenantConfig && tenantConfig.tenants && isMultiTenant && selectedTenant && selectedTenant.theme
      ? createTheme(getMergedThemeWithDefault(selectedTenant.theme))
      : undefined

  if (loading) {
    return <Loader />
  }

  if (!tenantConfig && !storybook) {
    return <>tenantConfig not available</>
  }

  const theme = createTheme(getMergedThemeWithDefault(storybook ? ({} as any) : tenantConfig?.theme))

  const isSentryEnabled = storybook ? false : tenantConfig?.features.sentry || false

  Sentry.init({
    dsn: isSentryEnabled ? process.env.REACT_APP_SENTRY_DSN : undefined,
    integrations: [new Integrations.BrowserTracing()],
    release: `bankman@${process.env.REACT_APP_VERSION}`,
    environment: storybook ? 'local' : tenantConfig?.environment,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })

  return (
    <StylesProvider injectFirst>
      <MUIThemeProvider theme={themeTenant || theme}>
        <LocalizationProvider dateAdapter={MomentAdapter} dateLibInstance={moment}>
          <SCThemeProvider theme={themeTenant || theme}>
            <CssBaseline />
            {getQuerySearchParameter('dls-debug') === 'true' &&
            (isLocalEnvironment() || isSTBEnvironment(tenantConfig)) ? (
              <DebugDls>{children}</DebugDls>
            ) : (
              children
            )}
          </SCThemeProvider>
        </LocalizationProvider>
      </MUIThemeProvider>
    </StylesProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantConfigProvider)
