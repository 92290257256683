import React, { memo } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import get from 'lodash/get'

interface SectionArrayProps {
  name: string
  children?: (props: FieldArrayRenderProps & { index: number }) => React.ReactNode
  validateOnChange?: boolean
}

const SectionArray = ({ name, children, validateOnChange }: SectionArrayProps) => (
  <FieldArray validateOnChange={validateOnChange} name={name}>
    {(arrayHelpers) =>
      children &&
      get(arrayHelpers.form.values, name, []).map((_: unknown, index: number) =>
        children({ ...arrayHelpers, name: `${name}.${index}`, index })
      )
    }
  </FieldArray>
)

export default memo(SectionArray)
