/* eslint-disable no-nested-ternary */
import React from 'react'
import { Fade, Dialog, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { orderBy, find, isEmpty } from 'lodash'
import AssigneeModalForm from './AssigneeModalForm'
import TasksActions from 'store/tasks/actions'
import ProductActions from 'store/products/actions'
import { RootState } from 'store/types'
import TasksSelectors from 'store/tasks/selectors'
import { Task, AssignTaskPayload, AssigneeType, AssignTasksResultType } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import GovernancSelector from 'store/governance/selectors'
import { Colleague, UserInformation } from 'store/user/types'
import ProductsSelectors from 'store/products/selectors'
import { selectConfig } from 'store/tenant-config/selectors'
import { selectUserInformation } from 'store/user/selectors'
import { FailureModal, ProgressModal, TaskApprovalsSentDialog } from 'components'
import { allowSelfAssign, useUserNameWithRole } from 'utils/common.util'

const mapDispatchToProps = {
  assignTasks: TasksActions.assignTasks,
  assignTasksResultClear: TasksActions.assignTasksResultClear,
  closeAssigneeModal: ProductActions.closeAssigneeModal,
}

const mapStateToProps = (state: RootState) => ({
  tasks: TasksSelectors.selectTasksForProduct(state),
  assignees: ProductsSelectors.selectColleagues(state),
  tenantConfig: selectConfig(state),
  userInformation: selectUserInformation(state) as UserInformation,
  processExecution: GovernancSelector.selectprocessExecutions(state),
  assignTasksResult: TasksSelectors.selectAssignTasksResult(state),
  isAssigning: TasksSelectors.selectIsAssigning(state),
  openAssigneesDialog: ProductsSelectors.selectOpenAssigneeModal(state),
  product: ProductsSelectors.selectSelectedProduct(state),
})

interface AssigneeModalProps {}

interface ColleagueFormItem {
  colleague?: Colleague
  task?: Task
}

interface ColleagueFormValues {
  profile?: ColleagueFormItem[]
}

interface UserSelectData {
  [key: string]: { name: Colleague; label?: string } | string
}

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & AssigneeModalProps

const AssigneeModal = (props: Props) => {
  const { t } = useTranslation()
  const { assignTasks } = props
  const process = props.processExecution ? props.processExecution.filter((i) => i.status !== 'CLOSED')[0] : undefined
  const processExecutionKey = process ? process.key : undefined
  const getUserNameWithRole = useUserNameWithRole()

  if (!props.userInformation) return null

  const initialValues =
    !isEmpty(props.assignees) && !isEmpty(props.tasks)
      ? {
          profile: orderBy(
            props.tasks
              .map((task) => ({ colleague: find(props.assignees, { userKey: task.assigneeKey }), task }))
              .filter((task) => task),
            ['task.sequence', 'task.context'],
            ['asc', 'asc']
          ),
        }
      : {}

  const tasksData = orderBy(
    props.tasks
      .map((task) => ({ assignee: find(props.assignees, { userKey: task.assigneeKey }), task }))
      .filter((task) => task),
    ['task.sequence', 'task.context'],
    ['asc', 'asc']
  )

  let assigned = false
  if (tasksData) {
    assigned = true
    tasksData.forEach(({ task }) => {
      if (!task.assigneeKey) {
        assigned = false
      }
    })
  }
  const selectAssigneeData: UserSelectData = {}
  orderBy(props.assignees, ['profile.firstName', 'profile.lastName'], ['asc', 'asc']).forEach((item) => {
    if (props.userInformation.userKey !== item.userKey || allowSelfAssign(props.tenantConfig)) {
      selectAssigneeData[item.userKey] = { name: item, label: getUserNameWithRole(item.profile) }
    }
  })

  const taskAssisgned = (colleagues: ColleagueFormValues) => {
    if (colleagues.profile) {
      const assigns: AssignTaskPayload[] = []
      colleagues.profile.forEach((item) => {
        if (
          item.task &&
          item.task.status !== TaskStatus.CLOSED &&
          item.task.status !== TaskStatus.REJECTED &&
          (props.userInformation.userKey !== item?.colleague?.userKey ||
            (props.tenantConfig &&
              props.tenantConfig.features &&
              props.tenantConfig.features.allow_self_assign === true))
        ) {
          const newTask: AssignTaskPayload = {
            assigneeKey: item.colleague ? item.colleague.userKey : '',
            assigneeType: AssigneeType.USER,
            assigneeName: `${item.colleague ? item.colleague.profile.firstName : null}  ${
              item.colleague ? item.colleague.profile.lastName : null
            }`,
            task: item.task,
            processExecutionKey,
          }
          assigns.push(newTask)
        }
      })
      props.closeAssigneeModal()
      assignTasks(assigns)
    }
  }
  return (
    <>
      {props.tasks && props.product && !isEmpty(props.tasks) && (
        <>
          <Dialog
            open={props.openAssigneesDialog}
            scroll="paper"
            onClose={() => props.closeAssigneeModal()}
            TransitionComponent={Fade}
            PaperComponent={Paper}
            maxWidth="md"
            fullWidth
          >
            <AssigneeModalForm
              somestatus={props.product.status}
              onSubmit={taskAssisgned}
              initialValues={initialValues}
              assigned={assigned}
              selectAssigneeData={selectAssigneeData}
              closeAssigneeModal={() => props.closeAssigneeModal()}
            />
          </Dialog>
          <ProgressModal
            title={t('Sending tasks to assignees')}
            description={t('Please wait while we send the assignees a notification')}
            open={props.isAssigning}
          />
          <TaskApprovalsSentDialog
            open={props.assignTasksResult === AssignTasksResultType.SUCCESS}
            setOpen={() => {
              props.closeAssigneeModal()
              props.assignTasksResultClear()
            }}
          />
          <FailureModal
            open={props.assignTasksResult === AssignTasksResultType.FAIL}
            description={t('tryAgain')}
            setOpen={() => {
              props.closeAssigneeModal()
              props.assignTasksResultClear()
            }}
          />
        </>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AssigneeModal)
