import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { Currency } from 'store/products/types'
import { Flag } from 'components'
import { ReviewRows } from 'dls'
import getISO2from3, { ISO3CountryCodes } from 'utils/countryISO3to2'

interface OwnProps {
  currency: Currency
}

const ViewCurrencies = (props: OwnProps): React.ReactElement => {
  const { currencyCode, country } = props.currency
  const { t } = useTranslation()

  const currencyDefinitions = [
    currencyCode && {
      term: t('Currency'),
      definitions: [
        <Box key={currencyCode} display="flex">
          <Flag code={currencyCode.substr(0, 2)} size={20} />
          {`${currencyCode} - ${t(`CURRENCIES.${currencyCode}`)}`}
        </Box>,
      ],
    },
    country && {
      term: t('Country'),
      definitions: [
        <Box key={country} display="flex">
          <Flag code={getISO2from3(country as ISO3CountryCodes)} size={20} />
          {`${country} - ${t(`COUNTRIES.${country}`)}`}
        </Box>,
      ],
    },
  ].filter((item) => item)

  const currencyDetails = [
    {
      definitions: currencyDefinitions,
    },
  ]

  return <ReviewRows definitionsList={currencyDetails as any} />
}

export default ViewCurrencies
