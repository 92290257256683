import { useDispatch, useSelector } from 'react-redux'
import { selectConfig } from 'store/tenant-config/selectors'
import { Feature, ProductKey } from 'store/products/types'
import { MultiPartyFormValues } from 'store/products/typings/multiParty'
import ProductsActions from 'store/products/actions'
import { isFeatureAllowedByConfig } from 'pages/Product/product.utils'

const useMultiParty = ({ productKey, version }: { productKey: ProductKey; version?: number }) => {
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectConfig)

  const submitMultiParty = (values: MultiPartyFormValues) => {
    if (productKey && version) {
      dispatch(
        ProductsActions.setMultiParty({
          ...values,
          productKey,
          version,
        })
      )
    }
  }

  const deleteMultiParty = () => {
    if (productKey && version) {
      dispatch(ProductsActions.deleteMultiParty({ productKey, version }))
    }
  }

  return {
    isNotAllowed: !isFeatureAllowedByConfig(tenantConfig, Feature.MULTI_PARTY),
    submitMultiParty,
    deleteMultiParty,
  }
}

export default useMultiParty
