/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { Add } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import BankInterestRateCard from './view/BankInterestRateCard/BankInterestRateCard'
import { BankInterestRate } from 'store/referenceData/types'
import { IndexRatesInfoGraphic } from 'assets'
import { ZeroStateLandingPage, Catalog } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useModal from 'components/Modal/hooks/useModal'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const AddIcon = styled(Add)`
  margin-right: 8px;
`

const MainGrid = styled(Grid)`
  margin-top: 16px;
  margin-bottom: 16px;
`

const InterestRateCatalog = ({ bankInterestRates }: { bankInterestRates?: BankInterestRate[] }): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleAdd = () => {
    navigate(`/reference-data/index-rates/create`)
  }

  const { showInfographicModal } = useModal()

  return (
    <ContentContainer data-test-id="interest-rate-page">
      {!isEmpty(bankInterestRates) && bankInterestRates ? (
        <Catalog
          entityName="Index rates"
          list={bankInterestRates}
          action={
            <Button
              onClick={handleAdd}
              variant="outlined"
              color={ColorType.BUTTON}
              fullWidth
              data-test-id="add-index-rate-button"
              aria-controls="main"
            >
              <AddIcon />
              {t('Add index rate')}
            </Button>
          }
          searchFilter={(item, text) =>
            item.name.toLowerCase().includes(text) || item.abbreviation.toLowerCase().includes(text)
          }
          cardRenderer={(list, idx, key) => (
            <CatalogCardContainer>
              <BankInterestRateCard key={key} indexRate={list[idx] as BankInterestRate} />
            </CatalogCardContainer>
          )}
          icons={[
            <InfoIconButton
              key="more-about-icon"
              ariaProps={{
                'aria-label': `${t('More about index rates')}`,
                'aria-haspopup': 'dialog',
                'aria-controls': 'more-about-dialog',
              }}
              onClick={() => showInfographicModal(HelpModalType.INDEX_RATES)}
              fontSize="medium"
            />,
          ]}
        />
      ) : (
        <>
          <MainGrid container direction="row" justifyContent="space-between" spacing={1} alignItems="flex-end">
            <Grid item>
              <Typography variant="h6">{t('Index rates')}</Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-between" alignItems="flex-end">
                <InfoIconButton
                  key="more-about-icon"
                  ariaProps={{
                    'aria-label': `${t('More about index rates')}`,
                    'aria-haspopup': 'dialog',
                    'aria-controls': 'more-about-dialog',
                  }}
                  onClick={showInfographicModal}
                  color="secondary"
                  fontSize="medium"
                />
                <Grid css="margin-left:16px" item>
                  <Button
                    onClick={() => navigate('/reference-data/index-rates/create')}
                    variant="contained"
                    color={ColorType.BUTTON}
                    aria-controls="main"
                  >
                    {t('Create index rate')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MainGrid>
          <ZeroStateLandingPage
            image={IndexRatesInfoGraphic}
            title={t('No index rates have been created')}
            definition={t(
              'Here you can create and manage index rates for central banks, other bodies or your own. Once created they can be choosen as a rate source in features like credit interest'
            )}
            buttonLink={t('How index rates work')}
            description={t(`Choose ‘Create index rate’ above to get started`)}
          />
        </>
      )}
    </ContentContainer>
  )
}

export default InterestRateCatalog
