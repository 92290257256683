import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.deleteFeature.type]: (state: ProductsState) => ({
    ...state,
  }),
  [actions.deleteFeatureSuccess.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.deleteFeatureSuccess>
  ) => ({
    ...state,
    selectedProduct: action.payload,
  }),
  [actions.deleteFeatureFail.type]: (state: ProductsState, action: ReturnType<typeof actions.deleteFeatureFail>) => ({
    ...state,
    error: action.payload,
  }),
}
export default reducer
