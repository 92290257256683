import moment from 'moment-timezone'
import { isEmpty } from 'lodash'
import uuid from 'uuid-hash'
import {
  FrequencyValues,
  TopLevelFrequency,
  MonthlyFrequencyItems,
  YearlyFrequencyItems,
  FrequencyTypeValue,
  Frequency,
  FrequencyMonth,
  FrequencyDayWeek,
} from 'store/products/types'

export const getFrequencyString = (frequencyValues: FrequencyValues): Frequency | undefined => {
  if (!frequencyValues || (frequencyValues && isEmpty(frequencyValues))) {
    return undefined
  }
  if ('ROOT' in frequencyValues) {
    switch (frequencyValues.ROOT) {
      case TopLevelFrequency.ANNUAL:
        switch (frequencyValues.ANNUAL.secondary) {
          case YearlyFrequencyItems.DAY_OF_OPENING:
            // return 'ANNUAL_ANNIVERSARY_OF_ACCOUNT'
            return {
              frequencyType: FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT,
            }
          case YearlyFrequencyItems.SPECIFIC_DATE:
            // returns a string like ANNUAL_MAY_31
            return {
              frequencyType: FrequencyTypeValue.ANNUAL,
              month: moment(frequencyValues.ANNUAL.tertiary).format('MMM').toUpperCase() as FrequencyMonth,
              day: Number(moment(frequencyValues.ANNUAL.tertiary).format('D').toUpperCase() as FrequencyDayWeek),
            }

          default:
            throw new Error(`${frequencyValues.ANNUAL.secondary} is not a valid frequency selection`)
        }
      case TopLevelFrequency.MONTH:
        switch (frequencyValues.MONTH.secondary) {
          case MonthlyFrequencyItems.END:
            // return 'MONTH_END'
            return {
              frequencyType: FrequencyTypeValue.MONTH_END,
            }
          case MonthlyFrequencyItems.BEGINNING:
            // return 'MONTH_BEGINNING'
            return {
              frequencyType: FrequencyTypeValue.MONTH_BEGINNING,
            }
          case MonthlyFrequencyItems.ANNIVERSARY:
            // return 'MONTH_ANNIVERSARY_OF_ACCOUNT'
            return {
              frequencyType: FrequencyTypeValue.MONTHLY_ANNIVERSARY_OF_ACCOUNT,
            }
          case MonthlyFrequencyItems.SPECIFIC_DAY:
            // return `MONTH_${frequencyValues.MONTH.tertiary}`
            return {
              frequencyType: FrequencyTypeValue.MONTH,
              day: frequencyValues.MONTH.tertiary,
            }
          default:
            throw new Error(`${frequencyValues.MONTH.secondary} is not a valid frequency selection`)
        }
      case TopLevelFrequency.DAYS_FROM_CALCULATION:
        // return `${frequencyValues.DAYS_FROM_CALCULATION.secondary}_DAYS_FROM_CALCULATION`
        return {
          frequencyType: FrequencyTypeValue.DAYS_FROM_CALCULATION,
          day: frequencyValues.DAYS_FROM_CALCULATION.secondary,
        }
      case TopLevelFrequency.DAILY:
        // return 'DAILY'
        return {
          frequencyType: FrequencyTypeValue.DAILY,
        }
      case TopLevelFrequency.WEEKLY:
        // return `WEEKLY_${frequencyValues.WEEKLY.secondary}`
        return {
          frequencyType: FrequencyTypeValue.WEEKLY,
          dayOfWeek: frequencyValues.WEEKLY.secondary as FrequencyDayWeek,
        }
      case TopLevelFrequency.PER_OCCURRENCE:
        // return `PER_OCCURRENCE`
        return {
          frequencyType: FrequencyTypeValue.PER_OCCURRENCE,
        }
      default:
    }
  }
  if ('frequencyType' in frequencyValues) {
    return frequencyValues
  }
  throw Error(`Unable to handle frequencyValues: ${JSON.stringify(frequencyValues)}`)
}

export const getFrequencyValues = (frequencyObject: Frequency): FrequencyValues => {
  switch (frequencyObject.frequencyType) {
    case FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT:
      return {
        ROOT: TopLevelFrequency.ANNUAL,
        ANNUAL: {
          secondary: YearlyFrequencyItems.DAY_OF_OPENING,
        },
      }
    case FrequencyTypeValue.MONTH_END:
      return {
        ROOT: TopLevelFrequency.MONTH,
        MONTH: {
          secondary: MonthlyFrequencyItems.END,
        },
      }
    case FrequencyTypeValue.MONTH_BEGINNING:
      return {
        ROOT: TopLevelFrequency.MONTH,
        MONTH: {
          secondary: MonthlyFrequencyItems.BEGINNING,
        },
      }
    case FrequencyTypeValue.MONTHLY_ANNIVERSARY_OF_ACCOUNT:
      return {
        ROOT: TopLevelFrequency.MONTH,
        MONTH: {
          secondary: MonthlyFrequencyItems.ANNIVERSARY,
        },
      }
    case FrequencyTypeValue.TRIGGERED_DAILY:
      return {
        ROOT: TopLevelFrequency.DAILY,
      }
    case FrequencyTypeValue.DAILY:
      return {
        ROOT: TopLevelFrequency.DAILY,
      }
    case FrequencyTypeValue.PER_OCCURRENCE:
      return {
        ROOT: TopLevelFrequency.PER_OCCURRENCE,
      }
    case FrequencyTypeValue.DAYS_FROM_CALCULATION:
      return {
        ROOT: TopLevelFrequency.DAYS_FROM_CALCULATION,
        DAYS_FROM_CALCULATION: { secondary: Number(frequencyObject.day) },
      }
    case FrequencyTypeValue.MONTH:
      return {
        ROOT: TopLevelFrequency.MONTH,
        MONTH: { secondary: MonthlyFrequencyItems.SPECIFIC_DAY, tertiary: Number(frequencyObject.day) },
      }
    case FrequencyTypeValue.ANNUAL:
      return {
        ROOT: TopLevelFrequency.ANNUAL,
        ANNUAL: {
          secondary: YearlyFrequencyItems.SPECIFIC_DATE,
          tertiary: new Date(`${frequencyObject.month}/${frequencyObject.day}/${new Date().getFullYear()}`),
        },
      }
    case FrequencyTypeValue.WEEKLY:
      return {
        ROOT: TopLevelFrequency.WEEKLY,
        WEEKLY: { secondary: frequencyObject.dayOfWeek as FrequencyDayWeek },
      }
    default:
      return {}
  }
}

export const hashObject = (key: string, payload: Record<string, any>) => {
  let contents
  try {
    contents = JSON.stringify(payload)
  } catch (e: any) {
    contents = key
  }
  const hash = uuid.createHash().update(`${key}-${contents}`).digest()
  return hash
}
