import { RateTierBand } from 'store/products/types'

const getRateBandLabelKey = (bandTier: RateTierBand) => {
  switch (bandTier) {
    case RateTierBand.BAND:
      return 'Bands'
    case RateTierBand.TIER:
      return 'Tiers'
    case RateTierBand.FLAT:
      return 'Flat'
    default:
      return ''
  }
}

export default getRateBandLabelKey
