export enum Direction {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  BOTH = 'BOTH',
}
export enum InternalClientCode {
  VENUS = 'VEN',
  JUPITER = 'JUP',
  NEMO = 'NEM',
  OLYMPUS = 'OLY',
  ALL = 'ALL',
}
export const transactionCodes = [
  {
    domain: {
      code: 'PMNT',
      description: 'Payments',
    },
    family: {
      code: 'ICDT',
      description: 'Issued Credit Transfers',
    },
    subfamily: {
      code: 'DMCT',
      description: 'Domestic Credit Transfer',
    },
    description: 'Domestic Credit Transfer (PMNT.ICDT.DMCT)',
    direction: Direction.OUTBOUND,
    icc: InternalClientCode.VENUS,
  },
  {
    domain: {
      code: 'PMNT',
      description: 'Payments',
    },
    family: {
      code: 'RCDT',
      description: 'Received Credit Transfers',
    },
    subfamily: {
      code: 'DMCT',
      description: 'Domestic Credit Transfer',
    },
    description: 'Domestic credit transfer (PMNT.RCDT.DMCT)',
    direction: Direction.INBOUND,
    icc: InternalClientCode.VENUS,
  },
  {
    domain: {
      code: 'PMNT',
      description: 'Payments',
    },
    family: {
      code: 'RRCT',
      description: 'Received Real-Time Credit Transfers',
    },
    subfamily: {
      code: 'DMCT',
      description: 'Domestic Credit Transfer',
    },
    description: 'Real-Time Domestic Credit Transfers (PMNT.RRCT.DMCT)',
    direction: Direction.INBOUND,
    icc: InternalClientCode.VENUS,
  },
  {
    domain: {
      code: 'PMNT',
      description: 'Payments',
    },
    family: {
      code: 'IDDT',
      description: 'Issued Direct Debits',
    },
    subfamily: {
      code: 'PMDD',
      description: 'Direct Debit Payment',
    },
    description: 'Direct Debit Payment (PMNT.IDDT.PMDD)',
    direction: Direction.INBOUND,
    icc: InternalClientCode.ALL,
  },
  {
    domain: {
      code: 'CAMT',
      description: 'Cash Management',
    },
    family: {
      code: 'MCOP',
      description: 'Miscellaneous Credit Operations',
    },
    subfamily: {
      code: 'REWD',
      description: 'Cashback Earned',
    },
    description: 'Cashback earned (CAMT.MCOP.REWD)',
    direction: Direction.INBOUND,
    icc: InternalClientCode.JUPITER,
  },
  {
    domain: {
      code: 'CAMT',
      description: 'Cash Management',
    },
    family: {
      code: 'MDOP',
      description: 'Miscellaneous Credit Operations',
    },
    subfamily: {
      code: 'REWR',
      description: 'Redeem Cashback Funds to Other Internal Accounts',
    },
    description: 'Redeem cashback (CAMT.MDOP.REWR)',
    direction: Direction.OUTBOUND,
    icc: InternalClientCode.JUPITER,
  },
  {
    domain: {
      code: 'CAMT',
      description: 'Cash Management',
    },
    family: {
      code: 'MDOP',
      description: 'Miscellaneous Credit Operations',
    },
    subfamily: {
      code: 'REWD',
      description: 'Cashback Manual Adjustment (Clawback)',
    },
    description: 'Cashback adjustment/clawback (CAMT.MCOP.REWD)',
    direction: Direction.OUTBOUND,
    icc: InternalClientCode.JUPITER,
  },
  {
    domain: {
      code: 'CAMT',
      description: 'Cash Management',
    },
    family: {
      code: 'MCOP',
      description: 'Miscellaneous Credit Operations',
    },
    subfamily: {
      code: 'REWG',
      description: 'Manual Adjustment or Goodwill',
    },
    description: 'Cashback adjustment/goodwill (CAMT.MCOP.REWG)',
    direction: Direction.INBOUND,
    icc: InternalClientCode.JUPITER,
  },
]

export const routingDestinations = [
  {
    code: 'ON_ME',
    description: 'Payments to accounts held by the same party on the 10x platform (On Me)',
    direction: Direction.BOTH,
    icc: InternalClientCode.ALL,
    mapping: {
      SCHEME_NAME: ['AU_BECS_CT', 'AU_BECS_CT_R', 'TRANSFER', 'GB_FPS', 'GB_FPS_R'],
    },
  },
  {
    code: 'ON_US',
    description: 'Payments to other accounts on the 10x platform (On Us)',
    direction: Direction.BOTH,
    icc: InternalClientCode.ALL,
    mapping: {
      SCHEME_NAME: ['AU_BECS_CT', 'AU_BECS_CT_R', 'TRANSFER', 'GB_FPS', 'GB_FPS_R'],
    },
  },
  {
    code: 'OFF_US',
    description: 'Payments to accounts at a different bank (Off Us)',
    direction: Direction.BOTH,
    icc: InternalClientCode.ALL,
    mapping: {
      SCHEME_NAME: [
        'AU_BECS_CT',
        'AU_BECS_CT_R',
        'AU_BECS_DD',
        'AU_BECS_DD_R',
        'AU_NPP_SCT',
        'AU_NPP_SCT_R',
        'AU_NPP_CATSCT',
        'AU_NPP_CATSCT_R',
        'AU_NPP_X2P1',
        'AU_NPP_X2P1_R',
        'AU_BPAY',
        'GB_FPS',
        'GB_FPS_R',
        'ZA_EFT_DD',
        'ZA_VAS',
        'ZA_DBCHECK_DD',
      ],
    },
  },
  // {
  //   code: 'AU_DE',
  //   description: 'Direct Entry (DE)	',
  //   direction: Direction.BOTH,
  //   icc: InternalClientCode.VENUS,
  //   mapping: {
  //     SCHEME_NAME: ['AU_BECS_CT', 'AU_BECS_CT_R', 'AU_BECS_DD', 'AU_BECS_DD_R'],
  //   },
  // },
  // {
  //   code: 'AU_NPP',
  //   description: 'New Payments Platform (NPP)',
  //   direction: Direction.BOTH,
  //   icc: InternalClientCode.VENUS,
  //   mapping: {
  //     SCHEME_NAME: ['AU_NPP_SCT', 'AU_NPP_SCT_R', 'AU_NPP_CATSCT', 'AU_NPP_CATSCT_R', 'AU_NPP_X2P1', 'AU_NPP_X2P1_R'],
  //   },
  // },
  // {
  //   code: 'AU_BPAY',
  //   description: 'BPAY',
  //   direction: Direction.BOTH,
  //   icc: InternalClientCode.VENUS,
  //   mapping: {
  //     SCHEME_NAME: ['AU_BPAY'],
  //   },
  // },
]

export const SchemeNames = [
  {
    code: 'TRANSFER',
    description: 'Internal transfers between accounts belonging to the same party',
    direction: Direction.BOTH,
    icc: InternalClientCode.ALL,
    mapping: {
      ROUTING_DESTINATION: ['ON_US'],
    },
  },
  {
    code: 'AU_BECS_CT',
    description: 'BECS Direct Entry Credits',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['ON_US', 'OFF_US'],
    },
  },
  {
    code: 'AU_BECS_CT_R',
    description: 'BECS Direct Entry Credit Returns',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['ON_US', 'OFF_US'],
    },
  },
  {
    code: 'AU_BECS_DD',
    description: 'BECS Direct Entry Direct Debits',
    direction: Direction.INBOUND,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'AU_BECS_DD_R',
    description: 'BECS Direct Entry Direct Debit Returns',
    direction: Direction.OUTBOUND,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'AU_NPP_SCT',
    description: 'NPP Single Credit Transfers',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'AU_NPP_SCT_R',
    description: 'NPP Single Credit Transfer Returns',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'AU_NPP_CATSCT',
    description: 'NPP Category Specific Single Credit Transfers',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['AU_NPP'],
    },
  },
  {
    code: 'AU_NPP_CATSCT_R',
    description: 'NPP Category Specific Single Credit Transfer Returns',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'AU_NPP_X2P1',
    description: 'NPP Osko Credit Transfers',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'AU_NPP_X2P1_R',
    description: 'NPP Osko Credit Transfer Returns',
    direction: Direction.BOTH,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'AU_BPAY',
    description: 'BPAY Payments',
    direction: Direction.OUTBOUND,
    icc: InternalClientCode.VENUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'GB_FPS',
    description: 'Faster Payments',
    direction: Direction.BOTH,
    icc: InternalClientCode.NEMO,
    mapping: {
      ROUTING_DESTINATION: ['ON_US', 'OFF_US'],
    },
  },
  {
    code: 'GB_FPS_R',
    description: 'Faster Payments - Returns',
    direction: Direction.BOTH,
    icc: InternalClientCode.NEMO,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'GB_FPS_RV',
    description: 'Faster Payments - Reversals',
    direction: Direction.INBOUND,
    icc: InternalClientCode.NEMO,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'ZA_EFT_DD',
    description: 'South African EFT Direct Debits',
    direction: Direction.INBOUND,
    icc: InternalClientCode.OLYMPUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'ZA_VAS',
    description: 'South African Value Added Service (VAS) Payments',
    direction: Direction.INBOUND,
    icc: InternalClientCode.OLYMPUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
  {
    code: 'ZA_DBCHECK_DD',
    description: 'South African Debicheck Direct Debits',
    direction: Direction.INBOUND,
    icc: InternalClientCode.OLYMPUS,
    mapping: {
      ROUTING_DESTINATION: ['OFF_US'],
    },
  },
]

export const ProductGroups = [
  {
    code: 'CURRENT',
    description: 'Current account	',
    direction: Direction.BOTH,
    icc: InternalClientCode.JUPITER,
  },
  {
    code: 'SAVINGS',
    description: 'Savings account	',
    direction: Direction.BOTH,
    icc: InternalClientCode.JUPITER,
  },
]

export const SweepTypes = [
  {
    code: 'ROUND_UP',
    description: 'Round up',
    direction: Direction.BOTH,
    icc: InternalClientCode.JUPITER,
  },
  {
    code: 'BALANCE_SURPLUS',
    description: 'Balance surplus',
    direction: Direction.BOTH,
    icc: InternalClientCode.JUPITER,
  },
]
