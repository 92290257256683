import React from 'react'
import BigNumber from 'bignumber.js'
import { isEmpty, orderBy } from 'lodash'
import { TFunction } from 'i18next'
import { SelectFieldData } from 'components/form/SelectFieldComplex'
import { getCurrectRate } from 'pages/features/CreditInterest/utils/creditInterestTransformers'
import { BankInterestRate, SelectedRateAndBankName } from 'store/referenceData/types'
import {
  ExtendedProduct,
  DebitInterestCommonConfigFormValues,
  FixedVariableType,
  MAX_END_RANGE,
  LendingRateFormValues,
  LendingRateType,
  TypeOfIndexRate,
  DebitInterestFormValues,
  DebitInterest,
  DebitInterestCommonConfig,
  LendingRate,
  LendingTierBand,
  LendingTierBandForm,
  RateTierBand,
  Operand,
} from 'store/products/types'
import { preciseFixRateNumber, preciseRateMultiplyByHundred, RateDividedByHundred } from 'utils/form.utils'
import { convertFormValuesToISOFrequency, convertISOFrequencyToFormValues } from 'pages/Product/product.utils'
import { convertISOFrequencyToStr } from 'utils/viewFrequencyUtils'
import { isNullOrUndefined } from 'utils/common.util'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { DefinitionRowItem } from 'dls/molecules/FeatureDefinitions/types'

export const buildDebitInterestCommonConfigFeatureDefinitions = ({
  commonConfig,
  description,
  bottomSeparator,
  t,
}: {
  commonConfig: DebitInterestCommonConfig
  description: string
  bottomSeparator: boolean
  t: TFunction
}): DefinitionRowItem[] => {
  if (!commonConfig) {
    return []
  }

  const { applicationFrequency, notificationApplication, balanceCriteria, dayCount, roundingMethod } = commonConfig

  return [
    { term: t('Description'), definition: [description] },
    { term: t('Accrual period ends'), definition: [convertISOFrequencyToStr(t, applicationFrequency)] },
    {
      term: t('Apply after'),
      definition: [!isNullOrUndefined(notificationApplication) ? `${notificationApplication} ${t('days')}` : null],
    },
    { term: t('Balance check'), definition: [t(`${balanceCriteria}`)] },
    { term: t('Day count'), definition: [t(dayCount)] },
    { term: t('Calculation frequency'), definition: ['Daily'] },
    { term: t('Rounding method'), definition: [t(roundingMethod)], hasSectionSeparator: bottomSeparator },
  ]
}

export const buildIndependentRateDefinitions = ({
  rate,
  currencyCode,
  showSeparator,
  t,
}: {
  rate: LendingRate
  currencyCode: string
  showSeparator: boolean
  t: TFunction
}): DefinitionRowItem[] => {
  const { interestRate, calculationMethod, tierBands } = rate

  const definitions: DefinitionRowItem[] = []

  if (!isNullOrUndefined(interestRate) && calculationMethod === RateTierBand.FLAT) {
    definitions.push({ term: t('Rate'), definition: [`${preciseRateMultiplyByHundred(interestRate as number, 10)}%`] })
  }

  if (calculationMethod && [RateTierBand.BAND, RateTierBand.TIER].includes(calculationMethod) && !isEmpty(tierBands)) {
    const rateTierBandTitle = calculationMethod === RateTierBand.TIER ? t('Tiers') : t('Bands')
    const rateTierBandDefinition = tierBands?.map((item, i) => (
      <div key={`${item.startRange}-${item.endRange}-${i}`}>
        <CurrencySymbol
          typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
          currencyCode={currencyCode}
        />
        {item.startRange}
        {' – '}
        {!isNullOrUndefined(item.endRange) && (
          <>
            <CurrencySymbol
              typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
              currencyCode={currencyCode}
            />
            {item.endRange}
          </>
        )}
        {!isNullOrUndefined(item.interestRate) ? (
          <span>{` = ${preciseRateMultiplyByHundred(item.interestRate as number, 10)}%`}</span>
        ) : null}
      </div>
    ))

    definitions.push({ term: t(rateTierBandTitle), definition: [rateTierBandDefinition] })
  }

  if (showSeparator && definitions.length) {
    definitions[definitions.length - 1].hasSectionSeparator = true
  }

  return definitions
}

export const buildStaticMarginRateDefinitions = ({
  rate,
  currencyCode,
  bankRate,
  showSeparator,
  t,
}: {
  rate: LendingRate
  currencyCode: string
  bankRate: SelectedRateAndBankName | null
  showSeparator: boolean
  t: TFunction
}): DefinitionRowItem[] => {
  const { calculationMethod, tierBands, spread, operator } = rate

  const definitions: DefinitionRowItem[] = []

  if (!isNullOrUndefined(spread) && calculationMethod === RateTierBand.FLAT && bankRate) {
    const userRateText =
      operator === Operand.SUBTRACT
        ? preciseFixRateNumber(
            new BigNumber(bankRate.rate as number).minus(preciseRateMultiplyByHundred(spread as number, 10)).toFixed(),
            10
          )
        : preciseFixRateNumber(
            new BigNumber(bankRate.rate as number).plus(preciseRateMultiplyByHundred(spread as number, 10)).toFixed(),
            10
          )

    const bankRateText = `${bankRate.bankName}: ${bankRate.rate}% `

    const spreadText =
      spread &&
      `${operator === Operand.SUBTRACT ? '-' : '+'} ${t('your margin')}: ${preciseRateMultiplyByHundred(spread, 10)}%`

    const definition = `${userRateText}% (${bankRateText} ${spreadText})`

    definitions.push({ term: t('Rate'), definition: [definition] })
  }

  if (
    calculationMethod &&
    [RateTierBand.BAND, RateTierBand.TIER].includes(calculationMethod) &&
    !isEmpty(tierBands) &&
    bankRate
  ) {
    const term = calculationMethod === RateTierBand.TIER ? t('Tiers') : t('Bands')

    tierBands?.forEach((item, index) => {
      const definitionTerm = index === 0 ? term : ''
      const definition = (
        <div key={`${item.startRange}-${index}`}>
          <CurrencySymbol
            typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
            currencyCode={currencyCode}
          />
          {item.startRange}
          {!isNullOrUndefined(item.endRange) && (
            <>
              {' – '}
              <CurrencySymbol
                typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                currencyCode={currencyCode}
              />
              {item.endRange}
            </>
          )}
          {!isNullOrUndefined(item.spread) && (
            <span>
              {` = ${
                item.operator === Operand.SUBTRACT
                  ? preciseFixRateNumber(
                      new BigNumber(bankRate.rate)
                        .minus(preciseRateMultiplyByHundred(item.spread as number, 10))
                        .toFixed(),
                      10
                    )
                  : preciseFixRateNumber(
                      new BigNumber(bankRate.rate)
                        .plus(preciseRateMultiplyByHundred(item.spread as number, 10))
                        .toFixed(),
                      10
                    )
              }% `}
            </span>
          )}
          {!isNullOrUndefined(item.spread) && (
            <span>
              {`(${bankRate.bankName}: ${bankRate.rate}%`}
              {` ${item.operator === Operand.SUBTRACT ? ' - ' : ' + '}`}
              {`${t('your margin')}: ${preciseRateMultiplyByHundred(item.spread as number, 10)}%)`}
            </span>
          )}
        </div>
      )

      definitions.push({ term: definitionTerm, definition: [definition] })
    })
  }

  if (showSeparator && definitions.length) {
    definitions[definitions.length - 1].hasSectionSeparator = true
  }

  return definitions
}

export const buildVariableMarginRateDefinitions = ({
  rate,
  currencyCode,
  bankRate,
  showSeparator,
  t,
}: {
  rate: LendingRate
  currencyCode: string
  bankRate: SelectedRateAndBankName | null
  showSeparator: boolean
  t: TFunction
}): DefinitionRowItem[] => {
  const { interestRate, calculationMethod, tierBands } = rate

  const definitions: DefinitionRowItem[] = []

  if (interestRate && calculationMethod === RateTierBand.FLAT && bankRate) {
    const interestText = `${preciseRateMultiplyByHundred(interestRate, 10)}% `
    const bankRateText = `(${bankRate.bankName}: ${bankRate.rate}% + ${t('your margin')}: ${preciseFixRateNumber(
      new BigNumber(preciseRateMultiplyByHundred(interestRate, 10)).minus(bankRate.rate).toFixed(),
      10
    )}%)`

    definitions.push({ term: t('Rate'), definition: [`${interestText} ${bankRateText}`] })
  }

  if (
    calculationMethod &&
    [RateTierBand.BAND, RateTierBand.TIER].includes(calculationMethod) &&
    !isEmpty(tierBands) &&
    bankRate
  ) {
    const term = calculationMethod === RateTierBand.TIER ? t('Tiers') : t('Bands')

    tierBands?.forEach((item, index) => {
      const definitionTerm = index === 0 ? term : ''
      const definition = (
        <div key={`${item.startRange}-${index}`}>
          <CurrencySymbol
            typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
            currencyCode={currencyCode}
          />
          {item.startRange}
          {!isNullOrUndefined(item.endRange) && (
            <>
              {' – '}
              <CurrencySymbol
                typographyProps={{ variant: 'inherit', variantMapping: { subtitle2: 'span' } }}
                currencyCode={currencyCode}
              />
              {item.endRange}
            </>
          )}
          {!isNullOrUndefined(item.interestRate) &&
            ` = ${preciseRateMultiplyByHundred(item.interestRate as number, 10)}% `}
          {!isNullOrUndefined(item.interestRate) &&
            ` (${bankRate.bankName}: ${bankRate.rate}% + ${t('your margin')}: ${preciseFixRateNumber(
              new BigNumber(preciseRateMultiplyByHundred(item.interestRate as number, 10))
                .minus(bankRate.rate as number)
                .toFixed(),
              10
            )}%)`}
        </div>
      )

      definitions.push({ term: definitionTerm, definition: [definition] })
    })
  }

  if (showSeparator && definitions.length) {
    definitions[definitions.length - 1].hasSectionSeparator = true
  }

  return definitions
}

const getTypeOfIndexRate = (rate: LendingRate): TypeOfIndexRate | undefined => {
  if (rate?.fixedVariableType === FixedVariableType.FIXED) {
    return TypeOfIndexRate.INDEPENDENT_INDEXED_RATE
  }

  if (rate?.fixedVariableType === FixedVariableType.VARIABLE) {
    if (rate.partnercentricModel) {
      return TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN
    }

    return TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
  }

  return undefined
}

const calculateSpread = (spread: number | string, divide?: boolean): number | string => {
  if (spread) {
    if (divide) {
      return RateDividedByHundred(spread, 12)
    }

    return preciseRateMultiplyByHundred(spread, 10)
  }

  return spread
}

const convertTierBands = (tierBands?: Array<LendingTierBand | LendingTierBandForm>, divide?: boolean) => {
  const isForPayLoad = divide
  const calculateInterestRate = (band: LendingTierBand | LendingTierBandForm) => {
    if (+(band?.spread as number)) {
      return null
    }

    if (band.interestRate) {
      return divide ? RateDividedByHundred(band.interestRate, 12) : preciseRateMultiplyByHundred(band.interestRate, 10)
    }

    return band.interestRate
  }

  if (!tierBands) {
    return null
  }

  if (isForPayLoad) {
    return tierBands?.map(
      (band) =>
        ({
          ...band,
          interestRate: calculateInterestRate(band),
          spread: calculateSpread(band.spread as number, divide),
          endRange: band.endRange === MAX_END_RANGE ? undefined : band.endRange,
        } as LendingTierBand)
    ) as LendingTierBand[]
  }

  return tierBands?.map(
    (band) =>
      ({
        ...band,
        interestRate: calculateInterestRate(band),
        spread: calculateSpread(band.spread as number, divide),
        endRange: band.endRange === MAX_END_RANGE ? undefined : band.endRange,
      } as LendingTierBandForm)
  ) as LendingTierBandForm[]
}

const convertInterestRate = (interestRate?: number | string, partnercentricModel?: boolean): number | undefined => {
  if (interestRate) {
    return RateDividedByHundred(interestRate, 12)
  }

  if (partnercentricModel) {
    return 0
  }

  return undefined
}

export const toDebitInterestCommonConfigFormValues = (
  product: ExtendedProduct
): DebitInterestCommonConfigFormValues | null => {
  if (!product?.debitInterest) {
    return null
  }

  const {
    balanceCriteria,
    dayCount,
    roundingMethod,
    notificationApplication,
    applicationFrequency,
  } = product.debitInterest.commonConfig

  return {
    description: product.debitInterest.description,
    balanceCriteria,
    dayCount,
    notificationApplication,
    roundingMethod,
    ...convertISOFrequencyToFormValues(applicationFrequency),
  }
}

export const toDebitInterestRateFormValues = (
  product: ExtendedProduct,
  selectedType: LendingRateType
): LendingRateFormValues | null => {
  const rate = product?.debitInterest?.[selectedType]

  if (!rate) {
    return null
  }

  return {
    ...rate,
    typeOfIndexRate: getTypeOfIndexRate(rate),
    interestRate: rate.interestRate
      ? preciseRateMultiplyByHundred(rate.interestRate, 10)
      : new BigNumber(rate.interestRate ?? 0).toFixed(),
    spread: rate.spread ? preciseRateMultiplyByHundred(rate.spread, 10) : new BigNumber(rate.spread ?? 0).toFixed(),
    tierBands: rate.tierBands && (convertTierBands(rate.tierBands, false) as LendingTierBandForm[]),
  }
}

export const toSetupDebitInterestPayload = (
  formValues: DebitInterestFormValues,
  rateType: LendingRateType
): DebitInterest => {
  const {
    description,
    dayCount,
    balanceCriteria,
    notificationApplication,
    tierBands,
    interestRate,
    calculationMethod,
    bankInterestRateIndex,
    fixedVariableType,
    roundingMethod,
    partnercentricModel,
    spread,
    operator,
    ...applicationFrequency
  } = formValues

  const convertedInterestRate = convertInterestRate(interestRate, partnercentricModel)
  const rate = {
    fixedVariableType,
    bankInterestRateIndex,
    interestRate: convertedInterestRate,
    tierBands:
      convertedInterestRate === undefined ||
      [RateTierBand.BAND, RateTierBand.TIER].includes(calculationMethod as RateTierBand)
        ? convertTierBands(tierBands, true)
        : [],
    calculationMethod,
    partnercentricModel,
    spread: calculateSpread(spread, true),
    operator,
  }

  return {
    description,
    commonConfig: {
      notificationApplication,
      dayCount,
      balanceCriteria,
      roundingMethod,
      applicationFrequency: convertFormValuesToISOFrequency(applicationFrequency),
      calculationFrequency: 'R/P1D',
    },
    [rateType]: rate,
  }
}

export const toDebitInterestCommonConfigPayload = (
  formValues: DebitInterestCommonConfigFormValues
): { commonConfig: DebitInterestCommonConfig; description: string } => {
  const {
    dayCount,
    balanceCriteria,
    notificationApplication,
    roundingMethod,
    description,
    ...applicationFrequency
  } = formValues

  return {
    description: description || '',
    commonConfig: {
      notificationApplication,
      dayCount,
      balanceCriteria,
      roundingMethod,
      applicationFrequency: convertFormValuesToISOFrequency(applicationFrequency),
      calculationFrequency: 'R/P1D',
    },
  }
}

export const toLendingRatePayload = (formValues: LendingRateFormValues): LendingRate => {
  const {
    fixedVariableType,
    interestRate,
    tierBands,
    calculationMethod,
    bankInterestRateIndex,
    partnercentricModel,
    spread,
    operator,
  } = formValues

  const convertedInterestRate = convertInterestRate(interestRate, partnercentricModel)
  const rate: LendingRate = {
    fixedVariableType,
    interestRate: convertedInterestRate,
    calculationMethod,
    bankInterestRateIndex,
    tierBands:
      convertedInterestRate === undefined ||
      [RateTierBand.BAND, RateTierBand.TIER].includes(calculationMethod as RateTierBand)
        ? (convertTierBands(tierBands, true) as LendingTierBand[])
        : [],
    partnercentricModel,
    spread: undefined,
    operator,
  }

  if (!isNullOrUndefined(spread)) {
    rate.spread = +calculateSpread(spread as string, true)
  }

  return rate
}

export const mapBankInterestRatesToLendingInterestFieldData = (bankInterestRates: BankInterestRate[]) => {
  const res: SelectFieldData = {}
  orderBy(bankInterestRates, [(bankInterestRate) => bankInterestRate.name.toLowerCase()], ['asc']).forEach((item) => {
    if (item.rates && getCurrectRate(item.rates)) {
      res[`${item.name ? item.name : ''}: ${item.rates ? getCurrectRate(item.rates) : ''}%`] = {
        name: `${item.bankIndexKey}`,
      }
    }
  })

  return res
}
