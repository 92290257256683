import styled from 'styled-components/macro'
import { Done } from '@material-ui/icons'
import { CardScheme } from 'store/products/types'
import { Mastercard, Visa } from 'assets'

export const CardLogo = styled.img`
  float: left;
  margin-right: 5px;
  height: 25px;
`

export const TooltipStyle = styled.div`
  margin-left: 16px;
  margin-right: 36px;
  display: inline-block;
  vertical-align: top;
  margin-top: 21px;
`
export const StyledDone = styled(Done)`
  color: ${(props: any) => props.theme.palette.success.main};
  font-size: 30px;
`

export const StyledSpan = styled.span`
  align-self: center;
`
export const ImageStyle = styled.img`
  margin-right: 8px;
  vertical-align: text-bottom;
`
export const cardImages = {
  [CardScheme.MASTERCARD]: Mastercard,
  // [CardScheme.UNIONPAY]: UnionPay,
  [CardScheme.VISA]: Visa,
}
