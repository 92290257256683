import { Grid, Typography } from '@material-ui/core'
import { FastField, Form, FormikProps } from 'formik'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { ContentGridContainer, ContentPaperContainer } from '../Styled'
import { FormBottomBar } from 'components'
import SwitchGroupFieldComplex from 'components/form/SwitchGroupFieldComplex'
import { RequiredExternalId, RequiredExternalIdFormValues, ProductKey } from 'store/products/types'
import { withFormikSimple } from 'utils/form.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  defaultValues?: {}
  productKey: ProductKey
  hasFeature: boolean
}

interface RequiredExternalIdFormProps extends FormikProps<RequiredExternalIdFormValues>, OwnProps {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RequiredExternalIdForm = (_props: RequiredExternalIdFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { productKey } = _props
  const multiSelectExternalIdData: Record<string, { name: string; code: string; tooltip: string }> = {}
  Object.entries(RequiredExternalId).forEach(([key, value]) => {
    multiSelectExternalIdData[key] = {
      name: value,
      code: key,
      tooltip: t(`${key}_Tooltip`),
    }
  })

  const onPressBack = useCallback(() => {
    navigate(`/products/${productKey}`)
  }, [productKey])

  return (
    <FeaturePageWithTopAddLayout
      productKey={productKey}
      title={t('External identifiers')}
      description={t(
        'The only external identifier supported is the Account Identification Number (AIN) and by default it’s active. Here you can deactivate it – you may wish to deactivate it to prevent transactions.'
      )}
    >
      <Form>
        <ContentPaperContainer>
          <ContentGridContainer
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                {t('Settings')}
              </Typography>
              <FastField
                name="requiredExternalId"
                data={multiSelectExternalIdData}
                component={SwitchGroupFieldComplex}
                inputLabelProps={{ shrink: true }}
                formControlProps={{ fullWidth: false }}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <FormBottomBar inlineNavigation noDirty>
                <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                  {t('Cancel')}
                </Button>
              </FormBottomBar>
            </Grid>
          </ContentGridContainer>
        </ContentPaperContainer>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export default withFormikSimple<OwnProps, RequiredExternalIdFormValues>(RequiredExternalIdForm, {
  isInitialValid: (props) => props.hasFeature,
})
