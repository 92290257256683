import React from 'react'
import { Grid, InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import FieldPercentage, { FieldPercentageProps } from './FieldPercentage'
import { preciseFixRateNumber } from 'utils/form.utils'

interface OwnProps {
  indexRateProps?: TextFieldProps
  calculatedProps?: TextFieldProps
  indexRate: number
}

interface FieldRateCalculatorProps extends FieldPercentageProps, OwnProps {}

const FieldRateCalculator = ({
  indexRateProps,
  calculatedProps,
  indexRate,
  ...percentageProps
}: FieldRateCalculatorProps) => {
  const { t } = useTranslation()
  const calculatedValue = percentageProps.form.values && getIn(percentageProps.form.values, percentageProps.field.name)

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={4}>
        <TextField
          value={indexRate}
          disabled
          fullWidth
          label={t('Index rate')}
          InputLabelProps={{ shrink: true }}
          {...indexRateProps}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={
            calculatedValue != null && calculatedValue !== undefined && calculatedValue !== ''
              ? preciseFixRateNumber(new BigNumber(calculatedValue).minus(indexRate).toFixed(), 10)
              : ''
          }
          disabled
          fullWidth
          helperText=""
          label={t('Your margin')}
          placeholder={t('Calculated')}
          InputLabelProps={{ shrink: true }}
          {...calculatedProps}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        />
      </Grid>
      <Grid item xs={4}>
        <FieldPercentage {...percentageProps} inputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
  )
}

export default FieldRateCalculator
