import React from 'react'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIcon as SvgIconMui, SvgIconTypeMap, SvgIconProps } from '@material-ui/core'
import styled from 'styled-components/macro'
import { ImageSize, IconSize, iconSVGFillType, ColorType } from 'dls/shared/types'
import { iconColorSelector } from 'dls/shared/styled'

export interface AIconProps extends Omit<SvgIconProps, 'color'> {
  className?: string
  color?: ColorType
  size?: ImageSize
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  IconSVGType?: iconSVGFillType
  disabled?: boolean
}

export interface IconColorSelectorProps extends AIconProps {
  color?: ColorType
  defaultColor?: boolean
}

const StyledDefaultSvgIcon = styled(SvgIconMui)<{
  $size: ImageSize | undefined
  $color?: ColorType
  component: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}>`
  width: ${({ $size = ImageSize.SMALL }: any) => IconSize[$size]};
  height: ${({ $size = ImageSize.SMALL }: any) => IconSize[$size]};
  fill: none;
`

const StyledSvgIcon: any = styled(SvgIconMui)<{
  $size: ImageSize
  color?: ColorType
  component: React.ReactElement
  $IconSVGType: iconSVGFillType
}>`
  width: ${({ $size = ImageSize.SMALL }: any) => IconSize[$size]};
  height: ${({ $size = ImageSize.SMALL }: any) => IconSize[$size]};
  & * {
    stroke: ${({ $IconSVGType }: { $IconSVGType: iconSVGFillType }): any =>
      $IconSVGType === iconSVGFillType.STROKE ? iconColorSelector : ColorType.NONE};
    fill: ${({ $IconSVGType }: { $IconSVGType: iconSVGFillType }): any =>
      $IconSVGType === iconSVGFillType.FILL ? iconColorSelector : ColorType.NONE};
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SVGIcon = ({
  Icon,
  color,
  disabled,
  IconSVGType = iconSVGFillType.FILL,
  size,
  defaultColor,
  ...props
}: IconColorSelectorProps) =>
  (color || disabled) && !defaultColor ? (
    <StyledSvgIcon
      data-dls="atom-svg-icon"
      component={Icon}
      {...props}
      color={disabled ? ColorType.SECONDARY : color}
      $IconSVGType={IconSVGType}
      $iconColorSelector={iconColorSelector}
      $size={size}
    />
  ) : (
    <StyledDefaultSvgIcon
      data-dls="atom-svg-icon"
      $color={disabled ? ColorType.SECONDARY : color}
      component={Icon as any}
      {...props}
      $size={size}
    />
  )
