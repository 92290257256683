import React from 'react'
import { Dialog, Fade } from '@material-ui/core'
import { Product, UpdateProductFormValues } from 'store/products/types'
import ProductCoreForm from 'pages/Products/CreateProductDialog/ProductCore/ProductCoreForm'

interface EditProductProps {
  product: Product
  open: boolean
  onPressCancel: () => void
  onSubmit: (payload: UpdateProductFormValues) => void
}

const EditProduct = ({ product, onPressCancel, onSubmit, open }: EditProductProps) => (
  <Dialog open={open} scroll="paper" onClose={onPressCancel} TransitionComponent={Fade} fullWidth>
    <ProductCoreForm
      onPressCancel={onPressCancel}
      initialValues={{
        productName: product.productName,
        productType: product.productType,
        productSegment: product.productSegment,
        productLine: product.productLine,
        productGroup: product.productGroup,
        productDescription: product.productDescription,
        productCategory: product.productCategory,
      }}
      onSubmit={onSubmit}
    />
  </Dialog>
)

export default EditProduct
