import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const MainGrid = styled(Grid)`
  margin-top: 4px;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      margin-top: 32px;
      margin-bottom: 24px;
      align-items: stretch;
      flex-direction: column;
    }
  `};
`

export const SearchCTAGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  ${({ theme }) => `
  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  ${theme.breakpoints.down('sm')} {
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: column;
    margin-bottom: 3px;
  }
`};
`

export const SearchIconsGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`

export const StyledGrid = styled(Grid)`
  white-space: nowrap;
  padding-right: 10px;
  margin-bottom: -6px;
  ${({ theme }) => `
  ${theme.breakpoints.down('md')} {
    padding-right: 0;
    margin-bottom: 0;
    width: auto !important;
  }
`};
`

export const CatalogListContainer = styled.ol`
  padding-inline-start: 0;
`
