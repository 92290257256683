import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { Paper, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import AssigneeBox from './AssigneeBox'
import { getAssigneeData } from './AssigneeBoxUtil'
import TasksSelectors from 'store/tasks/selectors'
import ProdctSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'
import { RootState } from 'store'
import { ProductStatus } from 'store/products/typings/productStatus'
import { TaskStatus } from 'store/tasks/typings/taskStatus'

const mapStateToProps = (state: RootState) => ({
  assignees: ProdctSelectors.selectColleagues(state),
  tasks: TasksSelectors.selectTasksForProduct(state),
})

const mapDispatchToProps = {
  getColleagues: ProductsActions.getAssignees,
}

interface OwnProps {
  productStatus: ProductStatus
}

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & OwnProps

const TypographyHeader = styled(Typography)`
  display: inline-block;
  margin-top: 24px;
  margin-left: 16px;
`

const StyledDiv = styled.div`
  padding-bottom: 16px;
  padding-left: 8px;
`
const PaperMain = styled(Paper)`
  border-radius: 4px;
  margin-bottom: 32px;
`

const AssigneeBoxContainerPage = ({ assignees, productStatus, tasks, getColleagues }: Props) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (isEmpty(assignees) && productStatus === ProductStatus.APPROVED) {
      getColleagues()
    }
  }, [assignees, getColleagues, productStatus])
  if (!tasks || !assignees) return null
  if (![ProductStatus.REVIEW, ProductStatus.APPROVED].includes(productStatus)) return null
  const assigneeData = getAssigneeData(assignees, tasks)
  const predefinedAssigneeData = assigneeData.filter((item) => item.task.type === 'PREDEFINED')
  const featureAssigneeData = assigneeData.filter((item) => item.task.type === 'FEATURE')
  const featureApprovedData = featureAssigneeData.filter((item) => item.task.status === TaskStatus.CLOSED)

  return (
    <>
      {assigneeData && assigneeData.length > 0 && featureAssigneeData.length === featureApprovedData.length && (
        <PaperMain elevation={1}>
          <StyledDiv>
            <TypographyHeader variant="h6" variantMapping={{ h6: 'div' }}>
              {t('Approvals')}
            </TypographyHeader>
            {predefinedAssigneeData.map((data, ind) => (
              <AssigneeBox key={ind} {...data} />
            ))}
          </StyledDiv>
        </PaperMain>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AssigneeBoxContainerPage)
