import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import {
  getMinimumTermInDays,
  getAllowedPaymentFrequecies,
} from 'pages/features/PaymentSchedule/utils/paymentScheduleTransformers'
import { H2, Body2 } from 'components'
import CheckboxGroupFieldComplex, { SwitchGroupFieldData } from 'components/form/CheckboxGroupFieldComplex'
import { AllowedRepaymentFrequencies, PaymentScheduleTerm } from 'store/products/types'

interface OwnProps {
  term: {
    type: PaymentScheduleTerm
    minimum: string
    maximum: string
  }
}

const RepaymentFrequencyForm = ({ term: { type, minimum } }: OwnProps) => {
  const { t } = useTranslation()

  const termInDays = getMinimumTermInDays(type, minimum)
  const paymentFrequencies = getAllowedPaymentFrequecies(termInDays)

  const allowedRepaymentFrequenciesData: SwitchGroupFieldData = {}
  Object.entries(AllowedRepaymentFrequencies).forEach(([key, item]) => {
    const optionData = {
      label: `VIEW_FREQUENCY.${key}`,
      name: `${item}`,
      disabled: !paymentFrequencies.includes(item),
    }
    allowedRepaymentFrequenciesData[`${key}`] = optionData
  })

  return (
    <Grid item style={{ marginTop: 20 }}>
      <H2 gutterBottom>{t('Repayment frequencies')}</H2>
      <Body2 gutterBottom>
        {t(
          'Choose the repayment frequencies that you want to offer to customers of this product. Frequencies cannot be a longer period than the minimum term.'
        )}
      </Body2>
      <Field
        name="allowedRepaymentFrequencies"
        fullWidth
        data={allowedRepaymentFrequenciesData}
        component={CheckboxGroupFieldComplex}
        required
      />
    </Grid>
  )
}

export default RepaymentFrequencyForm
