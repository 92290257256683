import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FastField } from 'formik'
import TextFieldComplex, { TextFieldComplexProps } from './TextFieldComplex'

interface OwnProps {
  percentageIsPrefix?: boolean
}

export interface FieldPercentageProps extends Omit<TextFieldComplexProps, 'children'>, OwnProps {}

const FieldPercentage = ({ percentageIsPrefix = false, ...props }: FieldPercentageProps) => (
  <FastField
    type="text"
    component={TextFieldComplex}
    {...props}
    InputProps={{
      ...props.InputProps,
      endAdornment: !percentageIsPrefix ? <InputAdornment position="end">%</InputAdornment> : null,
      startAdornment: percentageIsPrefix ? (
        <InputAdornment position="end" style={{ marginLeft: 4, marginRight: 16 }}>
          %
        </InputAdornment>
      ) : null,
    }}
  />
)

export default FieldPercentage
