import { createAction } from '@reduxjs/toolkit'
import { ProductKey } from 'store/products/types'
import { ProcessExecution, Task } from 'store/tasks/types'
import { ProcessFormValues, Process } from 'store/governance/types'
import { ShouldRedirectOnFail } from 'store/utils/types'

const actions = {
  getTasks: createAction<ProductKey>('GOVERNANCE/TASKS/GET'),
  getTasksSuccess: createAction<Task[]>('GOVERNANCE/TASKS/GET/SUCCESS'),
  getTasksFail: createAction<Error>('GOVERNANCE/TASKS/GET/FAIL'),

  selectProcessDetails: createAction<Process>('PROCESS/DETAILS'),

  processExecution: createAction<{ entityKey: ProductKey }>('GOVERNANCE/PROCESS_EXECUTION'),
  processExecutionSuccess: createAction<ProcessExecution[]>('GOVERNANCE/PROCESS_EXECUTION/SUCCESS'),
  processExecutionFail: createAction<Error>('GOVERNANCE/PROCESS_EXECUTION/FAIL'),
  processExecutionFailCloseModal: createAction('GOVERNANCE/PROCESS_EXECUTION/FAIL'),

  clearProcessExecution: createAction('GOVERNANCE/CLEAR/PROCESS_EXECUTION'),

  getProcess: createAction<ShouldRedirectOnFail | undefined>('PROCESS/GET'),
  getProcessSuccess: createAction<Process[]>('PROCESS/GET/SUCCESS'),
  getProcessFail: createAction<Error>('PROCESS/GET/FAIL'),

  createProcess: createAction<ProcessFormValues>('PROCESS/CREATE'),
  createProcessSuccess: createAction<Process[]>('PROCESS/CREATE/SUCCESS'),
  createProcessFail: createAction<Error>('PROCESS/CREATE/FAIL'),

  updateSelectedProcess: createAction<ProcessFormValues>('PROCESS/UPDATE'),
  updateSelectedProcessSuccess: createAction<Process[]>('PROCESS/UPDATE/SUCCESS'),
  updateSelectedProcessFail: createAction<Error>('PROCESS/UPDATE/FAIL'),
}

export default actions
