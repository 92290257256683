import moment from 'moment-timezone'
import BigNumber from 'bignumber.js'
import { isEmpty, omit, orderBy } from 'lodash'
import { TFunction } from 'i18next'
import ReactDOMServer from 'react-dom/server'
import {
  CreditInterest,
  CreditInterestFormValues,
  CreditInterestPayloadValues,
  ExtendedProduct,
  FixedVariableType,
  RateTierBand,
  TypeOfIndexRate,
} from 'store/products/types'
import { getFrequencyValues } from 'utils/api.utils'
import { BankInterestRate, InterestRate, SelectedRateAndBankName } from 'store/referenceData/types'
import { preciseRateMultiplyByHundred, RateDividedByHundred, RateMultiplyByHundred } from 'utils/form.utils'
import { WithholdingRule } from 'store/withholdingTax/types'
import { CheckIcon } from 'pages/Product/product.utils'
import { SelectFieldData } from 'components/form/SelectFieldComplex'

export const hasCreditInterestFeature = (product: ExtendedProduct): boolean => !!product && !!product.creditInterest

const getCreditInterestFromProduct = (product: ExtendedProduct): CreditInterestPayloadValues | null =>
  hasCreditInterestFeature(product)
    ? ({
        ...product.creditInterest,
        applicationFrequency: getFrequencyValues(
          (product.creditInterest || ({} as CreditInterest)).applicationFrequency
        ),
        calculationFrequency: getFrequencyValues(
          (product.creditInterest || ({} as CreditInterest)).calculationFrequency
        ),
        compoundingFrequency: getFrequencyValues(
          (product.creditInterest || ({} as CreditInterest)).compoundingFrequency
        ),
      } as CreditInterestPayloadValues)
    : null

export const toCreditInterestFormValues = (product: ExtendedProduct): CreditInterestFormValues | null => {
  const res = getCreditInterestFromProduct(product)
  if (res && res.interestTierBand && res.interestTierBand.length > 0) {
    return {
      ...res,
      interestRate: res.interestRate
        ? preciseRateMultiplyByHundred(res.interestRate, 10)
        : new BigNumber(res.interestRate ?? 0).toFixed(),
      spread: res.spread ? RateMultiplyByHundred(res.spread, 4) : res.spread,
      interestTierBand: res.interestTierBand.map((i) => ({
        ...i,
        tierBandRate: i.tierBandRate
          ? preciseRateMultiplyByHundred(i.tierBandRate, 10)
          : new BigNumber(i.tierBandRate ?? 0).toFixed(),
        tierBandSpread: i.tierBandSpread ? RateMultiplyByHundred(i.tierBandSpread, 4) : i.tierBandSpread,
      })),
    }
  }
  return res
    ? {
        ...res,
        interestRate: res.interestRate
          ? preciseRateMultiplyByHundred(res.interestRate, 10)
          : new BigNumber(res.interestRate ?? 0).toFixed(),
        spread: res.spread ? RateMultiplyByHundred(res.spread, 4) : res.spread,
        interestTierBand: [{}],
      }
    : null
}

export const getCreditInterestToPayload = (payload: CreditInterestFormValues): CreditInterestPayloadValues | null => {
  if (payload) {
    const interestTierBand =
      payload.tierBandCalcMethod !== RateTierBand.FLAT &&
      payload.interestTierBand &&
      payload.interestTierBand.length > 0
        ? payload.interestTierBand.map((i) => ({
            ...i,
            // eslint-disable-next-line no-nested-ternary
            tierBandRate: i.tierBandSpread
              ? 0
              : +(i.tierBandRate || 0)
              ? RateDividedByHundred(i.tierBandRate || 0, 12)
              : +(i.tierBandRate || 0),
            tierBandSpread: i.tierBandSpread ? RateDividedByHundred(i.tierBandSpread, 6) : i.tierBandSpread,
          }))
        : undefined
    const interestRate = payload.interestRate
      ? RateDividedByHundred(payload.interestRate, 12)
      : +(payload.interestRate || 0)
    const spread = payload.spread ? RateDividedByHundred(payload.spread, 6) : payload.spread

    if (payload.typeOfIndexRate === TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN) {
      return {
        ...(omit(payload, [
          'typeOfIndexRate',
          'showWithholdingTax',
          'availableHoldingTaxRules',
        ]) as CreditInterestFormValues),
        interestRate,
        spread,
        interestTierBand,
      }
    }
    return {
      ...(omit(payload, [
        'interestRateIndex',
        'typeOfIndexRate',
        'showWithholdingTax',
        'availableHoldingTaxRules',
      ]) as CreditInterestFormValues),
      interestRate,
      spread,
      interestTierBand,
    }
  }
  return null
}

export const getDefaultCalcMethod = (product: ExtendedProduct) => {
  const { creditInterest } = product
  if (creditInterest && creditInterest.interestRate && creditInterest.interestRate > 0) {
    return RateTierBand.FLAT
  }

  if (
    (creditInterest && creditInterest.tierBandCalcMethod === RateTierBand.TIER) ||
    (creditInterest && creditInterest.tierBandCalcMethod === RateTierBand.BAND)
  ) {
    return creditInterest.tierBandCalcMethod
  }
  if (creditInterest && creditInterest.tierBandCalcMethod === RateTierBand.FLAT) {
    return RateTierBand.FLAT
  }
  return undefined
}

export const getTypeOfIndexRate = (product: ExtendedProduct) => {
  const { creditInterest } = product
  if (
    creditInterest &&
    creditInterest.fixedVariableType &&
    creditInterest.fixedVariableType === FixedVariableType.FIXED
  ) {
    return TypeOfIndexRate.INDEPENDENT_INDEXED_RATE
  }
  if (
    creditInterest &&
    creditInterest.fixedVariableType &&
    creditInterest.fixedVariableType === FixedVariableType.VARIABLE
  ) {
    if (creditInterest.partnercentricModel) {
      return TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN
    }
    if (!creditInterest.partnercentricModel) {
      return TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
    }
  }

  return undefined
}

export const getCurrectRate = (rates: InterestRate[]) => {
  const currentDate = moment()
  let currentdateRate: InterestRate = {}

  const historicalDateRate = rates.filter((item) =>
    item.effectiveDate ? moment(item.effectiveDate).isSameOrBefore(currentDate) : ''
  )
  if (!isEmpty(historicalDateRate)) {
    currentdateRate =
      historicalDateRate && historicalDateRate.length > 1
        ? orderBy(
            historicalDateRate,
            function (o) {
              if (o.effectiveDate && o.effectiveDate !== null) {
                return moment(o.effectiveDate)
              }
              return null
            },
            ['desc']
          )[0]
        : historicalDateRate[0]
  }
  return currentdateRate?.indexRate && RateMultiplyByHundred(currentdateRate.indexRate)
}

export const selectedRateAndBankName = (
  bankIndexRates: BankInterestRate[],
  key: string
): SelectedRateAndBankName | null => {
  const data = bankIndexRates.filter((item) => item.abbreviation === key || item.bankIndexKey === key)
  if (data && data[0]) {
    const rate = getCurrectRate(data[0].rates)
    if (rate) {
      return {
        bankName: data[0].name,
        rate,
      }
    }

    return null
  }

  return null
}

// eslint-disable-next-line consistent-return
export const getTypeOfIndexRateForView = (variableType: FixedVariableType, partnercentricModel?: boolean) => {
  if (variableType === FixedVariableType.FIXED) {
    return TypeOfIndexRate.INDEPENDENT_INDEXED_RATE as string
  }
  if (variableType === FixedVariableType.VARIABLE) {
    if (partnercentricModel) {
      return TypeOfIndexRate.INDEX_RATE_PLUS_VARIABLE_MARGIN as string
    }
    if (!partnercentricModel) {
      return TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
    }
  }
  return ''
}

const getIconAndTitleLayout = (title: string, t: TFunction) => [
  {
    layout: 'noBorders',
    table: {
      widths: ['100%', '100%'],
      body: [
        [
          {
            layout: 'noBorders',
            table: {
              body: [
                [
                  { svg: ReactDOMServer.renderToString(CheckIcon), fit: [20, 16] },
                  [{ text: t(title), padding: [0, 0, 0, 0] }],
                ],
              ],
            },
            margin: 0,
            padding: 0,
          },
        ],
      ],
    },
  },
]
export const getTaxtRuleNames = (ruleIds: string[], taxRules: WithholdingRule[], t: TFunction) => {
  const currentRules = taxRules
    .filter((rule) => ruleIds.includes(rule.ruleId))
    .map((i) => getIconAndTitleLayout(i.name, t))
  const emptRows = currentRules.map(() => ' ')
  return [emptRows, currentRules]
}

export const mapBankInterestRatesToFieldData = (bankInterestRates: BankInterestRate[]) => {
  const res: SelectFieldData = {}
  orderBy(bankInterestRates, [(bankInterestRate) => bankInterestRate.name.toLowerCase()], ['asc']).forEach((item) => {
    if (item.rates && getCurrectRate(item.rates)) {
      res[`${item.name ? item.name : ''}: ${item.rates ? getCurrectRate(item.rates) : ''}%`] = {
        name: `${item.abbreviation}`,
      }
    }
  })
  return res
}
