import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { uniq } from 'lodash'
import { Separator } from 'dls/molecules/ReviewRows/types'
import { ReviewRows, IconLabel, Subtitle1, Caption, InfoPanel } from 'dls'
import { CheckIcon } from 'dls/atoms/Icons/MUIIcons'
import { ColorType } from 'dls/shared/types'
import { AllowedRolesMapType } from 'store/products/types'
import { MultiParty } from 'store/products/typings/multiParty'

interface OwnProps {
  multiParty: MultiParty
}

const StyledWrapper = styled.div`
  width: 100%;

  dl {
    margin-bottom: 10px;
  }

  [data-dls='molecules-ReviewRow']:last-child {
    dl {
      margin-bottom: 0;
    }
  }
`

const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  & li {
    margin-bottom: 8px;
  }
  & li:last-child {
    margin-bottom: 0;
  }
`

const ViewMultiParty = (props: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const { multiParty } = props
  const { maximumParties, inviterAllowedRoles, inviteeAllowedRoles } = multiParty

  const renderRoles = (inviterAllowedRoleParam: string[]) => (
    <StyledUl>
      {inviterAllowedRoleParam.map((item) => (
        <li key={`multiparty-list-${AllowedRolesMapType[item]}`}>
          <IconLabel Icon={<CheckIcon color={ColorType.BRAND} />} label={AllowedRolesMapType[item]} />
        </li>
      ))}
    </StyledUl>
  )

  const multiPartyDefinitions = [
    {
      term: t('Number of parties'),
      itemSeperator: 'strong' as Separator,
      definitions: [
        <ReviewRows
          key="multi-parties-number"
          definitionsList={[
            {
              definitions: [
                {
                  term: 'Minimum number',
                  definitions: [1],
                },
                maximumParties && {
                  term: 'Maximum number',
                  definitions: [maximumParties],
                },
              ].filter((item) => item) as any,
            },
          ]}
        />,
      ],
    },
  ].filter((item) => item)

  const multiPartyDetails = [
    {
      definitions: multiPartyDefinitions,
    },
  ]

  const rolesDetails = [
    {
      definitions: [
        {
          term: t('Owners'),
          itemSeperator: null,
          definitions: [
            <Caption key="roles-details-owners">
              These are the individuals who open the account and are therefore unrestricted. Owners are responsible for
              all deposits and any lending liabilities.
            </Caption>,
          ],
        },
        {
          term: t('Admins'),
          itemSeperator: null,
          definitions: [
            <Caption key="roles-details-admins">Admins can fully transact on the account but can’t close it.</Caption>,
          ],
        },
        {
          term: t('Viewers'),
          itemSeperator: 'strong' as Separator,
          definitions: [
            <Caption key="roles-details-viewers">Viewers only have a read-only view of the account.</Caption>,
          ],
        },
      ].filter((item) => item),
    },
    {
      definitions: [
        {
          term: (
            <>
              {t('Who can ')} <strong>{t(' be invited?')}</strong>
            </>
          ),
          itemSeperator: 'strong' as Separator,
          definitions: [renderRoles(inviteeAllowedRoles)],
        },
      ].filter((item) => item),
    },
    {
      definitions: [
        {
          term: (
            <>
              {t('Who can ')} <strong>{t(' invite others?')}</strong>
            </>
          ),
          itemSeperator: null,
          definitions: [renderRoles(uniq(['PrimaryOwner', ...inviterAllowedRoles]))],
        },
      ].filter((item) => item),
    },
  ]

  return (
    <StyledWrapper>
      <InfoPanel
        color="info"
        body={t(
          'All parties will need to meet the eligibility criteria for the product (set in the eligibility feature)'
        )}
        css="margin-bottom: 16px"
      />
      <ReviewRows definitionsList={multiPartyDetails} />
      <Subtitle1 variantMapping={{ subtitle1: 'h4' }} gutter>
        {t('Roles & permissions')}
      </Subtitle1>
      <InfoPanel
        color="info"
        body={t(
          'The party with the role ‘Owner’ is responsible for all deposits and any lending liabilities – no other roles have this responsibility'
        )}
        css="margin-bottom: 16px"
      />
      <ReviewRows definitionsList={rolesDetails} />
    </StyledWrapper>
  )
}

export default ViewMultiParty
