import React from 'react'
import { useTranslation } from 'react-i18next'
import { buildDebitInterestCommonConfigFeatureDefinitions } from '../../utils/transformers'
import { DebitInterestCommonConfig } from 'store/products/types'
import { FeatureDefinitions, InfoPanel, H5 } from 'dls'

interface OwnProps {
  description: string
  commonConfig: DebitInterestCommonConfig
  previousVersionHasDebitInterest?: boolean
}

const CommonConfigDefinitions = ({ description, commonConfig, previousVersionHasDebitInterest = false }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <>
      {previousVersionHasDebitInterest && (
        <InfoPanel
          title={t(`Debit interest has been configured on a previous version of this product`)}
          body={t(`This means that you cannot change the accrual period or delete the feature`)}
        />
      )}
      <H5 gutter>{t('How interest is calculated & charged')}</H5>
      <FeatureDefinitions
        definitions={buildDebitInterestCommonConfigFeatureDefinitions({
          commonConfig,
          description,
          bottomSeparator: true,
          t,
        })}
      />
    </>
  )
}
export default CommonConfigDefinitions
