import { UserFilters } from 'store/user/types'

export enum ActionTypes {
  SEARCH_QUERY,
  FILTER_QUERY,
  RESET_SEARCH,
  LOAD_MORE,
}

export interface SearchAction {
  type: ActionTypes.SEARCH_QUERY
  payload: string
}

export interface FilterAction {
  type: ActionTypes.FILTER_QUERY
  payload: UserFilters
}

export interface ResetSearchAction {
  type: ActionTypes.RESET_SEARCH
}

export interface LoadMoreAction {
  type: ActionTypes.LOAD_MORE
}

export type UserSearchActions = SearchAction | FilterAction | ResetSearchAction | LoadMoreAction
