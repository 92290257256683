import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { ExtendedProduct } from 'store/products/types'
import ProductsActions from 'store/products/actions'
import { hasGovernanceEnabled } from 'pages/Product/product.utils'
import { TenantConfig } from 'store/tenant-config/types'
import GovernanceActions from 'store/governance/actions'
import TasksActions from 'store/tasks/actions'
import { isProductInGovernanceStatus } from 'utils/productVersion.utils'

const useProductGovernance = ({
  product,
  isLoading,
  tenantConfig,
}: {
  product?: ExtendedProduct
  isLoading: boolean
  tenantConfig?: TenantConfig
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isLoading && hasGovernanceEnabled(tenantConfig) && product && isProductInGovernanceStatus(product)) {
      dispatch(ProductsActions.getAllGovernenceDataForProduct({ productKey: product.productKey }))
      dispatch(ProductsActions.getAssignees())
    }
  }, [isLoading, dispatch, tenantConfig, product])

  useEffect(
    // This code is only called when component will unmount
    () => () => {
      dispatch(GovernanceActions.clearProcessExecution())
      dispatch(TasksActions.clearTasksForProduct())
    },
    [dispatch]
  )
}

export default useProductGovernance
