import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'
import styled from 'styled-components/macro'

interface MenuItemTitleProps extends TypographyProps {
  label?: React.ReactNode
}

const TypographyStyle = styled(Typography)`
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 8px;
`
const MenuItemTitle = ({ label, ...props }: MenuItemTitleProps) => (
  <TypographyStyle variant="subtitle1" color="primary" variantMapping={{ subtitle1: 'div' }} {...props}>
    {label}
  </TypographyStyle>
)

export default MenuItemTitle
