import { call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { filter, find, get, isArray, isEmpty } from 'lodash'
import i18next from 'i18next'
import * as Sentry from '@sentry/react'
import GovernanceActions from '../governance/actions'
import TasksActions from '../tasks/actions'
import productSelector from './selectors'
import { ExtendedProduct, LendingRateType, TransactionRules, CardsFormValues } from './types'
import { setPaymentScheduleSaga, deletePaymentScheduleSaga } from './sagas/paymentSchedule'
import {
  setupDebitInterestSaga,
  updateDebitInterestSaga,
  removeDebitInterestRateSaga,
  deleteDebitInterestFeatureSaga,
} from './sagas/debitInterest'
import { setPaymentLimitsConfigSaga, setPaymentLimitSaga, deletePaymentLimitSaga } from './sagas/paymentLimits'
import { setMultiPartySaga, deleteMultiPartySaga } from './sagas/multiParty'
import { setFeeRewardSaga, removeFeeRewardSaga } from './features/feesRewards/sagas'
import { setCardTransaction, deleteCardTransaction } from './sagas/cardLimits'
import { updateCreditLimitSaga, deleteCreditLimitSaga } from './sagas/creditLimit'
import { CardTransactionLimitsResponse } from './typings/cardLimits'
import { ProductSyndicateResponse } from './typings/productSyndication'
import { PaymentLimitsResponse } from './typings/paymentLimits'
import { ProductStatus } from 'store/products/typings/productStatus'
import { navigate } from 'store/router/actions'
import { navigateToUnavailable } from 'utils/url.utils'
import {
  GovernanceApi,
  ProductsApi,
  TasksApi,
  UserApi,
  PaymentLimitsApi,
  CardLimitsApi,
  ProductSyndicationApi,
} from 'api'
import ProductsActions from 'store/products/actions'
import ActivityActions from 'store/activities/actions'
import FeeRewardActions from 'store/products/features/feesRewards/actions'
import NotificationActions from 'store/notifications/actions'
import ModalActions from 'store/modal/actions'
import { ProcessExecution, Task } from 'store/tasks/types'
import TaskActions from 'store/tasks/actions'
import { NUMBEROFTRIES, UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { selectConfig } from 'store/tenant-config/selectors'
import { store } from 'store'
import { EntityType, Process } from 'store/governance/types'
import { LifeCycle } from 'store/governance/typings/lifeCycle'
import { UserRole } from 'store/user/types'
import { reOrderTransactionsRulesItems, extractKeysFromObject } from 'utils/common.util'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import { FeatureName } from 'store/products/types'
import { getPreviousVersionToGetProduct, isVersionFirst } from 'utils/productVersion.utils'
import { TenantConfig } from 'store/tenant-config/types'
import { showPendingActionModalSaga } from 'store/modal/sagas'
import StatementConfigActions from 'store/templates/statementConfiguration/actions'

function* showInProgress() {
  yield put(ModalActions.showFeatureProgressModal())
  yield call(ProductsActions.saveFeature)
}

function* showSuccess(productKey: string, successResponse: any) {
  yield put(navigate(`/products/${productKey}` as any))
  yield delay(UI_DELAY_TO_SHOW_LOADING)
  yield put(ModalActions.closeModal())
  if (successResponse) yield put(ModalActions.showFeatureSuccessModal(successResponse))
}

function* showError(e: Error) {
  yield put(ModalActions.closeModal())
  yield put(ModalActions.showFeatureFailureModal(e))
}

function* create(action: ReturnType<typeof ProductsActions.create>) {
  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.create> = yield call(ProductsApi.create, action.payload)
    yield showSuccess(response.productKey, { ...response, featureName: FeatureName.PRODUCT })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* fetch(action: ReturnType<typeof ProductsActions.fetch>) {
  try {
    const response: ReturnTypePromise<typeof ProductsApi.fetch> = yield call(ProductsApi.fetch)
    yield put(ProductsActions.fetchSuccess(response))
  } catch (e: any) {
    yield put(ProductsActions.fetchFail(e as Error))
    if (action?.payload?.shouldRedirect ?? true) {
      yield call(navigateToUnavailable)
    }
  }
}

export function* errorOnLoad(e: Error) {
  yield put(ModalActions.closeModal())
  yield put(ModalActions.showFeatureFailureModal(e))
}

function* getProductDetails(action: ReturnType<typeof ProductsActions.getProductDetails>) {
  try {
    const product:
      | ReturnTypePromise<typeof ProductsApi.getProductByVersion>
      | ReturnTypePromise<typeof ProductsApi.getProductDetails> = action.payload?.version
      ? yield ProductsApi.getProductByVersionSaga(action.payload.productKey, action.payload.version)
      : yield ProductsApi.getLatestProduct(action.payload.productKey)
    const tenantConfig: TenantConfig = yield select(selectConfig)
    let isCreditInterestEnabled = true
    let isDebitInterestEnabled = true
    let publishedDebitInterestRates = []
    const debitInterestRates =
      product.debitInterest &&
      extractKeysFromObject(product.debitInterest, [
        LendingRateType.rateWithinLimit,
        LendingRateType.rateAboveLimit,
        LendingRateType.rateAboveLimitOverride,
      ])

    // to refactor
    if ((product as any).scheduledFees) {
      product.fees = {
        fees: (product as any).scheduledFees?.scheduledFees,
      }
    }

    // to refactor
    if ((product as any).scheduledRewards) {
      product.rewards = {
        rewards: (product as any).scheduledRewards?.scheduledRewards,
      }
    }

    if (!isVersionFirst(product)) {
      const productVersionData: ReturnTypePromise<typeof ProductsApi.getProductByVersion> = yield call(
        ProductsApi.getProductByVersion,
        product.productKey,
        getPreviousVersionToGetProduct(product)
      )

      isCreditInterestEnabled = !productVersionData.creditInterest
      isDebitInterestEnabled = !productVersionData.debitInterest

      if (productVersionData.debitInterest) {
        publishedDebitInterestRates = Object.entries(productVersionData.debitInterest).map(
          ([key, value]) => value && key
        )
      }
    }

    if (tenantConfig.features.mission_wip) {
      try {
        const paymentLimits: PaymentLimitsResponse = yield call(
          PaymentLimitsApi.fetchPaymentLimits,
          product.productKey,
          product.version
        )
        product.paymentLimits = paymentLimits
      } catch (e: any) {
        // do nothing
      }
      try {
        const cardLimits: CardTransactionLimitsResponse = yield call(
          CardLimitsApi.fetchCardTransactionLimits,
          product.productKey,
          product.version
        )
        product.cardLimits = cardLimits
      } catch (e: any) {
        // do nothing
      }
    }

    yield put(ProductsActions.updateProductSuccess(product))
    yield put(
      ProductsActions.setProductPermissions({
        isCreditInterestEnabled,
        isDebitInterestEnabled,
        publishedDebitInterestRates,
        debitInterestRates,
      })
    )
  } catch (e: any) {
    yield put(ProductsActions.getProductDetailsFail(e as Error))
    yield call(navigateToUnavailable)
  }
}

function* publish(action: ReturnType<typeof ProductsActions.publish>) {
  try {
    const { payload } = action
    const response: ReturnTypePromise<typeof ProductsApi.publish> = yield call(ProductsApi.publish, payload)
    // @ TO DO: remove next line because previous line returns product without de-centralised features
    const product: ExtendedProduct = yield call(ProductsApi.getLatestProduct, response.productKey)
    yield put(ProductsActions.publishSuccess(product))
    yield put(ActivityActions.fetch({ productKey: response.productKey }))
  } catch (e: any) {
    yield put(ProductsActions.publishFail(e as Error))
  }
}

function* getProductSyndication(action: ReturnType<typeof ProductsActions.getProductSyndication>) {
  try {
    const response: ProductSyndicateResponse = yield call(ProductSyndicationApi.fetchProductSyndication, action.payload)

    yield put(ProductsActions.getProductSyndicationSuccess(response))
  } catch (error: any) {
    const errorResponse = error.toJSON()
    yield put(
      ProductsActions.getProductSyndicationFail({
        ...errorResponse,
        nonSyndicated: error?.response?.data?.code?.includes('404'),
        error: true,
      } as Error)
    )
  }
}

function* reviewProduct(action: ReturnType<typeof ProductsActions.review>) {
  const NO_USER_ERROR_MESSAGE = 'Users not defined'
  const NO_PROCESS_ERROR_MESSAGE = 'Process is not defined'
  const NO_PROCESS_LIFECYCLE_ERROR_MESSAGE = 'Process not available for this flow'
  const NO_TASK_ERROR_MESSAGE = 'Task is not defined'
  try {
    const { version, status, ...payload } = action.payload
    if (get(selectConfig(store.getState()), 'features.governance')) {
      const responseProcess: Process[] = yield call(GovernanceApi.getProcess)

      if (!responseProcess || !isArray(responseProcess) || responseProcess.length === 0) {
        Sentry.captureException(new Error(NO_PROCESS_ERROR_MESSAGE))
        throw new Error(NO_PROCESS_ERROR_MESSAGE)
      }
      const filteredProcesses = find(responseProcess, (i: Process) =>
        isVersionFirst({ version } as any) && status === ProductStatus.DESIGN
          ? i.entityLifecycle.toLowerCase() === LifeCycle.CREATE && i.entityType === EntityType.PRODUCT
          : i.entityLifecycle.toLowerCase() === LifeCycle.UPDATE && i.entityType === EntityType.PRODUCT
      )
      if (!filteredProcesses) {
        Sentry.captureException(new Error(NO_PROCESS_LIFECYCLE_ERROR_MESSAGE))
        throw new Error(NO_PROCESS_LIFECYCLE_ERROR_MESSAGE)
      } else {
        const responseTasks: ReturnTypePromise<typeof TasksApi.getTaskDefinitions> = yield call(
          TasksApi.getTaskDefinitions,
          filteredProcesses.key
        )
        if (!responseTasks || !isArray(responseTasks) || responseTasks.length === 0) {
          Sentry.captureException(new Error(NO_TASK_ERROR_MESSAGE))
          throw new Error(NO_TASK_ERROR_MESSAGE)
        }
      }
      const users: ReturnTypePromise<typeof UserApi.getUsers> = yield call(UserApi.getUsers)
      if (!users || !isArray(users) || users.length < 1) {
        Sentry.captureException(new Error(NO_USER_ERROR_MESSAGE))
        throw new Error(NO_USER_ERROR_MESSAGE)
      }
      const response: ReturnTypePromise<typeof ProductsApi.sendProductForReview> = yield call(
        ProductsApi.sendProductForReview,
        payload
      )
      // @ TO DO: remove next line because previous line returns product without de-centralised features
      const product: ExtendedProduct = yield call(ProductsApi.getLatestProduct, response.productKey)
      // @ Need to discuss with FT6 -- what is the expected result of response
      yield put(ProductsActions.reviewSuccess(product))
      if (!isEmpty(response)) {
        let isDone = false
        for (let i = 0; i < NUMBEROFTRIES; i += 1) {
          yield delay(UI_DELAY_TO_SHOW_LOADING)
          const actualProcesses: ReturnTypePromise<typeof GovernanceApi.getProcessExecutions> = yield call(
            GovernanceApi.getProcessExecutions,
            payload.productKey
          )
          if (!isEmpty(filter(actualProcesses, { status: 'ACTIVE' }))) {
            isDone = true
            yield put(GovernanceActions.processExecution({ entityKey: payload.productKey }))
            yield put(ProductsActions.openAssigneeModal())
            break
          }
        }
        if (!isDone) {
          Sentry.captureException(new Error('Approve task failed'))
          throw new Error('Approve task failed')
        }
      }
    } else {
      const response: ReturnTypePromise<typeof ProductsApi.sendProductForReview> = yield call(
        ProductsApi.sendProductForReview,
        payload
      )
      // @ Need to discuss with FT6 -- what is the expected result of response
      yield put(ProductsActions.reviewSuccess(response))
    }
  } catch (e: any) {
    if (e.message === NO_USER_ERROR_MESSAGE) {
      yield put(ProductsActions.reviewFailNoUser(e))
    } else if (
      e.message === NO_PROCESS_ERROR_MESSAGE ||
      e.message === NO_PROCESS_LIFECYCLE_ERROR_MESSAGE ||
      e.message === NO_TASK_ERROR_MESSAGE
    ) {
      yield put(ProductsActions.reviewFail(e as Error))
    } else {
      yield put(ProductsActions.reviewGeneralFail(e as Error))
    }
    yield put(GovernanceActions.processExecutionFail(e as Error))
    yield put(TasksActions.assignTasksResultClear())
  }
}

function* deleteProduct(action: ReturnType<typeof ProductsActions.deleteProduct>) {
  try {
    yield call(ProductsApi.deleteProduct, action.payload.productOrPackageKey)
    yield put(NotificationActions.deleteProductSuccess(action.payload.productOrPackageName))
    yield put(ProductsActions.fetch())
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(navigate(`/products`))
  } catch (e: any) {
    yield put(ProductsActions.deleteProductFail(e as Error))
    yield put(NotificationActions.deleteProductFail(action.payload.productOrPackageName))
  }
}

function* deleteFeatureSaga(action: ReturnType<typeof ProductsActions.deleteFeature>) {
  try {
    const {
      payload: { productKey, featureName },
    } = action
    const t = i18next.t.bind(i18next)
    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: `You're deleting ${t(featureName)?.toLowerCase()} feature`,
      message: "Are you sure? This can't be undone.",
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())
      yield call(ProductsApi.deleteFeature, action.payload)

      yield put(ProductsActions.getProductDetails({ productKey }))

      yield put(navigate(`/products/${productKey}` as any))

      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: `${t(featureName)} successfully deleted`,
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e as Error))
  }
}

function* editProduct(action: ReturnType<typeof ProductsActions.editProductDetails>) {
  const {
    payload: { productKey, ...data },
  } = action

  try {
    yield showInProgress()
    yield call(ProductsApi.updateProduct, productKey, data)
    const reponse: ReturnTypePromise<typeof ProductsApi.getProductDetails> = yield ProductsApi.getLatestProduct(
      productKey
    )
    yield showSuccess(productKey, { ...reponse, featureName: FeatureName.PRODUCTCORE })
    yield put(ActivityActions.fetch({ productKey: reponse.productKey }))
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setEligibility(action: ReturnType<typeof ProductsActions.setEligibility>) {
  try {
    const {
      payload: { productKey, ...data },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureEligibility> = yield call(
      ProductsApi.setFeatureEligibility,
      productKey,
      data
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.ELIGIBILITY })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setLimits(action: ReturnType<typeof ProductsActions.setLimits>) {
  try {
    const {
      payload: { productKey, ...data },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureLimits> = yield call(
      ProductsApi.setFeatureLimits,
      productKey,
      data
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.LIMITS })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setCards(action: ReturnType<typeof ProductsActions.setCards>) {
  try {
    const {
      payload: { productKey, ...data },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureCards> = yield call(
      ProductsApi.setFeatureCards,
      productKey,
      data
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.CARDS })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setCurrency(action: ReturnType<typeof ProductsActions.setCurrency>) {
  try {
    const {
      payload: { productKey, ...data },
    } = action

    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureCurrency> = yield call(
      ProductsApi.setFeatureCurrency,
      productKey,
      data
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.CURRENCY })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setRequiredExternalId(action: ReturnType<typeof ProductsActions.setRequiredExternalId>) {
  try {
    const {
      payload: { productKey, ...data },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureRequiredExternalId> = yield call(
      ProductsApi.setFeatureRequiredExternalId,
      productKey,
      data
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.REQUIREDEXTERNALID })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setCreditInterest(action: ReturnType<typeof ProductsActions.setCreditInterest>) {
  try {
    const {
      payload: { productKey, ...data },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureCreditInterest> = yield call(
      ProductsApi.setFeatureCreditInterest,
      productKey,
      data
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.CREDITINTEREST })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setTermsAndConditions(action: ReturnType<typeof ProductsActions.setTermsAndConditions>) {
  try {
    const {
      payload: { productKey, formData },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureTermsAndConditions> = yield call(
      ProductsApi.setFeatureTermsAndConditions,
      productKey,
      formData
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.TERMSANDCONDITIONS })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setDocuments(action: ReturnType<typeof ProductsActions.setDocuments>) {
  try {
    const {
      payload: { productKey, formData },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureDocuments> = yield call(
      ProductsApi.setFeatureDocuments,
      productKey,
      formData
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.DOCUMENTS })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setStatement(action: ReturnType<typeof ProductsActions.setStatement>) {
  try {
    const {
      payload: { productKey, ...data },
    } = action
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setFeatureStatement> = yield call(
      ProductsApi.setFeatureStatement,
      productKey,
      data
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.STATEMENTS })
    yield put(StatementConfigActions.updateDraftStatementFormValues({}))
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setSubscriptionCreation(action: ReturnType<typeof ProductsActions.setSubscriptionCreationRule>) {
  const { productKey, ...payload } = action.payload
  try {
    yield showInProgress()
    const response: ReturnTypePromise<typeof ProductsApi.setSubscriptionCreationRule> = yield call(
      ProductsApi.setSubscriptionCreationRule,
      productKey,
      payload
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.SUBSCRIPTIONCREATIONRULE })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* getAssignees() {
  try {
    const response: ReturnTypePromise<typeof UserApi.getUsers> = yield call(UserApi.getUsers)
    const filteredAssignees = [...response].filter((i) => i && [UserRole.ADMIN, UserRole.PRODUCT].includes(i.userRole))
    yield put(ProductsActions.getAssigneesSuccess(filteredAssignees))
  } catch (e: any) {
    yield put(ProductsActions.getAssigneesFail(e as Error))
  }
}

function* getProductVersions(action: ReturnType<typeof ProductsActions.getVersions>) {
  try {
    const response: ReturnTypePromise<typeof ProductsApi.getProductVersions> = yield call(
      ProductsApi.getProductVersions,
      action.payload
    )
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(ProductsActions.getVersionsSuccess(response))
  } catch (e: any) {
    yield put(ProductsActions.getVersionsFail(e as Error))
  }
}

function* getProductVersionsForPublish(action: ReturnType<typeof ProductsActions.getVersionsForPublish>) {
  try {
    const response: ReturnTypePromise<typeof ProductsApi.getProductVersions> = yield call(
      ProductsApi.getProductVersions,
      action.payload
    )
    yield put(ProductsActions.getVersionsSuccessForPublish(response))
  } catch (error) {
    yield put(ProductsActions.getVersionsFailForPublish(error as Error))
  }
}

function* setIdentification(action: ReturnType<typeof ProductsActions.setIdentification>) {
  const { productKey, ...otherPayload } = action.payload
  try {
    yield put(ModalActions.showFeatureProgressModal())
    yield put(ProductsActions.saveFeature())
    const response: ReturnTypePromise<typeof ProductsApi.setIdentification> = yield call(
      ProductsApi.setIdentification,
      productKey,
      otherPayload
    )
    yield showSuccess(productKey, { ...response, featureName: FeatureName.PRODUCTCODE })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* getAllGovernenceDataForProduct(action: ReturnType<typeof ProductsActions.getAllGovernenceDataForProduct>) {
  const { productKey } = action.payload
  try {
    const processes: ProcessExecution[] = yield call(GovernanceApi.getProcessExecutions, productKey)
    const activeProcesses = [
      ...processes.filter((process) => process.entityKey === productKey && process.status !== 'CLOSED'),
    ]
      // older first
      .sort((process1, process2) => new Date(process1.createdAt).getTime() - new Date(process2.createdAt).getTime())
    const allTasks = []
    if (activeProcesses && activeProcesses.length > 0) {
      for (let index = 0; index < activeProcesses.length; index += 1) {
        let tasks: Task[] = yield call(TasksApi.tasksExecutionsForProduct, activeProcesses[index].key) || []
        tasks = tasks.sort(
          (task1: Task, task2: Task) => new Date(task1.createdAt).getTime() - new Date(task2.createdAt).getTime()
        )
        allTasks.push(...tasks)
      }
    }
    yield put(GovernanceActions.processExecutionSuccess(processes))
    yield put(TaskActions.tasksExecutionsForProductSuccess(allTasks))
  } catch (e: any) {
    yield put(ProductsActions.getAllGovernenceDataForProductFail(e as Error))
  }
}

function* retireProduct(action: ReturnType<typeof ProductsActions.retireProduct>) {
  try {
    const product: ExtendedProduct = yield call(ProductsApi.retireProduct, action.payload)
    yield put(NotificationActions.retireProductSuccess(action.payload.productOrPackageName))
    let isCreditInterestEnabled = true
    let isDebitInterestEnabled = true

    if (!isVersionFirst(product)) {
      const productVersionData: ReturnTypePromise<typeof ProductsApi.getProductByVersion> = yield call(
        ProductsApi.getProductByVersion,
        product.productKey,
        getPreviousVersionToGetProduct(product)
      )
      isCreditInterestEnabled = !productVersionData.creditInterest
      isDebitInterestEnabled = !productVersionData.debitInterest
    }
    yield put(ProductsActions.setProductPermissions({ isCreditInterestEnabled, isDebitInterestEnabled }))
    yield put(ProductsActions.updateProductSuccess(product))
    yield put(ProductsActions.fetch())
  } catch (e: any) {
    yield put(NotificationActions.retireProductFail(action.payload.productOrPackageName))
  }
}

function* fetchCardImages() {
  try {
    const payload: CardsFormValues = yield call(ProductsApi.fetchCardImages)
    yield delay(UI_DELAY_TO_SHOW_LOADING + 1)
    yield put(ProductsActions.fetchCardImagesSuccessful(payload as any))
  } catch (e: any) {
    yield put(ProductsActions.fetchCardImagesFail(e as any))
  }
}

function* setTransactionRules(action: ReturnType<typeof ProductsActions.setTransactionRules>) {
  try {
    const {
      payload: { productKey, transactionRuleMessage, ...data },
    } = action
    yield showInProgress()
    const transactionRules: TransactionRules = yield ProductsApi.getLatestTransactionRules(productKey)
    yield call(ProductsApi.setFeatureTransactionRules, productKey, { ...transactionRules, ...data })
    yield put(ProductsActions.getProductDetails({ productKey }))

    yield showSuccess(productKey, {
      ...transactionRules,
      featureName: FeatureName.TRANSACTIONRULES,
      title: transactionRuleMessage,
    })
  } catch (e: any) {
    yield showError(e as Error)
  }
}

function* setTransactionRulesClearSuccess() {
  const product: ReturnType<typeof productSelector.selectSelectedProduct> = yield select(
    productSelector.selectSelectedProduct
  )
  if (product) {
    const { productKey } = product as ExtendedProduct
    yield put(navigate(`/products/${productKey}` as any))
  }
}

function* deleteTransactionRules(action: ReturnType<typeof ProductsActions.deleteTransactionRules>) {
  try {
    const product: ReturnType<typeof productSelector.selectSelectedProduct> = yield select(
      productSelector.selectSelectedProduct
    )
    if (product) {
      const { productKey } = product as ExtendedProduct

      const { payload: key } = action
      const transactionRules: TransactionRules = yield ProductsApi.getLatestTransactionRules(productKey)

      if (transactionRules.inbound && transactionRules.inbound.rules) {
        const { rules } = transactionRules.inbound
        transactionRules.inbound.rules = reOrderTransactionsRulesItems(rules.filter((i) => i.key !== key))
      }
      if (transactionRules.outbound && transactionRules.outbound.rules) {
        const { rules } = transactionRules.outbound
        transactionRules.outbound.rules = reOrderTransactionsRulesItems(rules.filter((i) => i.key !== key))
      }
      yield call(ProductsApi.setFeatureTransactionRules, productKey, transactionRules)
      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ProductsActions.deleteTransactionRulesSuccess())
      yield put(ProductsActions.getProductDetails({ productKey }))
    } else {
      throw new Error('Product not found')
    }
  } catch (e: any) {
    yield put(ProductsActions.deleteTransactionRulesFail(e as any))
  }
}

function* deleteTransactionRulesClear() {
  const product: ReturnType<typeof productSelector.selectSelectedProduct> = yield select(
    productSelector.selectSelectedProduct
  )
  if (product) {
    const { productKey } = product as ExtendedProduct
    yield put(navigate(`/products/${productKey}` as any))
  }
}

function* createNewProductVersion(action: ReturnType<typeof ProductsActions.createNewProductVersion>) {
  try {
    const t = i18next.t.bind(i18next)
    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: t('Are you sure you want to create a new version?'),
      message: t(
        'You will not be able to delete the version once it has been created. Subscriptions will be converted to the new version in line with the effective date specified when it is published.'
      ),
      pendingAction: ModalPendingAction.PRODUCT_NEW_CREATE,
      confirmBtnLabel: t('OK, Continue'),
    })
    if (isConfirmed) {
      yield showInProgress()
      yield delay(UI_DELAY_TO_SHOW_LOADING + 1)
      const response: ReturnTypePromise<typeof ProductsApi.createNewProductVersion> = yield call(
        ProductsApi.createNewProductVersion,
        action.payload
      )
      yield put(ProductsActions.getProductDetails({ productKey: action.payload }))
      yield put(ProductsActions.createNewProductVersionSuccess(response))

      yield showSuccess(action.payload, {
        title: `Version ${response.version} of ${response.productName} successfully created`,
      })
    }
  } catch (e: any) {
    yield put(ProductsActions.createNewProductVersionFail(e as any))
    yield showError(e as Error)
  }
}
export default function* () {
  yield takeLatest(ProductsActions.create.type, create)
  yield takeLatest(ProductsActions.fetch.type, fetch)
  yield takeLatest(ProductsActions.getProductDetails.type, getProductDetails)
  yield takeLatest(ProductsActions.publish.type, publish)
  yield takeLatest(ProductsActions.deleteProduct.type, deleteProduct)
  yield takeLatest(ProductsActions.editProductDetails, editProduct)
  yield takeLatest(ProductsActions.getProductSyndication, getProductSyndication)
  yield takeLatest(ProductsActions.review.type, reviewProduct)
  yield takeLatest(ProductsActions.setEligibility.type, setEligibility)
  yield takeLatest(ProductsActions.setCreditInterest.type, setCreditInterest)
  yield takeLatest(ProductsActions.setCards.type, setCards)
  yield takeLatest(ProductsActions.setCurrency.type, setCurrency)
  yield takeLatest(ProductsActions.setRequiredExternalId.type, setRequiredExternalId)
  yield takeLatest(ProductsActions.setTermsAndConditions.type, setTermsAndConditions)
  yield takeLatest(ProductsActions.setStatement.type, setStatement)
  yield takeLatest(ProductsActions.setLimits.type, setLimits)
  yield takeLatest(ProductsActions.setDocuments.type, setDocuments)
  yield takeLatest(ProductsActions.setSubscriptionCreationRule.type, setSubscriptionCreation)
  yield takeLatest(ProductsActions.setIdentification.type, setIdentification)
  yield takeLatest(ProductsActions.deleteFeature.type, deleteFeatureSaga)
  yield takeLatest(ProductsActions.getAssignees.type, getAssignees)
  yield takeLatest(ProductsActions.getVersions.type, getProductVersions)
  yield takeLatest(ProductsActions.getProductByVersion.type, getProductDetails)
  yield takeLatest(ProductsActions.getAllGovernenceDataForProduct.type, getAllGovernenceDataForProduct)
  yield takeLatest(ProductsActions.retireProduct.type, retireProduct)
  yield takeLatest(ProductsActions.getVersionsForPublish.type, getProductVersionsForPublish)
  yield takeLatest(ProductsActions.fetchCardImages.type, fetchCardImages)
  yield takeLatest(ProductsActions.setTransactionRules.type, setTransactionRules)
  yield takeLatest(ProductsActions.setTransactionRulesClearSuccess.type, setTransactionRulesClearSuccess)
  yield takeLatest(ProductsActions.deleteTransactionRules.type, deleteTransactionRules)
  yield takeLatest(ProductsActions.deleteTransactionRulesClear.type, deleteTransactionRulesClear)
  yield takeLatest(ProductsActions.createNewProductVersion.type, createNewProductVersion)
  yield takeLatest(ProductsActions.setPaymentSchedule.type, setPaymentScheduleSaga)
  yield takeLatest(ProductsActions.deletePaymentSchedule.type, deletePaymentScheduleSaga)
  yield takeLatest(ProductsActions.setupDebitInterest.type, setupDebitInterestSaga)
  yield takeLatest(ProductsActions.updateDebitInterest.type, updateDebitInterestSaga)
  yield takeLatest(ProductsActions.deleteDebitInterestFeature.type, deleteDebitInterestFeatureSaga)
  yield takeLatest(ProductsActions.removeDebitInterestRate.type, removeDebitInterestRateSaga)
  yield takeLatest(FeeRewardActions.setFeeReward.type, setFeeRewardSaga)
  yield takeLatest(FeeRewardActions.removeFeeReward.type, removeFeeRewardSaga)
  yield takeLatest(ProductsActions.setPaymentLimitsConfig.type, setPaymentLimitsConfigSaga)
  yield takeLatest(ProductsActions.setPaymentLimit.type, setPaymentLimitSaga)
  yield takeLatest(ProductsActions.deletePaymentLimit.type, deletePaymentLimitSaga)
  yield takeLatest(ProductsActions.setCardTransactionLimit.type, setCardTransaction)
  yield takeLatest(ProductsActions.deleteCardTransactionLimit.type, deleteCardTransaction)
  yield takeLatest(ProductsActions.updateCreditLimit.type, updateCreditLimitSaga)
  yield takeLatest(ProductsActions.deleteCreditLimit.type, deleteCreditLimitSaga)
  yield takeLatest(ProductsActions.setMultiParty.type, setMultiPartySaga)
  yield takeLatest(ProductsActions.deleteMultiParty.type, deleteMultiPartySaga)
}
