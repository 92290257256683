import React from 'react'
import { InfoOutlined } from '@material-ui/icons'
import { Typography, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface OwnProps {
  maxSelectableTransactions: number
  colour: string
}

const ReachedMaxSelectedInfo = ({ maxSelectableTransactions, colour }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <div style={{ textAlign: 'center', marginTop: '30px' }}>
      <InfoOutlined style={{ color: colour, fontSize: '60px' }} />
      <Typography variant="h3" variantMapping={{ h3: 'h2' }} css="display: contents;">
        {t(`So you know, there’s a limit...`)}
        <br />
        {t(`The maximum number of transactions you can transfer at once is ${maxSelectableTransactions}`)}
      </Typography>
      <Divider variant="middle" style={{ marginTop: '30px' }} />
    </div>
  )
}

export default ReachedMaxSelectedInfo
