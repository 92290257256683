import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SettingsTile from './ReferenceDataCard'
import { EntityBadgeIndexRate, EntityBadgeTaxRules } from 'dls/atoms/EntityBadges/EntityBadge'
import { Catalog } from 'components'
import { selectFeatures } from 'store/tenant-config/selectors'
import ContentContainer from 'pages/Layout/ContentContainer'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import { HelpModalType } from 'store/modal/types'
import { InfoIconButton } from 'dls'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'
import useModal from 'components/Modal/hooks/useModal'

const ReferenceData = () => {
  const { t } = useTranslation()
  const features = useSelector(selectFeatures)
  const showWithholdTaxes = Boolean(features.withholding_tax)

  const { showInfographicModal } = useModal()

  useBreadcrumbs({ breadcrumbs: [{ title: t('Reference data'), path: '' }], trigger: true })

  const settings = [
    {
      title: 'Index rates',
      goto: '/reference-data/index-rates',
      show: true,
      icon: <EntityBadgeIndexRate />,
    },
    {
      title: 'Rules for withholding tax on credit interest',
      goto: '/reference-data/withholding-tax',
      show: showWithholdTaxes,
      icon: <EntityBadgeTaxRules />,
    },
  ]

  return (
    <ContentContainer data-test-id="ban-settings-page">
      <Catalog
        entityName="Reference data"
        icons={[
          <InfoIconButton
            key="more-about-icon"
            ariaProps={{
              'aria-label': `${t('More about reference data')}`,
              'data-test-id': 'settings-info-dialog',
              'aria-haspopup': 'dialog',
              'aria-controls': 'more-about-dialog',
            }}
            onClick={() => showInfographicModal(HelpModalType.REFERENCE_DATA)}
            fontSize="medium"
          />,
        ]}
        list={settings}
        searchFilter={(item, text) => item.title.toLowerCase().includes(text)}
        cardRenderer={(list, idx, key) => (
          <CatalogCardContainer>
            {list[idx].show && (
              <SettingsTile key={key} goto={list[idx].goto} title={list[idx].title} icon={list[idx].icon} />
            )}
          </CatalogCardContainer>
        )}
      />
    </ContentContainer>
  )
}

export default ReferenceData
