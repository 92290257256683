import React, { ElementType } from 'react'
import { Button as MUIButton } from '@material-ui/core'
import styled, { css } from 'styled-components/macro'
import { ButtonCustom, iconColorSelector } from 'dls/shared/styled'
import { ColorType } from 'dls/shared/types'

export type ButtonCustomProps = ButtonCustom &
  ({ component: string; to?: never } | { component: ElementType; to: string } | { component?: never; to?: never })

export const buttonColorSelector = css<ButtonCustom>`
  ${({ color, theme }: any) => {
    switch (color) {
      case ColorType.BUTTON:
        return {
          backgroundColor: `${theme.palette.secondary.main}`,
          color: `${theme.palette.background.paper}`,
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.dark}`,
          },
        }

      case ColorType.SUCCESS:
        return {
          backgroundColor: `${theme.palette.success.main}`,
          color: `${theme.palette.background.paper}`,
          '&:hover': {
            backgroundColor: `${theme.palette.success.dark}`,
          },
        }
      case ColorType.ERROR:
        return {
          backgroundColor: `${theme.palette.error.main}`,
          color: `${theme.palette.background.paper}`,
          '&:hover': {
            backgroundColor: `${theme.palette.error.dark}`,
          },
        }

      case ColorType.INFO:
        return {
          backgroundColor: `${theme.palette.info.main}`,
          color: `${theme.palette.background.paper}`,
          '&:hover': {
            backgroundColor: `${theme.palette.info.dark}`,
          },
        }

      case ColorType.SECONDARY:
        return {
          backgroundColor: `${theme.palette.background.dark}`,
          color: `${theme.palette.text.secondary}`,
          '&:hover': {
            backgroundColor: `${theme.palette.background.dark}`,
          },
        }

      default:
        return {}
    }
  }}
`

export const buttonTertiaryColorSelector = css<ButtonCustom>`
  ${({ color, theme }: any) => {
    switch (color) {
      case ColorType.BUTTON:
        return {
          borderColor: `${theme.palette.secondary.main}`,
          color: `${theme.palette.secondary.main}`,
          '&:hover': {
            borderColor: `${theme.palette.secondary.dark}`,
            color: `${theme.palette.secondary.dark}`,
          },
        }

      case ColorType.SUCCESS:
        return {
          borderColor: `${theme.palette.success.main}`,
          color: `${theme.palette.success.main}`,
          '&:hover': {
            borderColor: `${theme.palette.success.dark}`,
            color: `${theme.palette.success.dark}`,
          },
        }
      case ColorType.ERROR:
        return {
          borderColor: `${theme.palette.error.main}`,
          color: `${theme.palette.error.main}`,
          '&:hover': {
            borderColor: `${theme.palette.error.dark}`,
            color: `${theme.palette.error.dark}`,
          },
        }

      case ColorType.INFO:
        return {
          borderColor: `${theme.palette.info.main}`,
          color: `${theme.palette.info.main}`,
          '&:hover': {
            borderColor: `${theme.palette.info.dark}`,
            color: `${theme.palette.info.dark}`,
          },
        }

      case ColorType.SECONDARY:
        return {
          borderColor: `${theme.palette.text.secondary}`,
          color: `${theme.palette.text.secondary}`,
          '&:hover': {
            borderColor: `${theme.palette.background.dark}`,
            color: `${theme.palette.background.dark}`,
          },
        }

      default:
        return {}
    }
  }}
`

const StyledMUIButton: any = styled(MUIButton)`
  color: ${iconColorSelector};

  &.MuiButton-contained {
    color: ${(props: any) => props.theme.palette.background.paper};
    background-color: ${(props: any) => props.theme.palette.secondary.main};
    ${buttonColorSelector}
    &.Mui-disabled {
      background-color: ${(props: any) => props.theme.palette.background.dark};
      color: ${(props: any) => props.theme.palette.text.secondary};
    }
  }

  &.MuiButton-outlined {
    background-color: transparent;
    color: ${(props: any) => props.theme.palette.secondary.dark};
    border-color: ${(props: any) => props.theme.palette.secondary.main};
    ${buttonTertiaryColorSelector}
    &.Mui-disabled {
      background-color: ${(props: any) => props.theme.palette.background.dark};
      color: ${(props: any) => props.theme.palette.text.secondary};
      border-color: ${(props: any) => props.theme.palette.text.secondary};
    }
  }

  &.MuiButton-text {
    &.Mui-disabled {
      color: ${(props: any) => props.theme.palette.text.secondary};
    }
  }
`

export const Button = ({ ariaProps, color = ColorType.BUTTON, ...props }: ButtonCustomProps) => (
  <StyledMUIButton data-dls="atom-button" {...props} {...ariaProps} color={color} />
)

export default Button
