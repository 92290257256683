import {
  ProductGroups,
  routingDestinations,
  SchemeNames as ImportedSchemeNames,
  SweepTypes,
} from '../../../static/transactionRules'

export enum TransactionRuleFieldType {
  RoutingDestination,
  SweepType,
  ProductGroup,
  TransactionCode,
  Number,
  SchemeName,
}

export const TypeOptions = {
  ROUTING_DESTINATION: routingDestinations,
  SWEEP_TYPE: SweepTypes,
  PRODUCT_GROUP: ProductGroups,
  // TRANSACTION_CODE: transactionCodes,
  SCHEME_NAME: ImportedSchemeNames,
}

export const TransactionThenOptions = [
  { name: 'PERMIT', label: 'Allow transaction' },
  { name: 'DENY', label: 'Block transaction' },
]

export const operators = {
  EQUAL_TO: 'Equal to',
  IN: 'Any of',
}

export const TransactionRuleType = {
  ROUTING_DESTINATION: {
    label: 'Routing destination',
    value: 'ROUTING_DESTINATION',
    operators: ['EQUAL_TO', 'IN'],
    type: TransactionRuleFieldType.RoutingDestination,
  },
  PRODUCT_GROUP: {
    label: 'Product group',
    value: 'PRODUCT_GROUP',
    operators: ['EQUAL_TO', 'IN'],
    type: TransactionRuleFieldType.ProductGroup,
  },
  SWEEP_TYPE: {
    label: 'Sweep type',
    value: 'SWEEP_TYPE',
    operators: ['EQUAL_TO', 'IN'],
    type: TransactionRuleFieldType.SweepType,
  },
  // TRANSACTION_CODE: {
  //   label: 'Transaction code',
  //   value: 'TRANSACTION_CODE',
  //   operators: ['EQUAL_TO', 'IN'],
  //   type: TransactionRuleFieldType.TransactionCode,
  // },
  SCHEME_NAME: {
    label: 'Scheme name',
    value: 'SCHEME_NAME',
    operators: ['EQUAL_TO', 'IN'],
    type: TransactionRuleFieldType.SchemeName,
  },
  // MERCHANT_CATEGORY_CODE: {
  //   label: 'Merchant category code (MCC)',
  //   value: 'MERCHANT_CATEGORY_CODE',
  //   operators: ['EQUAL_TO', 'IN'],
  //   type: TransactionRuleFieldType.Number,
  // },
}
