import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card, CardActionArea, Grid, Tooltip } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router'
import useInterestStatementTemplate from '../utils/useInterestStatementTemplate'
import useStatementConfiguration from '../utils/useStatementConfiguration'
import ContentContainer from 'pages/Layout/ContentContainer'
import { TemplateForm, EntityBadgeStatements, Paper, Body2, DownloadIconButton, ARadio, InfoIconButton } from 'dls'
import { AvatarSize } from 'dls/shared/types'
import { Loader } from 'components'
import { ProductSegment, StatementType } from 'store/products/types'
import { GetAppIcon } from 'dls/atoms/Icons/MUIIcons'
import StatementSampleImage from 'pages/features/Statements/StatementSampleImage'
import downloadTemplatePdf from 'pages/features/Statements/utils/downloadTemplatePdf'
import { Template } from 'store/statement/types'
import { Flexbox } from 'pages/Layout/Styled'
import useModal from 'components/Modal/hooks/useModal'
import { HelpModalType } from 'store/modal/types'
import StatementTemplateError from 'pages/features/Statements/StatementConfigLoader/StatementTemplateError'

const StyledCard = styled(Card)<{ isSelected: boolean }>`
  border: ${({ theme, isSelected }) =>
    isSelected ? `solid 2px ${theme.palette.secondary.main}` : 'solid 2px transparent'};
`

const StyledFlexbox = styled(Flexbox)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.dark};
`

const SelectStatementTemplatePage = (): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { showInfographicModal } = useModal()

  const { saveDraftStatementConfigurationTemplate, draftStatementConfigurationFormValues } = useStatementConfiguration()
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    draftStatementConfigurationFormValues?.template || null
  )

  const productSegment = draftStatementConfigurationFormValues?.productSegment ?? ProductSegment.PERSONAL
  const { templates, templatesLoading, error } = useInterestStatementTemplate(ProductSegment[productSegment])

  const onCancel = () => {
    navigate(location.pathname.replace(/\/selectTemplate/i, ''))
  }

  const onSubmit = () => {
    if (selectedTemplate) {
      saveDraftStatementConfigurationTemplate(selectedTemplate)
      navigate(location.pathname.replace(/\/selectTemplate/i, ''))
    }
  }

  const renderForm = () => {
    if (templatesLoading) {
      return <Loader />
    }

    if (error) {
      return (
        <StatementTemplateError
          statementType={StatementType.INTEREST_STATEMENT}
          productSegment={ProductSegment[productSegment]}
          reason="template-error"
        />
      )
    }

    if (!templates?.length) {
      return (
        <StatementTemplateError
          reason="no-items"
          messageTitle={t('Oops, no templates exist')}
          message={t("Don't worry, you can raise a Zendesk ticket so a template can be created")}
        />
      )
    }

    return (
      <Grid container component="ol" spacing={3} css="padding-inline-start: 0px;">
        {[...templates]
          .sort((a, b) => a.templateName.localeCompare(b.templateName))
          .map((template) => {
            const isSelected = template.templateGroupId === selectedTemplate?.templateGroupId
            return (
              <Grid
                item
                component="li"
                key={template.templateGroupId}
                xs={12}
                sm={6}
                md={4}
                css="list-style: none; position: relative; width: 100%;"
              >
                <StyledCard isSelected={isSelected}>
                  <CardActionArea onClick={() => setSelectedTemplate(template)} aria-selected={isSelected}>
                    <StyledFlexbox direction="column" justifyContent="center" alignItems="center">
                      <StatementSampleImage
                        template={template}
                        templateType={StatementType.INTEREST_STATEMENT}
                        productSegment={ProductSegment[productSegment]}
                      />
                    </StyledFlexbox>
                    <Flexbox direction="row" justifyContent="space-between" alignItems="center" p={12}>
                      <ARadio checked={isSelected} />
                      <Body2 css="flex-grow: 2;">{template.templateName}</Body2>
                      <Tooltip title={`${t('Download PDF')}`} placement="bottom">
                        <DownloadIconButton
                          aria-label={t('Download statement as a PDF')}
                          onClick={() =>
                            downloadTemplatePdf(ProductSegment.PERSONAL, StatementType.INTEREST_STATEMENT, {
                              id: template.templateGroupId,
                              name: template.templateName,
                            })
                          }
                        >
                          <GetAppIcon />
                        </DownloadIconButton>
                      </Tooltip>
                    </Flexbox>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            )
          })}
      </Grid>
    )
  }

  return (
    <ContentContainer data-test-id="select-statement-template-page">
      <TemplateForm
        icon={<EntityBadgeStatements size={AvatarSize.LARGE} />}
        title={t('Choose template design')}
        description={t('STATEMENT_CONFIGURATION.SELECT_TEMPLATE_PAGE_DESCRIPTION')}
        toolbarButtons={
          <div style={{ marginTop: -12, marginRight: -12 }}>
            <InfoIconButton
              key="more-about-icon"
              ariaProps={{
                'aria-label': `${t('More about interest statements')}`,
                'aria-haspopup': 'dialog',
                'aria-controls': 'more-about-dialog',
              }}
              onClick={() => showInfographicModal(HelpModalType.INTEREST_STATEMENTS)}
              fontSize="medium"
            />
          </div>
        }
        main={<Paper responsivePadding>{renderForm()}</Paper>}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButtonText={t('Choose')}
        submitButtonDisabled={!selectedTemplate}
      />
    </ContentContainer>
  )
}

export default SelectStatementTemplatePage
