import React, { useCallback } from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Field, Form, FormikProps } from 'formik'
import moment from 'moment-timezone'
import { ButtonContainer, FormBottom, ContentPaperContainer, RowGrid } from '../styles'
import CreateInterestRateList from './CreateInterestRateList'
import { BankInterestRate, BankInterestRateFormValues } from 'store/referenceData/types'
import { IndexRatesInfoGraphic } from 'assets'
import {
  BackButton,
  FormBottomBar,
  SidePanel,
  TextFieldComplex,
  TemplateFeatureForm as TemplateFeatureFormComp,
} from 'components'
import { rateYupValidation, withFormikSimple, Yip } from 'utils/form.utils'
import ContentContainer from 'pages/Layout/ContentContainer'
import { HelpModalType } from 'store/modal/types'
import { InfoPanel } from 'dls'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  bankInterestRates?: BankInterestRate[]
}

interface CreateRateFormProps extends FormikProps<BankInterestRateFormValues>, OwnProps {}

const CreateInterestRateForm = (props: CreateRateFormProps): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onPressBack = useCallback(() => {
    navigate(`/reference-data/index-rates`)
  }, [])

  const existsAbbreviations = props.bankInterestRates?.map((i) => i?.abbreviation) || []

  return (
    <ContentContainer data-test-id="add-rate-page">
      <TemplateFeatureFormComp
        main={
          <>
            <Form>
              <ContentPaperContainer elevation={1}>
                <Box mb={3}>
                  <InfoPanel
                    title={t(
                      `You won’t be able to rename the index rate or change the abbreviation later and rate effective dates can’t be changed once submitted.`
                    )}
                    body={t(`You can create a current rate and add/edit future rates`)}
                  />
                </Box>
                <Grid container justifyContent="flex-start" alignItems="flex-start">
                  <RowGrid item xs={12}>
                    <Field
                      name="name"
                      label={t('Index rate name')}
                      placeholder={t('Enter name')}
                      required
                      validate={Yip(Yup.string().required('This is a required field'))}
                      component={TextFieldComplex}
                      helperText={t('E.g Bank of England')}
                      inputLabelProps={{ shrink: true }}
                      style={{ width: 300 }}
                    />
                  </RowGrid>
                  <RowGrid item xs={12}>
                    <Field
                      name="abbreviation"
                      label={t('Abbreviation')}
                      placeholder={t('Enter abbreviation')}
                      required
                      validate={Yip(
                        Yup.string()
                          .matches(/^[A-Z]+$/, 'Only uppercase letters are allowed for this field ')
                          .required('This is a required field')
                          .notOneOf([...(existsAbbreviations || [])], t('Abbreviation must be unique'))
                      )}
                      component={TextFieldComplex}
                      helperText={t('E.g. BOE, must be unique, uppercase alpha characters only, no spaces or numbers')}
                      inputLabelProps={{ shrink: true }}
                      style={{ width: 300 }}
                    />
                  </RowGrid>
                  <RowGrid item xs={12}>
                    <CreateInterestRateList {...props} name="rates" />
                  </RowGrid>
                </Grid>
                <FormBottom>
                  <FormBottomBar inlineNavigation spacing={1}>
                    <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
                      {t('Cancel')}
                    </Button>
                  </FormBottomBar>
                </FormBottom>
              </ContentPaperContainer>
            </Form>
          </>
        }
        header={
          <>
            <ButtonContainer>
              <BackButton onPressBack={onPressBack} aria-controls="main" />
            </ButtonContainer>
            <Typography variant="h1" gutterBottom>
              {t('Create index rate')}
            </Typography>
            <Typography variant="body2" variantMapping={{ body2: 'p' }} gutterBottom>
              {t('Set-up and manage index rates and effective dates.')}
            </Typography>
          </>
        }
        aside={
          <SidePanel
            heading={t('ABOUT INDEX RATES')}
            image={IndexRatesInfoGraphic}
            imageAlt={t('Illustration of a laptop showing a bar chart of changing index rate.')}
            details={t(
              'Index rates can be created, set and managed here. These rates can be selected when building a product to configure indexed interest rates.'
            )}
            linkToOpenModal={t('More about index rates')}
            infographicType={HelpModalType.INDEX_RATES}
          />
        }
      />
    </ContentContainer>
  )
}

export default withFormikSimple<OwnProps, BankInterestRateFormValues>(CreateInterestRateForm, {
  validationSchema: Yup.object({
    rates: Yup.array(
      Yup.object({
        effectiveDate: Yup.date()
          .typeError('Please select a valid date')
          .default(() => new Date().toISOString())
          .required('Date is required')
          .min(moment().startOf('day').toISOString(), 'Cannot be before today'),
        indexRate: rateYupValidation(4, 1000).required('Rate is required'),
      }).nullable()
    ).required(),
  }),
})
