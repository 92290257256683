import React from 'react'
import { FastField } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { InfoPanel } from 'dls'
import countriesJson from 'static/countries.json'
import { Yip } from 'utils/form.utils'
import FormikMultiSelectAutocomplete, {
  AutocompleteSelectFieldData,
} from 'components/form/FormikMultiSelectAutocomplete'
import { CountryItem } from 'store/utils/types'
import { Flexbox } from 'pages/Layout/Styled'

const TaxIdentificationEligibilityElement = ({ name }: { name: string }) => {
  const { t } = useTranslation()

  const selectDataCountriesJson: AutocompleteSelectFieldData = {}
  Object.entries(countriesJson).forEach(([, item]: [string, CountryItem]) => {
    selectDataCountriesJson[`${item.alpha3Code}`] = {
      key: item.alpha3Code,
      label: `${item.name}`,
      flag: `${item.alpha2Code}`,
    }
  })

  return (
    <Flexbox direction="column" width="100%">
      <InfoPanel
        color="info"
        body={t(
          'A party will be eligible for the product if their tax ID is from one or more of the countries you choose here'
        )}
      />
      <FastField
        name={`${name}.countryCode`}
        label={t('Any countries for which a tax ID must be held by a party')}
        data={selectDataCountriesJson}
        component={FormikMultiSelectAutocomplete}
        validate={Yip(Yup.string().required())}
        hasFlag
        required
        multiple
        style={{ paddingTop: 28, paddingBottom: 24 }}
      />
    </Flexbox>
  )
}

export default TaxIdentificationEligibilityElement
