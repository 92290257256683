import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { GroupHeadingMenuItem, FormInputProps, StyledMenuItem } from './shared'
import { FeeRewardDataPointType } from 'store/products/features/feesRewards/types'
import { Select } from 'dls'
import { DataPointAggregatedOperator, DataPointOperator } from 'store/products/typings/conditionBuilder'
import { SelectOption } from 'dls/shared/types'
import { FeeRewardFeatureType } from 'store/products/types'

const FirstOperatorSelect = ({
  control,
  watch,
  featureType,
  onChange,
}: FormInputProps & { featureType: FeeRewardFeatureType }): JSX.Element | null => {
  const { t } = useTranslation()

  const dataPoint = watch('dataPoint')

  const options = useMemo((): SelectOption[] | null => {
    switch (dataPoint) {
      case FeeRewardDataPointType.BALANCE_END_OF_DAY_AMOUNT:
        return [
          {
            value: 'comparisons_heading',
            label: t(`PRODUCT_FEATURE.${featureType}.COMPARISONS_HEADING`),
            groupHeading: true,
          },
          ...Object.values(DataPointOperator).map((operator) => ({
            value: operator,
            label: t(`CONDITION_BUILDER.${operator}`),
          })),
          {
            value: 'calculations_heading',
            label: t(`PRODUCT_FEATURE.${featureType}.CALCULATIONS_HEADING`),
            groupHeading: true,
          },
          ...Object.values(DataPointAggregatedOperator)
            .filter((operator) => ![DataPointAggregatedOperator.COUNT].includes(operator))
            .map((operator) => ({
              value: operator,
              label: t(`CONDITION_BUILDER.${operator}`),
            })),
        ]
      case FeeRewardDataPointType.BALANCE_UNIQUE_ENTRY:
        return [
          {
            value: DataPointAggregatedOperator.COUNT,
            label: t(`CONDITION_BUILDER.${DataPointAggregatedOperator.COUNT}`),
          },
        ]
      case FeeRewardDataPointType.TRANSACTION_AMOUNT:
        return [
          {
            value: 'comparisons_heading',
            label: t(`PRODUCT_FEATURE.${featureType}.COMPARISONS_HEADING`),
            groupHeading: true,
          },
          ...Object.values(DataPointOperator).map((operator) => ({
            value: operator,
            label: t(`CONDITION_BUILDER.${operator}`),
          })),
          {
            value: 'calculations_heading',
            label: t(`PRODUCT_FEATURE.${featureType}.CALCULATIONS_HEADING`),
            groupHeading: true,
          },
          ...Object.values(DataPointAggregatedOperator)
            .filter((operator) => ![DataPointAggregatedOperator.COUNT].includes(operator))
            .map((operator) => ({
              value: operator,
              label: t(`CONDITION_BUILDER.${operator}`),
            })),
        ]
      case FeeRewardDataPointType.TRANSACTION_ISO_CODE:
      case FeeRewardDataPointType.TRANSACTION_IS_CREDIT:
        return [
          {
            value: DataPointOperator.EQUAL_TO,
            label: t(`CONDITION_BUILDER.${DataPointOperator.EQUAL_TO}`),
          },
        ]
      case FeeRewardDataPointType.TRANSACTION_UNIQUE_ENTRY:
        return [
          {
            value: DataPointAggregatedOperator.COUNT,
            label: t(`CONDITION_BUILDER.${DataPointAggregatedOperator.COUNT}`),
          },
        ]
      default:
        return null
    }
  }, [dataPoint])

  if (dataPoint && options) {
    return (
      <Grid item xs={12} sm={6}>
        <Controller
          name="dataPointFirstOperator"
          control={control}
          render={({ field }) => (
            <Select
              label={`${t(`PRODUCT_FEATURE.${featureType}.AND`)} ${t(
                `PRODUCT_FEATURE.${featureType}.${dataPoint}`
              )?.toLocaleLowerCase()}`}
              required
              placeholder={t('Please select')}
              displayEmpty
              fullWidth
              {...field}
              onChange={(e) => {
                onChange(() => field.onChange(e))
              }}
            >
              {options.map(({ value, label, groupHeading }) =>
                groupHeading ? (
                  <GroupHeadingMenuItem key={value} value={value}>
                    {label}
                  </GroupHeadingMenuItem>
                ) : (
                  <StyledMenuItem key={value} value={value}>
                    {label}
                  </StyledMenuItem>
                )
              )}
            </Select>
          )}
        />
      </Grid>
    )
  }

  return null
}

export default FirstOperatorSelect
