import React, { CSSProperties } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { SvgIconTypeMap, Tooltip as MuiTooltip } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

interface Props {
  id?: string
  style?: CSSProperties
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  ariaProps?: Record<string, string | boolean>
  interactive?: boolean
  children?: React.ReactNode
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  Icon?: OverridableComponent<SvgIconTypeMap>
}

export const ContextualHelp = ({
  id,
  style,
  title,
  placement = 'right',
  ariaProps,
  Icon = HelpOutlineIcon,
  ...props
}: Props) => {
  const theme = useTheme()

  return (
    <MuiTooltip data-dls="molecule-contextual-help" id={id} title={title} placement={placement} {...props}>
      <Icon
        style={{
          verticalAlign: 'middle',
          margin: '-2px 12px 0',
          cursor: 'pointer',
          color: theme.palette.text.secondary,
          ...style,
        }}
        tabIndex={0}
        {...ariaProps}
      />
    </MuiTooltip>
  )
}

export default ContextualHelp
