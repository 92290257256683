import styled from 'styled-components/macro'
import { DialogActions, Grid, Typography } from '@material-ui/core'

export const FormBottom = styled(Grid)`
  padding-top: 16px;
`
export const StyledDivEnhanceTable = styled.div`
  padding-top: 32px;
`
export const StyledDivProductsTitle = styled(Typography)`
  padding-top: 32px;
  padding-bottom: 16px;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 6px;
`

export const TooltipStyle = styled.div`
  margin-top: 16px;
`
export const ContentGridContainer = styled(Grid)`
  padding: 16px 24px 24px;
`
export const StyledDialogActions = styled(DialogActions)`
  padding-top: 20px;
  padding-right 0;
`
export const Header = styled.div`
  padding-bottom: 0;
`
