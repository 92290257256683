import React from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { Grid, Box } from '@material-ui/core'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import { H2, Body2, RadioGroupFieldComplex } from 'components'
import { AmortisationMethod } from 'store/products/types'
import { LinkWithText } from 'dls/molecules/Buttons'
import { HelpModalType } from 'store/modal/types'
import useModal from 'components/Modal/hooks/useModal'

interface OwnProps {
  onMethodChange: () => void
}

const AmortisationMethodForm = ({ onMethodChange }: OwnProps) => {
  const { t } = useTranslation()
  const { showInfographicModal } = useModal()

  return (
    <Grid item>
      <H2 gutterBottom>{t('Amortisation method')}</H2>
      <Body2 gutterBottom>
        {t('There are 2 options :')}{' '}
        <LinkWithText
          aria-controls="more-about-dialog"
          aria-haspopup="dialog"
          onClick={() => showInfographicModal(HelpModalType.PAYMENT)}
          component="span"
          endIcon={<InfoOutlined />}
        >
          {t('about amortisation methods')}
        </LinkWithText>
      </Body2>
      <Box ml={1}>
        <Field
          name="amortisationMethod"
          data={AmortisationMethod}
          component={RadioGroupFieldComplex}
          required
          onChange={onMethodChange}
        />
      </Box>
    </Grid>
  )
}

export default AmortisationMethodForm
