import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

export interface SelectItem {
  value: string
  label: string
}

interface SimpleProps {
  id: string
  label: string
  onChange: (e: ChangeEvent<any>) => void
  value: string
  options: SelectItem[]
  required?: boolean
}

const SelectFieldSimple = React.forwardRef(
  ({ options, id, label, onChange, value, required }: SimpleProps, ref: React.Ref<HTMLSelectElement>) => {
    const { t } = useTranslation()
    return (
      <FormControl fullWidth>
        <InputLabel shrink htmlFor={id}>
          {label} {required ? '*' : ''}
        </InputLabel>
        <Select
          inputProps={{ id }}
          value={value}
          onChange={onChange}
          fullWidth
          displayEmpty
          required={required}
          inputRef={ref}
        >
          <MenuItem value="">{t('Please Select')}</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
)

export default SelectFieldSimple
