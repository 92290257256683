import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Control, Controller, FieldValues, UseFormWatch, UseFormSetValue } from 'react-hook-form'
import { ContextualHelp } from 'components'
import {
  ApplicationFrequency,
  WeeklyFrequencyItems,
  MonthlyFrequencyItems,
  YearlyFrequencyItems,
  CalendarDay,
  CalendarMonth,
} from 'store/products/types'
import { calculateDaysInMonth } from 'utils/date.utils'
import { Select } from 'dls'
import MenuItem from 'dls/atoms/MenuItem'

interface Props {
  disabled?: boolean
  disableTooltip?: boolean
  frequencyFieldLabel?: string
  control: Control
  watch: UseFormWatch<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

const ReactHookFormISOFrequencySelector = ({
  disabled,
  disableTooltip,
  frequencyFieldLabel,
  control,
  watch,
  setValue,
}: Props) => {
  const { t } = useTranslation()

  const monthlyOccurence = watch('monthlyOccurence')
  const annualOccurence = watch('annualOccurence')
  const annualSpecificMonth = watch('annualSpecificMonth')
  const frequency = watch('frequency')

  const onFieldChange = (value: string): void => {
    switch (true) {
      case Object.values(ApplicationFrequency).includes(value as ApplicationFrequency):
        setValue('frequency', value)
        setValue('weeklyOccurence', undefined)
        setValue('monthlyOccurence', undefined)
        setValue('monthlySpecificDay', undefined)
        setValue('annualOccurence', undefined)
        setValue('annualSpecificMonth', undefined)
        setValue('annualSpecificDay', undefined)
        break
      case Object.values(MonthlyFrequencyItems).includes(value as MonthlyFrequencyItems):
        setValue('monthlyOccurence', value)
        setValue('monthlySpecificDay', undefined)
        break
      case Object.values(YearlyFrequencyItems).includes(value as YearlyFrequencyItems):
        setValue('annualOccurence', value)
        setValue('annualSpecificMonth', undefined)
        setValue('annualSpecificDay', undefined)
        break
      case Object.values(CalendarMonth).includes(value as CalendarMonth):
        setValue('annualSpecificMonth', value)
        setValue('annualSpecificDay', undefined)
        break
      default:
        break
    }
  }

  const renderWeeklyField = (): JSX.Element => (
    <Grid item xs={12} sm={4}>
      <Controller
        name="weeklyOccurence"
        control={control}
        render={({ field }) => (
          <Select
            id="weeklyOccurence"
            fullWidth
            label={t('Day')}
            placeholder={t('Please select')}
            required
            displayEmpty
            disabled={disabled}
            {...field}
          >
            {Object.values(WeeklyFrequencyItems).map((item) => (
              <MenuItem key={item} value={item}>
                {t(item)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Grid>
  )

  const renderMonthlyFields = (): JSX.Element => (
    <>
      <Grid item xs={12} sm={4}>
        <Controller
          name="monthlyOccurence"
          control={control}
          render={({ field }) => (
            <Select
              id="monthlyOccurence"
              fullWidth
              displayEmpty
              label={t('Occurrence')}
              placeholder={t('Please select')}
              required
              disabled={disabled}
              {...field}
              onChange={(e) => onFieldChange(e.target.value as string)}
            >
              {Object.values(MonthlyFrequencyItems).map((item) => (
                <MenuItem key={item} value={item}>
                  {t(item)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      {monthlyOccurence === MonthlyFrequencyItems.SPECIFIC_DAY ? (
        <Grid item xs={12} sm={4}>
          <Controller
            name="monthlySpecificDay"
            control={control}
            render={({ field }) => (
              <Select
                id="monthlySpecificDay"
                fullWidth
                displayEmpty
                label={t('Choose day')}
                placeholder={t('Please select')}
                required
                disabled={disabled}
                {...field}
              >
                {Object.values(CalendarDay).map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(`CALENDAR.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      ) : null}
    </>
  )

  const renderAnnualFields = (): JSX.Element => (
    <>
      <Grid item xs={12} sm={4}>
        <Controller
          name="annualOccurence"
          control={control}
          render={({ field }) => (
            <Select
              id="annualOccurence"
              fullWidth
              displayEmpty
              label={t('Occurrence')}
              placeholder={t('Please select')}
              required
              disabled={disabled}
              {...field}
              onChange={(e) => onFieldChange(e.target.value as string)}
            >
              {Object.values(YearlyFrequencyItems).map((item) => (
                <MenuItem key={item} value={item}>
                  {t(item)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      {annualOccurence === YearlyFrequencyItems.SPECIFIC_DATE ? (
        <>
          <Grid item xs={12} sm={4}>
            <Controller
              name="annualSpecificMonth"
              control={control}
              render={({ field }) => (
                <Select
                  id="annualSpecificMonth"
                  fullWidth
                  displayEmpty
                  label={t('Choose month')}
                  placeholder={t('Please select')}
                  required
                  disabled={disabled}
                  {...field}
                  onChange={(e) => onFieldChange(e.target.value as string)}
                >
                  {Object.values(CalendarMonth).map((item) => (
                    <MenuItem key={item} value={item}>
                      {t(`CALENDAR.${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          {annualSpecificMonth && (
            <Grid item xs={12} sm={4}>
              <Controller
                name="annualSpecificDay"
                control={control}
                render={({ field }) => (
                  <Select
                    id="annualSpecificDay"
                    fullWidth
                    displayEmpty
                    label={t('Choose day')}
                    placeholder={t('Please select')}
                    required
                    disabled={disabled}
                    {...field}
                  >
                    {Object.values(calculateDaysInMonth(annualSpecificMonth)).map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`CALENDAR.${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          )}
        </>
      ) : null}
    </>
  )

  const renderFrequencyTypeField = (): JSX.Element => {
    const frequencyTypeField = (
      <>
        <Grid item xs={12} sm={4}>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Select
                id="frequency"
                fullWidth
                displayEmpty
                label={t(frequencyFieldLabel || 'Accrual period ends')}
                placeholder={t('Please select')}
                required
                disabled={disabled}
                {...field}
                onChange={(e) => onFieldChange(e.target.value as string)}
              >
                {Object.values(ApplicationFrequency).map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(`VIEW_FREQUENCY.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        {!disableTooltip && (
          <Grid item xs={2} style={{ paddingLeft: '10px' }}>
            <ContextualHelp
              placement="top"
              title={<>{t('VIEW_FREQUENCY.ACCRUAL_PERIOD_HELP')}</>}
              style={{ marginTop: 16 }}
            />
          </Grid>
        )}
      </>
    )

    if (disableTooltip) {
      return frequencyTypeField
    }

    return (
      <Grid container item direction="row" xs={12} spacing={1}>
        {frequencyTypeField}
      </Grid>
    )
  }

  const renderTopLevelFrequencyField = (): JSX.Element | null => {
    switch (frequency) {
      case ApplicationFrequency.DAILY:
        return null
      case ApplicationFrequency.WEEKLY:
        return renderWeeklyField()
      case ApplicationFrequency.MONTHLY:
        return renderMonthlyFields()
      case ApplicationFrequency.ANNUALLY:
        return renderAnnualFields()
      default:
        return null
    }
  }

  return (
    <Grid container spacing={2}>
      {renderFrequencyTypeField()}
      {renderTopLevelFrequencyField()}
    </Grid>
  )
}

export default ReactHookFormISOFrequencySelector
