import { createSelector } from 'reselect'
import { RootState } from 'store'
import { StatementType } from 'store/products/types'

const statementConfigurationState = (state: RootState) => state.statementConfiguration

const statementConfigurations = createSelector(statementConfigurationState, (state) => state.statementConfigurations)

const loading = createSelector(statementConfigurationState, (state) => state.loading)

const error = createSelector(statementConfigurationState, (state) => state.error)

const selectInterestStatementConfigs = createSelector(statementConfigurations, (configurations) =>
  configurations
    ?.filter((config) => config.statementType === StatementType.INTEREST_STATEMENT)
    ?.sort((a, b) => a.name.localeCompare(b.name))
)

const selectedStatementProducts = createSelector(
  statementConfigurationState,
  (state) => state.selectedStatementProducts
)

const statementProductsLoading = createSelector(
  statementConfigurationState,
  (state) => state.selectedStatementProductsLoading
)

const statementProductsError = createSelector(
  statementConfigurationState,
  (state) => state.selectedStatementProductsError
)

const draftStatementConfigurationFormValues = createSelector(
  statementConfigurationState,
  (state) => state.draftStatementConfigurationFormValues
)

const selectDraftStatementsForm = createSelector(statementConfigurationState, (state) => state.draftStatementsForm)

export default {
  statementConfigurations,
  loading,
  error,
  selectInterestStatementConfigs,
  draftStatementConfigurationFormValues,
  selectedStatementProducts,
  statementProductsLoading,
  statementProductsError,
  selectDraftStatementsForm,
}
