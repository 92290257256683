import { useState, useMemo } from 'react'
import { findIndex as _findIndex } from 'lodash'
import getProductStatusFilterOptions from 'pages/Products/FilterMenuItems/getProductStatusFilterOptions'
import { Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { toBankTz } from 'utils/date.utils'

const useProductFilter = (items: Product[], statusQueryParam: string) => {
  const statusFilterOptions = getProductStatusFilterOptions(items)

  let initialSelectedFilter = 0

  if (statusQueryParam) {
    if (statusQueryParam.toLowerCase() === 'scheduled') {
      initialSelectedFilter = _findIndex(statusFilterOptions, (option: any) => option.isScheduled)
    } else {
      initialSelectedFilter = _findIndex(statusFilterOptions, (option: any) =>
        option?.status?.some(
          (item: any) => item.toLowerCase() === statusQueryParam.toLowerCase() && !option.isScheduled
        )
      )
    }
  }

  const preSelectFilter = initialSelectedFilter > -1 ? initialSelectedFilter : 0

  const [selectedFilter, setSelectedFilter] = useState(preSelectFilter)

  const filteredItems = useMemo(() => {
    if (selectedFilter === 0) {
      return items
    }

    const selectedItem = statusFilterOptions[selectedFilter]
    const isLiveStatus = selectedItem?.status?.includes(ProductStatus.LIVE)

    if (isLiveStatus) {
      const isScheduledStatus = selectedItem?.isScheduled
      if (isScheduledStatus) {
        return items.filter(
          (item) =>
            selectedItem?.status?.includes(item.status) && item.effectiveDate && toBankTz(item.effectiveDate).isAfterNow
        )
      }

      return items.filter(
        (item) =>
          selectedItem?.status?.includes(item.status) && item.effectiveDate && !toBankTz(item.effectiveDate).isAfterNow
      )
    }
    return items.filter((item) => selectedItem?.status?.includes(item.status))
  }, [items, selectedFilter])

  return {
    selectedFilter,
    setSelectedFilter,
    filteredItems,
    statusFilterOptions,
  }
}

export default useProductFilter
