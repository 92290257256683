/* eslint-disable no-nested-ternary */

import React from 'react'
import ViewPackage from './ViewPackage'
import PackageHeader from './PackageHeader'
import PackageStepperCard from './PackageStepperCard'
import { AvailableProducts, PackagesWithProducts } from 'store/packages/types'
import { TemplateReview } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'

interface OwnProps {
  selectedPackage: PackagesWithProducts
  availableProducts: AvailableProducts[] | undefined
}

const Package = ({ selectedPackage, availableProducts }: OwnProps): React.ReactElement => (
  <ContentContainer data-test-id="package-page" style={{ marginTop: 14 }}>
    <TemplateReview
      header={
        <>
          <PackageHeader selectedPackage={selectedPackage} />
          <PackageStepperCard selectedPackage={selectedPackage} />
        </>
      }
      main={<ViewPackage selectedPackage={selectedPackage} availableProducts={availableProducts} />}
    />
  </ContentContainer>
)

export default Package
