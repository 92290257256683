import { call, put, delay } from 'redux-saga/effects'
import i18next from 'i18next'
import { ExtendedProduct, FeatureName } from '../types'
import { navigate } from 'store/router/actions'
import ModalActions from 'store/modal/actions'
import ProductsActions from 'store/products/actions'
import { UI_DELAY_TO_SHOW_LOADING } from 'store/utils/constants'
import { ModalPendingAction, ModalType } from 'store/modal/types'
import { showPendingActionModalSaga } from 'store/modal/sagas'
import { MultiPartyApi } from 'api'

export function* setMultiPartySaga(action: ReturnType<typeof ProductsActions.setMultiParty>) {
  try {
    const t = i18next.t.bind(i18next)
    const {
      payload: { productKey, version, ...multiParty },
    } = action
    yield put(ModalActions.showFeatureProgressModal())
    yield call(MultiPartyApi.setMultiParty, productKey, version, multiParty)
    yield delay(UI_DELAY_TO_SHOW_LOADING)
    yield put(navigate(`/products/${productKey}`))
    yield put(ModalActions.closeModal())
    yield put(
      ModalActions.showFeatureSuccessModal({
        featureName: t(FeatureName.MULTI_PARTY),
      } as ExtendedProduct)
    )
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}

export function* deleteMultiPartySaga(action: ReturnType<typeof ProductsActions.deleteMultiParty>) {
  try {
    const {
      payload: { productKey, version },
    } = action
    const isConfirmed: boolean = yield call(showPendingActionModalSaga, {
      modalType: ModalType.PENDING_ACTION,
      title: `You're deleting ${FeatureName.MULTI_PARTY}`,
      message: "Are you sure? This can't be undone.",
      pendingAction: ModalPendingAction.REMOVE,
    })

    if (isConfirmed) {
      yield put(ModalActions.showFeatureProgressModal())

      yield call(MultiPartyApi.deleteMultiParty, productKey, version)

      yield put(ProductsActions.getProductDetails({ productKey }))

      yield delay(UI_DELAY_TO_SHOW_LOADING)
      yield put(ModalActions.closeModal())

      yield put(
        ModalActions.showModal({
          modalType: ModalType.SUCCESS,
          title: `${FeatureName.MULTI_PARTY} successfully deleted`,
        })
      )
    }
  } catch (e: any) {
    yield put(ModalActions.closeModal())
    yield put(ModalActions.showFeatureFailureModal(e))
  }
}
