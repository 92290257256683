import React from 'react'
import FormControl, { FormControlProps as MUIFormControlProps } from '@material-ui/core/FormControl'
import FieldLabel, { FieldLabelCustomProps } from 'dls/atoms/FieldLabel'
import FormHelperText, { FormHelperTextCustomProps } from 'dls/atoms/FormHelperText'
import { LabelMode } from 'dls/shared/types'

export interface FormErrorCustomProps {
  errorText?: string
  ariaProps?: Record<string, string | boolean>
}

export interface GeneralFieldWrapperProps {
  InputLabelProps?: FieldLabelCustomProps
  FormHelperTextProps?: FormHelperTextCustomProps
  FormErrorTextProps?: FormErrorCustomProps
  FormControlProps?: MUIFormControlProps
  label?: React.ReactNode
  helperText?: React.ReactNode
  errorText?: React.ReactNode
  children?: React.ReactNode
  required?: boolean
  disabled?: boolean
  error?: boolean
  name: string
  labelMode?: LabelMode
  control?: React.ReactElement<any, any> | undefined
  fullWidth?: boolean
  className?: string
}

const GeneralFieldWrapper = ({
  InputLabelProps,
  FormControlProps,
  FormHelperTextProps,
  FormErrorTextProps = {},
  label,
  children,
  helperText,
  name,
  labelMode = LabelMode.INPUT_FIELD,
  errorText,
  control,
  fullWidth,
  ...props
}: GeneralFieldWrapperProps) => (
  <FormControl
    {...props}
    {...FormControlProps}
    {...{ component: labelMode === LabelMode.TOP_LABEL && !!label ? 'fieldset' : undefined }}
    fullWidth={fullWidth}
    data-dls="molecule-form-control"
  >
    {(!!label || labelMode === LabelMode.RIGHT_LABEL) && (
      <FieldLabel
        {...props}
        {...InputLabelProps}
        labelMode={labelMode}
        htmlFor={name}
        control={labelMode === LabelMode.RIGHT_LABEL ? control : undefined}
        {...(labelMode === LabelMode.INPUT_FIELD
          ? {}
          : { component: labelMode === LabelMode.TOP_LABEL && !!label ? 'legend' : undefined })}
      >
        {label}
      </FieldLabel>
    )}
    {children}
    {!!helperText && <FormHelperText {...FormHelperTextProps} helperText={helperText} id={`${name}-helper-text`} />}
    {!!errorText && (
      <FormHelperText
        {...FormErrorTextProps}
        error={props.error}
        errorText={errorText}
        {...FormErrorTextProps?.ariaProps}
        id={`${name}-error-text`}
      />
    )}
  </FormControl>
)

export default GeneralFieldWrapper
