/* eslint-disable no-useless-concat */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TableHead, Box } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { kebabCase } from 'lodash'
import { useParams } from 'react-router-dom'
import WithholdingTaxSidePanel from '../../shared/WithholdingTaxRuleSidePanel'
import ViewProductsBody from './ViewProductsBody'
import { BMTable, StyledInfoGrid, ContentPaper } from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import useTaxRule from 'pages/ReferenceData/WithholdingTaxRuleCatalog/hooks/useTaxRule'
import { truncate } from 'utils/common.util'
import { Loader, TemplateFeatureForm, H2, H1, Body2 } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { InfoPanel } from 'dls'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export function TaxRuleProductsPage() {
  const { t } = useTranslation()
  const { ruleId } = useParams()
  const { getTaxRuleProducts, selectedTaxRule, selectedTaxRuleProducts, getTaxRule, productsLoading } = useTaxRule()
  const allRulesPageUrl = 'reference-data/withholding-tax'

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Reference data'), path: 'reference-data/' },
      { title: t('Rules for withholding tax'), path: allRulesPageUrl },
      { title: `${truncate(selectedTaxRule?.name ?? '', 60)}`, path: `${allRulesPageUrl}/${ruleId}` },
      {
        title: t('Products using rule'),
        path: '',
      },
    ],
    trigger: !!selectedTaxRule,
  })

  useEffect(() => {
    if (ruleId) {
      getTaxRule(ruleId)
      getTaxRuleProducts(ruleId)
    }
  }, [ruleId])

  const products = selectedTaxRuleProducts && ruleId ? selectedTaxRuleProducts[ruleId] : []

  if (productsLoading) return <Loader />

  return (
    <ContentContainer data-test-id={kebabCase(`products using this rule`)}>
      <TemplateFeatureForm
        header={<H1 gutterBottom>{t('Products using rule')}</H1>}
        main={
          <ContentPaper elevation={1}>
            <Box m={3}>
              {selectedTaxRule && <H2>{selectedTaxRule.name}</H2>}
              <StyledInfoGrid>
                <InfoPanel body={t("Rules are applied to products on the product's credit interest feature")} />
              </StyledInfoGrid>
              {products?.length > 0 && (
                <BMTable>
                  <TableHead>
                    <TableCell width="50%">
                      <Body2 style={{ fontWeight: 'bold' }}>{t('Product name')}</Body2>
                    </TableCell>
                    <TableCell width="50%">
                      <Body2 style={{ fontWeight: 'bold' }}>{t('Version, status and name')}</Body2>
                    </TableCell>
                  </TableHead>
                  <ViewProductsBody products={products} />
                </BMTable>
              )}
            </Box>
          </ContentPaper>
        }
        aside={<WithholdingTaxSidePanel />}
      />
    </ContentContainer>
  )
}

export default TaxRuleProductsPage
