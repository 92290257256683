/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { InfoOutlined } from '@material-ui/icons'
import { ConfirmModalDeprecated } from 'components'
import ProductsSelectors from 'store/products/selectors'
import ProductsActions from 'store/products/actions'
import { Product } from 'store/products/types'
import { RootState } from 'store'
import { Colleague } from 'store/user/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { useUserNameWithRole } from 'utils/common.util'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  product: Product
  isProductInDesign: boolean
}

const mapDispatchToProps = {
  sendProductToReview: ProductsActions.review,
}

const mapStateToProps = (state: RootState) => ({
  assignees: ProductsSelectors.selectColleagues(state),
  productReviewing: ProductsSelectors.selectReview(state),
  tenantConfig: selectConfig(state),
})

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & OwnProps

enum ProductApproveModalState {
  closed,
  warning,
}

const StyledInfoOutlined = styled(InfoOutlined)`
  color: ${(props: any) => props.theme.palette.success.main};
  font-size: 74px;
`

const ProductApproval = (props: Props) => {
  const { assignees, productReviewing, product } = props
  const { t } = useTranslation()
  const getUserNameWithRole = useUserNameWithRole()
  const isGovernanceEnabled = props.tenantConfig && props.tenantConfig.features.governance

  interface UserSelectData {
    [key: string]: { name: Colleague; label?: string } | string
  }

  const selectAssigneeData: UserSelectData = {}
  assignees.forEach((item) => {
    const userNameWithRole = getUserNameWithRole(item.profile)
    if (userNameWithRole) {
      selectAssigneeData[userNameWithRole] = { name: item }
    }
  })
  const { productKey } = product
  const [modalState, setModalState] = useState<ProductApproveModalState>(ProductApproveModalState.closed)
  const setOpen = useCallback(() => setModalState(ProductApproveModalState.warning), [setModalState])
  const setClosed = useCallback(() => setModalState(ProductApproveModalState.closed), [setModalState])

  return (
    <>
      {props.isProductInDesign && (
        <>
          <Button
            onClick={setOpen}
            variant="contained"
            color={ColorType.BUTTON}
            data-test-id="product-status-button"
            disabled={productReviewing}
            style={{ textTransform: 'uppercase' }}
          >
            {t('Complete Design')}
          </Button>
        </>
      )}
      <ConfirmModalDeprecated
        onCancelClick={setClosed}
        onConfirmClick={{
          action: () => {
            setClosed()
            props.sendProductToReview({
              productKey,
              effectiveDate: moment(),
              version: `${product.version}`,
              status: product.status,
            })
          },
          title: t('OK, continue'),
        }}
        open={modalState === ProductApproveModalState.warning}
        subtitle={t('If you need to make changes you should choose ‘Go back’')}
        title={t(
          `If you continue, you won’t be able to change the product ${
            isGovernanceEnabled ? 'unless it’s rejected by an approver' : 'until after it’s been published'
          }`
        )}
        image={{ src: <StyledInfoOutlined />, alt: t('Info Icon') }}
        imageStyle={{ marginBottom: '24px' }}
        cancelText="Go Back"
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductApproval)
