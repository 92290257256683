import React, { useState, CSSProperties } from 'react'
import { CopyIconButton, CopiedIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface Props {
  payload: string
  extraButtonProps?: object
  ariaProps?: Record<string, string | boolean>
  title?: string
  onClickTitle?: string
  tabIndex?: string
  style?: CSSProperties
}

export const CopyComponent = ({
  payload = 'Copied',
  title = '',
  onClickTitle = '',
  ariaProps = {},
  extraButtonProps = {},
  tabIndex,
  ...remainingProps
}: Props) => {
  const [copied, setCopied] = useState(false)

  const onCopy = () => {
    navigator.clipboard.writeText(payload)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const iconTitle = copied ? onClickTitle : title

  return copied ? (
    <CopiedIconButton
      data-dls="molecule-copiedIconButton"
      alt={iconTitle}
      title={iconTitle}
      onClick={onCopy}
      fontSize="small"
      tabIndex={tabIndex}
      {...remainingProps}
      {...ariaProps}
      {...extraButtonProps}
    />
  ) : (
    <CopyIconButton
      data-dls="molecule-copy-icon-button"
      alt={iconTitle}
      title={iconTitle}
      onClick={onCopy}
      fontSize="small"
      tabIndex={tabIndex}
      {...remainingProps}
      {...ariaProps}
      {...extraButtonProps}
    />
  )
}

export default CopyComponent
