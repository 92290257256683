import React, { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem } from '@material-ui/core'
import { StyledSelect } from '../../ReferenceData/WithholdingTaxRuleCatalog/styled'
import MultiSelectAutocomplete from 'components/form/MultiSelectAutocomplete/'
import {
  AutocompleteSelectFieldDataItem,
  AutoCompleteSelectFieldItem,
} from 'components/form/MultiSelectAutocomplete/types'

export interface LocalAutocompleteSelectFieldData {
  [key: string]: AutocompleteSelectFieldDataItem
}
interface Props {
  onChange: (e: ChangeEvent, value?: string) => void
  value: string | string[]
  operator: string
  typeOptions: LocalAutocompleteSelectFieldData
}

export default ({ onChange, operator, value: formValue, typeOptions }: Props) => {
  const { t } = useTranslation()
  const shouldShowMultiple = operator !== 'EQUAL_TO'
  const multiSelectTitle = shouldShowMultiple ? t('These') : t('This')
  const isValueEmpty =
    (Array.isArray(formValue) && formValue.length === 0) || formValue === undefined || formValue === ''
  let sanitisedValue: string[] | string | null
  if (isValueEmpty) {
    sanitisedValue = shouldShowMultiple ? [] : null
  } else {
    sanitisedValue = formValue
  }
  useEffect(() => {
    const doAllValuesExist =
      Array.isArray(sanitisedValue) && sanitisedValue.length > 0
        ? sanitisedValue.reduce((combined, value) => combined && Object.keys(typeOptions).includes(value), true)
        : true
    if (!doAllValuesExist && typeof sanitisedValue !== 'string')
      onChange({ target: { value: sanitisedValue?.filter((value) => typeOptions[value]) } } as any)
  }, [typeOptions])

  return shouldShowMultiple ? (
    <MultiSelectAutocomplete
      chipLabel={(item: AutoCompleteSelectFieldItem) => {
        if (item === undefined) {
          return ''
        }
        return typeof item === 'string' ? item : item.label
      }}
      data={typeOptions}
      disabled={operator === ''}
      hasFlag={false}
      label={multiSelectTitle}
      onChange={(e, value) => onChange({ target: { value } } as any)}
      value={sanitisedValue as any}
      inputProps={{ style: { minHeight: shouldShowMultiple ? 55 : 32 } }}
      reloadOptionsOnChange
      multiple
      required
    />
  ) : (
    <FormControl fullWidth>
      <InputLabel shrink id="select-value">
        {`${multiSelectTitle} *`}
      </InputLabel>
      <StyledSelect
        id="select-rule-value"
        value={sanitisedValue}
        onChange={onChange}
        hasPlaceholder={!sanitisedValue}
        displayEmpty
        fullWidth
        required
      >
        <MenuItem value={null as any} disabled>
          {t('Please select or start typing')}
        </MenuItem>
        {Object.keys(typeOptions)
          .sort((a, b) => (typeOptions[a].label > typeOptions[b].label ? 1 : -1))
          .map((key) => (
            <MenuItem value={key} key={key}>
              {typeOptions[key].label}
            </MenuItem>
          ))}
      </StyledSelect>
    </FormControl>
  )
}
