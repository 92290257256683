import { createSelector } from 'reselect'
import { RootState } from 'store'

const selectTasksState = (state: RootState) => state.tasks

const selectTasksForProduct = createSelector(selectTasksState, (state) => state.taksForProduct)

const selectTasksForAssignee = createSelector(selectTasksState, (state) => state.taksForAssignee)

const selectAssignee = createSelector(selectTasksState, (state) => state.assignee)
const selectAllTask = createSelector(selectTasksState, (state) => state.taskDefinitions)

const selectIsLoading = createSelector(selectTasksState, (state) => state.isLoading)
const selectUpdating = createSelector(selectTasksState, (state) => state.updating)
const selectIsAssigning = createSelector(selectTasksState, (state) => state.isAssigning)
const selectAssignTasksResult = createSelector(selectTasksState, (state) => state.assignTasksResult)
const selectTaskActionResult = createSelector(selectTasksState, (state) => state.taskActionResult)

export default {
  selectTasksForProduct,
  selectTasksForAssignee,
  selectIsLoading,
  selectAssignee,
  selectAllTask,
  selectUpdating,
  selectIsAssigning,
  selectAssignTasksResult,
  selectTaskActionResult,
}
