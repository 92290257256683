import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import AboutCardLimitsCTA from '../../shared/AboutCardLimitsCTA'
import CardTransactionLimitsPageContent from './components/CardTransactionLimitsPageContent'
import { BackButton, TemplateSubReview, H1, Body2, Loader, NoActivityHelpPanel } from 'components'
import ContentContainer from 'pages/Layout/ContentContainer'
import { Feature } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'

const ViewCardTransactionLimitsPage = () => {
  const { t } = useTranslation()
  const { productKey = '' } = useParams()
  const navigate = useNavigate()
  const [product, isLoading] = useSelectedProduct(productKey)

  useUpdateBreadcrumbs({ productKey, context: Feature.CARD_TRANSACTION_LIMITS })

  if (isLoading || !product) {
    return <Loader />
  }

  const onPressBack = () => {
    navigate(`/products/${productKey}`)
  }

  return (
    <ContentContainer>
      <TemplateSubReview
        header={
          <>
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <BackButton onPressBack={onPressBack} aria-controls="main" />
              </Grid>
              <Grid item>
                <div style={{ gridArea: 'infoArea', textAlign: 'right' }}>
                  <AboutCardLimitsCTA />
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <H1 gutterBottom>{t('Card transaction limits')}</H1>
              </Grid>
            </Grid>
            <Body2 variantMapping={{ body2: 'p' }} gutterBottom>
              {t(
                'Here you can view and manage card transaction limits. The limits you set here will apply to all cards (physical and virtual) which you’ve created in Bank Manager.'
              )}
            </Body2>
          </>
        }
        aside={<NoActivityHelpPanel />}
        main={
          <CardTransactionLimitsPageContent
            cardTransactionLimits={product.cardLimits?.cardLimits || []}
            currencyCode={product.currency.currencyCode}
            productKey={product.productKey}
          />
        }
      />
    </ContentContainer>
  )
}

export default ViewCardTransactionLimitsPage
