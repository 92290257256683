import styled from 'styled-components/macro'
import { Chip } from '@material-ui/core'

export interface SelectLabelProps {
  multiple: boolean
}

export const FlagContainer = styled.div`
  margin-left: 0;
  margin-right: 0;
  vertical-align: text-bottom;
  width: auto;
  height: auto;
`
export const ChipContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`
export const SelectLabel = styled.div<SelectLabelProps>`
  display: flex;
  padding: ${(props: any) => (props.multiple ? '0px' : '12px 22px')};
  align-items: center;
`

export const StyledChip = styled(Chip)`
  .MuiChip-deleteIcon {
    color: ${(props: any) => `${props.theme.palette.text.secondary}`};
  }
`
