import React from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AllStatusesImg } from 'assets'
import { Body2 } from 'components'

interface OwnProps<FilterItem> {
  isActive: boolean
  items: FilterItem[]
}

const AllStatuses = <FilterItem extends object>({ isActive, items }: OwnProps<FilterItem>) => {
  const { t } = useTranslation()
  return (
    <Box component="span" display="flex">
      <img style={{ marginRight: 8 }} src={AllStatusesImg} alt="icon all statuses" aria-hidden={!isActive} />
      <Body2 color={!isActive ? 'textPrimary' : undefined}>
        {t('All statuses')} ({items.length})
      </Body2>
    </Box>
  )
}
export default AllStatuses
