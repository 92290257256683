/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Field, FormikProps, getIn } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import IndependentRateForm from './IndependentRateForm'
import { selectedRateAndBankName } from 'pages/features/CreditInterest/utils/creditInterestTransformers'
import { LendingRateFormValues, ExtendedProduct, TypeOfIndexRate } from 'store/products/types'
import { BankInterestRate, SelectedRateAndBankName } from 'store/referenceData/types'
import SelectFieldComplex, { SelectFieldData } from 'components/form/SelectFieldComplex'
import { Yip } from 'utils/form.utils'
import { AccessibleLink, ContextualHelp } from 'components'
import { mapBankInterestRatesToLendingInterestFieldData } from 'pages/features/DebitInterest/utils/transformers'
import { Body2, H3, InfoPanel } from 'dls'

interface OwnProps {
  hasFeature?: boolean
  currencyOfProduct?: string | null
  bankInterestRates?: BankInterestRate[]
  selectTypeOfIndexRate: TypeOfIndexRate.INDEX_RATE_PLUS_STATIC_MARGIN
  product: ExtendedProduct
}

const TooltipStyle = styled.div`
  margin-top: 16px;
`

interface StaticMarginProps extends FormikProps<LendingRateFormValues>, OwnProps {}

const StaticMarginForm = (props: StaticMarginProps): React.ReactElement => {
  const { t } = useTranslation()
  const [selectDataRateSource, setSelectDataRateSource] = useState<SelectFieldData>({})
  const { values, bankInterestRates, selectTypeOfIndexRate, product } = props

  useEffect(() => {
    if (bankInterestRates && (bankInterestRates?.length || 0) > 0) {
      setSelectDataRateSource(mapBankInterestRatesToLendingInterestFieldData(bankInterestRates))
    }
  }, [bankInterestRates])

  const selectedbankInterestRateIndex = getIn(values, 'bankInterestRateIndex')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let selectedRateAndBankNameObject: SelectedRateAndBankName | null = null
  if (selectedbankInterestRateIndex && bankInterestRates) {
    selectedRateAndBankNameObject = selectedRateAndBankName(bankInterestRates, selectedbankInterestRateIndex)
  }

  return (
    <Grid item>
      <H3 style={{ marginBottom: 24 }}>{t('Index rate source')}</H3>
      {!isEmpty(bankInterestRates) ? (
        <>
          <Grid container item direction="row" spacing={3}>
            <Grid item xs={7}>
              <Field
                name="bankInterestRateIndex"
                label={t('Rate source')}
                required
                data={selectDataRateSource}
                selectProps={{ displayEmpty: true }}
                inputLabelProps={{ shrink: true }}
                component={SelectFieldComplex}
                placeholder={t('Please select')}
                validate={Yip(Yup.string().required('This field is required').nullable())}
                helperText={
                  <>
                    Add new index rate in&nbsp;
                    <AccessibleLink color="secondary" to="/reference-data/index-rates">
                      reference dataa
                    </AccessibleLink>
                  </>
                }
              />
            </Grid>
            <Grid item xs={1}>
              <TooltipStyle>
                <ContextualHelp
                  title={t(`You’ll only see the index rates which have been
                          created in Reference data. Also, you’ll only see index
                          rates which have an effective date of today or earlier`)}
                />
              </TooltipStyle>
            </Grid>
          </Grid>
          {selectedbankInterestRateIndex && selectedRateAndBankNameObject && (
            <Box mt={3}>
              <IndependentRateForm
                {...props}
                selectedRateAndBankNameObject={selectedRateAndBankNameObject}
                selectTypeOfIndexRate={selectTypeOfIndexRate}
                product={product}
              />
            </Box>
          )}
        </>
      ) : (
        <InfoPanel
          title={t('Please create an index rate with an effective date of today or earlier')}
          body={
            <>
              <Body2 variantMapping={{ body2: 'div' }}>
                {t('You can create an index rate in ')}
                <AccessibleLink color="secondary" to="/reference-data/index-rates">
                  {t('Reference data.')}
                </AccessibleLink>
              </Body2>
              <Body2>
                {t(
                  'As an example, you could create a source as the Bank of England and set the appropriate rate. Or you can create your own sources.'
                )}
              </Body2>
            </>
          }
        />
      )}
    </Grid>
  )
}

export default React.memo(StaticMarginForm)
