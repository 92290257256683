import styled, { css } from 'styled-components/macro'
import { Grid, Typography } from '@material-ui/core'
import { ImgStyled } from 'pages/Layout/Styled'
import { Button } from 'dls/atoms/Button'

const FullHeightDiv = css`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 100vh;
  }
  ${(props) => props.theme.breakpoints.down('md')} {
    height: 50vh;
  }
  ${(props) => props.theme.breakpoints.down('xs')} {
    height: 40vh;
  }
`
export const LoginImageView = styled(Grid)`
  background-color: ${(props) => props.theme.palette.blue.light};
  align-items: center;
  ${FullHeightDiv}
  img {
    width: 100%;
    height: 72vh;
    ${(props) => props.theme.breakpoints.down('md')} {
      height: 85%;
      padding-top: 55px;
    }
  }
`
export const LoginFormGrid = styled(Grid)`
  background-color: ${(props) => props.theme.palette.background.light};
  display: flex;
  justify-content: center;
  width: 100%;
`

export const LoginForm = styled(Grid)`
  flex-direction: column;
  padding: 48px;
  ${FullHeightDiv}
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 48px 0;
    justify-content: start;
    max-width: 475px;
  }
  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: 20px 32px;
    height: 60vh;
  }
`
export const SSOLoginButton = styled(Button)`
  border-radius: 20px;
  width: 260px;
  margin-right: 16px;
  padding: 9px 16px;
  color: ${(props) => props.theme.palette.background.dark};
`

export const LegacyLoginButton = styled(Button)`
  border-radius: 20px;
  width: 120px;
  padding: 9px 16px;
  color: ${(props) => props.theme.palette.background.dark};
`

export const Logo = styled(ImgStyled)`
  margin: 32px 0 0 48px;
  width: 102px;
  height: 71px;
  position: absolute;
`
export const Title = styled(Typography)`
  font-weight: 500;
  margin-bottom: 15px;
`

export const StyledErrorPage = styled.div`
  #server_unavailable {
    height: 100vh;
  }
  .MuiTypography-body1 {
    font-size: 14px;
  }
`
