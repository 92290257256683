import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { HelpModalType } from 'store/modal/types'
import { ProductSyndication } from 'store/products/typings/productSyndication'
import ContentContainer from 'pages/Layout/ContentContainer'
import { FilterMenu, ZeroStateLandingPage } from 'components'
import { NoProductSyndications } from 'assets'
import useModal from 'components/Modal/hooks/useModal'
import { TemplateForm, InfoIconButton, Button, Paper, Catalog } from 'dls'
import { CatalogCardContainer } from 'dls/organisms/CatalogCard/CatalogCard'
import ProductSyndicationCard from 'pages/ProductSyndication/ProductSyndicationCatalog/view/ProductSyndicationCard'
import useProductSyndicationFilter from 'pages/ProductSyndication/ProductSyndicationCatalog/hooks/useProductSyndicationFilter'

const ProductSyndicationCatalog = ({
  syndicationProducts,
  statusQueryParam,
}: {
  syndicationProducts?: ProductSyndication[]
  statusQueryParam: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showInfographicModal } = useModal()

  const { selectedFilter, setSelectedFilter, filteredItems, statusFilterOptions } = useProductSyndicationFilter(
    syndicationProducts ?? [],
    statusQueryParam
  )

  const createSyndicationButton = (
    <Button
      onClick={() => navigate('/product-syndications/create')}
      variant="contained"
      fullWidth
      data-test-id="create-product-syndication-button"
      aria-controls="main"
      css="height:36px;"
    >
      {t('Create syndication')}
    </Button>
  )

  const infoButton = (
    <InfoIconButton
      ariaProps={{
        'aria-label': `${t('About product syndication')}`,
        'aria-haspopup': 'dialog',
        'aria-controls': 'more-about-dialog',
      }}
      onClick={() => showInfographicModal(HelpModalType.PRODUCT_SYNDICATION)}
      fontSize="medium"
      css="margin-right:6px;"
    />
  )

  const onSearch = (item: ProductSyndication, text: string) =>
    item.name.toLowerCase().includes(text) || item.productKey.toLowerCase().includes(text)

  return (
    <ContentContainer>
      {syndicationProducts?.length ? (
        <Catalog
          entityName="Product syndications"
          list={filteredItems}
          icons={[
            <FilterMenu
              key="prod-filter"
              filter={selectedFilter}
              setFilter={setSelectedFilter}
              filterOptions={statusFilterOptions}
              showAllButton={false}
              ariaControls="products-list-section"
              ariaLabel={t('Filter syndication by status')}
            />,
            infoButton,
          ]}
          searchFilter={onSearch}
          searchButtonAriaLabel={t('Search product syndications')}
          cardRenderer={(list, idx, key) => (
            <CatalogCardContainer key={key}>
              <ProductSyndicationCard productSyndication={list[idx]} />
            </CatalogCardContainer>
          )}
          action={createSyndicationButton}
          placeHolder={t('Search by name or key')}
        />
      ) : (
        <TemplateForm
          title={t('Product syndication')}
          description={t(
            'Here you can create and manage product syndication – this allows you to share information that describes your products with third parties.'
          )}
          toolbarButtons={
            <Box display="flex" flexDirection="row" alignItems="center">
              {infoButton}
              {createSyndicationButton}
            </Box>
          }
          main={
            <Paper>
              <ZeroStateLandingPage
                image={NoProductSyndications}
                imageAlt={t('Illustration of meta data being added to a product')}
                title={t('No product syndications exists')}
                definition={
                  'This feature allows you to create product meta data so that you can syndicate your product to third parties. The meta data you create here will be contextually show along-side the product in Bank Manager. Choose "Create syndication" above to get started'
                }
                buttonLink={t('More about product syndication')}
                location="product-syndication"
              />
            </Paper>
          }
          CTAHidden
        />
      )}
    </ContentContainer>
  )
}

export default ProductSyndicationCatalog
