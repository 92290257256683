import { createAction } from '@reduxjs/toolkit'
import { StatementType } from '../products/types'
import { Template, TemplatesRequest } from './types'

const actions = {
  getTemplates: createAction<TemplatesRequest>('STATEMENT/GET_TEMPLATES'),
  getTemplatesSuccess: createAction<{ response: Template[]; templateType: StatementType }>(
    'STATEMENT/GET_TEMPLATES/SUCCESS'
  ),
  getTemplatesFail: createAction<StatementType>('STATEMENT/GET_TEMPLATES/FAIL'),
}

export default actions
