import moment from 'moment-timezone'
import {
  PaymentLimitDirection,
  PaymentLimit,
  PaymentLimitRoutingDestination,
  PaymentLimitRange,
  PaymentLimitAmountType,
  PaymentLimitChannel,
  PaymentLimitType,
  PaymentLimitsConfig,
  PaymentLimitsConfigFormValues,
  PaymentLimitFormValues,
  PaymentLimitURLValues,
  PaymentLimitsConfigYearType,
} from 'store/products/typings/paymentLimits'

export const getLimitValue = (
  direction: PaymentLimitDirection,
  channel: PaymentLimitChannel,
  routingDestinations: PaymentLimitRoutingDestination[],
  range: PaymentLimitRange,
  amountType: PaymentLimitAmountType
) => (paymentLimits: PaymentLimit[]) =>
  paymentLimits?.find(
    (limit) =>
      limit.direction === direction &&
      limit.channel === channel &&
      limit.range === range &&
      limit.amount.type === amountType &&
      limit.routingDestinations.some((dest) => routingDestinations.includes(dest))
  )

// To self

export const getOutBoundSingleToSelf = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.TRANSFER],
  PaymentLimitRange.TRANSACTION,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundCumulativeToSelfDay = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.TRANSFER],
  PaymentLimitRange.DAY,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundCumulativeToSelfMonth = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.TRANSFER],
  PaymentLimitRange.MONTH,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundCumulativeToSelfYear = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.TRANSFER],
  PaymentLimitRange.YEAR,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundMaxToSelfDay = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.TRANSFER],
  PaymentLimitRange.DAY,
  PaymentLimitAmountType.VOLUME
)

export const getOutBoundMaxToSelfMonth = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.TRANSFER],
  PaymentLimitRange.MONTH,
  PaymentLimitAmountType.VOLUME
)

export const getOutBoundMaxToSelfYear = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.TRANSFER],
  PaymentLimitRange.YEAR,
  PaymentLimitAmountType.VOLUME
)

// To party

export const getOutBoundSingleToParty = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US],
  PaymentLimitRange.TRANSACTION,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundCumulativeToPartyDay = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US],
  PaymentLimitRange.DAY,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundCumulativeToPartyMonth = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US],
  PaymentLimitRange.MONTH,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundCumulativeToPartyYear = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US],
  PaymentLimitRange.YEAR,
  PaymentLimitAmountType.MONETARY
)

export const getOutBoundMaxToPartyDay = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US],
  PaymentLimitRange.DAY,
  PaymentLimitAmountType.VOLUME
)

export const getOutBoundMaxToPartyMonth = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US],
  PaymentLimitRange.MONTH,
  PaymentLimitAmountType.VOLUME
)

export const getOutBoundMaxToPartyYear = getLimitValue(
  PaymentLimitDirection.DEBIT,
  PaymentLimitChannel.MOBILE,
  [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US],
  PaymentLimitRange.YEAR,
  PaymentLimitAmountType.VOLUME
)

// Inbound

export const getInBoundSingle = getLimitValue(
  PaymentLimitDirection.CREDIT,
  PaymentLimitChannel.NOT_APPLICABLE,
  [PaymentLimitRoutingDestination.NOT_APPLICABLE],
  PaymentLimitRange.TRANSACTION,
  PaymentLimitAmountType.MONETARY
)

export const getInBoundCumulativeDay = getLimitValue(
  PaymentLimitDirection.CREDIT,
  PaymentLimitChannel.NOT_APPLICABLE,
  [PaymentLimitRoutingDestination.NOT_APPLICABLE],
  PaymentLimitRange.DAY,
  PaymentLimitAmountType.MONETARY
)

export const getInBoundCumulativeMonth = getLimitValue(
  PaymentLimitDirection.CREDIT,
  PaymentLimitChannel.NOT_APPLICABLE,
  [PaymentLimitRoutingDestination.NOT_APPLICABLE],
  PaymentLimitRange.MONTH,
  PaymentLimitAmountType.MONETARY
)

export const getInBoundCumulativeYear = getLimitValue(
  PaymentLimitDirection.CREDIT,
  PaymentLimitChannel.NOT_APPLICABLE,
  [PaymentLimitRoutingDestination.NOT_APPLICABLE],
  PaymentLimitRange.YEAR,
  PaymentLimitAmountType.MONETARY
)

export const getInBoundMaxDay = getLimitValue(
  PaymentLimitDirection.CREDIT,
  PaymentLimitChannel.NOT_APPLICABLE,
  [PaymentLimitRoutingDestination.NOT_APPLICABLE],
  PaymentLimitRange.DAY,
  PaymentLimitAmountType.VOLUME
)

export const getInBoundMaxMonth = getLimitValue(
  PaymentLimitDirection.CREDIT,
  PaymentLimitChannel.NOT_APPLICABLE,
  [PaymentLimitRoutingDestination.NOT_APPLICABLE],
  PaymentLimitRange.MONTH,
  PaymentLimitAmountType.VOLUME
)

export const getInBoundMaxYear = getLimitValue(
  PaymentLimitDirection.CREDIT,
  PaymentLimitChannel.NOT_APPLICABLE,
  [PaymentLimitRoutingDestination.NOT_APPLICABLE],
  PaymentLimitRange.YEAR,
  PaymentLimitAmountType.VOLUME
)

export const defineLimitType = ({
  direction,
  channel,
  routingDestination,
  range,
  amountType,
}: {
  direction?: PaymentLimitDirection
  channel?: PaymentLimitChannel
  routingDestination?: PaymentLimitRoutingDestination
  range?: PaymentLimitRange
  amountType?: PaymentLimitAmountType
}) => {
  switch (direction) {
    case PaymentLimitDirection.CREDIT: // inbound
      switch (channel) {
        case PaymentLimitChannel.NOT_APPLICABLE: // always
          switch (routingDestination) {
            case PaymentLimitRoutingDestination.NOT_APPLICABLE: // always
              switch (range) {
                case PaymentLimitRange.TRANSACTION: // single
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY: // only case
                      return PaymentLimitType.INBOUND_SINGLE
                    default:
                      return null
                  }
                case PaymentLimitRange.DAY:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.INBOUND_CUMULATIVE_DAY
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.INBOUND_MAX_DAY
                    default:
                      return null
                  }
                case PaymentLimitRange.MONTH:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.INBOUND_CUMULATIVE_MONTH
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.INBOUND_MAX_MONTH
                    default:
                      return null
                  }
                case PaymentLimitRange.YEAR:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.INBOUND_CUMULATIVE_YEAR
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.INBOUND_MAX_YEAR
                    default:
                      return null
                  }
                default:
                  return null
              }
            default:
              return null
          }
        default:
          return null
      }
    case PaymentLimitDirection.DEBIT: // outbound
      switch (channel) {
        case PaymentLimitChannel.MOBILE: // always
          switch (true) {
            case routingDestination === PaymentLimitRoutingDestination.TRANSFER: // self
              switch (range) {
                case PaymentLimitRange.TRANSACTION: // single
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_SINGLE_SELF
                    default:
                      return null
                  }
                case PaymentLimitRange.DAY:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_CUMULATIVE_SELF_DAY
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.OUTBOUND_MAX_SELF_DAY
                    default:
                      return null
                  }
                case PaymentLimitRange.MONTH:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_CUMULATIVE_SELF_MONTH
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.OUTBOUND_MAX_SELF_MONTH
                    default:
                      return null
                  }
                case PaymentLimitRange.YEAR:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_CUMULATIVE_SELF_YEAR
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.OUTBOUND_MAX_SELF_YEAR
                    default:
                      return null
                  }
                default:
                  return null
              }
            case [PaymentLimitRoutingDestination.OUTBOUND_PAYMENT, PaymentLimitRoutingDestination.ON_US].some(
              (type) => type === routingDestination
            ): // party
              switch (range) {
                case PaymentLimitRange.TRANSACTION: // single
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_SINGLE_PARTY
                    default:
                      return null
                  }
                case PaymentLimitRange.DAY:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_CUMULATIVE_PARTY_DAY
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.OUTBOUND_MAX_PARTY_DAY
                    default:
                      return null
                  }
                case PaymentLimitRange.MONTH:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_CUMULATIVE_PARTY_MONTH
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.OUTBOUND_MAX_PARTY_MONTH
                    default:
                      return null
                  }
                case PaymentLimitRange.YEAR:
                  switch (amountType) {
                    case PaymentLimitAmountType.MONETARY:
                      return PaymentLimitType.OUTBOUND_CUMULATIVE_PARTY_YEAR
                    case PaymentLimitAmountType.VOLUME:
                      return PaymentLimitType.OUTBOUND_MAX_PARTY_YEAR
                    default:
                      return null
                  }
                default:
                  return null
              }
            default:
              return null
          }
        default:
          return null
      }
    default:
      return null
  }
}

export const convertPaymentLimitsConfigToFormValues = (config: PaymentLimitsConfig): PaymentLimitsConfigFormValues => {
  const regex = /--(?<month>[0-9]{2})-(?<day>[0-9]{2})/
  const res = regex.exec(config?.taxYearStart || '')

  if (!res?.groups) {
    return { yearType: config.yearType, startMonth: '', startDay: '' }
  }

  const { month, day } = res.groups

  return {
    yearType: config.yearType,
    startMonth: month && `MONTH${month}`,
    startDay: day && `DAY${parseInt(day, 10)}`,
  }
}

export const convertValuesToPaymentLimitsConfigPayload = (
  values: PaymentLimitsConfigFormValues
): PaymentLimitsConfig => {
  const { yearType, startMonth, startDay } = values

  return {
    yearType: yearType as PaymentLimitsConfigYearType,
    taxYearStart:
      yearType === PaymentLimitsConfigYearType.TAX
        ? `--${startMonth?.slice(-2)}-${startDay?.replace(/Y/g, '0').slice(-2)}`
        : undefined,
  }
}

export const convertPaymentLimitToFormValues = (limit?: PaymentLimit): PaymentLimitFormValues => {
  if (!limit) {
    return {}
  }

  return {
    limitRuleId: limit?.limitRuleId,
    minimum: limit?.amount?.minimum,
    maximum: limit?.amount?.maximum,
    enableSubscriptionOverride: limit?.enableSubscriptionOverride,
  }
}

export const convertValuesToPaymentLimitPayload = (
  values: PaymentLimitFormValues & PaymentLimitURLValues
): PaymentLimit => {
  const {
    minimum,
    maximum,
    enableSubscriptionOverride,
    direction,
    channel,
    routingDestinations,
    amountType,
    range,
    limitRuleId,
  } = values

  return {
    limitRuleId,
    direction,
    channel,
    routingDestinations,
    range,
    amount: {
      minimum: minimum && minimum >= 0 ? minimum : undefined,
      maximum: maximum || undefined,
      type: amountType,
    },
    enableSubscriptionOverride: !!enableSubscriptionOverride,
  }
}

export const formatConfigTaxYear = (taxYearStart: string): string => {
  const date = moment(taxYearStart, '[--]MM[-]DD')
  return date.format('DD MMMM')
}
