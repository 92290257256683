import React, { useEffect, useState } from 'react'
import { Card, Grid, Table, TablePagination, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { get, isEqual, orderBy, sortBy } from 'lodash'
import styled from 'styled-components/macro'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import {
  TableCommonCellContentType,
  TableCommonCellType,
  TableCommonDataType,
  TableCommonFieldOwnProps,
  TableCommonFormValue,
  TableParam,
} from './types'
import { SearchField } from 'dls/molecules/SearchField'
import { useTableDispatch } from 'context/TableContext'
import { Order } from 'store/packages/types'

const UtilRowGrid = styled(Grid)`
  margin-bottom: 24px;
  width: 100%;
`

export default function RowTable({
  data,
  headRows,
  isSelectableRow,
  isDisabled,
  getSearchString,
  getKeyString,
  value,
  title,
  updateForm,
  defaultRowsPerPage,
  noEmptyRows = false,
  selectableCellProps,
  hideHeader = false,
  hidePagination = false,
  getExpandedContent,
  disableInsideSortPagination = false,
  fullCellSelectable,
  isThisRowSelectable,
  mode,
  NoRowImage,
  noRowImageAlt,
  noRowTitle,
  noRowDescription,
  cardElevation = 1,
  rowSelectableComponent,
  maxSelectableItems,
  disableSelectAll,
  searchPlaceholderText,
  searchText,
}: TableCommonFieldOwnProps) {
  const [order, setOrder] = useState<Order>('asc')
  const [orderby, setOrderby] = useState<any>()
  const [page, setPage] = useState(0)
  const [rowsOrig, setOrigRows] = useState<TableCommonCellType[]>([])
  const [selectedKeys, setSelectedKeys] = useState<TableCommonFormValue[]>(value && value !== undefined ? value : [])
  const [rows, setRows] = useState<TableCommonCellType[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 5)
  const [filterRowName, setFilterRowName] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const isSelected = (key: string) => selectedKeys.indexOf(key as TableCommonFormValue) !== -1
  function notEmpty<TValue>(nonEmptyItem: TValue | null | undefined): nonEmptyItem is TValue {
    return nonEmptyItem !== null && nonEmptyItem !== undefined
  }
  const dispatch = mode === TableParam.OPTIMIZE ? useTableDispatch() : false

  const handleSelectAllClick = (_isSelected: boolean) => {
    let _data: TableCommonFormValue[] = _isSelected
      ? rows
          .map((item) =>
            // eslint-disable-next-line no-nested-ternary
            !isThisRowSelectable
              ? (item._key as TableCommonFormValue)
              : isThisRowSelectable(item)
              ? (item._key as TableCommonFormValue)
              : null
          )
          .filter(notEmpty)
      : []

    if (maxSelectableItems && _data.length) {
      _data = _data.slice(0, maxSelectableItems)
    }

    setSelectedKeys(_data)
    if (updateForm) {
      updateForm(_data)
    }

    if (_isSelected && dispatch) {
      dispatch({
        type: 'REMOVEALL',
      })
    }
    setSelectAll(_isSelected)
  }

  const handleSelectClick = (_key: string) => {
    if (selectedKeys.includes(_key)) {
      const _data = selectedKeys.filter((k) => k !== _key)
      setSelectedKeys(_data)
      if (dispatch) {
        dispatch({
          type: 'REMOVE',
          id: _key,
        })
      }
      if (updateForm) {
        updateForm(_data)
      }
    } else {
      const _data = [...selectedKeys, _key]
      setSelectedKeys(_data)
      if (updateForm) {
        updateForm(_data)
      }
    }
  }

  const filterOrderRows = (_rows: TableCommonCellType[], search: string) => {
    const textToFind = search.toLowerCase()
    const filteredRows =
      search === '' ? _rows : _rows.filter((item) => item._searchString.toLowerCase().indexOf(textToFind) > -1)
    setFilterRowName(search)
    setPage(0)
    setRows(filteredRows)
  }
  useEffect(() => {
    if (value && !isEqual(value, selectedKeys)) {
      setSelectedKeys(value)
    }
  }, [selectedKeys, value])

  useEffect(() => {
    const _origRows = data.map((item: TableCommonDataType) => {
      const row: TableCommonCellType = { ...item, _searchString: '', _orderCell: {} }
      row._searchString = getSearchString(item)
      row._key = getKeyString(item)
      row._orderString = getKeyString(item)
      headRows.forEach((headColumn) => {
        row[headColumn.id] = get(item, headColumn.id) as TableCommonCellContentType
        row._orderCell[headColumn.id] = headColumn.getCellOrderString
          ? headColumn.getCellOrderString(item)
          : get(item, headColumn.id)
      })
      return row
    })
    setOrigRows(_origRows)

    const filteredSortedRows = disableInsideSortPagination
      ? _origRows
      : sortBy(orderBy(_origRows, [`_orderCell.${orderby}`], [order]), (item: TableCommonCellType) =>
          item._key && selectedKeys.includes(item._key) ? 0 : 1
        ) || []

    filterOrderRows(filteredSortedRows, filterRowName || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, headRows, getKeyString, getSearchString, orderby, order, setFilterRowName, setPage, setRows])

  const onRequestSort = (newOrderby: string) => {
    if (orderby === newOrderby) {
      setOrder(order === 'desc' ? 'asc' : 'desc')
    }
    setOrderby(newOrderby)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const numSelectableRowsCalculator = (selectAllFlag: boolean) => {
    const totalSelectable =
      isThisRowSelectable || selectAllFlag
        ? rows.filter((i) => isThisRowSelectable && isThisRowSelectable(i)).length
        : rows.length // this makes no sense

    if (maxSelectableItems || selectAllFlag) {
      return totalSelectable < (maxSelectableItems || 0) ? totalSelectable : maxSelectableItems
    }

    return totalSelectable
  }

  const emptyRows = noEmptyRows ? 0 : rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
  const { t } = useTranslation()
  return (
    <Card style={{ marginBottom: -4 }} elevation={cardElevation}>
      <Grid container direction="row" alignItems="flex-end" style={{ overflowX: 'auto' }}>
        {!hideHeader && (
          <>
            <UtilRowGrid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                <Grid
                  item
                  css={`
                    width: 50%;
                  `}
                >
                  <Typography variant="body2" component="div">
                    {title ||
                      t('Showing {{start}} - {{end}} of {{limit}} rows', {
                        start: rows.length ? 1 : 0,
                        end: rowsPerPage > rows.length ? rows.length : rowsPerPage,
                        limit: rows.length,
                      })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  css={`
                    width: 50%;
                  `}
                >
                  <SearchField
                    id="filter-table-item-name"
                    placeHolder={searchPlaceholderText || t('Search by name')}
                    search={(val: string) => filterOrderRows(rowsOrig, val)}
                    value={filterRowName}
                    searchFieldAriaLabel="test"
                    searchFieldAriaControls="test"
                    searchButtonAriaLabel="test"
                    css={{ marginBottom: 7 }}
                  />
                </Grid>
              </Grid>
            </UtilRowGrid>
          </>
        )}
        <Table size="medium" id="table-list-section" role="region" aria-live="polite" aria-atomic="true">
          <TableHeader
            orderAscDesc={order}
            orderByKey={orderby}
            numSelecableRows={numSelectableRowsCalculator(selectAll) || 0}
            numSelected={selectedKeys.length}
            onRequestSort={onRequestSort}
            headRows={headRows}
            onClickSelectAll={handleSelectAllClick}
            isSelectableRow={isSelectableRow}
            fullCellSelectable={fullCellSelectable}
            disableSelectAll={disableSelectAll}
          />
          <TableBody
            rows={rows}
            orderAscDesc={order}
            orderByKey={orderby}
            page={page}
            rowsPerPage={rowsPerPage}
            headRows={headRows}
            selectAll={selectAll}
            mode={mode}
            rowCount={rows.length}
            emptyRows={emptyRows}
            isSelectableRow={isSelectableRow}
            isDisabled={isDisabled}
            isSelected={isSelected}
            onClickSelectRow={handleSelectClick}
            selectableCellProps={selectableCellProps}
            getExpandedContent={getExpandedContent}
            disableInsideSortPagination={disableInsideSortPagination}
            fullCellSelectable={fullCellSelectable}
            isThisRowSelectable={isThisRowSelectable}
            NoRowImage={NoRowImage}
            noRowImageAlt={noRowImageAlt}
            noRowTitle={noRowTitle}
            searchText={searchText}
            noRowDescription={noRowDescription}
            value={value}
            rowSelectableComponent={rowSelectableComponent}
          />
        </Table>
      </Grid>
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
            'aria-controls': 'table-list-section',
            role: 'button',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
            'aria-controls': 'table-list-section',
            role: 'button',
          }}
          SelectProps={{
            'aria-label': 'rows per page',
            'aria-controls': 'table-list-section',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Card>
  )
}
