import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MainGrid, SearchCTAGrid, SearchIconsGrid, StyledGrid } from './Style'
import { SearchField } from 'dls/molecules/SearchField'
import { H1, Subtitle1 } from 'dls/atoms/Typography'

interface OwnProps {
  title?: React.ReactNode
  info: React.ReactNode
  actions?: React.ReactNode
  icons?: React.ReactNode[]
  value: string
  isSearch?: boolean
  search(e: string): void
  searchFieldAriaLabel: string
  searchFieldAriaControls: string
  placeHolder?: string
  expandedButton?: React.ReactNode
  searchButtonAriaLabel?: string
}

const CatalogHeader = ({
  info,
  search,
  actions,
  value,
  icons,
  isSearch,
  searchFieldAriaLabel,
  searchFieldAriaControls,
  title,
  placeHolder,
  searchButtonAriaLabel,
  expandedButton,
}: OwnProps) => {
  const { t } = useTranslation()

  return (
    <div data-dls="organism-CatalogHeader">
      <header>{typeof title === 'string' ? <H1>{title}</H1> : { title }}</header>
      <MainGrid container spacing={0} role="toolbar">
        <Grid item>
          {typeof info === 'string' ? <Subtitle1 variantMapping={{ subtitle1: 'div' }}>{info}</Subtitle1> : info}
        </Grid>
        <Grid item>
          <SearchCTAGrid container spacing={2}>
            {(isSearch || (icons && icons.length > 0) || actions) && (
              <Grid item>
                <SearchIconsGrid container spacing={2}>
                  {isSearch ? (
                    <Grid item xs>
                      <StyledGrid>
                        <SearchField
                          id="filter-name"
                          search={search}
                          placeHolder={t(placeHolder || 'Search')}
                          value={value}
                          searchFieldAriaLabel={searchFieldAriaLabel}
                          searchButtonAriaLabel={searchButtonAriaLabel}
                          searchFieldAriaControls={searchFieldAriaControls}
                          css={{ marginBottom: 7 }}
                        />
                      </StyledGrid>
                    </Grid>
                  ) : null}
                  {expandedButton && <Grid item>{expandedButton}</Grid>}
                  {icons
                    ? icons.map((icon, key) => (
                        <Grid item key={key}>
                          {icon}
                        </Grid>
                      ))
                    : null}
                </SearchIconsGrid>
              </Grid>
            )}
            {actions && <Grid item>{actions}</Grid>}
          </SearchCTAGrid>
        </Grid>
      </MainGrid>
    </div>
  )
}

export default CatalogHeader
