import { createAction } from '@reduxjs/toolkit'
import {
  Task,
  RejectTask,
  ApproveTask,
  AssigneeKey,
  ProcessExecutionKey,
  TaskDefinition,
  TaskDefinitionFormValue,
  AssignTaskPayload,
} from './types'
import { ProcessKey } from 'store/governance/types'
import { UserKey, Colleague } from 'store/user/types'

const actions = {
  reject: createAction<RejectTask>('TASK/REJECT'),
  rejectSuccess: createAction('TASK/REJECT/SUCCESS'),
  rejectFail: createAction<Error>('TASK/REJECT/FAIL'),
  approve: createAction<ApproveTask>('TASK/APPROVE'),
  approveSuccess: createAction('TASK/APPROVE/SUCCESS'),
  approveFail: createAction<Error>('TASK/APPROVE/FAIL'),

  assignTask: createAction<AssignTaskPayload>('TASKS/ASSIGN'),
  assignTaskSuccess: createAction('TASKS/ASSIGN/SUCCESS'),
  assignTaskFail: createAction<Error>('TASKS/ASSIGN/FAIL'),

  assignTasks: createAction<AssignTaskPayload[]>('TASKS/ASSIGNS'),
  assignTasksSuccesss: createAction('TASKS/ASSIGNS/SUCCESS'),
  assignTasksFails: createAction<Error>('TASKS/ASSIGNS/FAIL'),
  assignTasksResultClear: createAction('TASKS/ASSIGNS/RESULTCLEAR'),

  taskActionResultClear: createAction('TASKS/ACTION/RESULTCLEAR'),
  tasksExecutionsForAssignee: createAction<AssigneeKey>('TASKS/ASSIGNEE/FETCH'),
  tasksExecutionsForAssigneeSuccess: createAction<Task[]>('TASKS/ASSIGNEE/FETCH/SUCCESS'),
  tasksExecutionsForAssigneeFail: createAction<Error>('TASKS/ASSIGNEE/FAIL'),

  tasksExecutionsForProduct: createAction<ProcessExecutionKey>('PRODUCT/TASK/FETCH'),
  tasksExecutionsForProductSuccess: createAction<Task[]>('PRODUCT/TASK/FETCH/SUCCESS'),
  tasksExecutionsForProductFail: createAction<Error>('PRODUCT/TASK/FETCH/FAIL'),

  getAssigneeForProductTasks: createAction<UserKey>('TASK/GET/ASSIGNEE'),
  getAssigneeForProductTaskSuccess: createAction<Colleague>('TASK/GET/ASSIGNEES/SUCCESS'),
  getAssigneeForProductTaskFail: createAction<Error>('TASK/GET/ASSIGNEE/FAIL'),

  getTasksDefinition: createAction<ProcessKey>('TASKDEFINITIONS/GET/ASSIGNEE'),
  getTasksDefinitionSuccess: createAction<TaskDefinition[]>('TASKDEFINITIONS/GET/ASSIGNEES/SUCCESS'),
  getTasksDefinitionFail: createAction<Error>('TASKDEFINITIONS/GET/ASSIGNEE/FAIL'),

  createTasksDefinition: createAction<TaskDefinitionFormValue & { processKey: ProcessKey }>(
    'TASKDEFINITIONS/CREATE/ASSIGNEE'
  ),
  createTasksDefinitionSuccess: createAction<TaskDefinitionFormValue & { processKey: ProcessKey }>(
    'TASKDEFINITIONS/CREATE/ASSIGNEES/SUCCESS'
  ),
  createTasksDefinitionFail: createAction<Error>('TASKDEFINITIONS/CREATE/ASSIGNEE/FAIL'),

  updateTasksDefinition: createAction<TaskDefinitionFormValue & { processKey: ProcessKey }>(
    'TASKDEFINITIONS/UPDATE/ASSIGNEE'
  ),
  updateTasksDefinitionSuccess: createAction<TaskDefinitionFormValue & { processKey: ProcessKey }>(
    'TASKDEFINITIONS/UPDATE/ASSIGNEES/SUCCESS'
  ),
  updateTasksDefinitionFail: createAction<Error>('TASKDEFINITIONS/UPDATE/ASSIGNEE/FAIL'),

  clearTasksForProduct: createAction('TASK/CLEAR/ASSIGNEE/FAIL'),
}

export default actions
