import React from 'react'
import styled from 'styled-components/macro'
import { Link as MUILink, LinkProps } from '@material-ui/core'

const StyledMUILink = styled(MUILink)`
  color: ${(props: any) => `${props.theme.palette.secondary.main}`};
`

interface LinkPropsExtended<C> {
  component?: C
}

export const Link = React.forwardRef(
  <C extends React.ElementType>(props: LinkProps<C, LinkPropsExtended<C>>, ref: React.Ref<HTMLAnchorElement>) => (
    <StyledMUILink ref={ref} data-dls="atom-link" {...props} />
  )
)

export default Link
