import React from 'react'
import Sticky from 'react-sticky-el'
import { Card, CardActions, Grid, Slide, Typography, useTheme } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { Field, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MAX_SELECTABLE_TRANSACTIONS } from '../utils'
import { CardPartially, FormBottom } from '../Styles'
import SelectedTransactionCount from './SelectedTransactionCount'
import ReachedMaxSelectedInfo from './ReachedMaxSelectedInfo'
import OverMaxSelectedInfo from './OverMaxSelectedInfo'
import { PLACCOUNTNAME, NOSTRO_WASH_ACCOUNTNAME } from 'store/utils/constants'
import { SelectFieldComplex, SubmitButton } from 'components'
import { Yip } from 'utils/form.utils'
import { SelectFieldData } from 'components/form/SelectFieldComplex'
import {
  AccountState,
  SubscriptionKey,
  SuspenseAccounts,
  SuspenseTransactionItem,
  SuspenseTransactionsForm,
  TransactionStatus,
} from 'store/accounts/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { useTableState } from 'context/TableContext'
import { ColorType } from 'dls/shared/types'

interface Props {
  accounts?: SuspenseAccounts
  transactions?: SuspenseTransactionItem[]
  values?: SuspenseTransactionsForm
  subscriptionKey: SubscriptionKey
  onTransferClick: (values: SuspenseTransactionsForm) => void
}

const TransactionsStickyBar = ({ subscriptionKey, accounts, transactions = [], values, onTransferClick }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const transferTable = useTableState()
  const tenantConfig = useSelector(selectConfig)

  const newValues = { ...values, selectedTransactions: [...(values?.selectedTransactions || []), ...transferTable] }

  const hideSuspenseAccount =
    transactions &&
    transactions
      .filter(
        (i: SuspenseTransactionItem) =>
          newValues && newValues.selectedTransactions && newValues.selectedTransactions.includes(i.transactionId)
      )
      .filter((i) => [TransactionStatus.PARTIALLY_CLEARED].includes(i.transactionStatus as TransactionStatus)).length >
      0

  const showWriteOff = tenantConfig && tenantConfig.features.suspense_account_write_off
  const showNostroWash = tenantConfig && tenantConfig.features.suspense_account_nostro_wash
  const selectDestinationSource: SelectFieldData = {}
  if (showWriteOff || showNostroWash) {
    selectDestinationSource.WriteOff = { name: '', header: true, label: 'GL NOMINAL' }
    if (showWriteOff) {
      selectDestinationSource[PLACCOUNTNAME] = {
        name: PLACCOUNTNAME,
      }
    }
    if (showNostroWash) {
      selectDestinationSource[NOSTRO_WASH_ACCOUNTNAME] = {
        name: NOSTRO_WASH_ACCOUNTNAME,
      }
    }
  }
  // eslint-disable-next-line array-callback-return
  if (accounts && accounts.subscriptions && transactions) {
    if (!hideSuspenseAccount) {
      selectDestinationSource.SuspenseAccounts = { name: '', header: true, label: 'SUSPENSE ACCOUNTS' }
      accounts.subscriptions.forEach((item) => {
        if (item.state === AccountState.ACTIVE && item.subscriptionKey !== subscriptionKey) {
          selectDestinationSource[`${item.name}`] = {
            name: `${item.subscriptionKey}`,
          }
        }
      })
    }
  }

  return hideSuspenseAccount ||
    (newValues &&
      accounts &&
      Object.keys(selectDestinationSource) &&
      Object.keys(selectDestinationSource).length > 0) ? (
    <FormBottom>
      <Sticky mode="bottom" positionRecheckInterval={500} holderCmp="div">
        <Slide
          direction="up"
          in={newValues.selectedTransactions && newValues.selectedTransactions.length > 0}
          mountOnEnter
          unmountOnExit
        >
          <Card
            elevation={10}
            css={
              hideSuspenseAccount
                ? `
                        border-radius: 4px;
                        box-shadow: 0 2px 2px 0 ${theme.palette.text.hint}, 0 -14px 26px 0 ${theme.palette.divider};
                        border-style: solid;
                        border-width: 0.5px;
                        border-image-source: linear-gradient(
                          to bottom,
                          ${theme.palette.common.black},
                          ${theme.palette.common.black} 80%,
                          ${theme.palette.action.hover} 95%,
                          ${theme.palette.action.hover}
                        );
                        border-image-slice: 1;
                        background-image: linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.paper}),
                          linear-gradient(
                            to bottom,
                            ${theme.palette.common.black},
                            ${theme.palette.common.black} 80%,
                            ${theme.palette.action.hover} 95%,
                            ${theme.palette.action.hover}
                          );
                        background-origin: border-box;
                        background-clip: content-box, border-box;
                      `
                : ''
            }
          >
            {hideSuspenseAccount && (
              <CardPartially>
                <InfoOutlined style={{ verticalAlign: 'middle', marginTop: -4, marginRight: 16 }} color="primary" />
                <Typography variant="subtitle2" style={{ display: 'contents' }}>
                  {t(
                    `You’ve selected one or more partially cleared transactions – these can only be cleared to a GL nominal`
                  )}
                </Typography>
              </CardPartially>
            )}
            {newValues.selectedTransactions.length === MAX_SELECTABLE_TRANSACTIONS && (
              <ReachedMaxSelectedInfo
                maxSelectableTransactions={MAX_SELECTABLE_TRANSACTIONS}
                colour={theme.palette.success.dark}
              />
            )}
            {newValues.selectedTransactions.length > MAX_SELECTABLE_TRANSACTIONS && (
              <OverMaxSelectedInfo
                maxSelectableTransactions={MAX_SELECTABLE_TRANSACTIONS}
                colour={theme.palette.error.dark}
              />
            )}
            <CardActions disableSpacing>
              <Form style={{ margin: '24px 12px', width: '100%' }}>
                <Grid container spacing={4} direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item md={3}>
                    <SelectedTransactionCount
                      transactionCount={newValues.selectedTransactions.length}
                      maxSelectableTransactions={MAX_SELECTABLE_TRANSACTIONS}
                      transactionText={t('Transactions selected')}
                    />
                  </Grid>
                  <Grid item>
                    {accounts && (
                      <Field
                        required
                        fullWidth
                        displayEmpty
                        data={selectDestinationSource}
                        label={t('Select destination account')}
                        component={SelectFieldComplex}
                        name="destinationAccount"
                        validate={Yip(Yup.string().required())}
                        selectProps={{ displayEmpty: true }}
                        inputLabelProps={{ shrink: true }}
                        style={{ width: 400 }}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <SubmitButton
                      variant="contained"
                      color={ColorType.BUTTON}
                      onClick={() => onTransferClick(newValues as any)}
                      disabled={
                        !newValues.destinationAccount ||
                        newValues.selectedTransactions.length > MAX_SELECTABLE_TRANSACTIONS
                      }
                    >
                      {t('Next: review', {
                        nsSeparator: '||',
                      })}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </Form>
            </CardActions>
          </Card>
        </Slide>
      </Sticky>
    </FormBottom>
  ) : null
}

export default TransactionsStickyBar
