import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Grid, Typography } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { NotAllowed } from 'assets'
import { ProductKey } from 'store/contents/types'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

const DivEmptyLanding = styled.div`
  height: 440px;
`
const MainZeroContainer = styled(Grid)`
  height: 100%;
`

const NotAllowedPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const productKey = (location?.state as any)?.productKey || null

  function MoveToProduct(key: ProductKey) {
    if (key) navigate(`/products/${key}`)
    else navigate(`/products`)
  }

  return (
    <DivEmptyLanding id="server_notallowed">
      <MainZeroContainer container direction="row" alignItems="center" justifyContent="center">
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <img src={NotAllowed} alt={t('Not allowed')} />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={0}>
              <Grid item>
                <Typography variant="h6">{t('Nice try!')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t('Sorry but the feature you’re trying to access isn’t supported ')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={ColorType.BUTTON}
              onClick={() => MoveToProduct(productKey)}
              aria-controls="main"
              css="text-transform: uppercase"
            >
              {t('Product')}
            </Button>
          </Grid>
        </Grid>
      </MainZeroContainer>
    </DivEmptyLanding>
  )
}

export default NotAllowedPage
