import React from 'react'
import AllStatuses from './AllStatuses'
import FilterMenuItem from './FilterMenuItem'
import { Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import ScheduledMenuItem from 'pages/Products/FilterMenuItems/ScheduledMenuItem'

const getProductStatusFilterOptions = (products: Product[]) => [
  {
    label: ({ isActive }: { isActive: boolean }) => <AllStatuses isActive={isActive} items={products} />,
    status: undefined,
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem items={products} itemStatus={ProductStatus.DESIGN} isActive={isActive} />
    ),
    status: [ProductStatus.DESIGN],
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem items={products} itemStatus={ProductStatus.REVIEW} isActive={isActive} />
    ),
    status: [ProductStatus.REVIEW],
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem items={products} itemStatus={ProductStatus.APPROVED} isActive={isActive} />
    ),
    status: [ProductStatus.APPROVED],
  },
  {
    label: () => <ScheduledMenuItem items={products} isActive />,
    status: [ProductStatus.LIVE],
    isScheduled: true,
  },
  {
    label: ({ isActive }: { isActive: boolean }) => <ScheduledMenuItem items={products} isActive={isActive} isLive />,
    status: [ProductStatus.LIVE],
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem items={products} itemStatus={ProductStatus.UPDATED} isActive={isActive} />
    ),
    status: [ProductStatus.UPDATED],
  },
  {
    label: ({ isActive }: { isActive: boolean }) => (
      <FilterMenuItem items={products} itemStatus={ProductStatus.CLOSED} isActive={isActive} />
    ),
    status: [ProductStatus.CLOSED],
  },
]

export default getProductStatusFilterOptions
