import { keyBy } from 'lodash'
import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.fetch.type]: (state: ProductsState) => ({
    ...state,
    dataLoading: true,
    productPermissions: { isCreditInterestEnabled: true },
  }),
  [actions.fetchSuccess.type]: (state: ProductsState, action: ReturnType<typeof actions.fetchSuccess>) => ({
    ...state,
    data: keyBy(action.payload, 'productKey'),
    dataLoading: false,
  }),
  [actions.fetchFail.type]: (state: ProductsState, action: ReturnType<typeof actions.fetchFail>) => ({
    ...state,
    dataLoading: false,
    error: action.payload,
  }),
  [actions.setProductPermissions.type]: (
    state: ProductsState,
    action: ReturnType<typeof actions.setProductPermissions>
  ) => ({ ...state, productPermissions: action.payload }),
}
export default reducer
