import styled from 'styled-components/macro'
import { Typography, CircularProgress } from '@material-ui/core'
import { Button } from 'dls/atoms/Button'

export const Title = styled(Typography)`
  margin-bottom: 24px;
`

export const Description = styled(Typography)`
  margin-bottom: 16px;
  white-space: pre;
`

export const GrindContainer = styled.div`
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
`

export const StyledCircularProgress = styled(CircularProgress)`
  margin-bottom: 24px;
`

export const StyledButton = styled(Button)`
  min-width: 110px;
  border-radius: 100px;
`
