/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Grid, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { LinearScale } from '@material-ui/icons'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { camelCase, orderBy, find, isArray, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { isProductInDesign } from '../../utils/productVersion.utils'
import ProductsActions from 'store/products/actions'
import ProductsSelectors from 'store/products/selectors'
import TasksSelectors from 'store/tasks/selectors'
import { featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'
import { StatusBadge } from 'components'
import { ExtendedProduct } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { FeatureFlag } from 'components/ControlComponents'
import { RootState } from 'store/types'
import { Colleague } from 'store/user/types'
import { selectSelectedUser } from 'store/user/selectors'
import UserActions from 'store/user/actions'
import { useUserNameWithRole } from 'utils/common.util'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { Accordion, Body1, Body2, H3, OAvatar } from 'dls'
import { AvatarSize, ColorType } from 'dls/shared/types'
import { getInitials, getColorFromInitials } from 'utils/ui.utils'
import { Button } from 'dls/atoms/Button'

const ImgDiv = styled.div`
  padding: 8px;
  padding-left: 2px;
  & .MuiSvgIcon-root {
    margin-top: 3px;
  }
  display: inline;
  vertical-align: middle;
  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      vertical-align: -webkit-baseline-middle;
    }
  }
`

const StyledBody2 = styled(Body2)`
  padding: 8px;
`

interface OwnProps {
  product: ExtendedProduct
}

const mapDispatchToProps = {
  getUserDetails: UserActions.getUserDetails,
  openAssigneeModal: ProductsActions.openAssigneeModal,
}

const mapStateToProps = (state: RootState) => ({
  assignees: ProductsSelectors.selectColleagues(state),
  taskloading: TasksSelectors.selectIsLoading(state),
  tasks: TasksSelectors.selectTasksForProduct(state),
  selectedUser: selectSelectedUser(state),
})

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & OwnProps

interface UserSelectData {
  [key: string]: { name: Colleague; label?: string } | string
}

const ProductApprovals = ({
  product,
  assignees,
  tasks,
  taskloading,
  getUserDetails,
  selectedUser,
  openAssigneeModal,
}: Props) => {
  const { t } = useTranslation()
  const getUserNameWithRole = useUserNameWithRole()
  const [openExpandable, setOpenExpandable] = useState(false)
  const { status } = product
  const userId = tasks.filter((taskOb) => taskOb.assigneeKey).map((taskOb) => taskOb.assigneeKey)
  const assignerKey = tasks && tasks[0] ? tasks[0].createdBy : ''

  useEffect(() => {
    if (assignerKey && assignerKey !== '') {
      getUserDetails(assignerKey)
    }
  }, [getUserDetails, assignerKey])

  const tasksData = orderBy(
    tasks.map((task) => ({ assignee: find(assignees, { userKey: task.assigneeKey }), task })).filter((task) => task),
    ['task.sequence', 'task.context'],
    ['asc', 'asc']
  )

  const selectAssigneeData: UserSelectData = {}
  assignees.forEach((item) => {
    const userNameWithRole = getUserNameWithRole(item.profile)
    if (userNameWithRole) {
      selectAssigneeData[userNameWithRole] = { name: item }
    }
  })

  if (!tasks || !isArray(tasks) || isEmpty(tasks)) {
    return null
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <H3 variantMapping={{ h3: 'h2' }} gutterBottom>
              {t('Product approvals')}
            </H3>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 12, paddingBottom: 12 }}>
        <Accordion
          title={<Body1>{t('Approval tasks and assignees')}</Body1>}
          headerIcon={<LinearScale />}
          actions={
            <div style={{ padding: '24px' }}>
              <FeatureFlag name="governance">
                {!isProductInDesign(status) && userId.length === 0 && !taskloading && (
                  <Button
                    onClick={() => openAssigneeModal()}
                    variant="outlined"
                    color={ColorType.BUTTON}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {t('Edit')}
                  </Button>
                )}

                {userId.length > 0 && status === ProductStatus.REVIEW && (
                  <Button
                    onClick={() => openAssigneeModal()}
                    variant="outlined"
                    color={ColorType.BUTTON}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {t('Edit')}
                  </Button>
                )}
              </FeatureFlag>
            </div>
          }
          externalExpanded={openExpandable}
          setExternalExpanded={() => setOpenExpandable(!openExpandable)}
        >
          <Grid container>
            <Grid item xs={12}>
              <StyledBody2 gutterBottom variantMapping={{ body2: 'div' }}>
                {t(
                  `Here are the tasks, assignees and the status of the approval. All statuses must show as approved before you can publish the product. `
                )}
                {selectedUser && (
                  <>
                    <span>{t('If you have any questions you can contact')}</span>
                    <Link component={RouterLink} color="secondary" to={`/user/${assignerKey}`}>
                      {` ${getUserNameWithRole(selectedUser.profile)}.`}
                    </Link>
                  </>
                )}
              </StyledBody2>
              <StyledBody2 gutterBottom>
                {t(
                  'Any comments relating to approved or rejected task can be viewed in the activity panel to the right of the screen.'
                )}
              </StyledBody2>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell css="padding-left: 8px;padding-right: 8px;">{t('Task')}</TableCell>
                      <TableCell css="padding-left: 8px;padding-right: 8px;" align="left">
                        {t('Assignee')}
                      </TableCell>
                      <TableCell css="padding-left: 8px;padding-right: 8px;" align="left">
                        {t('Status')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasksData &&
                      tasksData.map(({ task, assignee }) => (
                        <TableRow key={task.key}>
                          <TableCell css="padding-left: 8px;padding-right: 8px;" scope="row">
                            {task.context && task.context !== null && (
                              <ImgDiv>
                                {task.context === 'productCore'
                                  ? featureIconsCreator(featureSVGIcons[camelCase('coreProduct')])
                                  : featureIconsCreator(featureSVGIcons[camelCase(task.context)])}
                              </ImgDiv>
                            )}
                            {task.name}
                          </TableCell>
                          <TableCell
                            css="padding-left: 8px;padding-right: 8px;"
                            align="left"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {assignee && assignee.profile && (
                              <div style={{ display: 'inline-flex', marginLeft: 6, marginRight: 6 }}>
                                <OAvatar
                                  color={getColorFromInitials(
                                    `${assignee.profile.firstName} ${assignee.profile.lastName}`
                                  )}
                                  size={AvatarSize.NORMAL}
                                >
                                  {getInitials(`${assignee.profile.firstName} ${assignee.profile.lastName}`)}
                                </OAvatar>
                              </div>
                            )}
                            {assignee && assignee.profile && assignee.email && (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <Link component={RouterLink} color="secondary" to={`/user/${assignee.userKey}`}>
                                {getUserNameWithRole(assignee.profile)}
                              </Link>
                            )}
                            {assignee && assignee.profile && !assignee.email && getUserNameWithRole(assignee.profile)}
                          </TableCell>
                          <TableCell css="padding-left: 8px;padding-right: 8px;" align="left">
                            <StatusBadge
                              status={[TaskStatus.CLOSED].includes(task.status) ? ProductStatus.APPROVED : task.status}
                              task={task}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Accordion>
      </Grid>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductApprovals)
