import React, { ReactElement } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export interface RadioGroupFieldData {
  [key: string]: { name: string | number; label?: string; disabled?: boolean } | string
}

export interface RadioGroupFieldComplexProps {
  data: RadioGroupFieldData
  name: string
  onChange?: (value: any) => void
  required?: boolean
  children?: ReactElement
  value: string
}

const RadioGroupFieldSimple = React.forwardRef(
  ({ children, data, onChange, ...otherProps }: RadioGroupFieldComplexProps, ref: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation()
    return (
      <RadioGroup {...otherProps} ref={ref}>
        {Object.entries(data).map(([key, item]) => (
          <FormControlLabel
            key={key}
            value={typeof item === 'string' ? item : item.name}
            control={<Radio />}
            label={typeof item !== 'string' && item.label ? t(item.label) : t(key)}
            onClick={() => onChange && onChange(typeof item === 'string' ? item : item.name)}
            disabled={typeof item === 'object' ? item.disabled : false}
          />
        ))}
        {children}
      </RadioGroup>
    )
  }
)

export default RadioGroupFieldSimple
