import styled from 'styled-components/macro'
import { Dialog, Divider, Grid, Typography } from '@material-ui/core'

export const ContentSpace = styled.div`
  padding-top: 16px;
  width: 100%;
`
export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`
export const SubField = styled.div`
  padding-top: 24px;
`
export const InputType = styled.div`
  padding-top: 8px;
`
export const TooltipStyle = styled.div`
  margin-top: 16px;
`
export const RoundingTypographyStyle = styled(Typography)`
  font-size: 10px;
`
export const ThickDivider = styled(Divider)`
  height: 3px;
  background-color: ${(props: any) => `${props.theme.palette.text.secondary}`};
  margin-bottom: 8px;
`
export const FormBottom = styled.div`
  padding-top: 16px;
`
export const ContentGridContainer = styled(Grid)`
  padding: 24px;
`
export const ComponentGridContainer = styled(Grid)`
  margin-bottom: 24px;
`
