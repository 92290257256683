import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Flexbox } from './Styled'
import { LendingRateType } from 'store/products/types'
import { DeleteIconButton, DesignIconButton } from 'dls/molecules/IconButtons/IconButtons'

interface OwnProps {
  showDeleteIcon?: boolean
  showEditIcon?: boolean
  editPath: LendingRateType | string
  removeEntity?: () => void
  ariaControlsLabel?: string
}

export const EditDeleteCardButtonGroup = ({
  showDeleteIcon = false,
  showEditIcon = false,
  editPath,
  removeEntity,
  ariaControlsLabel,
}: OwnProps): React.ReactElement => (
  <Flexbox direction="row" justifyContent="flex-end">
    {showDeleteIcon && (
      <DeleteIconButton
        aria-label="Delete"
        aria-haspopup="dialog"
        aria-controls={ariaControlsLabel}
        onClick={removeEntity}
      />
    )}
    {showEditIcon && <DesignIconButton aria-label="Edit" role="button" component={RouterLink} to={editPath} />}
  </Flexbox>
)

export default EditDeleteCardButtonGroup
