import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Field, getIn, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { ThickDivider } from 'pages/ReferenceData/WithholdingTaxRuleCatalog/styled'
import { FieldAmount, FieldPercentage, RepeaterLayout } from 'components'
import { RateTierBand } from 'store/products/types'
import { rateYupValidation, Yip, validationForTierBandStartRangeEndRangeTax } from 'utils/form.utils'
import { WithholdingTaxRuleFormValues } from 'store/withholdingTax/types'

interface Props {
  name: string
  currencyCode?: string | null
}

const TiersBandsList = ({ name, currencyCode }: Props) => {
  const { t } = useTranslation()
  const { values, errors, setFieldValue, setFieldTouched } = useFormikContext<WithholdingTaxRuleFormValues>()

  const selectTierBandCalcMethod = getIn(values, 'tierBandCalcMethod')
  const lastTierBandIndex = getIn(values, `${name}`).length - 1
  const currentTierBandValues = getIn(values, `${name}[${lastTierBandIndex}]`)
  const canAddNewTierBand =
    getIn(values, `${name}[${lastTierBandIndex}].endRange`) !== null &&
    getIn(values, `${name}[${lastTierBandIndex}].endRange`) !== undefined &&
    getIn(values, `${name}[${lastTierBandIndex}].endRange`) !== '' &&
    getIn(values, `${name}[${lastTierBandIndex}].tierBandRate`) !== null &&
    getIn(values, `${name}[${lastTierBandIndex}].tierBandRate`) !== undefined &&
    getIn(values, `${name}[${lastTierBandIndex}].tierBandRate`) !== ''

  const handleEndRangeChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(`${name}[${index}].endRange`)
    setFieldValue(`${name}[${index}].endRange`, e.target.value !== '' ? Number(e.target.value) : e.target.value)
    if (lastTierBandIndex > index && e.target.value !== '') {
      setFieldTouched(`${name}[${index}].startRange`)
      setFieldValue(`${name}[${index + 1}].startRange`, e.target.value)
    }
  }
  return (
    <RepeaterLayout
      name={name}
      labelDelete={t('Delete Band')}
      labelNewItem={selectTierBandCalcMethod === RateTierBand.TIER ? t('Add a Tier') : t('Add a Band')}
      isAddDisabled={
        (!canAddNewTierBand && lastTierBandIndex !== -1) || !!getIn(errors, `${name}[${lastTierBandIndex}]`)
      }
      newItem={
        getIn(values, `${name}`).length > 0
          ? {
              startRange: currentTierBandValues.endRange,
              tierBandOperand: undefined,
            }
          : {}
      }
      isItemDeletable={({ index }) => index === lastTierBandIndex && index !== 0}
      deleteButtonStyle={{ marginTop: 70, top: 7 }}
    >
      {({ name: itemName, index }) => (
        <Grid container direction="row" spacing={2}>
          {index > 0 && (
            <Grid item xs={12}>
              <ThickDivider style={{ marginTop: 24 }} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="subtitle1">{t('When income is...')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Field
              label={t(`Greater than (pre-set)`)}
              name={`${itemName}.startRange`}
              component={FieldAmount}
              currencyCode={currencyCode}
              fullWidth
              readOnly
              disabled
              required
              placeholder={t('Please enter')}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              label={t(`And equal to or less than`)}
              name={`${itemName}.endRange`}
              required={index !== lastTierBandIndex}
              fullWidth
              component={FieldAmount}
              currencyCode={currencyCode}
              InputProps={{ onChange: handleEndRangeChange(index) }}
              validate={
                index !== lastTierBandIndex
                  ? Yip(
                      Yup.number()
                        .typeError(t('An upper balance is required for the preceding tier'))
                        .required()
                        .notOneOf([0, '0'], t('The upper balance must be greater than the lower balance'))
                        .moreThan(0)
                    )
                  : Yip(
                      Yup.string()
                        .nullable()
                        .notOneOf([0, '0'], t('The upper balance must be greater than the lower balance'))
                    )
              }
              placeholder={t('Leave for unlimited')}
            />
          </Grid>

          <Grid item xs={4}>
            <Field
              label={t(`Tax rate is`)}
              name={`${itemName}.tierBandRate`}
              required
              fullWidth
              component={FieldPercentage}
              validate={Yip(rateYupValidation(2, 1000).required())}
              placeholder={t('Please enter')}
              numberFormatProps={{
                decimalScale: 2,
              }}
            />
          </Grid>
        </Grid>
      )}
    </RepeaterLayout>
  )
}

export default TiersBandsList

export const yupValidationForTiersBands = Yup.array(
  validationForTierBandStartRangeEndRangeTax('The upper balance must be greater than the lower balance')
)
