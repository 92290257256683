import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { reversalSubtitle } from '../utils'
import {
  ConfirmTransactionResultType,
  ReverseWriteOffAndWriteBackTransactionTransferRequest,
  SuspenseTransactionItem,
} from 'store/accounts/types'
import SuspenseAccountsActions from 'store/accounts/actions'
import SuspenseAccountsSelectors from 'store/accounts/selectors'
import { ReverseTransaction } from 'assets'
import { FailureModal, ProgressModal, SuccessModal, ConfirmModalDeprecated } from 'components'

interface OwnProps {
  item: SuspenseTransactionItem
  onFinish: () => void
  onCancel: () => void
  open: boolean
}

const ReverseTransactionModal = ({ onCancel, onFinish, open, item }: OwnProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector(SuspenseAccountsSelectors.selectedConfirmTransactionLoading)
  const result = useSelector(SuspenseAccountsSelectors.selectedConfirmTransactionResult)

  const onWriteOffTransaction = () =>
    dispatch(
      SuspenseAccountsActions.reverseWriteOffAndWriteBackTransaction({
        transactionIds: [item.transactionId],
      } as ReverseWriteOffAndWriteBackTransactionTransferRequest)
    )

  const onClearResult = () => dispatch(SuspenseAccountsActions.confirmTransactionResultClear())

  const onSuccessfulClose = () => {
    onClearResult()
    onFinish()
  }

  const onFailedClose = () => {
    onClearResult()
    onCancel()
  }

  if (isLoading)
    return (
      <ProgressModal
        open={isLoading}
        title={t('Processing reversal')}
        description={t('Please wait while we process \nthe reversal')}
      />
    )

  if (result === ConfirmTransactionResultType.SUCCESS) {
    return (
      <SuccessModal
        title="The reversal was successful"
        description={t(
          'The transaction has been reversed, the original transaction will appear as ‘pending’. \n' +
            'Next, you need to find the pending transaction and clear it to the correct \n' +
            'destination account.'
        )}
        open
        setOpen={onSuccessfulClose}
      />
    )
  }
  if (result === ConfirmTransactionResultType.FAIL)
    return <FailureModal open description={t('Please go back and try again')} setOpen={onFailedClose} />

  return (
    <ConfirmModalDeprecated
      image={{ src: ReverseTransaction, alt: 'reversing transaction icon' }}
      imageStyle={{ marginBottom: '50px' }}
      onCancelClick={onCancel}
      onConfirmClick={{ action: onWriteOffTransaction, title: t('Confirm') }}
      open={open}
      subtitle={reversalSubtitle(item, t)}
      title={t('You’re reversing a transaction')}
    />
  )
}

export default ReverseTransactionModal
