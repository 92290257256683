import { StatementFormValues } from 'store/products/types'
import { Template } from 'store/statement/types'

export enum StatementLevel {
  PARTY = 'PARTY',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface StatementConfiguration {
  name: string
  statementDescription: string
  statementType: string
  offsetDays: number
  startDay: number
  startMonth?: number
  statementPeriod: string
  level: StatementLevel
  productSegment: string
  statementConfigKey?: string
  template?: {
    id: string
    name: string
  }
}

export interface StatementConfigurationFormValues {
  name: string
  statementDescription: string
  statementType: string
  offsetDays: string
  startDay: string
  startMonth?: string
  statementPeriod: string
  level: StatementLevel
  productSegment: string
  statementConfigKey?: string
  template: Template | null
}

export interface StatementConfigState {
  statementConfigurations: StatementConfiguration[]
  draftStatementConfigurationFormValues: StatementConfigurationFormValues | null
  loading: boolean
  error?: Error
  selectedStatementProducts: object
  selectedStatementProductsLoading: boolean
  selectedStatementProductsError: boolean
  draftStatementsForm?: StatementFormValues
}
