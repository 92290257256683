import { createAction } from '@reduxjs/toolkit'
import {
  UserLoginRequest,
  UserData,
  Colleague,
  UserFormValues,
  Team,
  TeamFormValues,
  UserKey,
  UserSSOLoginRequest,
} from './types'
import { AUTH_TYPE } from 'api/client/authAwareAxios'

const login = createAction<UserLoginRequest>('LOGIN')
const ssoLogin = createAction<UserSSOLoginRequest>('SSO/LOGIN')
const loginSuccess = createAction<{ userData: UserData; authType: AUTH_TYPE }>('LOGIN/SUCCESS')
const loginFail = createAction<Error>('LOGIN/FAIL')
const loginReset = createAction('LOGIN/RESET')
const logout = createAction('LOGOUT')
const logoutFail = createAction<Error>('LOGOUT/FAIL')
const nullAccessToken = createAction('NULL/ACCESS/TOKEN')
const startAuthWatcher = createAction('AUTH_STATUS_WATCH_START')
const getUser = createAction('USERS/GET')
const getUserSuccess = createAction<Colleague[]>('USERS/GET/SUCCESS')
const getUserFail = createAction<Error>('USERS/GET/FAIL')
const createUser = createAction<UserFormValues>('USERS/CREATE')
const createUserSuccess = createAction<Colleague>('USERS/CREATE/SUCCESS')
const createUserFail = createAction<Error>('USERS/CREATE/FAIL')

const getTeams = createAction('TEAMS/GET')
const getTeamsSuccess = createAction<Team[]>('TEAMS/GET/SUCCESS')
const getTeamsFail = createAction<Error>('TEAMS/GET/FAIL')
const createTeam = createAction<TeamFormValues>('TEAMS/CREATE')
const createTeamSuccess = createAction<Team>('TEAMS/CREATE/SUCCESS')
const createTeamFail = createAction<Error>('TEAMS/CREATE/FAIL')

const getUserDetails = createAction<UserKey>('USER/GET/DETAILS')
const getUserDetailsSuccess = createAction<Colleague>('USER/GET/DETAILS/SUCCESS')
const getUserDetailsFail = createAction<Error>('USER/GET/DETAILS/FAIL')
const setTenantIndexAndApiKey = createAction<number>('USER/SET/APIKEY')
const setSelectedTenantIndex = createAction<number>('USER/SET/SELECTEDTENANTINDEX')
const setSelectedTenantIndexFail = createAction<Error>('USER/SET/SELECTEDTENANTINDEX/FAIL')
const setSelectedTenantIndexSuccess = createAction<number>('USER/SET/SELECTEDTENANTINDEX/SUCCESS')

export default {
  ssoLogin,
  login,
  loginSuccess,
  loginFail,
  logout,
  nullAccessToken,
  startAuthWatcher,
  getUser,
  getUserSuccess,
  getUserFail,
  getUserDetails,
  getUserDetailsSuccess,
  getUserDetailsFail,
  createUser,
  createUserSuccess,
  createUserFail,
  getTeams,
  getTeamsSuccess,
  getTeamsFail,
  createTeam,
  createTeamSuccess,
  createTeamFail,
  setTenantIndexAndApiKey,
  setSelectedTenantIndex,
  setSelectedTenantIndexFail,
  setSelectedTenantIndexSuccess,
  loginReset,
  logoutFail,
}
