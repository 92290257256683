import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { WithholdingTaxRuleState } from './types'

const initialState: WithholdingTaxRuleState = {
  isLoading: false,
  rules: null,
  selectedRule: null,
  error: undefined,
  selectedRuleProducts: {},
  selectedRuleProductsLoading: false,
  selectedRuleProductsError: false,
}

const reducer = createReducer<WithholdingTaxRuleState>(initialState, {
  [actions.getAllTaxRules.type]: (state: WithholdingTaxRuleState) => ({
    ...state,
    rules: null,
    error: undefined,
    isLoading: true,
  }),
  [actions.getAllTaxRulesWithoutErrorNavigation.type]: (state: WithholdingTaxRuleState) => ({
    ...state,
    rules: null,
    error: undefined,
    isLoading: true,
  }),
  [actions.getAllTaxRulesSuccess.type]: (
    state: WithholdingTaxRuleState,
    action: ReturnType<typeof actions.getAllTaxRulesSuccess>
  ) => ({
    ...state,
    isLoading: false,
    error: undefined,
    rules: action.payload,
  }),
  [actions.getTaxRule.type]: (state: WithholdingTaxRuleState) => ({ ...state, selectedRule: null, isLoading: true }),
  [actions.getTaxRuleSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    selectedRule: action.payload,
  }),
  [actions.getTaxRuleProducts.type]: (state: WithholdingTaxRuleState) => ({
    ...state,
    selectedRuleProductsLoading: true,
    selectedRuleProductsError: false,
    selectedRuleProducts: {},
  }),
  [actions.getTaxRuleProductsSuccess.type]: (
    state: WithholdingTaxRuleState,
    action: ReturnType<typeof actions.getTaxRuleProductsSuccess>
  ) => ({
    ...state,
    selectedRuleProductsLoading: false,
    selectedRuleProductsError: false,
    selectedRuleProducts: { ...state.selectedRuleProducts, [action.payload.key]: action.payload.products },
  }),
  [actions.getTaxRuleProductsError.type]: (state: WithholdingTaxRuleState) => ({
    ...state,
    selectedRuleProductsLoading: false,
    selectedRuleProductsError: true,
    selectedRuleProducts: {},
  }),
})

export default reducer
