import { Box } from '@material-ui/core'
import styled from 'styled-components/macro'

export const OrderedListItem = styled(Box)<{ hasBorder?: boolean }>`
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  ${({ hasBorder, theme }) => (hasBorder ? `border-bottom: 1px ${theme.palette.primary.greyLight} solid;` : '')}
  padding: 24px;
`
