import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { cloneDeep } from 'lodash'
import { Grid, Box } from '@material-ui/core'
import { Search } from './components/Search'
import Footer from './components/Footer'
import { DataListsWrapper } from './components/DataListsWrapper'
import { DataPickerRow } from 'dls/shared/types'
import { Body2, ContextualHelp } from 'dls/'
import { ConditionValue } from 'store/products/typings/conditionBuilder'

const StyledGrid = styled(Grid)`
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  border: 1px solid ${(props: any) => `${props.theme.palette.background.medium}`};
  border-bottom: 0;
`

const StyledHeaderGrid = styled(Grid)<{ headerColor?: string }>`
  background-color: ${({ headerColor, theme }) => headerColor || theme.palette.teal.light};
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      min-height: 10px;
    }
  `}
`

interface Props {
  title: React.ReactNode
  contextualHelp?: {
    ariaProps: Record<string, string | boolean>
    id: string
    title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  }
  callToActionBar?: {
    cancelButton?: {
      label: string
      onClick: (e: React.MouseEvent) => void
    }
    submitButton?: {
      label: string
      onClick: (e: React.MouseEvent) => void
    }
  }
  dataRows: DataPickerRow[]
  hideFooter?: boolean
  showSearch?: boolean
  customSubmitEnableHandler?: (status: boolean) => void
  onChange: (codes: ConditionValue[]) => void
  headerColor?: string
}

export const DataPicker = ({
  title,
  headerColor,
  contextualHelp,
  callToActionBar,
  dataRows = [],
  hideFooter,
  showSearch,
  customSubmitEnableHandler,
  onChange,
}: Props) => {
  const [selectedState, setSelectedState] = useState<ConditionValue[]>([])
  const dataPickerSelector = React.useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState<string>('')

  const formChanged = useCallback(() => {
    const allCheckedElements = dataPickerSelector?.current?.querySelectorAll<HTMLElement>('.isLeaf.Mui-checked input')
    const codes: ConditionValue[] = []

    if (allCheckedElements) {
      allCheckedElements.forEach((element) => {
        const el = element as HTMLInputElement
        codes.push({ id: el.value, value: el.value, type: 'code' })
      })
    }

    if (customSubmitEnableHandler) {
      customSubmitEnableHandler(codes.length === 0)
    }

    setSelectedState(codes)
    onChange(codes)
  }, [])

  const filteredDataRows = useMemo(() => {
    const findSearchText = (node: DataPickerRow) => {
      if (node.description?.indexOf(searchText) > -1 || node.code.indexOf(searchText) > -1) {
        return true
      }

      if (node.children) {
        for (let i = 0; i < node.children.length; i += 1) {
          const found = findSearchText(node.children[i])
          if (found) {
            return true
          }
        }
      }

      return false
    }

    return cloneDeep(dataRows).filter((row) => findSearchText(row))
  }, [searchText])

  return (
    <div data-dls="organism-data-picker" ref={dataPickerSelector}>
      <StyledGrid container direction="row" alignItems="stretch" justifyContent="space-between">
        <StyledHeaderGrid item xs={12} md={showSearch ? 6 : 12} justify="center" headerColor={headerColor}>
          <Box
            p={3}
            sx={{ padding: showSearch ? '24px 0 0 24px' : '24px' }}
            display="grid"
            alignItems="stretch"
            height="100%"
            textAlign="left"
          >
            <Body2 id="datapickerHeading" variantMapping={{ body2: 'h4' }} css={{ paddingTop: 3 }}>
              {title}
              {contextualHelp && (
                <ContextualHelp ariaProps={contextualHelp.ariaProps} interactive title={contextualHelp.title} />
              )}
            </Body2>
          </Box>
        </StyledHeaderGrid>
        {showSearch && (
          <StyledHeaderGrid item xs={12} md={6} headerColor={headerColor}>
            <Box p={3} display="flex" alignItems="left">
              <Search onSearch={setSearchText} searchText={searchText} />
            </Box>
          </StyledHeaderGrid>
        )}
      </StyledGrid>
      <DataListsWrapper dataRows={filteredDataRows} formChanged={formChanged} />
      {!hideFooter && <Footer callToActionBar={callToActionBar} disableSubmit={selectedState.length === 0} />}
    </div>
  )
}

export default DataPicker
