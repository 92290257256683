import { createAction } from '@reduxjs/toolkit'
import { StatementConfiguration, StatementConfigurationFormValues } from './types'
import { Template } from 'store/statement/types'
import { SearchProduct, StatementFormValues } from 'store/products/types'

const actions = {
  fetchStatementConfigurations: createAction('STATEMENT_CONFIGURATION/FETCH'),
  fetchStatementConfigurationSuccess: createAction<StatementConfiguration[]>('STATEMENT_CONFIGURATION/FETCH/SUCCESS'),
  fetchStatementConfigurationError: createAction<Error>('STATEMENT_CONFIGURATION/FETCH/ERROR'),
  updateStatementConfiguration: createAction<StatementConfiguration>('STATEMENT_CONFIGURATION/UPDATE'),
  saveDraftStatementConfigurationFormValues: createAction<StatementConfigurationFormValues>(
    'STATEMENT_CONFIGURATION/SAVE_DRAFT_CONFIGURATION'
  ),
  deleteDraftStatementConfigurationFormValues: createAction('STATEMENT_CONFIGURATION/DELETE_DRAFT_CONFIGURATION'),
  saveDraftStatementConfigurationTemplate: createAction<Template>('STATEMENT_CONFIGURATION/SAVE_DRAFT_TEMPLATE'),
  deleteDraftStatementConfigurationTemplate: createAction('STATEMENT_CONFIGURATION/DELETE_DRAFT_TEMPLATE'),
  deleteStatementConfiguration: createAction<{ statementKey: string }>('STATEMENT_CONFIGURATION/DELETE'),
  getStatementProducts: createAction<string>('STATEMENT_CONFIGURATION/GET/PRODUCTS'),
  getStatementProductsSuccess: createAction<SearchProduct>('STATEMENT_CONFIGURATION/GET/PRODUCTS/SUCCESS'),
  getStatementProductsError: createAction<Error>('STATEMENT_CONFIGURATION/GET/PRODUCTS/ERROR'),
  updateSelectedStatementKey: createAction<{ statementConfigurationKey?: string }>(
    'STATEMENT_CONFIGURATION/UPDATE/SELECTED_CONFIGKEY'
  ),
  updateDraftStatementFormValues: createAction<StatementFormValues>('STATEMENTS/UPDATE/STATEMENT_FORM'),
}

export default actions
