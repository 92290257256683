import { useDispatch, useSelector } from 'react-redux'
import { toRule, toTaxRule } from './transformers'
import selectors from 'store/withholdingTax/selectors'
import actions from 'store/withholdingTax/actions'
import {
  EntityUpdateMode,
  WithholdingRule,
  WithholdingRuleFormValues,
  WithholdingTaxRuleFormValues,
} from 'store/withholdingTax/types'

const useTaxRule = () => {
  const taxRules = useSelector(selectors.taxRules)
  const selectedTaxRule = useSelector(selectors.selectedTaxRule)
  const selectedTaxRuleProducts = useSelector(selectors.selectedTaxRuleProducts)
  const productsLoading = useSelector(selectors.selectedTaxRuleProductsLoading)
  const productsError = useSelector(selectors.selectedRuleProductsError)
  const isLoading = useSelector(selectors.isLoading)
  const hasError = useSelector(selectors.error)

  const dispatch = useDispatch()

  const getAllTaxRules = () => dispatch(actions.getAllTaxRules())

  const getTaxRule = (ruleId: string) => dispatch(actions.getTaxRule(ruleId))

  const getTaxRuleProducts = (ruleId: string) => dispatch(actions.getTaxRuleProducts(ruleId))

  const createRule = (values: WithholdingRuleFormValues) => dispatch(actions.createRule(toRule(values)))

  const updateTaxRule = (values: WithholdingTaxRuleFormValues, mode: EntityUpdateMode) =>
    dispatch(actions.updateTaxRule(toTaxRule({ formValues: values, mode })))

  const deleteRule = (rule: WithholdingRule) => dispatch(actions.deleteRule(rule))

  return {
    taxRules,
    selectedTaxRule,
    selectedTaxRuleProducts,
    productsLoading,
    productsError,
    isLoading,
    hasError,
    getAllTaxRules,
    getTaxRule,
    createRule,
    updateTaxRule,
    getTaxRuleProducts,
    deleteRule,
  }
}

export default useTaxRule
