import React from 'react'
import { FastField } from 'formik'
import * as Yup from 'yup'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AccessibleLink, TextFieldComplex } from 'components'
import { Yip } from 'utils/form.utils'
import { InfoPanel } from 'dls'

export const CardLimitElement = ({
  name,
  description,
  productKey,
  maximumNumber,
}: {
  name: string
  description?: string
  productKey: string
  maximumNumber?: number
}) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      {description && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom variantMapping={{ subtitle2: 'div' }}>
            {description}
          </Typography>
        </Grid>
      )}
      {maximumNumber && (
        <Grid item xs={12} style={{ marginTop: -16 }}>
          <InfoPanel
            title={
              <>
                <Typography variant="body2" variantMapping={{ body2: 'div' }}>
                  {t(
                    'The limit you set here must be equal or greater than {{maximumNumber}} which is maximum individual card limit that’s been set in the ',
                    { maximumNumber }
                  )}
                  <AccessibleLink to={`/products/${productKey}/features/cards`} aria-label="cards" color="secondary">
                    {t('product’s cards feature.')}
                  </AccessibleLink>
                </Typography>
              </>
            }
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <FastField
          name={`${name}.maximumNumber`}
          label={t('Maximum number')}
          required
          type="number"
          validate={Yip(Yup.number().typeError(t('This field must be a number')).required().moreThan(0).lessThan(100))}
          component={TextFieldComplex}
          fullWidth
          placeholder={t('Enter number')}
          inputLabelProps={{ shrink: true }}
          helperText={
            maximumNumber &&
            t('Must be equal or greater than {{maximumNumber}} and less than 100', {
              maximumNumber,
            })
          }
          numberFormatProps={{
            decimalSeparator: false,
          }}
        />
      </Grid>
    </Grid>
  )
}
