import { Trans } from 'react-i18next'
import React from 'react'
import {
  EntityBadge10x,
  EntityReferenceData,
  EntityBadgeGovernance,
  EntityBadgePackage,
  EntityBadgeSuspenseAccount,
  EntityBadgeProduct,
  EntityTemplates,
  EntityBadgeProductSyndication,
} from 'dls/atoms/EntityBadges/EntityBadge'

const Overview = React.lazy(() => import('./Overview'))
const ProductManagement = React.lazy(() => import('./ProductManagement'))
const ProductGovernance = React.lazy(() => import('./ProductGovernance'))
const Packages = React.lazy(() => import('./Packages'))
const Templates = React.lazy(() => import('./Templates'))
const ReferenceData = React.lazy(() => import('./ReferenceData'))
const SuspenseAccounts = React.lazy(() => import('./SuspenseAccounts'))
const ProductSyndication = React.lazy(() => import('./ProductSyndication'))

export default () => ({
  overview: {
    icon: EntityBadge10x,
    title: <Trans>Bank Manager overview</Trans>,
    description: <Trans>Manage many aspects of your bank on the 10x platform…</Trans>,
    page: Overview,
    featureFlag: 'dashboard',
    show: true,
  },
  'product-management': {
    icon: EntityBadgeProduct,
    title: <Trans>Product management</Trans>,
    description: <Trans>Create a portfolio of products and manage their lifecycles...</Trans>,
    page: ProductManagement,
    featureFlag: 'menu_products',
    show: true,
  },
  'product-governance': {
    icon: EntityBadgeGovernance,
    title: <Trans>Product governance</Trans>,
    description: <Trans>Create and manage approval processes for new products and updates…</Trans>,
    page: ProductGovernance,
    featureFlag: 'governance',
    show: true,
  },
  'product-syndication': {
    icon: EntityBadgeProductSyndication,
    title: <Trans>Product syndication</Trans>,
    description: <Trans>Share your products with third-parties...</Trans>,
    page: ProductSyndication,
    featureFlag: 'menu_product_syndications',
    show: true,
  },
  packages: {
    icon: EntityBadgePackage,
    title: <Trans>Packages</Trans>,
    description: <Trans>Distribute your products to your customers...</Trans>,
    page: Packages,
    featureFlag: 'menu_packages',
    show: true,
  },
  'reference-data': {
    icon: EntityReferenceData,
    title: <Trans>Reference data</Trans>,
    description: <Trans>Manage index rates and rules for withholding tax on credit interest...</Trans>,
    page: ReferenceData,
    featureFlag: 'menu_reference',
    show: true,
  },
  templates: {
    icon: EntityTemplates,
    title: <Trans>Templates</Trans>,
    description: <Trans>Create and manage credit interest templates for your products...</Trans>,
    page: Templates,
    featureFlag: 'menu_templates',
    show: true,
    permissions: ['BM_ADMIN', 'BM_PRODUCT_CREATOR'],
  },
  'suspense-accounts': {
    icon: EntityBadgeSuspenseAccount,
    title: <Trans>Suspense accounts</Trans>,
    description: <Trans>Unallocated transactions which you can view and clear to another account…</Trans>,
    page: SuspenseAccounts,
    featureFlag: 'suspense_account',
    show: true,
  },
})
