import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import StatementActions from 'store/statement/actions'
import StatementSelectors from 'store/statement/selectors'
import { ProductSegment, StatementType } from 'store/products/types'
import { Template } from 'store/statement/types'

const useInterestStatementTemplate = (segment: ProductSegment) => {
  const dispatch = useDispatch()
  const allTemplates = useSelector(StatementSelectors.selectTemplates)
  const error: Error | null = useSelector(StatementSelectors.selectTemplatesError)
  const templatesLoading: boolean = useSelector(StatementSelectors.selectTemplatesLoading)
  const templates: Template[] = allTemplates[StatementType.INTEREST_STATEMENT] || []

  const getTemplates = (prodSegment: ProductSegment) =>
    dispatch(
      StatementActions.getTemplates({
        productSegment: prodSegment,
        templateType: StatementType.INTEREST_STATEMENT,
      })
    )

  useEffect(() => {
    if (segment) {
      getTemplates(segment)
    }
  }, [segment])

  return {
    getTemplates,
    templates,
    templatesLoading,
    error,
  }
}

export default useInterestStatementTemplate
