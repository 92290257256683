import {
  CardTransactionLimitsDirection,
  CardTransactionLimitsRoutingDestination,
  CardTransactionLimitsRange,
  CardTransactionLimitsAmountType,
  CardTransactionLimitsChannel,
} from 'store/products/typings/cardLimits'

export const getEditUrl = (limitRuleId: string) => `edit/${limitRuleId}`

export const addUrls = {
  perCashTransaction: `add?direction=${CardTransactionLimitsDirection.DEBIT}&channel=${CardTransactionLimitsChannel.NOT_APPLICABLE}&routingDestinations=${CardTransactionLimitsRoutingDestination.CARD_CASH}&range=${CardTransactionLimitsRange.TRANSACTION}&amountType=${CardTransactionLimitsAmountType.MONETARY}`,
  cumulativeCashDaily: `add?direction=${CardTransactionLimitsDirection.DEBIT}&channel=${CardTransactionLimitsChannel.NOT_APPLICABLE}&routingDestinations=${CardTransactionLimitsRoutingDestination.CARD_CASH}&range=${CardTransactionLimitsRange.DAY}&amountType=${CardTransactionLimitsAmountType.MONETARY}`,
  perNonCashTransaction: `add?direction=${CardTransactionLimitsDirection.DEBIT}&channel=${CardTransactionLimitsChannel.NOT_APPLICABLE}&routingDestinations=${CardTransactionLimitsRoutingDestination.CARD_NON_CASH}&range=${CardTransactionLimitsRange.TRANSACTION}&amountType=${CardTransactionLimitsAmountType.MONETARY}`,
  cumulativeNonCashDaily: `add?direction=${CardTransactionLimitsDirection.DEBIT}&channel=${CardTransactionLimitsChannel.NOT_APPLICABLE}&routingDestinations=${CardTransactionLimitsRoutingDestination.CARD_NON_CASH}&range=${CardTransactionLimitsRange.DAY}&amountType=${CardTransactionLimitsAmountType.MONETARY}`,
}
