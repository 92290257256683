import createAsyncAuthAwareAxios from './client/authAwareAxios'
import { DebitInterest, DebitInterestCommonConfig, LendingRate, ProductKey } from 'store/products/types'
import { hashObject } from 'utils/api.utils'

async function createDebitInterest(
  productKey: ProductKey,
  version: number,
  payload: DebitInterest
): Promise<DebitInterest> {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).put<DebitInterest>(
    `/v1/products/${productKey}/versions/${version}/features/debitInterest/v1`,
    payload
  )

  return data
}

async function updateDebitInterest(
  entity: 'commonConfig' | 'rateWithinLimit' | 'rateAboveLimit' | 'rateAboveLimitOverride',
  productKey: ProductKey,
  version: number,
  payload: { description: string; commonConfig: DebitInterestCommonConfig } | LendingRate
): Promise<DebitInterest> {
  const hash = hashObject('debitInterest', { ...payload, number: Math.random() })
  const apiPayload = [
    {
      op: 'replace',
      path: `/${entity}`,
      value: entity === 'commonConfig' ? (payload as any).commonConfig : payload,
    },
  ]

  if (entity === 'commonConfig') {
    apiPayload.push({
      op: 'replace',
      path: `/description`,
      value: (payload as any).description,
    })
  }

  const { data } = await (await createAsyncAuthAwareAxios(null, false)).patch<DebitInterest>(
    `/v1/products/${productKey}/versions/${version}/features/debitInterest/v1`,
    apiPayload,
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )

  return data
}

async function removeDebitInterestRate(
  rateType: 'rateWithinLimit' | 'rateAboveLimit' | 'rateAboveLimitOverride',
  productKey: ProductKey,
  version: number
): Promise<DebitInterest> {
  const hash = hashObject('debitInterest', { rateType, productKey, version, number: Math.random() })
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).patch<DebitInterest>(
    `/v1/products/${productKey}/versions/${version}/features/debitInterest/v1`,
    [
      {
        op: 'remove',
        path: `/${rateType}`,
      },
    ],
    {
      headers: {
        'Idempotency-Key': hash,
      },
    }
  )

  return data
}

async function deleteDebitInterestFeature(productKey: ProductKey, version: number): Promise<DebitInterest> {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).delete<DebitInterest>(
    `/v1/products/${productKey}/versions/${version}/features/debitInterest/v1`
  )

  return data
}

export default {
  createDebitInterest,
  deleteDebitInterestFeature,
  updateDebitInterest,
  removeDebitInterestRate,
}
