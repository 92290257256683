/* eslint-disable jsx-a11y/anchor-is-valid */
import { capitalize, Grid, Paper, Typography } from '@material-ui/core'
import { Form, FormikProps, getIn } from 'formik'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, pick, set } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import FeaturePageWithTopAddLayout from '../FeaturePageWithTopAddLayout'
import { cardMaximumNumberValidation, defaultCardsValue } from './cardsTransformers'
import CardsElement from './CardsElement'
import { stopPropagation, withFormikSimple } from 'utils/form.utils'
import { SelectFieldData } from 'components/form/SelectFieldComplex'
import {
  CardFormType,
  Product,
  CardScheme,
  CardsFormValues,
  MultipleChoiceElement,
  ProductKey,
} from 'store/products/types'
import {
  ConfirmModalDeprecated,
  FormAddFeatureButton,
  FormBottomBar,
  SectionArray,
  ZeroStateLandingPage,
} from 'components'
import { ClientConfig } from 'store/tenant-config/types'
import { DeleteInfo, NoCards } from 'assets'
import { Accordion, DeleteIconButton, Body1 } from 'dls'
import { featureIconsCreator, featureSVGIcons } from 'pages/Product/product.utils'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  // eslint-disable-next-line react/no-unused-prop-types
  hasFeature?: boolean
  clientConfig?: Partial<ClientConfig>
  productKey: ProductKey
  // eslint-disable-next-line react/no-unused-prop-types
  product: Product
  // eslint-disable-next-line react/no-unused-prop-types
  t: TFunction
}

type CardFormProps = FormikProps<CardsFormValues> & OwnProps

const CardForm = (props: CardFormProps) => {
  const availableSchemes =
    (props && props.clientConfig && props.clientConfig.cards && props.clientConfig.cards.scheme) || null

  let schemeList = {}

  if (availableSchemes && !isEmpty(availableSchemes)) {
    schemeList = pick(CardScheme, availableSchemes)
  } else {
    schemeList = CardScheme
  }

  const selectDataCardScheme: SelectFieldData = {}
  Object.entries(schemeList as CardScheme).forEach(([key, value]) => {
    selectDataCardScheme[key] = { name: value }
  })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = useState<number | null>(null)

  const getCardsMenuItem = (formType: CardFormType): MultipleChoiceElement => ({
    defaultValue: { ...defaultCardsValue, formType },
    multiple: true,
    name: 'cards',
    label: t(`${formType}_LABEL`),
    disabled: false,
  })

  const onPressBack = useCallback(() => {
    navigate(`/products/${props.productKey}`)
  }, [props.productKey])

  return (
    <FeaturePageWithTopAddLayout
      productKey={props.productKey}
      title={t('Cards')}
      description={t(
        'We currently only issue debit cards. Debit cards are deactivated when issued to customers – the customer will need to activate their card – this is a fraud control. If you have more than one card, you can set the combined card limit per party in the Limits feature.'
      )}
      rightArea={
        <FormAddFeatureButton
          addLabel={t('Add Card')}
          elements={{
            [CardFormType.PHYSICAL]: {
              ...getCardsMenuItem(CardFormType.PHYSICAL),
            },
            [CardFormType.VIRTUAL]: {
              ...getCardsMenuItem(CardFormType.VIRTUAL),
            },
          }}
        />
      }
    >
      <Form>
        {props.values.cards && props.values.cards.length ? (
          <SectionArray name="cards">
            {({ remove, name, index }) => {
              const formType = getIn(props.values, `${name}.formType`)
              const cardName = getIn(props.values, `${name}.name`)

              let cardNamelegacy
              if (cardName) {
                cardNamelegacy = `${capitalize(`${cardName}`)} - ${t(`${formType}_LABEL`).toLowerCase()}`
              } else if (formType !== null) {
                cardNamelegacy = t(`${formType}_LABEL`)
              } else {
                cardNamelegacy = t('Card')
              }

              return (
                <div key={index} style={{ marginBottom: 16 }}>
                  <ConfirmModalDeprecated
                    id={`delete-card-${index}`}
                    onCancelClick={() => setOpenConfirm(null)}
                    onConfirmClick={{
                      action: () => {
                        setOpenConfirm(null)
                        remove(index)
                      },
                      title: t('Ok, got it'),
                    }}
                    open={openConfirm === index}
                    title={t(`{{cardName}} will only be deleted when you submit the form`, {
                      cardName: cardNamelegacy,
                    })}
                    image={{ src: DeleteInfo, alt: t('Delete Icon') }}
                    imageStyle={{ marginBottom: '24px' }}
                    cancelText="Cancel"
                  />
                  <Accordion
                    title={<Body1 css="word-break: break-word">{cardNamelegacy}</Body1>}
                    headerIcon={featureIconsCreator(featureSVGIcons.cards)}
                    headerButtonGroup={
                      <DeleteIconButton
                        onClick={(e: React.MouseEvent) => {
                          stopPropagation(e)
                          setOpenConfirm(index)
                        }}
                      />
                    }
                    defaultExpanded={!cardName}
                  >
                    <CardsElement name={name} />
                  </Accordion>
                </div>
              )
            }}
          </SectionArray>
        ) : (
          <Paper style={{ paddingTop: 40, marginBottom: 15 }}>
            <ZeroStateLandingPage
              image={NoCards}
              title="No cards have been added or configured"
              fixHeight={false}
              imageAlt={t('Image of a card')}
            >
              <Typography variant="body2" style={{ marginBottom: 40, marginTop: -10 }}>
                {t('Select “Add card” above to get started')}
              </Typography>
            </ZeroStateLandingPage>
          </Paper>
        )}
        <Grid item xs={12}>
          <FormBottomBar position="bottom">
            <Button color={ColorType.BUTTON} onClick={onPressBack} aria-controls="main">
              {t('Cancel')}
            </Button>
          </FormBottomBar>
        </Grid>
      </Form>
    </FeaturePageWithTopAddLayout>
  )
}

export const cardsValidation = (values: CardsFormValues, props: any) => {
  const errors = {}
  const limitMaximumNumber = getIn(props, 'product.limits.cardLimit.maximumNumber')
  if (!!limitMaximumNumber && limitMaximumNumber > 0 && values && values.cards) {
    values.cards.forEach((item: any, index: number) => {
      if (!cardMaximumNumberValidation(item.maximumNumber, limitMaximumNumber)) {
        set(
          errors,
          `cards[${index}].maximumNumber`,
          props.t(
            'This must be equal to or less than {{limitMaximumNumber}} which has been set in the limits feature',
            { limitMaximumNumber }
          )
        )
      }
    })
  }
  return errors
}

export default withFormikSimple<OwnProps, CardsFormValues>(CardForm, {
  validate: cardsValidation,
})
