import React, { ReactElement } from 'react'
import { Grid, Fade } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import 'styled-components/macro'
import { HeadingGrid, CloseIconGrid, StyledFullScreenDialog } from './styled'
import { DialogStyledContainer } from 'dls/shared/styled'
import { H3 } from 'dls/atoms/Typography'
import { DialogProps } from 'dls/atoms/Dialog/Dialog'
import { Button } from 'dls/atoms'
import { ColorType } from 'dls/shared/types'
import CallToActionBar from 'dls/organisms/CallToActionBar/CallToActionBar'
import DialogActions from 'dls/atoms/Dialog/DialogActions'
import { extraPadding, spacingBlockInBoxItem, dialogPadding } from 'dls/shared/styleVar'
import ContextualHelp from 'dls/molecules/ContextualHelp'
import { CloseIconButton, DownloadIconButton } from 'dls/molecules/IconButtons/IconButtons'
import { stopPropagation } from 'utils/form.utils'

interface ContainerProps extends DialogProps {
  id: string
  title?: string
  ariaProps?: Record<string, string | boolean>
  children?: React.ReactNode
}

interface ContentProps {
  id?: string
  children?: React.ReactNode
  title: React.ReactElement | string
  headerIcon?: React.ReactElement
  helpText?: string
  leftRegion?: ReactElement[]
  rightRegion?: ReactElement[]
  cancelButtonText?: string
  submitButtonText?: string
  onCancel?: () => void
  onSubmit?: () => void
  onClose?: () => void
  submitButtonDisabled?: boolean
  download?: { action: () => void; ariaLabel: string }
}

export const FullScreenModalContainer = ({ ariaProps, id, children }: ContainerProps) => (
  <StyledFullScreenDialog
    open
    role="dialog"
    scroll="paper"
    fullScreen
    maxWidth={false}
    TransitionComponent={Fade}
    id={`${id}-dialog`}
    aria-labelledby="modal-heading"
    data-dls="organism-fullscreen-modal"
    {...ariaProps}
  >
    {children}
  </StyledFullScreenDialog>
)

export const FullScreenModalContent = ({
  title,
  id,
  children,
  leftRegion,
  rightRegion,
  cancelButtonText,
  submitButtonText,
  headerIcon,
  helpText,
  onSubmit,
  onCancel,
  onClose,
  download,
  submitButtonDisabled,
}: ContentProps) => {
  const { t } = useTranslation()
  return (
    <>
      <DialogStyledContainer id={`${id}-container`}>
        <Grid container direction="row" justifyContent="space-between" spacing={7}>
          <HeadingGrid item style={{ margin: spacingBlockInBoxItem, marginBottom: dialogPadding }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} wrap="nowrap">
              {headerIcon && <Grid item>{headerIcon}</Grid>}
              <Grid item>
                {title && typeof title === 'string' ? (
                  <H3
                    id="modal-heading"
                    style={{
                      padding: 0,
                      width: '70vw',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {title}
                  </H3>
                ) : (
                  title
                )}
              </Grid>
              {helpText && (
                <Grid item css="flex-grow: 2;">
                  <ContextualHelp title={<>{t(helpText)}</>} style={{ margin: 0 }} />
                </Grid>
              )}
            </Grid>
          </HeadingGrid>
          <CloseIconGrid item>
            {download && (
              <DownloadIconButton
                arial-label={download?.ariaLabel ?? t('download')}
                onClick={(e: React.MouseEvent) => {
                  stopPropagation(e)
                  download?.action()
                }}
              />
            )}
            <CloseIconButton
              onClick={(e: React.MouseEvent) => {
                stopPropagation(e)
                if (onClose) {
                  onClose()
                }
              }}
              aria-label="Close"
              data-test-id="dialog-close-button"
            />
          </CloseIconGrid>
        </Grid>
        <div>{children}</div>
      </DialogStyledContainer>
      {(!!leftRegion || !!rightRegion || !!onSubmit) && (
        <DialogActions style={{ padding: extraPadding, paddingTop: 0 }}>
          <CallToActionBar
            leftRegion={leftRegion}
            rightRegion={
              rightRegion || [
                <Button color={ColorType.BUTTON} key={cancelButtonText || 'Cancel'} variant="text" onClick={onCancel}>
                  {t(cancelButtonText || 'Cancel')}
                </Button>,
                <Button
                  color={ColorType.BUTTON}
                  key={submitButtonText || 'Submit'}
                  variant="contained"
                  onClick={onSubmit}
                  disabled={submitButtonDisabled}
                >
                  {t(submitButtonText || 'Submit')}
                </Button>,
              ]
            }
          />
        </DialogActions>
      )}
    </>
  )
}

const FullScreenModal = ({
  ariaProps,
  id,
  title,
  children,
  leftRegion,
  rightRegion,
  cancelButtonText,
  submitButtonText,
  headerIcon,
  helpText,
  submitButtonDisabled,
  download,
  onClose,
  onSubmit,
  onCancel,
}: ContainerProps & ContentProps & { children?: React.ReactNode }) => (
  <FullScreenModalContainer id={id} onClose={onClose} ariaProps={ariaProps} open>
    <FullScreenModalContent
      id={id}
      title={title}
      leftRegion={leftRegion}
      rightRegion={rightRegion}
      cancelButtonText={cancelButtonText}
      submitButtonText={submitButtonText}
      headerIcon={headerIcon}
      helpText={helpText}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onClose={onClose}
      download={download}
      submitButtonDisabled={submitButtonDisabled}
    >
      {children}
    </FullScreenModalContent>
  </FullScreenModalContainer>
)

export default FullScreenModal
