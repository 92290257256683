import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import ConditionModal from './ConditionModal/ConditionModal'
import { ConditionBuilder, InfoIconButton, Input, Paper, TemplateForm } from 'dls'
import { ExtendedProduct, Feature, FeeRewardFeatureType } from 'store/products/types'
import ContentContainer from 'pages/Layout/ContentContainer'
import { EntityBadgeFees, EntityBadgeRewards, H2 } from 'dls/atoms'
import { AvatarSize } from 'dls/shared/types'
import ReactHookFormISOFrequencySelector from 'components/ISOFrequencySelector/ReactHookFormISOFrequencySelector'
import {
  BuilderCondition,
  BuilderConditionFormValues,
  ConditionOperator,
} from 'store/products/typings/conditionBuilder'
import { FeeRewardDataPointType, FeeRewardFormValues } from 'store/products/features/feesRewards/types'
import { validationSchema } from 'pages/features/FeesRewards/utils/validationSchema'
import { toConditionFormValues } from 'pages/features/FeesRewards/utils/transformers'
import useModal from 'components/Modal/hooks/useModal'
import { HelpModalType } from 'store/modal/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { showMissionWIP } from 'utils/common.util'

interface Props {
  featureType: FeeRewardFeatureType
  product: ExtendedProduct
  productKey: string
  defaultValues: FieldValues | null
  onSubmit: (values: FeeRewardFormValues) => void
}

const UpdateForm = ({ product, productKey, defaultValues, featureType, onSubmit }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showInfographicModal } = useModal()
  const isShowingMissionWIP = showMissionWIP(useSelector(selectConfig))

  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedConditionIndex, setSelectedConditionIndex] = useState<number>(-1)
  const [defaultTransactionCodes, setDefaultTransactionCodes] = useState<string>('')
  const [conditions, setConditions] = useState<BuilderCondition[]>(defaultValues?.conditions || [])

  const { control, watch, setValue, reset, formState } = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: defaultValues || {},
    resolver: yupResolver(validationSchema(t)),
  })

  const name = watch('name')
  const amount = watch('amount')
  const frequency = watch('frequency')
  const monthlyOccurence = watch('monthlyOccurence')
  const monthlySpecificDay = watch('monthlySpecificDay')
  const annualOccurence = watch('annualOccurence')
  const annualSpecificMonth = watch('annualSpecificMonth')
  const annualSpecificDay = watch('annualSpecificDay')
  const weeklyOccurence = watch('weeklyOccurence')

  const onConditionModalOpen = (i?: number) => {
    const conditionFormValues = typeof i === 'number' ? toConditionFormValues(conditions[i]) : {}

    reset({
      ...conditionFormValues,
      name,
      amount,
      frequency,
      weeklyOccurence,
      monthlyOccurence,
      monthlySpecificDay,
      annualOccurence,
      annualSpecificDay,
      annualSpecificMonth,
    })
    setSelectedConditionIndex(typeof i === 'number' ? i : -1)

    const formValues = conditionFormValues as BuilderConditionFormValues
    if (formValues.dataPoint === FeeRewardDataPointType.TRANSACTION_ISO_CODE) {
      setDefaultTransactionCodes(formValues.dataValue)
    }
    setModalOpen(true)
  }

  const onConditionModalClose = () => {
    setSelectedConditionIndex(-1)
    setDefaultTransactionCodes('')
    setModalOpen(false)
  }

  const onConditionSubmit = (condition: BuilderCondition) => {
    if (selectedConditionIndex > -1) {
      const updatedConditions = [...conditions]
      updatedConditions[selectedConditionIndex] = condition
      setConditions(updatedConditions)
    } else {
      setConditions([...conditions, condition])
    }
  }

  const onConditionDelete = (i: number) => {
    const updatedConditions = [...conditions]
    updatedConditions.splice(i, 1)
    setConditions(updatedConditions)
  }

  const handleSubmit = () => {
    onSubmit({
      id: defaultValues?.id,
      name,
      amount,
      frequency,
      monthlyOccurence,
      monthlySpecificDay,
      annualOccurence,
      annualSpecificMonth,
      annualSpecificDay,
      weeklyOccurence,
      conditions,
    })
  }

  const helpModalType = featureType === Feature.FEES ? HelpModalType.FEES : HelpModalType.REWARDS

  return (
    <ContentContainer>
      <TemplateForm
        title={t(`PRODUCT_FEATURE.${featureType}.ENTITY_NAME`)}
        description={t(`PRODUCT_FEATURE.${featureType}.SETUP_DESCRIPTION`)}
        toolbarButtons={
          <Grid container direction="row" justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <InfoIconButton
                ariaProps={{
                  'aria-label': `About ${featureType}`,
                  'aria-haspopup': 'dialog',
                  'aria-controls': 'more-about-dialog',
                }}
                onClick={() => showInfographicModal(helpModalType)}
              />
            </Grid>
          </Grid>
        }
        main={
          <form>
            <section aria-labelledby={`about-${featureType}-heading`}>
              <Box mb={3}>
                <Paper responsivePadding>
                  <Box display="flex" flexDirection="column">
                    <Box mb={4}>
                      <H2 id={`about-${featureType}-heading`}>
                        {t(`PRODUCT_FEATURE.${featureType}.ABOUT_ENTITY_HEADING`)}
                      </H2>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Input
                              id="name"
                              fullWidth
                              label={t(`PRODUCT_FEATURE.${featureType}.ENTITY_NAME_FIELD_LABEL`)}
                              type="text"
                              placeholder={t('Enter name')}
                              required
                              error={fieldState.invalid}
                              errorText={fieldState.error?.message}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
            </section>
            <section aria-labelledby="conditions-and-amount-heading">
              <Box mb={3}>
                <Paper responsivePadding>
                  <Box display="flex" flexDirection="column">
                    <Box mb={4}>
                      <H2 id="conditions-and-amount-heading">
                        {t(`PRODUCT_FEATURE.${featureType}.CONDITIONS_AND_AMOUNT_HEADING`)}
                      </H2>
                    </Box>
                    <ConditionBuilder
                      featureType={featureType}
                      conditions={conditions}
                      onConditionChange={onConditionModalOpen}
                      onConditionDelete={onConditionDelete}
                      currencyCode={product.currency.currencyCode}
                      control={control}
                      disable={!isShowingMissionWIP}
                    />
                  </Box>
                </Paper>
              </Box>
            </section>
            <section aria-labelledby="frequency-heading">
              <Paper responsivePadding>
                <Box display="flex" flexDirection="column">
                  <Box mb={4}>
                    <H2 id="frequency-heading">{t(`PRODUCT_FEATURE.${featureType}.FREQUENCY_HEADING`)}</H2>
                  </Box>
                  <ReactHookFormISOFrequencySelector
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    frequencyFieldLabel={`PRODUCT_FEATURE.${featureType}.ENTITY_FREQUENCY_FIELD_LABEL`}
                    disableTooltip
                  />
                </Box>
              </Paper>
            </section>
            {isModalOpen ? (
              <ConditionModal
                name={name}
                featureType={featureType}
                selectedConditionIndex={selectedConditionIndex}
                conditions={conditions}
                operator={conditions.length ? ConditionOperator.AND : ConditionOperator.IF}
                defaultTransactionCodes={defaultTransactionCodes}
                control={control}
                watch={watch}
                setValue={setValue}
                onClose={onConditionModalClose}
                onConditionSubmit={onConditionSubmit}
              />
            ) : null}
          </form>
        }
        icon={
          featureType === Feature.FEES ? (
            <EntityBadgeFees size={AvatarSize.LARGE} />
          ) : (
            <EntityBadgeRewards size={AvatarSize.LARGE} />
          )
        }
        onCancel={() => navigate(`/products/${productKey}/features/${featureType}`)}
        onSubmit={handleSubmit}
        submitButtonDisabled={!formState.isValid}
      />
    </ContentContainer>
  )
}

export default UpdateForm
