import React, { Suspense, useState } from 'react'
import { Dialog, DialogContent, Fade, Grid, Input, InputAdornment, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Close, InfoOutlined, Search } from '@material-ui/icons'
import styled from 'styled-components/macro'
import CopyButton from 'components/CopyButton/CopyButton'
import { ContextualHelp, Loader } from 'components'
import { CloseButton } from 'dls/molecules/IconButtons/IconButtons'

const ReactJson = React.lazy(() => import('searchable-react-json-view'))

interface OwnProps {
  open: boolean
  onCancelClick?: () => void
  payload: object
}

const StyledGrid = styled(Grid)`
  overflow: scroll;
  height: 100%;
  .react-json-view {
    padding: 20px;
    width: 100%;
    font-size: 16px;
  }
`
const SearchInput = styled(Input)`
  float: left;
`

const Content = styled(DialogContent)`
  padding: 0 24px 24px;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperFullWidth {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
`

const ViewPayloadDialog = ({ onCancelClick, open, payload }: OwnProps) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const onSearchChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSearchTerm(e.target.value)
  }

  const onClose = () => {
    setSearchTerm('')
    if (onCancelClick) {
      onCancelClick()
    }
  }

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      onClose={onClose}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="xl"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        css={`
          padding: 22px 22px 14px;
          align-items: flex-end;
        `}
      >
        <Grid item>
          <Typography variant="h6">{t('JSON payload')}</Typography>
        </Grid>
        <Grid item>
          <SearchInput
            onChange={onSearchChange}
            value={searchTerm}
            placeholder={t('Search')}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
          <CopyButton
            payload={JSON.stringify(payload, null, '\t')}
            style={{
              margin: '0 12px',
              paddingTop: '0',
            }}
          />
          <ContextualHelp
            placement="bottom"
            title={t(
              'This is the full payload for your current product configuration. The labelling is different to what you see in Bank Manager UI where we adjust labels and presentation to make the information easier to understand.'
            )}
            Icon={InfoOutlined}
          />
          <CloseButton
            onClick={onClose}
            aria-label={t('Close')}
            css={`
              float: right;
              margin: -12px -12px -12px 12px;
              color: ${(props: any) => `${props.theme.palette.text.primary}`};
            `}
          >
            <Close />
          </CloseButton>
        </Grid>
      </Grid>
      <Content>
        <StyledGrid container direction="row" justifyContent="center" alignItems="center">
          <Suspense fallback={<Loader />}>
            <ReactJson
              highlightSearch={searchTerm}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={2}
              indentWidth={10}
              enableClipboard={false}
              src={payload}
              theme="rjv-default"
            />
          </Suspense>
        </StyledGrid>
      </Content>
    </StyledDialog>
  )
}
export default ViewPayloadDialog
