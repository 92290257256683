import React from 'react'
import { CircularProgress, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { ConfirmModalSVG } from 'assets'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface DeleteProps {
  isUpdating?: boolean
  closeDialog(e: React.MouseEvent): void
  confirmEdit(e: React.MouseEvent): void
}

const ButtonProgress = styled(CircularProgress)`
  position: relative;
  color: ${(props: any) => props.theme.palette.secondary};
  top: 25%;
  left: -12%;
  z-index: 1501;
`
const DialogStyledContainer = styled(DialogContent)`
  padding: 24px;
  padding-bottom: 16px;
  padding-top: 16px !important;
`

const TitleGridStyle = styled(Grid)`
  width: 95%;
  text-align: center;
`

const StyledDialogActions = styled(DialogActions)`
  padding-top: 20px;
  padding-right: 0;
`

export default function ConfirmInterestRateEditingModal(props: DeleteProps) {
  const { t } = useTranslation()
  return (
    <DialogStyledContainer>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          <img src={ConfirmModalSVG} alt="confirm modal" />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <TitleGridStyle item>
              <Typography variant="h5" gutterBottom>
                {t('You’re changing a rate – are you sure?')}
              </Typography>
            </TitleGridStyle>
            <TitleGridStyle item>
              <Typography variant="body2" gutterBottom>
                {t(
                  'Changes you have made will automatically impact any product which uses the index rate, in accordance with the effective date. Are you sure you want to proceed? If in doubt, go back and check.'
                )}
              </Typography>
            </TitleGridStyle>
          </Grid>
        </Grid>
      </Grid>
      <StyledDialogActions>
        <Button disabled={props.isUpdating} onClick={props.closeDialog} color={ColorType.BUTTON}>
          {t('Go back')}
        </Button>
        <>
          <Button
            css="margin-left:16px !important"
            onClick={props.confirmEdit}
            disabled={props.isUpdating}
            color={ColorType.BUTTON}
            variant="contained"
          >
            {t('Confirm')}
          </Button>
          {props.isUpdating && <ButtonProgress size={24} />}
        </>
      </StyledDialogActions>
    </DialogStyledContainer>
  )
}
