import React from 'react'
import { buildFeeRewardDefinitions } from '../../utils/transformers'
import { FeeReward } from 'store/products/features/feesRewards/types'
import { ReviewRows } from 'dls'
import { FeeRewardFeatureType } from 'store/products/types'

const ViewFeesRewards = ({
  entities,
  currencyCode,
  featureType,
}: {
  entities: FeeReward[]
  currencyCode: string
  featureType: FeeRewardFeatureType
}) => <ReviewRows definitionsList={buildFeeRewardDefinitions({ entities, currencyCode, featureType })} />

export default ViewFeesRewards
