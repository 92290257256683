import { createSelector } from 'reselect'
import uniqBy from 'lodash/uniqBy'
import { Product, ProductType } from './types'
import { RootState } from 'store'
import { ProductKey } from 'store/contents/types'

const selectProductState = (state: RootState) => state.products

const selectAllProducts = createSelector(selectProductState, (state) => Object.values(state.data))
const selectAllProductsOrigin = createSelector(selectProductState, (state) => state.data)

const selectProductSyndication = createSelector(selectProductState, (state) => state.productSyndication)
const selectProductSyndicationError = createSelector(selectProductState, (state) => state.productSyndicationError)
const selectProductSyndicationLoading = createSelector(selectProductState, (state) => state.productSyndicationLoading)

const selectSelectedProduct = createSelector(selectProductState, (state) => state.selectedProduct)
const selectDataLoading = createSelector(selectProductState, (state) => state.dataLoading)
const selectSelectedProductSegment = createSelector(
  selectSelectedProduct,
  (selectedProduct) => selectedProduct?.productSegment
)

const selectUpdating = createSelector(selectProductState, (state) => state.updating)
const selectError = createSelector(selectProductState, (state) => state.error)

const selectColleagues = createSelector(selectProductState, (state) => state.colleagues)

const selectLoading = createSelector(selectProductState, (state) => state.loading)
const selectVersions = createSelector(selectProductState, (state) => state.versions)

const filterProducts = (product: Product, filterProductType: ProductType) => product.productType === filterProductType

const selectReview = createSelector(selectProductState, (state) => state.review)
const selectReviewError = createSelector(selectProductState, (state) => state.reviewError)
const selectPublishError = createSelector(selectProductState, (state) => state.publishError)

const selectPublishing = createSelector(selectProductState, (state) => state.publishing)
const selectPublishSuccess = createSelector(selectProductState, (state) => state.publishSuccess)
const selectReviewNoUserError = createSelector(selectProductState, (state) => state.reviewNoUserError)
const selectReviewGeneralError = createSelector(selectProductState, (state) => state.reviewGeneralError)
const selectProductVersionsLoading = createSelector(selectProductState, (state) => state.productVersionsLoading)
const selectProductVersionsFail = createSelector(selectProductState, (state) => state.productVersionsFail)

const selectProductsByProductType = createSelector(
  selectAllProducts,
  // todo seems like this filter does an identity filtering, as you pass the same product type you check against in filterProducts
  (products) => products.filter((item) => filterProducts(item, item.productType))
)

const selectProductByKey = (state: RootState, productKeys: ProductKey[] | null) => {
  const productsSelected: Product[] = []
  if (state.products && state.products.data && productKeys !== null) {
    productKeys.forEach((productKey) => {
      productsSelected.push(state.products.data[productKey])
    })
  }
  return productsSelected
}

const selectOpenAssigneeModal = createSelector(selectProductState, (state) => state.assigneeModalOpen)

const selectTaskLoaderModal = createSelector(selectProductState, (state) => state.taskLoader)

const selectCardImageLoading = createSelector(selectProductState, (state) => state.cardImagesLoading)
const selectCardImageError = createSelector(selectProductState, (state) => state.cardImagesError)
const selectCardImage = createSelector(selectProductState, (state) => uniqBy(state.cardImages, 'url'))

const selectProductPermissions = createSelector(selectProductState, (state) => state.productPermissions)

const selectTransactionRulesDefaultAccessPutting = createSelector(
  selectProductState,
  (state) => state.transactionRulesDefaultAccessPutting
)
const selectTransactionRulesDefaultAccessPuttingSuccess = createSelector(
  selectProductState,
  (state) => state.transactionRulesDefaultAccessPuttingSuccess
)
const selectTransactionRulesDefaultAccessPuttingError = createSelector(
  selectProductState,
  (state) => state.transactionRulesDefaultAccessPuttingError
)

const selectTransactionRuleDeleting = createSelector(selectProductState, (state) => state.transactionRuleDeleting)
const selectTransactionRuleDeletingSuccess = createSelector(
  selectProductState,
  (state) => state.transactionRuleDeletingSuccess
)
const selectTransactionRuleDeletingError = createSelector(
  selectProductState,
  (state) => state.transactionRuleDeletingError
)

const selectPaymentSchedulesDefaultAccessSetting = createSelector(
  selectProductState,
  (state) => state.paymentSchedulesDefaultAccessSetting
)
const selectPaymentSchedulesDefaultAccessSettingSuccess = createSelector(
  selectProductState,
  (state) => state.paymentSchedulesDefaultAccessSettingSuccess
)
const selectPaymentSchedulesDefaultAccessSettingError = createSelector(
  selectProductState,
  (state) => state.paymentSchedulesDefaultAccessSettingError
)
const selectSelectedPaymentSchedules = createSelector(selectProductState, (state) => state.selectedPaymentSchedules)

const selectCreateNewProductVersionLoading = createSelector(
  selectProductState,
  (state) => state.createNewProductVersionLoading
)
const selectCreateNewProductVersionFail = createSelector(
  selectProductState,
  (state) => state.createNewProductVersionFail
)
const selectCreateNewProductSuccess = createSelector(selectProductState, (state) => state.createNewProductSuccess)

export default {
  selectAllProducts,
  selectSelectedProduct,
  selectLoading,
  selectColleagues,
  selectProductsByProductType,
  selectUpdating,
  selectProductByKey,
  selectVersions,
  selectOpenAssigneeModal,
  selectReview,
  selectReviewError,
  selectTaskLoaderModal,
  selectPublishing,
  selectPublishSuccess,
  selectPublishError,
  selectReviewNoUserError,
  selectReviewGeneralError,
  selectProductVersionsLoading,
  selectProductVersionsFail,
  selectCardImageLoading,
  selectCardImageError,
  selectCardImage,
  selectProductPermissions,
  selectError,
  selectDataLoading,
  selectAllProductsOrigin,
  selectTransactionRulesDefaultAccessPutting,
  selectTransactionRulesDefaultAccessPuttingSuccess,
  selectTransactionRulesDefaultAccessPuttingError,
  selectTransactionRuleDeleting,
  selectTransactionRuleDeletingSuccess,
  selectTransactionRuleDeletingError,
  selectPaymentSchedulesDefaultAccessSetting,
  selectPaymentSchedulesDefaultAccessSettingSuccess,
  selectPaymentSchedulesDefaultAccessSettingError,
  selectSelectedPaymentSchedules,
  selectCreateNewProductVersionLoading,
  selectCreateNewProductVersionFail,
  selectCreateNewProductSuccess,
  selectSelectedProductSegment,
  selectProductSyndication,
  selectProductSyndicationLoading,
  selectProductSyndicationError,
}
