import React, { ReactElement } from 'react'
import { Dialog, DialogContent, Fade, Paper, Grid, DialogActions, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

export const Title = styled(Typography)`
  margin-bottom: 14px;
`

export const Description = styled(Typography)`
  white-space: pre;
  margin-bottom: 16px;
`

export const StyledButton = styled(Button)`
  margin-right: 24px;
  margin-bottom: 24px;
  ${({ variant }) => variant === 'contained' && `width: 188px;`}
`

export const DialogContainer = styled.div`
  text-align: center;
  padding-top: 48px;
  padding-bottom: 0;
`

interface Image {
  src: string | ReactElement
  alt: string
}

export interface Button {
  action: () => void
  title: string
}

interface OwnProps {
  open: boolean
  onConfirmClick: Button
  onCancelClick?: () => void
  title: React.ReactNode
  subtitle?: React.ReactNode
  image?: Image
  hideCancelButton?: boolean
  imageStyle?: React.CSSProperties
  cancelText?: string
  id?: string
  titleStyle?: React.CSSProperties
}

const ConfirmModalDeprecated = ({
  image,
  onCancelClick,
  onConfirmClick,
  open,
  subtitle,
  title,
  hideCancelButton,
  imageStyle,
  cancelText = 'Cancel',
  id,
  titleStyle,
}: OwnProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={onCancelClick}
      TransitionComponent={Fade}
      fullWidth
      PaperComponent={Paper}
      maxWidth="sm"
      aria-labelledby={id}
    >
      <DialogContent>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <DialogContainer>
            {image && (
              <Grid item style={imageStyle}>
                {typeof image.src === 'string' ? <img src={image.src} alt={image.alt} aria-hidden="true" /> : image.src}
              </Grid>
            )}
            <Title variant="h5" variantMapping={{ h5: 'div' }} style={titleStyle} id={id}>
              {title}
            </Title>
            {subtitle && (
              <Description variant="body2" variantMapping={{ body2: 'div' }}>
                {subtitle}
              </Description>
            )}
          </DialogContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!hideCancelButton && (
          <StyledButton onClick={onCancelClick} color={ColorType.BUTTON}>
            {t(cancelText)}
          </StyledButton>
        )}
        <StyledButton onClick={onConfirmClick.action} color={ColorType.BUTTON} variant="contained">
          {onConfirmClick.title}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmModalDeprecated
