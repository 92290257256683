import React, { useCallback, useEffect, useState } from 'react'
import Input from '@material-ui/core/Input'
import { connect } from 'formik'
import useDebounce from 'components/useDebounce'

function FastInputField(props: any) {
  const { name, formik, onChange } = props
  const [value, setValue] = useState(props.value)
  const debouncedValue = useDebounce(value, 500)
  const setMemoValue = useCallback(({ target: { value: text } }) => setValue(text), [value])

  useEffect(() => {
    formik.setFieldValue(name, value, true)
    if (onChange) {
      onChange({ target: { value, id: name } })
    }
  }, [debouncedValue])

  useEffect(() => {
    if (props.value !== value && !Number.isNaN(props.value)) {
      setValue(props.value ?? '')
    } else if (!props.value && props.value !== 0) {
      setValue('')
      formik.setFieldTouched(name, false)
    }
  }, [props.value])

  return <Input {...props} value={value} onChange={setMemoValue} />
}

export default connect(FastInputField)
