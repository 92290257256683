export interface ModalState {
  modal: Modal | null
}

export enum ModalType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  PROCESS = 'PROCESS',
  PENDING_ACTION = 'PENDING_ACTION',
  INFOGRAPHIC = 'INFOGRAPHIC',
  FEATURE = 'FEATURE',
}

export enum ModalPendingAction {
  REMOVE = 'REMOVE',
  CREATE = 'CREATE',
  PRODUCT_NEW_CREATE = 'PRODUCT_NEW_CREATE',
}

export interface Modal {
  modalType: ModalType
  helpModalType?: HelpModalType
  featureModalType?: FeatureModalType
  message?: string
  messageLink?: {
    label: string
    url: string
  }
  title?: string
  pendingAction?: ModalPendingAction
  confirmBtnLabel?: string
  confirmNavigationRoute?: string
}

export interface Image {
  src: React.ReactNode
  alt: string
}

export enum FeatureModalType {
  FEES_CONDITIONS_BUILDER = 'FEES_CONDITIONS_BUILDER',
}

export enum HelpModalType {
  PACKAGE = 'package',
  PAYMENT = 'payment',
  SUSPENSE_ACCOUNTS = 'suspence-accounts',
  TRANSACTION_STATUS = 'transaction-status',
  GOVERNANCE = 'governance',
  PRODUCT_LIFECYCLE = 'product-lifecycle',
  PRODUCT_VERSIONING = 'product-versioning',
  INTEREST_RATE_OPTION = 'interest-rate-option',
  INDEX_RATES = 'index-rates',
  SIDE_PANEL = 'side-panel',
  USERS = 'users',
  STATUS_TABLE = 'status-table',
  INTEREST_RATES = 'interest-rates',
  REFERENCE_DATA = 'reference-data',
  DEBIT_INTEREST = 'debit-interest',
  PRODUCTS = 'products',
  AMOUNT_PAYMENT_SCHEDULE = 'amount-payment-schedule',
  PAYMENT_LIMITS = 'payment-limits',
  CARD_LIMITS = 'card-limits',
  CREDIT_LIMIT = 'credit-limit',
  INTEREST_STATEMENTS = 'interest-statements',
  INTEREST_STATEMENTS_PRODUCT = 'interest-statements-product',
  MULTI_PARTY = 'multi-party',
  PRODUCT_SYNDICATION = 'product-syndication',
  FEES = 'fees',
  REWARDS = 'rewards',
}

export type MoreAboutModalType =
  | 'packages'
  | 'governance'
  | 'indexRate'
  | 'withholdTax'
  | 'tax-rate-options'
  | 'interest-statements'
  | 'product-syndication'
  | ''
