import React from 'react'
import styled from 'styled-components/macro'
import { Grid, Divider } from '@material-ui/core'

export const CardGridContainerItem = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}) => (
  <Grid container style={{ flexGrow: 1, ...style }}>
    {children}
  </Grid>
)

export const StyledDivider = styled(Divider)`
  margin: 16px 0px 24px 0px;
`

export const TemplateImage = styled.img`
  border: 1px solid ${({ theme }) => theme.palette.background.dark};
`
