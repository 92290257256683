import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { LendingRate, LendingRateType } from 'store/products/types'
import { ContextualHelp, FeatureDefinitions, H5, Subtitle1 } from 'dls'
import getRateBandLabelKey from 'utils/getRateBandLabelKey'
import { DefinitionRowItem } from 'dls/molecules/FeatureDefinitions/types'

interface OwnProps {
  rate: LendingRate
  rateType: LendingRateType
  indexRateTypeText: string
  rateDefinitions: DefinitionRowItem[]
  position: 'productPage' | 'featurePage'
}

const InterestRateDefinition = ({
  rate: { calculationMethod },
  rateType,
  indexRateTypeText,
  rateDefinitions,
  position,
}: OwnProps) => {
  const { t } = useTranslation()

  const definitions = [
    { term: t('Type of interest rate'), definition: [indexRateTypeText] },
    {
      term: t('How interest rate is applied'),
      definition: [calculationMethod && t(getRateBandLabelKey(calculationMethod))],
    },
  ]

  return (
    <>
      <Box mb={3} mt={position === 'productPage' ? 2 : 0} display="flex" flexDirection="row">
        <H5 variantMapping={{ h5: 'h2' }}>{t(`DEBIT_INTEREST.${rateType}`)}</H5>
        {position === 'featurePage' && (
          <Subtitle1>
            <ContextualHelp
              placement="top"
              style={{ verticalAlign: 'middle', marginLeft: '8', marginTop: '-2' }}
              title={`${t(`DEBIT_INTEREST.${rateType}_help`)}`}
            />
          </Subtitle1>
        )}
      </Box>
      <FeatureDefinitions definitions={[...definitions, ...rateDefinitions]} />
    </>
  )
}
export default InterestRateDefinition
