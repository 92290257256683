import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { NotificationState, NotificationTypes, MultipleNotification } from './types'

const empty: MultipleNotification = { message: '', type: NotificationTypes.SUCCESS, key: 1 }
const initialState = {
  data: {
    open: false,
    notificationType: NotificationTypes.SUCCESS,
    notificationMessage: '',
    isMultipleMessage: false,
    notification: empty,
  },
}
const reducer = createReducer<NotificationState>(initialState, {
  [actions.closeNotification.type]: (state, action) => ({
    ...state,
    data: {
      notificationType: action.payload,
      open: false,
      isMultipleMessage: false,
      notificationMessage: '',
      notification: empty,
    },
  }),
  [actions.deleteFeatureSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      isMultipleMessage: false,
      notificationMessage: 'Feature successfully deleted.',
      notification: empty,
    },
  }),
  [actions.deleteFeatureFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      isMultipleMessage: false,
      notificationMessage: "Feature couldn't be deleted. Please try again",
      notification: empty,
    },
  }),
  [actions.createProductSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      isMultipleMessage: false,
      notificationMessage: 'Product successfully created.',
      notification: empty,
    },
  }),
  [actions.createProductFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      isMultipleMessage: false,
      notificationMessage: "Product couldn't be created. Please try again.",
      notification: empty,
    },
  }),
  [actions.createUserSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      isMultipleMessage: false,
      notificationMessage: 'User successfully created.',
      notification: empty,
    },
  }),
  [actions.createUserFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      isMultipleMessage: false,
      notificationMessage: "User couldn't be created. Please try again.",
      notification: empty,
    },
  }),
  [actions.editProcessSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      notificationMessage: 'Governance process successfully updated.',
      isMultipleMessage: false,
      notification: empty,
    },
  }),
  [actions.editProcessFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      notificationMessage: "Governance process couldn't be updated. Please try again.",
      isMultipleMessage: false,
      notification: empty,
    },
  }),

  [actions.createTaskSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      notificationMessage: 'Task successfully Created.',
      isMultipleMessage: false,
      notification: empty,
    },
  }),
  [actions.createTaskFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      notificationMessage: "Task couldn't be Created. Please try again.",
      isMultipleMessage: false,
      notification: empty,
    },
  }),

  [actions.updateTaskSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      notificationMessage: 'Task successfully updated.',
      isMultipleMessage: false,
      notification: empty,
    },
  }),
  [actions.updateTaskFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      notificationMessage: "Task couldn't be updated. Please try again.",
      isMultipleMessage: false,
      notification: empty,
    },
  }),
  [actions.deleteProductSuccess.type]: (state, action) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      isMultipleMessage: false,
      notificationMessage: `${action.payload} successfully deleted.`,
      notification: empty,
    },
  }),
  [actions.deleteProductFail.type]: (state, action) => ({
    ...state,
    error: action.payload,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      isMultipleMessage: false,
      notificationMessage: `${action.payload} couldn't be deleted. Please try again.`,
      notification: empty,
    },
  }),

  [actions.retireProductSuccess.type]: (state, action) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      isMultipleMessage: false,
      notificationMessage: `${action.payload} successfully retired.`,
      notification: empty,
    },
  }),
  [actions.retireProductFail.type]: (state, action) => ({
    ...state,
    error: action.payload,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      isMultipleMessage: false,
      notificationMessage: `${action.payload} couldn't be retired, only live products can be retired.`,
      notification: empty,
    },
  }),
  [actions.assignTaskSuccess.type]: (state, action) => {
    const multipleNotification = {
      message: `${action.payload.taskName} assigned to ${action.payload.assigneeName}`,
      type: NotificationTypes.SUCCESS,
    }
    return {
      ...state,
      data: {
        notificationType: NotificationTypes.SUCCESS,
        open: false,
        isMultipleMessage: true,
        notificationMessage: `${action.payload.taskName} assigned to ${action.payload.assigneeName}`,
        notification: multipleNotification,
      },
    }
  },
  [actions.assignTaskFail.type]: (state, action) => {
    const multipleNotification = {
      message: `${action.payload.taskName} assigned to ${action.payload.assigneeName}`,
      type: NotificationTypes.ERROR,
    }
    return {
      ...state,
      data: {
        notificationType: NotificationTypes.ERROR,
        open: false,
        isMultipleMessage: true,
        notificationMessage: `Failed to assign a task${action.payload.assigneName}`,
        notification: multipleNotification,
      },
    }
  },
  [actions.assignTasksSuccess.type]: (state) => {
    const multipleNotification = {
      message: `The tasks assigned to assigners successfully`,
      type: NotificationTypes.SUCCESS,
    }
    return {
      ...state,
      data: {
        notificationType: NotificationTypes.SUCCESS,
        open: false,
        isMultipleMessage: true,
        notificationMessage: `The tasks assigned to assigners successfully`,
        notification: multipleNotification,
      },
    }
  },
  [actions.assignTasksFail.type]: (state) => {
    const multipleNotification = {
      message: `Failed to assigned tasks to assigners`,
      type: NotificationTypes.ERROR,
    }
    return {
      ...state,
      data: {
        notificationType: NotificationTypes.ERROR,
        open: false,
        isMultipleMessage: true,
        notificationMessage: `Failed to assigned tasks to assigners`,
        notification: multipleNotification,
      },
    }
  },

  [actions.sendProductToReviewSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      isMultipleMessage: false,
      notificationMessage: 'Product design completed',
      notification: empty,
    },
  }),

  [actions.sendProductToReviewFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      isMultipleMessage: false,
      notificationMessage: "Product design couldn't be completed",
      notification: empty,
    },
  }),
  [actions.createTeamSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      notificationMessage: 'Team successfully created.',
      notification: empty,
      isMultipleMessage: false,
    },
  }),
  [actions.createTeamFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      notificationMessage: "Team couldn't be created. Please try again.",
      notification: empty,
      isMultipleMessage: false,
    },
  }),
  [actions.getSuspenseTransactionsSuccess.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.SUCCESS,
      open: true,
      isMultipleMessage: false,
      notificationMessage: `Transactions loaded.`,
      notification: empty,
    },
  }),
  [actions.getSuspenseTransactionsFail.type]: (state) => ({
    ...state,
    data: {
      notificationType: NotificationTypes.ERROR,
      open: true,
      isMultipleMessage: false,
      notificationMessage: `Sorry, more couldn't be loaded.`,
      notification: empty,
    },
  }),
})

export default reducer
