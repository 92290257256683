import React from 'react'
import InputMui, { InputProps } from '@material-ui/core/Input'
import styled from 'styled-components/macro'
import InputAdornment from '@material-ui/core/InputAdornment'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { omit } from 'lodash'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { KeyCode } from 'dls/shared/types'

export interface AFieldProps extends InputProps {
  id: string
  numberFormatProps?: NumberFormatProps
  positiveIntegersOnly?: boolean
  currencyCode?: string | null
  hideCurrencySymbol?: boolean
  ariaProps?: Record<string, string | boolean>
}

const StyledInputMui = styled(InputMui)`
  & input:focus {
    color: ${(props: any) => `${props.theme.palette.secondary.main}`};
  }
  ${(props: any) =>
    props.disabled &&
    `
    background: ${props.theme.palette.action.disabledBackground};
    color: ${props.theme.palette.action.disabled};

  `}
`
export const NUMBER_FORMAT_PREFIX = 'number:'

export function NumberFormatCustom(props: {
  inputRef: (instance: NumberFormat | null) => void
  valueType: 'formattedValue' | 'floatValue' | 'value'
  inputProps?: NumberFormatProps
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
}) {
  const { inputRef, onChange, inputProps = {}, ...other } = props
  return (
    <NumberFormat
      {...omit(other, ['onChange'])}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: { name: props.name, value: `${NUMBER_FORMAT_PREFIX}${values[inputProps.valueType || 'value']}` },
        } as React.ChangeEvent<HTMLInputElement>)
      }}
      {...omit(inputProps, ['css', 'valueType'])}
      {...omit(other, ['valueType'])}
    />
  )
}

const Field = React.forwardRef(
  (
    {
      numberFormatProps,
      positiveIntegersOnly,
      currencyCode,
      hideCurrencySymbol,
      type: origType = '',
      ariaProps,
      ...props
    }: AFieldProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const isNumber = ['currency', 'number', 'percentage'].includes(origType) || positiveIntegersOnly
    return (
      <StyledInputMui
        data-dls="atom-input"
        color="secondary"
        aria-describedby={`${props.name}-helper-text`}
        inputRef={ref}
        {...ariaProps}
        {...(positiveIntegersOnly
          ? { onKeyDown: (e: React.KeyboardEvent) => [KeyCode.COMMA].includes(e.keyCode) && e.preventDefault() }
          : {})}
        {...(isNumber
          ? {
              inputProps: {
                inputProps: {
                  ...numberFormatProps,
                  valueType: 'value',
                },
                step: ['percentage'].includes(origType) ? '0.1' : 'any',
              },
              startAdornment:
                props.startAdornment ||
                (!hideCurrencySymbol && origType === 'currency' && (
                  <InputAdornment position="start" className="inputAdornment">
                    <CurrencySymbol currencyCode={currencyCode} />
                  </InputAdornment>
                )),
              endAdornment:
                props.endAdornment ||
                (['percentage'].includes(origType) ? <InputAdornment position="end">%</InputAdornment> : null),
              inputComponent: NumberFormatCustom as React.FunctionComponent,
            }
          : null)}
        {...omit(props, ['valueType'])}
      />
    )
  }
)

export default Field
