import React from 'react'
import { DefinitionRowItem } from './types'
import FeatureDefinitionRow from './FeatureDefinitionRow'
import { FeatureDefinitionList } from './styles'

interface OwnProps {
  definitions: DefinitionRowItem[]
  ariaLabelledBy?: string
}

const FeatureDefinitions = ({ definitions, ariaLabelledBy }: OwnProps) => (
  <>
    {definitions.length > 0 && (
      <FeatureDefinitionList
        {...(ariaLabelledBy && { 'aria-labelledby': ariaLabelledBy })}
        data-dls="molecules-feature-definition-list"
      >
        {definitions.map(({ term, definition, hasSectionSeparator }) => (
          <FeatureDefinitionRow
            key={term}
            term={term}
            definition={definition}
            hasSectionSeparator={hasSectionSeparator}
          />
        ))}
      </FeatureDefinitionList>
    )}
  </>
)

export default FeatureDefinitions
