import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import useDebitInterest from '../utils/useDebitInterest'
import SetupForm from './components/forms/SetupForm'
import { DebitInterestFormValues, LendingRateType } from 'store/products/types'
import useUpdateBreadcrumbs from 'pages/Product/hooks/useUpdateBreadcrumbs'
import useSelectedProduct from 'pages/Product/hooks/useSelectedProduct'
import { Loader } from 'components'

const SetupDebitInterestPage = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { productKey = '' } = useParams()
  const [product, isLoading] = useSelectedProduct(productKey)

  const { submitDebitInterestSetup, isNotAllowed, isLending } = useDebitInterest({
    productKey,
    version: product?.version || 1,
    productLine: product?.productLine as any,
  })

  const [rateType, setRateType] = useState<LendingRateType | undefined>(undefined)

  useUpdateBreadcrumbs({ productKey, context: t('Debit interest setup') })

  if (isLoading || !product) {
    return <Loader />
  }

  if (isNotAllowed) navigate(`./../not-allowed`)

  const selectedRateType = isLending ? LendingRateType.rateWithinLimit : rateType

  return (
    <SetupForm
      product={product}
      productKey={productKey}
      initialValues={{}}
      onSubmit={(formValues: DebitInterestFormValues) =>
        selectedRateType && submitDebitInterestSetup(formValues, selectedRateType)
      }
      rateType={selectedRateType}
      setRateType={setRateType}
      isLending={isLending}
      t={t}
    />
  )
}

export default SetupDebitInterestPage
