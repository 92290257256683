import React from 'react'
import { useTranslation } from 'react-i18next'
import { EntityBadgeHistory } from 'dls/atoms'
import EntitySectionCard from 'pages/Layout/EntityReviewPageLayout/EntitySectionCard'
import { toBankTz } from 'utils/date.utils'
import { InterestRate } from 'store/referenceData/types'
import { RateMultiplyByHundred } from 'utils/form.utils'
import EntityCardFooterLink from 'pages/ReferenceData/_shared/EntityCardFooterLink'
import { Body1, ReviewRows } from 'dls'
import { HistoryIcon } from 'dls/atoms/Icons/MUIIcons'
import { Separator } from 'dls/molecules/ReviewRows/types'

interface Props {
  historicRates: InterestRate[]
  rateId: string
}

const HistoricInterestRateSectionCard = ({ historicRates, rateId }: Props) => {
  const { t } = useTranslation()
  const historicRatesDefinitions = historicRates.map((rate, index) => ({
    groupSeparator: index < historicRates.length - 1 ? ('light' as Separator) : undefined,
    definitions: [
      { term: t('Rate'), definitions: [`${RateMultiplyByHundred(rate.indexRate || 0)}%`] },
      { term: t('Effective date'), definitions: [toBankTz(rate?.effectiveDate?.toString()).date] },
    ],
  }))
  return (
    <EntitySectionCard
      headline="Historic rates"
      title={
        <Body1>{t(`${historicRates.length || 'No'} historic rate${historicRates.length !== 1 ? 's' : ''}`)}</Body1>
      }
      headerIcon={<EntityBadgeHistory />}
      fixedBottomContent={
        historicRates.length ? (
          <EntityCardFooterLink
            Icon={HistoryIcon}
            label="view timeline"
            href={`/reference-data/index-rates/${rateId}/history`}
          />
        ) : undefined
      }
      accordionDisabled={!historicRates.length}
      scrollEnabled
    >
      <ReviewRows definitionsList={historicRatesDefinitions} />
    </EntitySectionCard>
  )
}

export default HistoricInterestRateSectionCard
