import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import { browserHistory } from 'store/router/history'
import { store, persistor } from 'store'
import { Loader } from 'components'

interface ReduxProviderProps {
  children: React.ReactNode
  storybook?: boolean
}

const basename: string = new URL(document.baseURI).pathname

const ReduxProvider = ({ children, storybook = false }: ReduxProviderProps) => (
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      {storybook ? (
        children
      ) : (
        <ReduxRouter history={browserHistory} store={store} basename={basename}>
          {children}
        </ReduxRouter>
      )}
    </PersistGate>
  </Provider>
)

export default ReduxProvider
