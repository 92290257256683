import { v4 } from 'uuid'
import createAsyncAuthAwareAxios from '../client/authAwareAxios'
import { StatementConfiguration } from 'store/templates/statementConfiguration/types'

async function getStatementConfigurations() {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).get<{
    statementConfigs: StatementConfiguration[]
  }>(`/v1/statement-configs`)
  return data
}

async function createStatementConfiguration(statementConfiguration: StatementConfiguration) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).post<StatementConfiguration>(
    '/v1/statement-configs',
    statementConfiguration,
    {
      headers: {
        'Idempotency-Key': v4(),
      },
    }
  )
  return data
}

async function updateStatementConfiguration(statementConfigId: string, statementConfiguration: StatementConfiguration) {
  const { data } = await (await createAsyncAuthAwareAxios(null, false)).put<StatementConfiguration>(
    `/v1/statement-configs/${statementConfigId}`,
    statementConfiguration,
    {
      headers: {
        'Idempotency-Key': v4(),
      },
    }
  )
  return data
}

export default { getStatementConfigurations, createStatementConfiguration, updateStatementConfiguration }
