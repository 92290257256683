import React from 'react'
import { Avatar, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import isEmpty from 'lodash/isEmpty'
import StatusBadge from '../StatusBadge/StatusBadge'
import { Colleague } from 'store/user/types'
import { getInitials } from 'utils/ui.utils'
import { Task } from 'store/tasks/types'
import { TaskStatus } from 'store/tasks/typings/taskStatus'
import { ProductStatus } from 'store/products/typings/productStatus'
import { useUserNameWithRole } from 'utils/common.util'

interface OwnProps {
  assignee?: Colleague
  task: Task
}

const StatusDiv = styled.div`
  margin-right: 24px;
`
const StyledTypography = styled(Typography)`
  font-weight: 500;
  margin-top: 8px;
`
const StyledDiv = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: 16px;
`
const TypographyHeader = styled(Typography)`
  display: block;
  margin-bottom: 2px;
  line-height: 1.5;
`

const StyledAvatar = styled(Avatar)`
  background-color: ${(props: any) => `${props.theme.palette.divider}`};
  color: ${(props: any) => `${props.theme.palette.text.primary}`};
  font-size: 1rem;
`

const AssigneeBox = ({ assignee, task }: OwnProps) => {
  const getUserNameWithRole = useUserNameWithRole()
  if (!assignee || isEmpty(assignee) || !task) return <></>
  return (
    <StyledDiv>
      <Grid container direction="row" alignItems="stretch" justifyContent="space-between">
        <Grid item>
          <TypographyHeader variant="subtitle1" variantMapping={{ subtitle1: 'div' }}>
            {task.name}
          </TypographyHeader>
        </Grid>
        <Grid item>
          <StatusDiv>
            <StatusBadge
              status={
                [TaskStatus.CLOSED, TaskStatus.REJECTED].includes(task.status)
                  ? ProductStatus.APPROVED
                  : ProductStatus.REVIEW
              }
            />
          </StatusDiv>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="stretch" spacing={1}>
        <Grid item>
          <StyledAvatar alt="assignee">
            {getInitials(`${assignee.profile.firstName} ${assignee.profile.lastName}`)}
          </StyledAvatar>
        </Grid>
        <Grid item>
          <StyledTypography variant="subtitle2">{getUserNameWithRole(assignee.profile)}</StyledTypography>
        </Grid>
      </Grid>
    </StyledDiv>
  )
}

export default AssigneeBox
