import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { EntityBadgeColleague } from 'dls/atoms'
import { InfoPanel, CardHeader, H6, Body1, Paper } from 'dls'

interface OwnProps {
  inline?: boolean
}

const ColleagueOutBoundLimits = ({ inline = false }: OwnProps) => {
  const { t } = useTranslation()

  const Wrapper = inline ? React.Fragment : Paper

  return (
    <Wrapper>
      <Box mb={3}>
        <CardHeader
          title={
            inline ? (
              <H6 variantMapping={{ h6: 'h5' }} style={{ fontWeight: 'bold' }}>
                {t('Colleague outbound limits')}
              </H6>
            ) : (
              <Body1 variantMapping={{ body1: 'h3' }}>{t('Colleague outbound limits')}</Body1>
            )
          }
          headerIcon={<EntityBadgeColleague />}
        />
      </Box>
      <InfoPanel
        color="info"
        body={t(
          'Colleague limits are managed in your CRM tool and do not contribute towards cumulative utilisation of limits set in Bank Manager'
        )}
      />
    </Wrapper>
  )
}

export default ColleagueOutBoundLimits
