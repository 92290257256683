import { useEffect, useState } from 'react'
import { StatementApi } from 'api'
import { ContentType } from 'store/statement/types'
import { ProductSegment, StatementType } from 'store/products/types'

const useTemplateImage = (
  productSegment: ProductSegment,
  templateType: StatementType,
  templateGroupId: string,
  contentType: ContentType = ContentType.PNG
) => {
  const [src, setSrc] = useState<string | undefined>(undefined)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function getSrcData() {
      try {
        const srcRes = await StatementApi.getSamples({
          productSegment,
          templateType,
          templateGroupId,
          contentType,
        })
        setError(false)
        setSrc(srcRes)
      } catch (e: any) {
        setSrc(undefined)
        setError(true)
      }
    }
    getSrcData()
  }, [templateGroupId])

  return { src, error }
}

export default useTemplateImage
