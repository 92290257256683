import styled from 'styled-components/macro'
import { Paper, Button } from 'dls/atoms'

export const CardActions = styled.div<{ $isTouchDevice?: boolean }>`
  position: absolute;
  bottom: 12px;
  right: 16px;
  ${({ $isTouchDevice }) => `
    display: ${$isTouchDevice ? 'block' : 'none'}
  `}
`

export const TeaserPaper = styled(Paper)<{ $zeroState?: boolean }>`
  min-width: 207px;
  max-width: 568px;
  position: relative;
  height: 15rem;
  word-break: break-word;
  ${({ $zeroState, theme }) => `
    box-shadow: 0px 1px 3px ${theme.palette.text.disabled};
    ${$zeroState ? `border-style: dashed; background: transparent; border-color: ${theme.palette.text.disabled};` : ''}
    &:hover, &:focus, &:focus-within {
      outline: none;
      ${CardActions} {
        display: block;
      }
    }
    &:focus {
      border-color: ${theme.palette.secondary.main};
    }
    &:hover {
      background-color: ${theme.palette.background.paper};
      box-shadow: ${theme.palette.text.disabled} 0px 2px 5px -3px,  ${theme.palette.text.disabled} 0px 2px 10px 1px, ${
    theme.palette.text.disabled
  } 0px 2px 14px 2px;
    }
  `}
`

export const AddButton = styled(Button)`
  margin-left: 12px;
`

export const CardContent = styled.div<{ disabled: boolean }>`
  margin-top: 16px;
  max-height: 99px;

  ${({ disabled, theme }) => `
    ${disabled ? `color: ${theme.palette.text.secondary}` : ''}
    `}
`
