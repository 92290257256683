import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import ViewFeesRewards from 'pages/features/FeesRewards/view/productPage/ViewFeesRewards'
import ViewDebitInterest from 'pages/features/DebitInterest/view/productPage/ViewDebitInterest'
import ViewPaymentLimits from 'pages/features/PaymentLimits/components/view/productPage/ViewPaymentLimits'
import ViewCardTransactionLimits from 'pages/features/CardTransactionLimits/components/view/productPage/ViewCardTransactionLimits'
import ViewPaymentSchedule from 'pages/features/PaymentSchedule/components/view/ViewPaymentSchedule'
import ViewCreditInterest from 'pages/features/CreditInterest/ViewCreditInterest/ViewCreditInterest'
import ViewCards from 'pages/features/Cards/ViewCards'
import ViewRequiredExternalId from 'pages/features/RequiredExternalId/ViewRequiredExternalId'
import ViewEligibility from 'pages/features/Eligibility/ViewEligibility'
import ViewLimits from 'pages/features/Limits/ViewLimits'
import ViewTermsAndConditions from 'pages/features/TermsAndConditions/ViewTermsAndConditions'
import ViewDocument from 'pages/features/Documents/ViewDocument'
import ViewSubscriptionRule from 'pages/features/SubscriptionCreationRules/ViewSubscriptionRule'
import ViewStatement from 'pages/features/Statements/ViewStatement'
import ViewProductCodes from 'pages/features/ProductCodes/ViewProductCodes'
import ViewProductCore from 'pages/Products/CreateProductDialog/ProductCore/ViewProductCore'
import ViewCurrencies from 'pages/features/Currency/ViewCurrencies'
import { RootState } from 'store'
import ProductsSelectors from 'store/products/selectors'
import { DeleteFeaturePayload, ExtendedProduct, Feature } from 'store/products/types'
import { selectUserInformation } from 'store/user/selectors'
import TasksSelectors from 'store/tasks/selectors'
import GovernanceSelectors from 'store/governance/selectors'
import { selectConfig } from 'store/tenant-config/selectors'
import { Colleague, UserInformation } from 'store/user/types'
import { Task } from 'store/tasks/types'
import ReferenceDataSelectors from 'store/referenceData/selectors'
import TasksActions from 'store/tasks/actions'
import TaxRulesSelectors from 'store/withholdingTax/selectors'
import { FeaturePaper } from 'pages/Product/ProductFeatureViewMode/FeaturePaper'
import ViewTransactionRules from 'pages/features/TransactionRules/ViewTransactionRules'
import ViewMultiParty from 'pages/features/TransactionRules/ViewMultiParty'
import { isProductInDesign } from 'utils/productVersion.utils'
import ViewCreditLimit from 'pages/features/CreditLimit/ViewCreditLimit'
import Loader from 'dls/atoms/Loader'
import { FeatureProductSyndication } from 'pages/features/ProductSyndication/components/view/productPage/FeatureProductSyndication'

interface OwnProps {
  product: ExtendedProduct
  isOldVersion?: boolean
  featureName: string
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  featureAssigneeData: Colleague | undefined
  featureTaskData: Task | undefined
  currencyOfProduct?: string | null
  deleteFeature(deleteFeaturePayload: DeleteFeaturePayload): void
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  taskActionResultClear: () => dispatch(TasksActions.taskActionResultClear()),
})

export const mapStateToProductProps = (state: RootState) => ({
  userInformation: selectUserInformation(state) as UserInformation,
  tasks: TasksSelectors.selectTasksForProduct(state),
  isTasksLoading: TasksSelectors.selectIsLoading(state),
  isGovernanceLoading: GovernanceSelectors.selectIsLoading(state),
  selectActiveprocessExecutions: GovernanceSelectors.selectActiveprocessExecutions(state),
  tenantConfig: selectConfig(state),
  assignees: ProductsSelectors.selectColleagues(state),
  bankInterestRates: ReferenceDataSelectors.selectAllBankInterestRate(state),
  selectTaskActionResult: TasksSelectors.selectTaskActionResult(state),
  taxRules: TaxRulesSelectors.taxRules(state),
  loading: ProductsSelectors.selectLoading(state),
})

export type ProductFeatureViewModeProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProductProps> &
  OwnProps

const ProductFeatureViewMode = (props: ProductFeatureViewModeProps): React.ReactElement | null => {
  const {
    product: {
      currency,
      cards,
      requiredExternalId,
      creditInterest,
      fees,
      rewards,
      productKey,
      debitInterest,
      eligibility,
      limits,
      termsAndConditions,
      documents,
      subscriptionCreationRule,
      statements,
      identification,
      transactionRules,
      paymentLimits,
      cardLimits,
      minMaxCreditLimit,
      multiParty,
    },
    isTasksLoading,
    isGovernanceLoading,
    loading,
    featureName,
  } = props

  const { t } = useTranslation()
  const { isCreditInterestEnabled, isDebitInterestEnabled } = useSelector(ProductsSelectors.selectProductPermissions)

  if (isTasksLoading || isGovernanceLoading || loading) {
    return <Loader />
  }

  switch (featureName) {
    case Feature.PRODUCTCORE:
      return (
        <FeaturePaper {...props} featureTitle={t('Product details')} featureID="productCore">
          <ViewProductCore product={props.product} />
        </FeaturePaper>
      )
    case Feature.CARDS:
      return (
        <FeaturePaper {...props} featureTitle={t('Cards')} featureID="cards">
          <ViewCards cards={cards} />
        </FeaturePaper>
      )
    case Feature.CREDITINTEREST:
      if (creditInterest) {
        return (
          <FeaturePaper
            {...props}
            featureTitle={t('Credit interest')}
            featureID="creditInterest"
            isCreditInterestEnabled={isCreditInterestEnabled}
          >
            <ViewCreditInterest
              creditInterest={creditInterest}
              currencyOfProduct={props.currencyOfProduct}
              bankIndexRates={props.bankInterestRates}
            />
          </FeaturePaper>
        )
      }

      return null
    case Feature.DEBIT_INTEREST:
      if (debitInterest) {
        return (
          <FeaturePaper
            {...props}
            isDebitInterestEnabled={isDebitInterestEnabled}
            featureTitle={t('Debit interest')}
            featureID="debitInterest"
          >
            <ViewDebitInterest
              product={props.product}
              debitInterest={{
                ...debitInterest,
                ...(!isDebitInterestEnabled && { previousVersionHasDebitInterest: !isDebitInterestEnabled }),
              }}
              isLending={false}
            />
          </FeaturePaper>
        )
      }

      return null
    case Feature.CURRENCY:
      return (
        <FeaturePaper {...props} featureTitle={t('Currency')} featureID="currency">
          <ViewCurrencies currency={currency} />
        </FeaturePaper>
      )
    case Feature.DOCUMENTS:
      return (
        <FeaturePaper {...props} featureTitle={t('Documents')} featureID="documents">
          <ViewDocument document={documents} />
        </FeaturePaper>
      )
    case Feature.ELIGIBILITY:
      if (eligibility) {
        return (
          <FeaturePaper {...props} featureTitle={t('Eligibility')} featureID="eligibility">
            <ViewEligibility eligibility={eligibility} currencyOfProduct={props.currencyOfProduct} />
          </FeaturePaper>
        )
      }

      return null
    case Feature.LIMITS:
      if (limits) {
        return (
          <FeaturePaper {...props} featureTitle={t('Limits')} featureID="limits">
            <ViewLimits limits={limits} currencyOfProduct={props.currencyOfProduct} />
          </FeaturePaper>
        )
      }

      return null
    case Feature.REQUIREDEXTERNALID:
      if (requiredExternalId) {
        return (
          <FeaturePaper {...props} featureTitle={t('External identifiers')} featureID="requiredExternalId">
            <ViewRequiredExternalId externalId={requiredExternalId} />
          </FeaturePaper>
        )
      }

      return null
    case Feature.STATEMENTS:
      return (
        <FeaturePaper {...props} featureTitle={t('Statements')} featureID="statements">
          <ViewStatement statement={statements} product={props.product} />
        </FeaturePaper>
      )
    case Feature.MULTI_PARTY:
      if (multiParty) {
        return (
          <FeaturePaper {...props} featureTitle={t('Multi-party')} featureID="multiParty">
            <ViewMultiParty multiParty={multiParty} />
          </FeaturePaper>
        )
      }

      return null
    case Feature.SUBSCRIPTIONCREATIONRULE:
      return (
        <FeaturePaper {...props} featureTitle={t('Subscription creation rules')} featureID="subscriptionCreationRule">
          <ViewSubscriptionRule subscriptionCreationRule={subscriptionCreationRule} />
        </FeaturePaper>
      )

    case Feature.PRODUCT_SYNDICATION:
      return <FeatureProductSyndication {...props} productKey={productKey} version={props.product.version} />

    case Feature.TERMSANDCONDITIONS:
      return (
        <FeaturePaper {...props} featureTitle={t('Terms and conditions')} featureID="termsAndConditions">
          <ViewTermsAndConditions termsAndConditions={termsAndConditions} />
        </FeaturePaper>
      )
    case Feature.IDENTIFICATION:
      return (
        <FeaturePaper {...props} featureTitle={t('identification')} featureID="identification">
          <ViewProductCodes {...identification} />
        </FeaturePaper>
      )
    case Feature.TRANSACTIONRULES:
      if (transactionRules) {
        return (
          <FeaturePaper {...props} featureTitle={t('transactionRules')} featureID="transactionRules" hideBottomBar>
            <ViewTransactionRules
              transactionRules={transactionRules}
              editable={isProductInDesign(props.product.status)}
            />
          </FeaturePaper>
        )
      }

      return null
    case Feature.PAYMENT_LIMITS:
      if (paymentLimits) {
        return (
          <FeaturePaper {...props} featureTitle={t('paymentLimits')} featureID="paymentLimits">
            <ViewPaymentLimits paymentLimits={paymentLimits} currencyCode={props.currencyOfProduct ?? ''} />
          </FeaturePaper>
        )
      }

      return null
    case Feature.CARD_TRANSACTION_LIMITS:
      if (cardLimits) {
        return (
          <FeaturePaper {...props} featureTitle={t('cardLimits')} featureID="cardLimits">
            <ViewCardTransactionLimits
              cardTransactionLimits={cardLimits}
              currencyCode={props.currencyOfProduct ?? ''}
              productKey={props?.product?.productKey}
            />
          </FeaturePaper>
        )
      }

      return null
    case Feature.PAYMENT_SCHEDULE:
      return (
        <FeaturePaper {...props} featureTitle={t('paymentSchedule')} featureID="paymentSchedule">
          <ViewPaymentSchedule product={props.product} />
        </FeaturePaper>
      )
    case Feature.FEES:
      if (fees) {
        return (
          <FeaturePaper {...props} featureTitle={t('fees')} featureID="fees">
            <ViewFeesRewards
              featureType={Feature.FEES}
              entities={fees.fees}
              currencyCode={props.currencyOfProduct || ''}
            />
          </FeaturePaper>
        )
      }

      return null
    case Feature.REWARDS:
      if (rewards) {
        return (
          <FeaturePaper {...props} featureTitle={t('Rewards')} featureID="rewards">
            <ViewFeesRewards
              featureType={Feature.REWARDS}
              entities={rewards.rewards}
              currencyCode={props.currencyOfProduct || ''}
            />
          </FeaturePaper>
        )
      }

      return null
    case Feature.CREDIT_LIMIT:
      if (minMaxCreditLimit?.minMaxCreditLimit) {
        return (
          <FeaturePaper {...props} featureTitle={t('minMaxCreditLimit')} featureID="minMaxCreditLimit">
            <ViewCreditLimit creditLimit={minMaxCreditLimit} currencyCode={props.currencyOfProduct || ''} />
          </FeaturePaper>
        )
      }

      return null
    default:
      return null
  }
}
export default connect(mapStateToProductProps, mapDispatchToProps)(ProductFeatureViewMode)
