import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import { generateMonth } from './form.utils'
import { Frequency, FrequencyTypeValue } from 'store/products/types'

export const generateFrequencyValue = (frequencyValues: Frequency) => {
  if (frequencyValues) {
    switch (frequencyValues.frequencyType) {
      case FrequencyTypeValue.ANNUAL_ANNIVERSARY_OF_ACCOUNT:
        return ['VIEW_FREQUENCY.ANNUAL_ANNIVERSARY_OF_ACCOUNT', '']
      case FrequencyTypeValue.ANNUAL:
        return [
          'VIEW_FREQUENCY.ANNUAL',
          {
            param1: frequencyValues.month,
            param2: frequencyValues.day,
          },
        ]
      case FrequencyTypeValue.MONTH_END:
        return ['VIEW_FREQUENCY.MONTH_END', '']
      case FrequencyTypeValue.MONTH_BEGINNING:
        return ['VIEW_FREQUENCY.MONTH_BEGINNING', '']
      case FrequencyTypeValue.MONTH:
        return ['VIEW_FREQUENCY.MONTH', { param1: frequencyValues.day }]
      case FrequencyTypeValue.MONTHLY_ANNIVERSARY_OF_ACCOUNT:
        return ['VIEW_FREQUENCY.MONTH_ANNIVERSARY', '']
      case FrequencyTypeValue.WEEKLY:
        return [`VIEW_FREQUENCY.WEEKLY_${frequencyValues.dayOfWeek}`, '']
      case FrequencyTypeValue.DAYS_FROM_CALCULATION:
        return ['VIEW_FREQUENCY.DAYS_FROM_CALCULATION', { param1: frequencyValues.day }]
      case FrequencyTypeValue.DAILY:
        return ['VIEW_FREQUENCY.DAILY', '']
      case FrequencyTypeValue.TRIGGERED_DAILY:
        return ['VIEW_FREQUENCY.DAILY', '']
      case FrequencyTypeValue.PER_OCCURRENCE:
        return ['VIEW_FREQUENCY.PER_OCCURRENCE', '']
      default:
    }
  }
  return ['', '']
  // TODO need to fix it on proper way
  // throw Error(`Unable to handle frequency: ${frequencyValues}`)
}

export const convertISOFrequencyToStr = (t: TFunction, str?: string) => {
  const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
  if (str && str !== '') {
    const parts = str.split('/')
    if (parts && parts[0] === 'R' && parts.length === 3) {
      if (parts[2] === 'P1D') {
        return t('VIEW_FREQUENCY.DAILY')
      }
      if (parts[2] === 'P7D') {
        const date = moment(parts[1], 'YYYY-MM-DDThh:mmZ')
        if (date && date.isValid()) {
          return t(`VIEW_FREQUENCY.WEEKLY_${weekdays[date.isoWeekday() - 1]}`)
        }
      }
      if (parts[2] === 'P1M') {
        const date = moment(parts[1], 'YYYY-MM-DDThh:mmZ')
        if (date && date.isValid()) {
          if (date.date() === 1) {
            return t('VIEW_FREQUENCY.MONTH_BEGINNING')
          }
          if (date.date() === 31) {
            return t('VIEW_FREQUENCY.MONTH_END')
          }
          return t(`VIEW_FREQUENCY.MONTH`, { param1: date.date() })
        }
      }
      if (parts[2] === 'P1Y') {
        const date = moment(parts[1], 'YYYY-MM-DDThh:mmZ')
        if (date && date.isValid()) {
          return t(`VIEW_FREQUENCY.ANNUAL`, {
            param1: t(`${generateMonth(1 + date.month())}`),
            param2: date.date(),
          })
        }
      }
    } else if (parts && parts[0] === 'R' && parts.length === 2) {
      if (parts[1] === 'P1M') {
        return t('VIEW_FREQUENCY.MONTH_ANNIVERSARY')
      }
      if (parts[1] === 'P1Y') {
        return t('VIEW_FREQUENCY.ANNUAL_ANNIVERSARY_OF_ACCOUNT')
      }
    }
    return str
  }
  return t('N/A')
}
