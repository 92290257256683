import actions from '../actions'
import { ProductsState } from '../types'

const reducer = {
  [actions.create.type]: (state: ProductsState) => ({
    ...state,
    loading: true,
  }),
  [actions.createSuccess.type]: (state: ProductsState, action: ReturnType<typeof actions.createSuccess>) => ({
    ...state,
    loading: false,
    selectedProduct: action.payload,
  }),
  [actions.createFail.type]: (state: ProductsState) => ({
    ...state,
    loading: false,
  }),
}
export default reducer
