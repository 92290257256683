import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box } from '@material-ui/core'
import { Field } from 'formik'
import { H2, Body2, RadioGroupFieldComplex } from 'components'
import {
  StraightLineRoundingAdjustment,
  DecliningBalanceRoundingAdjustment,
  AmortisationMethod,
} from 'store/products/types'

interface OwnProps {
  amortisationMethod: AmortisationMethod
}
const RoundingAdjustmentForm = ({ amortisationMethod }: OwnProps) => {
  const { t } = useTranslation()

  const CalculationRoundingAdjustment =
    amortisationMethod === AmortisationMethod.STRAIGHT_LINE
      ? StraightLineRoundingAdjustment
      : DecliningBalanceRoundingAdjustment

  const amortisationMethodText =
    amortisationMethod === AmortisationMethod.STRAIGHT_LINE ? t('straight line') : t('declining balance')

  const optionsCount = Object.keys(CalculationRoundingAdjustment).length

  return (
    <Grid item style={{ marginTop: 20 }}>
      <H2 gutterBottom>{t('Rounding adjustment')}</H2>
      <Body2 gutterBottom>
        {t('For {{amortisationMethodText}} amortisation, there are {{optionsCount}} options', {
          optionsCount,
          amortisationMethodText,
        })}
      </Body2>
      <Box ml={1}>
        <Field
          name="calculationRoundingAdjustment"
          fullWidth
          data={CalculationRoundingAdjustment}
          component={RadioGroupFieldComplex}
          required
        />
      </Box>
    </Grid>
  )
}

export default RoundingAdjustmentForm
