import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import TasksBadge from './TasksBadge'
import TasksActions from 'store/tasks/actions'
import TasksSelectors from 'store/tasks/selectors'
import { Task } from 'store/tasks/types'
import { RootState } from 'store'
import { selectUserInformation } from 'store/user/selectors'
import { UserInformation } from 'store/user/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { hasGovernanceEnabled } from 'pages/Product/product.utils'
import ProductsSelectors from 'store/products/selectors'

const mapDispatchToProps = {
  tasksExecutionsForAssignee: TasksActions.tasksExecutionsForAssignee,
}
const mapStateToProps = (state: RootState) => ({
  tasks: TasksSelectors.selectTasksForAssignee(state),
  isLoading: TasksSelectors.selectIsLoading(state),
  userInformation: selectUserInformation(state) as UserInformation,
  tenantConfig: selectConfig(state),
  products: ProductsSelectors.selectAllProducts(state),
})

const sortedByDate = (tasks: Task[]) =>
  [...tasks].sort((task1, task2) => new Date(task2.createdAt).getTime() - new Date(task1.createdAt).getTime())

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const TasksBadgePage = ({
  tasks,
  tasksExecutionsForAssignee,
  userInformation,
  isLoading,
  products,
  tenantConfig,
}: Props) => {
  useEffect(() => {
    if (userInformation && userInformation.userKey && hasGovernanceEnabled(tenantConfig)) {
      tasksExecutionsForAssignee(userInformation.userKey)
    }
  }, [tasksExecutionsForAssignee, userInformation, tenantConfig])

  const sortedTasks = tasks ? sortedByDate(tasks).filter((task) => task.entityKey && task.entityType) : []
  return <TasksBadge tasks={sortedTasks} isLoading={isLoading} products={products} />
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksBadgePage)
