// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import moment from 'moment-timezone'
import { InterestRate } from 'store/referenceData/types'
import { Product } from 'store/products/types'
import { ProductStatus } from 'store/products/typings/productStatus'
import { WithholdingTaxFact, WithholdingTaxFactName, WithholdingTaxRule } from 'store/withholdingTax/types'

export const sortByEffectiveDate = ({
  rates,
  desc = true,
}: {
  rates: WithholdingTaxRule[] | InterestRate[]
  desc?: boolean
}) =>
  [...rates].sort(({ effectiveDate }, { effectiveDate: effectiveDate1 }) =>
    desc
      ? moment(effectiveDate1).valueOf() - moment(effectiveDate).valueOf()
      : moment(effectiveDate).valueOf() - moment(effectiveDate1).valueOf()
  )

export function getMostRecentPastRate(rates: WithholdingTaxRule[]): WithholdingTaxRule
export function getMostRecentPastRate(rates: InterestRate[]): InterestRate

export function getMostRecentPastRate(rates = []) {
  const [mostRecentRate] = sortByEffectiveDate({ rates }).filter(({ effectiveDate }) =>
    moment(effectiveDate).isSameOrBefore(moment(), 'day')
  )
  return mostRecentRate
}

export function getNearestFutureRate(rates: WithholdingTaxRule[]): WithholdingTaxRule
export function getNearestFutureRate(rates: InterestRate[]): InterestRate

export function getNearestFutureRate(rates = []) {
  const [nearestFutureRate] = sortByEffectiveDate({ rates, desc: false }).filter(({ effectiveDate }) =>
    moment(effectiveDate).isAfter(moment(), 'day')
  )

  return nearestFutureRate
}

export const pickDateByStatus = (product: Product): string | null => {
  const { status, createdDate, publishedDate, updatedDate, closedDate, effectiveDate } = product
  switch (status) {
    case ProductStatus.DESIGN:
      return createdDate
    case ProductStatus.LIVE:
      return publishedDate
    case ProductStatus.UPDATED:
      return updatedDate
    case ProductStatus.CLOSED:
      return closedDate
    case ProductStatus.REVIEW:
      return updatedDate
    case ProductStatus.APPROVED:
      return effectiveDate
    default:
      return null
  }
}

export const getTaxRuleConditionsText = (
  fact: WithholdingTaxFact,
  ruleValueKey: number,
  t: (key: string) => string,
  value: string | number | boolean,
  currencySymbol: string
) => {
  if (fact.name === WithholdingTaxFactName.NATIONALITY || fact.name === WithholdingTaxFactName.COUNTRY_OF_RESIDENCE)
    return `${fact.value.length > 1 && ruleValueKey === fact.value.length - 1 ? ' or' : ''} ${t(`COUNTRIES.${value}`)}${
      fact.value.length > 1 && ruleValueKey < fact.value.length - 2 ? ',' : ''
    }`

  if (fact.name === WithholdingTaxFactName.PROJECTED_ANNUAL_INCOME)
    return `${fact.value.length >= 1 ? ` ${currencySymbol}${fact.value[0]}` : fact.value[0]}`

  if (fact.name === WithholdingTaxFactName.INTEREST_EARNED_IN_A_PERIOD)
    return `${fact.value.length >= 1 ? ` ${currencySymbol}${fact.value[0]}` : fact.value[0]}`

  if (fact.name === WithholdingTaxFactName.TIN_AVAILABLE || fact.name === WithholdingTaxFactName.TAX_RESIDENCY_APPLIED)
    return `${fact.value.length >= 1 && fact.value[0] ? 'true' : 'false'}`

  if (fact.name === WithholdingTaxFactName.EXEMPTION_REASON)
    return `${fact.value.length > 1 && ruleValueKey === fact.value.length - 1 ? ' or' : ''} ${t(value.toString())}${
      fact.value.length > 1 && ruleValueKey < fact.value.length - 2 ? ',' : ''
    }`

  return value.toString()
}
