import { useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { parse } from 'query-string'
import { INITIAL_FILTERS, TRANSACTIONS_STATE } from '../utils'
import {
  amountFilterOptions,
  Filters,
  isCreditFilterOptions,
  postedTimeStampFilterOptions,
  statusFilterOptions,
} from '../components/TransactionsTableHeadRows'
import {
  CreditOrDebit,
  GetSuspenseTransactionsRequest,
  SuspenseTransactionItem,
  SuspenseTransactionsForm,
  SuspenseTransactionsTransferResult,
} from 'store/accounts/types'
import { bankTzToIso } from 'utils/date.utils'
import SuspenseAccountsActions from 'store/accounts/actions'
import SuspenseAccountsSelectors from 'store/accounts/selectors'
import { NOSTRO_WASH_ACCOUNTNAME, PLACCOUNTNAME } from 'store/utils/constants'
import { FilterOptionsProps } from 'components/FilterMenu'
import useBreadcrumbs from 'pages/Layout/hooks/useBreadcrumbs'

export default (subscriptionKey: string, isSearchPage: boolean) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const searchQuery = parse(location.search)
  const [showSearchResults, setShowSearchResult] = useState(false)
  const [isLoadMoreResult, setIsLoadMoreResult] = useState(false)
  const [showSearchForm, setShowSearchForm] = useState(isSearchPage)
  const [searchOptions, setSearchOptions] = useState<GetSuspenseTransactionsRequest>({
    subscriptionKey: undefined,
    transactionId: undefined,
    customerSubscriptionKey: undefined,
    cr_dr: undefined,
    f_amt: undefined,
    f_dt: undefined,
    t_amt: undefined,
    t_dt: undefined,
    transactionStatus: undefined,
    correlationId: undefined,
  })

  const searchTransaction = useCallback(
    (payload: GetSuspenseTransactionsRequest) => dispatch(SuspenseAccountsActions.getSuspenseTransactions(payload)),
    [dispatch]
  )

  const { t } = useTranslation()
  const [filters, setFilter] = useState<Filters>(INITIAL_FILTERS)
  const navigate = useNavigate()

  const selectTransactionsLoading = useSelector(SuspenseAccountsSelectors.selectTransactionsLoading)
  const account = useSelector(SuspenseAccountsSelectors.selectedSuspenseAccount)
  const accounts = useSelector(SuspenseAccountsSelectors.selectAllSuspenseAccounts)
  const selectedTransactionError = useSelector(SuspenseAccountsSelectors.selectedTransactionError)
  const selectError = useSelector(SuspenseAccountsSelectors.selectError)
  const transactionsPayload = useSelector(SuspenseAccountsSelectors.selectedSuspenseTransactions)
  const isLoadingMore = useSelector(SuspenseAccountsSelectors.selectTransactionsAppending)
  const confirmTransactionResult = useSelector(SuspenseAccountsSelectors.selectedConfirmTransactionResult)

  const showLoadMore = transactionsPayload && transactionsPayload.links && transactionsPayload.links.next
  const currency = account && account.balances && account.balances[0] ? account.balances[0].currency : 'GBP'
  const onTransferClick = (values: SuspenseTransactionsForm) => {
    if (!transactionsPayload) return
    if (account && values.selectedTransactions.length > 0 && transactionsPayload.transactions) {
      const transferedData: SuspenseTransactionsTransferResult = {
        suspenseAccount: account,
        destinationAccount:
          values.destinationAccount && [PLACCOUNTNAME, NOSTRO_WASH_ACCOUNTNAME].includes(values.destinationAccount)
            ? values.destinationAccount
            : find(accounts?.subscriptions, {
                subscriptionKey: values.destinationAccount,
              }),
        selectedTransactions: transactionsPayload.transactions.filter((i: SuspenseTransactionItem) =>
          values.selectedTransactions.includes(i.transactionId)
        ),
      }

      navigate(`/accounts/${account.subscriptionKey}/confirm-transfer`, {
        state: { suspenseTransactionsTransferResult: transferedData as SuspenseTransactionsTransferResult },
      })
    }
  }

  const loadMore = () => {
    if (!transactionsPayload) return

    setIsLoadMoreResult(true)

    const url = new URL(transactionsPayload.links.next)
    // eslint-disable-next-line consistent-return
    return dispatch(
      SuspenseAccountsActions.getSuspenseTransactionsLoadMore({
        subscriptionKey,
        limit: url.searchParams.get('limit') || undefined,
        f_dt: url.searchParams.get('f-dt') || undefined,
        t_dt: url.searchParams.get('t-dt') || undefined,
        t_amt: url.searchParams.get('t-amt') || undefined,
        f_amt: url.searchParams.get('f-amt') || undefined,
        cr_dr: url.searchParams.get('cr-dr') || undefined,
        cursor: url.searchParams.get('cursor') || undefined,
        transactionStatus: url.searchParams.get('transactionStatus') || undefined,
        transactionId: url.searchParams.get('transactionId') || undefined,
        correlationId: url.searchParams.get('correlationId') || undefined,
        customerSubscriptionKey: url.searchParams.get('customerSubscriptionKey') || undefined,
      })
    )
  }

  useEffect(() => {
    if (!isSearchPage || !account) {
      dispatch(SuspenseAccountsActions.getSuspenseAccount(subscriptionKey))
      dispatch(SuspenseAccountsActions.fetchSuspenseAccounts())
    }
  }, [dispatch, subscriptionKey, isSearchPage])

  useBreadcrumbs({
    breadcrumbs: [
      { title: t('Accounts'), path: 'accounts/' },
      { title: account?.name ?? '', path: '' },
    ],
    trigger: !!account,
  })

  let state: TRANSACTIONS_STATE
  if (selectTransactionsLoading) {
    state = TRANSACTIONS_STATE.LOADING
  } else if (!!selectedTransactionError || !!selectError) {
    state = TRANSACTIONS_STATE.ERROR
  } else if (transactionsPayload) {
    state = TRANSACTIONS_STATE.SUCCESS
  } else {
    state = TRANSACTIONS_STATE.SUCCESS
  }
  const statusFilterOptionsLocal = statusFilterOptions(t)
  const onSearch = useCallback(
    (payload?: GetSuspenseTransactionsRequest) => {
      if (isSearchPage && payload === undefined) {
        return
      }

      setIsLoadMoreResult(false)

      if (!isSearchPage) {
        let time: FilterOptionsProps = {}
        if (filters.timestamp) time = postedTimeStampFilterOptions[filters.timestamp]
        else if (searchQuery && searchQuery.time) {
          const filterIndex = postedTimeStampFilterOptions.findIndex(
            (filter) => filter.to === ((searchQuery.time as any) || 0) * -1
          )
          time = postedTimeStampFilterOptions[filterIndex]
          setFilter({ ...INITIAL_FILTERS, timestamp: filterIndex })
        }
        const amount = filters.amount ? amountFilterOptions(currency)[filters.amount] : undefined
        const creditFilter = filters.credit ? isCreditFilterOptions[filters.credit] : undefined

        // eslint-disable-next-line no-nested-ternary
        const transactionStatus = filters.status
          ? statusFilterOptionsLocal[filters.status].status
            ? statusFilterOptionsLocal[filters.status].status?.join(',')
            : undefined
          : undefined
        searchTransaction({
          subscriptionKey,
          t_dt: typeof time?.to !== 'undefined' ? bankTzToIso(moment().add(time.to, 'd').endOf('day')) : undefined,
          f_dt:
            typeof time?.from !== 'undefined' ? bankTzToIso(moment().add(time.from, 'd').startOf('day')) : undefined,
          t_amt: amount ? amount.to : undefined,
          f_amt: amount ? amount.from : undefined,
          cr_dr: creditFilter ? creditFilter.data : undefined,
          transactionStatus: transactionStatus || undefined,
        })
      } else {
        setShowSearchResult(true)
        if (payload) {
          setSearchOptions({ ...payload })
        }
        const payloadGot = payload ? { ...payload } : searchOptions
        if (Object.values(payloadGot).join('') !== '' || !isSearchPage)
          searchTransaction({
            subscriptionKey,
            t_dt: payloadGot.t_dt ? bankTzToIso(moment(payloadGot.t_dt).endOf('day')) : undefined,
            f_dt: payloadGot.f_dt ? bankTzToIso(moment(payloadGot.f_dt).startOf('day')) : undefined,
            t_amt: payloadGot.t_amt,
            f_amt: payloadGot.f_amt,
            transactionStatus: payloadGot.transactionStatus,
            cr_dr: payloadGot.cr_dr !== CreditOrDebit.CREDITANDDEBIT ? payloadGot.cr_dr : undefined,
            customerSubscriptionKey: payloadGot.customerSubscriptionKey
              ? payloadGot.customerSubscriptionKey.trim()
              : undefined,
            transactionId: payloadGot.transactionId ? payloadGot.transactionId.trim() : undefined,
            correlationId: payloadGot.correlationId ? payloadGot.correlationId.trim() : undefined,
          })
      }
    },
    [searchTransaction, subscriptionKey, setShowSearchResult, searchOptions, isSearchPage, filters, currency]
  )

  useEffect(onSearch, [confirmTransactionResult, filters])

  const onReset = () => {
    setShowSearchResult(false)
  }

  return {
    account,
    accounts,
    currency,
    filters,
    isLoadingMore,
    isLoadMoreResult,
    loadMore,
    onSearch,
    onTransferClick,
    setFilter,
    setShowSearchForm,
    setShowSearchResult,
    showLoadMore,
    showSearchForm,
    showSearchResults,
    state,
    transactions: transactionsPayload ? transactionsPayload.transactions : [],
    transactionsPayload,
    onReset,
    searchQuery,
  }
}
