import React, { CSSProperties } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { kebabCase } from 'lodash'
import { Button, Paper, H5 } from 'dls/atoms'

interface Props {
  title: string
  icon: React.ReactNode
  children: React.ReactNode
  loader?: boolean
  style?: CSSProperties
  styleInnerGrid?: CSSProperties
  stretchHeightToContainer?: boolean
  viewAllCta?: string
  widgetId?: string
  buttonComponentType: React.ElementType<any>
}

const WidgetContainer = (props: Props) => {
  const {
    title,
    icon,
    children,
    loader,
    style,
    styleInnerGrid,
    viewAllCta,
    stretchHeightToContainer = false,
    widgetId,
    buttonComponentType: ButtonComponentType,
  } = props
  const { t } = useTranslation()
  return (
    <Paper
      data-dls="organism-widget-container"
      data-test-id={kebabCase(`dashboard-widget-${title}`)}
      style={{ marginBottom: 0, height: stretchHeightToContainer ? '100%' : 'auto' }}
      role="region"
      aria-live="polite"
      id={widgetId}
    >
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ position: 'relative', height: loader ? '90%' : '100%', ...styleInnerGrid }}
      >
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gridColumnGap={12} alignItems="center">
              {icon}
              <H5 variantMapping={{ h5: 'h2' }}>{t(title)}</H5>
            </Box>
            {viewAllCta && (
              <Button component={ButtonComponentType} aria-controls="main" to={viewAllCta} variant="outlined">
                {t('View all')}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems={loader ? 'center' : 'flex-start'}
          justifyContent={loader ? 'center' : 'flex-start'}
          style={{ ...style }}
        >
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default WidgetContainer
