import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReviewRows } from 'dls'
import { RequiredExternalId } from 'store/products/types'

interface OwnProps {
  externalId: RequiredExternalId[]
}

const ViewRequiredExternalId = ({ externalId }: OwnProps): React.ReactElement => {
  const { t } = useTranslation()

  const externalIdDefinitions = externalId?.map((item) => ({
    term: t(item),
    definitions: [t('Active')],
  }))

  const externalIdDetails = [
    {
      definitions: externalIdDefinitions,
    },
  ]

  return <ReviewRows definitionsList={externalIdDetails} />
}

export default ViewRequiredExternalId
