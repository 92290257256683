import styled from 'styled-components/macro'
import Avatar from '@material-ui/core/Avatar'
import React from 'react'
import { Typography } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { getStatusColor, getStatusIcon } from 'pages/Product/product.utils'
import { BankDateTime } from 'utils/date.utils'
import { ProductVersionStatus } from 'store/products/typings/productVersionStatus'

export const StatusContainer = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 168px;
`
export const DateTypography = styled(Typography)`
  font-size: 11px;
  color: ${(props: any) => props.theme.palette.text.secondary};
`
export const StyledAvatar = styled((props) => (
  <Avatar className={props.className} alt={props.alt} src={props.src} variant={props.variant}>
    {getStatusIcon(props.status, props.parsedDate)}
  </Avatar>
))<{ status: ProductVersionStatus; parsedDate: BankDateTime }>`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${(props) => getStatusColor({ text: props.status, date: props.parsedDate, theme: props.theme })};
`

export const StyledDoneStepAvatar = styled((props) => (
  <Avatar className={props.className} alt={props.alt} src={props.src} variant={props.variant}>
    <Done style={{ fontSize: 16 }} />
  </Avatar>
))<{ status: ProductVersionStatus; parsedDate: BankDateTime }>`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`
