import React from 'react'
import { FastField } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import countriesJson from 'static/countries.json'
import { Yip } from 'utils/form.utils'
import FormikMultiSelectAutocomplete, {
  AutocompleteSelectFieldData,
} from 'components/form/FormikMultiSelectAutocomplete'
import { CountryItem } from 'store/utils/types'
import { Flexbox } from 'pages/Layout/Styled'

export const TaxResidencyEligibilityElement = ({ name }: { name: string }) => {
  const { t } = useTranslation()

  const selectDataCountriesJson: AutocompleteSelectFieldData = {}
  Object.entries(countriesJson).forEach(([, item]: [string, CountryItem]) => {
    selectDataCountriesJson[`${item.alpha3Code}`] = {
      key: item.alpha3Code,
      label: `${item.name}`,
      flag: `${item.alpha2Code}`.substring(0, 2),
    }
  })

  return (
    <Flexbox direction="column" width="100%">
      <FastField
        name={`${name}.countryIncluded`}
        label={t('Countries of tax residency')}
        data={selectDataCountriesJson}
        component={FormikMultiSelectAutocomplete}
        validate={Yip(Yup.string().required())}
        hasFlag
        required
        multiple
      />
    </Flexbox>
  )
}
