import React, { Dispatch, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import AccessibleMenu, { AccessibleMenuItem } from '../AccessibleMenu/AccessibleMenu'
import { getProductVersionURLPostfix } from '../../utils/productVersion.utils'
import { ProductVersionLoader } from './ProductVersionLoader'
import { ArrowForwardGray } from './Styled'
// eslint-disable-next-line import/no-cycle
import { VersionStatusBadge } from 'components'
import { stopPropagation } from 'utils/form.utils'
import { Product } from 'store/products/types'

interface Props {
  anchorEl: HTMLElement
  loading: boolean
  error: boolean
  showProductVersions: (event: React.MouseEvent<HTMLButtonElement>) => void
  productVersions: Product[]
  setAnchorEl: Dispatch<null | HTMLElement>
  currentProduct: Product
  menuButton: ReactElement
  id: string
}

export default function ProductVersionSelectorMenu({
  id,
  anchorEl,
  error,
  loading,
  productVersions,
  showProductVersions,
  currentProduct,
  setAnchorEl,
  menuButton,
}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleClose = (e: React.MouseEvent, postfixUrl: string = '') => {
    stopPropagation(e)
    if (postfixUrl !== '') {
      navigate(`/products/${currentProduct.productKey}/${postfixUrl}`)
    }
    setAnchorEl(null)
  }
  const menuItems: AccessibleMenuItem[] = [
    ...productVersions.map(
      (item, index): AccessibleMenuItem => ({
        onClick: (e: React.MouseEvent) => handleClose(e, index > 0 ? getProductVersionURLPostfix(item) : ' '),
        testId: item.productKey,
        ariaControls: 'main',
        children: (
          <VersionStatusBadge
            status={item.status}
            date={item.effectiveDate}
            version={item.version}
            name={item.productName}
          />
        ),
      })
    ),
    {
      testId: 'all-versions',
      onClick: (e: React.MouseEvent) => handleClose(e, 'versions/'),
      children: (
        <>
          <Typography component="span" align="left" variant="body2">
            {t('All versions – full product timeline')}
          </Typography>
          <ArrowForwardGray />
        </>
      ),
    },
  ]
  return (
    <AccessibleMenu
      id={id}
      anchorEl={anchorEl}
      onClose={(e: React.MouseEvent) => handleClose(e)}
      isLoading={loading || productVersions.length === 0 || error}
      loadingComponent={<ProductVersionLoader loading={loading} error={error} onClick={showProductVersions} />}
      menuItems={menuItems}
      menuButton={menuButton}
    />
  )
}
