import React from 'react'
import { DialogActions, Grid, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { Form, FormikProps, FastField } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { productMatrix } from './productMatrix'
import { withFormikSimple, Yip } from 'utils/form.utils'
import { SelectFieldComplex, SubmitButton, TextFieldComplex, ModalWrapper } from 'components'
import { CreateProductPayload } from 'store/products/types'
import { selectConfig } from 'store/tenant-config/selectors'
import { InfoPanel } from 'dls'
import { Button } from 'dls/atoms/Button'
import { ColorType } from 'dls/shared/types'

interface OwnProps {
  onPressCancel: () => void
}

interface ProductFormProps extends FormikProps<CreateProductPayload>, OwnProps {}

const StyledSubmitButton = styled(SubmitButton)`
  width: 150px;
`

const StyledDialogActions = styled(DialogActions)`
  padding-top: 20px;
  padding-right 0px;
`

const ProductCoreForm = ({ onPressCancel }: ProductFormProps) => {
  const { t } = useTranslation()
  const tenantConfig = useSelector(selectConfig)

  return (
    <Form>
      <ModalWrapper
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography variant="h4" variantMapping={{ h4: 'h2' }} style={{ marginBottom: 24 }}>
            {t('Create product')}
          </Typography>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FastField
              name="productName"
              type="name"
              label={t('Product name')}
              validate={Yip(Yup.string().required().max(100))}
              required
              fullWidth
              component={TextFieldComplex}
              inputLabelProps={{
                shrink: true,
              }}
              placeholder={t('Enter name')}
              margin="none"
              css="margin: 0.2em 0"
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <InfoPanel
                body={
                  <>
                    <Typography variant="body2">
                      {t(`The product Segment, Group and Line can't be changed later`)}
                    </Typography>
                  </>
                }
                css="margin-bottom: 8px"
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <FastField
              name="productTaxonomy"
              label={t('Product Segment : Group : Line', { nsSeparator: '||' })}
              validate={Yip(Yup.string().required())}
              required
              data={productMatrix(tenantConfig)}
              selectProps={{ displayEmpty: true }}
              inputLabelProps={{ shrink: true }}
              component={SelectFieldComplex}
              css="margin: 0.2em 0"
              data-test-id="product-taxonomy-dropdown"
            />
          </Grid>
          <Grid item xs={12}>
            <FastField
              name="productDescription"
              type="description"
              placeholder={t('Enter description')}
              label={t('Description')}
              validate={Yip(Yup.string().nullable().max(200))}
              fullWidth
              component={TextFieldComplex}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <StyledDialogActions>
          <Button color={ColorType.BUTTON} type="button" onClick={onPressCancel}>
            {t('Cancel')}
          </Button>
          <StyledSubmitButton
            data-test-id="product-custom-create-button"
            css="margin-left:16px !important"
            type="submit"
            color={ColorType.BUTTON}
            variant="contained"
          >
            {t('Create')}
          </StyledSubmitButton>
        </StyledDialogActions>
      </ModalWrapper>
    </Form>
  )
}

export default withFormikSimple<OwnProps, CreateProductPayload>(ProductCoreForm)
