import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { ExtendedProduct } from 'store/products/types'
import CurrencySymbol from 'components/CurrencySymbol/CurrencySymbol'
import { ReviewRows } from 'dls'
import { ReviewRowGroup } from 'dls/molecules/ReviewRows/types'

interface OwnProps {
  product: ExtendedProduct
}

const StyledWrapper = styled.div`
  width: 100%;

  [data-dls='molecules-ReviewRow']:last-child hr {
    display: none;
  }

  [data-dls='molecules-review-row']:last-child hr {
    display: none;
  }
`

const ViewpaymentSchedule = ({ product }: OwnProps): React.ReactElement => {
  const { t } = useTranslation()
  const { paymentSchedule } = product
  const prodDetails: ReviewRowGroup[] = [
    {
      definitions: [
        {
          term: t('Amount range'),
          definitions: [
            <>
              {t('Min')}{' '}
              <CurrencySymbol
                currencyCode={product?.currency?.currencyCode ? product.currency.currencyCode : null}
                noTypography
              />
              {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(
                (paymentSchedule?.amount?.minimum === '' ? null : paymentSchedule?.amount?.minimum) as any
              )}
              {' – '}
              {t('Max')}{' '}
              <CurrencySymbol
                currencyCode={product?.currency?.currencyCode ? product.currency.currencyCode : null}
                noTypography
              />
              {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(
                (paymentSchedule?.amount?.maximum === '' ? null : paymentSchedule?.amount?.maximum) as any
              )}
            </>,
          ],
        },
        {
          term: t('Term'),
          definitions: [
            <>
              {paymentSchedule?.term?.minimum}
              {' – '}
              {paymentSchedule?.term?.maximum} {t(`${paymentSchedule?.term?.type}`)?.toLowerCase()}
            </>,
          ],
        },
        {
          term: t('Amortisation method'),
          definitions: [t(`${paymentSchedule?.amortisationMethod}`)],
        },
        {
          term: t('Rounding adjustment'),
          definitions: [t(`${paymentSchedule?.calculationRoundingAdjustment}`)],
        },
        {
          term: t('Repayment frequencies'),
          definitions: [
            <>
              {paymentSchedule?.allowedRepaymentFrequencies &&
                paymentSchedule.allowedRepaymentFrequencies.map((i) => t(`VIEW_FREQUENCY.${i}`)).join(', ')}
            </>,
          ],
        },
      ],
    },
  ]

  return (
    <StyledWrapper>
      <ReviewRows definitionsList={prodDetails} />
    </StyledWrapper>
  )
}

export default ViewpaymentSchedule
