import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { ReviewRows } from 'dls'
import { CreditLimitResponse } from 'store/products/typings/creditLimit'
import { getCurrencyInfo } from 'api/utils'

interface OwnProps {
  currencyCode: string
  creditLimit: CreditLimitResponse
}

const ViewCreditLimit = (props: OwnProps): React.ReactElement => {
  const { creditLimit, currencyCode } = props
  const { t } = useTranslation()

  const definitions = currencyCode
    ? [
        {
          term: t('Min amount'),
          definitions: [
            <Box key={currencyCode} display="flex">
              {getCurrencyInfo(currencyCode)?.nativeSymbol}
              {new Intl.NumberFormat('en-GB').format(creditLimit.minMaxCreditLimit?.minimum || 0)}
            </Box>,
          ],
        },
        {
          term: t('Max amount'),
          definitions: [
            <Box key={currencyCode} display="flex">
              {getCurrencyInfo(currencyCode)?.nativeSymbol}
              {new Intl.NumberFormat('en-GB').format(creditLimit.minMaxCreditLimit?.maximum || 0)}
            </Box>,
          ],
        },
      ]
    : []

  return <ReviewRows definitionsList={[{ definitions }]} />
}

export default ViewCreditLimit
