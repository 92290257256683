import React from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { CardLimitContainer } from './styles'
import { CardTransactionLimit, CardTransactionLimitsRoutingDestination } from 'store/products/typings/cardLimits'
import { H6, CardHeader, ReviewRows } from 'dls'
import {
  getPerNonCashTransaction,
  getCumulativeNonCashDaily,
} from 'pages/features/CardTransactionLimits/utilities/transformers'
import { getCurrencyInfo } from 'api/utils'
import buildDefinitionsForNonCash from 'pages/features/CardTransactionLimits/utilities/buildDefinitionsForNonCash'

interface OwnProps {
  cardTransactions: CardTransactionLimit[]
  currencyCode: string
  showActions?: boolean
  hasSeparators?: boolean
  productKey?: string
  inline?: boolean
}

const NonCashTransactionLimitsRow = ({
  cardTransactions,
  currencyCode,
  showActions = false,
  hasSeparators = false,
  productKey = '',
  inline = false,
}: OwnProps) => {
  const { t } = useTranslation()

  const preFilteredTransactions = cardTransactions.filter((limit) =>
    limit.routingDestinations.some((dest) => dest === CardTransactionLimitsRoutingDestination.CARD_NON_CASH)
  )
  const definitions = buildDefinitionsForNonCash({
    perNonCashTransaction: getPerNonCashTransaction(preFilteredTransactions),
    cumulativeNonCashDaily: getCumulativeNonCashDaily(preFilteredTransactions),
    currencySymbol: getCurrencyInfo(currencyCode)?.nativeSymbol,
    productKey,
    t,
    hasSeparators,
    showActions,
    ariaLabelledBy: 'non-cash-transaction-limits-heading',
  })

  return (
    <CardLimitContainer>
      <Box mb={3}>
        <CardHeader
          title={
            <H6 variantMapping={{ h6: 'h4' }} id="non-cash-transaction-limits-heading" style={{ fontWeight: 'bold' }}>
              {t('Non-cash transaction limits')}
            </H6>
          }
          headerIcon={inline ? <PersonOutlineIcon /> : undefined}
        />
      </Box>
      <ReviewRows definitionsList={definitions} />
    </CardLimitContainer>
  )
}

export default NonCashTransactionLimitsRow
