import React from 'react'
import ACheckbox, { ACheckboxProps } from 'dls/atoms/ACheckbox'
import FieldLabel, { FieldLabelCustomProps } from 'dls/atoms/FieldLabel'
import { LabelMode } from 'dls/shared/types'

export interface MCheckboxProps extends ACheckboxProps {
  InputLabelProps?: FieldLabelCustomProps
  name: string
  checked: boolean
  label?: React.ReactNode
  error?: boolean
  data?: string[]
}

const MCheckbox = ({ InputLabelProps, label, checked, error, ...props }: MCheckboxProps) => (
  <FieldLabel
    data-dls="molecule-checkbox"
    labelMode={LabelMode.RIGHT_LABEL}
    error={error}
    {...InputLabelProps}
    control={<ACheckbox data-dls="molecule-checkbox" id={props.name} {...props} checked={checked} />}
  >
    {label}
  </FieldLabel>
)

export default MCheckbox
